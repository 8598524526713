
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "datatables.net/js/jquery.dataTables";
import "datatables.net-bs4/js/dataTables.bootstrap4.js";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-buttons-bs4";
import { Link, useHistory } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "../../../Loader/Loader";
import SVG from "react-inlinesvg";
import Select, { components } from 'react-select';
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import "./css/today.scss";
import { useTable, useFilters, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import matchSorter from 'match-sorter'
//import makeData from "./../../utils/makeData";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { generateToken,teleCaller, searchLoan, clearMessages, telePhoneCall, telePhoneClearMessages } from '../../../../store/teleCaller';
import { eventList } from '../../../../store/events';
//import EditModal from "../../Today/Details/components/EditModal";
import EditModal from "./Details/components/EditModal";
import { techdialer } from "../../../../utils/constant";
const axios = require('axios');
let tempPageSize,tempPageIndex=0;
function TeleCaller(props) {
    // console.log('Today page props:', techdialer.hostname);
    const history = useHistory();
    const dispatch = useDispatch();
    //const [showAddAgencyForm, setShowAddAgencyForm] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [teleCallingData, setTeleCallingData] = useState([]);
    const [selectedData, setSelectedData] = useState('');
    const [searchLoanId, setSearchLoanId] = useState('');
    const [searchTelephone, setSearchTelephone] = useState('');
    const [options, setOptions] = useState([]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(true);
    const [dispositionData, setDispositionData] = useState();
    const [disLoanId, setDisLoanId] = useState();
    const [counter, setCounter] = useState(0);
    const [terminalIdInState, setTerminalIdInState] = useState();
    const [dialedLoanId, setDialedLoanId] = useState('');

    const [data, setData] = React.useState([]);
    // const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);

    const user = useSelector(state => state.entities.auth.data);
    const { status, error, loading, message, offset, limit, hasMore, total } = useSelector((state) => state.entities.teleCaller);
    let eventListData = useSelector((state) => state.entities.events.eventList.data.loan);
    let terminalId = useSelector((state) => state.entities.auth.terminalId);
    // console.log(" terminalId ====== ", terminalId);
    //console.log("teleCaller response:", error, status, loading, message);
    let isPrimaryNo = null, isPrimaryEmail = null;

    const { status: telePhoneStatus, error: telePhoneError, loading: telePhoneLoading, message: telePhoneMsg } = useSelector((state) => state.entities.teleCaller.telePhoneCallData);

    const columns =[
        {
            Header: <div style={{ textAlign: 'left' }}>Loan Id</div>,
            accessor: 'loan.loanId',
            // accessor: (value) => {
            //   return <div><button onClick={displayTodayLoanInfo(value.loan.loanId)}>{value.loan.loanId}</button></div >
            // },
            Cell: ({ cell }) => {
                return <>
                    <div className="loanIdDownloadBtnDiv">
                        <button
                            className="btn btn-sm btn-clean view-log-detail loanTblDownloadLoanIdBtn"
                            title="View details"
                            value={cell.row.original.loan.loanId}
                            onClick={() => displayTodayLoanInfo(cell.row.original.loan.loanId)}
                        >
                            {/* <i className="flaticon2-file"></i> */}
                            {cell.row.original.loan.loanId}
                        </button>
                    </div>
                </>
            },
            filter: 'fuzzyText',
            disableSortBy: true,
            disableFilters: true,
            //Filter: DefaultColumnFilter
        },
        {
            Header: 'Customer Name',
            accessor: 'fullName',
            filter: 'fuzzyText',
            disableSortBy: true,
            disableFilters: true,
        },
        {
            Header: 'Phone',
            accessor: 'phone',
            Cell: ({ cell }) => {
                //console.log('cell:', cell);
                let options = [], selectedValue = null, primaryNoLbl = null, primaryNo = null;
                if (cell.row.original && cell.row.original.contactDetails && cell.row.original.contactDetails.telephone) {
                    if (cell.row.original.contactDetails.telephone.length !== 0) {
                        cell.row.original.contactDetails.telephone.map((isPrimaryTelephoneitem) => {
                            options.push({
                                value: isPrimaryTelephoneitem.contactValue,
                                label: isPrimaryTelephoneitem.maskedContactValue,
                                isPrimary: isPrimaryTelephoneitem.isPrimary
                            })

                            if (isPrimaryTelephoneitem.isPrimary == 'Y') {
                                primaryNoLbl = isPrimaryTelephoneitem.maskedContactValue;
                                primaryNo = isPrimaryTelephoneitem.contactValue;
                                selectedValue = isPrimaryTelephoneitem.contactValue;
                            }
                        })
                    }
                }

                return (
                    <div>
                        {options && options.length == 0 ? '' : (
                            <div className="teleSelectDiv">
                                <div>
                                    <i className="flaticon2-phone phoneIcon" onClick={() => {
                                        // alert(" test "+ selectedValue)
                                        console.log(" selectedValue ", selectedValue);
                                        if (selectedValue !== null) {
                                            makeCall(selectedValue,cell.row.original.loan.loanId);
                                            // console.log('call:', cell.row.original.loan.loanId);
                                            // setSelectedData(cell.row.original);
                                            // if(selectedData) 
                                            // dispatch(telePhoneCall(user.userId, user.telephone, selectedValue, cell.row.original.loan.loanId, cell.row.original.loan.financierId, cell.row.original.loan.agencyId));
                                            // dispatch(addEvents('DIALER INVOKE', user.userId, cell.row.original.loan.financierId, cell.row.original.loan.agencyId, cell.row.original.loan.loanId, 0, 0))
                                            
                                            
                                            //Below enter your no to test call directly
                                            //dispatch(telePhoneCall(user.userId, 'your mobile no.', 'other test no.', cell.row.original.loan.loanId, cell.row.original.loan.financierId, cell.row.original.loan.agencyId));
                                        }
                                    }}></i>
                                </div>
                                {options && options.length == 1 ? (
                                    <div className="ml-4">{options[0].value}</div>
                                )
                                    :
                                    (
                                        <div className="selectDropDownDiv">
                                            <Select
                                                placeholder="Select..."
                                                // name="role"
                                                className="jobRoleSelect"
                                                value={options.value}
                                                defaultValue={{ label: primaryNoLbl ? primaryNoLbl : 'Select...', value: primaryNo ? primaryNo : 'Select...' }}
                                                onChange={options => {
                                                    //console.log('options:', options);
                                                    selectedValue = options.value;
                                                }}
                                                options={options}
                                                menuPortalTarget={document.body}
                                                maxMenuHeight={190}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    </div>
                );

            },
            disableSortBy: true,
            disableFilters: true,
        },
        {
            Header: 'Action',
            accessor: 'loan',
            Cell: ({ cell }) => {
                // console.log('action row data:', (cell.row.original.loan.loanId == dialedLoanId ? true : false));


                return <>
                    <div className="indivisualtodayLoanData">
                        <div className="indivisualtodayLoanDataInnerDiv">
                            <button
                                disabled={(cell.row.original.loan.loanId == dialedLoanId ? false : true)}
                                id="upload"
                                test={cell.row.original.loan.loanId}
                                className="btn btn-primary font-weight-bold upload transformBtn"
                                onClick={() => addDispositon(cell.row.original, true)}
                            >
                                Add Disposition
                            </button>
                        </div>
                    </div>
                </>;
            },
            disableSortBy: true,
            disableFilters: true,
        },
    ];

    let teleCallerLoanData = [], teleCallerLoanDataCopy = [], agencyFinancierListData = [], setLimit = 10;
    teleCallerLoanData = useSelector((state) => state.entities.teleCaller.data.teleCallers);
    let totalRecord = useSelector((state) => state.entities.teleCaller.total);
    teleCallerLoanDataCopy = useSelector((state) => state.entities.teleCaller.data.teleCallers);
    console.log('totalRecord:', totalRecord);
    agencyFinancierListData = useSelector((state) => state.entities.loan.agenciesFinanciersList.data);
    //console.log("agencyFinancierListData:", agencyFinancierListData);


    const makeCall = async (mobileNumber,loanId) => {
        // alert(mobileNumber)

        if(!terminalIdInState) {
            setErrorAlertMsg('Please set your terminal id from your profile');
            setShowErrorAlert(true);
            return false;
        }
        // generateToken();
        mobileNumber = mobileNumber;
        try {
            setDialedLoanId(loanId)
            let tokenData = await axios.get(`${techdialer.hostname}/GetAgentToken?hostId=${user.userId}`)
            // console.log(" data ", tokenData.data.ResultString);
            if(tokenData.status == 200) {
                let token = tokenData.data.ResultString;
                loanId = `${loanId};`;
                let callData = await axios.get(`${techdialer.hostname}/MakeCall?dni=1019&cli=${mobileNumber}&addon=${loanId}&hostId=${user.userId}&tspltoken=${token}`)
                console.log(" callData ", callData);
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if(terminalId) {
            setTerminalIdInState(terminalId);
        }
    },[terminalId])
    
    //only first itme called with offset 0 and limit 50
    // useEffect(() => {
    //     console.log(" testign ");
    //     if (teleCallerLoanData.length === 0) {
    //         dispatch(teleCaller(user.userId, user.agencyId, 0, setLimit));
    //         enableLoading();
    //     }
    // }, []);

    useEffect(() => {
        dispatch(eventList(user.userId));
    }, [])

    useEffect(() => {
        if (teleCallerLoanData.length !== 0) {
            console.log(" if ===== ");
            setTeleCallingData(teleCallerLoanData);
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                const startRow = tempPageSize * tempPageIndex;
                const endRow = startRow + tempPageSize;
                setData(teleCallerLoanData);
                setPageCount(Math.ceil(totalRecord / tempPageSize));
        
            }
        }
    }, [teleCallerLoanData]);

    let errorMsg = "Something went wrong. Please try again.";
    //entity error
    useEffect(() => {
        if (status === "error") {
            if (typeof error === "object") {
                errorMsg = error.message;
            }
            else {
                errorMsg = error;
            }
            setErrorAlertMsg(errorMsg);
            setShowErrorAlert(true);
            disableLoading();
            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
                dispatch(clearMessages());
            }, 2000);
        }
    }, [status])

    //entity telephone error
    useEffect(() => {
        if (telePhoneStatus === "error") {
            if (typeof telePhoneError === "object") {
                errorMsg = telePhoneError.message;
            }
            else {
                errorMsg = telePhoneError;
            }
            setErrorAlertMsg(errorMsg);
            setShowErrorAlert(true);
            disableLoading();
            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
                dispatch(telePhoneClearMessages());
            }, 2000);
        }
    }, [telePhoneStatus])


    useEffect(() => {
        if (status === "success" && !loading) {
            disableLoading();
        }
    }, [status, loading])

    useEffect(() => {
        if (status === "success" && message !== null && message !== undefined) {
            setErrorAlertMsg(message);
            setShowErrorAlert(true);
            setTeleCallingData(teleCallerLoanDataCopy);
            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
                dispatch(clearMessages());
            }, 2000)
            
        }
    }, [status, message])

    useEffect(() => {
        console.log(" counter ", counter);
        if(counter > 1 && counter < 12) {
            setTimeout(() => {
                setCounter(counter+1);
                dispatch(eventList(user.userId));
            }, 10000);
        }
        
    }, [counter]);

    useEffect(() => {
        if (telePhoneStatus === "success" && telePhoneMsg !== null && telePhoneMsg !== undefined) {
            // alert("tesitnh");
            setSuccessAlertMsg(telePhoneMsg);
            console.log(" selectedData ", selectedData);
            addDispositon(selectedData, false);
            setCounter(counter+2);
            setShowSuccessAlert(true);
            setTimeout(() => {
                setSuccessAlertMsg(null);
                setShowSuccessAlert(false);
                dispatch(telePhoneClearMessages());
            }, 4000)
        }
    }, [telePhoneStatus, telePhoneMsg])

    function displayTodayLoanInfo(teleCallerLoanInfoData) {
        //console.log('onclick called:', teleCallerLoanInfoData);
        if (teleCallerLoanInfoData) {
            history.push({
                pathname: '/teleCaller/details',
                loanId: teleCallerLoanInfoData
            });
        }
    }

    const refreshTeleCallerLoanInfo = () => {
        if (user) {
            dispatch(teleCaller(user.userId, user.agencyId, 0, setLimit));
            enableLoading();
        }
    }

    const enableLoading = () => {
        setPageLoading(true);
    };

    const disableLoading = () => {
        setPageLoading(false);
    };

    const searchRecord = () => {
        console.log('searchTelephone,searchLoanId', searchTelephone, searchLoanId)

        if (searchLoanId || searchTelephone) {
            let setLoanId = searchLoanId.toUpperCase() || null;
            teleCallerLoanData = teleCallerLoanData.filter((item) => {
                item.contactDetails.telephone.filter((telephoneNumber) => {
                    //console.log('contactValue,searchTelephone', telephoneNumber.contactValue, searchTelephone)
                    if (telephoneNumber.contactValue === searchTelephone) {
                        setLoanId = (item.loan.loanId).toUpperCase();
                    }
                })
                return ((item.loan.loanId).toUpperCase()) == setLoanId;
            });

            //console.log('teleCallerLoanData:', teleCallerLoanData);
            if (teleCallerLoanData.length == 0) {
                //console.log('teleCallerLoanData length:', teleCallerLoanData.length);
                dispatch(searchLoan(user.userId, user.agencyId, setLoanId, searchTelephone, user.financierId));
            }
            else {
                setTeleCallingData(teleCallerLoanData);
            }
        }
        else {
            let errMsg = 'Please enter atleast one field.';
            setErrorAlertMsg(errMsg);
            setShowErrorAlert(true);

            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
            }, 2000)
        }
    }


    const nextRecord = () => {
        console.log(" hasMore ", hasMore, loading, offset)
        //console.log(" test ", status, error, loading, message, offset, limit, hasMore, total);
        if (hasMore === true && loading === false) {
            //console.log(" testing application ======= ");
            //console.log('Calling api:', (offset + setLimit));
            dispatch(teleCaller(user.userId, user.agencyId, (offset + setLimit), setLimit), user.financierId);
            // dispatch(teleCaller(user.userId, user.agencyId, (offset + setLimit), setLimit));
            //console.log('called api:', (offset + setLimit));
        }
    }

    const hideEditModal = () => {
        setShowEditModal(false);
        setDispositionData('');
        setDisLoanId('');
    }

    const addDispositon = (loanData, cancel) => {
        let temp = {}
        temp.techInfoAgentLoginId = user.userId;
        temp.techInfoAgentTerminalId = terminalId;
        let newObj = {
            ...loanData,
            ...temp
        };
        console.log(" newObj ", newObj);

        setDisLoanId(loanData.loan.loanId);
        setDispositionData(newObj);
        setShowEditModal(true);
        setCancelModal(cancel)
    }

    const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
        dispatch(teleCaller(user.userId, user.agencyId, pageIndex, pageSize, user.financierId));
    }, []);


    return (
        <div className="logs-card-div">
            {loading ? <Loader /> : " "}
            {/* <LoadingOverlay
                active={loading || telePhoneLoading}
                spinner
                text={telePhoneLoading ? 'Initiating the call' : 'Loading...'}
                // classNamePrefix='pg-list-loader-'
                styles={{
                    wrapper: {
                        overflow: loading || telePhoneLoading ? 'hidden' : 'hidden'
                    },
                    spinner: (base) => ({
                        ...base,
                        width: '75px',
                        '& svg circle': {
                            stroke: '#00b1cc'
                        }
                    })
                }}
            > */}
                <Card className="loanInfoCard">
                    <CardHeader>
                        <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-start flex-column">
                            <div className="d-flex justify-content-center align-items-start flex-column">
                                <h3 className="mb-0">Tele Caller</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4 d-block">
                            {showErrorAlert &&
                                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                    {errorAlertMsg}
                                </Alert>
                            }

                            {showSuccessAlert &&
                                <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                                    {successAlertMsg}
                                </Alert>
                            }
                        </div>
                        <div className="col-lg-3 pr-2 d-flex justify-content-end align-items-center">
                            <button className="defaultBtn transformBtn" onClick={refreshTeleCallerLoanInfo}>
                                <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                            </button>
                        </div>
                    </CardHeader>
                    <CardHeader>
                        <div className="pl-0 customSearch d-flex justify-content-start align-items-center">
                            <div className="d-flex justify-content-start align-items-center">
                                <input
                                    value={searchLoanId}
                                    onChange={(e) => {
                                        if (e.target.value == undefined) {
                                            //console.log('undefined searchLoanId e.target.value:', e.target.value)
                                            setTeleCallingData(teleCallerLoanDataCopy);
                                        }
                                        if (e.target.value == '') {
                                            //console.log('searchLoanId e.target.value:', e.target.value)
                                            setTeleCallingData(teleCallerLoanDataCopy);
                                        }
                                        if (e.target.value == null) {
                                            //console.log('null searchLoanId e.target.value:', e.target.value)
                                            setTeleCallingData(teleCallerLoanDataCopy);
                                        }
                                        setSearchLoanId(e.target.value || '') // Set undefined to remove the filter entirely

                                    }}
                                    placeholder="Loan Id"
                                    className="searchFilter w-75"
                                />
                                <input
                                    value={searchTelephone}
                                    onChange={e => {
                                        //console.log('telephone e.target.value:', e.target.value, searchTelephone)
                                        if (e.target.value == undefined) {
                                            //console.log('undefined telephone e.target.value:', e.target.value)
                                            setTeleCallingData(teleCallerLoanDataCopy);
                                        }
                                        if (e.target.value == '') {
                                            //console.log(' telephone e.target.value:', e.target.value)
                                            setTeleCallingData(teleCallerLoanDataCopy);
                                        }
                                        if (e.target.value == null) {
                                            //console.log('null telephone e.target.value:', e.target.value)
                                            setTeleCallingData(teleCallerLoanDataCopy);
                                        }
                                        setSearchTelephone(e.target.value || '') // Set undefined to remove the filter entirely
                                    }}
                                    placeholder="Phone"
                                    className="searchFilter w-75 ml-4"
                                />
                                <button
                                    id="submit"
                                    type="submit"
                                    className="btn btn-primary font-weight-bold upload transformBtn ml-4 pt-2 pb-2"
                                    onClick={searchRecord}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody className="p-4">
                        {teleCallingData && (
                            // <Table columns={columns} data={data} nextRecord={nextRecord} />
                            <Table
                                columns={columns}
                                data={teleCallingData}
                                fetchData={fetchData}
                                loading={loading}
                                pageCount={pageCount}
                            />
                        )}


                        {showEditModal && (<EditModal showModal={showEditModal} hideModal={hideEditModal}
                            editDispositionData={dispositionData}
                            loanId={disLoanId}
                            dispositionTeleCallPage="dispositionTeleCallPage" cancelModal={cancelModal} />
                        )}
                    </CardBody>
                </Card>
                <Card className="loanInfoCard">
                    <CardHeader>
                        
                    </CardHeader>
                    <CardBody className="p-4">
                    {user && user.userId && terminalIdInState && (
                        <iframe 
                            // src={`https://dialer.acenna.in/panel?agent_login_id=ace_agent1&agent_terminal_id=4001`} 
                            src={`https://dialer.acenna.in/panel?agent_login_id=${user.userId}&agent_terminal_id=${terminalIdInState}`} 
                            title="description" 
                            width="100%" 
                            height="700" 
                            style={{borderWidth:0}}
                            >
                            
                        </iframe>
                    )}

                    {!terminalIdInState &&(
                        <div style={{'textAlign':'center'}}>
                            <button
                                    // id="submit"
                                    // type="submit"
                                    className="btn btn-primary font-weight-bold upload transformBtn ml-4 pt-2 pb-2"
                                    // onClick={searchRecord}
                                >
                                    <Link to="/user-profile/terminal-id" style={{color:'white'}}>Set Terminal Id</Link>
                                    {/* Set Terminal Id */}
                                </button>
                        </div>
                    )}
                    
                    </CardBody>
                </Card>
            {/* </LoadingOverlay> */}
        </div>
    );
}

export default TeleCaller;

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <div>
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({
    columns,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount
  }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      // Get the state from the instance
      state: { pageIndex, pageSize }
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 }, // Pass our hoisted table state
        manualPagination: true, // Tell the usePagination
        // hook that we'll handle our own data fetching
        // This means we'll also have to provide our own
        // pageCount.
        pageCount: controlledPageCount
      },
      usePagination
    );
  
    // Listen for changes in pagination and use the state to fetch our new data
    useEffect(() => {
        // dispatch(teleCaller(user.userId, user.agencyId, (offset + setLimit), setLimit), user.financierId);
        console.log(" fetchData is called ");
        tempPageIndex = pageIndex;
        tempPageSize = pageSize;
        fetchData({ pageIndex, pageSize });
        
    }, [fetchData, pageIndex, pageSize]);
  
    // Render the UI for your table
    return (
      <>
        <div>
            {/* <pre>
            <code>
                {JSON.stringify(
                {
                    pageIndex,
                    pageSize,
                    pageCount,
                    canNextPage,
                    canPreviousPage
                },
                null,
                2
                )}
            </code>
            </pre> */}
            <div className="tblScroll">
                <table {...getTableProps()} className="teleCallerTable">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                                <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                    : ""}
                                </span>
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                );
                            })}
                            </tr>
                        );
                        })}
                        <tr>
                        {loading ? (
                            // Use our custom loading state to show a loading indicator
                            <td colSpan="10000">Loading...</td>
                        ) : (
                            <td colSpan="10000">
                            Showing {page.length} of ~{controlledPageCount * pageSize}{" "}
                            results
                            </td>
                        )}
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* 
            Pagination can be built however you'd like. 
            This is just a very basic UI implementation:
            */}
            <div className="pagination" style={{ justifyContent:'space-around' }} >
                    
                <div>
                    <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {"<<"}
                    </button>{" "}
                    <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {"<"}
                    </button>{" "}
                    <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}
                    </button>{" "}
                    <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {">>"}
                    </button>{" "}
                </div>
                <div>
                    <span>
                        Page{" "}
                        <strong>
                        {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                    </span>
                    <span>
                        | Go to page:{" "}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            style={{ width: "100px" }}
                            className="gotoPage"
                        />
                    </span>{" "}
                </div>
                <div>
                    <select
                        value={pageSize}
                        onChange={(e) => {
                        setPageSize(Number(e.target.value));
                        }}
                        className="selectDisplayNoOfRecords"
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
      </>
    );
  }

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'