import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const initialState = {
  data: {},
  loading: false,
  lastFetch: null,
  message: null,
  status: undefined,
  error: {},
}

const slice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    statisticsRequested: (statistics, action) => {
      statistics.loading = true;
      statistics.message = null;
      statistics.status = undefined;
      statistics.error = {};
    },
    statisticsReceived: (statistics, action) => {
      statistics.data = action.payload.data;
      statistics.loading = false;
      statistics.lastFetch = Date.now();
      statistics.message = null;
      statistics.status = "success";
      statistics.error = {};
    },
    statisticsRequestFailed: (statistics, action) => {
      statistics.loading = false;
      statistics.message = null;
      statistics.status = undefined;
      statistics.error =
        action.payload.response !== null ||
          action.payload.response !== undefined
          ? action.payload.response
          : action.payload.message
    },
    clearMessagesRequested: (statistics) => {
      statistics.status = undefined;
      statistics.message = null;
      statistics.error = {};
    },
    clearStatisticsStorageRequested: () => initialState,
  },
});

const {
  statisticsRequested,
  statisticsReceived,
  statisticsRequestFailed,
  clearMessagesRequested,
  clearStatisticsStorageRequested
} = slice.actions;
export default slice.reducer;

// Action Creators
export const getStatistics = (mapView, userId, startDate, endDate) => (dispatch, getState) => {
  console.log("Invoking getStatistics Api", mapView);

  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: { feature: "FINANCIERS_DASHBOARD_MAP_STATISTICS", mapView, userId, startDate,endDate },
      onStart: statisticsRequested.type,
      onSuccess: statisticsReceived.type,
      onError: statisticsRequestFailed.type,
    })
  );
};

export const clearMessages = () => (dispatch) => {
  console.log("in clearMessages...");
  dispatch(clearMessagesRequested());
};

export const clearStatisticsStorage = () => (dispatch) => {
  console.log("in clearStatisticsStorage...");
  dispatch(clearStatisticsStorageRequested());
};
