import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'react-moment';
import DatePicker from 'react-date-picker';


import "../css/generatedData.scss";
import "../../Loan/css/loan.scss";

import { useTable, useFilters, useGlobalFilter, usePagination, useSortBy, useRowSelect, useExpanded } from 'react-table';
import matchSorter from 'match-sorter'

//import makeData from "./../../utils/makeData";

import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { trackLegal, searchLoan, clearMessages } from '../../../store/trackLegalNotice';
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from './Toggler';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: '#000',
    },
}));

function TrackExpandableTbl(props) {
    const classes = useStyles();
    console.log('TrackExpandableTbl:', props);
    const history = useHistory();
    const dispatch = useDispatch();
    //const [showAddAgencyForm, setShowAddAgencyForm] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [pageLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchLoanId, setSearchLoanId] = useState('');
    const [searchTelephone, setSearchTelephone] = useState('');
    const [expanded, setExpanded] = React.useState(false);

    const user = useSelector(state => state.entities.auth.data);
    const { status, error, loading, message, offset, limit, hasMore, total } = useSelector((state) => state.entities.trackLegal);

    //console.log("trackLegal response:", error, status, loading, message);
    let isPrimaryNo = null, isPrimaryEmail = null;

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    var date = new Date();
    var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
    var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    //console.log('firstDay , lastDay', firstDay, lastDay);


    let [startDate, setStartDate] = useState(new Date(firstDay));
    let [endDate, setEndDate] = useState(new Date(lastDay));

    const columns = React.useMemo(
        () => [
            {
                Header: 'Batch Number',
                accessor: 'batchNumber',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'E-Mail',
                accessor: 'email',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Phone',
                accessor: 'mobile',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Sent Date',
                accessor: (value) => (value.sendDate ? <Moment format="DD-MM-YYYY hh:mm">{value.sendDate}</Moment> : ''),
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Sms Sent',
                accessor: "smsSend",
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Email Sent',
                accessor: "emailSend",
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Reference #',
                accessor: 'refrence',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Actions',
                accessor: 'fileName',
                Cell: ({ cell }) => {

                    return (
                        <div>
                            <a
                                className="btn btn-sm btn-clean btn-icon view-log-detail"
                                title="Download"
                                // type="button"
                                href={cell.row.original.shortUrl}
                                target="_blank"
                            >
                                <i className="flaticon2-download-2"></i>
                            </a>
                        </div>
                    )
                },
                disableSortBy: true,
                disableFilters: true,
            },


        ],
        []
    )

    let trackLegalLoanData = [], trackLegalLoanDataCopy = [], setLimit = 150;

    useEffect(() => {
        if (props && props.trackExpandableData) {
            trackLegalLoanData = props.trackExpandableData;
            trackLegalLoanDataCopy = props.trackExpandableData;
            console.log('props data:', trackLegalLoanData);
            setData(trackLegalLoanData);
        }
    }, [props])



    let errorMsg = "Something went wrong. Please try again.";

    //entity error
    useEffect(() => {
        if (status === "error") {
            if (typeof error === "object") {
                errorMsg = error.message;
            }
            else {
                errorMsg = error;
            }
            setErrorAlertMsg(errorMsg);
            setShowErrorAlert(true);
            disableLoading();
            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
                dispatch(clearMessages());
            }, 2000);
        }
    }, [status])

    useEffect(() => {
        if (status === "success" && !loading) {
            disableLoading();
        }
    }, [status, loading])

    useEffect(() => {
        if (status === "success" && message !== null && message !== undefined) {
            setErrorAlertMsg(message);
            setShowErrorAlert(true);
            setData(trackLegalLoanDataCopy);
            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
                dispatch(clearMessages());
            }, 2000)

        }
    }, [status, message])




    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };




    return (
        <div className="logs-card-div">
            <LoadingOverlay
                active={loading}
                spinner
                text='Loading...'
                // classNamePrefix='pg-list-loader-'
                styles={{
                    wrapper: {
                        overflow: loading ? 'hidden' : 'hidden'
                    },
                    spinner: (base) => ({
                        ...base,
                        width: '75px',
                        '& svg circle': {
                            stroke: '#00b1cc'
                        }
                    })
                }}
            >
                <Card className="loanInfoCard tblExpandedMarginBorder">
                    <CardHeader>
                        <div className="col-lg-4 pl-0 d-flex justify-content-center align-items-start flex-column">
                            <div className="d-flex justify-content-center align-items-start flex-column">
                                <h3 className="mb-0">Legal Notice Details</h3>
                            </div>
                        </div>

                    </CardHeader>
                    <CardBody className="p-0">
                        {data && (
                            <Table columns={columns} data={data} />
                        )}
                    </CardBody>
                </Card>
            </LoadingOverlay>
        </div>
    );
}

export default TrackExpandableTbl;




// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter }, }) {
    const count = preFilteredRows.length

    return (
        <div>
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    )
}


function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val



// Our table component
function Table({ columns, data }) {
    console.log(" Table columns ", columns);
    console.log(" Tabledata", data);


    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )



    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        //state,
        visibleColumns,
        //preGlobalFilteredRows,
        //setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10 }
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,//sort data
        useExpanded,
        usePagination,//use pagination
    )

    return (
        <>
            <div>
                <div className="tblScroll">
                    <table {...getTableProps()} className="trackExpandTbl">
                        <thead className="tableHead">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                                    {headerGroup.headers.map((column, index) => (
                                        <th key={index}>
                                            <table style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render('Header')}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? '↓'
                                                                        : '↑'
                                                                    : ''}
                                                            </span>
                                                        </th>
                                                    </tr>
                                                    {/* <tr>
                                                        <th>{column.canFilter ? column.render('Filter') : null}</th>
                                                    </tr> */}
                                                </thead>
                                            </table>
                                        </th>
                                    ))}
                                </tr>
                            ))}

                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} className="tableTd">{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}

                {/* <div>Showing the first 20 results of {rows.length} rows</div>
      <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div> */}
            </div>
        </>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

