export const numberToCrore = (number = 0) => {
    return number ? ((number / 10000000).toFixed(2)) : 0.00;
}

export const getYears = () => {
    let temp = []
    for (let index = 2020; index <= 2030; index++) {
        temp[index] = { value: index, label: index }
    }
    return temp;
}

export const getCurrentYearMonthDate = (type) => {
    var d = new Date(),
    month = "" + ("0" + (d.getMonth() + 1)).slice(-2),
    day = "" + ("0" + d.getDate()).slice(-2),
    // day = "" + d.getDate(),
    year = d.getFullYear();
    if(type =='year') {
        return year;
    }
    else if(type =='month') {
        return month;
    }
    else if(type =='day') {
        return day;
    }
    else {
        return [year, month, day];
    }
    
}

export const getAllMonth = (month = 0) => {

    let monthNames = [
        { value: '01', label: 'January' },
        { value: '02', label: 'February' },
        { value: '03', label: 'March' },
        { value: '04', label: 'April' },
        { value: '05', label: 'May' },
        { value: '06', label: 'June' },
        { value: '07', label: 'July' },
        { value: '08', label: 'August' },
        { value: '09', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' },
    ]
    if(month) {
        return monthNames.filter((mn) => mn.value == month);
    }
    else {
       return monthNames;
    }
    
}

export const dateTimeFormat = (dateTime) => {
    // console.log(" dateTime ------ ", dateTime.split('T')[1]);
    var d = new Date(dateTime),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    // console.log(time)
    return [day, month, year].join("-")+" "+(dateTime.split('T')[1].substring(0, 8));
}