import React from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logout } from "./../../../store/authentication";

export default function Logout(props) {
  const dispatch = useDispatch();
      dispatch(logout());
  return <Redirect to="/auth/login" />;
}



