import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { original } from "immer";

const initialState = {
  data: null,
  loading: false,
  lastFetch: null,
  message: null,
  status: undefined,
  error: {},
  bioAttendanceList: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  }
};

const slice = createSlice({
  name: "biometric",
  initialState,
  reducers: {
    bioAttendanceListRequested: (biometric, action) => {
      biometric.bioAttendanceList.loading = true;
      biometric.bioAttendanceList.message = null;
      biometric.bioAttendanceList.status = undefined;
      biometric.bioAttendanceList.error = {};
    },
    bioAttendanceListReceived: (biometric, action) => {
      biometric.bioAttendanceList.data = action.payload.data;
      // console.log('bioAttendanceListReceived data here =========', action.payload.data);
      biometric.bioAttendanceList.loading = false;
      biometric.bioAttendanceList.lastFetch = Date.now();
      biometric.bioAttendanceList.message = null;
      biometric.bioAttendanceList.status = "success";
      biometric.bioAttendanceList.error = {};
    },
    bioAttendanceListRequestFailed: (biometric, action) => {
      biometric.bioAttendanceList.loading = false;
      biometric.bioAttendanceList.message = null;
      biometric.bioAttendanceList.status = undefined;
      biometric.bioAttendanceList.error = "Error";
    },

    clearMessagesRequested: (biometric) => {
      biometric.status = undefined;
      biometric.message = null;
      biometric.error = {};
    },
    clearUserListStorageRequested: () => initialState,

    currentSelectedRow: (biometric, action) => {
      biometric.statusManagement.rowData = action.payload;
    }
  },
});

const {
  bioAttendanceListRequested,
  bioAttendanceListReceived,
  bioAttendanceListRequestFailed,

  clearMessagesRequested,
  clearUserListStorageRequested,

  currentSelectedRow,
  statusChange,
} = slice.actions;
export default slice.reducer;

// Action Creators
export const bioAttendanceList = (userId, startDate, endDate, employeeName) => (
  dispatch,
  getState
) => {
  // console.log("Invoking bioAttendanceList Panel Api", userId, startDate, endDate);
  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: {
        feature: "BIOMETRIC",
        featureType: "BIO_ATTENDANCE_LIST",
        startDate: startDate,
        endDate: endDate,
        userId: userId,
        employeeName:employeeName,
      },
      onStart: bioAttendanceListRequested.type,
      onSuccess: bioAttendanceListReceived.type,
      onError: bioAttendanceListRequestFailed.type,
    })
  );
};


export const clearMessages = () => (dispatch) => {
  console.log("in clearMessages...");
  dispatch(clearMessagesRequested());
};

export const clearUserListStorage = () => (dispatch) => {
  console.log("in clearUserListStorageRequested...");
  dispatch(clearUserListStorageRequested());
};
