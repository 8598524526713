import React, { useEffect, useState } from 'react'
import './css/dashboard_component.scss'
import {
    injectIntl
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Alert from 'react-bootstrap/Alert';
import DatePicker from 'react-date-picker';

import {
    Card,
    CardBody,
    CardHeader,
} from "../../../_metronic/_partials/controls";

import FinancierStatusCard from './components/FinancierStatusCard'
import LoadingOverlay from 'react-loading-overlay';
import Loader from '../Loader/Loader';
import { financierClearMessages } from '../../store/financier';
import { agencyClearMessages } from '../../store/agency';
import { financierStatus } from '../../store/dashboard';
import Select from 'react-select';
import moment from 'moment';
import { getAllMonth, getCurrentYearMonthDate, getYears } from '../../utils/helperFunctions';

function DashboardComponent() {
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [filterType, setFilterType] = useState('monthly');
    const [monthEnd, setMonthEnd] = useState();
    const [year, setYear] = useState(getCurrentYearMonthDate('year'));
    const [month, setMonth] = useState(getCurrentYearMonthDate('month'));
    const [defaultMonthValue, setDefaultMonthValue] = useState(null);
    const [defaultYearValue, setDefaultYearValue] = useState(null);
    let financiersStatus;

    console.log("defaultYearValue==========", defaultYearValue);

    const dispatch = useDispatch();
    financiersStatus = useSelector((state) => state.entities.dashboard.financierStatus);
    console.log(" financiersStatus ======= ", financiersStatus);
    const user = useSelector(state => state.entities.auth.data);
    const [isAgency, setIsAgency] = useState(false);



    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    var date = new Date();
    var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
    var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    let [startDate, setStartDate] = useState(new Date(firstDay));
    let [endDate, setEndDate] = useState(new Date(lastDay));
    const { status, error, loading, message } = useSelector((state) => state.entities.dashboard.financierStatus);

    useEffect(() => {
        if (status === "error") {
            let errorMsg = "Something went wrong. Please try again.";
            if (typeof error === "object") {
                errorMsg = error.message;
            }
            else {
                errorMsg = error;
            }
            setErrorAlertMsg(errorMsg);
            setShowErrorAlert(true);
            setTimeout(() => {
                if (user.role !== 'Agency Manager') {
                    dispatch(financierClearMessages());
                }
                dispatch(agencyClearMessages());
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
            }, 2000);
        }
    }, [status, dispatch])

    useEffect(() => {
        // console.log(" getAllMonth ", getAllMonth(month));
        // console.log(" month ", month);
        if (month) {
            setDefaultMonthValue(getAllMonth(month));
        }
        console.log(" defaultMonthValue ===== ", defaultMonthValue);
    }, [month])


    useEffect(() => {
        // console.log(" getAllMonth ", getAllMonth(month));
        // console.log(" month ", month);
        if (year) {
            setDefaultYearValue(year);
        }
        // console.log(" defaultMonthValue ===== ", defaultMonthValue);
    }, [year])



    useEffect(() => {
        if (financiersStatus.data === null) {
            if (firstDay && lastDay) {
                console.log('user:', user);
                dispatch(financierStatus(user.userId, user.agencyId, firstDay, lastDay, user.financierId));
            }
        }
    }, [])

    const filterTypeOptions = [
        { value: 'monthly', label: 'Monthly' },
        { value: 'daterange', label: 'Date Range' }
    ];


    useEffect(() => {
        if (user.role === 'Agency Manager') {
            setIsAgency(true);
        }
    }, [isAgency, user.role])


    const searchFinancierStatus = (searchType = 'normal') => {
        let formateStartDate = formatDate(startDate);
        let formateEndDate = formatDate(endDate);
        console.log(" searchType ==== ", searchType);
        setMonthEnd(formateEndDate);
        if (formateStartDate && formateEndDate) {
            if (searchType == 'currentMonth') {
                formateStartDate = moment(new Date()).startOf('month').format("YYYY-MM-DD");
                formateEndDate = moment(new Date()).endOf('month').format("YYYY-MM-DD");
                let CurrentMonth = moment(new Date()).endOf('month').format("MM");
                let CurrentYear = moment(new Date()).endOf('month').format("YYYY");
                console.log("LastMonth------", CurrentMonth);
                console.log("CurrentYear------", CurrentYear);
                setYear(CurrentYear);
                setMonth(CurrentMonth)
                setMonthEnd(formateEndDate);
            }
            else if (searchType == 'lastMonth') {
                formateStartDate = moment(new Date()).subtract(1, 'month').startOf('month').format("YYYY-MM-DD")
                formateEndDate = moment(new Date()).subtract(1, 'month').endOf('month').format("YYYY-MM-DD")
                let LastMonth = moment(new Date()).subtract(1, 'month').endOf('month').format("MM")
                let CurrentYear = moment(new Date()).subtract(1, 'month').endOf('month').format("YYYY")
                console.log("LastMonth------", LastMonth);
                console.log("CurrentYear------", CurrentYear);
                setYear(CurrentYear);
                setMonth(LastMonth)
                setMonthEnd(formateEndDate);
            }
            else {
                formateEndDate = moment(`${year}-${month}`).endOf('month').format('YYYY-MM-DD');
                formateStartDate = moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD');
                setMonthEnd(formateEndDate);
            }
            dispatch(financierStatus(user.userId, user.agencyId, formateStartDate, formateEndDate, user.financierId));
        }
    }

    return (
        <div className="dashboard-component-div">
            {loading ? <Loader /> : " "}
            {/* <LoadingOverlay
                active={loading}
                spinner
                text="Loading..."
                className="loading-spinner-custom-color"
                classNamePrefix='dashboard-page-loader-'
                styles={{
                    wrapper: {
                        overflow: loading ? 'hidden' : 'hidden'
                    },
                    spinner: (base) => ({
                        ...base,
                        width: '75px'
                    })
                }}
            > */}
                <div className="row">
                    {/* {dashboardBodyContent} */}
                    <Card className="financierStatus ml-4 mr-4 w-100">
                        <CardHeader>
                            <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-start flex-column">
                                <h3 className="mb-0 tracking-wide">Financier Status</h3>
                            </div>
                            <div className="col-lg-4 mt-4 d-block">
                                {showErrorAlert &&
                                    <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                        {errorAlertMsg}
                                    </Alert>
                                }

                                {showSuccessAlert &&
                                    <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                                        {successAlertMsg}
                                    </Alert>
                                }
                            </div>
                            {/* <div className="col-lg-3 pr-4 d-flex justify-content-end align-items-center">
                                <div className=" d-flex justify-content-center align-items-center">
                                    <button className="defaultBtn transformBtn" onClick={refreshFinancierStatusInfo}>
                                        <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                                    </button>
                                </div>
                            </div> */}
                        </CardHeader>
                        <CardHeader>
                            <div className="col-lg-12 pl-0 pt-4 d-flex justify-content-start align-items-center">
                                <div className="d-none col-lg-4 pl-0 justify-content-center align-items-center">
                                    <div className="form-group">
                                        <label>Select Filter</label>
                                        <Select
                                            placeholder="Select..."
                                            name="export_type"
                                            className="jobRoleSelect w-100"
                                            options={filterTypeOptions}
                                            onChange={(data) => {
                                                setFilterType(data.value);
                                            }}
                                        />

                                    </div>
                                </div>
                                {filterType == 'daterange' &&
                                    <>
                                        <div className="col-lg-4 pl-0 justify-content-center align-items-center">
                                            <div className="form-group">
                                                <label>From date</label>
                                                <DatePicker
                                                    onChange={value => {

                                                        let formatedStartDate = formatDate(value);
                                                        if (formatedStartDate) {
                                                            setStartDate(new Date(formatedStartDate));
                                                        }


                                                        //formikProps.setFieldValue("startDate", value)}
                                                    }}
                                                    value={startDate}
                                                    name="startDate"
                                                    // minDate={new Date()}
                                                    format="dd-MM-y"
                                                    className="form-control form-control-solid h-auto readOnlySpan"
                                                    clearIcon={null}
                                                    calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pl-0 justify-content-center align-items-center">
                                            <div className="form-group">
                                                <label>To date</label>
                                                <DatePicker
                                                    onChange={value => {

                                                        let formatedEndDate = formatDate(value);
                                                        if (formatedEndDate) {
                                                            // console.log(" new Date(formatedEndDate ", new Date(formatedEndDate));
                                                            setEndDate(new Date(formatedEndDate));
                                                        }

                                                        // if (endDate) {
                                                        //     console.log('end date:', endDate);
                                                        // }
                                                        //formikProps.setFieldValue("endDate", value)}
                                                    }}
                                                    value={endDate}
                                                    name="endDate"
                                                    //minDate={new Date(startDate)}
                                                    format="dd-MM-y"
                                                    className="form-control form-control-solid h-auto readOnlySpan"
                                                    clearIcon={null}
                                                    calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                {filterType == 'monthly' &&
                                    <>
                                        {defaultYearValue && (
                                            <div className="col-lg-3 pl-0 justify-content-center align-items-center">
                                                <div className="form-group">
                                                    <label>Select Year</label>
                                                    <Select
                                                        defaultValue={{label: defaultYearValue, value: defaultYearValue}}
                                                        placeholder="Select..."
                                                        name="export_type"
                                                        className="jobRoleSelect w-100"
                                                        options={getYears()}
                                                        // onChange={(e)=> setYear(e.target.value)}
                                                        onChange={(data) => {
                                                            console.log(" testDatavalue-------- ", data.value);
                                                            setYear(data.value);
                                                        }}
                                                        value={{label: defaultYearValue, value: defaultYearValue}}
                                                    />

                                                </div>
                                            </div>
                                        )}

                                        {defaultMonthValue && (
                                            <div className="col-lg-3 pl-0 justify-content-center align-items-center">
                                                <div className="form-group">
                                                    <label>Select Month</label>
                                                    <Select
                                                        defaultValue={defaultMonthValue}
                                                        placeholder="Select..."
                                                        name="export_type"
                                                        className="jobRoleSelect w-100"
                                                        options={getAllMonth()}
                                                        onChange={(data) => {
                                                            // console.log(" test ", data.value);
                                                            let currentYear = year;
                                                            if (!currentYear) {
                                                                currentYear = date.getFullYear()
                                                            }
                                                            var my_date = new Date("" + currentYear + ", " + data.value + "");
                                                            var first_date = formatDate(new Date(my_date.getFullYear(), my_date.getMonth(), 1));
                                                            var last_date = formatDate(new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0));
                                                            setStartDate(new Date(first_date));
                                                            setEndDate(new Date(last_date));
                                                            setMonth(data.value);
                                                            console.log(` first_date = ${first_date}, last_date = ${last_date}`);
                                                        }}
                                                        value={defaultMonthValue}
                                                    />

                                                </div>
                                            </div>
                                        )}
                                    </>
                                }

                                <div className="col-lg-2">
                                    <button
                                        id="button"
                                        type="button"
                                        className="btn btn-primary font-weight-bold upload mr-4 transformBtn" style={{ width: "100%" }}
                                        // disabled={isEmailUnique}
                                        // onClick={searchFinancierStatus}
                                        onClick={() => searchFinancierStatus('normal')}
                                    >
                                        Search
                                    </button>
                                </div>

                                <div className="col-lg-2">

                                    <button
                                        id="button"
                                        type="button"
                                        className="btn btn-primary font-weight-bold upload mr-4 transformBtn" style={{ width: "100%" }}
                                        // disabled={isEmailUnique}
                                        onClick={() => searchFinancierStatus('currentMonth')}
                                    // onClick={()=>searchBucketWisePerformance('today')}
                                    >
                                        Current Month
                                    </button>
                                </div>

                                <div className="col-lg-2">

                                    <button
                                        id="button"
                                        type="button"
                                        className="btn btn-primary font-weight-bold upload mr-4 transformBtn" style={{ width: "100%" }}
                                        // disabled={isEmailUnique}
                                        onClick={() => searchFinancierStatus('lastMonth')}
                                    // onClick={()=>searchBucketWisePerformance('yesterday')}
                                    >
                                        Last Month
                                    </button>
                                </div>
                            </div>

                        </CardHeader>

                        <CardBody className="card-body pl-2 pr-2 pt-4 d-flex flex-wrap">
                            {financiersStatus && financiersStatus.data && (
                                financiersStatus.data.map((financiersStatusData, index) => (
                                    <div key={index + 1} className="col-lg-4">
                                        <FinancierStatusCard financiersStatusData={financiersStatusData} keyForChart={index} endDate={monthEnd} />
                                    </div>
                                ))
                            )}
                        </CardBody>

                    </Card>
                </div>
            {/* </LoadingOverlay> */}
        </div>
    );
}

export default injectIntl(DashboardComponent);