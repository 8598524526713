import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { useHistory } from "react-router-dom";
import Moment from 'react-moment';

// import Select from "react-select";
// import "react-select/dist/react-select.css";
// import "react-table/react-table.css";

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import LoadingOverlay from 'react-loading-overlay';

import "../../Loan/css/loan.scss";

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table';
import matchSorter from 'match-sorter'

//import makeData from "./../../utils/makeData";

import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getAgentActivities, agentActivitiesClearMessages } from "../../../store/dashboard";


function AgentActivities(props) {

    const mapStyles = {
        width: '6vw',
        height: '5vh',
        position: 'relative'
    };
    const history = useHistory();

    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const user = useSelector(state => state.entities.auth.data);

    let agentActivities = useSelector((state) => state.entities.dashboard.agentActivities.data);
    //console.log('agentActivities:', agentActivities);
    const { status, error, loading, message } = useSelector((state) => state.entities.dashboard.agentActivities);
    //console.log("got error...", error, status, loading, message);
    const dispatch = useDispatch();
   

    const columns = React.useMemo(

        // constructor() {
        //     super();
        //     this.state = {
        //       data: makeData(),
        //       filtered: [],
        //       select2: undefined,
        //     };
        //   }
        
        
        () => [
            {
                Header: 'User Id',
                accessor: 'userId',
                // accessor: (value) => {
                //     return `DIP${value.id}`
                // },
                filter: 'fuzzyText',
            },
            {
                Header: 'Name',
                accessor: 'fullName',
                filter: 'fuzzyText',
            },
            {
                Header: 'Action',
                accessor: 'actionText',
                filter: 'fuzzyText',
                // Filter: ({ filter, onChange }) => {
                //     return (
                //       <Select
                //         style={{ width: "100%", marginBottom: "20px" }}
                //         onChange={entry => {
                //           this.setState({ select2: entry });
                //           this.onFilteredChangeCustom(
                //             entry.map(o => {
                //               return o.value;
                //             }),
                //             "fuzzyText"
                //           );
                //         }}
                //         value={this.state.select2}
                //         multi={true}
                //         options={this.state.data.map((o, i) => {
                //           return {
                //             id: i,
                //             value: o.firstName,
                //             label: o.firstName
                //           };
                //         })}
                //       />
                //     );
                //   }
            },
            {
                Header: 'Created At',
                //accessor: 'createdAt',
                accessor: (value) => (<Moment format="DD-MM-YYYY">{value.createdAt}</Moment>),
                filter: 'fuzzyText',
            },
            {
                Header: 'Location',
                accessor: (value) => {
                    return (
                        <div className="customMapStyle">
                            {value && value.latitude && value.longitude && (
                                <a target="blank"
                                    href={`https://maps.google.com?q=${value.latitude},${value.longitude} `}
                                //href={`https://www.google.com/maps/@${value.latitude},${value.longitude},${14}z`}
                                >
                                    <Map
                                        google={props.google}
                                        zoom={14}
                                        style={mapStyles}
                                        initialCenter={{ lat: value.latitude, lng: value.longitude }}

                                    >
                                        <Marker position={{ lat: value.latitude, lng: value.longitude }} />
                                    </Map>
                                </a>
                            )
                            }
                        </div >
                    )
                },
                disableFilters: true,
            },
        ],
        []
    )

    useEffect(() => {
        if (user.role === 'Central Team') {
            if (agentActivities == null) {
                dispatch(getAgentActivities(user.userId));
            }
        }
    }, [])

    useEffect(() => {
        if (status === "error") {
            let errorMsg = "Something went wrong. Please try again.";
            if (typeof error === "object") {
                errorMsg = error.message;
            }
            else {
                errorMsg = error;
            }
            setErrorAlertMsg(errorMsg);
            setShowErrorAlert(true);
            setTimeout(() => {
                dispatch(agentActivitiesClearMessages());
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
            }, 2000);
        }
    }, [status])



    const data = agentActivities;

    const refreshAgentActivities = () => {
        console.log('user role:', user.role)
        // if (user.role === 'Central Team') {
        //if (agentActivities == null) {
        dispatch(getAgentActivities(user.userId));
        //}
        // }
    }
    return (
        <div className="agentActivityDiv">
            <LoadingOverlay
                active={loading}
                spinner
                text='Loading...'
                // classNamePrefix='pg-list-loader-'
                styles={{
                    // overlay: (base) => ({
                    //   ...base,
                    //   background: 'rgba(255, 0, 0, 0.5)'
                    // }),
                    wrapper: {
                        overflow: loading ? 'hidden' : 'hidden'
                    },
                    spinner: (base) => ({
                        ...base,
                        width: '75px',
                        '& svg circle': {
                            stroke: '#00b1cc'
                        }
                    })
                }}
            >
                <Card className="agentActivityDiv-card">
                    <CardHeader>
                        <div className="col-lg-4 d-flex justify-content-start align-items-center pl-0">
                            <h3 className="pl-0">Agent Activities</h3>
                        </div>
                        <div className="col-lg-4 d-flex justify-content-center align-items-center">
                            {showErrorAlert &&
                                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                    {errorAlertMsg}
                                </Alert>
                            }

                            {showSuccessAlert &&
                                <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                                    {successAlertMsg}
                                </Alert>
                            }
                        </div>
                        <div className="col-lg-4 d-flex justify-content-end align-items-center">
                            <button className="defaultBtn transformBtn" onClick={refreshAgentActivities}>
                                <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                            </button>
                        </div>
                    </CardHeader>
                    <CardBody className="p-4">
                        {data && (
                            <Table columns={columns} data={data} />
                        )}
                        {data == null && (
                            <h6 className="subHeader ml-2 mt-2">No data found</h6>
                        )}
                    </CardBody>
                </Card>
            </LoadingOverlay>
        </div>
    );
}

//export default Dispositions;
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDvHvEhe0C4plQ60VSIEnKReJIDqOQ47qw'
})(AgentActivities)


// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
            Search:{' '}
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                className="searchFilter"
            />
        </span>
    )
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <div>
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
        to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10 }
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,//sort data
        usePagination,//use pagination
    )

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    const firstPageRows = rows.slice(0, 10);


    return (
        
        <>
            <div>
                <div className="tblScroll">
                    <table {...getTableProps()} className="agentActivityTable">
                        <thead className="tableHead">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                                    {headerGroup.headers.map((column, index) => (
                                        <th key={index}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render('Header')}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? '↓'
                                                                        : '↑'
                                                                    : ''}
                                                            </span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>{column.canFilter ? column.render('Filter') : null}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            {/* <tr>
                                <th
                                    colSpan={visibleColumns.length}
                                    style={{
                                        textAlign: 'left',
                                    }}
                                >
                                    <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                                </th>
                            </tr> */}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} className="tableTd">{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
                <div className="row m-0">
                    <div className="pagination col-lg-12">
                        <div className="col-lg-2">
                            <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>{' '}
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Total Records{' '}
                                <strong>
                                    {data.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-lg-3">
                            <span>
                                Go to page{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(page)
                                    }}
                                    className="gotoPage"
                                />
                            </span>{' '}
                        </div>
                        <div className="col-lg-3">
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                className="selectDisplayNoOfRecords"
                            >
                                {[10, 20, 30].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                {/* <div>Showing the first 20 results of {rows.length} rows</div>
      <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div> */}
            </div>
        </>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

