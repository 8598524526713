import React, { useState, useEffect, Fragment } from "react";
//import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
//import LogDetailsModal from './components/LogDetailsModal';
//import $ from "jquery";
import "datatables.net/js/jquery.dataTables";
import "datatables.net-bs4/js/dataTables.bootstrap4.js";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-buttons-bs4";
import { useHistory } from "react-router-dom";
import millify from 'millify';
import DatePicker from 'react-date-picker';

import "../../Loan/css/loan.scss";
import "../css/bucketWiseTable.scss";
import "../css/summary.scss";

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy, useExpanded } from 'react-table';
import matchSorter from 'match-sorter';
import LoadingOverlay from 'react-loading-overlay';
import Alert from 'react-bootstrap/Alert';

import FinancierWiseSubRows from "./FinancierWiseSubRows";

import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
//import { toAbsoluteUrl } from "../../../../layout/_metronic/_helpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
//import PrimaryEmail from "../../Loan/components/PrimaryEmail";
//import PrimaryContact from "../../Loan/components/PrimaryContact";
import { financierWisePerformanceClearMessages, financierWisePerformance } from "../../../store/dashboard";
import {
  bucketWisePerformance, portfolio, summary, portfolioGeographyStateWise,
  bucketWisePerformanceClearMessages, portfolioGeographyCityWise, portfolioGeographyDistrictWise, posDistribution,
  portfolioGoeStateClearMessages, portfolioGoeCityClearMessages, posDistributionClearMessages, portfolioExpectedCollections
} from "../../../store/summary";
import Select, { components } from 'react-select';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import DashboardComponent from "../DashboardComponent";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';


function Segmentation(props) {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  //const [pageLoader, setPageLoader] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.entities.auth.data);
  let summaryData = useSelector((state) => state.entities.summary.data);
  let portfolioData = useSelector((state) => state.entities.summary.portfolio_view.data);
  let bucketWiseData = useSelector((state) => state.entities.summary.bucketWisePerformance.data);
  let portfolioGeographicalStateData = useSelector((state) => state.entities.summary.portfolioGoeState.data);
  let portfolioGeographicalCityData = useSelector((state) => state.entities.summary.portfolioGoeCity.data);
  let portfolioGeographicalDistrictData = useSelector((state) => state.entities.summary.portfolioGoeDistrict.data);
  let posDistributionData = useSelector((state) => state.entities.summary.posDistribution.data);
  let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data);
  //console.log("financierWisePerformanceData table Data:", financierWisePerformanceData);
  //   console.log("portfolioData:", portfolioData);

  const mapStyles1 = {
    width: '95.5%',
    height: '93%',
    overflow: 'hidden',
    position: 'relative'
  };

  const mapStyles = {
    width: '95.5%',
    height: '96%',
    overflow: 'hidden',
    position: 'relative'
  };



  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const numFormatter = (num) => {
    let toFixe = 4;
    if (num <= 0) {
      return num.toFixed(2)
    } else if (num > 999 && num < 9999) {
      toFixe = 3
    } else if (num >= 10000) {
      toFixe = 2
    }
    return (num / 1000000).toFixed(toFixe);
  }

  var date = new Date();
  var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  let [startDate, setStartDate] = useState(new Date(firstDay));
  let [endDate, setEndDate] = useState(new Date(lastDay));
  let [startDateDpd, setStartDateDpd] = useState(new Date(firstDay));
  let [endDateDpd, setEndDateDpd] = useState(new Date(lastDay));
  let [bucketWiseFilter, setBucketWiseFilter] = useState(-1);
  //   console.log(" startDate " , startDate);
  //   console.log(" startDateDpd " , startDateDpd);

  // useEffect(() => {
  // 	dispatch(summary(user.userId));
  // 	let formateStartDate = formatDate(startDate);
  // 	let formateEndDate = formatDate(endDate);
  // 	let formateStartDateDpd = formatDate(startDateDpd);
  // 	let formateEndDateDpd = formatDate(endDateDpd);
  // 	dispatch(portfolio(user.userId, user.financierId, formateStartDate, formateEndDate));
  // 	dispatch(bucketWisePerformance(user.userId, user.financierId, formateStartDateDpd, formateEndDateDpd));
  // 	dispatch(portfolioGeographyStateWise(user.userId, user.financierId));
  // 	dispatch(portfolioGeographyCityWise(user.userId));
  // 	dispatch(portfolioGeographyDistrictWise(user.userId));
  // 	dispatch(posDistribution(user.userId));
  // 	dispatch(portfolioExpectedCollections(user.userId) )
  // }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: <div style={{ textAlign: 'center' }}>Total Pos</div>,
        id: 'totalPos',
        accessor: 'totalPos',
        accessor: (value) => {
          return <div style={{ textAlign: 'center', width: '160px' }}>{value.totalPos}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: 'Resolution',
        accessor: 'resolution',
        accessor: (value) => {
          return <div style={{ textAlign: 'center', width: 'auto' }}>{value.resolution}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: 'Total Collection',
        accessor: 'totalCollection',
        accessor: (value) => {
          return <div style={{ textAlign: 'center', width: 'auto' }}>{value.totalCollection}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: 'Expected Collection',
        accessor: 'expectedCollection',
        accessor: (value) => {
          return <div style={{ textAlign: 'center', width: 'auto' }}>{value.expectedCollection}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },

    ],
    []
  )

  const portfolioColumns = React.useMemo(
    () => [
      {
        Header: <div style={{ textAlign: 'center' }}>Accounts Recieved</div>,
        id: 'accountsRecieved',
        accessor: 'accountsRecieved',
        accessor: (value) => {
          return <div style={{ textAlign: 'center', width: '160px' }}>{value.accountsRecieved}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: 'Pos',
        accessor: 'pos',
        accessor: (value) => {
          return <div style={{ textAlign: 'center', width: 'auto' }}>{value.pos}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: 'Repeat',
        accessor: 'repeat',
        accessor: (value) => {
          return <div style={{ textAlign: 'center', width: 'auto' }}>{value.repeat}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: 'Expected Collection',
        accessor: 'expectedCollection',
        accessor: (value) => {
          return <div style={{ textAlign: 'center', width: 'auto' }}>{value.expectedCollection}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },


    ],
    []
  )

  const bucketWiseColumns = React.useMemo(
    () => [
      {
        Header: 'Bucket',
        // accessor: 'bucket',
        accessor: (value) => (<div style={{ textAlign: 'left' }}><div>{value.bucket}</div><div className="descriptionStyle" style={{ color: value.color || 'black' }}>{value.description}</div></div>),
        Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: <div><div>POS</div><div>(M)</div></div>,
        id: 'POS',
        accessor: (value) => (numFormatter(value.pos ? value.pos : 0)),
        Footer: value => {
          let total = 0;
          let bucketWisePerformanceData = useSelector((state) => state.entities.summary.bucketWisePerformance.data);
          if (bucketWisePerformanceData) {
            //console.log('bucketwise');
            bucketWisePerformanceData.map((sum) => {
              total += sum.pos;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (millify(total, { space: true })) : total}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: 'Count',
        accessor: (value) => (value.totalCases ? value.totalCases : 0),
        Footer: value => {
          let total = 0;
          let bucketWisePerformanceData = useSelector((state) => state.entities.summary.bucketWisePerformance.data);
          if (bucketWisePerformanceData) {
            //console.log('bucketwise');
            bucketWisePerformanceData.map((sum) => {
              total += sum.totalCases;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (total) : total}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>Portfolio</div><div>(%)</div></div>,
        id: 'portfolio',
        accessor: (value) => {
          return (value.portfolio ? parseFloat(value.portfolio).toFixed(2) + '%' : 0);
        },
        Footer: value => {
          //   const total = React.useMemo(
          //     () => value.rows.reduce((sum, row) => (parseFloat(row.values.portfolio)) + sum, 0),
          //     [value.rows]
          //   );

          let total = 0;
          console.log(" bucketWise_data Footer ", bucketWise_data);
          if (bucketWise_data && bucketWise_data[0]) {
            // console.log('portfolioGeographicalStateData INside ', portfolioGeographicalStateData);
            bucketWise_data.map((sum) => {
              total += sum.portfolio;
            })
          }

          return <><div className="mt-4 tblFooterColor">{total > 0 ? total.toFixed(2) : 0}%</div></>;
        },
        disableFilters: true,
      },
    ],
    []
  )

  const portfolioStateWiseColumns = React.useMemo(
    () => [
      {
        Header: <div style={{ textAlign: 'center' }}>Sr.No.</div>,
        id: 'id',
        accessor: 'id',
        accessor: (value) => {
          return <div style={{ textAlign: 'center' }}>{value && value.id ? value.id : ''}</div>;
        },
        Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: <div style={{ textAlign: 'center' }}>State Name</div>,
        id: 'name',
        accessor: 'name',
        accessor: (value) => {
          return <div style={{ textAlign: 'center' }}>{value.name}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: <div><div>POS</div><div></div></div>,
        id: 'POS',
        accessor: (value) => (numFormatter(value.pos ? value.pos : 0)),
        Footer: value => {
          let total = 0;
          let portfolioGeographicalStateData = useSelector((state) => state.entities.summary.portfolioGoeState.data);
          if (portfolioGeographicalStateData && portfolioGeographicalStateData[0]) {
            //console.log('bucketwise');
            portfolioGeographicalStateData[0].map((sum) => {
              total += sum.pos;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (millify(total, { space: true })) : total}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>Count</div><div>(#)</div></div>,
        id: 'count',
        accessor: (value) => (value.count ? value.count : 0),
        Footer: value => {
          let total = 0;
          let portfolioGeographicalStateData = useSelector((state) => state.entities.summary.portfolioGoeState.data);
          if (portfolioGeographicalStateData && portfolioGeographicalStateData[0]) {
            console.log('portfolioGeographicalStateData INside ', portfolioGeographicalStateData);
            portfolioGeographicalStateData[0].map((sum) => {
              total += sum.count;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (total) : total}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>% of</div><div>Portfolio</div></div>,
        id: 'portfolio',
        accessor: (value) => {
          return (value.portfolio ? parseFloat(value.portfolio).toFixed(2) : 0);
        },
        Footer: value => {
          let total = 0;
          let portfolioGeographicalStateData = useSelector((state) => state.entities.summary.portfolioGoeState.data);
          if (portfolioGeographicalStateData && portfolioGeographicalStateData[0]) {
            //console.log('bucketwise');
            portfolioGeographicalStateData[0].map((sum) => {
              total += sum.portfolio;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (parseFloat(total).toFixed(2)) : total}</div></>;
        },
        disableFilters: true,
      },
    ],
    []
  )
  //   let srNumber = 0;
  const portfolioCityWiseColumns = React.useMemo(
    () => [
      {
        Header: <div style={{ textAlign: 'center' }}>Sr.No.</div>,
        id: 'id',
        accessor: 'id',
        accessor: (value) => {
          return <div style={{ textAlign: 'center' }}>{value && value.id ? value.id : ''}</div>;
        },
        Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: <div style={{ textAlign: 'center' }}>City Name</div>,
        id: 'name',
        accessor: 'name',
        accessor: (value) => {
          return <div style={{ textAlign: 'center' }}>{value.name}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: <div><div>POS</div><div></div></div>,
        id: 'POS',
        accessor: (value) => (numFormatter(value.pos ? value.pos : 0)),
        Footer: value => {
          let total = 0;
          let portfolioGeographicalCityData = useSelector((state) => state.entities.summary.portfolioGoeCity.data);
          if (portfolioGeographicalCityData && portfolioGeographicalCityData[0]) {
            //console.log('bucketwise');
            portfolioGeographicalCityData[0].map((sum) => {
              total += sum.pos;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (millify(total, { space: true })) : total}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>Count</div><div>(#)</div></div>,
        id: 'count',
        accessor: (value) => (value.totalCases ? value.totalCases : 0),
        Footer: value => {
          let total = 0;
          let portfolioGeographicalCityData = useSelector((state) => state.entities.summary.portfolioGoeCity.data);
          if (portfolioGeographicalCityData[0]) {
            //console.log('bucketwise');
            portfolioGeographicalCityData[0].map((sum) => {
              total += sum.count;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (total) : total}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>% of</div><div>Portfolio</div></div>,
        id: 'portfolio',
        accessor: (value) => {
          return (value.portfolio ? parseFloat(value.portfolio).toFixed(2) : 0);
        },
        Footer: value => {
          let total = 0;
          let portfolioGeographicalCityData = useSelector((state) => state.entities.summary.portfolioGoeCity.data);
          if (portfolioGeographicalCityData && portfolioGeographicalCityData[0]) {
            //console.log('bucketwise');
            portfolioGeographicalCityData[0].map((sum) => {
              total += sum.portfolio;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? parseFloat(total).toFixed(2) : total}</div></>;
        },
        disableFilters: true,
      },
    ],
    []
  )

  let srNumber = 0;
  const portfolioDistrictWiseColumns = React.useMemo(
    () => [
      {
        Header: <div style={{ textAlign: 'center' }}>Sr.No.</div>,
        id: 'id',
        accessor: 'id',
        accessor: (value) => {
          // srNumber = srNumber+1;
          // console.log(" value ====", srNumber);
          return <div style={{ textAlign: 'center' }}>{value && value.id ? value.id : ''}</div>;
          // return <div style={{ textAlign: 'center', width: '15vw' }}>{srNumber}</div>;
        },
        Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: <div style={{ textAlign: 'center' }}>District Name</div>,
        id: 'name',
        accessor: 'name',
        accessor: (value) => {
          return <div style={{ textAlign: 'center' }}>{value.name}</div>;
        },
        // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: <div><div>POS</div><div></div></div>,
        id: 'pos',
        accessor: (value) => (numFormatter(value.pos ? value.pos : 0)),
        Footer: value => {
          let total = 0;
          let portfolioGeographicalDistrictDatas = useSelector((state) => state.entities.summary.portfolioGoeDistrict.data);
          if (portfolioGeographicalDistrictDatas) {
            console.log('portfolioGeographicalDistrictDatas ', portfolioGeographicalDistrictDatas[0]);
            portfolioGeographicalDistrictDatas.map((sum) => {
              total += sum.pos;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (millify(total, { space: true })) : total}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>Count</div><div>(#)</div></div>,
        id: 'count',
        accessor: (value) => (value.count ? value.count : 0),
        Footer: value => {
          let total = 0;
          let portfolioGeographicalDistrictData = useSelector((state) => state.entities.summary.portfolioGoeDistrict.data);
          if (portfolioGeographicalDistrictData) {
            //console.log('bucketwise');
            portfolioGeographicalDistrictData.map((sum) => {
              total += sum.count;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (total) : total}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>% of</div><div>Portfolio</div></div>,
        id: 'portfolio',
        accessor: (value) => {
          return (value.portfolio ? value.portfolio : 0);
        },
        Footer: value => {
          let total = 0;
          let portfolioGeographicalDistrictData = useSelector((state) => state.entities.summary.portfolioGoeDistrict.data);
          if (portfolioGeographicalDistrictData) {
            //console.log('bucketwise');
            portfolioGeographicalDistrictData.map((sum) => {
              total += sum.portfolio;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (total) : total}</div></>;
        },
        disableFilters: true,
      },
    ],
    []
  )

  const posDistributionColumns = React.useMemo(
    () => [
      {
        Header: 'Pos Band',
        // accessor: 'bucket',
        accessor: (value) => (<div style={{ textAlign: 'left' }}><div>{value.posband}</div><div className="descriptionStyle" style={{ color: value.color || 'black' }}>{value.description}</div></div>),
        Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: 'Count',
        id: 'count',
        accessor: (value) => (value.count ? value.count : 0),
        Footer: value => {
          let total = 0;
          let posDistributionData = useSelector((state) => state.entities.summary.posDistribution.data);
          if (posDistributionData) {
            //console.log('bucketwise');
            posDistributionData.map((sum) => {
              total += sum.count;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (total) : total}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>POS</div><div>(M)</div></div>,
        id: 'POS',
        accessor: (value) => (numFormatter(value.pos ? value.pos : 0)),
        Footer: value => {
          let total = 0;
          let posDistributionData = useSelector((state) => state.entities.summary.posDistribution.data);
          if (posDistributionData) {
            //console.log('bucketwise');
            posDistributionData.map((sum) => {
              total += sum.pos;
            })
          }
          return <><div className="mt-4 tblFooterColor">{total ? (millify(total, { space: true })) : total}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>Portfolio</div><div>(%)</div></div>,
        id: 'portfolio',
        accessor: (value) => {
          return (value.portfolio ? value.portfolio : 0);
        },
        Footer: value => {
          const total = React.useMemo(
            () => value.rows.reduce((sum, row) => (parseFloat(row.values.portfolio)) + sum, 0),
            [value.rows]
          );

          return <><div className="mt-4 tblFooterColor">{total > 0 ? total.toFixed(2) : 0}</div></>;
        },
        disableFilters: true,
      },
    ],
    []
  )

  let bucketFilterList = [
    { value: -1, label: 'All' },
    { value: 0, label: '0-30 DPD' },
    { value: 31, label: '31-60 DPD' },
    { value: 61, label: '61-90 DPD' },
    { value: 91, label: '91-120 DPD' },
    { value: 121, label: '121-150 DPD' },
    { value: 151, label: '151-180 DPD' },
    { value: 181, label: '181+ DPD' }
  ]
  const { status, error, loading, message } = useSelector((state) => state.entities.dashboard.financierWisePerformance);
  let bucketwiseError = useSelector((state) => state.entities.summary.bucketWisePerformance);
  let portfolioGoeStateError = useSelector((state) => state.entities.summary.portfolioGoeState);
  let portfolioGoeCityError = useSelector((state) => state.entities.summary.portfolioGoeCity);
  let portfolioGoeDistrictError = useSelector((state) => state.entities.summary.portfolioGoeDistrict);
  let posDistributionError = useSelector((state) => state.entities.summary.posDistribution);
  let portfolioExpectedCollection = useSelector((state) => state.entities.summary.posExpectedCollection);
  console.log(" portfolioExpectedCollection ", portfolioExpectedCollection);
  //console.log("financierWisePerformance...", error, status, loading, message);


  useEffect(() => {
    if (status === "error") {
      let errorMsg = "Something went wrong. Please try again.";
      if (typeof error === "object") {
        errorMsg = error.message;
      }
      else {
        errorMsg = error;
      }
      setErrorAlertMsg(errorMsg);
      setShowErrorAlert(true);
      setTimeout(() => {
        //setPageLoader(false);
        dispatch(financierWisePerformanceClearMessages());
        setErrorAlertMsg('');
        setShowErrorAlert(false);
      }, 2000);
    }
  }, [status])

  useEffect(() => {
    if (bucketwiseError.status === "error") {
      let errorMsg = "Something went wrong. Please try again.";
      if (typeof bucketwiseError.error === "object") {
        errorMsg = bucketwiseError.error.message;
      }
      else {
        errorMsg = bucketwiseError.error;
      }
      setErrorAlertMsg(errorMsg);
      setShowErrorAlert(true);
      setTimeout(() => {
        //setPageLoader(false);
        dispatch(bucketWisePerformanceClearMessages());
        setErrorAlertMsg('');
        setShowErrorAlert(false);
      }, 2000);
    }
  }, [bucketwiseError])

  useEffect(() => {
    if (portfolioGoeStateError.status === "error") {
      let errorMsg = "Something went wrong. Please try again.";
      if (typeof portfolioGoeStateError.error === "object") {
        errorMsg = portfolioGoeStateError.error.message;
      }
      else {
        errorMsg = portfolioGoeStateError.error;
      }
      setErrorAlertMsg(errorMsg);
      setShowErrorAlert(true);
      setTimeout(() => {
        //setPageLoader(false);
        dispatch(portfolioGoeStateClearMessages());
        setErrorAlertMsg('');
        setShowErrorAlert(false);
      }, 2000);
    }
  }, [portfolioGoeStateError])


  //   useEffect(() => {
  //     if (user.role !== 'Agency Manager') {
  //       if (financierWisePerformanceData === null) {
  //         if (firstDay && lastDay) {
  //           dispatch(financierWisePerformance(user.userId, user.agencyId, firstDay, lastDay, bucketWiseFilter));
  //         }
  //       }
  //     }
  //   }, [])



  // const data = React.useMemo(() => makeData(100000), [])
  // const data = React.useMemo(() => financierWisePerformanceData, [])
  const data = summaryData;
  const portfolio_data = portfolioData;
  const bucketWise_data = bucketWiseData;
  //   console.log(" bucketWise_data ==== ", bucketWise_data);
  const portfolioGeographicalState_Data = portfolioGeographicalStateData ? portfolioGeographicalStateData[0] : null;
  const portfolioGeographicalCity_Data = portfolioGeographicalCityData ? portfolioGeographicalCityData[0] : null;
  const portfolioGeographicalDistrict_Data = portfolioGeographicalDistrictData ? portfolioGeographicalDistrictData : null;
  const posDistribution_data = posDistributionData;
  //   console.log(" portfolioGeographicalState_Data ", portfolioGeographicalState_Data);


  //console.log("portfolioGeographicalStateData data:", portfolioGeographicalStateData);

  const refreshFinancierWiseInfo = () => {
    console.log('start date and end date :', firstDay, lastDay, bucketWiseFilter);
    dispatch(financierWisePerformance(user.userId, user.agencyId, firstDay, lastDay, bucketWiseFilter));
    setStartDate(new Date(firstDay));
    setEndDate(new Date(lastDay));
  }



  const searchPortfolio = () => {
    let formateStartDate = formatDate(startDate);
    let formateEndDate = formatDate(endDate);
    console.log(' start date and end date :', formateStartDate, formateEndDate, bucketWiseFilter);
    console.log(" user ", user);
    if (formateStartDate && formateEndDate) {
      dispatch(portfolio(user.userId, user.financierId, formateStartDate, formateEndDate));
    }
  }

  const searchDpd = () => {
    let formateStartDateDpd = formatDate(startDateDpd);
    let formateEndDateDpd = formatDate(endDateDpd);
    console.log(' start date and end date :', formateStartDateDpd, formateEndDateDpd);
    console.log(" user ", user);
    if (formateStartDateDpd && formateEndDateDpd) {
      dispatch(bucketWisePerformance(user.userId, user.financierId, formateStartDateDpd, formateEndDateDpd));
    }
  }

  const renderRowSubComponent = (row) => {
    return (
      <FinancierWiseSubRows financierWiseSubRowsData={row.original.subRows} rowData={row.original} />
    );
  };

  return (
    <div className="logs-card-div">
      {/* Portfolio */}
      <Card className="finacierWise w-100 summaryFin">
        <CardHeader>
  
            <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-start flex-column">
              <h3 className="mb-0 mt-5">Portfolio View</h3>
              {/* <span className="subHeader">Long term loan performance financier wise</span> */}
            </div>
            <div className="col-md-9">
              <h4 className="text-right mt-7">‘Currently you have not added any data for usage under this screen’</h4>
            </div>


          <div className="col-lg-4 mt-4 d-block">
            {showErrorAlert &&
              <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                {errorAlertMsg}
              </Alert>
            }

            {showSuccessAlert &&
              <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                {successAlertMsg}
              </Alert>
            }
          </div>

        </CardHeader>
        <CardHeader>
          <div className="col-lg-12 pt-4 pl-0 d-flex justify-content-start align-items-center">

            <div className="col-lg-3 pl-0 justify-content-center align-items-center">
              <div className="form-group">
                <label>From date </label>
                <DatePicker
                  onChange={value => {
                    let formatedStartDate = formatDate(value);
                    if (formatedStartDate) {
                      setStartDate(new Date(formatedStartDate));
                    }
                  }}
                  value={startDate}
                  name="startDate"
                  // minDate={new Date()}
                  format="dd-MM-y"
                  className="form-control form-control-solid h-auto readOnlySpan"
                  clearIcon={null}
                  calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                />
              </div>
            </div>
            <div className="col-lg-3 pl-0 justify-content-center align-items-center">
              <div className="form-group">
                <label>To date </label>
                <DatePicker
                  onChange={value => {
                    let formatedEndDate = formatDate(value);
                    if (formatedEndDate) {
                      setEndDate(new Date(formatedEndDate));
                    }
                  }}
                  value={endDate}
                  name="endDate"
                  //minDate={new Date(startDate)}
                  format="dd-MM-y"
                  className="form-control form-control-solid h-auto readOnlySpan"
                  clearIcon={null}
                  calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <button
                id="button"
                type="button"
                className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                // disabled={isEmailUnique}
                onClick={searchPortfolio}
              >
                Search
              </button>

            </div>

            <div className="col-lg-3 protbtnflo">
              <button
                id="button"
                type="button"
                className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
              // disabled={isEmailUnique}
              // onClick={searchFinancierWisePerformance}
              >
                Enrich Data
              </button>

            </div>
            {/* <div className="col-lg-2">
                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                  // disabled={isEmailUnique}
                  // onClick={searchFinancierWisePerformance}
                >
                  Upload Data
                </button>

              </div> */}
          </div>

        </CardHeader>
        <CardBody className="p-4">
          {/* <LoadingOverlay
				active={portfolio_data == null}
				spinner
				text="Loading data..."
				className="loading-spinner-custom-color"
				classNamePrefix='dashboard-page-loader-'
				styles={{
				wrapper: {
					overflow: loading ? 'hidden' : 'hidden'
				},
				spinner: (base) => ({
					...base,
					width: '75px'
				})
				}}
			>
			</LoadingOverlay> */}

          <div className="row p-2">
            <div className="col-lg-3 p-2 ">
              <div class="center shadow bg-light-danger high-risk">
                <div className="text-div">
                  <div className="header-text">
                    High Risk
                  </div>
                  <div className="value-text">
                    0 crore
                    {/* {portfolioData && portfolioData.length > 0 ? portfolioData[0].accountsRecieved : 0	} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 p-2 ">
              <div class="center shadow bg-light-primary medium-risk">
                <div className="text-div">
                  <div className="header-text">
                    Medium Risk
                  </div>
                  <div className="value-text">
                    {/* {portfolioData && portfolioData.length > 0 ? millify(portfolioData[0].pos, { space: true }) : 0	} */}
                    0 crore
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 p-2 ">
              <div class="center shadow bg-light-success low-risk">
                <div className="text-div">
                  <div className="header-text">
                    Low risk
                  </div>
                  <div className="value-text">
                    0 crore
                    {/* {portfolioData && portfolioData.length > 0 ? portfolioData[0].repeat : 0	} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 p-2 ">
              <div class="center shadow bg-light-warning nw-pool">
                <div className="text-div">
                  <div className="header-text">
                    NW Pool
                  </div>
                  <div className="value-text">
                    0 crores
                    {/* {portfolioExpectedCollection && portfolioExpectedCollection.data ? millify(Math.round(portfolioExpectedCollection.data.expectedCollection), { space: true }) : 0} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-lg-3 p-2 ">
              <div class="center shadow">
                <div className="text-div">
                  <div className="header-text">
                    Segments
                  </div>
                  <div className="value-text">
                    0
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 p-2 ">
              <div class="center shadow">
                <div className="text-div">
                  <div className="header-text">
                    Segments
                  </div>
                  <div className="value-text">
                    0
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 p-2 ">
              <div class="center shadow">
                <div className="text-div">
                  <div className="header-text">
                    Segments
                  </div>
                  <div className="value-text">
                    {/* 0.75 crore */}
                    0
                    {/* {portfolioData && portfolioData.length > 0 ? portfolioData[0].repeat : 0	} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 p-2 ">
              <div class="center shadow">
                <div className="text-div">
                  <div className="header-text">
                    {/* NW Pool */}
                  </div>
                  <div className="value-text">
                    Segments
                    {/* {portfolioExpectedCollection && portfolioExpectedCollection.data ? millify(Math.round(portfolioExpectedCollection.data.expectedCollection), { space: true }) : 0} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {/* Portfolio */}
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDvHvEhe0C4plQ60VSIEnKReJIDqOQ47qw'
})(Segmentation)


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <div>
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value.toString().toLowerCase() || undefined) // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data, renderRowSubComponent, bucketWise_data, posDistribution_data, paginationShow, footer, footerTotal }) {
  // alert()
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    //setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },

  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,//sort data
    useExpanded,
    usePagination,//use pagination
  )

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);
  // We don't want to render all of the rows for this example, so cap
  // it for this use case


  let display = 'content';

  let default_class_Name = 'loanTable financierWiseTable';

  if (bucketWise_data) {
    default_class_Name = 'loanTable bucketWiseTable'
  }
  else if (posDistribution_data) {
    default_class_Name = 'loanTable bucketWiseTable'
  }

  return (
    <>
      <div>
        <div className="tblScroll">
          <table {...getTableProps()} className={default_class_Name}>
            <thead className="tableHead">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? '↓'
                            : '↑'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  {/* <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              /> */}
                </th>
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    {!(parseFloat(row.id) % 1) && (
                      <tr style={{ display: display }} className={`${display} ${display = 'content'}`}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          );
                        })}
                      </tr>
                    )}
                    {row.isExpanded && (
                      <tr cclassName={`p-0 ${display = 'none'}`}>
                        <td className="p-0" colSpan={visibleColumns.length}>
                          {renderRowSubComponent(row)}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )
              })}
            </tbody>
            {footerTotal ? <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot>
              :
              null
            }
          </table>
        </div>
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
        {footer && <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className={'col-lg-4'}>
              <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </button>{' '}
            </div>
            <div className={paginationShow ? 'col-lg-2' : 'col-lg-4'}>
              <span>
                Total Records{' '}
                <strong>
                  {data.length}
                </strong>{' '}
              </span>
            </div>
            <div className={paginationShow ? 'col-lg-2' : 'col-lg-4'}>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>

            {/* {paginationShow && <div className="col-lg-3">
              <span>
                Go to page{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page)
                  }}
                  className="gotoPage"
                />
              </span>{' '}
            </div>
            } */}

            {paginationShow && <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            }
          </div>
        </div>
        }


        <br />
      </div>
    </>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

