import React, { useState, useEffect } from "react";
//import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import millify from "millify";
import DatePicker from "react-date-picker";
import Select from "react-select";
import "../../Loan/css/loan.scss";
import "../css/bucketWiseTable.scss";
import "../css/teleCallingModal.scss";
import moment from "moment";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import matchSorter from "match-sorter";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../Loader/Loader";
import Alert from "react-bootstrap/Alert";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
//import { toAbsoluteUrl } from "../../../../layout/_metronic/_helpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { tlWiseMisData, callerWiseMis } from "../../../store/dashboard";
import { agencyFinancierList } from "../../../store/loan";
import { postApiData } from "../../../utils/utils";
import { format } from "date-fns";
import { style } from "d3-selection";
import classNames from "classnames";

function AprReports() {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState();
  const [tlName, setTlName] = useState();
  const [selectedTl, setSelectedTl] = useState();

  const [reportData, setReportData] = useState([]);
  console.log("reportData****", reportData);
  const [isLoading, setIsLoading] = useState(false);

  // console.log("csvData========", csvData)

  const user = useSelector((state) => state.entities.auth.data);
  let servicesList = useSelector(
    (state) => state.entities.dashboard.callerServieces.data
  );
  let callerWiseMis = useSelector(
    (state) => state.entities.dashboard.callerWiseMis.data
  );
  // console.log("callerWiseMis==123",callerWiseMis);
  let tlWiseMis = useSelector(
    (state) => state.entities.dashboard.tlWiseMis.data
  );
  console.log();

  const onchangeTl = (data) => {
    setSelectedTl(data.value);
  };

  //   useEffect(() => {
  //     if (tlWiseMis) {
  //       let roleOptionsTemp = [];
  //       for (let index = 0; index < tlWiseMis.length; index++) {
  //         let value = tlWiseMis[index];
  //         if (value.tlName) {
  //           roleOptionsTemp.push({
  //             value: value.tlId,
  //             label: value.tlName,
  //           });
  //         }
  //       }
  //       setTlName(roleOptionsTemp);
  //     }
  //   }, [tlWiseMis]);
 
  async function AprReportsData() {
    const data = {
      feature: "APR",
      featureType: "APR_LIST",
      startDate: newStartDate,
      userId: 1135,
      selectedTl: selectedTl,
    };
    setIsLoading(true);
    let aprResponse = await postApiData(data);
    setLastUpdatedAt(aprResponse.lastUpdateAt);
    if (aprResponse.data && aprResponse.data.length > 0) {
      setReportData(aprResponse.data);
      setSelectedTl(data.value);
    } else {
      setReportData([]);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    AprReportsData();
  }, []);

  useEffect(() => {
    if (reportData) {
      const uniqueReportingTo = reportData.reduce((uniqueNames, obj) => {
        if (uniqueNames.indexOf(obj.reportingTo) === -1) {
          uniqueNames.push(obj.reportingTo);
        }
        return uniqueNames;
      }, []);

      if (uniqueReportingTo.length > 0) {
        let roleOptionsTemp = [];
        roleOptionsTemp.push({ value: "", label: "All" }); // Add the "All" option

        for (let index = 0; index < uniqueReportingTo.length; index++) {
          let value = uniqueReportingTo[index];
          if (value) {
            roleOptionsTemp.push({
              value: value,
              label: value,
            });
          }
        }
        setTlName(roleOptionsTemp);
      }
    }
  }, [reportData]);
  // useEffect(()=> {
  //   if(reportData) {
  //       const uniqueReportingTo = reportData.reduce((uniqueNames, obj) => {
  //           if (uniqueNames.indexOf(obj.reportingTo) === -1) {
  //               uniqueNames.push(obj.reportingTo);
  //           }
  //           return uniqueNames;
  //       }, []);
  //       if(uniqueReportingTo.length > 0) {
  //           let roleOptionsTemp = [];
  //           for (let index = 0; index < uniqueReportingTo.length; index++) {
  //               let value = uniqueReportingTo[index];
  //               if (value) {
  //                   roleOptionsTemp.push({
  //                   value: value,
  //                   label: value,
  //                   });
  //               }
  //           }
  //           setTlName(roleOptionsTemp);
  //       }
  //   }
  // },[reportData])

  // console.log("sublorsdf-----", isLoading);
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const numFormatter = (num) => {
    let toFixe = 4;
    if (num <= 0) {
      return num.toFixed(2);
    } else if (num > 999 && num < 9999) {
      toFixe = 3;
    } else if (num >= 10000) {
      toFixe = 2;
    }
    return (num / 1000000).toFixed(toFixe);
  };

  let [startDate, setStartDate] = useState(new Date());

  const newStartDate = moment(startDate).format("YYYY-MM-DD");

  // console.log("startDate------", newStartDate);

  const refreshDataExport = () => {
    AprReportsData();
  };

  const columns = React.useMemo(
    () => [
      //   {
      //     Header: "Call Date",
      //     // accessor: 'bucket',
      //     accessor: (value) => (
      //       <div style={{ textAlign: "center" }}>
      //         <div>{value.srNo}</div>
      //         <div
      //           className="descriptionStyle"
      //           style={{ color: value.color || "black" }}
      //         ></div>
      //       </div>
      //     ),
      //     // Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
      //     disableFilters: true,
      //   },

      {
        Header: "Call Date",
        accessor: "callDate",
      },

      {
        Header: "Interval",
        accessor: "interval",
      },

      {
        Header: "Agent",
        accessor: "agent",
      },

      {
        Header: "Reporting To",
        accessor: "reportingTo",
      },
      {
        Header: "Total calls",
        accessor: "totalCalls",
      },

      {
        Header: "Dialer Calls",
        accessor: "dialerCalls",
      },

      {
        Header: "Outbound Calls",
        accessor: "outboundCalls",
      },

      {
        Header: "Manual Calls",
        accessor: "manualCalls",
      },

      {
        Header: "Login Time",
        accessor: "loginTime",
      },

      {
        Header: "Break Count",
        accessor: "breakCount",
      },

      {
        Header: "Lunch",
        accessor: "lunch",
      },

      {
        Header: "Dinner",
        accessor: "dinner",
      },

      {
        Header: "Tea",
        accessor: "tea",
      },

      {
        Header: "Meeting",
        accessor: "meeting",
      },

      {
        Header: "Break 5",
        accessor: "break5",
      },

      {
        Header: "Rest Room",
        accessor: "restRoom",
      },

      {
        Header: "Break 7",
        accessor: "break7",
      },
      {
        Header: "Break 8",
        accessor: "break8",
      },
      {
        Header: "Break 9",
        accessor: "break9",
      },
      {
        Header: "Break 10",
        accessor: "break10",
      },

      {
        Header: "Total Break Dur.",
        accessor: "totalBreakDuration",
        Cell: ({ value }) => {
          // console.log("value*******", value);
          return (
            <div
              style={{
                backgroundColor: value > "01:00:00" ? "#ffab40" : "inherit",
              }}
            >
              {value}
            </div>
          );
        },

        // Cell: ({ value }) => {
        //   if (value > "01:00:00") {
        //     <div className="totalBreakDuration1">{value}</div>;
        //     return;
        //   }
        // },
      },

      {
        Header: "Handle Dur.",
        accessor: "handleDuration",
      },

      {
        Header: "Avg. Handle Dur.",
        accessor: "averageHandleDuration",
      },

      {
        Header: "Idle Dur.",
        accessor: "idleDuration",
      },

      {
        Header: "Avg. Idle Dur.",
        accessor: "averageIdleDuration",
      },

      {
        Header: "Idle Block Dur.",
        accessor: "idleBlockDuration",
      },

      {
        Header: "Ring Dur.",
        accessor: "ringDuration",
      },

      {
        Header: "Avg. Ring Dur.",
        accessor: "averageRingDuration",
      },

      {
        Header: "Talk Dur.",
        accessor: "talkDuration",
      },

      {
        Header: "Avg. Talk Dur.",
        accessor: "averageTalkDuration",
      },

      {
        Header: "Hold Dur.",
        accessor: "holdDuration",
      },

      {
        Header: "Avg. Hold Dur.",
        accessor: "averageHoldDuration",
      },

      {
        Header: "Wrapup Dur.",
        accessor: "wrapupDuration",
      },

      {
        Header: "Avg. Wrapup Dur.",
        accessor: "averageWrapupDuration",
      },
    ],
    []
  );

  const handleSearch = () => {
    AprReportsData();
  };

  return (
    <div className="logs-card-div">
      {isLoading ? <Loader /> : ""}
      <Card className="bucketWise w-100">
        <CardHeader>
          <div className="col-lg-4 pl-0 d-flex justify-content-center align-items-start flex-column">
            <h3 className="mb-0">Apr Reports</h3>
          </div>
          <div className="col-lg-4 mt-4 pr-0 d-block">
            <div className="d-flex justify-content-end align-items-center">
              <div className="col-2 justify-content-end">
                <div className="col-3 d-flex justify-content-center align-items-center pr-0">
                  <button
                    className="defaultBtn transformBtn"
                    onClick={refreshDataExport}
                  >
                    <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                  </button>
                </div>
              </div>

              <div className="col-lg-2 justify-content-end align-items-center">
                <div className="col-lg-3 pl-0">
                  {reportData && (
                    <button
                      id="CSV"
                      type="button"
                      className="font-weight-bold download-csv transformBtn"
                    >
                      <CSVLink
                        data={reportData}
                        filename={"Apr_Reports.csv"}
                        target="_blank"
                      >
                        <span className="svg-icon svgSetting">
                          <SVG
                            className="svgSetting"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/csv-file-format-extension.svg"
                            )}
                          ></SVG>
                        </span>
                      </CSVLink>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        {showErrorAlert ||
          (showSuccessAlert && (
            <CardHeader>
              <div className="col-lg-4 mt-4 d-block">
                {showErrorAlert && (
                  <Alert
                    variant="danger"
                    onClose={() => setShowErrorAlert(false)}
                    dismissible
                  >
                    {errorAlertMsg}
                  </Alert>
                )}

                {showSuccessAlert && (
                  <Alert
                    className="custom-success-alert"
                    onClose={() => setShowSuccessAlert(false)}
                    dismissible
                  >
                    {successAlertMsg}
                  </Alert>
                )}
              </div>
            </CardHeader>
          ))}

        <CardHeader>
          <div className="col-lg-12 pl-0 pt-4 d-flex justify-content-start align-items-center">
            <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-center cityWiseDate">
              <div className="form-group w-100">
                <label>Date</label>
                <DatePicker
                  onChange={(value) => {
                    let formatedStartDate = formatDate(value);
                    if (formatedStartDate) {
                      setStartDate(new Date(formatedStartDate));
                    }

                    //formikProps.setFieldValue("startDate", value)}
                  }}
                  popperProps={{
                    positionFixed: true, // use this to make the popper position: fixed
                  }}
                  value={startDate}
                  name="startDate"
                  // minDate={new Date()}
                  format="dd-MM-y"
                  className="form-control form-control-solid h-auto readOnlySpan aprDatePicker"
                  clearIcon={null}
                  calendarIcon={
                    <div>
                      <i className="flaticon-calendar-1"></i>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="col-lg-3 pl-0">
              <div className="form-group">
                <label>Team Leader</label>
                <Select
                  placeholder="Select..."
                  name="role"
                  className="jobRoleSelect w-100 calmisset"
                  options={tlName}
                  onChange={onchangeTl}
                  // onChange={(value) => {
                  // console.log("value.value==",value);
                  // setSelectedTl(value.value);
                  // }}
                />
              </div>
            </div>
            <div className="col-lg-2">
              <button
                id="button"
                type="button"
                className="btn btn-primary font-weight-bold upload w-100 transformBtn"
                // disabled={isEmailUnique}
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
            <div className="col-lg-4 pl-0 d-flex justify-content-center align-items-end flex-column">
              <div className="lastUploadApr">
                <h4>Last Updated: {lastUpdatedAt}</h4>
              </div>
            </div>
          </div>
        </CardHeader>

        <CardBody className="p-4">
          {reportData && <Table columns={columns} data={reportData} />}
          {/* {error && (
            <div className="d-flex justify-content-center align-items-center">
              <h6>{errShow}</h6>
            </div>
          )} */}
        </CardBody>
      </Card>
    </div>
  );
}

export default AprReports;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <div className="tblScroll" style={{ height: "100%" }}>
          <table {...getTableProps()} className="loanTable aprReportsTable">
            <thead
              className="tableHead stickyTop table-fixed tableFixed"
              style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="tableHeader"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "↓"
                            : "↑"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
              {/* <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                </th>
              </tr> */}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="tableTd">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {/* <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot> */}
          </table>
        </div>

        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
