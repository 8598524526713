import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../Loader/Loader";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
// import makeData from "./MakeData";
import matchSorter from "match-sorter";
import { acceptList, updateStatus } from "../../store/attendance";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import DatePicker from "react-date-picker";
import Attendance_modal from "./Attendance_modal";
import Attendance_Drop from "./Attendance_Drop";
import moment from "moment";
import Select from "react-select";
import { dateTimeFormat } from "../../utils/helperFunctions";
import {fieldMisLocationLists, getFosAndReportingToList} from "../../store/userList";

let rowSelected = null,
  manageStatus = [];
export default function Accept_Reject() {
  const dispatch = useDispatch();

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  var date = new Date();
  // var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var firstDay = formatDate(new Date());
  var lastDay = formatDate(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  let [startDate, setStartDate] = useState(new Date(firstDay));
  let [endDate, setEndDate] = useState(new Date(firstDay));
  const [selectedRow, setSelectedRow] = useState(null);
  const [status, setStatus] = useState();

  const [options, setOptions] = useState([
    { value: "", label: "Select" },
    { value: "Present", label: "Present" },
    { value: "Absent", label: "Absent" },
    { value: "Half Day", label: "Half Day" },
  ]);
  const user = useSelector((state) => state.entities.auth.data);
  const acceptListData = useSelector(
    (state) => state.entities.attendance.acceptList.data
  );
  const loadingData = useSelector(
    (state) => state.entities.attendance.acceptList.loading
  );
  // console.log('acceptlistdata data here ========', acceptListData);

  const fosAndReportingToUsers = useSelector(
    (state) => state.entities.userList.fosAndReportingToList.data
  );
  console.log("fosAndReportingToUsers*********", fosAndReportingToUsers);
  useEffect(() => {
    dispatch(
      acceptList(
        user.userId,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      )
    );
  }, []);
  let tempStartDate = "";
  let tempEndDate = "";

  // useEffect(() => {
  //   dispatch(acceptList(manageStatus));
  //   console.log('mnagshdl========',manageStatus);
  // }, [])
  const [agentId, setAgentId] = useState();
  const [selectFullName, setSelectFullName] = useState();
  const [acceptListDataData, setAcceptListDataData] = useState();

  useEffect(() => {
    const acceptListDataFilter = acceptListData;
    setAcceptListDataData(acceptListData);
  }, [acceptListData]);

  useEffect(() => {
    dispatch(getFosAndReportingToList(user.userId));
  }, []);

  

  useEffect(() => {
    if (
      fosAndReportingToUsers !== null &&
      fosAndReportingToUsers !== undefined
    ) {
      let tempacceptListData = [];
      // tempacceptListData.push({ label: "All", value: "All" });
      const countt = fosAndReportingToUsers[0].fosData.length;
      // console.log("countt---", countt);
      for (let index = 0; index < countt; index++) {
        const tempSingle = fosAndReportingToUsers[0].fosData[index];
        // console.log("tempSingle---", tempSingle.fosData);
        let newSingleData = {};
        newSingleData = tempSingle.fullName;
        // console.log("newSingleData---", newSingleData);
        if (newSingleData) {
          tempacceptListData.push({
            label: newSingleData,
            value: tempSingle.userId,
          });
        }
      }
      setSelectFullName(tempacceptListData);
    } else {
     dispatch(getFosAndReportingToList(user.userId));
    }
  }, [fosAndReportingToUsers]);

  const searchData = (searchType) => {
    // console.log(" type ===== ", formatDate(startDate), formatDate(endDate));
    tempStartDate = formatDate(startDate);
    tempEndDate = formatDate(endDate);
    if (searchType == "today") {
      let todayDateTemp = new Date();
      todayDateTemp = formatDate(todayDateTemp);
      tempStartDate = formatDate(todayDateTemp);
      tempEndDate = formatDate(todayDateTemp);
      // console.log(" today ", tempStartDate);
    } else if (searchType == "yesterday") {
      var todayDateTemp = new Date();
      todayDateTemp.setDate(todayDateTemp.getDate() - 1);
      todayDateTemp = formatDate(todayDateTemp);
      tempStartDate = formatDate(todayDateTemp);
      tempEndDate = formatDate(todayDateTemp);
      // console.log(" yesterday ", tempStartDate);
    }
    setStartDate(new Date(tempStartDate));
    setEndDate(new Date(tempEndDate));
    let formateStartDate = formatDate(tempStartDate);
    let formateEndDate = formatDate(tempEndDate);
    console.log("start date and end date :", formateStartDate, formateEndDate);
    if (formateStartDate && formateEndDate) {
      dispatch(
        acceptList(user.userId, formateStartDate, formateEndDate, agentId)
      );
    }
  };

  let Enddate = moment(endDate).format("YYYY-MM-DD");
  console.log("data log1========", Enddate);

  const manageStatusFunc = (data) => {
    console.log(" data ", data);
    if (manageStatus.length > 0) {
      let indexNumber = manageStatus.findIndex(
        (ms) => ms.loginTime === data.loginTime && ms.userId == data.userId
      );
      console.log(" indexNumber ----- ", indexNumber);
      if (indexNumber >= 0) {
        manageStatus.splice(indexNumber, 1);
      }
      // let tempData = manageStatus.filter((ms)=> ms.loginTime == data.loginTime && ms.userId == data.userId)
      // if(tempData.length > 0) {
      // 	manageStatus.splice(manageStatus.findIndex(ms => ms.loginTime === data.loginTime  && ms.userId == data.userId) , 1)
      // }
      manageStatus.push(data);
    } else {
      manageStatus.push(data);
    }
    console.log(" manageStatus ====== ", manageStatus);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "fullName",
      },
      {
        Header: "Designation",
        accessor: "role",
      },
      {
        Header: "In Time",
        accessor: "loggedInTime",
      },
      {
        Header: "Out Time",
        accessor: "loggedOutTime",
      },
      {
        Header: "No. of Visit ",
        accessor: "noOfVisits",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (cell) => {
          // console.log(" cell ====== ", cell.row.original)
          let selectedDropDown = {
            label: cell.row.original.status,
            value: cell.row.original.status,
          };
          return (
            <Select
              defaultValue={selectedDropDown}
              placeholder="Select..."
              name="role"
              className="jobRoleSelect w-100"
              options={options}
              onChange={(e) => {
                // console.log(" cell.row.original ", cell.row.original)
                // handleChange(cell.row.original,e.value)
                // dispatch(statusManagement(cell.row.original));
                const tempData = {
                  loginTime: cell.row.original.loggedInDate,
                  userId: cell.row.original.userId,
                  status: e.value,
                };
                manageStatusFunc(tempData);
                // tempData.newStatus = e.value;
                // dispatch(statusManagement('changeStatus',tempData));
              }}
            />
          );
        },
      },

      {
        Header: "Approval",
        accessor: "statusApproved",
        Cell: (cell) => {
          // return  <button
          // 	// onClick={(e)=>approvalChange(cell.row.original)}
          // 	// onClick={(e) => {
          // 	// }}
          // 	className="activefosbtn"
          // >
          // 	{cell.row.original.statusApproved}
          // </button>;
          return (
            <Attendance_modal
              manageStatus={manageStatus}
              currentRow={cell.row.original}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <div className="agentActivityDiv">
      {loadingData ? <Loader /> : " "}
      {/* <LoadingOverlay
        active={loadingData}
        spinner
        text='Loading...'
        styles={{
          wrapper: {
            overflow: loadingData ? 'hidden' : 'hidden'
          },
          spinner: (base) => ({
            ...base,
            width: '75px',
            '& svg circle': {
              stroke: '#00b1cc'
            }
          })
        }}
      > */}
      <Card>
        <CardHeader>
          <div className="col-lg-2 d-flex justify-content-start align-items-center pl-5">
            <h3 className="pl-0">Accept/Reject</h3>
          </div>
          <div className="col-lg-2 d-flex justify-content-end align-items-center">
            <div className="col-lg-3 pl-0">
              {acceptListData && (
                <button
                  id="CSV"
                  type="button"
                  className="font-weight-bold download-csv transformBtn acceptDataCss"
                >
                  <CSVLink
                    data={acceptListData}
                    filename={"AttendanceData.csv"}
                    target="_blank"
                  >
                    <span className="svg-icon svgSetting">
                      <SVG
                        className="svgSetting"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/csv-file-format-extension.svg"
                        )}
                      ></SVG>
                    </span>
                  </CSVLink>
                </button>
              )}
            </div>
            {/* <div className="col-lg-3 pl-0">
                                <button className="defaultBtn transformBtn" onClick={refreshAgentActivities}>
                                    <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                                </button>
                            </div> */}
          </div>
        </CardHeader>
        <CardHeader>
          <div className="col-12 pl-3 pt-4 d-flex justify-content-start align-items-center">
            <div className="col-lg-2">
              <div className="form-group">
                <label>Name</label>
                <Select
                  name="sendPushNotificationType"
                  className="jobRoleSelect w-80 calmisset"
                  multi={true}
                  options={selectFullName}
                  onChange={(e) => {
                    console.log("value********88", e.value);
                    setAgentId(e.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-2 pl-0 justify-content-center align-items-center">
              <div className="form-group">
                <label>From date</label>
                <DatePicker
                  onChange={(value) => {
                    let formatedStartDate = formatDate(value);
                    if (formatedStartDate) {
                      setStartDate(new Date(formatedStartDate));
                    }

                    //formikProps.setFieldValue("startDate", value)}
                  }}
                  value={startDate}
                  name="startDate"
                  // minDate={new Date()}
                  format="dd-MM-y"
                  className="form-control form-control-solid h-auto readOnlySpan"
                  clearIcon={null}
                  calendarIcon={
                    <div>
                      <i className="flaticon-calendar-1"></i>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="col-lg-2 pl-0 justify-content-center align-items-center">
              <div className="form-group">
                <label>To date</label>
                <DatePicker
                  onChange={(value) => {
                    let formatedEndDate = formatDate(value);
                    console.log(" formatedEndDate ", formatedEndDate);
                    if (formatedEndDate) {
                      setEndDate(new Date(formatedEndDate));
                    }
                  }}
                  value={endDate}
                  name="endDate"
                  minDate={new Date(startDate)}
                  format="dd-MM-y"
                  className="form-control form-control-solid h-auto readOnlySpan"
                  clearIcon={null}
                  calendarIcon={
                    <div>
                      <i className="flaticon-calendar-1"></i>
                    </div>
                  }
                />
              </div>
            </div>

            <div className="col-lg-2">
              <button
                id="button"
                type="button"
                className="btn btn-primary font-weight-bold w-100"
                onClick={() => {
                  searchData("normal");
                  // filterAcceptListData();
                }}
              >
                Search
              </button>
            </div>

            <div className="col-lg-2">
              <button
                id="button"
                type="button"
                className="btn btn-primary font-weight-bold w-100"
                // disabled={isEmailUnique}
                onClick={() => searchData("today")}
              >
                Today
              </button>
            </div>

            <div className="col-lg-2">
              <button
                id="button"
                type="button"
                className="btn btn-primary font-weight-bold w-100"
                // disabled={isEmailUnique}
                onClick={() => searchData("yesterday")}
              >
                Yesterday
              </button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="p-4">
          {/* <Table columns={columns} data={acceptListData} /> */}
          {acceptListDataData && (
            <Table columns={columns} data={acceptListDataData} />
          )}
        </CardBody>
      </Card>
      {/* </LoadingOverlay> */}
    </div>
  );

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <div>
        <input
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          // placeholder={`Search ${count} records...`}
          className="searchFilter"
        />
        {/* <i className="flaticon-search searchIcon"></i> */}
      </div>
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 5 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy, //sort data
      usePagination //use pagination
    );

    // Render the UI for your table
    return (
      <>
        <div className="tblScroll1">
          <table {...getTableProps()} className="attendancetabl dashAccept">
            <thead className="tableHead">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* // Add a sort direction indicator  */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "↓"
                            : "↑"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="row m-0">
          <div className="pagination col-lg-12 mb-7">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records{" "}
                <strong>
                  {/* {data.length} */}
                  {rows.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
