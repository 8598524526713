import React from "react";
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { logout } from "../store/authentication";


export default function ReactIdleTimer() {
    //const dispatch = useDispatch();
    //dispatch(logout());
    const history = useHistory();

    const handleOnIdle = event => {
        // alert(getRemainingTime()) 
        // console.log(' time remaining', getRemainingTime())
        // console.log('user is idle', event)
        // console.log('last active', getLastActiveTime());

        let urls = window.location.href;
        let urlSplit = urls.split('/');
        // Taking last array value
        let getCurrentUrl = urlSplit.slice(-1).pop();
        //console.log('check url:', getCurrentUrl);

        if(getCurrentUrl !== 'login'){
            history.push({
                pathname: '/logout',
                sessionTimeOut: 'sessionTimeOut'
            });
        }
    }

    const handleOnActive = event => {
        // console.log(' user is active', event)
        // console.log(' time remaining', getRemainingTime())
    }

    const handleOnAction = (e) => {
        //console.log('user did something', e) 
    }

    const {getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 60 * 24,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    return (
        <div></div>
    );
}



