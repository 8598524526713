import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";

import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";
import { bucketWisePerformance, agencyWisePerformance, financierWisePerformance, financierStatus } from './../../../../app/store/dashboard';

export function Header() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const user = useSelector(state => state.entities.auth.data);
  const dispatch = useDispatch();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      )
    };
  }, [uiService]);

  const layoutProps1 = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      )
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);



  const refreshDashboard = () => {
    console.log('refreshing...');
    dispatch(bucketWisePerformance(user.userId, user.agencyId));
    dispatch(agencyWisePerformance(user.userId, user.agencyId));
    // if (user.role !== 'Agency Manager') {
    dispatch(financierWisePerformance(user.userId, user.agencyId));
    // }
    dispatch(financierStatus(user.userId, user.agencyId));


  }

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/* DK Header Changes */}
        <div className="d-flex align-items-center justify-content-start w-50">
          <h5 className="text-dark font-weight-bold my-2 ml-5">
            <>
              {/* {subheader.title} */}
            </>
            {/*<small></small>*/}
          </h5>
          {/* {subheader.title == 'Overview' && ( */}
            {/* <div className="ml-6">
              <button className="defaultBtn transformBtn dashboardRefresh" onClick={refreshDashboard}>
                <i className="flaticon-refresh refreshIcon rotate"></i>
              </button>
            </div> */}
          {/* )} */}
        </div>
        {/*begin::Container*/}
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {!layoutProps.menuHeaderDisplay && <div />}
          {/*end::Header Menu Wrapper*/}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
