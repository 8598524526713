import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "../../Loader/Loader";
import DatePicker from 'react-date-picker';
import Moment from 'react-moment';
import "../css/generatedData.scss";
import "../../Loan/css/loan.scss";
import { useTable, useFilters, useGlobalFilter, usePagination, useSortBy, useRowSelect, useExpanded } from 'react-table';
import matchSorter from 'match-sorter'
import { Dropdown } from "react-bootstrap";
//import makeData from "./../../utils/makeData";

import {
    Card,
    CardBody,
    CardHeader
} from "../../../../_metronic/_partials/controls";
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { legal, searchLoan, sendEmail, clearMessages } from '../../../store/legal';
import GeneratedSubRowsData from "./GeneratedSubRowsData";
import { DropdownCustomToggler } from './Toggler';

console.log("bothleagal----",legal, searchLoan )

let dataPerPage = 10;
const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: '#000',
    },
}));

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

function GeneratedData(props) {
    const classes = useStyles();
    //console.log('Today page props:', props);
    const history = useHistory();
    const dispatch = useDispatch();
    //const [showAddAgencyForm, setShowAddAgencyForm] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [pageLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchLoanId, setSearchLoanId] = useState('');
    const [searchTelephone, setSearchTelephone] = useState('');
    const [expanded, setExpanded] = React.useState(false);
    const [selectedRow, setSelectedRow] = useState();


    const user = useSelector(state => state.entities.auth.data);
    console.log("user====",user);
    const { status, error, loading, message, offset, limit, hasMore, total, sendEmailData } = useSelector((state) => state.entities.legal);

    console.log("legal response:", error, status, loading, message, total);
    dataPerPage = total;
    let isPrimaryNo = null, isPrimaryEmail = null;
    let tempLoanId = [];

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    var date = new Date();
    var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
    var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    //console.log('firstDay , lastDay', firstDay, lastDay);


    let [startDate, setStartDate] = useState(new Date(firstDay));
    let [endDate, setEndDate] = useState(new Date(lastDay));

    let legalLoanData = [], legalLoanDataCopy = [], setLimit = 150;
    legalLoanData = useSelector((state) => state.entities.legal.data.legals);
    legalLoanDataCopy = useSelector((state) => state.entities.legal.data.legals);
    console.log(' legalLoanDataCopy====', legalLoanDataCopy);

    const columns = React.useMemo(
        () => [
            {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps, selectedFlatRows }) => {
                    //console.log('selectedFlatRows:', selectedFlatRows)
                    setSelectedRow(selectedFlatRows);
                    return <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                },
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => {

                    return <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                },
                disableSortBy: true,
                disableFilters: true
            },
            {
                // Build our expander column
                id: "expander", // Make sure it has an ID
                Header: () => null,
                Cell: ({ row }) => {
                    //console.log('row:', row);
                    return row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                            })}
                        >
                            {row.isExpanded ? (<i className="fas fa-angle-down"></i>) : (<i className="fas fa-angle-right"></i>)}
                        </span>
                    ) : null
                },
                disableSortBy: true,
                disableFilters: true
            },
            {
                Header: <div style={{ textAlign: "center" }}>File Name</div>,
                accessor: 'name',
                Cell: ({ cell }) => {
                    return (
                        <div style={{ textAlign: "left" }}>
                            <div>
                                <span className="">{cell.row.original.fileName}</span>
                            </div>
                            <div className="w-100">
                                <span className="exportTypeTxt">Batch Number</span> : <span> {cell.row.original.id} </span>
                            </div>
                            <div className="w-100">
                                <span className="exportTypeTxt dateWidth">Uploaded Date</span> : {cell.row.original.createdAt ? <Moment format="DD-MM-YYYY HH:MM">{cell.row.original.createdAt}</Moment> : ''}
                            </div>
                            {/* <div className="w-100">
                                <span className="exportTypeTxt">Financier Name</span> : <span> {cell.row.original.financierName} </span>
                            </div> */}
                        </div >
                    )
                },
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Total Record',
                accessor: 'totalRecord',
                disableSortBy: true,
                disableFilters: true
                // filter: 'fuzzyText',
            },
            {
                Header: 'Start Date',
                accessor: 'createdAt',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    let createdAt = cell.row.original.createdAt;
                    if (createdAt) {
                        let createdAtArray = createdAt.split(".");
                        return <Moment format="DD-MM-YYYY hh:mm">{createdAtArray[0]}</Moment>;
                    }
                    else {
                        return '';
                    }
                },
            },
            {
                Header: 'End Date',
                accessor: 'updatedAt',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    let updatedAt = cell.row.original.updatedAt;
                    if (updatedAt) {
                        let updatedAtArray = updatedAt.split(".");
                        return <Moment format="DD-MM-YYYY hh:mm">{updatedAtArray[0]}</Moment>;
                    }
                    else {
                        return '';
                    }
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell }) => {
                    // console.log(" cell ======== ", cell.row.original.fileName);
                    if (cell.row.original.status === 'COMPLETED') {
                        return (
                            <div style={{ width: '100px', }}>
                                <span className="label label-lg label-light-success label-inline font-weight-bold py-4 pl-0" style={{ background: 'none', color: 'darkgreen' }}>
                                    COMPLETED
                                </span>

                            </div>
                        )
                    } else if (cell.row.original.status === 'FAILED') {
                        return (
                            <div style={{ width: '100px' }}>
                                <span className="label label-lg label-light-danger label-inline font-weight-bold py-4 pl-0" style={{ background: 'none' }}>
                                    FAILED
                                </span>

                            </div>
                        )
                    } else if (cell.row.original.status === 'PROCESSING') {
                        return (
                            <div style={{ width: '100px' }}>
                                <span className="label label-lg label-inline font-weight-bold py-4 pl-0" style={{ background: 'none', color: '#fcba03' }}>
                                    PROCESSING
                                </span>

                            </div>
                        )
                    } else {
                        return (
                            <div style={{ width: '100px' }}>
                                <span className="label-lg label-light-primary label-inline font-weight-bold py-4 pl-0" style={{ background: 'none', color: 'black' }}>
                                    {cell.row.original.status}
                                </span>
                            </div>
                        )
                    }

                },
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Action',
                accessor: 'loan',
                Cell: ({ cell, row }) => {

                    if (row && row.isSelected) {
                        // console.log('getToggleRowSelectedProps:', row)
                    }
                    // console.log(" sayeed =========== ",row.original)
                    //console.log('action row data:', cell.row.original);
                    return <>
                        {/* <div className="indivisualtodayLoanData">
                            <div className="indivisualtodayLoanDataInnerDiv">
                                <a target="blank" href={row.original.attachment}>
                                    <i className="flaticon2-download-2 pdfIcon"></i>
                                </a>
                                <button
                                    id="button"
                                    type="button"
                                    className="btn btn-primary font-weight-bold transformBtn ml-4 pt-2 pb-2"
                                    onClick={() => {
                                        if (row && row.isSelected) {
                                            checkData(row, 'email');
                                        }
                                        else {
                                            setErrorAlertMsg('Please click on check box first');
                                            setShowErrorAlert(true);
                                            setTimeout(() => {
                                                setErrorAlertMsg(null);
                                                setShowErrorAlert(false);
                                            }, 2000);
                                        }
                                    }}
                                >
                                    Send Email
                                </button>
                                <button
                                    id="button"
                                    type="button"
                                    className="btn btn-primary font-weight-bold transformBtn ml-4 pt-2 pb-2"
                                    onClick={() => {
                                        if (row && row.isSelected) {
                                            checkData(row, 'sms');
                                        }
                                        else {
                                            setErrorAlertMsg('Please click on check box first');
                                            setShowErrorAlert(true);
                                            setTimeout(() => {
                                                setErrorAlertMsg(null);
                                                setShowErrorAlert(false);
                                            }, 2000);
                                        }
                                    }}
                                >
                                    Send SMS
                                </button>
                            </div>
                        </div> */}
                        {/* <div style={{textAlign: 'center'}}>
                            <i class="fas fa-ellipsis-v"></i>
                        </div> */}
                        <div className="card-toolbar">
                            <Dropdown className="dropdown-inline" alignRight>
                                <Dropdown.Toggle
                                    id="dropdown-toggle-top"
                                    as={DropdownCustomToggler}
                                >
                                    <i className="ki ki-bold-more-ver" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                    {/* <DropdownMenu3 /> */}
                                    <ul className="navi text-center">

                                        {/* <li className="navi-separator mb-3 opacity-70"></li> */}
                                        <li className="navi-item">
                                            <a
                                                // <a target="blank" href={row.original.attachment}>
                                                // <i className="flaticon2-download-2 pdfIcon"></i>
                                                // </a>
                                                href={row.original.attachment}
                                                style={{ width: '100px', padding: '10px' }}
                                                id="button"
                                                type="button"
                                                className="btn btn-primary-light font-weight-bold transformBtn actionsBtnColor pt-2 pb-2"

                                            >
                                                Download
                                        </a>
                                            {/* <div style={{marginLeft: '35px'}}>
                                            <a target="blank" href={row.original.attachment}>
                                                <i className="flaticon2-download-2 pdfIcon"></i>
                                            </a>
                                        </div> */}
                                        </li>
                                        <li className="navi-item">
                                            <button
                                                style={{ width: '100px', padding: '10px', marginTop: '5px' }}
                                                id="button"
                                                type="button"
                                                className="btn btn-primary-light font-weight-bold transformBtn actionsBtnColor pt-2 pb-2"
                                                onClick={() => {
                                                    if (row && row.isSelected) {
                                                        checkData(row, 'email');
                                                    }
                                                    else {
                                                        setErrorAlertMsg('Please click on check box first');
                                                        setShowErrorAlert(true);
                                                        setTimeout(() => {
                                                            setErrorAlertMsg(null);
                                                            setShowErrorAlert(false);
                                                        }, 2000);
                                                    }
                                                }}
                                            >
                                                Send Email
                                        </button>
                                        </li>
                                        <li className="navi-item">
                                            <button
                                                style={{ width: '100px', marginTop: '5px' }}
                                                id="button"
                                                type="button"
                                                className="btn btn-primary-light font-weight-bold transformBtn actionsBtnColor pt-2 pb-2"
                                                onClick={() => {
                                                    if (row && row.isSelected) {
                                                        checkData(row, 'sms');
                                                    }
                                                    else {
                                                        setErrorAlertMsg('Please click on check box first');
                                                        setShowErrorAlert(true);
                                                        setTimeout(() => {
                                                            setErrorAlertMsg(null);
                                                            setShowErrorAlert(false);
                                                        }, 2000);
                                                    }
                                                }}
                                            >
                                                Send SMS
                                        </button>
                                        </li>
                                    </ul>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </>;
                },
                disableSortBy: true,
                disableFilters: true,
            },

        ],
        []
    )

    function handleExpandClick() {
        setExpanded(!expanded);
    }

    //only first itme called with offset 0 and limit 50
    useEffect(() => {
        if (legalLoanData.length === 0) {
            dispatch(legal(user.userId, user.agencyId, 0, setLimit, firstDay, lastDay));
            //console.log('first call:');
            //setLoading(true);
            enableLoading();
        }
    }, []);

    useEffect(() => {
        if (legalLoanData.length !== 0) {
            setData(legalLoanData);
        }
    }, [legalLoanData]);


    useEffect(() => {
        if (legalLoanData.length !== 0) {
            if (hasMore === true && loading === false) {
                //console.log('Calling api:', (offset + setLimit));
                dispatch(legal(user.userId, user.agencyId, (offset + setLimit), setLimit, startDate, endDate));
                //console.log('called api:', (offset + setLimit));
            }
        }
    })



    let errorMsg = "Something went wrong. Please try again.";

    //entity error
    useEffect(() => {
        if (status === "error") {
            if (typeof error === "object") {
                errorMsg = error.message;
            }
            else {
                errorMsg = error;
            }
            setErrorAlertMsg(errorMsg);
            setShowErrorAlert(true);
            disableLoading();
            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
                dispatch(clearMessages());
            }, 2000);
        }
    }, [status])

    useEffect(() => {
        if (status === "success" && !loading) {
            disableLoading();
        }
    }, [status, loading])

    useEffect(() => {
        if (status === "success" && message !== null && message !== undefined) {
            setErrorAlertMsg(message);
            setShowErrorAlert(true);
            setData(legalLoanDataCopy);
            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
                dispatch(clearMessages());
            }, 2000)

        }

    }, [status, message])



    useEffect(() => {
        if (sendEmailData.status === "success" && sendEmailData.message !== null && sendEmailData.message !== undefined) {
            setSuccessAlertMsg(sendEmailData.message);
            setShowSuccessAlert(true);
            setTimeout(() => {
                setSuccessAlertMsg(null);
                setShowSuccessAlert(false);
                dispatch(clearMessages());
            }, 2000)
        }

    }, [sendEmailData])

    // function displayTodayLoanInfo(todayLoanInfoData) {
    //     console.log('onclick called:', todayLoanInfoData);
    //     if (todayLoanInfoData) {
    //         history.push({
    //             pathname: '/legal/details',
    //             loanId: todayLoanInfoData
    //         });
    //     }
    // }





    const refreshTodayLoanInfo = () => {
        //console.log('refresh');
        if (user) {
            dispatch(legal(user.userId, user.agencyId, 0, setLimit, firstDay, lastDay));
            enableLoading();
            // }
        }
    }

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const searchRecord = () => {
        //console.log('searchTelephone,searchLoanId', searchTelephone, searchLoanId)

        if (searchLoanId || searchTelephone) {
            let setLoanId = searchLoanId.toUpperCase() || null;
            //console.log('searchTelephone,searchLoanId', searchTelephone, setLoanId)
            legalLoanData = legalLoanData.filter((item) => {
                item.contactDetails.telephone.filter((telephoneNumber) => {
                    if (telephoneNumber.contactValue === searchTelephone) {
                        //console.log('telephoneNumber:', telephoneNumber);
                        setLoanId = (item.loan.loanId).toUpperCase();
                    }
                })
                return ((item.loan.loanId).toUpperCase()) == setLoanId;
            });

            //console.log('legalLoanData:', legalLoanData);
            if (legalLoanData.length == 0) {
                //console.log('legalLoanData length:', legalLoanData.length);
                dispatch(searchLoan(user.userId, user.agencyId, setLoanId, searchTelephone, user.financierId));
            }
            else {
                setData(legalLoanData);
            }
        }
        else {
            let errMsg = 'Please enter atleast one field.';
            setErrorAlertMsg(errMsg);
            setShowErrorAlert(true);

            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
            }, 2000)
        }
    }


    const checkData = (row, clickOn) => {
        console.log(" row ", row);
        let tempLoanId = 0;
        // row.forEach(element => {
        //     console.log(" element.original.id ", element.original.id);
        //     // tempLoanId.push(element.original.id);
        // });
        if (row && row.original.id) {
            console.log('checkData:', row.original)
            console.log(' clickOn: ', clickOn);
            dispatch(sendEmail(user.userId, [row.original.id], clickOn == 'sms' ? 1 : 0, clickOn == 'email' ? 1 : 0, localStorage.getItem("notificationtoken"), 'BROWSER'));
            // dispatch(sendEmail(user.userId, [row.values.id], clickOn));
        }
    }


    const sendToAll = () => {
        console.log('row:', selectedRow);
        tempLoanId = [];

        selectedRow.forEach(element => {
            tempLoanId.push(element.original.id);;
        });
        if(tempLoanId.length > 0) {
            // console.log(" tempLoanId ", tempLoanId);
            dispatch(sendEmail(user.userId, tempLoanId, 1, 1, localStorage.getItem("notificationtoken"), 'BROWSER'));
        }
        // if (selectedRow && selectedRow.length !== 0) {
        //     selectedRow.map((item) => {
        //         console.log('item:', item);
        //         if (item.isSelected) {
        //             if (item && item.values && item.values.id)
        //                 tempLoanId.push(item.values.id)
        //         }
        //     })
        //     if (selectedRow && selectedRow.length == tempLoanId.length) {
        //         dispatch(sendEmail(user.userId, tempLoanId, 1, 1, localStorage.getItem("notificationtoken"), 'BROWSER'));
        //     }
        // }
        if (tempLoanId && tempLoanId.length == 0) {
            tempLoanId = [];
            let errMsg = 'Please click on the checkbox.';
            setErrorAlertMsg(errMsg);
            setShowErrorAlert(true);
            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
            }, 2000)
        }
    }


    const searchGeneratedData = () => {
        // console.log("searchGeneratedData=====",searchGeneratedData);
        let formateStartDate = formatDate(startDate);
        let formateEndDate = formatDate(endDate);
        console.log('start date and end date :', formateStartDate, formateEndDate);
        if (formateStartDate && formateEndDate) {
            dispatch(legal(user.userId, user.agencyId, 0, setLimit, formateStartDate, formateEndDate));
        }
    }


    // useEffect(() => {
    //     if (total == 0) {
    //         setData([]);
    //     }
    // }, [total])

    const renderRowSubComponent = (row) => {
        console.log(" renderRowSubComponent row ", row.original.subRows);
        return (
            // <div></div>
            <GeneratedSubRowsData generatedSubRowData={row.original.subRows} rowData={row.original} />
        );
    };


    return (
        <div className="logs-card-div">
            {loading ? <Loader /> : " "}
            {/* <LoadingOverlay
                active={loading || sendEmailData.loading}
                spinner
                text='Loading...'
                // classNamePrefix='pg-list-loader-'
                styles={{
                    wrapper: {
                        overflow: loading || sendEmailData.loading ? 'hidden' : 'hidden'
                    },
                    spinner: (base) => ({
                        ...base,
                        width: '75px',
                        '& svg circle': {
                            stroke: '#00b1cc'
                        }
                    })
                }}
            > */}
                <Card className="loanInfoCard">
                    <CardHeader>
                        <div className="col-4 pl-0 d-flex justify-content-center align-items-start flex-column">
                            <div className="d-flex justify-content-center align-items-start flex-column">
                                <h3 className="mb-0">Generated Notice Info</h3>
                            </div>
                        </div>
                        <div className="col-4 mt-4 d-block">
                            {showErrorAlert &&
                                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                    {errorAlertMsg}
                                </Alert>
                            }

                            {showSuccessAlert &&
                                <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                                    {successAlertMsg}
                                </Alert>
                            }
                        </div>
                        {/* <div className="col-2 d-flex justify-content-end align-items-center"> */}
                            {/* {hasMore == true ? (
                                <div className="d-flex justify-content-center align-items-center mr-2">
                                    <div className="spinner mr-8">
                                    </div>
                                </div>
                            ) :
                                (
                                    <div className=" d-flex justify-content-center align-items-center">
                                        <button className="defaultBtn transformBtn" onClick={refreshTodayLoanInfo}>
                                            <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                                        </button>
                                    </div>
                                )
                            } */}
                        {/* </div> */}
                        <div className="col-3 d-flex justify-content-end align-items-center">
                            <button
                                id="button"
                                type="button"
                                className="btn btn-primary font-weight-bold upload transformBtn ml-4 pt-2 pb-2"
                                onClick={sendToAll}
                            >
                                Send Notification
                        </button>
                        </div>
                    </CardHeader>
                    <CardHeader>
                        <div className="col-8 pl-0 pt-4 d-flex justify-content-start align-items-center">
                            <div className="col-3 pl-0 justify-content-center align-items-center">
                                <div className="form-group">
                                    <label>From date</label>
                                    <DatePicker
                                        onChange={value => {

                                            let formatedStartDate = formatDate(value);
                                            if (formatedStartDate) {
                                                setStartDate(new Date(formatedStartDate));
                                            }


                                            //formikProps.setFieldValue("startDate", value)}
                                        }}
                                        value={startDate}
                                        name="startDate"
                                        // minDate={new Date()}
                                        format="dd-MM-y"
                                        className="form-control form-control-solid h-auto readOnlySpan"
                                        clearIcon={null}
                                        calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                                    />
                                </div>
                            </div>
                            <div className="col-3 pl-0 justify-content-center align-items-center">
                                <div className="form-group">
                                    <label>To date</label>
                                    <DatePicker
                                        onChange={value => {

                                            let formatedEndDate = formatDate(value);
                                            if (formatedEndDate) {
                                                setEndDate(new Date(formatedEndDate));
                                            }

                                            // if (endDate) {
                                            //     console.log('end date:', endDate);
                                            // }
                                            //formikProps.setFieldValue("endDate", value)}
                                        }}
                                        value={endDate}
                                        name="endDate"
                                        //minDate={new Date(startDate)}
                                        format="dd-MM-y"
                                        className="form-control form-control-solid h-auto readOnlySpan"
                                        clearIcon={null}
                                        calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                                    />
                                </div>
                            </div>
                            <div className="col-2">
                                <button
                                    id="button"
                                    type="button"
                                    className="btn btn-primary font-weight-bold upload transformBtn ml-4 pt-2 pb-2"
                                    // disabled={isEmailUnique}
                                    onClick={searchGeneratedData}
                                    
                                    // {() =>{searchBucketWisePerformance()}}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody className="p-4">
                        {data && (
                            <Table columns={columns} data={data}
                                renderRowSubComponent={renderRowSubComponent}
                            />
                        )}
                    </CardBody>
                </Card>
            {/* </LoadingOverlay> */}
        </div >
    );
}

export default GeneratedData;





// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <div>
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    )
}


function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val


// Our table component
function Table({ columns, data, renderRowSubComponent }) {

    // if(data.length < 10) {
    //     dataPerPage = data.length;
    // }
    // if(data && data.length < 10)
    //     dataPerPage = data.length
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        //state,
        visibleColumns,
        //preGlobalFilteredRows,
        //setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize },

    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            autoResetExpanded: false,
            initialState: { pageIndex: 0, pageSize: 5,}
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,//sort data
        useExpanded,
        usePagination,//use pagination
        useRowSelect,
        // hooks => {
        //     hooks.visibleColumns.push(columns => [
        //         // Let's make a column for selection

        //         ...columns,
        //     ])
        // }
    )

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    page.map((row) => {
        //console.log(" row =========== ", (row.id));
        //console.log(" row =========== ", (!parseFloat(row.id)));
        if(!(parseFloat(row.id) % 1)) {
            //console.log(" return ");
        }
    });

    let display = 'content';
    return (
        <>
            <div>
                <div className="tblScroll scrolltablehm" style={{height:'65vh'}}>
                    <table {...getTableProps()} className="legalTables _tabgenbi">
                        <thead className="tableHead">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                                    {headerGroup.headers.map((column, index) => (
                                        <th key={index}>
                                            <table style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th className="generadatath" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render('Header')}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? '↓'
                                                                        : '↑'
                                                                    : ''}
                                                            </span>
                                                        </th>
                                                    </tr>
                                                    {/* <tr>
                                                        <th>{column.canFilter ? column.render('Filter') : null}</th>
                                                    </tr> */}
                                                </thead>
                                            </table>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, index) => {
                                prepareRow(row);
                                
                                //console.log(" display ======== ", display);
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        { !(parseFloat(row.id) % 1) && (
                                            <tr style={{display: display}} className={`${display} ${ display = 'content'}`}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    );
                                                })}
                                            </tr>
                                        )}
                                        
                                        {row.isExpanded && (
                                            <tr className={`p-0 ${display = 'none'}`}>
                                                <td className="p-0" colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent(row)}
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
                <div className="row m-0">
                    <div className="pagination col-lg-12">
                        <div className="col-lg-2">
                            <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>{' '}
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Total Records{' '}
                                <strong>
                                    {rows.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-lg-3">
                            <span>
                                Go to page{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(page)
                                    }}
                                    className="gotoPage"
                                />
                            </span>{' '}
                        </div>
                        <div className="col-lg-3">
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                className="selectDisplayNoOfRecords"
                            >
                                {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                {/* <div>Showing the first 20 results of {rows.length} rows</div>
      <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div> */}
            </div>
        </>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

