/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
//import { shallowEqual } from "react-redux";
import { useSelector } from 'react-redux';

import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import ShowImageWithOtherInfo from "./modules/Auth/pages/ShowImageWithOtherInfo";
import CPV_DataOut from "./modules/Auth/pages/CPV_DataOut";


export function Routes() {
  let { loggedIn } = useSelector(state => state.entities.auth);
  const user = useSelector(state => state.entities.auth.data);
  const isLogs = useSelector(state => state.entities.auth.showLogs);
  console.log('isLogs:', isLogs);

  //let loggedIn = false;


  // useEffect(() => {
  //   if (isLogs) {
  //     console.log = function () { };
  //   }
  // },[isLogs]);

  // if (user) {
  //   if (loggedIn) {
  //     console.log('loggedIn:', loggedIn);
  //     dispatch(loan(user.userId, user.agencyId));
  //     if (user.role !== 'Agency Manager') {
  //       dispatch(financiers(user.userId));
  //     }
  //     dispatch(agencies(user.userId));
  //   }
  // }

  return (
    <Switch>
      <Route path="/ShowImageWithOtherInfo" component={ShowImageWithOtherInfo } />
      <Route path="/CPV_DataOut" component={CPV_DataOut } />
      {!loggedIn ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!loggedIn ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
