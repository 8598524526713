import React, { useEffect, useState, lazy, Suspense } from "react";
import classnames from "classnames";
import AreaChart from "../../Charts/pages/AreaChart";
import BarChart from "../../Charts/pages/BarChart";
import CustomMap from "../../Charts/pages/CustomMap";
import CustomTable from "../../Charts/pages/CustomTable";
import DynamicChart from "../../Charts/pages/DynamicChart";
import "../../Charts/css/Chart.scss";
import CustomProgressBar from "./CustomProgressBar";
import AreaChartSingle from "../../Charts/pages/AreaChartSingle";
import UserActivityTable from "../../Charts/pages/UserActivityTable";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../Loader/Loader";
import {
  activities,
  customerConnects,
  financierDashboardAmountCollected,
  intensities,
  noContacts,
  portfolioProfitCounts,
  portfolios,
  todaysCollections,
} from "../../../store/financierDashboard";
import {
  getStatistics,
  clearMessages as statisticsClearMessages,
} from "../../../store/statistics";
import DatePicker from "react-date-picker";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import moment from "moment";

const TableVisualizer = lazy(() =>
  import("./../../../components/TableVisualizer/TableVisualizer")
);
const MapVisualizer = lazy(() =>
  import("./../../../components/MapVisualizer/MapVisualizer")
);

let mapCode = "TT";
const Tempdashboard = () => {
  let [selectDD, setSelectDD] = useState();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.entities.auth.data);
  const loading = useSelector(
    (state) => state.entities.financierDashboard.loading
  );
  console.log("DashDataFin-------", loading);
  const amountCollecte = useSelector(
    (state) => state.entities.financierDashboard.data
  );
  const todaysCollection = useSelector(
    (state) => state.entities.financierDashboard.todaysCollection.data
  );
  const activity = useSelector(
    (state) => state.entities.financierDashboard.activity.data
  );
  const portfolioProfitCount = useSelector(
    (state) => state.entities.financierDashboard.portfolioProfitCount.data
  );
  const portfolio = useSelector(
    (state) => state.entities.financierDashboard.portfolio.data
  );
  const customerConnect = useSelector(
    (state) => state.entities.financierDashboard.customerConnect.data
  );
  const intensity = useSelector(
    (state) => state.entities.financierDashboard.intensity.data
  );
  const noContact = useSelector(
    (state) => state.entities.financierDashboard.noContact.data
  );

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  var date = new Date();
  var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var lastDay = formatDate(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  let [startDate, setStartDate] = useState(new Date(firstDay));
  let [endDate, setEndDate] = useState(new Date(lastDay));
  const [years, setYears] = useState([]);
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [months, setMonths] = useState([
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ]);
  const [defaultMonth, setDefaultMonth] = useState();
  /* BEGIN: SVG Map and Table's var, configs, etc */
  console.log(
    "statisticsLoading--------",
    statisticsStatus,
    statisticsLoading,
    statisticsData
  );
  const {
    status: statisticsStatus,
    loading: statisticsLoading,
    data: statisticsData,
  } = useSelector((state) => state.entities.statistics);
  const [isDistrictView, setIsDistrictView] = useState(false);
  const [districtsData, setDistrictsData] = useState({});
  const [mapStatisticsData, setMapStatisticsData] = useState({});
  const [expandTable, setExpandTable] = useState(false);
  const [regionHighlighted, setRegionHighlighted] = useState({
    stateCode: mapCode,
    districtName: null,
  });

  useEffect(() => {
    let temp = [];
    for (let index = 2019; index <= 2030; index++) {
      temp[index] = { value: index, label: index };
    }
    setYears(temp);
  }, []);

  useEffect(() => {
    setMapStatisticsData({});
    setDistrictsData({});
    setRegionHighlighted({
      stateCode: mapCode,
      districtName: null,
    });
    dispatch(
      getStatistics(isDistrictView ? "DISTRICTS" : "STATES", user.userId)
    );
  }, [isDistrictView]);

  useEffect(() => {
    if (statisticsStatus === "error") {
      setMapStatisticsData({});
      setRegionHighlighted({
        stateCode: mapCode,
        districtName: null,
      });
      setTimeout(() => {
        dispatch(statisticsClearMessages());
      }, 3000);
    }
  }, [statisticsStatus]);

  useEffect(() => {
    if (statisticsStatus === "success" && !statisticsLoading) {
      let districtWiseData = {};
      if (isDistrictView) {
        let tempData = JSON.parse(JSON.stringify(statisticsData));

        if (
          tempData &&
          tempData.hasOwnProperty("regional") &&
          Object.keys(tempData.regional).length
        ) {
          for (const [stateCode, regionalData] of Object.entries(
            tempData.regional
          )) {
            if (regionalData.hasOwnProperty("district_wise")) {
              for (const [districtName, districtData] of Object.entries(
                regionalData.district_wise
              )) {
                if (districtName && districtName != "-") {
                  districtData["stateCode"] = stateCode;
                  districtWiseData[districtName] = districtData;
                }
              }
            }
          }
        }
      }

      setMapStatisticsData(statisticsData);
      setDistrictsData(districtWiseData);
      setTimeout(() => {
        dispatch(statisticsClearMessages());
      }, 3000);
    }
  }, [statisticsLoading]);
  /* END: SVG Map and Table's var, configs, etc */

  useEffect(() => {
    var d = new Date(),
      month = "" + ("0" + (d.getMonth() + 1)).slice(-2),
      day = "" + d.getDate(),
      year = d.getFullYear();
    setYear(year);
    setMonth(month);
    let defalutMonth = months.filter((m) => m.value == month);
    setDefaultMonth(defalutMonth);
    var result = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    let tempStartDate = `${year}-${month}-01`;
    let tempEndDate = `${year}-${month}-${result}`;
    dispatch(
      financierDashboardAmountCollected(user.userId, tempStartDate, tempEndDate)
    );
    dispatch(todaysCollections(user.userId, tempStartDate, tempEndDate));
    dispatch(activities(user.userId, tempStartDate, tempEndDate));
    dispatch(
      getStatistics(
        isDistrictView ? "DISTRICTS" : "STATES",
        user.userId,
        tempStartDate,
        tempEndDate
      )
    );
    dispatch(portfolioProfitCounts(user.userId, tempStartDate, tempEndDate));
    dispatch(portfolios(user.userId, tempStartDate, tempEndDate));
    dispatch(customerConnects(user.userId, tempStartDate, tempEndDate));
    dispatch(intensities(user.userId, tempStartDate, tempEndDate));
    dispatch(noContacts(user.userId, tempStartDate, tempEndDate));
  }, []);

  const onLoanFilter = () => {
    // console.log(year, month);
    var result = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    let tempStartDate = `${year}-${month}-01`;
    let tempEndDate = `${year}-${month}-${result}`;
    dispatch(
      financierDashboardAmountCollected(user.userId, tempStartDate, tempEndDate)
    );
    dispatch(todaysCollections(user.userId, tempStartDate, tempEndDate));
    dispatch(activities(user.userId, tempStartDate, tempEndDate));
    dispatch(
      getStatistics(
        isDistrictView ? "DISTRICTS" : "STATES",
        user.userId,
        tempStartDate,
        tempEndDate
      )
    );
    dispatch(portfolioProfitCounts(user.userId, tempStartDate, tempEndDate));
    dispatch(portfolios(user.userId, tempStartDate, tempEndDate));
    dispatch(customerConnects(user.userId, tempStartDate, tempEndDate));
    dispatch(intensities(user.userId, tempStartDate, tempEndDate));
    dispatch(noContacts(user.userId, tempStartDate, tempEndDate));
    // console.log(tempStartDate, tempEndDate);
  };

  return (
    <div>
      <CardHeader>
        {loading ? <Loader /> : " "}
        <div className="col-lg-12 pt-4 pl-0 d-flex justify-content-start align-items-left">
          {/* <div className="col-lg-4 pl-0 justify-content-left align-items-left">
                  <div className="form-group">
                    <label>From date </label>
                    <DatePicker
                      onChange={value => {
                        let formatedStartDate = formatDate(value);
                        if (formatedStartDate) {
                          setStartDate(new Date(formatedStartDate));
                        }
                      }}
                      value={startDate}
                      name="startDate"
                      // minDate={new Date()}
                      format="dd-MM-y"
                      className="form-control form-control-solid h-auto readOnlySpan"
                      clearIcon={null}
                      calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                    />
                  </div>
                </div>
                <div className="col-lg-4 pl-0 justify-content-left align-items-left">
                  <div className="form-group">
                    <label>From date </label>
                    <DatePicker
                      onChange={value => {
                        let formatedEndDate = formatDate(value);
                        if (formatedEndDate) {
                          setEndDate(new Date(formatedEndDate));
                        }
                      }}
                      value={endDate}
                      name="endDate"
                      //minDate={new Date(startDate)}
                      format="dd-MM-y"
                      className="form-control form-control-solid h-auto readOnlySpan"
                      clearIcon={null}
                      calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                    />
                  </div>
                </div> */}
          {year && (
            <div className="col-lg-3 pl-0 justify-content-center align-items-center">
              <div className="form-group">
                <label>Select Year</label>
                <Select
                  defaultValue={{ label: year, value: year }}
                  // defaultValue={{label: '2022', value: '2022'}}
                  placeholder="Select..."
                  name="export_type"
                  className="jobRoleSelect w-100"
                  options={years}
                  onChange={(data) => {
                    setYear(data.value);
                  }}
                />
              </div>
            </div>
          )}
          {month && (
            <div className="col-lg-3 pl-0 justify-content-center align-items-center">
              <div className="form-group">
                <label>Select Month</label>
                <Select
                  defaultValue={defaultMonth}
                  placeholder="Select..."
                  name="export_type"
                  className="jobRoleSelect w-100"
                  options={months}
                  onChange={(data) => {
                    // console.log(" test ", data.value);
                    let currentYear = year;
                    if (!currentYear) {
                      currentYear = date.getFullYear();
                    }
                    setMonth(data.value);
                    // var my_date = new Date("" + currentYear + ", " + data.value + "");
                    // var first_date = formatDate(new Date(my_date.getFullYear(), my_date.getMonth(), 1));
                    // var last_date = formatDate(new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0));
                    // setStartDate(new Date(first_date));
                    // setEndDate(new Date(last_date));
                    // console.log(` first_date = ${first_date}, last_date = ${last_date}`);
                  }}
                />
              </div>
            </div>
          )}
          <div className="col-lg-2 mt-8">
            <button
              id="button"
              type="button"
              className="btn btn-primary w-100 font-weight-bold upload mr-4 transformBtn"
              // disabled={isEmailUnique}
              onClick={onLoanFilter}
            >
              Search
            </button>
          </div>
        </div>
      </CardHeader>
      <div className="row mt-8">
        <div className="col-lg-12 pl-8">
          <div className="row">
            <div className="col-lg-5 pl-0">
              <div className="col-lg-12 tablemnpadd">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <BarChart amountCollected={amountCollecte} />
                  </div>

                  <div className="col-lg-12 p-0">
                    <DynamicChart amountCollected={amountCollecte} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 p-0">
              <div className="col-lg-12 areacharher">
                <AreaChart
                  portfolioProfitCount={portfolioProfitCount}
                  portfolio={portfolio}
                  noContact={noContact}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          {/* <div className='col-lg-8 pl-0'>
                        <div className={`col-lg-12 pl-0 pr-0 card card-custom card-stretch gutter-b card-div`}>
                            <div className="card-header p-0 borderBottom">
                                <div className="card-title ml-8">
                                    <h4 className="card-label">Country Pool Statistics</h4>
                                </div>
                            </div>
                            <div className='d-flex flex-row'>
                                
                                <div className='col-lg-9 pl-0'>
                                    <div className="card-body pl-0 pt-0 position-relative overflow-hidden">
                                        <CustomMap zoom={4} height="50vh" />
                                    </div>
                                </div>
                                <div className='col-lg-3 d-flex justify-content-center align-items-center'>
                                    <p className='h1'>India</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
          <div className="col-lg-12 pl-0">
            <div className="row">
              <div className="col-lg-6 pr-8">
                <div style={{ height: "100px" }}>
                  <CustomTable customerConnect={customerConnect} />
                </div>
              </div>
              <div
                className={`col-lg-6 pl-0 pr-0 card card-custom card-stretch gutter-b card-div`}
                style={{ height: "375px" }}
              >
                <div className="card-body pr-0 position-relative overflow-hidden">
                  <div className="my-1">
                    <h6>Till Date Collection</h6>
                    <h1>
                      {todaysCollection ? todaysCollection.totalCollection : 0}
                    </h1>
                    <div className="pr-8">
                      <CustomProgressBar
                        value={
                          todaysCollection
                            ? todaysCollection.totalCollectionPercentage
                            : 0
                        }
                      />
                    </div>
                  </div>
                  <div className="my-8">
                    <h6>Ever PTP</h6>
                    <h1>{todaysCollection ? todaysCollection.everPtp : 0}</h1>
                    <div className="pr-8">
                      <CustomProgressBar
                        value={
                          todaysCollection
                            ? todaysCollection.everPtpPercentage
                            : 0
                        }
                      />
                    </div>
                  </div>
                  <div className="my-8">
                    <h6>Ever Connect</h6>
                    <h1>
                      {todaysCollection ? todaysCollection.everConnect : 0}
                    </h1>
                    <div className="pr-8">
                      <CustomProgressBar
                        value={
                          todaysCollection
                            ? todaysCollection.everConnectPercentage
                            : 0
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 d-flex flex-row">
          <div className="col-lg-8 pl-0 pr-8">
            <AreaChartSingle intensity={intensity} />
          </div>
          <div className="col-lg-4 p-0">
            {/* <UserActivityTable /> */}
            <div
              className={`col-lg- 12 p-0 card card-custom card-stretch gutter-b card-div`}
            >
              {/* Body */}
              <div className="col-lg-12 pl-0 pr-0 card-body pt-0 position-relative overflow-hidden">
                <div className="col-lg-12 p-0 py-4 d-flex flex-row justify-content-center align-items-center borderBottom">
                  <div className="col-lg-6 customercon">User Activity</div>
                  <div className="col-lg-6">
                    {/* <Select
                                            placeholder="Select..."
                                            name="role"
                                            className="jobRoleSelect w-100"
                                            options={selectDD}
                                            onChange={value => { setSelectDD(value.value) }}
                                        /> */}
                  </div>
                </div>
                <div className="col-lg-12 p-0 py-4 d-flex flex-row justify-content-center align-items-center borderBottom">
                  <div className="col-lg-4 font-weight-bold">Month</div>
                  <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center">
                    <div style={{ textAlign: "center" }}>Total Disposition</div>
                    {/* <div>325</div> */}
                  </div>
                  <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center">
                    <div>Users</div>
                    {/* <div>69%</div> */}
                  </div>
                </div>

                {activity &&
                  activity.map((ad, index) => {
                    return (
                      <div
                        key={index}
                        className="col-lg-12 p-0 py-4 d-flex flex-row justify-content-center align-items-center borderBottom"
                      >
                        <div className="col-lg-4 font-weight-bold">
                          {ad.month}
                        </div>
                        <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center">
                          <div>{ad.disposition}</div>
                        </div>
                        <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center">
                          <div>{ad.user}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 d-flex flex-row">
          <div className="col-md-12 p-0">
            <div
              className={`col-md-12 pl-0 pr-0 card card-custom gutter-b card-div`}
            >
              <LoadingOverlay
                active={statisticsLoading}
                spinner
                text="Loading data..."
                className="loading-spinner-custom-color"
                classNamePrefix="dashboard-page-loader-"
                styles={{
                  wrapper: {
                    zIndex: 50,
                    overflow: statisticsLoading ? "hidden" : "hidden",
                  },
                  spinner: (base) => ({
                    ...base,
                    width: "75px",
                  }),
                }}
              >
                <div
                  className={classnames(
                    "py-8",
                    "d-flex",
                    { "flex-row": !expandTable },
                    { "flex-column": expandTable }
                  )}
                >
                  <div
                    className={classnames(
                      { "col-md-5": !expandTable },
                      { "col-md-12": expandTable }
                    )}
                  >
                    <Suspense fallback={<div />}>
                      <TableVisualizer
                        {...{
                          mapCode,
                          mapStatisticsData,
                          expandTable,
                          setExpandTable,
                          regionHighlighted,
                          setRegionHighlighted,
                          isDistrictView,
                          districtsData,
                        }}
                      />
                    </Suspense>
                  </div>
                  <div className="col-md-7">
                    <Suspense fallback={<div />}>
                      <MapVisualizer
                        {...{
                          mapCode,
                          mapStatisticsData,
                          regionHighlighted,
                          setRegionHighlighted,
                          isDistrictView,
                          setIsDistrictView,
                        }}
                      />
                    </Suspense>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tempdashboard;
