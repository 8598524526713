import React, { useState, useEffect, Fragment } from "react";
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
//import LogDetailsModal from './components/LogDetailsModal';
//import $ from "jquery";
import "datatables.net/js/jquery.dataTables";
import "datatables.net-bs4/js/dataTables.bootstrap4.js";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-buttons-bs4";
import { useHistory } from "react-router-dom";
import millify from 'millify';
import DatePicker from 'react-date-picker';

import "../../Loan/css/loan.scss";
import "../css/bucketWiseTable.scss";
import "../css/summary.scss";

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy, useExpanded } from 'react-table';
import matchSorter from 'match-sorter';
import LoadingOverlay from 'react-loading-overlay';
import Alert from 'react-bootstrap/Alert';
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import FinancierWiseSubRows from "./FinancierWiseSubRows";

import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
//import { toAbsoluteUrl } from "../../../../layout/_metronic/_helpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
//import PrimaryEmail from "../../Loan/components/PrimaryEmail";
//import PrimaryContact from "../../Loan/components/PrimaryContact";
import { financierWisePerformanceClearMessages, financierWisePerformance } from "../../../store/dashboard";
import {
  bucketWisePerformance, portfolio, summary, portfolioGeographyStateWise,
  bucketWisePerformanceClearMessages, portfolioGeographyCityWise, portfolioGeographyDistrictWise, posDistribution,
  portfolioGoeStateClearMessages, portfolioGoeCityClearMessages, posDistributionClearMessages, portfolioExpectedCollections
} from "../../../store/summary";
import Select, { components } from 'react-select';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import DashboardComponent from "../DashboardComponent";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import s3BucketConfig from "../../../utils/s3BucketConfig";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { processOnData } from "../../DataImport/DataImportProcess";
import { loanTableForLegal } from "../../TableConstants/tableConstant";
import environment from '../../../../../src/env.json';
// import LegalNoticeTempalteCSV_File from "../../DataImport/files/LegalNoticeTempalteCSV_File.csv";
// import sampleCSVFile from "../../DataImport/files/.sampleCSVFile.csv";
import LoanSampleForLegal from "../../DataImport/files/LoanSampleForLegal.csv";

// /Users/sayeedansari/Documents/code/acenna/Acenna_Panel/src/env.json

import styled from 'styled-components';
import { saveFileUploadRequest } from "../../../store/upload";

const Styles = styled.div`
  table {
    th{
      padding: 0px;
    }
  }
`;

let s3Bucket;
function AutomatedLegal(props) {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [selectedPdfFile, setSelectedPdfFile] = useState(null);
  const [pageLoader, setPageLoader] = useState(false);
  const [showpdf, setShowpdf] = useState(false);
  const [csvData, setCsvData] = useState([]);
  console.log("csv data",csvData);
  const [columns1, setColumns] = useState([]);
    const [uploadCsvData, setUploadCsvData] = useState([]);
	const [selectedCsvFile, setSelectedCsvFile] = useState(null);
	let columns = [], list2;
  let mandatoryYes = [], invalidCSV = false, errorMsg = 'CSV file does not contain proper record , please refer sample csv file.';
  //const [pageLoader, setPageLoader] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.entities.auth.data);
  let summaryData = useSelector((state) => state.entities.summary.data);
  let portfolioData = useSelector((state) => state.entities.summary.portfolio_view.data);
  let bucketWiseData = useSelector((state) => state.entities.summary.bucketWisePerformance.data);
  let portfolioGeographicalStateData = useSelector((state) => state.entities.summary.portfolioGoeState.data);
  let portfolioGeographicalCityData = useSelector((state) => state.entities.summary.portfolioGoeCity.data);
  let portfolioGeographicalDistrictData = useSelector((state) => state.entities.summary.portfolioGoeDistrict.data);
  let posDistributionData = useSelector((state) => state.entities.summary.posDistribution.data);
  let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data);
  //console.log("financierWisePerformanceData table Data:", financierWisePerformanceData);
  //   console.log("portfolioData:", portfolioData);

  const mapStyles1 = {
    width: '95.5%',
    height: '93%',
    overflow: 'hidden',
    position: 'relative'
  };

  const mapStyles = {
    width: '95.5%',
    height: '96%',
    overflow: 'hidden',
    position: 'relative'
  };



  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const numFormatter = (num) => {
    let toFixe = 4;
    if (num <= 0) {
      return num.toFixed(2)
    } else if (num > 999 && num < 9999) {
      toFixe = 3
    } else if (num >= 10000) {
      toFixe = 2
    }
    return (num / 1000000).toFixed(toFixe);
  }

  var date = new Date();
  var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  let [startDate, setStartDate] = useState(new Date(firstDay));
  let [endDate, setEndDate] = useState(new Date(lastDay));
  let [startDateDpd, setStartDateDpd] = useState(new Date(firstDay));
  let [endDateDpd, setEndDateDpd] = useState(new Date(lastDay));
  let [bucketWiseFilter, setBucketWiseFilter] = useState(-1);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  //   console.log(" startDate " , startDate);
  //   console.log(" startDateDpd " , startDateDpd);



  


  let bucketFilterList = [
    { value: -1, label: 'All' },
    { value: 0, label: '0-30 DPD' },
    { value: 31, label: '31-60 DPD' },
    { value: 61, label: '61-90 DPD' },
    { value: 91, label: '91-120 DPD' },
    { value: 121, label: '121-150 DPD' },
    { value: 151, label: '151-180 DPD' },
    { value: 181, label: '181+ DPD' }
  ]
  const { status, error, loading, message } = useSelector((state) => state.entities.dashboard.financierWisePerformance);

  //console.log("financierWisePerformance...", error, status, loading, message);


  useEffect(() => {
    if (status === "error") {
      let errorMsg = "Something went wrong. Please try again.";
      if (typeof error === "object") {
        errorMsg = error.message;
      }
      else {
        errorMsg = error;
      }
      setErrorAlertMsg(errorMsg);
      //   setShowErrorAlert(true);
      setTimeout(() => {
        //setPageLoader(false);
        dispatch(financierWisePerformanceClearMessages());
        setErrorAlertMsg('');
        setShowErrorAlert(false);
      }, 2000);
    }
  }, [status])

  //   useEffect(() => {
  //     if (user.role !== 'Agency Manager') {
  //       if (financierWisePerformanceData === null) {
  //         if (firstDay && lastDay) {
  //           dispatch(financierWisePerformance(user.userId, user.agencyId, firstDay, lastDay, bucketWiseFilter));
  //         }
  //       }
  //     }
  //   }, [])



  // const data = React.useMemo(() => makeData(100000), [])
  // const data = React.useMemo(() => financierWisePerformanceData, [])
  const data = summaryData;
  const portfolio_data = portfolioData;
  const bucketWise_data = bucketWiseData;


  //console.log("portfolioGeographicalStateData data:", portfolioGeographicalStateData);

  const refreshFinancierWiseInfo = () => {
    console.log('start date and end date :', firstDay, lastDay, bucketWiseFilter);
    dispatch(financierWisePerformance(user.userId, user.agencyId, firstDay, lastDay, bucketWiseFilter));
    setStartDate(new Date(firstDay));
    setEndDate(new Date(lastDay));
  }



  const searchPortfolio = () => {
    let formateStartDate = formatDate(startDate);
    let formateEndDate = formatDate(endDate);
    console.log(' start date and end date :', formateStartDate, formateEndDate, bucketWiseFilter);
    console.log(" user ", user);
    if (formateStartDate && formateEndDate) {
      dispatch(portfolio(user.userId, user.financierId, formateStartDate, formateEndDate));
    }
  }

  const searchDpd = () => {
    let formateStartDateDpd = formatDate(startDateDpd);
    let formateEndDateDpd = formatDate(endDateDpd);
    console.log(' start date and end date :', formateStartDateDpd, formateEndDateDpd);
    console.log(" user ", user);
    if (formateStartDateDpd && formateEndDateDpd) {
      dispatch(bucketWisePerformance(user.userId, user.financierId, formateStartDateDpd, formateEndDateDpd));
    }
  }

  	// handle file upload
  	const handleFileUpload = e => {
		// console.log('data length:', data);
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = (evt) => {
			/* Parse data */
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: 'binary' });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
			processData(data);
		};
		reader.readAsBinaryString(file);
		setSelectedCsvFile(e.target.files[0]);
		e.target.value = '';
	}

	//set table column from constant file
    for (const iterator of loanTableForLegal) {
        if (iterator.displayOnUI == 'Y') {
            columns.push(
                {
                    Header: iterator.tableColumnName == 'Loan Id' ? "Loan Id" : iterator.tableColumnName,
                    accessor: iterator.csvColumnName,
                    filter: 'fuzzyText',
                    disableSortBy: true,
                    disableFilters: true,
                }
            )
        }
    }

    // process CSV data
    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        let list = processOnData(dataString);
        // prepare columns list from headers
        const columns1 = headers.map(c => ({
            name: c,
            selector: c,
        }));
        list2 = JSON.parse(JSON.stringify(list));
        for (let i in list2) {
            delete list2[i].id;
        }
        //mandatory column added in array variable
        
        for (const iterator of loanTableForLegal) {
            if (iterator.mandatory == 'Y') {
                mandatoryYes.push(iterator.csvColumnName);
            }
        }
        // Expect input as d/m/y
        function isValidDate(s) {
            var bits = s.split('/');
            var d = new Date(bits[2], bits[1] - 1, bits[0]);
            return d && (d.getMonth() + 1) == bits[1];
        }

        console.log(" mandatoryYes ===== ", mandatoryYes);
        //loop through check mandatory column and their values
        list2.forEach((element, index) => {

            if (element.agencyAllocationDate) {
                let isDate = isValidDate(element.agencyAllocationDate);
                if (!isDate) {
                    errorMsg = `Missing or invalid data at Record #${index + 1}.`;
                    invalidCSV = true;
                }
            }

            mandatoryYes.forEach(mandatoryYesElement => {

                // if (!element[mandatoryYesElement]) {
                //     errorMsg = `Missing or invalid data at Record #${index + 1}.`;
                //     invalidCSV = true;
                // }
            });
        });

        //if valid csv then display in table otherwise throwing error
        if (!invalidCSV) {
			setUploadCsvData(list2)
            // setDisplayError(false);
            setShowErrorAlert(false);
        }
        else {
            setUploadCsvData([]);
            setErrorAlertMsg(errorMsg);
            setShowErrorAlert(true);
        }
        setColumns(columns1);
    }

	// handle pdf file upload
	const handlePdfFileUpload = e => {

		//console.log('data length:', data);
		const file = e.target.files[0];
		const reader = new FileReader();
		setSelectedPdfFile(e.target.files[0]);
		e.target.value = '';
	}

  //upload csv to server
  async function uploadPdf() {
    setPageLoader(true);
    s3Bucket = await s3BucketConfig("FINANCIER_SAMPLE_PDF");

    if (s3Bucket) {

      const fileName = `${user.financierId}.pdf`;
      const params = {
        ACL: 'public-read',
        Body: selectedPdfFile,
        Key: fileName
      };
      // console.log(' fileName ', fileName)
	  setShowpdf(false);
      s3Bucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
          // setProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .send((err) => {
			setShowpdf(true);
          if (err) {
            setPageLoader(false);
            setErrorAlertMsg("Something went wrong. Please try again.");
            setShowErrorAlert(true);
            setTimeout(() => {
              setErrorAlertMsg("");
              setShowErrorAlert(false);
            }, 4000);
          }
          else {
            setSelectedPdfFile(null);
            setPageLoader(false);
          }
        });
    }
    else {
      setPageLoader(false);
      setErrorAlertMsg("Something went wrong. Please try again.");
      setShowErrorAlert(true);
      setTimeout(() => {
        setErrorAlertMsg("");
        setShowErrorAlert(false);
      }, 4000);
    }
  }

  //upload csv to server
  	async function uploadCSV() {
		try {
			if (uploadCsvData) {
				setPageLoader(true);
				s3Bucket = await s3BucketConfig("LOAN_UPLOADS");

				if (s3Bucket) {
					const fileName = `LOAN_UPLOADS_${new Date().getTime()}_${user.userId}.csv`;
					const params = {
						ACL: 'public-read',
						Body: selectedCsvFile,
						Key: fileName
					};
					
					s3Bucket.putObject(params)
						.on('httpUploadProgress', (evt) => {
							// setProgress(Math.round((evt.loaded / evt.total) * 100))
						})
						.send((err) => {
							if (err) {
								setPageLoader(false);
								setErrorAlertMsg("Something went wrong. Please try again.");
								setShowErrorAlert(true);
								setTimeout(() => {
									setErrorAlertMsg("");
									setShowErrorAlert(false);
								}, 4000);
							}
							else {
								setSelectedCsvFile(null);
								const postData = {
									'feature': "LONG_RUNNING_FILE_UPLOAD",
									'userId': user.userId,
									'fileName': fileName,
									'operation': "LOAN_UPLOADS",
									'totalRecord': uploadCsvData.length,
									'financierId': user.financierId || ""
								};
								setPageLoader(false);
								dispatch(saveFileUploadRequest(postData));
								// navigate('/import/loanforlegal');
								history.push({
									pathname: '/import/loanforlegal'
								});
							}
						});
				}
				else {
					setPageLoader(false);
					setErrorAlertMsg("Something went wrong. Please try again.");
					setShowErrorAlert(true);
					setTimeout(() => {
						setErrorAlertMsg("");
						setShowErrorAlert(false);
					}, 4000);
				}
			}
		} catch (e) {
			setPageLoader(false);
			setErrorAlertMsg("Something went wrong. Please try again.");
			setShowErrorAlert(true);
			setTimeout(() => {
				setErrorAlertMsg("");
				setShowErrorAlert(false);
			}, 4000);
		}
	}

  const renderRowSubComponent = (row) => {
    return (
      <FinancierWiseSubRows financierWiseSubRowsData={row.original.subRows} rowData={row.original} />
    );
  };

  return (
    <div className="logs-card-div">
      <LoadingOverlay
        active={loading || pageLoader}
        spinner
        text='Loading...'
        // classNamePrefix='pg-list-loader-'
        styles={{
          // overlay: (base) => ({
          //   ...base,
          //   background: 'rgba(255, 0, 0, 0.5)'
          // }),
          wrapper: {
            overflow: loading || pageLoader ? 'hidden' : 'hidden'
          },
          spinner: (base) => ({
            ...base,
            width: '75px',
            '& svg circle': {
              stroke: '#00b1cc'
            }
          })
        }}
      >
        <Card className="finacierWise w-100 summaryFin">
          <CardHeader>
            <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-start flex-column">
              <h3 className="mb-0">Legal Dashboard</h3>
              {/* <span className="subHeader">Long term loan performance financier wise</span> */}
            </div>
            <div className="col-lg-4 mt-4 d-block">
              {showErrorAlert &&
                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                  {errorAlertMsg}
                </Alert>
              }

              {showSuccessAlert &&
                <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                  {successAlertMsg}
                </Alert>
              }
            </div>

          </CardHeader>
          <CardHeader>
            <div className="col-lg-12 pt-4 pl-0 d-flex justify-content-start align-items-center">

              <div className="col-lg-3 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>From date </label>
                  <DatePicker
                    onChange={value => {
                      let formatedStartDate = formatDate(value);
                      if (formatedStartDate) {
                        setStartDate(new Date(formatedStartDate));
                      }
                    }}
                    value={startDate}
                    name="startDate"
                    // minDate={new Date()}
                    format="dd-MM-y"
                    className="form-control form-control-solid h-auto readOnlySpan"
                    clearIcon={null}
                    calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                  />
                </div>
              </div>
              <div className="col-lg-3 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>To date </label>
                  <DatePicker
                    onChange={value => {
                      let formatedEndDate = formatDate(value);
                      if (formatedEndDate) {
                        setEndDate(new Date(formatedEndDate));
                      }
                    }}
                    value={endDate}
                    name="endDate"
                    //minDate={new Date(startDate)}
                    format="dd-MM-y"
                    className="form-control form-control-solid h-auto readOnlySpan"
                    clearIcon={null}
                    calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                  // disabled={isEmailUnique}
                  onClick={searchPortfolio}
                >
                  Search
                </button>

              </div>

              <div className="col-lg-3 protbtnflo">
                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                // disabled={isEmailUnique}
                // onClick={searchFinancierWisePerformance}
                >
                  Enrich Data
                </button>

              </div>
              {/* <div className="col-lg-2">
                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                  // disabled={isEmailUnique}
                  // onClick={searchFinancierWisePerformance}
                >
                  Upload Data
                </button>

              </div> */}
            </div>

          </CardHeader>
          <CardBody className="p-4">

            <div className="col-md-12 m-3">
              <h4 className="text-center">'Dashboard to be updated on realtime basis post usage of services'</h4>
            </div>

            {/* {portfolio_data && (
              <Table columns={portfolioColumns} data={portfolio_data} renderRowSubComponent={renderRowSubComponent} paginationShow={true} footer={true} />
            )} */}
            <div className="row p-2">
              <div className="col-lg-3 p-2 ">
                <div class="center box">
                  <div className="text-div">
                    <div className="header-text">
                      Total notices generated
                    </div>
                    <div className="value-text">
                      0000 <span style={{ 'fontSize': '12px' }}>customers</span>
                      {/* {portfolioData && portfolioData.length > 0 ? portfolioData[0].accountsRecieved : 0	} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-2 ">
                <div class="center box">
                  <div className="text-div">
                    <div className="header-text">
                      Sent / Delivered
                    </div>
                    <div className="value-text">
                      {/* {portfolioData && portfolioData.length > 0 ? millify(portfolioData[0].pos, { space: true }) : 0	} */}
                      0000 <span style={{ 'fontSize': '12px' }}>customers</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-2 ">
                <div class="center box">
                  <div className="text-div">
                    <div className="header-text">
                      Client engaged
                    </div>
                    <div className="value-text">
                      0000 <span style={{ 'fontSize': '12px' }}>customers,</span> 0% <span style={{ 'fontSize': '12px' }}>of total base</span>
                      {/* {portfolioData && portfolioData.length > 0 ? portfolioData[0].repeat : 0	} */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 p-2 ">
                <div class="center box">
                  <div className="text-div">
                    <div className="header-text">
                      Lawyer engaged
                    </div>
                    <div className="value-text">
                      0
                      {/* {portfolioExpectedCollection && portfolioExpectedCollection.data ? millify(Math.round(portfolioExpectedCollection.data.expectedCollection), { space: true }) : 0} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>

          <CardBody className="p-4 box m-3">

            <div className="col-md-12 m-3">
              <h4 className="">Initiate Legal Process</h4>
            </div>

            {/* {portfolio_data && (
              <Table columns={portfolioColumns} data={portfolio_data} renderRowSubComponent={renderRowSubComponent} paginationShow={true} footer={true} />
            )} */}
            <Card className="col-12">
              <CardHeader className="col-4 mr-0">
                <div className="col-3 mt-5 p-0">
                  <div
                  // style={{ display: tmeplateTypeNameOptionValue.value !== '' ? 'block' : 'none' }}
                  >
                    <label className="csvLabel mb-0 mr-0">
                      <input className="mngfiletypeh" type="file"
                        accept=".csv"
                        onChange={handleFileUpload} required
                      // disabled={tmeplateTypeNameOptionValue.value !== '' ? false : true}
                      />
                      <span className='btn btn-primary font-weight-bold transformBtn csvSpan somesecltn'>Select CSV</span>
                    </label>
                  </div>

                </div>

				{selectedCsvFile && (
                  <div className="col-7 p-0">
                    <div className="col-12 p-0 d-flex justify-content-end">
                      <button className='btn btn-primary font-weight-bold upload mt-5 transformBtn'
                        onClick={() => uploadCSV()}
                      >Confirm</button>
                    </div>
                  </div>
                )}


                <div className="col-2 d-flex justify-content-end align-items-center">
                {csvData && (
                  <button
                    id="CSV"
                    type="button"
                    className="settingBtn font-weight-bold download-csv transformBtn"
                  // onClick={()=>sampleCSVDownload()}
                  >
                      {/* <a href=""
                      // {LegalNoticeTempalteCSV_File}
                       download="LegalNoticeTempalte.csv">
                        Legal Notice Template1</a> */}

                     <a
                      data={csvData}
                      // href={LegalNoticeTempalteCSVFile}
                      href={LoanSampleForLegal} 
                      download="LegalNoticeTemplate.csv"
                    >Legal Notice Template</a>
                  </button>
                  )}
                </div>
              </CardHeader>
              <CardBody style={{ display: (showErrorAlert || showSuccessAlert) ? 'block' : 'none'}}>
                <div className="col-8 d-flex align-items-center justify-content-center">
                  {showErrorAlert &&
                    <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                      {errorAlertMsg}
                    </Alert>
                  }
                  {showSuccessAlert &&
                    <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                      {successAlertMsg}
                    </Alert>
                  }
                </div>
              </CardBody>

			  	<CardBody className="customCardBody">
					{uploadCsvData.length !== 0 && (
						<Styles>
							<Table columns={columns} data={uploadCsvData} />
						</Styles>
					)}
					
				</CardBody>
            </Card>
          </CardBody>

          <CardBody className="p-4 box m-3">

            <div className="col-md-12 m-3">
              <h4 className="">Customize legal document</h4>
            </div>

            <div>
              <div className="row" style={{ postion: "relative" }}>
                <div className="col-3">
                  <div className="selcsvcomi text-center">
                    <label className="csvLabel mb-0 mr-0">
                      <input type="file"
                        accept=".pdf, .doc"
                        onChange={handlePdfFileUpload} required
                      />
                      <span className='btn btn-primary w-100 font-weight-bold upload mr-4 transformBtn'>Upload Legal Sample File</span>
                    </label>
                  </div>
                </div>
                {selectedPdfFile && (
                  <div className="col-6">
                    <div className="col-12 d-flex justify-content-start">
                      <button className='btn btn-primary font-weight-bold upload mr-4 transformBtn'
                        onClick={() => uploadPdf()}
                      >Confirm</button>
                    </div>
                  </div>
                )}
                <div className="col-3 mngbtnpositon">
                  <div className="col-12 d-flex justify-content-end">
                    <button className='btn btn-primary w-100 font-weight-bold upload mr-4 transformBtn'
                      onClick={(e) => {
                        // console.log(" e ===== ", e.value);
                        setShowpdf(!showpdf)
                      }}
                    >
                      {showpdf ? 'Hide your uploaded legal' : 'View your uploaded legal'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
			{showpdf && (
				<div className="col-md-12 mt-10 text-center">
					<Document
						file={environment.environment == 'Production' ? `https://s3.ap-south-1.amazonaws.com/dataimportacenna.in/financierSamplePdf/${user.financierId}.pdf` : `https://s3.ap-south-1.amazonaws.com/dev.dataimportacenna.in/financierSamplePdf/${user.financierId}.pdf`}
						// file="https://s3.ap-south-1.amazonaws.com/dev.dataimportacenna.in/financierSamplePdf/31.pdf"
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page pageNumber={pageNumber} />
					</Document>
					<p>
						Page {pageNumber} of {numPages} 
					</p>
					<p className="text-center inpnummng">  
						<p onClick={()=> {
							if(numPages >= pageNumber && pageNumber != 1) {
								setPageNumber(pageNumber-1);
							}
							
						}}
						>
							previous
						</p> 
						<p 
							onClick={()=> {
								if(pageNumber < numPages) {
									setPageNumber(pageNumber+1);
								}
							}}
						>
							next
						</p> 
					</p>
				</div>
			) }
            <div className="col-md-12 mt-10">
              <h4 className="text-left" style={{fontStyle:'italic'}}>*post uploading of legal notice, customisation will take 7-10 working days.</h4>
            </div>
          </CardBody>
        </Card>
      </LoadingOverlay>
      {/* Portfolio */}
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDvHvEhe0C4plQ60VSIEnKReJIDqOQ47qw'
})(AutomatedLegal)


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <div>
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value.toString().toLowerCase() || undefined) // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data, renderRowSubComponent, bucketWise_data, posDistribution_data, paginationShow, footer, footerTotal }) {
  // alert()
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    //setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },

  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,//sort data
    useExpanded,
    usePagination,//use pagination
  )

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);
  // We don't want to render all of the rows for this example, so cap
  // it for this use case


  let display = 'content';

  let default_class_Name = 'loanTable financierWiseTable';

  if (bucketWise_data) {
    default_class_Name = 'loanTable bucketWiseTable'
  }
  else if (posDistribution_data) {
    default_class_Name = 'loanTable bucketWiseTable'
  }

  return (
    <>
      <div>
        <div className="tblScroll">
          <table {...getTableProps()} className={default_class_Name}>
            <thead className="tableHead">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? '↓'
                            : '↑'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  {/* <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              /> */}
                </th>
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    {!(parseFloat(row.id) % 1) && (
                      <tr style={{ display: display }} className={`${display} ${display = 'content'}`}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          );
                        })}
                      </tr>
                    )}
                    {row.isExpanded && (
                      <tr cclassName={`p-0 ${display = 'none'}`}>
                        <td className="p-0" colSpan={visibleColumns.length}>
                          {renderRowSubComponent(row)}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )
              })}
            </tbody>
            {footerTotal ? <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot>
              :
              null
            }
          </table>
        </div>
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
        {footer && <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className={'col-lg-4'}>
              <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </button>{' '}
            </div>
            <div className={paginationShow ? 'col-lg-2' : 'col-lg-4'}>
              <span>
                Total Records{' '}
                <strong>
                  {data.length}
                </strong>{' '}
              </span>
            </div>
            <div className={paginationShow ? 'col-lg-2' : 'col-lg-4'}>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>

            {/* {paginationShow && <div className="col-lg-3">
              <span>
                Go to page{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page)
                  }}
                  className="gotoPage"
                />
              </span>{' '}
            </div>
            } */}

            {paginationShow && <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            }
          </div>
        </div>
        }


        <br />
      </div>
    </>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

