import React, { useEffect, useMemo } from "react";
//import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
//import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { 
    //FormattedMessage,
     injectIntl } from "react-intl";
//import { useHistory } from "react-router-dom";
//import { Dropdown } from "react-bootstrap";
//import { DropdownMenu2 } from "../../../../_metronic/_partials/dropdowns";
import { useHtmlClassService } from "../../../../_metronic/layout";
//import '../css/report_card.scss';
import { useDispatch, useSelector } from "react-redux";
import { noContacts } from "../../../store/financierDashboard";


function ColumnChart(props) {
    const dispatch = useDispatch();
    // const user = useSelector(state => state.entities.auth.data);
    const noContact =  props.noContact; //useSelector(state => state.entities.financierDashboard.noContact.data);
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseColor: objectPath.get(
                uiService.config,
                `js.colors.theme.base.danger`
            ),
            colorsThemeLightColor: objectPath.get(
                uiService.config,
                `js.colors.theme.light.danger`
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);

    // useEffect(()=>{
    //     dispatch(noContacts(user.userId));
    // },[])

    useEffect(() => {
        const element = document.getElementById(`column_chart`);

        if (!element) {
            return;
        }
        if(noContact) {
            const options = getChartOption(layoutProps, noContact);
            const chart = new ApexCharts(element, options);
            chart.render();
            return function cleanUp() {
                chart.destroy();
            };
        }
        
    // }, [layoutProps]);
    });



    return (
        <div>
            <div className="report-card-div">
                <div className={`card card-custom card-stretch gutter-b card-div`}>
                    {/* Body */}
                    <div className="card-body position-relative overflow-hidden mngcrdbod">
                        {/* Title */}
                        <div
                            id={`column_chart`}
                            className="card-rounded-bottom"
                            style={{ width: "100%" }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(ColumnChart);

function getChartOption(layoutProps, noContact) {
    console.log(" noContact ====== ", noContact);
    const options = {
        // series: [
        //     {
        //         name: 'Payment',
        //         data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        //     },
        //     {
        //         name: 'Cancelled',
        //         data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        //     }, {
        //         name: 'All Orders',
        //         data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
        //     }
        // ],
        series: noContact[0].series,
        chart: {
            type: 'bar',
            height: 300,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '100%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        xaxis: {
            // categories: ['2022-04-01', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            categories: noContact[1].categories,
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return " " + val + " "
                }
            }
        }

    };
    return options;
}

