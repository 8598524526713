import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddDisposition from '../pages/AddDisposition';
import EditPaymentModule from '../pages/EditPaymentModule';
import EditContactModule from '../pages/EditContactModule';
import EditDocumentModule from '../pages/EditDocumentModule';
import "../css/editDisposition.scss";

function EditModal(props) {
    console.log('edit modal props:', props);
    return (
        <div className="editModalDiv">
            <Modal show={props.showModal}
                onHide={props.hideModal}
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                className="editTodayModalStyle"
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className="pl-0 pr-0 pt-0 pb-0">
                    {props.dispositionTodayPage === 'dispositionTodayPage' && <AddDisposition hideModal={props.hideModal}
                        loanId={props && props.loanId}
                        dispositionTodayPage={props.dispositionTodayPage} editDispositionData={props.editDispositionData} cancelModal={props.cancelModal} />}
                    {props.dispositionTeleCallPage === 'dispositionTeleCallPage' && <AddDisposition hideModal={props.hideModal}
                        loanId={props && props.loanId}
                        dispositionTeleCallPage={props.dispositionTeleCallPage} editDispositionData={props.editDispositionData} cancelModal={props.cancelModal} />}
                    {props.paymentPage === 'paymentPage' && <EditPaymentModule editPaymentData={props.editPaymentData} />}
                    {props.contactPage === 'contactPage' && <EditContactModule editContactData={props.editContactData} />}
                    {props.documentPage === 'documentPage' && <EditDocumentModule editDocumentData={props.editDocumentData} />}
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={props.hideModal} >
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </div>
    );
}

export default EditModal;