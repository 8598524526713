/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import FirebaseNotifications from "../FirebaseNotifications";
import { I18nProvider } from "../_metronic/i18n";
import { MaterialThemeProvider } from "../_metronic/layout";
import ReactIdleTimer from "./components/ReactIdleTimer";
import LocationChecker from "./components/LocationChecker";
import NetworkAccess from "./components/NetworkAccess";

export default function App({ store, persistor, basename }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      {/* <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}> */}
      <PersistGate persistor={persistor}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
        <FirebaseNotifications />
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          <MaterialThemeProvider>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              {/* Render routes with provided `Layout`. */} 
              <Routes />
              <ReactIdleTimer />
              <LocationChecker />
              {/* <NetworkAccess /> */}
            </I18nProvider>
          </MaterialThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
