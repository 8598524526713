import React, { useState, useEffect } from "react";

import {
    // FormattedMessage, 
    injectIntl
} from "react-intl";
import { useHtmlClassService } from "../../../../_metronic/layout";
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import '../css/Chart.scss';
import moment from "moment";


function DynamicChart(props) {
    const [data, setData] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [resolutionPercentage, setResolutionPercentage] = useState();


    function randomData() {
        now = new Date(+now + oneDay);
        value = value + Math.random() * 21 - 10;
        return {
            name: now.toString(),
            value: [
                [now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'),
                Math.round(value)
            ]
        };
    }
    //let data = [];
    let now = new Date(1997, 9, 3);
    let oneDay = 24 * 3600 * 1000;
    let value = Math.random() * 1000;

    useEffect(() => {
        for (var i = 0; i < 1000; i++) {
            setTempData(tempData => [...tempData, randomData()])
        }
    }, []);

    useEffect(() => {
        if (props.amountCollected) {
            console.log(" props DynamicChart ", props.amountCollected);
            let sAxisData = [], resolution = [];
            for (let i = 0; i < props.amountCollected[2].transactions.length; i++) {
                const tempResolution = props.amountCollected[2].transactions[i];
                console.log(" tempResolution ======= ", tempResolution);
                if (i == 0) {
                    resolution.push(parseFloat(tempResolution).toFixed(2));
                }
                else {
                    resolution.push(parseFloat(parseFloat(tempResolution) + parseFloat(resolution[i - 1])).toFixed(2))
                }

            }
            // console.log(" resolution ", resolution);
            let finalaArray = [];
            for (let index = 0; index < props.amountCollected[0].xAxisData.length; index++) {
                const element = props.amountCollected[0].xAxisData[index];
                // console.log(" element ", new Date(element));
                let tempObj = {
                    name: `'${new Date(element)}'`,
                    value: [props.amountCollected[0].xAxisData[index], resolution[index]]
                }
                finalaArray.push(tempObj);
            }
            // moment(new Date()).format("DD")
            // console.log(" finalaArray ", );
            setData(finalaArray.slice(0,moment(new Date()).format("DD")))
            setResolutionPercentage(finalaArray[parseFloat(moment(new Date()).format("DD"))])
        }
    }, [props.amountCollected])

    // console.log(" data ==== ", data);
    // console.log(" tempData ==== ", tempData);



    let option = {
        title: [
            {
                text: 'Resolution %',
            },
            {
                text: resolutionPercentage ? resolutionPercentage.value[1] : '',
                right: '20px',
            }
            
        ],

        legend: {
            data: [],
            top: '30px',
            left: '20px',
        },
        padding: 30,
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                params = params[0];
                var date = new Date(params.name);
                return (
                    date.getDate() +
                    '/' +
                    (date.getMonth() + 1) +
                    '/' +
                    date.getFullYear() +
                    ' : ' +
                    params.value[1]
                );
            },
            axisPointer: {
                animation: false
            }
        },
        xAxis: {
            type: 'time',
            splitLine: {
                show: false
            },
            show: true
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            splitLine: {
                show: false
            },
            show: true
        },
        series: [
            {
                name: 'Fake Data',
                type: 'line',
                showSymbol: false,
                data: data
            }
        ]
    };




    return (
        <div>
            <div className="report-card-div dynamicChart">
                <div className={`card card-custom card-stretch gutter-b card-div`}>
                    {/* Body */}
                    <div className="card-body  pt-4 position-relative overflow-hidden">
                        {/* Title */}
                        {/* <div
                            id={`bar_chart`}
                            className="card-rounded-bottom"
                            style={{ height: "150px" }}>
                        </div> */}
                        <ReactECharts option={option} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(DynamicChart);