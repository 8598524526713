import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import millify from "millify";
import { injectIntl } from "react-intl";
import Moment from "react-moment";
import moment from "moment";

import { useHtmlClassService } from "../../../../_metronic/layout";
import "../css/financier_card.scss";

function FinancierStatusCard(props) {
    if(props.financiersStatusData.monthEndDateType==="Customized Date") {
        console.log(" props.financiersStatusData ", props.financiersStatusData);
    }
    
  const currentMonthProps = moment(props.financiersStatusData.monthEndDate).format("YYYY-MM-DD");

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  var date = new Date();
  var monthLastDay = formatDate(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  const propsEndDate = props.endDate;

  function getMonthDifference(startDateMonth, endDateMonth) {
    return (
      endDateMonth.getMonth() -
      startDateMonth.getMonth() +
      12 * (endDateMonth.getFullYear() - startDateMonth.getFullYear())
    );
  }

  // let todayDate = new Date();
  let todayDate = moment(new Date()).format("YYYY-MM-DD");
  console.log("this  is today date:", todayDate);

  const daysBetween = getMonthDifference(
    new Date(propsEndDate),
    new Date(todayDate)
  );

  console.log("daysBetween--------", daysBetween);

  // .subtract(daysBetween, "months")

  //   function subtractMonths(numOfMonths, date = new Date(currentMonthProps)) {
  //     date.setMonth(date.getMonth() - numOfMonths);

  //     return date;
  //   }

  //  const monthDiff = (subtractMonths(3, currentMonthProps));;

  //   console.log("monthDiff----", monthDiff);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.danger`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.danger`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  let newEndDate = props.endDate;
  // if(props.financiersStatusData.monthEndDate) {
  //     var d = new Date(props.financiersStatusData.monthEndDate),
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();
  //     var d1 = new Date(props.endDate);
  //     newDate = year+"-"+(d1.getMonth() + 1)+"-"+day;

  // }
  console.log("endDateHere------", props.financiersStatusData.monthEndDate);
  console.log(
    "endDateHeretype------",
    props.financiersStatusData.monthEndDateType
  );
  // useEffect(() => {
  //   // console.log(" props.endDate-------- ", props.endDate);
  //   // var d = new Date(props.financiersStatusData.monthEndDate),
  //   // month = "" + (d.getMonth() + 1),
  //   // day = "" + d.getDate(),
  //   // year = d.getFullYear();
  //   // var d1 = new Date(props.endDate);
  //   // var d1 = new Date(props.financiersStatusData.monthEndDate);
  //   // newDate = year+"-"+(d1.getMonth() + 1)+"-"+day;
  //   if(props.endDate){
  //     setEndDate(endDate);
  //   }
  // }, [endDate]);

  // console.log("financiersStatusData---------", moment().endOf('month').format("Do MMM"));

  return (
    <div className="financier-card-div">
      <div
        className={`card card-custom card-stretch gutter-b card-div cardBorder`}
      >
        {/* Header */}
        <div className="row card-header border-0 pl-2 pt-2 pr-0">
          <div className="d-flex col-lg-12 pl-0 pr-0">
            <div className="col-7 ml-1 pr-0">
              <span className="financierTitle font-semibold tracking-wide">
                {" "}
                {props.financiersStatusData.financierName}
              </span>
            </div>
            {props.financiersStatusData.financierName !== "All Financier" && (
              <div className="col-4 pl-0 pr-0 ml-1">
                <div className="monthEndDateLblDiv">
                  <span className="monthEndDateLbl tracking-wide">
                    Month End Date
                  </span>
                </div>
                <div className="monthEndDate">
                  {props.financiersStatusData.monthEndDate &&
                  props.financiersStatusData.monthEndDateType ==="Customized Date"
                    ? moment(currentMonthProps)
                        .subtract(daysBetween, "months")
                        .format("Do MMM")
                    : propsEndDate
                    ? moment(propsEndDate).format("Do MMM")
                    : moment(currentMonthProps)
                        .subtract(daysBetween, "months")
                        .format("Do MMM")}{" "}
                </div>
              </div>
            )}

            {/* {props.financiersStatusData.financierName !== 'All Financier' && (
                            <div className="col-lg-6">
                                {props.financiersStatusData.image !='' && (
                                    <img className="cardHeaderLogo" src={props.financiersStatusData.image} />
                                )}
                                
                            </div>
                        )} */}
          </div>
        </div>

        {/* Body */}
        <div className="card-body padmngspac position-relative overflow-hidden">
          <div>
            {/* <div
                            id={`individual_chart${props.keyForChart}`}
                            className="card-rounded-bottom"
                            style={{ height: "60px", minHeight: "60px" }} >

                        </div> */}

            {props.financiersStatusData.financierName == "All Financier" ? (
              <div className="card-spacer">
                <div className="row">
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColorAllFinancier shadowEffect">
                      <span className="descriptionTitleAll tracking-wide">
                        Total POS Allocation
                      </span>
                      <span className="amountTxt">
                        {millify(
                          props && props.financiersStatusData.pos
                            ? props.financiersStatusData.pos
                            : "0",
                          { space: true }
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColorAllFinancier shadowEffect">
                      <span className="descriptionTitleAll tracking-wide">
                        Resolution Percentage
                      </span>
                      <span className="amountTxt">
                        {props &&
                        props.financiersStatusData.resolutionPercentage
                          ? props.financiersStatusData.resolutionPercentage
                          : "0"}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColorAllFinancier shadowEffect">
                      <span className="descriptionTitleAll tracking-wide">
                        Total Collection
                      </span>
                      <span className="amountTxt">
                        {millify(
                          props &&
                            props.financiersStatusData.totalAmountCollected
                            ? props.financiersStatusData.totalAmountCollected
                            : "0",
                          { space: true }
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColorAllFinancier shadowEffect">
                      <span className="descriptionTitleAll tracking-wide">
                        Total PTP Stock
                      </span>
                      <span className="amountTxt">
                        {millify(
                          props && props.financiersStatusData.totalPTPStock
                            ? props.financiersStatusData.totalPTPStock
                            : "0",
                          { space: true }
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColorAllFinancier shadowEffect">
                      <span className="descriptionTitleAll tracking-wide">
                        Non Contactable
                      </span>
                      <span className="amountTxt">
                        {millify(
                          props && props.financiersStatusData.nonContatable
                            ? props.financiersStatusData.nonContatable
                            : "0",
                          { space: true }
                        )}
                      </span>
                      {/* <span className="">{props.financiersStatusData.nonContactablePercentage}</span> */}
                    </button>
                  </div>
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColorAllFinancier shadowEffect">
                      <span className="descriptionTitleAll tracking-wide">
                        Expected Collection
                      </span>
                      <span className="amountTxt">
                        {millify(
                          props && props.financiersStatusData.expectedCollection
                            ? props.financiersStatusData.expectedCollection
                            : "0",
                          { space: true }
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-spacer">
                <div className="row">
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColor shadowEffect">
                      <span className="descriptionTitle tracking-wide">
                        Total POS Allocation
                      </span>
                      <span className="amountTxt">
                        {millify(
                          props && props.financiersStatusData.pos
                            ? props.financiersStatusData.pos
                            : "0",
                          { space: true }
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColor shadowEffect">
                      <span className="descriptionTitle tracking-wide">
                        Resolution Percentage
                      </span>
                      <span className="amountTxt">
                        {props &&
                        props.financiersStatusData.resolutionPercentage
                          ? parseFloat(
                              props.financiersStatusData.resolutionPercentage
                            ).toFixed(2)
                          : "0"}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColor shadowEffect">
                      <span className="descriptionTitle tracking-wide">
                        Total Collection
                      </span>
                      <span className="amountTxt">
                        {millify(
                          props &&
                            props.financiersStatusData.totalAmountCollected
                            ? props.financiersStatusData.totalAmountCollected
                            : "0",
                          { space: true }
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColor shadowEffect">
                      <span className="descriptionTitle tracking-wide">
                        Total PTP Stock
                      </span>
                      <span className="amountTxt">
                        {millify(
                          props && props.financiersStatusData.totalPTPStock
                            ? props.financiersStatusData.totalPTPStock
                            : "0",
                          { space: true }
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColor shadowEffect">
                      <span className="descriptionTitle tracking-wide">
                        Non Contactable
                      </span>
                      <span className="amountTxt">
                        {millify(
                          props && props.financiersStatusData.nonContatable
                            ? props.financiersStatusData.nonContatable
                            : "0",
                          { space: true }
                        )}
                      </span>
                      {/* <span className="">{props.financiersStatusData.nonContactablePercentage}</span> */}
                    </button>
                  </div>
                  <div className="col-lg-4 p-2">
                    <button className="font-weight-bold font-size-h6 rounded-lg report-btn backColor shadowEffect">
                      <span className="descriptionTitle tracking-wide">
                        Expected Collection
                      </span>
                      <span className="amountTxt">
                        {millify(
                          props && props.financiersStatusData.expectedCollection
                            ? props.financiersStatusData.expectedCollection
                            : "0",
                          { space: true }
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Resize */}
          <div className="resize-triggers">
            <div className="expand-trigger">
              <div style={{ width: "411px", height: "461px" }} />
            </div>
            <div className="contract-trigger" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(FinancierStatusCard);

function getChartOption(props, layoutProps) {
  if (props.financiersStatusData.financierName == "All Financier") {
    //console.log('check financier status graph layoutProps:', layoutProps);
    layoutProps.colorsThemeBaseColor = "#5adb13";
    layoutProps.colorsThemeLightColor = "#dffbd0";
  } else {
    layoutProps.colorsThemeBaseColor = "#db1313";
    layoutProps.colorsThemeLightColor = "#fbd0d0";
  }

  const options = {
    series: [
      {
        name: "Net Profit",
        data: [40, 40, 30, 30, 35, 35, 50],
      },
    ],
    chart: {
      type: "area",
      height: 60,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0.55,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.2,
        stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseColor],
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Aug", "Sep"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 55,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return val + " thousands"; //"$" + val + " thousands";
        },
      },
    },
    colors: [layoutProps.colorsThemeLightColor],
    markers: {
      colors: [layoutProps.colorsThemeLightColor],
      strokeColor: [layoutProps.colorsThemeBaseSuccess],
      strokeWidth: 3,
    },
  };
  return options;
}
