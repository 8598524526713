import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import millify from 'millify';
import "../../Loan/css/loan.scss";
import "../../Dashboard/css/bucketWiseTable.scss";

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import LoadingOverlay from 'react-loading-overlay';
import Alert from 'react-bootstrap/Alert';

//import makeData from "./../../utils/makeData";

import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
//import { toAbsoluteUrl } from "../../../../layout/_metronic/_helpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { financierWisePerformanceClearMessages, financierWisePerformance } from "../../../store/dashboard";


function CityWiseLoanFinancier(props) {
    // console.log('CityWiseLoanFinancier props:', props)
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    //const [pageLoader, setPageLoader] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.entities.auth.data);
    //let financierWisePerformanceData = useSelector((state) => state.entities.loan.citiesWiseLoan.data.loan);
    //console.log("financierWisePerformanceData table Data:", financierWisePerformanceData);

    let citywiseData = useSelector((state) => state.entities.loan.citiesWiseLoan.data.loan);
    //console.log('financierWisePerformance:', citywiseData);

    const columns = React.useMemo(
        () => [
            {
                Header: <div style={{ textAlign: "center" }}>financier Name</div>,
                accessor: 'financierName',
                // accessor: (value) => {
                //   return <div style={{ textAlign: 'left', width: '160px' }}>{value.financierName}</div>;
                // },
                Footer: <div className="mt-4 tblFooterColor text-left">Total</div>,
                disableFilters: true,
            },
            {
                Header: 'Total Cases',
                accessor: (value) => (millify(value.totalCases ? value.totalCases : 0, { space: true })),
                id: 'totalCases',
                Footer: value => {
                    //console.log('value:', value);
                    let total = 0;
                    let financierWisePerformanceData = useSelector((state) => state.entities.loan.citiesWiseLoan.data.loan);
                    if (props && props.rowData && financierWisePerformanceData) {
                        financierWisePerformanceData.map((sum) => {
                            sum.subRows.map((sumItem) => {
                                if (props.rowData.city == sum.city) {
                                    total += sumItem.totalCases;
                                }
                            })

                        })
                    }
                    console.log('total:', total);
                    return <><div className="mt-4 tblFooterColor">{total !== 0 ? millify(total, { space: true }) : total}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: 'POS',
                accessor: (value) => (millify(value.pos ? value.pos : 0, { space: true })),
                Footer: value => {
                    let total = 0;
                    let financierWisePerformanceData = useSelector((state) => state.entities.loan.citiesWiseLoan.data.loan);
                    if (props && props.rowData && financierWisePerformanceData) {
                        financierWisePerformanceData.map((sum) => {
                            sum.subRows.map((sumItem) => {
                                if (props.rowData.city == sum.city) {
                                    total += sumItem.pos;
                                }
                            })

                        })
                    }
                    return <><div className="mt-4 tblFooterColor">{total !== 0 ? millify(total, { space: true }) : total}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: 'dues',
                accessor: (value) => (millify(value.dues ? value.dues : 0, { space: true })),
                Footer: value => {
                    let total = 0;
                    let financierWisePerformanceData = useSelector((state) => state.entities.loan.citiesWiseLoan.data.loan);
                    if (props && props.rowData && financierWisePerformanceData) {
                        financierWisePerformanceData.map((sum) => {
                            sum.subRows.map((sumItem) => {
                                if (props.rowData.city == sum.city) {
                                    total += sumItem.dues;
                                }
                            })

                        })
                    }
                    return <><div className="mt-4 tblFooterColor">{total !== 0 ? millify(total, { space: true }) : total}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: 'Collection',
                accessor: (value) => (millify(value.amountCollected ? value.amountCollected : 0, { space: true })),
                Footer: value => {
                    let total = 0;
                    let financierWisePerformanceData = useSelector((state) => state.entities.loan.citiesWiseLoan.data.loan);
                    if (props && props.rowData && financierWisePerformanceData) {
                        financierWisePerformanceData.map((sum) => {
                            sum.subRows.map((sumItem) => {
                                if (props.rowData.city == sum.city) {
                                    total += sumItem.amountCollected;
                                }
                            })

                        })
                    }
                    return <><div className="mt-4 tblFooterColor">{total !== 0 ? millify(total, { space: true }) : total}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: <div><div>Resolution</div><div>(%)</div></div>,
                id: 'resolution',
                accessor: (value) => {
                    return (value.resolution).toFixed(2);
                },
                Footer: value => {
                    const total = React.useMemo(
                        () => value.rows.reduce((sum, row) => (parseFloat(row.values.resolution)) + sum, 0),
                        [value.rows]
                    );

                    return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: <div><div>PTP</div><div>(%)</div></div>,
                id: 'ptp',
                accessor: (value) => {
                    return (value.ptp).toFixed(2);
                },
                Footer: value => {
                    const total = React.useMemo(
                        () => value.rows.reduce((sum, row) => (parseFloat(row.values.ptp)) + sum, 0),
                        [value.rows]
                    );
                    return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: <div><div>BPTP</div><div>(%)</div></div>,
                id: 'bptp',
                accessor: (value) => {
                    return (value.bptp).toFixed(2);
                },
                Footer: value => {
                    const total = React.useMemo(
                        () => value.rows.reduce((sum, row) => (parseFloat(row.values.bptp)) + sum, 0),
                        [value.rows]
                    );

                    return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: <div><div>pending</div><div>(%)</div></div>,
                id: 'pending',
                accessor: (value) => {
                    return (value.pending).toFixed(2);
                },
                Footer: value => {
                    const total = React.useMemo(
                        () => value.rows.reduce((sum, row) => (parseFloat(row.values.pending)) + sum, 0),
                        [value.rows]
                    );

                    return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: <div><div>nc</div><div>(%)</div></div>,
                id: 'nc',
                accessor: (value) => {
                    return (value.nc).toFixed(2);
                },
                Footer: value => {
                    const total = React.useMemo(
                        () => value.rows.reduce((sum, row) => (parseFloat(row.values.nc)) + sum, 0),
                        [value.rows]
                    );

                    return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: <div><div>anf</div><div>(%)</div></div>,
                id: 'anf',
                accessor: (value) => {
                    return (value.anf).toFixed(2);
                },
                Footer: value => {
                    const total = React.useMemo(
                        () => value.rows.reduce((sum, row) => (parseFloat(row.values.anf)) + sum, 0),
                        [value.rows]
                    );

                    return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: <div><div>sfd</div><div>(%)</div></div>,
                id: 'sfd',
                accessor: (value) => {
                    return (value.sfd).toFixed(2);
                },
                Footer: value => {
                    const total = React.useMemo(
                        () => value.rows.reduce((sum, row) => (parseFloat(row.values.sfd)) + sum, 0),
                        [value.rows]
                    );

                    return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: <div><div>cb</div><div>(%)</div></div>,
                id: 'cb',
                accessor: (value) => {
                    return (value.cb).toFixed(2);
                },
                Footer: value => {
                    const total = React.useMemo(
                        () => value.rows.reduce((sum, row) => (parseFloat(row.values.cb)) + sum, 0),
                        [value.rows]
                    );

                    return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: <div><div>lm</div><div>(%)</div></div>,
                id: 'lm',
                accessor: (value) => {
                    return (value.lm).toFixed(2);
                },
                Footer: value => {
                    const total = React.useMemo(
                        () => value.rows.reduce((sum, row) => (parseFloat(row.values.lm)) + sum, 0),
                        [value.rows]
                    );

                    return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
                },
                disableFilters: true,
            },
            {
                Header: <div><div>dl</div><div>(%)</div></div>,
                id: 'dl',
                accessor: (value) => {
                    return (value.dl).toFixed(2);
                },
                Footer: value => {
                    const total = React.useMemo(
                        () => value.rows.reduce((sum, row) => (parseFloat(row.values.dl)) + sum, 0),
                        [value.rows]
                    );

                    return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
                },
                disableFilters: true,
            },

        ],
        []
    )

    let data;
    if (props && props.cityWiseLoanFinancierData) {
        data = props.cityWiseLoanFinancierData;
        //console.log('cityWiseLoanFinancierData:', data);
    }
    //console.log("financier wise data:", data);

    // const refreshFinancierWiseInfo = () => {
    //     dispatch(financierWisePerformance(user.userId, user.agencyId));
    // }

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    // const searchFinancierWisePerformance = () => {
    //     let formateStartDate = formatDate(startDate);
    //     let formateEndDate = formatDate(endDate);
    //     console.log('start date and end date :', formateStartDate, formateEndDate);
    //     if (formateStartDate && formateEndDate) {
    //         dispatch(financierWisePerformance(user.userId, user.agencyId, formateStartDate, formateEndDate));
    //     }
    // }

    return (
        <div className="logs-card-div">
            {/* <LoadingOverlay
                active={loading}
                spinner
                text="Loading data..."
                className="loading-spinner-custom-color"
                classNamePrefix='dashboard-page-loader-'
                styles={{
                    wrapper: {
                        overflow: loading ? 'hidden' : 'hidden'
                    },
                    spinner: (base) => ({
                        ...base,
                        width: '75px'
                    })
                }}
            > */}
            <Card className="finacierWise w-100 tblExpandedMarginBorder">
                <CardHeader>
                    <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-start flex-column">
                        <h3 className="mb-0">Financier List</h3>
                        {/* <span className="subHeader">Long term loan performance financier wise</span> */}
                    </div>
                    <div className="col-lg-4 mt-4 d-block">
                        {showErrorAlert &&
                            <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {errorAlertMsg}
                            </Alert>
                        }

                        {showSuccessAlert &&
                            <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                                {successAlertMsg}
                            </Alert>
                        }
                    </div>
                    {/* <div className="col-lg-3 pr-0 d-flex justify-content-end align-items-center">
                        <div className="col-lg-2 pl-0">
                            <div className=" d-flex justify-content-center align-items-center">
                                <button className="defaultBtn transformBtn"
                                    onClick={refreshFinancierWiseInfo}
                                >
                                    <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-2 pl-0">
                            {data && (
                                <button
                                    id="CSV"
                                    type="button"
                                    className="settingBtn font-weight-bold download-csv transformBtn">
                                    <CSVLink
                                        data={data}
                                        filename={"FinancierWisePerformance.csv"}
                                        target="_blank"
                                    >
                                        <span className="svg-icon svgSetting">
                                            <SVG className="svgSetting"
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/csv-file-format-extension.svg"
                                                )}
                                            ></SVG>
                                        </span>
                                    </CSVLink>
                                </button>
                            )}

                        </div>
                    </div> */}
                </CardHeader>
                {/* <CardHeader>
                        <div className="col-lg-8 pl-0 d-flex justify-content-start align-items-center">
                            <div className="col-lg-4 pl-0 d-flex justify-content-center align-items-center">
                                <DatePicker
                                    onChange={value => {

                                        let formatedStartDate = formatDate(value);
                                        if (formatedStartDate) {
                                            setStartDate(new Date(formatedStartDate));
                                        }


                                        //formikProps.setFieldValue("startDate", value)}
                                    }}
                                    value={startDate}
                                    name="startDate"
                                    // minDate={new Date()}
                                    format="dd-MM-y"
                                    className="form-control form-control-solid h-auto readOnlySpan"
                                    clearIcon={null}
                                    calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                                />
                            </div>
                            <div className="col-lg-4 pl-0 d-flex justify-content-center align-items-center">
                                <DatePicker
                                    onChange={value => {

                                        let formatedEndDate = formatDate(value);
                                        if (formatedEndDate) {
                                            setEndDate(new Date(formatedEndDate));
                                        }

                                        // if (endDate) {
                                        //     console.log('end date:', endDate);
                                        // }
                                        //formikProps.setFieldValue("endDate", value)}
                                    }}
                                    value={endDate}
                                    name="endDate"
                                    //minDate={new Date(startDate)}
                                    format="dd-MM-y"
                                    className="form-control form-control-solid h-auto readOnlySpan"
                                    clearIcon={null}
                                    calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                                />
                            </div>
                            <div className="col-lg-2">
                                <button
                                    id="button"
                                    type="button"
                                    className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                                    // disabled={isEmailUnique}
                                    onClick={searchFinancierWisePerformance}
                                >
                                    Search
                                </button>
                            </div>
                        </div>

                    </CardHeader> */}
                <CardBody className="p-4">
                    {data && (
                        <Table columns={columns} data={data} />
                    )}
                </CardBody>
            </Card>
            {/* </LoadingOverlay> */}
        </div>
    );
}

export default CityWiseLoanFinancier;


// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
            Search:{' '}
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                className="searchFilter"
            />
        </span>
    )
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <div>
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value.toString().toLowerCase() || undefined) // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        //state,
        visibleColumns,
        preGlobalFilteredRows,
        //setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10 }
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,//sort data
        usePagination,//use pagination
    )

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    //const firstPageRows = rows.slice(0, 10);


    return (
        <>
            <div>
                <div className="tblScroll">
                    <table {...getTableProps()} className="loanTable financierWiseTable">
                        <thead className="tableHead">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? '↓'
                                                        : '↑'
                                                    : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            <tr>
                                <th
                                    colSpan={visibleColumns.length}
                                    style={{
                                        textAlign: 'left',
                                    }}
                                >
                                    {/* <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              /> */}
                                </th>
                            </tr>
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} className="tableTd">{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            {footerGroups.map(group => (
                                <tr {...group.getFooterGroupProps()}>
                                    {group.headers.map(column => (
                                        <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                    ))}
                                </tr>
                            ))}
                        </tfoot>
                    </table>
                </div>
                {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
                <div className="row m-0">
                    <div className="pagination col-lg-12">
                        <div className="col-lg-2">
                            <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>{' '}
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Total Records{' '}
                                <strong>
                                    {data.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-lg-3">
                            <span>
                                Go to page{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(page)
                                    }}
                                    className="gotoPage"
                                />
                            </span>{' '}
                        </div>
                        <div className="col-lg-3">
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                className="selectDisplayNoOfRecords"
                            >
                                {[10, 20, 30].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>


                <br />
            </div>
        </>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

