import React, {useEffect, useMemo } from "react";
//import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
//import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { 
   // FormattedMessage, 
    injectIntl } from "react-intl";
//import { useHistory } from "react-router-dom";
//import { Dropdown } from "react-bootstrap";
//import { DropdownMenu2 } from "../../../../_metronic/_partials/dropdowns";
import { useHtmlClassService } from "../../../../_metronic/layout";
//import '../css/report_card.scss';


function AreaChart_old(props) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseColor: objectPath.get(
                uiService.config,
                `js.colors.theme.base.danger`
            ),
            colorsThemeLightColor: objectPath.get(
                uiService.config,
                `js.colors.theme.light.danger`
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);

    useEffect(() => {
        const element = document.getElementById(`area_chart`);

        if (!element) {
            return;
        }

        const options = getChartOption(layoutProps);
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps]);



    return (
        <div>
            <div className="report-card-div">
                <div className={`card card-custom card-stretch gutter-b card-div`}>
                    {/* Body */}
                    <div className="card-body position-relative overflow-hidden">
                        {/* Title */}
                        <div
                            id={`area_chart`}
                            className="card-rounded-bottom"
                            style={{ height: "150px" }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(AreaChart_old);

function getChartOption(layoutProps) {
    const options = {
        series: [
            {
                name: "STOCK ABC",
                data: [10, 41, 35, 51, 49, 62, 69, 91,10, 41, 35, 51, 49, 62, 69, 91]
            },
        ],
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },

        title: {
            text: 'Amount Collected (Rs. Lacs)',
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        labels: ['15 April','16 April','17 April','18 April','19 April','20 April','21 April','22 April', '23 April', '24 April', '25 April', '26 April', '27 April', '28 April', '29 April'],
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: 'left'
        }

    };
    return options;
}

