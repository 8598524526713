import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { original } from "immer";

const initialState = {
  data: null,
  loading: false,
  lastFetch: null,
  message: null,
  status: undefined,
  error: {},
  acceptList: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  updateStatus: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  userWiseData: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  statusManagement: {
    status: [],
    rowData: null,
  },
};

const slice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    getAttendanceListRequested: (attendance, action) => {
      attendance.loading = true;
      attendance.message = null;
      attendance.status = undefined;
      attendance.error = {};
    },
    getAttendanceListReceived: (attendance, action) => {
      attendance.data = action.payload.data;
      // console.log('payload data here =========', action.payload.data);
      attendance.loading = false;
      attendance.lastFetch = Date.now();
      attendance.message = null;
      attendance.status = "success";
      attendance.error = {};
    },
    getAttendanceListRequestFailed: (attendance, action) => {
      attendance.loading = false;
      attendance.message = null;
      attendance.status = undefined;
      attendance.error =
        action.payload.response !== null ||
        action.payload.response !== undefined
          ? action.payload.response.data
          : action.payload.message;
    },

    acceptListRequested: (attendance, action) => {
      attendance.acceptList.loading = true;
      attendance.acceptList.message = null;
      attendance.acceptList.status = undefined;
      attendance.acceptList.error = {};
    },
    acceptListReceived: (attendance, action) => {
      attendance.acceptList.data = action.payload.data;
      // console.log('acceptListReceived data here =========', action.payload.data);
      attendance.acceptList.loading = false;
      attendance.acceptList.lastFetch = Date.now();
      attendance.acceptList.message = null;
      attendance.acceptList.status = "success";
      attendance.acceptList.error = {};
    },
    acceptListRequestFailed: (attendance, action) => {
      attendance.acceptList.loading = false;
      attendance.acceptList.message = null;
      attendance.acceptList.status = undefined;
      attendance.acceptList.error = "Error";
    },

    updateStatusRequested: (attendance, action) => {
      attendance.updateStatus.loading = true;
      attendance.updateStatus.message = null;
      attendance.updateStatus.status = undefined;
      attendance.updateStatus.error = {};
    },
    updateStatusReceived: (attendance, action) => {
      attendance.updateStatus.data = action.payload.data;
      // console.log('payload data here =========', action.payload.data);
      attendance.updateStatus.loading = false;
      attendance.updateStatus.lastFetch = Date.now();
      attendance.updateStatus.message = null;
      attendance.updateStatus.status = "success";
      attendance.updateStatus.error = {};
    },
    updateStatusRequestFailed: (attendance, action) => {
      attendance.updateStatus.loading = false;
      attendance.updateStatus.message = null;
      attendance.updateStatus.status = undefined;
      attendance.updateStatus.error = "Error";
    },

    userWiseDataRequested: (attendance, action) => {
      attendance.userWiseData.loading = true;
      attendance.userWiseData.message = null;
      attendance.userWiseData.status = undefined;
      attendance.userWiseData.error = {};
    },
    userWiseDataReceived: (attendance, action) => {
      console.log("payload data here =========", action.payload.data);
      attendance.userWiseData.data = action.payload.data;
      attendance.userWiseData.loading = false;
      attendance.userWiseData.lastFetch = Date.now();
      attendance.userWiseData.message = null;
      attendance.userWiseData.status = "success";
      attendance.userWiseData.error = {};
    },
    userWiseDataRequestFailed: (attendance, action) => {
      attendance.userWiseData.loading = false;
      attendance.userWiseData.message = null;
      attendance.userWiseData.status = undefined;
      attendance.userWiseData.error = "Error";
    },

    clearMessagesRequested: (attendance) => {
      attendance.status = undefined;
      attendance.message = null;
      attendance.error = {};
    },
    clearUserListStorageRequested: () => initialState,

    currentSelectedRow: (attendance, action) => {
      attendance.statusManagement.rowData = action.payload;
    },

    statusChange: (attendance, action) => {
      let lastStatusData = original(attendance.statusManagement.status);
      console.log(" lastStatusData ", lastStatusData);
      console.log(" action ", action.payload);
      attendance.statusManagement.status = action.payload;
      // statusManagement.status.push(action.payload);
      // attendance.statusManagement.status = action.payload;
    },
  },
});

const {
  getAttendanceListRequested,
  getAttendanceListReceived,
  getAttendanceListRequestFailed,

  acceptListRequested,
  acceptListReceived,
  acceptListRequestFailed,

  updateStatusRequested,
  updateStatusReceived,
  updateStatusRequestFailed,

  userWiseDataRequested,
  userWiseDataReceived,
  userWiseDataRequestFailed,

  clearMessagesRequested,
  clearUserListStorageRequested,

  currentSelectedRow,
  statusChange,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const getAttendanceList = (userId, date) => (dispatch, getState) => {
  console.log("Invoking Attendance Panel Api", userId, date);
  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: {
        feature: "ATTENDANCE_DASHBOARD",
        userId: userId,
        date: date,
      },
      onStart: getAttendanceListRequested.type,
      onSuccess: getAttendanceListReceived.type,
      onError: getAttendanceListRequestFailed.type,
    })
  );
};

export const acceptList = (userId, startDate, endDate, agentId) => (
  dispatch,
  getState
) => {
  console.log(
    "Invoking acceptList Panel Api",
    userId,
    startDate,
    endDate,
    agentId
  );
  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: {
        feature: "GET_ATTENDANCE",
        userId: userId,
        startDate: startDate,
        endDate: endDate,
        agentId: agentId,
      },
      onStart: acceptListRequested.type,
      onSuccess: acceptListReceived.type,
      onError: acceptListRequestFailed.type,
    })
  );
};

export const updateStatus = (userId, date, status, remark, approvalStatus) => (
  dispatch,
  getState
) => {
  console.log("Invoking Attendance Panel Api", userId);
  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: {
        feature: "UPDATE_STATUS_ATTENDANCE",
        status: status,
        userId: userId,
        date: date,
        remark: remark,
        approvalStatus: approvalStatus,
      },
      onStart: updateStatusRequested.type,
      onSuccess: updateStatusReceived.type,
      onError: updateStatusRequestFailed.type,
    })
  );
};

export const userWiseData = (userId, startDate) => (dispatch, getState) => {
  console.log("Invoking userWiseData Panel Api", userId, startDate);
  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: {
        feature: "ATTENDANCE_DASHBOARD_USER_WISE",
        userId: userId,
        startDate: startDate,
      },
      onStart: userWiseDataRequested.type,
      onSuccess: userWiseDataReceived.type,
      onError: userWiseDataRequestFailed.type,
    })
  );
};

export const statusManagement = (type, row) => (dispatch, getState) => {
  // console.log("Invoking userWiseData Panel Api", status);
  if (type == "changeStatus") {
    console.log(" row ===== ", row);
    dispatch(statusChange(row));
  } else {
    dispatch(currentSelectedRow(row));
  }

  // dispatch(
  //     apiCallBegan({
  //         url: "/api/v1/service",
  //         method: "post",
  //         data: {
  //             feature: 'ATTENDANCE_DASHBOARD_USER_WISE',
  //             userId: userId,
  //             startDate: startDate
  //         },
  //         onStart: userWiseDataRequested.type,
  //         onSuccess: userWiseDataReceived.type,
  //         onError: userWiseDataRequestFailed.type,
  //     })
  // );
};

export const clearMessages = () => (dispatch) => {
  console.log("in clearMessages...");
  dispatch(clearMessagesRequested());
};

export const clearUserListStorage = () => (dispatch) => {
  console.log("in clearUserListStorageRequested...");
  dispatch(clearUserListStorageRequested());
};
