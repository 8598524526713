import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../Loader/Loader";
import { useSticky } from "react-table-sticky";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useBlockLayout,
  usePagination,
  useSortBy,
} from "react-table";
// import makeData from "./MakeData";
import matchSorter from "match-sorter";
import { bioAttendanceList } from "../../store/biometric";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import DatePicker from "react-date-picker";
import Attendance_modal from "./Attendance_modal";
import Attendance_Drop from "./Attendance_Drop";
import moment from "moment";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap/Table";
import { dateTimeFormat } from "../../utils/helperFunctions";
import getApiData from "../../utils/utils";
import LoaderPop from "../Loader/LoaderPop";

const Styles = styled.div`
  padding: 1rem;

  .table {
    border: 1px solid #ddd;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;
      vertical-align: middle;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 0px 0px 0px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;
let modalStartDate = null,
  modalEndDate = null;
export default function Biometric_Attendance() {
  const dispatch = useDispatch();

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const user = useSelector((state) => state.entities.auth.data);

  var date = new Date();
  // var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var firstDay = formatDate(new Date());
  var lastDay = formatDate(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  //   modalStartDate = firstDay;
  const [startDate, setStartDate] = useState(new Date(firstDay));
  const [endDate, setEndDate] = useState(new Date(firstDay));
  // const [modalStartDate, setmodalStartDate] = useState(new Date());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeUserId, setEmployeeUserId] = useState();
  const [employeeFullName, setEmployeeFullName] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [biometricCsvData, setBiometricCsvData] = useState();
  const [biometricPersonCsv,setBiometricPersonCsv] = useState();

  // console.log("globalFilter----",globalFilter);

  var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var lastDay = formatDate(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );

  useEffect(() => {
    modalStartDate = startDate;
    modalEndDate = endDate;
  }, [startDate, endDate]);

  async function modalDetails(original) {
    setEmployeeUserId(original.userId);
    setEmployeeFullName(original.fullName);
    setModalIsOpen(true);
    const data = {
      feature: "BIOMETRIC",
      featureType: "BIOMETRIC_USER_WISE_DATA",
      startDate: formatDate(modalStartDate),
      endDate: formatDate(modalEndDate),
      employeeId: original.userId,
      userId: user.userId,
    };
    setIsLoading(true);
    let cusDataTemp = await getApiData(data);
    setIsLoading(false);
    setTableData(cusDataTemp);
    // console.log("data-----", cusDataTemp);
  }

  const bioAttendanceData = useSelector(
    (state) => state.entities.biometric.bioAttendanceList.data
  );
  const loading = useSelector(
    (state) => state.entities.biometric.bioAttendanceList.loading
  );
  console.log("bioAttendanceData data here ========", bioAttendanceData);

  useEffect(() => {
    dispatch(
      bioAttendanceList(
        user.userId,
        moment(startDate).format("YYYY-MM-DD"),
        moment(startDate).format("YYYY-MM-DD"),
        employeeName
      )
    );
  }, []);
  let tempStartDate = "";
  let tempEndDate = "";
  // const sortData = financierData? financierData.slice().sort((a, b) => (a.active > b.active ? -1 : 1)): "";
  //   const sortData = bioAttendanceData ? bioAttendanceData.slice().sort((a, b) => (a.arrivalTime < b.arrivalTime ? 1 : -1 )): "";

  //   console.log("sortData---", sortData);

  const searchData = (searchType) => {
    // console.log(" type ===== ", formatDate(startDate), formatDate(endDate));
    tempStartDate = formatDate(startDate);
    tempEndDate = formatDate(endDate);
    if (searchType == "today") {
      let todayDateTemp = new Date();
      todayDateTemp = formatDate(todayDateTemp);
      tempStartDate = formatDate(todayDateTemp);
      tempEndDate = formatDate(todayDateTemp);
      // console.log(" today ", tempStartDate);
    } else if (searchType == "yesterday") {
      var todayDateTemp = new Date();
      todayDateTemp.setDate(todayDateTemp.getDate() - 1);
      todayDateTemp = formatDate(todayDateTemp);
      tempStartDate = formatDate(todayDateTemp);
      tempEndDate = formatDate(todayDateTemp);
      // console.log(" yesterday ", tempStartDate);
    }
    setStartDate(new Date(tempStartDate));
    setEndDate(new Date(tempEndDate));
    let formateStartDate = formatDate(tempStartDate);
    let formateEndDate = formatDate(tempEndDate);
    // console.log("start date and end date :", formateStartDate, formateEndDate);
    if (formateStartDate && formateEndDate) {
      dispatch(
        bioAttendanceList(
          user.userId,
          formateStartDate,
          formateEndDate,
          employeeName
        )
      );
    }
  };

  var EmployeeIdDate = tableData;

  useEffect(() => {
    let tempBiometricPersonCsvData = [];
    if (tableData !== null) {
      console.log("userListtempcsv --------- ", tableData);
      for (let index = 0; index < tableData.length; index++) {
        const tempSingle = tableData[index];
        let newSingleData = {};
        newSingleData.userId = tempSingle.userId;
        newSingleData.fullName = tempSingle.fullName;
        newSingleData.checkInDate = tempSingle.checkInDate.split("T")[0];
        // newSingleData.check_In_Date = tempSingle.checkInDateTime ? tempSingle.checkInDateTime.split("T")[0] : " ";
        // newSingleData.check_Out_Date = tempSingle.checkOutDateTime ? tempSingle.checkOutDateTime.split("T")[0]: " ";
        newSingleData.arrivalTime = tempSingle.arrivalTime;
        newSingleData.departureTime = tempSingle.departureTime;
        newSingleData.workingHours = tempSingle.workingHours;
        newSingleData.shiftStartTime = tempSingle.shiftStartTime;
        newSingleData.shiftEndTime = tempSingle.shiftEndTime;
        newSingleData.day = tempSingle.day;
        newSingleData.overTime = tempSingle.overTime;
        newSingleData.status = tempSingle.status;
        newSingleData.lateMark = tempSingle.lateMark;
        newSingleData.remark = tempSingle.remark;
        // newSingleData.firstCallTime = tempSingle.firstCallTime;
      
        tempBiometricPersonCsvData.push(newSingleData);
      }
      setBiometricPersonCsv(tempBiometricPersonCsvData);
    }
  }, [tableData]);

  // console.log("EmployeeIdDate-----",tableData[0].userId);

  useEffect(() => {
    let tempBiometricCsvData = [];
    if (bioAttendanceData !== null) {
      //   console.log("userListtempcsv --------- ", bioAttendanceData);
      for (let index = 0; index < bioAttendanceData.length; index++) {
        const tempSingle = bioAttendanceData[index];
        let newSingleData = {};
        // console.log(" tempSingle ----- ", tempSingle.checkInDateTime.split("T")[0], tempSingle.checkInDateTime.split("T")[1]);
        newSingleData.userId = tempSingle.userId;
        newSingleData.fullName = tempSingle.fullName;
        newSingleData.checkInDate = tempSingle.checkInDate.split("T")[0];
        // newSingleData.check_In_Date = tempSingle.checkInDateTime ? tempSingle.checkInDateTime.split("T")[0] : " ";
        // newSingleData.check_Out_Date = tempSingle.checkOutDateTime ? tempSingle.checkOutDateTime.split("T")[0]: " ";
        newSingleData.arrivalTime = tempSingle.arrivalTime;
        newSingleData.departureTime = tempSingle.departureTime;
        newSingleData.workingHours = tempSingle.workingHours;
        newSingleData.shiftStartTime = tempSingle.shiftStartTime;
        newSingleData.shiftEndTime = tempSingle.shiftEndTime;
        newSingleData.day = tempSingle.day;
        newSingleData.overTime = tempSingle.overTime;
        newSingleData.status = tempSingle.status;
        newSingleData.lateMark = tempSingle.lateMark;
        newSingleData.remark = tempSingle.remark;
        newSingleData.firstCallTime = tempSingle.firstCallTime;
        // newSingleData.shiftEndTime = tempSingle.shiftEndTime;
        // newSingleData.showInBiometric = tempSingle.showInBiometric;
        // newSingleData.bankAccount = tempSingle.bankAccount;
        // newSingleData.bankBranchName = tempSingle.bankBranchName;
        // newSingleData.bankIfscCode = tempSingle.bankIfscCode;
        // newSingleData.alternateNumber = tempSingle.alternateNumber;
        // newSingleData.dateOfBirth = tempSingle.dateOfBirth;
        // newSingleData.adharNumber = tempSingle.adharNumber;
        // newSingleData.panCardNumber = tempSingle.panCardNumber;
        // newSingleData.employeeStatus = tempSingle.employeeStatus;
        // newSingleData.process = tempSingle.process;
        // newSingleData.interviewDate = tempSingle.interviewDate;
        // newSingleData.interviewerName = tempSingle.interviewerName;
        // newSingleData.source = tempSingle.source;
        // newSingleData.draNonDra = tempSingle.draNonDra;
        // newSingleData.salary = tempSingle.salary;
        // newSingleData.higherEducation = tempSingle.higherEducation;
        // newSingleData.workExperience = tempSingle.workExperience;

        tempBiometricCsvData.push(newSingleData);
      }
      setBiometricCsvData(tempBiometricCsvData);
      //   console.log(" tempBiometricCsvData --------- ", tempBiometricCsvData);
    }
  }, [bioAttendanceData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Sr. No.",
        sticky: "left",
        width: 100,
        accessor: "#",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv1">
                <span>{cell.row.index + 1}</span>
              </div>
            </>
          );
        },
        filter: "fuzzyText",
        disableFilters: true,
      },
      {
        Header: "Employee Name",
        sticky: "left",
        width: 300,
        accessor: "fullName",
        Cell: ({ cell }) => {
          // console.log(" cell ", cell.row.original.userId);
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell.row.original.fullName}
                  onClick={() => modalDetails(cell.row.original)}
                >
                  {" "}
                  {cell.row.original.fullName}
                </button>
              </div>
            </>
          );
        },
      },
      // {
      //   Header: "Employee Code",
      //   accessor: "userId",
      // },
      {
        Header: "Date",
        accessor: "checkInDate",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv1">
                <span>{formatDate(cell.row.original.checkInDate)}</span>
              </div>
            </>
          );
        },
      },
      // {
      //   Header: "Day",
      //   accessor: "day",
      // },
      // {
      //   Header: "Shift",
      //   accessor: "shiftCode",
      // },
      // {
      //   Header: "Shift Time",
      //   accessor: "shiftStartTime",
      // },
      {
        Header: "ArrTim",
        accessor: "arrivalTime",
        // sortMethod: (a, b) => {
        //   var a1 = new Date(a).getTime();
        //   var b1 = new Date(b).getTime();
        // if(a1<b1)
        // return 1;
        // else if(a1<b1)
        // return -1;
        // else
        // return 0;
        // }
      },
      {
        Header: "First Call Time",
        accessor: "firstCallTime",
      },
      // {
      //   Header: "Time Gap",
      //   accessor: "arrivalTime",
      // },
      {
        Header: "Time Gap",
        accessor: "timeGap",
        // const arrivalTime = new Date(row && row.original.arrivalTime);
        // const firstCallTime = new Date(row && row.original.firstCallTime);
        // const timeGap = arrivalTime.getTime() - firstCallTime.getTime();
        // return timeGap;

        Cell: ({ cell }) => {
          const arrivalTimeString = cell.row.original.arrivalTime;
          const firstCallTimeString = cell.row.original.firstCallTime;

          const referenceDate = new Date(); // Using today's date

          const arrivalTime = new Date(
            referenceDate.toDateString() + " " + arrivalTimeString
          );
          const firstCallTime = new Date(
            referenceDate.toDateString() + " " + firstCallTimeString
          );

          let timeDiff = firstCallTime.getTime() - arrivalTime.getTime();

          if (timeDiff < 0) {
            const midnight = new Date(
              referenceDate.toDateString() + " 00:00:00"
            );
            timeDiff =
              firstCallTime.getTime() -
              midnight.getTime() +
              (arrivalTime.getTime() - midnight.getTime());
          }

          // Convert milliseconds to hours, minutes, seconds
          const hours = Math.floor(timeDiff / 3600000);
          const minutes = Math.floor((timeDiff % 3600000) / 60000);
          const seconds = Math.floor((timeDiff % 60000) / 1000);

          const formattedTime = `${hours
            .toString()
            .padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

          const formattedTimeArray = formattedTime.split(","); // Convert formattedTime to an array

          const formattedTime1 = formattedTimeArray.map((time) =>
            time.replace(/NaN:NaN:NaN/g, "-")
          );
          // console.log("gap**********1234", formattedTime1);

          // if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
          //   console.log("gap**********123", formattedTime);
          //   // if (formattedTime == "NaN:NaN:NaN"){
          //   //   setTimeBlk(" ");
          //   // }
           // }
          return formattedTime1;
        },
        filter: "fuzzyText",
        disableFilters: true,
      },
      {
        Header: "DepTim",
        accessor: "departureTime",
      },

      {
        Header: "WrkHrs",
        accessor: "workingHours",
      },
      // {
      //   Header: "OvTim",
      //   accessor: "overTime",
      // },
      // {
      //   Header: "PresAbs",
      //   accessor: "status",
      // },
      // {
      //   Header: "Remarks",
      //   accessor: "remarks",
      // },
      // {
      //   Header: "Shift Time",
      //   accessor: "shiftStartTime",
      // },
    ],
    []
  );

  useEffect(() => {
    function handleEscapeKey(event) {
      console.log("  event.code  ", event.code);
      if (event.code === "Escape") {
        setModalIsOpen(false);
        // setShowModal(false);
        // props.hideModal();
      }
      // document.getElementById("Space").blur();
    }
    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, []);

  return (
    <>
      <div className="agentActivityDiv">
        {loading ? <Loader /> : " "}
        {/* <LoadingOverlay
        active={loadingData}
        spinner
        text='Loading...'
        styles={{
          wrapper: {
            overflow: loadingData ? 'hidden' : 'hidden'
          },
          spinner: (base) => ({
            ...base,
            width: '75px',
            '& svg circle': {
              stroke: '#00b1cc'
            }
          })
        }}
      > */}
        <Card>
          <CardHeader>
            <div className="col-lg-3 d-flex justify-content-start align-items-center pl-5">
              <h3 className="pl-0">Biometric Attendance</h3>
            </div>
            <div className="col-lg-2 d-flex justify-content-end align-items-center">
              <div className="col-lg-3 pl-0">
                {biometricCsvData && (
                  <button
                    id="CSV"
                    type="button"
                    className="font-weight-bold download-csv transformBtn acceptDataCss"
                  >
                    <CSVLink
                      data={biometricCsvData}
                      filename={"BiometricAttendance.csv"}
                      target="_blank"
                    >
                      <span className="svg-icon svgSetting">
                        <SVG
                          className="svgSetting"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/csv-file-format-extension.svg"
                          )}
                        ></SVG>
                      </span>
                    </CSVLink>
                  </button>
                )}
              </div>
              {/* <div className="col-lg-3 pl-0">
                                <button className="defaultBtn transformBtn" onClick={refreshAgentActivities}>
                                    <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                                </button>
                            </div> */}
            </div>
          </CardHeader>
          <CardHeader>
            <div className="col-12 pl-3 pt-4 d-flex justify-content-start align-items-center">
              <div className="col-lg-2 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>Employee Name</label>
                  <input
                    className="BiometricGlobalFil"
                    type="text"
                    onChange={(e) => setEmployeeName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-2 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>From date</label>
                  <DatePicker
                    onChange={(value) => {
                      let formatedStartDate = formatDate(value);
                      if (formatedStartDate) {
                        setStartDate(new Date(formatedStartDate));
                      }

                      //formikProps.setFieldValue("startDate", value)}
                    }}
                    value={startDate}
                    name="startDate"
                    // minDate={new Date()}
                    format="dd-MM-y"
                    className="form-control form-control-solid h-auto calmisset readOnlySpan"
                    clearIcon={null}
                    calendarIcon={
                      <div>
                        <i className="flaticon-calendar-1"></i>
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="col-lg-2 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>To date</label>
                  <DatePicker
                    onChange={(value) => {
                      let formatedEndDate = formatDate(value);
                      console.log(" formatedEndDate ", formatedEndDate);
                      if (formatedEndDate) {
                        setEndDate(new Date(formatedEndDate));
                      }
                    }}
                    value={endDate}
                    name="endDate"
                    minDate={new Date(startDate)}
                    format="dd-MM-y"
                    className="form-control form-control-solid h-auto calmisset readOnlySpan"
                    clearIcon={null}
                    calendarIcon={
                      <div>
                        <i className="flaticon-calendar-1"></i>
                      </div>
                    }
                  />
                </div>
              </div>

              <div className="col-lg-2">
                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold w-100"
                  onClick={() => searchData("normal")}
                >
                  Search
                </button>
              </div>

              <div className="col-lg-2">
                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold w-100"
                  // disabled={isEmailUnique}
                  onClick={() => searchData("today")}
                >
                  Today
                </button>
              </div>

              <div className="col-lg-2">
                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold w-100"
                  // disabled={isEmailUnique}
                  onClick={() => searchData("yesterday")}
                >
                  Yesterday
                </button>
              </div>
            </div>
          </CardHeader>
          <CardBody className="p-4">
            {/* <Table columns={columns} data={acceptListData} /> */}
            {/* {sortData && <Table columns={columns} data={sortData} />} */}
            {bioAttendanceData && (
              <Table columns={columns} data={bioAttendanceData} />
            )}
          </CardBody>
        </Card>
        {/* </LoadingOverlay> */}
      </div>
      <Modal
        show={modalIsOpen}
        // onHide={closeModal}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
        className="editTodayModalStyleBiometric"
      >
        <Modal.Header closeButton>
          <div className="biometricHeaderTab">
            <h2 className="mb-0">Biometric Attendance</h2>
            {biometricPersonCsv && (
              <button
                id="CSV"
                type="button"
                className="font-weight-bold download-csv transformBtn acceptDataCss"
              >
                <CSVLink
                  data={biometricPersonCsv}
                  filename={"BiometricPerson.csv"}
                  target="_blank"
                >
                  <span className="svg-icon svgSetting">
                    <SVG
                      fill="#000"
                      className="svgSetting"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/csv-file-format-extension.svg"
                      )}
                    ></SVG>
                  </span>
                </CSVLink>
              </button>
            )}
          </div>
        </Modal.Header>
        {isLoading ? <LoaderPop /> : ""}
        <Modal.Body className="p-10">
          <div className="EmployeeBiometric">
            <BootstrapTable
              striped
              bordered
              hover
              size="sm"
              className="inactable"
            >
              <thead>
                <tr>
                  <th colSpan={6}>Name: {employeeFullName}</th>
                  <th colSpan={6}>Employee Code: {employeeUserId}</th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Day</th>
                  {/* <th>Shift</th> */}
                  <th>Shift Start Time</th>
                  <th>Shift End Time</th>
                  <th>ArrTim</th>
                  <th>DepTim</th>
                  <th>WrkHrs</th>
                  <th>PresAbs</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formatDate(item.checkInDate)}</td>
                        <td>{item.day}</td>
                        {/* <td>{item.shiftCode}</td> */}
                        <td>{item.shiftStartTime}</td>
                        <td>{item.shiftEndTime}</td>
                        <td>{item.arrivalTime}</td>
                        <td>{item.departureTime}</td>
                        <td>{item.workingHours}</td>
                        <td>{item.status}</td>
                        <td>{item.lateMark}</td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </BootstrapTable>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setModalIsOpen(false)}
            id="cancel"
            type="button"
            className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <div>
        <input
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          // placeholder={`Search ${count} records...`}
          className="searchFilter"
        />
        {/* <i className="flaticon-search searchIcon"></i> */}
      </div>
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 5 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy, //sort data
      usePagination, //use pagination
      useBlockLayout,
      useSticky
    );

    return (
      <>
        <Styles>
          <div className="tblScroll">
            {/* <span className="globalFilter">
            Search: {" "}

          <input
        type="text"
        value={globalFilter || ""}
        onChange={e => setGlobalFilter(e.target.value)}
      />
      </span> */}

            <table
              {...getTableProps()}
              className="sticky attendancetabl dashAccept biometrictbl"
            >
              <thead className="tableHead">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Styles>
        <div className="row m-0">
          <div className="pagination col-lg-12 mb-7">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records{" "}
                <strong>
                  {/* {data.length} */}
                  {rows.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </>
    );
  }
}
