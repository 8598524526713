import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from "react-router-dom";

import { forgotPassword, clearMessages } from "./../../../store/authentication";
//import * as auth from "../_redux/authRedux";
//import { requestPassword } from "../_redux/authCrud";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const dispatch = useDispatch();
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  //const [pageLoading, setLoading] = useState(false);
  const history = useHistory();
  // const user = useSelector(state => state.entities.auth.data);
  const { status, error, loading, message } = useSelector((state) => state.entities.auth);
  console.log("got error...", error, status);
  let errorMsg = "Something went wrong. Please try again.";

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  useEffect(() => {
    if (status === "error") {
      if (typeof error === "object") {
        errorMsg = error.message;
        //setLoading(false);
      }
      else {
        errorMsg = error;
        //setLoading(false);
      }
      setErrorAlertMsg(errorMsg);
      setShowErrorAlert(true);
      setTimeout(() => {
        dispatch(clearMessages());
        setErrorAlertMsg('');
        setShowErrorAlert(false);
      }, 4000);
    }
  }, [status]);

  useEffect(() => {
    if (status === "success" && !loading && message !== null) {
      //disableLoading();
      setSuccessAlertMsg(message);
      setShowSuccessAlert(true);
      setTimeout(() => {
        dispatch(clearMessages());
        setSuccessAlertMsg('');
        setShowSuccessAlert(false);
        history.push("/login");
      }, 2000);
    }
  }, [status, loading, message]);

  // const enableLoading = () => {
  //   setLoading(true);
  // };

  // const disableLoading = () => {
  //   setLoading(false);
  // };


  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {

      dispatch(forgotPassword(values.email));
      //enableLoading();
      // requestPassword(values.email)
      //   .then(() => setIsRequested(true))
      //   .catch(() => {
      //     setIsRequested(false);
      //     setSubmitting(false);
      //     setStatus(
      //       intl.formatMessage(
      //         { id: "AUTH.VALIDATION.NOT_FOUND" },
      //         { name: values.email }
      //       )
      //     );
      //   });
    },
  });

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        text='Sending ...'
        // classNamePrefix='pg-list-loader-'
        styles={{
          // overlay: (base) => ({
          //   ...base,
          //   background: 'rgba(255, 0, 0, 0.5)'
          // }),
          wrapper: {
            overflow: loading ? 'hidden' : 'hidden'
          },
          spinner: (base) => ({
            ...base,
            width: '75px',
            '& svg circle': {
              stroke: '#00b1cc'
            }
          })
        }}
      >
        {isRequested && <Redirect to="/auth" />}
        {!isRequested && (
          <div className="login-form login-forgot" style={{ display: "block" }}>
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">Forgot Password ?</h3>
              <div className="text-muted font-weight-bold">
                Enter your email to reset your password
            </div>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              {/* {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )} */}

              <div className="mt-4 mb-4 d-block">
                {showErrorAlert &&
                  <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                    {errorAlertMsg}
                  </Alert>
                }

                {showSuccessAlert &&
                  <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                    {successAlertMsg}
                  </Alert>
                }
              </div>

              <div className="form-group fv-plugins-icon-container">
                <input
                  type="email"
                  placeholder="E-Mail"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container ml-5">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap flex-center">
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                // disabled={formik.isSubmitting}
                >
                  Submit
              </button>
                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Cancel
                </button>
                </Link>
              </div>
            </form>
          </div>
        )}
      </LoadingOverlay>
    </>
  );
}

// export default injectIntl(connect(null, auth.actions)(ForgotPassword));
export default injectIntl(ForgotPassword);
