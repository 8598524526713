import React, { useState, useEffect } from 'react';
import { Map, InfoWindow, GoogleApiWrapper, Marker } from 'google-maps-react';
import { useDispatch, useSelector } from "react-redux";
import '../css/customMap.scss';
import NodeGeocoder from 'node-geocoder';
import LoadingOverlay from 'react-loading-overlay';
import Geocode from "react-geocode";
import millify from 'millify';

import { google_api_key } from '../../../utils/constant';
import { citiesLoanCountForMarker } from '../../../store/loan';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(google_api_key.api_key);

function CustomMap(props) {
    //console.log(" CustomMap props ========= ", props);
    const [data, setData] = useState([]);
    const user = useSelector(state => state.entities.auth.data);
    const dispatch = useDispatch();

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    var date = new Date();
    var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
    var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    //console.log('firstDay , lastDay', firstDay, lastDay);


    // let [startDate, setStartDate] = useState(new Date(firstDay));
    // let [endDate, setEndDate] = useState(new Date(lastDay));
    let loanData = [];
    let loanDataTemp = useSelector((state) => state.entities.loan.citiesWiseLoanForMarker.data);

    useEffect(() => {
        if (loanDataTemp)
            loanData = loanDataTemp.loan
        console.log(" loanData =========== ", loanData);
    }, [loanDataTemp])


    const { status, error, loading, message } = useSelector((state) => state.entities.loan.citiesWiseLoan);
    //console.log('loanData:', loanData);

    const [latLog, setLatLog] = useState([]);
    const [selected, setSelected] = useState(null);
    const [show, setShow] = useState(false);
    const [marker, setMarker] = useState(null);
    //let latLog = [];

    const mapStyles = {
        width: '100%',
        height: props && props.height ? props.height : '85vh',
        position: 'relative'
    };

    useEffect(() => {
        if (loanData.length !== 0) {
            setData(loanData);
        }
    }, [data]);

    useEffect(() => {
        if (firstDay) {
            dispatch(citiesLoanCountForMarker(user.userId, '', ''));
        }
    }, [])

    useEffect(() => {
        console.log('loanData:', data);
        if (data) {
            data.map((item, i) => {
                //console.log('city name:', item);
                if (item && item.city) {
                    // Get latitude & longitude from address.
                    Geocode.fromAddress(item.city).then(
                        (response) => {
                            const { lat, lng } = response.results[0].geometry.location;
                            setLatLog(latLog => [...latLog, {
                                "lat": lat,
                                "lng": lng,
                                "city": item.city,
                                "count": item.count,
                                "loanAmount": item.loanAmount
                            }])
                        },
                        (error) => {
                            console.error('Error:', error);
                        }
                    );
                }
            })
        }
    }, [data])

    const onMarkerClick = (props, marker) => {
        setMarker(marker);
        setSelected(props);
        setShow(true);
    }

    return (
        // <LoadingOverlay
        //     active={loading}
        //     spinner
        //     text="Loading..."
        //     className="loading-spinner-custom-color"
        //     classNamePrefix='dashboard-page-loader-'
        //     styles={{
        //         wrapper: {
        //             overflow: loading ? 'hidden' : 'hidden'
        //         },
        //         spinner: (base) => ({
        //             ...base,
        //             width: '75px'
        //         })
        //     }}
        // >

        <div className="customMap">
            {data && (
                <Map google={props.google}
                    //onClick={onMapClicked}
                    zoom={props && props.zoom ? props.zoom : 5}
                    style={mapStyles}
                    initialCenter={{ lat: 19.172553, lng: 72.874638 }}
                >


                    {latLog.length !== 0 && latLog.map((markerItem, i) => (
                        <Marker key={i}
                            onClick={onMarkerClick}
                            name={markerItem.city}
                            data={markerItem}
                            position={{ lat: markerItem.lat, lng: markerItem.lng }}
                        />
                    ))}

                    {/* { latLog.map((markerItem, i) => (
                            <Marker key={i}
                                onClick={onMarkerClick}
                                name={markerItem.city}
                                data={markerItem}
                                position={{ lat: markerItem.lat, lng: markerItem.lng }}
                            />
                        ))} */}

                    {selected && (<InfoWindow
                        marker={marker}
                        onClose={() => {
                            setSelected(null);
                            setShow(false);
                            setMarker(null);
                        }}
                        visible={show}
                    >
                        <div>
                            <h4>{selected.data.city}</h4>
                            <h6 className="totalLoan">Loanee Count {selected.data.count}</h6>
                            <h6 className="totalLoan">Loan Amount {millify(selected ? selected.data.loanAmount : 0, { space: true })}</h6>
                        </div>
                    </InfoWindow>
                    )}
                    {/* {selected ? (
                            <InfoWindow
                                position={{ lat: selected.lat, lng: selected.lng }}
                                onCloseClick={() => {
                                    setSelected(null);
                                }}
                            >
                                <div>
                                    <h2>
                                        Alert
                                    </h2>
                                </div>
                            </InfoWindow>
                        ) : null} */}

                    {/* <InfoWindow
                            marker={activeMarker}
                            visible={showingInfoWindow}>
                            <div>
                                <h1>{selectedPlace.name}</h1>
                            </div>
                        </InfoWindow> */}
                </Map>
            )}
        </div>
        // </LoadingOverlay>
    )
}

export default GoogleApiWrapper({
    apiKey: google_api_key.api_key
})(CustomMap)
