import React from "react";
import { ListsWidget10 } from "../../../../_metronic/_partials/widgets";
// import { toAbsoluteUrl } from "../../../_metronic/_helpers";
// console.log("ListsWidget10-----",ListsWidget10);
export default function User_Notification() {
  return (
    <div className="row">
      <div className="col-lg-12">
        <ListsWidget10 className="card-stretch gutter-b"></ListsWidget10>
      </div>
    </div>
  );
}