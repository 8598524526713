import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHistory } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";

export function Topbar() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  let d = new Date().toDateString();
  //console.log('date:', d.split(' ').lastIndex(-2).join(','));
  const history = useHistory();

  const displayNotification = () => {
    history.push({
      pathname: '/Notifications/components/User_Notification'
    });
  }

  return (
    <div className="topbar">

      

      {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}
      <div className="d-flex align-items-center">
        <a href="#" className="btn btn-light btn-sm font-weight-bold" id="kt_dashboard_daterangepicker"
          data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
          {/* <span className="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today </span> */}
          <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">{d}</span>
        </a>
        {/* <QuickActions /> */}
      </div><div
        className="topbar-item"
        // data-toggle="tooltip"
        title="Notifications"
      // data-placement="right"
      >
        <div
          className="btn btn-icon btn-clean btn-lg mr-1"
          // id="kt_quick_panel_toggle"
          onClick={displayNotification}
        >
          <span className="svg-icon svg-icon-xl svg-icon-primary">
            {/* <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Notifications1.svg"
              )}
            /> */}
            {/* <div className="notificationCircle">

              1</div> */}
            <i className="flaticon2-notification"></i>

          </span>
        </div>
      </div>
      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
