
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
import DatePicker from 'react-date-picker';
import "datatables.net/js/jquery.dataTables";
import "datatables.net-bs4/js/dataTables.bootstrap4.js";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-buttons-bs4";
import millify from 'millify';

import "../../Loan/css/loan.scss";
import "../css/bucketWiseTable.scss";

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy, useExpanded } from 'react-table';
import matchSorter from 'match-sorter';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "../../Loader/Loader";
import Alert from 'react-bootstrap/Alert';
import AgencyWiseSubRows from "./AgencyWiseSubRows";
import Select from 'react-select';


import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { agencyWisePerformanceClearMessages, agencyWisePerformance } from "../../../store/dashboard";
import { agencyFinancierList } from "../../../store/loan" 
import { getAllMonth, getCurrentYearMonthDate } from "../../../utils/helperFunctions";

function AgencyWisePerformaceTable() {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [errShow, setErrShow] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [roleOption, setRoleOptions] = useState([]);
  const [financierId, setFinancierId] = useState(0);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [years, setYears] = useState([]);
  const [csvData,setCsvData] = useState([]);
	const [year, setYear] = useState(getCurrentYearMonthDate('year'));
	const [month, setMonth] = useState(getCurrentYearMonthDate('month'));
  let errorMsg //;= "Something went wrong. Please try again.";
  const dispatch = useDispatch();
  const user = useSelector(state => state.entities.auth.data);
  let agencyFinancierListData = useSelector((state) => state.entities.loan.agenciesFinanciersList.data);
  console.log("agencyFinancierListData:", agencyFinancierListData);
  let agencyWisePerformanceData = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data);
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const numFormatter = (num) => {
		let toFixe = 4;
    if(num <= 0) {
      return num.toFixed(2)
    } else if(num > 999 && num < 9999){
    	toFixe = 3
    } else if(num >= 10000) {
    	toFixe = 2
    }
    return (num/1000000).toFixed(toFixe);
  }

  	useEffect(() => {
		let temp = []
		for (let index = 2019; index <= 2030; index++) {
			temp[index] = { value: index, label: index }
		}
		setYears(temp);
	}, [])

  var date = new Date();
  var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  let [startDate, setStartDate] = useState(new Date(firstDay));
  let [endDate, setEndDate] = useState(new Date(lastDay));

  const columns = React.useMemo(
    () => [
      // {
      //   // Build our expander column
      //   id: "expander", // Make sure it has an ID
      //   Header: () => null,
      //   Cell: ({ row }) => {
      //       return row.canExpand ? (
      //           <span
      //               {...row.getToggleRowExpandedProps({
      //                   style: {
      //                       paddingLeft: `${row.depth * 2}rem`,
      //                   },
      //               })}
      //           >
      //               {row.isExpanded ? (<i className="fas fa-angle-down"></i>) : (<i className="fas fa-angle-right"></i>)}
      //           </span>
      //       ) : null
      //   }
      // },
	  {
        Header: 'Allocation',
        accessor: (value) => (value.name ? value.name : ''),
        Footer: value => {
          return <><div className=" tblFooterColor">Total</div></>;
        },
        disableFilters: true,
      },
      {
        Header: 'Total Cases',
        accessor: (value) => (value.totalCases ? value.totalCases : 0),
        Footer: value => {
          let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          return <><div className=" tblFooterColor">{agencyWisePerformance ? (agencyWisePerformance.totalCases) : 0}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>POS</div><div>(M)</div></div>,
        id: 'POS',
        accessor: (value) => (numFormatter(value.pos ? value.pos : 0)),
        Footer: value => {
		    let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{agencyWisePerformance.totalPos ? (numFormatter(agencyWisePerformance.totalPos)) : 0}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>dues</div><div>(M)</div></div>,
        id: 'dues',
        accessor: (value) => (numFormatter(value.dues ? value.dues : 0)),
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{agencyWisePerformance.totalDues !== 0 ? (numFormatter(agencyWisePerformance.totalDues)) : 0}</div></>;
        },
        disableFilters: true,
      },
	  {
        Header: <div><div>Amt Collected</div><div>(M)</div></div>,
        id: 'paidAmount',
        accessor: (value) => (numFormatter(value.paidAmount ? value.paidAmount : 0)),
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{agencyWisePerformance.totalPaidAmount !== 0 ? (numFormatter(agencyWisePerformance.totalPaidAmount)) : 0}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>Resolution</div><div>(%)</div></div>,
        id: 'resolution',
        accessor: (value) => {
		  return (value.resolution ? value.resolution : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{agencyWisePerformance.totalResolution.toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>PTP</div><div>(%)</div></div>,
        id: 'ptp',
        accessor: (value) => {
          return (value.ptp ? value.ptp : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{agencyWisePerformance.totalPtp.toFixed(2)}</div></>;
          
        },
        disableFilters: true,
      },
      {
        Header: <div><div>cb</div><div>(%)</div></div>,
        id: 'cb',
        accessor: (value) => {
          return (value.cb ? value.cb : 0).toFixed(2);
        },
        Footer: value => {
        
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
			return <><div className=" tblFooterColor">{(agencyWisePerformance.totalCb).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>BPTP</div><div>(%)</div></div>,
        id: 'bptp',
        accessor: (value) => {
          return (value.bptp ? value.bptp : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
			return <><div className=" tblFooterColor">{(agencyWisePerformance.totalBptp).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>Dispute</div><div>(%)</div></div>,
        id: 'dispute',
        accessor: (value) => {
          return (value.dispute ? value.dispute : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalDispute).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>Bfu</div><div>(%)</div></div>,
        id: 'bfu',
        accessor: (value) => {
          return (value.bfu ? value.bfu : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalBfu).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>Rtp</div><div>(%)</div></div>,
        id: 'rtp',
        accessor: (value) => {
          return (value.rtp ? value.rtp : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalRtp).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>lm</div><div>(%)</div></div>,
        id: 'lm',
        accessor: (value) => {
          return (value.lm ? value.lm : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalLm).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>Rnr</div><div>(%)</div></div>,
        id: 'rnr',
        accessor: (value) => {
          return (value.rnr ? value.rnr : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalRnr).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>dl</div><div>(%)</div></div>,
        id: 'dl',
        accessor: (value) => {
          return (value.dl ? value.dl : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalDl).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>nc</div><div>(%)</div></div>,
        id: 'nc',
        accessor: (value) => {
          return (value.nc ? value.nc : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalNc).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>sfd</div><div>(%)</div></div>,
        id: 'sfd',
        accessor: (value) => {
          return (value.sfd ? value.sfd : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalSfd).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>anf</div><div>(%)</div></div>,
        id: 'anf',
        accessor: (value) => {
          return (value.anf ? value.anf : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalAnf).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
	  {
        Header: <div><div>dnc</div><div>(%)</div></div>,
        id: 'dnc',
        accessor: (value) => {
          return (value.dnc ? value.dnc : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalDnc).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>pending</div><div>(%)</div></div>,
        id: 'pending',
        accessor: (value) => {
          return (value.pending ? value.pending : 0).toFixed(2);
        },
        Footer: value => {
			let agencyWisePerformance = useSelector((state) => state.entities.dashboard.agencyWisePerformance.data[1][0]);
          	return <><div className=" tblFooterColor">{(agencyWisePerformance.totalPending).toFixed(2)}</div></>;
        },
        disableFilters: true,
      },

    ],
    []
  )

  const { status, error, loading, message } = useSelector((state) => state.entities.dashboard.agencyWisePerformance);
  
  useEffect(() => {
    if(agencyFinancierListData) {
      let roleOptionsTemp = [];
      for (let index = 0; index < agencyFinancierListData.length; index++) {
          let value = agencyFinancierListData[index];
          if(value.type === 'financier' || value.type === 'All') {
            if(value.active === "Y" || value.type === "All"){

              roleOptionsTemp.push({ value: value.id, label: value.name },)
            }
          }
      }
      setRoleOptions(roleOptionsTemp)
    }
    
  }, [agencyFinancierListData])

  useEffect(() => {
    if (!agencyFinancierListData) {
      dispatch(agencyFinancierList(user.userId));
    }
  }, [])

  useEffect(() => {
    if (status === "error") {
      if (typeof error === "object") {
        errorMsg = error.message;
        setErrShow(error.message);
      }
      else {
        errorMsg = error;
      }
      setErrorAlertMsg(errorMsg);
      setShowErrorAlert(true);
      setTimeout(() => {
        dispatch(agencyWisePerformanceClearMessages());
        setErrorAlertMsg('');
        setShowErrorAlert(false);
      }, 2000);
    }
  }, [status])
  const onChangeRole = (data) => {
    console.log(" data ", data);
    setFinancierId(data.value);
  }

  useEffect(() => {
	let getCurrentYearMonth = getCurrentYearMonthDate();
    if (agencyWisePerformanceData === null) {
		dispatch(agencyWisePerformance(user.userId, user.agencyId, getCurrentYearMonth[0], getCurrentYearMonth[1], financierId));
    }
  }, [])


  useEffect(() => {
    let tempCsvData = [];
    if (agencyWisePerformanceData && agencyWisePerformanceData !== null) {
      for (let index = 0; index < agencyWisePerformanceData.length; index++) {
        const tempSingle = agencyWisePerformanceData[index];
        let newSingleData = {};
        newSingleData.totalCases = tempSingle.totalCases;
        newSingleData.allocationDPD = tempSingle.allocationDPD;
        newSingleData.pos = tempSingle.pos ? parseFloat(tempSingle.pos).toFixed(2) : 0;
        newSingleData.dues = tempSingle.dues ? parseFloat(tempSingle.dues).toFixed(2) : 0;
        // newSingleData.paidAmount = tempSingle.paidAmount ? parseFloat(tempSingle.paidAmount).toFixed(2) : 0;
        newSingleData.Amountcollected = tempSingle.Amountcollected ? parseFloat(tempSingle.Amountcollected).toFixed(2) : 0;
        newSingleData.resolution = tempSingle.resolution ? parseFloat(tempSingle.resolution).toFixed(2) : 0;
        newSingleData.ptp = tempSingle.ptp ? parseFloat(tempSingle.ptp).toFixed(2) : 0;
        newSingleData.cb = tempSingle.cb ? parseFloat(tempSingle.cb).toFixed(2) : 0;
        newSingleData.bptp = tempSingle.bptp ? parseFloat(tempSingle.bptp).toFixed(2) : 0;
        newSingleData.dispute = tempSingle.dispute ? parseFloat(tempSingle.dispute).toFixed(2) : 0;
        newSingleData.bfu = tempSingle.bfu ? parseFloat(tempSingle.bfu).toFixed(2) : 0;
        newSingleData.rtp = tempSingle.rtp ? parseFloat(tempSingle.rtp).toFixed(2) : 0;
        newSingleData.lm = tempSingle.lm ? parseFloat(tempSingle.lm).toFixed(2) : 0;
        newSingleData.dl = tempSingle.dl ? parseFloat(tempSingle.dl).toFixed(2) : 0;

        newSingleData.pending = tempSingle.pending ? parseFloat(tempSingle.pending).toFixed(2) : 0;
        newSingleData.nc = tempSingle.nc ? parseFloat(tempSingle.nc).toFixed(2) : 0;
        newSingleData.rnr = tempSingle.rnr ? parseFloat(tempSingle.rnr).toFixed(2) : 0;
        newSingleData.sfd = tempSingle.sfd ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        newSingleData.anf = tempSingle.anf ? parseFloat(tempSingle.anf).toFixed(2) : 0;
        newSingleData.dnc = tempSingle.dnc ? parseFloat(tempSingle.dnc).toFixed(2) : 0;
        // newSingleData.bucket = tempSingle.bucket;
        // newSingleData.description = tempSingle.description;
        // newSingleData.color = tempSingle.color;
        tempCsvData.push(newSingleData);
      }
      setCsvData(tempCsvData);
      console.log(" agencyWisePerformanceData --------- ", tempCsvData);

    }
  }, [agencyWisePerformanceData])




  const data = agencyWisePerformanceData;
  console.log(" data ======== ", data);

  useEffect(() => {
    if (status == 'success') {
      if (data) {
        setErrShow(null);
      }
    }
  }, [status])

  const searchAgencyWisePerformance = () => {
    console.log(" testing " , year, month);
    
	if (year && month) {
		dispatch(agencyWisePerformance(user.userId, user.agencyId, year, month, financierId));
    }
  }

  const renderRowSubComponent = (row) => {
    return (
        <AgencyWiseSubRows agencyWiseSubRowsData={row.original.subRows} rowData={row.original} />
    );
  };

  return (
    <div className="logs-card-div">
      {loading ? <Loader /> : " "}
      {/* <LoadingOverlay
        active={loading}
        spinner
        text="Loading data..."
        className="loading-spinner-custom-color"
        classNamePrefix='dashboard-page-loader-'
        styles={{
          wrapper: {
            overflow: loading ? 'hidden' : 'hidden'
          },
          spinner: (base) => ({
            ...base,
            width: '75px'
          })
        }}
      > */}
        <Card className="agencyWise w-100" style={{minHeight:'400px'}}>
          <CardHeader>
            <div className="col-lg-4 pl-0 d-flex justify-content-center align-items-start flex-column">
              <h3 className="mb-0">Allocation Wise</h3>
            </div>
            <div className="col-lg-4 mt-4 d-block">
              {showErrorAlert &&
                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                  {errorAlertMsg}
                </Alert>
              }

              {showSuccessAlert &&
                <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                  {successAlertMsg}
                </Alert>
              }
            </div>
            <div className="col-lg-3 pl-0 pr-0 d-flex justify-content-end align-items-center">
             

              {csvData && (
                <div className="col-lg-2 pl-0">
                  <button
                    id="CSV"
                    type="button"
                    className="settingBtn font-weight-bold download-csv transformBtn">
                    <CSVLink
                      data={csvData}
                      filename={"Allocation Wise.csv"}
                      target="_blank"
                    >
                      <span className="svg-icon svgSetting">
                        <SVG className="svgSetting"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/csv-file-format-extension.svg"
                          )}
                        ></SVG>
                      </span>
                    </CSVLink>
                  </button>
                </div>
              )}
            </div>
          </CardHeader>
          <CardHeader>
            <div className="col-lg-8 pt-4 pl-0 d-flex justify-content-start align-items-center" style={{zIndex:2}}>
			{user.role !== 'Financier Manager' && (
                <div className="col-lg-4 pl-0 justify-content-center align-items-center remove">
                  <div className="form-group">
                    <label>Select financier</label>
                    <Select
                      placeholder="Select..."
					  defaultValue={{label: 'All', value: 0}}
                      name="role"
                      className="jobRoleSelect w-100"
                      options={roleOption}
                      onChange={onChangeRole}
                      // style={{display: 'none'}}
                    />
                  </div>
                </div>
              )}
			  	{year && (
					<div className="col-lg-4 pl-0 justify-content-center align-items-center" style={{zIndex:2}}>
						<div className="form-group">
							<label>Select Year</label>
							<Select
								// defaultValue={'2022'}
								defaultValue={{label: year, value: year}}
								placeholder="Select..."
								name="export_type"
								className="jobRoleSelect w-100"
								options={years}
								onChange={(data) => {
									setYear(data.value);
								}}
							/>

						</div>
					</div>
				)}

				{/* {month && ( */}
					<div className="col-lg-4 pl-0 justify-content-center align-items-center" style={{zIndex:2}}>
						<div className="form-group">
							<label>Select Month</label>
							<Select
								defaultValue={getAllMonth(month)}
								placeholder="Select..."
								name="export_type"
								className="jobRoleSelect w-100"
								options={getAllMonth()}
								onChange={(data) => {
									// console.log(" test ", data.value);
									let currentYear = year;
									if (!currentYear) {
										currentYear = date.getFullYear()
									}
									setMonth(data.value);
								}}
							/>

						</div>
					</div>
				{/* )} */}
              <div className="col-lg-2">
                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                  onClick={searchAgencyWisePerformance}
                >
                  Search
                </button>
              </div>
            </div>

          </CardHeader>
          <CardBody className="p-4">
            {data && data[0] && (
              <Table columns={columns} data={data[0]} renderRowSubComponent={renderRowSubComponent}/>
            )}
            {error && (
              <div className="d-flex justify-content-center align-items-center">
                <h6>{errShow}</h6>
              </div>
            )}
          </CardBody>
        </Card>
      {/* </LoadingOverlay> */}
    </div>
  );
}

export default AgencyWisePerformaceTable;


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <div>
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value.toString().toLowerCase() || undefined) // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
        to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data, renderRowSubComponent }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    //setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },

  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,//sort data
    useExpanded,
    usePagination,//use pagination
  )

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  let display = 'content';
  return (
    <>
      <div>
        <div className="tblScroll someradiush">
          <table {...getTableProps()} className="loanTable financierWiseTable">
            <thead className="tableHead">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? '↓'
                            : '↑'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
              {/* <tr> 
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
                </th>
              </tr> */}
            </thead>
            <thead className="alocfooth">
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <Fragment key={row.getRowProps().key}>
                            { !(parseFloat(row.id) % 1) && (
                                <tr style={{display: display}} className={`${display} ${ display = 'content'}`}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        );
                                    })}
                                </tr>
                            )}
                            {row.isExpanded && (
                                <tr className={`p-0 ${display = 'none'}`}>
                                    <td className="p-0" colSpan={visibleColumns.length}>
                                        {renderRowSubComponent(row)}
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    );
                })}
            </tbody>
            
          </table>
        </div>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        {/* <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </button>{' '}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records{' '}
                <strong>
                  {data.length}
                </strong>{' '}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page)
                  }}
                  className="gotoPage"
                />
              </span>{' '}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div> */}


        <br />
      </div>
    </>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

