import React, { useEffect, useMemo } from "react";
//import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
//import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
    //FormattedMessage, 
    injectIntl
} from "react-intl";
//import { useHistory } from "react-router-dom";
//import { Dropdown } from "react-bootstrap";
//import { DropdownMenu2 } from "../../../../_metronic/_partials/dropdowns";
import { useHtmlClassService } from "../../../../_metronic/layout";
//import '../css/report_card.scss';


function MixChart(props) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseColor: objectPath.get(
                uiService.config,
                `js.colors.theme.base.danger`
            ),
            colorsThemeLightColor: objectPath.get(
                uiService.config,
                `js.colors.theme.light.danger`
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);

    useEffect(() => {
        const element = document.getElementById(`mix_chart`);

        if (!element) {
            return;
        }

        const options = getChartOption(layoutProps);
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps]);



    return (
        <div>
            <div className="report-card-div">
                <div className={`card card-custom card-stretch gutter-b card-div`}>
                    {/* Body */}
                    <div className="card-body position-relative overflow-hidden">
                        {/* Title */}
                        <div
                            id={`mix_chart`}
                            className="card-rounded-bottom"
                            style={{ height: "150px" }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(MixChart);

function getChartOption(layoutProps) {
    const options = {
        series: [
            {
                name: 'PTP+FPTP Generated',
                type: 'column',
                data: [1.4, 2, 2.5, 1.5, 2.5]
            }, {
                name: 'Cashflow',
                type: 'column',
                data: [1.1, 3, 3.1, 4, 4.1]
            }, {
                name: 'Resolution %',
                type: 'line',
                data: [20, 29, 37, 36, 44]
            }
        ],
        chart: {
            height: 350,
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 4]
        },
        title: {
            text: 'PTP Generated',
            align: 'left',
            // offsetX: 110
        },
        subtitle: {
            text: 'Long term load performance',
            align: 'left'
        },
        xaxis: {
            categories: ['April 22', 'March 22', 'Feb 22', 'Jan 22', 'Dec 21'],
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                labels: {
                    style: {
                        colors: '#008FFB',
                    }
                },
                title: {
                    text: "Income (thousand crores)",
                    style: {
                        color: '#008FFB',
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            {
                seriesName: 'Income',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#00E396'
                },
                labels: {
                    style: {
                        colors: '#00E396',
                    }
                },
                title: {
                    text: "Operating Cashflow (thousand crores)",
                    style: {
                        color: '#00E396',
                    }
                },
            },
            {
                seriesName: 'Revenue',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#FEB019'
                },
                labels: {
                    style: {
                        colors: '#FEB019',
                    },
                },
                title: {
                    text: "Revenue (thousand crores)",
                    style: {
                        color: '#FEB019',
                    }
                }
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        }

    };
    return options;
}

