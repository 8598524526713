import React, { useEffect, useMemo } from "react";

import {
    // FormattedMessage, 
    injectIntl
} from "react-intl";
import { useHtmlClassService } from "../../../../_metronic/layout";
import ReactECharts from 'echarts-for-react';
import '../css/Chart.scss';


function BarChart(props) {
//    console.log(" props BarChart ", props.amountCollecte);
    var xAxisDatas = props.amountCollected ? props.amountCollected[0].xAxisData : [];
    let xAxisData = xAxisDatas.map((xAd)=>xAd.substring(8, 10));
    // console.log(" xAxisDatas ======== ", xAxisDatas);
    var data1 = props.amountCollected ? props.amountCollected[1].orders : [];
    var data2 = props.amountCollected ? props.amountCollected[2].transactions : [];

    // ============= start here ============

    // var xAxisData = [],data1=[],data2=[];
    // for (var i = 0; i < 100; i++) {
    //     xAxisData.push('A' + i);
    //     data1.push((Math.sin(i / 5) * (i / 5 - 10) + i / 6) * 5);
    //     data2.push((Math.cos(i / 5) * (i / 5 - 10) + i / 6) * 5);
    // }

    // ====================== end here =====================

    let option = {
        title: {
            text: 'Day Wise Trend',
            left: '170px',
        },
        legend: {
            data: ['Amount Collected','Resolution Percentage'],
            top: '30px',
            left: '90px',
        },
        height: 200,
        padding: 30,
        
        

        showScale: false,
        toolbox: {
            // y: 'bottom',
            feature: {
                magicType: {
                    type: ['stack']
                },
                dataView: {},
                saveAsImage: {
                    pixelRatio: 1
                }
            }
        },
        tooltip: {},
        xAxis: {
            data: xAxisData,
            splitLine: {
                show: false
            },
            show: true
        },
        yAxis: {
            boundaryGap: [0, '100%'],
            position:'center',
            show: true,
            axisLabel: {
                inside: true,
                margin: -40
              },
        },

        series: [
            {
                name: 'Resolution Percentage',
                type: 'bar',
                data: data2,
                height:100,
                emphasis: {
                    focus: 'series'
                },
                left:'170px',
                animationDelay: function (idx) {
                    return idx * 10;
                }
            },
            {
                name: 'Amount Collected',
                type: 'bar',
                data: data1,
                paddingLeft:'30',
                emphasis: {
                    focus: 'series'
                },
                animationDelay: function (idx) {
                    return idx * 10;
                }
            }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
            return idx * 5;
        }
    };

    return (
        <div>
            <div className="report-card-div barChart">
                <div className={`card card-custom card-stretch gutter-b card-div`}>
                    {/* Body */}
                    <div className="card-body pt-4 position-relative overflow-hidden">
                        {/* Title */}
                        {/* <div
                            id={`bar_chart`}
                            className="card-rounded-bottom"
                            style={{ height: "150px" }}>
                        </div> */}
                        <ReactECharts className="pl-5" option={option} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(BarChart);