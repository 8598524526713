import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import firebase from './firebaseConfig';
import 'firebase/messaging';
import { Toast } from 'react-bootstrap';
import { setToken } from '../src/app/store/dashboard';

function FirebaseNotifications() {

    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [isTokenFound, setTokenFound] = useState(false);
    const [redirectUlr, setRedirectUrl] = useState('');
    const dispatch = useDispatch();
    

    const { setDeviceToken } = useSelector(state => state.entities.dashboard);

   
    let messaging = firebase.messaging();
   

    useEffect(() => {
        messaging.getToken().then((token) => {
            setTokenFound(token);
            localStorage.setItem("notificationtoken", token);

            if (setDeviceToken == null) {
                dispatch(setToken(token));
            }
        

        }).catch((err) => {
            console.log('error in acessing notification :', err);
            //alert(" Please allow the notification ") 
        })
    }, [])

    useEffect(() => {
        
        messaging.onMessage((payload) => {
            var str = window.location.pathname; 
            var n = str.includes("login");
            if(n) {
                setShow(false);
            } else {
                setShow(true);
                setTimeout(() => {
                    setShow(false);
                }, 10000);
            }
                
            setNotification({ title: payload.notification.title, body: payload.notification.body })
            setRedirectUrl(payload.notification.click_action);
        })
    },[])

    const closeNotification = () => {
        setShow(false);
    }

    return (
        <div className="firebaseNotificationDiv">
            <Toast onClose={() => closeNotification()} show={show} delay={10000} animation className="toastBlock">
              
                <Toast.Header>
                    <strong className="mr-auto">{notification.title}</strong>
                </Toast.Header>
                <Toast.Body>{notification.body}</Toast.Body>
            </Toast>
        </div>
    );
}

export default FirebaseNotifications
