import React from "react";
// import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import { useDispatch
//     //, useSelector 
// } from "react-redux";
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';

import { useFormik } from "formik";
import * as Yup from "yup";
//import { FormattedMessage, injectIntl } from "react-intl";
//import * as auth from "../_redux/authRedux";
//import { login } from "../_redux/authCrud";
//import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../css/editDisposition.scss";

function EditDocumentModule(props) {
    console.log('edit document page props:', props);

    //const dispatch = useDispatch();
    const initialValues = {
        dispositionId: props.editDocumentData.id,
        type: props.editDocumentData.dispositionStatus,
        disposition: props.editDocumentData.dispositionCode,
        agencyId: props.editDocumentData.agencyId,
        agent: props.editDocumentData.loanId,
        dispositionDate: props.editDocumentData.dispositionDate,
        comments: props.editDocumentData.remark,
    };
    const EditDispositionSchema = Yup.object().shape({
        dispositionId: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(15, "Maximum 15 symbols"),
        type: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(25, "Maximum 25 symbols"),
        disposition: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(15, "Maximum 15 symbols"),
        agencyId: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(15, "Maximum 15 symbols"),
        agent: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols"),
        dispositionDate: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(25, "Maximum 25 symbols"),
        comments: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(400, "Maximum 400 symbols"),
    });



    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: EditDispositionSchema,
        onSubmit: (values) => {
            // dispatch(login(values.email, values.password));
            //enableLoading();
            // setTimeout(() => {
            //   login(values.email, values.password)
            //     .then(({ data: { accessToken } }) => {
            //       disableLoading();
            //       props.login(accessToken);
            //     })
            //     .catch(() => {
            //       disableLoading();
            //       setSubmitting(false);
            //       setStatus(
            //         intl.formatMessage({
            //           id: "AUTH.VALIDATION.INVALID_LOGIN",
            //         })
            //       );
            //     });
            // }, 1000);
        },
    });
    return (
        <div className="editDispositionDiv">
            {/* begin::Head */}
            <div className="col-lg-12 pl-0">
                <h1>Edit Document</h1>
            </div>
            <div>
                <hr />
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                {/* {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (
            <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
              <div className="alert-text ">
                Use account <strong>admin@demo.com</strong> and password{" "}
                <strong>demo</strong> to continue.
              </div>
            </div>
          )} */}

                <div className="form-group fv-plugins-icon-container row">
                    <div className="col-lg-12 displayContent">
                        <div className="col-lg-2 pl-0">
                            <span className="customLabel lblFontSize">Disposition Id</span>
                        </div>
                        <div className="col-lg-3">
                            <input
                                placeholder="Disposition Id"
                                type="dispositionId"
                                className={`form-control form-control-solid h-auto readOnlySpan ${getInputClasses(
                                    "dispositionId"
                                )}`}
                                name="dispositionId"
                                {...formik.getFieldProps("dispositionId")}
                            />
                            {formik.touched.dispositionId && formik.errors.dispositionId ? (
                                <div className="fv-plugins-message-container ml-6">
                                    <div className="fv-help-block">{formik.errors.dispositionId}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-2 pl-0">
                            <span className="customLabel lblFontSize">Type</span>
                        </div>
                        <div className="col-lg-3">
                            <input
                                placeholder="Type"
                                type="type"
                                className={`form-control form-control-solid h-auto readOnlySpan ${getInputClasses(
                                    "type"
                                )}`}
                                name="type"
                                {...formik.getFieldProps("type")}
                            />
                            {formik.touched.type && formik.errors.type ? (
                                <div className="fv-plugins-message-container ml-6">
                                    <div className="fv-help-block">{formik.errors.type}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="form-group fv-plugins-icon-container row">
                    <div className="col-lg-12 displayContent">
                        <div className="col-lg-2 pl-0">
                            <span className="customLabel lblFontSize">Disposition</span>
                        </div>
                        <div className="col-lg-3">
                            <input
                                placeholder="Disposition"
                                type="disposition"
                                className={`form-control form-control-solid h-auto readOnlySpan ${getInputClasses(
                                    "disposition"
                                )}`}
                                name="disposition"
                                {...formik.getFieldProps("disposition")}
                            />
                            {formik.touched.disposition && formik.errors.disposition ? (
                                <div className="fv-plugins-message-container ml-6">
                                    <div className="fv-help-block">{formik.errors.disposition}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-2 pl-0">
                            <span className="customLabel lblFontSize">Agency Id</span>
                        </div>
                        <div className="col-lg-3">
                            <input
                                placeholder="Agency Id"
                                type="agencyId"
                                className={`form-control form-control-solid h-auto readOnlySpan ${getInputClasses(
                                    "agencyId"
                                )}`}
                                name="agencyId"
                                {...formik.getFieldProps("agencyId")}
                            />
                            {formik.touched.agencyId && formik.errors.agencyId ? (
                                <div className="fv-plugins-message-container ml-6">
                                    <div className="fv-help-block">{formik.errors.agencyId}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="form-group fv-plugins-icon-container row">
                    <div className="col-lg-12 displayContent">
                        <div className="col-lg-2 pl-0">
                            <span className="customLabel lblFontSize">Agent</span>
                        </div>
                        <div className="col-lg-3">
                            <input
                                placeholder="Agent"
                                type="agent"
                                className={`form-control form-control-solid h-auto readOnlySpan ${getInputClasses(
                                    "agent"
                                )}`}
                                name="agent"
                                {...formik.getFieldProps("agent")}
                            />
                            {formik.touched.agent && formik.errors.agent ? (
                                <div className="fv-plugins-message-container ml-6">
                                    <div className="fv-help-block">{formik.errors.agent}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-2 pl-0">
                            <span className="customLabel lblFontSize">Disposition Date</span>
                        </div>
                        <div className="col-lg-3">
                            <input
                                placeholder="Disposition Date"
                                type="dispositionDate"
                                className={`form-control form-control-solid h-auto readOnlySpan ${getInputClasses(
                                    "dispositionDate"
                                )}`}
                                name="dispositionDate"
                                {...formik.getFieldProps("dispositionDate")}
                            />
                            {formik.touched.dispositionDate && formik.errors.dispositionDate ? (
                                <div className="fv-plugins-message-container ml-6">
                                    <div className="fv-help-block">{formik.errors.dispositionDate}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="form-group fv-plugins-icon-container row">
                    <div className="col-lg-12 displayContent">
                        <div className="col-lg-2 pl-0">
                            <span className="customLabel lblFontSize">Comments</span>
                        </div>
                        <div className="col-lg-3">
                            <input
                                placeholder="Comments"
                                type="email"
                                className={`form-control form-control-solid h-auto readOnlySpan ${getInputClasses(
                                    "comments"
                                )}`}
                                name="comments"
                                {...formik.getFieldProps("comments")}
                            />
                            {formik.touched.comments && formik.errors.comments ? (
                                <div className="fv-plugins-message-container ml-6">
                                    <div className="fv-help-block">{formik.errors.comments}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>


                {/* <div className="d-flex justify-content-center justify-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                      
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3 signInBtn`}
                    >
                        <span>Sign In</span>
                      
                    </button>
                </div> */}

            </form>
            {/*end::Form*/}
        </div>
    )
}

export default EditDocumentModule;
