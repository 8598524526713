import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { original } from "immer";

const initialState = {
  data: null,
  loading: false,
  lastFetch: null,
  message: null,
  status: undefined,
  error: {},
  leaveApply: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  leaveSummary: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  leaveTeamSummary: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  leaveStatusUpdate: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  leaveAvailable: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  leaveCompensatory: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  leaveCompensatoryUpdateOff: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
};

const slice = createSlice({
  name: "leave",
  initialState,
  reducers: {
    leaveApplyRequested: (leave, action) => {
      leave.leaveApply.loading = true;
      leave.leaveApply.message = null;
      leave.leaveApply.status = undefined;
      leave.leaveApply.error = {};
    },
    leaveApplyReceived: (leave, action) => {
      leave.leaveApply.data = action.payload.data;
      // console.log('leaveApplyReceived data here =========', action.payload.data);
      leave.leaveApply.loading = false;
      leave.leaveApply.lastFetch = Date.now();
      leave.leaveApply.message = null;
      leave.leaveApply.status = "success";
      leave.leaveApply.error = {};
    },
    leaveApplyRequestFailed: (leave, action) => {
      leave.leaveApply.loading = false;
      leave.leaveApply.message = null;
      leave.leaveApply.status = undefined;
      leave.leaveApply.error = "Error";
    },

    leaveSummaryRequested: (leave, action) => {
      leave.leaveSummary.loading = true;
      leave.leaveSummary.message = null;
      leave.leaveSummary.status = undefined;
      leave.leaveSummary.error = {};
    },
    leaveSummaryReceived: (leave, action) => {
      leave.leaveSummary.data = action.payload.data;
      leave.leaveSummary.loading = false;
      leave.leaveSummary.lastFetch = Date.now();
      leave.leaveSummary.message = null;
      leave.leaveSummary.status = "success";
      leave.leaveSummary.error = {};
    },
    leaveSummaryRequestFailed: (leave, action) => {
      leave.leaveSummary.loading = false;
      leave.leaveSummary.message = null;
      leave.leaveSummary.status = undefined;
      leave.leaveSummary.error = "Error";
    },

    leaveTeamSummaryRequested: (leave, action) => {
      leave.leaveTeamSummary.loading = true;
      leave.leaveTeamSummary.message = null;
      leave.leaveTeamSummary.status = undefined;
      leave.leaveTeamSummary.error = {};
    },
    leaveTeamSummaryReceived: (leave, action) => {
      leave.leaveTeamSummary.data = action.payload.data;
      leave.leaveTeamSummary.loading = false;
      leave.leaveTeamSummary.lastFetch = Date.now();
      leave.leaveTeamSummary.message = null;
      leave.leaveTeamSummary.status = "success";
      leave.leaveTeamSummary.error = {};
    },
    leaveTeamSummaryRequestFailed: (leave, action) => {
      leave.leaveTeamSummary.loading = false;
      leave.leaveTeamSummary.message = null;
      leave.leaveTeamSummary.status = undefined;
      leave.leaveTeamSummary.error = "Error";
    },

    leaveStatusUpdateRequested: (leave, action) => {
      leave.leaveStatusUpdate.loading = true;
      leave.leaveStatusUpdate.message = null;
      leave.leaveStatusUpdate.status = undefined;
      leave.leaveStatusUpdate.error = {};
    },
    leaveStatusUpdateReceived: (leave, action) => {
      leave.leaveStatusUpdate.data = action.payload.data;
      leave.leaveStatusUpdate.loading = false;
      leave.leaveStatusUpdate.lastFetch = Date.now();
      leave.leaveStatusUpdate.message = null;
      leave.leaveStatusUpdate.status = "success";
      leave.leaveStatusUpdate.error = {};
    },
    leaveStatusUpdateRequestFailed: (leave, action) => {
      leave.leaveStatusUpdate.loading = false;
      leave.leaveStatusUpdate.message = null;
      leave.leaveStatusUpdate.status = undefined;
      leave.leaveStatusUpdate.error = "Error";
    },

    leaveAvailableRequested: (leave, action) => {
      leave.leaveAvailable.loading = true;
      leave.leaveAvailable.message = null;
      leave.leaveAvailable.status = undefined;
      leave.leaveAvailable.error = {};
    },
    leaveAvailableReceived: (leave, action) => {
      leave.leaveAvailable.data = action.payload.data;
      leave.leaveAvailable.loading = false;
      leave.leaveAvailable.lastFetch = Date.now();
      leave.leaveAvailable.message = null;
      leave.leaveAvailable.status = "success";
      leave.leaveAvailable.error = {};
    },
    leaveAvailableRequestFailed: (leave, action) => {
      leave.leaveAvailable.loading = false;
      leave.leaveAvailable.message = null;
      leave.leaveAvailable.status = undefined;
      leave.leaveAvailable.error = "Error";
    },

    leaveCompensatoryRequested: (leave, action) => {
      leave.leaveCompensatory.loading = true;
      leave.leaveCompensatory.message = null;
      leave.leaveCompensatory.status = undefined;
      leave.leaveCompensatory.error = {};
    },
    leaveCompensatoryReceived: (leave, action) => {
      leave.leaveCompensatory.data = action.payload.data;
      console.log(
        "leaveApplyReceived data here =========",
        action.payload.data
      );
      leave.leaveCompensatory.loading = false;
      leave.leaveCompensatory.lastFetch = Date.now();
      leave.leaveCompensatory.message = null;
      leave.leaveCompensatory.status = "success";
      leave.leaveCompensatory.error = {};
    },
    leaveCompensatoryRequestFailed: (leave, action) => {
      leave.leaveCompensatory.loading = false;
      leave.leaveCompensatory.message = null;
      leave.leaveCompensatory.status = undefined;
      leave.leaveCompensatory.error = "Error";
    },

    leaveCompensatoryUpdateOffRequested: (leave, action) => {
      leave.leaveCompensatoryUpdateOff.loading = true;
      leave.leaveCompensatoryUpdateOff.message = null;
      leave.leaveCompensatoryUpdateOff.status = undefined;
      leave.leaveCompensatoryUpdateOff.error = {};
    },
    leaveCompensatoryUpdateOffReceived: (leave, action) => {
      leave.leaveCompensatoryUpdateOff.data = action.payload.data;
      console.log(
        "leaveApplyReceived data here =========",
        action.payload.data
      );
      leave.leaveCompensatoryUpdateOff.loading = false;
      leave.leaveCompensatoryUpdateOff.lastFetch = Date.now();
      leave.leaveCompensatoryUpdateOff.message = null;
      leave.leaveCompensatoryUpdateOff.status = "success";
      leave.leaveCompensatoryUpdateOff.error = {};
    },
    leaveCompensatoryUpdateOffRequestFailed: (leave, action) => {
      leave.leaveCompensatoryUpdateOff.loading = false;
      leave.leaveCompensatoryUpdateOff.message = null;
      leave.leaveCompensatoryUpdateOff.status = undefined;
      leave.leaveCompensatoryUpdateOff.error = "Error";
    },

    clearMessagesRequested: (leave) => {
      leave.status = undefined;
      leave.message = null;
      leave.error = {};
    },
    clearUserListStorageRequested: () => initialState,

    currentSelectedRow: (leave, action) => {
      leave.statusManagement.rowData = action.payload;
    },
  },
});
 
const {
  leaveApplyRequested,
  leaveApplyReceived,
  leaveApplyRequestFailed,

  leaveSummaryRequested,
  leaveSummaryReceived,
  leaveSummaryRequestFailed,

  leaveTeamSummaryRequested,
  leaveTeamSummaryReceived,
  leaveTeamSummaryRequestFailed,

  leaveStatusUpdateRequested,
  leaveStatusUpdateReceived,
  leaveStatusUpdateRequestFailed,

  leaveAvailableRequested,
  leaveAvailableReceived,
  leaveAvailableRequestFailed,

  leaveCompensatoryRequested,
  leaveCompensatoryReceived,
  leaveCompensatoryRequestFailed,

  leaveCompensatoryUpdateOffRequested,
  leaveCompensatoryUpdateOffReceived,
  leaveCompensatoryUpdateOffRequestFailed,

  clearMessagesRequested,
  clearUserListStorageRequested,

  currentSelectedRow,
  statusChange,
} = slice.actions;
export default slice.reducer;

// Action Creators
export const leaveApply = (userId, employeeId, leaveType, leaveStartDate,leaveEndDate,leaveReason, bioId) => (
  dispatch,
  getState
) => {
  console.log("Invoking leaveApply Panel Api", userId, employeeId, leaveType, leaveStartDate, leaveEndDate, leaveReason, bioId);
  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: {
        feature: "LEAVE_MANAGEMENT",
        featureType: "LEAVE_APPLY",
        userId,
        employeeId,
        leaveType,
        leaveStartDate,
        leaveEndDate,
        leaveReason,
        bioId,
      },
      onStart: leaveApplyRequested.type,
      onSuccess: leaveApplyReceived.type,
      onError: leaveApplyRequestFailed.type,
    })
  );
};

export const leaveSummary = (userId) => (
    dispatch,
    getState
  ) => {
    console.log("Invoking leaveSummary Panel Api", userId);
    dispatch(
      apiCallBegan({
        url: "/api/v1/service",
        method: "post",
        data: {
          feature:"LEAVE_MANAGEMENT",
          featureType:"LEAVE_SUMMARY",
          userId
        },
        onStart: leaveSummaryRequested.type,
        onSuccess: leaveSummaryReceived.type,
        onError: leaveSummaryRequestFailed.type,
      })
    );
};

export const leaveTeamSummary = (userId) => (
  dispatch,
  getState
) => {
  console.log("Invoking leaveTeamSummary Panel Api", userId);
  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: {
        feature:"LEAVE_MANAGEMENT",
        featureType:"TEAM_SUMMARY",
        userId
      },
      onStart: leaveTeamSummaryRequested.type,
      onSuccess: leaveTeamSummaryReceived.type,
      onError: leaveTeamSummaryRequestFailed.type,
    })
  );
};

export const leaveStatusUpdate= (userId, teamId, statusOptionData, comment,bioId) => (
  dispatch,
  getState
) => {
  console.log("Invoking leaveStatusUpdate Panel Api1", userId, teamId, statusOptionData, comment,bioId);
  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: {
        feature: "LEAVE_MANAGEMENT",
        featureType: "LEAVE_STATUS_UPDATE",
        userId: userId,
        id: teamId,
        status: statusOptionData,
        comment: comment,
        bioId
        // approvedBy:teamfullName
      },
      onStart: leaveStatusUpdateRequested.type,
      onSuccess: leaveStatusUpdateReceived.type,
      onError: leaveStatusUpdateRequestFailed.type,
    })
  );
};

export const leaveAvailable = (userId) => (
  dispatch,
  getState
) => {
  console.log("Invoking leaveAvailable Panel Api", userId);
  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: {
        feature:"LEAVE_MANAGEMENT",
        featureType:"LEAVE_AVAILABLE",
        userId,
},
      onStart: leaveAvailableRequested.type,
      onSuccess:leaveAvailableReceived.type,
      onError: leaveAvailableRequestFailed.type,
    })
  );
};

export const leaveCompensatory = (userId) => (dispatch, getState) => {
  console.log("Invoking leaveCompensatory Panel Api", userId);
  dispatch(
    apiCallBegan({
      url: "/api/v1/service",
      method: "post",
      data: {
        feature: "LEAVE_MANAGEMENT",
        featureType: "COMPENSATORY_OFF",
        userId,
      },
      onStart: leaveCompensatoryRequested.type,
      onSuccess: leaveCompensatoryReceived.type,
      onError: leaveCompensatoryRequestFailed.type,
    })
  );
};

export const leaveCompensatoryUpdateOff = (userId, bioId) => (
         dispatch,
         getState
       ) => {
         console.log("Invoking leaveStatusUpdate Panel Api2", userId, bioId);
         dispatch(
           apiCallBegan({
             url: "/api/v1/service",
             method: "post",
             data: {
               feature: "LEAVE_MANAGEMENT",
               featureType: "UPDATE_COMPENSATORY_OFF",
               userId,
               bioId,
             },
             onStart: leaveCompensatoryUpdateOffRequested.type,
             onSuccess: leaveCompensatoryUpdateOffReceived.type,
             onError: leaveCompensatoryUpdateOffRequestFailed.type,
           })
         );
       };

export const clearMessages = () => (dispatch) => {
  console.log("in clearMessages...");
  dispatch(clearMessagesRequested());
};

export const clearUserListStorage = () => (dispatch) => {
  console.log("in clearUserListStorageRequested...");
  dispatch(clearUserListStorageRequested());
};
