import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table';
import matchSorter from 'match-sorter'
import Alert from 'react-bootstrap/Alert';
import LoadingOverlay from 'react-loading-overlay';
import Loader from '../../../Loader/Loader';
import { useDispatch, useSelector } from "react-redux";
import Moment from 'react-moment';
import moment from 'moment';
import { Parser } from "json2csv";
import s3BucketConfig from '../../../../utils/s3BucketConfig';
import sampleCSVFile from "../../files/VerificationAllocation.csv";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../../_metronic/_partials/controls";
import './../../css/dataImport.scss';
import { VerificationAllocationTable } from '../../../TableConstants/tableConstant';
import { VerificationTable } from '../../../TableConstants/tableConstant';
import { saveFileUploadRequest, uploadClearMessages } from '../../../../store/upload';
import { getLongRunningProcess } from '../../../../store/longRunningProcess';
import LongProcessFailed from "../../../commonaModal/LongProcessFailed";
import styled from 'styled-components'
import { processOnData } from '../../DataImportProcess';
import { customFileName } from '../../../../utils/utils';

const Styles = styled.div`
  table {
    th{
      padding: 0px;
    }
  }
`;

let s3Bucket;
function VerificationAllocation(props) {
    const [columns1, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [displayError, setDisplayError] = useState(false);

    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [longRunningData, setLongRunningData] = useState([]);
    const [showLongProcessModal, setShowLongProcessModal] = useState(false);
    const [failedReason, setFailedReason] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [FileNameData, setFileNameData] = useState(null);
    console.log("FileNameData----12",FileNameData);
    const [pageLoader, setPageLoader] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.entities.auth.data);
    console.log("user-----12",user)
    
    let columns = [], list2;
    let mandatoryYes = [], invalidCSV = false, errorMsg = 'CSV file does not contain proper record , please refer sample csv file.';

    let setLimit = 200;
    const { status, error, loading, message } = useSelector((state) => state.entities.upload);
    const longRunningProcessData = useSelector(state => state.entities.longRunningProcess);
    const { offset, limit, hasMore, total } = useSelector((state) => state.entities.longRunningProcess);
    console.log('longRunningProcessData:---', longRunningProcessData);

    const longRunningProcessColumn = React.useMemo(
        () => [
            {
                Header: <div style={{ textAlign: 'center' }}>File Name</div>,
                accessor: 'name',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },

            {
                Header: <div>Total Record</div>,
                accessor: 'totalRecord',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: <div>Start Date</div>,
                accessor: 'createdAt',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    let createdAt = cell.row.original.createdAt;
                    if (createdAt) {
                        let createdAtArray = createdAt.split(".");
                        return <Moment format="DD-MM-YYYY hh:mm">{createdAtArray[0]}</Moment>;
                    }
                    else {
                        return '';
                    }
                },
            },
            {
                Header: <div>End Date</div>,
                accessor: 'updatedAt',
                Cell: ({ cell }) => {
                    let updatedAtArray = (cell.row.original.updatedAt).split(".");
                    if (cell.row.original.status === 'COMPLETED') {
                        return (
                            <div> {cell.row.original.updatedAt ? <Moment format="DD-MM-YYYY hh:mm">{updatedAtArray[0]}</Moment> : ''} </div>
                        )
                    } else if (cell.row.original.status === 'FAILED') {
                        return (
                            
                            <div> {cell.row.original.updatedAt ? <Moment format="DD-MM-YYYY hh:mm">{updatedAtArray[0]}</Moment> : ''} </div>
                        )
                    } else {
                        return (
                            <div> </div>
                        )
                    }

                },
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: <div>Status</div>,
                accessor: 'status',
                Cell: ({ cell }) => {
                    console.log(" cell ========12 ", cell.row.original.fileName);
                    if (cell.row.original.status === 'COMPLETED') {
                        return (
                            <div style={{ paddingLeft: '10px' }}>
                                <span className="label label label-light-success label-inline font-weight-bold py-4 pl-0" style={{ background: 'none', color: 'darkgreen' }}>
                                    COMPLETED
                                </span>

                            </div>
                        )
                    } else if (cell.row.original.status === 'FAILED') {
                        return (
                            <div className="long-process-modal-div" style={{ paddingLeft: '10px' }} onClick={()=>longProcessFailedClick(cell.row.original.failedReason)}>
                                <span className="label label label-light-danger label-inline font-weight-bold py-4 pl-0" style={{ background: 'none' }}>
                                    FAILED
                                </span>

                            </div>
                        )
                    } else if (cell.row.original.status === 'PROCESSING') {
                        return (
                            <div style={{ paddingLeft: '10px' }}>
                                <span className="label label label-inline font-weight-bold py-4 pl-0" style={{ background: 'none', color: '#fcba03' }}>
                                    PROCESSING
                                </span>

                            </div>
                        )
                    } else {
                        return (
                            <div style={{ paddingLeft: '10px' }}>
                                <span className="label label label-light-primary label-inline font-weight-bold py-4" style={{ background: 'none', color: 'black' }}>
                                    {cell.row.original.status}
                                </span>
                            </div>
                        )
                    }

                },
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: <div>Action</div>,
                accessor: 'fileName',
                Cell: ({ cell }) => {
                    return (
                        <div>
                            <a
                                className="btn btn-sm btn-clean btn-icon view-log-detail"
                                title="Download"
                                href={cell.row.original.fileName}
                                target="_blank"
                            >
                                <i className="flaticon2-download-2"></i>
                            </a>
                           
                        </div>

                    )
                  
                },
                disableSortBy: true,
                disableFilters: true,
            },

        ],
        []
    )

    useEffect(() => {
        if (status === "error") {
            let errorMsg = "Something went wrong. Please try again.";
            if (typeof error === "object") {
                errorMsg = error.message;
            }
            else {
                errorMsg = error;
            }
            setErrorAlertMsg(errorMsg);
            setShowErrorAlert(true);
            setTimeout(() => {
                dispatch(uploadClearMessages());
                setErrorAlertMsg('');
                setShowErrorAlert(false);
            }, 4000);
        }
    }, [status]);

    useEffect(() => {
        if (status === "success" && !loading && message !== null) {
            setShowSuccessAlert(true);
            setSuccessAlertMsg(message);
            setData([]);
            setTimeout(() => {
                setShowSuccessAlert(false);
                setSuccessAlertMsg(null);
                dispatch(uploadClearMessages());
                setData([]);
                columns = [];
            }, 3000)
            dispatch(getLongRunningProcess(user.userId, 0, setLimit));
        }
    }, [status, loading, message]);

    useEffect(() => {
        if (!longRunningProcessData.data) {
            dispatch(getLongRunningProcess(user.userId, 0, setLimit));
        }
       
        if (longRunningProcessData.data) {
            console.log(" longRunningProcessData.data ===== ", longRunningProcessData.data.data);
            let temp = []
            for (let index = 0; index < longRunningProcessData.data.data.length; index++) {
                let element = longRunningProcessData.data.data[index];
                if (element.operation === 'VERIFICATION_ALLOCATION') {
                    temp.push(element)
                }
            }
            console.log(" temp ", temp);
            setLongRunningData(temp);
        }
        if (hasMore === true && loading === false) {
            dispatch(getLongRunningProcess(user.userId, (offset + setLimit), setLimit));
        }

    }, [longRunningProcessData.data]);


    //set table column from constant file
    for (const iterator of VerificationAllocationTable) {
        if (iterator.displayOnUI == 'Y') {
            columns.push(
                {
                    Header: iterator.tableColumnName == 'Loan Id' ? "Loan Id" : iterator.tableColumnName,
                    accessor: iterator.csvColumnName,
                    filter: 'fuzzyText',
                    disableSortBy: true,
                    disableFilters: true,
                }
            )
        }
    }

    // process CSV data
    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        let list = processOnData(dataString);
        // prepare columns list from headers
        const columns1 = headers.map(c => ({
            name: c,
            selector: c,
        }));
        list2 = JSON.parse(JSON.stringify(list));
        for (let i in list2) {
            delete list2[i].id;
        }
        //mandatory column added in array variable
        for (const iterator of VerificationAllocationTable) {
            if (iterator.mandatory == 'Y') {
                mandatoryYes.push(iterator.csvColumnName);
            }
        }
        // Expect input as d/m/y
        function isValidDate(s) {
            var bits = s.split('/');
            var d = new Date(bits[2], bits[1] - 1, bits[0]);
            return d && (d.getMonth() + 1) == bits[1];
        }

        //loop through check mandatory column and their values
        list2.forEach((element, index) => {

            if (element.agencyAllocationDate) {
                let isDate = isValidDate(element.agencyAllocationDate);
                console.log(" isDate -------- ", isDate);
                if (!isDate) {
                    errorMsg = `Missing or invalid data at Record #${index + 1}.`;
                    // invalidCSV = true;
                }
            }

            mandatoryYes.forEach(mandatoryYesElement => {

                if (!element[mandatoryYesElement]) {
                    errorMsg = `Missing or invalid data at Record #${index + 1}.`;
                    // invalidCSV = true;
                }
            });
        });

        //if valid csv then display in table otherwise throwing error
        if (!invalidCSV) {
            setData(list2);
            setDisplayError(false);
            setShowErrorAlert(false);
        }
        else {
            setData([]);
            setErrorAlertMsg(errorMsg);
            setShowErrorAlert(true);
        }
        setColumns(columns1);
    }

    //upload csv to server
    //upload csv to server
    async function uploadCSV() {
        try {
            if (data) {
                setPageLoader(true);
                s3Bucket = await s3BucketConfig("VERIFICATION_ALLOCATION", 'VERIFICATION');
                console.log(" s3Bucket ........ ", s3Bucket);
                if (s3Bucket) {
                    let financierId = "";
                    for (const iterator of data) {
                        if (iterator.financierId) {
                            financierId = iterator.financierId;
                        }
                    }
                    
                    const fileName = FileNameData;
                    const params = {
                        ACL: 'public-read',
                        Body: selectedFile,
                        Key: fileName
                    };
                    s3Bucket.putObject(params)
                        .on('httpUploadProgress', (evt) => {
                            // setProgress(Math.round((evt.loaded / evt.total) * 100))
                        })
                        .send((err) => {
                            if (err) {
                                console.log(" err ------ ", err);
                                setPageLoader(false);
                                setErrorAlertMsg("Something went wrong. Please try again.");
                                setShowErrorAlert(true);
                                setTimeout(() => {
                                    setErrorAlertMsg("");
                                    setShowErrorAlert(false);
                                }, 4000);
                            }
                            else {
                                setSelectedFile(null);
                                const postData = {
                                    'feature': "LONG_RUNNING_FILE_UPLOAD",
                                    'userId': user.userId,
                                    'fileName': fileName,
                                    'operation': "VERIFICATION_ALLOCATION",
                                    'totalRecord': data.length,
                                    'financierId': financierId || ""
                                };
                                setPageLoader(false);
                                dispatch(saveFileUploadRequest(postData));
                            }
                        });
                }
                else {
                    setPageLoader(false);
                    setErrorAlertMsg("Something went wrong. Please try again.");
                    setShowErrorAlert(true);
                    setTimeout(() => {
                        setErrorAlertMsg("");
                        setShowErrorAlert(false);
                    }, 4000);
                }
            }
        } catch (e) {
            setPageLoader(false);
            setErrorAlertMsg("Something went wrong. Please try again.");
            setShowErrorAlert(true);
            setTimeout(() => {
                setErrorAlertMsg("");
                setShowErrorAlert(false);
            }, 4000);
        }
    }

    //sample csv download function
    let sampleTemplate = [], myData = [];
    let CSVData = null;
    for (const iterator of VerificationAllocationTable) {
        sampleTemplate.push(iterator.csvColumnName);
    }
    if (sampleTemplate) {
        const fields = sampleTemplate;
        const opts = { fields };

        try {
            const parser = new Parser(opts);
            const csv = parser.parse(myData);
            CSVData = sampleCSVFile;
        } catch (err) {
            console.error(err);
        }
    }


    // handle file upload
    const handleFileUpload = e => {
        // console.log('data length:', e);
        // console.log('data length:', data);
        const file = e.target.files[0];
        const nameOfFile = customFileName(e.target.files[0].name);
        // console.log("filenamecsv-----------", nameOfFile);
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
        setSelectedFile(e.target.files[0]);
        setFileNameData(nameOfFile)
        e.target.value = '';
    }

    const refreshDataExport = () => {
        if (user) {
            dispatch(getLongRunningProcess(user.userId, 0, setLimit));
        }
    }

    const longProcessFailedClick = (failedReason) => {
        setFailedReason(failedReason)
        setShowLongProcessModal(true)

    }

    const hideEditModal = () => {
        setShowLongProcessModal(false);
    }


    return (
        <div className="row m-0 loanDataImport">
            <Card className="col-12">
                <CardHeader className="col-4">
                    <div className="col-2">
                        <div>
                            <label className="csvLabel mb-0">
                                <input type="file"
                                    accept=".csv"
                                    onChange={handleFileUpload} required
                                />
                                <span className='btn btn-primary font-weight-bold transformBtn csvSpan'>
                                    Select CSV</span>
                            </label>
                        </div>

                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-center">
                        {showErrorAlert &&
                            <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {errorAlertMsg}
                            </Alert>
                        }
                        {showSuccessAlert &&
                            <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                                {successAlertMsg}
                            </Alert>
                        }
                    </div>
                    <div className="col-4 d-flex justify-content-end align-items-center">

                        <button
                            id="CSV"
                            type="button"
                            className="settingBtn font-weight-bold download-csv transformBtn"
                        // onClick={()=>sampleCSVDownload()}
                        >

                         
                            <a href={sampleCSVFile} download="CPV_Allocation_Template.csv">CPV Allocation Template</a>
                        </button>
                    </div>
                </CardHeader>
                {/* <CardBody>
                    <DataTable
                        pagination
                        highlightOnHover
                        columns={columns1}
                        data={data}
                    />
                </CardBody> */}
            </Card>


            <div className="col-12 pl-0 pr-0 customCardDiv">
            {loading ? <Loader /> : " "}
                    <Card className="uploadedDataCard">
                        <CardHeader className="col-3 ml-0 mr-0 pl-2 pr-2" title="CPV  Allocation">
                            <div className="col-2"></div>
                            <div className="col-5"></div>
                            {data.length !== 0 && (
                                <div className="col-2 d-flex justify-content-center">
                                    <button className='btn btn-primary font-weight-bold transformBtn uploadCSVBtn'
                                        onClick={() => uploadCSV()}
                                    >Confirm</button>
                                </div>
                            )}
                            {data.length === 0 && (

                                <div className="col-md-2">
                                    <button className="defaultBtn transformBtn float-right" style={{ paddingRight: '10%' }} onClick={refreshDataExport} >
                                        <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                                    </button>
                                </div>
                            )}
                        </CardHeader>


                        
                            <CardBody className="customCardBody">
                                {data.length !== 0 && (
                                    <Styles>
                                        <Table columns={columns} data={data} />
                                    </Styles>
                                )}
                                {data.length == 0 && (
                                    <Styles>
                                        <Table columns={longRunningProcessColumn} data={longRunningData} />
                                    </Styles>
                                )}
                                <LongProcessFailed showModal={showLongProcessModal} hideModal={hideEditModal} failedReason={failedReason} />
                            </CardBody>
                        

                        
                    </Card>

                {/* </LoadingOverlay> */}
            </div>

        </div>
    )
}

export default VerificationAllocation;

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
            Search:{' '}
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                className="searchFilter"
            />
        </span>
    )
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <div>
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value.toString().toLowerCase() || undefined) // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 5 }
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,//sort data
        usePagination,//use pagination
    )

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    const firstPageRows = rows.slice(0, 10);
    return (
        <>
      {/* {longRunningProcessData && longRunningProcessData.agentData && longRunningProcessData.agentData.length > 0 && ( */}
            <div>
                <div className="tblScroll">
                    <table {...getTableProps()} className="loanTableImport loanUpload" style={{ width: '100%' }}>
                        <thead className="tableHead">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                                    {headerGroup.headers.map((column, index) => (
                                        <th key={index}>
                                            <table style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th className='updateleagalfith' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render('Header')}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? '↓'
                                                                        : '↑'
                                                                    : ''}
                                                            </span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>{column.canFilter ? column.render('Filter') : null}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            <tr>
                                <th
                                    colSpan={visibleColumns.length}
                                    style={{
                                        textAlign: 'left',
                                    }}
                                >
                                    {/* <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                /> */}
                                </th>
                            </tr>
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} className="tableTd">{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
                <div className="row m-0">
                    <div className="pagination col-12">
                        <div className="col-2">
                            <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>{' '}
                        </div>
                        <div className="col-2">
                            <span>
                                Total Records{' '}
                                <strong>
                                    {/* {data.length} */}
                                    {rows.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-2">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-3">
                            <span>
                                Go to page{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(page)
                                    }}
                                    className="searchFilter"
                                />
                            </span>{' '}
                        </div>
                        <div className="col-3">
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                className="selectDisplayNoOfRecords"
                            >
                                {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                {/* <div>Showing the first 20 results of {rows.length} rows</div>
        <div>
          <pre>
            <code>{JSON.stringify(state.filters, null, 2)}</code>
          </pre>
        </div> */}
            </div>
        {/* )} */}
        </>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'
