import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../store/authentication';

const LocationChecker = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.entities.auth.data);
    const [isAllowed, setIsAllowed] = useState(false);
    useEffect(() => {
        // console.log(" first ---- ", user);
        if (Object.keys(user).length && user.specailAccess != 1) {
            // Function to check the user's location
            const checkLocation = () => {
                // Get user's current position using the Geolocation API
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        console.log(" position.coords ", position.coords);
                    const { latitude, longitude } = position.coords;
                    const allowedLatitude = 19.0751997; // Example latitude
                    const allowedLongitude = 73.0197571; // Example longitude
                    const allowedRadius = 2; // 0.5 kilometers (500 meters)
            
                    // Calculate distance between user's position and the allowed location
                    const distance = getDistance(latitude, longitude, allowedLatitude, allowedLongitude);
                        console.log(" distance ", distance);
                        // Check if the distance is within the allowed radius
                        if (distance <= allowedRadius || user.specailAccess) {
                            // console.log(" if specailAccess");
                            setIsAllowed(true);
                        } else {
                            // console.log(" else specailAccess");
                            redirectToLogin();
                        }
                    
                    },
                    (error) => {
                        // console.error('Error getting user location:', error);
                        redirectToLogin();
                        
                    }
                );
            };
        
            checkLocation();
        
            const interval = setInterval(checkLocation, 10000);
        
            return () => clearInterval(interval);
        }
    }, [user]);

// Function to calculate distance between two coordinates using Haversine formula
const getDistance = (lat1, lon1, lat2, lon2) => {
    const earthRadius = 6371; // Radius of the earth in kilometers
    const dLat = degToRad(lat2 - lat1);
    const dLon = degToRad(lon2 - lon1);
    const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;
    return distance;
};

// Function to convert degrees to radians
const degToRad = (deg) => {
    return deg * (Math.PI / 180);
};
const redirectToLogin = () => {
    let urls = window.location.href;
    let urlSplit = urls.split('/');
    // Taking last array value
    let getCurrentUrl = urlSplit.slice(-1).pop();
    //console.log('check url:', getCurrentUrl);

    if(getCurrentUrl !== 'login'){
        history.push({
            pathname: '/logout',
            sessionTimeOut: 'sessionTimeOut'
        });
    }
}

return (
    <div>
      {/* {isAllowed ? (
        <p>Your location is allowed.</p>
      ) : (
        <p>Your location is not allowed.</p>
      )} */}
    </div>
  );
};

export default LocationChecker;