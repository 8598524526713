import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHistory } from "react-router-dom";
import Moment from 'react-moment';
import image from "../../../../images/AcennaStamp & Signature.jpg"


function CPV_DataOut(props) {

  const history = useHistory();
  const [tableDataCPV, setTableDataCPV] = useState([]);
  const [userDocument, setUserDocument] = useState();
  console.log("tableDataCPV-----", userDocument)
  // const tableDataCPV = props.location ? props.location.CPVData[0] : "";
  useEffect(() => {
    if (props.location.CPVData) {
      // console.log("propsuserdata---",props.location.CPVData[0].document)
      setTableDataCPV(props.location.CPVData)
      setUserDocument(props.location.CPVData[0].document)
    }
    else {
      history.push({
        pathname: '/import/Search_CPV',
      });
    }
  }, [props])

  const goBackBtn = () => {
    history.push({
      pathname: '/import/Search_CPV',
    });
  }

  return (
    <>
      {/* <Pdf targetRef={ref} filename="document.pdf">
        {({ toPdf }) => (
          <button onClick={toPdf} className="button">
            Generate PDF
          </button>
        )}
      </Pdf> */}
      <div className='mngBtnOnCPV'>
        <button className='printPageButton' onClick={goBackBtn}>Back</button>
        <button className='printPageButton' onClick={() => window.print()}>Print</button>
      </div>
      <div className='CPVTableData' >
        {tableDataCPV && tableDataCPV.map((item, index) => {
          return (
            <div key={index + 1}>
              <table style={{ borderCollapse: 'collapse', width: "100%", backgroundColor: "#fff" }} cellSpacing={0}>
                <tbody>

                  <tr style={{ height: '19pt' }}>
                    <td style={{ width: '510pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                      <p className="s1" style={{ fontSize: '15px', paddingTop: "6pt", fontWeight: "bold", paddingLeft: '181pt', paddingRight: '184pt', textIndent: '0pt', textAlign: 'center' }}>
                        RESIDENCE VERIFICATION REPORT</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '51pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Case ID
                      </p>
                    </td>
                    <td style={{ width: '77pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.caseId}</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Date &amp;
                        Time</p>
                    </td>
                    <td style={{ width: '85pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}><Moment format="DD-MM-YYYY hh:mm">{item.allocationDateTime}</Moment></p>
                    </td>
                    <td style={{ width: '85pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Report Date
                        &amp; Time</p>
                    </td>
                    <td style={{ width: '85pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}><Moment format="DD-MM-YYYY hh:mm">{item.reportingDateTime}</Moment></p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Candidate’s
                        Name</p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.name}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Resident
                        Address</p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.residentAddress}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Person Met
                      </p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.personMet}</p>
                    </td>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Relationship
                      </p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>
                      {item.personMetRelationship == "Others" ? item.otherRelationship :item.personMetRelationship}
                        
                        {/* {item.personMetRelationship} */}
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Marital
                        Status</p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.maritalStatus}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Total
                        Members in the Family</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.totalMembersInFamily}</p>
                    </td>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Dependents
                      </p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.dependents}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Adult</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.adults}</p>
                    </td>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Children</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.children}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Is Spouse
                        Working?</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.isSpouseWorking}</p>
                    </td>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>If Yes,
                        which company</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.spouseCompanyName}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Ownership
                      </p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.ownership}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>No. of years
                        in existing house</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.noOfYearsInExistingHouse}</p>
                    </td>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Locality</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.locality}
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Unit</p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>
                      {item.unit == "Others" ? item.othersUnit : item.unit}

                        {/* {item.unit} */}
                        </p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Construction
                      </p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.construction}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Type of roof
                      </p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.typeOfRoof}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Approx. area
                        of the house (sq. ft.)</p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.approxAreaOfTheHouse}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Assets Seen
                      </p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.assetsSeen}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Purpose</p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>
                        {item.purpose}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '26pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left' }}>
                        Portrait of political leaders in the house</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.portraitOfThePoliticalLeadersInHouse}</p>
                    </td>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Any loans
                        taken</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.anyLoansTaken}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>If yes,
                        financier</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.loanTakenFinancierName}</p>
                    </td>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>EMI</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.emi}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Proposed
                        property location</p>
                    </td>
                    <td style={{ width: '382pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>{item.proposedPropertyLocation}</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '510pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Comments
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>
                        Accessibility of the Resi with direction</p>
                    </td>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      {/* <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Na</p> */}
                    </td>
                  </tr>
                  <tr style={{ height: '36pt' }}>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Remark</p>
                    </td>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      {/* <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', paddingRight: '2pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'justify' }}>
                  Our area executive visited on provided address and met the applicant Mr.Nidhin. He has shared
                  Residence related information and provided required documents.</p> */}
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Did the
                        customer apply previously</p>
                    </td>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      {/* <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>NA</p> */}
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Date of
                        previous FI</p>
                    </td>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      {/* <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>NA</p> */}
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Status</p>
                    </td>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      {/* <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Recommended
                </p> */}
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '510pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>Reasons for
                        not recommended (Please circle) </p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '12pt', textIndent: '0pt', textAlign: 'left' }}>Out of
                        geographical limits</p>
                    </td>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>Existing
                        loan defaulter</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '12pt', textIndent: '0pt', textAlign: 'left' }}>Negative
                        feedback from colleagues</p>
                    </td>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>Politically
                        connected / Union leader</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '12pt', textIndent: '0pt', textAlign: 'left' }}>Negative
                        Area</p>
                    </td>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>4th class
                        staff</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '12pt', textIndent: '0pt', textAlign: 'left' }}>Negative
                        profile</p>
                    </td>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '3pt', paddingLeft: '12pt', textIndent: '0pt', textAlign: 'left' }}>Drunkard
                        /drug addict /major vices</p>
                    </td>
                  </tr>
                  <tr style={{ height: '16pt' }}>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '12pt', textIndent: '0pt', textAlign: 'left' }}>De-dup
                        match</p>
                    </td>
                    <td style={{ width: '255pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                      <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>Others /
                        Irregular in office</p>
                    </td>
                  </tr>
                  <tr style={{ height: '64pt' }}>
                    <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                      <p className="s2" style={{ paddingLeft: '3pt', paddingRight: '7pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left' }}>
                        Photo of the Documents/Premises</p>
                    </td>
                    <td style={{ width: '127pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      {/* <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s2" style={{ paddingLeft: '3pt', textIndent: '0pt', textAlign: 'left' }}>YES</p> */}
                    </td>
                    <td style={{ width: '153pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2}>
                      <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                      <p className="s2" style={{ paddingLeft: '7pt', textIndent: '0pt', textAlign: 'left' }}>Final Admin Approval</p>
                    </td>
                    <td style={{ width: '102pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                      <p style={{ textIndent: '0pt', textAlign: 'center'}}><br />
                      <img  className="stampAcenna"src={image} alt="Image description"></img>
                      </p>
                      <p style={{ paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}><span>
                      </span></p><table border={0} cellSpacing={0} cellPadding={0}>
                        <tbody><tr>
                          <td>
                          </td>
                        </tr>
                        </tbody></table>
                      <p />
                    </td>
                  </tr>
                  <tr style={{ height: '22pt' }}>
                    <td style={{ width: '510pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                      <p style={{ paddingTop: '3pt', paddingLeft: '3pt', paddingRight: '5pt', textIndent: '0pt', lineHeight: '117%', textAlign: 'left' }}>
                        <a href="#" className="s4" target="_blank">Disclaimer :- Informations
                          Provided through this document are highly confidential in nature. By registering for such a
                          service and utilizing the services the user gives his assent to the terms &amp; conditions as
                          mentioned in www.verificationbazaar.com</a></p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className='col-lg-12'>
                <div className='row'>

                  {userDocument && userDocument.map((docItem, index) => {
                    // console.log("docItem---", docItem)
                    return (
                      <div key={index + 1} className='col-lg-4 col-sm-4'>
                        <div className="imageviewCPV">
                          <img alt='photo' className='photopdfimageCPV' src={docItem.filePath} />
                          {/* <div className="bottomInfoCPV">
                          <div className="googlemapimgCPV">
                            <a target="_blank" href="javascript:void(0)">
                              <img
                                alt="mapicon"
                                src={toAbsoluteUrl("/media/img/mapback.jpg")}
                              />
                            </a>
                          </div>
                          <div className="imagePersonInfocpv">
                            <p>{item.residentAddress}</p>
                            <p>28 Dec 2022, 17:40 PM</p>
                          </div>
                        </div> */}
                        </div>
                      </div>

                    )
                  })}
                </div>

              </div>
            </div>

          )

        })}


      </div>
    </>
  )
}

export default CPV_DataOut;