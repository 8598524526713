import firebase from 'firebase';
import environment from './env.json'; 


let firebaseConfig = null; 

if(environment.environment == 'Production') {
    firebaseConfig = {
        apiKey: "AIzaSyDzQhl9uZc5e0qupZs-BVvbsAihxxNrWRc",
        authDomain: "acennacollect-a03cc.firebaseapp.com",
        projectId: "acennacollect-a03cc",
        storageBucket: "acennacollect-a03cc.appspot.com",
        messagingSenderId: "655000916219",
        appId: "1:655000916219:web:38e66bd8dcd71f41c9205e",
        measurementId: "G-8WFVX4RDLX"
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyCtslKRYvyTAWlIMPNC1t25pPkSpf4ckeg",
        authDomain: "betaacennacollect.firebaseapp.com",
        projectId: "betaacennacollect",
        storageBucket: "betaacennacollect.appspot.com",
        messagingSenderId: "307575975864",
        appId: "1:307575975864:web:c3beb57a1800943e79a94d",
        measurementId: "G-BPZZ5PF776"
    };
}

firebase.initializeApp(firebaseConfig);
export default firebase