import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import LoadingOverlay from "react-loading-overlay";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
import "../../Loan/css/loan.scss";
import "../../CollectionAgency/css/agencyDetails.scss";
import Loader from "../../Loader/Loader";
import {
  getAllMonth,
  getCurrentYearMonthDate,
  getYears,
} from "../../../utils/helperFunctions";
import { useSticky } from "react-table-sticky";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useExpanded,
  useBlockLayout,
} from "react-table";
import matchSorter from "match-sorter";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  fieldMisPerformance,
  fieldMisPerformanceClearMessages,
} from "../../../store/dashboard";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import Collapse from "@material-ui/core/Collapse";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  fieldMisLocationLists,
  getFosAndReportingToList,
} from "../../../store/userList";

const Styles = styled.div`
  padding: 1rem;

  .table {
    border: 1px solid #ddd;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;
      vertical-align: middle;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#000",
  },
}));


var d = new Date(),
  currentmonth = "" + ("0" + (d.getMonth() + 1)).slice(-2),
  // month = "05",
  day = "" + d.getDate(),
  currentyear = d.getFullYear();
let tablePageSize;
const FieldMisPerformanceTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const fetchIdRef = useRef(0);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(currentyear);
  const [month, setMonth] = useState(currentmonth);
  const [tableData, setTableData] = useState([]);
  const [tableDataCal, setTableDataCal] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [agentList, setAgentList] = React.useState([]);
  const [LocationItem, setLocationItem] = React.useState([]);
  const [reportingToList, setReportingToList] = React.useState([]);
  const [reportingToLocation, setreportingToLocation] = React.useState([]);
  const [LocationName, setLocationName] = React.useState();
  const [MonthName, setMonthName] = React.useState();
  const [YearName, setYearName] = React.useState();
  const [agentId, setAgentId] = React.useState();
  const [reportingToId, setReportingToId] = React.useState();
  const [fetchButtonclicked, setFetchButtonclicked] = useState(false);
  const [refreshButtonclicked, setrefreshButtonclicked] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const user = useSelector((state) => state.entities.auth.data);
  const fosAndReportingToUsers = useSelector(
    (state) => state.entities.userList.fosAndReportingToList.data
  );
  const fieldMisLocationListItem = useSelector(
    (state) => state.entities.userList.fieldMisLocationList.data
  );
  const { loading, error, status, data: fieldMisPerformanceData } = useSelector(
    (state) => state.entities.dashboard.fieldMisPerformance
  );

  console.log(
    "fieldMisLocationListItem ------------ ",
    fieldMisLocationListItem
  );
  // console.log(" fieldmisLocationList ------------ ", fieldMisLocationList(user.userId));
  console.log("fosAndReportingToUsers ------------ ", fosAndReportingToUsers);
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    let temp = [];
    for (let index = 2019; index <= 2030; index++) {
      temp[index] = { value: index, label: index };
    }
    setYears(temp);
  }, []);

  const numFormatter = (num) => {
    let toFixe = 4;
    if (num <= 0) {
      return num.toFixed(2);
    } else if (num > 999 && num < 9999) {
      toFixe = 3;
    } else if (num >= 10000) {
      toFixe = 2;
    }
    return (num / 1000000).toFixed(toFixe);
  };

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "FOS Name",
        sticky: 'left',
        width: 300,
        accessor: "fosName",
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Reporting To",
        sticky: 'left',
        width: 300,
        accessor: "reportingToName",
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Location",
        width: 300,
        accessor: "assignedCity",
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Total Allocation",
        width: 150,
        accessor: (value) =>
          value.totalAllocation ? value.totalAllocation : 0,
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "POS",
        Header: (
          <div>
            <div>Total POS</div>
            <div>(M)</div>
          </div>
        ),
        width: 150,
        accessor: (value) =>
          numFormatter(value.totalPos ? value.totalPos : 0, { space: true }),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "mtdCollection",
        Header: (
          <div>
            <div>MTD Collection</div>
            <div>(M)</div>
          </div>
        ),
        width: 180,
        accessor: (value) =>
          numFormatter(value.mtdCollection ? value.mtdCollection : 0, {
            space: true,
          }),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "ftdCollection",
        Header: (
          <div>
            <div>FTD Collection</div>
            <div>(M)</div>
          </div>
        ),
        width: 150,
        accessor: (value) =>
          numFormatter(value.ftdCollection ? value.ftdCollection : 0, {
            space: true,
          }),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "MTD Visit Count",
        width: 150,
        accessor: (value) => (value.mtdVisitCount ? value.mtdVisitCount : 0),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "FTD Visit Count",
        width: 150,
        accessor: (value) => (value.ftdVisitCount ? value.ftdVisitCount : 0),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Untouched Visit",
        width: 150,
        accessor: (value) =>
          value.untouchedVisitCount > 0 ? value.untouchedVisitCount : 0,
        // (currentGas <= GAS_CAP ? (GAS_CAP - currentGas) : 0 );
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },

      {
        id: "paidAmount",
        width: 100,
        Header: (
          <div>
            <div>PAID</div>
            <div>(%)</div>
          </div>
        ),
        // accessor: ( feildWisePerformanceData )  => (feildWisePerformanceData .paid/feildWisePerformanceData.totalPos*100).toFixed(2),
        // accessor: (value) => (numFormatter(value.totalPos ? value.resolution : 0, { space: true })),
        // accessor: (value) => (value.resolution ? value.resolution : 0).toFixed(2),

        // accessor: (value) => (numFormatter(value.paidAmount ? value.paidAmount : 0, { space: true })),
        // Footer: value => {
        //     let fieldWisePerformanceData = useSelector((state) => state.entities.dashboard.fieldetWisePerformance.data[1][0]);
        //     return <><div className="">{fieldWisePerformanceData.totalPaidAmount !== 0 ? (numFormatter(fieldWisePerformanceData.totalPaidAmount)) : 0}</div></>;
        // },

        accessor: (value) => {
          console.log("value", value);
          // return (value.paid ? value.paid : 0).toFixed(2);
          return ((value.paidAmount / value.totalPos) * 100).toFixed(2);
        },

        //   Footer: value => {
        //       let fieldWisePerformanceData = useSelector((state) => state.entities.dashboard.fieldWisePerformance.data[1][0]);
        //         return <><div className="">{fieldWisePerformanceData.totalpaid ? (fieldWisePerformanceData.paid/fieldWisePerformanceData.totalPos * 100).toFixed(2) : 0}</div></>;

        //   },

        // accessor: (value) => (value.paid/value.totalPos*100).toFixed(2),

        filter: "fuzzyText",
        width: 100,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "ptp",
        width: 100,
        Header: (
          <div>
            <div>PTP</div>
            <div>(%)</div>
          </div>
        ),
        accessor: (value) => {
          // console.log('value', value )
          // return (value.paid ? value.paid : 0).toFixed(2);
          return ((value.ptp / value.totalPos) * 100).toFixed(2);
        },
        // accessor: (value) => (value.ptp ? value.ptp : 0).toFixed(2),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "cb",
        width: 100,
        Header: (
          <div>
            <div>REVISIT</div>
            <div>(%)</div>
          </div>
        ),
        accessor: (value) => {
          // console.log('value', value )
          // return (value.paid ? value.paid : 0).toFixed(2);
          return ((value.cb / value.totalPos) * 100).toFixed(2);
        },
        // accessor: (value) => (value.revisit ? value.revisit : 0).toFixed(2),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "bptp",
        width: 100,
        Header: (
          <div>
            <div>BPTP</div>
            <div>(%)</div>
          </div>
        ),
        accessor: (value) => {
          // console.log('value', value )
          // return (value.paid ? value.paid : 0).toFixed(2);
          return ((value.bptp / value.totalPos) * 100).toFixed(2);
        },
        // accessor: (value) => (value.bptp ? value.bptp : 0).toFixed(2),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "dispute",
        width: 100,
        Header: (
          <div>
            <div>DISPUTE</div>
            <div>(%)</div>
          </div>
        ),
        accessor: (value) => {
          // console.log('value', value )
          // return (value.paid ? value.paid : 0).toFixed(2);
          return ((value.dispute / value.totalPos) * 100).toFixed(2);
        },
        // accessor: (value) => (value.dispute ? value.dispute : 0).toFixed(2),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "bfu",
        width: 100,
        Header: (
          <div>
            <div>BFU</div>
            <div>(%)</div>
          </div>
        ),
        accessor: (value) => {
          // console.log('value', value )
          // return (value.paid ? value.paid : 0).toFixed(2);
          return ((value.bfu / value.totalPos) * 100).toFixed(2);
        },
        // accessor: (value) => (value.bfu ? value.bfu : 0).toFixed(2),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "rtp",
        width: 100,
        Header: (
          <div>
            <div>RTP</div>
            <div>(%)</div>
          </div>
        ),
        accessor: (value) => {
          // console.log('value', value )
          // return (value .paid ? value.paid : 0).toFixed(2);
          return ((value.rtp / value.totalPos) * 100).toFixed(2);
        },
        // accessor: (value) => (value.rtp ? value.rtp : 0).toFixed(2),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "lm",
        width: 100,
        Header: (
          <div>
            <div>LM</div>
            <div>(%)</div>
          </div>
        ),
        accessor: (value) => {
          // console.log('value', value )
          // return (value.paid ? value.paid : 0).toFixed(2);
          return ((value.lm / value.totalPos) * 100).toFixed(2);
        },
        // accessor: (value) => (value.lm ? value.lm : 0).toFixed(2),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "sft",
        width: 100,
        Header: (
          <div>
            <div>SFD</div>
            <div>(%)</div>
          </div>
        ),
        accessor: (value) => {
          // console.log('value', value )
          // return (value.paid ? value.paid : 0).toFixed(2);
          return ((value.sfd / value.totalPos) * 100).toFixed(2);
        },
        // accessor:(value) => (value.sft ? value.sft : 0).toFixed(2),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "dl",
        width: 100,
        Header: (
          <div>
            <div>DL</div>
            <div>(%)</div>
          </div>
        ),
        accessor: (value) => {
          // console.log('value', value )
          // return (value.paid ? value.paid : 0).toFixed(2);
          return ((value.dl / value.totalPos) * 100).toFixed(2);
        },
        // accessor:(value) =>(value.dl ? value.dl : 0) .toFixed(2),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "anf",
        width: 100,
        Header: (
          <div>
            <div>ANF</div>
            <div>(%)</div>
          </div>
        ),
        accessor: (value) => {
          // console.log('value', value )
          // return (value.paid ? value.paid : 0).toFixed(2);
          return ((value.anf / value.totalPos) * 100).toFixed(2);
        },
        // accessor:(value) =>(value.anf ? value.anf : 0) .toFixed(2),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      // {
      //     id: 'revisit',
      //     Header: <div><div>REVISIT</div><div>(%)</div></div>,
      //     accessor:(value) =>(value.revisit ? value.revisit : 0) .toFixed(2),
      //     filter: 'fuzzyText',
      //     disableSortBy: true,
      //     disableFilters: true,
      // },

      // {
      //     id: 'rnr',
      //     Header: <div><div>RNR</div><div>(%)</div></div>,
      //     accessor: (value) => (value.rnr ? value.rnr : 0).toFixed(2),
      //     filter: 'fuzzyText',
      //     disableSortBy: true,
      //     disableFilters: true,
      // },
      // {
      //     id: 'nc',
      //     Header: <div><div>NC</div><div>(%)</div></div>,
      //     accessor: (value) => (value.nc ? value.nc : 0).toFixed(2),
      //     filter: 'fuzzyText',
      //     disableSortBy: true,
      //     disableFilters: true,
      // },
      // {
      //     id: 'dnc',
      //     Header: <div><div>DNC</div><div>(%)</div></div>,
      //     accessor: (value) => (value.dnc ? value.dnc : 0).toFixed(2),
      //     filter: 'fuzzyText',
      //     disableSortBy: true,
      //     disableFilters: true,
      // },
    ],
    []
  );

  useEffect(() => {
    if (status === "error") {
      let errorMsg = "Something went wrong. Please try again.";
      if (typeof error === "object") {
        errorMsg = error.message;
      } else {
        errorMsg = error;
      }
      setErrorAlertMsg(errorMsg);
      setShowErrorAlert(true);
      setTableData([]);
      setTableDataCal([]);
      setTotalData(0);
      setPageCount(0);

      setTimeout(() => {
        dispatch(fieldMisPerformanceClearMessages());
        setErrorAlertMsg("");
        setShowErrorAlert(false);
      }, 2000);
    }
  }, [status]);

  useEffect(() => {
    setTableData(fieldMisPerformanceData.data);
    setTableDataCal(fieldMisPerformanceData.data);
    setTotalData(fieldMisPerformanceData.total);
    setPageCount(Math.ceil(fieldMisPerformanceData.total / tablePageSize));
  }, [fieldMisPerformanceData]);

  const dataCsv = fieldMisPerformanceData.data;
  console.log("dataCsv---------", dataCsv);

  useEffect(() => {
    let tempCsvData = [];
    if (dataCsv && dataCsv !== null) {
      for (let index = 0; index < dataCsv.length; index++) {
        const tempSingle = dataCsv[index];

        let newSingleData = {};
        newSingleData.id = tempSingle.id;
        newSingleData.year = tempSingle.year;
        newSingleData.month = tempSingle.month;
        newSingleData.agentId = tempSingle.agentId;
        newSingleData.fosName = tempSingle.fosName;
        newSingleData.reportingTo = tempSingle.reportingTo;
        newSingleData.reportingToName = tempSingle.reportingToName;
        newSingleData.totalAllocation = tempSingle.totalAllocation;
        newSingleData.totalPos = tempSingle.totalPos
          ? parseFloat(tempSingle.totalPos).toFixed(2)
          : 0;
        newSingleData.assignedCity = tempSingle.assignedCity;
        newSingleData.mtdCollection = tempSingle.mtdCollection;
        newSingleData.mtdVisitCount = tempSingle.mtdVisitCount;
        newSingleData.dispositionDate = tempSingle.dispositionDate;
        newSingleData.ftdCollecton = tempSingle.ftdCollecton;
        newSingleData.untouchedVisitCount = tempSingle.untouchedVisitCount > 0 ? tempSingle.untouchedVisitCount : 0;
        newSingleData.resolution = tempSingle.resolution
          ? parseFloat(tempSingle.resolution).toFixed(2)
          : 0;
        newSingleData.paidAmount = tempSingle.paidAmount
          ? parseFloat(tempSingle.paidAmount).toFixed(2)
          : 0;
        newSingleData.ptp = tempSingle.ptp
          ? parseFloat((tempSingle.ptp / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.cb = tempSingle.cb
          ? parseFloat((tempSingle.cb / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.bptp = tempSingle.bptp
          ? parseFloat((tempSingle.bptp / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.dispute = tempSingle.dispute
          ? parseFloat(
              (tempSingle.dispute / tempSingle.totalPos) * 100
            ).toFixed(2)
          : 0;
        newSingleData.bfu = tempSingle.bfu
          ? parseFloat((tempSingle.bfu / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.rtp = tempSingle.rtp
          ? parseFloat((tempSingle.rtp / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.lm = tempSingle.lm
          ? parseFloat((tempSingle.lm / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.rnr = tempSingle.rnr
          ? parseFloat((tempSingle.rnr / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.dl = tempSingle.dl
          ? parseFloat((tempSingle.dl / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.nc = tempSingle.nc
          ? parseFloat((tempSingle.nc / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.sfd = tempSingle.sfd
          ? parseFloat((tempSingle.sfd / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.anf = tempSingle.anf
          ? parseFloat((tempSingle.anf / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.dnc = tempSingle.dnc
          ? parseFloat((tempSingle.dnc / tempSingle.totalPos) * 100).toFixed(2)
          : 0;
        newSingleData.pending = tempSingle.pending
          ? parseFloat(
              (tempSingle.pending / tempSingle.totalPos) * 100
            ).toFixed(2)
          : 0;
        newSingleData.startDate = tempSingle.startDate;
        newSingleData.createdAt = tempSingle.createdAt;
        newSingleData.updatedAt = tempSingle.updatedAt;
        newSingleData.ftdVisitCount = tempSingle.ftdVisitCount;
        // newSingleData.dues = tempSingle.dues ? parseFloat(tempSingle.dues).toFixed(2) : 0;
        // newSingleData.totalCases = tempSingle.totalCases;
        // newSingleData.ptp = tempSingle.ptp ? parseFloat((tempSingle.ptp / tempSingle.totalPos )*100).toFixed(2) : 0;
        // =======================================================================================================
        // newSingleData.bucket = tempSingle.bucket;
        // newSingleData.description = tempSingle.description;
        // newSingleData.color = tempSingle.color;
        tempCsvData.push(newSingleData);
      }
      setCsvData(tempCsvData);
      console.log(" csvData========= ", tempCsvData);
    }
  }, [dataCsv]);

  // ===================== testing here=========================

  // ===================== testing here=========================

  useEffect(() => {
    if (fieldMisLocationListItem) {
      let LocationtempList = [];
      // console.log("locationSelectData===", fieldMisLocationListItem[0]);
      if (fieldMisLocationListItem.length > 0) {
        // console.log("locationSelectDatalen===", fieldMisLocationListItem);
        for (let index = 0; index < fieldMisLocationListItem.length; index++) {
          // console.log("locationSelectDatalen===", index);
          let value = fieldMisLocationListItem[index];
          // console.log("locationSelectDatalen===", value);
          if (value.value != "null") {
            LocationtempList.push({ value: value.value, label: value.label });
            console.log("LocationList===", LocationtempList);
          }
        }
      }
      setLocationItem(LocationtempList);
    } else {
      dispatch(fieldMisLocationLists(user.userId));
    }
  }, [fieldMisLocationListItem]);

  useEffect(() => {
    if (fosAndReportingToUsers) {
      console.log(" fosAndReportingToUsers ====== ", fosAndReportingToUsers);
      let fosTempList = [];
      let reportingToTempList = [];
      if (fosAndReportingToUsers.length > 0) {
        // console.log("fosAndReportingToUsers========", fosAndReportingToUsers);
        for (
          let index = 0;
          index < fosAndReportingToUsers[0].fosData.length;
          index++
        ) {
          let value = fosAndReportingToUsers[0].fosData[index];
          fosTempList.push({ value: value.userId, label: value.fullName });
        }
        for (
          let index = 0;
          index < fosAndReportingToUsers[0].reportingToData.length;
          index++
        ) {
          let value = fosAndReportingToUsers[0].reportingToData[index];
          reportingToTempList.push({
            value: value.userId,
            label: value.fullName,
          });
        }
      }

      setAgentList(fosTempList);
      setReportingToList(reportingToTempList);
      setreportingToLocation(reportingToTempList);
    } else {
      dispatch(getFosAndReportingToList(user.userId));
    }
  }, [fosAndReportingToUsers]);

  const refereshData = () => {};

  useEffect(() => {
    console.log(
      " Reporting to ",
      reportingToId,
      agentId,
      LocationName,
      MonthName,
      YearName
    );
  }, [reportingToId, agentId, LocationName, MonthName, YearName]);

  const fetchTableData = useCallback(
    ({
      pageSize,
      pageIndex,
      reportingToId,
      agentId,
      LocationName,
      MonthName,
      YearName,
    }) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        dispatch(
          fieldMisPerformance(
            user.userId,
            `${startRow}`,
            `${pageSize}`,
            reportingToId,
            agentId,
            LocationName,
            MonthName,
            YearName
          )
        );
      }
    },
    []
  );

  const refreshFieldMisPerformanceTable = () => {
    setExpanded(false);
    setLocationName("");
    setReportingToId("");
    setAgentId("");

    dispatch(
      fieldMisPerformance(
        user.userId,
        user.reportingToId,
        user.agentId,
        user.LocationName,
        MonthName,
        YearName
      )
    );
  };

  console.log("tableData---",tableData);

  return (
    <>
      {loading ? <Loader /> : " "}
      <div className="logs-card-div cityWiseLoanCount">
        {/* mt-6 */}
        <Card className="cityWiseLoanCount-card">
          <CardHeader>
            <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-start flex-column">
              <h3 className="mb-0">Field MIS</h3>
            </div>
            <div className="col-lg-4 mt-4 d-block">
              {showErrorAlert && (
                <Alert
                  variant="danger"
                  onClose={() => setShowErrorAlert(false)}
                  dismissible
                >
                  {errorAlertMsg}
                </Alert>
              )}

              {showSuccessAlert && (
                <Alert
                  className="custom-success-alert"
                  onClose={() => setShowSuccessAlert(false)}
                  dismissible
                >
                  {successAlertMsg}
                </Alert>
              )}
            </div>
            <div className="col-lg-3 fildmiscsv pr-0 d-flex justify-content-end align-items-center">
              <div className="col-lg-3 pr-2 d-flex justify-content- align-items-center">
                {csvData && (
                  <button
                    id="CSV"
                    type="button"
                    className="font-weight-bold download-csv transformBtn"
                  >
                    <CSVLink
                      data={csvData}
                      filename={"FieldMIS.csv"}
                      target="_blank"
                    >
                      <span className="svg-icon svgSetting">
                        <SVG
                          className="svgSetting"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/csv-file-format-extension.svg"
                          )}
                        ></SVG>
                      </span>
                    </CSVLink>
                  </button>
                )}
              </div>

              <div className="col-lg-3 pr-2 d-flex justify-content- align-items-center">
                <button
                  className="defaultBtn transformBtn"
                  onClick={refreshFieldMisPerformanceTable}
                >
                  <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                </button>
              </div>
              <div className="col-lg-3 pr-2 d-flex justify-content- align-items-center">
                <IconButton
                  className={clsx(
                    classes.expand,
                    { [classes.expandOpen]: expanded },
                    "colapsbtnher"
                  )}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </div>
            </div>
          </CardHeader>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardBody className="pt-2 mt-5">
              <div className="d-flex">
                <div className="col-lg-2 ">
                  <div className="form-group">
                    <label>Select Fos</label>
                    <Select
                      // placeholder={sendPushNotificationListData && sendPushNotificationListData.data ? sendPushNotificationListData.sendPushNotificationType : 'Select'}
                      name="sendPushNotificationType"
                      className="jobRoleSelect w-100 calmisset"
                      options={agentList}
                      onChange={(value) => {
                        setAgentId(value.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-2 pl-0">
                  <div className="form-group">
                    <label>Select Team Leader</label>
                    <Select
                      placeholder="Select..."
                      name="role"
                      className="jobRoleSelect w-100 calmisset"
                      options={reportingToList}
                      onChange={(value) => {
                        setReportingToId(value.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-2 pl-0">
                  <div className="form-group">
                    <label>Select Location</label>
                    <Select
                      placeholder="Select..."
                      name="role"
                      className="jobRoleSelect w-100 calmisset"
                      options={LocationItem}
                      onChange={(value) => {
                        setLocationName(value.value);
                      }}
                    />
                  </div>
                </div>

                {year && (
                  <div className="col-lg-2 pl-0 justify-content-center align-items-center">
                    <div className="form-group">
                      <label>Select Year</label>
                      <Select
                        // defaultValue={'2022'}
                        defaultValue={{ label: year, value: year }}
                        placeholder="Select..."
                        name="export_type"
                        className="jobRoleSelect w-100 calmisset"
                        options={years}
                        onChange={(data) => {
                          setYear(data.value);
                        }}
                      />
                    </div>
                  </div>
                )}
                {month && (
                  <div className="col-lg-2 pl-0 justify-content-center align-items-center">
                    <div className="form-group">
                      <label>Select Month</label>
                      <Select
                        defaultValue={getAllMonth(month)}
                        placeholder="Select..."
                        name="export_type"
                        className="jobRoleSelect w-100 calmisset"
                        options={getAllMonth()}
                        onChange={(data) => {
                          // console.log(" test ", data.value);
                          let currentYear = year;
                          if (!currentYear) {
                            currentYear = d.getFullYear();
                          }
                          setMonth(data.value);
                        }}
                      />
                    </div>
                  </div>
                )}

                {/* <div className="col-lg-2 pl-0">
                                    <Formik
                                        initialValues={{nbfc:''}}
                                        onSubmit={(values) => {
                                            console.log();
                                        }}
                                    >
                                        <Form id="createFinancier" className="form fv-plugins-bootstrap fv-plugins-framework">
                                        
                                            <div className="form-group">
                                                <label>City</label>
                                                <Field
                                                    className="form-control form-control-solid h-auto readOnlySpan"
                                                    name="nbfc"
                                                />   
                                            </div>
                                        </Form>
                                    </Formik>

                                </div> */}

                <div
                  className="col-lg-2 pl-0 pt-8"
                  style={{ marginTop: "-4px" }}
                >
                  {/* <div className="form-group"> */}
                  <button
                    id="button"
                    type="button"
                    className="btn btn-primary font-weight-bold searchBtn mr-4 transformBtn w-100"
                    // disabled={isEmailUnique}
                    onClick={() => {
                      setFetchButtonclicked(true);
                      setTimeout(() => {
                        setFetchButtonclicked(false);
                      }, 1000);
                    }}
                  >
                    {" "}
                    Fetch
                  </button>
                  {/* </div> */}
                </div>
              </div>
            </CardBody>
            {/* <CardBody>
                            
                        </CardBody> */}
          </Collapse>

          <CardBody className="p-4">
            {tableData && (
              <Table
                columns={columns}
                data={tableData}
                totalData={totalData}
                fetchData={fetchTableData}
                pageCount={pageCount}
                reportingToId={reportingToId}
                agentId={agentId}
                LocationName={LocationName}
                MonthName={month}
                YearName={year}
                fetchButtonclicked={fetchButtonclicked}
                refreshButtonclicked={refreshButtonclicked}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default FieldMisPerformanceTable;

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  //const count = preFilteredRows.length

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({
  columns,
  data,
  totalData,
  pageCount: controlledPageCount,
  fetchData,
  reportingToId,
  agentId,
  LocationName,
  MonthName,
  YearName,
  fetchButtonclicked,
  refreshButtonclicked,
}) {
  // console.log(" Table columns ======== ", reportingToId, agentId);
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const history = useHistory();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
      manualPagination: true,
      autoResetPage: false,
      pageCount: controlledPageCount,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    useExpanded,
    usePagination, //use pagination
    useBlockLayout,
    useSticky
  );
  const datFetch = () => {
    fetchData({
      pageIndex,
      pageSize,
      reportingToId,
      agentId,
      LocationName,
      MonthName,
      YearName,
    });
  };
  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    tablePageSize = pageSize;
    // fetchData({ pageIndex, pageSize, reportingToId, agentId });
    datFetch();
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    if (fetchButtonclicked) {
      datFetch();
    }
  }, [fetchButtonclicked]);

  page.map((row) => {
    if (!(parseFloat(row.id) % 1)) {
      console.log(" return ");
    }
  });

  let display = "content";

  return (
    <>
      <Styles>
        <div>
          <div className="tblScroll">
          <table {...getTableProps()} className="sticky customListTable table table-bordered flidmistabl">
            <thead
              className="tableHead stickyTop table-fixed tableFixed"
              style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="tableHeader"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "↓"
                            : "↑"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
              {/* <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                </th>
              </tr> */}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="tableTd">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {/* <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot> */}
          </table>
          </div>
          {/* 
                    Pagination can be built however you'd like. 
                    This is just a very basic UI implementation:
                */}
          <div className="row m-0">
            <div className="pagination col-lg-12">
              <div className="col-lg-2">
                <button
                  className="defaultPaginationButton"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>{" "}
                <button
                  className="defaultPaginationButton"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>{" "}
                <button
                  className="defaultPaginationButton"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  {">"}
                </button>{" "}
                <button
                  className="defaultPaginationButton"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>{" "}
              </div>
              <div className="col-lg-2">
                <span>
                  Total Records <strong>
                    {totalData}
                    </strong>
                    {" "}
                </span>
              </div>
              <div className="col-lg-2">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="gotoPageSection">
                  Go to page{" "}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    className="gotoPage"
                  />
                </span>{" "}
              </div>
              <div className="col-lg-3">
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  className="selectDisplayNoOfRecords"
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <br />
          {/* <div>Showing the first 20 results of {rows.length} rows</div>
                    <div>
                        <pre>
                        <code>{JSON.stringify(state.filters, null, 2)}</code>
                        </pre>
                    </div> */}
        </div>
      </Styles>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
