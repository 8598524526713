
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { updateStatus } from '../../store/attendance';
import moment from 'moment';
import { ms } from 'date-fns/locale';
import { valHooks } from 'jquery';

export default function Attendance_modal(props) {

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const updateStatusData = useSelector(state => state.entities.attendance.updateStatus);
    const [approvalStatus, setApprovalStatus] = useState(props.currentRow.statusApproved);
    const [changeApprovalButton, setChangeApprovalButton] = useState(props.currentRow.statusApproved);
    const [showAleart, setshowAleart] = useState(false);
    const [showAleartRemark, setshowAleartRemark] = useState(false);
    const [remark, setRemark] = useState('');
    
    const openmodalhere = () => {
        setShowModal(true);
    }

    const [IsAccept, setIsAccept] = useState(false);
    const [IsReject, setIsReject] = useState(false);

    const Accepthandle = () => {
        setIsReject(false)
        setIsAccept(true);
        setshowAleart(false);
        setshowAleartRemark(false);
    };

    const Rejecthandle = () => {
        setIsAccept(false);
        setIsReject(true);
        // setshowAleart(true);
        setshowAleartRemark(true);

    };

    useEffect(() => {
        // console.log(" updateStatusData ======= ", updateStatusData);
        if (updateStatusData.status && updateStatusData.status == 'success') {
            setChangeApprovalButton(approvalStatus);
        }

    }, [updateStatusData])

    const submitData = () => {
        // console.log(" approvalStatus ", approvalStatus);

        if (!approvalStatus) {
            alert('Please Select Accept Or Reject');
            return
        }
        else {
            let userId = props.currentRow.userId;
            console.log(" props ===== ", props.manageStatus);
            console.log(" currentRow --- ", props.currentRow);
            let currentRowDate = props.currentRow.loggedInDate;
            console.log(" currentRowDate ----- ", currentRowDate);
            let status = props.currentRow.status;
            let getStatus = props.manageStatus.filter((ms) => ms.userId == userId && ms.loginTime == currentRowDate);
            if (getStatus.length > 0) {
                status = getStatus[0].status;
            }
            console.log(" status ----- ", status);
            if (approvalStatus == "Rejected" && remark == "" ) {
                setshowAleart(true);
                return
            }
            dispatch(updateStatus(userId, currentRowDate, status, remark, approvalStatus));
            setShowModal(false);
            // setApprovalStatus();
        }

    }

useEffect(() => {
  function handleEscapeKey(event) {
    console.log("  event.code  ", event.code);
    if (event.code === "Escape") {
      setShowModal(false);
    }
    // document.getElementById("Space").blur();
  }
  document.addEventListener("keydown", handleEscapeKey);
  return () => document.removeEventListener("keydown", handleEscapeKey);
}, []);

    return (
        <div>
            <div className="editModalDiv col-md-3">
                <Modal show={showModal}
                    // onHide={props.hideModal}
                    size="lg"
                    centered
                    backdrop="static"
                    keyboard={false}
                    className="editTodayModalStyle attendanmodalmngpr"
                >
                    <Modal.Header closeButton>
                        <h2 className="mb-0">Attendance Status</h2>
                    </Modal.Header>
                    <Modal.Body className="p-10">
                        <div className="confirmationbtn mb-8">
                            {/* <button type="button" onClick={() => setShowModal(false)} className="btnapcpt">Accept <i className="cherigt fa-solid fa-check"></i></button>
                            <button type="button" onClick={() => { setShowRemark(true) }} className="btnreject">Reject <i className="chewrong fa-solid fa-xmark"></i></button> <br /> */}
                            <div className="wrapper_btnselt">
                                <button onClick={Accepthandle} htmlFor="option-1" className={IsAccept ? 'acceptlisth option option-1' : 'option option-1'}>
                                    <span onClick={() => { setApprovalStatus('Approved') }}>Accept 
                                    <i className="cherigt fa-solid fa-check"></i>
                                    </span> 
                                </button>
                                <button onClick={Rejecthandle} htmlFor="option-2" className={IsReject ? 'rejectlisth option option-2' : 'option option-2'}>
                                    <span onClick={() => { setApprovalStatus('Rejected'); }}>Reject 
                                    <i className="chewrong fa-solid fa-xmark"></i>
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className="remardi">
                            <div className="form-group">

                                <label htmlFor="exampleFormControlTextarea1">Remark</label>
                                <textarea
                                    
                                    onChange={(event) => {
                                        // console.log(" testing ", event.target.value);
                                        setRemark(event.target.value)
                                    }}

                                    value={remark}
                                    className="form-control textacont"
                                    rows="3"
                                >
                                </textarea>
                                {
                                    showAleart ? <p className='requiredRemark'>Remark is required...</p> : null
                                }

                            </div>
                        </div>
                        {/* {
                            showAleart ? <div className="remardi">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlTextarea1">Remark</label>
                                    <textarea required className="form-control textacont" rows="3"></textarea>
                                </div>
                            </div> : null
                        } */}
                        <div className="text-center"> 
                            <button onClick={() => submitData()} id="cancel" type="button" className="btn btn-primary font-weight-bold upload mr-4 transformBtn">Submit</button>
                            <button onClick={() => setShowModal(false)} id="cancel" type="button" className="btn btn-primary font-weight-bold upload mr-4 transformBtn">Cancel</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <h4 className={`${changeApprovalButton} modalpending`} onClick={openmodalhere}>{changeApprovalButton}</h4>
            {/* <h4 className='modaldone'>Done</h4> */}
        </div>
    )
}

