import React from 'react';

function Loader() {
  return (
    <div className='loaderMngProps'>
      <div className='imageTextLoading'>
        <img src='../assets/img/loader.gif' />
        <h4 className='loadingLoaderMsg'>Loading...</h4>
      </div>
    </div>
  )
}

export default Loader;
