import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const initialState = {
  bucketWisePerformance: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  agencyWisePerformance: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  financierWisePerformance: {
    data: {
      performanceData: [],
      totalData: []
    },
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  financierStatus: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  agentActivities: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  
  agentWiseDisposition: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  callerWiseMis: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  callerWiseMisAgentCount: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  tlWiseMis: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  callerServieces: {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  fieldMisPerformance: {
    data: {},
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
  },
  
  setDeviceToken: null

}

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    bucketWisePerformanceRequested: (dashboard, action) => {
      dashboard.bucketWisePerformance.loading = true;
      dashboard.bucketWisePerformance.status = undefined;
      dashboard.bucketWisePerformance.message = null;
      dashboard.bucketWisePerformance.error = {};
    },
    bucketWisePerformanceReceived: (dashboard, action) => {
      dashboard.bucketWisePerformance.data = action.payload.data;
      dashboard.bucketWisePerformance.loading = false;
      dashboard.bucketWisePerformance.lastFetch = Date.now();
      dashboard.bucketWisePerformance.message = null;
      dashboard.bucketWisePerformance.status = "success";
      dashboard.bucketWisePerformance.error = {};
    },
    bucketWisePerformanceRequestFailed: (dashboard, action) => {
      dashboard.bucketWisePerformance.loading = false;
      dashboard.bucketWisePerformance.message = null;
      dashboard.bucketWisePerformance.status = "error";
      dashboard.bucketWisePerformance.error = {};
    },
    agencyWiseperformanceRequested: (dashboard, action) => {
      dashboard.agencyWisePerformance.loading = true;
      dashboard.agencyWisePerformance.status = undefined;
      dashboard.agencyWisePerformance.message = null;
      dashboard.agencyWisePerformance.error = {};
    },
    agencyWiseperformanceReceived: (dashboard, action) => {
      dashboard.agencyWisePerformance.data = action.payload.data;
      dashboard.agencyWisePerformance.loading = false;
      dashboard.agencyWisePerformance.lastFetch = Date.now();
      dashboard.agencyWisePerformance.message = null;
      dashboard.agencyWisePerformance.status = "success";
      dashboard.agencyWisePerformance.error = {};
    },
    agencyWiseperformanceRequestFailed: (dashboard, action) => {
      dashboard.agencyWisePerformance.loading = false;
      dashboard.agencyWisePerformance.message = null;
      dashboard.agencyWisePerformance.status = "error";
      dashboard.agencyWisePerformance.error =
        action.payload.response !== null || action.payload.response !== undefined
          ? action.payload.response.data
          : action.payload.message;
    },

    financierWiseperformanceRequested: (dashboard, action) => {
      dashboard.financierWisePerformance.loading = true;
      dashboard.financierWisePerformance.status = undefined;
      dashboard.financierWisePerformance.message = null;
      dashboard.financierWisePerformance.error = {};
    },
    financierWiseperformanceReceived: (dashboard, action) => {
      dashboard.financierWisePerformance.data = action.payload.data;
      dashboard.financierWisePerformance.loading = false;
      dashboard.financierWisePerformance.lastFetch = Date.now();
      dashboard.financierWisePerformance.message = null;
      dashboard.financierWisePerformance.status = "success";
      dashboard.financierWisePerformance.error = {};
    },
    financierWiseperformanceRequestFailed: (dashboard, action) => {
      dashboard.financierWisePerformance.loading = false;
      dashboard.financierWisePerformance.message = null;
      dashboard.financierWisePerformance.status = "error";
      dashboard.financierWisePerformance.error = "";
    },
    
    fieldMisPerformanceRequested: (dashboard, action) => {
      dashboard.fieldMisPerformance.loading = true;
      dashboard.fieldMisPerformance.status = undefined;
      dashboard.fieldMisPerformance.message = null;
      dashboard.fieldMisPerformance.error = {};
    },
    fieldMisPerformanceReceived: (dashboard, action) => {
      dashboard.fieldMisPerformance.data = action.payload.data;
      dashboard.fieldMisPerformance.loading = false;
      dashboard.fieldMisPerformance.lastFetch = Date.now();
      dashboard.fieldMisPerformance.message = null;
      dashboard.fieldMisPerformance.status = "success";
      dashboard.fieldMisPerformance.error = {};
    },
    fieldMisPerformanceRequestFailed: (dashboard, action) => {
      dashboard.fieldMisPerformance.loading = false;
      dashboard.fieldMisPerformance.message = null;
      dashboard.fieldMisPerformance.status = "error";
      dashboard.fieldMisPerformance.error = 
        action.payload.response !== null || action.payload.response !== undefined
          ? action.payload.response.data
          : action.payload.message;
    },

    financierStatusRequested: (dashboard, action) => {
      dashboard.financierStatus.loading = true;
      dashboard.financierStatus.status = undefined;
      dashboard.financierStatus.message = null;
      dashboard.financierStatus.error = {};
    },
    financierStatusReceived: (dashboard, action) => {
      dashboard.financierStatus.data = action.payload.data;
      dashboard.financierStatus.loading = false;
      dashboard.financierStatus.lastFetch = Date.now();
      dashboard.financierStatus.message = null;
      dashboard.financierStatus.status = "success";
      dashboard.financierStatus.error = {};
    },
    financierStatusRequestFailed: (dashboard, action) => {
      dashboard.financierStatus.loading = false;
      dashboard.financierStatus.message = null;
      dashboard.financierStatus.status = "error";
      dashboard.financierStatus.error =
        action.payload.response !== null || action.payload.response !== undefined
          ? action.payload.response.data
          : action.payload.message;
    },
    fieldMisClearMessagesRequested: (dashboard) => {
      dashboard.fieldMisPerformance.error = {};
      dashboard.fieldMisPerformance.status = undefined;
      dashboard.fieldMisPerformance.message = null;
      dashboard.fieldMisPerformance.loading = false;
    },

    agentActivitiesRequested: (dashboard, action) => {
      dashboard.agentActivities.loading = true;
      dashboard.agentActivities.status = undefined;
      dashboard.agentActivities.message = null;
      dashboard.agentActivities.error = {};
    },
    agentActivitiesReceived: (dashboard, action) => {
      dashboard.agentActivities.data = action.payload.data;
      dashboard.agentActivities.loading = false;
      dashboard.agentActivities.lastFetch = Date.now();
      dashboard.agentActivities.message = null;
      dashboard.agentActivities.status = "success";
      dashboard.agentActivities.error = {};
    },
    agentActivitiesRequestFailed: (dashboard, action) => {
      dashboard.agentActivities.loading = false;
      dashboard.agentActivities.message = null;
      dashboard.agentActivities.status = "error";
      dashboard.agentActivities.error = {};
    },

	agentWiseDispositionRequested: (dashboard, action) => {
		dashboard.agentWiseDisposition.loading = true;
		dashboard.agentWiseDisposition.status = undefined;
		dashboard.agentWiseDisposition.message = null;
		dashboard.agentWiseDisposition.error = {};
	},
	agentWiseDispositionReceived: (dashboard, action) => {
		dashboard.agentWiseDisposition.data = action.payload.data;
		dashboard.agentWiseDisposition.loading = false;
		dashboard.agentWiseDisposition.lastFetch = Date.now();
		dashboard.agentWiseDisposition.message = null;
		dashboard.agentWiseDisposition.status = "success";
		dashboard.agentWiseDisposition.error = {};
	},
	agentWiseDispositionFailed: (dashboard, action) => {
		dashboard.agentWiseDisposition.loading = false;
		dashboard.agentWiseDisposition.message = null;
		dashboard.agentWiseDisposition.status = "error";
		dashboard.agentWiseDisposition.error =
		  action.payload.response !== null || action.payload.response !== undefined
			? action.payload.response.data
			: action.payload.message;
	},

    setDeviceTokenRequested: (dashboard, action) => {
      dashboard.setDeviceToken = action.payload;
    },

    bucketWiseClearMessagesRequested: (dashboard) => {
      dashboard.bucketWisePerformance.error = {};
      dashboard.bucketWisePerformance.status = undefined;
      dashboard.bucketWisePerformance.message = null;
      dashboard.bucketWisePerformance.loading = false;
    },
    agencyWiseClearMessagesRequested: (dashboard) => {
      dashboard.agencyWisePerformance.error = {};
      dashboard.agencyWisePerformance.status = undefined;
      dashboard.agencyWisePerformance.message = null;
      dashboard.agencyWisePerformance.loading = false;
    },
    financierWiseClearMessagesRequested: (dashboard) => {
      dashboard.financierWisePerformance.error = {};
      dashboard.financierWisePerformance.status = undefined;
      dashboard.financierWisePerformance.message = null;
      dashboard.financierWisePerformance.loading = false;
    },

    financierStatusClearMessagesRequested: (dashboard) => {
      dashboard.financierStatus.error = {};
      dashboard.financierStatus.status = undefined;
      dashboard.financierStatus.message = null;
      dashboard.financierStatus.loading = false;
    },
    agentActivitiesClearMessagesRequested: (dashboard) => {
      dashboard.agentActivities.error = {};
      dashboard.agentActivities.status = undefined;
      dashboard.agentActivities.message = null;
      dashboard.agentActivities.loading = false;
    },

    callerWiseMisRequested: (dashboard, action) => {
      dashboard.callerWiseMis.loading = true;
      dashboard.callerWiseMis.status = true;
      dashboard.callerWiseMis.message = null;
      dashboard.callerWiseMis.error = {};
    },
    callerWiseMisReceived: (dashboard, action) => {
      dashboard.callerWiseMis.data = action.payload.data;
      dashboard.callerWiseMis.loading = false;
      dashboard.callerWiseMis.lastFetch = Date.now();
      dashboard.callerWiseMis.message = null;
      dashboard.callerWiseMis.status = "success";
      dashboard.callerWiseMis.error = {};
    },
    callerWiseMisFailed: (dashboard, action) => {
      dashboard.callerWiseMis.loading = false;
      dashboard.callerWiseMis.message = null;
      dashboard.callerWiseMis.status = "error";
      dashboard.callerWiseMis.error = "";
    },

    callerWiseMisAgentCountRequested: (dashboard, action) => {
      dashboard.callerWiseMisAgentCount.loading = true;
      dashboard.callerWiseMisAgentCount.status = true;
      dashboard.callerWiseMisAgentCount.message = null;
      dashboard.callerWiseMisAgentCount.error = {};
    },
    callerWiseMisAgentCountReceived: (dashboard, action) => {
      dashboard.callerWiseMisAgentCount.data = action.payload.data;
      dashboard.callerWiseMisAgentCount.loading = false;
      dashboard.callerWiseMisAgentCount.lastFetch = Date.now();
      dashboard.callerWiseMisAgentCount.message = null;
      dashboard.callerWiseMisAgentCount.status = "success";
      dashboard.callerWiseMisAgentCount.error = {};
    },
    callerWiseMisAgentCountFailed: (dashboard, action) => {
      dashboard.callerWiseMisAgentCount.loading = false;
      dashboard.callerWiseMisAgentCount.message = null;
      dashboard.callerWiseMisAgentCount.status = "error";
      dashboard.callerWiseMisAgentCount.error = "";
    },

    tlWiseMisRequested: (dashboard, action) => {
      dashboard.tlWiseMis.loading = true;
      dashboard.tlWiseMis.status = true;
      dashboard.tlWiseMis.message = null;
      dashboard.tlWiseMis.error = {};
    },
    tlWiseMisReceived: (dashboard, action) => {
      dashboard.tlWiseMis.data = action.payload.data;
      dashboard.tlWiseMis.loading = false;
      dashboard.tlWiseMis.lastFetch = Date.now();
      dashboard.tlWiseMis.message = null;
      dashboard.tlWiseMis.status = "success";
      dashboard.tlWiseMis.error = {};
    },
    tlWiseMisFailed: (dashboard, action) => {
      dashboard.tlWiseMis.loading = false;
      dashboard.tlWiseMis.message = null;
      dashboard.tlWiseMis.status = "error";
      dashboard.tlWiseMis.error = "";
    },

    callerServiecesRequested: (dashboard, action) => {
        dashboard.callerServieces.loading = true;
        dashboard.callerServieces.status = true;
        dashboard.callerServieces.message = null;
        dashboard.callerServieces.error = {};
    },
    callerServiecesReceived: (dashboard, action) => {
        dashboard.callerServieces.data = action.payload.data;
        dashboard.callerServieces.loading = false;
        dashboard.callerServieces.lastFetch = Date.now();
        dashboard.callerServieces.message = null;
        dashboard.callerServieces.status = "success";
        dashboard.callerServieces.error = {};
    },
    callerServiecesFailed: (dashboard, action) => {
        dashboard.callerServieces.loading = false;
        dashboard.callerServieces.message = null;
        dashboard.callerServieces.status = "error";
        dashboard.callerServieces.error = "";
    },

    dashboardClearStorageRequested: () => initialState,
  },
});

const {
  bucketWisePerformanceRequested,
  bucketWisePerformanceReceived,
  bucketWisePerformanceRequestFailed,

  agencyWiseperformanceRequested,
  agencyWiseperformanceReceived,
  agencyWiseperformanceRequestFailed,

  financierWiseperformanceRequested,
  financierWiseperformanceReceived,
  financierWiseperformanceRequestFailed,

  fieldMisPerformanceRequested,
  fieldMisPerformanceReceived,
  fieldMisPerformanceRequestFailed,
  fieldMisClearMessagesRequested,

  financierStatusRequested,
  financierStatusReceived,
  financierStatusRequestFailed,

  agentActivitiesRequested,
  agentActivitiesReceived,
  agentActivitiesRequestFailed,

  agentWiseDispositionRequested,
  agentWiseDispositionReceived,
  agentWiseDispositionFailed,

  setDeviceTokenRequested,

  bucketWiseClearMessagesRequested,
  agencyWiseClearMessagesRequested,
  financierWiseClearMessagesRequested,
  financierStatusClearMessagesRequested,
  agentActivitiesClearMessagesRequested,

  callerWiseMisRequested,
  callerWiseMisReceived,
  callerWiseMisFailed,

  callerWiseMisAgentCountRequested,
  callerWiseMisAgentCountReceived,
  callerWiseMisAgentCountFailed,

  tlWiseMisRequested,
  tlWiseMisReceived,
  tlWiseMisFailed,

  callerServiecesRequested,
  callerServiecesReceived,
  callerServiecesFailed,

  dashboardClearStorageRequested
} = slice.actions;
export default slice.reducer;

// Action Creators

export const bucketWisePerformance = (userId, agencyId, year, month, financierId) => (dispatch, getState) => {
  console.log("Invoking bucketWisePerformance Api", userId, agencyId, year, month, financierId);

  dispatch(
    apiCallBegan({
      url: '/api/v1/service',
      method: 'post',
      data: { feature: 'BUCKETWISE_PERFORMANCE', userId, agencyId, year, month, financierId },
      onStart: bucketWisePerformanceRequested.type,
      onSuccess: bucketWisePerformanceReceived.type,
      onError: bucketWisePerformanceRequestFailed.type,
    })
  );
};

export const agencyWisePerformance = (userId, agencyId, year, month,financierId) => (dispatch, getState) => {
  console.log("Invoking agencyWisePerformance Api", userId);

  dispatch(
    apiCallBegan({
      url: '/api/v1/service',
      method: 'post',
      data: { feature: 'AGENCYWISE_PERFORMANCE', userId, agencyId, year, month, financierId },
      onStart: agencyWiseperformanceRequested.type,
      onSuccess: agencyWiseperformanceReceived.type,
      onError: agencyWiseperformanceRequestFailed.type,
    })
  );
};

export const financierWisePerformance = (userId, agencyId, year, month, bucketWiseFilter) => (dispatch, getState) => {
  console.log("Invoking financierWisePerformance Api", userId);

  dispatch(
    apiCallBegan({
      url: '/api/v1/service',
      method: 'post',
      data: { feature: 'FINANCIERWISE_PERFORMANCE', userId, agencyId, year, month, bucketWiseFilter },
      onStart: financierWiseperformanceRequested.type,
      onSuccess: financierWiseperformanceReceived.type,
      onError: financierWiseperformanceRequestFailed.type,
    })
  );
};

export const fieldMisPerformance = (userId, offset, limit, reportingToId, agentId, LocationName, MonthName, YearName) => (dispatch, getState) => {
  console.log("Invoking fieldMisPerformance Api", reportingToId, agentId);

  dispatch(
    apiCallBegan({
      url: '/api/v1/service',
      method: 'post',
      data: { feature: 'FIELD_MIS_PERFORMANCE', userId, offset, limit, reportingToId, agentId, LocationName, month:MonthName, year:YearName},
      onStart: fieldMisPerformanceRequested.type,
      onSuccess: fieldMisPerformanceReceived.type,
      onError: fieldMisPerformanceRequestFailed.type,
    })
  );
};

export const financierStatus = (userId, agencyId, startDate, endDate, financierId) => (dispatch, getState) => {
  console.log("Invoking financierStatus Api", userId, agencyId, startDate, endDate, financierId);

  dispatch(
    apiCallBegan({
      url: '/api/v1/service',
      method: 'post',
      data: { feature: 'DASHBOARD_FINANCIER_STATUS', userId, agencyId, startDate, endDate,financierId },
      onStart: financierStatusRequested.type,
      onSuccess: financierStatusReceived.type,
      onError: financierStatusRequestFailed.type,
    })
  );
};

export const getAgentActivities = (userId, startDate, endDate) => (dispatch, getState) => {
  console.log("Invoking agentActivities Api", userId, startDate, endDate);

  dispatch(
    apiCallBegan({
      url: '/api/v1/service',
      method: 'post',
      data: { feature: 'AGENT_ACTIVITIES', userId, startDate, endDate },
      onStart: agentActivitiesRequested.type,
      onSuccess: agentActivitiesReceived.type,
      onError: agentActivitiesRequestFailed.type,
    })
  );
};

export const getAgentWiseDisposition = (userId, startDate, endDate) => (dispatch, getState) => {
  console.log("Invoking agentActivities Api", userId, startDate, endDate);

  dispatch(
    apiCallBegan({
      url: '/api/v1/service',
      method: 'post',
      data: { feature: 'AGENT_WISE_DISPOSITIONS', userId, startDate, endDate },
      onStart: agentWiseDispositionRequested.type,
      onSuccess: agentWiseDispositionReceived.type,
      onError: agentWiseDispositionFailed.type,
    })
  );
};

export const setToken = (data) => (dispatch, getState) => {
  dispatch(setDeviceTokenRequested(data));
};

export const bucketWisePerformanceClearMessages = () => (dispatch) => {
  console.log("in bucketWisePerformanceClearMessages...");
  dispatch(bucketWiseClearMessagesRequested());
};

export const agencyWisePerformanceClearMessages = () => (dispatch) => {
  console.log("in agencyWisePerformanceClearMessages...");
  dispatch(agencyWiseClearMessagesRequested());
};

export const financierWisePerformanceClearMessages = () => (dispatch) => {
  console.log("in financierWisePerformanceClearMessages...");
  dispatch(financierWiseClearMessagesRequested());
};

export const fieldMisPerformanceClearMessages = () => (dispatch) => {
  console.log("in fieldMisPerformanceClearMessages...");
  dispatch(fieldMisClearMessagesRequested());
};

export const financierStatusClearMessages = () => (dispatch) => {
  console.log("in financierStatusClearMessages...");
  dispatch(financierStatusClearMessagesRequested());
};

export const agentActivitiesClearMessages = () => (dispatch) => {
  console.log("in agentActivitiesClearMessages...");
  dispatch(agentActivitiesClearMessagesRequested());
};

export const dashboardClearStorage = () => (dispatch) => {
  console.log("in dashboardClearStorage...");
  dispatch(dashboardClearStorageRequested());
};

export const callerWiseMisData = (userId, startDate, financierId) => (dispatch, getState) => {
	console.log("Invoking bucketWisePerformance Api", userId, startDate, financierId);
  
	dispatch(
	  apiCallBegan({
		url: '/api/v1/service',
		method: 'post',
		data: { feature: 'PERFORMANCE_CALLER_WISE_MIS', userId, startDate, financierId },
		onStart: callerWiseMisRequested.type,
		onSuccess: callerWiseMisReceived.type,
		onError: callerWiseMisFailed.type,
	  })
	);
};

export const callerWiseMisAgentCountData = (userId, startDate, financierId) => (dispatch, getState) => {
	console.log("Invoking bucketWisePerformance Api", userId, startDate, financierId);
  
	dispatch(
	  apiCallBegan({
		url: '/api/v1/service',
		method: 'post',
		data: { feature: 'PERFORMANCE_CALLER_WISE_AGENT_COUNT', userId, startDate, financierId },
		onStart: callerWiseMisAgentCountRequested.type,
		onSuccess: callerWiseMisAgentCountReceived.type,
		onError: callerWiseMisAgentCountFailed.type,
	  })
	);
};

export const tlWiseMisData = (userId, startDate, financierId) => (dispatch, getState) => {
	console.log("Invoking bucketWisePerformance Api", userId, startDate, financierId);
  
	dispatch(
	  apiCallBegan({
		url: '/api/v1/service',
		method: 'post',
		data: { feature: 'PERFORMANCE_TL_WISE_MIS', userId, startDate, financierId },
		onStart: tlWiseMisRequested.type,
		onSuccess: tlWiseMisReceived.type,
		onError: tlWiseMisFailed.type,
	  })
	);
};

export const callerServieces = (userId, startDate) => (dispatch, getState) => {
	console.log("Invoking bucketWisePerformance Api", userId, startDate);
  
	dispatch(
	  apiCallBegan({
		url: '/api/v1/service',
		method: 'post',
		data: { feature: 'GET_LOAN_TRANSACTION_SERVICES', userId, startDate },
		onStart: callerServiecesRequested.type,
		onSuccess: callerServiecesReceived.type,
		onError: callerServiecesFailed.type,
	  })
	);
};