import React, { useState, useEffect, Fragment } from "react";
//import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
//import LogDetailsModal from './components/LogDetailsModal';
//import $ from "jquery";
import "datatables.net/js/jquery.dataTables";
import "datatables.net-bs4/js/dataTables.bootstrap4.js";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-buttons-bs4";
import { useHistory } from "react-router-dom";

import "../../Loan/css/loan.scss";
import "../css/bucketWiseTable.scss";

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy, useExpanded } from 'react-table';
import matchSorter from 'match-sorter';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "../../Loader/Loader";
import Alert from 'react-bootstrap/Alert';

import FinancierWiseSubRows from "./FinancierWiseSubRows";

import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
//import { toAbsoluteUrl } from "../../../../layout/_metronic/_helpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
//import PrimaryEmail from "../../Loan/components/PrimaryEmail";
//import PrimaryContact from "../../Loan/components/PrimaryContact";
import { financierWisePerformanceClearMessages, financierWisePerformance } from "../../../store/dashboard";
import Select, { components } from 'react-select';
import { getAllMonth, getCurrentYearMonthDate, getYears } from "../../../utils/helperFunctions";


function FinancierWisePerformaceTable() {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
//   const [years, setYears] = useState(getYears());
  const [year, setYear] = useState(getCurrentYearMonthDate('year'));
  const [month, setMonth] = useState(getCurrentYearMonthDate('month'));
  const [csvData,setCsvData] = useState([]);
  //const [pageLoader, setPageLoader] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.entities.auth.data);
  let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.performanceData);
  console.log("financierWisePerformanceData table Data:", financierWisePerformanceData);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const numFormatter = (num) => {
    let toFixe = 4;
    if (num <= 0) {
      return num.toFixed(2)
    } else if (num > 999 && num < 9999) {
      toFixe = 3
    } else if (num >= 10000) {
      toFixe = 2
    }
    return (num / 1000000).toFixed(toFixe);
  }

  var date = new Date();
  var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  let [startDate, setStartDate] = useState(new Date(firstDay));
  let [endDate, setEndDate] = useState(new Date(lastDay));
  let [bucketWiseFilter, setBucketWiseFilter] = useState(-1);

  const columns = React.useMemo(
    () => [

      // {
      //   // Build our expander column
      //   id: "expander", // Make sure it has an ID
      //   Header: () => null,
      //   Cell: ({ row }) => {
      //       //console.log('row:', row);
      //       return row.canExpand ? (
      //           <span
      //               {...row.getToggleRowExpandedProps({
      //                   style: {
      //                       paddingLeft: `${row.depth * 2}rem`,
      //                   },
      //               })}
      //           >
      //               {row.isExpanded ? (<i className="fas fa-angle-down"></i>) : (<i className="fas fa-angle-right"></i>)}
      //           </span>
      //       ) : null
      //   }
      // },
      {
        Header: 'financier Name',
        accessor: 'financierName',
        // accessor: (value) => {
        //   return <div style={{ textAlign: 'left', width: '160px' }}>{value.financierName}</div>;
        // },
        Footer: <div className="mt-1 tblFooterColor text-left">Total</div>,
        disableFilters: true,
      },
      {
        Header: 'Total Cases',
        accessor: (value) => (value.totalCases ? value.totalCases : 0),
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">{(financierWisePerformanceData && financierWisePerformanceData.totalCases !== 0) ? financierWisePerformanceData.totalCases : 0}</div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>POS</div><div>(M)</div></div>,
        id: 'POS',
        accessor: (value) => (numFormatter(value.pos ? value.pos : 0)),
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalPos !== 0) ? (numFormatter(financierWisePerformanceData.totalPos ? financierWisePerformanceData.totalPos : 0)) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>dues</div><div>(M)</div></div>,
        id: 'dues',
        accessor: (value) => (numFormatter(value.dues ? value.dues : 0)),
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalDues !== 0) ? (numFormatter(financierWisePerformanceData.totalDues ? financierWisePerformanceData.totalDues : 0)) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      // {
      //   Header: <div><div>Collection</div><div>(M)</div></div>,
      //   id: 'Collection',
      //   accessor: (value) => (numFormatter(value.amountCollected ? value.amountCollected : 0)),
      //   Footer: value => {
      //     let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
      //     return <><div className="mt-4 tblFooterColor">
      //       {(financierWisePerformanceData && financierWisePerformanceData.totalAmountCollected !== 0) ? (millify(financierWisePerformanceData.totalAmountCollected, { space: true })) : 0}
      //     </div></>;
      //   },
      //   disableFilters: true,
      // },
      {
        Header: <div><div>Resolution</div><div>(%)</div></div>,
        id: 'resolution',
        accessor: (value) => {
          // return (value.resolution);
          return (value.resolution ? value.resolution : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalResolution !== 0) ? financierWisePerformanceData.totalResolution.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>PTP</div><div>(%)</div></div>,
        id: 'ptp',
        accessor: (value) => {
          // return (value.ptp).toFixed(2);
          return (value.ptp ? value.ptp : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalPtp !== 0) ? financierWisePerformanceData.totalPtp.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>CB</div><div>(%)</div></div>,
        id: 'cb',
        accessor: (value) => {
          return (value.cb ? value.cb : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalCb !== 0) ? financierWisePerformanceData.totalCb.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>BPTP</div><div>(%)</div></div>,
        id: 'bptp',
        accessor: (value) => {
          // return (value.bptp).toFixed(2);
          return (value.bptp ? value.bptp : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalBptp !== 0) ? financierWisePerformanceData.totalBptp.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>DISPUTE</div><div>(%)</div></div>,
        id: 'dispute',
        accessor: (value) => {
          return (value.dispute ? value.dispute : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalDispute !== 0) ? financierWisePerformanceData.totalDispute.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>BFU</div><div>(%)</div></div>,
        id: 'bfu',
        accessor: (value) => {
          return (value.bfu ? value.bfu : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalBfu !== 0) ? financierWisePerformanceData.totalBfu.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>RTP</div><div>(%)</div></div>,
        id: 'rtp',
        accessor: (value) => {
          return (value.rtp ? value.rtp : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalRtp !== 0) ? financierWisePerformanceData.totalRtp.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>lm</div><div>(%)</div></div>,
        id: 'lm',
        accessor: (value) => {
          return (value.lm ? value.lm : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalLm !== 0) ? financierWisePerformanceData.totalLm.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>RNR</div><div>(%)</div></div>,
        id: 'rnr',
        accessor: (value) => {
          return (value.rnr ? value.rnr : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalRnr !== 0) ? financierWisePerformanceData.totalRnr.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>dl</div><div>(%)</div></div>,
        id: 'dl',
        accessor: (value) => {
          // return (value.dl).toFixed(2);
          return (value.dl ? value.dl : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalDl !== 0) ? financierWisePerformanceData.totalDl.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>nc</div><div>(%)</div></div>,
        id: 'nc',
        accessor: (value) => {
          // return (value.nc).toFixed(2);
          return (value.nc ? value.nc : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalNc !== 0) ? financierWisePerformanceData.totalNc.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>sfd</div><div>(%)</div></div>,
        id: 'sfd',
        accessor: (value) => {
          // return (value.sfd).toFixed(2);
          return (value.sfd ? value.sfd : 0).toFixed(2);

        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalSfd !== 0) ? financierWisePerformanceData.totalSfd.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>anf</div><div>(%)</div></div>,
        id: 'anf',
        accessor: (value) => {
          // return (value.anf).toFixed(2);
          return (value.anf ? value.anf : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalAnf !== 0) ? financierWisePerformanceData.totalAnf.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>DNC</div><div>(%)</div></div>,
        id: 'dnc',
        accessor: (value) => {
          return (value.dnc ? value.dnc : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt-1 tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalDnc !== 0) ? financierWisePerformanceData.totalDnc.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
      {
        Header: <div><div>pending</div><div>(%)</div></div>,
        id: 'pending',
        accessor: (value) => {
          // return (value.pending).toFixed(2);
          return (value.pending ? value.pending : 0).toFixed(2);
        },
        Footer: value => {
          let financierWisePerformanceData = useSelector((state) => state.entities.dashboard.financierWisePerformance.data.totalData)[0];
          return <><div className="mt- tblFooterColor">
            {(financierWisePerformanceData && financierWisePerformanceData.totalPending !== 0) ? financierWisePerformanceData.totalPending.toFixed(2) : 0}
          </div></>;
        },
        disableFilters: true,
      },
    ],
    []
  )

  let bucketFilterList = [
    { value: -1, label: 'All' },
    { value: 0, label: '0-30 DPD' },
    { value: 31, label: '31-60 DPD' },
    { value: 61, label: '61-90 DPD' },
    { value: 91, label: '91-120 DPD' },
    { value: 121, label: '121-150 DPD' },
    { value: 151, label: '151-180 DPD' },
    { value: 181, label: '181+ DPD' }
  ]
  const { status, error, loading, message } = useSelector((state) => state.entities.dashboard.financierWisePerformance);
  //console.log("financierWisePerformance...", error, status, loading, message);

  

  useEffect(() => {
    if (status === "error") {
      let errorMsg = "Something went wrong. Please try again.";
      if (typeof error === "object") {
        errorMsg = error.message;
      }
      else {
        errorMsg = error;
      }
      setErrorAlertMsg(errorMsg);
      setShowErrorAlert(true);
      setTimeout(() => {
        //setPageLoader(false);
        dispatch(financierWisePerformanceClearMessages());
        setErrorAlertMsg('');
        setShowErrorAlert(false);
      }, 2000);
    }
  }, [status])

  useEffect(() => {
    if (user.role !== 'Agency Manager') {
      if (!financierWisePerformanceData.length) {
        if (year && month) {
          dispatch(financierWisePerformance(user.userId, user.agencyId, year, month, bucketWiseFilter));
        }
        else {
          let d = new Date(date),
            currentMonth = "" + (d.getMonth() + 1),
            currentYear = d.getFullYear();

          if (currentMonth.length < 2) currentMonth = "0" + currentMonth;

          if (currentMonth && currentYear) {
            dispatch(financierWisePerformance(user.userId, user.agencyId, currentYear, currentMonth, bucketWiseFilter));
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    let tempCsvData = [];
    if (financierWisePerformanceData !== null) {
      for (let index = 0; index < financierWisePerformanceData.length; index++) {
				const tempSingle = financierWisePerformanceData[index];
				// console.log("tempSinglemis ----- ", callerWiseMis[index]);
				let newSingleData = {};
        newSingleData.financierId = tempSingle.financierId;
        newSingleData.financierName = tempSingle.financierName;
        newSingleData.totalCases = tempSingle.totalCases;
        newSingleData.allocationDPD = tempSingle.allocationDPD;
        newSingleData.pos = tempSingle.pos ? parseFloat(tempSingle.pos).toFixed(2) : 0;
        newSingleData.dues = tempSingle.dues ? parseFloat(tempSingle.dues).toFixed(2) : 0;
        // newSingleData.paidAmount = tempSingle.paidAmount ? parseFloat(tempSingle.paidAmount).toFixed(2) : 0;
        newSingleData.Amountcollected = tempSingle.Amountcollected ? parseFloat(tempSingle.Amountcollected).toFixed(2) : 0;
        newSingleData.resolution = tempSingle.resolution ? parseFloat(tempSingle.resolution).toFixed(2) : 0;
        newSingleData.ptp = tempSingle.ptp ? parseFloat(tempSingle.ptp).toFixed(2) : 0;
        newSingleData.cb = tempSingle.cb ? parseFloat(tempSingle.cb).toFixed(2) : 0;
        newSingleData.bptp = tempSingle.bptp ? parseFloat(tempSingle.bptp).toFixed(2) : 0;
        newSingleData.dispute = tempSingle.dispute ? parseFloat(tempSingle.dispute).toFixed(2) : 0;
        newSingleData.bfu = tempSingle.bfu ? parseFloat(tempSingle.bfu).toFixed(2) : 0;
        newSingleData.rtp = tempSingle.rtp ? parseFloat(tempSingle.rtp).toFixed(2) : 0;
        newSingleData.lm = tempSingle.lm ? parseFloat(tempSingle.lm).toFixed(2) : 0;
        newSingleData.dl = tempSingle.dl ? parseFloat(tempSingle.dl).toFixed(2) : 0;

        newSingleData.pending = tempSingle.pending ? parseFloat(tempSingle.pending).toFixed(2) : 0;
        newSingleData.nc = tempSingle.nc ? parseFloat(tempSingle.nc).toFixed(2) : 0;
        newSingleData.rnr = tempSingle.rnr ? parseFloat(tempSingle.rnr).toFixed(2) : 0;
        newSingleData.sfd = tempSingle.sfd ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        newSingleData.anf = tempSingle.anf ? parseFloat(tempSingle.anf).toFixed(2) : 0;
        newSingleData.dnc = tempSingle.dnc ? parseFloat(tempSingle.dnc).toFixed(2) : 0;
        // newSingleData.bucket = tempSingle.bucket;
        // newSingleData.description = tempSingle.description;
        // newSingleData.color = tempSingle.color;
        tempCsvData.push(newSingleData);
      }
      setCsvData(tempCsvData);
      console.log(" financierWisePerformanceData --------- ", tempCsvData);

    }
  }, [financierWisePerformanceData])



  // const data = React.useMemo(() => makeData(100000), [])
  // const data = React.useMemo(() => financierWisePerformanceData, [])
  const data = financierWisePerformanceData;

  //console.log("financier wise data:", data);

  const refreshFinancierWiseInfo = () => {
    console.log('start date and end date :', firstDay, lastDay, bucketWiseFilter);
    if (year && month) {
      dispatch(financierWisePerformance(user.userId, user.agencyId, year, month, bucketWiseFilter));
    }
    else {
      let d = new Date(date),
        currentMonth = "" + (d.getMonth() + 1),
        currentYear = d.getFullYear();

      if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
      dispatch(financierWisePerformance(user.userId, user.agencyId, currentYear, currentMonth, bucketWiseFilter));
    }

    setStartDate(new Date(firstDay));
    setEndDate(new Date(lastDay));
  }



  const searchFinancierWisePerformance = () => {
    // let formateStartDate = formatDate(startDate);
    // let formateEndDate = formatDate(endDate);
    // console.log('start date and end date :', formateStartDate, formateEndDate, bucketWiseFilter);
    if (year && month) {
      dispatch(financierWisePerformance(user.userId, user.agencyId, year, month, bucketWiseFilter));
    }
  }

  const renderRowSubComponent = (row) => {
    return (
      <FinancierWiseSubRows financierWiseSubRowsData={row.original.subRows} rowData={row.original} />
    );
  };

  return (
    <div className="logs-card-div">
      {loading ? <Loader /> : " "}
        <Card className="finacierWise w-100">
          <CardHeader>
            <div className="col-lg-4 pl-0 d-flex justify-content-center align-items-start flex-column">
              <h3 className="mb-0">Financier Wise Performance</h3>
              {/* <span className="subHeader">Long term loan performance financier wise</span> */}
            </div>
            <div className="col-lg-3 mt-4 d-block">
              {showErrorAlert &&
                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                  {errorAlertMsg}
                </Alert>
              }

              {showSuccessAlert &&
                <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                  {successAlertMsg}
                </Alert>
              }
            </div>
            <div className="col-lg-3 pr-0 d-flex justify-content-end align-items-center">
              <div className="col-lg-2 pl-0">
                <div className=" d-flex justify-content-center align-items-center">
                  <button className="defaultBtn transformBtn"
                    onClick={refreshFinancierWiseInfo}
                  >
                    <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                  </button>
                </div>
              </div>
              <div className="col-lg-2 pl-0">
                {csvData && (
                  <button
                    id="CSV"
                    type="button"
                    className="settingBtn font-weight-bold download-csv transformBtn">
                    <CSVLink
                      data={csvData}
                      filename={"FinancierWisePerformance.csv"}
                      target="_blank"
                    >
                      <span className="svg-icon svgSetting">
                        <SVG className="svgSetting"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/csv-file-format-extension.svg"
                          )}
                        ></SVG>
                      </span>
                    </CSVLink>
                  </button>
                )}

              </div>
            </div>
          </CardHeader>
          <CardHeader>
            <div className="col-lg-8 pt-4 pl-0 d-flex justify-content-start align-items-center">
              <div className="col-4 pl-0">
                <div className="form-group selectbuckfin">
                  <label>Select bucket</label>
                  <Select
				  	defaultValue={{ value: -1, label: 'All' }}
                    placeholder="Select..."
                    name="role"
                    className="jobRoleSelect w-100"
                    options={bucketFilterList}
                    onChange={value => { setBucketWiseFilter(value.value) }}
                  />
                </div>
              </div>

			  {year && (
              <div className="col-lg-4 pl-0 justify-content-center align-items-center" style={{zIndex:2}}>
                <div className="form-group">
                  <label>Select Year</label>
                  <Select
				  	defaultValue={{ label: year, value: year }}
                    placeholder="Select..."
                    name="export_type"
                    className="jobRoleSelect w-100"
                    options={ getYears() }
                    onChange={(data) => {
                      setYear(data.value);
                    }}
                  />

                </div>
              </div>
			  )}

             {year && (
              <div className="col-lg-4 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>Select Month</label>
                  <Select
				  	        defaultValue={getAllMonth(month)}
                    placeholder="Select..."
                    name="export_type"
                    className="jobRoleSelect w-100"
                    options={ getAllMonth() }
                    onChange={(data) => {
                      let currentYear = year;
                      if (!currentYear) {
                        currentYear = date.getFullYear()
                      }
                      setMonth(data.value);
                    }}
                  />
                </div>
              </div>
			   )}
              <div className="col-lg-2">
                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                  // disabled={isEmailUnique}
                  onClick={searchFinancierWisePerformance}
                >
                  Search
                </button>
              </div>
            </div>

          </CardHeader>
          <CardBody className="p-4">
            {data && (
              <Table columns={columns} data={data} renderRowSubComponent={renderRowSubComponent} />
            )}
          </CardBody>
        </Card>

        {/* <Card className="finacierWise w-100">
          {/* <CardHeader>
            <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-start flex-column">
              <h3 className="mb-0">Priority pool</h3>
             {/* <span className="subHeader">Long term loan performance financier wise</span> */}
            {/* </div>
            <div className="col-lg-4 mt-4 d-block">
              {showErrorAlert &&
                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                  {errorAlertMsg}
                </Alert>
              }

              {showSuccessAlert &&
                <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                  {successAlertMsg}
                </Alert>
              }
            </div>
          </CardHeader>  */}
          {/* <CardHeader>
            {/* <div className="col-lg-12 pl-0 d-flex justify-content-start align-items-center">
              {/* <h5 style={{textAlign:'center'}}></h5> */}

              {/* <h5 style={{ margin: 'auto' }}>Coming soon</h5>
            </div> */} 
          {/* </CardHeader> */}
          {/* <CardBody className="p-4">
            {/* {data && (
              <Table columns={columns} data={data} />
            )} */}
          {/* </CardBody>  */}
        {/* </Card> */}
    </div>
  );
}

export default FinancierWisePerformaceTable;


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <div>
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value.toString().toLowerCase() || undefined) // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data, renderRowSubComponent }) {
  // alert()
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    //setGlobalFilter,
    // page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },

  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      // initialState: { pageIndex: 0, pageSize: 10 }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,//sort data
    useExpanded,
    // usePagination,//use pagination
  )

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);
  // We don't want to render all of the rows for this example, so cap
  // it for this use case


  let display = 'content';


  return (
    <>
      <div>
        <div className="tblScroll someradiushone financierwiseperfom">
          <table {...getTableProps()} className="loanTable financierWiseTable table table-bordered">
            <thead className="tableHead">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? '↓'
                            : '↑'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
              {/* <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr> */}
            </thead>
            <thead className="financewfoot">
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    {!(parseFloat(row.id) % 1) && (
                      <tr style={{ display: display }} className={`${display} ${display = 'content'}`}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          );
                        })}
                      </tr>
                    )}
                    {row.isExpanded && (
                      <tr cclassName={`p-0 ${display = 'none'}`}>
                        <td className="p-0" colSpan={visibleColumns.length}>
                          {renderRowSubComponent(row)}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )
              })}
            </tbody>
            
          </table>
        </div>
        {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
        {/* <div className="d-none row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </button>{' '}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records{' '}
                <strong>
                  {data.length}
                </strong>{' '}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page)
                  }}
                  className="gotoPage"
                />
              </span>{' '}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div> */}


        <br />
      </div>
    </>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

