import React, { useEffect, useMemo } from "react";
//import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
//import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
    //FormattedMessage,
    injectIntl
} from "react-intl";
//import { useHistory } from "react-router-dom";
//import { Dropdown } from "react-bootstrap";
//import { DropdownMenu2 } from "../../../../_metronic/_partials/dropdowns";
import { useHtmlClassService } from "../../../../_metronic/layout";
//import '../css/report_card.scss';


function LineChart(props) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseColor: objectPath.get(
                uiService.config,
                `js.colors.theme.base.danger`
            ),
            colorsThemeLightColor: objectPath.get(
                uiService.config,
                `js.colors.theme.light.danger`
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);

    useEffect(() => {
        const element = document.getElementById(`line_chart`);

        if (!element) {
            return;
        }

        const options = getChartOption(layoutProps);
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps]);



    return (
        <div>
            <div className="report-card-div">
                <div className={`card card-custom card-stretch gutter-b card-div`}>
                    {/* Body */}
                    <div className="card-body position-relative overflow-hidden">
                        {/* Title */}
                        <div
                            id={`line_chart`}
                            className="card-rounded-bottom"
                            style={{ height: "150px" }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(LineChart);

function getChartOption(layoutProps) {
    const options = {
        series: [
            {
                name: "Desktops",
                data: [35, 51, 49, 62, 69]
            },
        ],
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Contactibily (CB + PTP + BPTP + FPTP)',
            align: 'left'
        },
        subtitle: {
            text: 'Long term load performance',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ["Nov 21", "Dec 21", "Jan 22", "Feb 22", "March 22"]
        },

    };
    return options;
}

