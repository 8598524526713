import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "../Loader/Loader";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
// import makeData from "./MakeData";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { getAttendanceList, userWiseData } from "../../store/attendance";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import DatePicker from 'react-date-picker';
import { Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap/Table';
import moment from 'moment';
import LoaderPop from "../Loader/LoaderPop";

export default function Dashboard_Attendance() {

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showRemark, setShowRemark] = useState(false);

  const [shortData, setShortData] = useState([]);

  console.log(" shortData ====== ", shortData);
  
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const showFormatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };
  
  var date = new Date();
  // var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var firstDay = formatDate(new Date());
  var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  let [startDate, setStartDate] = useState(new Date(firstDay));
  let [endDate, setEndDate] = useState(new Date(firstDay));
  const user = useSelector(state => state.entities.auth.data);
  const attendanceData = useSelector(state => state.entities.attendance.data);
  const loadingDashboard = useSelector((state) => state.entities.attendance.loading);
  const loadingFosData = useSelector((state) => state.entities.attendance.userWiseData.loading);
  // console.log("got error...===", loading);
  const userWiseDatas = useSelector(state => state.entities.attendance.userWiseData.data);
  let tempStartDate = '';
  let tempEndDate = '';
  console.log("attendanceData-------",attendanceData);
  //  const activeInactData = userWiseDatas.totalFos[0].status;
  //  console.log("activeInactData----", activeInactData.short(a,b));
  // const shortDataActive = activeInactData.short((a, b)=>a.name.localeCompare(b.name))
  //   setShortData(shortDataActive);
  // useEffect(() => {
  //   console.log(" userWiseDatas ====== ", userWiseDatas.totalFos);
  // }, [userWiseDatas])

  useEffect(() => {
    dispatch(getAttendanceList(user.userId, moment(startDate).format("YYYY-MM-DD")));
  }, [])

  const searchData = (searchType) => {
    // console.log(" type ===== ", formatDate(startDate), formatDate(endDate));
    tempStartDate = formatDate(startDate);
    tempEndDate = formatDate(endDate);
    if (searchType == 'today') {
      let todayDateTemp = new Date();
      todayDateTemp = formatDate(todayDateTemp);
      tempStartDate = formatDate(todayDateTemp);
      tempEndDate = formatDate(todayDateTemp);
      // console.log(" today ", tempStartDate);
    }
    else if (searchType == 'yesterday') {
      var todayDateTemp = new Date();
      todayDateTemp.setDate(todayDateTemp.getDate() - 1);
      todayDateTemp = formatDate(todayDateTemp);
      tempStartDate = formatDate(todayDateTemp);
      tempEndDate = formatDate(todayDateTemp);
      // console.log(" yesterday ", tempStartDate);

    }
    setStartDate(new Date(tempStartDate));
    setEndDate(new Date(tempEndDate));
    let formateStartDate = formatDate(tempStartDate);
    let formateEndDate = formatDate(tempEndDate);
    console.log('start date and end date :', formateStartDate, formateEndDate);
    if(formateStartDate && formateEndDate) {
      dispatch(
        getAttendanceList(user.userId, formateStartDate, formateEndDate)
      );
  }
}


  const openModal = (data) => {
    dispatch(userWiseData(data.userId, moment(startDate).format("YYYY-MM-DD")));
    setShowModal(true);
  }


  const columns = React.useMemo(
    () => [

      {
        Header: 'Name',
        accessor: 'fullName',

      },
      // {
      //   Header: 'Date',
      //   accessor: 'loggedInTime',
      // },
      {
        Header: 'Total FOS Count',
        accessor: 'totalFos',
        Cell: cell => (
          <button onClick={() => openModal(cell.row.original)}
            className="activefosbtn"
            value={cell.row.values.totalFos}>
            {cell.row.values.totalFos}
          </button>
        )
      },
      {
        Header: 'Active FOS Count',
        accessor: 'activeFos',
      },
      {
        Header: 'Inactive FOS Count',
        accessor: 'inActiveFos',
      },
      
      {
        Header: 'Pending for Approval',
        accessor: 'pendingAprovalFos',
      },
      {
        Header: 'No. Of Visit',
        accessor: 'noOfVisits',
      },
    ],
    []
  )

useEffect(() => {
  function handleEscapeKey(event) {
    console.log("  event.code  ", event.code);
    if (event.code === "Escape") {
    setShowModal(false);
    }
    // document.getElementById("Space").blur();
  }
  document.addEventListener("keydown", handleEscapeKey);
  return () => document.removeEventListener("keydown", handleEscapeKey);
}, []);


  // const data = React.useMemo(() => makeData(20), [])
  return (
    <div className="agentActivityDiv">
      {loadingDashboard ? <Loader /> : ""}
      {/* <LoadingOverlay
        active={loadingDashboard}
        spinner
        text='Loading...'
        styles={{
          wrapper: {
            overflow: loadingDashboard ? 'hidden' : 'hidden'
          },
          spinner: (base) => ({
            ...base,
            width: '75px',
            '& svg circle': {
              stroke: '#00b1cc'
            }
          })
        }}
      > */}
        <Card>
          <CardHeader>
            <div className="col-lg-2 d-flex justify-content-start align-items-center pl-5">
              <h3 className="pl-0">Dashboard</h3>
            </div>
          </CardHeader>
          <CardHeader>
            <div className="col-12 pl-3 pt-4 d-flex justify-content-start align-items-center">
              <div className="col-lg-3 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>Date</label>
                  <DatePicker
                    onChange={value => {
                      console.log(" value ====== ", value);
                      let formatedStartDate = formatDate(value);
                      if (formatedStartDate) {
                        console.log(" formatedStartDate ", formatedStartDate);
                        setStartDate(new Date(formatedStartDate));
                      }


                      //formikProps.setFieldValue("startDate", value)}
                    }}
                    value={startDate}
                    name="startDate"
                    // minDate={new Date()}
                    format="dd-MM-y"
                    className="form-control form-control-solid h-auto readOnlySpan"
                    clearIcon={null}
                    calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                  />
                </div>
              </div>

              <div className="col-lg-2">

                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold w-100"
                  onClick={() => searchData('normal')}
                >
                  Search
                </button>
              </div>

              <div className="col-lg-2">

                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold w-100"
                  // disabled={isEmailUnique}
                  onClick={() => searchData('today')}
                >
                  Today
                </button>
              </div>

              <div className="col-lg-2">

                <button
                  id="button"
                  type="button"
                  className="btn btn-primary font-weight-bold w-100"
                  // disabled={isEmailUnique}
                  onClick={() => searchData('yesterday')}
                >
                  Yesterday
                </button>
              </div>
            </div>
          </CardHeader>
          <CardBody className="p-4">
            {attendanceData && (
              <Table columns={columns} data={attendanceData} />
            )}

          </CardBody>
        </Card>
      {/* </LoadingOverlay> */}

      <div className="editModalDiv col-md-3">
        <Modal show={showModal}
          size="lg"
          centered
          backdrop="static"
          keyboard={false}
          className="editTodayModalStyle attendanmodalmngpr"
        >
          <Modal.Header closeButton>
            <h2 className="mb-0">Total FOS Count</h2>
          </Modal.Header>
          {loadingFosData ? <LoaderPop /> : " "}
          {/* <LoadingOverlay
            active={loadingFosData}
            spinner
            text='Loading...'
            styles={{
              wrapper: {
                overflow: loadingFosData ? 'hidden' : 'hidden'
              },
              spinner: (base) => ({
                ...base,
                width: '75px',
                '& svg circle': {
                  stroke: '#00b1cc'
                }
              })
            }}
          > */}
            <Modal.Body className="p-10">
              <BootstrapTable striped bordered hover size="sm" className="inactable mngindash">
                {userWiseDatas && userWiseDatas.totalFos.length > 0 && (
                  <>
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>InActive days</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userWiseDatas && userWiseDatas.totalFos && userWiseDatas.totalFos .slice().sort((a,b)=> a.status > b.status ? 1 : -1) .map((fos, index) => (
                       
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{fos.fullName}</td>
                          <td>{fos.status}</td>
                          <td>{fos.status == "Active" ? "OKAY" : (fos.inactiveDays == null ? "not logged out" : fos.inactiveDays)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                )}
                {userWiseDatas && userWiseDatas.totalFos.length == 0 && (
                  <div className="ndatafound">No Data Found</div>
                )}
              </BootstrapTable>

            </Modal.Body>
          {/* </LoadingOverlay> */}
          <Modal.Footer>
            <button onClick={() => setShowModal(false)} id="cancel" type="button" className="btn btn-primary font-weight-bold upload mr-4 transformBtn">Cancel</button>
          </Modal.Footer>
        </Modal>
      </div>

    </div >
  );

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length

    return (
      <div>
        <input
          value={filterValue || ''}
          onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          }}
          // placeholder={`Search ${count} records...`}
          className="searchFilter"
        />
        {/* <i className="flaticon-search searchIcon"></i> */}
      </div>
    )
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = val => !val

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
              ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
              : true
          })
        },
      }),
      []
    )

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },

    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 5 }
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,//sort data
      usePagination,//use pagination
    )

    // Render the UI for your table
    return (
      <>
        <div className="tblScroll">
          <table {...getTableProps()} className="attendancetabl dashAttendance">
            <thead className="tableHead">
              {headerGroups.map(headerGroup => (
                 <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                 {headerGroup.headers.map(column => (
                 <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                   {column.render('Header')}
                   {/* Add a sort direction indicator */}
                   <span>
                   {column.isSorted
                     ? column.isSortedDesc
                     ? '↓'
                     : '↑'
                     : ''}
                   </span>
                 </th>
                 ))}
               </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="row m-0">
          <div className="pagination col-lg-12 mb-7">
            <div className="col-lg-2">
              <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </button>{' '}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records{' '}
                <strong>
                  {/* {data.length} */}
                  {rows.length}

                </strong>{' '}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page)
                  }}
                  className="gotoPage"
                />
              </span>{' '}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </>
    )
  }
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

