import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
function LoaderPop() {
  return (
    <div>
      <div className="mainloadercomponentLoad">
        <img
          alt="loader"
          src={toAbsoluteUrl("/media/img/loader_Trans.gif")}
        />
      </div>
    </div>
  );
}

export default LoaderPop;
