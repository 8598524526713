import React, { useState, useEffect } from "react";
//import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import millify from "millify";
import DatePicker from "react-date-picker";
import Select from "react-select";
import "../../Loan/css/loan.scss";
import "../css/bucketWiseTable.scss";
import "../css/teleCallingModal.scss";
import styled from "styled-components";
import { useSticky } from "react-table-sticky";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useBlockLayout,
} from "react-table";
import matchSorter from "match-sorter";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../Loader/Loader";
import Alert from "react-bootstrap/Alert";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap/Table";
//import makeData from "./../../utils/makeData";

import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
//import { toAbsoluteUrl } from "../../../../layout/_metronic/_helpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  bucketWisePerformanceClearMessages,
  bucketWisePerformance,
  callerWiseMisData,
  callerWiseMisAgentCountData,
  tlWiseMisData,
  callerServieces,
} from "../../../store/dashboard";
import { agencyFinancierList } from "../../../store/loan";
import LoaderPop from "../../Loader/LoaderPop";

const Styles = styled.div`
  padding: 1rem;

  .table {
    border: 1px solid #ddd;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;
      vertical-align: middle;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 0px 0px 0px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;

function CallerWiseMis() {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errShow, setErrShow] = useState(null);
  const [showCsvFile, setShowCsvFile] = useState("callerwise");
  const [roleOption, setRoleOptions] = useState([]);
  const [financierId, setFinancierId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [callerWiseMisCsvData, setCallerWiseMisCsvData] = useState([]);
  const [tlWiseMisCsvData, setTlWiseMisCsvData] = useState([]);
  // console.log("csvData========", csvData)

  let errorMsg; //= "Something went wrong. Please try again.";
  const user = useSelector((state) => state.entities.auth.data);
  let bucketWisePerformanceData = useSelector(
    (state) => state.entities.dashboard.bucketWisePerformance.data
  );
  let agencyFinancierListData = useSelector(
    (state) => state.entities.loan.agenciesFinanciersList.data
  );
  let servicesList = useSelector(
    (state) => state.entities.dashboard.callerServieces.data
  );
  console.log("servicesList--123", servicesList);
  let callerWiseMis = useSelector(
    (state) => state.entities.dashboard.callerWiseMis.data
  );
  let callerWiseMisAgentCounts = useSelector(
    (state) => state.entities.dashboard.callerWiseMisAgentCount.data
  );
  const ModalUsersData =
    callerWiseMisAgentCounts && callerWiseMisAgentCounts.agentData
      ? callerWiseMisAgentCounts &&
        callerWiseMisAgentCounts.agentData
          .slice()
          .sort((a, b) =>
            a.lastDispositionTime < b.lastDispositionTime ? 1 : -1
          )
      : "";
  console.log("shortcallerWiseMisAgentCounts******", ModalUsersData);

  let tlWiseMis = useSelector(
    (state) => state.entities.dashboard.tlWiseMis.data
  );
  // console.log('====================================');
  // console.log(tlWiseMis);
  // console.log('====================================');

  console.log("callerWiseMisAgentCounts:======", callerWiseMisAgentCounts);
  // console.log("tlWiseMis:", tlWiseMis);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const numFormatter = (num) => {
    let toFixe = 4;
    if (num <= 0) {
      return num.toFixed(2);
    } else if (num > 999 && num < 9999) {
      toFixe = 3;
    } else if (num >= 10000) {
      toFixe = 2;
    }
    return (num / 1000000).toFixed(toFixe);
  };

  function sumCallDuration(callDuration) {
    let totalSeconds = 0;

    for (let i = 0; i < callDuration.length; i++) {
      const timeParts = callDuration[i].split(":");
      const hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[1]);
      const seconds = parseInt(timeParts[2]);

      totalSeconds += hours * 3600 + minutes * 60 + seconds;
    }

    // Convert totalSeconds back to HH:MM:SS format
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedDuration = `${padZero(hours)}:${padZero(minutes)}:${padZero(
      seconds
    )}`;

    return formattedDuration;
  }

  function padZero(num) {
    return num.toString().padStart(2, "0");
  }

  // const [sorting, setSorting] = useState({ key: "fullName", ascending: true });
  // console.log("sorting", sorting);
  // const [currentUsers, setCurrentUsers] = useState(ModalUsersData);
  // console.log("currentUsers", currentUsers);

  // useEffect(() => {
  //   const currentUsersCopy = [...currentUsers];
  //   const sortedCurrentUsers = currentUsersCopy.sort((a, b) => {
  //     return a[sorting.key].localeCompare(b[sorting.key]);
  //   });
  //   console.log("sortedCurrentUsers", sortedCurrentUsers);

  //   setCurrentUsers(
  //     sorting.ascending
  //       ? sortedCurrentUsers
  //       : sortedCurrentUsers.reverse()
  //   );
  // }, [sorting]);

  // function applySorting(key, ascending) {
  //   setSorting({ key: key, ascending: ascending });
  // }

  // var date = new Date();
  // var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  // var firstDay = formatDate(new Date());
  // var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  let [startDate, setStartDate] = useState(new Date());
  // let [endDate, setEndDate] = useState(new Date(lastDay));

  useEffect(() => {
    if (!callerWiseMis) {
      dispatch(
        callerWiseMisData(user.userId, formatDate(startDate), financierId)
      );
      dispatch(
        callerWiseMisAgentCountData(
          user.userId,
          formatDate(startDate),
          financierId
        )
      );
      dispatch(tlWiseMisData(user.userId, formatDate(startDate), financierId));
      dispatch(callerServieces(user.userId, formatDate(startDate)));
    }
  }, []);

  const refreshDataExport = () => {
    if (user) {
      dispatch(
        callerWiseMisData(user.userId, formatDate(startDate), financierId)
      );
      dispatch(
        callerWiseMisAgentCountData(
          user.userId,
          formatDate(startDate),
          financierId
        )
      );
      dispatch(tlWiseMisData(user.userId, formatDate(startDate), financierId));
      dispatch(callerServieces(user.userId, formatDate(startDate)));
    }
  };

  let i = 0;
  const columns = React.useMemo(
    () => [
      {
        Header: "Sr. No.",
        sticky: 'left',
        width: 100,
        // accessor: 'bucket',
        accessor: (value) => (
          <div style={{ textAlign: "center" }}>
            <div>{value.srNo}</div>
            <div
              className="descriptionStyle"
              style={{ color: value.color || "black" }}
            ></div>
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "Caller name",
        sticky: 'left',
        width: 300,
        accessor: (value) => (value.callerName ? value.callerName : ""),
        disableFilters: true,
        Footer: <div className="tblFooterColor text-left text-uppercase">Total</div>,
      },
      {
        Header: (
          <div style={{ textAlign: "center", verticalAlign: "middle" }}>
            <div>Portfolio</div>
          </div>
        ),
        width: 180,
        id: "portFolioDetail",
        accessor: (value) =>
          value.portFolioDetail ? value.portFolioDetail : "",
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Total calls</div>
          </div>
        ),
        width: 120,
        id: "numberOfCall",
        accessor: (value) => (value.numberOfCall ? value.numberOfCall : 0),
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.numberOfCall) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">{total.toFixed(0)}</div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Total call duration </div>
            <div></div>
          </div>
        ),
        width: 140,
        id: "averageCallDuration",
        accessor: (value) => {
          return value.avgCallDuration ? value.avgCallDuration : 0;
        },
        Footer: (value) => {
          let tmpCallDuration = [];
          if (value.rows.length > 0) {
            console.log(" value.rows ===== ", value.rows);
            value.rows.reduce((sum, row) => {
              tmpCallDuration.push(row.values.averageCallDuration);
            });
          }

          return (
            <>
              <div className="tblFooterColor">
                {sumCallDuration(tmpCallDuration)}
              </div>
            </>
          );
        },

        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Talk time</div>
            <div></div>
          </div>
        ),
        width: 100,
        id: "holdTime",
        accessor: (value) => {
          return value.holdTime ? value.holdTime : 0;
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.holdTime) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">{total.toFixed(2)}</div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>call connect </div>
            <div>(%)</div>
          </div>
        ),
        id: "callConnected",
        accessor: (value) => {
          return (value.callConnected ? value.callConnected : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.callConnected) + sum,
                0
              ),
            [value.rows]
          );
          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Paid Amount </div>
            <div></div>
          </div>
        ),
        width: 130,
        id: "amountPaid",
        accessor: (value) => {
          return (value.amountPaid ? value.amountPaid : 0).toFixed(0);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.amountPaid) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">{total.toFixed(0)}</div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Paid</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "paid",
        accessor: (value) => {
          return (value.paid ? value.paid : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.paid) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>PTP</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "ptp",
        accessor: (value) => {
          return (value.ptp ? value.ptp : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.ptp) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>CB</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "cb",
        accessor: (value) => {
          return (value.cb ? value.cb : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.cb) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>bptp</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "bptp",
        accessor: (value) => {
          return (value.bptp ? value.bptp : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.bptp) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>DISPUTE</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "dispute",
        accessor: (value) => {
          return (value.dispute ? value.dispute : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.dispute) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>BFU</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "bfu",
        accessor: (value) => {
          return (value.bfu ? value.bfu : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.bfu) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>RTP</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "rtp",
        accessor: (value) => {
          return (value.rtp ? value.rtp : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.rtp) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>lm</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "lm",
        accessor: (value) => {
          return (value.lm ? value.lm : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.lm) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>rnr</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "rnr",
        accessor: (value) => {
          return (value.rnr ? value.rnr : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.rnr) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>NC</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "nc",
        accessor: (value) => {
          return (value.nc ? value.nc : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.nc) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>dnc</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "dnc",
        accessor: (value) => {
          return (value.dnc ? value.dnc : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.dnc) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
    ],
    []
  );

  const columnsTlWise = React.useMemo(
    () => [
      {
        Header: "Sr. No.",
        sticky: "left",
        width: 100,
        // accessor: 'bucket',
        accessor: (value) => (
          <div style={{ textAlign: "center" }}>
            <div>{value.srNo}</div>
            <div
              className="descriptionStyle"
              style={{ color: value.color || "black" }}
            ></div>
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "TL name",
        sticky: "left",
        width: 270,
        accessor: (value) => (value.tlName ? value.tlName : ""),
        disableFilters: true,
        Footer: (
          <div className="tblFooterColor text-left text-uppercase">Total</div>
        ),
      },
      {
        Header: (
          <div>
            <div>Portfolio</div>
          </div>
        ),
        width: 170,
        id: "portFolioDetail",
        accessor: (value) =>
          value.portFolioDetail ? value.portFolioDetail : "",

        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Callers Count </div>
            <div></div>
          </div>
        ),
        width: 100,
        id: "agentCount",
        accessor: (value) => {
          return value.agentCount ? value.agentCount : 0;
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.agentCount) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">{total.toFixed(0)}</div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Total calls</div>
          </div>
        ),
        width: 120,
        id: "numberOfCall",
        accessor: (value) => (value.numberOfCall ? value.numberOfCall : 0),

        disableFilters: true,
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.numberOfCall) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">{total.toFixed(0)}</div>
            </>
          );
        },
      },
      {
        Header: (
          <div>
            <div>Average call duration </div>
            <div></div>
          </div>
        ),
        width: 150,
        id: "averageCallDuration",
        accessor: (value) => {
          return value.avgCallDuration ? value.avgCallDuration : 0;
        },

        Footer: (value) => {
          //   const total = React.useMemo(
          //       () => value.rows.reduce((sum, row) => (parseFloat(row.values.avgCallDuration)) + sum, 0),
          //       [value.rows]
          //   );
          let tmpCallDuration = [];
          if (value.rows.length > 0) {
            value.rows.reduce((sum, row) => {
              tmpCallDuration.push(row.values.averageCallDuration);
            });
          }

          return (
            <>
              <div className="tblFooterColor">
                {sumCallDuration(tmpCallDuration)}
              </div>
            </>
          );
        },

        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Talk time</div>
            <div></div>
          </div>
        ),
        width: 100,
        id: "holdTime",
        accessor: (value) => {
          return value.holdTime ? value.holdTime : 0;
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.holdTime) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">{total.toFixed(2)}</div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>call connect </div>
            <div>(%)</div>
          </div>
        ),
        width: 150,
        id: "callConnected",
        accessor: (value) => {
          return (value.callConnected ? value.callConnected : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.callConnected) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Paid Amount </div>
            <div></div>
          </div>
        ),
        width: 130,
        id: "amountPaid",
        accessor: (value) => {
          return (value.amountPaid ? value.amountPaid : 0).toFixed(0);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.amountPaid) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">{total.toFixed(0)}</div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Paid</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "paid",
        accessor: (value) => {
          return (value.paid ? value.paid : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.paid) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>PTP</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "ptp",
        accessor: (value) => {
          return (value.ptp ? value.ptp : 0).toFixed(2);
        },
        disableFilters: true,
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.ptp) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
      },
      {
        Header: (
          <div>
            <div>CB</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "cb",
        accessor: (value) => {
          return (value.cb ? value.cb : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.cb) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>bptp</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "bptp",
        accessor: (value) => {
          return (value.bptp ? value.bptp : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.bptp) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>DISPUTE</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "dispute",
        accessor: (value) => {
          return (value.dispute ? value.dispute : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.dispute) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>BFU</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "bfu",
        accessor: (value) => {
          return (value.bfu ? value.bfu : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.bfu) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>RTP</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "rtp",
        accessor: (value) => {
          return (value.rtp ? value.rtp : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.rtp) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>lm</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "lm",
        accessor: (value) => {
          return (value.lm ? value.lm : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.lm) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>rnr</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "rnr",
        accessor: (value) => {
          return (value.rnr ? value.rnr : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.rnr) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>NC</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "nc",
        accessor: (value) => {
          return (value.nc ? value.nc : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.nc) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>dnc</div>
            <div>(%)</div>
          </div>
        ),
        width: 100,
        id: "dnc",
        accessor: (value) => {
          return (value.dnc ? value.dnc : 0).toFixed(2);
        },
        Footer: (value) => {
          const total = React.useMemo(
            () =>
              value.rows.reduce(
                (sum, row) => parseFloat(row.values.dnc) + sum,
                0
              ),
            [value.rows]
          );

          return (
            <>
              <div className="tblFooterColor">
                {(total ? total / value.rows.length : 0).toFixed(2)}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
    ],
    []
  );

  const { status, error, loading, message } = useSelector(
    (state) => state.entities.dashboard.callerWiseMis
  );
  useEffect(() => {
    if (status === "error") {
      if (typeof error === "object") {
        //console.log('check bucketwise');
        errorMsg = error.message;
        setErrShow(error.message);
      } else {
        errorMsg = error;
      }
      setErrorAlertMsg(errorMsg);
      setShowErrorAlert(true);
      setTimeout(() => {
        //setPageLoader(false);
        dispatch(bucketWisePerformanceClearMessages());
        setErrorAlertMsg("");
        setShowErrorAlert(false);
      }, 2000);
    }
  }, [status]);

  const data = callerWiseMis;
  useEffect(() => {
    if (status == "success") {
      if (data) {
        setErrShow(null);
      }
    }
  }, [status]);

  useEffect(() => {
    let tempCsvData = [];
    if (callerWiseMis !== null) {
      // console.log(" bucketWisePerformancemis--------- ", callerWiseMis.length);
      for (let index = 0; index < callerWiseMis.length; index++) {
        const tempSingle = callerWiseMis[index];
        // console.log("tempSinglemis ----- ", callerWiseMis[index]);
        let newSingleData = {};
        newSingleData.srNo = tempSingle.srNo
          ? parseFloat(tempSingle.srNo).toFixed(2)
          : 0;
        newSingleData.callerName = tempSingle.callerName;
        // newSingleData.callerName = tempSingle.callerName ? parseFloat(tempSingle.callerName).toFixed(2) : 0;
        newSingleData.agentId = tempSingle.agentId;
        // newSingleData.portFolioDetail = tempSingle.portFolioDetail ? parseFloat(tempSingle.portFolioDetail).toFixed(2) : 0;
        newSingleData.portFolioDetail = tempSingle.portFolioDetail;
        newSingleData.numberOfCall = tempSingle.numberOfCall
          ? parseFloat(tempSingle.numberOfCall).toFixed(2)
          : 0;
        newSingleData.avgCallDuration = tempSingle.avgCallDuration
          ? parseFloat(tempSingle.avgCallDuration).toFixed(2)
          : 0;
        newSingleData.paid = tempSingle.paid
          ? parseFloat(tempSingle.paid).toFixed(2)
          : 0;
        newSingleData.ptp = tempSingle.ptp
          ? parseFloat(tempSingle.ptp).toFixed(2)
          : 0;
        newSingleData.cb = tempSingle.cb
          ? parseFloat(tempSingle.cb).toFixed(2)
          : 0;
        newSingleData.bptp = tempSingle.bptp
          ? parseFloat(tempSingle.bptp).toFixed(2)
          : 0;
        newSingleData.dispute = tempSingle.dispute
          ? parseFloat(tempSingle.dispute).toFixed(2)
          : 0;
        newSingleData.bfu = tempSingle.bfu
          ? parseFloat(tempSingle.bfu).toFixed(2)
          : 0;
        newSingleData.rtp = tempSingle.rtp
          ? parseFloat(tempSingle.rtp).toFixed(2)
          : 0;
        newSingleData.lm = tempSingle.lm
          ? parseFloat(tempSingle.lm).toFixed(2)
          : 0;
        newSingleData.callConnected = tempSingle.callConnected
          ? parseFloat(tempSingle.callConnected).toFixed(2)
          : 0;
        newSingleData.rnr = tempSingle.rnr
          ? parseFloat(tempSingle.rnr).toFixed(2)
          : 0;
        newSingleData.nc = tempSingle.nc
          ? parseFloat(tempSingle.nc).toFixed(2)
          : 0;
        newSingleData.dnc = tempSingle.dnc
          ? parseFloat(tempSingle.dnc).toFixed(2)
          : 0;
        newSingleData.amountPaid = tempSingle.amountPaid;

        // newSingleData.dnc = tempSingle.dnc ? parseFloat(tempSingle.dnc).toFixed(2) : 0;
        // newSingleData.bucket = tempSingle.bucket;
        // newSingleData.description = tempSingle.description;
        // newSingleData.color = tempSingle.color;
        // newSingleData.totalCases = tempSingle.totalCases ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        // newSingleData.sfd = tempSingle.sfd ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        // newSingleData.sfd = tempSingle.sfd ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        tempCsvData.push(newSingleData);
      }
      setCallerWiseMisCsvData(tempCsvData);
      console.log(" callerWiseMis[0] --------- ", tempCsvData);
    }
  }, [callerWiseMis]);

  useEffect(() => {
    let tempCsvData = [];
    if (tlWiseMis !== null) {
      // console.log(" bucketWisePerformancemis--------- ", callerWiseMis.length);
      for (let index = 0; index < tlWiseMis.length; index++) {
        const tempSingle = tlWiseMis[index];
        // console.log("tempSinglemis ----- ", callerWiseMis[index]);
        let newSingleData = {};
        newSingleData.srNo = tempSingle.srNo
          ? parseFloat(tempSingle.srNo).toFixed(2)
          : 0;
        newSingleData.tlName = tempSingle.tlName;
        // newSingleData.callerName = tempSingle.callerName ? parseFloat(tempSingle.callerName).toFixed(2) : 0;
        newSingleData.agentId = tempSingle.agentId;
        // newSingleData.portFolioDetail = tempSingle.portFolioDetail ? parseFloat(tempSingle.portFolioDetail).toFixed(2) : 0;
        newSingleData.portFolioDetail = tempSingle.portFolioDetail;
        newSingleData.numberOfCall = tempSingle.numberOfCall
          ? parseFloat(tempSingle.numberOfCall).toFixed(2)
          : 0;
        newSingleData.avgCallDuration = tempSingle.avgCallDuration
          ? parseFloat(tempSingle.avgCallDuration).toFixed(2)
          : 0;
        newSingleData.paid = tempSingle.paid
          ? parseFloat(tempSingle.paid).toFixed(2)
          : 0;
        newSingleData.ptp = tempSingle.ptp
          ? parseFloat(tempSingle.ptp).toFixed(2)
          : 0;
        newSingleData.cb = tempSingle.cb
          ? parseFloat(tempSingle.cb).toFixed(2)
          : 0;
        newSingleData.bptp = tempSingle.bptp
          ? parseFloat(tempSingle.bptp).toFixed(2)
          : 0;
        newSingleData.dispute = tempSingle.dispute
          ? parseFloat(tempSingle.dispute).toFixed(2)
          : 0;
        newSingleData.bfu = tempSingle.bfu
          ? parseFloat(tempSingle.bfu).toFixed(2)
          : 0;
        newSingleData.rtp = tempSingle.rtp
          ? parseFloat(tempSingle.rtp).toFixed(2)
          : 0;
        newSingleData.lm = tempSingle.lm
          ? parseFloat(tempSingle.lm).toFixed(2)
          : 0;
        newSingleData.callConnected = tempSingle.callConnected
          ? parseFloat(tempSingle.callConnected).toFixed(2)
          : 0;
        newSingleData.rnr = tempSingle.rnr
          ? parseFloat(tempSingle.rnr).toFixed(2)
          : 0;
        newSingleData.nc = tempSingle.nc
          ? parseFloat(tempSingle.nc).toFixed(2)
          : 0;
        newSingleData.dnc = tempSingle.dnc
          ? parseFloat(tempSingle.dnc).toFixed(2)
          : 0;
        newSingleData.amountPaid = tempSingle.amountPaid;

        // newSingleData.dnc = tempSingle.dnc ? parseFloat(tempSingle.dnc).toFixed(2) : 0;
        // newSingleData.bucket = tempSingle.bucket;
        // newSingleData.description = tempSingle.description;
        // newSingleData.color = tempSingle.color;
        // newSingleData.totalCases = tempSingle.totalCases ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        // newSingleData.sfd = tempSingle.sfd ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        // newSingleData.sfd = tempSingle.sfd ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        tempCsvData.push(newSingleData);
      }
      setTlWiseMisCsvData(tempCsvData);
      console.log(" tlWiseMis[0] --------- ", tempCsvData);
    }
  }, [tlWiseMis]);

  const handleSearch = () => {
    // console.log(' financierId ', financierId);
    dispatch(
      callerWiseMisData(user.userId, formatDate(startDate), financierId)
    );
    dispatch(
      callerWiseMisAgentCountData(
        user.userId,
        formatDate(startDate),
        financierId
      )
    );
    dispatch(tlWiseMisData(user.userId, formatDate(startDate), financierId));
    dispatch(callerServieces(user.userId, formatDate(startDate)));
  };

  const onChangeRole = (data) => {
    console.log("data====123", data);
    setFinancierId(data.value);
  };

  useEffect(() => {
    console.log(" servicesList ", servicesList);
    if (servicesList) {
      let roleOptionsTemp = [];
      for (let index = 0; index < servicesList.length; index++) {
        let value = servicesList[index];
        if (value.services) {
          roleOptionsTemp.push({
            value: value.services,
            label: value.services,
          });
        }
      }
      setRoleOptions(roleOptionsTemp);
    }
  }, [servicesList]);

  const displayInactiveAgentInfo = (agentId) => {
    setShowModal(true);
  };
useEffect(() => {
  function handleEscapeKey(event) {
    console.log("  event.code  ", event.code);
    if (event.code === "Escape") {
      setShowModal(false);
    }
    // document.getElementById("Space").blur();
  }
  document.addEventListener("keydown", handleEscapeKey);
  return () => document.removeEventListener("keydown", handleEscapeKey);

}, []);

  return (
    <div className="logs-card-div">
      {loading ? <Loader /> : " "}
      <Card className="bucketWise callerTabs">
        <CardHeader>
          <div className="col-lg-6 pl-0 d-flex justify-content-center align-items-start flex-column">
            <h3 className="mb-0">Tele Calling</h3>
            {/* <span className="subHeader">Long term loan performance bucket wise</span> */}
          </div>
          <div className="col-lg-4 mt-4 pr-0 d-block">
            <div className="d-flex justify-content-end align-items-center">
              <div className="col-2 justify-content-end">
                <div className="col-3 d-flex justify-content-center align-items-center pr-0">
                  <button
                    className="defaultBtn transformBtn"
                    onClick={refreshDataExport}
                  >
                    <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                  </button>
                </div>
              </div>
              {showCsvFile == "callerwise" && (
                <div className="col-lg-2 justify-content-end align-items-center">
                  <div className="col-lg-3 pl-0">
                    {callerWiseMisCsvData && (
                      <button
                        id="CSV"
                        type="button"
                        className="font-weight-bold download-csv transformBtn"
                      >
                        <CSVLink
                          data={callerWiseMisCsvData}
                          filename={"CallerWiseMis.csv"}
                          target="_blank"
                        >
                          <span className="svg-icon svgSetting">
                            <SVG
                              className="svgSetting"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/csv-file-format-extension.svg"
                              )}
                            ></SVG>
                          </span>
                        </CSVLink>
                      </button>
                    )}
                  </div>
                </div>
              )}
              {showCsvFile == "tlwise" && (
                <div className="col-lg-2 justify-content-end align-items-center">
                  <div className="col-lg-3 pl-0">
                    {tlWiseMisCsvData && (
                      <button
                        id="CSV"
                        type="button"
                        className="font-weight-bold download-csv transformBtn"
                      >
                        <CSVLink
                          data={tlWiseMisCsvData}
                          filename={"TlWiseMis.csv"}
                          target="_blank"
                        >
                          <span className="svg-icon svgSetting">
                            <SVG
                              className="svgSetting"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/csv-file-format-extension.svg"
                              )}
                            ></SVG>
                          </span>
                        </CSVLink>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardHeader>
        {showErrorAlert ||
          (showSuccessAlert && (
            <CardHeader>
              <div className="col-lg-4 mt-4 d-block">
                {showErrorAlert && (
                  <Alert
                    variant="danger"
                    onClose={() => setShowErrorAlert(false)}
                    dismissible
                  >
                    {errorAlertMsg}
                  </Alert>
                )}

                {showSuccessAlert && (
                  <Alert
                    className="custom-success-alert"
                    onClose={() => setShowSuccessAlert(false)}
                    dismissible
                  >
                    {successAlertMsg}
                  </Alert>
                )}
              </div>
            </CardHeader>
          ))}

        <CardHeader>
          <div className="col-lg-12 pl-0 pt-4 d-flex justify-content-start align-items-center">
            <div className="col-lg-4 pl-0 d-flex justify-content-center align-items-center cityWiseDate">
              <div className="form-group w-100">
                <label>From date</label>
                <DatePicker
                  onChange={(value) => {
                    let formatedStartDate = formatDate(value);
                    if (formatedStartDate) {
                      setStartDate(new Date(formatedStartDate));
                    }

                    //formikProps.setFieldValue("startDate", value)}
                  }}
                  popperProps={{
                    positionFixed: true, // use this to make the popper position: fixed
                  }}
                  value={startDate}
                  name="startDate"
                  // minDate={new Date()}
                  format="dd-MM-y"
                  className="form-control form-control-solid h-auto calmisset readOnlySpan"
                  clearIcon={null}
                  calendarIcon={
                    <div>
                      <i className="flaticon-calendar-1"></i>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="col-lg-4 pl-0 d-flex justify-content-center align-items-center">
              {user.role !== "Financier Manager" && (
                <div className="col-lg-12 pl-0 justify-content-center align-items-center remove">
                  <div className="form-group">
                    <label>Select financier</label>
                    <Select
                      placeholder="Select..."
                      name="role"
                      className="jobRoleSelect w-100 calmisset"
                      options={roleOption}
                      onChange={onChangeRole}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-2">
              <button
                id="button"
                type="button"
                className="btn btn-primary font-weight-bold upload w-100 transformBtn"
                // disabled={isEmailUnique}
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
          <div className="col-lg-12 pl-0 d-flex">
            <div className="col-lg-3">
              <div className="form-group w-100">
                <label style={{ fontSize: "1.5rem" }}>
                  Total Agent:&nbsp;{callerWiseMis && callerWiseMis.length}
                </label>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group w-100">
                <label style={{ fontSize: "1.5rem" }}>
                  Active Agent:&nbsp;
                  {callerWiseMisAgentCounts && callerWiseMisAgentCounts.count}
                </label>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group w-100">
                <label
                  style={{ fontSize: "1.5rem" }}
                  onClick={() => displayInactiveAgentInfo()}
                >
                  Inactive Agent:&nbsp;
                  <a>
                    {callerWiseMisAgentCounts &&
                    callerWiseMisAgentCounts.count &&
                    callerWiseMis
                      ? callerWiseMis.length - callerWiseMisAgentCounts.count
                      : "All are inactive"}
                    {/* {callerWiseMisAgentCounts && callerWiseMisAgentCounts.count == 0 ? callerWiseMis.length - callerWiseMisAgentCounts.count : 0} */}
                  </a>
                </label>
              </div>
            </div>
          </div>
        </CardHeader>

        <CardBody className="p-4">
          <Tabs
            defaultActiveKey="callerwise"
            transition={false}
            onSelect={(k) => {
              // console.log(k);
              setShowCsvFile(k);
            }}
          >
            <Tab eventKey="callerwise" title="Caller Wise">
              {callerWiseMis && (
                <Table columns={columns} data={callerWiseMis} />
              )}
              {error && (
                <div className="d-flex justify-content-center align-items-center">
                  <h6>{errShow}</h6>
                </div>
              )}
            </Tab>
            <Tab
              eventKey="tlwise"
              title="TL Wise"
              onClick={() => console.log(" tesitbn g")}
            >
              {tlWiseMis && <Table columns={columnsTlWise} data={tlWiseMis} />}
              {error && (
                <div className="d-flex justify-content-center align-items-center">
                  <h6>{errShow}</h6>
                </div>
              )}
            </Tab>
          </Tabs>
        </CardBody>
      </Card>

      <div className="editModalDiv">
        <Modal
          show={showModal}
          // onHide={props.hideModal}
          size="lg"
          centered
          backdrop="static"
          keyboard={false}
          className="editTodayModalStyle"
        >
          <Modal.Header closeButton>
            <h2 className="mb-0">Inactive Agents</h2>
          </Modal.Header>
          {loading ? <LoaderPop /> : ""}
          <Modal.Body className="p-10">
            <BootstrapTable
              striped
              bordered
              hover
              size="sm"
              className="inactable"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Portfolio</th>
                  <th>Last Disposition Time</th>
                </tr>
              </thead>
              {/* <thead>
                <tr>
                  <th>#</th>

                  <th
                    className="cursor-pointer"
                    onClick={() => applySorting("fullName", !sorting.ascending)}
                  >
                    Name
                  </th>

                  <th
                    className="cursor-pointer"
                    onClick={() => applySorting("services", !sorting.ascending)}
                  >
                    Portfolio
                  </th>

                  <th
                    className="cursor-pointer"
                    onClick={() =>
                      applySorting("lastDispositionTime", !sorting.ascending)
                    }
                  >
                    Last Disposition Time
                  </th>
                </tr>
              </thead> */}
              <tbody>
                {ModalUsersData &&
                  ModalUsersData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.fullName}</td>
                      <td>{item.services ? item.services : ""}</td>
                      <td>{item.lastDispositionTime}</td>
                    </tr>
                  ))}
              </tbody>
            </BootstrapTable>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => setShowModal(false)}
              id="cancel"
              type="button"
              className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default CallerWiseMis;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    //preGlobalFilteredRows,
    //setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    //setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 100 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination, //use pagination
    useBlockLayout,
    useSticky
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <Styles>
        <div>
          <div className="tblScroll" style={{ height: "65vh" }}>
            <table
              {...getTableProps()}
              className="sticky loanTable callerTelecallingTable callerWiseMisTable"
            >
              <thead
                className="tableHead stickyTop table-fixed tableFixed"
                style={{ position: "sticky", top: 0, zIndex: 1 }}
              >
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="tableHeader"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "↓"
                              : "↑"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
                {/* <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                </th>
              </tr> */}
              </thead>

              <thead className="financewfoot">
                {footerGroups.map((group) => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                      <td {...column.getFooterProps()}>
                        {column.render("Footer")}
                      </td>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className="tableTd">
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              {/* <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot> */}
            </table>
          </div>
          {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
          {/* <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>{' '}
              <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </button>{' '}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records{' '}
                <strong>
                  {data.length}
                </strong>{' '}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page)
                  }}
                  className="gotoPage"
                />
              </span>{' '}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div> */}

          <br />
        </div>
      </Styles>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
