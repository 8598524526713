import axios from 'axios';
import AWS from 'aws-sdk';
import environment from './../../env.json';

export default async function s3BucketConfig(operation, featureType='COLLECTION') {
    return new Promise(async (resolve, reject) => {
        const data = {
            'feature': "BUCKET_CONFIG",
            'operation': operation,
            'featureType': featureType
        };
        const headers = {
            'X-Api-Key': environment.apiKey,
            'Content-Type': 'application/json',
        }
        await axios.post(`${environment.baseURL}/api/v1/service`, data,{
            headers: headers
        })
        .then(function (response) {
            if (response && 
                response.data && 
                response.data.status &&
                response.data.status === "success"
            ) {
                const data = response.data.data;
                console.log(" data ====== ", data)
                AWS.config.update({
                    accessKeyId: data.accessKeyId,
                    secretAccessKey: data.secretAccessKey
                });
                let s3Bucket = null;
                try {
                    s3Bucket = new AWS.S3({
                        params: { Bucket: data.bucketName },
                        region: data.region,
                    });
                    console.log(" s3Bucket =========", s3Bucket);
                    
                    resolve(s3Bucket);
                } catch (error) {
                    console.log(" s3Bucket eroor ", error);
                    
                }
                
            }
            else {
                reject(false);
            }
            
        })
        .catch(function (error) {
            reject(false);
        });
    });
};
