import React, { useEffect, useState } from "react";

import {
    useDispatch,
    useSelector
} from "react-redux";
import Select, { components } from 'react-select';
import DatePicker from 'react-date-picker';
import Alert from 'react-bootstrap/Alert';
import LoadingOverlay from 'react-loading-overlay';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../../../../_metronic/_partials/controls";
import "../css/editDisposition.scss";
import { addDisposition, clearMessages } from '../../../../../../store/today';
import { getLoanDetails, getLoanDetailsClearMessages } from '../../../../../../store/teleCaller';

import { getLookup } from '../../../../../../store/lookup';


function AddDisposition(props) {
    console.log('add ord edit disposition page props:', props);
    // State to store uploaded file
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [showDate, setShowDate] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const dispatch = useDispatch();

    const user = useSelector(state => state.entities.auth.data);
    let dispositionData = useSelector(state => state.entities.lookup.data);
    //console.log('disposition lookup:', dispositionData);
    const { status, error, loading, message } = useSelector((state) => state.entities.today);
    console.log('status, error, loading, message:', status, error, loading, message);
    let options = [], setLimit = 150

    useEffect(() => {
        dispatch(getLookup());
    }, [])

    useEffect(() => {
        if (dispositionData) {
            dispositionData.map((item) => {
                if (item.attribute == 'DISPOSITION') {
                    return options.push({
                        value: item.code,
                        label: item.description,
                        level: item.level
                    });
                }
            })
        }
    }, [dispositionData, options])


    //entity errors
    useEffect(() => {
        if (status === "error") {
            let errorMsg = "Something went wrong. Please try again.";
            if (typeof error === "object") {
                errorMsg = error.message;
            }
            else {
                errorMsg = error;
            }
            setErrorAlertMsg(errorMsg);
            setShowErrorAlert(true);
            setTimeout(() => {
                dispatch(clearMessages());
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
            }, 1000);
        }
    }, [dispatch, status])

    useEffect(() => {
        if (status === "success" && !loading && message !== null) {
            //disableLoading();
            if (props && props.loanId) {
                dispatch(getLoanDetails(user.userId, user.agencyId, props.loanId, user.financierId));
            }
            setShowSuccessAlert(true);
            setSuccessAlertMsg(message);
            setTimeout(() => {
                setShowSuccessAlert(false);
                setSuccessAlertMsg(null);
                dispatch(clearMessages());
                if (props && props.hideModal) {
                    props.hideModal();
                }
            }, 1000)
        }
    }, [loading, status]);

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    // useEffect(() => {
    //     if (props && props.editDispositionData.attachment1) {
    //         setFile(props.editDispositionData.attachment1);
    //     }
    //     if (props && props.editDispositionData) {
    //         if (props.editDispositionData.dispositionCode == "PTP" || props.editDispositionData.dispositionCode == "BPTP" || props.editDispositionData.dispositionCode == "FPTP") {
    //             setShowDate(true);
    //         } else {
    //             setShowDate(false);
    //         }
    //     }
    // }, [props && props.editDispositionData])


    let initialValues, formatDispositionDate = null;
    // if (props && props.editDispositionData) {

    //     if (props.editDispositionData.dispositionDate) {
    //         formatDispositionDate = formatDate(props.editDispositionData.dispositionDate); //new Date(props.editDispositionData.dispositionDate);
    //     }
    //     initialValues = {
    //         // dispositionId: props.editDispositionData['Disposition Id'],
    //         // type: props.editDispositionData.dispositionStatus,
    //         disposition: props.editDispositionData.dispositionCode || '',
    //         // agencyName: props.editDispositionData.agencyName,
    //         // agentName: props.editDispositionData.agentName,
    //         dispositionDate: new Date(formatDispositionDate) || new Date(),
    //         comments: props.editDispositionData.remark || '',
    //     };
    //     // setFile(props.editDispositionData.attachment1);
    //     //}
    // }
    // else {
    initialValues = {
        // dispositionId: '',
        // type: '',
        disposition: '',
        // agencyName: '',
        // agentName: '',
        dispositionDate: new Date(),
        attachment1: '',
        comments: '',
    };
    // }

    const EditDispositionSchema = Yup.object().shape({
        // dispositionId: Yup.string()
        //     .min(3, "Minimum 3 symbols")
        //     .max(15, "Maximum 15 symbols"),
        // type: Yup.string()
        //     .min(3, "Minimum 3 symbols")
        //     .max(25, "Maximum 25 symbols"),
        disposition: Yup.string()
            .required('Disposition Required')
        // agencyName: Yup.string()
        //     .min(3, "Minimum 3 symbols")
        //     .max(15, "Maximum 15 symbols"),
        // agent: Yup.string()
        //     .min(3, "Minimum 3 symbols")
        //     .max(50, "Maximum 50 symbols"),
        // dispositionDate: Yup.string()
        //     .min(3, "Minimum 3 symbols")
        //     .max(25, "Maximum 25 symbols"),
        // comments: Yup.string()
        //     .min(3, "Minimum 3 symbols")
        //     .max(400, "Maximum 400 symbols"),
    });


    const closeModal = () => {
        props.hideModal();
    }

    // const formatDate = (date) => {
    //     var d = new Date(date),
    //         month = "" + (d.getMonth() + 1),
    //         day = "" + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2) month = "0" + month;
    //     if (day.length < 2) day = "0" + day;

    //     return [year, month, day].join("-");
    // };

    function handleUpload(event) {
        //console.log('event file:', event.target.files);
        //setFile(event.target.files[0]);
        setFileName(event.target.files[0]);

        //only when base64 convert
        getBase64(event.target.files[0]).then(
            data => setFile(data)
        );

        event.target.value = '';
    }
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const clearImg = () => {
        setFile("");
    }

    // function FileUpload() {

    //     // Handles file upload event and updates state
    //     function handleUpload(event) {
    //         //console.log('event file:', event.target.files);
    //         //setFile(event.target.files[0]);

    //         getBase64(event.target.files[0]).then(
    //             data => setFile(data)
    //         );

    //         // Add code here to upload file to server
    //         // ...
    //     }

    //     const clearImg = () => {
    //         setFile("");
    //     }

    //     function getBase64(file) {
    //         return new Promise((resolve, reject) => {
    //             const reader = new FileReader();
    //             reader.readAsDataURL(file);
    //             reader.onload = () => resolve(reader.result);
    //             reader.onerror = error => reject(error);
    //         });
    //     }

    //     return (
    //         // <div id="upload-box">
    //         //     <div className="uploadImgDivDisposition">
    //         //         <label className="csvLabel mb-0 pl-0 mt-3">
    //         //             <input type="file"
    //         //                 accept="image/*"
    //         //                 onChange={handleUpload}
    //         //             />
    //         //             <span className='btn font-weight-bold transformBtn csvSpan'>
    //         //                 Upload Image</span>
    //         //         </label>
    //         //     </div>
    //         //     <p>{file && file.name}</p>
    //         //     <div className="attachment1">
    //         //         {file && <img src={file} />}
    //         //         {file && (
    //         //             <i className="flaticon2-cross" onClick={clearImg}></i>
    //         //         )}
    //         //     </div>
    //         // </div>
    //         <>
    //         </>
    //     );
    // }

    // /**
    //  * Component to display thumbnail of image.
    //  */
    // const ImageThumb = ({ image }) => {
    //     return <img src={URL.createObjectURL(image)} alt={image.name} />;
    // };


    return (
        <div className="editTodayDispositionDiv">
            <Card className="mb-0">
                {/* begin::Head */}
                <CardHeader className="pl-2 d-flex justify-content-center align-items-center">
                    <div className="col-lg-11 pl-0 d-flex justify-content-start align-items-center">

                        {/* {props && props.editDispositionData ? (
                        <div className="col-lg-4 pl-3 text-left">
                            <h2 className="mb-0 pt-2 pb-2">Edit Disposition</h2>
                        </div>
                    )
                        :
                        (
                            <div className="col-lg-3 pl-3 text-left">
                                <h2 className="mb-0 pt-2 pb-2">Add Disposition</h2>
                            </div>
                        )} */}
                        <div className="col-lg-5 pl-3 text-left">
                            <h2 className="mb-0 pt-2 pb-2">Add Disposition</h2>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center align-items-center">
                            {showErrorAlert &&
                                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                    {errorAlertMsg}
                                </Alert>
                            }

                            {showSuccessAlert &&
                                <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                                    {successAlertMsg}
                                </Alert>
                            }
                        </div>

                    </div>
                    {/* <div>
                    <hr />
                </div> */}
                    {props.cancelModal &&(
                        <div className="col-lg-1 pr-0 d-flex justify-content-center align-items-center">
                            <i onClick={closeModal}
                                className="flaticon2-cross cancel_Icon">
                            </i>
                        </div>
                    )}
                    
                </CardHeader>
                {/* end::Head */}

                {/*begin::Form*/}
                <CardBody>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={EditDispositionSchema}
                        onSubmit={(values) => {
                            let formatedDate;
                            //console.log('values:', values);

                            // if (props && props.editDispositionData) {
                            //     console.log('update call');
                            // }
                            // else {

                            if (file) {
                                //  console.log('uploaded file:', file);
                                values.attachment1 = file;
                                formatedDate = formatDate(values.dispositionDate);
                                //console.log('add disposition values:', values);
                                if (props && props.loanId && formatedDate) {
                                    // console.log(" editDispositionData ===== ", props.editDispositionData);
                                    dispatch(addDisposition(
                                            user.userId, 
                                            user.agencyId, 
                                            props.loanId,
                                            values.disposition, 
                                            formatedDate, 
                                            values.comments, 
                                            0,
                                            '',
                                            values.attachment1, 
                                            props.editDispositionData.techInfoAgentLoginId ? props.editDispositionData.techInfoAgentLoginId : '', 
                                            props.editDispositionData.techInfoAgentTerminalId ? props.editDispositionData.techInfoAgentTerminalId : ''
                                        )
                                    )
                                }
                            }
                            else {
                                setErrorAlertMsg('Atleast 1 attachment required.');
                                setShowErrorAlert(true);
                                setTimeout(() => {
                                    dispatch(clearMessages());
                                    setErrorAlertMsg(null);
                                    setShowErrorAlert(false);
                                }, 2000);
                            }


                            //}
                        }}

                    >
                        {formikProps => (
                            <LoadingOverlay
                                active={loading}
                                spinner
                                text={props && props.editDispositionData ? 'Edit Disposition...' : 'Adding Disposition...'}
                                // classNamePrefix='pg-list-loader-'
                                styles={{
                                    // overlay: (base) => ({
                                    //   ...base,
                                    //   background: 'rgba(255, 0, 0, 0.5)'
                                    // }),
                                    wrapper: {
                                        overflow: loading ? 'hidden' : 'hidden'
                                    },
                                    spinner: (base) => ({
                                        ...base,
                                        width: '75px',
                                        '& svg circle': {
                                            stroke: '#00b1cc'
                                        }
                                    })
                                }}
                            >
                                <Form onSubmit={formikProps.handleSubmit} id="addDispositon" className="form fv-plugins-bootstrap fv-plugins-framework addDisposition">
                                    <div className="form-group fv-plugins-icon-container row">
                                        <div className="col-lg-8 pl-0">
                                            <div className="displayContent mt-8">
                                                <div className="col-lg-4 pl-0 ml-8 text-left">
                                                    <span className="customLabel lblFontSize">Disposition</span>
                                                </div>
                                                <div className="col-lg-7 outlineNone">
                                                    <Select
                                                        placeholder="Select Disposition"
                                                        name="disposition"
                                                        className="jobRoleSelect"
                                                        value={options.value}
                                                        onChange={options => {
                                                            formikProps.setFieldValue("disposition", options.value);
                                                            if (options.value == "PTP" || options.value == "BPTP" || options.value == "FPTP") {
                                                                setShowDate(true);
                                                            } else {
                                                                setShowDate(false);
                                                                formikProps.setFieldValue("dispositionDate", new Date());
                                                            }
                                                        }}
                                                        options={options}
                                                        maxMenuHeight={190}
                                                    />
                                                    {/* {props && props.editDispositionData ? (
                                                <Select
                                                    placeholder="Select Role"
                                                    name="disposition"
                                                    className="jobRoleSelect"
                                                    value={options.value}
                                                    //defaultValue={{ value: options[2].value, label: options[2].label }}
                                                    defaultValue={{ label: props && props.editDispositionData ? props.editDispositionData.dispositionCode : '', value: props && props.editDispositionData ? props.editDispositionData.dispositionCode : '' }}
                                                    onChange={options => {
                                                        formikProps.setFieldValue("disposition", options.value)
                                                        if (options.value == "PTP" || options.value == "BPTP" || options.value == "FPTP") {
                                                            setShowDate(true);
                                                        } else {
                                                            setShowDate(false);
                                                            formikProps.setFieldValue("dispositionDate", new Date());
                                                        }
                                                    }}
                                                    options={options}
                                                    maxMenuHeight={190}

                                                />
                                            )
                                                :
                                                (
                                                    <Select
                                                        placeholder="Select Role"
                                                        name="disposition"
                                                        className="jobRoleSelect"
                                                        value={options.value}
                                                        onChange={options => {
                                                            formikProps.setFieldValue("disposition", options.value);
                                                            if (options.value == "PTP" || options.value == "BPTP" || options.value == "FPTP") {
                                                                setShowDate(true);
                                                            } else {
                                                                setShowDate(false);
                                                                formikProps.setFieldValue("dispositionDate", new Date());
                                                            }
                                                        }}
                                                        options={options}
                                                        maxMenuHeight={190}
                                                    />
                                                )} */}
                                                    <div className="text-left pl-2 errorMsg">
                                                        <ErrorMessage name="disposition" />
                                                    </div>
                                                </div>

                                            </div>

                                            {showDate && (
                                                <div className="displayContent mt-8">

                                                    <div className="col-lg-4 pl-0 ml-8 text-left">
                                                        <span className="customLabel lblFontSize">Disposition Date</span>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <DatePicker
                                                            onChange={value => formikProps.setFieldValue("dispositionDate", value)}
                                                            value={formikProps.values.dispositionDate}
                                                            name="dispositionDate"
                                                            // minDate={new Date()}
                                                            format="dd-MM-y"
                                                            className="form-control form-control-solid h-auto readOnlySpan"
                                                            clearIcon={null}
                                                            calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                                                        />
                                                        <div className="text-left pl-2 errorMsg">
                                                            <ErrorMessage name="dispositionDate" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="displayContent mt-6">
                                                <div className="col-lg-4 pl-0 ml-8 text-left">
                                                    <span className="customLabel lblFontSize">Comments</span>
                                                </div>
                                                <div className="col-lg-7">
                                                    <Field
                                                        as="textarea"
                                                        type="text"
                                                        className="form-control form-control-solid h-auto readOnlySpan"
                                                        name="comments"
                                                    />
                                                    <div className="text-left pl-2 errorMsg">
                                                        <ErrorMessage name="comments" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="displayContent mt-6">
                                                {/* <div className="col-lg-4 pl-0 ml-8 text-left">
                                            <span className="customLabel lblFontSize">Upload Attachment</span>
                                        </div> */}



                                                <div className="col-lg-4 pl-0 ml-8">
                                                    <div className="uploadImgDivTodayDisposition d-flex">
                                                        <label className="csvLabel mb-0 pl-0 mt-3">
                                                            <input type="file"
                                                                accept="image/*"
                                                                onChange={handleUpload}
                                                            />
                                                            <span className='btn btn-primary font-weight-bold transformBtn csvSpan'>
                                                                Browse Image</span>
                                                        </label>

                                                    </div>
                                                </div>
                                                <div className="col-lg-7 mt-6">
                                                    <p>{fileName && fileName.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 p4-0">
                                            {file && (
                                                <div>
                                                    <div id="upload-box">
                                                        <div className="attachment1">
                                                            {file && <img src={file} />}
                                                        </div>
                                                        {/* <div>
                                                    {file && (
                                                        <i className="flaticon2-cross" onClick={clearImg}></i>
                                                    )}
                                                </div> */}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div className="col-lg-12 d-flex justify-content-center align-items-center mt-23 pb-12">
                                        {props.cancelModal &&(
                                        <button
                                            id="cancel"
                                            type="button"
                                            className="btn btn-default font-weight-bold upload mr-4 transformBtn"
                                            // onClick={addAgencyUserOpen}
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        )}
                                        <button
                                            id="submit"
                                            type="submit"
                                            className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                                        // disabled={isEmailUnique}
                                        // onClick={addAgencyUserOpen}
                                        >
                                            {/* {props && props.editDispositionData ? 'Update' : 'Create'} */}
                                            Create
                                        </button>
                                    </div>
                                </Form>
                            </LoadingOverlay>
                        )}
                    </Formik>
                </CardBody>
                {/*end::Form*/}
            </Card>
        </div>
    )
}

export default AddDisposition;
