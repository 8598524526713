

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from "react";
import { Dropdown,Button,
  Card,
  ListGroup,
  ListGroupItem,
  Nav,
  CardGroup,
  CardDeck,
  CardColumns } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu3 } from "../../dropdowns";
import { useDispatch, useSelector } from "react-redux";
// import LoadingOverlay from 'react-loading-overlay';
import Loader from "../../../../app/modules/Loader/Loader";
import './list.scss';
import { notificationList, searchLoan, clearMessages, removeNotification } from '../../../../../src/app/store/noitificationList.js';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table';
import matchSorter from 'match-sorter'
import LongProcessFailedModal from "../../../../app/modules/commonaModal/LongProcessFailed";
import { getLongRunningProcess } from '../../../../app/store/longRunningProcess';
import Moment from 'react-moment';
import styled from 'styled-components';
const Styles = styled.div`
  table {
    th{
      padding: 0px;
    }
  }
`;

export function ListsWidget10({ className }) {

  const [pageLoading, setLoading] = useState(false);
  const [longRunningData, setLongRunningData] = useState([]);
  const [showLongProcessModal, setShowLongProcessModal] = useState(false);
  const [failedReason, setFailedReason] = useState(false);
   
  const dispatch = useDispatch();
  let notificationListData = [], notificationListDataCopy = [], setLimit = 20;
  notificationListData = useSelector((state) => state.entities.noitificationList.data.notificationLists);
  // const { status, error, loading, message } = useSelector((state) => state.entities.noitificationList);
  console.log( ` status, error, loading, message `, status, error, loading, message);
  // notificationListDataCopy = useSelector((state) => state.entities.noitificationList.data.notificationLists);
  console.log('notificationListData:', notificationListData);

  const longRunningProcessData = useSelector(state => state.entities.longRunningProcess);
  const { status, error, loading, message, offset, limit, hasMore, total } = useSelector((state) => state.entities.longRunningProcess);
  console.log(" status, error ", status, error, loading, message, offset, limit, hasMore, total);
  console.log(' longRunningProcessData:', longRunningProcessData);
  let longRunningProcessDataCopy = longRunningProcessData;
 
  const user = useSelector(state => state.entities.auth.data);

  const formatDate = (date) => {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  var date = new Date();
  var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  //only first itme called with offset 0 and limit 50
  useEffect(() => {
    // if (notificationListData.length === 0) {
      setLoading(true);
        dispatch(notificationList(user.userId, user.agencyId, 0, setLimit, firstDay, lastDay));
      setLoading(false);
    // }
  }, []);

  const enableLoading = () => {
    // alert(" test ")
    setLoading(true);
  };

  const removeNotication = (data) => {
    console.log(" data ", data);
    dispatch(removeNotification(user.userId, data.batchNumber))
  }

  const camelize = (str) => {
    // console.log(" str =======  ", );
    // let string = '';
    // str.split(' ').map(a => {
    //   // console.log(" a ===== ", a[0].toUpperCase());
    //   // console.log(" sub ===== ",a[0].toUpperCase() + a.substring(1).toLowerCase());
    //   string += a[0].toUpperCase() +''+ a.substring(1).toLowerCase();
    // })
    // return string;
   
    // Split the string at all space characters
    return str.split(' ')
    // get rid of any extra spaces using trim
    .map(a => a.trim())
    // Convert first char to upper case for each word
    .map(a => a[0].toUpperCase() + a.substring(1).toLowerCase())
    // Join all the strings back together
    .join(" ")
  }

  const longRunningProcessColumn = React.useMemo(
    () => [
      {
          // Header: 'File Name',
          Header: <div>File Name</div>,
          accessor: 'name',
          filter: 'fuzzyText',
          disableSortBy: true,
          disableFilters: true,
      },
      {
        Header: <div style={{ textAlign: 'center' }}>Notification Type</div>,
        accessor: 'operation',//(value) => (value.operation ? value.operation.replace("_", " ") : ''),
        Cell: ({ cell }) => {
          return (
              <div> {cell.row.original.operation ? camelize(cell.row.original.operation.replaceAll("_", " ")) : ''} </div>

            // <div> {cell.row.original.operation ? cell.row.original.operation.replaceAll("_", " ") : ''} </div>
          )
        },
        filter: 'fuzzyText',
        disableSortBy: true,
        disableFilters: true,
        
      },

      {
          Header: 'Total Record',
          accessor: 'totalRecord',
          filter: 'fuzzyText',
          disableSortBy: true,
          disableFilters: true,
      },
      {
          Header: 'Start Date',
          accessor: (value) => (value.createdAt ? <Moment format="DD-MM-YYYY hh:mm">{value.createdAt}</Moment> : ''),
          filter: 'fuzzyText',
          disableSortBy: true,
          disableFilters: true,
      },
      {
          Header: 'End Date',
          accessor: 'updatedAt',
          Cell: ({ cell }) => {
              // console.log(" cell ======== ", cell.row.original.fileName);
              if (cell.row.original.status === 'COMPLETED') {
                  return (
                      <div> {cell.row.original.updatedAt ? <Moment format="DD-MM-YYYY hh:mm">{cell.row.original.updatedAt}</Moment> : ''} </div>
                  )
              } else if (cell.row.original.status === 'FAILED') {
                  return (
                      
                      <div> {cell.row.original.updatedAt ? <Moment format="DD-MM-YYYY hh:mm">{cell.row.original.updatedAt}</Moment> : ''} </div>
                  )
              } else {
                  return (
                      <div> </div>
                  )
              }

          },
          filter: 'fuzzyText',
          disableSortBy: true,
          disableFilters: true,
      },
      {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ cell }) => {
              // console.log(" cell ======== ", cell.row.original.fileName);
              if (cell.row.original.status === 'COMPLETED') {
                  return (
                      <div style={{ paddingLeft: '10px' }}>
                          <span className="label label-lg label-light-success label-inline font-weight-bold py-4 pl-0" style={{ background: 'none', color: 'darkgreen' }}>
                              COMPLETED
                          </span>

                      </div>
                  )
              } else if (cell.row.original.status === 'FAILED') {
                  return (
                      <div className="long-process-modal-div" style={{ paddingLeft: '10px' }} onClick={()=>longProcessFailedClick(cell.row.original.failedReason)}>
                          <span className="label label-lg label-light-danger label-inline font-weight-bold py-4 pl-0" style={{ background: 'none' }}>
                              FAILED
                          </span>

                      </div>
                  )
              } else if (cell.row.original.status === 'PROCESSING') {
                  return (
                      <div style={{ paddingLeft: '10px' }}>
                          <span className="label label-lg label-inline font-weight-bold py-4 pl-0" style={{ background: 'none', color: '#fcba03' }}>
                              PROCESSING
                          </span>

                      </div>
                  )
              } else {
                  return (
                      <div style={{ paddingLeft: '10px' }}>
                          <span className="label label-lg label-light-primary label-inline font-weight-bold py-4" style={{ background: 'none', color: 'black' }}>
                              {cell.row.original.status}
                          </span>
                      </div>
                  )
              }

          },
          disableSortBy: true,
          disableFilters: true,
      },
      {
          Header: 'Action',
          accessor: 'fileName',
          Cell: ({ cell }) => {
            return (
              <div>
                  <a
                      className="btn btn-sm btn-clean btn-icon view-log-detail"
                      title="Download"
                      // type="button"
                      href={cell.row.original.operation == 'EXPORT_DATA' ? cell.row.original.exportFileName : cell.row.original.fileName}
                      target="_blank"
                  >
                      <i className="flaticon2-download-2"></i>
                  </a>
              </div>
            )
          },
          disableSortBy: true,
          disableFilters: true,
      },

    ],
    []
  )

  function NotificationList() {
    
    const listItems = notificationListData.map((notificationList, index) =>
      <div className="mb-6" key={index}>
        <div className="d-flex align-items-center flex-grow-1" >
          {/* <label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
              <input type="checkbox" value="1" />
              <span></span>
          </label> */}
    
          <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
            <div className="d-flex flex-column align-items-cente py-2 w-75" >
              <spane
                key={index}
                className=" mb-1"
              >
                <span>
                  BATCH NUMBER
                </span> 
                <span style={{fontWeight: 'bold', padding: '5px 5px 5px 5px'}}>
                  {notificationList.batchNumber}
                </span> 
                <span>
                of
                </span> 
                <span  style={{fontWeight: 'bold',padding: '5px 5px 5px 5px'}}>
                  {notificationList.operation}
                </span>
              </spane>
            </div>
            {notificationList.status == 'FAILED' && (
              <span className="label label-lg label-light-danger label-inline font-weight-bold" style={{background: 'none'}}>
                {notificationList.status}
              </span>
            )}
            {notificationList.status == 'PROCESSING' && (
              <span className="label label-lg label-inline font-weight-bold py-4 pl-0" style={{ background: 'none', color: '#fcba03' }}>
                {notificationList.status}
              </span>
            )}
            {notificationList.status == 'COMPLETED' && (
              <span className="label label-lg label-light-success label-inline font-weight-bold py-4 pl-0" style={{ background: 'none', color: 'darkgreen' }}>
                {notificationList.status}
              </span>
            )}
            {notificationList.status != 'COMPLETED' && notificationList.status != 'FAILED'  && notificationList.status != 'PROCESSING' && (
              <span className="label label-lg label-light-primary label-inline font-weight-bold py-4" style={{ background: 'none', color: 'black' }}>
                {notificationList.status}
              </span>
            )}
          </div>
        </div>
      </div>
    );

    return(
      listItems
    );
  }

  useEffect(() => {
    dispatch(getLongRunningProcess(user.userId, 0, setLimit));
  },[]);

  useEffect(() => {
    // if (!longRunningProcessData.data) {
    //     dispatch(getLongRunningProcess(user.userId, 0, setLimit));
    // }
    // alert(' test ')
    if (longRunningProcessData.data) {
        let temp = []
        for (let index = 0; index < longRunningProcessDataCopy.data.data.length; index++) {
          let element = longRunningProcessDataCopy.data.data[index];
          // let tempData = {};
          // tempData.operation = camelize(element.operation.replaceAll("_", " "));
          // tempData.id = element.id;
          // tempData.createdAt = element.createdAt;
          // tempData.createdBy = element.createdBy;
          // tempData.endDate = element.endDate;
          // tempData.exportFileName = element.exportFileName;
          // tempData.exportType = element.exportType;
          // tempData.exportTypeId = element.exportTypeId;
          // tempData.exportTypeName = element.exportTypeName;
          // tempData.failedReason = element.failedReason;
          // tempData.fileName = element.fileName;
          // tempData.name = element.name;
          // tempData.startDate = element.startDate;
          // tempData.status = element.status;
          // tempData.stream = element.stream;
          // tempData.totalRecord = element.totalRecord;
          // tempData.updatedAt = element.updatedAt;
          temp.push(element);
        }
        setLongRunningData(temp);
    }
    if (hasMore === true && loading === false) {
        dispatch(getLongRunningProcess(user.userId, (offset + setLimit), setLimit));
    }

  }, [longRunningProcessData.data]);  

  const longProcessFailedClick = (failedReason) => {
    setFailedReason(failedReason)
    setShowLongProcessModal(true)

  }

  const hideEditModal = () => {
    setShowLongProcessModal(false);
  }

  return (
    <>
      <div className={`card card-custom ${className}`}>

        {/* Header */}
        <div className="card-header border-2">
          <h3 className="card-title font-weight-bolder text-dark">
            Notifications
          </h3>
        </div>

        {/* Body */}
        <div className="card-body pt-0">
        {loading ? <Loader /> : " "}
          {/* <LoadingOverlay
            active={loading}
            spinner
            text="Loading data..."
            className="loading-spinner-custom-color"
            classNamePrefix='dashboard-page-loader-'
            styles={{
              wrapper: {
                overflow: loading ? 'hidden' : 'hidden'
              },
              spinner: (base) => ({
                ...base,
                width: '75px'
              })
            }}
          > */}
          {/* <NotificationList /> */}
            <Styles>
              <Table columns={longRunningProcessColumn} data={longRunningData} />
            </Styles>
            <LongProcessFailedModal showModal={showLongProcessModal} hideModal={hideEditModal} failedReason={failedReason} />
          {/* </LoadingOverlay> */}
        </div>
        
      </div>
    </>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
      <div>
          <input
              value={filterValue || ''}
              onChange={e => {
                  setFilter(e.target.value.toString().toLowerCase() || undefined) // Set undefined to remove the filter entirely
              }}
              // placeholder={`Search ${count} records...`}
              className="searchFilter"
          />
          <i className="flaticon-search searchIcon"></i>
      </div>
  )
}


function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
      () => ({
          // Add a new fuzzyTextFilterFn filter type.
          fuzzyText: fuzzyTextFilterFn,
          // Or, override the default text filter to use
          // "startWith"
          text: (rows, id, filterValue) => {
              return rows.filter(row => {
                  const rowValue = row.values[id]
                  return rowValue !== undefined
                      ? String(rowValue)
                          .toLowerCase()
                          .startsWith(String(filterValue).toLowerCase())
                      : true
              })
          },
      }),
      []
  )

  const defaultColumn = React.useMemo(
      () => ({
          // Let's set up our default Filter UI
          Filter: DefaultColumnFilter,
      }),
      []
  )

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },

  } = useTable(
      {
          columns,
          data,
          defaultColumn, // Be sure to pass the defaultColumn option
          filterTypes,
          initialState: { pageIndex: 0, pageSize: 10 }
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,//sort data
      usePagination,//use pagination
  )

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows.slice(0, 10);
  return (
      <>
          <div>
              <div className="tblScroll">
                  <table {...getTableProps()} className="allNotificationTable popnotifitab" style={{ width: '100%' }}>
                      <thead className="tableHead">
                          {headerGroups.map(headerGroup => (
                              <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                                  {headerGroup.headers.map((column, index) => (
                                      <th key={index}>
                                          <table style={{ width: '100%' }}>
                                              <thead>
                                                  <tr>
                                                      <th className="noborder" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                          {column.render('Header')}
                                                          {/* Add a sort direction indicator */}
                                                          <span>
                                                              {column.isSorted
                                                                  ? column.isSortedDesc
                                                                      ? '↓'
                                                                      : '↑'
                                                                  : ''}
                                                          </span>
                                                      </th>
                                                  </tr>
                                                  {/* <tr>
                                                      <th>{column.canFilter ? column.render('Filter') : null}</th>
                                                  </tr> */}
                                              </thead>
                                          </table>
                                      </th>
                                  ))}
                              </tr>
                          ))}
                          <tr>
                              <th
                                  colSpan={visibleColumns.length}
                                  style={{
                                      textAlign: 'left',
                                  }}
                              >
                                  {/* <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              /> */}
                              </th>
                          </tr>
                      </thead>
                      <tbody {...getTableBodyProps()}>
                          {page.map((row, i) => {
                              prepareRow(row)
                              return (
                                  <tr {...row.getRowProps()}>
                                      {row.cells.map(cell => {
                                          return <td {...cell.getCellProps()} className="tableTd">{cell.render('Cell')}</td>
                                      })}
                                  </tr>
                              )
                          })}
                      </tbody>
                  </table>
              </div>
              {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
              <div className="row m-0">
                  <div className="pagination col-lg-12">
                      <div className="col-lg-2">
                          <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                              {'<<'}
                          </button>{' '}
                          <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                              {'<'}
                          </button>{' '}
                          <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                              {'>'}
                          </button>{' '}
                          <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                              {'>>'}
                          </button>{' '}
                      </div>
                      <div className="col-lg-2">
                          <span>
                              Total Records{' '}
                              <strong>
                                  {data.length}
                              </strong>{' '}
                          </span>
                      </div>
                      <div className="col-lg-2">
                          <span>
                              Page{' '}
                              <strong>
                                  {pageIndex + 1} of {pageOptions.length}
                              </strong>{' '}
                          </span>
                      </div>
                      <div className="col-lg-3">
                          <span>
                              Go to page{' '}
                              <input
                                  type="number"
                                  defaultValue={pageIndex + 1}
                                  onChange={e => {
                                      const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                      gotoPage(page)
                                  }}
                                  className="searchFilter"
                              />
                          </span>{' '}
                      </div>
                      <div className="col-lg-3">
                          <select
                              value={pageSize}
                              onChange={e => {
                                  setPageSize(Number(e.target.value))
                              }}
                              className="selectDisplayNoOfRecords"
                          >
                              {[10, 20, 30, 40, 50].map(pageSize => (
                                  <option key={pageSize} value={pageSize}>
                                      Show {pageSize}
                                  </option>
                              ))}
                          </select>
                      </div>
                  </div>
              </div>
              <br />
              {/* <div>Showing the first 20 results of {rows.length} rows</div>
      <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div> */}
          </div>
      </>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
      const rowValue = row.values[id]
      return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'
