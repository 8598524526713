import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Geocode from "react-geocode";
import { google_api_key } from '../../../utils/constant';
Geocode.setApiKey(google_api_key.api_key);

function ShowImageWithOtherInfo(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  // useEffect(()=>{
  //   // Get address from latitude & longitude.
  //   Geocode.fromLatLng("48.8583701", "2.2922926").then(
  //     (response) => {
  //       const address = response.results[0].formatted_address;
  //       console.log(" address ----- ", address);
  //       console.log(address);
  //     },
  //     (error) => {
  //       console.log(" Error --- ", error);
  //       console.error(error);
  //     }
  //   );
  // },[])
  

  return (
    <div>
    <div className="imageviewMain" style={{backgroundImage: 'url("https://s3.ap-south-1.amazonaws.com/assetsandevidence.acenna.in/evidence/1671591896890_2350.jpg")'}}>
        <div className="bottomInfo">
          <div className="googlemapimg">
            <a target="_blank" href="https://www.google.com/maps/place/29%C2%B024'10.8%22N+76%C2%B057'57.6%22E/@29.4023737,76.9639508,17z/data=!4m5!3m4!1s0x0:0xbc7500644bf4a19a!8m2!3d29.403!4d76.966">
              <img
                alt="mapicon"
                src={toAbsoluteUrl("/media/img/mapback.jpg")}
              />
            </a>
          </div>
          <div className="imagePersonInfo">
            <p>4,1st Floor, 237/243, Bharat Mkt,barar House, A Rehman Street, Mandvi, Mumbai, Maharashtra, 400003, India</p>
            <p>28 Dec 2022, 17:40 PM</p>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default injectIntl(connect(null, auth.actions)(ShowImageWithOtherInfo));
export default injectIntl(ShowImageWithOtherInfo);
