import React, { useState, useEffect } from "react";
//import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import millify from "millify";
import DatePicker from "react-date-picker";
import Select from "react-select";
import moment from "moment";

import "../../Loan/css/loan.scss";
import "../css/bucketWiseTable.scss";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import matchSorter from "match-sorter";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../Loader/Loader";
import Alert from "react-bootstrap/Alert";

//import makeData from "./../../utils/makeData";

import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
//import { toAbsoluteUrl } from "../../../../layout/_metronic/_helpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import {
  bucketWisePerformanceClearMessages,
  bucketWisePerformance,
} from "../../../store/dashboard";
import { agencyFinancierList } from "../../../store/loan";
import {
  getAllMonth,
  getCurrentYearMonthDate,
  getYears,
} from "../../../utils/helperFunctions";

console.log("getAllMonth----------", getAllMonth());
function BucketWisePerformaceTable() {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errShow, setErrShow] = useState(null);
  const [roleOption, setRoleOptions] = useState([]);
  // console.log("agencyFinancierListData-------",roleOption);
  const [financierId, setFinancierId] = useState(0);
  const [years, setYears] = useState(getYears());
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [defaultMonthValue, setDefaultMonthValue] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  let errorMsg; //= "Something went wrong. Please try again.";
  const user = useSelector((state) => state.entities.auth.data);
  let bucketWisePerformanceData = useSelector(
    (state) => state.entities.dashboard.bucketWisePerformance.data
  );
 
  let agencyFinancierListData = useSelector(
    (state) => state.entities.loan.agenciesFinanciersList.data
  );

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const numFormatter = (num) => {
    let toFixe = 4;
    if (num <= 0) {
      return num.toFixed(2);
    } else if (num > 999 && num < 9999) {
      toFixe = 3;
    } else if (num >= 10000) {
      toFixe = 2;
    }
    return (num / 1000000).toFixed(toFixe);
  };

  var date = new Date();
  var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  var lastDay = formatDate(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );

  let [startDate, setStartDate] = useState(new Date(firstDay));
  let [endDate, setEndDate] = useState(new Date(lastDay));

  const onChangeRole = (data) => {
    console.log(" data ", data);
    setFinancierId(data.value);
  };
  console.log("agencyFinancierListData1----------",agencyFinancierListData);
  useEffect(() => {
    if (agencyFinancierListData) {
      let roleOptionsTemp = [];
      for (let index = 0; index < agencyFinancierListData.length; index++) {
        let value = agencyFinancierListData[index];
        // console.log("agencyFinancierListData-------",value);
        if (value.type === "financier" || value.type === "All" ) {
          if(value.active === "Y" || value.type === "All"){
            roleOptionsTemp.push({ value: value.id, label: value.name });
          }
        }
      }
      setRoleOptions(roleOptionsTemp);
    }
  }, [agencyFinancierListData]);

  useEffect(() => {
    if (!agencyFinancierListData) {
      dispatch(agencyFinancierList(user.userId));
    }
  }, []);

  useEffect(() => {
    // let temp = []
    // for (let index = 2019; index <= 2030; index++) {
    // 	temp[index] = { value: index, label: index }
    // }
    // setYears(temp);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Bucket",
        // accessor: 'bucket',
        accessor: (value) => (
          <div style={{ textAlign: "left" }}>
            <div>{value.bucket}</div>
            <div
              className="descriptionStyle"
              style={{ color: value.color || "black" }}
            >
              {value.description}
            </div>
          </div>
        ),
        Footer: <div className=" tblFooterColor text-left">TOTAL</div>,
        disableFilters: true,
      },
      {
        Header: "Total Cases",
        accessor: (value) => (value.totalCases ? value.totalCases : 0),
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData
                  ? bucketWisePerformanceData.totalCases
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>POS</div>
            <div>(M)</div>
          </div>
        ),
        id: "POS",
        accessor: (value) => numFormatter(value.pos ? value.pos : 0),
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalPos
                  ? numFormatter(bucketWisePerformanceData.totalPos)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>dues</div>
            <div>(M)</div>
          </div>
        ),
        id: "dues",
        accessor: (value) => numFormatter(value.dues ? value.dues : 0),
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalDues !== 0
                  ? numFormatter(bucketWisePerformanceData.totalDues)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Paid</div>
            <div>(M)</div>
          </div>
        ),
        id: "Paid (M)",
        accessor: (value) =>
          numFormatter(value.paidAmount ? value.paidAmount : 0, {
            space: true,
          }),
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalPaidAmount !== 0
                  ? numFormatter(bucketWisePerformanceData.totalPaidAmount)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Resolution</div>
            <div>(%)</div>
          </div>
        ),
        id: "resolution",
        accessor: (value) => {
          return (value.resolution ? value.resolution : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalResolution
                  ? (
                      (bucketWisePerformanceData.totalResolution /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>PTP</div>
            <div>(%)</div>
          </div>
        ),
        id: "ptp",
        accessor: (value) => {
          return (value.ptp ? value.ptp : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className="tblFooterColor">
                {bucketWisePerformanceData.totalPtp
                  ? (
                      (bucketWisePerformanceData.totalPtp /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>cb</div>
            <div>(%)</div>
          </div>
        ),
        id: "cb",
        accessor: (value) => {
          return (value.cb ? value.cb : 0).toFixed(2);
        },
        Footer: (value) => {
          //   const total = React.useMemo(
          //     () => value.rows.reduce((sum, row) => (parseFloat(row.values.cb)) + sum, 0),
          //     [value.rows]
          //   );

          //   return <><div className="mt-4 tblFooterColor">{total.toFixed(2)}</div></>;
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalCb
                  ? (
                      (bucketWisePerformanceData.totalCb /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>BPTP</div>
            <div>(%)</div>
          </div>
        ),
        id: "bptp",
        accessor: (value) => {
          return (value.bptp ? value.bptp : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalBptp
                  ? (
                      (bucketWisePerformanceData.totalBptp /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Dispute</div>
            <div>(%)</div>
          </div>
        ),
        id: "dispute",
        accessor: (value) => {
          return (value.dispute ? value.dispute : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalDispute
                  ? (
                      (bucketWisePerformanceData.totalDispute /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Bfu</div>
            <div>(%)</div>
          </div>
        ),
        id: "bfu",
        accessor: (value) => {
          return (value.bfu ? value.bfu : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalBfu
                  ? (
                      (bucketWisePerformanceData.totalBfu /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Rtp</div>
            <div>(%)</div>
          </div>
        ),
        id: "rtp",
        accessor: (value) => {
          return (value.rtp ? value.rtp : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalRtp
                  ? (
                      (bucketWisePerformanceData.totalRtp /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>lm</div>
            <div>(%)</div>
          </div>
        ),
        id: "lm",
        accessor: (value) => {
          return (value.lm ? value.lm : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalLm
                  ? (
                      (bucketWisePerformanceData.totalLm /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>Rnr</div>
            <div>(%)</div>
          </div>
        ),
        id: "rnr",
        accessor: (value) => {
          return (value.rnr ? value.rnr : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalRnr
                  ? (
                      (bucketWisePerformanceData.totalRnr /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>dl</div>
            <div>(%)</div>
          </div>
        ),
        id: "dl",
        accessor: (value) => {
          return (value.dl ? value.dl : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalDl
                  ? (
                      (bucketWisePerformanceData.totalDl /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>nc</div>
            <div>(%)</div>
          </div>
        ),
        id: "nc",
        accessor: (value) => {
          return (value.nc ? value.nc : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalNc
                  ? (
                      (bucketWisePerformanceData.totalNc /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>sfd</div>
            <div>(%)</div>
          </div>
        ),
        id: "sfd",
        accessor: (value) => {
          return (value.sfd ? value.sfd : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalSfd
                  ? (
                      (bucketWisePerformanceData.totalSfd /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>anf</div>
            <div>(%)</div>
          </div>
        ),
        id: "anf",
        accessor: (value) => {
          return (value.anf ? value.anf : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalAnf
                  ? (
                      (bucketWisePerformanceData.totalAnf /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>dnc</div>
            <div>(%)</div>
          </div>
        ),
        id: "dnc",
        accessor: (value) => {
          return (value.dnc ? value.dnc : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalDnc
                  ? (
                      (bucketWisePerformanceData.totalDnc /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: (
          <div>
            <div>pending</div>
            <div>(%)</div>
          </div>
        ),
        id: "pending",
        accessor: (value) => {
          return (value.pending ? value.pending : 0).toFixed(2);
        },
        Footer: (value) => {
          let bucketWisePerformanceData = useSelector(
            (state) => state.entities.dashboard.bucketWisePerformance.data[1][0]
          );
          return (
            <>
              <div className=" tblFooterColor">
                {bucketWisePerformanceData.totalPending
                  ? (
                      (bucketWisePerformanceData.totalPending /
                        bucketWisePerformanceData.totalPos) *
                      100
                    ).toFixed(2)
                  : 0}
              </div>
            </>
          );
        },
        disableFilters: true,
      },
    ],
    []
  );

  const { status, error, loading, message } = useSelector(
    (state) => state.entities.dashboard.bucketWisePerformance
  );
  // console.log("bucketWisePerformance...", error, status, loading, message);

  useEffect(() => {
    if (status === "error") {
      if (typeof error === "object") {
        //console.log('check bucketwise');
        errorMsg = error.message;
        setErrShow(error.message);
      } else {
        errorMsg = error;
      }
      setErrorAlertMsg(errorMsg);
      setShowErrorAlert(true);
      setTimeout(() => {
        //setPageLoader(false);
        dispatch(bucketWisePerformanceClearMessages());
        setErrorAlertMsg("");
        setShowErrorAlert(false);
      }, 2000);
    }
  }, [status, dispatch]);

  useEffect(() => {
    let getYearMonthDate = getCurrentYearMonthDate();
    console.log(
      " testing ======= ",
      getYearMonthDate[0],
      getYearMonthDate[1],
      getYearMonthDate[2]
    );
    console.log(" getYearMonthDate[1] ----", getYearMonthDate[1]);
    setYear(getYearMonthDate[0]);
    setMonth(getYearMonthDate[1]);
    if (!month) {
      setDefaultMonthValue(getAllMonth(getYearMonthDate[1]));
    }
    if (bucketWisePerformanceData === null) {
      dispatch(
        bucketWisePerformance(
          user.userId,
          user.agencyId,
          getYearMonthDate[0],
          getYearMonthDate[1],
          user.role == "Financier Manager" ? user.financierId : financierId
        )
      );
    }
  }, [getAllMonth]);

  useEffect(() => {
    let tempCsvData = [];
    if (bucketWisePerformanceData !== null) {
      // console.log(" bucketWisePerformanceData[0] --------- ", bucketWisePerformanceData[0]);
      for (
        let index = 0;
        index < bucketWisePerformanceData[0].length;
        index++
      ) {
        const tempSingle = bucketWisePerformanceData[0][index];
        let newSingleData = {};
        // console.log(" tempSingle ----- ", tempSingle);
        newSingleData.totalCases = tempSingle.totalCases;
        newSingleData.allocationDPD = tempSingle.allocationDPD;
        newSingleData.pos = tempSingle.pos
          ? parseFloat(tempSingle.pos).toFixed(2)
          : 0;
        newSingleData.dues = tempSingle.dues
          ? parseFloat(tempSingle.dues).toFixed(2)
          : 0;
        newSingleData.paidAmount = tempSingle.paidAmount
          ? parseFloat(tempSingle.paidAmount).toFixed(2)
          : 0;
        newSingleData.resolution = tempSingle.resolution
          ? parseFloat(tempSingle.resolution).toFixed(2)
          : 0;
        newSingleData.ptp = tempSingle.ptp
          ? parseFloat(tempSingle.ptp).toFixed(2)
          : 0;
        newSingleData.cb = tempSingle.cb
          ? parseFloat(tempSingle.cb).toFixed(2)
          : 0;
        newSingleData.bptp = tempSingle.bptp
          ? parseFloat(tempSingle.bptp).toFixed(2)
          : 0;
        newSingleData.dispute = tempSingle.dispute
          ? parseFloat(tempSingle.dispute).toFixed(2)
          : 0;
        newSingleData.bfu = tempSingle.bfu
          ? parseFloat(tempSingle.bfu).toFixed(2)
          : 0;
        newSingleData.rtp = tempSingle.rtp
          ? parseFloat(tempSingle.rtp).toFixed(2)
          : 0;
        newSingleData.lm = tempSingle.lm
          ? parseFloat(tempSingle.lm).toFixed(2)
          : 0;
        newSingleData.dl = tempSingle.dl
          ? parseFloat(tempSingle.dl).toFixed(2)
          : 0;

        newSingleData.pending = tempSingle.pending
          ? parseFloat(tempSingle.pending).toFixed(2)
          : 0;
        newSingleData.nc = tempSingle.nc
          ? parseFloat(tempSingle.nc).toFixed(2)
          : 0;
        newSingleData.rnr = tempSingle.rnr
          ? parseFloat(tempSingle.rnr).toFixed(2)
          : 0;
        newSingleData.sfd = tempSingle.sfd
          ? parseFloat(tempSingle.sfd).toFixed(2)
          : 0;
        newSingleData.anf = tempSingle.anf
          ? parseFloat(tempSingle.anf).toFixed(2)
          : 0;
        newSingleData.dnc = tempSingle.dnc
          ? parseFloat(tempSingle.dnc).toFixed(2)
          : 0;
        newSingleData.bucket = tempSingle.bucket;
        newSingleData.description = tempSingle.description;
        newSingleData.color = tempSingle.color;
        // newSingleData.totalCases = tempSingle.totalCases ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        // newSingleData.sfd = tempSingle.sfd ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        // newSingleData.sfd = tempSingle.sfd ? parseFloat(tempSingle.sfd).toFixed(2) : 0;
        tempCsvData.push(newSingleData);
      }
      setCsvData(tempCsvData);
      // console.log(" bucketWisePerformanceData[0] --------- ", tempCsvData);
    }
  }, [bucketWisePerformanceData]);

  // const data = React.useMemo(() => makeData(100000), [])
  // const data = React.useMemo(() => bucketWisePerformanceData, [])
  const data = bucketWisePerformanceData;

  //console.log("Bucket wise data:", bucketWisePerformanceData);

  useEffect(() => {
    if (status == "success") {
      if (data) {
        setErrShow(null);
      }
    }
  }, [status]);

  const refreshBucketWiseInfo = () => {
    if (user) {
      dispatch(
        bucketWisePerformance(
          user.userId,
          user.agencyId,
          firstDay,
          lastDay,
          user.role == "Financier Manager" ? user.financierId : financierId
        )
      );
      setStartDate(new Date(firstDay));
      setEndDate(new Date(lastDay));
    }
  };

  const setDefaultMonthValueFunction = (currentYear, currentMonth) => {
    setDefaultMonthValue(getAllMonth(currentMonth));
  };

  const searchBucketStatus = (searchType = "normal") => {
    let currentMonth = month;
    let currentYear = year;
    setYear(currentYear);
    setMonth(currentMonth);
    setDefaultMonthValue(getAllMonth(currentMonth));
    console.log("NormalMonth-----------", currentMonth);
    if (currentMonth && currentYear) {
      if (searchType == "currentMonth") {
        console.log("check month year=========", searchType);
        currentMonth = moment(new Date())
          .startOf("month")
          .format("MM");
        currentYear = moment(new Date())
          .endOf("month")
          .format("YYYY");
        setYear(currentYear);
        setMonth(currentMonth);
        setDefaultMonthValue(getAllMonth(currentMonth));
        console.log("CurrentMonth=========", currentMonth, currentYear);
      } else if (searchType == "lastMonth") {
        console.log("check month year=========", searchType);
        currentMonth = moment(new Date())
          .subtract(1, "month")
          .startOf("month")
          .format("MM");
        currentYear = moment(new Date())
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY");
        setYear(currentYear);
        setMonth(currentMonth);
        setDefaultMonthValue(getAllMonth(currentMonth));
        console.log("lastMonth=========", currentMonth);
      }

      dispatch(
        bucketWisePerformance(
          user.userId,
          user.agencyId,
          currentYear,
          currentMonth,
          user.role == "Financier Manager" ? user.financierId : financierId
        )
      );
    }
  };

  return (
    <div className="logs-card-div">
      {loading ? <Loader /> : " "}
      <Card className="bucketWise w-100">
        <CardHeader>
          <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-start flex-column">
            <h3 className="mb-0">Overall bucket wise</h3>
            {/* <span className="subHeader">Long term loan performance bucket wise</span> */}
          </div>
          <div className="col-lg-4 mt-4 d-block">
            {showErrorAlert && (
              <Alert
                variant="danger"
                onClose={() => setShowErrorAlert(false)}
                dismissible
              >
                {errorAlertMsg}
              </Alert>
            )}

            {showSuccessAlert && (
              <Alert
                className="custom-success-alert"
                onClose={() => setShowSuccessAlert(false)}
                dismissible
              >
                {successAlertMsg}
              </Alert>
            )}
          </div>
          <div className="col-lg-3 pl-0 pr-0 d-flex justify-content-end align-items-center">
            <div className="col-lg-2 pl-0">
              {csvData && (
                <button
                  id="CSV"
                  type="button"
                  className="settingBtn font-weight-bold download-csv transformBtn"
                >
                  <CSVLink
                    data={csvData}
                    filename={"BucketWisePerformance.csv"}
                    target="_blank"
                  >
                    <span className="svg-icon svgSetting">
                      <SVG
                        className="svgSetting"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/csv-file-format-extension.svg"
                        )}
                      ></SVG>
                    </span>
                  </CSVLink>
                </button>
              )}
            </div>
          </div>
        </CardHeader>
        <CardHeader>
          <div className="col-lg-12 pt-4 pl-0 d-flex justify-content-start align-items-center">
            {user.role !== "Financier Manager" && (
              <div className="col-lg-2 pl-0 justify-content-center align-items-center remove">
                <div className="form-group">
                  <label>Select financier</label>
                  <Select
                    defaultValue={{ label: "All", value: 0 }}
                    placeholder="Select..."
                    name="role"
                    className="jobRoleSelect w-100"
                    options={roleOption}
                    onChange={onChangeRole}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            )}
            {/* <div className="col-lg-4 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>From date</label>
                  <DatePicker
                    onChange={value => {

                      let formatedStartDate = formatDate(value);
                      if (formatedStartDate) {
                        setStartDate(new Date(formatedStartDate));
                      }


                      //formikProps.setFieldValue("startDate", value)}
                    }}
                    value={startDate}
                    name="startDate"
                    // minDate={new Date()}
                    format="dd-MM-y"
                    className="form-control form-control-solid h-auto readOnlySpan"
                    clearIcon={null}
                    calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                  />
                </div>
              </div>
              <div className="col-lg-4 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>To date</label>
                  <DatePicker
                    onChange={value => {

                      let formatedEndDate = formatDate(value);
                      if (formatedEndDate) {
                        setEndDate(new Date(formatedEndDate));
                      }

                      // if (endDate) {
                      //     console.log('end date:', endDate);
                      // }
                      //formikProps.setFieldValue("endDate", value)}
                    }}
                    value={endDate}
                    name="endDate"
                    //minDate={new Date(startDate)}
                    format="dd-MM-y"
                    className="form-control form-control-solid h-auto readOnlySpan"
                    clearIcon={null}
                    calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                  />
                </div>
              </div> */}
            {year && (
              <div className="col-lg-2 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>Select Year</label>

                  <Select
                    // defaultValue={'2022'}
                    defaultValue={{label: year, value: year}}
                    // defaultValue={years}
                    placeholder="Select..."
                    name="export_type"
                    className="jobRoleSelect w-100"
                    options={years}
                    onChange={(data) => {
                      setYear(data.value);
                    }}
                    value={{label: year, value: year}}
                  />
                </div>
              </div>
            )}
            {defaultMonthValue && (
              <div className="col-lg-2 pl-0 justify-content-center align-items-center">
                <div className="form-group">
                  <label>Select Month</label>
                  <Select
                    placeholder="Select..."
                    defaultValue={defaultMonthValue}
                    name="export_type"
                    className="jobRoleSelect w-100"
                    options={getAllMonth()}
                    onChange={(data) => {
                      let currentYear = year;
                      if (!currentYear) {
                        currentYear = date.getFullYear();
                      }
                      setMonth(data.value);
                      setDefaultMonthValue(getAllMonth(data.value));
                    }}
                    value={defaultMonthValue}
                  />
                </div>
              </div>
            )}

            <div className="col-lg-2">
              <button
                id="button"
                type="button"
                className="btn btn-primary font-weight-bold upload mr-4 transformBtn" style={{ width: "100%" }}
                // disabled={isEmailUnique}
                // onClick={searchBucketWisePerformance}
                onClick={() => searchBucketStatus("normal")}
              >
                Search
              </button>
            </div>
            <div className="col-lg-2">
              <button
                id="button"
                type="button"
                className="btn btn-primary font-weight-bold upload mr-4 transformBtn" style={{ width: "100%" }}
                onClick={() => searchBucketStatus("currentMonth")}
              >
                Current Month
              </button>
            </div>

            <div className="col-lg-2">
              <button
                id="button"
                type="button"
                className="btn btn-primary font-weight-bold upload mr-4 transformBtn" style={{ width: "100%" }}
                onClick={() => searchBucketStatus("lastMonth")}
              >
                Last Month
              </button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="p-4">
          {data && <Table columns={columns} data={data[0]} />}
          {error && (
            <div className="d-flex justify-content-center align-items-center">
              <h6>{errShow}</h6>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default BucketWisePerformaceTable;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <div className="tblScroll someradiush">
          <table {...getTableProps()} className="loanTable bucketWiseTable">
            <thead className="tableHead">
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="tableHeader"
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {// Render the header
                      column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
              {/* <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr> */}
            </thead>
            <thead className="tfootmngbuckt">
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="tableTd">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
