import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../css/Chart.scss';
import { useDispatch, useSelector } from "react-redux";
import { customerConnects } from '../../../store/financierDashboard';

// const columns = [
//   { id: 'name', label: 'Name', minWidth: 170 },
//   { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
//   {
//     id: 'population',
//     label: 'Population',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'size',
//     label: 'Size\u00a0(km\u00b2)',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'density',
//     label: 'Density',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toFixed(2),
//   },
// ];

const columns = [
	{ id: 'day', label: 'Day', minWidth: 170 },
	{ id: 'customerConnect', label: 'Customer Connect', minWidth: 100, align: 'center', },
	{
	  id: 'previous',
	  label: 'Previous Day Diff',
	  minWidth: 170,
	  align: 'center',
	  format: (value) => value.toLocaleString('en-US'),
	},
	
];

function createData(day, customeconnect, previous, type) {
//   const density = population / size;
  return { day, customeconnect, previous, type};
}

const rows = [
  createData('India',1324171354, 3287263,'up'),
  createData('China', 1403500365, '357578%','up'),
  createData('Italy', 60483973, '301340','down'),
  createData('United States',327167434, 9833520,'down'),
  createData('Canada', 37602103, 9984670,'up'),
  createData('Australia', 25475400, 7692024,'up'),
  createData('Germany', 83019200, '357578%','up')
];

console.log(" rows ===== ", rows);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
});

export default function CustomTable(props) {
	const dispatch = useDispatch();
    const user = useSelector(state => state.entities.auth.data);
    const customerConnect = props.customerConnect;
  	console.log(" customerConnect ", customerConnect)
	const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container} style={{height:'375px'}}>
        <div className='tblHeader'>
          <div className='customercon'>Customer Connect</div>
        </div>
        <Table stickyHeader aria-label="sticky table" >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
			
            {customerConnect && customerConnect.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column, index) => {
                    const value = row[column.id];
                    // const value = row[column.id];
					// console.log(" index ==== ", row.type);
					// console.log(" column ==== ", column.id);
                    return (
                      <TableCell key={column.id} align={column.align}>
						  {column.id != 'previous' && (
							  column.format && typeof value === 'number' ? column.format(value) : value
						  )}
						  {column.id == 'previous' && row.type == 'up' && (
							<div style={{ color:'gred' }}><span>{value}</span><span style={{fontSize:'20px'}}>&uarr;</span></div>
						  )}
						  {column.id == 'previous' && row.type == 'down' && (
							<div style={{ color:'red' }}><span>{value}</span><span style={{fontSize:'20px'}}>&#8595;</span></div>
						  )}
						  {column.id == 'previous' && row.type == 'same' && (
							<div ><span>{value}</span></div>
						  )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}