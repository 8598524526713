import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "../../Loader/Loader";
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
import "../../Loan/css/loan.scss";
import "../../CollectionAgency/css/agencyDetails.scss";
import { useTable, useFilters, useGlobalFilter, usePagination, useSortBy, useExpanded } from 'react-table';
import matchSorter from 'match-sorter';
import millify from 'millify';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import moment from 'moment';

//import makeData from "./../../utils/makeData";

import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
//import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
    citiesLoanCount,
    clearMessages,
    clearCitiwiseStorage,
    clearLoanStorage,
    getStatesLoanCountData,
    clearStateWiseStorage
} from '../../../store/loan';
import CityWiseLoanFinancier from "./CityWiseLoanFinancier";
import { agencyFinancierList } from "../../../store/loan";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { getAllMonth, getCurrentYearMonthDate, getYears } from "../../../utils/helperFunctions";

var d = new Date(),
    currentmonth = "" + ("0" + (d.getMonth() + 1)).slice(-2),
    // month = "05",
    day = "" + d.getDate(),
    currentyear = d.getFullYear();
let tablePageSize;
// let disableInfiniteLoad = 0;
function CityWiseLoanCountTable() {
    // if(disableInfiniteLoad > 1) {
    //     disableInfiniteLoad = 0;
    // }
    const history = useHistory();
    const dispatch = useDispatch();
    //const [active, setActive] = React.useState(false);
    const [showAddAgencyForm, setShowAddAgencyForm] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const fetchIdRef = useRef(0);
    const [statesDataPageCount, setStatesDataPageCount] = useState(0);
    const fetchIdRef2 = useRef(1000);
    const [citiesDataPageCount, setCitiesDataPageCount] = useState(0);
    const [cityWiseTableData, setCityWiseTableData] = useState([]);
    const [stateWiseTableData, setStateWiseTableData] = useState([]);
    const [citySearchTriggered, setCitySearchTriggered] = useState(false);
    const [stateSearchTriggered, setStateSearchTriggered] = useState(false);
    const [financierId, setFinancierId] = useState(0);
    // const [csvData,setCsvData] = useState([]);
    const [cityCsvData,setCityCsvData] = useState([]);
    const [stateCsvData,setStateCsvData] = useState([]);
    const [filterType, setFilterType] = useState([{ label: 'DPD', value: 'dpd' }, { label: 'POS', value: 'pos' }])
    const [selectedFilterType, setSelectedFilterType] = useState('dpd');
    const [selectedFilterTypeValue, setSelectedFilterTypeValue] = useState('all');
    const [dpdBucket, setDpdBucketList] = useState('all');
    const [pos, setPos] = useState('all');
    const [financierOptions, setFinancierOptions] = useState([]);
    const [dpdList, setDpdList] = useState([
        { label: 'BUCKET All', value: 'all' },
        { label: 'BUCKET 0', value: 0 },
        { label: 'BUCKET 1', value: 31 },
        { label: 'BUCKET 2', value: 61 },
        { label: 'BUCKET 3', value: 91 },
        { label: 'BUCKET 4', value: 121 },
        { label: 'BUCKET 5', value: 151 },
        { label: 'BUCKET 6', value: 181 }
    ]);
    const [posList, setPosList] = useState([
        { label: 'All', value: 'all' },
        { label: '0-3k', value: 0 },
        { label: '3k - 5k', value: 3 },
        { label: '5k - 10k', value: 5 },
        { label: '10k - 15k', value: 10 },
        { label: '15k - 20k', value: 15 },
        { label: '20k - 30k', value: 20 },
        { label: '30k - 40k', value: 30 },
        { label: '40k - 50k', value: 40 },
        { label: '50k - 1 lakh', value: 50 },
        { label: '1 lakh above', value: 100 }
    ]);
    const [showCsvFile, setShowCsvFile] = useState('cityWise');
    const user = useSelector(state => state.entities.auth.data);
    const [years, setYears] = useState([]);
    const [year, setYear] = useState(currentyear);
    const [month, setMonth] = useState(currentmonth);
    const [loadTable, setLoadTable] = useState(0);
    const { loading, message, offset, limit, hasMore, total } = useSelector((state) => state.entities.loan.citiesWiseLoan);
    // console.log(" loading     ", loading, offset, hasMore, total);
    const {
        loading: stateWiseLoanLoading,
        message: stateWiseLoanMessage,
        offset: stateWiseLoanOffset,
        limit: stateWiseLoanLimit,
        hasMore: stateWiseLoanHasMore,
        total: stateWiseLoanTotal,
    } = useSelector((state) => state.entities.loan.stateWiseLoan);

    let agencyFinancierListData = useSelector((state) => state.entities.loan.agenciesFinanciersList.data);

    let cityWiseData = [], cityWiseDataCopy = [], setLimit = 5, stateWiseLoanData = [];
    console.log("setlimit data =========", setLimit)
    let cityWiseDataTemp = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
    if (cityWiseDataTemp) {
        cityWiseData = cityWiseDataTemp.loan
    }

    useEffect(() => {
        console.log(" loadTable ", loadTable);
    }, [])

    let stateWiseLoanDataTemp = useSelector((state) => state.entities.loan.stateWiseLoan.data);
    if (stateWiseLoanDataTemp) {
        stateWiseLoanData = stateWiseLoanDataTemp.loan;
    }

    // console.log(" stateWiseLoanDataTemp ========== ", stateWiseLoanDataTemp.sumData);

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    var date = new Date();
    var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
    var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    let [startDate, setStartDate] = useState(new Date(firstDay));
    let [endDate, setEndDate] = useState(new Date(lastDay));

    const numFormatter = (num) => {
        let toFixe = 4;
        if (num <= 0) {
            return num.toFixed(2)
        } else if (num > 999 && num < 9999) {
            toFixe = 3
        } else if (num >= 10000) {
            toFixe = 2
        }
        return (num / 1000000).toFixed(toFixe);
    };

    let tempPos = 0;
    const columns = React.useMemo(
        () => [
            {
                Header: <div style={{ textAlign: "center" }}>Location</div>,
                accessor: 'location',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
                Footer: <div className=" tblFooterColor text-left">Total</div>,
                disableFilters: true,
            },
            {
                Header: 'Count (#)',
                accessor: 'totalCases',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        console.log(" if ======== ");
                        tempData.sumData.map((sum) => {
                            total += sum.totalCases;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? (total) : total}</div></>;
                },
            },
            {
                Header: <div><div>POS</div><div>(M)</div></div>,
                id: 'POS (M)',
                accessor: (value) => {
                    tempPos = numFormatter(value.pos ? value.pos : 0, { space: true })
                    return tempPos;
                },
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.pos;
                        })
                    }
                    return <><div className="tblFooterColor">{numFormatter(total ? total : 0, { space: true })}</div></>;
                },
            },
            {
                Header: <div><div>Dues</div><div>(M)</div></div>,
                id: 'Dues (M)',
                accessor: (value) => (numFormatter(value.dues ? value.dues : 0, { space: true })),
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.dues;
                        })
                    }
                    return <><div className="tblFooterColor">{numFormatter(total ? total : 0, { space: true })}</div></>;
                },
            },
            {
                Header: <div><div>Paid</div><div>(M)</div></div>,
                id: 'Paid (M)',
                accessor: (value) => (numFormatter(value.paidAmount ? value.paidAmount : 0, { space: true })),
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.paidAmount;
                        })
                    }
                    return <><div className="tblFooterColor">{numFormatter(total ? total : 0, { space: true })}</div></>;
                },
            },
            {
                Header: <div><div>Resolution</div><div>(%)</div></div>,
                id: 'resolution',
                accessor: (value) => {
                    return (value.resolution ? ((value.resolution / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= Resolution", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.resolution;
                        })
                    }
                    // console.log(" total ======= ", total);
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>PTP</div><div>(%)</div></div>,
                id: 'ptp',
                accessor: (value) => {
                    //   return (value.ptp ? value.ptp : 0).toFixed(2);
                    return (value.ptp ? ((value.ptp / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.ptp;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>cb</div><div>(%)</div></div>,
                id: 'cb',
                accessor: (value) => {
                    return (value.cb ? ((value.cb / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.cb;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>BPTP</div><div>(%)</div></div>,
                id: 'bptp',
                accessor: (value) => {
                    return (value.bptp ? ((value.bptp / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.bptp;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>Dispute</div><div>(%)</div></div>,
                id: 'dispute',
                accessor: (value) => {
                    return (value.dispute ? ((value.dispute / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.dispute;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>Bfu</div><div>(%)</div></div>,
                id: 'bfu',
                accessor: (value) => {
                    return (value.bfu ? ((value.bfu / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.bfu;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>Rtp</div><div>(%)</div></div>,
                id: 'rtp',
                accessor: (value) => {
                    return (value.rtp ? ((value.rtp / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.rtp;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>lm</div><div>(%)</div></div>,
                id: 'lm',
                accessor: (value) => {
                    return (value.lm ? ((value.lm / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.lm;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>Rnr</div><div>(%)</div></div>,
                id: 'rnr',
                accessor: (value) => {
                    return (value.rnr ? ((value.rnr / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.rnr;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>dl</div><div>(%)</div></div>,
                id: 'dl',
                accessor: (value) => {
                    return (value.dl ? ((value.dl / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.dl;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>nc</div><div>(%)</div></div>,
                id: 'nc',
                accessor: (value) => {
                    return (value.nc ? ((value.nc / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.nc;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>sfd</div><div>(%)</div></div>,
                id: 'sfd',
                accessor: (value) => {
                    return (value.sfd ? ((value.sfd / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.sfd;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>anf</div><div>(%)</div></div>,
                id: 'anf',
                accessor: (value) => {
                    return (value.anf ? ((value.anf / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.anf;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>dnc</div><div>(%)</div></div>,
                id: 'dnc',
                accessor: (value) => {
                    return (value.dnc ? ((value.dnc / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.dnc;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
            {
                Header: <div><div>pending</div><div>(%)</div></div>,
                id: 'pending',
                accessor: (value) => {
                    return (value.pending ? ((value.pending / value.pos) * 100).toFixed(2) : 0);
                },
                disableFilters: true,
                Footer: value => {
                    let total = 0;
                    let tempData = useSelector((state) => state.entities.loan.citiesWiseLoan.data);
                    // console.log(" tempData ======= ", tempData);
                    if (tempData && tempData.sumData) {
                        tempData.sumData.map((sum) => {
                            total += sum.pending;
                        })
                    }
                    return <><div className="tblFooterColor">{total ? parseFloat(total).toFixed(2) : 0}</div></>;
                },
            },
        ],
        []
    )

    useEffect(() => {
        // console.log(" user ========= ", user);
        if (agencyFinancierListData) {
            // console.log(" agencyFinancierListData ========== ", agencyFinancierListData);
            let financierOptionsTemp = [];
            for (let index = 0; index < agencyFinancierListData.length; index++) {
                let value = agencyFinancierListData[index];
                console.log("agencyFinancierListData-------1",value);
                if (user.role == 'Financier Manager') {
                    if (value.type === 'financier' && user.financierId == value.id) {
                        if(value.active === "Y" || value.type === "All" ){
                          financierOptionsTemp.push({ value: value.id, label: value.name },)
                        }
                    }
                }
                else {
                    if (value.type === 'financier' || value.type === 'All') {
                        if(value.active === "Y" || value.type === "All"){
                            financierOptionsTemp.push({ value: value.id, label: value.name },)
                          }
                    }
                }

            }
            console.log("roleOptionsTemp---",financierOptionsTemp);
            setFinancierOptions(financierOptionsTemp)
        }

    }, [agencyFinancierListData]);

    useEffect(() => {
        if (!agencyFinancierListData) {
            dispatch(agencyFinancierList(user.userId));
        }
    }, [])

    // useEffect(() => {
    //     var d = new Date(),
    //     month = "" + ("0" + (d.getMonth() + 1)).slice(-2),
    //     // month = "05",
    //     day = "" + d.getDate(),
    //     year = d.getFullYear();
    //     setYear(year);
    //     setMonth(month);
    // }, [])

    useEffect(() => {
        let temp = []
        for (let index = 2019; index <= 2030; index++) {
            temp[index] = { value: index, label: index }
        }
        setYears(temp);
    }, [])


    useEffect(() => {
        // if (!disableInfiniteLoad) {
        //     setCityWiseTableData(cityWiseData);
        //     // setCitiesDataPageCount(Math.ceil(total / tablePageSize));
        // }
        if (cityWiseData.length !== 0) {
            if (citySearchTriggered) {
                handleSearch();
            }
            else {
                setCityWiseTableData(cityWiseData);
                setCitiesDataPageCount(Math.ceil(total / tablePageSize));
            }
        }
        // if(disableInfiniteLoad > 1) {
        //     console.log(" disableInfiniteLoad ------ ", disableInfiniteLoad);
        //     setCityWiseTableData(cityWiseData);
        // }

        
    }, [cityWiseData]);

    const fetchCityWiseLoanData = React.useCallback(({ pageSize, pageIndex, financierIdFilter, year, month, selectedFilterType, selectedFilterTypeValue }) => {
        const fetchId = ++fetchIdRef2.current;
        console.log(" selectedFilterTypeValue ====== ", selectedFilterTypeValue);
        const endOfMonth = moment(`${year}-${month}`).endOf('month').format('YYYY-MM-DD');
        const startOfMonth = moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD');
        console.log(endOfMonth);
        if (fetchId === fetchIdRef2.current) {
            const startRow = pageSize * pageIndex;
            const endRow = startRow + pageSize;
            dispatch(citiesLoanCount(user.userId, startOfMonth, endOfMonth, selectedFilterType, selectedFilterTypeValue, startRow, pageSize, '', financierIdFilter));
        }
    }, []);

    useEffect(() => {
        console.log("stateWiseLoanData--------", stateWiseLoanData);
        // if (!disableInfiniteLoad ) {
        //     disableInfiniteLoad = 1;
        //     setStateWiseTableData(stateWiseLoanData);
        // }
        if (stateWiseLoanData.length !== 0) {
            if (stateSearchTriggered) {
                handleSearch();
            }
            else {
                setStateWiseTableData(stateWiseLoanData);
                setStatesDataPageCount(Math.ceil(stateWiseLoanTotal / tablePageSize));
            }
        }
        // if(disableInfiniteLoad > 1) {
        //     setStateWiseTableData(stateWiseLoanData);
        // }
        
        
    }, [stateWiseLoanData]);

    const fetchStateWiseLoanData = React.useCallback(({ pageSize, pageIndex, financierIdFilter, year, month, selectedFilterType, selectedFilterTypeValue }) => {
        const fetchId = ++fetchIdRef.current;
        if (fetchId === fetchIdRef.current) {
            const startRow = pageSize * pageIndex;
            const endRow = startRow + pageSize;
            const endOfMonth = moment(`${year}-${month}`).endOf('month').format('YYYY-MM-DD');
            const startOfMonth = moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD');
            dispatch(getStatesLoanCountData(user.userId, startOfMonth, endOfMonth, selectedFilterType, selectedFilterTypeValue, startRow, pageSize, '', financierIdFilter, "GET_STATES_DATA", year, month));
        }
    }, []);

        useEffect(() => {
        let tempCsvData = [];
        if (cityWiseData && cityWiseData !== null) {
          for (let index = 0; index < cityWiseData.length; index++) {
                    const tempSingle = cityWiseData[index];
                    // console.log("tempSinglemis ----- ", callerWiseMis[index]);
                    let newSingleData = {};
            newSingleData.location = tempSingle.location;
            newSingleData.financierId = tempSingle.financierId;
            newSingleData.financierName = tempSingle.financierName;
            newSingleData.totalCases = tempSingle.totalCases;
            newSingleData.pos = tempSingle.pos ? parseFloat(tempSingle.pos).toFixed(2) : 0;
            newSingleData.dues = tempSingle.dues ? parseFloat(tempSingle.dues).toFixed(2) : 0;
            newSingleData.paidAmount = tempSingle.paidAmount ? parseFloat((tempSingle.paidAmount / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.resolution = tempSingle.resolution ? parseFloat((tempSingle.resolution / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.ptp = tempSingle.ptp ? parseFloat((tempSingle.ptp / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.cb = tempSingle.cb ? parseFloat((tempSingle.cb / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.bptp = tempSingle.bptp ? parseFloat((tempSingle.bptp / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.dispute = tempSingle.dispute ? parseFloat((tempSingle.dispute / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.bfu = tempSingle.bfu ? parseFloat((tempSingle.bfu  / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.rtp = tempSingle.rtp ? parseFloat((tempSingle.rtp / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.lm= tempSingle.lm ? parseFloat((tempSingle.lm / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.dl = tempSingle.dl ? parseFloat((tempSingle.dl / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.pending = tempSingle.pending ? parseFloat((tempSingle.pending / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.rnr = tempSingle.rnr ? parseFloat((tempSingle.rnr / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.anf = tempSingle.anf ? parseFloat((tempSingle.anf / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.dnc = tempSingle.dnc ? parseFloat((tempSingle.dnc / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.nc = tempSingle.nc ? parseFloat((tempSingle.nc / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.sfd = tempSingle.sfd ? parseFloat((tempSingle.sfd / tempSingle.pos)*100).toFixed(2) : 0;
            // newSingleData.Amountcollected = tempSingle.Amountcollected ? parseFloat(tempSingle.Amountcollected).toFixed(2) : 0;
            // return (value.dnc ? ((value.dnc / value.pos) * 100).toFixed(2) : 0);
            // newSingleData.bucket = tempSingle.bucket;
            // newSingleData.description = tempSingle.description;
            // newSingleData.color = tempSingle.color;
            tempCsvData.push(newSingleData);
        }
        if(tempCsvData.length > 0){
            setCityCsvData(tempCsvData);
        }
        //   console.log(" cityWiseData --------- ", tempCsvData);
    
        }
      }, [cityWiseData])
   
      console.log("stateWiseLoanData-----",stateWiseLoanData);

    useEffect(() => {
        let tempCsvDataState = [];
        if (stateWiseLoanData !== null) {
          for (let index = 0; index < stateWiseLoanData.length; index++) {
                    const tempSingle = stateWiseLoanData[index];
                    // console.log("tempSinglemis ----- ", stateWiseLoanData[index]);
                    let newSingleData = {};
            newSingleData.location = tempSingle.location;
            newSingleData.financierId = tempSingle.financierId;
            newSingleData.financierName = tempSingle.financierName;
            newSingleData.totalCases = tempSingle.totalCases;
            newSingleData.pos = tempSingle.pos ? parseFloat(tempSingle.pos).toFixed(2) : 0;
            newSingleData.dues = tempSingle.dues ? parseFloat(tempSingle.dues).toFixed(2) : 0;
            newSingleData.paidAmount = tempSingle.paidAmount ? parseFloat((tempSingle.paidAmount / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.resolution = tempSingle.resolution ? parseFloat((tempSingle.resolution / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.ptp = tempSingle.ptp ? parseFloat((tempSingle.ptp / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.cb = tempSingle.cb ? parseFloat((tempSingle.cb / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.bptp = tempSingle.bptp ? parseFloat((tempSingle.bptp / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.dispute = tempSingle.dispute ? parseFloat((tempSingle.dispute / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.bfu = tempSingle.bfu ? parseFloat((tempSingle.bfu  / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.rtp = tempSingle.rtp ? parseFloat((tempSingle.rtp / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.lm= tempSingle.lm ? parseFloat((tempSingle.lm / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.dl = tempSingle.dl ? parseFloat((tempSingle.dl / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.pending = tempSingle.pending ? parseFloat((tempSingle.pending / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.rnr = tempSingle.rnr ? parseFloat((tempSingle.rnr / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.anf = tempSingle.anf ? parseFloat((tempSingle.anf / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.dnc = tempSingle.dnc ? parseFloat((tempSingle.dnc / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.nc = tempSingle.nc ? parseFloat((tempSingle.nc / tempSingle.pos)*100).toFixed(2) : 0;
            newSingleData.sfd = tempSingle.sfd ? parseFloat((tempSingle.sfd / tempSingle.pos)*100).toFixed(2) : 0;
            // newSingleData.Amountcollected = tempSingle.Amountcollected ? parseFloat(tempSingle.Amountcollected).toFixed(2) : 0;
            // return (value.dnc ? ((value.dnc / value.pos) * 100).toFixed(2) : 0);
            // newSingleData.bucket = tempSingle.bucket;
            // newSingleData.description = tempSingle.description;
            // newSingleData.color = tempSingle.color;
            tempCsvDataState.push(newSingleData);
          }
          console.log("tempCsvDataState-----", tempCsvDataState);
        //   setCityCsvData(tempCsvData);
        if(tempCsvDataState.length > 0){
            setStateCsvData(tempCsvDataState);
        }
        // 
        //   console.log(" stateWiseLoanData --------- ", tempCsvDataState);
    
        }
      }, [stateWiseLoanData])

    let errorMsg = "Something went wrong. Please try again.";


    const refreshLoanee = () => {
        // console.log('refresh');
        if (user) {
            dispatch(citiesLoanCount(user.userId, firstDay, lastDay, selectedFilterType, selectedFilterTypeValue, offset, setLimit));
            setStartDate(new Date(firstDay));
            setEndDate(new Date(lastDay));
            //enableLoading();
        }
    }

    const handleSearch = () => {
        console.log(" selectedFilterType ", selectedFilterType);
        // disableInfiniteLoad = 2;
        const endOfMonth = moment(`${year}-${month}`).endOf('month').format('YYYY-MM-DD');
        const startOfMonth = moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD');
        // console.log(moment().format('DD'));
        if (!stateSearchTriggered) {
            setCitySearchTriggered(true);
            setStateSearchTriggered(true);
            dispatch(
                citiesLoanCount(
                    user.userId,
                    startOfMonth,
                    endOfMonth,
                    selectedFilterType,
                    selectedFilterTypeValue,
                    0,
                    setLimit,
                    'search',
                    financierId,
                    year,
                    month
                )
            ); 
            dispatch(
                getStatesLoanCountData(
                    user.userId,
                    startOfMonth,
                    endOfMonth,
                    selectedFilterType,
                    selectedFilterTypeValue,
                    0,
                    setLimit,
                    'search',
                    financierId,
                    "GET_STATES_DATA",
                    year,
                    month
                )
            );
        }
        else {
            let formatStartDate = formatDate(startDate);
            let formatEndDate = formatDate(endDate);
            setCitySearchTriggered(false);
            setStateSearchTriggered(false);


            if (formatStartDate && formatEndDate) {
                dispatch(
                    citiesLoanCount(
                        user.userId,
                        startOfMonth,
                        endOfMonth,
                        selectedFilterType,
                        selectedFilterTypeValue,
                        0,
                        setLimit,
                        'search',
                        financierId,
                        year,
                        month
                    )
                );
            }
            if (formatStartDate && formatEndDate) {
                dispatch(
                    getStatesLoanCountData(
                        user.userId,
                        startOfMonth,
                        endOfMonth,
                        selectedFilterType,
                        selectedFilterTypeValue,
                        0,
                        setLimit,
                        'search',
                        financierId,
                        "GET_STATES_DATA",
                        year,
                        month
                    )
                );
            }
        }
    }

    const nextRecord = () => {
        console.log(" hasMore ", hasMore, loading, offset)
        if (hasMore === true && loading === false && cityWiseData.length < 100) {
            // console.log('Calling api:', (offset + setLimit));
            // dispatch(today(user.userId, user.agencyId, (offset + setLimit), setLimit));
            dispatch(citiesLoanCount(user.userId, firstDay, lastDay, selectedFilterType, selectedFilterTypeValue, (offset + setLimit), setLimit, '', financierId));
        }
    }

    const stateNextRecord = () => {
        console.log(" stateNextRecord ", stateWiseLoanHasMore, stateWiseLoanLoading, stateWiseLoanOffset);
        if (stateWiseLoanHasMore === true && stateWiseLoanLoading === false) {
            dispatch(
                getStatesLoanCountData(user.userId, firstDay, lastDay, selectedFilterType, selectedFilterTypeValue, (stateWiseLoanOffset + setLimit), setLimit, '', financierId, "GET_STATES_DATA")
            );
        }
    }


    const renderRowSubComponent = (row) => {
        console.log(" renderRowSubComponent========= ", row);
        return (
            // <div>test</div>
            <CityWiseLoanFinancier cityWiseLoanFinancierData={row.original.subRows} rowData={row.original} />
        );
    };


    return (
        <>
        {loading ? <Loader /> : " "}
            <div className="logs-card-div mt-8 cityWiseLoanCount">
                {/* mt-6 */}
                <Card className="cityWiseLoanCount-card">
                    <CardHeader>
                        <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-start flex-column">
                            <h3 className="mb-0">Geography Wise</h3>
                            {/* <span className="subHeader">Long term loan performance bucket wise</span> */}
                        </div>

                        <div className="col-lg-4 mt-4 d-block">
                            {showErrorAlert &&
                                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                    {errorAlertMsg}
                                </Alert>
                            }

                            {showSuccessAlert &&
                                <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                                    {successAlertMsg}
                                </Alert>
                            }
                        </div>
                        <div className="col-lg-3 pr-0 d-flex justify-content-end align-items-center">
                            {/* <div className="col-lg-12 d-flex justify-content-end pr-0"> */}
                            {/* <div className="">
                                <button className="defaultBtn transformBtn" onClick={refreshLoanee}>
                                    <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                                </button>
                            </div> */}
                            {/* </div> */}
                        </div>

                        {showCsvFile == 'cityWise' && (
                            <div className="col-lg-2 d-flex justify-content-end align-items-center">
                                <div className="col-lg-3 pl-0">
                                    {cityCsvData &&  (
                                        <button
                                            id="CSV"
                                            type="button"
                                            className="font-weight-bold download-csv transformBtn">
                                            <CSVLink
                                                data={cityCsvData}
                                                filename={"CityWise.csv"}
                                                target="_blank"
                                            >
                                                <span className="svg-icon svgSetting">
                                                    <SVG className="svgSetting"
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/csv-file-format-extension.svg"
                                                        )}
                                                    ></SVG>
                                                </span>
                                            </CSVLink>
                                        </button>
                                    )}
                                </div>
                                {/* <div className="col-lg-3 pl-0">
                                    <button className="defaultBtn transformBtn" onClick={refreshAgentActivities}>
                                        <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                                    </button>
                                </div> */}

                            </div>
                         )} 
                        {showCsvFile == 'stateWise' && (
                            <div className="col-lg-2 d-flex justify-content-end align-items-center">
                                <div className="col-lg-3 pl-0">
                                    {stateCsvData && (
                                        <button
                                            id="CSV"
                                            type="button"
                                            className="font-weight-bold download-csv transformBtn">
                                            <CSVLink
                                                data={stateCsvData}
                                                filename={"StateWise.csv"}
                                                target="_blank"
                                            >
                                                <span className="svg-icon svgSetting">
                                                    <SVG className="svgSetting"
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/csv-file-format-extension.svg"
                                                        )}
                                                    ></SVG>
                                                </span>
                                            </CSVLink>
                                        </button>
                                    )}
                                </div>

                            </div>
                        )}
                    </CardHeader>
                    <CardHeader>
                        <div className="col-lg-8 pl-0 pt-4 d-flex justify-content-start align-items-center">

                            {user && user.role != 'Financier Manager' && (
                                <div className="col-md-3 pl-0 d-flex justify-content-center align-items-center">
                                    <div className="form-group soposzin w-100">
                                        <label>Select financier</label>
                                        <Select
                                            defaultValue={{ label: 'All', value: 0 }}
                                            placeholder="Select..."
                                            name="role"
                                            className="jobRoleSelect w-100"
                                            options={financierOptions}
                                            onChange={(e) => setFinancierId(e.value)}
                                        // style={{ display: 'none' }}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="col-md-3 pl-0 d-flex justify-content-center align-items-center">
                                <div className="form-group soposzin w-100">
                                    <label>Select Filter Type</label>
                                    <Select
                                        defaultValue={filterType[0]}
                                        placeholder="Select..."
                                        name="role"
                                        className="jobRoleSelect w-100"
                                        options={filterType}
                                        onChange={(e) => {
                                            setSelectedFilterType(e.value);
                                            setSelectedFilterTypeValue('all');
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>

                            {selectedFilterType == 'pos' && (
                                <div className="col-md-3 pl-0 d-flex justify-content-center align-items-center">
                                    <div className="form-group soposzin w-100">
                                        <label>Select pos</label>
                                        <Select
                                            defaultValue={posList[0]}
                                            placeholder="Select..."
                                            name="role"
                                            className="jobRoleSelect w-100"
                                            options={posList}
                                            onChange={(e) => {
                                                setSelectedFilterTypeValue(e.value);
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                </div>
                            )}

                            {selectedFilterType == 'dpd' && (
                                <div className="col-md-3 pl-0 d-flex justify-content-center align-items-center" style={{ zIndex: 2 }}>
                                    <div className="form-group w-100">
                                        <label>Select Bucket</label>
                                        <Select
                                            defaultValue={dpdList[0]}
                                            placeholder="Select..."
                                            name="role"
                                            className="jobRoleSelect w-100"
                                            options={dpdList}
                                            onChange={(e) => {
                                                setSelectedFilterTypeValue(e.value);
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                </div>
                            )}

                            {year && (
                                <div className="col-lg-3 pl-0 justify-content-center align-items-center">
                                    <div className="form-group">
                                        <label>Select Year</label>
                                        <Select
                                            // defaultValue={'2022'}
                                            defaultValue={{ label: year, value: year }}
                                            placeholder="Select..."
                                            name="export_type"
                                            className="jobRoleSelect w-100"
                                            options={years}
                                            onChange={(data) => {
                                                setYear(data.value);
                                            }}
                                        />

                                    </div>
                                </div>
                            )}
                            {month && (
                                <div className="col-lg-3 pl-0 justify-content-center align-items-center">
                                    <div className="form-group">
                                        <label>Select Month</label>
                                        <Select
                                            defaultValue={getAllMonth(month)}
                                            placeholder="Select..."
                                            name="export_type"
                                            className="jobRoleSelect w-100"
                                            options={getAllMonth()}
                                            onChange={(data) => {
                                                // console.log(" test ", data.value);
                                                let currentYear = year;
                                                if (!currentYear) {
                                                    currentYear = date.getFullYear()
                                                }
                                                setMonth(data.value);

                                            }}
                                        />

                                    </div>
                                </div>
                            )}

                            <div className="col-lg-2">
                                <button
                                    id="button"
                                    type="button"
                                    className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                                    // disabled={isEmailUnique}
                                    onClick={handleSearch}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody className="p-4">
                        <Tabs
                            defaultActiveKey="cityWise"
                            transition={false}
                            onSelect={(k) => {
                                // console.log(k);
                                setShowCsvFile(k)
                            }}
                        >


                            <Tab
                                eventKey="cityWise"
                                title="City wise"
                            >
                                {cityWiseData &&  cityWiseData.length > 0 ? (
                                    <Table
                                        columns={columns}
                                        data={cityWiseTableData}
                                        renderRowSubComponent={renderRowSubComponent}
                                        // nextRecord={nextRecord}
                                        totalData={total}
                                        fetchData={fetchCityWiseLoanData}
                                        pageCount={citiesDataPageCount}
                                        isSearchTriggered={citySearchTriggered}
                                        financierIdFilter={financierId}
                                        year={year}
                                        month={month}
                                        selectedFilterType={selectedFilterType}
                                        selectedFilterTypeValue={selectedFilterTypeValue}
                                        footer={false}
                                        footerTotal={true}
                                    />
                                ) : (<h4 className="cityNoDataFound">No "City Wise" Data Found</h4>)}
                            </Tab>

                            <Tab
                                eventKey="stateWise"
                                title="State wise"
                                onSelect={(k) => {
                                    // console.log(k);
                                    setShowCsvFile(k)
                                }}
                            >
                                {stateWiseLoanData && stateWiseLoanData.length > 0 ? (
                                    <Table
                                        columns={columns}
                                        data={stateWiseTableData}
                                        renderRowSubComponent={renderRowSubComponent}
                                        // nextRecord={stateNextRecord}
                                        totalData={stateWiseLoanTotal}
                                        fetchData={fetchStateWiseLoanData}
                                        pageCount={statesDataPageCount}
                                        isSearchTriggered={stateSearchTriggered}
                                        financierIdFilter={financierId}
                                        year={year}
                                        month={month}
                                        selectedFilterType={selectedFilterType}
                                        selectedFilterTypeValue={selectedFilterTypeValue}
                                        footer={true}
                                        footerTotal={true}
                                    />
                                ) : (<h4 className="cityNoDataFound">No "State Wise" Data Found</h4>) }
                            </Tab>
                            {/* <Tab eventKey="contact" title="District wise">
                                {portfolioGeographicalDistrict_Data && (
                                    <Table columns={portfolioDistrictWiseColumns} data={portfolioGeographicalDistrict_Data} renderRowSubComponent={renderRowSubComponent} paginationShow={false} footer={true} footerTotal={true} />
                                )}
                            </Tab> */}
                        </Tabs>
                    </CardBody>
                </Card>

            </div>
            </>
    );
}

export default CityWiseLoanCountTable;




// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    //const count = preFilteredRows.length
    console.log("DefaultColumnFilter==========",DefaultColumnFilter);

    return (
        <div>
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value.toString().toLowerCase() || undefined) // Set undefined to remove the filter entirely
                }}
                
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({
    columns,
    data,
    renderRowSubComponent,
    // nextRecord, 
    totalData,
    pageCount: controlledPageCount,
    fetchData,
    isSearchTriggered,
    startDateFilter,
    endDateFilter,
    financierIdFilter,
    year,
    month,
    selectedFilterType,
    selectedFilterTypeValue,
    footer,
    footerTotal
}) {
    console.log(" Table columns ======== ", columns);
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const history = useHistory();


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        page,
        prepareRow,
        visibleColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 5},
            manualPagination: true,
            autoResetPage: false,
            pageCount: controlledPageCount,
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,//sort data
        useExpanded,
        usePagination,//use pagination

    )

    // Listen for changes in pagination and use the state to fetch our new data
    useEffect(() => {
        tablePageSize = pageSize;
        fetchData({ pageIndex, pageSize, startDateFilter, endDateFilter, financierIdFilter, year, month, selectedFilterType, selectedFilterTypeValue });
    }, [fetchData, pageIndex, pageSize]);

    useEffect(() => {
        if (isSearchTriggered) {
            gotoPage(0);
        }
    }, [isSearchTriggered]);

    page.map((row) => {
        // console.log(" row =========== ", (row.id));
        // console.log(" row =========== ", (!parseFloat(row.id)));
        if (!(parseFloat(row.id) % 1)) {
            console.log(" return ");
        }
    });
    let display = 'content';
    return (
        <>
            <div>
                <div className="tblScroll geogracity">
                    <table {...getTableProps()} className="citiesWiseTable table table-bordered">
                        <thead className="tableHead">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                                    {headerGroup.headers.map((column, index) => (
                                        <th className="citythwise" key={index}>
                                            <table style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th
                                                            className="custom-header"
                                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        >
                                                            {column.render('Header')}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? '↓'
                                                                        : '↑'
                                                                    : ''}
                                                            </span>
                                                        </th>
                                                    </tr>
                                                    {/* <tr>
                                                        <th>{column.canFilter ? column.render('Filter') : null}</th>
                                                    </tr> */}
                                                </thead>
                                            </table>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {/* ================= city wise total add here =========== */}
                        {footerTotal ? <thead className="foottableHead">
                            {footerGroups.map(group => (
                                <tr {...group.getFooterGroupProps()}>
                                    {group.headers.map(column => (
                                        <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                            :
                            null
                        }
                        {/* <thead className="foottableHeadcity">
                            <tr>
                                <td style={{textAlign:'left'}}>Total</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                        </thead> */}
                        {/* ================= city wise total add here =========== */}
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        {!(parseFloat(row.id) % 1) && (
                                            <tr style={{ display: display }} className={`${display} ${display = 'content'}`}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    );
                                                })}
                                            </tr>
                                        )}
                                        {row.isExpanded && (
                                            <tr className={`p-0 ${display = 'none'}`}>
                                                <td className="p-0" colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent(row)}
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* 
                    Pagination can be built however you'd like. 
                    This is just a very basic UI implementation:
                */}
                <div className="row m-0">
                    <div className="pagination col-lg-12">
                        <div className="col-lg-2">
                            <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {"<<"}
                            </button>
                            <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {"<"}
                            </button>
                            <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                                {">"}
                            </button>
                            <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {">>"}
                            </button>
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Total Records{' '}
                                <strong>
                                    {totalData}
                                    {/* {rows.length} */}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-lg-3">
                            <span className="gotoPageSection">
                                Go to page{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(page)
                                    }}
                                    className="gotoPage"
                                />
                            </span>{' '}
                        </div>
                        <div className="col-lg-3">
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                className="selectDisplayNoOfRecords"
                            >
                                {[5, 10, 20, 30, 40, 50, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                {/* <div>Showing the first 20 results of {rows.length} rows</div>
      <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div> */}
            </div>
        </>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

