import React, { useState, useEffect } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import matchSorter from "match-sorter";
import facebook from "../../../../../../src/images/facebook.jpg";
import instagram from "../../../../../../src/images/instagram.jpg";
import linkedin from "../../../../../../src/images/linkdin.jpg";
import twitter from "../../../../../../src/images/twitter.jpg";
import Alert from "react-bootstrap/Alert";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import styled from "styled-components";
import { klimoPos_data } from "../../../../utils/utils";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import outData from "../../../../../images/outdata.jpg";
// F:\Acenna\Acenna_Panel\src\images\outdata.jpg

function AdditionalCustomerDetails(props) {
  const contactValue = props.location.contactValue;
  const loanId = props.location.loanId;
  // console.log("loanId=====", loanId);
  // console.log("contactValue=====-----", contactValue);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.entities.auth.data);
  let history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [custData, setCustData] = useState([]);
  const [singleCustData, setSingleCustData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // console.log("custDatas---", custData);

  const [openModal, setOpenModal] = useState(false);

  const showTableData = tableData.data;

  // let tempDataModal = [];
  useEffect(() => {
    setCustData(tableData.data);
  }, [tableData.data]);

  // console.log("data-----", tableData.data);

  async function AdditionalKlimoCustomerDetails() {
    //
    const data = {
      feature: "SEARCH_CUSTOMER",
      // phone: "7666655345",
      phone: contactValue,
      fullName: "",
      panNumber: "",
      loanId: loanId,
    };
    setIsLoading(true);
    let cusDataTemp = await klimoPos_data("acenna-search", data);
    setIsLoading(false);
    setTableData(cusDataTemp);
    // console.log("data-----", cusDataTemp);
  }

  // console.log("custData1----", custData);

  function modalDetails(original) {
    // console.log("indexNumber---", original);
    let tempData = [];
    tempData.push(original);
    setSingleCustData(tempData);
    // console.log("cusDataTemp---", tempData);
    setOpenModal(true);
  }

  // function modalOpen(){
  //   setModalIsOpen(true)
  // }
  const closeKlimoModal = () => {
    setModalIsOpen(false)
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  //   function MobileNumber(props) {
  //     // console.log(" props ----- ", props);
  //     if (props.value != '0' && props.value != 'NA' && props.value != '-' && props.value != '----' && props.value != 'NULL') {
  //         return <>{props.value},</>;
  //     }
  //     else {
  //         return <></>
  //     }

  // }

  useEffect(() => {
   if (showTableData && showTableData.length <= 0 ){
    setModalIsOpen(true)
   }
  }, [showTableData]);

  useEffect(() => {
    AdditionalKlimoCustomerDetails();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Sr no",
        accessor: "#",
        Cell: ({ cell }) => {
          // console.log(" cell======= ", cell.row.index);
          return (
            <>
              <div className="loanIdDownloadBtnDiv1">
                <span>{cell.row.index + 1}</span>
              </div>
            </>
          );
        },
        filter: "fuzzyText",
        disableFilters: true,
      },
      {
        Header: "Name",
        accessor: "fullName",
        Cell: ({ cell }) => {
          // console.log(" cell ", cell.row.original.userId);
          return (
            <>
              <div className="loanIdDownloadBtnDiv2">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell.row.original.fullName}
                  onClick={() => modalDetails(cell.row.original)}
                >
                  {" "}
                  {cell.row.original.fullName}
                </button>
              </div>
            </>
          );
        },
        filter: "fuzzyText",
        disableFilters: true,
      },
      {
        Header: "Contact Details ",
        accessor: "phone",
        Cell: ({ cell }) => {
          const phone1 = cell.row.original.phone;
          const phone2 = phone1.filter(number => number !== "0" && number !== "_"&& number !== "-"&& number !== "_____________"&& number !== "NULL"&& number !== "NA").join(", ");
          console.log("cell.row.original.phone", phone2);
          return (
            <>
              <span>{phone2}</span>
            </>
          );
        },
        // Cell: ({cell}) => (
        //   // console.log("")
        //     (cell.row.original.phone != '0' && cell.row.original.phone != 'NA' && cell.row.original.phone != '-' && cell.row.original.phone != '----' && cell.row.original.phone != 'NULL' ? cell.row.original.phone.join(", ") : " ")
        // ),
      },

      {
        Header: "Address",
        accessor: "address[0].address",
      },

      {
        Header: "Email ",
        accessor: "email[0].email",
      },
    ],
    []
  );

  return (
    <div className="row m-0 loanDataImport">
      {isLoading ? (
        <Loader />
      ) : ( " "
      )}
      <div className="col-12 pl-0 pr-0 customCardDiv">
        {showTableData && showTableData.length >= 0 ?
        <Card className="uploadedDataCard">
          <CardBody className="customCardBody">
          <div>
            <button
              className="backbtn"
              onClick={() => {
                history.push({
                  pathname: "/today/details",
                  loanId: loanId,
                  // loanId: loanId,
                  cameFrom: "klimo",
                });
              }}
            >
              <span>&larr;</span>Back
            </button>
            <h4 className='resultfound'>We have found {custData ? custData.length : " " } Matches</h4>
            </div>
            {showTableData && <Table columns={columns} data={showTableData} />}
            {/* {data.length>0 ? To dikhaye : " "  */}
          </CardBody>
        </Card>
          : null }
      </div>
      <div className="Klim-Modal">
        <Modal
          show={openModal}
          onHide={closeModal}
          className="modalstylechange"
          contentLabel="Example Modal"
          // ariaHideApp={false}
        >
          {singleCustData &&
            singleCustData.map((cus, index) => (
              <div key={index} className="container designlayout klimo-modal">
                {/* <div className="container designlayout "> */}
                {/* ================= Name Row =========== */}
                <div className="row">
                  <div className="col-md-2">
                    <h4 className="datanamfo">
                      <b>Name</b>
                    </h4>
                  </div>
                  <div className="col-md-1">
                    <h4 className="TimeDote">:</h4>
                  </div>
                  <div className="col-md-9">
                    <h4 className="datanamfo">{cus.fullName}</h4>
                  </div>
                </div>

                {/* ================= Name Row =========== */}

                {/* ================= Telephone Row =========== */}
                {cus.phone && cus.phone.length > 0 && (
                  <div className="row">
                    <div className="col-md-2">
                      <h4 className="datanamfo">
                        <b>Telephone</b>
                      </h4>
                    </div>
                    <div className="col-md-1">
                      <h4 className="TimeDote">:</h4>
                    </div>
                    <div className="col-md-9">
                      <h4 className="datanamfo">
                        {/* {cus.phone.join()} */}
                       { cus.phone.filter(number => number !== "0" && number !== "_"&& number !== "-"&& number !== "_____________"&& number !== "NULL"&& number !== "NA").join(", ")}
                      </h4>
                    </div>
                  </div>
                )}

                {/* ================= Telephone Row =========== */}

                {/* ================= Address Row =========== */}
                {cus.address.map((cus, ak) => {
                  return (
                    <div key={ak} className="row">
                      <div className="col-md-2">
                        <h4 className="datanamfo">
                          <b>Address {ak + 1}</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-9">
                        {/* {cus.address && cus.address[0] && (
                                        <td>
                                            {cus.address[0].address}
                                        </td>
                                    )} */}
                        <h4 className="datanamfo">{cus.address}</h4>
                      </div>
                    </div>
                  );
                })}
                {/* ================= Address Row =========== */}

                {/* ================= Work Address Row =========== */}
                <div className="row">
                  <div className="col-md-2">
                    <h4 className="datanamfo">
                      <b>Work Address</b>
                    </h4>
                  </div>
                  <div className="col-md-1">
                    <h4 className="TimeDote">:</h4>
                  </div>
                  <div className="col-md-9">
                    <h4 className="datanamfo">
                      {cus.workAddress ? cus.workAddress : ""}
                    </h4>
                  </div>
                </div>

                {/* ================= Work Address Row =========== */}

                {/* ================= Work Email Row =========== */}
                <div className="row">
                  <div className="col-md-2">
                    <h4 className="datanamfo">
                      <b>Work Email</b>
                    </h4>
                  </div>
                  <div className="col-md-1">
                    <h4 className="TimeDote">:</h4>
                  </div>
                  <div className="col-md-9">
                    <h4 className="datanamfo"></h4>
                    <h4 className="datanamfo">
                      {cus.workEmail ? cus.workEmail : ""}
                    </h4>
                  </div>
                </div>

                {/* ================= Work Email Row =========== */}

                {/* =============================================== */}

                <hr />

                {/* =============================================== */}

                {/* ================== Family Details ============ */}

                <h4 className="resultfound">Family Details</h4>
                {/* ================= Father Details =================== */}
                <div className="row">
                  {/* ======== Left row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Father Name</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"> {cus.fatherName} </h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== Left row ========= */}

                  {/* ======== right row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Contact Number</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo">{cus.fatherName}</h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== right row ========= */}
                </div>

                {/* ================= Father Details =================== */}

                {/* ================= Mother Details =================== */}
                <div className="row">
                  {/* ======== Left row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Mother Name</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo">{cus.motherName}</h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== Left row ========= */}

                  {/* ======== right row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Contact Number</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo">{cus.motherName}</h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== right row ========= */}
                </div>

                {/* ================= Mother Details =================== */}

                {/* ================== Family Details ============ */}

                {/* ==================================== */}

                <hr />

                {/* ========================== */}

                {/* ================== Other Family Details ============ */}

                <h4 className="resultfound">Other Family Details</h4>
                {/* ================= Wife Details =================== */}
                <div className="row">
                  {/* ======== Left row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>spouse Name</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== Left row ========= */}

                  {/* ======== right row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Contact Number</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== right row ========= */}
                </div>

                {/* ================= Wife Details =================== */}

                {/* ================= Son Details =================== */}
                <div className="row">
                  {/* ======== Left row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Son Name</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== Left row ========= */}

                  {/* ======== right row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Contact Number</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== right row ========= */}
                </div>

                {/* ================= Son Details =================== */}

                {/* ================= Daughter Details =================== */}
                <div className="row">
                  {/* ======== Left row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Daughter Name</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== Left row ========= */}

                  {/* ======== right row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Contact Number</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== right row ========= */}
                </div>

                {/* ================= Daughter Details =================== */}

                {/* ================= Brother Details =================== */}
                <div className="row">
                  {/* ======== Left row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Brother Name</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== Left row ========= */}

                  {/* ======== right row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Contact Number</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== right row ========= */}
                </div>

                {/* ================= Brother Details =================== */}

                {/* ================= Sister Details =================== */}
                <div className="row">
                  {/* ======== Left row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Sister Name</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== Left row ========= */}

                  {/* ======== right row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Contact Number</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== right row ========= */}
                </div>

                {/* ================= Sister Details =================== */}

                {/* ================= Uncle Details =================== */}
                <div className="row">
                  {/* ======== Left row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Uncle Name</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== Left row ========= */}

                  {/* ======== right row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Contact Number</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== right row ========= */}
                </div>

                {/* ================= Uncle Details =================== */}

                {/* ================= Other Details =================== */}
                <div className="row">
                  {/* ======== Left row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Other Name</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== Left row ========= */}

                  {/* ======== right row ========= */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="datanamfo">
                          <b>Contact Number</b>
                        </h4>
                      </div>
                      <div className="col-md-1">
                        <h4 className="TimeDote">:</h4>
                      </div>
                      <div className="col-md-7">
                        <h4 className="datanamfo"></h4>
                      </div>
                    </div>
                  </div>

                  {/* ======== right row ========= */}
                </div>

                {/* ================= Other Details =================== */}

                {/* ================== Other Family Details ============ */}

                {/* ================================= */}
                <hr />
                {/* ============================ */}

                {/* ========================== Social Links =============== */}
                <h4 className="resultfound">Social Links</h4>
                <div className="row">
                  <div className="iconlist">
                    <ul>
                      <li>
                        <a href="javascript:void(0)">
                          <img className="iconlogo" src={facebook} alt="face" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <img className="iconlogo" src={linkedin} alt="face" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <img className="iconlogo" src={twitter} alt="face" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <img
                            className="iconlogo"
                            src={instagram}
                            alt="face"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* ========================== Social Links =============== */}

                {/* =================================== */}
                <hr />
                {/* =================================== */}

                {/* ==================== Amount Details ================ */}
                <h4 className="resultfound">ACE RAW</h4>

                <div className="row">
                  <div className="col-md-4">
                    <h4 className="datanamfo">
                      <b>Total Outstanding</b>
                    </h4>
                  </div>
                  <div className="col-md-1">
                    <h4 className="TimeDote">:</h4>
                  </div>
                  <div className="col-md-7">
                    <h4 className="datanamfo">
                      <b>Rs.</b>
                    </h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <h4 className="datanamfo">
                      <b>Max DPD</b>
                    </h4>
                  </div>
                  <div className="col-md-1">
                    <h4 className="TimeDote">:</h4>
                  </div>
                  <div className="col-md-7">
                    <h4 className="datanamfo"></h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <h4 className="datanamfo">
                      <b>Financier From</b>
                    </h4>
                  </div>
                  <div className="col-md-1">
                    <h4 className="TimeDote">:</h4>
                  </div>
                  <div className="col-md-7">
                    <h4 className="datanamfo"></h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <h4 className="datanamfo">
                      <b>Last Due+ DPD</b>
                    </h4>
                  </div>
                  <div className="col-md-1">
                    <h4 className="TimeDote">:</h4>
                  </div>
                  <div className="col-md-7">
                    <h4 className="datanamfo"></h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <h4 className="datanamfo">
                      <b>Amount</b>
                    </h4>
                  </div>
                  <div className="col-md-1">
                    <h4 className="TimeDote">:</h4>
                  </div>
                  <div className="col-md-7">
                    <h4 className="datanamfo">
                      <b>Rs. </b>
                    </h4>
                  </div>
                </div>
                {/* ==================== Amount Details ================ */}
              </div>
            ))}

          <button className="btnmodalclosemng" onClick={closeModal}>
            <span>&#10006;</span>
          </button>
        </Modal>

      </div>
      <Modal
          show={modalIsOpen}
          onHide={closeKlimoModal}
          className="modalstylechange_klimo"
          contentLabel="Example Modal"
          ariaHideApp={false}>
          <div className="mainmodaldata">
              <div className="noDataFound">
                  <img src={outData} alt="outdata" />
                  {/* <p>No Data Found</p> */}
                  <button className='btnclosebtnh' onClick={closeKlimoModal}><span>&#10006;</span></button>
              </div>
          </div>
      </Modal> 
    </div>
  );
}

export default AdditionalCustomerDetails;

// Define a default UI for filtering
// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
// }) {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <span>
//       Search:{" "}
//       <input
//         value={value || ""}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={`${count} records...`}
//         className="searchFilter"
//       />
//     </span>
//   );
// }

// Define a default UI for filtering
// function DefaultColumnFilter({
//   column: { filterValue, preFilteredRows, setFilter },
// }) {
//   const count = preFilteredRows.length;

//   return (
//     <div>
//       <input
//         value={filterValue || ""}
//         onChange={(e) => {
//           setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
//         }}
//         // placeholder={`Search ${count} records...`}
//         className="searchFilter"
//       />
//       <i className="flaticon-search searchIcon"></i>
//     </div>
//   );
// }

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // const defaultColumn = React.useMemo(
  //   () => ({
  //     // Let's set up our default Filter UI
  //     Filter: DefaultColumnFilter,
  //   }),
  //   []
  // );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows.slice(0, 10);

  return (
    <>
      {/* {longRunningProcessData && longRunningProcessData.agentData && longRunningProcessData.agentData.length > 0 && ( */}
      <div>
        {/* <button className='backbtn' onClick={() => histroy(-1)}><span>&larr;</span>Back</button>s */}

        <div className="tblScroll">
       
          <table {...getTableProps()} className="loanTable kilmoTableDetails">
            <thead
              className="tableHead stickyTop table-fixed tableFixed"
              style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="tableHeader"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "↓"
                            : "↑"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
              {/* <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                </th>
              </tr> */}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="tableTd">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {/* <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot> */}
          </table>
        </div>
        {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
        <div className="row m-0">
          <div className="pagination col-12">
            <div className="col-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-2">
              <span>
                Total Records <strong>{data.length}</strong>{" "}
              </span>
            </div>
            <div className="col-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="searchFilter"
                />
              </span>{" "}
            </div>
            <div className="col-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <br />
        {/* <div>Showing the first 20 results of {rows.length} rows</div>
        <div>
          <pre>
            <code>{JSON.stringify(state.filters, null, 2)}</code>
          </pre>
        </div> */}
      </div>
      {/* )} */}
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
