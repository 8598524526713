import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import Alert from 'react-bootstrap/Alert';
import LoadingOverlay from 'react-loading-overlay';
import Select, { components } from 'react-select';
import DatePicker from 'react-date-picker';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import "./css/longprocessModal.scss";
import { addNotification } from '../../store/pushNotification';

function LongProcessFailed(props) {
    // console.log(" props SendNotificationModal ", props.userIds);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState();
    const [showSuccessAlert, setShowSuccessAlert] = useState(true);
    //const [pageLoading, setLoading] = useState(false);
    const [isEmailUnique, setIsEmailUnique] = useState(false);
    const user = useSelector(state => state.entities.auth.data);
    const dispatch = useDispatch();
    console.log('Financier data:', user.agencyId);

    const { status, error, loading, message } = useSelector((state) => state.entities.pushNotification.addNotification);
    console.log("got error... status", status, error, loading, message);

    useEffect(() => {
        if (status === "success" && !loading && message !== null) {
            //disableLoading();
            setShowSuccessAlert(true);
            setSuccessAlertMsg(message);
            setTimeout(() => {
                setShowSuccessAlert(false);
                setSuccessAlertMsg(null);
                props.hideModal();
                if (props && props.setShowAddFinancierForm) {
                    props.setShowAddFinancierForm(false);
                }
            }, 3000)
        }
    }, [status, loading, message]);

    
    const sendNotificationSchema = Yup.object().shape({
        notificationTitle: Yup.string()
            .min(3, "Minimum 3 characters")
            // .max(25, "Maximum 25 characters")
            .required('This is required'),
        notificationBody: Yup.string()
            .min(3, "Minimum 3 characters")
            // .max(25, "Maximum 25 characters")
            .required('This is required'),
        

    });

    const closeModal = () => {
        props.hideModal();
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    const Option = props => {
        console.log('select props:', props)
        return (
            <components.Option {...props}>
                <div>{props.data.label}</div>
                <div style={{ fontSize: 12, color: '#b5b5c3' }}>{props.data.description}</div>
            </components.Option>
        );
    };

    return (
        <div className="addFinancierUserModal">
            <Modal 
                show={props.showModal}
                onHide={props.hideModal}
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                className="long-process-modal"
                style={{left: '10%'}}
            >
                <Modal.Header >
                    <div className="col-lg-12 header-div">
                        <div className="col-lg-6 text-left">
                            <h2 className="mb-0">Failed Reason</h2>
                        </div>

                        <div className="col-lg-6 text-right pr-0">
                            <i onClick={closeModal}
                                className="flaticon2-cross cancel_Icon">
                            </i>
                        </div>
                    </div>
                    
                </Modal.Header>
                <Modal.Body>
                    <div className="editDispositionDiv financierCreateFormDiv">

                        <LoadingOverlay
                            active={loading}
                            spinner
                            text={props && props.editFinancierUserData ? 'Updating Financier User...' : 'Creating Financier User...'}
                            // classNamePrefix='pg-list-loader-'
                            styles={{
                                // overlay: (base) => ({
                                //   ...base,
                                //   background: 'rgba(255, 0, 0, 0.5)'
                                // }),
                                wrapper: {
                                    overflow: loading ? 'hidden' : 'hidden'
                                },
                                spinner: (base) => ({
                                    ...base,
                                    width: '75px',
                                    '& svg circle': {
                                        stroke: '#00b1cc'
                                    }
                                })
                            }}
                        >
                            <Formik
                                initialValues={{ notificationTitle: "", notificationBody: "" }}
                                // initialValues={initialValues}
                                validationSchema={sendNotificationSchema}
                                onSubmit={(values) => {
                                // onSubmit={async values => {
                                    console.log(" values ", values);
                                    console.log(" props.userIdss ", props.userIds);
                                    console.log(" props.user ", user.userId);
                                    dispatch(addNotification(
                                        user.userId,
                                        values.notificationTitle,
                                        values.notificationBody,
                                        props.userIds
                                    ))
                                    // await new Promise(resolve => setTimeout(resolve, 500));
                                    // alert(JSON.stringify(values, null, 2));
                                }}
                            >
                                {formikProps => (
                                <Form onSubmit={formikProps.handleSubmit} id="addAgencyUser" className="form fv-plugins-bootstrap fv-plugins-framework addAgencyUser">
                                    <div className="form-group fv-plugins-icon-container row">
                                        <div className="col-lg-12 pl-0">

                                            <div className="displayContent mt-6">
                                                
                                            </div>
                                        </div>

                                        <div className="col-lg-12 d-flex justify-content-center align-items-center mt-20 pb-12">
                                            {/* <button
                                                id="cancel"
                                                type="button"
                                                className="btn btn-default font-weight-bold upload mr-4 transformBtn"
                                                // onClick={addAgencyUserOpen}
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                id="submit"
                                                type="submit"
                                                className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
                                                // onClick={addAgencyUserOpen}
                                                // disabled={isEmailUnique}
                                            >
                                                Submit
                                            </button> */}
                                            <p> {props.failedReason} </p>


                                        </div>
                                    </div>
                                </Form>
                                )}

                                                
                            </Formik>
                            
                            
                        </LoadingOverlay>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    
                </Modal.Footer> */}
            </Modal>
        </div>
    );
}

export default LongProcessFailed;