import React from 'react';
import comingSoon from "../../files/Coming_soon.png"; 
function FieldData() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <img
        src={comingSoon}
        alt="Coming Soon"
        style={{ width: "300px", maxWidth: "100%" }}
      />
    </div>
  );
}

export default FieldData;

