import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AreaChart from './pages/AreaChart_old';
import ColumnChart from './pages/ColumnChart';
import LineChart from './pages/LineChart';
import MixChart from './pages/MixChart';
import CustomMap from './pages/CustomMap';
import AgentActivities from './pages/AgentActivities';
import CityWiseLoanCountTable from './pages/CityWiseLoanCountTable';

function Charts() {
    const user = useSelector(state => state.entities.auth.data);

    return (
        <div className="row">
            <div className="col-md-12 m-3">
                <h4 className="text-center">‘Currently you have not added any data for usage under this screen’</h4>
            </div>
            <div className="col-lg-6">
                <MixChart />
            </div>
            <div className="col-lg-6">
                <AreaChart />
            </div>
            <div className="col-lg-6">
                <ColumnChart />
            </div>
            <div className="col-lg-6">
                <LineChart />
            </div>
            {/* {user.role == 'Central Team' && (
                <div className="col-lg-12">
                    <AgentActivities />
                </div>
            )} */}
            {/* <div className="col-lg-12">
                <CustomMap />
            </div> */}
            {/* <div className="col-lg-12">
                <CityWiseLoanCountTable />
            </div> */}
        </div>
    )
}

export default Charts
