export const prod = {
    baseURL: 'https://mjgp3zsss0.execute-api.ap-south-1.amazonaws.com/Production',
    X_Api_Key: 'TpupnebKGCas9jOiUxH1B97sPcb4glmPaUM6JOHX'
};

export const dev = {
    //baseURL: 'https://gsg8hi850j.execute-api.ap-south-1.amazonaws.com/Dev',
    //X_Api_Key: 'xvF3vMk6m88pWAAuyoiCp6diSYG1gMSkFZ2pxOVa'
    baseURL: 'https://dev-cms-api.daqi.in/'
};
export const qa = {
    baseURL: 'https://gsg8hi850j.execute-api.ap-south-1.amazonaws.com/Dev',
    X_Api_Key: '2V7I5AVIbZ4ZL8jTiN1HG9EpyQuafteDaFa6gP1g'
}

export const google_api_key = {
    api_key: 'AIzaSyBBaZJgVSNJbU3-rrMvKZpjOn2yv7zSa2M'
}

export const techdialer = {
    hostname:'https://dialer.acenna.in/api/cti'
}

export const UNASSIGNED_STATE_CODE = 'UN';

export const UNKNOWN_DISTRICT_KEY = 'Unknown';

export const STATE_NAMES = {
    AP: 'Andhra Pradesh',
    AR: 'Arunachal Pradesh',
    AS: 'Assam',
    BR: 'Bihar',
    CT: 'Chhattisgarh',
    GA: 'Goa',
    GJ: 'Gujarat',
    HR: 'Haryana',
    HP: 'Himachal Pradesh',
    JH: 'Jharkhand',
    KA: 'Karnataka',
    KL: 'Kerala',
    MP: 'Madhya Pradesh',
    MH: 'Maharashtra',
    MN: 'Manipur',
    ML: 'Meghalaya',
    MZ: 'Mizoram',
    NL: 'Nagaland',
    OR: 'Odisha',
    PB: 'Punjab',
    RJ: 'Rajasthan',
    SK: 'Sikkim',
    TN: 'Tamil Nadu',
    TG: 'Telangana',
    TR: 'Tripura',
    UT: 'Uttarakhand',
    UP: 'Uttar Pradesh',
    WB: 'West Bengal',
    AN: 'Andaman and Nicobar Islands',
    CH: 'Chandigarh',
    DN: 'Dadra and Nagar Haveli and Daman and Diu',
    DL: 'Delhi',
    JK: 'Jammu and Kashmir',
    LA: 'Ladakh',
    LD: 'Lakshadweep',
    PY: 'Puducherry',
    TT: 'India',
    [UNASSIGNED_STATE_CODE]: 'Unassigned',
};
  
const stateCodes = [];
const stateCodesMap = {};
Object.keys(STATE_NAMES).map((key, index) => {
    stateCodesMap[STATE_NAMES[key]] = key;
    stateCodes.push({code: key, name: STATE_NAMES[key]});
    return null;
});
export const STATE_CODES = stateCodesMap;
export const STATE_CODES_ARRAY = stateCodes;

export const MAP_STATS_TABLE_CONFIG = {
    cell2: {
        keyName: 'principalOutStanding',
        displayName: 'Principal OutStanding Amount (Cr.)'
    },
    cell3: {
        displayName: 'Header 3'
    },
    cell4: {
        displayName: 'Header 4'
    },
    cell5: {
        displayName: 'Header 5'
    },
    cell6: {
        displayName: 'Header 6'
    },
    cell7: {
        displayName: 'Header 7'
    },
    cell8: {
        displayName: 'Header 8'
    },
    cell9: {
        displayName: 'Header 9'
    },
    cell10: {
        displayName: 'Header 10'
    },
    cell11: {
        displayName: 'Header 11'
    },
    cell12: {
        displayName: 'Header 12'
    },
    cell13: {
        displayName: 'Header 13'
    },
    cell14: {
        displayName: 'Header 14'
    }    
};

export const INDIA_MAP_BORDER = [
    {
        "type": "MultiLineString",
        "coordinates": [
            [
                [
                    368.2100605024375,
                    243.4989926765191
                ],
                [
                    368.165094457976,
                    244.7482716429098
                ],
                [
                    368.8845511693612,
                    246.741254343901
                ],
                [
                    368.72717001374576,
                    248.51370578623175
                ],
                [
                    369.5590418362849,
                    249.25727282871833
                ],
                [
                    369.71642299190034,
                    251.5956918893612
                ],
                [
                    369.1543474361306,
                    253.1459951051233
                ],
                [
                    367.535569835514,
                    252.81855613663242
                ],
                [
                    366.77114707966734,
                    255.15323861573285
                ],
                [
                    365.89430921266626,
                    255.74197982002136
                ],
                [
                    364.65774298997303,
                    255.00057679457007
                ],
                [
                    362.45440681135574,
                    253.60432896332463
                ],
                [
                    362.20709356681687,
                    255.37130891461868
                ],
                [
                    360.9030782774312,
                    255.82918761626541
                ],
                [
                    360.9930103663546,
                    254.04074999855038
                ],
                [
                    360.633282010662,
                    250.8966101966913
                ],
                [
                    359.576579965815,
                    244.22234675038993
                ],
                [
                    358.67725907658337,
                    242.0734999593161
                ],
                [
                    357.55310796504386,
                    241.10801876359346
                ],
                [
                    357.8453872540442,
                    239.17576064138086
                ],
                [
                    357.1708965871205,
                    236.29610911790297
                ],
                [
                    358.00276840965967,
                    235.83412491994062
                ],
                [
                    357.4406928538899,
                    234.93186712868714
                ],
                [
                    356.09171152004274,
                    229.7970982469957
                ],
                [
                    355.9792964088888,
                    228.45073327985256
                ],
                [
                    355.86688129773506,
                    227.0593156758572
                ],
                [
                    356.8561342758894,
                    225.44587649969725
                ],
                [
                    356.67627009804323,
                    223.2115153828487
                ],
                [
                    356.9460663648126,
                    221.10768206720206
                ],
                [
                    356.42895685350436,
                    220.11037835966232
                ],
                [
                    358.2950476986598,
                    220.02170568994188
                ],
                [
                    358.49739489873696,
                    221.572924919763
                ],
                [
                    359.9138252992768,
                    221.196307865403
                ],
                [
                    360.1836215660462,
                    220.06604250396128
                ],
                [
                    361.0829424552776,
                    219.93302918637352
                ],
                [
                    361.28528965535475,
                    218.71333787437902
                ],
                [
                    362.2295765890476,
                    218.358382012636
                ],
                [
                    363.2862786338949,
                    215.7831025955789
                ],
                [
                    363.84835418966463,
                    217.29314401206744
                ],
                [
                    365.2647845902043,
                    217.75925407821552
                ],
                [
                    366.92852823528256,
                    217.40413226614857
                ],
                [
                    367.1308754353597,
                    220.28771220215162
                ],
                [
                    366.43390174620527,
                    222.19308523570663
                ],
                [
                    369.8288381030543,
                    223.30005079705575
                ],
                [
                    370.503328769978,
                    223.7205422561999
                ],
                [
                    372.6167328596723,
                    225.24687267504459
                ],
                [
                    373.0663933042879,
                    229.6867729644013
                ],
                [
                    373.0214272598264,
                    231.64969837594663
                ],
                [
                    372.5268007707491,
                    232.13463422627513
                ],
                [
                    372.70666494859506,
                    233.8750788802319
                ],
                [
                    372.05465730390233,
                    234.7997973266829
                ],
                [
                    372.54928379297985,
                    236.56005520728655
                ],
                [
                    372.00969125944084,
                    238.91213654592013
                ],
                [
                    371.20030245913244,
                    240.0103572813149
                ],
                [
                    369.67145694743886,
                    239.1977278572828
                ],
                [
                    368.5922718803613,
                    239.9883983544513
                ],
                [
                    368.9295172138229,
                    241.23970066169045
                ],
                [
                    368.2100605024375,
                    243.4989926765191
                ]
            ],
            [
                [
                    175.77787322911945,
                    406.15438543327946
                ],
                [
                    173.95674842842573,
                    409.00771993206286
                ],
                [
                    173.55205402827164,
                    409.23507232161984
                ],
                [
                    173.55205402827164,
                    409.87572383634495
                ],
                [
                    173.30474078373277,
                    410.6402397904577
                ],
                [
                    172.49535198342437,
                    410.5782573728726
                ],
                [
                    171.03395553842324,
                    411.77641703789357
                ],
                [
                    172.83259731688622,
                    412.31340909035265
                ],
                [
                    172.81011429465548,
                    412.4786232698357
                ],
                [
                    172.65273313904004,
                    413.201357266982
                ],
                [
                    172.135623627732,
                    415.6370734271328
                ],
                [
                    172.2255557166552,
                    417.1638315817245
                ],
                [
                    172.90004638357868,
                    418.62817471833455
                ],
                [
                    173.55205402827164,
                    422.3383458886653
                ],
                [
                    173.46212193934844,
                    425.20125313055644
                ],
                [
                    171.9557594498856,
                    425.0365320698666
                ],
                [
                    171.596031094193,
                    426.04535237589914
                ],
                [
                    172.9899784725019,
                    426.78638046610513
                ],
                [
                    173.4396389171177,
                    427.5684433389141
                ],
                [
                    173.77688425057931,
                    434.5598616263122
                ],
                [
                    173.59702007273313,
                    435.7103767191842
                ],
                [
                    172.24803873888573,
                    435.87471307424585
                ],
                [
                    167.88633242611263,
                    435.217333289769
                ],
                [
                    166.64976620341963,
                    435.19678869318244
                ],
                [
                    164.87360744718717,
                    436.51146251329055
                ],
                [
                    164.2890488691869,
                    437.8873714474537
                ],
                [
                    164.64877722487927,
                    439.18077577398367
                ],
                [
                    162.78268637972383,
                    441.68448116150796
                ],
                [
                    162.7377203352621,
                    441.72551481495145
                ],
                [
                    162.71523731303137,
                    442.1358325029054
                ],
                [
                    162.6702712685701,
                    442.17686238925717
                ],
                [
                    160.66928229002974,
                    444.57651911308255
                ],
                [
                    159.9273425564138,
                    445.97066173957364
                ],
                [
                    159.477682111798,
                    447.8562434320329
                ],
                [
                    160.71424833449146,
                    449.49531513947204
                ],
                [
                    161.90584851272297,
                    450.3965831739276
                ],
                [
                    163.27731286880135,
                    450.6013950548511
                ],
                [
                    165.8853434475725,
                    450.2122456017893
                ],
                [
                    165.41319998072595,
                    451.19530412193694
                ],
                [
                    163.95180353572482,
                    450.7037979817993
                ],
                [
                    162.2655768684158,
                    450.97003620326745
                ],
                [
                    160.6917653122605,
                    450.7652387745385
                ],
                [
                    159.07298771164392,
                    451.01099472722245
                ],
                [
                    154.75624744333254,
                    452.89474212447465
                ],
                [
                    152.4854621980228,
                    453.0994568390155
                ],
                [
                    151.9009036200223,
                    453.44745376980063
                ],
                [
                    149.6076353524818,
                    455.2280248903346
                ],
                [
                    148.77576352994265,
                    456.5784110524018
                ],
                [
                    148.61838237432698,
                    457.74438456554185
                ],
                [
                    149.1130088634045,
                    458.5420134706204
                ],
                [
                    148.28113704086536,
                    459.6053400813795
                ],
                [
                    148.3485861075576,
                    462.2627743655155
                ],
                [
                    147.08953686263362,
                    464.08137880401324
                ],
                [
                    145.80800459547868,
                    464.7351188130317
                ],
                [
                    143.9194307280925,
                    465.981107348469
                ],
                [
                    143.22245703893782,
                    467.28808980312874
                ],
                [
                    140.20973206001236,
                    467.6352084979439
                ],
                [
                    139.71510557093484,
                    468.57436948598524
                ],
                [
                    138.09632797031827,
                    468.3089693854432
                ],
                [
                    134.9711878802384,
                    467.0226323939488
                ],
                [
                    132.99268192392924,
                    465.36835931896246
                ],
                [
                    135.3983653026237,
                    462.18102576606793
                ],
                [
                    135.5107804137774,
                    461.1181868946985
                ],
                [
                    134.04938396877628,
                    458.685165450309
                ],
                [
                    134.47656139116134,
                    458.2556984029617
                ],
                [
                    135.30843321370048,
                    456.6397842739358
                ],
                [
                    134.38662930223813,
                    455.9441806029515
                ],
                [
                    133.73462165754518,
                    454.69596204523714
                ],
                [
                    134.54401045785357,
                    453.7954279807409
                ],
                [
                    135.48829739154667,
                    452.5671821544626
                ],
                [
                    135.69064459162382,
                    450.3965831739276
                ],
                [
                    136.5449994363937,
                    448.59389026813096
                ],
                [
                    137.46680334785606,
                    447.01601616677704
                ],
                [
                    136.23023712516283,
                    445.8681643967666
                ],
                [
                    135.5107804137774,
                    446.31913716483075
                ],
                [
                    134.0044179243148,
                    445.72466462143973
                ],
                [
                    135.1735350803158,
                    442.93585372885093
                ],
                [
                    134.67890859123827,
                    441.7665481254312
                ],
                [
                    135.4208483248542,
                    439.5092041166811
                ],
                [
                    135.48829739154667,
                    438.1132290534713
                ],
                [
                    135.78057668054703,
                    436.4703844873533
                ],
                [
                    134.20676512439172,
                    434.60095630486563
                ],
                [
                    132.8802668127753,
                    435.2378777966299
                ],
                [
                    132.2957082347748,
                    436.01850269529353
                ],
                [
                    131.28397223438924,
                    436.6552328033882
                ],
                [
                    130.06988903392653,
                    436.16228794387985
                ],
                [
                    130.04740601169578,
                    436.1417474616197
                ],
                [
                    129.9574739227728,
                    436.0595846408702
                ],
                [
                    129.1031190780027,
                    434.86806293483295
                ],
                [
                    128.8333228112333,
                    433.61458431250026
                ],
                [
                    129.17056814469515,
                    431.0243598654458
                ],
                [
                    130.18230414508048,
                    428.2474925455808
                ],
                [
                    129.05815303354143,
                    426.9304549575541
                ],
                [
                    126.49508849923131,
                    425.9012492447812
                ],
                [
                    127.93400192200193,
                    423.80092781975355
                ],
                [
                    127.05716405500107,
                    422.4825659118913
                ],
                [
                    127.23702823284748,
                    421.3904950394153
                ],
                [
                    126.09039409907723,
                    422.07049600164925
                ],
                [
                    123.34746538692093,
                    421.9674724130309
                ],
                [
                    124.51658254292192,
                    420.9371016437707
                ],
                [
                    124.89879392084549,
                    419.5972510926923
                ],
                [
                    123.61726165369055,
                    419.1642867307584
                ],
                [
                    120.28977436353375,
                    417.0813182858652
                ],
                [
                    122.40317845322784,
                    415.9672303758259
                ],
                [
                    122.98773703122833,
                    414.91476413112946
                ],
                [
                    124.35920138730648,
                    414.4193939349537
                ],
                [
                    125.07865809869168,
                    415.65770899972654
                ],
                [
                    127.97896796646341,
                    415.7196151065575
                ],
                [
                    129.30546627807985,
                    416.0703990837003
                ],
                [
                    129.12560210023344,
                    415.16242712180235
                ],
                [
                    130.20478716731145,
                    413.0568206154486
                ],
                [
                    131.73363267900527,
                    412.767732099406
                ],
                [
                    133.17254610177565,
                    413.3871826627191
                ],
                [
                    135.8480257472395,
                    412.850330889533
                ],
                [
                    137.8490147257794,
                    413.46976904236044
                ],
                [
                    138.81578468170324,
                    410.82618137602833
                ],
                [
                    141.4912643271673,
                    410.7022212632785
                ],
                [
                    141.96340779401385,
                    409.44174519680416
                ],
                [
                    142.95266077216843,
                    408.28424055267146
                ],
                [
                    142.5254833497836,
                    407.64333564675314
                ],
                [
                    140.52449437124324,
                    406.8782501717279
                ],
                [
                    138.9731658373189,
                    407.0023280516838
                ],
                [
                    138.4785393482416,
                    406.3198518522173
                ],
                [
                    139.6476565042426,
                    405.5958852977216
                ],
                [
                    140.5919434379357,
                    404.4579556927715
                ],
                [
                    140.88422272693606,
                    402.6365824500783
                ],
                [
                    140.1872490377816,
                    402.2432198855558
                ],
                [
                    140.38959623785877,
                    399.92363921631704
                ],
                [
                    142.41306823862965,
                    399.8614883998184
                ],
                [
                    143.19997401670707,
                    398.70115479630374
                ],
                [
                    143.76204957247683,
                    396.9392591880378
                ],
                [
                    144.2791590837851,
                    396.87705968605474
                ],
                [
                    145.20096299524744,
                    396.56604682416724
                ],
                [
                    145.94290272886337,
                    397.3953576450434
                ],
                [
                    147.08953686263362,
                    397.1465834776219
                ],
                [
                    148.2136879741729,
                    398.203761094275
                ],
                [
                    149.6526013969435,
                    398.53536410504523
                ],
                [
                    150.6868204195598,
                    399.9650725353037
                ],
                [
                    152.30559802017638,
                    400.54509213272604
                ],
                [
                    154.9585946434097,
                    398.203761094275
                ],
                [
                    155.1159757990249,
                    397.2709726028056
                ],
                [
                    155.38577206579453,
                    395.05208434784544
                ],
                [
                    157.29682895541146,
                    394.0977666392871
                ],
                [
                    159.36526700064405,
                    393.4752540092643
                ],
                [
                    160.87162949010667,
                    393.620516267623
                ],
                [
                    163.34476193549358,
                    394.61644778743164
                ],
                [
                    164.40146398034085,
                    393.3299860751252
                ],
                [
                    164.31153189141764,
                    392.54128948551477
                ],
                [
                    165.12092069172581,
                    393.06018769518107
                ],
                [
                    167.39170593703557,
                    392.0845988743573
                ],
                [
                    166.9195624701888,
                    389.86261367117527
                ],
                [
                    168.8531023820367,
                    389.8002956033107
                ],
                [
                    168.80813633757498,
                    390.75572264400955
                ],
                [
                    170.98898949396175,
                    390.4441977459441
                ],
                [
                    171.28126878296212,
                    391.56556368987776
                ],
                [
                    172.6302501168093,
                    391.295636495247
                ],
                [
                    172.85508033911697,
                    390.13264634981897
                ],
                [
                    174.85606931765733,
                    389.4263648472654
                ],
                [
                    176.20505065150473,
                    386.7869808065768
                ],
                [
                    176.81209225173575,
                    387.5353482202108
                ],
                [
                    178.8130812302761,
                    387.51456233296904
                ],
                [
                    179.59998700835354,
                    386.391946395858
                ],
                [
                    180.45434185312342,
                    388.20043351815
                ],
                [
                    180.72413811989304,
                    390.75572264400955
                ],
                [
                    180.07213047520008,
                    392.76961365051005
                ],
                [
                    179.33019074158415,
                    397.0636551244569
                ],
                [
                    179.3976398082766,
                    398.2452130490322
                ],
                [
                    178.09362451889092,
                    402.01546547639055
                ],
                [
                    177.6889301187366,
                    403.79573616008565
                ],
                [
                    175.77787322911945,
                    406.15438543327946
                ]
            ],
            [
                [
                    207.7936968857623,
                    338.0545833901276
                ],
                [
                    207.99604408583946,
                    338.3078521971803
                ],
                [
                    208.58060266383995,
                    338.37116597514057
                ],
                [
                    208.98529706399427,
                    338.7088163504323
                ],
                [
                    209.03026310845576,
                    338.28674730029394
                ],
                [
                    207.7936968857623,
                    338.0545833901276
                ]
            ],
            [
                [
                    122.98773703122833,
                    414.91476413112946
                ],
                [
                    122.9202879645361,
                    413.6142912339898
                ],
                [
                    121.52634058622698,
                    413.71751827729065
                ],
                [
                    119.88507996337967,
                    412.0655753480642
                ],
                [
                    118.51361560730152,
                    412.10688201197706
                ],
                [
                    118.51361560730152,
                    410.28899358864646
                ],
                [
                    116.89483800668472,
                    411.01211446817126
                ],
                [
                    115.90558502852991,
                    410.8675005746163
                ],
                [
                    114.9388150726063,
                    410.7022212632785
                ],
                [
                    113.81466396106657,
                    409.1317319152436
                ],
                [
                    111.49891267129556,
                    407.7467143413026
                ],
                [
                    110.6220748042947,
                    407.62265958732576
                ],
                [
                    109.27309347044752,
                    405.6165718922423
                ],
                [
                    108.23887444783122,
                    405.6165718922423
                ],
                [
                    108.35128955898517,
                    404.37518435632614
                ],
                [
                    107.51941773644603,
                    403.7750399987695
                ],
                [
                    108.26135747006197,
                    402.4709609529235
                ],
                [
                    106.26036849152183,
                    402.0982868063205
                ],
                [
                    103.78723604613515,
                    399.73718374865393
                ],
                [
                    101.87617915651799,
                    398.8669382871243
                ],
                [
                    100.21243551143971,
                    398.5560883354567
                ],
                [
                    99.537944844516,
                    397.229510014879
                ],
                [
                    98.86345417759253,
                    393.84877407428723
                ],
                [
                    97.98661631059167,
                    390.1741880685811
                ],
                [
                    97.289642621437,
                    385.3313757606395
                ],
                [
                    96.00811035428228,
                    380.9193345064175
                ],
                [
                    95.01885737612747,
                    380.00293428897646
                ],
                [
                    94.344366709204,
                    376.93957033325773
                ],
                [
                    93.93967230904991,
                    376.39746764384256
                ],
                [
                    93.24269861989524,
                    373.456103746806
                ],
                [
                    92.65814004189497,
                    372.07841800695917
                ],
                [
                    91.39909079697054,
                    370.2824038223705
                ],
                [
                    91.30915870804756,
                    368.88251343008875
                ],
                [
                    89.1732715961225,
                    367.8792435463023
                ],
                [
                    88.70112812927596,
                    366.12278638250007
                ],
                [
                    90.40983781881596,
                    365.7462803919617
                ],
                [
                    91.80378519712508,
                    363.98867799183813
                ],
                [
                    91.44405684143203,
                    363.13045534015816
                ],
                [
                    92.09606448612521,
                    361.7693912391307
                ],
                [
                    91.26419266358607,
                    360.93152711432356
                ],
                [
                    92.23096261950991,
                    360.30298525856836
                ],
                [
                    92.18599657504842,
                    359.00360653197083
                ],
                [
                    91.48902288589375,
                    358.6472325808302
                ],
                [
                    91.12929453020115,
                    357.1164651656124
                ],
                [
                    91.53398893035524,
                    356.65499247900243
                ],
                [
                    90.926947330124,
                    354.4305821362681
                ],
                [
                    89.1732715961225,
                    354.68248154546563
                ]
            ],
            [
                [
                    88.70112812927596,
                    366.12278638250007
                ],
                [
                    87.1722826175826,
                    363.7793763466385
                ],
                [
                    85.73336919481198,
                    359.21321960134367
                ],
                [
                    86.47530892842792,
                    358.01823970568796
                ],
                [
                    84.00217648304147,
                    357.0745158952351
                ],
                [
                    82.81057630480927,
                    353.5067767316349
                ]
            ],
            [
                [
                    234.63842542932343,
                    247.61671758132377
                ],
                [
                    236.0998218743248,
                    248.4262108249671
                ],
                [
                    237.358871119249,
                    250.00058958377485
                ],
                [
                    238.75281849755788,
                    250.56883981193528
                ],
                [
                    240.776290498329,
                    250.4158637089795
                ],
                [
                    242.3276190322531,
                    249.21354052577732
                ],
                [
                    245.49772516679445,
                    249.34473483659679
                ],
                [
                    246.37456303379508,
                    248.79804042796673
                ],
                [
                    248.55541619018186,
                    248.9292594374598
                ],
                [
                    250.19667681302917,
                    248.2074582187015
                ],
                [
                    250.30909192418312,
                    251.92331071393085
                ],
                [
                    249.58963521279793,
                    254.06256881444784
                ],
                [
                    251.81545441364574,
                    254.84790458056392
                ],
                [
                    252.66980925841608,
                    255.8509890366209
                ],
                [
                    253.20940179195486,
                    254.69522196799147
                ],
                [
                    255.00804357041784,
                    253.4079116870576
                ],
                [
                    259.2123687275755,
                    255.0878133931747
                ],
                [
                    260.17913868349933,
                    254.82609341554814
                ],
                [
                    260.69624819480737,
                    256.24337846140685
                ],
                [
                    262.35999183988565,
                    256.2651758670818
                ],
                [
                    264.0012524627334,
                    254.17165970201722
                ],
                [
                    264.33849779619527,
                    252.99319623514305
                ],
                [
                    263.48414295142516,
                    252.0761829820002
                ],
                [
                    264.29353175173355,
                    250.69995378116596
                ],
                [
                    264.1361505961179,
                    249.23540678551217
                ],
                [
                    263.3267617958097,
                    248.29496186813037
                ],
                [
                    264.8556073075033,
                    247.15714289738588
                ],
                [
                    265.62003006335,
                    248.27308628169772
                ],
                [
                    268.02571344204466,
                    248.88552063486793
                ],
                [
                    269.1498645535837,
                    250.45957224480526
                ],
                [
                    270.1840835762,
                    250.9840074508607
                ],
                [
                    271.1733365543548,
                    250.50327991829283
                ],
                [
                    272.0276913991247,
                    251.26802475502126
                ],
                [
                    274.56827291120385,
                    252.57840366039642
                ],
                [
                    276.18705051182064,
                    254.45527112511826
                ],
                [
                    278.0531413569761,
                    254.65159645365782
                ],
                [
                    278.9974282906692,
                    255.3931147792477
                ],
                [
                    278.59273389051486,
                    257.37656396225395
                ],
                [
                    280.0541303355162,
                    258.1607437695603
                ],
                [
                    280.16654544667017,
                    257.2022647999703
                ],
                [
                    282.14505140297956,
                    256.5703173851811
                ],
                [
                    282.23498349190277,
                    258.31319159441324
                ],
                [
                    282.72960998097983,
                    259.1188167835528
                ],
                [
                    285.06784429298204,
                    259.68476059413837
                ],
                [
                    285.69736891544426,
                    262.09932332841703
                ],
                [
                    284.5732178039045,
                    263.20786178190934
                ],
                [
                    281.8078060695175,
                    263.3599722799399
                ],
                [
                    280.75110402467067,
                    263.7727928285829
                ],
                [
                    277.8283111346682,
                    266.16135249497614
                ],
                [
                    276.50181282305175,
                    268.4390424134878
                ],
                [
                    276.41188073412854,
                    270.32458389478785
                ],
                [
                    278.23300553482227,
                    274.0046047805903
                ],
                [
                    278.0981074014378,
                    275.0858725634809
                ],
                [
                    276.59174491197496,
                    275.215591566649
                ],
                [
                    275.7149070449741,
                    275.97214460478534
                ],
                [
                    276.5242958452825,
                    275.34530348229924
                ],
                [
                    278.90749620174597,
                    275.4101567833875
                ],
                [
                    279.4021226908235,
                    276.29630796304826
                ],
                [
                    275.46759380043545,
                    279.1254654705514
                ],
                [
                    274.70317104458877,
                    280.2691385576315
                ],
                [
                    275.28772962258904,
                    282.25309552412483
                ],
                [
                    273.8712992220494,
                    283.37374404953823
                ],
                [
                    271.7129290878938,
                    284.66615888421546
                ],
                [
                    269.3072457091994,
                    287.7866787236396
                ],
                [
                    267.21632464173626,
                    289.05527556553113
                ],
                [
                    262.18012766203947,
                    290.43064579847066
                ],
                [
                    259.14491966088303,
                    291.48314925524636
                ],
                [
                    254.3560359257251,
                    293.9301154676676
                ],
                [
                    251.70303930249202,
                    295.81737230170785
                ],
                [
                    250.3315749464141,
                    297.2533163268572
                ],
                [
                    248.89266152364348,
                    298.17446447106767
                ],
                [
                    246.39704605602606,
                    300.65776450157654
                ],
                [
                    245.72255538910235,
                    301.89851303715903
                ],
                [
                    244.77826845540926,
                    301.2568212788392
                ],
                [
                    245.7000723668716,
                    301.94128680410773
                ],
                [
                    243.33935503263865,
                    304.80551846801353
                ],
                [
                    242.57493227679197,
                    306.3645559034897
                ],
                [
                    241.72057743202208,
                    307.1330539512041
                ],
                [
                    239.67462240902023,
                    310.0129218778187
                ],
                [
                    236.6394144078638,
                    312.82576651933846
                ],
                [
                    236.34713511886343,
                    313.76272249013573
                ],
                [
                    233.51427431778416,
                    315.3803307277066
                ],
                [
                    229.5122963607041,
                    317.37971556690445
                ],
                [
                    227.3988922710098,
                    319.37762882907356
                ],
                [
                    225.93749582600844,
                    320.82201743336566
                ],
                [
                    224.85831075893066,
                    322.838643416455
                ],
                [
                    222.13786506900533,
                    325.4050630075431
                ],
                [
                    220.06942702377273,
                    326.2105585099651
                ],
                [
                    219.64224960138768,
                    326.7403633875493
                ],
                [
                    215.39295839976876,
                    328.7315364691631
                ],
                [
                    213.03224106553603,
                    330.19224925321834
                ],
                [
                    209.74971981984095,
                    333.1325701859349
                ],
                [
                    208.71550079722465,
                    335.01363999261446
                ],
                [
                    208.71550079722465,
                    335.9220327434618
                ],
                [
                    210.37924444230293,
                    336.2388469959311
                ],
                [
                    209.7272367976102,
                    339.21521904200824
                ],
                [
                    209.6822707531485,
                    340.79716587683697
                ],
                [
                    208.49067057491675,
                    341.05019868567683
                ],
                [
                    204.42124355114402,
                    343.2843848663954
                ],
                [
                    202.8474319949887,
                    343.6425417799953
                ],
                [
                    200.86892603867955,
                    344.5903972085086
                ],
                [
                    198.48572568221584,
                    344.04278401304305
                ],
                [
                    196.1250083479831,
                    344.1059752606034
                ],
                [
                    194.25891750282767,
                    345.09579758253983
                ],
                [
                    193.494494746981,
                    346.1694914659786
                ],
                [
                    192.52772479105693,
                    349.74572380574756
                ],
                [
                    190.57170185697828,
                    351.637329305188
                ],
                [
                    190.4817697680553,
                    353.10777590818884
                ],
                [
                    189.447550745439,
                    353.6957592372588
                ],
                [
                    187.8737391892837,
                    353.8427376910373
                ],
                [
                    187.04186736674455,
                    351.38518245254227
                ],
                [
                    185.6479199884359,
                    351.11200007350715
                ],
                [
                    183.53451589874157,
                    351.61631785508115
                ],
                [
                    181.80332318697083,
                    352.4356584696585
                ],
                [
                    180.29696069750798,
                    353.5907703746818
                ],
                [
                    179.3526737638149,
                    354.9553496425532
                ],
                [
                    178.6781830968912,
                    357.3891218277098
                ],
                [
                    177.4640998964287,
                    359.00360653197083
                ],
                [
                    176.25001669596622,
                    364.47002058696734
                ],
                [
                    177.26175269635155,
                    368.5899244662831
                ],
                [
                    178.5432849635065,
                    370.90902983773424
                ],
                [
                    178.13859056335218,
                    374.4785680944054
                ],
                [
                    177.57651500758266,
                    376.3349118210916
                ],
                [
                    177.80134522989056,
                    379.1696340714409
                ],
                [
                    179.3976398082766,
                    383.08441995169403
                ],
                [
                    179.0828774970455,
                    384.99858291896464
                ],
                [
                    179.59998700835354,
                    386.391946395858
                ]
            ],
            [
                [
                    132.99268192392924,
                    465.36835931896246
                ],
                [
                    130.9467269009274,
                    463.4479976670746
                ],
                [
                    127.12461312169353,
                    458.7260653366865
                ],
                [
                    126.87729987715466,
                    458.1738913738211
                ],
                [
                    124.89879392084549,
                    456.29199354985946
                ],
                [
                    123.88705792045994,
                    453.01757189980543
                ],
                [
                    121.97600103084301,
                    449.08559606784866
                ],
                [
                    121.54882360845772,
                    447.65132269649564
                ],
                [
                    120.96426503045745,
                    443.0794335753683
                ],
                [
                    119.30052138537894,
                    437.23027047370135
                ],
                [
                    119.99749507453339,
                    437.04544451606046
                ],
                [
                    119.23307231868671,
                    437.18919865842923
                ],
                [
                    118.3337514294551,
                    434.23109121079204
                ],
                [
                    116.22034733976125,
                    429.6875615886563
                ],
                [
                    115.52337365060657,
                    428.08288397510495
                ],
                [
                    114.28680742791335,
                    423.1417966200068
                ],
                [
                    112.39823356052693,
                    418.60755367724744
                ],
                [
                    111.22911640452617,
                    417.8238799029757
                ],
                [
                    110.28482947083307,
                    414.46067703953213
                ],
                [
                    110.0375162262942,
                    414.3987522287599
                ],
                [
                    109.97006715960197,
                    414.3987522287599
                ],
                [
                    107.81169702544616,
                    412.12753518784575
                ],
                [
                    107.38451960306134,
                    412.08622873205434
                ],
                [
                    105.83319106913677,
                    409.8550592423301
                ],
                [
                    105.09125133552061,
                    409.87572383634495
                ],
                [
                    104.57414182421257,
                    408.63566107557654
                ],
                [
                    102.75301702351862,
                    404.126859887723
                ],
                [
                    102.37080564559551,
                    403.69225426042715
                ],
                [
                    100.21243551143971,
                    398.5560883354567
                ]
            ],
            [
                [
                    295.11775523014444,
                    261.1642556092064
                ],
                [
                    293.7462908740663,
                    260.9467430852385
                ],
                [
                    294.80299291891356,
                    258.5091808522876
                ],
                [
                    295.7247968303757,
                    258.8140193186129
                ],
                [
                    295.79224589706814,
                    260.1635260111431
                ],
                [
                    295.11775523014444,
                    261.1642556092064
                ]
            ],
            [
                [
                    308.20287416846327,
                    259.510639064621
                ],
                [
                    309.34950830223374,
                    261.51223251374955
                ],
                [
                    308.54011950192535,
                    262.16454633377396
                ],
                [
                    308.20287416846327,
                    259.510639064621
                ]
            ],
            [
                [
                    304.6955227004603,
                    258.09540584249646
                ],
                [
                    305.16766616730683,
                    261.25125481047445
                ],
                [
                    304.2458622558445,
                    261.3599991470084
                ],
                [
                    303.4364734555361,
                    259.9459178675629
                ],
                [
                    303.7962018112287,
                    257.50727950153583
                ],
                [
                    304.6955227004603,
                    258.09540584249646
                ]
            ],
            [
                [
                    307.1461721236162,
                    257.35477730314017
                ],
                [
                    307.2136211903087,
                    259.6629961329742
                ],
                [
                    305.34753034515325,
                    257.1586879055543
                ],
                [
                    306.24685123438485,
                    257.37656396225395
                ],
                [
                    307.1461721236162,
                    257.35477730314017
                ]
            ],
            [
                [
                    110.30731249306382,
                    414.15104374638247
                ],
                [
                    110.10496529298666,
                    413.90332046441563
                ],
                [
                    110.0375162262942,
                    414.19232952059235
                ],
                [
                    110.30731249306382,
                    414.15104374638247
                ]
            ],
            [
                [
                    173.30474078373277,
                    410.6402397904577
                ],
                [
                    172.83259731688622,
                    412.31340909035265
                ]
            ],
            [
                [
                    185.37812372166627,
                    266.55197254752545
                ],
                [
                    184.68115003251182,
                    268.243899348228
                ],
                [
                    182.47781385389453,
                    269.046049627439
                ],
                [
                    182.11808549820194,
                    270.28125519830496
                ],
                [
                    182.47781385389453,
                    272.92284256151237
                ],
                [
                    183.691897054357,
                    272.83628016414497
                ],
                [
                    183.91672727666514,
                    274.5885506642957
                ],
                [
                    184.1865235434343,
                    276.9445023831133
                ],
                [
                    183.30968567643367,
                    278.4130920196855
                ],
                [
                    184.72611607697354,
                    278.32672934026704
                ],
                [
                    184.34390469904997,
                    281.34757805026777
                ],
                [
                    184.86101421035823,
                    282.29620696045447
                ],
                [
                    181.46607785350898,
                    283.67536884329616
                ],
                [
                    182.00567038704799,
                    285.3766953991411
                ],
                [
                    183.08485545412577,
                    285.24752229959677
                ],
                [
                    183.66941403212627,
                    285.9363667516583
                ],
                [
                    183.44458380981837,
                    288.4533108385035
                ],
                [
                    182.54526292058677,
                    290.2157944473971
                ],
                [
                    183.691897054357,
                    290.3447074875358
                ],
                [
                    183.9392102988959,
                    291.2683887574663
                ],
                [
                    185.5355048772817,
                    292.14878932807136
                ],
                [
                    186.16502949974392,
                    293.5009959963532
                ],
                [
                    188.8180261229768,
                    295.00259383908593
                ],
                [
                    188.18850150051458,
                    297.4247180004906
                ],
                [
                    186.70462203328293,
                    298.6242063924207
                ],
                [
                    184.65866701028108,
                    298.2815530809514
                ],
                [
                    184.14155749897282,
                    296.9104782590516
                ],
                [
                    182.74761012066392,
                    297.85317161362866
                ],
                [
                    181.44359483127823,
                    300.18697895462606
                ],
                [
                    180.54427394204663,
                    300.8503336990302
                ],
                [
                    180.4993078975849,
                    302.0054461237708
                ],
                [
                    179.64495305281525,
                    303.9935951878714
                ],
                [
                    180.7915871865855,
                    305.4676893045989
                ],
                [
                    179.68991909727674,
                    306.748833118846
                ],
                [
                    179.71240211950771,
                    307.45319497145044
                ],
                [
                    177.39665082973625,
                    308.0720236147391
                ],
                [
                    175.93525438473512,
                    306.6634430578111
                ],
                [
                    174.38392585081078,
                    305.80938909539
                ],
                [
                    175.08089953996523,
                    305.1900252530876
                ],
                [
                    174.76613722873412,
                    302.5614267274125
                ],
                [
                    173.57453705050239,
                    301.5135171753282
                ],
                [
                    174.60875607311846,
                    300.6363670325046
                ],
                [
                    175.32821278450388,
                    296.8890493416166
                ],
                [
                    174.83358629542658,
                    295.77449577791907
                ],
                [
                    173.66446913942536,
                    295.1527043752976
                ],
                [
                    172.81011429465548,
                    294.0802899650972
                ],
                [
                    171.88831038319313,
                    293.60828274047594
                ],
                [
                    167.88633242611263,
                    295.3028059785232
                ],
                [
                    166.3350038921883,
                    294.12319531642106
                ],
                [
                    164.67126024711,
                    293.865752221271
                ],
                [
                    163.74945633564766,
                    295.9459975097123
                ],
                [
                    162.37799197956974,
                    294.80958144369424
                ],
                [
                    160.01727464533678,
                    294.53075965343004
                ],
                [
                    160.30955393433715,
                    292.87864235534573
                ],
                [
                    158.8931235337975,
                    292.3634740927217
                ],
                [
                    158.6233272670279,
                    291.22543443707144
                ],
                [
                    154.71128139887082,
                    290.3447074875358
                ],
                [
                    152.59787730917674,
                    290.40916149935356
                ],
                [
                    151.8559375755608,
                    289.3776964312693
                ],
                [
                    150.75426948625227,
                    288.88330059587133
                ],
                [
                    151.60862433102193,
                    290.92473345138
                ],
                [
                    151.29386201979105,
                    291.97702823496
                ],
                [
                    150.30460904163624,
                    292.3205386148391
                ],
                [
                    150.75426948625227,
                    293.65119615408014
                ],
                [
                    150.79923553071376,
                    295.6029824072024
                ],
                [
                    149.36032210794315,
                    296.43900022765195
                ],
                [
                    149.09052584117376,
                    298.17446447106767
                ],
                [
                    148.82072957440437,
                    299.395008135298
                ],
                [
                    146.86470664032572,
                    298.88116616423434
                ],
                [
                    145.42579321755534,
                    297.76748666660114
                ],
                [
                    144.23419303932337,
                    298.36722072773574
                ],
                [
                    144.18922699486188,
                    300.2939832584313
                ],
                [
                    143.26742308339954,
                    301.9626734214149
                ],
                [
                    143.94191375032324,
                    304.52778378512915
                ],
                [
                    145.49324228424734,
                    305.5317614246396
                ],
                [
                    143.89694770586152,
                    306.1296920331829
                ],
                [
                    143.1325249500146,
                    308.02935045477244
                ],
                [
                    142.27817010524473,
                    308.28537903189954
                ],
                [
                    142.41306823862965,
                    309.991601073371
                ],
                [
                    140.3221471711663,
                    310.0982033786218
                ],
                [
                    140.1422829933199,
                    311.69672404844084
                ],
                [
                    139.26544512631926,
                    312.8470646176613
                ],
                [
                    139.6476565042426,
                    314.0820644185967
                ],
                [
                    138.3886072593184,
                    314.5503638720703
                ],
                [
                    137.55673543677926,
                    313.9330429162161
                ],
                [
                    137.62418450347172,
                    312.52757531400954
                ],
                [
                    136.20775410293209,
                    311.7606448813965
                ],
                [
                    135.4433313470854,
                    312.05892182099956
                ],
                [
                    135.1735350803158,
                    312.86836254626604
                ],
                [
                    132.49805543485195,
                    316.27385554479633
                ],
                [
                    130.83431178977366,
                    315.6994806846033
                ],
                [
                    130.38465134515786,
                    316.78430856963826
                ],
                [
                    130.8567948120044,
                    317.9749919944694
                ],
                [
                    130.15982112284973,
                    318.4638716117512
                ],
                [
                    130.36216832292712,
                    319.9087425726369
                ],
                [
                    128.2487642332328,
                    320.50346770989375
                ],
                [
                    127.73165472192477,
                    320.1636405742418
                ],
                [
                    128.47359445554093,
                    321.28915690173204
                ],
                [
                    127.3494433440012,
                    321.9897174869891
                ],
                [
                    126.92226592161637,
                    323.5175966334206
                ],
                [
                    126.49508849923131,
                    322.9659598241531
                ],
                [
                    124.53906556515267,
                    322.3080952848776
                ],
                [
                    124.0444390760756,
                    323.8570108987007
                ],
                [
                    123.145118186844,
                    324.1115443718832
                ],
                [
                    121.72868778630436,
                    324.8325960536707
                ],
                [
                    121.70620476407362,
                    326.78274345349035
                ],
                [
                    122.31324636430463,
                    327.3124403099387
                ],
                [
                    122.47062751992007,
                    329.17618098117003
                ],
                [
                    120.22232529684129,
                    328.392712737739
                ],
                [
                    117.95154005153177,
                    328.8374105520343
                ],
                [
                    115.83813596183768,
                    329.0491468244974
                ],
                [
                    115.2985434282989,
                    327.84203738422815
                ],
                [
                    114.10694325006693,
                    328.2868219818396
                ],
                [
                    111.45394662683384,
                    326.67679208825695
                ],
                [
                    110.57710875983321,
                    328.1173885046705
                ],
                [
                    111.02676920444901,
                    329.3243802917495
                ],
                [
                    111.76870893806495,
                    329.8112663085058
                ],
                [
                    111.34153151567989,
                    331.35604764336426
                ],
                [
                    112.03850520483456,
                    332.24444673844187
                ],
                [
                    111.92609009368039,
                    334.33744160267224
                ],
                [
                    109.92510111514025,
                    334.4853735317836
                ],
                [
                    108.98081418144716,
                    334.1895020868333
                ],
                [
                    107.15968938075343,
                    334.696691708251
                ],
                [
                    106.26036849152183,
                    334.696691708251
                ],
                [
                    106.0355382692137,
                    336.13324608585606
                ],
                [
                    104.82145506875145,
                    336.42891896263467
                ],
                [
                    103.4499907126733,
                    334.7600841461107
                ],
                [
                    100.70706200051723,
                    336.13324608585606
                ],
                [
                    100.95437524505564,
                    337.50575903981166
                ],
                [
                    99.94263924467032,
                    337.50575903981166
                ],
                [
                    97.67185399936079,
                    338.3922702636092
                ],
                [
                    96.70508404343695,
                    340.4175760379173
                ],
                [
                    95.8282461764361,
                    340.86042610897977
                ],
                [
                    95.44603479851253,
                    339.616059271383
                ],
                [
                    92.07358146389424,
                    340.86042610897977
                ],
                [
                    92.32089470843289,
                    342.39934215564455
                ],
                [
                    92.09606448612521,
                    344.3587269167076
                ],
                [
                    92.92793630866436,
                    345.3484658903204
                ],
                [
                    94.50174786481944,
                    345.53795319410347
                ],
                [
                    94.50174786481944,
                    347.09550870021513
                ],
                [
                    92.90545328643361,
                    348.14745754022647
                ],
                [
                    94.25443462028079,
                    348.75742110718363
                ],
                [
                    93.13028350874151,
                    350.81777654426855
                ],
                [
                    92.52324190851004,
                    352.96076267340675
                ],
                [
                    90.94943035235497,
                    353.25478217715886
                ],
                [
                    90.52225292996968,
                    352.70872376518497
                ],
                [
                    89.24072066281497,
                    353.63276634512624
                ],
                [
                    89.1732715961225,
                    354.68248154546563
                ],
                [
                    87.30718075096706,
                    355.29115460849647
                ],
                [
                    86.54275799512038,
                    353.2967826895309
                ],
                [
                    85.59847106142706,
                    353.17077944756636
                ],
                [
                    82.81057630480927,
                    353.5067767316349
                ],
                [
                    81.39414590426986,
                    350.7337075076276
                ],
                [
                    80.24751177049961,
                    350.3133278073674
                ],
                [
                    78.78611532549826,
                    345.18002271110595
                ],
                [
                    78.15659070303627,
                    343.79000567715116
                ],
                [
                    77.84182839180517,
                    341.239959090874
                ],
                [
                    77.90927745849763,
                    339.93247351358445
                ],
                [
                    77.23478679157415,
                    338.16011472352744
                ],
                [
                    77.41465096942034,
                    337.0201745538279
                ],
                [
                    76.80760936918887,
                    335.68968030816086
                ],
                [
                    77.09988865818923,
                    334.25290566578303
                ],
                [
                    76.98747354703528,
                    332.24444673844187
                ],
                [
                    76.02070359111121,
                    329.93826630476786
                ],
                [
                    76.33546590234232,
                    329.07031957999726
                ],
                [
                    75.43614501311072,
                    328.09620860424155
                ],
                [
                    75.34621292418797,
                    325.0446345519327
                ],
                [
                    73.39018999010932,
                    318.888913027936
                ],
                [
                    72.33348794526205,
                    316.6779721268407
                ],
                [
                    72.28852190080056,
                    314.84833013741405
                ],
                [
                    73.16535976780119,
                    314.5077945673256
                ],
                [
                    71.47913310049216,
                    313.1239244569379
                ],
                [
                    71.1868538114918,
                    311.4410253651083
                ],
                [
                    71.77141238949253,
                    310.22612048051855
                ],
                [
                    70.62477825572228,
                    307.77329694908633
                ],
                [
                    70.80464243356846,
                    306.21509951831786
                ],
                [
                    70.33249896672191,
                    305.2113850766203
                ],
                [
                    69.83787247764462,
                    304.5705141286223
                ],
                [
                    70.3774650111834,
                    302.3476022081399
                ],
                [
                    69.59055923310598,
                    301.0856764485497
                ],
                [
                    69.568076210875,
                    298.9882251001346
                ],
                [
                    69.77042341095216,
                    298.36722072773574
                ],
                [
                    68.6013062549514,
                    294.7023459465084
                ],
                [
                    68.55634021048968,
                    293.0288797975515
                ],
                [
                    67.6570193212583,
                    290.06538744305016
                ],
                [
                    67.79191745464277,
                    288.5393147749173
                ],
                [
                    68.80365345502832,
                    287.9372243010922
                ],
                [
                    68.44392509933573,
                    286.34527654727094
                ],
                [
                    69.43317807749031,
                    285.44127939184284
                ],
                [
                    70.87209150026092,
                    283.95541555045895
                ],
                [
                    72.17610678964661,
                    284.23543007389594
                ],
                [
                    72.2210728341081,
                    285.505861680397
                ],
                [
                    74.76165434618724,
                    286.1515909311974
                ],
                [
                    75.84083941326503,
                    285.61349504214576
                ],
                [
                    76.40291496903478,
                    285.59196874830667
                ],
                [
                    77.14485470265072,
                    284.2138916369992
                ],
                [
                    78.85356439219072,
                    284.30004424326154
                ],
                [
                    78.67370021434431,
                    281.4553955188571
                ],
                [
                    79.6179871480374,
                    279.7297378646218
                ],
                [
                    79.68543621472986,
                    278.3483203019426
                ],
                [
                    78.7186662588058,
                    277.72210335817573
                ],
                [
                    79.7079192369606,
                    276.209868716718
                ],
                [
                    81.7763572821932,
                    277.70050674738934
                ],
                [
                    82.33843283796296,
                    278.65057334408
                ],
                [
                    83.68741417181013,
                    278.65057334408
                ],
                [
                    85.05887852788828,
                    277.7652959947034
                ],
                [
                    85.59847106142706,
                    276.0585924836837
                ],
                [
                    86.2279956838895,
                    276.14503722256995
                ],
                [
                    86.58772403958187,
                    274.3290369398761
                ],
                [
                    85.80081826150422,
                    272.8579210614112
                ],
                [
                    85.21625968350395,
                    271.84058389546976
                ],
                [
                    85.21625968350395,
                    271.8189336445579
                ],
                [
                    84.60921808327248,
                    271.6673763040509
                ],
                [
                    84.5192859943495,
                    271.34257764751965
                ],
                [
                    83.57499906065641,
                    270.5628773920299
                ],
                [
                    82.54078003804011,
                    269.37116706610857
                ],
                [
                    84.72163319442643,
                    269.06772553304796
                ],
                [
                    84.83404830558038,
                    267.5499246969801
                ],
                [
                    86.58772403958187,
                    267.072696816181
                ],
                [
                    86.83503728412052,
                    265.5752986776284
                ],
                [
                    88.11656955127569,
                    264.7719461996339
                ],
                [
                    90.52225292996968,
                    264.51133931372397
                ],
                [
                    92.16351355281745,
                    263.94658981350665
                ],
                [
                    91.48902288589375,
                    262.94707745684065
                ],
                [
                    89.84776226304643,
                    262.8384085655481
                ],
                [
                    87.1722826175826,
                    263.55552810731183
                ],
                [
                    87.1722826175826,
                    263.57725550436294
                ],
                [
                    85.0139124834268,
                    263.59898269583147
                ],
                [
                    84.69915017219569,
                    261.64271017786166
                ],
                [
                    85.68840315035027,
                    260.533412090034
                ],
                [
                    84.85653132781113,
                    259.79357977235304
                ],
                [
                    84.74411621665718,
                    258.530956389162
                ],
                [
                    86.54275799512038,
                    258.00828567001014
                ],
                [
                    89.60044901850756,
                    256.78825030177217
                ]
            ],
            [
                [
                    69.43317807749031,
                    285.44127939184284
                ],
                [
                    68.91606856618228,
                    283.6969120410993
                ],
                [
                    69.47814412195203,
                    282.23153951844193
                ],
                [
                    70.22008385556796,
                    281.73569844657766
                ],
                [
                    71.07443870033785,
                    280.2691385576315
                ],
                [
                    71.32175194487672,
                    278.477861986839
                ],
                [
                    70.6022952334913,
                    275.7343967387643
                ],
                [
                    68.64627229941266,
                    271.0393916853882
                ],
                [
                    69.07344972179794,
                    270.82280628859183
                ],
                [
                    67.16239283218079,
                    270.2379256996293
                ],
                [
                    67.40970607671943,
                    268.48240531749315
                ],
                [
                    66.82514749871893,
                    266.8123493084137
                ],
                [
                    67.6570193212583,
                    266.3566707625562
                ],
                [
                    68.2190948770276,
                    264.53305768065474
                ],
                [
                    68.84861949948981,
                    264.31586478988004
                ],
                [
                    68.42144207710498,
                    263.4903446825006
                ],
                [
                    67.00501167656512,
                    262.6427915730003
                ],
                [
                    68.33150998818155,
                    261.62096441821745
                ],
                [
                    66.01575869841054,
                    260.22880439423415
                ],
                [
                    67.18487585441153,
                    259.0317359747364
                ],
                [
                    65.52113220933325,
                    256.8972088565251
                ],
                [
                    66.3754870541029,
                    253.12416733581836
                ],
                [
                    67.5670872323351,
                    252.57840366039642
                ],
                [
                    69.09593274402846,
                    253.25513074919206
                ],
                [
                    70.78215941133772,
                    252.6002367731303
                ],
                [
                    71.95127656733871,
                    251.35540738433673
                ],
                [
                    67.72446838795031,
                    251.57384891733625
                ],
                [
                    65.61106429825645,
                    250.8966101966913
                ],
                [
                    64.80167549794805,
                    251.92331071393085
                ],
                [
                    63.5651092752546,
                    251.00585622640926
                ],
                [
                    63.00303371948485,
                    253.1896500031919
                ],
                [
                    61.6315693634067,
                    256.0907907085739
                ],
                [
                    61.0470107854062,
                    257.7251219206184
                ],
                [
                    61.58660331894521,
                    258.79224650272477
                ],
                [
                    60.88962962979099,
                    259.96767961913724
                ],
                [
                    62.57585629709979,
                    261.94712908067964
                ],
                [
                    61.496671230022,
                    264.61992909992597
                ],
                [
                    59.63058038486656,
                    267.05100230715357
                ],
                [
                    59.69802945155902,
                    268.67752839364096
                ],
                [
                    55.42625522770936,
                    270.5412153488389
                ],
                [
                    53.290368115784304,
                    271.86223394699664
                ],
                [
                    50.83971869262837,
                    272.68478832037374
                ],
                [
                    49.76053362555058,
                    273.8748194601293
                ],
                [
                    48.05182393601058,
                    274.22089782779216
                ],
                [
                    46.36559726870155,
                    275.0210104029956
                ],
                [
                    44.072329001160824,
                    276.0369808083752
                ],
                [
                    43.330389267545115,
                    275.8640803389601
                ],
                [
                    42.16127211154412,
                    275.8640803389601
                ],
                [
                    40.78980775546597,
                    276.6852457635207
                ],
                [
                    35.214018242230395,
                    274.1992694125984
                ],
                [
                    32.15632721884322,
                    271.9271829054315
                ],
                [
                    29.975474062456897,
                    269.9995991119276
                ],
                [
                    28.356696461839874,
                    268.2222157752747
                ],
                [
                    24.33223548252886,
                    263.3599722799399
                ],
                [
                    19.38597059175538,
                    258.7051531469332
                ],
                [
                    19.745698947447863,
                    257.7686878836714
                ],
                [
                    19.09369130275502,
                    258.55273171663043
                ],
                [
                    17.115185346445855,
                    256.67928647868337
                ],
                [
                    13.427969700596464,
                    252.29455371884785
                ],
                [
                    13.00079227821152,
                    251.0714012621392
                ],
                [
                    14.012528278597074,
                    249.01673444160116
                ],
                [
                    16.328279568368202,
                    250.48142618933292
                ],
                [
                    16.55310979067599,
                    252.62206967192594
                ],
                [
                    18.936310147139693,
                    251.0495531320318
                ],
                [
                    21.13964632575687,
                    250.65624998653277
                ],
                [
                    21.881586059372808,
                    250.02244805911346
                ],
                [
                    23.05070321537403,
                    250.10987980142187
                ],
                [
                    25.00672614945256,
                    249.36659979741415
                ],
                [
                    26.62550375006913,
                    248.64494171589382
                ],
                [
                    28.26676437291667,
                    247.37599997277755
                ],
                [
                    30.47010055153396,
                    247.22280230965094
                ],
                [
                    31.90901397430457,
                    245.49311503921888
                ],
                [
                    31.84156490761211,
                    244.5948899833091
                ],
                [
                    32.920749974690125,
                    243.82781977773777
                ],
                [
                    34.4945615308452,
                    241.6127558397618
                ],
                [
                    35.23650126446114,
                    242.57801210056658
                ],
                [
                    35.93347495361559,
                    241.15191362081123
                ],
                [
                    36.87776188730868,
                    239.61506235483483
                ],
                [
                    38.76633575469509,
                    238.2089807853951
                ],
                [
                    39.19351317707992,
                    237.0658607736736
                ],
                [
                    36.04589006476954,
                    237.26375213346256
                ],
                [
                    34.87677290876877,
                    236.62603663421203
                ],
                [
                    33.21302926369026,
                    236.64802999044565
                ],
                [
                    31.054659129534457,
                    239.02198384715552
                ],
                [
                    29.81809290684123,
                    241.37137454117885
                ],
                [
                    27.547307661531477,
                    241.74440701997742
                ],
                [
                    25.231556371760462,
                    242.86311924716665
                ],
                [
                    23.455397615528,
                    243.3455230176241
                ],
                [
                    20.060461258678856,
                    243.1043345200577
                ],
                [
                    15.85613610152177,
                    242.09543771126374
                ],
                [
                    8.526670854284589,
                    237.37368387198012
                ],
                [
                    7.042791387052603,
                    235.6140972721712
                ],
                [
                    7.312587653821879,
                    234.4035387009302
                ],
                [
                    5.109251475204701,
                    233.36851470106518
                ],
                [
                    5.334081697512602,
                    232.3109469365628
                ],
                [
                    4.030066408126913,
                    232.1566741195603
                ],
                [
                    3.782753163588154,
                    230.8338729840525
                ],
                [
                    1.309620718201586,
                    230.4589283415172
                ],
                [
                    0.702579117970231,
                    229.22334345541645
                ],
                [
                    1.759281162817274,
                    228.9364072100185
                ],
                [
                    2.298873696356168,
                    226.06487771093498
                ],
                [
                    4.2099305859730975,
                    224.6055083557327
                ],
                [
                    10.302829610516824,
                    224.51702875076313
                ],
                [
                    10.460210766132263,
                    219.29000977355628
                ],
                [
                    11.696776988825832,
                    220.1990471966829
                ],
                [
                    13.135690411596215,
                    219.17912395208293
                ],
                [
                    13.967562234135357,
                    220.465030723025
                ],
                [
                    15.316543567982649,
                    219.64480405838214
                ],
                [
                    16.77794001298389,
                    220.24338017828967
                ],
                [
                    18.531615746985608,
                    219.53393744564943
                ],
                [
                    21.162129347987616,
                    219.75566467309886
                ],
                [
                    22.668491837450574,
                    219.33436242056325
                ],
                [
                    24.691963838221454,
                    221.30708474330936
                ],
                [
                    29.00870410653306,
                    221.30708474330936
                ],
                [
                    30.245270329226287,
                    219.31218621715476
                ],
                [
                    37.05762606515509,
                    217.24874701775877
                ],
                [
                    37.035143042924346,
                    219.9995369235179
                ],
                [
                    38.451573443463985,
                    220.53152122012204
                ],
                [
                    40.475045444234865,
                    220.50935796031672
                ],
                [
                    41.48678144462042,
                    219.31218621715476
                ],
                [
                    43.12804206746796,
                    218.15869223672456
                ],
                [
                    44.04984597893031,
                    218.3140081940855
                ],
                [
                    44.83675175700796,
                    217.0267475314228
                ],
                [
                    43.30790624531437,
                    216.89353622258778
                ],
                [
                    43.12804206746796,
                    214.4497799508872
                ],
                [
                    44.971649890392655,
                    213.16006596611408
                ],
                [
                    46.09580100193193,
                    213.56041013680016
                ],
                [
                    47.55719744693329,
                    214.11631211050508
                ],
                [
                    48.88369575854972,
                    213.96067496847235
                ],
                [
                    50.36757522578205,
                    213.09333421669788
                ],
                [
                    52.27863211539875,
                    213.13782229486714
                ],
                [
                    52.9756058045532,
                    213.7383154061759
                ],
                [
                    54.97659478309333,
                    213.13782229486714
                ],
                [
                    56.84268562824877,
                    213.20455257302189
                ],
                [
                    58.888640651250626,
                    212.55940071717254
                ],
                [
                    60.462452207405704,
                    214.02737806768494
                ],
                [
                    62.95806767502336,
                    213.84949824528678
                ],
                [
                    63.22786394179275,
                    214.96099063533734
                ],
                [
                    65.38623407594855,
                    217.33754003906057
                ],
                [
                    65.67851336494891,
                    215.98302542702993
                ],
                [
                    68.08419674364313,
                    216.5826759270039
                ],
                [
                    68.73620438833586,
                    218.13650327845693
                ],
                [
                    72.1311407451849,
                    218.24744565871737
                ],
                [
                    72.7606653676471,
                    216.3161864314664
                ],
                [
                    74.0197146125713,
                    216.02745005059455
                ],
                [
                    73.86233345695564,
                    217.67047942288724
                ],
                [
                    74.9640015462644,
                    218.33619522385152
                ],
                [
                    73.72743532357094,
                    220.93041901026214
                ],
                [
                    74.58179016834083,
                    222.0380626548403
                ],
                [
                    75.88580545772675,
                    222.81305906840663
                ],
                [
                    76.3129828801118,
                    224.03032339958867
                ],
                [
                    77.39216794718959,
                    223.18938093673984
                ],
                [
                    78.69618323657505,
                    223.38858241975362
                ],
                [
                    78.85356439219072,
                    225.2689851524994
                ],
                [
                    78.29148883642097,
                    225.60064407781806
                ],
                [
                    78.15659070303627,
                    227.03722220733812
                ],
                [
                    79.7079192369606,
                    228.80396184925877
                ],
                [
                    80.42737594834603,
                    230.37069636377163
                ],
                [
                    81.14683265973122,
                    229.62057501272216
                ],
                [
                    82.38339888242444,
                    230.15010022519328
                ],
                [
                    81.95622146003961,
                    232.9058938199626
                ],
                [
                    83.84479532742557,
                    232.81776409694464
                ],
                [
                    84.94646341673456,
                    233.2804042266112
                ],
                [
                    85.84578430596594,
                    234.73375935062114
                ],
                [
                    86.83503728412052,
                    234.00720616665353
                ],
                [
                    87.1722826175826,
                    234.68973289401652
                ],
                [
                    89.48803390735361,
                    235.83412491994062
                ],
                [
                    89.30816972950743,
                    237.28573893281117
                ],
                [
                    91.08432848573966,
                    237.1538147485146
                ],
                [
                    92.1410305305867,
                    239.13182553649392
                ],
                [
                    93.10780048651077,
                    238.2968878502532
                ],
                [
                    94.883959242743,
                    238.73636918582042
                ],
                [
                    98.23392955513009,
                    236.56005520728655
                ],
                [
                    95.22120457620463,
                    234.95387796531952
                ],
                [
                    95.85072919866684,
                    233.36851470106518
                ],
                [
                    98.79600511090007,
                    231.4071886640163
                ],
                [
                    99.89767320020883,
                    231.25284978267752
                ],
                [
                    100.99934128951713,
                    229.42196863275268
                ],
                [
                    101.26913755628675,
                    227.83243980999953
                ],
                [
                    100.70706200051723,
                    226.08698149088036
                ],
                [
                    101.76376404536427,
                    223.41071473313826
                ],
                [
                    100.36981666705515,
                    221.2627747080008
                ],
                [
                    100.7969940894402,
                    220.64233393146185
                ],
                [
                    100.18995248920874,
                    220.13254592818816
                ],
                [
                    98.52620884413068,
                    220.1547132571883
                ],
                [
                    98.93090324428476,
                    218.60242078962796
                ],
                [
                    100.01008831136255,
                    217.22654815781567
                ],
                [
                    99.42552973336205,
                    216.49384997521207
                ],
                [
                    97.69433702159154,
                    216.27176811965217
                ],
                [
                    98.99835231097722,
                    214.60537947340043
                ],
                [
                    98.79600511090007,
                    213.3824891955212
                ],
                [
                    99.26814857774662,
                    212.18111221241656
                ],
                [
                    100.50471480044007,
                    213.8272621668114
                ],
                [
                    102.03356031213343,
                    212.87087910758856
                ],
                [
                    101.80873008982553,
                    211.7137164826724
                ],
                [
                    100.10002040028576,
                    211.15715130844973
                ],
                [
                    99.58291088897772,
                    209.53109699146876
                ],
                [
                    100.70706200051723,
                    209.0407942008415
                ],
                [
                    101.53893382305637,
                    210.19949816254245
                ],
                [
                    102.70805097905713,
                    210.39997503206564
                ],
                [
                    103.85468511282738,
                    209.8876053808561
                ],
                [
                    103.8097190683659,
                    208.43889449815975
                ],
                [
                    104.95635320213614,
                    207.30147712021056
                ],
                [
                    107.31707053636887,
                    207.59146869830084
                ],
                [
                    107.09224031406097,
                    208.66184146665967
                ],
                [
                    104.84393809098219,
                    211.9808130508498
                ],
                [
                    108.84591604806246,
                    213.07108980973862
                ],
                [
                    110.21738040414061,
                    212.71514592873683
                ],
                [
                    111.09421827114147,
                    213.04884515750535
                ],
                [
                    113.67976582768188,
                    211.7582350403839
                ],
                [
                    114.2643244056826,
                    213.29352284500686
                ],
                [
                    115.68075480622201,
                    215.2943194312777
                ],
                [
                    115.38847551722188,
                    216.91573871267303
                ],
                [
                    113.70224884991262,
                    216.7603161962095
                ],
                [
                    112.84789400514273,
                    217.67047942288724
                ],
                [
                    114.10694325006693,
                    220.11037835966232
                ],
                [
                    113.18513933860459,
                    221.75013263322847
                ],
                [
                    114.44418858352878,
                    223.14511133332928
                ],
                [
                    112.4656826272194,
                    224.6055083557327
                ],
                [
                    109.8126860039863,
                    223.6541545501911
                ],
                [
                    108.93584813698567,
                    225.37954400932404
                ],
                [
                    110.44221062644829,
                    226.37430927608494
                ],
                [
                    110.7120068932179,
                    227.3465094833473
                ],
                [
                    112.28581844937298,
                    228.119527361905
                ],
                [
                    113.81466396106657,
                    226.19749686999472
                ],
                [
                    115.92806805076088,
                    225.71118529392055
                ],
                [
                    117.45691356245447,
                    222.9901602519932
                ],
                [
                    118.71596280737867,
                    222.63594269368355
                ],
                [
                    118.58106467399398,
                    220.93041901026214
                ],
                [
                    119.97501205230265,
                    220.68665734207815
                ],
                [
                    121.5713066306887,
                    220.11037835966232
                ],
                [
                    124.26926929838328,
                    220.77530129452293
                ],
                [
                    125.23603925430712,
                    220.68665734207815
                ],
                [
                    126.31522432138513,
                    219.71132114683058
                ],
                [
                    126.78736778823145,
                    220.97473620742227
                ],
                [
                    128.63097561115615,
                    222.17093986645227
                ],
                [
                    129.80009276715737,
                    221.94947308761192
                ],
                [
                    130.4071343673886,
                    220.64233393146185
                ],
                [
                    129.53029650038775,
                    219.66697666109485
                ],
                [
                    129.08063605577195,
                    218.2918209233198
                ],
                [
                    129.26050023361813,
                    217.0267475314228
                ],
                [
                    128.85580583346427,
                    215.44985411948062
                ],
                [
                    129.50781347815723,
                    215.20543711093757
                ],
                [
                    131.21652316769678,
                    216.6270874483855
                ],
                [
                    132.5655045015444,
                    215.0721063241832
                ],
                [
                    132.04839499023615,
                    212.62615013878042
                ],
                [
                    130.7668627230812,
                    211.80275261269435
                ],
                [
                    129.41788138923403,
                    212.0475816528899
                ],
                [
                    128.67594165561786,
                    211.02355272093314
                ],
                [
                    130.81182876754292,
                    210.02127966223958
                ],
                [
                    129.50781347815723,
                    208.52807627351444
                ],
                [
                    129.7326437004649,
                    207.2345499877598
                ],
                [
                    131.55376850115886,
                    206.67673630965936
                ],
                [
                    132.81281774608283,
                    207.01144328844862
                ],
                [
                    134.11683303546852,
                    206.09644397322825
                ],
                [
                    135.5107804137774,
                    206.0294762520095
                ],
                [
                    136.14030503623962,
                    206.5874715974225
                ],
                [
                    137.33190521447136,
                    205.96250627111868
                ],
                [
                    137.60170148124098,
                    204.26517817618077
                ],
                [
                    136.9496938365478,
                    201.22420824429963
                ],
                [
                    136.16278805847037,
                    200.88851913092162
                ],
                [
                    134.99367090246915,
                    201.73882010620457
                ],
                [
                    134.83628974685394,
                    202.72291982042339
                ],
                [
                    132.76785170162134,
                    202.43221430333296
                ],
                [
                    130.74437970085046,
                    203.28184836137234
                ],
                [
                    129.44036441146477,
                    202.499304001376
                ],
                [
                    128.04641703315588,
                    202.70055938102786
                ],
                [
                    126.6974356993087,
                    202.2309314876798
                ],
                [
                    125.5732845877692,
                    201.2689624551254
                ],
                [
                    124.42665045399872,
                    199.3212092357315
                ],
                [
                    124.24678627615253,
                    196.9903102884008
                ],
                [
                    124.87631089861475,
                    194.252419556946
                ],
                [
                    126.29274129915439,
                    193.10674233294566
                ],
                [
                    127.37192636623195,
                    193.21909370751172
                ],
                [
                    128.42862841107922,
                    192.5224095842063
                ],
                [
                    129.3729153447723,
                    191.08329564260998
                ],
                [
                    130.0923720561575,
                    190.09327874529941
                ],
                [
                    131.5312854789281,
                    189.48551499157264
                ],
                [
                    133.30744423516035,
                    188.11170730662718
                ],
                [
                    135.53326343600838,
                    187.2328525180992
                ],
                [
                    137.06210894770197,
                    185.6318430311929
                ],
                [
                    137.9839128591641,
                    185.00008755688083
                ],
                [
                    139.46779232639642,
                    184.86468397843328
                ],
                [
                    142.50300032755263,
                    182.78728088915636
                ],
                [
                    143.64963446132288,
                    182.58393387283974
                ],
                [
                    144.8412346395546,
                    180.79805570744838
                ],
                [
                    146.25766504009448,
                    180.2099269173329
                ],
                [
                    147.56168032948017,
                    180.52663455691493
                ],
                [
                    147.89892566294202,
                    178.55765479531544
                ],
                [
                    149.51770326355881,
                    177.94617042733995
                ],
                [
                    151.51869224209872,
                    177.33448564016356
                ],
                [
                    152.05828477563773,
                    178.19531785965967
                ],
                [
                    154.26162095425502,
                    179.0783910963238
                ],
                [
                    157.7240063777965,
                    178.98783867777342
                ],
                [
                    159.07298771164392,
                    180.16467860276208
                ],
                [
                    161.05149366795308,
                    180.59449352444204
                ],
                [
                    160.80418042341466,
                    182.1093721422248
                ],
                [
                    162.94006753533927,
                    184.39069518220964
                ],
                [
                    162.98503357980098,
                    186.06041443306088
                ],
                [
                    160.87162949010667,
                    188.3820426680805
                ],
                [
                    161.11894273464554,
                    190.25081563943877
                ],
                [
                    160.1971388231832,
                    191.15077819093682
                ],
                [
                    160.03975766756753,
                    192.38753838713353
                ],
                [
                    159.27533491172085,
                    193.03932836551178
                ],
                [
                    157.47669313325787,
                    196.18280999678103
                ],
                [
                    158.1736668224121,
                    197.81989188029127
                ],
                [
                    156.8022024663344,
                    198.0664561097849
                ],
                [
                    155.9253645993333,
                    198.80596179442637
                ],
                [
                    152.8901565981771,
                    198.60430622092792
                ],
                [
                    152.41801313133033,
                    199.25401075259998
                ],
                [
                    152.37304708686884,
                    200.14980075221837
                ],
                [
                    152.05828477563773,
                    200.68707808871193
                ],
                [
                    151.47372619763723,
                    200.55277256290827
                ],
                [
                    151.24889597532956,
                    200.86613781456367
                ],
                [
                    151.24889597532956,
                    201.13469676161787
                ],
                [
                    151.3163450420218,
                    202.566391413337
                ],
                [
                    151.9908357089455,
                    204.55561380279602
                ],
                [
                    152.82270753148464,
                    205.9178583615714
                ],
                [
                    151.74352246440685,
                    206.2973335838446
                ],
                [
                    151.20392993086784,
                    206.52052043187786
                ],
                [
                    151.1814469086371,
                    206.78831156297593
                ],
                [
                    151.33882806425277,
                    207.7029926782419
                ],
                [
                    148.77576352994265,
                    209.909885047366
                ],
                [
                    149.04555979671227,
                    210.4667962001878
                ],
                [
                    150.30460904163624,
                    213.3602479754668
                ],
                [
                    150.1921939304823,
                    216.80472384052575
                ],
                [
                    151.6760733977144,
                    218.0255548696034
                ],
                [
                    151.0915148197139,
                    218.77988523697832
                ],
                [
                    151.96835268671475,
                    219.82217816381473
                ],
                [
                    153.63209633179304,
                    217.75925407821552
                ],
                [
                    154.8686625544865,
                    218.358382012636
                ],
                [
                    156.5773722440265,
                    220.39853807261665
                ],
                [
                    158.10621775571985,
                    221.17415177389626
                ],
                [
                    160.53438415664505,
                    218.44712675831806
                ],
                [
                    160.8266634456454,
                    217.33754003906057
                ],
                [
                    160.7367313567222,
                    216.93794096062277
                ],
                [
                    159.7025123341059,
                    216.64929284551118
                ],
                [
                    160.26458788987543,
                    215.22765805627142
                ],
                [
                    159.23036886725913,
                    213.7160781042428
                ],
                [
                    157.29682895541146,
                    214.3830907794068
                ],
                [
                    157.7240063777965,
                    212.6706485249627
                ],
                [
                    157.56662522218107,
                    210.17722171690622
                ],
                [
                    156.19516086610292,
                    209.5533806263865
                ],
                [
                    155.54315322140997,
                    208.59495999037682
                ],
                [
                    155.65556833256414,
                    206.7436822131175
                ],
                [
                    154.46396815433218,
                    204.44391284074004
                ],
                [
                    153.78947748740848,
                    203.41596763802636
                ],
                [
                    155.94784762156405,
                    201.67170446403247
                ],
                [
                    157.63407428887353,
                    202.16383264027468
                ],
                [
                    157.00454966641132,
                    200.4632304332613
                ],
                [
                    159.25285188948988,
                    199.52279082718655
                ],
                [
                    160.10720673426,
                    199.9034993806925
                ],
                [
                    159.18540282279764,
                    201.6269594291178
                ],
                [
                    160.3770030010296,
                    202.29802804698835
                ],
                [
                    159.05050468941317,
                    204.86834294836825
                ],
                [
                    159.18540282279764,
                    205.33734405868904
                ],
                [
                    159.9273425564138,
                    204.53327411505444
                ],
                [
                    161.88336549049222,
                    204.28752089294233
                ],
                [
                    162.87261846864703,
                    206.11876604490465
                ],
                [
                    164.55884513595606,
                    206.0517990767994
                ],
                [
                    165.7054792697263,
                    205.47132398487923
                ],
                [
                    165.43568300295715,
                    204.9800199544856
                ],
                [
                    165.25581882511074,
                    204.91301450679902
                ],
                [
                    165.05347162503335,
                    203.95235361647195
                ],
                [
                    164.82864140272568,
                    203.46067203741575
                ],
                [
                    165.0759546472641,
                    202.6334765397201
                ],
                [
                    166.13265669211137,
                    202.54402919666137
                ],
                [
                    167.3692229148046,
                    203.77357448215753
                ],
                [
                    166.69473224788112,
                    205.136357181871
                ],
                [
                    166.9195624701888,
                    206.16340943527427
                ],
                [
                    168.178611715113,
                    206.60978815135434
                ],
                [
                    169.32524584888324,
                    205.11402404783905
                ],
                [
                    170.85409136057683,
                    205.9401824419195
                ],
                [
                    171.9557594498856,
                    205.62762245409385
                ],
                [
                    173.30474078373277,
                    206.2973335838446
                ],
                [
                    173.52957100604067,
                    204.7566596413032
                ],
                [
                    176.13760158481227,
                    202.34275781561223
                ],
                [
                    177.19430362965932,
                    202.20856545946924
                ],
                [
                    178.90301331919932,
                    201.44796909848702
                ],
                [
                    180.29696069750798,
                    201.58221337525544
                ],
                [
                    180.22951163081575,
                    203.2594942619557
                ],
                [
                    181.60097598689367,
                    203.95235361647195
                ],
                [
                    181.98318736481724,
                    205.06935702425443
                ],
                [
                    180.83655323104722,
                    205.8955340300563
                ],
                [
                    179.93723234181562,
                    206.94450639939396
                ],
                [
                    179.53253794166108,
                    207.5022450183834
                ],
                [
                    180.67917207543155,
                    207.68068838170717
                ],
                [
                    181.55600994243218,
                    206.7659970132791
                ],
                [
                    182.68016105397146,
                    208.2828168009213
                ],
                [
                    183.01740638743354,
                    207.2345499877598
                ],
                [
                    184.36638772128072,
                    206.43124870171494
                ],
                [
                    186.16502949974392,
                    206.83293991104978
                ],
                [
                    186.29992763312862,
                    205.98482984918786
                ],
                [
                    188.07608638936085,
                    204.86834294836825
                ],
                [
                    188.57071287843837,
                    206.83293991104978
                ],
                [
                    187.8737391892837,
                    207.36840200246638
                ],
                [
                    188.30091661166875,
                    209.0630832999944
                ],
                [
                    189.55996585659295,
                    208.43889449815975
                ],
                [
                    190.8639811459784,
                    208.66184146665967
                ],
                [
                    192.1230303909026,
                    209.46424459755315
                ],
                [
                    192.7075889689031,
                    209.30824698927938
                ],
                [
                    194.23643448059693,
                    207.03375508425228
                ],
                [
                    194.43878168067408,
                    205.20335507314826
                ],
                [
                    196.1250083479831,
                    205.7615827654164
                ],
                [
                    197.69881990413842,
                    205.49365309208832
                ],
                [
                    198.30586150436966,
                    204.84600679110542
                ],
                [
                    199.56491074929363,
                    205.2926820703011
                ],
                [
                    200.1719523495251,
                    206.8998805551363
                ],
                [
                    202.0155601724498,
                    207.83681321461017
                ],
                [
                    203.47695661745092,
                    208.08212753183136
                ],
                [
                    204.21889635106686,
                    210.39997503206564
                ],
                [
                    206.4447155519149,
                    210.86767646549896
                ],
                [
                    208.24335733037788,
                    211.2462120963409
                ],
                [
                    209.43495750860984,
                    214.27193727601372
                ],
                [
                    211.21111626484208,
                    214.4720091863975
                ],
                [
                    211.21111626484208,
                    213.53817087638868
                ],
                [
                    212.7624447987664,
                    213.13782229486714
                ],
                [
                    214.9208149329222,
                    212.80413778964464
                ],
                [
                    215.46040746646122,
                    213.7827892758766
                ],
                [
                    216.42717742238506,
                    213.69384055756348
                ],
                [
                    216.78690577807788,
                    214.7609670389707
                ],
                [
                    216,
                    216.0496619980794
                ],
                [
                    215.5503395553842,
                    217.93679180041983
                ],
                [
                    216.38221137792334,
                    218.09212463847297
                ],
                [
                    216,
                    221.39570195115408
                ],
                [
                    214.83088284399923,
                    221.99376834726996
                ],
                [
                    217.01173600038555,
                    224.67186558224176
                ],
                [
                    218.99024195669494,
                    226.13118834659565
                ],
                [
                    221.10364604638903,
                    226.13118834659565
                ],
                [
                    222.8123557359288,
                    225.31320940126614
                ],
                [
                    224.610997514392,
                    222.41452584882916
                ],
                [
                    226.11736000385486,
                    222.6802232120328
                ],
                [
                    227.33144320431734,
                    224.36218035573995
                ],
                [
                    228.16331502685648,
                    226.2638032816111
                ],
                [
                    230.1418209831661,
                    226.94884599338133
                ],
                [
                    231.15355698355143,
                    230.32657898710443
                ],
                [
                    233.55924036224587,
                    230.98824255043223
                ],
                [
                    234.7058744960159,
                    229.92948093797713
                ],
                [
                    234.68339147378515,
                    232.02443131085795
                ],
                [
                    233.91896871793847,
                    233.91912222297583
                ],
                [
                    235.56022934078578,
                    234.75577223713685
                ],
                [
                    235.020636807247,
                    237.35169797588736
                ],
                [
                    235.40284818517057,
                    238.27491142164945
                ],
                [
                    237.0890748524796,
                    240.405579728637
                ],
                [
                    239.96690169802037,
                    240.4275344081202
                ],
                [
                    240.30414703148222,
                    241.23970066169045
                ],
                [
                    239.22496196440443,
                    242.46834553154054
                ],
                [
                    238.77530151978885,
                    243.71821626484558
                ],
                [
                    237.96591271948046,
                    245.01118655356788
                ],
                [
                    236.70686347455626,
                    245.88734116252078
                ],
                [
                    235.6951274741707,
                    245.86544157465636
                ],
                [
                    234.6159424070927,
                    246.8944856115864
                ],
                [
                    234.63842542932343,
                    247.61671758132377
                ],
                [
                    235.1555349406317,
                    248.55745196428205
                ],
                [
                    234.6159424070927,
                    250.10987980142187
                ],
                [
                    232.4575722729371,
                    250.54698672957738
                ],
                [
                    231.5582513837055,
                    251.33356204939224
                ],
                [
                    228.99518684939585,
                    252.7748939746333
                ],
                [
                    227.66868853777942,
                    255.28408333778236
                ],
                [
                    227.69117156001016,
                    256.54852293384806
                ],
                [
                    228.45559431585684,
                    258.7704734776293
                ],
                [
                    226.5445374262399,
                    260.79447196891806
                ],
                [
                    226.92674880416303,
                    261.64271017786166
                ],
                [
                    225.39790329246944,
                    262.0123564274094
                ],
                [
                    224.72341262554596,
                    263.9031417994257
                ],
                [
                    225.62273351477756,
                    265.423334526894
                ],
                [
                    225.53280142585436,
                    266.4000725832204
                ],
                [
                    223.8016087140836,
                    265.8574912889921
                ],
                [
                    223.7566426696219,
                    265.8574912889921
                ],
                [
                    223.7116766251604,
                    265.8574912889921
                ],
                [
                    222.58752551362113,
                    269.60955774234714
                ],
                [
                    220.90129884631187,
                    270.0429330249793
                ],
                [
                    219.26003822346433,
                    269.046049627439
                ],
                [
                    214.4711544883064,
                    269.37116706610857
                ],
                [
                    214.44867146607567,
                    270.4112388816923
                ],
                [
                    213.30203733230542,
                    272.64150314787855
                ],
                [
                    212.24533528745815,
                    273.2474232625957
                ],
                [
                    211.81815786507332,
                    274.5453003510488
                ],
                [
                    210.04199910884108,
                    273.7882919559541
                ],
                [
                    210.24434630891824,
                    276.51239235588304
                ],
                [
                    210.60407466461083,
                    277.59252076759645
                ],
                [
                    209.9520670199181,
                    278.6937492397244
                ],
                [
                    211.5033955538422,
                    280.700572186207
                ],
                [
                    210.9413199980727,
                    282.166870351132
                ],
                [
                    211.2560823093038,
                    284.30004424326154
                ],
                [
                    210.73897279799576,
                    285.225992786545
                ],
                [
                    210.91883697584194,
                    286.62501767972105
                ],
                [
                    215.61778862207666,
                    287.6791405394448
                ],
                [
                    215.30302631084555,
                    290.1513354182346
                ],
                [
                    213.79666382138294,
                    291.01065172405964
                ],
                [
                    212.8748599099206,
                    289.3991896632078
                ],
                [
                    211.6158106649964,
                    288.9047981208613
                ],
                [
                    210.1319311977643,
                    290.06538744305016
                ],
                [
                    209.007780086225,
                    287.89421221684734
                ],
                [
                    207.77121386353156,
                    287.7436640129897
                ],
                [
                    206.0849871962223,
                    286.60350026242526
                ],
                [
                    205.32056444037562,
                    286.7326019392257
                ],
                [
                    204.26386239552858,
                    285.85027178905193
                ],
                [
                    203.22964337291228,
                    286.689568800756
                ],
                [
                    203.22964337291228,
                    286.71108546420237
                ],
                [
                    202.77998292829625,
                    288.25979103151303
                ],
                [
                    203.1397112839893,
                    289.03377934799676
                ],
                [
                    204.6011077289902,
                    289.7000753384096
                ],
                [
                    204.84842097352907,
                    290.6025135068488
                ],
                [
                    206.01753812953007,
                    290.7743693372183
                ],
                [
                    205.92760604060686,
                    293.3078682953254
                ],
                [
                    205.47794559599083,
                    295.1955914589453
                ],
                [
                    206.5346476408381,
                    295.04548347555584
                ],
                [
                    206.91685901876144,
                    296.4175673234107
                ],
                [
                    207.8386629302238,
                    296.43900022765195
                ],
                [
                    207.43396853006948,
                    299.9515537585971
                ],
                [
                    208.08597617476266,
                    301.214036139142
                ],
                [
                    208.67053475276293,
                    304.3568553860261
                ],
                [
                    207.6587987523776,
                    304.6132437698453
                ],
                [
                    207.389002485608,
                    306.38590611835997
                ],
                [
                    206.39974950745318,
                    307.17574167819885
                ],
                [
                    203.58937172860487,
                    308.3707163557692
                ],
                [
                    203.7467528842203,
                    309.7783835813269
                ],
                [
                    201.67831483898772,
                    312.1228339647078
                ],
                [
                    201.38603554998758,
                    313.1239244569379
                ],
                [
                    200.0370542161404,
                    313.23040138013846
                ],
                [
                    198.148480348754,
                    314.89089405277133
                ],
                [
                    198.08103128206176,
                    316.2313134453493
                ],
                [
                    197.4290236373688,
                    317.80492631959953
                ],
                [
                    197.45150665959954,
                    318.95266352806374
                ],
                [
                    196.2374234591373,
                    320.2486013062439
                ],
                [
                    196.19245741467557,
                    321.41654480746115
                ],
                [
                    194.25891750282767,
                    321.52269689282633
                ],
                [
                    192.79752105782632,
                    320.80078193469564
                ],
                [
                    191.60592087959458,
                    321.7349887910929
                ]
            ],
            [
                [
                    63.27282998625424,
                    259.2058942490245
                ],
                [
                    62.59833931933076,
                    258.530956389162
                ],
                [
                    62.328543052561145,
                    256.5267282715989
                ],
                [
                    63.542626253023855,
                    255.54574990222073
                ],
                [
                    64.4644301644862,
                    257.1586879055543
                ],
                [
                    63.27282998625424,
                    259.2058942490245
                ]
            ],
            [
                [
                    149.6526013969435,
                    398.53536410504523
                ],
                [
                    149.9673637081744,
                    397.0221902666455
                ],
                [
                    150.84420157517548,
                    397.0429227523314
                ],
                [
                    151.47372619763723,
                    395.81951134430466
                ],
                [
                    153.114986820485,
                    395.30099678162685
                ],
                [
                    153.81196050963945,
                    393.9940217586318
                ],
                [
                    154.8686625544865,
                    390.75572264400955
                ],
                [
                    152.77774148702315,
                    390.2572700909077
                ],
                [
                    151.60862433102193,
                    389.4471397840182
                ],
                [
                    151.74352246440685,
                    387.2235473425733
                ],
                [
                    152.14821686456094,
                    386.0384577247579
                ],
                [
                    151.0465487752524,
                    385.9344838387466
                ],
                [
                    149.13549188563525,
                    386.3295685717369
                ],
                [
                    149.2479069967892,
                    385.41456912690865
                ],
                [
                    148.03382379632671,
                    384.1040481801888
                ],
                [
                    148.10127286301918,
                    383.02198419484705
                ],
                [
                    147.9438917074035,
                    381.8771328332061
                ],
                [
                    146.25766504009448,
                    381.73139777365213
                ],
                [
                    145.80800459547868,
                    380.4195096506329
                ],
                [
                    143.51473632793818,
                    381.0859269238159
                ],
                [
                    143.04259286109186,
                    382.5432733933859
                ],
                [
                    140.6818755268589,
                    383.3133416305616
                ],
                [
                    138.2537091259337,
                    384.6033510946153
                ],
                [
                    138.45605632601087,
                    383.45901135338335
                ],
                [
                    137.91646379247186,
                    382.1685848352115
                ],
                [
                    136.34265223631655,
                    382.31430178688527
                ],
                [
                    134.20676512439172,
                    381.7730369787499
                ],
                [
                    133.86951979092987,
                    382.7306027416779
                ],
                [
                    132.49805543485195,
                    383.68790881622556
                ],
                [
                    131.21652316769678,
                    382.4183816336855
                ],
                [
                    132.1608101013901,
                    380.81521021936544
                ],
                [
                    130.5420325007733,
                    379.3363100909173
                ],
                [
                    130.78934574531195,
                    378.35697376036944
                ],
                [
                    130.0024399672343,
                    377.2522829787066
                ],
                [
                    131.59873454562035,
                    376.7936280296344
                ],
                [
                    131.95846290131294,
                    378.8570949621379
                ],
                [
                    134.09435001323777,
                    379.60714157403197
                ],
                [
                    136.27520316962455,
                    379.3363100909173
                ],
                [
                    137.39935428116382,
                    381.2108660373848
                ],
                [
                    137.9389468147026,
                    380.58612587672064
                ],
                [
                    137.28693917000987,
                    379.5446438732567
                ],
                [
                    137.33190521447136,
                    377.1480485892632
                ],
                [
                    138.95068281508816,
                    377.4190514364095
                ],
                [
                    138.59095445939533,
                    375.3755793407373
                ],
                [
                    137.55673543677926,
                    374.68719645370254
                ],
                [
                    136.9047277920863,
                    375.6258659424137
                ],
                [
                    133.57724050192974,
                    374.68719645370254
                ],
                [
                    133.26247819069863,
                    376.3766158297702
                ],
                [
                    131.6212175678511,
                    376.23064957898316
                ],
                [
                    129.84505881161886,
                    374.52029479042596
                ],
                [
                    130.0923720561575,
                    372.37070143247956
                ],
                [
                    128.6984246778486,
                    371.7443486907565
                ],
                [
                    128.09138307761737,
                    370.6583935357795
                ],
                [
                    128.2487642332328,
                    369.4676139383835
                ],
                [
                    129.14808512246418,
                    367.7956239753053
                ],
                [
                    129.5977455670802,
                    365.0768293271419
                ],
                [
                    128.02393401092513,
                    364.7839012913978
                ],
                [
                    128.6084925889254,
                    363.1513903062983
                ],
                [
                    131.32893827885096,
                    364.386313951856
                ],
                [
                    133.21751214623714,
                    364.0933237531512
                ],
                [
                    133.84703676869913,
                    362.48140410815665
                ],
                [
                    133.39737632408355,
                    361.1619615566112
                ],
                [
                    133.7795877020069,
                    360.15630771551014
                ],
                [
                    132.4755724126212,
                    359.61144639814796
                ],
                [
                    132.02591196800518,
                    357.9763024069549
                ],
                [
                    132.00342894577443,
                    354.93436063488946
                ],
                [
                    132.76785170162134,
                    354.55653438234856
                ],
                [
                    131.9359798790822,
                    351.8474359357862
                ],
                [
                    132.6104705460059,
                    350.7337075076276
                ],
                [
                    135.19601810254653,
                    349.8928904961221
                ],
                [
                    139.1080639707036,
                    350.4604672499446
                ],
                [
                    141.24395108262866,
                    351.11200007350715
                ],
                [
                    143.35735517232274,
                    351.28011517464984
                ],
                [
                    144.59392139501597,
                    350.79675950067804
                ],
                [
                    147.47174824055696,
                    351.78440544875787
                ],
                [
                    148.82072957440437,
                    351.5322706229566
                ],
                [
                    149.89991464148216,
                    349.78777215339574
                ],
                [
                    150.0123297526361,
                    349.22007068610407
                ],
                [
                    152.28311499794563,
                    348.2105628873989
                ],
                [
                    154.35155304317823,
                    348.6943270914546
                ],
                [
                    155.09349277679416,
                    348.0633150405487
                ],
                [
                    157.13944779979602,
                    349.22007068610407
                ],
                [
                    159.81492744525985,
                    347.4742522919842
                ],
                [
                    159.54513117849024,
                    346.5483509830394
                ],
                [
                    161.38873900141493,
                    345.66427143710615
                ],
                [
                    163.27731286880135,
                    346.1484423211059
                ],
                [
                    164.10918469134026,
                    345.89584112635896
                ],
                [
                    164.04173562464803,
                    343.8953325795894
                ],
                [
                    164.17663375803272,
                    341.5140359870794
                ],
                [
                    164.73870931380247,
                    340.691729147672
                ],
                [
                    167.0994266480352,
                    340.4597550962666
                ],
                [
                    167.52660407042026,
                    339.8691933851028
                ],
                [
                    168.92055144872916,
                    339.6582498020447
                ],
                [
                    170.94402344950004,
                    338.7299182075236
                ],
                [
                    172.60776709457832,
                    338.24453705008614
                ],
                [
                    173.84433331727178,
                    338.66661218067463
                ],
                [
                    174.90103536211882,
                    339.616059271383
                ],
                [
                    176.4748469182739,
                    337.9490482492745
                ],
                [
                    176.72216016281277,
                    336.8301412106184
                ],
                [
                    176.22753367373548,
                    335.28830040873225
                ],
                [
                    176.4748469182739,
                    334.46424086335946
                ],
                [
                    178.4083868301218,
                    333.7033616280257
                ],
                [
                    180.1620625641233,
                    334.4219750622625
                ],
                [
                    180.3868927864312,
                    335.8797882304449
                ],
                [
                    184.0291423878191,
                    337.59020026646783
                ],
                [
                    183.9392102988959,
                    336.5978613900968
                ],
                [
                    184.5012858546654,
                    335.2671736099114
                ],
                [
                    183.51203287651083,
                    334.612166293502
                ],
                [
                    180.99393438666243,
                    334.37970864207193
                ],
                [
                    183.08485545412577,
                    332.96342501683444
                ],
                [
                    184.1865235434343,
                    331.67336474516026
                ],
                [
                    185.9401992774358,
                    332.73083412978826
                ],
                [
                    188.07608638936085,
                    333.4074098421323
                ],
                [
                    188.54822985620763,
                    332.94228116988154
                ],
                [
                    188.59319590066912,
                    331.5464421368405
                ],
                [
                    189.49251678990072,
                    330.6578257081953
                ],
                [
                    190.27942256797814,
                    331.081010939613
                ],
                [
                    192.90993616898027,
                    330.46737182676316
                ],
                [
                    193.1572494135189,
                    329.0279739105419
                ],
                [
                    194.3938156362126,
                    329.13383689590773
                ],
                [
                    194.91092514752063,
                    327.71494317432547
                ],
                [
                    190.66163394590149,
                    326.019804223012
                ],
                [
                    189.04285634528492,
                    324.19638368209445
                ],
                [
                    188.6831279895921,
                    322.69010020238863
                ],
                [
                    189.96466025674727,
                    322.0109438162972
                ],
                [
                    191.22370950167124,
                    322.7325419347086
                ],
                [
                    191.60592087959458,
                    321.7349887910929
                ],
                [
                    190.59418487920925,
                    321.77744520815463
                ],
                [
                    189.91969421228555,
                    317.8687021941131
                ],
                [
                    190.05459234567002,
                    316.0398657125112
                ],
                [
                    188.39084870059196,
                    316.44401725113886
                ],
                [
                    188.18850150051458,
                    315.42288623743144
                ],
                [
                    186.77207109997494,
                    315.97604678746836
                ],
                [
                    186.6596559888212,
                    313.8265939175697
                ],
                [
                    186.030131366359,
                    311.8032579175971
                ],
                [
                    185.01839536597345,
                    310.5032530175455
                ],
                [
                    182.3429157205096,
                    308.8400222210896
                ],
                [
                    181.12883252004713,
                    309.13862791742974
                ],
                [
                    179.71240211950771,
                    307.45319497145044
                ]
            ],
            [
                [
                    173.46212193934844,
                    425.20125313055644
                ],
                [
                    173.4396389171177,
                    427.5684433389141
                ]
            ],
            [
                [
                    73.52508812349402,
                    465.65431637956203
                ],
                [
                    73.36770696787835,
                    465.79728967017735
                ],
                [
                    73.00797861218575,
                    465.6134662268048
                ],
                [
                    73.52508812349402,
                    465.65431637956203
                ]
            ],
            [
                [
                    70.22008385556796,
                    281.73569844657766
                ],
                [
                    70.33249896672191,
                    280.39857675239057
                ],
                [
                    71.07443870033785,
                    280.2691385576315
                ]
            ],
            [
                [
                    43.330389267545115,
                    275.8640803389601
                ],
                [
                    42.993143934083264,
                    276.4907847984798
                ],
                [
                    42.16127211154412,
                    275.8640803389601
                ]
            ],
            [
                [
                    399.64132558107985,
                    180.4135308237888
                ],
                [
                    397.1007440690007,
                    184.2326725297292
                ],
                [
                    398.0000649582323,
                    185.90253096540033
                ],
                [
                    397.9101328693091,
                    189.8456945843066
                ],
                [
                    398.92186886969466,
                    190.40833958140684
                ],
                [
                    398.0450310026938,
                    191.8254732951113
                ],
                [
                    396.40377037984626,
                    193.39884229375588
                ],
                [
                    396.7859817577696,
                    195.82381316015983
                ],
                [
                    395.257136246076,
                    197.03516157986718
                ],
                [
                    394.47023046799836,
                    198.357809996968
                ],
                [
                    393.36856237868983,
                    199.88110681022363
                ],
                [
                    391.4125394446112,
                    200.6646944733265
                ],
                [
                    389.63638068837895,
                    199.70195702180837
                ],
                [
                    389.59141464391746,
                    198.4698575957417
                ],
                [
                    388.6696107324551,
                    198.0664561097849
                ],
                [
                    387.61290868760807,
                    199.1868099589674
                ],
                [
                    386.2189613092992,
                    199.7243516423776
                ],
                [
                    384.55521766422066,
                    199.8363209006764
                ],
                [
                    384.2854213974513,
                    198.9851937099886
                ],
                [
                    383.0713381969888,
                    199.4108036200027
                ],
                [
                    381.5874587297567,
                    198.6715270601573
                ],
                [
                    380.5532397071404,
                    198.80596179442637
                ],
                [
                    378.0351412172922,
                    199.14200814601628
                ],
                [
                    378.6196997952925,
                    200.3736842122609
                ],
                [
                    376.5962277945216,
                    202.85707712717016
                ],
                [
                    375.69690690529,
                    204.66730845741046
                ],
                [
                    374.16806139359664,
                    203.95235361647195
                ],
                [
                    373.69591792674987,
                    202.9241523551957
                ]
            ],
            [
                [
                    274.56827291120385,
                    252.57840366039642
                ],
                [
                    276.1196014451282,
                    250.83105999601986
                ],
                [
                    276.2994656229746,
                    249.65082459930676
                ],
                [
                    275.10786544474263,
                    249.23540678551217
                ],
                [
                    275.64745797828164,
                    248.25121047799308
                ],
                [
                    275.1303484669734,
                    246.85070634133768
                ],
                [
                    273.5565369108185,
                    246.85070634133768
                ],
                [
                    273.30922366627965,
                    245.55882432749638
                ],
                [
                    272.09514046581717,
                    244.66062630068006
                ],
                [
                    270.02670242058457,
                    243.43322129176278
                ],
                [
                    270.2740156651232,
                    241.2616468650195
                ],
                [
                    271.2407856210473,
                    240.18602064442905
                ],
                [
                    267.41867184181297,
                    240.14210614537546
                ],
                [
                    264.5183619740412,
                    237.76939141161546
                ],
                [
                    262.18012766203947,
                    237.41765498687136
                ],
                [
                    261.21335770611563,
                    235.79012120680818
                ],
                [
                    262.1576446398087,
                    234.49160255690322
                ],
                [
                    261.7979162841159,
                    233.21431896631873
                ],
                [
                    262.18012766203947,
                    232.35502281608967
                ],
                [
                    264.4509129073492,
                    232.11259410308043
                ],
                [
                    265.0804375298112,
                    231.0323460631859
                ],
                [
                    266.2045886413507,
                    231.14260080664485
                ],
                [
                    266.4743849081199,
                    232.57538843244515
                ],
                [
                    268.4304078421985,
                    233.5006735451497
                ],
                [
                    269.2397966425069,
                    231.5835621281135
                ],
                [
                    271.0159553987394,
                    229.9074177354044
                ],
                [
                    274.34344268889595,
                    228.89225968643308
                ],
                [
                    275.64745797828164,
                    228.89225968643308
                ],
                [
                    276.029669356205,
                    227.83243980999953
                ],
                [
                    275.64745797828164,
                    226.72788907314225
                ],
                [
                    277.3336846455909,
                    225.954355289621
                ],
                [
                    279.8293001132083,
                    227.0814089104237
                ],
                [
                    280.4813077579013,
                    226.41851003224383
                ],
                [
                    282.01015326959487,
                    226.37430927608494
                ],
                [
                    282.90947415882624,
                    225.6448612694977
                ],
                [
                    282.01015326959487,
                    223.6541545501911
                ],
                [
                    283.5165157590577,
                    223.4992416180525
                ],
                [
                    285.0453612707513,
                    224.34005821213282
                ],
                [
                    287.8557390495996,
                    221.41785565680897
                ],
                [
                    287.9681541607538,
                    219.9551986717517
                ],
                [
                    288.95740713890837,
                    219.86651929204368
                ],
                [
                    290.3513545172175,
                    217.89240881806478
                ],
                [
                    290.23893940606354,
                    214.64983428338735
                ],
                [
                    291.65536980660295,
                    214.9387667664539
                ],
                [
                    291.92516607337257,
                    212.40364346664177
                ],
                [
                    290.913430072987,
                    212.02532569834705
                ],
                [
                    291.6778528288339,
                    210.24405031093966
                ],
                [
                    292.68958882921925,
                    209.17452506761623
                ],
                [
                    292.59965674029627,
                    208.327411674482
                ],
                [
                    290.2614224282943,
                    206.4758850682062
                ],
                [
                    290.0365922059864,
                    205.5606389046763
                ],
                [
                    290.01410918375564,
                    203.840618554123
                ],
                [
                    289.0248562056008,
                    203.70652813366212
                ],
                [
                    289.7892789614475,
                    203.59477915918285
                ],
                [
                    290.5986677617559,
                    203.97469987046992
                ],
                [
                    291.02584518414096,
                    203.05829596306438
                ],
                [
                    290.1040412726786,
                    202.36512231870518
                ],
                [
                    289.85672802813997,
                    201.13469676161787
                ],
                [
                    291.3181244731411,
                    199.8139275615602
                ],
                [
                    292.68958882921925,
                    199.14200814601628
                ],
                [
                    294.26340038537455,
                    200.03784942442138
                ],
                [
                    293.5889097184506,
                    198.20093254423512
                ],
                [
                    294.0160871408359,
                    196.49687777229695
                ],
                [
                    292.8020039403732,
                    196.093067013952
                ],
                [
                    291.70033585106466,
                    193.803215400044
                ],
                [
                    290.7335658951406,
                    193.39884229375588
                ],
                [
                    290.66611682844837,
                    192.65727133490464
                ],
                [
                    291.02584518414096,
                    191.73552763467018
                ],
                [
                    290.935913095218,
                    191.66806562720058
                ],
                [
                    291.11577727306417,
                    191.12828427175089
                ],
                [
                    291.0708112286027,
                    190.97081945604486
                ],
                [
                    292.9144190515274,
                    189.26036830341496
                ],
                [
                    294.35333247429776,
                    188.74243025542103
                ],
                [
                    295.4325175413753,
                    187.50331256193084
                ],
                [
                    297.38854047545396,
                    186.1957326708128
                ],
                [
                    296.8039818974537,
                    184.09721403232572
                ],
                [
                    294.89292500783677,
                    182.62912399451233
                ],
                [
                    295.6348647414525,
                    181.15988955241363
                ],
                [
                    296.01707611937604,
                    178.94256082447095
                ],
                [
                    295.050306163452,
                    175.99714173838458
                ],
                [
                    293.1617322960658,
                    173.38768315540204
                ],
                [
                    293.8362229629895,
                    171.54750449671536
                ],
                [
                    294.6905778077594,
                    172.9789130780631
                ],
                [
                    295.54493265252927,
                    173.36497607844342
                ],
                [
                    297.4110234976847,
                    173.00162489949003
                ],
                [
                    299.524427587379,
                    174.00066887169754
                ],
                [
                    301.0982391435341,
                    172.2747079990349
                ],
                [
                    302.06500909945817,
                    172.07021101032637
                ],
                [
                    303.8861339001521,
                    172.88806299949783
                ],
                [
                    305.999537989846,
                    173.38768315540204
                ],
                [
                    306.04450403430747,
                    175.74770005683268
                ],
                [
                    306.8089267901544,
                    175.2714001696047
                ],
                [
                    309.2820592355413,
                    177.01723671916784
                ],
                [
                    309.304542257772,
                    177.81025780847506
                ],
                [
                    309.8666178135418,
                    178.37649511305597
                ],
                [
                    311.7327086586972,
                    178.35384891750977
                ],
                [
                    313.4863843926987,
                    177.5837147715933
                ],
                [
                    314.6555015486995,
                    178.51236652149305
                ],
                [
                    316.0269659047776,
                    178.2632613894408
                ],
                [
                    317.3309811941633,
                    179.28211801454836
                ],
                [
                    319.197072039319,
                    180.14205403599158
                ],
                [
                    320.50108732870444,
                    180.16467860276208
                ],
                [
                    320.6135024398584,
                    179.55371941397095
                ],
                [
                    323.4013971964764,
                    179.62161360815935
                ],
                [
                    325.537284308401,
                    178.91992148674427
                ],
                [
                    326.07687684194,
                    177.5157464942671
                ],
                [
                    327.6282053758646,
                    177.44777573925705
                ],
                [
                    328.8198055540963,
                    176.74526606037483
                ],
                [
                    330.70837942148273,
                    178.19531785965967
                ],
                [
                    333.2264779113307,
                    178.98783867777342
                ],
                [
                    336.6663803126414,
                    178.85200182902577
                ],
                [
                    338.17274280210427,
                    178.7840797040859
                ],
                [
                    338.8247504467972,
                    178.10472264063327
                ],
                [
                    340.8707054697986,
                    178.28590868338034
                ],
                [
                    342.3321019148002,
                    178.80672068656747
                ],
                [
                    343.38880395964725,
                    177.81025780847506
                ],
                [
                    344.67033622680196,
                    178.62558514816146
                ],
                [
                    346.5813931164191,
                    178.1273718575187
                ],
                [
                    347.72802725018914,
                    178.2632613894408
                ],
                [
                    350.51592200680716,
                    176.51859348949495
                ],
                [
                    351.8424203184236,
                    177.5157464942671
                ],
                [
                    352.9216053855016,
                    177.71964389532712
                ],
                [
                    353.75347720804075,
                    176.949247778193
                ],
                [
                    353.6185790746558,
                    175.0672342275334
                ],
                [
                    352.58436005203976,
                    174.1141624638558
                ],
                [
                    352.44946191865483,
                    172.5019002869775
                ],
                [
                    353.12395258557876,
                    170.61535511470396
                ],
                [
                    352.89912236327086,
                    168.56751307788642
                ],
                [
                    352.2920807630394,
                    167.08709050193585
                ],
                [
                    351.2128956959616,
                    167.56551202876574
                ],
                [
                    347.0085705388042,
                    167.20101176094283
                ],
                [
                    345.6820722271875,
                    165.51424978142933
                ],
                [
                    345.6146231604953,
                    164.69309736870974
                ],
                [
                    346.5813931164191,
                    163.50633234782367
                ],
                [
                    346.6488421831116,
                    161.907535239894
                ],
                [
                    349.52666902865235,
                    161.77042967585152
                ],
                [
                    350.336057828961,
                    163.23235314168426
                ],
                [
                    351.8873863628853,
                    162.43301019751112
                ],
                [
                    352.9216053855016,
                    162.61574829492355
                ],
                [
                    354.4054848527335,
                    161.31333591012793
                ],
                [
                    355.86688129773506,
                    160.9018524763885
                ],
                [
                    356.6313040535815,
                    162.27309921036692
                ],
                [
                    358.96953836558373,
                    161.67902018260446
                ],
                [
                    360.34100272166165,
                    161.58760606071945
                ],
                [
                    361.9372973000475,
                    160.28445112134392
                ],
                [
                    362.8815842337408,
                    159.00323929752477
                ],
                [
                    361.8248821888935,
                    157.49206501097768
                ],
                [
                    362.20709356681687,
                    156.50682565958732
                ],
                [
                    363.8708372118956,
                    155.24584337992928
                ],
                [
                    365.2647845902043,
                    155.19997267969188
                ],
                [
                    367.1533584575907,
                    153.54783994841722
                ],
                [
                    370.07615134759294,
                    152.9049290305806
                ],
                [
                    369.4691097473619,
                    151.38857336438866
                ],
                [
                    370.88554014790134,
                    150.19295309658827
                ],
                [
                    371.7848610371327,
                    147.91443352609042
                ],
                [
                    374.0331632602117,
                    147.17731080527108
                ],
                [
                    375.9442201498289,
                    147.63804875693927
                ],
                [
                    379.22674139552396,
                    146.78558842923786
                ],
                [
                    380.93545108506373,
                    146.69340574378674
                ],
                [
                    381.45256059637177,
                    144.54878920538607
                ],
                [
                    383.8132779306047,
                    142.63255022619722
                ],
                [
                    384.2404553529898,
                    141.22288384587006
                ],
                [
                    385.7693008646834,
                    139.39554654007213
                ],
                [
                    386.7810368650689,
                    139.30297181579255
                ],
                [
                    387.8602219321467,
                    138.05272738766092
                ],
                [
                    389.72631277730216,
                    138.02956617301504
                ],
                [
                    390.0185920663023,
                    137.14920940294525
                ],
                [
                    391.47998851130365,
                    136.36114018728517
                ],
                [
                    393.1437321563817,
                    138.0758882913767
                ],
                [
                    394.33533233461367,
                    138.79372220350137
                ],
                [
                    399.91112184784924,
                    140.04343095324793
                ],
                [
                    400.1584350923879,
                    141.03792467127755
                ],
                [
                    402.8788807823132,
                    141.2922384542278
                ],
                [
                    404.1154470050067,
                    138.30748023215165
                ],
                [
                    404.83490371639186,
                    138.23800591316729
                ],
                [
                    406.54361340593186,
                    136.84793142449104
                ],
                [
                    409.1741270069342,
                    135.3175517621517
                ],
                [
                    410.27579509624275,
                    135.45673352128586
                ],
                [
                    412.2093350080904,
                    134.13405066506897
                ],
                [
                    413.4908672752456,
                    135.7814470961356
                ],
                [
                    414.48012025339995,
                    137.96008066327965
                ],
                [
                    416.68345643201746,
                    137.61261111562084
                ],
                [
                    415.986482742863,
                    139.04836576457882
                ],
                [
                    414.0979088754766,
                    139.60382155584546
                ],
                [
                    413.10865589732225,
                    141.03792467127755
                ],
                [
                    414.5475693200924,
                    142.88663078098676
                ],
                [
                    415.986482742863,
                    141.38470695477963
                ],
                [
                    417.31298105447945,
                    141.2922384542278
                ],
                [
                    418.52706425494216,
                    142.42463853771574
                ],
                [
                    418.79686052171155,
                    143.87949927347097
                ],
                [
                    419.83107954432785,
                    145.33314745878602
                ],
                [
                    419.493834210866,
                    146.624265549261
                ],
                [
                    417.8300905657877,
                    147.17731080527108
                ],
                [
                    418.1448528770186,
                    148.02958101872025
                ],
                [
                    415.6942034538629,
                    150.07794695008005
                ],
                [
                    416.36869412078636,
                    150.56092243003673
                ],
                [
                    415.51433927601624,
                    152.2158373137446
                ],
                [
                    418.3696830993265,
                    151.71033305293332
                ],
                [
                    419.4039021219428,
                    150.97479633156206
                ],
                [
                    420.64046834463625,
                    150.9288151283842
                ],
                [
                    422.37166105640677,
                    152.53744677245413
                ],
                [
                    424.08037074594677,
                    152.2617850949249
                ],
                [
                    425.5192841687174,
                    153.43305149689223
                ],
                [
                    426.91323154702604,
                    152.56041664433235
                ],
                [
                    428.104831725258,
                    153.73148604711088
                ],
                [
                    428.84677145887395,
                    153.73148604711088
                ],
                [
                    431.05010763749124,
                    154.97060146732542
                ],
                [
                    430.420583015029,
                    156.02546482505977
                ],
                [
                    430.420583015029,
                    157.65240148400216
                ],
                [
                    431.2974208820301,
                    158.54544214672222
                ],
                [
                    431.1175567041837,
                    160.1243495452551
                ],
                [
                    429.3189149257207,
                    160.26158033940476
                ],
                [
                    427.90248452518085,
                    161.70187298979982
                ],
                [
                    426.05887670225616,
                    162.77562898611973
                ],
                [
                    424.17030283486974,
                    165.03528929179924
                ],
                [
                    423.56326123463896,
                    164.73872668270548
                ],
                [
                    424.6424463017165,
                    166.76807313455453
                ],
                [
                    424.17030283486974,
                    167.70218087795195
                ],
                [
                    425.0471407018706,
                    168.54474642110074
                ],
                [
                    426.9357145692568,
                    171.66114896269323
                ],
                [
                    428.0148996363348,
                    172.8426362842216
                ],
                [
                    426.5310201691027,
                    173.66014632986042
                ],
                [
                    425.3619030131017,
                    172.638202233593
                ],
                [
                    423.540778212408,
                    172.04748772263684
                ],
                [
                    423.9005065681006,
                    170.88822821660284
                ],
                [
                    422.61897430094564,
                    169.455192030766
                ],
                [
                    419.8085965220971,
                    169.1365875245927
                ],
                [
                    418.3696830993265,
                    170.25146128475848
                ],
                [
                    415.4019241648623,
                    170.3196968678941
                ],
                [
                    412.2093350080904,
                    171.50204474679418
                ],
                [
                    411.22008202993584,
                    172.20654485581207
                ],
                [
                    410.09593091839656,
                    174.34112878041174
                ],
                [
                    407.9825268287025,
                    174.93111111578168
                ],
                [
                    407.15065500616333,
                    176.9039204386164
                ],
                [
                    406.25133411693173,
                    176.76793179930314
                ],
                [
                    405.1271830053922,
                    178.2632613894408
                ],
                [
                    403.4859223825447,
                    178.19531785965967
                ],
                [
                    402.47418638215913,
                    180.16467860276208
                ],
                [
                    400.6530615814654,
                    180.93375156843007
                ],
                [
                    399.64132558107985,
                    180.4135308237888
                ],
                [
                    399.1691821142333,
                    177.71964389532712
                ],
                [
                    399.5738765143874,
                    176.45058633386583
                ],
                [
                    399.0792500253099,
                    174.6134516566417
                ],
                [
                    397.685302647001,
                    175.81573292732423
                ],
                [
                    395.9316269129997,
                    176.60926583074888
                ],
                [
                    395.0772720682296,
                    176.01981659475757
                ],
                [
                    394.2229172234597,
                    176.67726718769262
                ],
                [
                    393.12124913415096,
                    178.53501079560965
                ],
                [
                    392.31186033384256,
                    179.30475296972304
                ],
                [
                    390.1085241552255,
                    179.71213537195842
                ],
                [
                    387.4105614875309,
                    181.5894767651336
                ],
                [
                    387.38807846529994,
                    182.8550583541218
                ],
                [
                    386.1065461981452,
                    184.052059043273
                ],
                [
                    385.67936877576017,
                    182.4031625484456
                ],
                [
                    384.19548930852807,
                    184.14236794298233
                ],
                [
                    383.90321001952816,
                    185.85741899107563
                ],
                [
                    383.0263721525273,
                    186.37614199197338
                ],
                [
                    381.4975266408335,
                    188.78747392112888
                ],
                [
                    380.8230359739098,
                    192.11776763738686
                ],
                [
                    381.1827643296026,
                    192.97191204042917
                ],
                [
                    379.743850906832,
                    194.16258708031688
                ],
                [
                    378.2150053951384,
                    194.07275042752798
                ],
                [
                    378.19252237290766,
                    192.11776763738686
                ],
                [
                    376.79857499459854,
                    193.1741539432444
                ],
                [
                    377.1133373058299,
                    194.02783053469386
                ],
                [
                    374.77510299382766,
                    196.00331988104625
                ],
                [
                    374.3254425492121,
                    196.92303141147397
                ],
                [
                    373.0663933042879,
                    197.61813429847217
                ],
                [
                    371.6274798815175,
                    199.074803500421
                ],
                [
                    373.49357072667294,
                    200.84375624283257
                ],
                [
                    373.69591792674987,
                    202.9241523551957
                ],
                [
                    372.3919026373644,
                    203.99704587168753
                ],
                [
                    371.8523101038252,
                    205.8955340300563
                ],
                [
                    370.4133966810548,
                    208.34970873749205
                ],
                [
                    370.43587970328554,
                    209.28596062335555
                ],
                [
                    369.356694636208,
                    211.17941687564286
                ],
                [
                    368.3899246802839,
                    210.95675009354903
                ],
                [
                    368.05267934682206,
                    212.60390057724038
                ],
                [
                    368.0751623690528,
                    214.82764376546936
                ],
                [
                    367.5130868132833,
                    215.27209921642333
                ],
                [
                    366.92852823528256,
                    217.40413226614857
                ]
            ],
            [
                [
                    355.9792964088888,
                    228.45073327985256
                ],
                [
                    354.67528111950287,
                    228.75981153538788
                ],
                [
                    353.84340929696396,
                    228.36241682668714
                ],
                [
                    352.9665714299631,
                    229.7970982469957
                ],
                [
                    351.1679296515001,
                    229.04677195135702
                ],
                [
                    351.7075221850389,
                    232.3990977766784
                ],
                [
                    351.23537871819235,
                    233.25837603553828
                ],
                [
                    350.1112276066531,
                    232.94995730561402
                ],
                [
                    349.21190671742147,
                    231.8480946854454
                ],
                [
                    349.70653320649876,
                    234.16134427435645
                ],
                [
                    348.53741605049777,
                    235.83412491994062
                ],
                [
                    349.3692878730369,
                    237.76939141161546
                ],
                [
                    349.2343897396522,
                    239.08788953404837
                ],
                [
                    347.975340494728,
                    240.29580298000235
                ],
                [
                    345.8619364050337,
                    240.64706892019518
                ],
                [
                    345.36730991595664,
                    240.09819075193013
                ],
                [
                    344.8502004046484,
                    237.1538147485146
                ],
                [
                    344.06329462657095,
                    235.92212962254501
                ],
                [
                    342.08478867026133,
                    235.6581046184242
                ],
                [
                    341.14050173656824,
                    232.3109469365628
                ],
                [
                    339.7465543582596,
                    230.56921311091634
                ],
                [
                    339.6791052915671,
                    228.20785406588323
                ],
                [
                    340.7133243141834,
                    228.36241682668714
                ],
                [
                    341.0280866254143,
                    226.7941786076969
                ],
                [
                    340.60090920302946,
                    225.99856496253483
                ],
                [
                    341.79250938126097,
                    224.09669904377125
                ],
                [
                    342.871694448339,
                    224.03032339958867
                ],
                [
                    344.28812484887885,
                    223.05656928123642
                ],
                [
                    346.6488421831116,
                    222.3923828573836
                ],
                [
                    347.0310535610349,
                    221.06336773574913
                ],
                [
                    348.4924500060363,
                    221.77228252524048
                ],
                [
                    348.35755187265136,
                    220.11037835966232
                ],
                [
                    350.02129551772964,
                    221.10768206720206
                ],
                [
                    350.96558245142296,
                    222.30380851441726
                ],
                [
                    351.23537871819235,
                    219.82217816381473
                ],
                [
                    350.943099429192,
                    218.60242078962796
                ],
                [
                    351.6625561405774,
                    217.89240881806478
                ],
                [
                    353.12395258557876,
                    217.98117381755134
                ],
                [
                    354.1356885859641,
                    217.20434905598972
                ],
                [
                    354.38300183050274,
                    215.58316007640576
                ],
                [
                    355.23735667527285,
                    215.84974572626857
                ]
            ],
            [
                [
                    399.0792500253099,
                    174.6134516566417
                ],
                [
                    400.0010539367722,
                    174.68152612312417
                ],
                [
                    401.61983153738925,
                    173.36497607844342
                ],
                [
                    401.7322466485432,
                    173.59203429960752
                ],
                [
                    402.78894869339,
                    173.00162489949003
                ],
                [
                    403.3060582046983,
                    171.18379506517118
                ],
                [
                    404.04799793831444,
                    170.82001373739962
                ],
                [
                    405.50939438331557,
                    171.36565876284556
                ],
                [
                    407.1056889617016,
                    170.7745360121379
                ],
                [
                    408.79191562901065,
                    170.70631731515624
                ],
                [
                    410.2982781184735,
                    170.04673932318514
                ],
                [
                    411.13014994101286,
                    169.09106807400508
                ],
                [
                    410.0284818517041,
                    167.95271389129755
                ],
                [
                    409.1741270069342,
                    167.97548791718424
                ],
                [
                    409.0392288735493,
                    166.1754641972754
                ],
                [
                    407.66776451747137,
                    164.8984202562649
                ],
                [
                    407.57783242854816,
                    163.0496773077897
                ],
                [
                    410.45565927408893,
                    159.27786157224932
                ],
                [
                    408.2298400732409,
                    159.07189880268027
                ],
                [
                    405.1046999831615,
                    159.3465105819941
                ],
                [
                    403.82316771600654,
                    160.53601055146703
                ],
                [
                    403.7557186493141,
                    160.60461157100235
                ],
                [
                    401.77721269300446,
                    161.29047818221875
                ],
                [
                    400.7429936703884,
                    160.81039895812654
                ],
                [
                    398.20241215830924,
                    161.67902018260446
                ],
                [
                    394.0205700233828,
                    163.14101752858807
                ],
                [
                    388.28739935453154,
                    165.99308420472312
                ],
                [
                    386.84848593176116,
                    165.6738752539506
                ],
                [
                    384.9823950866057,
                    164.7159121689955
                ],
                [
                    384.6226667309131,
                    165.14933894821905
                ],
                [
                    385.40957250899055,
                    166.5857524416062
                ],
                [
                    384.71259881983633,
                    166.83643869547728
                ],
                [
                    381.3626285074488,
                    169.7509895151403
                ],
                [
                    378.64218281752323,
                    172.72906460643605
                ],
                [
                    378.9794281509853,
                    174.06876586194176
                ],
                [
                    377.04588823913764,
                    175.2714001696047
                ],
                [
                    376.57374477229087,
                    175.7023434256688
                ],
                [
                    373.7184009489806,
                    176.22387785735907
                ],
                [
                    372.63921588190306,
                    175.88376330678574
                ],
                [
                    367.69295099112946,
                    176.4732556619427
                ],
                [
                    367.10839241312897,
                    176.60926583074888
                ],
                [
                    365.1298864568196,
                    175.18066252433488
                ],
                [
                    361.9822633445092,
                    174.65883491209587
                ],
                [
                    361.7349500999708,
                    175.04454773594563
                ],
                [
                    360.3634857438924,
                    175.88376330678574
                ],
                [
                    358.6547760543526,
                    175.86108679037966
                ],
                [
                    357.37324378719745,
                    176.4052468490122
                ],
                [
                    354.9450773862727,
                    176.99457401460182
                ],
                [
                    353.75347720804075,
                    176.949247778193
                ]
            ],
            [
                [
                    391.4125394446112,
                    200.6646944733265
                ],
                [
                    389.95114299960983,
                    204.42157189123185
                ],
                [
                    391.0977771333803,
                    206.0294762520095
                ],
                [
                    392.31186033384256,
                    206.0517990767994
                ],
                [
                    392.42427544499674,
                    207.30147712021056
                ],
                [
                    391.5924036224576,
                    209.68707722511004
                ],
                [
                    390.7830148221492,
                    210.51134240851565
                ],
                [
                    390.7380487776877,
                    211.84726919975043
                ],
                [
                    389.50148255499425,
                    212.5816507700439
                ],
                [
                    389.3441013993786,
                    213.69384055756348
                ],
                [
                    387.0058670873768,
                    216.09408516454317
                ],
                [
                    387.47801055422315,
                    217.00454625183642
                ],
                [
                    385.7693008646834,
                    220.30987785497337
                ],
                [
                    385.2072253089136,
                    222.21523036709993
                ],
                [
                    384.9823950866057,
                    223.47711025208213
                ],
                [
                    383.99314210845114,
                    225.20264701390266
                ],
                [
                    383.790794908374,
                    226.5069087311166
                ],
                [
                    381.74483988537213,
                    225.22475996218492
                ],
                [
                    379.9237150846784,
                    224.80457366734862
                ],
                [
                    378.6646658397542,
                    225.24687267504459
                ],
                [
                    377.6754128615994,
                    223.96394562796934
                ],
                [
                    375.9667031720596,
                    223.87543862230092
                ],
                [
                    374.21302743805813,
                    224.98150457894116
                ],
                [
                    372.90901214867245,
                    222.9016115585212
                ],
                [
                    372.3469365929027,
                    222.63594269368355
                ],
                [
                    370.503328769978,
                    223.7205422561999
                ]
            ],
            [
                [
                    364.8151241455887,
                    406.4439471430881
                ],
                [
                    364.65774298997303,
                    407.5813071478491
                ],
                [
                    363.39869374504883,
                    407.2297941381608
                ],
                [
                    362.971516322664,
                    406.2784858938817
                ],
                [
                    364.0057353452803,
                    405.2648850686768
                ],
                [
                    364.3204976565112,
                    405.4510761072603
                ],
                [
                    364.9050562345119,
                    405.4303886472072
                ],
                [
                    364.8151241455887,
                    406.4439471430881
                ]
            ],
            [
                [
                    364.92753925674265,
                    404.9959269601941
                ],
                [
                    364.29801463428043,
                    405.4303886472072
                ],
                [
                    363.98325232304956,
                    405.2235080907593
                ],
                [
                    363.53359187843375,
                    405.2648850686768
                ],
                [
                    362.6567540114331,
                    405.4717634590764
                ],
                [
                    362.36447472243253,
                    405.0373063237787
                ],
                [
                    362.5668219225099,
                    401.7876977079968
                ],
                [
                    363.03896538935624,
                    399.0948788424058
                ],
                [
                    362.81413516704833,
                    397.3953576450434
                ],
                [
                    363.7584221007414,
                    397.0014576673865
                ],
                [
                    363.6684900118182,
                    394.61644778743164
                ],
                [
                    364.1631165008955,
                    393.22621978754245
                ],
                [
                    364.4553957898961,
                    389.0108412261104
                ],
                [
                    364.88257321228093,
                    387.7847695395011
                ],
                [
                    365.8043771237433,
                    387.3898444964634
                ],
                [
                    366.6137659240517,
                    386.24639645379756
                ],
                [
                    367.4231547243601,
                    386.93250886473515
                ],
                [
                    367.24329054651366,
                    389.71720319155605
                ],
                [
                    367.73791703559095,
                    390.7972572974195
                ],
                [
                    367.24329054651366,
                    392.2299156444393
                ],
                [
                    367.2882565909754,
                    393.68276978484676
                ],
                [
                    366.1865885016666,
                    394.67868468674453
                ],
                [
                    365.4896148125122,
                    394.0977666392871
                ],
                [
                    364.7027090344345,
                    396.4001628252291
                ],
                [
                    365.6694789903586,
                    396.669720622516
                ],
                [
                    366.3214866350513,
                    398.84621574431543
                ],
                [
                    366.1416224572049,
                    400.58651876042904
                ],
                [
                    366.4114187239745,
                    401.8084044212527
                ],
                [
                    364.92753925674265,
                    404.9959269601941
                ]
            ],
            [
                [
                    359.7339611214304,
                    426.5188012105983
                ],
                [
                    360.7007310773545,
                    428.16518901288157
                ],
                [
                    360.8581122329697,
                    429.74926860482367
                ],
                [
                    359.8913422770461,
                    432.15519212630477
                ],
                [
                    358.45242885427547,
                    431.70289259345725
                ],
                [
                    357.7329721428903,
                    429.91381655291434
                ],
                [
                    357.64304005396707,
                    428.2474925455808
                ],
                [
                    359.7339611214304,
                    426.5188012105983
                ]
            ],
            [
                [
                    361.4876368554319,
                    417.32885333136943
                ],
                [
                    362.2295765890476,
                    419.37046570975656
                ],
                [
                    360.88059525520043,
                    419.7827938248516
                ],
                [
                    360.7007310773545,
                    418.97871713017173
                ],
                [
                    361.4876368554319,
                    417.32885333136943
                ]
            ],
            [
                [
                    363.1963465449717,
                    407.31250586428405
                ],
                [
                    363.1963465449717,
                    407.3538610849056
                ],
                [
                    362.8815842337408,
                    408.7183437933055
                ],
                [
                    363.57855792289547,
                    409.25574008527565
                ],
                [
                    362.4768898335865,
                    410.37164248646695
                ],
                [
                    363.71345605627994,
                    411.69379662598817
                ],
                [
                    363.17386352274093,
                    414.46067703953213
                ],
                [
                    361.8023991666628,
                    415.18306504012276
                ],
                [
                    363.1288974782792,
                    415.8434245721072
                ],
                [
                    361.86984823335524,
                    417.4113617868014
                ],
                [
                    360.1836215660462,
                    414.19232952059235
                ],
                [
                    359.9587913437381,
                    411.30132686286674
                ],
                [
                    360.92556129966215,
                    412.25145205864067
                ],
                [
                    361.24032361089326,
                    408.4496187448165
                ],
                [
                    361.8023991666628,
                    406.75416842760245
                ],
                [
                    363.1963465449717,
                    407.31250586428405
                ]
            ],
            [
                [
                    379.09184326213904,
                    480.97426246712837
                ],
                [
                    379.5639867289856,
                    481.5039897153546
                ],
                [
                    380.2384773959095,
                    485.29237127867646
                ],
                [
                    379.6988848623705,
                    487.5929039587656
                ],
                [
                    378.55225072860026,
                    487.99999999999994
                ],
                [
                    377.3381675281378,
                    484.6611195168338
                ],
                [
                    376.5962277945216,
                    484.19273424316384
                ],
                [
                    376.28146548329073,
                    482.6651689510825
                ],
                [
                    376.7086429056758,
                    481.70771982956404
                ],
                [
                    379.09184326213904,
                    480.97426246712837
                ]
            ],
            [
                [
                    377.0908542835991,
                    477.9377015917818
                ],
                [
                    377.7203789060609,
                    478.9568379575161
                ],
                [
                    377.2707184614453,
                    479.91468179481956
                ],
                [
                    376.14656734990604,
                    480.91313739450254
                ],
                [
                    375.7418729497517,
                    479.91468179481956
                ],
                [
                    377.0908542835991,
                    477.9377015917818
                ]
            ],
            [
                [
                    363.7359390785107,
                    451.2976954302485
                ],
                [
                    364.230565567588,
                    452.7514371275113
                ],
                [
                    362.7916521448176,
                    452.95615736724744
                ],
                [
                    362.67923703366364,
                    451.5843804694513
                ],
                [
                    363.7359390785107,
                    451.2976954302485
                ]
            ],
            [
                [
                    149.51770326355881,
                    177.94617042733995
                ],
                [
                    150.237159974944,
                    176.49592471381243
                ],
                [
                    148.4385181964808,
                    176.01981659475757
                ],
                [
                    147.87644264071128,
                    176.9039204386164
                ],
                [
                    146.05531784001732,
                    177.01723671916784
                ],
                [
                    142.72783054986053,
                    176.29189330186995
                ],
                [
                    142.165754994091,
                    177.24384860279855
                ],
                [
                    139.26544512631926,
                    178.17266946701233
                ],
                [
                    138.23122610370297,
                    179.4179236398544
                ],
                [
                    137.39935428116382,
                    177.96882156813717
                ],
                [
                    138.748335615011,
                    176.8132624493296
                ],
                [
                    141.2664341048594,
                    175.65698568698423
                ],
                [
                    139.6476565042426,
                    173.34226872255766
                ],
                [
                    141.2214680603979,
                    172.3655882717078
                ],
                [
                    141.0416038825515,
                    171.18379506517118
                ],
                [
                    137.9839128591641,
                    167.63384773127706
                ],
                [
                    136.5449994363937,
                    166.47179274953226
                ],
                [
                    136.72486361424012,
                    164.3964788773534
                ],
                [
                    135.71312761385457,
                    161.95323478167535
                ]
            ],
            [
                [
                    289.0248562056008,
                    203.70652813366212
                ],
                [
                    288.7550599388312,
                    202.83471821043156
                ],
                [
                    286.68662189359884,
                    202.52166672601396
                ],
                [
                    285.6074368265208,
                    204.73432222357246
                ],
                [
                    283.381617625673,
                    204.26517817618077
                ],
                [
                    282.90947415882624,
                    206.45356701032227
                ],
                [
                    281.15579842482475,
                    206.9668189460379
                ],
                [
                    280.7061379802092,
                    207.59146869830084
                ],
                [
                    280.88600215805513,
                    209.82076489392534
                ],
                [
                    280.1890284689009,
                    210.3108766766228
                ],
                [
                    279.334673624131,
                    213.93844011300024
                ],
                [
                    277.8732771791297,
                    213.87173407912496
                ],
                [
                    277.2887186011292,
                    215.22765805627142
                ],
                [
                    274.9504842891274,
                    214.0940789664739
                ],
                [
                    273.4666048218953,
                    214.84986885326907
                ],
                [
                    272.8595632216641,
                    214.24970584253288
                ],
                [
                    271.2183025988163,
                    215.8275315924002
                ],
                [
                    270.52132890966186,
                    218.04774503376967
                ],
                [
                    269.01496642019924,
                    216.84913051593702
                ],
                [
                    267.93578135312123,
                    216.53826343599422
                ],
                [
                    268.2955097088138,
                    215.0054376419355
                ],
                [
                    265.82237726342737,
                    214.316399410119
                ],
                [
                    265.7549281967349,
                    212.78189019270235
                ],
                [
                    264.6757431296569,
                    211.46884679468383
                ],
                [
                    262.8771013511939,
                    212.13660301011572
                ],
                [
                    262.4274409065781,
                    211.69145683424563
                ],
                [
                    260.0217575278839,
                    210.82313816905747
                ],
                [
                    258.9650554830366,
                    213.3824891955212
                ],
                [
                    259.1224366386523,
                    214.51646692505085
                ],
                [
                    257.1439306823429,
                    215.4720723871876
                ],
                [
                    254.67079823695622,
                    215.1165508941275
                ],
                [
                    254.041273614494,
                    215.8275315924002
                ],
                [
                    253.41174899203202,
                    215.62759344941736
                ],
                [
                    250.4664730797988,
                    218.09212463847297
                ],
                [
                    249.58963521279793,
                    217.3597376898589
                ],
                [
                    247.74602738987323,
                    218.04774503376967
                ],
                [
                    247.52119716756556,
                    216.5826759270039
                ],
                [
                    246.26214792264136,
                    215.49429041158243
                ],
                [
                    243.06955876586926,
                    217.60389589547043
                ],
                [
                    241.7655434764838,
                    219.42306483265537
                ],
                [
                    241.22595094294456,
                    218.6246046880375
                ],
                [
                    239.49475823117405,
                    217.71486723352376
                ],
                [
                    239.26992800886592,
                    216.16071809324785
                ],
                [
                    238.23570898624962,
                    215.56094302507728
                ],
                [
                    235.8300256075554,
                    215.44985411948062
                ],
                [
                    234.59345938486194,
                    213.69384055756348
                ],
                [
                    233.53675734001513,
                    215.20543711093757
                ],
                [
                    232.5924704063218,
                    215.4720723871876
                ],
                [
                    230.32168516101228,
                    215.93859983188412
                ],
                [
                    227.15157902647115,
                    215.58316007640576
                ]
            ],
            [
                [
                    217.01173600038555,
                    224.67186558224176
                ],
                [
                    215.25806026638406,
                    225.35743270862667
                ],
                [
                    214.40370542161395,
                    226.66159743062695
                ],
                [
                    212.38023342084307,
                    227.5232244989087
                ],
                [
                    208.89536497507106,
                    226.92675135483387
                ],
                [
                    208.13094221922393,
                    226.41851003224383
                ],
                [
                    206.2648513740687,
                    227.01512850484846
                ],
                [
                    205.16318328475995,
                    226.2859049496139
                ],
                [
                    203.90413403983598,
                    226.13118834659565
                ],
                [
                    202.2628734169882,
                    227.14768721058402
                ],
                [
                    201.026307194295,
                    226.63949974793672
                ],
                [
                    200.1719523495251,
                    225.2689851524994
                ],
                [
                    199.227665415832,
                    225.9101446773575
                ],
                [
                    199.34008052698596,
                    226.68369487904596
                ],
                [
                    200.59912977190993,
                    228.5390460075647
                ],
                [
                    199.38504657144745,
                    229.4661050078696
                ],
                [
                    199.34008052698596,
                    231.89218022226925
                ],
                [
                    201.49845066114153,
                    231.29694775870172
                ],
                [
                    202.42025457260388,
                    231.8480946854454
                ],
                [
                    203.83668497314352,
                    231.53947014451995
                ],
                [
                    204.84842097352907,
                    233.45662151261507
                ],
                [
                    206.5796136852996,
                    233.72091998100075
                ],
                [
                    207.99604408583946,
                    235.06392873366076
                ],
                [
                    206.87189297429995,
                    238.42874169653413
                ],
                [
                    204.21889635106686,
                    238.82425466291818
                ],
                [
                    204.3313114622208,
                    240.36166969947817
                ],
                [
                    203.02729617283535,
                    241.8541102221863
                ],
                [
                    201.76824692791092,
                    242.09543771126374
                ],
                [
                    202.03804319468054,
                    243.82781977773777
                ],
                [
                    201.1162392832182,
                    245.25216408295194
                ],
                [
                    201.0712732387567,
                    245.3616905377184
                ],
                [
                    199.90215608275548,
                    246.23770477966417
                ],
                [
                    199.49746168260117,
                    247.46353669894637
                ],
                [
                    197.3390915484456,
                    248.22933445700005
                ],
                [
                    195.18072141429002,
                    247.6604815874491
                ],
                [
                    194.01160425828903,
                    248.7105567512076
                ],
                [
                    193.20221545798063,
                    248.2074582187015
                ],
                [
                    191.98813225751792,
                    249.08233841932707
                ],
                [
                    192.10054736867187,
                    251.3772525043865
                ],
                [
                    191.02136230159408,
                    252.1853710286345
                ],
                [
                    190.41432070136307,
                    255.0660045616135
                ],
                [
                    188.9978903008232,
                    254.25892858277388
                ],
                [
                    187.89622221151444,
                    257.6379874741467
                ],
                [
                    188.0985694115916,
                    258.6180564427656
                ],
                [
                    187.67139198920677,
                    259.7718163536691
                ],
                [
                    186.77207109997494,
                    259.7718163536691
                ],
                [
                    186.16502949974392,
                    261.3599991470084
                ],
                [
                    186.50227483320577,
                    264.33758500130074
                ],
                [
                    185.37812372166627,
                    266.55197254752545
                ],
                [
                    184.79356514366577,
                    266.7472578617716
                ],
                [
                    183.42210078758762,
                    265.5752986776284
                ],
                [
                    181.8707722536633,
                    264.85880861040016
                ],
                [
                    181.17379856450884,
                    263.5338005046621
                ],
                [
                    179.59998700835354,
                    262.0558402913773
                ],
                [
                    177.44161687419773,
                    262.16454633377396
                ],
                [
                    176.63222807388956,
                    263.055741196677
                ],
                [
                    174.45137491750302,
                    263.5338005046621
                ],
                [
                    171.82086131650067,
                    262.2949867653879
                ],
                [
                    168.87558540426744,
                    263.2730532301903
                ],
                [
                    168.4484079818826,
                    262.4036814447588
                ],
                [
                    168.24606078180545,
                    261.1642556092064
                ],
                [
                    166.71721527011186,
                    261.1642556092064
                ],
                [
                    164.40146398034085,
                    260.46814244401054
                ],
                [
                    164.24408282472518,
                    261.5774722777289
                ],
                [
                    162.08571269056938,
                    262.25150744789653
                ],
                [
                    160.57935020110654,
                    262.0558402913773
                ],
                [
                    159.72499535633665,
                    262.49063347171574
                ],
                [
                    159.477682111798,
                    263.9031417994257
                ],
                [
                    154.84617953225575,
                    264.1203736589941
                ],
                [
                    153.76699446517773,
                    263.4686164629562
                ],
                [
                    152.62036033140748,
                    263.96831351258334
                ],
                [
                    152.41801313133033,
                    262.79493956616363
                ],
                [
                    151.29386201979105,
                    262.5123709624061
                ],
                [
                    149.54018628578933,
                    263.09920525066366
                ],
                [
                    148.8432125966351,
                    263.92486590912625
                ],
                [
                    146.6848424624793,
                    265.16280127333005
                ],
                [
                    144.54895535055425,
                    265.74895974848255
                ],
                [
                    143.334872150092,
                    265.3582039711842
                ],
                [
                    139.80503765985804,
                    265.5535901255982
                ],
                [
                    138.72585259278026,
                    265.90090247803136
                ],
                [
                    138.23122610370297,
                    263.07747332665
                ],
                [
                    140.0073848599352,
                    263.4903446825006
                ],
                [
                    139.53524139308888,
                    260.685700655545
                ],
                [
                    138.59095445939533,
                    259.6412314632642
                ],
                [
                    135.66816156939308,
                    259.83710598439757
                ],
                [
                    134.63394254677678,
                    260.9249906914684
                ],
                [
                    132.45308939039046,
                    260.5551682229161
                ],
                [
                    132.13832707915913,
                    260.79447196891806
                ],
                [
                    131.89101383462048,
                    260.9467430852385
                ],
                [
                    131.64370059008206,
                    261.0990040305394
                ],
                [
                    131.5312854789281,
                    260.8814852811474
                ],
                [
                    130.11485507838825,
                    262.38194292188825
                ],
                [
                    129.41788138923403,
                    262.12106453687977
                ],
                [
                    128.338696322156,
                    262.99054357083503
                ],
                [
                    128.36117934438676,
                    264.4896207419155
                ],
                [
                    125.97797898792305,
                    266.5736717289267
                ],
                [
                    126.58502058815475,
                    267.3763986401044
                ],
                [
                    126.00046201015402,
                    268.8943126460534
                ],
                [
                    124.0444390760756,
                    268.78592304068036
                ],
                [
                    123.8645748982292,
                    269.479529481531
                ],
                [
                    122.47062751992007,
                    270.60620087741404
                ],
                [
                    119.30052138537894,
                    270.5628773920299
                ],
                [
                    119.18810627422499,
                    267.78850185591307
                ],
                [
                    118.2438193405319,
                    265.9443128517437
                ],
                [
                    116.64752476214608,
                    265.9877224102602
                ],
                [
                    111.09421827114147,
                    265.6621308451458
                ],
                [
                    107.76673098098445,
                    265.81407928449573
                ],
                [
                    105.24863249113628,
                    265.31478257953853
                ],
                [
                    103.71978697944269,
                    264.51133931372397
                ],
                [
                    102.91039817913429,
                    262.86014275602156
                ],
                [
                    100.1225034225165,
                    261.79492469904255
                ],
                [
                    98.84097115536156,
                    262.09932332841703
                ],
                [
                    96.00811035428228,
                    261.3165020342865
                ],
                [
                    94.86147622051226,
                    260.3375975372823
                ],
                [
                    95.1537555095124,
                    257.398350411083
                ],
                [
                    94.09705346466512,
                    256.61390565516456
                ],
                [
                    93.80477417566499,
                    255.52394551983252
                ],
                [
                    91.57895497481695,
                    257.04974198633124
                ],
                [
                    89.60044901850756,
                    256.78825030177217
                ],
                [
                    88.83602626266088,
                    255.74197982002136
                ],
                [
                    89.26320368504571,
                    254.45527112511826
                ],
                [
                    89.24072066281497,
                    252.64390235680003
                ],
                [
                    88.22898466242941,
                    251.26802475502126
                ],
                [
                    88.45381488473731,
                    250.35029928806267
                ],
                [
                    90.22997364096977,
                    250.91845983306354
                ],
                [
                    90.61218501889289,
                    248.99486601565394
                ],
                [
                    89.19575461835325,
                    249.34473483659679
                ],
                [
                    88.29643372912187,
                    247.2665741607115
                ],
                [
                    89.64541506296905,
                    247.72612596358795
                ],
                [
                    91.30915870804756,
                    245.77784103166402
                ],
                [
                    93.01786839758756,
                    245.90924053126577
                ],
                [
                    93.35511373104919,
                    244.37575461480566
                ],
                [
                    94.27691764251153,
                    243.01662297453777
                ],
                [
                    94.20946857581907,
                    241.56887033362534
                ],
                [
                    93.22021559766449,
                    241.74440701997742
                ],
                [
                    92.1410305305867,
                    239.13182553649392
                ]
            ],
            [
                [
                    358.3400137431215,
                    207.41301734077405
                ],
                [
                    359.1494025434299,
                    206.14108786558035
                ],
                [
                    360.02624041043055,
                    206.2973335838446
                ],
                [
                    361.2178405886625,
                    206.0294762520095
                ],
                [
                    361.8473652111245,
                    205.02468899318632
                ],
                [
                    363.0164823671255,
                    204.53327411505444
                ],
                [
                    363.7359390785107,
                    203.30420220743213
                ],
                [
                    361.68998405550906,
                    200.84375624283257
                ],
                [
                    360.4534178328156,
                    200.328909567128
                ],
                [
                    361.8023991666628,
                    198.60430622092792
                ],
                [
                    360.6782480551235,
                    198.94038727316092
                ],
                [
                    359.5091308991225,
                    197.86472406080117
                ],
                [
                    358.6098100098909,
                    196.18280999678103
                ],
                [
                    355.3947378308883,
                    196.25011451114244
                ],
                [
                    354.3155527638105,
                    196.96788425474108
                ],
                [
                    354.3155527638105,
                    194.6117077261772
                ],
                [
                    355.05749249742644,
                    193.12921313159035
                ],
                [
                    354.49541694165646,
                    192.09528503420222
                ],
                [
                    356.1141945422735,
                    190.54334979920532
                ],
                [
                    354.6303150750414,
                    190.3633340621115
                ],
                [
                    354.0457564970409,
                    190.88083375999736
                ],
                [
                    350.1337106288838,
                    190.9258271355319
                ],
                [
                    349.52666902865235,
                    189.66561326245682
                ],
                [
                    348.02030653918973,
                    191.03830595898228
                ],
                [
                    347.997823516959,
                    192.16273205554762
                ],
                [
                    345.9069024494954,
                    191.0608009326163
                ],
                [
                    343.928396493186,
                    194.2299618292372
                ],
                [
                    342.30961889256923,
                    194.20750384057538
                ],
                [
                    340.64587524749095,
                    196.1379390240507
                ],
                [
                    339.5442071581822,
                    194.0502906116634
                ],
                [
                    337.4982521351808,
                    194.47698248579837
                ],
                [
                    337.76804840194995,
                    193.46624369574357
                ],
                [
                    336.62141426817993,
                    192.45497516672435
                ],
                [
                    333.2264779113307,
                    192.34257922153648
                ],
                [
                    331.9674286664067,
                    192.92696651364815
                ],
                [
                    329.06711879863497,
                    191.75801444433387
                ],
                [
                    325.42486919724706,
                    192.5224095842063
                ],
                [
                    324.4805822635542,
                    192.27513850395394
                ],
                [
                    323.55877835209185,
                    193.01685651913726
                ],
                [
                    321.1980610178591,
                    196.07063061984258
                ],
                [
                    322.97421977409135,
                    198.02162857008324
                ],
                [
                    320.883298706628,
                    198.96279062005817
                ],
                [
                    320.7933666177048,
                    200.14980075221837
                ],
                [
                    320.50108732870444,
                    200.328909567128
                ],
                [
                    320.95074777332024,
                    197.86472406080117
                ],
                [
                    320.1413589730121,
                    195.98088244922798
                ],
                [
                    320.36618919532,
                    193.53364274333688
                ],
                [
                    321.0181968400127,
                    192.49993170747035
                ],
                [
                    318.70244555024146,
                    188.80999535560653
                ],
                [
                    317.30849817193257,
                    187.86386622414176
                ],
                [
                    316.92628679400923,
                    189.10274984497897
                ],
                [
                    317.53332839424047,
                    190.56585057777647
                ],
                [
                    315.9370338158544,
                    191.5106450661874
                ],
                [
                    313.10417301477514,
                    191.44317516297508
                ],
                [
                    311.35049728077365,
                    189.575566246258
                ],
                [
                    310.2488291914651,
                    189.32791509331787
                ],
                [
                    309.39447434669523,
                    185.20317478034963
                ],
                [
                    307.7307307016167,
                    184.27782320613
                ],
                [
                    306.65154563453916,
                    185.38367848827244
                ],
                [
                    307.7756967460782,
                    185.87997511227115
                ],
                [
                    306.8988588790776,
                    186.96235403965198
                ],
                [
                    304.44820945592164,
                    186.21828477304
                ],
                [
                    303.59385461115176,
                    185.2708656851467
                ],
                [
                    301.07575612130336,
                    183.7585253177803
                ],
                [
                    299.45697852068656,
                    182.380564913333
                ],
                [
                    299.1646992316864,
                    181.04682396332447
                ],
                [
                    298.1754462535316,
                    183.46494598075537
                ],
                [
                    300.40126545437965,
                    184.052059043273
                ],
                [
                    300.8509258989957,
                    185.6318430311929
                ],
                [
                    299.1871822539172,
                    186.1957326708128
                ],
                [
                    298.445242520301,
                    187.97652525560028
                ],
                [
                    295.9046610082221,
                    189.1703022043481
                ],
                [
                    294.6456117632979,
                    192.74717392275141
                ],
                [
                    294.75802687445184,
                    194.3871604442499
                ],
                [
                    295.38755149691406,
                    194.9709291740636
                ],
                [
                    297.208676297608,
                    195.17296194676436
                ],
                [
                    299.95160500976385,
                    197.10243657771719
                ],
                [
                    299.83918989861013,
                    198.22334438157935
                ],
                [
                    302.78446581084336,
                    200.12741099867793
                ],
                [
                    305.21263221176855,
                    199.38840540886827
                ],
                [
                    305.61732661192264,
                    201.98489119045092
                ],
                [
                    307.7981797683092,
                    203.46067203741575
                ],
                [
                    306.65154563453916,
                    204.9800199544856
                ],
                [
                    305.1227001228451,
                    205.136357181871
                ],
                [
                    304.0659980779981,
                    204.66730845741046
                ],
                [
                    299.6818087429947,
                    204.6449700309717
                ],
                [
                    299.92912198753334,
                    206.60978815135434
                ],
                [
                    299.0747671427632,
                    208.39430211606344
                ],
                [
                    297.3660574532232,
                    209.68707722511004
                ],
                [
                    296.6016346973763,
                    208.55037109478582
                ],
                [
                    295.34258545245234,
                    209.28596062335555
                ],
                [
                    295.83721194152986,
                    210.15494502362048
                ],
                [
                    294.2858834076053,
                    211.6246764104614
                ],
                [
                    293.67884180737383,
                    212.91537209233132
                ],
                [
                    295.2301703412984,
                    215.8275315924002
                ],
                [
                    298.19792927576236,
                    216.91573871267303
                ],
                [
                    298.89490296491704,
                    217.67047942288724
                ],
                [
                    301.5029335436884,
                    219.09041096964927
                ],
                [
                    303.32405834438214,
                    218.8242489422397
                ],
                [
                    304.178413189152,
                    219.9551986717517
                ],
                [
                    304.2458622558445,
                    221.68368152781005
                ],
                [
                    303.5713715889208,
                    222.50309543796368
                ],
                [
                    304.20089621138277,
                    223.4992416180525
                ],
                [
                    304.04351505576733,
                    225.49009698369122
                ],
                [
                    302.19990723284286,
                    226.2859049496139
                ],
                [
                    301.5703826103809,
                    228.67150811637526
                ],
                [
                    301.9975600327657,
                    230.21628149657494
                ],
                [
                    303.90861692238286,
                    231.89218022226925
                ],
                [
                    304.49317550038313,
                    232.94995730561402
                ],
                [
                    303.7062697223057,
                    236.38409477212406
                ],
                [
                    305.1901491895376,
                    236.01013069452756
                ],
                [
                    307.1236891013855,
                    236.80197714747953
                ],
                [
                    305.887122878692,
                    240.6031638039509
                ],
                [
                    307.5283835015398,
                    242.57801210056658
                ],
                [
                    306.69651167900065,
                    243.95933670963234
                ],
                [
                    307.4384514126166,
                    244.98927818698422
                ],
                [
                    306.8988588790776,
                    245.66833542069753
                ],
                [
                    307.97804394615537,
                    249.1916740494974
                ],
                [
                    307.91059487946313,
                    251.4427865753343
                ],
                [
                    308.94481390207943,
                    252.70939912806188
                ],
                [
                    308.78743274646376,
                    253.97529227378982
                ],
                [
                    308.9897799465409,
                    256.81004243412303
                ],
                [
                    308.09045905730954,
                    257.55084966630375
                ],
                [
                    306.9887909680008,
                    256.9189999354585
                ],
                [
                    305.8196738119998,
                    257.22405293154367
                ],
                [
                    304.49317550038313,
                    256.7228726402522
                ],
                [
                    304.49317550038313,
                    255.76378208633906
                ],
                [
                    302.58211861076643,
                    256.7228726402522
                ],
                [
                    301.0982391435341,
                    258.3349690166874
                ],
                [
                    299.8167068763794,
                    257.31120335399106
                ],
                [
                    299.0298010983015,
                    257.6379874741467
                ],
                [
                    298.55765763145496,
                    259.0970468948286
                ],
                [
                    297.208676297608,
                    260.3158393248062
                ],
                [
                    297.72578580891604,
                    261.2295053212104
                ],
                [
                    297.09626118645406,
                    263.0340088607277
                ],
                [
                    296.3992874972994,
                    259.44534004859435
                ],
                [
                    295.4100345191446,
                    256.7228726402522
                ],
                [
                    296.3318384306069,
                    255.58935803196744
                ],
                [
                    296.48921958622236,
                    254.36800989885
                ],
                [
                    295.9721100749143,
                    253.03685412306936
                ],
                [
                    294.48823060768245,
                    251.8577908110779
                ],
                [
                    293.251664384989,
                    252.11985884355863
                ],
                [
                    293.49897762952764,
                    252.79672516252657
                ],
                [
                    295.2751363857601,
                    253.05868274661952
                ],
                [
                    296.01707611937604,
                    254.3461940608674
                ],
                [
                    294.21843434091284,
                    255.7201773421719
                ],
                [
                    292.622139762527,
                    258.68337928484306
                ],
                [
                    290.1714903393711,
                    260.7727181216838
                ],
                [
                    288.4852636720618,
                    261.5339793088642
                ],
                [
                    285.69736891544426,
                    262.09932332841703
                ]
            ],
            [
                [
                    152.10325082009945,
                    81.5429352661858
                ],
                [
                    152.9351226426386,
                    82.81295723342777
                ],
                [
                    154.10423979863936,
                    83.48349755673365
                ],
                [
                    153.31733402056193,
                    84.63233021284816
                ],
                [
                    153.33981704279267,
                    85.8281401694577
                ],
                [
                    155.20590788794811,
                    86.9513755199576
                ],
                [
                    154.93611162117872,
                    88.00219186966535
                ],
                [
                    156.1501948216412,
                    89.31473219745055
                ],
                [
                    157.74648940002726,
                    90.79302216022876
                ],
                [
                    156.62233828848798,
                    94.57785818903375
                ],
                [
                    157.47669313325787,
                    96.31255206239564
                ],
                [
                    158.69077633372035,
                    97.45215106835911
                ],
                [
                    157.0495157108728,
                    98.68580558858912
                ],
                [
                    157.97131962233516,
                    100.29762191271487
                ],
                [
                    157.27434593318048,
                    101.6237929590356
                ],
                [
                    157.88138753341195,
                    102.68867909347927
                ],
                [
                    159.2078858450284,
                    103.0434857042705
                ],
                [
                    160.7367313567222,
                    104.9344792717958
                ],
                [
                    161.07397669018383,
                    106.02080599378047
                ],
                [
                    159.27533491172085,
                    106.23326350001224
                ],
                [
                    158.15118380018157,
                    104.50919750259402
                ],
                [
                    156.12771179941046,
                    104.53282718477067
                ],
                [
                    155.20590788794811,
                    103.98925758492987
                ],
                [
                    153.36230006502342,
                    104.50919750259402
                ],
                [
                    151.69855641994536,
                    102.9961826405252
                ],
                [
                    150.8217185529445,
                    103.0434857042705
                ],
                [
                    149.8774316192514,
                    103.96561999969339
                ],
                [
                    147.76402752955732,
                    104.72185228434887
                ],
                [
                    145.76303855101696,
                    104.9344792717958
                ],
                [
                    144.25667606155434,
                    106.04441375043041
                ],
                [
                    143.73956655024608,
                    107.41307954173863
                ],
                [
                    143.40232121678423,
                    110.42953698318485
                ],
                [
                    141.89595872732139,
                    111.95921188357568
                ],
                [
                    143.58218539463064,
                    115.43687846380374
                ],
                [
                    143.53721935016893,
                    116.37553481048968
                ],
                [
                    140.0073848599352,
                    118.15752119008204
                ],
                [
                    141.2214680603979,
                    118.15752119008204
                ],
                [
                    142.165754994091,
                    119.37567648359311
                ],
                [
                    145.650623439863,
                    120.87372464136291
                ],
                [
                    144.18922699486188,
                    123.0481881877322
                ],
                [
                    143.334872150092,
                    123.35192099671212
                ],
                [
                    142.0758229051678,
                    126.92251010493908
                ],
                [
                    143.60466841686116,
                    130.50883753210553
                ],
                [
                    145.1784799730167,
                    129.83406905945645
                ],
                [
                    146.54994432909461,
                    132.06676345644382
                ],
                [
                    148.16872192971164,
                    130.88100905616938
                ],
                [
                    149.2479069967892,
                    129.438390556466
                ],
                [
                    151.1814469086371,
                    128.4138530436337
                ],
                [
                    153.76699446517773,
                    129.64787889024302
                ],
                [
                    154.0817567764086,
                    131.09031979261152
                ],
                [
                    155.31832299910207,
                    132.46183180410338
                ],
                [
                    158.01628566679688,
                    134.04119247998375
                ],
                [
                    159.85989348972134,
                    134.4822240383554
                ],
                [
                    158.7582254004128,
                    136.01334779309036
                ],
                [
                    157.43172708879615,
                    137.42726538470652
                ],
                [
                    159.11795375610518,
                    138.4232645502476
                ],
                [
                    159.88237651195232,
                    139.88148258510347
                ],
                [
                    160.57935020110654,
                    139.67324098968385
                ],
                [
                    161.47867109033814,
                    139.0252179003624
                ],
                [
                    163.20986380210888,
                    139.92775509321194
                ],
                [
                    163.05248264649322,
                    140.73732394611335
                ],
                [
                    164.76119233603322,
                    142.00874057959822
                ],
                [
                    166.94204549241954,
                    142.67874944981673
                ],
                [
                    167.16687571472767,
                    143.602476591019
                ],
                [
                    169.55007607119114,
                    144.4564886087245
                ],
                [
                    170.98898949396175,
                    144.3180285641797
                ],
                [
                    173.01246149473263,
                    143.76407850423834
                ],
                [
                    174.99096745104202,
                    146.53207438155283
                ],
                [
                    176.09263554035056,
                    146.09410009431065
                ],
                [
                    176.60974505165882,
                    144.61801145150974
                ],
                [
                    177.419133851967,
                    144.77951935804305
                ],
                [
                    179.73488514173846,
                    146.71645186945568
                ],
                [
                    180.27447767527724,
                    148.0986658940107
                ],
                [
                    182.41036478720207,
                    148.1677480547832
                ],
                [
                    183.78182914328022,
                    147.062107420208
                ],
                [
                    185.46805581058948,
                    147.86837241671014
                ],
                [
                    186.16502949974392,
                    149.11159916251592
                ],
                [
                    191.13377741274803,
                    151.8482121606139
                ],
                [
                    193.494494746981,
                    152.62932447486878
                ],
                [
                    193.78677403598113,
                    153.7773946067232
                ],
                [
                    195.06830630313607,
                    155.31464721716867
                ],
                [
                    196.6196348370604,
                    155.75034321557786
                ],
                [
                    197.3840575929073,
                    157.21716907341352
                ],
                [
                    201.27362043883363,
                    159.39227513153259
                ],
                [
                    203.36454150629697,
                    160.9933013548614
                ],
                [
                    204.48869261783648,
                    159.87273257371726
                ],
                [
                    206.0849871962223,
                    159.87273257371726
                ],
                [
                    206.8494099520692,
                    160.83326277274404
                ],
                [
                    208.1084591969934,
                    161.1990443739952
                ],
                [
                    211.66077670945788,
                    163.9400480571705
                ],
                [
                    214.83088284399923,
                    163.20951967031078
                ],
                [
                    215.43792444423025,
                    163.52916207809204
                ],
                [
                    216.1348981333847,
                    165.46863993108872
                ],
                [
                    215.82013582215382,
                    167.13265985879747
                ],
                [
                    218.72044568992533,
                    166.9048016941964
                ],
                [
                    220.2717742238499,
                    167.77051146933468
                ],
                [
                    222.54255946915964,
                    167.6794034463416
                ],
                [
                    224.04892195862226,
                    169.6372268965
                ],
                [
                    224.81334471446917,
                    169.6372268965
                ],
                [
                    225.780114670393,
                    168.33983376473708
                ],
                [
                    225.57776747031585,
                    167.2465782738435
                ],
                [
                    228.79283964931847,
                    167.42883295405016
                ],
                [
                    231.9854288060908,
                    169.06830792468395
                ],
                [
                    232.43508925070637,
                    169.6372268965
                ],
                [
                    231.9854288060908,
                    168.112126115951
                ],
                [
                    234.7058744960159,
                    167.95271389129755
                ],
                [
                    235.89747467424786,
                    166.6313343239101
                ],
                [
                    238.2581920084806,
                    167.7249580256406
                ],
                [
                    238.5729543197117,
                    168.6585768736831
                ],
                [
                    243.5866682771773,
                    169.56896593896926
                ],
                [
                    244.59840427756285,
                    171.52477476202688
                ],
                [
                    243.90143058840863,
                    174.3865187062333
                ],
                [
                    245.6101402779484,
                    175.13529203776187
                ],
                [
                    246.57691023387224,
                    174.8403568237876
                ],
                [
                    248.60038223464312,
                    175.83840999723265
                ],
                [
                    249.61211823502867,
                    177.24384860279855
                ],
                [
                    251.99531859149215,
                    177.33448564016356
                ],
                [
                    251.792971391415,
                    178.51236652149305
                ],
                [
                    252.9171225029545,
                    179.23684728289294
                ],
                [
                    254.0862396589555,
                    179.44055695286954
                ],
                [
                    257.16641370457387,
                    177.85556311471964
                ],
                [
                    258.62781014957477,
                    177.60637031353116
                ],
                [
                    259.88685939449897,
                    178.33120244739496
                ],
                [
                    259.79692730557576,
                    180.54925448541366
                ],
                [
                    261.16839166165414,
                    181.77032623342234
                ],
                [
                    261.7979162841159,
                    182.29017166189567
                ],
                [
                    263.281795751348,
                    180.93375156843007
                ],
                [
                    264.29353175173355,
                    180.6849683311683
                ],
                [
                    266.67673210819703,
                    181.5216537309779
                ],
                [
                    268.88006828681455,
                    181.65729735342507
                ],
                [
                    271.8478272212783,
                    182.78728088915636
                ],
                [
                    272.2525216214324,
                    183.46494598075537
                ],
                [
                    274.5008238445114,
                    184.6389898305144
                ],
                [
                    276.32194864520534,
                    184.36812132576443
                ],
                [
                    279.1997754907461,
                    182.42575991249868
                ],
                [
                    279.9192322021315,
                    184.2326725297292
                ],
                [
                    280.9534512247476,
                    184.9775209659755
                ],
                [
                    283.98865922590426,
                    184.93238697761956
                ],
                [
                    285.38260660421315,
                    184.39069518220964
                ],
                [
                    287.2262144271376,
                    185.36111646471
                ],
                [
                    288.2604334497539,
                    185.38367848827244
                ],
                [
                    291.04832820637193,
                    184.45841513554478
                ],
                [
                    293.76877389629726,
                    185.18061060814762
                ],
                [
                    294.7355438522211,
                    184.14236794298233
                ],
                [
                    294.89292500783677,
                    182.62912399451233
                ]
            ],
            [
                [
                    107.78921400321519,
                    49.17832166734854
                ],
                [
                    107.65431586983073,
                    48.713026461248546
                ],
                [
                    106.8449270695221,
                    47.880030415525084
                ],
                [
                    107.38451960306134,
                    46.188104728666985
                ],
                [
                    105.65332689129059,
                    44.420560940001224
                ],
                [
                    104.07951533513506,
                    44.1011965092199
                ],
                [
                    102.43825471228774,
                    44.46968782249576
                ],
                [
                    101.04430733397885,
                    43.8063374672231
                ],
                [
                    98.21144653289934,
                    43.65888593266857
                ],
                [
                    96.27790662105167,
                    42.4787445411356
                ],
                [
                    95.71583106528215,
                    41.91292619234332
                ],
                [
                    92.1410305305867,
                    41.593019937985446
                ],
                [
                    93.51249488666463,
                    41.37150576994429
                ],
                [
                    94.16450253135758,
                    40.41122658758843
                ],
                [
                    95.7832801319746,
                    40.04172185287791
                ],
                [
                    96.07555942097451,
                    37.600661431359185
                ],
                [
                    94.59167995374264,
                    35.798085324476006
                ],
                [
                    93.04035141981831,
                    36.73668909486253
                ],
                [
                    91.1517775524319,
                    37.1070252429389
                ],
                [
                    89.60044901850756,
                    36.02044055302349
                ],
                [
                    84.4293539054263,
                    33.37469421895037
                ],
                [
                    83.4850669717332,
                    33.86959023936231
                ],
                [
                    83.0354065271174,
                    31.343863350633796
                ],
                [
                    84.40687088319555,
                    29.48391236652924
                ],
                [
                    84.27197274981086,
                    28.317128004557162
                ],
                [
                    82.06863657119357,
                    27.472485575609653
                ],
                [
                    78.7186662588058,
                    27.621575908864884
                ],
                [
                    74.7391713239565,
                    24.811124136396074
                ],
                [
                    74.98648456849492,
                    22.593723518538468
                ],
                [
                    74.04219763480205,
                    21.845514780716144
                ],
                [
                    71.68148030056932,
                    22.518920159167465
                ],
                [
                    71.276785900415,
                    21.870461346258594
                ],
                [
                    68.17412883256611,
                    22.74331857051402
                ],
                [
                    65.88086056502561,
                    21.22170983656929
                ],
                [
                    66.78018145425744,
                    19.22371000213741
                ],
                [
                    65.83589452056435,
                    18.37371576899409
                ],
                [
                    66.78018145425744,
                    14.943606856039992
                ],
                [
                    69.47814412195203,
                    14.065976695122458
                ],
                [
                    71.00698963364539,
                    12.660641038962012
                ],
                [
                    70.9620235891839,
                    11.630852103039047
                ],
                [
                    72.60328421203167,
                    11.37957062940552
                ],
                [
                    74.3794429682639,
                    8.587347799984173
                ],
                [
                    74.58179016834083,
                    7.176562581921644
                ],
                [
                    77.34720190272787,
                    6.823645711432164
                ],
                [
                    79.21329274788332,
                    6.117546703150879
                ],
                [
                    83.0354065271174,
                    7.226972066739904
                ],
                [
                    84.60921808327248,
                    6.647154133549861
                ],
                [
                    85.4186068835811,
                    5.335380983938421
                ],
                [
                    83.37265186057925,
                    4.477021425574435
                ],
                [
                    82.87802537150174,
                    3.036829193296626
                ],
                [
                    85.64343710588878,
                    3.3654241873783803
                ],
                [
                    88.25146768466016,
                    4.982002274372007
                ],
                [
                    89.53299995181533,
                    4.376003478836424
                ],
                [
                    89.9152113297389,
                    3.2137745228911854
                ],
                [
                    91.26419266358607,
                    3.517057464263644
                ],
                [
                    93.73732510897275,
                    1.620461960669104
                ],
                [
                    95.24368759843537,
                    1.6710711692519453
                ],
                [
                    96.05307639874377,
                    0.6078932823550076
                ],
                [
                    97.85171817720698,
                    -1.1368683772161603e-13
                ],
                [
                    98.23392955513009,
                    1.038324510707298
                ],
                [
                    99.78525808905488,
                    1.013008692723929
                ],
                [
                    100.97685826728639,
                    3.1632209918844865
                ],
                [
                    102.39328866782625,
                    1.8734897144871638
                ],
                [
                    103.5399228015965,
                    2.480569910103327
                ],
                [
                    105.20366644667479,
                    0.75982531231125
                ],
                [
                    107.18217240298418,
                    0.4559447428854355
                ],
                [
                    107.29458751413813,
                    1.7722840991913245
                ],
                [
                    108.35128955898517,
                    2.1517674566573533
                ],
                [
                    109.09322929260111,
                    4.906266710568389
                ],
                [
                    111.18415036006468,
                    5.814824437557718
                ],
                [
                    113.14017329414332,
                    8.990170517877345
                ],
                [
                    115.70323782845298,
                    10.550025534075075
                ],
                [
                    117.97402307376274,
                    11.077974055100526
                ],
                [
                    120.58205365253389,
                    13.388577614560518
                ],
                [
                    121.99848405307375,
                    13.16270617370742
                ],
                [
                    123.19008423130526,
                    14.116141572180027
                ],
                [
                    123.90954094269068,
                    15.870799655081896
                ],
                [
                    125.6632166766924,
                    16.697247037228067
                ],
                [
                    126.29274129915439,
                    16.37173400031793
                ],
                [
                    127.41689241069366,
                    18.198654268137147
                ],
                [
                    128.6084925889254,
                    18.84877476101599
                ],
                [
                    127.46185845515538,
                    20.572665113038624
                ],
                [
                    128.76587374454107,
                    22.54385504447214
                ],
                [
                    130.5869985452348,
                    23.640562545304306
                ],
                [
                    133.23999516846789,
                    23.391384183404398
                ],
                [
                    133.98193490208382,
                    24.53724807140452
                ],
                [
                    137.87149774801014,
                    25.98073689692319
                ],
                [
                    138.36612423708766,
                    27.447635694429096
                ],
                [
                    137.9389468147026,
                    28.466131465904596
                ],
                [
                    140.54697739347398,
                    29.335013396218415
                ],
                [
                    142.165754994091,
                    29.21091925418341
                ],
                [
                    143.5597023723999,
                    28.540627455111917
                ],
                [
                    144.77378557286238,
                    29.359830951742197
                ],
                [
                    145.74055552878622,
                    27.472485575609653
                ],
                [
                    146.84222361809498,
                    27.199113423099107
                ],
                [
                    148.2586540186344,
                    28.06875486417755
                ],
                [
                    149.157974907866,
                    25.70708262742778
                ],
                [
                    150.237159974944,
                    24.761332355114973
                ],
                [
                    151.9009036200223,
                    24.512347668798384
                ],
                [
                    152.46297917579182,
                    23.615646648654092
                ],
                [
                    153.90189259856243,
                    24.01424930324788
                ],
                [
                    156.0152966882565,
                    22.294486738018236
                ],
                [
                    158.33104797802775,
                    22.34436385824131
                ],
                [
                    160.35451997879886,
                    21.670876695915922
                ],
                [
                    161.25384086803024,
                    22.469049091870488
                ],
                [
                    162.87261846864703,
                    22.693455281324987
                ],
                [
                    163.79442238010938,
                    21.77067248589458
                ],
                [
                    164.10918469134026,
                    20.0981774741565
                ],
                [
                    166.49238504780396,
                    19.798420159464513
                ],
                [
                    167.14439269249692,
                    21.59602530474058
                ],
                [
                    173.73191820611783,
                    23.665478011052983
                ],
                [
                    175.620492073504,
                    22.668522988723453
                ],
                [
                    177.21678665189006,
                    25.50802877818819
                ],
                [
                    179.91474931958487,
                    26.82624999339498
                ],
                [
                    180.45434185312342,
                    29.31019541643866
                ],
                [
                    178.9479793636608,
                    33.82010818342826
                ],
                [
                    178.3184547411986,
                    34.78470619075506
                ],
                [
                    178.20603963004464,
                    37.72404454794969
                ],
                [
                    176.6996771405818,
                    40.85451004693152
                ],
                [
                    176.6547110961203,
                    42.89681895263084
                ],
                [
                    174.8111032731956,
                    43.48684058340018
                ],
                [
                    173.57453705050239,
                    43.3393572266541
                ],
                [
                    172.33797082780893,
                    44.44512458474571
                ],
                [
                    172.60776709457832,
                    46.997526616750065
                ],
                [
                    169.6624911823451,
                    46.70324235716225
                ],
                [
                    168.40344193742112,
                    47.169165557064844
                ],
                [
                    169.64000816011435,
                    50.59780030948525
                ],
                [
                    168.38095891519015,
                    51.96705417758608
                ],
                [
                    168.20109473734396,
                    53.21296553512548
                ],
                [
                    166.1551397143421,
                    54.97014115819752
                ],
                [
                    163.83938842457087,
                    54.70181663811411
                ],
                [
                    162.91758451310852,
                    55.14086822048182
                ],
                [
                    161.25384086803024,
                    54.70181663811411
                ],
                [
                    159.54513117849024,
                    56.042961304102846
                ],
                [
                    160.93907855679913,
                    59.086590720615845
                ],
                [
                    161.61356922372283,
                    59.76752483940447
                ],
                [
                    162.51289011295444,
                    62.391125843137274
                ],
                [
                    160.7816974011837,
                    62.488209319185785
                ],
                [
                    159.68002931187516,
                    63.14336087915535
                ],
                [
                    159.94982557864455,
                    65.6400605402414
                ],
                [
                    160.7816974011837,
                    67.38290755556807
                ],
                [
                    162.55785615741615,
                    68.10850912256831
                ],
                [
                    164.46891304703308,
                    68.01178207462499
                ],
                [
                    167.61653615934347,
                    68.7129151229044
                ],
                [
                    166.46990202557322,
                    71.75551127855681
                ],
                [
                    167.14439269249692,
                    73.92512571740326
                ],
                [
                    169.28027980442175,
                    76.98153242932756
                ],
                [
                    170.1571176714226,
                    77.79876388548615
                ],
                [
                    167.52660407042026,
                    81.42306874600308
                ],
                [
                    165.63803020303408,
                    80.36784747903289
                ],
                [
                    163.79442238010938,
                    82.28590314150932
                ],
                [
                    162.94006753533927,
                    82.38174432709638
                ],
                [
                    162.6253052241084,
                    84.0101500161054
                ],
                [
                    161.43370504587665,
                    83.96227980826217
                ],
                [
                    160.66928229002974,
                    84.7997982543663
                ],
                [
                    158.8481574893358,
                    83.02851927297297
                ],
                [
                    157.65655731110428,
                    80.96749228521412
                ],
                [
                    157.43172708879615,
                    79.2879069299322
                ],
                [
                    155.9703306437948,
                    79.2879069299322
                ],
                [
                    152.98008868710008,
                    80.5837460863412
                ],
                [
                    152.10325082009945,
                    81.5429352661858
                ],
                [
                    150.84420157517548,
                    80.70367688304464
                ],
                [
                    152.2156659312534,
                    79.88796604314695
                ],
                [
                    151.94586966448378,
                    77.48634369150966
                ],
                [
                    149.4727372190971,
                    79.11984905002049
                ],
                [
                    148.3485861075576,
                    78.80769353942111
                ],
                [
                    147.69657846286486,
                    79.69597220535945
                ],
                [
                    146.48249526240215,
                    79.88796604314695
                ],
                [
                    145.04358183963177,
                    78.66360069822059
                ],
                [
                    145.29089508417064,
                    78.03904447629282
                ],
                [
                    143.4697702834767,
                    74.86434805482429
                ],
                [
                    142.4355512608604,
                    73.78057941221118
                ],
                [
                    140.4120792600895,
                    74.23826309741764
                ],
                [
                    138.43357330378012,
                    75.73081783944122
                ],
                [
                    137.51176939231777,
                    75.2976434304428
                ],
                [
                    136.52251641416296,
                    76.4525067757196
                ],
                [
                    135.57822948046987,
                    75.4420483335183
                ],
                [
                    133.75710467977592,
                    73.63601960847268
                ],
                [
                    131.68866663454355,
                    73.44325219621123
                ],
                [
                    130.56451552300405,
                    72.7442688729434
                ],
                [
                    129.8675418338496,
                    71.10403215067163
                ],
                [
                    128.85580583346427,
                    70.42813324983808
                ],
                [
                    128.2037981887711,
                    68.83376778306638
                ],
                [
                    124.22430325392179,
                    64.16192588598028
                ],
                [
                    121.39144245284228,
                    63.1918794136177
                ],
                [
                    120.82936689707276,
                    61.92989470587679
                ],
                [
                    120.73943480814955,
                    60.180799903666184
                ],
                [
                    119.34548742984066,
                    59.23253094553536
                ],
                [
                    117.54684565137768,
                    56.8714359911105
                ],
                [
                    117.16463427345434,
                    55.652933286405755
                ],
                [
                    114.84888298368287,
                    55.99421332157408
                ],
                [
                    112.24085240491172,
                    52.773350273745336
                ],
                [
                    110.89187107106432,
                    52.08924813068256
                ],
                [
                    109.45295764829393,
                    51.453730717698704
                ],
                [
                    108.21639142560048,
                    50.573337958388606
                ],
                [
                    107.78921400321519,
                    49.17832166734854
                ]
            ],
            [
                [
                    128.27124725546355,
                    109.55816255202666
                ],
                [
                    127.61923961077105,
                    108.99269911544303
                ],
                [
                    126.02294503238477,
                    107.93192878106038
                ],
                [
                    126.33770734361565,
                    104.320147682536
                ],
                [
                    125.48335249884599,
                    103.20903556689939
                ],
                [
                    124.15685418722933,
                    103.27998033778812
                ],
                [
                    123.19008423130526,
                    102.31013310405513
                ],
                [
                    122.605525653305,
                    100.81874744128868
                ],
                [
                    121.09916316384215,
                    102.64136567919769
                ],
                [
                    119.79514787445646,
                    102.7123352828504
                ],
                [
                    118.62603071845524,
                    99.34968978476525
                ],
                [
                    117.09718520676188,
                    97.04863556603516
                ],
                [
                    116.26531338422274,
                    94.45897474189468
                ],
                [
                    116.26531338422274,
                    93.15067291470228
                ],
                [
                    115.52337365060657,
                    91.53165183778276
                ],
                [
                    111.76870893806495,
                    89.33858650989373
                ],
                [
                    110.9368371155258,
                    89.24316710757074
                ],
                [
                    110.9818031599873,
                    89.05231107819549
                ],
                [
                    111.85864102698793,
                    88.28865706723838
                ],
                [
                    111.5213956935263,
                    86.30621034144457
                ],
                [
                    113.47741862760495,
                    85.66078137836263
                ],
                [
                    114.98378111706756,
                    83.79472260487671
                ],
                [
                    115.83813596183768,
                    83.8186604413624
                ],
                [
                    114.44418858352878,
                    81.78264080016584
                ]
            ],
            [
                [
                    129.17056814469515,
                    112.12386132629348
                ],
                [
                    129.2829832558491,
                    110.75912568444716
                ],
                [
                    128.27124725546355,
                    109.55816255202666
                ],
                [
                    129.62022858931095,
                    110.09988213639912
                ],
                [
                    130.3172022784654,
                    109.62883177232561
                ],
                [
                    131.91349685685122,
                    112.2179393338422
                ],
                [
                    134.0044179243148,
                    113.32295396423399
                ],
                [
                    134.07186699100703,
                    114.73253709535277
                ],
                [
                    133.35241027962184,
                    115.38993169112189
                ],
                [
                    134.61145952454604,
                    116.68048380076033
                ],
                [
                    137.53425241454852,
                    117.71220363196386
                ],
                [
                    139.24296210408852,
                    117.43088900364017
                ],
                [
                    140.07483392662766,
                    118.53243306865761
                ],
                [
                    140.0073848599352,
                    118.15752119008204
                ]
            ],
            [
                [
                    44.971649890392655,
                    213.16006596611408
                ],
                [
                    42.40858535608277,
                    208.88476354534936
                ],
                [
                    41.689128644697576,
                    205.49365309208832
                ],
                [
                    39.66565664392647,
                    203.3936150584725
                ],
                [
                    38.40660739900227,
                    201.44796909848702
                ],
                [
                    38.54150553238719,
                    196.90060460182877
                ],
                [
                    37.574735576463354,
                    196.2725488307433
                ],
                [
                    36.42810144269288,
                    196.7884666727324
                ],
                [
                    34.31469735299879,
                    196.94545796243835
                ],
                [
                    32.583504641228046,
                    196.29498289111513
                ],
                [
                    30.08788917361062,
                    192.6123184673483
                ],
                [
                    29.863058951302946,
                    190.25081563943877
                ],
                [
                    30.69493077384186,
                    189.05771361222082
                ],
                [
                    31.18955726291938,
                    187.50331256193084
                ],
                [
                    31.34693841853459,
                    185.47392389778616
                ],
                [
                    31.18955726291938,
                    182.5613384056578
                ],
                [
                    29.458364551148634,
                    181.77032623342234
                ],
                [
                    26.962749083531207,
                    182.1093721422248
                ],
                [
                    25.568801705222086,
                    181.79293119452444
                ],
                [
                    24.51209966037527,
                    180.86590486368925
                ],
                [
                    21.43192561475712,
                    179.4179236398544
                ],
                [
                    21.049714236833665,
                    178.39914103405306
                ],
                [
                    21.47689165921861,
                    175.02186096690298
                ],
                [
                    22.556076726296624,
                    172.13837919308935
                ],
                [
                    26.62550375006913,
                    168.52197948114008
                ],
                [
                    27.659722772685427,
                    166.95037560349863
                ],
                [
                    29.00870410653306,
                    165.87908743327858
                ],
                [
                    30.58251566268791,
                    161.83898375903215
                ],
                [
                    31.95398001876606,
                    160.26158033940476
                ],
                [
                    34.08986713069089,
                    158.40808022470634
                ],
                [
                    36.090856109231254,
                    157.99593131913525
                ],
                [
                    37.282456287462765,
                    158.43097460883035
                ],
                [
                    38.58647157684891,
                    159.91848373139692
                ],
                [
                    38.676403665771886,
                    161.47333189734434
                ],
                [
                    39.77807175508042,
                    163.23235314168426
                ],
                [
                    41.958924911466966,
                    163.43784143100714
                ],
                [
                    46.34311424647058,
                    161.33619334841933
                ],
                [
                    48.95114482524218,
                    160.71894079876108
                ],
                [
                    53.132986960168864,
                    160.62747799697928
                ],
                [
                    56.19067798355604,
                    159.3465105819941
                ],
                [
                    56.28061007247925,
                    160.0328565357271
                ],
                [
                    56.977583761633696,
                    156.5755809065153
                ],
                [
                    57.96683673978828,
                    155.131164415802
                ],
                [
                    59.96782571832864,
                    153.38713403927807
                ],
                [
                    61.06949380763717,
                    152.03203427356738
                ],
                [
                    62.440958163715095,
                    147.43073163963157
                ],
                [
                    63.96980367540891,
                    145.51765121193375
                ],
                [
                    67.76943443241203,
                    143.6717363879684
                ],
                [
                    68.80365345502832,
                    142.74804598566402
                ],
                [
                    71.92879354510774,
                    141.40782331031255
                ],
                [
                    72.8056314121086,
                    139.30297181579255
                ],
                [
                    73.97474856810959,
                    137.91375543507496
                ],
                [
                    76.89754145811207,
                    132.22944973205313
                ],
                [
                    78.58376812542133,
                    125.89644839908289
                ],
                [
                    81.50656101542381,
                    124.65968142679304
                ],
                [
                    84.58673506104174,
                    123.8424503411461
                ],
                [
                    86.96993541750544,
                    121.69240234347961
                ],
                [
                    87.08235052865939,
                    122.95472088509376
                ],
                [
                    86.02564848381235,
                    124.12268857663673
                ],
                [
                    85.89075035042742,
                    125.42985046644867
                ],
                [
                    91.8262682193556,
                    125.75648256996413
                ],
                [
                    94.02960439797312,
                    125.87312156713011
                ],
                [
                    95.17623853174314,
                    125.98975250270416
                ],
                [
                    96.95239728797537,
                    126.47951446254183
                ],
                [
                    97.80675213274526,
                    125.40651718097536
                ],
                [
                    99.38056368890057,
                    125.10315507679042
                ],
                [
                    101.0667903562096,
                    125.82646693581137
                ],
                [
                    102.10100937882589,
                    127.22554551373196
                ],
                [
                    103.87716813505813,
                    127.83145354710103
                ],
                [
                    104.95635320213614,
                    127.62174069602156
                ],
                [
                    105.69829293575208,
                    129.20559542975303
                ],
                [
                    104.61910786867429,
                    130.62514987529534
                ],
                [
                    106.59761382498368,
                    132.43859502012322
                ],
                [
                    107.2271384474459,
                    130.1365845891346
                ],
                [
                    108.9133651147547,
                    128.25080136178462
                ],
                [
                    110.66704084875619,
                    129.39183408431268
                ],
                [
                    112.73547889398878,
                    128.13432626945752
                ],
                [
                    113.5898337387589,
                    127.99454558844337
                ],
                [
                    116.06296618414558,
                    129.5780523161945
                ],
                [
                    117.56932867360842,
                    129.27543731921543
                ],
                [
                    118.28878538499362,
                    128.20421228558297
                ],
                [
                    119.99749507453339,
                    127.69164786603676
                ],
                [
                    119.3679704520714,
                    126.22299020024639
                ],
                [
                    120.58205365253389,
                    123.46872668535616
                ],
                [
                    122.87532192007438,
                    122.51068010213277
                ],
                [
                    123.70719374261375,
                    123.30519644722006
                ],
                [
                    125.32597134323032,
                    123.25847059801043
                ],
                [
                    126.22529223246192,
                    122.48730627901114
                ],
                [
                    126.29274129915439,
                    122.44055765559403
                ],
                [
                    125.10114112092242,
                    120.87372464136291
                ],
                [
                    127.30447729953971,
                    119.44592752306795
                ],
                [
                    127.84406983307872,
                    117.68876256369498
                ],
                [
                    130.24975321177294,
                    117.73564436989062
                ],
                [
                    130.04740601169578,
                    117.40744396948793
                ],
                [
                    130.18230414508048,
                    117.17297543907432
                ],
                [
                    130.4521004118501,
                    114.59163285058702
                ],
                [
                    129.0131869890797,
                    113.36995944955044
                ],
                [
                    129.17056814469515,
                    112.12386132629348
                ],
                [
                    127.28199427730874,
                    112.0062562609989
                ],
                [
                    127.7091716996938,
                    113.18192947641796
                ],
                [
                    129.0131869890797,
                    113.36995944955044
                ]
            ],
            [
                [
                    95.17623853174314,
                    125.98975250270416
                ],
                [
                    95.6933480430514,
                    127.20223702531388
                ],
                [
                    94.5691969315119,
                    128.13432626945752
                ],
                [
                    94.50174786481944,
                    129.29871731057636
                ],
                [
                    96.50273684335957,
                    129.18231416139866
                ],
                [
                    95.89569524312856,
                    132.3688827708129
                ],
                [
                    96.592668932283,
                    132.9265011127074
                ],
                [
                    96.03059337651302,
                    134.1572644247558
                ],
                [
                    96.30038964328264,
                    135.73506320071198
                ],
                [
                    99.02083533320797,
                    135.7814470961356
                ],
                [
                    99.94263924467032,
                    135.08555708148975
                ],
                [
                    101.22417151182526,
                    135.7814470961356
                ],
                [
                    101.56141684528711,
                    137.14920940294525
                ],
                [
                    102.88791515690355,
                    137.0565141006938
                ],
                [
                    103.33757560151935,
                    138.02956617301504
                ],
                [
                    105.67580991352133,
                    137.49677236957177
                ],
                [
                    107.2271384474459,
                    137.10286237545995
                ],
                [
                    108.10397631444653,
                    137.49677236957177
                ],
                [
                    107.90162911436937,
                    140.82982202635327
                ],
                [
                    108.6435688479853,
                    140.89919234891937
                ],
                [
                    109.9026180929095,
                    142.3553291189566
                ],
                [
                    109.11571231483185,
                    143.11758092813028
                ],
                [
                    109.79020298175556,
                    144.66415809144922
                ],
                [
                    110.39724458198702,
                    148.37497825570864
                ],
                [
                    111.29656547121863,
                    148.5591623858453
                ],
                [
                    111.99353916037285,
                    149.9399296807117
                ],
                [
                    115.27606040606793,
                    152.1009626481482
                ],
                [
                    117.65926076253163,
                    154.92472368145167
                ],
                [
                    116.82738893999226,
                    156.55266278478518
                ],
                [
                    116.96228707337718,
                    157.629497152471
                ],
                [
                    116.44517756206892,
                    159.73547211814935
                ],
                [
                    115.86061898406842,
                    159.8041036553122
                ],
                [
                    116.42269453983818,
                    161.0618849696438
                ],
                [
                    117.97402307376274,
                    161.2447618574655
                ],
                [
                    119.43541951876387,
                    161.95323478167535
                ],
                [
                    120.15487623014906,
                    161.3819073562314
                ],
                [
                    119.32300440760991,
                    160.21583790382152
                ],
                [
                    119.18810627422499,
                    158.63701091900003
                ],
                [
                    120.67198574145709,
                    157.49206501097768
                ],
                [
                    121.52634058622698,
                    158.33939531887165
                ],
                [
                    122.13338218645845,
                    156.5297443691494
                ],
                [
                    121.43640849730377,
                    155.63569669182777
                ],
                [
                    124.83134485415303,
                    158.0417303214009
                ],
                [
                    124.7638957874608,
                    159.0261260909095
                ],
                [
                    126.67495267707773,
                    158.38518554835213
                ],
                [
                    126.94474894384712,
                    157.14843849105966
                ],
                [
                    128.54104352223317,
                    156.14008631568345
                ],
                [
                    129.39539836700305,
                    154.92472368145167
                ],
                [
                    131.10410805654283,
                    156.30054404341928
                ],
                [
                    130.51954947854256,
                    158.70568443188802
                ],
                [
                    130.69941365638874,
                    161.22190326057995
                ],
                [
                    129.9574739227728,
                    163.0496773077897
                ],
                [
                    131.21652316769678,
                    164.35084096151058
                ],
                [
                    132.2057761458516,
                    161.58760606071945
                ],
                [
                    135.71312761385457,
                    161.95323478167535
                ],
                [
                    136.61244850308617,
                    161.10760593024867
                ],
                [
                    137.73659961462545,
                    161.01616284966627
                ],
                [
                    139.33289419301173,
                    159.8269802523929
                ],
                [
                    139.51275837085768,
                    158.7743553171195
                ],
                [
                    138.54598841493407,
                    157.1942591726281
                ],
                [
                    138.90571677062644,
                    155.17703688688727
                ],
                [
                    138.9731658373189,
                    152.4225929493748
                ],
                [
                    136.6574145475479,
                    149.9169257528721
                ],
                [
                    135.9154748139315,
                    149.13461360098
                ],
                [
                    136.56748245862468,
                    148.42102609597526
                ],
                [
                    136.20775410293209,
                    147.89140312230205
                ],
                [
                    135.93795783616224,
                    146.7625432121597
                ],
                [
                    134.90373881354617,
                    145.7482535455273
                ],
                [
                    134.61145952454604,
                    145.37927522161073
                ],
                [
                    134.8138067246232,
                    144.71030351227415
                ],
                [
                    134.72387463569999,
                    144.34110600089326
                ],
                [
                    134.9711878802384,
                    143.2330445174532
                ],
                [
                    134.0044179243148,
                    141.3153560411718
                ],
                [
                    133.64468956862197,
                    139.07151331879635
                ],
                [
                    133.82455374646838,
                    136.87110852534937
                ],
                [
                    133.4198593463143,
                    135.9901591315476
                ],
                [
                    133.48730841300676,
                    133.7161491708291
                ],
                [
                    133.1050970350832,
                    131.2065924218739
                ],
                [
                    133.7795877020069,
                    130.27618898977073
                ],
                [
                    133.30744423516035,
                    129.13575066675685
                ],
                [
                    134.09435001323777,
                    128.29738915722186
                ],
                [
                    134.88125579131543,
                    126.01307772267364
                ],
                [
                    138.2537091259337,
                    122.20679498915416
                ],
                [
                    140.11979997108915,
                    119.75031452155741
                ],
                [
                    140.07483392662766,
                    118.53243306865761
                ]
            ],
            [
                [
                    159.2078858450284,
                    103.0434857042705
                ],
                [
                    160.12968975649073,
                    101.6237929590356
                ],
                [
                    161.05149366795308,
                    101.4107316651909
                ],
                [
                    162.01826362387692,
                    100.08438572457953
                ],
                [
                    162.98503357980098,
                    100.46345281633813
                ],
                [
                    163.41221100218604,
                    101.7184778641564
                ],
                [
                    164.37898095810988,
                    102.19181934549187
                ],
                [
                    164.22159980249444,
                    103.44550607733402
                ],
                [
                    165.30078486957223,
                    104.1783458894518
                ],
                [
                    165.63803020303408,
                    105.59580789574449
                ],
                [
                    166.94204549241954,
                    106.65809551250425
                ],
                [
                    168.40344193742112,
                    107.36590326173149
                ],
                [
                    169.7973893157298,
                    109.06339271365539
                ],
                [
                    171.70844620534695,
                    108.28559527553597
                ],
                [
                    173.66446913942536,
                    108.37989343279736
                ],
                [
                    174.0241974951182,
                    109.32257648889066
                ],
                [
                    176.45236389604315,
                    110.594339598023
                ],
                [
                    177.14933758519783,
                    111.53575198992178
                ],
                [
                    178.20603963004464,
                    111.20631895110603
                ],
                [
                    179.33019074158415,
                    112.28849431440818
                ],
                [
                    178.79059820804537,
                    115.03778847601637
                ],
                [
                    181.80332318697083,
                    116.04706552339536
                ],
                [
                    183.6019649654338,
                    117.36055290924469
                ],
                [
                    184.59121794358862,
                    116.96192547323142
                ],
                [
                    186.07509741082072,
                    117.87628186154672
                ],
                [
                    187.4690447891296,
                    119.46934387887973
                ],
                [
                    190.3468716346706,
                    120.42913177263642
                ],
                [
                    191.02136230159408,
                    121.41175794929154
                ],
                [
                    189.31265261205408,
                    122.01976138595461
                ],
                [
                    186.86200318889814,
                    124.14603965546218
                ],
                [
                    185.58047092174343,
                    125.7098247132264
                ],
                [
                    184.5012858546654,
                    125.7098247132264
                ],
                [
                    183.8043121655112,
                    127.52852664481651
                ],
                [
                    182.05063643150947,
                    128.29738915722186
                ],
                [
                    181.03890043112415,
                    129.39183408431268
                ],
                [
                    181.66842505358613,
                    131.8343275813533
                ],
                [
                    180.67917207543155,
                    133.27492019960215
                ],
                [
                    179.2177756304302,
                    134.3893847227717
                ],
                [
                    180.3419267419697,
                    136.80157628657497
                ],
                [
                    179.46508887496884,
                    138.72426744580866
                ],
                [
                    177.7788622076598,
                    140.15909908653663
                ],
                [
                    177.39665082973625,
                    142.19359639870856
                ],
                [
                    176.74464318504351,
                    142.86353408128753
                ],
                [
                    176.60974505165882,
                    144.61801145150974
                ]
            ],
            [
                [
                    86.96993541750544,
                    121.69240234347961
                ],
                [
                    86.85752030635149,
                    120.38232564417501
                ],
                [
                    85.66592012811952,
                    118.86041175484712
                ],
                [
                    86.92496937304372,
                    117.75908477749823
                ],
                [
                    86.90248635081298,
                    116.72739405857305
                ],
                [
                    90.61218501889289,
                    113.20543439450836
                ],
                [
                    92.8155211975104,
                    109.74660704111204
                ],
                [
                    96.21045755435944,
                    107.27154661542465
                ],
                [
                    97.85171817720698,
                    106.68169404820247
                ],
                [
                    97.58192191043759,
                    105.71387404084538
                ],
                [
                    94.95140830943524,
                    105.6430353804256
                ],
                [
                    95.55844990966648,
                    101.64746470461534
                ],
                [
                    97.01984635466783,
                    100.06069107617088
                ],
                [
                    96.43528777666711,
                    99.32598434313269
                ],
                [
                    94.83899319828129,
                    95.17214297540556
                ],
                [
                    95.67086502082066,
                    94.86314242526413
                ],
                [
                    95.85072919866684,
                    93.46000460602579
                ],
                [
                    97.24467657697573,
                    92.0080052692386
                ],
                [
                    99.29063159997736,
                    91.31724628620015
                ],
                [
                    100.86444315613267,
                    89.91098244958312
                ],
                [
                    102.3258396011338,
                    90.05404918961057
                ],
                [
                    103.94461720175059,
                    89.26702249631381
                ],
                [
                    104.7090399575975,
                    89.5055566542701
                ],
                [
                    107.72176493652296,
                    87.50075297597738
                ],
                [
                    107.7442479587537,
                    85.99548120594443
                ],
                [
                    107.15968938075343,
                    84.65625530931538
                ]
            ],
            [
                [
                    128.2037981887711,
                    68.83376778306638
                ],
                [
                    127.84406983307872,
                    70.09007254820813
                ],
                [
                    126.33770734361565,
                    70.40398851811904
                ],
                [
                    125.21355623207637,
                    69.55868423644347
                ],
                [
                    122.87532192007438,
                    69.96931858990149
                ],
                [
                    121.14412920830364,
                    71.41774163535979
                ],
                [
                    120.10991018568734,
                    72.86480578518058
                ],
                [
                    118.49113258507077,
                    73.12995391464801
                ],
                [
                    118.13140422937818,
                    73.99739380976803
                ],
                [
                    116.17538129529953,
                    75.46611451101239
                ],
                [
                    114.64653578360571,
                    74.47909493760369
                ],
                [
                    113.92707907222075,
                    75.24950547619221
                ],
                [
                    115.72572085068373,
                    77.65457773821737
                ],
                [
                    115.63578876176052,
                    79.64797005916375
                ],
                [
                    114.44418858352878,
                    81.78264080016584
                ],
                [
                    112.84789400514273,
                    82.81295723342777
                ],
                [
                    112.24085240491172,
                    83.89047176438032
                ],
                [
                    110.91435409329483,
                    84.10588606130119
                ],
                [
                    109.6777878706016,
                    85.3260104983832
                ],
                [
                    107.15968938075343,
                    84.65625530931538
                ],
                [
                    105.15870040221307,
                    83.41166763925168
                ],
                [
                    103.71978697944269,
                    82.66923276206631
                ],
                [
                    101.35906964520996,
                    82.90876623794958
                ],
                [
                    100.50471480044007,
                    81.61485077657295
                ],
                [
                    100.21243551143971,
                    82.21401840921243
                ],
                [
                    97.44702377705266,
                    82.11816697405959
                ],
                [
                    96.81749915459068,
                    80.00795021391701
                ],
                [
                    97.13226146582178,
                    76.95748971230643
                ],
                [
                    94.99637435389673,
                    77.72667248041842
                ],
                [
                    94.00712137574214,
                    76.93344662368224
                ],
                [
                    93.06283444204905,
                    77.48634369150966
                ],
                [
                    90.05010946312336,
                    75.2976434304428
                ],
                [
                    88.31891675135262,
                    74.84027253966997
                ],
                [
                    86.81255426188977,
                    73.46734943608578
                ],
                [
                    84.92398039450359,
                    73.10585141800277
                ],
                [
                    82.85554234927099,
                    71.70726299935961
                ],
                [
                    81.95622146003961,
                    71.6590132115141
                ],
                [
                    82.38339888242444,
                    69.58284223280737
                ],
                [
                    81.03441754857727,
                    67.2377460317474
                ],
                [
                    81.86628937111618,
                    63.14336087915535
                ],
                [
                    80.98945150411555,
                    62.318309170878365
                ],
                [
                    81.52904403765456,
                    60.69116073294845
                ],
                [
                    80.9669684818848,
                    59.57300346157649
                ],
                [
                    81.39414590426986,
                    57.67510909065527
                ],
                [
                    80.40489292611528,
                    55.872336467055106
                ],
                [
                    79.91026643703776,
                    53.04201934623245
                ],
                [
                    79.97771550373022,
                    51.69819239932076
                ],
                [
                    78.47135301426738,
                    48.95793676279209
                ],
                [
                    79.30322483680652,
                    45.40278954661551
                ],
                [
                    81.82132332665469,
                    45.18184479861884
                ],
                [
                    83.7548632385026,
                    41.593019937985446
                ],
                [
                    86.90248635081298,
                    40.6082579107599
                ],
                [
                    88.52126395142977,
                    38.85868333764142
                ],
                [
                    88.40884884027582,
                    36.90952426538581
                ],
                [
                    89.60044901850756,
                    36.02044055302349
                ]
            ],
            [
                [
                    139.6476565042426,
                    314.0820644185967
                ],
                [
                    140.38959623785877,
                    314.3375106050478
                ],
                [
                    139.8275206820888,
                    315.57182523303993
                ],
                [
                    140.34463019339705,
                    317.16708521653703
                ],
                [
                    139.6026904597809,
                    317.7411489488714
                ],
                [
                    141.19898503816717,
                    319.0801600616893
                ],
                [
                    140.16476601555087,
                    320.03619453787593
                ],
                [
                    139.44530930416568,
                    322.0109438162972
                ],
                [
                    138.20874308147222,
                    323.32665785726863
                ],
                [
                    138.0738449480873,
                    325.0234314278245
                ],
                [
                    138.41109028154938,
                    325.12944543634273
                ],
                [
                    138.81578468170324,
                    325.2990594705033
                ],
                [
                    141.626162460552,
                    326.14697519187723
                ],
                [
                    139.08558094847285,
                    327.3971825412574
                ],
                [
                    138.6584035260878,
                    328.5621296847546
                ],
                [
                    137.1295580143942,
                    330.5943424977285
                ],
                [
                    136.9047277920863,
                    331.58875029975655
                ],
                [
                    138.36612423708766,
                    332.32904178301135
                ],
                [
                    138.86075072616495,
                    333.36512852903206
                ],
                [
                    138.41109028154938,
                    334.71782267543756
                ],
                [
                    138.2986751703952,
                    335.79529735895176
                ],
                [
                    138.54598841493407,
                    337.27353306835386
                ],
                [
                    137.8040486813179,
                    338.3078521971803
                ],
                [
                    138.20874308147222,
                    339.9113802884659
                ],
                [
                    137.6691505479332,
                    341.429707334981
                ],
                [
                    135.10608601362333,
                    342.1042694246895
                ],
                [
                    135.78057668054703,
                    343.094754982906
                ],
                [
                    137.64666752570247,
                    343.7268077548465
                ],
                [
                    138.748335615011,
                    343.45293464721277
                ],
                [
                    140.27718112670482,
                    344.1059752606034
                ],
                [
                    138.90571677062644,
                    345.95899340849985
                ],
                [
                    139.1080639707036,
                    350.4604672499446
                ]
            ],
            [
                [
                    356.42895685350436,
                    220.11037835966232
                ],
                [
                    355.3048057419651,
                    219.44523983538272
                ],
                [
                    355.9118473421963,
                    218.26963341153646
                ],
                [
                    355.23735667527285,
                    215.84974572626857
                ],
                [
                    355.82191525327335,
                    213.11557837840127
                ],
                [
                    356.42895685350436,
                    212.15885773427692
                ],
                [
                    355.52963596427276,
                    209.53109699146876
                ],
                [
                    356.47392289796585,
                    209.2636740090482
                ],
                [
                    357.755455165121,
                    210.3108766766228
                ],
                [
                    359.6665120547382,
                    209.84304530415403
                ],
                [
                    358.3400137431215,
                    207.41301734077405
                ],
                [
                    355.0125264529647,
                    205.87320944735495
                ],
                [
                    353.281333741194,
                    204.91301450679902
                ],
                [
                    352.3370468075009,
                    204.82367038178552
                ],
                [
                    348.35755187265136,
                    205.11402404783905
                ],
                [
                    346.82870636095777,
                    205.87320944735495
                ],
                [
                    346.2441477829575,
                    205.06935702425443
                ],
                [
                    344.6253701823405,
                    205.71693033369343
                ],
                [
                    342.9616265372622,
                    205.02468899318632
                ],
                [
                    342.6018981815696,
                    204.10877208671786
                ],
                [
                    341.09553569210675,
                    204.68964663164456
                ],
                [
                    338.57743720225835,
                    204.62263135230944
                ],
                [
                    336.9361765794108,
                    205.0916906619729
                ],
                [
                    335.2499499121018,
                    205.3150131903286
                ],
                [
                    334.148281822793,
                    204.91301450679902
                ],
                [
                    332.8667495556381,
                    205.3150131903286
                ],
                [
                    330.19126991017447,
                    205.15869006408747
                ],
                [
                    329.2244999542504,
                    205.5606389046763
                ],
                [
                    327.5607563091721,
                    204.77899680690325
                ],
                [
                    326.12184288640174,
                    204.60029242140507
                ],
                [
                    324.32320110793876,
                    204.26517817618077
                ],
                [
                    322.29972910716765,
                    203.03593932905312
                ],
                [
                    320.253774084166,
                    203.0806523435325
                ],
                [
                    319.96149479516566,
                    201.58221337525544
                ],
                [
                    320.7933666177048,
                    200.14980075221837
                ]
            ],
            [
                [
                    232.43508925070637,
                    169.6372268965
                ],
                [
                    233.10957991763007,
                    170.63809608678525
                ],
                [
                    234.36862916255427,
                    172.29742848697998
                ],
                [
                    233.94145174016944,
                    173.79636286686815
                ],
                [
                    235.2005009850934,
                    174.45460150978084
                ],
                [
                    235.35788214070885,
                    177.03989914800133
                ],
                [
                    238.28067503071134,
                    178.39914103405306
                ],
                [
                    239.31489405332763,
                    180.39090925889997
                ],
                [
                    240.61890934271332,
                    181.2503371251491
                ],
                [
                    238.59543734194244,
                    181.77032623342234
                ],
                [
                    236.12230489655576,
                    181.27294833814375
                ],
                [
                    235.22298400732416,
                    182.5613384056578
                ],
                [
                    233.22199502878402,
                    183.37460469415583
                ],
                [
                    233.10957991763007,
                    184.27782320613
                ],
                [
                    236.7743125412485,
                    185.451362948079
                ],
                [
                    237.06659183024885,
                    187.27793186024098
                ],
                [
                    235.7400935186322,
                    188.02158700415328
                ],
                [
                    235.29043307401662,
                    189.9807444587998
                ],
                [
                    238.82026756425034,
                    192.49993170747035
                ],
                [
                    239.67462240902023,
                    192.27513850395394
                ],
                [
                    241.40581512079075,
                    193.53364274333688
                ],
                [
                    243.65411734386976,
                    195.0158271675752
                ],
                [
                    243.29438898817716,
                    195.98088244922798
                ],
                [
                    242.66486436571495,
                    196.83332262062044
                ],
                [
                    239.40482614225084,
                    195.80137365201693
                ],
                [
                    239.15751289771197,
                    197.03516157986718
                ],
                [
                    237.49376925263368,
                    197.01273606343653
                ],
                [
                    236.52699929670985,
                    196.00331988104625
                ],
                [
                    235.56022934078578,
                    196.5866020947537
                ],
                [
                    235.650161429709,
                    197.55087712615182
                ],
                [
                    233.44682525109192,
                    198.82836668351786
                ],
                [
                    232.2102590283987,
                    200.84375624283257
                ],
                [
                    231.2210060502439,
                    201.42559416036562
                ],
                [
                    230.65893049447413,
                    201.22420824429963
                ],
                [
                    229.30994916062673,
                    202.38748656767723
                ],
                [
                    227.66868853777942,
                    202.87943579019833
                ],
                [
                    224.90327680339237,
                    204.91301450679902
                ],
                [
                    224.92575982562312,
                    207.747600522261
                ],
                [
                    225.57776747031585,
                    211.44658443479636
                ],
                [
                    226.949231826394,
                    212.18111221241656
                ],
                [
                    227.55627342662524,
                    213.67160276611997
                ],
                [
                    227.46634133770203,
                    215.18321592207457
                ],
                [
                    227.15157902647115,
                    215.58316007640576
                ],
                [
                    225.6452165370083,
                    216.02745005059455
                ],
                [
                    225.35293724800817,
                    219.0016941412979
                ],
                [
                    225.780114670393,
                    219.75566467309886
                ],
                [
                    224.610997514392,
                    222.41452584882916
                ]
            ],
            [
                [
                    75.84083941326503,
                    285.61349504214576
                ],
                [
                    74.71668830172575,
                    284.3861938065795
                ],
                [
                    75.1888317685723,
                    282.98588576889335
                ],
                [
                    73.36770696787835,
                    282.4039821976396
                ],
                [
                    71.74892936726178,
                    282.8781363706169
                ],
                [
                    72.17610678964661,
                    284.23543007389594
                ]
            ],
            [
                [
                    231.42335325032082,
                    302.81799278820716
                ],
                [
                    230.7488625833971,
                    303.2027941476589
                ],
                [
                    230.88376071678204,
                    304.48505273924457
                ]
            ],
            [
                [
                    230.88376071678204,
                    304.48505273924457
                ],
                [
                    229.5122963607041,
                    301.5562973356006
                ],
                [
                    228.7029075603955,
                    301.5990767850153
                ],
                [
                    226.83681671523982,
                    302.5614267274125
                ],
                [
                    226.52205440400894,
                    303.7798889661604
                ],
                [
                    224.8807937811614,
                    302.81799278820716
                ]
            ],
            [
                [
                    224.8807937811614,
                    302.81799278820716
                ],
                [
                    224.43113333654583,
                    303.5020445442596
                ],
                [
                    225.82508071485472,
                    305.0618626336847
                ],
                [
                    222.76738969146732,
                    306.94095057738656
                ],
                [
                    221.755653691082,
                    306.42860602527935
                ],
                [
                    219.75466471254163,
                    309.18128311579073
                ],
                [
                    220.4066723572348,
                    310.1621627018085
                ],
                [
                    220.04694400154176,
                    311.52626098958984
                ],
                [
                    220.90129884631187,
                    312.3571653871958
                ]
            ],
            [
                [
                    220.90129884631187,
                    312.3571653871958
                ],
                [
                    219.30500426792582,
                    313.01744329564167
                ],
                [
                    218.20333617861706,
                    312.6979743644775
                ],
                [
                    216.92180391146235,
                    311.61149388371393
                ],
                [
                    216.42717742238506,
                    313.31557986852647
                ],
                [
                    213.68424871022876,
                    314.31622435089565
                ],
                [
                    213.97652799922912,
                    312.9322553139194
                ],
                [
                    212.20036924299689,
                    310.4179845538481
                ],
                [
                    211.346014398227,
                    311.07874347819785
                ]
            ],
            [
                [
                    240.68635840940578,
                    303.88674427768785
                ],
                [
                    240.8662225872522,
                    304.2713869696947
                ],
                [
                    239.15751289771197,
                    306.38590611835997
                ],
                [
                    237.7635655194033,
                    306.21509951831786
                ],
                [
                    235.7850595630939,
                    306.85556677976746
                ],
                [
                    234.99815378501626,
                    305.93751498421256
                ],
                [
                    232.86226667309143,
                    305.9588686878389
                ],
                [
                    231.42335325032082,
                    302.81799278820716
                ]
            ],
            [
                [
                    244.77826845540926,
                    301.2568212788392
                ],
                [
                    243.631634321639,
                    301.7701874765389
                ],
                [
                    243.15949085479224,
                    302.7324735982772
                ],
                [
                    242.03533974325296,
                    302.3262187821396
                ],
                [
                    240.68635840940578,
                    303.88674427768785
                ]
            ],
            [
                [
                    211.346014398227,
                    311.07874347819785
                ],
                [
                    210.37924444230293,
                    312.0802260391547
                ],
                [
                    210.69400675353404,
                    312.91095789438015
                ],
                [
                    209.6822707531485,
                    315.74203115988996
                ],
                [
                    210.2218632866875,
                    315.93349999984366
                ],
                [
                    209.74971981984095,
                    317.91121861275644
                ],
                [
                    208.7379838194554,
                    318.7189044116174
                ],
                [
                    207.52390061899268,
                    317.7836673622894
                ],
                [
                    205.52291164045255,
                    317.52854690197216
                ],
                [
                    204.75848888460587,
                    318.40010968259304
                ]
            ],
            [
                [
                    204.75848888460587,
                    318.40010968259304
                ],
                [
                    202.2628734169882,
                    319.2713937015509
                ],
                [
                    200.8914090609103,
                    320.5247055081348
                ],
                [
                    199.43001261590916,
                    321.3316201920964
                ],
                [
                    196.19245741467557,
                    321.41654480746115
                ]
            ],
            [
                [
                    320.253774084166,
                    184.81954730098056
                ],
                [
                    320.36618919532,
                    185.33855417263595
                ]
            ],
            [
                [
                    320.50108732870444,
                    180.16467860276208
                ],
                [
                    320.77088359547406,
                    181.54426168082517
                ],
                [
                    320.253774084166,
                    184.81954730098056
                ]
            ],
            [
                [
                    320.36618919532,
                    185.33855417263595
                ],
                [
                    319.4668683060884,
                    185.83486260179404
                ],
                [
                    318.27526812785663,
                    188.0441174790722
                ],
                [
                    318.70244555024146,
                    188.80999535560653
                ]
            ],
            [
                [
                    136.6574145475479,
                    149.9169257528721
                ],
                [
                    135.28595019146974,
                    150.53792659275922
                ],
                [
                    134.11683303546852,
                    151.8482121606139
                ]
            ],
            [
                [
                    134.11683303546852,
                    151.8482121606139
                ],
                [
                    133.30744423516035,
                    150.44594024966932
                ],
                [
                    132.9701989016985,
                    150.10094877889287
                ],
                [
                    131.64370059008206,
                    149.5718308300514
                ],
                [
                    129.84505881161886,
                    150.2159534267281
                ]
            ],
            [
                [
                    129.84505881161886,
                    150.2159534267281
                ],
                [
                    129.2829832558491,
                    149.41076341694048
                ],
                [
                    129.91250787831132,
                    148.0526096121319
                ],
                [
                    130.8567948120044,
                    147.45376808351426
                ],
                [
                    131.1265910787738,
                    146.9468964709858
                ],
                [
                    130.69941365638874,
                    145.263953533292
                ]
            ],
            [
                [
                    130.69941365638874,
                    145.263953533292
                ],
                [
                    131.08162503431208,
                    145.03328733715534
                ],
                [
                    133.3299272573911,
                    144.24879442335947
                ],
                [
                    134.72387463569999,
                    144.34110600089326
                ]
            ],
            [
                [
                    294.9603740745292,
                    164.94404441354504
                ],
                [
                    296.1744572749917,
                    162.11317407409388
                ],
                [
                    296.28687238614566,
                    159.5753216775937
                ],
                [
                    300.153952209841,
                    158.31649976570378
                ],
                [
                    300.5586466099953,
                    157.69820926835
                ],
                [
                    302.37977141068905,
                    156.96514402845133
                ],
                [
                    304.40324341146015,
                    157.33171417818147
                ],
                [
                    305.6847756786151,
                    158.7285750188995
                ],
                [
                    305.57236056746115,
                    159.78122676725843
                ],
                [
                    306.3367833233078,
                    160.9933013548614
                ],
                [
                    305.8646398564615,
                    161.6561670861194
                ],
                [
                    305.75222474530756,
                    164.1226341649687
                ],
                [
                    304.5156585226141,
                    165.83348672606246
                ],
                [
                    304.94283594499916,
                    168.29429450247613
                ]
            ],
            [
                [
                    293.8362229629895,
                    171.54750449671536
                ],
                [
                    293.92615505191293,
                    167.26936110379586
                ],
                [
                    294.9603740745292,
                    164.94404441354504
                ]
            ],
            [
                [
                    304.94283594499916,
                    168.29429450247613
                ],
                [
                    306.78644376792363,
                    169.88749534815088
                ],
                [
                    306.5616135456157,
                    170.6835771869915
                ],
                [
                    305.0777340783836,
                    171.0928564767043
                ],
                [
                    304.9203529227684,
                    172.11565674553248
                ],
                [
                    303.8861339001521,
                    172.88806299949783
                ]
            ]
        ],
        "id": "TT-states"
    }
];

export const STATE_WISE_MAP_PLOT = [
    {
        "type": "Feature",
        "id": "TT-Mizoram",
        "properties": {
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            368.5922718803613,
                            239.9883983544513
                        ],
                        [
                            368.9295172138229,
                            241.23970066169045
                        ],
                        [
                            368.2100605024375,
                            243.4989926765191
                        ],
                        [
                            368.165094457976,
                            244.7482716429098
                        ],
                        [
                            368.8845511693612,
                            246.741254343901
                        ],
                        [
                            368.72717001374576,
                            248.51370578623175
                        ],
                        [
                            369.5590418362849,
                            249.25727282871833
                        ],
                        [
                            369.71642299190034,
                            251.5956918893612
                        ],
                        [
                            369.1543474361306,
                            253.1459951051233
                        ],
                        [
                            367.535569835514,
                            252.81855613663242
                        ],
                        [
                            366.77114707966734,
                            255.15323861573285
                        ],
                        [
                            365.89430921266626,
                            255.74197982002136
                        ],
                        [
                            364.65774298997303,
                            255.00057679457007
                        ],
                        [
                            362.45440681135574,
                            253.60432896332463
                        ],
                        [
                            362.20709356681687,
                            255.37130891461868
                        ],
                        [
                            360.9030782774312,
                            255.82918761626541
                        ],
                        [
                            360.9930103663546,
                            254.04074999855038
                        ],
                        [
                            360.633282010662,
                            250.8966101966913
                        ],
                        [
                            359.576579965815,
                            244.22234675038993
                        ],
                        [
                            358.67725907658337,
                            242.0734999593161
                        ],
                        [
                            357.55310796504386,
                            241.10801876359346
                        ],
                        [
                            357.8453872540442,
                            239.17576064138086
                        ],
                        [
                            357.1708965871205,
                            236.29610911790297
                        ],
                        [
                            358.00276840965967,
                            235.83412491994062
                        ],
                        [
                            357.4406928538899,
                            234.93186712868714
                        ],
                        [
                            356.09171152004274,
                            229.7970982469957
                        ],
                        [
                            355.9792964088888,
                            228.45073327985256
                        ],
                        [
                            355.86688129773506,
                            227.0593156758572
                        ],
                        [
                            356.8561342758894,
                            225.44587649969725
                        ],
                        [
                            356.67627009804323,
                            223.2115153828487
                        ],
                        [
                            356.9460663648126,
                            221.10768206720206
                        ],
                        [
                            356.42895685350436,
                            220.11037835966232
                        ],
                        [
                            358.2950476986598,
                            220.02170568994188
                        ],
                        [
                            358.49739489873696,
                            221.572924919763
                        ],
                        [
                            359.9138252992768,
                            221.196307865403
                        ],
                        [
                            360.1836215660462,
                            220.06604250396128
                        ],
                        [
                            361.0829424552776,
                            219.93302918637352
                        ],
                        [
                            361.28528965535475,
                            218.71333787437902
                        ],
                        [
                            362.2295765890476,
                            218.358382012636
                        ],
                        [
                            363.2862786338949,
                            215.7831025955789
                        ],
                        [
                            363.84835418966463,
                            217.29314401206744
                        ],
                        [
                            365.2647845902043,
                            217.75925407821552
                        ],
                        [
                            366.92852823528256,
                            217.40413226614857
                        ],
                        [
                            367.1308754353597,
                            220.28771220215162
                        ],
                        [
                            366.43390174620527,
                            222.19308523570663
                        ],
                        [
                            369.8288381030543,
                            223.30005079705575
                        ],
                        [
                            370.503328769978,
                            223.7205422561999
                        ],
                        [
                            372.6167328596723,
                            225.24687267504459
                        ],
                        [
                            373.0663933042879,
                            229.6867729644013
                        ],
                        [
                            373.0214272598264,
                            231.64969837594663
                        ],
                        [
                            372.5268007707491,
                            232.13463422627513
                        ],
                        [
                            372.70666494859506,
                            233.8750788802319
                        ],
                        [
                            372.05465730390233,
                            234.7997973266829
                        ],
                        [
                            372.54928379297985,
                            236.56005520728655
                        ],
                        [
                            372.00969125944084,
                            238.91213654592013
                        ],
                        [
                            371.20030245913244,
                            240.0103572813149
                        ],
                        [
                            369.67145694743886,
                            239.1977278572828
                        ],
                        [
                            368.5922718803613,
                            239.9883983544513
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Tamil Nadu",
        "properties": {
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            175.77787322911945,
                            406.15438543327946
                        ],
                        [
                            173.95674842842573,
                            409.00771993206286
                        ],
                        [
                            173.55205402827164,
                            409.23507232161984
                        ],
                        [
                            173.55205402827164,
                            409.87572383634495
                        ],
                        [
                            173.30474078373277,
                            410.6402397904577
                        ],
                        [
                            172.49535198342437,
                            410.5782573728726
                        ],
                        [
                            171.03395553842324,
                            411.77641703789357
                        ],
                        [
                            172.83259731688622,
                            412.31340909035265
                        ],
                        [
                            172.81011429465548,
                            412.4786232698357
                        ],
                        [
                            172.65273313904004,
                            413.201357266982
                        ],
                        [
                            172.135623627732,
                            415.6370734271328
                        ],
                        [
                            172.2255557166552,
                            417.1638315817245
                        ],
                        [
                            172.90004638357868,
                            418.62817471833455
                        ],
                        [
                            173.55205402827164,
                            422.3383458886653
                        ],
                        [
                            173.46212193934844,
                            425.20125313055644
                        ],
                        [
                            171.9557594498856,
                            425.0365320698666
                        ],
                        [
                            171.596031094193,
                            426.04535237589914
                        ],
                        [
                            172.9899784725019,
                            426.78638046610513
                        ],
                        [
                            173.4396389171177,
                            427.5684433389141
                        ],
                        [
                            173.77688425057931,
                            434.5598616263122
                        ],
                        [
                            173.59702007273313,
                            435.7103767191842
                        ],
                        [
                            172.24803873888573,
                            435.87471307424585
                        ],
                        [
                            167.88633242611263,
                            435.217333289769
                        ],
                        [
                            166.64976620341963,
                            435.19678869318244
                        ],
                        [
                            164.87360744718717,
                            436.51146251329055
                        ],
                        [
                            164.2890488691869,
                            437.8873714474537
                        ],
                        [
                            164.64877722487927,
                            439.18077577398367
                        ],
                        [
                            162.78268637972383,
                            441.68448116150796
                        ],
                        [
                            162.7377203352621,
                            441.72551481495145
                        ],
                        [
                            162.71523731303137,
                            442.1358325029054
                        ],
                        [
                            162.6702712685701,
                            442.17686238925717
                        ],
                        [
                            160.66928229002974,
                            444.57651911308255
                        ],
                        [
                            159.9273425564138,
                            445.97066173957364
                        ],
                        [
                            159.477682111798,
                            447.8562434320329
                        ],
                        [
                            160.71424833449146,
                            449.49531513947204
                        ],
                        [
                            161.90584851272297,
                            450.3965831739276
                        ],
                        [
                            163.27731286880135,
                            450.6013950548511
                        ],
                        [
                            165.8853434475725,
                            450.2122456017893
                        ],
                        [
                            165.41319998072595,
                            451.19530412193694
                        ],
                        [
                            163.95180353572482,
                            450.7037979817993
                        ],
                        [
                            162.2655768684158,
                            450.97003620326745
                        ],
                        [
                            160.6917653122605,
                            450.7652387745385
                        ],
                        [
                            159.07298771164392,
                            451.01099472722245
                        ],
                        [
                            154.75624744333254,
                            452.89474212447465
                        ],
                        [
                            152.4854621980228,
                            453.0994568390155
                        ],
                        [
                            151.9009036200223,
                            453.44745376980063
                        ],
                        [
                            149.6076353524818,
                            455.2280248903346
                        ],
                        [
                            148.77576352994265,
                            456.5784110524018
                        ],
                        [
                            148.61838237432698,
                            457.74438456554185
                        ],
                        [
                            149.1130088634045,
                            458.5420134706204
                        ],
                        [
                            148.28113704086536,
                            459.6053400813795
                        ],
                        [
                            148.3485861075576,
                            462.2627743655155
                        ],
                        [
                            147.08953686263362,
                            464.08137880401324
                        ],
                        [
                            145.80800459547868,
                            464.7351188130317
                        ],
                        [
                            143.9194307280925,
                            465.981107348469
                        ],
                        [
                            143.22245703893782,
                            467.28808980312874
                        ],
                        [
                            140.20973206001236,
                            467.6352084979439
                        ],
                        [
                            139.71510557093484,
                            468.57436948598524
                        ],
                        [
                            138.09632797031827,
                            468.3089693854432
                        ],
                        [
                            134.9711878802384,
                            467.0226323939488
                        ],
                        [
                            132.99268192392924,
                            465.36835931896246
                        ],
                        [
                            135.3983653026237,
                            462.18102576606793
                        ],
                        [
                            135.5107804137774,
                            461.1181868946985
                        ],
                        [
                            134.04938396877628,
                            458.685165450309
                        ],
                        [
                            134.47656139116134,
                            458.2556984029617
                        ],
                        [
                            135.30843321370048,
                            456.6397842739358
                        ],
                        [
                            134.38662930223813,
                            455.9441806029515
                        ],
                        [
                            133.73462165754518,
                            454.69596204523714
                        ],
                        [
                            134.54401045785357,
                            453.7954279807409
                        ],
                        [
                            135.48829739154667,
                            452.5671821544626
                        ],
                        [
                            135.69064459162382,
                            450.3965831739276
                        ],
                        [
                            136.5449994363937,
                            448.59389026813096
                        ],
                        [
                            137.46680334785606,
                            447.01601616677704
                        ],
                        [
                            136.23023712516283,
                            445.8681643967666
                        ],
                        [
                            135.5107804137774,
                            446.31913716483075
                        ],
                        [
                            134.0044179243148,
                            445.72466462143973
                        ],
                        [
                            135.1735350803158,
                            442.93585372885093
                        ],
                        [
                            134.67890859123827,
                            441.7665481254312
                        ],
                        [
                            135.4208483248542,
                            439.5092041166811
                        ],
                        [
                            135.48829739154667,
                            438.1132290534713
                        ],
                        [
                            135.78057668054703,
                            436.4703844873533
                        ],
                        [
                            134.20676512439172,
                            434.60095630486563
                        ],
                        [
                            132.8802668127753,
                            435.2378777966299
                        ],
                        [
                            132.2957082347748,
                            436.01850269529353
                        ],
                        [
                            131.28397223438924,
                            436.6552328033882
                        ],
                        [
                            130.06988903392653,
                            436.16228794387985
                        ],
                        [
                            130.04740601169578,
                            436.1417474616197
                        ],
                        [
                            129.9574739227728,
                            436.0595846408702
                        ],
                        [
                            129.1031190780027,
                            434.86806293483295
                        ],
                        [
                            128.8333228112333,
                            433.61458431250026
                        ],
                        [
                            129.17056814469515,
                            431.0243598654458
                        ],
                        [
                            130.18230414508048,
                            428.2474925455808
                        ],
                        [
                            129.05815303354143,
                            426.9304549575541
                        ],
                        [
                            126.49508849923131,
                            425.9012492447812
                        ],
                        [
                            127.93400192200193,
                            423.80092781975355
                        ],
                        [
                            127.05716405500107,
                            422.4825659118913
                        ],
                        [
                            127.23702823284748,
                            421.3904950394153
                        ],
                        [
                            126.09039409907723,
                            422.07049600164925
                        ],
                        [
                            123.34746538692093,
                            421.9674724130309
                        ],
                        [
                            124.51658254292192,
                            420.9371016437707
                        ],
                        [
                            124.89879392084549,
                            419.5972510926923
                        ],
                        [
                            123.61726165369055,
                            419.1642867307584
                        ],
                        [
                            120.28977436353375,
                            417.0813182858652
                        ],
                        [
                            122.40317845322784,
                            415.9672303758259
                        ],
                        [
                            122.98773703122833,
                            414.91476413112946
                        ],
                        [
                            124.35920138730648,
                            414.4193939349537
                        ],
                        [
                            125.07865809869168,
                            415.65770899972654
                        ],
                        [
                            127.97896796646341,
                            415.7196151065575
                        ],
                        [
                            129.30546627807985,
                            416.0703990837003
                        ],
                        [
                            129.12560210023344,
                            415.16242712180235
                        ],
                        [
                            130.20478716731145,
                            413.0568206154486
                        ],
                        [
                            131.73363267900527,
                            412.767732099406
                        ],
                        [
                            133.17254610177565,
                            413.3871826627191
                        ],
                        [
                            135.8480257472395,
                            412.850330889533
                        ],
                        [
                            137.8490147257794,
                            413.46976904236044
                        ],
                        [
                            138.81578468170324,
                            410.82618137602833
                        ],
                        [
                            141.4912643271673,
                            410.7022212632785
                        ],
                        [
                            141.96340779401385,
                            409.44174519680416
                        ],
                        [
                            142.95266077216843,
                            408.28424055267146
                        ],
                        [
                            142.5254833497836,
                            407.64333564675314
                        ],
                        [
                            140.52449437124324,
                            406.8782501717279
                        ],
                        [
                            138.9731658373189,
                            407.0023280516838
                        ],
                        [
                            138.4785393482416,
                            406.3198518522173
                        ],
                        [
                            139.6476565042426,
                            405.5958852977216
                        ],
                        [
                            140.5919434379357,
                            404.4579556927715
                        ],
                        [
                            140.88422272693606,
                            402.6365824500783
                        ],
                        [
                            140.1872490377816,
                            402.2432198855558
                        ],
                        [
                            140.38959623785877,
                            399.92363921631704
                        ],
                        [
                            142.41306823862965,
                            399.8614883998184
                        ],
                        [
                            143.19997401670707,
                            398.70115479630374
                        ],
                        [
                            143.76204957247683,
                            396.9392591880378
                        ],
                        [
                            144.2791590837851,
                            396.87705968605474
                        ],
                        [
                            145.20096299524744,
                            396.56604682416724
                        ],
                        [
                            145.94290272886337,
                            397.3953576450434
                        ],
                        [
                            147.08953686263362,
                            397.1465834776219
                        ],
                        [
                            148.2136879741729,
                            398.203761094275
                        ],
                        [
                            149.6526013969435,
                            398.53536410504523
                        ],
                        [
                            150.6868204195598,
                            399.9650725353037
                        ],
                        [
                            152.30559802017638,
                            400.54509213272604
                        ],
                        [
                            154.9585946434097,
                            398.203761094275
                        ],
                        [
                            155.1159757990249,
                            397.2709726028056
                        ],
                        [
                            155.38577206579453,
                            395.05208434784544
                        ],
                        [
                            157.29682895541146,
                            394.0977666392871
                        ],
                        [
                            159.36526700064405,
                            393.4752540092643
                        ],
                        [
                            160.87162949010667,
                            393.620516267623
                        ],
                        [
                            163.34476193549358,
                            394.61644778743164
                        ],
                        [
                            164.40146398034085,
                            393.3299860751252
                        ],
                        [
                            164.31153189141764,
                            392.54128948551477
                        ],
                        [
                            165.12092069172581,
                            393.06018769518107
                        ],
                        [
                            167.39170593703557,
                            392.0845988743573
                        ],
                        [
                            166.9195624701888,
                            389.86261367117527
                        ],
                        [
                            168.8531023820367,
                            389.8002956033107
                        ],
                        [
                            168.80813633757498,
                            390.75572264400955
                        ],
                        [
                            170.98898949396175,
                            390.4441977459441
                        ],
                        [
                            171.28126878296212,
                            391.56556368987776
                        ],
                        [
                            172.6302501168093,
                            391.295636495247
                        ],
                        [
                            172.85508033911697,
                            390.13264634981897
                        ],
                        [
                            174.85606931765733,
                            389.4263648472654
                        ],
                        [
                            176.20505065150473,
                            386.7869808065768
                        ],
                        [
                            176.81209225173575,
                            387.5353482202108
                        ],
                        [
                            178.8130812302761,
                            387.51456233296904
                        ],
                        [
                            179.59998700835354,
                            386.391946395858
                        ],
                        [
                            180.45434185312342,
                            388.20043351815
                        ],
                        [
                            180.72413811989304,
                            390.75572264400955
                        ],
                        [
                            180.07213047520008,
                            392.76961365051005
                        ],
                        [
                            179.33019074158415,
                            397.0636551244569
                        ],
                        [
                            179.3976398082766,
                            398.2452130490322
                        ],
                        [
                            178.09362451889092,
                            402.01546547639055
                        ],
                        [
                            177.6889301187366,
                            403.79573616008565
                        ],
                        [
                            175.77787322911945,
                            406.15438543327946
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Madhya Pradesh",
        "properties": {
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            111.09421827114147,
                            265.6621308451458
                        ],
                        [
                            107.76673098098445,
                            265.81407928449573
                        ],
                        [
                            105.24863249113628,
                            265.31478257953853
                        ],
                        [
                            103.71978697944269,
                            264.51133931372397
                        ],
                        [
                            102.91039817913429,
                            262.86014275602156
                        ],
                        [
                            100.1225034225165,
                            261.79492469904255
                        ],
                        [
                            98.84097115536156,
                            262.09932332841703
                        ],
                        [
                            96.00811035428228,
                            261.3165020342865
                        ],
                        [
                            94.86147622051226,
                            260.3375975372823
                        ],
                        [
                            95.1537555095124,
                            257.398350411083
                        ],
                        [
                            94.09705346466512,
                            256.61390565516456
                        ],
                        [
                            93.80477417566499,
                            255.52394551983252
                        ],
                        [
                            91.57895497481695,
                            257.04974198633124
                        ],
                        [
                            89.60044901850756,
                            256.78825030177217
                        ],
                        [
                            88.83602626266088,
                            255.74197982002136
                        ],
                        [
                            89.26320368504571,
                            254.45527112511826
                        ],
                        [
                            89.24072066281497,
                            252.64390235680003
                        ],
                        [
                            88.22898466242941,
                            251.26802475502126
                        ],
                        [
                            88.45381488473731,
                            250.35029928806267
                        ],
                        [
                            90.22997364096977,
                            250.91845983306354
                        ],
                        [
                            90.61218501889289,
                            248.99486601565394
                        ],
                        [
                            89.19575461835325,
                            249.34473483659679
                        ],
                        [
                            88.29643372912187,
                            247.2665741607115
                        ],
                        [
                            89.64541506296905,
                            247.72612596358795
                        ],
                        [
                            91.30915870804756,
                            245.77784103166402
                        ],
                        [
                            93.01786839758756,
                            245.90924053126577
                        ],
                        [
                            93.35511373104919,
                            244.37575461480566
                        ],
                        [
                            94.27691764251153,
                            243.01662297453777
                        ],
                        [
                            94.20946857581907,
                            241.56887033362534
                        ],
                        [
                            93.22021559766449,
                            241.74440701997742
                        ],
                        [
                            92.1410305305867,
                            239.13182553649392
                        ],
                        [
                            93.10780048651077,
                            238.2968878502532
                        ],
                        [
                            94.883959242743,
                            238.73636918582042
                        ],
                        [
                            98.23392955513009,
                            236.56005520728655
                        ],
                        [
                            95.22120457620463,
                            234.95387796531952
                        ],
                        [
                            95.85072919866684,
                            233.36851470106518
                        ],
                        [
                            98.79600511090007,
                            231.4071886640163
                        ],
                        [
                            99.89767320020883,
                            231.25284978267752
                        ],
                        [
                            100.99934128951713,
                            229.42196863275268
                        ],
                        [
                            101.26913755628675,
                            227.83243980999953
                        ],
                        [
                            100.70706200051723,
                            226.08698149088036
                        ],
                        [
                            101.76376404536427,
                            223.41071473313826
                        ],
                        [
                            100.36981666705515,
                            221.2627747080008
                        ],
                        [
                            100.7969940894402,
                            220.64233393146185
                        ],
                        [
                            100.18995248920874,
                            220.13254592818816
                        ],
                        [
                            98.52620884413068,
                            220.1547132571883
                        ],
                        [
                            98.93090324428476,
                            218.60242078962796
                        ],
                        [
                            100.01008831136255,
                            217.22654815781567
                        ],
                        [
                            99.42552973336205,
                            216.49384997521207
                        ],
                        [
                            97.69433702159154,
                            216.27176811965217
                        ],
                        [
                            98.99835231097722,
                            214.60537947340043
                        ],
                        [
                            98.79600511090007,
                            213.3824891955212
                        ],
                        [
                            99.26814857774662,
                            212.18111221241656
                        ],
                        [
                            100.50471480044007,
                            213.8272621668114
                        ],
                        [
                            102.03356031213343,
                            212.87087910758856
                        ],
                        [
                            101.80873008982553,
                            211.7137164826724
                        ],
                        [
                            100.10002040028576,
                            211.15715130844973
                        ],
                        [
                            99.58291088897772,
                            209.53109699146876
                        ],
                        [
                            100.70706200051723,
                            209.0407942008415
                        ],
                        [
                            101.53893382305637,
                            210.19949816254245
                        ],
                        [
                            102.70805097905713,
                            210.39997503206564
                        ],
                        [
                            103.85468511282738,
                            209.8876053808561
                        ],
                        [
                            103.8097190683659,
                            208.43889449815975
                        ],
                        [
                            104.95635320213614,
                            207.30147712021056
                        ],
                        [
                            107.31707053636887,
                            207.59146869830084
                        ],
                        [
                            107.09224031406097,
                            208.66184146665967
                        ],
                        [
                            104.84393809098219,
                            211.9808130508498
                        ],
                        [
                            108.84591604806246,
                            213.07108980973862
                        ],
                        [
                            110.21738040414061,
                            212.71514592873683
                        ],
                        [
                            111.09421827114147,
                            213.04884515750535
                        ],
                        [
                            113.67976582768188,
                            211.7582350403839
                        ],
                        [
                            114.2643244056826,
                            213.29352284500686
                        ],
                        [
                            115.68075480622201,
                            215.2943194312777
                        ],
                        [
                            115.38847551722188,
                            216.91573871267303
                        ],
                        [
                            113.70224884991262,
                            216.7603161962095
                        ],
                        [
                            112.84789400514273,
                            217.67047942288724
                        ],
                        [
                            114.10694325006693,
                            220.11037835966232
                        ],
                        [
                            113.18513933860459,
                            221.75013263322847
                        ],
                        [
                            114.44418858352878,
                            223.14511133332928
                        ],
                        [
                            112.4656826272194,
                            224.6055083557327
                        ],
                        [
                            109.8126860039863,
                            223.6541545501911
                        ],
                        [
                            108.93584813698567,
                            225.37954400932404
                        ],
                        [
                            110.44221062644829,
                            226.37430927608494
                        ],
                        [
                            110.7120068932179,
                            227.3465094833473
                        ],
                        [
                            112.28581844937298,
                            228.119527361905
                        ],
                        [
                            113.81466396106657,
                            226.19749686999472
                        ],
                        [
                            115.92806805076088,
                            225.71118529392055
                        ],
                        [
                            117.45691356245447,
                            222.9901602519932
                        ],
                        [
                            118.71596280737867,
                            222.63594269368355
                        ],
                        [
                            118.58106467399398,
                            220.93041901026214
                        ],
                        [
                            119.97501205230265,
                            220.68665734207815
                        ],
                        [
                            121.5713066306887,
                            220.11037835966232
                        ],
                        [
                            124.26926929838328,
                            220.77530129452293
                        ],
                        [
                            125.23603925430712,
                            220.68665734207815
                        ],
                        [
                            126.31522432138513,
                            219.71132114683058
                        ],
                        [
                            126.78736778823145,
                            220.97473620742227
                        ],
                        [
                            128.63097561115615,
                            222.17093986645227
                        ],
                        [
                            129.80009276715737,
                            221.94947308761192
                        ],
                        [
                            130.4071343673886,
                            220.64233393146185
                        ],
                        [
                            129.53029650038775,
                            219.66697666109485
                        ],
                        [
                            129.08063605577195,
                            218.2918209233198
                        ],
                        [
                            129.26050023361813,
                            217.0267475314228
                        ],
                        [
                            128.85580583346427,
                            215.44985411948062
                        ],
                        [
                            129.50781347815723,
                            215.20543711093757
                        ],
                        [
                            131.21652316769678,
                            216.6270874483855
                        ],
                        [
                            132.5655045015444,
                            215.0721063241832
                        ],
                        [
                            132.04839499023615,
                            212.62615013878042
                        ],
                        [
                            130.7668627230812,
                            211.80275261269435
                        ],
                        [
                            129.41788138923403,
                            212.0475816528899
                        ],
                        [
                            128.67594165561786,
                            211.02355272093314
                        ],
                        [
                            130.81182876754292,
                            210.02127966223958
                        ],
                        [
                            129.50781347815723,
                            208.52807627351444
                        ],
                        [
                            129.7326437004649,
                            207.2345499877598
                        ],
                        [
                            131.55376850115886,
                            206.67673630965936
                        ],
                        [
                            132.81281774608283,
                            207.01144328844862
                        ],
                        [
                            134.11683303546852,
                            206.09644397322825
                        ],
                        [
                            135.5107804137774,
                            206.0294762520095
                        ],
                        [
                            136.14030503623962,
                            206.5874715974225
                        ],
                        [
                            137.33190521447136,
                            205.96250627111868
                        ],
                        [
                            137.60170148124098,
                            204.26517817618077
                        ],
                        [
                            136.9496938365478,
                            201.22420824429963
                        ],
                        [
                            136.16278805847037,
                            200.88851913092162
                        ],
                        [
                            134.99367090246915,
                            201.73882010620457
                        ],
                        [
                            134.83628974685394,
                            202.72291982042339
                        ],
                        [
                            132.76785170162134,
                            202.43221430333296
                        ],
                        [
                            130.74437970085046,
                            203.28184836137234
                        ],
                        [
                            129.44036441146477,
                            202.499304001376
                        ],
                        [
                            128.04641703315588,
                            202.70055938102786
                        ],
                        [
                            126.6974356993087,
                            202.2309314876798
                        ],
                        [
                            125.5732845877692,
                            201.2689624551254
                        ],
                        [
                            124.42665045399872,
                            199.3212092357315
                        ],
                        [
                            124.24678627615253,
                            196.9903102884008
                        ],
                        [
                            124.87631089861475,
                            194.252419556946
                        ],
                        [
                            126.29274129915439,
                            193.10674233294566
                        ],
                        [
                            127.37192636623195,
                            193.21909370751172
                        ],
                        [
                            128.42862841107922,
                            192.5224095842063
                        ],
                        [
                            129.3729153447723,
                            191.08329564260998
                        ],
                        [
                            130.0923720561575,
                            190.09327874529941
                        ],
                        [
                            131.5312854789281,
                            189.48551499157264
                        ],
                        [
                            133.30744423516035,
                            188.11170730662718
                        ],
                        [
                            135.53326343600838,
                            187.2328525180992
                        ],
                        [
                            137.06210894770197,
                            185.6318430311929
                        ],
                        [
                            137.9839128591641,
                            185.00008755688083
                        ],
                        [
                            139.46779232639642,
                            184.86468397843328
                        ],
                        [
                            142.50300032755263,
                            182.78728088915636
                        ],
                        [
                            143.64963446132288,
                            182.58393387283974
                        ],
                        [
                            144.8412346395546,
                            180.79805570744838
                        ],
                        [
                            146.25766504009448,
                            180.2099269173329
                        ],
                        [
                            147.56168032948017,
                            180.52663455691493
                        ],
                        [
                            147.89892566294202,
                            178.55765479531544
                        ],
                        [
                            149.51770326355881,
                            177.94617042733995
                        ],
                        [
                            151.51869224209872,
                            177.33448564016356
                        ],
                        [
                            152.05828477563773,
                            178.19531785965967
                        ],
                        [
                            154.26162095425502,
                            179.0783910963238
                        ],
                        [
                            157.7240063777965,
                            178.98783867777342
                        ],
                        [
                            159.07298771164392,
                            180.16467860276208
                        ],
                        [
                            161.05149366795308,
                            180.59449352444204
                        ],
                        [
                            160.80418042341466,
                            182.1093721422248
                        ],
                        [
                            162.94006753533927,
                            184.39069518220964
                        ],
                        [
                            162.98503357980098,
                            186.06041443306088
                        ],
                        [
                            160.87162949010667,
                            188.3820426680805
                        ],
                        [
                            161.11894273464554,
                            190.25081563943877
                        ],
                        [
                            160.1971388231832,
                            191.15077819093682
                        ],
                        [
                            160.03975766756753,
                            192.38753838713353
                        ],
                        [
                            159.27533491172085,
                            193.03932836551178
                        ],
                        [
                            157.47669313325787,
                            196.18280999678103
                        ],
                        [
                            158.1736668224121,
                            197.81989188029127
                        ],
                        [
                            156.8022024663344,
                            198.0664561097849
                        ],
                        [
                            155.9253645993333,
                            198.80596179442637
                        ],
                        [
                            152.8901565981771,
                            198.60430622092792
                        ],
                        [
                            152.41801313133033,
                            199.25401075259998
                        ],
                        [
                            152.37304708686884,
                            200.14980075221837
                        ],
                        [
                            152.05828477563773,
                            200.68707808871193
                        ],
                        [
                            151.47372619763723,
                            200.55277256290827
                        ],
                        [
                            151.24889597532956,
                            200.86613781456367
                        ],
                        [
                            151.24889597532956,
                            201.13469676161787
                        ],
                        [
                            151.3163450420218,
                            202.566391413337
                        ],
                        [
                            151.9908357089455,
                            204.55561380279602
                        ],
                        [
                            152.82270753148464,
                            205.9178583615714
                        ],
                        [
                            151.74352246440685,
                            206.2973335838446
                        ],
                        [
                            151.20392993086784,
                            206.52052043187786
                        ],
                        [
                            151.1814469086371,
                            206.78831156297593
                        ],
                        [
                            151.33882806425277,
                            207.7029926782419
                        ],
                        [
                            148.77576352994265,
                            209.909885047366
                        ],
                        [
                            149.04555979671227,
                            210.4667962001878
                        ],
                        [
                            150.30460904163624,
                            213.3602479754668
                        ],
                        [
                            150.1921939304823,
                            216.80472384052575
                        ],
                        [
                            151.6760733977144,
                            218.0255548696034
                        ],
                        [
                            151.0915148197139,
                            218.77988523697832
                        ],
                        [
                            151.96835268671475,
                            219.82217816381473
                        ],
                        [
                            153.63209633179304,
                            217.75925407821552
                        ],
                        [
                            154.8686625544865,
                            218.358382012636
                        ],
                        [
                            156.5773722440265,
                            220.39853807261665
                        ],
                        [
                            158.10621775571985,
                            221.17415177389626
                        ],
                        [
                            160.53438415664505,
                            218.44712675831806
                        ],
                        [
                            160.8266634456454,
                            217.33754003906057
                        ],
                        [
                            160.7367313567222,
                            216.93794096062277
                        ],
                        [
                            159.7025123341059,
                            216.64929284551118
                        ],
                        [
                            160.26458788987543,
                            215.22765805627142
                        ],
                        [
                            159.23036886725913,
                            213.7160781042428
                        ],
                        [
                            157.29682895541146,
                            214.3830907794068
                        ],
                        [
                            157.7240063777965,
                            212.6706485249627
                        ],
                        [
                            157.56662522218107,
                            210.17722171690622
                        ],
                        [
                            156.19516086610292,
                            209.5533806263865
                        ],
                        [
                            155.54315322140997,
                            208.59495999037682
                        ],
                        [
                            155.65556833256414,
                            206.7436822131175
                        ],
                        [
                            154.46396815433218,
                            204.44391284074004
                        ],
                        [
                            153.78947748740848,
                            203.41596763802636
                        ],
                        [
                            155.94784762156405,
                            201.67170446403247
                        ],
                        [
                            157.63407428887353,
                            202.16383264027468
                        ],
                        [
                            157.00454966641132,
                            200.4632304332613
                        ],
                        [
                            159.25285188948988,
                            199.52279082718655
                        ],
                        [
                            160.10720673426,
                            199.9034993806925
                        ],
                        [
                            159.18540282279764,
                            201.6269594291178
                        ],
                        [
                            160.3770030010296,
                            202.29802804698835
                        ],
                        [
                            159.05050468941317,
                            204.86834294836825
                        ],
                        [
                            159.18540282279764,
                            205.33734405868904
                        ],
                        [
                            159.9273425564138,
                            204.53327411505444
                        ],
                        [
                            161.88336549049222,
                            204.28752089294233
                        ],
                        [
                            162.87261846864703,
                            206.11876604490465
                        ],
                        [
                            164.55884513595606,
                            206.0517990767994
                        ],
                        [
                            165.7054792697263,
                            205.47132398487923
                        ],
                        [
                            165.43568300295715,
                            204.9800199544856
                        ],
                        [
                            165.25581882511074,
                            204.91301450679902
                        ],
                        [
                            165.05347162503335,
                            203.95235361647195
                        ],
                        [
                            164.82864140272568,
                            203.46067203741575
                        ],
                        [
                            165.0759546472641,
                            202.6334765397201
                        ],
                        [
                            166.13265669211137,
                            202.54402919666137
                        ],
                        [
                            167.3692229148046,
                            203.77357448215753
                        ],
                        [
                            166.69473224788112,
                            205.136357181871
                        ],
                        [
                            166.9195624701888,
                            206.16340943527427
                        ],
                        [
                            168.178611715113,
                            206.60978815135434
                        ],
                        [
                            169.32524584888324,
                            205.11402404783905
                        ],
                        [
                            170.85409136057683,
                            205.9401824419195
                        ],
                        [
                            171.9557594498856,
                            205.62762245409385
                        ],
                        [
                            173.30474078373277,
                            206.2973335838446
                        ],
                        [
                            173.52957100604067,
                            204.7566596413032
                        ],
                        [
                            176.13760158481227,
                            202.34275781561223
                        ],
                        [
                            177.19430362965932,
                            202.20856545946924
                        ],
                        [
                            178.90301331919932,
                            201.44796909848702
                        ],
                        [
                            180.29696069750798,
                            201.58221337525544
                        ],
                        [
                            180.22951163081575,
                            203.2594942619557
                        ],
                        [
                            181.60097598689367,
                            203.95235361647195
                        ],
                        [
                            181.98318736481724,
                            205.06935702425443
                        ],
                        [
                            180.83655323104722,
                            205.8955340300563
                        ],
                        [
                            179.93723234181562,
                            206.94450639939396
                        ],
                        [
                            179.53253794166108,
                            207.5022450183834
                        ],
                        [
                            180.67917207543155,
                            207.68068838170717
                        ],
                        [
                            181.55600994243218,
                            206.7659970132791
                        ],
                        [
                            182.68016105397146,
                            208.2828168009213
                        ],
                        [
                            183.01740638743354,
                            207.2345499877598
                        ],
                        [
                            184.36638772128072,
                            206.43124870171494
                        ],
                        [
                            186.16502949974392,
                            206.83293991104978
                        ],
                        [
                            186.29992763312862,
                            205.98482984918786
                        ],
                        [
                            188.07608638936085,
                            204.86834294836825
                        ],
                        [
                            188.57071287843837,
                            206.83293991104978
                        ],
                        [
                            187.8737391892837,
                            207.36840200246638
                        ],
                        [
                            188.30091661166875,
                            209.0630832999944
                        ],
                        [
                            189.55996585659295,
                            208.43889449815975
                        ],
                        [
                            190.8639811459784,
                            208.66184146665967
                        ],
                        [
                            192.1230303909026,
                            209.46424459755315
                        ],
                        [
                            192.7075889689031,
                            209.30824698927938
                        ],
                        [
                            194.23643448059693,
                            207.03375508425228
                        ],
                        [
                            194.43878168067408,
                            205.20335507314826
                        ],
                        [
                            196.1250083479831,
                            205.7615827654164
                        ],
                        [
                            197.69881990413842,
                            205.49365309208832
                        ],
                        [
                            198.30586150436966,
                            204.84600679110542
                        ],
                        [
                            199.56491074929363,
                            205.2926820703011
                        ],
                        [
                            200.1719523495251,
                            206.8998805551363
                        ],
                        [
                            202.0155601724498,
                            207.83681321461017
                        ],
                        [
                            203.47695661745092,
                            208.08212753183136
                        ],
                        [
                            204.21889635106686,
                            210.39997503206564
                        ],
                        [
                            206.4447155519149,
                            210.86767646549896
                        ],
                        [
                            208.24335733037788,
                            211.2462120963409
                        ],
                        [
                            209.43495750860984,
                            214.27193727601372
                        ],
                        [
                            211.21111626484208,
                            214.4720091863975
                        ],
                        [
                            211.21111626484208,
                            213.53817087638868
                        ],
                        [
                            212.7624447987664,
                            213.13782229486714
                        ],
                        [
                            214.9208149329222,
                            212.80413778964464
                        ],
                        [
                            215.46040746646122,
                            213.7827892758766
                        ],
                        [
                            216.42717742238506,
                            213.69384055756348
                        ],
                        [
                            216.78690577807788,
                            214.7609670389707
                        ],
                        [
                            216,
                            216.0496619980794
                        ],
                        [
                            215.5503395553842,
                            217.93679180041983
                        ],
                        [
                            216.38221137792334,
                            218.09212463847297
                        ],
                        [
                            216,
                            221.39570195115408
                        ],
                        [
                            214.83088284399923,
                            221.99376834726996
                        ],
                        [
                            217.01173600038555,
                            224.67186558224176
                        ],
                        [
                            215.25806026638406,
                            225.35743270862667
                        ],
                        [
                            214.40370542161395,
                            226.66159743062695
                        ],
                        [
                            212.38023342084307,
                            227.5232244989087
                        ],
                        [
                            208.89536497507106,
                            226.92675135483387
                        ],
                        [
                            208.13094221922393,
                            226.41851003224383
                        ],
                        [
                            206.2648513740687,
                            227.01512850484846
                        ],
                        [
                            205.16318328475995,
                            226.2859049496139
                        ],
                        [
                            203.90413403983598,
                            226.13118834659565
                        ],
                        [
                            202.2628734169882,
                            227.14768721058402
                        ],
                        [
                            201.026307194295,
                            226.63949974793672
                        ],
                        [
                            200.1719523495251,
                            225.2689851524994
                        ],
                        [
                            199.227665415832,
                            225.9101446773575
                        ],
                        [
                            199.34008052698596,
                            226.68369487904596
                        ],
                        [
                            200.59912977190993,
                            228.5390460075647
                        ],
                        [
                            199.38504657144745,
                            229.4661050078696
                        ],
                        [
                            199.34008052698596,
                            231.89218022226925
                        ],
                        [
                            201.49845066114153,
                            231.29694775870172
                        ],
                        [
                            202.42025457260388,
                            231.8480946854454
                        ],
                        [
                            203.83668497314352,
                            231.53947014451995
                        ],
                        [
                            204.84842097352907,
                            233.45662151261507
                        ],
                        [
                            206.5796136852996,
                            233.72091998100075
                        ],
                        [
                            207.99604408583946,
                            235.06392873366076
                        ],
                        [
                            206.87189297429995,
                            238.42874169653413
                        ],
                        [
                            204.21889635106686,
                            238.82425466291818
                        ],
                        [
                            204.3313114622208,
                            240.36166969947817
                        ],
                        [
                            203.02729617283535,
                            241.8541102221863
                        ],
                        [
                            201.76824692791092,
                            242.09543771126374
                        ],
                        [
                            202.03804319468054,
                            243.82781977773777
                        ],
                        [
                            201.1162392832182,
                            245.25216408295194
                        ],
                        [
                            201.0712732387567,
                            245.3616905377184
                        ],
                        [
                            199.90215608275548,
                            246.23770477966417
                        ],
                        [
                            199.49746168260117,
                            247.46353669894637
                        ],
                        [
                            197.3390915484456,
                            248.22933445700005
                        ],
                        [
                            195.18072141429002,
                            247.6604815874491
                        ],
                        [
                            194.01160425828903,
                            248.7105567512076
                        ],
                        [
                            193.20221545798063,
                            248.2074582187015
                        ],
                        [
                            191.98813225751792,
                            249.08233841932707
                        ],
                        [
                            192.10054736867187,
                            251.3772525043865
                        ],
                        [
                            191.02136230159408,
                            252.1853710286345
                        ],
                        [
                            190.41432070136307,
                            255.0660045616135
                        ],
                        [
                            188.9978903008232,
                            254.25892858277388
                        ],
                        [
                            187.89622221151444,
                            257.6379874741467
                        ],
                        [
                            188.0985694115916,
                            258.6180564427656
                        ],
                        [
                            187.67139198920677,
                            259.7718163536691
                        ],
                        [
                            186.77207109997494,
                            259.7718163536691
                        ],
                        [
                            186.16502949974392,
                            261.3599991470084
                        ],
                        [
                            186.50227483320577,
                            264.33758500130074
                        ],
                        [
                            185.37812372166627,
                            266.55197254752545
                        ],
                        [
                            184.79356514366577,
                            266.7472578617716
                        ],
                        [
                            183.42210078758762,
                            265.5752986776284
                        ],
                        [
                            181.8707722536633,
                            264.85880861040016
                        ],
                        [
                            181.17379856450884,
                            263.5338005046621
                        ],
                        [
                            179.59998700835354,
                            262.0558402913773
                        ],
                        [
                            177.44161687419773,
                            262.16454633377396
                        ],
                        [
                            176.63222807388956,
                            263.055741196677
                        ],
                        [
                            174.45137491750302,
                            263.5338005046621
                        ],
                        [
                            171.82086131650067,
                            262.2949867653879
                        ],
                        [
                            168.87558540426744,
                            263.2730532301903
                        ],
                        [
                            168.4484079818826,
                            262.4036814447588
                        ],
                        [
                            168.24606078180545,
                            261.1642556092064
                        ],
                        [
                            166.71721527011186,
                            261.1642556092064
                        ],
                        [
                            164.40146398034085,
                            260.46814244401054
                        ],
                        [
                            164.24408282472518,
                            261.5774722777289
                        ],
                        [
                            162.08571269056938,
                            262.25150744789653
                        ],
                        [
                            160.57935020110654,
                            262.0558402913773
                        ],
                        [
                            159.72499535633665,
                            262.49063347171574
                        ],
                        [
                            159.477682111798,
                            263.9031417994257
                        ],
                        [
                            154.84617953225575,
                            264.1203736589941
                        ],
                        [
                            153.76699446517773,
                            263.4686164629562
                        ],
                        [
                            152.62036033140748,
                            263.96831351258334
                        ],
                        [
                            152.41801313133033,
                            262.79493956616363
                        ],
                        [
                            151.29386201979105,
                            262.5123709624061
                        ],
                        [
                            149.54018628578933,
                            263.09920525066366
                        ],
                        [
                            148.8432125966351,
                            263.92486590912625
                        ],
                        [
                            146.6848424624793,
                            265.16280127333005
                        ],
                        [
                            144.54895535055425,
                            265.74895974848255
                        ],
                        [
                            143.334872150092,
                            265.3582039711842
                        ],
                        [
                            139.80503765985804,
                            265.5535901255982
                        ],
                        [
                            138.72585259278026,
                            265.90090247803136
                        ],
                        [
                            138.23122610370297,
                            263.07747332665
                        ],
                        [
                            140.0073848599352,
                            263.4903446825006
                        ],
                        [
                            139.53524139308888,
                            260.685700655545
                        ],
                        [
                            138.59095445939533,
                            259.6412314632642
                        ],
                        [
                            135.66816156939308,
                            259.83710598439757
                        ],
                        [
                            134.63394254677678,
                            260.9249906914684
                        ],
                        [
                            132.45308939039046,
                            260.5551682229161
                        ],
                        [
                            132.13832707915913,
                            260.79447196891806
                        ],
                        [
                            131.89101383462048,
                            260.9467430852385
                        ],
                        [
                            131.64370059008206,
                            261.0990040305394
                        ],
                        [
                            131.5312854789281,
                            260.8814852811474
                        ],
                        [
                            130.11485507838825,
                            262.38194292188825
                        ],
                        [
                            129.41788138923403,
                            262.12106453687977
                        ],
                        [
                            128.338696322156,
                            262.99054357083503
                        ],
                        [
                            128.36117934438676,
                            264.4896207419155
                        ],
                        [
                            125.97797898792305,
                            266.5736717289267
                        ],
                        [
                            126.58502058815475,
                            267.3763986401044
                        ],
                        [
                            126.00046201015402,
                            268.8943126460534
                        ],
                        [
                            124.0444390760756,
                            268.78592304068036
                        ],
                        [
                            123.8645748982292,
                            269.479529481531
                        ],
                        [
                            122.47062751992007,
                            270.60620087741404
                        ],
                        [
                            119.30052138537894,
                            270.5628773920299
                        ],
                        [
                            119.18810627422499,
                            267.78850185591307
                        ],
                        [
                            118.2438193405319,
                            265.9443128517437
                        ],
                        [
                            116.64752476214608,
                            265.9877224102602
                        ],
                        [
                            111.09421827114147,
                            265.6621308451458
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Maharashtra",
        "properties": {
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            172.81011429465548,
                            294.0802899650972
                        ],
                        [
                            171.88831038319313,
                            293.60828274047594
                        ],
                        [
                            167.88633242611263,
                            295.3028059785232
                        ],
                        [
                            166.3350038921883,
                            294.12319531642106
                        ],
                        [
                            164.67126024711,
                            293.865752221271
                        ],
                        [
                            163.74945633564766,
                            295.9459975097123
                        ],
                        [
                            162.37799197956974,
                            294.80958144369424
                        ],
                        [
                            160.01727464533678,
                            294.53075965343004
                        ],
                        [
                            160.30955393433715,
                            292.87864235534573
                        ],
                        [
                            158.8931235337975,
                            292.3634740927217
                        ],
                        [
                            158.6233272670279,
                            291.22543443707144
                        ],
                        [
                            154.71128139887082,
                            290.3447074875358
                        ],
                        [
                            152.59787730917674,
                            290.40916149935356
                        ],
                        [
                            151.8559375755608,
                            289.3776964312693
                        ],
                        [
                            150.75426948625227,
                            288.88330059587133
                        ],
                        [
                            151.60862433102193,
                            290.92473345138
                        ],
                        [
                            151.29386201979105,
                            291.97702823496
                        ],
                        [
                            150.30460904163624,
                            292.3205386148391
                        ],
                        [
                            150.75426948625227,
                            293.65119615408014
                        ],
                        [
                            150.79923553071376,
                            295.6029824072024
                        ],
                        [
                            149.36032210794315,
                            296.43900022765195
                        ],
                        [
                            149.09052584117376,
                            298.17446447106767
                        ],
                        [
                            148.82072957440437,
                            299.395008135298
                        ],
                        [
                            146.86470664032572,
                            298.88116616423434
                        ],
                        [
                            145.42579321755534,
                            297.76748666660114
                        ],
                        [
                            144.23419303932337,
                            298.36722072773574
                        ],
                        [
                            144.18922699486188,
                            300.2939832584313
                        ],
                        [
                            143.26742308339954,
                            301.9626734214149
                        ],
                        [
                            143.94191375032324,
                            304.52778378512915
                        ],
                        [
                            145.49324228424734,
                            305.5317614246396
                        ],
                        [
                            143.89694770586152,
                            306.1296920331829
                        ],
                        [
                            143.1325249500146,
                            308.02935045477244
                        ],
                        [
                            142.27817010524473,
                            308.28537903189954
                        ],
                        [
                            142.41306823862965,
                            309.991601073371
                        ],
                        [
                            140.3221471711663,
                            310.0982033786218
                        ],
                        [
                            140.1422829933199,
                            311.69672404844084
                        ],
                        [
                            139.26544512631926,
                            312.8470646176613
                        ],
                        [
                            139.6476565042426,
                            314.0820644185967
                        ],
                        [
                            138.3886072593184,
                            314.5503638720703
                        ],
                        [
                            137.55673543677926,
                            313.9330429162161
                        ],
                        [
                            137.62418450347172,
                            312.52757531400954
                        ],
                        [
                            136.20775410293209,
                            311.7606448813965
                        ],
                        [
                            135.4433313470854,
                            312.05892182099956
                        ],
                        [
                            135.1735350803158,
                            312.86836254626604
                        ],
                        [
                            132.49805543485195,
                            316.27385554479633
                        ],
                        [
                            130.83431178977366,
                            315.6994806846033
                        ],
                        [
                            130.38465134515786,
                            316.78430856963826
                        ],
                        [
                            130.8567948120044,
                            317.9749919944694
                        ],
                        [
                            130.15982112284973,
                            318.4638716117512
                        ],
                        [
                            130.36216832292712,
                            319.9087425726369
                        ],
                        [
                            128.2487642332328,
                            320.50346770989375
                        ],
                        [
                            127.73165472192477,
                            320.1636405742418
                        ],
                        [
                            128.47359445554093,
                            321.28915690173204
                        ],
                        [
                            127.3494433440012,
                            321.9897174869891
                        ],
                        [
                            126.92226592161637,
                            323.5175966334206
                        ],
                        [
                            126.49508849923131,
                            322.9659598241531
                        ],
                        [
                            124.53906556515267,
                            322.3080952848776
                        ],
                        [
                            124.0444390760756,
                            323.8570108987007
                        ],
                        [
                            123.145118186844,
                            324.1115443718832
                        ],
                        [
                            121.72868778630436,
                            324.8325960536707
                        ],
                        [
                            121.70620476407362,
                            326.78274345349035
                        ],
                        [
                            122.31324636430463,
                            327.3124403099387
                        ],
                        [
                            122.47062751992007,
                            329.17618098117003
                        ],
                        [
                            120.22232529684129,
                            328.392712737739
                        ],
                        [
                            117.95154005153177,
                            328.8374105520343
                        ],
                        [
                            115.83813596183768,
                            329.0491468244974
                        ],
                        [
                            115.2985434282989,
                            327.84203738422815
                        ],
                        [
                            114.10694325006693,
                            328.2868219818396
                        ],
                        [
                            111.45394662683384,
                            326.67679208825695
                        ],
                        [
                            110.57710875983321,
                            328.1173885046705
                        ],
                        [
                            111.02676920444901,
                            329.3243802917495
                        ],
                        [
                            111.76870893806495,
                            329.8112663085058
                        ],
                        [
                            111.34153151567989,
                            331.35604764336426
                        ],
                        [
                            112.03850520483456,
                            332.24444673844187
                        ],
                        [
                            111.92609009368039,
                            334.33744160267224
                        ],
                        [
                            109.92510111514025,
                            334.4853735317836
                        ],
                        [
                            108.98081418144716,
                            334.1895020868333
                        ],
                        [
                            107.15968938075343,
                            334.696691708251
                        ],
                        [
                            106.26036849152183,
                            334.696691708251
                        ],
                        [
                            106.0355382692137,
                            336.13324608585606
                        ],
                        [
                            104.82145506875145,
                            336.42891896263467
                        ],
                        [
                            103.4499907126733,
                            334.7600841461107
                        ],
                        [
                            100.70706200051723,
                            336.13324608585606
                        ],
                        [
                            100.95437524505564,
                            337.50575903981166
                        ],
                        [
                            99.94263924467032,
                            337.50575903981166
                        ],
                        [
                            97.67185399936079,
                            338.3922702636092
                        ],
                        [
                            96.70508404343695,
                            340.4175760379173
                        ],
                        [
                            95.8282461764361,
                            340.86042610897977
                        ],
                        [
                            95.44603479851253,
                            339.616059271383
                        ],
                        [
                            92.07358146389424,
                            340.86042610897977
                        ],
                        [
                            92.32089470843289,
                            342.39934215564455
                        ],
                        [
                            92.09606448612521,
                            344.3587269167076
                        ],
                        [
                            92.92793630866436,
                            345.3484658903204
                        ],
                        [
                            94.50174786481944,
                            345.53795319410347
                        ],
                        [
                            94.50174786481944,
                            347.09550870021513
                        ],
                        [
                            92.90545328643361,
                            348.14745754022647
                        ],
                        [
                            94.25443462028079,
                            348.75742110718363
                        ],
                        [
                            93.13028350874151,
                            350.81777654426855
                        ],
                        [
                            92.52324190851004,
                            352.96076267340675
                        ],
                        [
                            90.94943035235497,
                            353.25478217715886
                        ],
                        [
                            90.52225292996968,
                            352.70872376518497
                        ],
                        [
                            89.24072066281497,
                            353.63276634512624
                        ],
                        [
                            89.1732715961225,
                            354.68248154546563
                        ],
                        [
                            87.30718075096706,
                            355.29115460849647
                        ],
                        [
                            86.54275799512038,
                            353.2967826895309
                        ],
                        [
                            85.59847106142706,
                            353.17077944756636
                        ],
                        [
                            82.81057630480927,
                            353.5067767316349
                        ],
                        [
                            81.39414590426986,
                            350.7337075076276
                        ],
                        [
                            80.24751177049961,
                            350.3133278073674
                        ],
                        [
                            78.78611532549826,
                            345.18002271110595
                        ],
                        [
                            78.15659070303627,
                            343.79000567715116
                        ],
                        [
                            77.84182839180517,
                            341.239959090874
                        ],
                        [
                            77.90927745849763,
                            339.93247351358445
                        ],
                        [
                            77.23478679157415,
                            338.16011472352744
                        ],
                        [
                            77.41465096942034,
                            337.0201745538279
                        ],
                        [
                            76.80760936918887,
                            335.68968030816086
                        ],
                        [
                            77.09988865818923,
                            334.25290566578303
                        ],
                        [
                            76.98747354703528,
                            332.24444673844187
                        ],
                        [
                            76.02070359111121,
                            329.93826630476786
                        ],
                        [
                            76.33546590234232,
                            329.07031957999726
                        ],
                        [
                            75.43614501311072,
                            328.09620860424155
                        ],
                        [
                            75.34621292418797,
                            325.0446345519327
                        ],
                        [
                            73.39018999010932,
                            318.888913027936
                        ],
                        [
                            72.33348794526205,
                            316.6779721268407
                        ],
                        [
                            72.28852190080056,
                            314.84833013741405
                        ],
                        [
                            73.16535976780119,
                            314.5077945673256
                        ],
                        [
                            71.47913310049216,
                            313.1239244569379
                        ],
                        [
                            71.1868538114918,
                            311.4410253651083
                        ],
                        [
                            71.77141238949253,
                            310.22612048051855
                        ],
                        [
                            70.62477825572228,
                            307.77329694908633
                        ],
                        [
                            70.80464243356846,
                            306.21509951831786
                        ],
                        [
                            70.33249896672191,
                            305.2113850766203
                        ],
                        [
                            69.83787247764462,
                            304.5705141286223
                        ],
                        [
                            70.3774650111834,
                            302.3476022081399
                        ],
                        [
                            69.59055923310598,
                            301.0856764485497
                        ],
                        [
                            69.568076210875,
                            298.9882251001346
                        ],
                        [
                            69.77042341095216,
                            298.36722072773574
                        ],
                        [
                            68.6013062549514,
                            294.7023459465084
                        ],
                        [
                            68.55634021048968,
                            293.0288797975515
                        ],
                        [
                            67.6570193212583,
                            290.06538744305016
                        ],
                        [
                            67.79191745464277,
                            288.5393147749173
                        ],
                        [
                            68.80365345502832,
                            287.9372243010922
                        ],
                        [
                            68.44392509933573,
                            286.34527654727094
                        ],
                        [
                            69.43317807749031,
                            285.44127939184284
                        ],
                        [
                            70.87209150026092,
                            283.95541555045895
                        ],
                        [
                            72.17610678964661,
                            284.23543007389594
                        ],
                        [
                            72.2210728341081,
                            285.505861680397
                        ],
                        [
                            74.76165434618724,
                            286.1515909311974
                        ],
                        [
                            75.84083941326503,
                            285.61349504214576
                        ],
                        [
                            76.40291496903478,
                            285.59196874830667
                        ],
                        [
                            77.14485470265072,
                            284.2138916369992
                        ],
                        [
                            78.85356439219072,
                            284.30004424326154
                        ],
                        [
                            78.67370021434431,
                            281.4553955188571
                        ],
                        [
                            79.6179871480374,
                            279.7297378646218
                        ],
                        [
                            79.68543621472986,
                            278.3483203019426
                        ],
                        [
                            78.7186662588058,
                            277.72210335817573
                        ],
                        [
                            79.7079192369606,
                            276.209868716718
                        ],
                        [
                            81.7763572821932,
                            277.70050674738934
                        ],
                        [
                            82.33843283796296,
                            278.65057334408
                        ],
                        [
                            83.68741417181013,
                            278.65057334408
                        ],
                        [
                            85.05887852788828,
                            277.7652959947034
                        ],
                        [
                            85.59847106142706,
                            276.0585924836837
                        ],
                        [
                            86.2279956838895,
                            276.14503722256995
                        ],
                        [
                            86.58772403958187,
                            274.3290369398761
                        ],
                        [
                            85.80081826150422,
                            272.8579210614112
                        ],
                        [
                            85.21625968350395,
                            271.84058389546976
                        ],
                        [
                            85.21625968350395,
                            271.8189336445579
                        ],
                        [
                            84.60921808327248,
                            271.6673763040509
                        ],
                        [
                            84.5192859943495,
                            271.34257764751965
                        ],
                        [
                            83.57499906065641,
                            270.5628773920299
                        ],
                        [
                            82.54078003804011,
                            269.37116706610857
                        ],
                        [
                            84.72163319442643,
                            269.06772553304796
                        ],
                        [
                            84.83404830558038,
                            267.5499246969801
                        ],
                        [
                            86.58772403958187,
                            267.072696816181
                        ],
                        [
                            86.83503728412052,
                            265.5752986776284
                        ],
                        [
                            88.11656955127569,
                            264.7719461996339
                        ],
                        [
                            90.52225292996968,
                            264.51133931372397
                        ],
                        [
                            92.16351355281745,
                            263.94658981350665
                        ],
                        [
                            91.48902288589375,
                            262.94707745684065
                        ],
                        [
                            89.84776226304643,
                            262.8384085655481
                        ],
                        [
                            87.1722826175826,
                            263.55552810731183
                        ],
                        [
                            87.1722826175826,
                            263.57725550436294
                        ],
                        [
                            85.0139124834268,
                            263.59898269583147
                        ],
                        [
                            84.69915017219569,
                            261.64271017786166
                        ],
                        [
                            85.68840315035027,
                            260.533412090034
                        ],
                        [
                            84.85653132781113,
                            259.79357977235304
                        ],
                        [
                            84.74411621665718,
                            258.530956389162
                        ],
                        [
                            86.54275799512038,
                            258.00828567001014
                        ],
                        [
                            89.60044901850756,
                            256.78825030177217
                        ],
                        [
                            91.57895497481695,
                            257.04974198633124
                        ],
                        [
                            93.80477417566499,
                            255.52394551983252
                        ],
                        [
                            94.09705346466512,
                            256.61390565516456
                        ],
                        [
                            95.1537555095124,
                            257.398350411083
                        ],
                        [
                            94.86147622051226,
                            260.3375975372823
                        ],
                        [
                            96.00811035428228,
                            261.3165020342865
                        ],
                        [
                            98.84097115536156,
                            262.09932332841703
                        ],
                        [
                            100.1225034225165,
                            261.79492469904255
                        ],
                        [
                            102.91039817913429,
                            262.86014275602156
                        ],
                        [
                            103.71978697944269,
                            264.51133931372397
                        ],
                        [
                            105.24863249113628,
                            265.31478257953853
                        ],
                        [
                            107.76673098098445,
                            265.81407928449573
                        ],
                        [
                            111.09421827114147,
                            265.6621308451458
                        ],
                        [
                            116.64752476214608,
                            265.9877224102602
                        ],
                        [
                            118.2438193405319,
                            265.9443128517437
                        ],
                        [
                            119.18810627422499,
                            267.78850185591307
                        ],
                        [
                            119.30052138537894,
                            270.5628773920299
                        ],
                        [
                            122.47062751992007,
                            270.60620087741404
                        ],
                        [
                            123.8645748982292,
                            269.479529481531
                        ],
                        [
                            124.0444390760756,
                            268.78592304068036
                        ],
                        [
                            126.00046201015402,
                            268.8943126460534
                        ],
                        [
                            126.58502058815475,
                            267.3763986401044
                        ],
                        [
                            125.97797898792305,
                            266.5736717289267
                        ],
                        [
                            128.36117934438676,
                            264.4896207419155
                        ],
                        [
                            128.338696322156,
                            262.99054357083503
                        ],
                        [
                            129.41788138923403,
                            262.12106453687977
                        ],
                        [
                            130.11485507838825,
                            262.38194292188825
                        ],
                        [
                            131.5312854789281,
                            260.8814852811474
                        ],
                        [
                            131.64370059008206,
                            261.0990040305394
                        ],
                        [
                            131.89101383462048,
                            260.9467430852385
                        ],
                        [
                            132.13832707915913,
                            260.79447196891806
                        ],
                        [
                            132.45308939039046,
                            260.5551682229161
                        ],
                        [
                            134.63394254677678,
                            260.9249906914684
                        ],
                        [
                            135.66816156939308,
                            259.83710598439757
                        ],
                        [
                            138.59095445939533,
                            259.6412314632642
                        ],
                        [
                            139.53524139308888,
                            260.685700655545
                        ],
                        [
                            140.0073848599352,
                            263.4903446825006
                        ],
                        [
                            138.23122610370297,
                            263.07747332665
                        ],
                        [
                            138.72585259278026,
                            265.90090247803136
                        ],
                        [
                            139.80503765985804,
                            265.5535901255982
                        ],
                        [
                            143.334872150092,
                            265.3582039711842
                        ],
                        [
                            144.54895535055425,
                            265.74895974848255
                        ],
                        [
                            146.6848424624793,
                            265.16280127333005
                        ],
                        [
                            148.8432125966351,
                            263.92486590912625
                        ],
                        [
                            149.54018628578933,
                            263.09920525066366
                        ],
                        [
                            151.29386201979105,
                            262.5123709624061
                        ],
                        [
                            152.41801313133033,
                            262.79493956616363
                        ],
                        [
                            152.62036033140748,
                            263.96831351258334
                        ],
                        [
                            153.76699446517773,
                            263.4686164629562
                        ],
                        [
                            154.84617953225575,
                            264.1203736589941
                        ],
                        [
                            159.477682111798,
                            263.9031417994257
                        ],
                        [
                            159.72499535633665,
                            262.49063347171574
                        ],
                        [
                            160.57935020110654,
                            262.0558402913773
                        ],
                        [
                            162.08571269056938,
                            262.25150744789653
                        ],
                        [
                            164.24408282472518,
                            261.5774722777289
                        ],
                        [
                            164.40146398034085,
                            260.46814244401054
                        ],
                        [
                            166.71721527011186,
                            261.1642556092064
                        ],
                        [
                            168.24606078180545,
                            261.1642556092064
                        ],
                        [
                            168.4484079818826,
                            262.4036814447588
                        ],
                        [
                            168.87558540426744,
                            263.2730532301903
                        ],
                        [
                            171.82086131650067,
                            262.2949867653879
                        ],
                        [
                            174.45137491750302,
                            263.5338005046621
                        ],
                        [
                            176.63222807388956,
                            263.055741196677
                        ],
                        [
                            177.44161687419773,
                            262.16454633377396
                        ],
                        [
                            179.59998700835354,
                            262.0558402913773
                        ],
                        [
                            181.17379856450884,
                            263.5338005046621
                        ],
                        [
                            181.8707722536633,
                            264.85880861040016
                        ],
                        [
                            183.42210078758762,
                            265.5752986776284
                        ],
                        [
                            184.79356514366577,
                            266.7472578617716
                        ],
                        [
                            185.37812372166627,
                            266.55197254752545
                        ],
                        [
                            184.68115003251182,
                            268.243899348228
                        ],
                        [
                            182.47781385389453,
                            269.046049627439
                        ],
                        [
                            182.11808549820194,
                            270.28125519830496
                        ],
                        [
                            182.47781385389453,
                            272.92284256151237
                        ],
                        [
                            183.691897054357,
                            272.83628016414497
                        ],
                        [
                            183.91672727666514,
                            274.5885506642957
                        ],
                        [
                            184.1865235434343,
                            276.9445023831133
                        ],
                        [
                            183.30968567643367,
                            278.4130920196855
                        ],
                        [
                            184.72611607697354,
                            278.32672934026704
                        ],
                        [
                            184.34390469904997,
                            281.34757805026777
                        ],
                        [
                            184.86101421035823,
                            282.29620696045447
                        ],
                        [
                            181.46607785350898,
                            283.67536884329616
                        ],
                        [
                            182.00567038704799,
                            285.3766953991411
                        ],
                        [
                            183.08485545412577,
                            285.24752229959677
                        ],
                        [
                            183.66941403212627,
                            285.9363667516583
                        ],
                        [
                            183.44458380981837,
                            288.4533108385035
                        ],
                        [
                            182.54526292058677,
                            290.2157944473971
                        ],
                        [
                            183.691897054357,
                            290.3447074875358
                        ],
                        [
                            183.9392102988959,
                            291.2683887574663
                        ],
                        [
                            185.5355048772817,
                            292.14878932807136
                        ],
                        [
                            186.16502949974392,
                            293.5009959963532
                        ],
                        [
                            188.8180261229768,
                            295.00259383908593
                        ],
                        [
                            188.18850150051458,
                            297.4247180004906
                        ],
                        [
                            186.70462203328293,
                            298.6242063924207
                        ],
                        [
                            184.65866701028108,
                            298.2815530809514
                        ],
                        [
                            184.14155749897282,
                            296.9104782590516
                        ],
                        [
                            182.74761012066392,
                            297.85317161362866
                        ],
                        [
                            181.44359483127823,
                            300.18697895462606
                        ],
                        [
                            180.54427394204663,
                            300.8503336990302
                        ],
                        [
                            180.4993078975849,
                            302.0054461237708
                        ],
                        [
                            179.64495305281525,
                            303.9935951878714
                        ],
                        [
                            180.7915871865855,
                            305.4676893045989
                        ],
                        [
                            179.68991909727674,
                            306.748833118846
                        ],
                        [
                            179.71240211950771,
                            307.45319497145044
                        ],
                        [
                            177.39665082973625,
                            308.0720236147391
                        ],
                        [
                            175.93525438473512,
                            306.6634430578111
                        ],
                        [
                            174.38392585081078,
                            305.80938909539
                        ],
                        [
                            175.08089953996523,
                            305.1900252530876
                        ],
                        [
                            174.76613722873412,
                            302.5614267274125
                        ],
                        [
                            173.57453705050239,
                            301.5135171753282
                        ],
                        [
                            174.60875607311846,
                            300.6363670325046
                        ],
                        [
                            175.32821278450388,
                            296.8890493416166
                        ],
                        [
                            174.83358629542658,
                            295.77449577791907
                        ],
                        [
                            173.66446913942536,
                            295.1527043752976
                        ],
                        [
                            172.81011429465548,
                            294.0802899650972
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Chhattisgarh",
        "properties": {
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            205.32056444037562,
                            286.7326019392257
                        ],
                        [
                            204.26386239552858,
                            285.85027178905193
                        ],
                        [
                            203.22964337291228,
                            286.689568800756
                        ],
                        [
                            203.22964337291228,
                            286.71108546420237
                        ],
                        [
                            202.77998292829625,
                            288.25979103151303
                        ],
                        [
                            203.1397112839893,
                            289.03377934799676
                        ],
                        [
                            204.6011077289902,
                            289.7000753384096
                        ],
                        [
                            204.84842097352907,
                            290.6025135068488
                        ],
                        [
                            206.01753812953007,
                            290.7743693372183
                        ],
                        [
                            205.92760604060686,
                            293.3078682953254
                        ],
                        [
                            205.47794559599083,
                            295.1955914589453
                        ],
                        [
                            206.5346476408381,
                            295.04548347555584
                        ],
                        [
                            206.91685901876144,
                            296.4175673234107
                        ],
                        [
                            207.8386629302238,
                            296.43900022765195
                        ],
                        [
                            207.43396853006948,
                            299.9515537585971
                        ],
                        [
                            208.08597617476266,
                            301.214036139142
                        ],
                        [
                            208.67053475276293,
                            304.3568553860261
                        ],
                        [
                            207.6587987523776,
                            304.6132437698453
                        ],
                        [
                            207.389002485608,
                            306.38590611835997
                        ],
                        [
                            206.39974950745318,
                            307.17574167819885
                        ],
                        [
                            203.58937172860487,
                            308.3707163557692
                        ],
                        [
                            203.7467528842203,
                            309.7783835813269
                        ],
                        [
                            201.67831483898772,
                            312.1228339647078
                        ],
                        [
                            201.38603554998758,
                            313.1239244569379
                        ],
                        [
                            200.0370542161404,
                            313.23040138013846
                        ],
                        [
                            198.148480348754,
                            314.89089405277133
                        ],
                        [
                            198.08103128206176,
                            316.2313134453493
                        ],
                        [
                            197.4290236373688,
                            317.80492631959953
                        ],
                        [
                            197.45150665959954,
                            318.95266352806374
                        ],
                        [
                            196.2374234591373,
                            320.2486013062439
                        ],
                        [
                            196.19245741467557,
                            321.41654480746115
                        ],
                        [
                            194.25891750282767,
                            321.52269689282633
                        ],
                        [
                            192.79752105782632,
                            320.80078193469564
                        ],
                        [
                            191.60592087959458,
                            321.7349887910929
                        ],
                        [
                            190.59418487920925,
                            321.77744520815463
                        ],
                        [
                            189.91969421228555,
                            317.8687021941131
                        ],
                        [
                            190.05459234567002,
                            316.0398657125112
                        ],
                        [
                            188.39084870059196,
                            316.44401725113886
                        ],
                        [
                            188.18850150051458,
                            315.42288623743144
                        ],
                        [
                            186.77207109997494,
                            315.97604678746836
                        ],
                        [
                            186.6596559888212,
                            313.8265939175697
                        ],
                        [
                            186.030131366359,
                            311.8032579175971
                        ],
                        [
                            185.01839536597345,
                            310.5032530175455
                        ],
                        [
                            182.3429157205096,
                            308.8400222210896
                        ],
                        [
                            181.12883252004713,
                            309.13862791742974
                        ],
                        [
                            179.71240211950771,
                            307.45319497145044
                        ],
                        [
                            179.68991909727674,
                            306.748833118846
                        ],
                        [
                            180.7915871865855,
                            305.4676893045989
                        ],
                        [
                            179.64495305281525,
                            303.9935951878714
                        ],
                        [
                            180.4993078975849,
                            302.0054461237708
                        ],
                        [
                            180.54427394204663,
                            300.8503336990302
                        ],
                        [
                            181.44359483127823,
                            300.18697895462606
                        ],
                        [
                            182.74761012066392,
                            297.85317161362866
                        ],
                        [
                            184.14155749897282,
                            296.9104782590516
                        ],
                        [
                            184.65866701028108,
                            298.2815530809514
                        ],
                        [
                            186.70462203328293,
                            298.6242063924207
                        ],
                        [
                            188.18850150051458,
                            297.4247180004906
                        ],
                        [
                            188.8180261229768,
                            295.00259383908593
                        ],
                        [
                            186.16502949974392,
                            293.5009959963532
                        ],
                        [
                            185.5355048772817,
                            292.14878932807136
                        ],
                        [
                            183.9392102988959,
                            291.2683887574663
                        ],
                        [
                            183.691897054357,
                            290.3447074875358
                        ],
                        [
                            182.54526292058677,
                            290.2157944473971
                        ],
                        [
                            183.44458380981837,
                            288.4533108385035
                        ],
                        [
                            183.66941403212627,
                            285.9363667516583
                        ],
                        [
                            183.08485545412577,
                            285.24752229959677
                        ],
                        [
                            182.00567038704799,
                            285.3766953991411
                        ],
                        [
                            181.46607785350898,
                            283.67536884329616
                        ],
                        [
                            184.86101421035823,
                            282.29620696045447
                        ],
                        [
                            184.34390469904997,
                            281.34757805026777
                        ],
                        [
                            184.72611607697354,
                            278.32672934026704
                        ],
                        [
                            183.30968567643367,
                            278.4130920196855
                        ],
                        [
                            184.1865235434343,
                            276.9445023831133
                        ],
                        [
                            183.91672727666514,
                            274.5885506642957
                        ],
                        [
                            183.691897054357,
                            272.83628016414497
                        ],
                        [
                            182.47781385389453,
                            272.92284256151237
                        ],
                        [
                            182.11808549820194,
                            270.28125519830496
                        ],
                        [
                            182.47781385389453,
                            269.046049627439
                        ],
                        [
                            184.68115003251182,
                            268.243899348228
                        ],
                        [
                            185.37812372166627,
                            266.55197254752545
                        ],
                        [
                            186.50227483320577,
                            264.33758500130074
                        ],
                        [
                            186.16502949974392,
                            261.3599991470084
                        ],
                        [
                            186.77207109997494,
                            259.7718163536691
                        ],
                        [
                            187.67139198920677,
                            259.7718163536691
                        ],
                        [
                            188.0985694115916,
                            258.6180564427656
                        ],
                        [
                            187.89622221151444,
                            257.6379874741467
                        ],
                        [
                            188.9978903008232,
                            254.25892858277388
                        ],
                        [
                            190.41432070136307,
                            255.0660045616135
                        ],
                        [
                            191.02136230159408,
                            252.1853710286345
                        ],
                        [
                            192.10054736867187,
                            251.3772525043865
                        ],
                        [
                            191.98813225751792,
                            249.08233841932707
                        ],
                        [
                            193.20221545798063,
                            248.2074582187015
                        ],
                        [
                            194.01160425828903,
                            248.7105567512076
                        ],
                        [
                            195.18072141429002,
                            247.6604815874491
                        ],
                        [
                            197.3390915484456,
                            248.22933445700005
                        ],
                        [
                            199.49746168260117,
                            247.46353669894637
                        ],
                        [
                            199.90215608275548,
                            246.23770477966417
                        ],
                        [
                            201.0712732387567,
                            245.3616905377184
                        ],
                        [
                            201.1162392832182,
                            245.25216408295194
                        ],
                        [
                            202.03804319468054,
                            243.82781977773777
                        ],
                        [
                            201.76824692791092,
                            242.09543771126374
                        ],
                        [
                            203.02729617283535,
                            241.8541102221863
                        ],
                        [
                            204.3313114622208,
                            240.36166969947817
                        ],
                        [
                            204.21889635106686,
                            238.82425466291818
                        ],
                        [
                            206.87189297429995,
                            238.42874169653413
                        ],
                        [
                            207.99604408583946,
                            235.06392873366076
                        ],
                        [
                            206.5796136852996,
                            233.72091998100075
                        ],
                        [
                            204.84842097352907,
                            233.45662151261507
                        ],
                        [
                            203.83668497314352,
                            231.53947014451995
                        ],
                        [
                            202.42025457260388,
                            231.8480946854454
                        ],
                        [
                            201.49845066114153,
                            231.29694775870172
                        ],
                        [
                            199.34008052698596,
                            231.89218022226925
                        ],
                        [
                            199.38504657144745,
                            229.4661050078696
                        ],
                        [
                            200.59912977190993,
                            228.5390460075647
                        ],
                        [
                            199.34008052698596,
                            226.68369487904596
                        ],
                        [
                            199.227665415832,
                            225.9101446773575
                        ],
                        [
                            200.1719523495251,
                            225.2689851524994
                        ],
                        [
                            201.026307194295,
                            226.63949974793672
                        ],
                        [
                            202.2628734169882,
                            227.14768721058402
                        ],
                        [
                            203.90413403983598,
                            226.13118834659565
                        ],
                        [
                            205.16318328475995,
                            226.2859049496139
                        ],
                        [
                            206.2648513740687,
                            227.01512850484846
                        ],
                        [
                            208.13094221922393,
                            226.41851003224383
                        ],
                        [
                            208.89536497507106,
                            226.92675135483387
                        ],
                        [
                            212.38023342084307,
                            227.5232244989087
                        ],
                        [
                            214.40370542161395,
                            226.66159743062695
                        ],
                        [
                            215.25806026638406,
                            225.35743270862667
                        ],
                        [
                            217.01173600038555,
                            224.67186558224176
                        ],
                        [
                            218.99024195669494,
                            226.13118834659565
                        ],
                        [
                            221.10364604638903,
                            226.13118834659565
                        ],
                        [
                            222.8123557359288,
                            225.31320940126614
                        ],
                        [
                            224.610997514392,
                            222.41452584882916
                        ],
                        [
                            226.11736000385486,
                            222.6802232120328
                        ],
                        [
                            227.33144320431734,
                            224.36218035573995
                        ],
                        [
                            228.16331502685648,
                            226.2638032816111
                        ],
                        [
                            230.1418209831661,
                            226.94884599338133
                        ],
                        [
                            231.15355698355143,
                            230.32657898710443
                        ],
                        [
                            233.55924036224587,
                            230.98824255043223
                        ],
                        [
                            234.7058744960159,
                            229.92948093797713
                        ],
                        [
                            234.68339147378515,
                            232.02443131085795
                        ],
                        [
                            233.91896871793847,
                            233.91912222297583
                        ],
                        [
                            235.56022934078578,
                            234.75577223713685
                        ],
                        [
                            235.020636807247,
                            237.35169797588736
                        ],
                        [
                            235.40284818517057,
                            238.27491142164945
                        ],
                        [
                            237.0890748524796,
                            240.405579728637
                        ],
                        [
                            239.96690169802037,
                            240.4275344081202
                        ],
                        [
                            240.30414703148222,
                            241.23970066169045
                        ],
                        [
                            239.22496196440443,
                            242.46834553154054
                        ],
                        [
                            238.77530151978885,
                            243.71821626484558
                        ],
                        [
                            237.96591271948046,
                            245.01118655356788
                        ],
                        [
                            236.70686347455626,
                            245.88734116252078
                        ],
                        [
                            235.6951274741707,
                            245.86544157465636
                        ],
                        [
                            234.6159424070927,
                            246.8944856115864
                        ],
                        [
                            234.63842542932343,
                            247.61671758132377
                        ],
                        [
                            235.1555349406317,
                            248.55745196428205
                        ],
                        [
                            234.6159424070927,
                            250.10987980142187
                        ],
                        [
                            232.4575722729371,
                            250.54698672957738
                        ],
                        [
                            231.5582513837055,
                            251.33356204939224
                        ],
                        [
                            228.99518684939585,
                            252.7748939746333
                        ],
                        [
                            227.66868853777942,
                            255.28408333778236
                        ],
                        [
                            227.69117156001016,
                            256.54852293384806
                        ],
                        [
                            228.45559431585684,
                            258.7704734776293
                        ],
                        [
                            226.5445374262399,
                            260.79447196891806
                        ],
                        [
                            226.92674880416303,
                            261.64271017786166
                        ],
                        [
                            225.39790329246944,
                            262.0123564274094
                        ],
                        [
                            224.72341262554596,
                            263.9031417994257
                        ],
                        [
                            225.62273351477756,
                            265.423334526894
                        ],
                        [
                            225.53280142585436,
                            266.4000725832204
                        ],
                        [
                            223.8016087140836,
                            265.8574912889921
                        ],
                        [
                            223.7566426696219,
                            265.8574912889921
                        ],
                        [
                            223.7116766251604,
                            265.8574912889921
                        ],
                        [
                            222.58752551362113,
                            269.60955774234714
                        ],
                        [
                            220.90129884631187,
                            270.0429330249793
                        ],
                        [
                            219.26003822346433,
                            269.046049627439
                        ],
                        [
                            214.4711544883064,
                            269.37116706610857
                        ],
                        [
                            214.44867146607567,
                            270.4112388816923
                        ],
                        [
                            213.30203733230542,
                            272.64150314787855
                        ],
                        [
                            212.24533528745815,
                            273.2474232625957
                        ],
                        [
                            211.81815786507332,
                            274.5453003510488
                        ],
                        [
                            210.04199910884108,
                            273.7882919559541
                        ],
                        [
                            210.24434630891824,
                            276.51239235588304
                        ],
                        [
                            210.60407466461083,
                            277.59252076759645
                        ],
                        [
                            209.9520670199181,
                            278.6937492397244
                        ],
                        [
                            211.5033955538422,
                            280.700572186207
                        ],
                        [
                            210.9413199980727,
                            282.166870351132
                        ],
                        [
                            211.2560823093038,
                            284.30004424326154
                        ],
                        [
                            210.73897279799576,
                            285.225992786545
                        ],
                        [
                            210.91883697584194,
                            286.62501767972105
                        ],
                        [
                            215.61778862207666,
                            287.6791405394448
                        ],
                        [
                            215.30302631084555,
                            290.1513354182346
                        ],
                        [
                            213.79666382138294,
                            291.01065172405964
                        ],
                        [
                            212.8748599099206,
                            289.3991896632078
                        ],
                        [
                            211.6158106649964,
                            288.9047981208613
                        ],
                        [
                            210.1319311977643,
                            290.06538744305016
                        ],
                        [
                            209.007780086225,
                            287.89421221684734
                        ],
                        [
                            207.77121386353156,
                            287.7436640129897
                        ],
                        [
                            206.0849871962223,
                            286.60350026242526
                        ],
                        [
                            205.32056444037562,
                            286.7326019392257
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Gujarat",
        "properties": {
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            68.84861949948981,
                            264.31586478988004
                        ],
                        [
                            68.42144207710498,
                            263.4903446825006
                        ],
                        [
                            67.00501167656512,
                            262.6427915730003
                        ],
                        [
                            68.33150998818155,
                            261.62096441821745
                        ],
                        [
                            66.01575869841054,
                            260.22880439423415
                        ],
                        [
                            67.18487585441153,
                            259.0317359747364
                        ],
                        [
                            65.52113220933325,
                            256.8972088565251
                        ],
                        [
                            66.3754870541029,
                            253.12416733581836
                        ],
                        [
                            67.5670872323351,
                            252.57840366039642
                        ],
                        [
                            69.09593274402846,
                            253.25513074919206
                        ],
                        [
                            70.78215941133772,
                            252.6002367731303
                        ],
                        [
                            71.95127656733871,
                            251.35540738433673
                        ],
                        [
                            67.72446838795031,
                            251.57384891733625
                        ],
                        [
                            65.61106429825645,
                            250.8966101966913
                        ],
                        [
                            64.80167549794805,
                            251.92331071393085
                        ],
                        [
                            63.5651092752546,
                            251.00585622640926
                        ],
                        [
                            63.00303371948485,
                            253.1896500031919
                        ],
                        [
                            61.6315693634067,
                            256.0907907085739
                        ],
                        [
                            61.0470107854062,
                            257.7251219206184
                        ],
                        [
                            61.58660331894521,
                            258.79224650272477
                        ],
                        [
                            60.88962962979099,
                            259.96767961913724
                        ],
                        [
                            62.57585629709979,
                            261.94712908067964
                        ],
                        [
                            61.496671230022,
                            264.61992909992597
                        ],
                        [
                            59.63058038486656,
                            267.05100230715357
                        ],
                        [
                            59.69802945155902,
                            268.67752839364096
                        ],
                        [
                            55.42625522770936,
                            270.5412153488389
                        ],
                        [
                            53.290368115784304,
                            271.86223394699664
                        ],
                        [
                            50.83971869262837,
                            272.68478832037374
                        ],
                        [
                            49.76053362555058,
                            273.8748194601293
                        ],
                        [
                            48.05182393601058,
                            274.22089782779216
                        ],
                        [
                            46.36559726870155,
                            275.0210104029956
                        ],
                        [
                            44.072329001160824,
                            276.0369808083752
                        ],
                        [
                            43.330389267545115,
                            275.8640803389601
                        ],
                        [
                            42.16127211154412,
                            275.8640803389601
                        ],
                        [
                            40.78980775546597,
                            276.6852457635207
                        ],
                        [
                            35.214018242230395,
                            274.1992694125984
                        ],
                        [
                            32.15632721884322,
                            271.9271829054315
                        ],
                        [
                            29.975474062456897,
                            269.9995991119276
                        ],
                        [
                            28.356696461839874,
                            268.2222157752747
                        ],
                        [
                            24.33223548252886,
                            263.3599722799399
                        ],
                        [
                            19.38597059175538,
                            258.7051531469332
                        ],
                        [
                            19.745698947447863,
                            257.7686878836714
                        ],
                        [
                            19.09369130275502,
                            258.55273171663043
                        ],
                        [
                            17.115185346445855,
                            256.67928647868337
                        ],
                        [
                            13.427969700596464,
                            252.29455371884785
                        ],
                        [
                            13.00079227821152,
                            251.0714012621392
                        ],
                        [
                            14.012528278597074,
                            249.01673444160116
                        ],
                        [
                            16.328279568368202,
                            250.48142618933292
                        ],
                        [
                            16.55310979067599,
                            252.62206967192594
                        ],
                        [
                            18.936310147139693,
                            251.0495531320318
                        ],
                        [
                            21.13964632575687,
                            250.65624998653277
                        ],
                        [
                            21.881586059372808,
                            250.02244805911346
                        ],
                        [
                            23.05070321537403,
                            250.10987980142187
                        ],
                        [
                            25.00672614945256,
                            249.36659979741415
                        ],
                        [
                            26.62550375006913,
                            248.64494171589382
                        ],
                        [
                            28.26676437291667,
                            247.37599997277755
                        ],
                        [
                            30.47010055153396,
                            247.22280230965094
                        ],
                        [
                            31.90901397430457,
                            245.49311503921888
                        ],
                        [
                            31.84156490761211,
                            244.5948899833091
                        ],
                        [
                            32.920749974690125,
                            243.82781977773777
                        ],
                        [
                            34.4945615308452,
                            241.6127558397618
                        ],
                        [
                            35.23650126446114,
                            242.57801210056658
                        ],
                        [
                            35.93347495361559,
                            241.15191362081123
                        ],
                        [
                            36.87776188730868,
                            239.61506235483483
                        ],
                        [
                            38.76633575469509,
                            238.2089807853951
                        ],
                        [
                            39.19351317707992,
                            237.0658607736736
                        ],
                        [
                            36.04589006476954,
                            237.26375213346256
                        ],
                        [
                            34.87677290876877,
                            236.62603663421203
                        ],
                        [
                            33.21302926369026,
                            236.64802999044565
                        ],
                        [
                            31.054659129534457,
                            239.02198384715552
                        ],
                        [
                            29.81809290684123,
                            241.37137454117885
                        ],
                        [
                            27.547307661531477,
                            241.74440701997742
                        ],
                        [
                            25.231556371760462,
                            242.86311924716665
                        ],
                        [
                            23.455397615528,
                            243.3455230176241
                        ],
                        [
                            20.060461258678856,
                            243.1043345200577
                        ],
                        [
                            15.85613610152177,
                            242.09543771126374
                        ],
                        [
                            8.526670854284589,
                            237.37368387198012
                        ],
                        [
                            7.042791387052603,
                            235.6140972721712
                        ],
                        [
                            7.312587653821879,
                            234.4035387009302
                        ],
                        [
                            5.109251475204701,
                            233.36851470106518
                        ],
                        [
                            5.334081697512602,
                            232.3109469365628
                        ],
                        [
                            4.030066408126913,
                            232.1566741195603
                        ],
                        [
                            3.782753163588154,
                            230.8338729840525
                        ],
                        [
                            1.309620718201586,
                            230.4589283415172
                        ],
                        [
                            0.702579117970231,
                            229.22334345541645
                        ],
                        [
                            1.759281162817274,
                            228.9364072100185
                        ],
                        [
                            2.298873696356168,
                            226.06487771093498
                        ],
                        [
                            4.2099305859730975,
                            224.6055083557327
                        ],
                        [
                            10.302829610516824,
                            224.51702875076313
                        ],
                        [
                            10.460210766132263,
                            219.29000977355628
                        ],
                        [
                            11.696776988825832,
                            220.1990471966829
                        ],
                        [
                            13.135690411596215,
                            219.17912395208293
                        ],
                        [
                            13.967562234135357,
                            220.465030723025
                        ],
                        [
                            15.316543567982649,
                            219.64480405838214
                        ],
                        [
                            16.77794001298389,
                            220.24338017828967
                        ],
                        [
                            18.531615746985608,
                            219.53393744564943
                        ],
                        [
                            21.162129347987616,
                            219.75566467309886
                        ],
                        [
                            22.668491837450574,
                            219.33436242056325
                        ],
                        [
                            24.691963838221454,
                            221.30708474330936
                        ],
                        [
                            29.00870410653306,
                            221.30708474330936
                        ],
                        [
                            30.245270329226287,
                            219.31218621715476
                        ],
                        [
                            37.05762606515509,
                            217.24874701775877
                        ],
                        [
                            37.035143042924346,
                            219.9995369235179
                        ],
                        [
                            38.451573443463985,
                            220.53152122012204
                        ],
                        [
                            40.475045444234865,
                            220.50935796031672
                        ],
                        [
                            41.48678144462042,
                            219.31218621715476
                        ],
                        [
                            43.12804206746796,
                            218.15869223672456
                        ],
                        [
                            44.04984597893031,
                            218.3140081940855
                        ],
                        [
                            44.83675175700796,
                            217.0267475314228
                        ],
                        [
                            43.30790624531437,
                            216.89353622258778
                        ],
                        [
                            43.12804206746796,
                            214.4497799508872
                        ],
                        [
                            44.971649890392655,
                            213.16006596611408
                        ],
                        [
                            46.09580100193193,
                            213.56041013680016
                        ],
                        [
                            47.55719744693329,
                            214.11631211050508
                        ],
                        [
                            48.88369575854972,
                            213.96067496847235
                        ],
                        [
                            50.36757522578205,
                            213.09333421669788
                        ],
                        [
                            52.27863211539875,
                            213.13782229486714
                        ],
                        [
                            52.9756058045532,
                            213.7383154061759
                        ],
                        [
                            54.97659478309333,
                            213.13782229486714
                        ],
                        [
                            56.84268562824877,
                            213.20455257302189
                        ],
                        [
                            58.888640651250626,
                            212.55940071717254
                        ],
                        [
                            60.462452207405704,
                            214.02737806768494
                        ],
                        [
                            62.95806767502336,
                            213.84949824528678
                        ],
                        [
                            63.22786394179275,
                            214.96099063533734
                        ],
                        [
                            65.38623407594855,
                            217.33754003906057
                        ],
                        [
                            65.67851336494891,
                            215.98302542702993
                        ],
                        [
                            68.08419674364313,
                            216.5826759270039
                        ],
                        [
                            68.73620438833586,
                            218.13650327845693
                        ],
                        [
                            72.1311407451849,
                            218.24744565871737
                        ],
                        [
                            72.7606653676471,
                            216.3161864314664
                        ],
                        [
                            74.0197146125713,
                            216.02745005059455
                        ],
                        [
                            73.86233345695564,
                            217.67047942288724
                        ],
                        [
                            74.9640015462644,
                            218.33619522385152
                        ],
                        [
                            73.72743532357094,
                            220.93041901026214
                        ],
                        [
                            74.58179016834083,
                            222.0380626548403
                        ],
                        [
                            75.88580545772675,
                            222.81305906840663
                        ],
                        [
                            76.3129828801118,
                            224.03032339958867
                        ],
                        [
                            77.39216794718959,
                            223.18938093673984
                        ],
                        [
                            78.69618323657505,
                            223.38858241975362
                        ],
                        [
                            78.85356439219072,
                            225.2689851524994
                        ],
                        [
                            78.29148883642097,
                            225.60064407781806
                        ],
                        [
                            78.15659070303627,
                            227.03722220733812
                        ],
                        [
                            79.7079192369606,
                            228.80396184925877
                        ],
                        [
                            80.42737594834603,
                            230.37069636377163
                        ],
                        [
                            81.14683265973122,
                            229.62057501272216
                        ],
                        [
                            82.38339888242444,
                            230.15010022519328
                        ],
                        [
                            81.95622146003961,
                            232.9058938199626
                        ],
                        [
                            83.84479532742557,
                            232.81776409694464
                        ],
                        [
                            84.94646341673456,
                            233.2804042266112
                        ],
                        [
                            85.84578430596594,
                            234.73375935062114
                        ],
                        [
                            86.83503728412052,
                            234.00720616665353
                        ],
                        [
                            87.1722826175826,
                            234.68973289401652
                        ],
                        [
                            89.48803390735361,
                            235.83412491994062
                        ],
                        [
                            89.30816972950743,
                            237.28573893281117
                        ],
                        [
                            91.08432848573966,
                            237.1538147485146
                        ],
                        [
                            92.1410305305867,
                            239.13182553649392
                        ],
                        [
                            93.22021559766449,
                            241.74440701997742
                        ],
                        [
                            94.20946857581907,
                            241.56887033362534
                        ],
                        [
                            94.27691764251153,
                            243.01662297453777
                        ],
                        [
                            93.35511373104919,
                            244.37575461480566
                        ],
                        [
                            93.01786839758756,
                            245.90924053126577
                        ],
                        [
                            91.30915870804756,
                            245.77784103166402
                        ],
                        [
                            89.64541506296905,
                            247.72612596358795
                        ],
                        [
                            88.29643372912187,
                            247.2665741607115
                        ],
                        [
                            89.19575461835325,
                            249.34473483659679
                        ],
                        [
                            90.61218501889289,
                            248.99486601565394
                        ],
                        [
                            90.22997364096977,
                            250.91845983306354
                        ],
                        [
                            88.45381488473731,
                            250.35029928806267
                        ],
                        [
                            88.22898466242941,
                            251.26802475502126
                        ],
                        [
                            89.24072066281497,
                            252.64390235680003
                        ],
                        [
                            89.26320368504571,
                            254.45527112511826
                        ],
                        [
                            88.83602626266088,
                            255.74197982002136
                        ],
                        [
                            89.60044901850756,
                            256.78825030177217
                        ],
                        [
                            86.54275799512038,
                            258.00828567001014
                        ],
                        [
                            84.74411621665718,
                            258.530956389162
                        ],
                        [
                            84.85653132781113,
                            259.79357977235304
                        ],
                        [
                            85.68840315035027,
                            260.533412090034
                        ],
                        [
                            84.69915017219569,
                            261.64271017786166
                        ],
                        [
                            85.0139124834268,
                            263.59898269583147
                        ],
                        [
                            87.1722826175826,
                            263.57725550436294
                        ],
                        [
                            87.1722826175826,
                            263.55552810731183
                        ],
                        [
                            89.84776226304643,
                            262.8384085655481
                        ],
                        [
                            91.48902288589375,
                            262.94707745684065
                        ],
                        [
                            92.16351355281745,
                            263.94658981350665
                        ],
                        [
                            90.52225292996968,
                            264.51133931372397
                        ],
                        [
                            88.11656955127569,
                            264.7719461996339
                        ],
                        [
                            86.83503728412052,
                            265.5752986776284
                        ],
                        [
                            86.58772403958187,
                            267.072696816181
                        ],
                        [
                            84.83404830558038,
                            267.5499246969801
                        ],
                        [
                            84.72163319442643,
                            269.06772553304796
                        ],
                        [
                            82.54078003804011,
                            269.37116706610857
                        ],
                        [
                            83.57499906065641,
                            270.5628773920299
                        ],
                        [
                            84.5192859943495,
                            271.34257764751965
                        ],
                        [
                            84.60921808327248,
                            271.6673763040509
                        ],
                        [
                            85.21625968350395,
                            271.8189336445579
                        ],
                        [
                            85.21625968350395,
                            271.84058389546976
                        ],
                        [
                            85.80081826150422,
                            272.8579210614112
                        ],
                        [
                            86.58772403958187,
                            274.3290369398761
                        ],
                        [
                            86.2279956838895,
                            276.14503722256995
                        ],
                        [
                            85.59847106142706,
                            276.0585924836837
                        ],
                        [
                            85.05887852788828,
                            277.7652959947034
                        ],
                        [
                            83.68741417181013,
                            278.65057334408
                        ],
                        [
                            82.33843283796296,
                            278.65057334408
                        ],
                        [
                            81.7763572821932,
                            277.70050674738934
                        ],
                        [
                            79.7079192369606,
                            276.209868716718
                        ],
                        [
                            78.7186662588058,
                            277.72210335817573
                        ],
                        [
                            79.68543621472986,
                            278.3483203019426
                        ],
                        [
                            79.6179871480374,
                            279.7297378646218
                        ],
                        [
                            78.67370021434431,
                            281.4553955188571
                        ],
                        [
                            78.85356439219072,
                            284.30004424326154
                        ],
                        [
                            77.14485470265072,
                            284.2138916369992
                        ],
                        [
                            76.40291496903478,
                            285.59196874830667
                        ],
                        [
                            75.84083941326503,
                            285.61349504214576
                        ],
                        [
                            74.71668830172575,
                            284.3861938065795
                        ],
                        [
                            75.1888317685723,
                            282.98588576889335
                        ],
                        [
                            73.36770696787835,
                            282.4039821976396
                        ],
                        [
                            71.74892936726178,
                            282.8781363706169
                        ],
                        [
                            72.17610678964661,
                            284.23543007389594
                        ],
                        [
                            70.87209150026092,
                            283.95541555045895
                        ],
                        [
                            69.43317807749031,
                            285.44127939184284
                        ],
                        [
                            68.91606856618228,
                            283.6969120410993
                        ],
                        [
                            69.47814412195203,
                            282.23153951844193
                        ],
                        [
                            70.22008385556796,
                            281.73569844657766
                        ],
                        [
                            71.07443870033785,
                            280.2691385576315
                        ],
                        [
                            71.32175194487672,
                            278.477861986839
                        ],
                        [
                            70.6022952334913,
                            275.7343967387643
                        ],
                        [
                            68.64627229941266,
                            271.0393916853882
                        ],
                        [
                            69.07344972179794,
                            270.82280628859183
                        ],
                        [
                            67.16239283218079,
                            270.2379256996293
                        ],
                        [
                            67.40970607671943,
                            268.48240531749315
                        ],
                        [
                            66.82514749871893,
                            266.8123493084137
                        ],
                        [
                            67.6570193212583,
                            266.3566707625562
                        ],
                        [
                            68.2190948770276,
                            264.53305768065474
                        ],
                        [
                            68.84861949948981,
                            264.31586478988004
                        ]
                    ]
                ],
                [
                    [
                        [
                            63.27282998625424,
                            259.2058942490245
                        ],
                        [
                            62.59833931933076,
                            258.530956389162
                        ],
                        [
                            62.328543052561145,
                            256.5267282715989
                        ],
                        [
                            63.542626253023855,
                            255.54574990222073
                        ],
                        [
                            64.4644301644862,
                            257.1586879055543
                        ],
                        [
                            63.27282998625424,
                            259.2058942490245
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Odisha",
        "properties": {
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            259.2123687275755,
                            255.0878133931747
                        ],
                        [
                            260.17913868349933,
                            254.82609341554814
                        ],
                        [
                            260.69624819480737,
                            256.24337846140685
                        ],
                        [
                            262.35999183988565,
                            256.2651758670818
                        ],
                        [
                            264.0012524627334,
                            254.17165970201722
                        ],
                        [
                            264.33849779619527,
                            252.99319623514305
                        ],
                        [
                            263.48414295142516,
                            252.0761829820002
                        ],
                        [
                            264.29353175173355,
                            250.69995378116596
                        ],
                        [
                            264.1361505961179,
                            249.23540678551217
                        ],
                        [
                            263.3267617958097,
                            248.29496186813037
                        ],
                        [
                            264.8556073075033,
                            247.15714289738588
                        ],
                        [
                            265.62003006335,
                            248.27308628169772
                        ],
                        [
                            268.02571344204466,
                            248.88552063486793
                        ],
                        [
                            269.1498645535837,
                            250.45957224480526
                        ],
                        [
                            270.1840835762,
                            250.9840074508607
                        ],
                        [
                            271.1733365543548,
                            250.50327991829283
                        ],
                        [
                            272.0276913991247,
                            251.26802475502126
                        ],
                        [
                            274.56827291120385,
                            252.57840366039642
                        ],
                        [
                            276.18705051182064,
                            254.45527112511826
                        ],
                        [
                            278.0531413569761,
                            254.65159645365782
                        ],
                        [
                            278.9974282906692,
                            255.3931147792477
                        ],
                        [
                            278.59273389051486,
                            257.37656396225395
                        ],
                        [
                            280.0541303355162,
                            258.1607437695603
                        ],
                        [
                            280.16654544667017,
                            257.2022647999703
                        ],
                        [
                            282.14505140297956,
                            256.5703173851811
                        ],
                        [
                            282.23498349190277,
                            258.31319159441324
                        ],
                        [
                            282.72960998097983,
                            259.1188167835528
                        ],
                        [
                            285.06784429298204,
                            259.68476059413837
                        ],
                        [
                            285.69736891544426,
                            262.09932332841703
                        ],
                        [
                            284.5732178039045,
                            263.20786178190934
                        ],
                        [
                            281.8078060695175,
                            263.3599722799399
                        ],
                        [
                            280.75110402467067,
                            263.7727928285829
                        ],
                        [
                            277.8283111346682,
                            266.16135249497614
                        ],
                        [
                            276.50181282305175,
                            268.4390424134878
                        ],
                        [
                            276.41188073412854,
                            270.32458389478785
                        ],
                        [
                            278.23300553482227,
                            274.0046047805903
                        ],
                        [
                            278.0981074014378,
                            275.0858725634809
                        ],
                        [
                            276.59174491197496,
                            275.215591566649
                        ],
                        [
                            275.7149070449741,
                            275.97214460478534
                        ],
                        [
                            276.5242958452825,
                            275.34530348229924
                        ],
                        [
                            278.90749620174597,
                            275.4101567833875
                        ],
                        [
                            279.4021226908235,
                            276.29630796304826
                        ],
                        [
                            275.46759380043545,
                            279.1254654705514
                        ],
                        [
                            274.70317104458877,
                            280.2691385576315
                        ],
                        [
                            275.28772962258904,
                            282.25309552412483
                        ],
                        [
                            273.8712992220494,
                            283.37374404953823
                        ],
                        [
                            271.7129290878938,
                            284.66615888421546
                        ],
                        [
                            269.3072457091994,
                            287.7866787236396
                        ],
                        [
                            267.21632464173626,
                            289.05527556553113
                        ],
                        [
                            262.18012766203947,
                            290.43064579847066
                        ],
                        [
                            259.14491966088303,
                            291.48314925524636
                        ],
                        [
                            254.3560359257251,
                            293.9301154676676
                        ],
                        [
                            251.70303930249202,
                            295.81737230170785
                        ],
                        [
                            250.3315749464141,
                            297.2533163268572
                        ],
                        [
                            248.89266152364348,
                            298.17446447106767
                        ],
                        [
                            246.39704605602606,
                            300.65776450157654
                        ],
                        [
                            245.72255538910235,
                            301.89851303715903
                        ],
                        [
                            244.77826845540926,
                            301.2568212788392
                        ],
                        [
                            243.631634321639,
                            301.7701874765389
                        ],
                        [
                            243.15949085479224,
                            302.7324735982772
                        ],
                        [
                            242.03533974325296,
                            302.3262187821396
                        ],
                        [
                            240.68635840940578,
                            303.88674427768785
                        ],
                        [
                            240.8662225872522,
                            304.2713869696947
                        ],
                        [
                            239.15751289771197,
                            306.38590611835997
                        ],
                        [
                            237.7635655194033,
                            306.21509951831786
                        ],
                        [
                            235.7850595630939,
                            306.85556677976746
                        ],
                        [
                            234.99815378501626,
                            305.93751498421256
                        ],
                        [
                            232.86226667309143,
                            305.9588686878389
                        ],
                        [
                            231.42335325032082,
                            302.81799278820716
                        ],
                        [
                            230.7488625833971,
                            303.2027941476589
                        ],
                        [
                            230.88376071678204,
                            304.48505273924457
                        ],
                        [
                            229.5122963607041,
                            301.5562973356006
                        ],
                        [
                            228.7029075603955,
                            301.5990767850153
                        ],
                        [
                            226.83681671523982,
                            302.5614267274125
                        ],
                        [
                            226.52205440400894,
                            303.7798889661604
                        ],
                        [
                            224.8807937811614,
                            302.81799278820716
                        ],
                        [
                            224.43113333654583,
                            303.5020445442596
                        ],
                        [
                            225.82508071485472,
                            305.0618626336847
                        ],
                        [
                            222.76738969146732,
                            306.94095057738656
                        ],
                        [
                            221.755653691082,
                            306.42860602527935
                        ],
                        [
                            219.75466471254163,
                            309.18128311579073
                        ],
                        [
                            220.4066723572348,
                            310.1621627018085
                        ],
                        [
                            220.04694400154176,
                            311.52626098958984
                        ],
                        [
                            220.90129884631187,
                            312.3571653871958
                        ],
                        [
                            219.30500426792582,
                            313.01744329564167
                        ],
                        [
                            218.20333617861706,
                            312.6979743644775
                        ],
                        [
                            216.92180391146235,
                            311.61149388371393
                        ],
                        [
                            216.42717742238506,
                            313.31557986852647
                        ],
                        [
                            213.68424871022876,
                            314.31622435089565
                        ],
                        [
                            213.97652799922912,
                            312.9322553139194
                        ],
                        [
                            212.20036924299689,
                            310.4179845538481
                        ],
                        [
                            211.346014398227,
                            311.07874347819785
                        ],
                        [
                            210.37924444230293,
                            312.0802260391547
                        ],
                        [
                            210.69400675353404,
                            312.91095789438015
                        ],
                        [
                            209.6822707531485,
                            315.74203115988996
                        ],
                        [
                            210.2218632866875,
                            315.93349999984366
                        ],
                        [
                            209.74971981984095,
                            317.91121861275644
                        ],
                        [
                            208.7379838194554,
                            318.7189044116174
                        ],
                        [
                            207.52390061899268,
                            317.7836673622894
                        ],
                        [
                            205.52291164045255,
                            317.52854690197216
                        ],
                        [
                            204.75848888460587,
                            318.40010968259304
                        ],
                        [
                            202.2628734169882,
                            319.2713937015509
                        ],
                        [
                            200.8914090609103,
                            320.5247055081348
                        ],
                        [
                            199.43001261590916,
                            321.3316201920964
                        ],
                        [
                            196.19245741467557,
                            321.41654480746115
                        ],
                        [
                            196.2374234591373,
                            320.2486013062439
                        ],
                        [
                            197.45150665959954,
                            318.95266352806374
                        ],
                        [
                            197.4290236373688,
                            317.80492631959953
                        ],
                        [
                            198.08103128206176,
                            316.2313134453493
                        ],
                        [
                            198.148480348754,
                            314.89089405277133
                        ],
                        [
                            200.0370542161404,
                            313.23040138013846
                        ],
                        [
                            201.38603554998758,
                            313.1239244569379
                        ],
                        [
                            201.67831483898772,
                            312.1228339647078
                        ],
                        [
                            203.7467528842203,
                            309.7783835813269
                        ],
                        [
                            203.58937172860487,
                            308.3707163557692
                        ],
                        [
                            206.39974950745318,
                            307.17574167819885
                        ],
                        [
                            207.389002485608,
                            306.38590611835997
                        ],
                        [
                            207.6587987523776,
                            304.6132437698453
                        ],
                        [
                            208.67053475276293,
                            304.3568553860261
                        ],
                        [
                            208.08597617476266,
                            301.214036139142
                        ],
                        [
                            207.43396853006948,
                            299.9515537585971
                        ],
                        [
                            207.8386629302238,
                            296.43900022765195
                        ],
                        [
                            206.91685901876144,
                            296.4175673234107
                        ],
                        [
                            206.5346476408381,
                            295.04548347555584
                        ],
                        [
                            205.47794559599083,
                            295.1955914589453
                        ],
                        [
                            205.92760604060686,
                            293.3078682953254
                        ],
                        [
                            206.01753812953007,
                            290.7743693372183
                        ],
                        [
                            204.84842097352907,
                            290.6025135068488
                        ],
                        [
                            204.6011077289902,
                            289.7000753384096
                        ],
                        [
                            203.1397112839893,
                            289.03377934799676
                        ],
                        [
                            202.77998292829625,
                            288.25979103151303
                        ],
                        [
                            203.22964337291228,
                            286.71108546420237
                        ],
                        [
                            203.22964337291228,
                            286.689568800756
                        ],
                        [
                            204.26386239552858,
                            285.85027178905193
                        ],
                        [
                            205.32056444037562,
                            286.7326019392257
                        ],
                        [
                            206.0849871962223,
                            286.60350026242526
                        ],
                        [
                            207.77121386353156,
                            287.7436640129897
                        ],
                        [
                            209.007780086225,
                            287.89421221684734
                        ],
                        [
                            210.1319311977643,
                            290.06538744305016
                        ],
                        [
                            211.6158106649964,
                            288.9047981208613
                        ],
                        [
                            212.8748599099206,
                            289.3991896632078
                        ],
                        [
                            213.79666382138294,
                            291.01065172405964
                        ],
                        [
                            215.30302631084555,
                            290.1513354182346
                        ],
                        [
                            215.61778862207666,
                            287.6791405394448
                        ],
                        [
                            210.91883697584194,
                            286.62501767972105
                        ],
                        [
                            210.73897279799576,
                            285.225992786545
                        ],
                        [
                            211.2560823093038,
                            284.30004424326154
                        ],
                        [
                            210.9413199980727,
                            282.166870351132
                        ],
                        [
                            211.5033955538422,
                            280.700572186207
                        ],
                        [
                            209.9520670199181,
                            278.6937492397244
                        ],
                        [
                            210.60407466461083,
                            277.59252076759645
                        ],
                        [
                            210.24434630891824,
                            276.51239235588304
                        ],
                        [
                            210.04199910884108,
                            273.7882919559541
                        ],
                        [
                            211.81815786507332,
                            274.5453003510488
                        ],
                        [
                            212.24533528745815,
                            273.2474232625957
                        ],
                        [
                            213.30203733230542,
                            272.64150314787855
                        ],
                        [
                            214.44867146607567,
                            270.4112388816923
                        ],
                        [
                            214.4711544883064,
                            269.37116706610857
                        ],
                        [
                            219.26003822346433,
                            269.046049627439
                        ],
                        [
                            220.90129884631187,
                            270.0429330249793
                        ],
                        [
                            222.58752551362113,
                            269.60955774234714
                        ],
                        [
                            223.7116766251604,
                            265.8574912889921
                        ],
                        [
                            223.7566426696219,
                            265.8574912889921
                        ],
                        [
                            223.8016087140836,
                            265.8574912889921
                        ],
                        [
                            225.53280142585436,
                            266.4000725832204
                        ],
                        [
                            225.62273351477756,
                            265.423334526894
                        ],
                        [
                            224.72341262554596,
                            263.9031417994257
                        ],
                        [
                            225.39790329246944,
                            262.0123564274094
                        ],
                        [
                            226.92674880416303,
                            261.64271017786166
                        ],
                        [
                            226.5445374262399,
                            260.79447196891806
                        ],
                        [
                            228.45559431585684,
                            258.7704734776293
                        ],
                        [
                            227.69117156001016,
                            256.54852293384806
                        ],
                        [
                            227.66868853777942,
                            255.28408333778236
                        ],
                        [
                            228.99518684939585,
                            252.7748939746333
                        ],
                        [
                            231.5582513837055,
                            251.33356204939224
                        ],
                        [
                            232.4575722729371,
                            250.54698672957738
                        ],
                        [
                            234.6159424070927,
                            250.10987980142187
                        ],
                        [
                            235.1555349406317,
                            248.55745196428205
                        ],
                        [
                            234.63842542932343,
                            247.61671758132377
                        ],
                        [
                            236.0998218743248,
                            248.4262108249671
                        ],
                        [
                            237.358871119249,
                            250.00058958377485
                        ],
                        [
                            238.75281849755788,
                            250.56883981193528
                        ],
                        [
                            240.776290498329,
                            250.4158637089795
                        ],
                        [
                            242.3276190322531,
                            249.21354052577732
                        ],
                        [
                            245.49772516679445,
                            249.34473483659679
                        ],
                        [
                            246.37456303379508,
                            248.79804042796673
                        ],
                        [
                            248.55541619018186,
                            248.9292594374598
                        ],
                        [
                            250.19667681302917,
                            248.2074582187015
                        ],
                        [
                            250.30909192418312,
                            251.92331071393085
                        ],
                        [
                            249.58963521279793,
                            254.06256881444784
                        ],
                        [
                            251.81545441364574,
                            254.84790458056392
                        ],
                        [
                            252.66980925841608,
                            255.8509890366209
                        ],
                        [
                            253.20940179195486,
                            254.69522196799147
                        ],
                        [
                            255.00804357041784,
                            253.4079116870576
                        ],
                        [
                            259.2123687275755,
                            255.0878133931747
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Andhra Pradesh",
        "properties": {
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            179.59998700835354,
                            386.391946395858
                        ],
                        [
                            178.8130812302761,
                            387.51456233296904
                        ],
                        [
                            176.81209225173575,
                            387.5353482202108
                        ],
                        [
                            176.20505065150473,
                            386.7869808065768
                        ],
                        [
                            174.85606931765733,
                            389.4263648472654
                        ],
                        [
                            172.85508033911697,
                            390.13264634981897
                        ],
                        [
                            172.6302501168093,
                            391.295636495247
                        ],
                        [
                            171.28126878296212,
                            391.56556368987776
                        ],
                        [
                            170.98898949396175,
                            390.4441977459441
                        ],
                        [
                            168.80813633757498,
                            390.75572264400955
                        ],
                        [
                            168.8531023820367,
                            389.8002956033107
                        ],
                        [
                            166.9195624701888,
                            389.86261367117527
                        ],
                        [
                            167.39170593703557,
                            392.0845988743573
                        ],
                        [
                            165.12092069172581,
                            393.06018769518107
                        ],
                        [
                            164.31153189141764,
                            392.54128948551477
                        ],
                        [
                            164.40146398034085,
                            393.3299860751252
                        ],
                        [
                            163.34476193549358,
                            394.61644778743164
                        ],
                        [
                            160.87162949010667,
                            393.620516267623
                        ],
                        [
                            159.36526700064405,
                            393.4752540092643
                        ],
                        [
                            157.29682895541146,
                            394.0977666392871
                        ],
                        [
                            155.38577206579453,
                            395.05208434784544
                        ],
                        [
                            155.1159757990249,
                            397.2709726028056
                        ],
                        [
                            154.9585946434097,
                            398.203761094275
                        ],
                        [
                            152.30559802017638,
                            400.54509213272604
                        ],
                        [
                            150.6868204195598,
                            399.9650725353037
                        ],
                        [
                            149.6526013969435,
                            398.53536410504523
                        ],
                        [
                            149.9673637081744,
                            397.0221902666455
                        ],
                        [
                            150.84420157517548,
                            397.0429227523314
                        ],
                        [
                            151.47372619763723,
                            395.81951134430466
                        ],
                        [
                            153.114986820485,
                            395.30099678162685
                        ],
                        [
                            153.81196050963945,
                            393.9940217586318
                        ],
                        [
                            154.8686625544865,
                            390.75572264400955
                        ],
                        [
                            152.77774148702315,
                            390.2572700909077
                        ],
                        [
                            151.60862433102193,
                            389.4471397840182
                        ],
                        [
                            151.74352246440685,
                            387.2235473425733
                        ],
                        [
                            152.14821686456094,
                            386.0384577247579
                        ],
                        [
                            151.0465487752524,
                            385.9344838387466
                        ],
                        [
                            149.13549188563525,
                            386.3295685717369
                        ],
                        [
                            149.2479069967892,
                            385.41456912690865
                        ],
                        [
                            148.03382379632671,
                            384.1040481801888
                        ],
                        [
                            148.10127286301918,
                            383.02198419484705
                        ],
                        [
                            147.9438917074035,
                            381.8771328332061
                        ],
                        [
                            146.25766504009448,
                            381.73139777365213
                        ],
                        [
                            145.80800459547868,
                            380.4195096506329
                        ],
                        [
                            143.51473632793818,
                            381.0859269238159
                        ],
                        [
                            143.04259286109186,
                            382.5432733933859
                        ],
                        [
                            140.6818755268589,
                            383.3133416305616
                        ],
                        [
                            138.2537091259337,
                            384.6033510946153
                        ],
                        [
                            138.45605632601087,
                            383.45901135338335
                        ],
                        [
                            137.91646379247186,
                            382.1685848352115
                        ],
                        [
                            136.34265223631655,
                            382.31430178688527
                        ],
                        [
                            134.20676512439172,
                            381.7730369787499
                        ],
                        [
                            133.86951979092987,
                            382.7306027416779
                        ],
                        [
                            132.49805543485195,
                            383.68790881622556
                        ],
                        [
                            131.21652316769678,
                            382.4183816336855
                        ],
                        [
                            132.1608101013901,
                            380.81521021936544
                        ],
                        [
                            130.5420325007733,
                            379.3363100909173
                        ],
                        [
                            130.78934574531195,
                            378.35697376036944
                        ],
                        [
                            130.0024399672343,
                            377.2522829787066
                        ],
                        [
                            131.59873454562035,
                            376.7936280296344
                        ],
                        [
                            131.95846290131294,
                            378.8570949621379
                        ],
                        [
                            134.09435001323777,
                            379.60714157403197
                        ],
                        [
                            136.27520316962455,
                            379.3363100909173
                        ],
                        [
                            137.39935428116382,
                            381.2108660373848
                        ],
                        [
                            137.9389468147026,
                            380.58612587672064
                        ],
                        [
                            137.28693917000987,
                            379.5446438732567
                        ],
                        [
                            137.33190521447136,
                            377.1480485892632
                        ],
                        [
                            138.95068281508816,
                            377.4190514364095
                        ],
                        [
                            138.59095445939533,
                            375.3755793407373
                        ],
                        [
                            137.55673543677926,
                            374.68719645370254
                        ],
                        [
                            136.9047277920863,
                            375.6258659424137
                        ],
                        [
                            133.57724050192974,
                            374.68719645370254
                        ],
                        [
                            133.26247819069863,
                            376.3766158297702
                        ],
                        [
                            131.6212175678511,
                            376.23064957898316
                        ],
                        [
                            129.84505881161886,
                            374.52029479042596
                        ],
                        [
                            130.0923720561575,
                            372.37070143247956
                        ],
                        [
                            128.6984246778486,
                            371.7443486907565
                        ],
                        [
                            128.09138307761737,
                            370.6583935357795
                        ],
                        [
                            128.2487642332328,
                            369.4676139383835
                        ],
                        [
                            129.14808512246418,
                            367.7956239753053
                        ],
                        [
                            129.5977455670802,
                            365.0768293271419
                        ],
                        [
                            128.02393401092513,
                            364.7839012913978
                        ],
                        [
                            128.6084925889254,
                            363.1513903062983
                        ],
                        [
                            131.32893827885096,
                            364.386313951856
                        ],
                        [
                            133.21751214623714,
                            364.0933237531512
                        ],
                        [
                            133.84703676869913,
                            362.48140410815665
                        ],
                        [
                            133.39737632408355,
                            361.1619615566112
                        ],
                        [
                            133.7795877020069,
                            360.15630771551014
                        ],
                        [
                            132.4755724126212,
                            359.61144639814796
                        ],
                        [
                            132.02591196800518,
                            357.9763024069549
                        ],
                        [
                            132.00342894577443,
                            354.93436063488946
                        ],
                        [
                            132.76785170162134,
                            354.55653438234856
                        ],
                        [
                            131.9359798790822,
                            351.8474359357862
                        ],
                        [
                            132.6104705460059,
                            350.7337075076276
                        ],
                        [
                            135.19601810254653,
                            349.8928904961221
                        ],
                        [
                            139.1080639707036,
                            350.4604672499446
                        ],
                        [
                            141.24395108262866,
                            351.11200007350715
                        ],
                        [
                            143.35735517232274,
                            351.28011517464984
                        ],
                        [
                            144.59392139501597,
                            350.79675950067804
                        ],
                        [
                            147.47174824055696,
                            351.78440544875787
                        ],
                        [
                            148.82072957440437,
                            351.5322706229566
                        ],
                        [
                            149.89991464148216,
                            349.78777215339574
                        ],
                        [
                            150.0123297526361,
                            349.22007068610407
                        ],
                        [
                            152.28311499794563,
                            348.2105628873989
                        ],
                        [
                            154.35155304317823,
                            348.6943270914546
                        ],
                        [
                            155.09349277679416,
                            348.0633150405487
                        ],
                        [
                            157.13944779979602,
                            349.22007068610407
                        ],
                        [
                            159.81492744525985,
                            347.4742522919842
                        ],
                        [
                            159.54513117849024,
                            346.5483509830394
                        ],
                        [
                            161.38873900141493,
                            345.66427143710615
                        ],
                        [
                            163.27731286880135,
                            346.1484423211059
                        ],
                        [
                            164.10918469134026,
                            345.89584112635896
                        ],
                        [
                            164.04173562464803,
                            343.8953325795894
                        ],
                        [
                            164.17663375803272,
                            341.5140359870794
                        ],
                        [
                            164.73870931380247,
                            340.691729147672
                        ],
                        [
                            167.0994266480352,
                            340.4597550962666
                        ],
                        [
                            167.52660407042026,
                            339.8691933851028
                        ],
                        [
                            168.92055144872916,
                            339.6582498020447
                        ],
                        [
                            170.94402344950004,
                            338.7299182075236
                        ],
                        [
                            172.60776709457832,
                            338.24453705008614
                        ],
                        [
                            173.84433331727178,
                            338.66661218067463
                        ],
                        [
                            174.90103536211882,
                            339.616059271383
                        ],
                        [
                            176.4748469182739,
                            337.9490482492745
                        ],
                        [
                            176.72216016281277,
                            336.8301412106184
                        ],
                        [
                            176.22753367373548,
                            335.28830040873225
                        ],
                        [
                            176.4748469182739,
                            334.46424086335946
                        ],
                        [
                            178.4083868301218,
                            333.7033616280257
                        ],
                        [
                            180.1620625641233,
                            334.4219750622625
                        ],
                        [
                            180.3868927864312,
                            335.8797882304449
                        ],
                        [
                            184.0291423878191,
                            337.59020026646783
                        ],
                        [
                            183.9392102988959,
                            336.5978613900968
                        ],
                        [
                            184.5012858546654,
                            335.2671736099114
                        ],
                        [
                            183.51203287651083,
                            334.612166293502
                        ],
                        [
                            180.99393438666243,
                            334.37970864207193
                        ],
                        [
                            183.08485545412577,
                            332.96342501683444
                        ],
                        [
                            184.1865235434343,
                            331.67336474516026
                        ],
                        [
                            185.9401992774358,
                            332.73083412978826
                        ],
                        [
                            188.07608638936085,
                            333.4074098421323
                        ],
                        [
                            188.54822985620763,
                            332.94228116988154
                        ],
                        [
                            188.59319590066912,
                            331.5464421368405
                        ],
                        [
                            189.49251678990072,
                            330.6578257081953
                        ],
                        [
                            190.27942256797814,
                            331.081010939613
                        ],
                        [
                            192.90993616898027,
                            330.46737182676316
                        ],
                        [
                            193.1572494135189,
                            329.0279739105419
                        ],
                        [
                            194.3938156362126,
                            329.13383689590773
                        ],
                        [
                            194.91092514752063,
                            327.71494317432547
                        ],
                        [
                            190.66163394590149,
                            326.019804223012
                        ],
                        [
                            189.04285634528492,
                            324.19638368209445
                        ],
                        [
                            188.6831279895921,
                            322.69010020238863
                        ],
                        [
                            189.96466025674727,
                            322.0109438162972
                        ],
                        [
                            191.22370950167124,
                            322.7325419347086
                        ],
                        [
                            191.60592087959458,
                            321.7349887910929
                        ],
                        [
                            192.79752105782632,
                            320.80078193469564
                        ],
                        [
                            194.25891750282767,
                            321.52269689282633
                        ],
                        [
                            196.19245741467557,
                            321.41654480746115
                        ],
                        [
                            199.43001261590916,
                            321.3316201920964
                        ],
                        [
                            200.8914090609103,
                            320.5247055081348
                        ],
                        [
                            202.2628734169882,
                            319.2713937015509
                        ],
                        [
                            204.75848888460587,
                            318.40010968259304
                        ],
                        [
                            205.52291164045255,
                            317.52854690197216
                        ],
                        [
                            207.52390061899268,
                            317.7836673622894
                        ],
                        [
                            208.7379838194554,
                            318.7189044116174
                        ],
                        [
                            209.74971981984095,
                            317.91121861275644
                        ],
                        [
                            210.2218632866875,
                            315.93349999984366
                        ],
                        [
                            209.6822707531485,
                            315.74203115988996
                        ],
                        [
                            210.69400675353404,
                            312.91095789438015
                        ],
                        [
                            210.37924444230293,
                            312.0802260391547
                        ],
                        [
                            211.346014398227,
                            311.07874347819785
                        ],
                        [
                            212.20036924299689,
                            310.4179845538481
                        ],
                        [
                            213.97652799922912,
                            312.9322553139194
                        ],
                        [
                            213.68424871022876,
                            314.31622435089565
                        ],
                        [
                            216.42717742238506,
                            313.31557986852647
                        ],
                        [
                            216.92180391146235,
                            311.61149388371393
                        ],
                        [
                            218.20333617861706,
                            312.6979743644775
                        ],
                        [
                            219.30500426792582,
                            313.01744329564167
                        ],
                        [
                            220.90129884631187,
                            312.3571653871958
                        ],
                        [
                            220.04694400154176,
                            311.52626098958984
                        ],
                        [
                            220.4066723572348,
                            310.1621627018085
                        ],
                        [
                            219.75466471254163,
                            309.18128311579073
                        ],
                        [
                            221.755653691082,
                            306.42860602527935
                        ],
                        [
                            222.76738969146732,
                            306.94095057738656
                        ],
                        [
                            225.82508071485472,
                            305.0618626336847
                        ],
                        [
                            224.43113333654583,
                            303.5020445442596
                        ],
                        [
                            224.8807937811614,
                            302.81799278820716
                        ],
                        [
                            226.52205440400894,
                            303.7798889661604
                        ],
                        [
                            226.83681671523982,
                            302.5614267274125
                        ],
                        [
                            228.7029075603955,
                            301.5990767850153
                        ],
                        [
                            229.5122963607041,
                            301.5562973356006
                        ],
                        [
                            230.88376071678204,
                            304.48505273924457
                        ],
                        [
                            230.7488625833971,
                            303.2027941476589
                        ],
                        [
                            231.42335325032082,
                            302.81799278820716
                        ],
                        [
                            232.86226667309143,
                            305.9588686878389
                        ],
                        [
                            234.99815378501626,
                            305.93751498421256
                        ],
                        [
                            235.7850595630939,
                            306.85556677976746
                        ],
                        [
                            237.7635655194033,
                            306.21509951831786
                        ],
                        [
                            239.15751289771197,
                            306.38590611835997
                        ],
                        [
                            240.8662225872522,
                            304.2713869696947
                        ],
                        [
                            240.68635840940578,
                            303.88674427768785
                        ],
                        [
                            242.03533974325296,
                            302.3262187821396
                        ],
                        [
                            243.15949085479224,
                            302.7324735982772
                        ],
                        [
                            243.631634321639,
                            301.7701874765389
                        ],
                        [
                            244.77826845540926,
                            301.2568212788392
                        ],
                        [
                            245.7000723668716,
                            301.94128680410773
                        ],
                        [
                            243.33935503263865,
                            304.80551846801353
                        ],
                        [
                            242.57493227679197,
                            306.3645559034897
                        ],
                        [
                            241.72057743202208,
                            307.1330539512041
                        ],
                        [
                            239.67462240902023,
                            310.0129218778187
                        ],
                        [
                            236.6394144078638,
                            312.82576651933846
                        ],
                        [
                            236.34713511886343,
                            313.76272249013573
                        ],
                        [
                            233.51427431778416,
                            315.3803307277066
                        ],
                        [
                            229.5122963607041,
                            317.37971556690445
                        ],
                        [
                            227.3988922710098,
                            319.37762882907356
                        ],
                        [
                            225.93749582600844,
                            320.82201743336566
                        ],
                        [
                            224.85831075893066,
                            322.838643416455
                        ],
                        [
                            222.13786506900533,
                            325.4050630075431
                        ],
                        [
                            220.06942702377273,
                            326.2105585099651
                        ],
                        [
                            219.64224960138768,
                            326.7403633875493
                        ],
                        [
                            215.39295839976876,
                            328.7315364691631
                        ],
                        [
                            213.03224106553603,
                            330.19224925321834
                        ],
                        [
                            209.74971981984095,
                            333.1325701859349
                        ],
                        [
                            208.71550079722465,
                            335.01363999261446
                        ],
                        [
                            208.71550079722465,
                            335.9220327434618
                        ],
                        [
                            210.37924444230293,
                            336.2388469959311
                        ],
                        [
                            209.7272367976102,
                            339.21521904200824
                        ],
                        [
                            209.6822707531485,
                            340.79716587683697
                        ],
                        [
                            208.49067057491675,
                            341.05019868567683
                        ],
                        [
                            204.42124355114402,
                            343.2843848663954
                        ],
                        [
                            202.8474319949887,
                            343.6425417799953
                        ],
                        [
                            200.86892603867955,
                            344.5903972085086
                        ],
                        [
                            198.48572568221584,
                            344.04278401304305
                        ],
                        [
                            196.1250083479831,
                            344.1059752606034
                        ],
                        [
                            194.25891750282767,
                            345.09579758253983
                        ],
                        [
                            193.494494746981,
                            346.1694914659786
                        ],
                        [
                            192.52772479105693,
                            349.74572380574756
                        ],
                        [
                            190.57170185697828,
                            351.637329305188
                        ],
                        [
                            190.4817697680553,
                            353.10777590818884
                        ],
                        [
                            189.447550745439,
                            353.6957592372588
                        ],
                        [
                            187.8737391892837,
                            353.8427376910373
                        ],
                        [
                            187.04186736674455,
                            351.38518245254227
                        ],
                        [
                            185.6479199884359,
                            351.11200007350715
                        ],
                        [
                            183.53451589874157,
                            351.61631785508115
                        ],
                        [
                            181.80332318697083,
                            352.4356584696585
                        ],
                        [
                            180.29696069750798,
                            353.5907703746818
                        ],
                        [
                            179.3526737638149,
                            354.9553496425532
                        ],
                        [
                            178.6781830968912,
                            357.3891218277098
                        ],
                        [
                            177.4640998964287,
                            359.00360653197083
                        ],
                        [
                            176.25001669596622,
                            364.47002058696734
                        ],
                        [
                            177.26175269635155,
                            368.5899244662831
                        ],
                        [
                            178.5432849635065,
                            370.90902983773424
                        ],
                        [
                            178.13859056335218,
                            374.4785680944054
                        ],
                        [
                            177.57651500758266,
                            376.3349118210916
                        ],
                        [
                            177.80134522989056,
                            379.1696340714409
                        ],
                        [
                            179.3976398082766,
                            383.08441995169403
                        ],
                        [
                            179.0828774970455,
                            384.99858291896464
                        ],
                        [
                            179.59998700835354,
                            386.391946395858
                        ]
                    ],
                    [
                        [
                            207.7936968857623,
                            338.0545833901276
                        ],
                        [
                            207.99604408583946,
                            338.3078521971803
                        ],
                        [
                            208.58060266383995,
                            338.37116597514057
                        ],
                        [
                            208.98529706399427,
                            338.7088163504323
                        ],
                        [
                            209.03026310845576,
                            338.28674730029394
                        ],
                        [
                            207.7936968857623,
                            338.0545833901276
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Karnataka",
        "properties": {
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            135.78057668054703,
                            343.094754982906
                        ],
                        [
                            137.64666752570247,
                            343.7268077548465
                        ],
                        [
                            138.748335615011,
                            343.45293464721277
                        ],
                        [
                            140.27718112670482,
                            344.1059752606034
                        ],
                        [
                            138.90571677062644,
                            345.95899340849985
                        ],
                        [
                            139.1080639707036,
                            350.4604672499446
                        ],
                        [
                            135.19601810254653,
                            349.8928904961221
                        ],
                        [
                            132.6104705460059,
                            350.7337075076276
                        ],
                        [
                            131.9359798790822,
                            351.8474359357862
                        ],
                        [
                            132.76785170162134,
                            354.55653438234856
                        ],
                        [
                            132.00342894577443,
                            354.93436063488946
                        ],
                        [
                            132.02591196800518,
                            357.9763024069549
                        ],
                        [
                            132.4755724126212,
                            359.61144639814796
                        ],
                        [
                            133.7795877020069,
                            360.15630771551014
                        ],
                        [
                            133.39737632408355,
                            361.1619615566112
                        ],
                        [
                            133.84703676869913,
                            362.48140410815665
                        ],
                        [
                            133.21751214623714,
                            364.0933237531512
                        ],
                        [
                            131.32893827885096,
                            364.386313951856
                        ],
                        [
                            128.6084925889254,
                            363.1513903062983
                        ],
                        [
                            128.02393401092513,
                            364.7839012913978
                        ],
                        [
                            129.5977455670802,
                            365.0768293271419
                        ],
                        [
                            129.14808512246418,
                            367.7956239753053
                        ],
                        [
                            128.2487642332328,
                            369.4676139383835
                        ],
                        [
                            128.09138307761737,
                            370.6583935357795
                        ],
                        [
                            128.6984246778486,
                            371.7443486907565
                        ],
                        [
                            130.0923720561575,
                            372.37070143247956
                        ],
                        [
                            129.84505881161886,
                            374.52029479042596
                        ],
                        [
                            131.6212175678511,
                            376.23064957898316
                        ],
                        [
                            133.26247819069863,
                            376.3766158297702
                        ],
                        [
                            133.57724050192974,
                            374.68719645370254
                        ],
                        [
                            136.9047277920863,
                            375.6258659424137
                        ],
                        [
                            137.55673543677926,
                            374.68719645370254
                        ],
                        [
                            138.59095445939533,
                            375.3755793407373
                        ],
                        [
                            138.95068281508816,
                            377.4190514364095
                        ],
                        [
                            137.33190521447136,
                            377.1480485892632
                        ],
                        [
                            137.28693917000987,
                            379.5446438732567
                        ],
                        [
                            137.9389468147026,
                            380.58612587672064
                        ],
                        [
                            137.39935428116382,
                            381.2108660373848
                        ],
                        [
                            136.27520316962455,
                            379.3363100909173
                        ],
                        [
                            134.09435001323777,
                            379.60714157403197
                        ],
                        [
                            131.95846290131294,
                            378.8570949621379
                        ],
                        [
                            131.59873454562035,
                            376.7936280296344
                        ],
                        [
                            130.0024399672343,
                            377.2522829787066
                        ],
                        [
                            130.78934574531195,
                            378.35697376036944
                        ],
                        [
                            130.5420325007733,
                            379.3363100909173
                        ],
                        [
                            132.1608101013901,
                            380.81521021936544
                        ],
                        [
                            131.21652316769678,
                            382.4183816336855
                        ],
                        [
                            132.49805543485195,
                            383.68790881622556
                        ],
                        [
                            133.86951979092987,
                            382.7306027416779
                        ],
                        [
                            134.20676512439172,
                            381.7730369787499
                        ],
                        [
                            136.34265223631655,
                            382.31430178688527
                        ],
                        [
                            137.91646379247186,
                            382.1685848352115
                        ],
                        [
                            138.45605632601087,
                            383.45901135338335
                        ],
                        [
                            138.2537091259337,
                            384.6033510946153
                        ],
                        [
                            140.6818755268589,
                            383.3133416305616
                        ],
                        [
                            143.04259286109186,
                            382.5432733933859
                        ],
                        [
                            143.51473632793818,
                            381.0859269238159
                        ],
                        [
                            145.80800459547868,
                            380.4195096506329
                        ],
                        [
                            146.25766504009448,
                            381.73139777365213
                        ],
                        [
                            147.9438917074035,
                            381.8771328332061
                        ],
                        [
                            148.10127286301918,
                            383.02198419484705
                        ],
                        [
                            148.03382379632671,
                            384.1040481801888
                        ],
                        [
                            149.2479069967892,
                            385.41456912690865
                        ],
                        [
                            149.13549188563525,
                            386.3295685717369
                        ],
                        [
                            151.0465487752524,
                            385.9344838387466
                        ],
                        [
                            152.14821686456094,
                            386.0384577247579
                        ],
                        [
                            151.74352246440685,
                            387.2235473425733
                        ],
                        [
                            151.60862433102193,
                            389.4471397840182
                        ],
                        [
                            152.77774148702315,
                            390.2572700909077
                        ],
                        [
                            154.8686625544865,
                            390.75572264400955
                        ],
                        [
                            153.81196050963945,
                            393.9940217586318
                        ],
                        [
                            153.114986820485,
                            395.30099678162685
                        ],
                        [
                            151.47372619763723,
                            395.81951134430466
                        ],
                        [
                            150.84420157517548,
                            397.0429227523314
                        ],
                        [
                            149.9673637081744,
                            397.0221902666455
                        ],
                        [
                            149.6526013969435,
                            398.53536410504523
                        ],
                        [
                            148.2136879741729,
                            398.203761094275
                        ],
                        [
                            147.08953686263362,
                            397.1465834776219
                        ],
                        [
                            145.94290272886337,
                            397.3953576450434
                        ],
                        [
                            145.20096299524744,
                            396.56604682416724
                        ],
                        [
                            144.2791590837851,
                            396.87705968605474
                        ],
                        [
                            143.76204957247683,
                            396.9392591880378
                        ],
                        [
                            143.19997401670707,
                            398.70115479630374
                        ],
                        [
                            142.41306823862965,
                            399.8614883998184
                        ],
                        [
                            140.38959623785877,
                            399.92363921631704
                        ],
                        [
                            140.1872490377816,
                            402.2432198855558
                        ],
                        [
                            140.88422272693606,
                            402.6365824500783
                        ],
                        [
                            140.5919434379357,
                            404.4579556927715
                        ],
                        [
                            139.6476565042426,
                            405.5958852977216
                        ],
                        [
                            138.4785393482416,
                            406.3198518522173
                        ],
                        [
                            138.9731658373189,
                            407.0023280516838
                        ],
                        [
                            140.52449437124324,
                            406.8782501717279
                        ],
                        [
                            142.5254833497836,
                            407.64333564675314
                        ],
                        [
                            142.95266077216843,
                            408.28424055267146
                        ],
                        [
                            141.96340779401385,
                            409.44174519680416
                        ],
                        [
                            141.4912643271673,
                            410.7022212632785
                        ],
                        [
                            138.81578468170324,
                            410.82618137602833
                        ],
                        [
                            137.8490147257794,
                            413.46976904236044
                        ],
                        [
                            135.8480257472395,
                            412.850330889533
                        ],
                        [
                            133.17254610177565,
                            413.3871826627191
                        ],
                        [
                            131.73363267900527,
                            412.767732099406
                        ],
                        [
                            130.20478716731145,
                            413.0568206154486
                        ],
                        [
                            129.12560210023344,
                            415.16242712180235
                        ],
                        [
                            129.30546627807985,
                            416.0703990837003
                        ],
                        [
                            127.97896796646341,
                            415.7196151065575
                        ],
                        [
                            125.07865809869168,
                            415.65770899972654
                        ],
                        [
                            124.35920138730648,
                            414.4193939349537
                        ],
                        [
                            122.98773703122833,
                            414.91476413112946
                        ],
                        [
                            122.9202879645361,
                            413.6142912339898
                        ],
                        [
                            121.52634058622698,
                            413.71751827729065
                        ],
                        [
                            119.88507996337967,
                            412.0655753480642
                        ],
                        [
                            118.51361560730152,
                            412.10688201197706
                        ],
                        [
                            118.51361560730152,
                            410.28899358864646
                        ],
                        [
                            116.89483800668472,
                            411.01211446817126
                        ],
                        [
                            115.90558502852991,
                            410.8675005746163
                        ],
                        [
                            114.9388150726063,
                            410.7022212632785
                        ],
                        [
                            113.81466396106657,
                            409.1317319152436
                        ],
                        [
                            111.49891267129556,
                            407.7467143413026
                        ],
                        [
                            110.6220748042947,
                            407.62265958732576
                        ],
                        [
                            109.27309347044752,
                            405.6165718922423
                        ],
                        [
                            108.23887444783122,
                            405.6165718922423
                        ],
                        [
                            108.35128955898517,
                            404.37518435632614
                        ],
                        [
                            107.51941773644603,
                            403.7750399987695
                        ],
                        [
                            108.26135747006197,
                            402.4709609529235
                        ],
                        [
                            106.26036849152183,
                            402.0982868063205
                        ],
                        [
                            103.78723604613515,
                            399.73718374865393
                        ],
                        [
                            101.87617915651799,
                            398.8669382871243
                        ],
                        [
                            100.21243551143971,
                            398.5560883354567
                        ],
                        [
                            99.537944844516,
                            397.229510014879
                        ],
                        [
                            98.86345417759253,
                            393.84877407428723
                        ],
                        [
                            97.98661631059167,
                            390.1741880685811
                        ],
                        [
                            97.289642621437,
                            385.3313757606395
                        ],
                        [
                            96.00811035428228,
                            380.9193345064175
                        ],
                        [
                            95.01885737612747,
                            380.00293428897646
                        ],
                        [
                            94.344366709204,
                            376.93957033325773
                        ],
                        [
                            93.93967230904991,
                            376.39746764384256
                        ],
                        [
                            93.24269861989524,
                            373.456103746806
                        ],
                        [
                            92.65814004189497,
                            372.07841800695917
                        ],
                        [
                            91.39909079697054,
                            370.2824038223705
                        ],
                        [
                            91.30915870804756,
                            368.88251343008875
                        ],
                        [
                            89.1732715961225,
                            367.8792435463023
                        ],
                        [
                            88.70112812927596,
                            366.12278638250007
                        ],
                        [
                            90.40983781881596,
                            365.7462803919617
                        ],
                        [
                            91.80378519712508,
                            363.98867799183813
                        ],
                        [
                            91.44405684143203,
                            363.13045534015816
                        ],
                        [
                            92.09606448612521,
                            361.7693912391307
                        ],
                        [
                            91.26419266358607,
                            360.93152711432356
                        ],
                        [
                            92.23096261950991,
                            360.30298525856836
                        ],
                        [
                            92.18599657504842,
                            359.00360653197083
                        ],
                        [
                            91.48902288589375,
                            358.6472325808302
                        ],
                        [
                            91.12929453020115,
                            357.1164651656124
                        ],
                        [
                            91.53398893035524,
                            356.65499247900243
                        ],
                        [
                            90.926947330124,
                            354.4305821362681
                        ],
                        [
                            89.1732715961225,
                            354.68248154546563
                        ],
                        [
                            89.24072066281497,
                            353.63276634512624
                        ],
                        [
                            90.52225292996968,
                            352.70872376518497
                        ],
                        [
                            90.94943035235497,
                            353.25478217715886
                        ],
                        [
                            92.52324190851004,
                            352.96076267340675
                        ],
                        [
                            93.13028350874151,
                            350.81777654426855
                        ],
                        [
                            94.25443462028079,
                            348.75742110718363
                        ],
                        [
                            92.90545328643361,
                            348.14745754022647
                        ],
                        [
                            94.50174786481944,
                            347.09550870021513
                        ],
                        [
                            94.50174786481944,
                            345.53795319410347
                        ],
                        [
                            92.92793630866436,
                            345.3484658903204
                        ],
                        [
                            92.09606448612521,
                            344.3587269167076
                        ],
                        [
                            92.32089470843289,
                            342.39934215564455
                        ],
                        [
                            92.07358146389424,
                            340.86042610897977
                        ],
                        [
                            95.44603479851253,
                            339.616059271383
                        ],
                        [
                            95.8282461764361,
                            340.86042610897977
                        ],
                        [
                            96.70508404343695,
                            340.4175760379173
                        ],
                        [
                            97.67185399936079,
                            338.3922702636092
                        ],
                        [
                            99.94263924467032,
                            337.50575903981166
                        ],
                        [
                            100.95437524505564,
                            337.50575903981166
                        ],
                        [
                            100.70706200051723,
                            336.13324608585606
                        ],
                        [
                            103.4499907126733,
                            334.7600841461107
                        ],
                        [
                            104.82145506875145,
                            336.42891896263467
                        ],
                        [
                            106.0355382692137,
                            336.13324608585606
                        ],
                        [
                            106.26036849152183,
                            334.696691708251
                        ],
                        [
                            107.15968938075343,
                            334.696691708251
                        ],
                        [
                            108.98081418144716,
                            334.1895020868333
                        ],
                        [
                            109.92510111514025,
                            334.4853735317836
                        ],
                        [
                            111.92609009368039,
                            334.33744160267224
                        ],
                        [
                            112.03850520483456,
                            332.24444673844187
                        ],
                        [
                            111.34153151567989,
                            331.35604764336426
                        ],
                        [
                            111.76870893806495,
                            329.8112663085058
                        ],
                        [
                            111.02676920444901,
                            329.3243802917495
                        ],
                        [
                            110.57710875983321,
                            328.1173885046705
                        ],
                        [
                            111.45394662683384,
                            326.67679208825695
                        ],
                        [
                            114.10694325006693,
                            328.2868219818396
                        ],
                        [
                            115.2985434282989,
                            327.84203738422815
                        ],
                        [
                            115.83813596183768,
                            329.0491468244974
                        ],
                        [
                            117.95154005153177,
                            328.8374105520343
                        ],
                        [
                            120.22232529684129,
                            328.392712737739
                        ],
                        [
                            122.47062751992007,
                            329.17618098117003
                        ],
                        [
                            122.31324636430463,
                            327.3124403099387
                        ],
                        [
                            121.70620476407362,
                            326.78274345349035
                        ],
                        [
                            121.72868778630436,
                            324.8325960536707
                        ],
                        [
                            123.145118186844,
                            324.1115443718832
                        ],
                        [
                            124.0444390760756,
                            323.8570108987007
                        ],
                        [
                            124.53906556515267,
                            322.3080952848776
                        ],
                        [
                            126.49508849923131,
                            322.9659598241531
                        ],
                        [
                            126.92226592161637,
                            323.5175966334206
                        ],
                        [
                            127.3494433440012,
                            321.9897174869891
                        ],
                        [
                            128.47359445554093,
                            321.28915690173204
                        ],
                        [
                            127.73165472192477,
                            320.1636405742418
                        ],
                        [
                            128.2487642332328,
                            320.50346770989375
                        ],
                        [
                            130.36216832292712,
                            319.9087425726369
                        ],
                        [
                            130.15982112284973,
                            318.4638716117512
                        ],
                        [
                            130.8567948120044,
                            317.9749919944694
                        ],
                        [
                            130.38465134515786,
                            316.78430856963826
                        ],
                        [
                            130.83431178977366,
                            315.6994806846033
                        ],
                        [
                            132.49805543485195,
                            316.27385554479633
                        ],
                        [
                            135.1735350803158,
                            312.86836254626604
                        ],
                        [
                            135.4433313470854,
                            312.05892182099956
                        ],
                        [
                            136.20775410293209,
                            311.7606448813965
                        ],
                        [
                            137.62418450347172,
                            312.52757531400954
                        ],
                        [
                            137.55673543677926,
                            313.9330429162161
                        ],
                        [
                            138.3886072593184,
                            314.5503638720703
                        ],
                        [
                            139.6476565042426,
                            314.0820644185967
                        ],
                        [
                            140.38959623785877,
                            314.3375106050478
                        ],
                        [
                            139.8275206820888,
                            315.57182523303993
                        ],
                        [
                            140.34463019339705,
                            317.16708521653703
                        ],
                        [
                            139.6026904597809,
                            317.7411489488714
                        ],
                        [
                            141.19898503816717,
                            319.0801600616893
                        ],
                        [
                            140.16476601555087,
                            320.03619453787593
                        ],
                        [
                            139.44530930416568,
                            322.0109438162972
                        ],
                        [
                            138.20874308147222,
                            323.32665785726863
                        ],
                        [
                            138.0738449480873,
                            325.0234314278245
                        ],
                        [
                            138.41109028154938,
                            325.12944543634273
                        ],
                        [
                            138.81578468170324,
                            325.2990594705033
                        ],
                        [
                            141.626162460552,
                            326.14697519187723
                        ],
                        [
                            139.08558094847285,
                            327.3971825412574
                        ],
                        [
                            138.6584035260878,
                            328.5621296847546
                        ],
                        [
                            137.1295580143942,
                            330.5943424977285
                        ],
                        [
                            136.9047277920863,
                            331.58875029975655
                        ],
                        [
                            138.36612423708766,
                            332.32904178301135
                        ],
                        [
                            138.86075072616495,
                            333.36512852903206
                        ],
                        [
                            138.41109028154938,
                            334.71782267543756
                        ],
                        [
                            138.2986751703952,
                            335.79529735895176
                        ],
                        [
                            138.54598841493407,
                            337.27353306835386
                        ],
                        [
                            137.8040486813179,
                            338.3078521971803
                        ],
                        [
                            138.20874308147222,
                            339.9113802884659
                        ],
                        [
                            137.6691505479332,
                            341.429707334981
                        ],
                        [
                            135.10608601362333,
                            342.1042694246895
                        ],
                        [
                            135.78057668054703,
                            343.094754982906
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Goa",
        "properties": {
            "st_nm": "Goa",
            "st_code": "30",
            "alpha2code": "GA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            86.47530892842792,
                            358.01823970568796
                        ],
                        [
                            84.00217648304147,
                            357.0745158952351
                        ],
                        [
                            82.81057630480927,
                            353.5067767316349
                        ],
                        [
                            85.59847106142706,
                            353.17077944756636
                        ],
                        [
                            86.54275799512038,
                            353.2967826895309
                        ],
                        [
                            87.30718075096706,
                            355.29115460849647
                        ],
                        [
                            89.1732715961225,
                            354.68248154546563
                        ],
                        [
                            90.926947330124,
                            354.4305821362681
                        ],
                        [
                            91.53398893035524,
                            356.65499247900243
                        ],
                        [
                            91.12929453020115,
                            357.1164651656124
                        ],
                        [
                            91.48902288589375,
                            358.6472325808302
                        ],
                        [
                            92.18599657504842,
                            359.00360653197083
                        ],
                        [
                            92.23096261950991,
                            360.30298525856836
                        ],
                        [
                            91.26419266358607,
                            360.93152711432356
                        ],
                        [
                            92.09606448612521,
                            361.7693912391307
                        ],
                        [
                            91.44405684143203,
                            363.13045534015816
                        ],
                        [
                            91.80378519712508,
                            363.98867799183813
                        ],
                        [
                            90.40983781881596,
                            365.7462803919617
                        ],
                        [
                            88.70112812927596,
                            366.12278638250007
                        ],
                        [
                            87.1722826175826,
                            363.7793763466385
                        ],
                        [
                            85.73336919481198,
                            359.21321960134367
                        ],
                        [
                            86.47530892842792,
                            358.01823970568796
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Kerala",
        "properties": {
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            122.98773703122833,
                            414.91476413112946
                        ],
                        [
                            122.40317845322784,
                            415.9672303758259
                        ],
                        [
                            120.28977436353375,
                            417.0813182858652
                        ],
                        [
                            123.61726165369055,
                            419.1642867307584
                        ],
                        [
                            124.89879392084549,
                            419.5972510926923
                        ],
                        [
                            124.51658254292192,
                            420.9371016437707
                        ],
                        [
                            123.34746538692093,
                            421.9674724130309
                        ],
                        [
                            126.09039409907723,
                            422.07049600164925
                        ],
                        [
                            127.23702823284748,
                            421.3904950394153
                        ],
                        [
                            127.05716405500107,
                            422.4825659118913
                        ],
                        [
                            127.93400192200193,
                            423.80092781975355
                        ],
                        [
                            126.49508849923131,
                            425.9012492447812
                        ],
                        [
                            129.05815303354143,
                            426.9304549575541
                        ],
                        [
                            130.18230414508048,
                            428.2474925455808
                        ],
                        [
                            129.17056814469515,
                            431.0243598654458
                        ],
                        [
                            128.8333228112333,
                            433.61458431250026
                        ],
                        [
                            129.1031190780027,
                            434.86806293483295
                        ],
                        [
                            129.9574739227728,
                            436.0595846408702
                        ],
                        [
                            130.04740601169578,
                            436.1417474616197
                        ],
                        [
                            130.06988903392653,
                            436.16228794387985
                        ],
                        [
                            131.28397223438924,
                            436.6552328033882
                        ],
                        [
                            132.2957082347748,
                            436.01850269529353
                        ],
                        [
                            132.8802668127753,
                            435.2378777966299
                        ],
                        [
                            134.20676512439172,
                            434.60095630486563
                        ],
                        [
                            135.78057668054703,
                            436.4703844873533
                        ],
                        [
                            135.48829739154667,
                            438.1132290534713
                        ],
                        [
                            135.4208483248542,
                            439.5092041166811
                        ],
                        [
                            134.67890859123827,
                            441.7665481254312
                        ],
                        [
                            135.1735350803158,
                            442.93585372885093
                        ],
                        [
                            134.0044179243148,
                            445.72466462143973
                        ],
                        [
                            135.5107804137774,
                            446.31913716483075
                        ],
                        [
                            136.23023712516283,
                            445.8681643967666
                        ],
                        [
                            137.46680334785606,
                            447.01601616677704
                        ],
                        [
                            136.5449994363937,
                            448.59389026813096
                        ],
                        [
                            135.69064459162382,
                            450.3965831739276
                        ],
                        [
                            135.48829739154667,
                            452.5671821544626
                        ],
                        [
                            134.54401045785357,
                            453.7954279807409
                        ],
                        [
                            133.73462165754518,
                            454.69596204523714
                        ],
                        [
                            134.38662930223813,
                            455.9441806029515
                        ],
                        [
                            135.30843321370048,
                            456.6397842739358
                        ],
                        [
                            134.47656139116134,
                            458.2556984029617
                        ],
                        [
                            134.04938396877628,
                            458.685165450309
                        ],
                        [
                            135.5107804137774,
                            461.1181868946985
                        ],
                        [
                            135.3983653026237,
                            462.18102576606793
                        ],
                        [
                            132.99268192392924,
                            465.36835931896246
                        ],
                        [
                            130.9467269009274,
                            463.4479976670746
                        ],
                        [
                            127.12461312169353,
                            458.7260653366865
                        ],
                        [
                            126.87729987715466,
                            458.1738913738211
                        ],
                        [
                            124.89879392084549,
                            456.29199354985946
                        ],
                        [
                            123.88705792045994,
                            453.01757189980543
                        ],
                        [
                            121.97600103084301,
                            449.08559606784866
                        ],
                        [
                            121.54882360845772,
                            447.65132269649564
                        ],
                        [
                            120.96426503045745,
                            443.0794335753683
                        ],
                        [
                            119.30052138537894,
                            437.23027047370135
                        ],
                        [
                            119.99749507453339,
                            437.04544451606046
                        ],
                        [
                            119.23307231868671,
                            437.18919865842923
                        ],
                        [
                            118.3337514294551,
                            434.23109121079204
                        ],
                        [
                            116.22034733976125,
                            429.6875615886563
                        ],
                        [
                            115.52337365060657,
                            428.08288397510495
                        ],
                        [
                            114.28680742791335,
                            423.1417966200068
                        ],
                        [
                            112.39823356052693,
                            418.60755367724744
                        ],
                        [
                            111.22911640452617,
                            417.8238799029757
                        ],
                        [
                            110.28482947083307,
                            414.46067703953213
                        ],
                        [
                            110.0375162262942,
                            414.3987522287599
                        ],
                        [
                            109.97006715960197,
                            414.3987522287599
                        ],
                        [
                            107.81169702544616,
                            412.12753518784575
                        ],
                        [
                            107.38451960306134,
                            412.08622873205434
                        ],
                        [
                            105.83319106913677,
                            409.8550592423301
                        ],
                        [
                            105.09125133552061,
                            409.87572383634495
                        ],
                        [
                            104.57414182421257,
                            408.63566107557654
                        ],
                        [
                            102.75301702351862,
                            404.126859887723
                        ],
                        [
                            102.37080564559551,
                            403.69225426042715
                        ],
                        [
                            100.21243551143971,
                            398.5560883354567
                        ],
                        [
                            101.87617915651799,
                            398.8669382871243
                        ],
                        [
                            103.78723604613515,
                            399.73718374865393
                        ],
                        [
                            106.26036849152183,
                            402.0982868063205
                        ],
                        [
                            108.26135747006197,
                            402.4709609529235
                        ],
                        [
                            107.51941773644603,
                            403.7750399987695
                        ],
                        [
                            108.35128955898517,
                            404.37518435632614
                        ],
                        [
                            108.23887444783122,
                            405.6165718922423
                        ],
                        [
                            109.27309347044752,
                            405.6165718922423
                        ],
                        [
                            110.6220748042947,
                            407.62265958732576
                        ],
                        [
                            111.49891267129556,
                            407.7467143413026
                        ],
                        [
                            113.81466396106657,
                            409.1317319152436
                        ],
                        [
                            114.9388150726063,
                            410.7022212632785
                        ],
                        [
                            115.90558502852991,
                            410.8675005746163
                        ],
                        [
                            116.89483800668472,
                            411.01211446817126
                        ],
                        [
                            118.51361560730152,
                            410.28899358864646
                        ],
                        [
                            118.51361560730152,
                            412.10688201197706
                        ],
                        [
                            119.88507996337967,
                            412.0655753480642
                        ],
                        [
                            121.52634058622698,
                            413.71751827729065
                        ],
                        [
                            122.9202879645361,
                            413.6142912339898
                        ],
                        [
                            122.98773703122833,
                            414.91476413112946
                        ]
                    ],
                    [
                        [
                            110.30731249306382,
                            414.15104374638247
                        ],
                        [
                            110.10496529298666,
                            413.90332046441563
                        ],
                        [
                            110.0375162262942,
                            414.19232952059235
                        ],
                        [
                            110.30731249306382,
                            414.15104374638247
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Telangana",
        "properties": {
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            172.81011429465548,
                            294.0802899650972
                        ],
                        [
                            173.66446913942536,
                            295.1527043752976
                        ],
                        [
                            174.83358629542658,
                            295.77449577791907
                        ],
                        [
                            175.32821278450388,
                            296.8890493416166
                        ],
                        [
                            174.60875607311846,
                            300.6363670325046
                        ],
                        [
                            173.57453705050239,
                            301.5135171753282
                        ],
                        [
                            174.76613722873412,
                            302.5614267274125
                        ],
                        [
                            175.08089953996523,
                            305.1900252530876
                        ],
                        [
                            174.38392585081078,
                            305.80938909539
                        ],
                        [
                            175.93525438473512,
                            306.6634430578111
                        ],
                        [
                            177.39665082973625,
                            308.0720236147391
                        ],
                        [
                            179.71240211950771,
                            307.45319497145044
                        ],
                        [
                            181.12883252004713,
                            309.13862791742974
                        ],
                        [
                            182.3429157205096,
                            308.8400222210896
                        ],
                        [
                            185.01839536597345,
                            310.5032530175455
                        ],
                        [
                            186.030131366359,
                            311.8032579175971
                        ],
                        [
                            186.6596559888212,
                            313.8265939175697
                        ],
                        [
                            186.77207109997494,
                            315.97604678746836
                        ],
                        [
                            188.18850150051458,
                            315.42288623743144
                        ],
                        [
                            188.39084870059196,
                            316.44401725113886
                        ],
                        [
                            190.05459234567002,
                            316.0398657125112
                        ],
                        [
                            189.91969421228555,
                            317.8687021941131
                        ],
                        [
                            190.59418487920925,
                            321.77744520815463
                        ],
                        [
                            191.60592087959458,
                            321.7349887910929
                        ],
                        [
                            191.22370950167124,
                            322.7325419347086
                        ],
                        [
                            189.96466025674727,
                            322.0109438162972
                        ],
                        [
                            188.6831279895921,
                            322.69010020238863
                        ],
                        [
                            189.04285634528492,
                            324.19638368209445
                        ],
                        [
                            190.66163394590149,
                            326.019804223012
                        ],
                        [
                            194.91092514752063,
                            327.71494317432547
                        ],
                        [
                            194.3938156362126,
                            329.13383689590773
                        ],
                        [
                            193.1572494135189,
                            329.0279739105419
                        ],
                        [
                            192.90993616898027,
                            330.46737182676316
                        ],
                        [
                            190.27942256797814,
                            331.081010939613
                        ],
                        [
                            189.49251678990072,
                            330.6578257081953
                        ],
                        [
                            188.59319590066912,
                            331.5464421368405
                        ],
                        [
                            188.54822985620763,
                            332.94228116988154
                        ],
                        [
                            188.07608638936085,
                            333.4074098421323
                        ],
                        [
                            185.9401992774358,
                            332.73083412978826
                        ],
                        [
                            184.1865235434343,
                            331.67336474516026
                        ],
                        [
                            183.08485545412577,
                            332.96342501683444
                        ],
                        [
                            180.99393438666243,
                            334.37970864207193
                        ],
                        [
                            183.51203287651083,
                            334.612166293502
                        ],
                        [
                            184.5012858546654,
                            335.2671736099114
                        ],
                        [
                            183.9392102988959,
                            336.5978613900968
                        ],
                        [
                            184.0291423878191,
                            337.59020026646783
                        ],
                        [
                            180.3868927864312,
                            335.8797882304449
                        ],
                        [
                            180.1620625641233,
                            334.4219750622625
                        ],
                        [
                            178.4083868301218,
                            333.7033616280257
                        ],
                        [
                            176.4748469182739,
                            334.46424086335946
                        ],
                        [
                            176.22753367373548,
                            335.28830040873225
                        ],
                        [
                            176.72216016281277,
                            336.8301412106184
                        ],
                        [
                            176.4748469182739,
                            337.9490482492745
                        ],
                        [
                            174.90103536211882,
                            339.616059271383
                        ],
                        [
                            173.84433331727178,
                            338.66661218067463
                        ],
                        [
                            172.60776709457832,
                            338.24453705008614
                        ],
                        [
                            170.94402344950004,
                            338.7299182075236
                        ],
                        [
                            168.92055144872916,
                            339.6582498020447
                        ],
                        [
                            167.52660407042026,
                            339.8691933851028
                        ],
                        [
                            167.0994266480352,
                            340.4597550962666
                        ],
                        [
                            164.73870931380247,
                            340.691729147672
                        ],
                        [
                            164.17663375803272,
                            341.5140359870794
                        ],
                        [
                            164.04173562464803,
                            343.8953325795894
                        ],
                        [
                            164.10918469134026,
                            345.89584112635896
                        ],
                        [
                            163.27731286880135,
                            346.1484423211059
                        ],
                        [
                            161.38873900141493,
                            345.66427143710615
                        ],
                        [
                            159.54513117849024,
                            346.5483509830394
                        ],
                        [
                            159.81492744525985,
                            347.4742522919842
                        ],
                        [
                            157.13944779979602,
                            349.22007068610407
                        ],
                        [
                            155.09349277679416,
                            348.0633150405487
                        ],
                        [
                            154.35155304317823,
                            348.6943270914546
                        ],
                        [
                            152.28311499794563,
                            348.2105628873989
                        ],
                        [
                            150.0123297526361,
                            349.22007068610407
                        ],
                        [
                            149.89991464148216,
                            349.78777215339574
                        ],
                        [
                            148.82072957440437,
                            351.5322706229566
                        ],
                        [
                            147.47174824055696,
                            351.78440544875787
                        ],
                        [
                            144.59392139501597,
                            350.79675950067804
                        ],
                        [
                            143.35735517232274,
                            351.28011517464984
                        ],
                        [
                            141.24395108262866,
                            351.11200007350715
                        ],
                        [
                            139.1080639707036,
                            350.4604672499446
                        ],
                        [
                            138.90571677062644,
                            345.95899340849985
                        ],
                        [
                            140.27718112670482,
                            344.1059752606034
                        ],
                        [
                            138.748335615011,
                            343.45293464721277
                        ],
                        [
                            137.64666752570247,
                            343.7268077548465
                        ],
                        [
                            135.78057668054703,
                            343.094754982906
                        ],
                        [
                            135.10608601362333,
                            342.1042694246895
                        ],
                        [
                            137.6691505479332,
                            341.429707334981
                        ],
                        [
                            138.20874308147222,
                            339.9113802884659
                        ],
                        [
                            137.8040486813179,
                            338.3078521971803
                        ],
                        [
                            138.54598841493407,
                            337.27353306835386
                        ],
                        [
                            138.2986751703952,
                            335.79529735895176
                        ],
                        [
                            138.41109028154938,
                            334.71782267543756
                        ],
                        [
                            138.86075072616495,
                            333.36512852903206
                        ],
                        [
                            138.36612423708766,
                            332.32904178301135
                        ],
                        [
                            136.9047277920863,
                            331.58875029975655
                        ],
                        [
                            137.1295580143942,
                            330.5943424977285
                        ],
                        [
                            138.6584035260878,
                            328.5621296847546
                        ],
                        [
                            139.08558094847285,
                            327.3971825412574
                        ],
                        [
                            141.626162460552,
                            326.14697519187723
                        ],
                        [
                            138.81578468170324,
                            325.2990594705033
                        ],
                        [
                            138.41109028154938,
                            325.12944543634273
                        ],
                        [
                            138.0738449480873,
                            325.0234314278245
                        ],
                        [
                            138.20874308147222,
                            323.32665785726863
                        ],
                        [
                            139.44530930416568,
                            322.0109438162972
                        ],
                        [
                            140.16476601555087,
                            320.03619453787593
                        ],
                        [
                            141.19898503816717,
                            319.0801600616893
                        ],
                        [
                            139.6026904597809,
                            317.7411489488714
                        ],
                        [
                            140.34463019339705,
                            317.16708521653703
                        ],
                        [
                            139.8275206820888,
                            315.57182523303993
                        ],
                        [
                            140.38959623785877,
                            314.3375106050478
                        ],
                        [
                            139.6476565042426,
                            314.0820644185967
                        ],
                        [
                            139.26544512631926,
                            312.8470646176613
                        ],
                        [
                            140.1422829933199,
                            311.69672404844084
                        ],
                        [
                            140.3221471711663,
                            310.0982033786218
                        ],
                        [
                            142.41306823862965,
                            309.991601073371
                        ],
                        [
                            142.27817010524473,
                            308.28537903189954
                        ],
                        [
                            143.1325249500146,
                            308.02935045477244
                        ],
                        [
                            143.89694770586152,
                            306.1296920331829
                        ],
                        [
                            145.49324228424734,
                            305.5317614246396
                        ],
                        [
                            143.94191375032324,
                            304.52778378512915
                        ],
                        [
                            143.26742308339954,
                            301.9626734214149
                        ],
                        [
                            144.18922699486188,
                            300.2939832584313
                        ],
                        [
                            144.23419303932337,
                            298.36722072773574
                        ],
                        [
                            145.42579321755534,
                            297.76748666660114
                        ],
                        [
                            146.86470664032572,
                            298.88116616423434
                        ],
                        [
                            148.82072957440437,
                            299.395008135298
                        ],
                        [
                            149.09052584117376,
                            298.17446447106767
                        ],
                        [
                            149.36032210794315,
                            296.43900022765195
                        ],
                        [
                            150.79923553071376,
                            295.6029824072024
                        ],
                        [
                            150.75426948625227,
                            293.65119615408014
                        ],
                        [
                            150.30460904163624,
                            292.3205386148391
                        ],
                        [
                            151.29386201979105,
                            291.97702823496
                        ],
                        [
                            151.60862433102193,
                            290.92473345138
                        ],
                        [
                            150.75426948625227,
                            288.88330059587133
                        ],
                        [
                            151.8559375755608,
                            289.3776964312693
                        ],
                        [
                            152.59787730917674,
                            290.40916149935356
                        ],
                        [
                            154.71128139887082,
                            290.3447074875358
                        ],
                        [
                            158.6233272670279,
                            291.22543443707144
                        ],
                        [
                            158.8931235337975,
                            292.3634740927217
                        ],
                        [
                            160.30955393433715,
                            292.87864235534573
                        ],
                        [
                            160.01727464533678,
                            294.53075965343004
                        ],
                        [
                            162.37799197956974,
                            294.80958144369424
                        ],
                        [
                            163.74945633564766,
                            295.9459975097123
                        ],
                        [
                            164.67126024711,
                            293.865752221271
                        ],
                        [
                            166.3350038921883,
                            294.12319531642106
                        ],
                        [
                            167.88633242611263,
                            295.3028059785232
                        ],
                        [
                            171.88831038319313,
                            293.60828274047594
                        ],
                        [
                            172.81011429465548,
                            294.0802899650972
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-West Bengal",
        "properties": {
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            295.11775523014444,
                            261.1642556092064
                        ],
                        [
                            293.7462908740663,
                            260.9467430852385
                        ],
                        [
                            294.80299291891356,
                            258.5091808522876
                        ],
                        [
                            295.7247968303757,
                            258.8140193186129
                        ],
                        [
                            295.79224589706814,
                            260.1635260111431
                        ],
                        [
                            295.11775523014444,
                            261.1642556092064
                        ]
                    ]
                ],
                [
                    [
                        [
                            308.20287416846327,
                            259.510639064621
                        ],
                        [
                            309.34950830223374,
                            261.51223251374955
                        ],
                        [
                            308.54011950192535,
                            262.16454633377396
                        ],
                        [
                            308.20287416846327,
                            259.510639064621
                        ]
                    ]
                ],
                [
                    [
                        [
                            293.251664384989,
                            252.11985884355863
                        ],
                        [
                            293.49897762952764,
                            252.79672516252657
                        ],
                        [
                            295.2751363857601,
                            253.05868274661952
                        ],
                        [
                            296.01707611937604,
                            254.3461940608674
                        ],
                        [
                            294.21843434091284,
                            255.7201773421719
                        ],
                        [
                            292.622139762527,
                            258.68337928484306
                        ],
                        [
                            290.1714903393711,
                            260.7727181216838
                        ],
                        [
                            288.4852636720618,
                            261.5339793088642
                        ],
                        [
                            285.69736891544426,
                            262.09932332841703
                        ],
                        [
                            285.06784429298204,
                            259.68476059413837
                        ],
                        [
                            282.72960998097983,
                            259.1188167835528
                        ],
                        [
                            282.23498349190277,
                            258.31319159441324
                        ],
                        [
                            282.14505140297956,
                            256.5703173851811
                        ],
                        [
                            280.16654544667017,
                            257.2022647999703
                        ],
                        [
                            280.0541303355162,
                            258.1607437695603
                        ],
                        [
                            278.59273389051486,
                            257.37656396225395
                        ],
                        [
                            278.9974282906692,
                            255.3931147792477
                        ],
                        [
                            278.0531413569761,
                            254.65159645365782
                        ],
                        [
                            276.18705051182064,
                            254.45527112511826
                        ],
                        [
                            274.56827291120385,
                            252.57840366039642
                        ],
                        [
                            276.1196014451282,
                            250.83105999601986
                        ],
                        [
                            276.2994656229746,
                            249.65082459930676
                        ],
                        [
                            275.10786544474263,
                            249.23540678551217
                        ],
                        [
                            275.64745797828164,
                            248.25121047799308
                        ],
                        [
                            275.1303484669734,
                            246.85070634133768
                        ],
                        [
                            273.5565369108185,
                            246.85070634133768
                        ],
                        [
                            273.30922366627965,
                            245.55882432749638
                        ],
                        [
                            272.09514046581717,
                            244.66062630068006
                        ],
                        [
                            270.02670242058457,
                            243.43322129176278
                        ],
                        [
                            270.2740156651232,
                            241.2616468650195
                        ],
                        [
                            271.2407856210473,
                            240.18602064442905
                        ],
                        [
                            267.41867184181297,
                            240.14210614537546
                        ],
                        [
                            264.5183619740412,
                            237.76939141161546
                        ],
                        [
                            262.18012766203947,
                            237.41765498687136
                        ],
                        [
                            261.21335770611563,
                            235.79012120680818
                        ],
                        [
                            262.1576446398087,
                            234.49160255690322
                        ],
                        [
                            261.7979162841159,
                            233.21431896631873
                        ],
                        [
                            262.18012766203947,
                            232.35502281608967
                        ],
                        [
                            264.4509129073492,
                            232.11259410308043
                        ],
                        [
                            265.0804375298112,
                            231.0323460631859
                        ],
                        [
                            266.2045886413507,
                            231.14260080664485
                        ],
                        [
                            266.4743849081199,
                            232.57538843244515
                        ],
                        [
                            268.4304078421985,
                            233.5006735451497
                        ],
                        [
                            269.2397966425069,
                            231.5835621281135
                        ],
                        [
                            271.0159553987394,
                            229.9074177354044
                        ],
                        [
                            274.34344268889595,
                            228.89225968643308
                        ],
                        [
                            275.64745797828164,
                            228.89225968643308
                        ],
                        [
                            276.029669356205,
                            227.83243980999953
                        ],
                        [
                            275.64745797828164,
                            226.72788907314225
                        ],
                        [
                            277.3336846455909,
                            225.954355289621
                        ],
                        [
                            279.8293001132083,
                            227.0814089104237
                        ],
                        [
                            280.4813077579013,
                            226.41851003224383
                        ],
                        [
                            282.01015326959487,
                            226.37430927608494
                        ],
                        [
                            282.90947415882624,
                            225.6448612694977
                        ],
                        [
                            282.01015326959487,
                            223.6541545501911
                        ],
                        [
                            283.5165157590577,
                            223.4992416180525
                        ],
                        [
                            285.0453612707513,
                            224.34005821213282
                        ],
                        [
                            287.8557390495996,
                            221.41785565680897
                        ],
                        [
                            287.9681541607538,
                            219.9551986717517
                        ],
                        [
                            288.95740713890837,
                            219.86651929204368
                        ],
                        [
                            290.3513545172175,
                            217.89240881806478
                        ],
                        [
                            290.23893940606354,
                            214.64983428338735
                        ],
                        [
                            291.65536980660295,
                            214.9387667664539
                        ],
                        [
                            291.92516607337257,
                            212.40364346664177
                        ],
                        [
                            290.913430072987,
                            212.02532569834705
                        ],
                        [
                            291.6778528288339,
                            210.24405031093966
                        ],
                        [
                            292.68958882921925,
                            209.17452506761623
                        ],
                        [
                            292.59965674029627,
                            208.327411674482
                        ],
                        [
                            290.2614224282943,
                            206.4758850682062
                        ],
                        [
                            290.0365922059864,
                            205.5606389046763
                        ],
                        [
                            290.01410918375564,
                            203.840618554123
                        ],
                        [
                            289.0248562056008,
                            203.70652813366212
                        ],
                        [
                            289.7892789614475,
                            203.59477915918285
                        ],
                        [
                            290.5986677617559,
                            203.97469987046992
                        ],
                        [
                            291.02584518414096,
                            203.05829596306438
                        ],
                        [
                            290.1040412726786,
                            202.36512231870518
                        ],
                        [
                            289.85672802813997,
                            201.13469676161787
                        ],
                        [
                            291.3181244731411,
                            199.8139275615602
                        ],
                        [
                            292.68958882921925,
                            199.14200814601628
                        ],
                        [
                            294.26340038537455,
                            200.03784942442138
                        ],
                        [
                            293.5889097184506,
                            198.20093254423512
                        ],
                        [
                            294.0160871408359,
                            196.49687777229695
                        ],
                        [
                            292.8020039403732,
                            196.093067013952
                        ],
                        [
                            291.70033585106466,
                            193.803215400044
                        ],
                        [
                            290.7335658951406,
                            193.39884229375588
                        ],
                        [
                            290.66611682844837,
                            192.65727133490464
                        ],
                        [
                            291.02584518414096,
                            191.73552763467018
                        ],
                        [
                            290.935913095218,
                            191.66806562720058
                        ],
                        [
                            291.11577727306417,
                            191.12828427175089
                        ],
                        [
                            291.0708112286027,
                            190.97081945604486
                        ],
                        [
                            292.9144190515274,
                            189.26036830341496
                        ],
                        [
                            294.35333247429776,
                            188.74243025542103
                        ],
                        [
                            295.4325175413753,
                            187.50331256193084
                        ],
                        [
                            297.38854047545396,
                            186.1957326708128
                        ],
                        [
                            296.8039818974537,
                            184.09721403232572
                        ],
                        [
                            294.89292500783677,
                            182.62912399451233
                        ],
                        [
                            295.6348647414525,
                            181.15988955241363
                        ],
                        [
                            296.01707611937604,
                            178.94256082447095
                        ],
                        [
                            295.050306163452,
                            175.99714173838458
                        ],
                        [
                            293.1617322960658,
                            173.38768315540204
                        ],
                        [
                            293.8362229629895,
                            171.54750449671536
                        ],
                        [
                            294.6905778077594,
                            172.9789130780631
                        ],
                        [
                            295.54493265252927,
                            173.36497607844342
                        ],
                        [
                            297.4110234976847,
                            173.00162489949003
                        ],
                        [
                            299.524427587379,
                            174.00066887169754
                        ],
                        [
                            301.0982391435341,
                            172.2747079990349
                        ],
                        [
                            302.06500909945817,
                            172.07021101032637
                        ],
                        [
                            303.8861339001521,
                            172.88806299949783
                        ],
                        [
                            305.999537989846,
                            173.38768315540204
                        ],
                        [
                            306.04450403430747,
                            175.74770005683268
                        ],
                        [
                            306.8089267901544,
                            175.2714001696047
                        ],
                        [
                            309.2820592355413,
                            177.01723671916784
                        ],
                        [
                            309.304542257772,
                            177.81025780847506
                        ],
                        [
                            309.8666178135418,
                            178.37649511305597
                        ],
                        [
                            311.7327086586972,
                            178.35384891750977
                        ],
                        [
                            313.4863843926987,
                            177.5837147715933
                        ],
                        [
                            314.6555015486995,
                            178.51236652149305
                        ],
                        [
                            316.0269659047776,
                            178.2632613894408
                        ],
                        [
                            317.3309811941633,
                            179.28211801454836
                        ],
                        [
                            319.197072039319,
                            180.14205403599158
                        ],
                        [
                            320.50108732870444,
                            180.16467860276208
                        ],
                        [
                            320.77088359547406,
                            181.54426168082517
                        ],
                        [
                            320.253774084166,
                            184.81954730098056
                        ],
                        [
                            320.36618919532,
                            185.33855417263595
                        ],
                        [
                            319.4668683060884,
                            185.83486260179404
                        ],
                        [
                            318.27526812785663,
                            188.0441174790722
                        ],
                        [
                            318.70244555024146,
                            188.80999535560653
                        ],
                        [
                            317.30849817193257,
                            187.86386622414176
                        ],
                        [
                            316.92628679400923,
                            189.10274984497897
                        ],
                        [
                            317.53332839424047,
                            190.56585057777647
                        ],
                        [
                            315.9370338158544,
                            191.5106450661874
                        ],
                        [
                            313.10417301477514,
                            191.44317516297508
                        ],
                        [
                            311.35049728077365,
                            189.575566246258
                        ],
                        [
                            310.2488291914651,
                            189.32791509331787
                        ],
                        [
                            309.39447434669523,
                            185.20317478034963
                        ],
                        [
                            307.7307307016167,
                            184.27782320613
                        ],
                        [
                            306.65154563453916,
                            185.38367848827244
                        ],
                        [
                            307.7756967460782,
                            185.87997511227115
                        ],
                        [
                            306.8988588790776,
                            186.96235403965198
                        ],
                        [
                            304.44820945592164,
                            186.21828477304
                        ],
                        [
                            303.59385461115176,
                            185.2708656851467
                        ],
                        [
                            301.07575612130336,
                            183.7585253177803
                        ],
                        [
                            299.45697852068656,
                            182.380564913333
                        ],
                        [
                            299.1646992316864,
                            181.04682396332447
                        ],
                        [
                            298.1754462535316,
                            183.46494598075537
                        ],
                        [
                            300.40126545437965,
                            184.052059043273
                        ],
                        [
                            300.8509258989957,
                            185.6318430311929
                        ],
                        [
                            299.1871822539172,
                            186.1957326708128
                        ],
                        [
                            298.445242520301,
                            187.97652525560028
                        ],
                        [
                            295.9046610082221,
                            189.1703022043481
                        ],
                        [
                            294.6456117632979,
                            192.74717392275141
                        ],
                        [
                            294.75802687445184,
                            194.3871604442499
                        ],
                        [
                            295.38755149691406,
                            194.9709291740636
                        ],
                        [
                            297.208676297608,
                            195.17296194676436
                        ],
                        [
                            299.95160500976385,
                            197.10243657771719
                        ],
                        [
                            299.83918989861013,
                            198.22334438157935
                        ],
                        [
                            302.78446581084336,
                            200.12741099867793
                        ],
                        [
                            305.21263221176855,
                            199.38840540886827
                        ],
                        [
                            305.61732661192264,
                            201.98489119045092
                        ],
                        [
                            307.7981797683092,
                            203.46067203741575
                        ],
                        [
                            306.65154563453916,
                            204.9800199544856
                        ],
                        [
                            305.1227001228451,
                            205.136357181871
                        ],
                        [
                            304.0659980779981,
                            204.66730845741046
                        ],
                        [
                            299.6818087429947,
                            204.6449700309717
                        ],
                        [
                            299.92912198753334,
                            206.60978815135434
                        ],
                        [
                            299.0747671427632,
                            208.39430211606344
                        ],
                        [
                            297.3660574532232,
                            209.68707722511004
                        ],
                        [
                            296.6016346973763,
                            208.55037109478582
                        ],
                        [
                            295.34258545245234,
                            209.28596062335555
                        ],
                        [
                            295.83721194152986,
                            210.15494502362048
                        ],
                        [
                            294.2858834076053,
                            211.6246764104614
                        ],
                        [
                            293.67884180737383,
                            212.91537209233132
                        ],
                        [
                            295.2301703412984,
                            215.8275315924002
                        ],
                        [
                            298.19792927576236,
                            216.91573871267303
                        ],
                        [
                            298.89490296491704,
                            217.67047942288724
                        ],
                        [
                            301.5029335436884,
                            219.09041096964927
                        ],
                        [
                            303.32405834438214,
                            218.8242489422397
                        ],
                        [
                            304.178413189152,
                            219.9551986717517
                        ],
                        [
                            304.2458622558445,
                            221.68368152781005
                        ],
                        [
                            303.5713715889208,
                            222.50309543796368
                        ],
                        [
                            304.20089621138277,
                            223.4992416180525
                        ],
                        [
                            304.04351505576733,
                            225.49009698369122
                        ],
                        [
                            302.19990723284286,
                            226.2859049496139
                        ],
                        [
                            301.5703826103809,
                            228.67150811637526
                        ],
                        [
                            301.9975600327657,
                            230.21628149657494
                        ],
                        [
                            303.90861692238286,
                            231.89218022226925
                        ],
                        [
                            304.49317550038313,
                            232.94995730561402
                        ],
                        [
                            303.7062697223057,
                            236.38409477212406
                        ],
                        [
                            305.1901491895376,
                            236.01013069452756
                        ],
                        [
                            307.1236891013855,
                            236.80197714747953
                        ],
                        [
                            305.887122878692,
                            240.6031638039509
                        ],
                        [
                            307.5283835015398,
                            242.57801210056658
                        ],
                        [
                            306.69651167900065,
                            243.95933670963234
                        ],
                        [
                            307.4384514126166,
                            244.98927818698422
                        ],
                        [
                            306.8988588790776,
                            245.66833542069753
                        ],
                        [
                            307.97804394615537,
                            249.1916740494974
                        ],
                        [
                            307.91059487946313,
                            251.4427865753343
                        ],
                        [
                            308.94481390207943,
                            252.70939912806188
                        ],
                        [
                            308.78743274646376,
                            253.97529227378982
                        ],
                        [
                            308.9897799465409,
                            256.81004243412303
                        ],
                        [
                            308.09045905730954,
                            257.55084966630375
                        ],
                        [
                            306.9887909680008,
                            256.9189999354585
                        ],
                        [
                            305.8196738119998,
                            257.22405293154367
                        ],
                        [
                            304.49317550038313,
                            256.7228726402522
                        ],
                        [
                            304.49317550038313,
                            255.76378208633906
                        ],
                        [
                            302.58211861076643,
                            256.7228726402522
                        ],
                        [
                            301.0982391435341,
                            258.3349690166874
                        ],
                        [
                            299.8167068763794,
                            257.31120335399106
                        ],
                        [
                            299.0298010983015,
                            257.6379874741467
                        ],
                        [
                            298.55765763145496,
                            259.0970468948286
                        ],
                        [
                            297.208676297608,
                            260.3158393248062
                        ],
                        [
                            297.72578580891604,
                            261.2295053212104
                        ],
                        [
                            297.09626118645406,
                            263.0340088607277
                        ],
                        [
                            296.3992874972994,
                            259.44534004859435
                        ],
                        [
                            295.4100345191446,
                            256.7228726402522
                        ],
                        [
                            296.3318384306069,
                            255.58935803196744
                        ],
                        [
                            296.48921958622236,
                            254.36800989885
                        ],
                        [
                            295.9721100749143,
                            253.03685412306936
                        ],
                        [
                            294.48823060768245,
                            251.8577908110779
                        ],
                        [
                            293.251664384989,
                            252.11985884355863
                        ]
                    ]
                ],
                [
                    [
                        [
                            304.6955227004603,
                            258.09540584249646
                        ],
                        [
                            305.16766616730683,
                            261.25125481047445
                        ],
                        [
                            304.2458622558445,
                            261.3599991470084
                        ],
                        [
                            303.4364734555361,
                            259.9459178675629
                        ],
                        [
                            303.7962018112287,
                            257.50727950153583
                        ],
                        [
                            304.6955227004603,
                            258.09540584249646
                        ]
                    ]
                ],
                [
                    [
                        [
                            307.1461721236162,
                            257.35477730314017
                        ],
                        [
                            307.2136211903087,
                            259.6629961329742
                        ],
                        [
                            305.34753034515325,
                            257.1586879055543
                        ],
                        [
                            306.24685123438485,
                            257.37656396225395
                        ],
                        [
                            307.1461721236162,
                            257.35477730314017
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Dadra and Nagar Haveli and Daman and Diu",
        "properties": {
            "st_nm": "Dadra and Nagar Haveli and Daman and Diu",
            "st_code": "26",
            "alpha2code": "DN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            72.17610678964661,
                            284.23543007389594
                        ],
                        [
                            71.74892936726178,
                            282.8781363706169
                        ],
                        [
                            73.36770696787835,
                            282.4039821976396
                        ],
                        [
                            75.1888317685723,
                            282.98588576889335
                        ],
                        [
                            74.71668830172575,
                            284.3861938065795
                        ],
                        [
                            75.84083941326503,
                            285.61349504214576
                        ],
                        [
                            74.76165434618724,
                            286.1515909311974
                        ],
                        [
                            72.2210728341081,
                            285.505861680397
                        ],
                        [
                            72.17610678964661,
                            284.23543007389594
                        ]
                    ]
                ],
                [
                    [
                        [
                            71.07443870033785,
                            280.2691385576315
                        ],
                        [
                            70.22008385556796,
                            281.73569844657766
                        ],
                        [
                            70.33249896672191,
                            280.39857675239057
                        ],
                        [
                            71.07443870033785,
                            280.2691385576315
                        ]
                    ]
                ],
                [
                    [
                        [
                            42.16127211154412,
                            275.8640803389601
                        ],
                        [
                            43.330389267545115,
                            275.8640803389601
                        ],
                        [
                            42.993143934083264,
                            276.4907847984798
                        ],
                        [
                            42.16127211154412,
                            275.8640803389601
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Puducherry",
        "properties": {
            "st_nm": "Puducherry",
            "st_code": "34",
            "alpha2code": "PY",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            171.03395553842324,
                            411.77641703789357
                        ],
                        [
                            172.49535198342437,
                            410.5782573728726
                        ],
                        [
                            173.30474078373277,
                            410.6402397904577
                        ],
                        [
                            172.83259731688622,
                            412.31340909035265
                        ],
                        [
                            171.03395553842324,
                            411.77641703789357
                        ]
                    ]
                ],
                [
                    [
                        [
                            171.596031094193,
                            426.04535237589914
                        ],
                        [
                            171.9557594498856,
                            425.0365320698666
                        ],
                        [
                            173.46212193934844,
                            425.20125313055644
                        ],
                        [
                            173.4396389171177,
                            427.5684433389141
                        ],
                        [
                            172.9899784725019,
                            426.78638046610513
                        ],
                        [
                            171.596031094193,
                            426.04535237589914
                        ]
                    ]
                ],
                [
                    [
                        [
                            110.30731249306382,
                            414.15104374638247
                        ],
                        [
                            110.0375162262942,
                            414.19232952059235
                        ],
                        [
                            110.10496529298666,
                            413.90332046441563
                        ],
                        [
                            110.30731249306382,
                            414.15104374638247
                        ]
                    ]
                ],
                [
                    [
                        [
                            207.7936968857623,
                            338.0545833901276
                        ],
                        [
                            209.03026310845576,
                            338.28674730029394
                        ],
                        [
                            208.98529706399427,
                            338.7088163504323
                        ],
                        [
                            208.58060266383995,
                            338.37116597514057
                        ],
                        [
                            207.99604408583946,
                            338.3078521971803
                        ],
                        [
                            207.7936968857623,
                            338.0545833901276
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Arunachal Pradesh",
        "properties": {
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            384.6226667309131,
                            165.14933894821905
                        ],
                        [
                            385.40957250899055,
                            166.5857524416062
                        ],
                        [
                            384.71259881983633,
                            166.83643869547728
                        ],
                        [
                            381.3626285074488,
                            169.7509895151403
                        ],
                        [
                            378.64218281752323,
                            172.72906460643605
                        ],
                        [
                            378.9794281509853,
                            174.06876586194176
                        ],
                        [
                            377.04588823913764,
                            175.2714001696047
                        ],
                        [
                            376.57374477229087,
                            175.7023434256688
                        ],
                        [
                            373.7184009489806,
                            176.22387785735907
                        ],
                        [
                            372.63921588190306,
                            175.88376330678574
                        ],
                        [
                            367.69295099112946,
                            176.4732556619427
                        ],
                        [
                            367.10839241312897,
                            176.60926583074888
                        ],
                        [
                            365.1298864568196,
                            175.18066252433488
                        ],
                        [
                            361.9822633445092,
                            174.65883491209587
                        ],
                        [
                            361.7349500999708,
                            175.04454773594563
                        ],
                        [
                            360.3634857438924,
                            175.88376330678574
                        ],
                        [
                            358.6547760543526,
                            175.86108679037966
                        ],
                        [
                            357.37324378719745,
                            176.4052468490122
                        ],
                        [
                            354.9450773862727,
                            176.99457401460182
                        ],
                        [
                            353.75347720804075,
                            176.949247778193
                        ],
                        [
                            353.6185790746558,
                            175.0672342275334
                        ],
                        [
                            352.58436005203976,
                            174.1141624638558
                        ],
                        [
                            352.44946191865483,
                            172.5019002869775
                        ],
                        [
                            353.12395258557876,
                            170.61535511470396
                        ],
                        [
                            352.89912236327086,
                            168.56751307788642
                        ],
                        [
                            352.2920807630394,
                            167.08709050193585
                        ],
                        [
                            351.2128956959616,
                            167.56551202876574
                        ],
                        [
                            347.0085705388042,
                            167.20101176094283
                        ],
                        [
                            345.6820722271875,
                            165.51424978142933
                        ],
                        [
                            345.6146231604953,
                            164.69309736870974
                        ],
                        [
                            346.5813931164191,
                            163.50633234782367
                        ],
                        [
                            346.6488421831116,
                            161.907535239894
                        ],
                        [
                            349.52666902865235,
                            161.77042967585152
                        ],
                        [
                            350.336057828961,
                            163.23235314168426
                        ],
                        [
                            351.8873863628853,
                            162.43301019751112
                        ],
                        [
                            352.9216053855016,
                            162.61574829492355
                        ],
                        [
                            354.4054848527335,
                            161.31333591012793
                        ],
                        [
                            355.86688129773506,
                            160.9018524763885
                        ],
                        [
                            356.6313040535815,
                            162.27309921036692
                        ],
                        [
                            358.96953836558373,
                            161.67902018260446
                        ],
                        [
                            360.34100272166165,
                            161.58760606071945
                        ],
                        [
                            361.9372973000475,
                            160.28445112134392
                        ],
                        [
                            362.8815842337408,
                            159.00323929752477
                        ],
                        [
                            361.8248821888935,
                            157.49206501097768
                        ],
                        [
                            362.20709356681687,
                            156.50682565958732
                        ],
                        [
                            363.8708372118956,
                            155.24584337992928
                        ],
                        [
                            365.2647845902043,
                            155.19997267969188
                        ],
                        [
                            367.1533584575907,
                            153.54783994841722
                        ],
                        [
                            370.07615134759294,
                            152.9049290305806
                        ],
                        [
                            369.4691097473619,
                            151.38857336438866
                        ],
                        [
                            370.88554014790134,
                            150.19295309658827
                        ],
                        [
                            371.7848610371327,
                            147.91443352609042
                        ],
                        [
                            374.0331632602117,
                            147.17731080527108
                        ],
                        [
                            375.9442201498289,
                            147.63804875693927
                        ],
                        [
                            379.22674139552396,
                            146.78558842923786
                        ],
                        [
                            380.93545108506373,
                            146.69340574378674
                        ],
                        [
                            381.45256059637177,
                            144.54878920538607
                        ],
                        [
                            383.8132779306047,
                            142.63255022619722
                        ],
                        [
                            384.2404553529898,
                            141.22288384587006
                        ],
                        [
                            385.7693008646834,
                            139.39554654007213
                        ],
                        [
                            386.7810368650689,
                            139.30297181579255
                        ],
                        [
                            387.8602219321467,
                            138.05272738766092
                        ],
                        [
                            389.72631277730216,
                            138.02956617301504
                        ],
                        [
                            390.0185920663023,
                            137.14920940294525
                        ],
                        [
                            391.47998851130365,
                            136.36114018728517
                        ],
                        [
                            393.1437321563817,
                            138.0758882913767
                        ],
                        [
                            394.33533233461367,
                            138.79372220350137
                        ],
                        [
                            399.91112184784924,
                            140.04343095324793
                        ],
                        [
                            400.1584350923879,
                            141.03792467127755
                        ],
                        [
                            402.8788807823132,
                            141.2922384542278
                        ],
                        [
                            404.1154470050067,
                            138.30748023215165
                        ],
                        [
                            404.83490371639186,
                            138.23800591316729
                        ],
                        [
                            406.54361340593186,
                            136.84793142449104
                        ],
                        [
                            409.1741270069342,
                            135.3175517621517
                        ],
                        [
                            410.27579509624275,
                            135.45673352128586
                        ],
                        [
                            412.2093350080904,
                            134.13405066506897
                        ],
                        [
                            413.4908672752456,
                            135.7814470961356
                        ],
                        [
                            414.48012025339995,
                            137.96008066327965
                        ],
                        [
                            416.68345643201746,
                            137.61261111562084
                        ],
                        [
                            415.986482742863,
                            139.04836576457882
                        ],
                        [
                            414.0979088754766,
                            139.60382155584546
                        ],
                        [
                            413.10865589732225,
                            141.03792467127755
                        ],
                        [
                            414.5475693200924,
                            142.88663078098676
                        ],
                        [
                            415.986482742863,
                            141.38470695477963
                        ],
                        [
                            417.31298105447945,
                            141.2922384542278
                        ],
                        [
                            418.52706425494216,
                            142.42463853771574
                        ],
                        [
                            418.79686052171155,
                            143.87949927347097
                        ],
                        [
                            419.83107954432785,
                            145.33314745878602
                        ],
                        [
                            419.493834210866,
                            146.624265549261
                        ],
                        [
                            417.8300905657877,
                            147.17731080527108
                        ],
                        [
                            418.1448528770186,
                            148.02958101872025
                        ],
                        [
                            415.6942034538629,
                            150.07794695008005
                        ],
                        [
                            416.36869412078636,
                            150.56092243003673
                        ],
                        [
                            415.51433927601624,
                            152.2158373137446
                        ],
                        [
                            418.3696830993265,
                            151.71033305293332
                        ],
                        [
                            419.4039021219428,
                            150.97479633156206
                        ],
                        [
                            420.64046834463625,
                            150.9288151283842
                        ],
                        [
                            422.37166105640677,
                            152.53744677245413
                        ],
                        [
                            424.08037074594677,
                            152.2617850949249
                        ],
                        [
                            425.5192841687174,
                            153.43305149689223
                        ],
                        [
                            426.91323154702604,
                            152.56041664433235
                        ],
                        [
                            428.104831725258,
                            153.73148604711088
                        ],
                        [
                            428.84677145887395,
                            153.73148604711088
                        ],
                        [
                            431.05010763749124,
                            154.97060146732542
                        ],
                        [
                            430.420583015029,
                            156.02546482505977
                        ],
                        [
                            430.420583015029,
                            157.65240148400216
                        ],
                        [
                            431.2974208820301,
                            158.54544214672222
                        ],
                        [
                            431.1175567041837,
                            160.1243495452551
                        ],
                        [
                            429.3189149257207,
                            160.26158033940476
                        ],
                        [
                            427.90248452518085,
                            161.70187298979982
                        ],
                        [
                            426.05887670225616,
                            162.77562898611973
                        ],
                        [
                            424.17030283486974,
                            165.03528929179924
                        ],
                        [
                            423.56326123463896,
                            164.73872668270548
                        ],
                        [
                            424.6424463017165,
                            166.76807313455453
                        ],
                        [
                            424.17030283486974,
                            167.70218087795195
                        ],
                        [
                            425.0471407018706,
                            168.54474642110074
                        ],
                        [
                            426.9357145692568,
                            171.66114896269323
                        ],
                        [
                            428.0148996363348,
                            172.8426362842216
                        ],
                        [
                            426.5310201691027,
                            173.66014632986042
                        ],
                        [
                            425.3619030131017,
                            172.638202233593
                        ],
                        [
                            423.540778212408,
                            172.04748772263684
                        ],
                        [
                            423.9005065681006,
                            170.88822821660284
                        ],
                        [
                            422.61897430094564,
                            169.455192030766
                        ],
                        [
                            419.8085965220971,
                            169.1365875245927
                        ],
                        [
                            418.3696830993265,
                            170.25146128475848
                        ],
                        [
                            415.4019241648623,
                            170.3196968678941
                        ],
                        [
                            412.2093350080904,
                            171.50204474679418
                        ],
                        [
                            411.22008202993584,
                            172.20654485581207
                        ],
                        [
                            410.09593091839656,
                            174.34112878041174
                        ],
                        [
                            407.9825268287025,
                            174.93111111578168
                        ],
                        [
                            407.15065500616333,
                            176.9039204386164
                        ],
                        [
                            406.25133411693173,
                            176.76793179930314
                        ],
                        [
                            405.1271830053922,
                            178.2632613894408
                        ],
                        [
                            403.4859223825447,
                            178.19531785965967
                        ],
                        [
                            402.47418638215913,
                            180.16467860276208
                        ],
                        [
                            400.6530615814654,
                            180.93375156843007
                        ],
                        [
                            399.64132558107985,
                            180.4135308237888
                        ],
                        [
                            399.1691821142333,
                            177.71964389532712
                        ],
                        [
                            399.5738765143874,
                            176.45058633386583
                        ],
                        [
                            399.0792500253099,
                            174.6134516566417
                        ],
                        [
                            400.0010539367722,
                            174.68152612312417
                        ],
                        [
                            401.61983153738925,
                            173.36497607844342
                        ],
                        [
                            401.7322466485432,
                            173.59203429960752
                        ],
                        [
                            402.78894869339,
                            173.00162489949003
                        ],
                        [
                            403.3060582046983,
                            171.18379506517118
                        ],
                        [
                            404.04799793831444,
                            170.82001373739962
                        ],
                        [
                            405.50939438331557,
                            171.36565876284556
                        ],
                        [
                            407.1056889617016,
                            170.7745360121379
                        ],
                        [
                            408.79191562901065,
                            170.70631731515624
                        ],
                        [
                            410.2982781184735,
                            170.04673932318514
                        ],
                        [
                            411.13014994101286,
                            169.09106807400508
                        ],
                        [
                            410.0284818517041,
                            167.95271389129755
                        ],
                        [
                            409.1741270069342,
                            167.97548791718424
                        ],
                        [
                            409.0392288735493,
                            166.1754641972754
                        ],
                        [
                            407.66776451747137,
                            164.8984202562649
                        ],
                        [
                            407.57783242854816,
                            163.0496773077897
                        ],
                        [
                            410.45565927408893,
                            159.27786157224932
                        ],
                        [
                            408.2298400732409,
                            159.07189880268027
                        ],
                        [
                            405.1046999831615,
                            159.3465105819941
                        ],
                        [
                            403.82316771600654,
                            160.53601055146703
                        ],
                        [
                            403.7557186493141,
                            160.60461157100235
                        ],
                        [
                            401.77721269300446,
                            161.29047818221875
                        ],
                        [
                            400.7429936703884,
                            160.81039895812654
                        ],
                        [
                            398.20241215830924,
                            161.67902018260446
                        ],
                        [
                            394.0205700233828,
                            163.14101752858807
                        ],
                        [
                            388.28739935453154,
                            165.99308420472312
                        ],
                        [
                            386.84848593176116,
                            165.6738752539506
                        ],
                        [
                            384.9823950866057,
                            164.7159121689955
                        ],
                        [
                            384.6226667309131,
                            165.14933894821905
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Assam",
        "properties": {
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            401.61983153738925,
                            173.36497607844342
                        ],
                        [
                            400.0010539367722,
                            174.68152612312417
                        ],
                        [
                            399.0792500253099,
                            174.6134516566417
                        ],
                        [
                            397.685302647001,
                            175.81573292732423
                        ],
                        [
                            395.9316269129997,
                            176.60926583074888
                        ],
                        [
                            395.0772720682296,
                            176.01981659475757
                        ],
                        [
                            394.2229172234597,
                            176.67726718769262
                        ],
                        [
                            393.12124913415096,
                            178.53501079560965
                        ],
                        [
                            392.31186033384256,
                            179.30475296972304
                        ],
                        [
                            390.1085241552255,
                            179.71213537195842
                        ],
                        [
                            387.4105614875309,
                            181.5894767651336
                        ],
                        [
                            387.38807846529994,
                            182.8550583541218
                        ],
                        [
                            386.1065461981452,
                            184.052059043273
                        ],
                        [
                            385.67936877576017,
                            182.4031625484456
                        ],
                        [
                            384.19548930852807,
                            184.14236794298233
                        ],
                        [
                            383.90321001952816,
                            185.85741899107563
                        ],
                        [
                            383.0263721525273,
                            186.37614199197338
                        ],
                        [
                            381.4975266408335,
                            188.78747392112888
                        ],
                        [
                            380.8230359739098,
                            192.11776763738686
                        ],
                        [
                            381.1827643296026,
                            192.97191204042917
                        ],
                        [
                            379.743850906832,
                            194.16258708031688
                        ],
                        [
                            378.2150053951384,
                            194.07275042752798
                        ],
                        [
                            378.19252237290766,
                            192.11776763738686
                        ],
                        [
                            376.79857499459854,
                            193.1741539432444
                        ],
                        [
                            377.1133373058299,
                            194.02783053469386
                        ],
                        [
                            374.77510299382766,
                            196.00331988104625
                        ],
                        [
                            374.3254425492121,
                            196.92303141147397
                        ],
                        [
                            373.0663933042879,
                            197.61813429847217
                        ],
                        [
                            371.6274798815175,
                            199.074803500421
                        ],
                        [
                            373.49357072667294,
                            200.84375624283257
                        ],
                        [
                            373.69591792674987,
                            202.9241523551957
                        ],
                        [
                            372.3919026373644,
                            203.99704587168753
                        ],
                        [
                            371.8523101038252,
                            205.8955340300563
                        ],
                        [
                            370.4133966810548,
                            208.34970873749205
                        ],
                        [
                            370.43587970328554,
                            209.28596062335555
                        ],
                        [
                            369.356694636208,
                            211.17941687564286
                        ],
                        [
                            368.3899246802839,
                            210.95675009354903
                        ],
                        [
                            368.05267934682206,
                            212.60390057724038
                        ],
                        [
                            368.0751623690528,
                            214.82764376546936
                        ],
                        [
                            367.5130868132833,
                            215.27209921642333
                        ],
                        [
                            366.92852823528256,
                            217.40413226614857
                        ],
                        [
                            365.2647845902043,
                            217.75925407821552
                        ],
                        [
                            363.84835418966463,
                            217.29314401206744
                        ],
                        [
                            363.2862786338949,
                            215.7831025955789
                        ],
                        [
                            362.2295765890476,
                            218.358382012636
                        ],
                        [
                            361.28528965535475,
                            218.71333787437902
                        ],
                        [
                            361.0829424552776,
                            219.93302918637352
                        ],
                        [
                            360.1836215660462,
                            220.06604250396128
                        ],
                        [
                            359.9138252992768,
                            221.196307865403
                        ],
                        [
                            358.49739489873696,
                            221.572924919763
                        ],
                        [
                            358.2950476986598,
                            220.02170568994188
                        ],
                        [
                            356.42895685350436,
                            220.11037835966232
                        ],
                        [
                            355.3048057419651,
                            219.44523983538272
                        ],
                        [
                            355.9118473421963,
                            218.26963341153646
                        ],
                        [
                            355.23735667527285,
                            215.84974572626857
                        ],
                        [
                            355.82191525327335,
                            213.11557837840127
                        ],
                        [
                            356.42895685350436,
                            212.15885773427692
                        ],
                        [
                            355.52963596427276,
                            209.53109699146876
                        ],
                        [
                            356.47392289796585,
                            209.2636740090482
                        ],
                        [
                            357.755455165121,
                            210.3108766766228
                        ],
                        [
                            359.6665120547382,
                            209.84304530415403
                        ],
                        [
                            358.3400137431215,
                            207.41301734077405
                        ],
                        [
                            359.1494025434299,
                            206.14108786558035
                        ],
                        [
                            360.02624041043055,
                            206.2973335838446
                        ],
                        [
                            361.2178405886625,
                            206.0294762520095
                        ],
                        [
                            361.8473652111245,
                            205.02468899318632
                        ],
                        [
                            363.0164823671255,
                            204.53327411505444
                        ],
                        [
                            363.7359390785107,
                            203.30420220743213
                        ],
                        [
                            361.68998405550906,
                            200.84375624283257
                        ],
                        [
                            360.4534178328156,
                            200.328909567128
                        ],
                        [
                            361.8023991666628,
                            198.60430622092792
                        ],
                        [
                            360.6782480551235,
                            198.94038727316092
                        ],
                        [
                            359.5091308991225,
                            197.86472406080117
                        ],
                        [
                            358.6098100098909,
                            196.18280999678103
                        ],
                        [
                            355.3947378308883,
                            196.25011451114244
                        ],
                        [
                            354.3155527638105,
                            196.96788425474108
                        ],
                        [
                            354.3155527638105,
                            194.6117077261772
                        ],
                        [
                            355.05749249742644,
                            193.12921313159035
                        ],
                        [
                            354.49541694165646,
                            192.09528503420222
                        ],
                        [
                            356.1141945422735,
                            190.54334979920532
                        ],
                        [
                            354.6303150750414,
                            190.3633340621115
                        ],
                        [
                            354.0457564970409,
                            190.88083375999736
                        ],
                        [
                            350.1337106288838,
                            190.9258271355319
                        ],
                        [
                            349.52666902865235,
                            189.66561326245682
                        ],
                        [
                            348.02030653918973,
                            191.03830595898228
                        ],
                        [
                            347.997823516959,
                            192.16273205554762
                        ],
                        [
                            345.9069024494954,
                            191.0608009326163
                        ],
                        [
                            343.928396493186,
                            194.2299618292372
                        ],
                        [
                            342.30961889256923,
                            194.20750384057538
                        ],
                        [
                            340.64587524749095,
                            196.1379390240507
                        ],
                        [
                            339.5442071581822,
                            194.0502906116634
                        ],
                        [
                            337.4982521351808,
                            194.47698248579837
                        ],
                        [
                            337.76804840194995,
                            193.46624369574357
                        ],
                        [
                            336.62141426817993,
                            192.45497516672435
                        ],
                        [
                            333.2264779113307,
                            192.34257922153648
                        ],
                        [
                            331.9674286664067,
                            192.92696651364815
                        ],
                        [
                            329.06711879863497,
                            191.75801444433387
                        ],
                        [
                            325.42486919724706,
                            192.5224095842063
                        ],
                        [
                            324.4805822635542,
                            192.27513850395394
                        ],
                        [
                            323.55877835209185,
                            193.01685651913726
                        ],
                        [
                            321.1980610178591,
                            196.07063061984258
                        ],
                        [
                            322.97421977409135,
                            198.02162857008324
                        ],
                        [
                            320.883298706628,
                            198.96279062005817
                        ],
                        [
                            320.7933666177048,
                            200.14980075221837
                        ],
                        [
                            320.50108732870444,
                            200.328909567128
                        ],
                        [
                            320.95074777332024,
                            197.86472406080117
                        ],
                        [
                            320.1413589730121,
                            195.98088244922798
                        ],
                        [
                            320.36618919532,
                            193.53364274333688
                        ],
                        [
                            321.0181968400127,
                            192.49993170747035
                        ],
                        [
                            318.70244555024146,
                            188.80999535560653
                        ],
                        [
                            318.27526812785663,
                            188.0441174790722
                        ],
                        [
                            319.4668683060884,
                            185.83486260179404
                        ],
                        [
                            320.36618919532,
                            185.33855417263595
                        ],
                        [
                            320.253774084166,
                            184.81954730098056
                        ],
                        [
                            320.77088359547406,
                            181.54426168082517
                        ],
                        [
                            320.50108732870444,
                            180.16467860276208
                        ],
                        [
                            320.6135024398584,
                            179.55371941397095
                        ],
                        [
                            323.4013971964764,
                            179.62161360815935
                        ],
                        [
                            325.537284308401,
                            178.91992148674427
                        ],
                        [
                            326.07687684194,
                            177.5157464942671
                        ],
                        [
                            327.6282053758646,
                            177.44777573925705
                        ],
                        [
                            328.8198055540963,
                            176.74526606037483
                        ],
                        [
                            330.70837942148273,
                            178.19531785965967
                        ],
                        [
                            333.2264779113307,
                            178.98783867777342
                        ],
                        [
                            336.6663803126414,
                            178.85200182902577
                        ],
                        [
                            338.17274280210427,
                            178.7840797040859
                        ],
                        [
                            338.8247504467972,
                            178.10472264063327
                        ],
                        [
                            340.8707054697986,
                            178.28590868338034
                        ],
                        [
                            342.3321019148002,
                            178.80672068656747
                        ],
                        [
                            343.38880395964725,
                            177.81025780847506
                        ],
                        [
                            344.67033622680196,
                            178.62558514816146
                        ],
                        [
                            346.5813931164191,
                            178.1273718575187
                        ],
                        [
                            347.72802725018914,
                            178.2632613894408
                        ],
                        [
                            350.51592200680716,
                            176.51859348949495
                        ],
                        [
                            351.8424203184236,
                            177.5157464942671
                        ],
                        [
                            352.9216053855016,
                            177.71964389532712
                        ],
                        [
                            353.75347720804075,
                            176.949247778193
                        ],
                        [
                            354.9450773862727,
                            176.99457401460182
                        ],
                        [
                            357.37324378719745,
                            176.4052468490122
                        ],
                        [
                            358.6547760543526,
                            175.86108679037966
                        ],
                        [
                            360.3634857438924,
                            175.88376330678574
                        ],
                        [
                            361.7349500999708,
                            175.04454773594563
                        ],
                        [
                            361.9822633445092,
                            174.65883491209587
                        ],
                        [
                            365.1298864568196,
                            175.18066252433488
                        ],
                        [
                            367.10839241312897,
                            176.60926583074888
                        ],
                        [
                            367.69295099112946,
                            176.4732556619427
                        ],
                        [
                            372.63921588190306,
                            175.88376330678574
                        ],
                        [
                            373.7184009489806,
                            176.22387785735907
                        ],
                        [
                            376.57374477229087,
                            175.7023434256688
                        ],
                        [
                            377.04588823913764,
                            175.2714001696047
                        ],
                        [
                            378.9794281509853,
                            174.06876586194176
                        ],
                        [
                            378.64218281752323,
                            172.72906460643605
                        ],
                        [
                            381.3626285074488,
                            169.7509895151403
                        ],
                        [
                            384.71259881983633,
                            166.83643869547728
                        ],
                        [
                            385.40957250899055,
                            166.5857524416062
                        ],
                        [
                            384.6226667309131,
                            165.14933894821905
                        ],
                        [
                            384.9823950866057,
                            164.7159121689955
                        ],
                        [
                            386.84848593176116,
                            165.6738752539506
                        ],
                        [
                            388.28739935453154,
                            165.99308420472312
                        ],
                        [
                            394.0205700233828,
                            163.14101752858807
                        ],
                        [
                            398.20241215830924,
                            161.67902018260446
                        ],
                        [
                            400.7429936703884,
                            160.81039895812654
                        ],
                        [
                            401.77721269300446,
                            161.29047818221875
                        ],
                        [
                            403.7557186493141,
                            160.60461157100235
                        ],
                        [
                            403.82316771600654,
                            160.53601055146703
                        ],
                        [
                            405.1046999831615,
                            159.3465105819941
                        ],
                        [
                            408.2298400732409,
                            159.07189880268027
                        ],
                        [
                            410.45565927408893,
                            159.27786157224932
                        ],
                        [
                            407.57783242854816,
                            163.0496773077897
                        ],
                        [
                            407.66776451747137,
                            164.8984202562649
                        ],
                        [
                            409.0392288735493,
                            166.1754641972754
                        ],
                        [
                            409.1741270069342,
                            167.97548791718424
                        ],
                        [
                            410.0284818517041,
                            167.95271389129755
                        ],
                        [
                            411.13014994101286,
                            169.09106807400508
                        ],
                        [
                            410.2982781184735,
                            170.04673932318514
                        ],
                        [
                            408.79191562901065,
                            170.70631731515624
                        ],
                        [
                            407.1056889617016,
                            170.7745360121379
                        ],
                        [
                            405.50939438331557,
                            171.36565876284556
                        ],
                        [
                            404.04799793831444,
                            170.82001373739962
                        ],
                        [
                            403.3060582046983,
                            171.18379506517118
                        ],
                        [
                            402.78894869339,
                            173.00162489949003
                        ],
                        [
                            401.7322466485432,
                            173.59203429960752
                        ],
                        [
                            401.61983153738925,
                            173.36497607844342
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Nagaland",
        "properties": {
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            396.40377037984626,
                            193.39884229375588
                        ],
                        [
                            396.7859817577696,
                            195.82381316015983
                        ],
                        [
                            395.257136246076,
                            197.03516157986718
                        ],
                        [
                            394.47023046799836,
                            198.357809996968
                        ],
                        [
                            393.36856237868983,
                            199.88110681022363
                        ],
                        [
                            391.4125394446112,
                            200.6646944733265
                        ],
                        [
                            389.63638068837895,
                            199.70195702180837
                        ],
                        [
                            389.59141464391746,
                            198.4698575957417
                        ],
                        [
                            388.6696107324551,
                            198.0664561097849
                        ],
                        [
                            387.61290868760807,
                            199.1868099589674
                        ],
                        [
                            386.2189613092992,
                            199.7243516423776
                        ],
                        [
                            384.55521766422066,
                            199.8363209006764
                        ],
                        [
                            384.2854213974513,
                            198.9851937099886
                        ],
                        [
                            383.0713381969888,
                            199.4108036200027
                        ],
                        [
                            381.5874587297567,
                            198.6715270601573
                        ],
                        [
                            380.5532397071404,
                            198.80596179442637
                        ],
                        [
                            378.0351412172922,
                            199.14200814601628
                        ],
                        [
                            378.6196997952925,
                            200.3736842122609
                        ],
                        [
                            376.5962277945216,
                            202.85707712717016
                        ],
                        [
                            375.69690690529,
                            204.66730845741046
                        ],
                        [
                            374.16806139359664,
                            203.95235361647195
                        ],
                        [
                            373.69591792674987,
                            202.9241523551957
                        ],
                        [
                            373.49357072667294,
                            200.84375624283257
                        ],
                        [
                            371.6274798815175,
                            199.074803500421
                        ],
                        [
                            373.0663933042879,
                            197.61813429847217
                        ],
                        [
                            374.3254425492121,
                            196.92303141147397
                        ],
                        [
                            374.77510299382766,
                            196.00331988104625
                        ],
                        [
                            377.1133373058299,
                            194.02783053469386
                        ],
                        [
                            376.79857499459854,
                            193.1741539432444
                        ],
                        [
                            378.19252237290766,
                            192.11776763738686
                        ],
                        [
                            378.2150053951384,
                            194.07275042752798
                        ],
                        [
                            379.743850906832,
                            194.16258708031688
                        ],
                        [
                            381.1827643296026,
                            192.97191204042917
                        ],
                        [
                            380.8230359739098,
                            192.11776763738686
                        ],
                        [
                            381.4975266408335,
                            188.78747392112888
                        ],
                        [
                            383.0263721525273,
                            186.37614199197338
                        ],
                        [
                            383.90321001952816,
                            185.85741899107563
                        ],
                        [
                            384.19548930852807,
                            184.14236794298233
                        ],
                        [
                            385.67936877576017,
                            182.4031625484456
                        ],
                        [
                            386.1065461981452,
                            184.052059043273
                        ],
                        [
                            387.38807846529994,
                            182.8550583541218
                        ],
                        [
                            387.4105614875309,
                            181.5894767651336
                        ],
                        [
                            390.1085241552255,
                            179.71213537195842
                        ],
                        [
                            392.31186033384256,
                            179.30475296972304
                        ],
                        [
                            393.12124913415096,
                            178.53501079560965
                        ],
                        [
                            394.2229172234597,
                            176.67726718769262
                        ],
                        [
                            395.0772720682296,
                            176.01981659475757
                        ],
                        [
                            395.9316269129997,
                            176.60926583074888
                        ],
                        [
                            397.685302647001,
                            175.81573292732423
                        ],
                        [
                            399.0792500253099,
                            174.6134516566417
                        ],
                        [
                            399.5738765143874,
                            176.45058633386583
                        ],
                        [
                            399.1691821142333,
                            177.71964389532712
                        ],
                        [
                            399.64132558107985,
                            180.4135308237888
                        ],
                        [
                            397.1007440690007,
                            184.2326725297292
                        ],
                        [
                            398.0000649582323,
                            185.90253096540033
                        ],
                        [
                            397.9101328693091,
                            189.8456945843066
                        ],
                        [
                            398.92186886969466,
                            190.40833958140684
                        ],
                        [
                            398.0450310026938,
                            191.8254732951113
                        ],
                        [
                            396.40377037984626,
                            193.39884229375588
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Meghalaya",
        "properties": {
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            355.0125264529647,
                            205.87320944735495
                        ],
                        [
                            353.281333741194,
                            204.91301450679902
                        ],
                        [
                            352.3370468075009,
                            204.82367038178552
                        ],
                        [
                            348.35755187265136,
                            205.11402404783905
                        ],
                        [
                            346.82870636095777,
                            205.87320944735495
                        ],
                        [
                            346.2441477829575,
                            205.06935702425443
                        ],
                        [
                            344.6253701823405,
                            205.71693033369343
                        ],
                        [
                            342.9616265372622,
                            205.02468899318632
                        ],
                        [
                            342.6018981815696,
                            204.10877208671786
                        ],
                        [
                            341.09553569210675,
                            204.68964663164456
                        ],
                        [
                            338.57743720225835,
                            204.62263135230944
                        ],
                        [
                            336.9361765794108,
                            205.0916906619729
                        ],
                        [
                            335.2499499121018,
                            205.3150131903286
                        ],
                        [
                            334.148281822793,
                            204.91301450679902
                        ],
                        [
                            332.8667495556381,
                            205.3150131903286
                        ],
                        [
                            330.19126991017447,
                            205.15869006408747
                        ],
                        [
                            329.2244999542504,
                            205.5606389046763
                        ],
                        [
                            327.5607563091721,
                            204.77899680690325
                        ],
                        [
                            326.12184288640174,
                            204.60029242140507
                        ],
                        [
                            324.32320110793876,
                            204.26517817618077
                        ],
                        [
                            322.29972910716765,
                            203.03593932905312
                        ],
                        [
                            320.253774084166,
                            203.0806523435325
                        ],
                        [
                            319.96149479516566,
                            201.58221337525544
                        ],
                        [
                            320.7933666177048,
                            200.14980075221837
                        ],
                        [
                            320.883298706628,
                            198.96279062005817
                        ],
                        [
                            322.97421977409135,
                            198.02162857008324
                        ],
                        [
                            321.1980610178591,
                            196.07063061984258
                        ],
                        [
                            323.55877835209185,
                            193.01685651913726
                        ],
                        [
                            324.4805822635542,
                            192.27513850395394
                        ],
                        [
                            325.42486919724706,
                            192.5224095842063
                        ],
                        [
                            329.06711879863497,
                            191.75801444433387
                        ],
                        [
                            331.9674286664067,
                            192.92696651364815
                        ],
                        [
                            333.2264779113307,
                            192.34257922153648
                        ],
                        [
                            336.62141426817993,
                            192.45497516672435
                        ],
                        [
                            337.76804840194995,
                            193.46624369574357
                        ],
                        [
                            337.4982521351808,
                            194.47698248579837
                        ],
                        [
                            339.5442071581822,
                            194.0502906116634
                        ],
                        [
                            340.64587524749095,
                            196.1379390240507
                        ],
                        [
                            342.30961889256923,
                            194.20750384057538
                        ],
                        [
                            343.928396493186,
                            194.2299618292372
                        ],
                        [
                            345.9069024494954,
                            191.0608009326163
                        ],
                        [
                            347.997823516959,
                            192.16273205554762
                        ],
                        [
                            348.02030653918973,
                            191.03830595898228
                        ],
                        [
                            349.52666902865235,
                            189.66561326245682
                        ],
                        [
                            350.1337106288838,
                            190.9258271355319
                        ],
                        [
                            354.0457564970409,
                            190.88083375999736
                        ],
                        [
                            354.6303150750414,
                            190.3633340621115
                        ],
                        [
                            356.1141945422735,
                            190.54334979920532
                        ],
                        [
                            354.49541694165646,
                            192.09528503420222
                        ],
                        [
                            355.05749249742644,
                            193.12921313159035
                        ],
                        [
                            354.3155527638105,
                            194.6117077261772
                        ],
                        [
                            354.3155527638105,
                            196.96788425474108
                        ],
                        [
                            355.3947378308883,
                            196.25011451114244
                        ],
                        [
                            358.6098100098909,
                            196.18280999678103
                        ],
                        [
                            359.5091308991225,
                            197.86472406080117
                        ],
                        [
                            360.6782480551235,
                            198.94038727316092
                        ],
                        [
                            361.8023991666628,
                            198.60430622092792
                        ],
                        [
                            360.4534178328156,
                            200.328909567128
                        ],
                        [
                            361.68998405550906,
                            200.84375624283257
                        ],
                        [
                            363.7359390785107,
                            203.30420220743213
                        ],
                        [
                            363.0164823671255,
                            204.53327411505444
                        ],
                        [
                            361.8473652111245,
                            205.02468899318632
                        ],
                        [
                            361.2178405886625,
                            206.0294762520095
                        ],
                        [
                            360.02624041043055,
                            206.2973335838446
                        ],
                        [
                            359.1494025434299,
                            206.14108786558035
                        ],
                        [
                            358.3400137431215,
                            207.41301734077405
                        ],
                        [
                            355.0125264529647,
                            205.87320944735495
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Manipur",
        "properties": {
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            391.0977771333803,
                            206.0294762520095
                        ],
                        [
                            392.31186033384256,
                            206.0517990767994
                        ],
                        [
                            392.42427544499674,
                            207.30147712021056
                        ],
                        [
                            391.5924036224576,
                            209.68707722511004
                        ],
                        [
                            390.7830148221492,
                            210.51134240851565
                        ],
                        [
                            390.7380487776877,
                            211.84726919975043
                        ],
                        [
                            389.50148255499425,
                            212.5816507700439
                        ],
                        [
                            389.3441013993786,
                            213.69384055756348
                        ],
                        [
                            387.0058670873768,
                            216.09408516454317
                        ],
                        [
                            387.47801055422315,
                            217.00454625183642
                        ],
                        [
                            385.7693008646834,
                            220.30987785497337
                        ],
                        [
                            385.2072253089136,
                            222.21523036709993
                        ],
                        [
                            384.9823950866057,
                            223.47711025208213
                        ],
                        [
                            383.99314210845114,
                            225.20264701390266
                        ],
                        [
                            383.790794908374,
                            226.5069087311166
                        ],
                        [
                            381.74483988537213,
                            225.22475996218492
                        ],
                        [
                            379.9237150846784,
                            224.80457366734862
                        ],
                        [
                            378.6646658397542,
                            225.24687267504459
                        ],
                        [
                            377.6754128615994,
                            223.96394562796934
                        ],
                        [
                            375.9667031720596,
                            223.87543862230092
                        ],
                        [
                            374.21302743805813,
                            224.98150457894116
                        ],
                        [
                            372.90901214867245,
                            222.9016115585212
                        ],
                        [
                            372.3469365929027,
                            222.63594269368355
                        ],
                        [
                            370.503328769978,
                            223.7205422561999
                        ],
                        [
                            369.8288381030543,
                            223.30005079705575
                        ],
                        [
                            366.43390174620527,
                            222.19308523570663
                        ],
                        [
                            367.1308754353597,
                            220.28771220215162
                        ],
                        [
                            366.92852823528256,
                            217.40413226614857
                        ],
                        [
                            367.5130868132833,
                            215.27209921642333
                        ],
                        [
                            368.0751623690528,
                            214.82764376546936
                        ],
                        [
                            368.05267934682206,
                            212.60390057724038
                        ],
                        [
                            368.3899246802839,
                            210.95675009354903
                        ],
                        [
                            369.356694636208,
                            211.17941687564286
                        ],
                        [
                            370.43587970328554,
                            209.28596062335555
                        ],
                        [
                            370.4133966810548,
                            208.34970873749205
                        ],
                        [
                            371.8523101038252,
                            205.8955340300563
                        ],
                        [
                            372.3919026373644,
                            203.99704587168753
                        ],
                        [
                            373.69591792674987,
                            202.9241523551957
                        ],
                        [
                            374.16806139359664,
                            203.95235361647195
                        ],
                        [
                            375.69690690529,
                            204.66730845741046
                        ],
                        [
                            376.5962277945216,
                            202.85707712717016
                        ],
                        [
                            378.6196997952925,
                            200.3736842122609
                        ],
                        [
                            378.0351412172922,
                            199.14200814601628
                        ],
                        [
                            380.5532397071404,
                            198.80596179442637
                        ],
                        [
                            381.5874587297567,
                            198.6715270601573
                        ],
                        [
                            383.0713381969888,
                            199.4108036200027
                        ],
                        [
                            384.2854213974513,
                            198.9851937099886
                        ],
                        [
                            384.55521766422066,
                            199.8363209006764
                        ],
                        [
                            386.2189613092992,
                            199.7243516423776
                        ],
                        [
                            387.61290868760807,
                            199.1868099589674
                        ],
                        [
                            388.6696107324551,
                            198.0664561097849
                        ],
                        [
                            389.59141464391746,
                            198.4698575957417
                        ],
                        [
                            389.63638068837895,
                            199.70195702180837
                        ],
                        [
                            391.4125394446112,
                            200.6646944733265
                        ],
                        [
                            389.95114299960983,
                            204.42157189123185
                        ],
                        [
                            391.0977771333803,
                            206.0294762520095
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Tripura",
        "properties": {
            "st_nm": "Tripura",
            "st_code": "16",
            "alpha2code": "TR"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            348.53741605049777,
                            235.83412491994062
                        ],
                        [
                            349.3692878730369,
                            237.76939141161546
                        ],
                        [
                            349.2343897396522,
                            239.08788953404837
                        ],
                        [
                            347.975340494728,
                            240.29580298000235
                        ],
                        [
                            345.8619364050337,
                            240.64706892019518
                        ],
                        [
                            345.36730991595664,
                            240.09819075193013
                        ],
                        [
                            344.8502004046484,
                            237.1538147485146
                        ],
                        [
                            344.06329462657095,
                            235.92212962254501
                        ],
                        [
                            342.08478867026133,
                            235.6581046184242
                        ],
                        [
                            341.14050173656824,
                            232.3109469365628
                        ],
                        [
                            339.7465543582596,
                            230.56921311091634
                        ],
                        [
                            339.6791052915671,
                            228.20785406588323
                        ],
                        [
                            340.7133243141834,
                            228.36241682668714
                        ],
                        [
                            341.0280866254143,
                            226.7941786076969
                        ],
                        [
                            340.60090920302946,
                            225.99856496253483
                        ],
                        [
                            341.79250938126097,
                            224.09669904377125
                        ],
                        [
                            342.871694448339,
                            224.03032339958867
                        ],
                        [
                            344.28812484887885,
                            223.05656928123642
                        ],
                        [
                            346.6488421831116,
                            222.3923828573836
                        ],
                        [
                            347.0310535610349,
                            221.06336773574913
                        ],
                        [
                            348.4924500060363,
                            221.77228252524048
                        ],
                        [
                            348.35755187265136,
                            220.11037835966232
                        ],
                        [
                            350.02129551772964,
                            221.10768206720206
                        ],
                        [
                            350.96558245142296,
                            222.30380851441726
                        ],
                        [
                            351.23537871819235,
                            219.82217816381473
                        ],
                        [
                            350.943099429192,
                            218.60242078962796
                        ],
                        [
                            351.6625561405774,
                            217.89240881806478
                        ],
                        [
                            353.12395258557876,
                            217.98117381755134
                        ],
                        [
                            354.1356885859641,
                            217.20434905598972
                        ],
                        [
                            354.38300183050274,
                            215.58316007640576
                        ],
                        [
                            355.23735667527285,
                            215.84974572626857
                        ],
                        [
                            355.9118473421963,
                            218.26963341153646
                        ],
                        [
                            355.3048057419651,
                            219.44523983538272
                        ],
                        [
                            356.42895685350436,
                            220.11037835966232
                        ],
                        [
                            356.9460663648126,
                            221.10768206720206
                        ],
                        [
                            356.67627009804323,
                            223.2115153828487
                        ],
                        [
                            356.8561342758894,
                            225.44587649969725
                        ],
                        [
                            355.86688129773506,
                            227.0593156758572
                        ],
                        [
                            355.9792964088888,
                            228.45073327985256
                        ],
                        [
                            354.67528111950287,
                            228.75981153538788
                        ],
                        [
                            353.84340929696396,
                            228.36241682668714
                        ],
                        [
                            352.9665714299631,
                            229.7970982469957
                        ],
                        [
                            351.1679296515001,
                            229.04677195135702
                        ],
                        [
                            351.7075221850389,
                            232.3990977766784
                        ],
                        [
                            351.23537871819235,
                            233.25837603553828
                        ],
                        [
                            350.1112276066531,
                            232.94995730561402
                        ],
                        [
                            349.21190671742147,
                            231.8480946854454
                        ],
                        [
                            349.70653320649876,
                            234.16134427435645
                        ],
                        [
                            348.53741605049777,
                            235.83412491994062
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Andaman and Nicobar Islands",
        "properties": {
            "st_nm": "Andaman and Nicobar Islands",
            "st_code": "35",
            "alpha2code": "AN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            364.8151241455887,
                            406.4439471430881
                        ],
                        [
                            364.65774298997303,
                            407.5813071478491
                        ],
                        [
                            363.39869374504883,
                            407.2297941381608
                        ],
                        [
                            362.971516322664,
                            406.2784858938817
                        ],
                        [
                            364.0057353452803,
                            405.2648850686768
                        ],
                        [
                            364.3204976565112,
                            405.4510761072603
                        ],
                        [
                            364.9050562345119,
                            405.4303886472072
                        ],
                        [
                            364.8151241455887,
                            406.4439471430881
                        ]
                    ]
                ],
                [
                    [
                        [
                            364.92753925674265,
                            404.9959269601941
                        ],
                        [
                            364.29801463428043,
                            405.4303886472072
                        ],
                        [
                            363.98325232304956,
                            405.2235080907593
                        ],
                        [
                            363.53359187843375,
                            405.2648850686768
                        ],
                        [
                            362.6567540114331,
                            405.4717634590764
                        ],
                        [
                            362.36447472243253,
                            405.0373063237787
                        ],
                        [
                            362.5668219225099,
                            401.7876977079968
                        ],
                        [
                            363.03896538935624,
                            399.0948788424058
                        ],
                        [
                            362.81413516704833,
                            397.3953576450434
                        ],
                        [
                            363.7584221007414,
                            397.0014576673865
                        ],
                        [
                            363.6684900118182,
                            394.61644778743164
                        ],
                        [
                            364.1631165008955,
                            393.22621978754245
                        ],
                        [
                            364.4553957898961,
                            389.0108412261104
                        ],
                        [
                            364.88257321228093,
                            387.7847695395011
                        ],
                        [
                            365.8043771237433,
                            387.3898444964634
                        ],
                        [
                            366.6137659240517,
                            386.24639645379756
                        ],
                        [
                            367.4231547243601,
                            386.93250886473515
                        ],
                        [
                            367.24329054651366,
                            389.71720319155605
                        ],
                        [
                            367.73791703559095,
                            390.7972572974195
                        ],
                        [
                            367.24329054651366,
                            392.2299156444393
                        ],
                        [
                            367.2882565909754,
                            393.68276978484676
                        ],
                        [
                            366.1865885016666,
                            394.67868468674453
                        ],
                        [
                            365.4896148125122,
                            394.0977666392871
                        ],
                        [
                            364.7027090344345,
                            396.4001628252291
                        ],
                        [
                            365.6694789903586,
                            396.669720622516
                        ],
                        [
                            366.3214866350513,
                            398.84621574431543
                        ],
                        [
                            366.1416224572049,
                            400.58651876042904
                        ],
                        [
                            366.4114187239745,
                            401.8084044212527
                        ],
                        [
                            364.92753925674265,
                            404.9959269601941
                        ]
                    ]
                ],
                [
                    [
                        [
                            359.7339611214304,
                            426.5188012105983
                        ],
                        [
                            360.7007310773545,
                            428.16518901288157
                        ],
                        [
                            360.8581122329697,
                            429.74926860482367
                        ],
                        [
                            359.8913422770461,
                            432.15519212630477
                        ],
                        [
                            358.45242885427547,
                            431.70289259345725
                        ],
                        [
                            357.7329721428903,
                            429.91381655291434
                        ],
                        [
                            357.64304005396707,
                            428.2474925455808
                        ],
                        [
                            359.7339611214304,
                            426.5188012105983
                        ]
                    ]
                ],
                [
                    [
                        [
                            361.4876368554319,
                            417.32885333136943
                        ],
                        [
                            362.2295765890476,
                            419.37046570975656
                        ],
                        [
                            360.88059525520043,
                            419.7827938248516
                        ],
                        [
                            360.7007310773545,
                            418.97871713017173
                        ],
                        [
                            361.4876368554319,
                            417.32885333136943
                        ]
                    ]
                ],
                [
                    [
                        [
                            363.1963465449717,
                            407.31250586428405
                        ],
                        [
                            363.1963465449717,
                            407.3538610849056
                        ],
                        [
                            362.8815842337408,
                            408.7183437933055
                        ],
                        [
                            363.57855792289547,
                            409.25574008527565
                        ],
                        [
                            362.4768898335865,
                            410.37164248646695
                        ],
                        [
                            363.71345605627994,
                            411.69379662598817
                        ],
                        [
                            363.17386352274093,
                            414.46067703953213
                        ],
                        [
                            361.8023991666628,
                            415.18306504012276
                        ],
                        [
                            363.1288974782792,
                            415.8434245721072
                        ],
                        [
                            361.86984823335524,
                            417.4113617868014
                        ],
                        [
                            360.1836215660462,
                            414.19232952059235
                        ],
                        [
                            359.9587913437381,
                            411.30132686286674
                        ],
                        [
                            360.92556129966215,
                            412.25145205864067
                        ],
                        [
                            361.24032361089326,
                            408.4496187448165
                        ],
                        [
                            361.8023991666628,
                            406.75416842760245
                        ],
                        [
                            363.1963465449717,
                            407.31250586428405
                        ]
                    ]
                ],
                [
                    [
                        [
                            379.09184326213904,
                            480.97426246712837
                        ],
                        [
                            379.5639867289856,
                            481.5039897153546
                        ],
                        [
                            380.2384773959095,
                            485.29237127867646
                        ],
                        [
                            379.6988848623705,
                            487.5929039587656
                        ],
                        [
                            378.55225072860026,
                            487.99999999999994
                        ],
                        [
                            377.3381675281378,
                            484.6611195168338
                        ],
                        [
                            376.5962277945216,
                            484.19273424316384
                        ],
                        [
                            376.28146548329073,
                            482.6651689510825
                        ],
                        [
                            376.7086429056758,
                            481.70771982956404
                        ],
                        [
                            379.09184326213904,
                            480.97426246712837
                        ]
                    ]
                ],
                [
                    [
                        [
                            377.0908542835991,
                            477.9377015917818
                        ],
                        [
                            377.7203789060609,
                            478.9568379575161
                        ],
                        [
                            377.2707184614453,
                            479.91468179481956
                        ],
                        [
                            376.14656734990604,
                            480.91313739450254
                        ],
                        [
                            375.7418729497517,
                            479.91468179481956
                        ],
                        [
                            377.0908542835991,
                            477.9377015917818
                        ]
                    ]
                ],
                [
                    [
                        [
                            363.7359390785107,
                            451.2976954302485
                        ],
                        [
                            364.230565567588,
                            452.7514371275113
                        ],
                        [
                            362.7916521448176,
                            452.95615736724744
                        ],
                        [
                            362.67923703366364,
                            451.5843804694513
                        ],
                        [
                            363.7359390785107,
                            451.2976954302485
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Uttar Pradesh",
        "properties": {
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            157.43172708879615,
                            137.42726538470652
                        ],
                        [
                            159.11795375610518,
                            138.4232645502476
                        ],
                        [
                            159.88237651195232,
                            139.88148258510347
                        ],
                        [
                            160.57935020110654,
                            139.67324098968385
                        ],
                        [
                            161.47867109033814,
                            139.0252179003624
                        ],
                        [
                            163.20986380210888,
                            139.92775509321194
                        ],
                        [
                            163.05248264649322,
                            140.73732394611335
                        ],
                        [
                            164.76119233603322,
                            142.00874057959822
                        ],
                        [
                            166.94204549241954,
                            142.67874944981673
                        ],
                        [
                            167.16687571472767,
                            143.602476591019
                        ],
                        [
                            169.55007607119114,
                            144.4564886087245
                        ],
                        [
                            170.98898949396175,
                            144.3180285641797
                        ],
                        [
                            173.01246149473263,
                            143.76407850423834
                        ],
                        [
                            174.99096745104202,
                            146.53207438155283
                        ],
                        [
                            176.09263554035056,
                            146.09410009431065
                        ],
                        [
                            176.60974505165882,
                            144.61801145150974
                        ],
                        [
                            177.419133851967,
                            144.77951935804305
                        ],
                        [
                            179.73488514173846,
                            146.71645186945568
                        ],
                        [
                            180.27447767527724,
                            148.0986658940107
                        ],
                        [
                            182.41036478720207,
                            148.1677480547832
                        ],
                        [
                            183.78182914328022,
                            147.062107420208
                        ],
                        [
                            185.46805581058948,
                            147.86837241671014
                        ],
                        [
                            186.16502949974392,
                            149.11159916251592
                        ],
                        [
                            191.13377741274803,
                            151.8482121606139
                        ],
                        [
                            193.494494746981,
                            152.62932447486878
                        ],
                        [
                            193.78677403598113,
                            153.7773946067232
                        ],
                        [
                            195.06830630313607,
                            155.31464721716867
                        ],
                        [
                            196.6196348370604,
                            155.75034321557786
                        ],
                        [
                            197.3840575929073,
                            157.21716907341352
                        ],
                        [
                            201.27362043883363,
                            159.39227513153259
                        ],
                        [
                            203.36454150629697,
                            160.9933013548614
                        ],
                        [
                            204.48869261783648,
                            159.87273257371726
                        ],
                        [
                            206.0849871962223,
                            159.87273257371726
                        ],
                        [
                            206.8494099520692,
                            160.83326277274404
                        ],
                        [
                            208.1084591969934,
                            161.1990443739952
                        ],
                        [
                            211.66077670945788,
                            163.9400480571705
                        ],
                        [
                            214.83088284399923,
                            163.20951967031078
                        ],
                        [
                            215.43792444423025,
                            163.52916207809204
                        ],
                        [
                            216.1348981333847,
                            165.46863993108872
                        ],
                        [
                            215.82013582215382,
                            167.13265985879747
                        ],
                        [
                            218.72044568992533,
                            166.9048016941964
                        ],
                        [
                            220.2717742238499,
                            167.77051146933468
                        ],
                        [
                            222.54255946915964,
                            167.6794034463416
                        ],
                        [
                            224.04892195862226,
                            169.6372268965
                        ],
                        [
                            224.81334471446917,
                            169.6372268965
                        ],
                        [
                            225.780114670393,
                            168.33983376473708
                        ],
                        [
                            225.57776747031585,
                            167.2465782738435
                        ],
                        [
                            228.79283964931847,
                            167.42883295405016
                        ],
                        [
                            231.9854288060908,
                            169.06830792468395
                        ],
                        [
                            232.43508925070637,
                            169.6372268965
                        ],
                        [
                            233.10957991763007,
                            170.63809608678525
                        ],
                        [
                            234.36862916255427,
                            172.29742848697998
                        ],
                        [
                            233.94145174016944,
                            173.79636286686815
                        ],
                        [
                            235.2005009850934,
                            174.45460150978084
                        ],
                        [
                            235.35788214070885,
                            177.03989914800133
                        ],
                        [
                            238.28067503071134,
                            178.39914103405306
                        ],
                        [
                            239.31489405332763,
                            180.39090925889997
                        ],
                        [
                            240.61890934271332,
                            181.2503371251491
                        ],
                        [
                            238.59543734194244,
                            181.77032623342234
                        ],
                        [
                            236.12230489655576,
                            181.27294833814375
                        ],
                        [
                            235.22298400732416,
                            182.5613384056578
                        ],
                        [
                            233.22199502878402,
                            183.37460469415583
                        ],
                        [
                            233.10957991763007,
                            184.27782320613
                        ],
                        [
                            236.7743125412485,
                            185.451362948079
                        ],
                        [
                            237.06659183024885,
                            187.27793186024098
                        ],
                        [
                            235.7400935186322,
                            188.02158700415328
                        ],
                        [
                            235.29043307401662,
                            189.9807444587998
                        ],
                        [
                            238.82026756425034,
                            192.49993170747035
                        ],
                        [
                            239.67462240902023,
                            192.27513850395394
                        ],
                        [
                            241.40581512079075,
                            193.53364274333688
                        ],
                        [
                            243.65411734386976,
                            195.0158271675752
                        ],
                        [
                            243.29438898817716,
                            195.98088244922798
                        ],
                        [
                            242.66486436571495,
                            196.83332262062044
                        ],
                        [
                            239.40482614225084,
                            195.80137365201693
                        ],
                        [
                            239.15751289771197,
                            197.03516157986718
                        ],
                        [
                            237.49376925263368,
                            197.01273606343653
                        ],
                        [
                            236.52699929670985,
                            196.00331988104625
                        ],
                        [
                            235.56022934078578,
                            196.5866020947537
                        ],
                        [
                            235.650161429709,
                            197.55087712615182
                        ],
                        [
                            233.44682525109192,
                            198.82836668351786
                        ],
                        [
                            232.2102590283987,
                            200.84375624283257
                        ],
                        [
                            231.2210060502439,
                            201.42559416036562
                        ],
                        [
                            230.65893049447413,
                            201.22420824429963
                        ],
                        [
                            229.30994916062673,
                            202.38748656767723
                        ],
                        [
                            227.66868853777942,
                            202.87943579019833
                        ],
                        [
                            224.90327680339237,
                            204.91301450679902
                        ],
                        [
                            224.92575982562312,
                            207.747600522261
                        ],
                        [
                            225.57776747031585,
                            211.44658443479636
                        ],
                        [
                            226.949231826394,
                            212.18111221241656
                        ],
                        [
                            227.55627342662524,
                            213.67160276611997
                        ],
                        [
                            227.46634133770203,
                            215.18321592207457
                        ],
                        [
                            227.15157902647115,
                            215.58316007640576
                        ],
                        [
                            225.6452165370083,
                            216.02745005059455
                        ],
                        [
                            225.35293724800817,
                            219.0016941412979
                        ],
                        [
                            225.780114670393,
                            219.75566467309886
                        ],
                        [
                            224.610997514392,
                            222.41452584882916
                        ],
                        [
                            222.8123557359288,
                            225.31320940126614
                        ],
                        [
                            221.10364604638903,
                            226.13118834659565
                        ],
                        [
                            218.99024195669494,
                            226.13118834659565
                        ],
                        [
                            217.01173600038555,
                            224.67186558224176
                        ],
                        [
                            214.83088284399923,
                            221.99376834726996
                        ],
                        [
                            216,
                            221.39570195115408
                        ],
                        [
                            216.38221137792334,
                            218.09212463847297
                        ],
                        [
                            215.5503395553842,
                            217.93679180041983
                        ],
                        [
                            216,
                            216.0496619980794
                        ],
                        [
                            216.78690577807788,
                            214.7609670389707
                        ],
                        [
                            216.42717742238506,
                            213.69384055756348
                        ],
                        [
                            215.46040746646122,
                            213.7827892758766
                        ],
                        [
                            214.9208149329222,
                            212.80413778964464
                        ],
                        [
                            212.7624447987664,
                            213.13782229486714
                        ],
                        [
                            211.21111626484208,
                            213.53817087638868
                        ],
                        [
                            211.21111626484208,
                            214.4720091863975
                        ],
                        [
                            209.43495750860984,
                            214.27193727601372
                        ],
                        [
                            208.24335733037788,
                            211.2462120963409
                        ],
                        [
                            206.4447155519149,
                            210.86767646549896
                        ],
                        [
                            204.21889635106686,
                            210.39997503206564
                        ],
                        [
                            203.47695661745092,
                            208.08212753183136
                        ],
                        [
                            202.0155601724498,
                            207.83681321461017
                        ],
                        [
                            200.1719523495251,
                            206.8998805551363
                        ],
                        [
                            199.56491074929363,
                            205.2926820703011
                        ],
                        [
                            198.30586150436966,
                            204.84600679110542
                        ],
                        [
                            197.69881990413842,
                            205.49365309208832
                        ],
                        [
                            196.1250083479831,
                            205.7615827654164
                        ],
                        [
                            194.43878168067408,
                            205.20335507314826
                        ],
                        [
                            194.23643448059693,
                            207.03375508425228
                        ],
                        [
                            192.7075889689031,
                            209.30824698927938
                        ],
                        [
                            192.1230303909026,
                            209.46424459755315
                        ],
                        [
                            190.8639811459784,
                            208.66184146665967
                        ],
                        [
                            189.55996585659295,
                            208.43889449815975
                        ],
                        [
                            188.30091661166875,
                            209.0630832999944
                        ],
                        [
                            187.8737391892837,
                            207.36840200246638
                        ],
                        [
                            188.57071287843837,
                            206.83293991104978
                        ],
                        [
                            188.07608638936085,
                            204.86834294836825
                        ],
                        [
                            186.29992763312862,
                            205.98482984918786
                        ],
                        [
                            186.16502949974392,
                            206.83293991104978
                        ],
                        [
                            184.36638772128072,
                            206.43124870171494
                        ],
                        [
                            183.01740638743354,
                            207.2345499877598
                        ],
                        [
                            182.68016105397146,
                            208.2828168009213
                        ],
                        [
                            181.55600994243218,
                            206.7659970132791
                        ],
                        [
                            180.67917207543155,
                            207.68068838170717
                        ],
                        [
                            179.53253794166108,
                            207.5022450183834
                        ],
                        [
                            179.93723234181562,
                            206.94450639939396
                        ],
                        [
                            180.83655323104722,
                            205.8955340300563
                        ],
                        [
                            181.98318736481724,
                            205.06935702425443
                        ],
                        [
                            181.60097598689367,
                            203.95235361647195
                        ],
                        [
                            180.22951163081575,
                            203.2594942619557
                        ],
                        [
                            180.29696069750798,
                            201.58221337525544
                        ],
                        [
                            178.90301331919932,
                            201.44796909848702
                        ],
                        [
                            177.19430362965932,
                            202.20856545946924
                        ],
                        [
                            176.13760158481227,
                            202.34275781561223
                        ],
                        [
                            173.52957100604067,
                            204.7566596413032
                        ],
                        [
                            173.30474078373277,
                            206.2973335838446
                        ],
                        [
                            171.9557594498856,
                            205.62762245409385
                        ],
                        [
                            170.85409136057683,
                            205.9401824419195
                        ],
                        [
                            169.32524584888324,
                            205.11402404783905
                        ],
                        [
                            168.178611715113,
                            206.60978815135434
                        ],
                        [
                            166.9195624701888,
                            206.16340943527427
                        ],
                        [
                            166.69473224788112,
                            205.136357181871
                        ],
                        [
                            167.3692229148046,
                            203.77357448215753
                        ],
                        [
                            166.13265669211137,
                            202.54402919666137
                        ],
                        [
                            165.0759546472641,
                            202.6334765397201
                        ],
                        [
                            164.82864140272568,
                            203.46067203741575
                        ],
                        [
                            165.05347162503335,
                            203.95235361647195
                        ],
                        [
                            165.25581882511074,
                            204.91301450679902
                        ],
                        [
                            165.43568300295715,
                            204.9800199544856
                        ],
                        [
                            165.7054792697263,
                            205.47132398487923
                        ],
                        [
                            164.55884513595606,
                            206.0517990767994
                        ],
                        [
                            162.87261846864703,
                            206.11876604490465
                        ],
                        [
                            161.88336549049222,
                            204.28752089294233
                        ],
                        [
                            159.9273425564138,
                            204.53327411505444
                        ],
                        [
                            159.18540282279764,
                            205.33734405868904
                        ],
                        [
                            159.05050468941317,
                            204.86834294836825
                        ],
                        [
                            160.3770030010296,
                            202.29802804698835
                        ],
                        [
                            159.18540282279764,
                            201.6269594291178
                        ],
                        [
                            160.10720673426,
                            199.9034993806925
                        ],
                        [
                            159.25285188948988,
                            199.52279082718655
                        ],
                        [
                            157.00454966641132,
                            200.4632304332613
                        ],
                        [
                            157.63407428887353,
                            202.16383264027468
                        ],
                        [
                            155.94784762156405,
                            201.67170446403247
                        ],
                        [
                            153.78947748740848,
                            203.41596763802636
                        ],
                        [
                            154.46396815433218,
                            204.44391284074004
                        ],
                        [
                            155.65556833256414,
                            206.7436822131175
                        ],
                        [
                            155.54315322140997,
                            208.59495999037682
                        ],
                        [
                            156.19516086610292,
                            209.5533806263865
                        ],
                        [
                            157.56662522218107,
                            210.17722171690622
                        ],
                        [
                            157.7240063777965,
                            212.6706485249627
                        ],
                        [
                            157.29682895541146,
                            214.3830907794068
                        ],
                        [
                            159.23036886725913,
                            213.7160781042428
                        ],
                        [
                            160.26458788987543,
                            215.22765805627142
                        ],
                        [
                            159.7025123341059,
                            216.64929284551118
                        ],
                        [
                            160.7367313567222,
                            216.93794096062277
                        ],
                        [
                            160.8266634456454,
                            217.33754003906057
                        ],
                        [
                            160.53438415664505,
                            218.44712675831806
                        ],
                        [
                            158.10621775571985,
                            221.17415177389626
                        ],
                        [
                            156.5773722440265,
                            220.39853807261665
                        ],
                        [
                            154.8686625544865,
                            218.358382012636
                        ],
                        [
                            153.63209633179304,
                            217.75925407821552
                        ],
                        [
                            151.96835268671475,
                            219.82217816381473
                        ],
                        [
                            151.0915148197139,
                            218.77988523697832
                        ],
                        [
                            151.6760733977144,
                            218.0255548696034
                        ],
                        [
                            150.1921939304823,
                            216.80472384052575
                        ],
                        [
                            150.30460904163624,
                            213.3602479754668
                        ],
                        [
                            149.04555979671227,
                            210.4667962001878
                        ],
                        [
                            148.77576352994265,
                            209.909885047366
                        ],
                        [
                            151.33882806425277,
                            207.7029926782419
                        ],
                        [
                            151.1814469086371,
                            206.78831156297593
                        ],
                        [
                            151.20392993086784,
                            206.52052043187786
                        ],
                        [
                            151.74352246440685,
                            206.2973335838446
                        ],
                        [
                            152.82270753148464,
                            205.9178583615714
                        ],
                        [
                            151.9908357089455,
                            204.55561380279602
                        ],
                        [
                            151.3163450420218,
                            202.566391413337
                        ],
                        [
                            151.24889597532956,
                            201.13469676161787
                        ],
                        [
                            151.24889597532956,
                            200.86613781456367
                        ],
                        [
                            151.47372619763723,
                            200.55277256290827
                        ],
                        [
                            152.05828477563773,
                            200.68707808871193
                        ],
                        [
                            152.37304708686884,
                            200.14980075221837
                        ],
                        [
                            152.41801313133033,
                            199.25401075259998
                        ],
                        [
                            152.8901565981771,
                            198.60430622092792
                        ],
                        [
                            155.9253645993333,
                            198.80596179442637
                        ],
                        [
                            156.8022024663344,
                            198.0664561097849
                        ],
                        [
                            158.1736668224121,
                            197.81989188029127
                        ],
                        [
                            157.47669313325787,
                            196.18280999678103
                        ],
                        [
                            159.27533491172085,
                            193.03932836551178
                        ],
                        [
                            160.03975766756753,
                            192.38753838713353
                        ],
                        [
                            160.1971388231832,
                            191.15077819093682
                        ],
                        [
                            161.11894273464554,
                            190.25081563943877
                        ],
                        [
                            160.87162949010667,
                            188.3820426680805
                        ],
                        [
                            162.98503357980098,
                            186.06041443306088
                        ],
                        [
                            162.94006753533927,
                            184.39069518220964
                        ],
                        [
                            160.80418042341466,
                            182.1093721422248
                        ],
                        [
                            161.05149366795308,
                            180.59449352444204
                        ],
                        [
                            159.07298771164392,
                            180.16467860276208
                        ],
                        [
                            157.7240063777965,
                            178.98783867777342
                        ],
                        [
                            154.26162095425502,
                            179.0783910963238
                        ],
                        [
                            152.05828477563773,
                            178.19531785965967
                        ],
                        [
                            151.51869224209872,
                            177.33448564016356
                        ],
                        [
                            149.51770326355881,
                            177.94617042733995
                        ],
                        [
                            150.237159974944,
                            176.49592471381243
                        ],
                        [
                            148.4385181964808,
                            176.01981659475757
                        ],
                        [
                            147.87644264071128,
                            176.9039204386164
                        ],
                        [
                            146.05531784001732,
                            177.01723671916784
                        ],
                        [
                            142.72783054986053,
                            176.29189330186995
                        ],
                        [
                            142.165754994091,
                            177.24384860279855
                        ],
                        [
                            139.26544512631926,
                            178.17266946701233
                        ],
                        [
                            138.23122610370297,
                            179.4179236398544
                        ],
                        [
                            137.39935428116382,
                            177.96882156813717
                        ],
                        [
                            138.748335615011,
                            176.8132624493296
                        ],
                        [
                            141.2664341048594,
                            175.65698568698423
                        ],
                        [
                            139.6476565042426,
                            173.34226872255766
                        ],
                        [
                            141.2214680603979,
                            172.3655882717078
                        ],
                        [
                            141.0416038825515,
                            171.18379506517118
                        ],
                        [
                            137.9839128591641,
                            167.63384773127706
                        ],
                        [
                            136.5449994363937,
                            166.47179274953226
                        ],
                        [
                            136.72486361424012,
                            164.3964788773534
                        ],
                        [
                            135.71312761385457,
                            161.95323478167535
                        ],
                        [
                            136.61244850308617,
                            161.10760593024867
                        ],
                        [
                            137.73659961462545,
                            161.01616284966627
                        ],
                        [
                            139.33289419301173,
                            159.8269802523929
                        ],
                        [
                            139.51275837085768,
                            158.7743553171195
                        ],
                        [
                            138.54598841493407,
                            157.1942591726281
                        ],
                        [
                            138.90571677062644,
                            155.17703688688727
                        ],
                        [
                            138.9731658373189,
                            152.4225929493748
                        ],
                        [
                            136.6574145475479,
                            149.9169257528721
                        ],
                        [
                            135.9154748139315,
                            149.13461360098
                        ],
                        [
                            136.56748245862468,
                            148.42102609597526
                        ],
                        [
                            136.20775410293209,
                            147.89140312230205
                        ],
                        [
                            135.93795783616224,
                            146.7625432121597
                        ],
                        [
                            134.90373881354617,
                            145.7482535455273
                        ],
                        [
                            134.61145952454604,
                            145.37927522161073
                        ],
                        [
                            134.8138067246232,
                            144.71030351227415
                        ],
                        [
                            134.72387463569999,
                            144.34110600089326
                        ],
                        [
                            134.9711878802384,
                            143.2330445174532
                        ],
                        [
                            134.0044179243148,
                            141.3153560411718
                        ],
                        [
                            133.64468956862197,
                            139.07151331879635
                        ],
                        [
                            133.82455374646838,
                            136.87110852534937
                        ],
                        [
                            133.4198593463143,
                            135.9901591315476
                        ],
                        [
                            133.48730841300676,
                            133.7161491708291
                        ],
                        [
                            133.1050970350832,
                            131.2065924218739
                        ],
                        [
                            133.7795877020069,
                            130.27618898977073
                        ],
                        [
                            133.30744423516035,
                            129.13575066675685
                        ],
                        [
                            134.09435001323777,
                            128.29738915722186
                        ],
                        [
                            134.88125579131543,
                            126.01307772267364
                        ],
                        [
                            138.2537091259337,
                            122.20679498915416
                        ],
                        [
                            140.11979997108915,
                            119.75031452155741
                        ],
                        [
                            140.07483392662766,
                            118.53243306865761
                        ],
                        [
                            140.0073848599352,
                            118.15752119008204
                        ],
                        [
                            141.2214680603979,
                            118.15752119008204
                        ],
                        [
                            142.165754994091,
                            119.37567648359311
                        ],
                        [
                            145.650623439863,
                            120.87372464136291
                        ],
                        [
                            144.18922699486188,
                            123.0481881877322
                        ],
                        [
                            143.334872150092,
                            123.35192099671212
                        ],
                        [
                            142.0758229051678,
                            126.92251010493908
                        ],
                        [
                            143.60466841686116,
                            130.50883753210553
                        ],
                        [
                            145.1784799730167,
                            129.83406905945645
                        ],
                        [
                            146.54994432909461,
                            132.06676345644382
                        ],
                        [
                            148.16872192971164,
                            130.88100905616938
                        ],
                        [
                            149.2479069967892,
                            129.438390556466
                        ],
                        [
                            151.1814469086371,
                            128.4138530436337
                        ],
                        [
                            153.76699446517773,
                            129.64787889024302
                        ],
                        [
                            154.0817567764086,
                            131.09031979261152
                        ],
                        [
                            155.31832299910207,
                            132.46183180410338
                        ],
                        [
                            158.01628566679688,
                            134.04119247998375
                        ],
                        [
                            159.85989348972134,
                            134.4822240383554
                        ],
                        [
                            158.7582254004128,
                            136.01334779309036
                        ],
                        [
                            157.43172708879615,
                            137.42726538470652
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Rajasthan",
        "properties": {
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            115.86061898406842,
                            159.8041036553122
                        ],
                        [
                            116.42269453983818,
                            161.0618849696438
                        ],
                        [
                            117.97402307376274,
                            161.2447618574655
                        ],
                        [
                            119.43541951876387,
                            161.95323478167535
                        ],
                        [
                            120.15487623014906,
                            161.3819073562314
                        ],
                        [
                            119.32300440760991,
                            160.21583790382152
                        ],
                        [
                            119.18810627422499,
                            158.63701091900003
                        ],
                        [
                            120.67198574145709,
                            157.49206501097768
                        ],
                        [
                            121.52634058622698,
                            158.33939531887165
                        ],
                        [
                            122.13338218645845,
                            156.5297443691494
                        ],
                        [
                            121.43640849730377,
                            155.63569669182777
                        ],
                        [
                            124.83134485415303,
                            158.0417303214009
                        ],
                        [
                            124.7638957874608,
                            159.0261260909095
                        ],
                        [
                            126.67495267707773,
                            158.38518554835213
                        ],
                        [
                            126.94474894384712,
                            157.14843849105966
                        ],
                        [
                            128.54104352223317,
                            156.14008631568345
                        ],
                        [
                            129.39539836700305,
                            154.92472368145167
                        ],
                        [
                            131.10410805654283,
                            156.30054404341928
                        ],
                        [
                            130.51954947854256,
                            158.70568443188802
                        ],
                        [
                            130.69941365638874,
                            161.22190326057995
                        ],
                        [
                            129.9574739227728,
                            163.0496773077897
                        ],
                        [
                            131.21652316769678,
                            164.35084096151058
                        ],
                        [
                            132.2057761458516,
                            161.58760606071945
                        ],
                        [
                            135.71312761385457,
                            161.95323478167535
                        ],
                        [
                            136.72486361424012,
                            164.3964788773534
                        ],
                        [
                            136.5449994363937,
                            166.47179274953226
                        ],
                        [
                            137.9839128591641,
                            167.63384773127706
                        ],
                        [
                            141.0416038825515,
                            171.18379506517118
                        ],
                        [
                            141.2214680603979,
                            172.3655882717078
                        ],
                        [
                            139.6476565042426,
                            173.34226872255766
                        ],
                        [
                            141.2664341048594,
                            175.65698568698423
                        ],
                        [
                            138.748335615011,
                            176.8132624493296
                        ],
                        [
                            137.39935428116382,
                            177.96882156813717
                        ],
                        [
                            138.23122610370297,
                            179.4179236398544
                        ],
                        [
                            139.26544512631926,
                            178.17266946701233
                        ],
                        [
                            142.165754994091,
                            177.24384860279855
                        ],
                        [
                            142.72783054986053,
                            176.29189330186995
                        ],
                        [
                            146.05531784001732,
                            177.01723671916784
                        ],
                        [
                            147.87644264071128,
                            176.9039204386164
                        ],
                        [
                            148.4385181964808,
                            176.01981659475757
                        ],
                        [
                            150.237159974944,
                            176.49592471381243
                        ],
                        [
                            149.51770326355881,
                            177.94617042733995
                        ],
                        [
                            147.89892566294202,
                            178.55765479531544
                        ],
                        [
                            147.56168032948017,
                            180.52663455691493
                        ],
                        [
                            146.25766504009448,
                            180.2099269173329
                        ],
                        [
                            144.8412346395546,
                            180.79805570744838
                        ],
                        [
                            143.64963446132288,
                            182.58393387283974
                        ],
                        [
                            142.50300032755263,
                            182.78728088915636
                        ],
                        [
                            139.46779232639642,
                            184.86468397843328
                        ],
                        [
                            137.9839128591641,
                            185.00008755688083
                        ],
                        [
                            137.06210894770197,
                            185.6318430311929
                        ],
                        [
                            135.53326343600838,
                            187.2328525180992
                        ],
                        [
                            133.30744423516035,
                            188.11170730662718
                        ],
                        [
                            131.5312854789281,
                            189.48551499157264
                        ],
                        [
                            130.0923720561575,
                            190.09327874529941
                        ],
                        [
                            129.3729153447723,
                            191.08329564260998
                        ],
                        [
                            128.42862841107922,
                            192.5224095842063
                        ],
                        [
                            127.37192636623195,
                            193.21909370751172
                        ],
                        [
                            126.29274129915439,
                            193.10674233294566
                        ],
                        [
                            124.87631089861475,
                            194.252419556946
                        ],
                        [
                            124.24678627615253,
                            196.9903102884008
                        ],
                        [
                            124.42665045399872,
                            199.3212092357315
                        ],
                        [
                            125.5732845877692,
                            201.2689624551254
                        ],
                        [
                            126.6974356993087,
                            202.2309314876798
                        ],
                        [
                            128.04641703315588,
                            202.70055938102786
                        ],
                        [
                            129.44036441146477,
                            202.499304001376
                        ],
                        [
                            130.74437970085046,
                            203.28184836137234
                        ],
                        [
                            132.76785170162134,
                            202.43221430333296
                        ],
                        [
                            134.83628974685394,
                            202.72291982042339
                        ],
                        [
                            134.99367090246915,
                            201.73882010620457
                        ],
                        [
                            136.16278805847037,
                            200.88851913092162
                        ],
                        [
                            136.9496938365478,
                            201.22420824429963
                        ],
                        [
                            137.60170148124098,
                            204.26517817618077
                        ],
                        [
                            137.33190521447136,
                            205.96250627111868
                        ],
                        [
                            136.14030503623962,
                            206.5874715974225
                        ],
                        [
                            135.5107804137774,
                            206.0294762520095
                        ],
                        [
                            134.11683303546852,
                            206.09644397322825
                        ],
                        [
                            132.81281774608283,
                            207.01144328844862
                        ],
                        [
                            131.55376850115886,
                            206.67673630965936
                        ],
                        [
                            129.7326437004649,
                            207.2345499877598
                        ],
                        [
                            129.50781347815723,
                            208.52807627351444
                        ],
                        [
                            130.81182876754292,
                            210.02127966223958
                        ],
                        [
                            128.67594165561786,
                            211.02355272093314
                        ],
                        [
                            129.41788138923403,
                            212.0475816528899
                        ],
                        [
                            130.7668627230812,
                            211.80275261269435
                        ],
                        [
                            132.04839499023615,
                            212.62615013878042
                        ],
                        [
                            132.5655045015444,
                            215.0721063241832
                        ],
                        [
                            131.21652316769678,
                            216.6270874483855
                        ],
                        [
                            129.50781347815723,
                            215.20543711093757
                        ],
                        [
                            128.85580583346427,
                            215.44985411948062
                        ],
                        [
                            129.26050023361813,
                            217.0267475314228
                        ],
                        [
                            129.08063605577195,
                            218.2918209233198
                        ],
                        [
                            129.53029650038775,
                            219.66697666109485
                        ],
                        [
                            130.4071343673886,
                            220.64233393146185
                        ],
                        [
                            129.80009276715737,
                            221.94947308761192
                        ],
                        [
                            128.63097561115615,
                            222.17093986645227
                        ],
                        [
                            126.78736778823145,
                            220.97473620742227
                        ],
                        [
                            126.31522432138513,
                            219.71132114683058
                        ],
                        [
                            125.23603925430712,
                            220.68665734207815
                        ],
                        [
                            124.26926929838328,
                            220.77530129452293
                        ],
                        [
                            121.5713066306887,
                            220.11037835966232
                        ],
                        [
                            119.97501205230265,
                            220.68665734207815
                        ],
                        [
                            118.58106467399398,
                            220.93041901026214
                        ],
                        [
                            118.71596280737867,
                            222.63594269368355
                        ],
                        [
                            117.45691356245447,
                            222.9901602519932
                        ],
                        [
                            115.92806805076088,
                            225.71118529392055
                        ],
                        [
                            113.81466396106657,
                            226.19749686999472
                        ],
                        [
                            112.28581844937298,
                            228.119527361905
                        ],
                        [
                            110.7120068932179,
                            227.3465094833473
                        ],
                        [
                            110.44221062644829,
                            226.37430927608494
                        ],
                        [
                            108.93584813698567,
                            225.37954400932404
                        ],
                        [
                            109.8126860039863,
                            223.6541545501911
                        ],
                        [
                            112.4656826272194,
                            224.6055083557327
                        ],
                        [
                            114.44418858352878,
                            223.14511133332928
                        ],
                        [
                            113.18513933860459,
                            221.75013263322847
                        ],
                        [
                            114.10694325006693,
                            220.11037835966232
                        ],
                        [
                            112.84789400514273,
                            217.67047942288724
                        ],
                        [
                            113.70224884991262,
                            216.7603161962095
                        ],
                        [
                            115.38847551722188,
                            216.91573871267303
                        ],
                        [
                            115.68075480622201,
                            215.2943194312777
                        ],
                        [
                            114.2643244056826,
                            213.29352284500686
                        ],
                        [
                            113.67976582768188,
                            211.7582350403839
                        ],
                        [
                            111.09421827114147,
                            213.04884515750535
                        ],
                        [
                            110.21738040414061,
                            212.71514592873683
                        ],
                        [
                            108.84591604806246,
                            213.07108980973862
                        ],
                        [
                            104.84393809098219,
                            211.9808130508498
                        ],
                        [
                            107.09224031406097,
                            208.66184146665967
                        ],
                        [
                            107.31707053636887,
                            207.59146869830084
                        ],
                        [
                            104.95635320213614,
                            207.30147712021056
                        ],
                        [
                            103.8097190683659,
                            208.43889449815975
                        ],
                        [
                            103.85468511282738,
                            209.8876053808561
                        ],
                        [
                            102.70805097905713,
                            210.39997503206564
                        ],
                        [
                            101.53893382305637,
                            210.19949816254245
                        ],
                        [
                            100.70706200051723,
                            209.0407942008415
                        ],
                        [
                            99.58291088897772,
                            209.53109699146876
                        ],
                        [
                            100.10002040028576,
                            211.15715130844973
                        ],
                        [
                            101.80873008982553,
                            211.7137164826724
                        ],
                        [
                            102.03356031213343,
                            212.87087910758856
                        ],
                        [
                            100.50471480044007,
                            213.8272621668114
                        ],
                        [
                            99.26814857774662,
                            212.18111221241656
                        ],
                        [
                            98.79600511090007,
                            213.3824891955212
                        ],
                        [
                            98.99835231097722,
                            214.60537947340043
                        ],
                        [
                            97.69433702159154,
                            216.27176811965217
                        ],
                        [
                            99.42552973336205,
                            216.49384997521207
                        ],
                        [
                            100.01008831136255,
                            217.22654815781567
                        ],
                        [
                            98.93090324428476,
                            218.60242078962796
                        ],
                        [
                            98.52620884413068,
                            220.1547132571883
                        ],
                        [
                            100.18995248920874,
                            220.13254592818816
                        ],
                        [
                            100.7969940894402,
                            220.64233393146185
                        ],
                        [
                            100.36981666705515,
                            221.2627747080008
                        ],
                        [
                            101.76376404536427,
                            223.41071473313826
                        ],
                        [
                            100.70706200051723,
                            226.08698149088036
                        ],
                        [
                            101.26913755628675,
                            227.83243980999953
                        ],
                        [
                            100.99934128951713,
                            229.42196863275268
                        ],
                        [
                            99.89767320020883,
                            231.25284978267752
                        ],
                        [
                            98.79600511090007,
                            231.4071886640163
                        ],
                        [
                            95.85072919866684,
                            233.36851470106518
                        ],
                        [
                            95.22120457620463,
                            234.95387796531952
                        ],
                        [
                            98.23392955513009,
                            236.56005520728655
                        ],
                        [
                            94.883959242743,
                            238.73636918582042
                        ],
                        [
                            93.10780048651077,
                            238.2968878502532
                        ],
                        [
                            92.1410305305867,
                            239.13182553649392
                        ],
                        [
                            91.08432848573966,
                            237.1538147485146
                        ],
                        [
                            89.30816972950743,
                            237.28573893281117
                        ],
                        [
                            89.48803390735361,
                            235.83412491994062
                        ],
                        [
                            87.1722826175826,
                            234.68973289401652
                        ],
                        [
                            86.83503728412052,
                            234.00720616665353
                        ],
                        [
                            85.84578430596594,
                            234.73375935062114
                        ],
                        [
                            84.94646341673456,
                            233.2804042266112
                        ],
                        [
                            83.84479532742557,
                            232.81776409694464
                        ],
                        [
                            81.95622146003961,
                            232.9058938199626
                        ],
                        [
                            82.38339888242444,
                            230.15010022519328
                        ],
                        [
                            81.14683265973122,
                            229.62057501272216
                        ],
                        [
                            80.42737594834603,
                            230.37069636377163
                        ],
                        [
                            79.7079192369606,
                            228.80396184925877
                        ],
                        [
                            78.15659070303627,
                            227.03722220733812
                        ],
                        [
                            78.29148883642097,
                            225.60064407781806
                        ],
                        [
                            78.85356439219072,
                            225.2689851524994
                        ],
                        [
                            78.69618323657505,
                            223.38858241975362
                        ],
                        [
                            77.39216794718959,
                            223.18938093673984
                        ],
                        [
                            76.3129828801118,
                            224.03032339958867
                        ],
                        [
                            75.88580545772675,
                            222.81305906840663
                        ],
                        [
                            74.58179016834083,
                            222.0380626548403
                        ],
                        [
                            73.72743532357094,
                            220.93041901026214
                        ],
                        [
                            74.9640015462644,
                            218.33619522385152
                        ],
                        [
                            73.86233345695564,
                            217.67047942288724
                        ],
                        [
                            74.0197146125713,
                            216.02745005059455
                        ],
                        [
                            72.7606653676471,
                            216.3161864314664
                        ],
                        [
                            72.1311407451849,
                            218.24744565871737
                        ],
                        [
                            68.73620438833586,
                            218.13650327845693
                        ],
                        [
                            68.08419674364313,
                            216.5826759270039
                        ],
                        [
                            65.67851336494891,
                            215.98302542702993
                        ],
                        [
                            65.38623407594855,
                            217.33754003906057
                        ],
                        [
                            63.22786394179275,
                            214.96099063533734
                        ],
                        [
                            62.95806767502336,
                            213.84949824528678
                        ],
                        [
                            60.462452207405704,
                            214.02737806768494
                        ],
                        [
                            58.888640651250626,
                            212.55940071717254
                        ],
                        [
                            56.84268562824877,
                            213.20455257302189
                        ],
                        [
                            54.97659478309333,
                            213.13782229486714
                        ],
                        [
                            52.9756058045532,
                            213.7383154061759
                        ],
                        [
                            52.27863211539875,
                            213.13782229486714
                        ],
                        [
                            50.36757522578205,
                            213.09333421669788
                        ],
                        [
                            48.88369575854972,
                            213.96067496847235
                        ],
                        [
                            47.55719744693329,
                            214.11631211050508
                        ],
                        [
                            46.09580100193193,
                            213.56041013680016
                        ],
                        [
                            44.971649890392655,
                            213.16006596611408
                        ],
                        [
                            42.40858535608277,
                            208.88476354534936
                        ],
                        [
                            41.689128644697576,
                            205.49365309208832
                        ],
                        [
                            39.66565664392647,
                            203.3936150584725
                        ],
                        [
                            38.40660739900227,
                            201.44796909848702
                        ],
                        [
                            38.54150553238719,
                            196.90060460182877
                        ],
                        [
                            37.574735576463354,
                            196.2725488307433
                        ],
                        [
                            36.42810144269288,
                            196.7884666727324
                        ],
                        [
                            34.31469735299879,
                            196.94545796243835
                        ],
                        [
                            32.583504641228046,
                            196.29498289111513
                        ],
                        [
                            30.08788917361062,
                            192.6123184673483
                        ],
                        [
                            29.863058951302946,
                            190.25081563943877
                        ],
                        [
                            30.69493077384186,
                            189.05771361222082
                        ],
                        [
                            31.18955726291938,
                            187.50331256193084
                        ],
                        [
                            31.34693841853459,
                            185.47392389778616
                        ],
                        [
                            31.18955726291938,
                            182.5613384056578
                        ],
                        [
                            29.458364551148634,
                            181.77032623342234
                        ],
                        [
                            26.962749083531207,
                            182.1093721422248
                        ],
                        [
                            25.568801705222086,
                            181.79293119452444
                        ],
                        [
                            24.51209966037527,
                            180.86590486368925
                        ],
                        [
                            21.43192561475712,
                            179.4179236398544
                        ],
                        [
                            21.049714236833665,
                            178.39914103405306
                        ],
                        [
                            21.47689165921861,
                            175.02186096690298
                        ],
                        [
                            22.556076726296624,
                            172.13837919308935
                        ],
                        [
                            26.62550375006913,
                            168.52197948114008
                        ],
                        [
                            27.659722772685427,
                            166.95037560349863
                        ],
                        [
                            29.00870410653306,
                            165.87908743327858
                        ],
                        [
                            30.58251566268791,
                            161.83898375903215
                        ],
                        [
                            31.95398001876606,
                            160.26158033940476
                        ],
                        [
                            34.08986713069089,
                            158.40808022470634
                        ],
                        [
                            36.090856109231254,
                            157.99593131913525
                        ],
                        [
                            37.282456287462765,
                            158.43097460883035
                        ],
                        [
                            38.58647157684891,
                            159.91848373139692
                        ],
                        [
                            38.676403665771886,
                            161.47333189734434
                        ],
                        [
                            39.77807175508042,
                            163.23235314168426
                        ],
                        [
                            41.958924911466966,
                            163.43784143100714
                        ],
                        [
                            46.34311424647058,
                            161.33619334841933
                        ],
                        [
                            48.95114482524218,
                            160.71894079876108
                        ],
                        [
                            53.132986960168864,
                            160.62747799697928
                        ],
                        [
                            56.19067798355604,
                            159.3465105819941
                        ],
                        [
                            56.28061007247925,
                            160.0328565357271
                        ],
                        [
                            56.977583761633696,
                            156.5755809065153
                        ],
                        [
                            57.96683673978828,
                            155.131164415802
                        ],
                        [
                            59.96782571832864,
                            153.38713403927807
                        ],
                        [
                            61.06949380763717,
                            152.03203427356738
                        ],
                        [
                            62.440958163715095,
                            147.43073163963157
                        ],
                        [
                            63.96980367540891,
                            145.51765121193375
                        ],
                        [
                            67.76943443241203,
                            143.6717363879684
                        ],
                        [
                            68.80365345502832,
                            142.74804598566402
                        ],
                        [
                            71.92879354510774,
                            141.40782331031255
                        ],
                        [
                            72.8056314121086,
                            139.30297181579255
                        ],
                        [
                            73.97474856810959,
                            137.91375543507496
                        ],
                        [
                            76.89754145811207,
                            132.22944973205313
                        ],
                        [
                            78.58376812542133,
                            125.89644839908289
                        ],
                        [
                            81.50656101542381,
                            124.65968142679304
                        ],
                        [
                            84.58673506104174,
                            123.8424503411461
                        ],
                        [
                            86.96993541750544,
                            121.69240234347961
                        ],
                        [
                            87.08235052865939,
                            122.95472088509376
                        ],
                        [
                            86.02564848381235,
                            124.12268857663673
                        ],
                        [
                            85.89075035042742,
                            125.42985046644867
                        ],
                        [
                            91.8262682193556,
                            125.75648256996413
                        ],
                        [
                            94.02960439797312,
                            125.87312156713011
                        ],
                        [
                            95.17623853174314,
                            125.98975250270416
                        ],
                        [
                            95.6933480430514,
                            127.20223702531388
                        ],
                        [
                            94.5691969315119,
                            128.13432626945752
                        ],
                        [
                            94.50174786481944,
                            129.29871731057636
                        ],
                        [
                            96.50273684335957,
                            129.18231416139866
                        ],
                        [
                            95.89569524312856,
                            132.3688827708129
                        ],
                        [
                            96.592668932283,
                            132.9265011127074
                        ],
                        [
                            96.03059337651302,
                            134.1572644247558
                        ],
                        [
                            96.30038964328264,
                            135.73506320071198
                        ],
                        [
                            99.02083533320797,
                            135.7814470961356
                        ],
                        [
                            99.94263924467032,
                            135.08555708148975
                        ],
                        [
                            101.22417151182526,
                            135.7814470961356
                        ],
                        [
                            101.56141684528711,
                            137.14920940294525
                        ],
                        [
                            102.88791515690355,
                            137.0565141006938
                        ],
                        [
                            103.33757560151935,
                            138.02956617301504
                        ],
                        [
                            105.67580991352133,
                            137.49677236957177
                        ],
                        [
                            107.2271384474459,
                            137.10286237545995
                        ],
                        [
                            108.10397631444653,
                            137.49677236957177
                        ],
                        [
                            107.90162911436937,
                            140.82982202635327
                        ],
                        [
                            108.6435688479853,
                            140.89919234891937
                        ],
                        [
                            109.9026180929095,
                            142.3553291189566
                        ],
                        [
                            109.11571231483185,
                            143.11758092813028
                        ],
                        [
                            109.79020298175556,
                            144.66415809144922
                        ],
                        [
                            110.39724458198702,
                            148.37497825570864
                        ],
                        [
                            111.29656547121863,
                            148.5591623858453
                        ],
                        [
                            111.99353916037285,
                            149.9399296807117
                        ],
                        [
                            115.27606040606793,
                            152.1009626481482
                        ],
                        [
                            117.65926076253163,
                            154.92472368145167
                        ],
                        [
                            116.82738893999226,
                            156.55266278478518
                        ],
                        [
                            116.96228707337718,
                            157.629497152471
                        ],
                        [
                            116.44517756206892,
                            159.73547211814935
                        ],
                        [
                            115.86061898406842,
                            159.8041036553122
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Delhi",
        "properties": {
            "st_nm": "Delhi",
            "st_code": "07",
            "alpha2code": "DL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            136.56748245862468,
                            148.42102609597526
                        ],
                        [
                            135.9154748139315,
                            149.13461360098
                        ],
                        [
                            136.6574145475479,
                            149.9169257528721
                        ],
                        [
                            135.28595019146974,
                            150.53792659275922
                        ],
                        [
                            134.11683303546852,
                            151.8482121606139
                        ],
                        [
                            133.30744423516035,
                            150.44594024966932
                        ],
                        [
                            132.9701989016985,
                            150.10094877889287
                        ],
                        [
                            131.64370059008206,
                            149.5718308300514
                        ],
                        [
                            129.84505881161886,
                            150.2159534267281
                        ],
                        [
                            129.2829832558491,
                            149.41076341694048
                        ],
                        [
                            129.91250787831132,
                            148.0526096121319
                        ],
                        [
                            130.8567948120044,
                            147.45376808351426
                        ],
                        [
                            131.1265910787738,
                            146.9468964709858
                        ],
                        [
                            130.69941365638874,
                            145.263953533292
                        ],
                        [
                            131.08162503431208,
                            145.03328733715534
                        ],
                        [
                            133.3299272573911,
                            144.24879442335947
                        ],
                        [
                            134.72387463569999,
                            144.34110600089326
                        ],
                        [
                            134.8138067246232,
                            144.71030351227415
                        ],
                        [
                            134.61145952454604,
                            145.37927522161073
                        ],
                        [
                            134.90373881354617,
                            145.7482535455273
                        ],
                        [
                            135.93795783616224,
                            146.7625432121597
                        ],
                        [
                            136.20775410293209,
                            147.89140312230205
                        ],
                        [
                            136.56748245862468,
                            148.42102609597526
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Haryana",
        "properties": {
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            134.72387463569999,
                            144.34110600089326
                        ],
                        [
                            133.3299272573911,
                            144.24879442335947
                        ],
                        [
                            131.08162503431208,
                            145.03328733715534
                        ],
                        [
                            130.69941365638874,
                            145.263953533292
                        ],
                        [
                            131.1265910787738,
                            146.9468964709858
                        ],
                        [
                            130.8567948120044,
                            147.45376808351426
                        ],
                        [
                            129.91250787831132,
                            148.0526096121319
                        ],
                        [
                            129.2829832558491,
                            149.41076341694048
                        ],
                        [
                            129.84505881161886,
                            150.2159534267281
                        ],
                        [
                            131.64370059008206,
                            149.5718308300514
                        ],
                        [
                            132.9701989016985,
                            150.10094877889287
                        ],
                        [
                            133.30744423516035,
                            150.44594024966932
                        ],
                        [
                            134.11683303546852,
                            151.8482121606139
                        ],
                        [
                            135.28595019146974,
                            150.53792659275922
                        ],
                        [
                            136.6574145475479,
                            149.9169257528721
                        ],
                        [
                            138.9731658373189,
                            152.4225929493748
                        ],
                        [
                            138.90571677062644,
                            155.17703688688727
                        ],
                        [
                            138.54598841493407,
                            157.1942591726281
                        ],
                        [
                            139.51275837085768,
                            158.7743553171195
                        ],
                        [
                            139.33289419301173,
                            159.8269802523929
                        ],
                        [
                            137.73659961462545,
                            161.01616284966627
                        ],
                        [
                            136.61244850308617,
                            161.10760593024867
                        ],
                        [
                            135.71312761385457,
                            161.95323478167535
                        ],
                        [
                            132.2057761458516,
                            161.58760606071945
                        ],
                        [
                            131.21652316769678,
                            164.35084096151058
                        ],
                        [
                            129.9574739227728,
                            163.0496773077897
                        ],
                        [
                            130.69941365638874,
                            161.22190326057995
                        ],
                        [
                            130.51954947854256,
                            158.70568443188802
                        ],
                        [
                            131.10410805654283,
                            156.30054404341928
                        ],
                        [
                            129.39539836700305,
                            154.92472368145167
                        ],
                        [
                            128.54104352223317,
                            156.14008631568345
                        ],
                        [
                            126.94474894384712,
                            157.14843849105966
                        ],
                        [
                            126.67495267707773,
                            158.38518554835213
                        ],
                        [
                            124.7638957874608,
                            159.0261260909095
                        ],
                        [
                            124.83134485415303,
                            158.0417303214009
                        ],
                        [
                            121.43640849730377,
                            155.63569669182777
                        ],
                        [
                            122.13338218645845,
                            156.5297443691494
                        ],
                        [
                            121.52634058622698,
                            158.33939531887165
                        ],
                        [
                            120.67198574145709,
                            157.49206501097768
                        ],
                        [
                            119.18810627422499,
                            158.63701091900003
                        ],
                        [
                            119.32300440760991,
                            160.21583790382152
                        ],
                        [
                            120.15487623014906,
                            161.3819073562314
                        ],
                        [
                            119.43541951876387,
                            161.95323478167535
                        ],
                        [
                            117.97402307376274,
                            161.2447618574655
                        ],
                        [
                            116.42269453983818,
                            161.0618849696438
                        ],
                        [
                            115.86061898406842,
                            159.8041036553122
                        ],
                        [
                            116.44517756206892,
                            159.73547211814935
                        ],
                        [
                            116.96228707337718,
                            157.629497152471
                        ],
                        [
                            116.82738893999226,
                            156.55266278478518
                        ],
                        [
                            117.65926076253163,
                            154.92472368145167
                        ],
                        [
                            115.27606040606793,
                            152.1009626481482
                        ],
                        [
                            111.99353916037285,
                            149.9399296807117
                        ],
                        [
                            111.29656547121863,
                            148.5591623858453
                        ],
                        [
                            110.39724458198702,
                            148.37497825570864
                        ],
                        [
                            109.79020298175556,
                            144.66415809144922
                        ],
                        [
                            109.11571231483185,
                            143.11758092813028
                        ],
                        [
                            109.9026180929095,
                            142.3553291189566
                        ],
                        [
                            108.6435688479853,
                            140.89919234891937
                        ],
                        [
                            107.90162911436937,
                            140.82982202635327
                        ],
                        [
                            108.10397631444653,
                            137.49677236957177
                        ],
                        [
                            107.2271384474459,
                            137.10286237545995
                        ],
                        [
                            105.67580991352133,
                            137.49677236957177
                        ],
                        [
                            103.33757560151935,
                            138.02956617301504
                        ],
                        [
                            102.88791515690355,
                            137.0565141006938
                        ],
                        [
                            101.56141684528711,
                            137.14920940294525
                        ],
                        [
                            101.22417151182526,
                            135.7814470961356
                        ],
                        [
                            99.94263924467032,
                            135.08555708148975
                        ],
                        [
                            99.02083533320797,
                            135.7814470961356
                        ],
                        [
                            96.30038964328264,
                            135.73506320071198
                        ],
                        [
                            96.03059337651302,
                            134.1572644247558
                        ],
                        [
                            96.592668932283,
                            132.9265011127074
                        ],
                        [
                            95.89569524312856,
                            132.3688827708129
                        ],
                        [
                            96.50273684335957,
                            129.18231416139866
                        ],
                        [
                            94.50174786481944,
                            129.29871731057636
                        ],
                        [
                            94.5691969315119,
                            128.13432626945752
                        ],
                        [
                            95.6933480430514,
                            127.20223702531388
                        ],
                        [
                            95.17623853174314,
                            125.98975250270416
                        ],
                        [
                            96.95239728797537,
                            126.47951446254183
                        ],
                        [
                            97.80675213274526,
                            125.40651718097536
                        ],
                        [
                            99.38056368890057,
                            125.10315507679042
                        ],
                        [
                            101.0667903562096,
                            125.82646693581137
                        ],
                        [
                            102.10100937882589,
                            127.22554551373196
                        ],
                        [
                            103.87716813505813,
                            127.83145354710103
                        ],
                        [
                            104.95635320213614,
                            127.62174069602156
                        ],
                        [
                            105.69829293575208,
                            129.20559542975303
                        ],
                        [
                            104.61910786867429,
                            130.62514987529534
                        ],
                        [
                            106.59761382498368,
                            132.43859502012322
                        ],
                        [
                            107.2271384474459,
                            130.1365845891346
                        ],
                        [
                            108.9133651147547,
                            128.25080136178462
                        ],
                        [
                            110.66704084875619,
                            129.39183408431268
                        ],
                        [
                            112.73547889398878,
                            128.13432626945752
                        ],
                        [
                            113.5898337387589,
                            127.99454558844337
                        ],
                        [
                            116.06296618414558,
                            129.5780523161945
                        ],
                        [
                            117.56932867360842,
                            129.27543731921543
                        ],
                        [
                            118.28878538499362,
                            128.20421228558297
                        ],
                        [
                            119.99749507453339,
                            127.69164786603676
                        ],
                        [
                            119.3679704520714,
                            126.22299020024639
                        ],
                        [
                            120.58205365253389,
                            123.46872668535616
                        ],
                        [
                            122.87532192007438,
                            122.51068010213277
                        ],
                        [
                            123.70719374261375,
                            123.30519644722006
                        ],
                        [
                            125.32597134323032,
                            123.25847059801043
                        ],
                        [
                            126.22529223246192,
                            122.48730627901114
                        ],
                        [
                            126.29274129915439,
                            122.44055765559403
                        ],
                        [
                            125.10114112092242,
                            120.87372464136291
                        ],
                        [
                            127.30447729953971,
                            119.44592752306795
                        ],
                        [
                            127.84406983307872,
                            117.68876256369498
                        ],
                        [
                            130.24975321177294,
                            117.73564436989062
                        ],
                        [
                            130.04740601169578,
                            117.40744396948793
                        ],
                        [
                            130.18230414508048,
                            117.17297543907432
                        ],
                        [
                            130.4521004118501,
                            114.59163285058702
                        ],
                        [
                            129.0131869890797,
                            113.36995944955044
                        ],
                        [
                            129.17056814469515,
                            112.12386132629348
                        ],
                        [
                            129.2829832558491,
                            110.75912568444716
                        ],
                        [
                            128.27124725546355,
                            109.55816255202666
                        ],
                        [
                            129.62022858931095,
                            110.09988213639912
                        ],
                        [
                            130.3172022784654,
                            109.62883177232561
                        ],
                        [
                            131.91349685685122,
                            112.2179393338422
                        ],
                        [
                            134.0044179243148,
                            113.32295396423399
                        ],
                        [
                            134.07186699100703,
                            114.73253709535277
                        ],
                        [
                            133.35241027962184,
                            115.38993169112189
                        ],
                        [
                            134.61145952454604,
                            116.68048380076033
                        ],
                        [
                            137.53425241454852,
                            117.71220363196386
                        ],
                        [
                            139.24296210408852,
                            117.43088900364017
                        ],
                        [
                            140.07483392662766,
                            118.53243306865761
                        ],
                        [
                            140.11979997108915,
                            119.75031452155741
                        ],
                        [
                            138.2537091259337,
                            122.20679498915416
                        ],
                        [
                            134.88125579131543,
                            126.01307772267364
                        ],
                        [
                            134.09435001323777,
                            128.29738915722186
                        ],
                        [
                            133.30744423516035,
                            129.13575066675685
                        ],
                        [
                            133.7795877020069,
                            130.27618898977073
                        ],
                        [
                            133.1050970350832,
                            131.2065924218739
                        ],
                        [
                            133.48730841300676,
                            133.7161491708291
                        ],
                        [
                            133.4198593463143,
                            135.9901591315476
                        ],
                        [
                            133.82455374646838,
                            136.87110852534937
                        ],
                        [
                            133.64468956862197,
                            139.07151331879635
                        ],
                        [
                            134.0044179243148,
                            141.3153560411718
                        ],
                        [
                            134.9711878802384,
                            143.2330445174532
                        ],
                        [
                            134.72387463569999,
                            144.34110600089326
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Sikkim",
        "properties": {
            "st_nm": "Sikkim",
            "st_code": "11",
            "alpha2code": "SK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            297.4110234976847,
                            173.00162489949003
                        ],
                        [
                            295.54493265252927,
                            173.36497607844342
                        ],
                        [
                            294.6905778077594,
                            172.9789130780631
                        ],
                        [
                            293.8362229629895,
                            171.54750449671536
                        ],
                        [
                            293.92615505191293,
                            167.26936110379586
                        ],
                        [
                            294.9603740745292,
                            164.94404441354504
                        ],
                        [
                            296.1744572749917,
                            162.11317407409388
                        ],
                        [
                            296.28687238614566,
                            159.5753216775937
                        ],
                        [
                            300.153952209841,
                            158.31649976570378
                        ],
                        [
                            300.5586466099953,
                            157.69820926835
                        ],
                        [
                            302.37977141068905,
                            156.96514402845133
                        ],
                        [
                            304.40324341146015,
                            157.33171417818147
                        ],
                        [
                            305.6847756786151,
                            158.7285750188995
                        ],
                        [
                            305.57236056746115,
                            159.78122676725843
                        ],
                        [
                            306.3367833233078,
                            160.9933013548614
                        ],
                        [
                            305.8646398564615,
                            161.6561670861194
                        ],
                        [
                            305.75222474530756,
                            164.1226341649687
                        ],
                        [
                            304.5156585226141,
                            165.83348672606246
                        ],
                        [
                            304.94283594499916,
                            168.29429450247613
                        ],
                        [
                            306.78644376792363,
                            169.88749534815088
                        ],
                        [
                            306.5616135456157,
                            170.6835771869915
                        ],
                        [
                            305.0777340783836,
                            171.0928564767043
                        ],
                        [
                            304.9203529227684,
                            172.11565674553248
                        ],
                        [
                            303.8861339001521,
                            172.88806299949783
                        ],
                        [
                            302.06500909945817,
                            172.07021101032637
                        ],
                        [
                            301.0982391435341,
                            172.2747079990349
                        ],
                        [
                            299.524427587379,
                            174.00066887169754
                        ],
                        [
                            297.4110234976847,
                            173.00162489949003
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Bihar",
        "properties": {
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            240.61890934271332,
                            181.2503371251491
                        ],
                        [
                            239.31489405332763,
                            180.39090925889997
                        ],
                        [
                            238.28067503071134,
                            178.39914103405306
                        ],
                        [
                            235.35788214070885,
                            177.03989914800133
                        ],
                        [
                            235.2005009850934,
                            174.45460150978084
                        ],
                        [
                            233.94145174016944,
                            173.79636286686815
                        ],
                        [
                            234.36862916255427,
                            172.29742848697998
                        ],
                        [
                            233.10957991763007,
                            170.63809608678525
                        ],
                        [
                            232.43508925070637,
                            169.6372268965
                        ],
                        [
                            231.9854288060908,
                            168.112126115951
                        ],
                        [
                            234.7058744960159,
                            167.95271389129755
                        ],
                        [
                            235.89747467424786,
                            166.6313343239101
                        ],
                        [
                            238.2581920084806,
                            167.7249580256406
                        ],
                        [
                            238.5729543197117,
                            168.6585768736831
                        ],
                        [
                            243.5866682771773,
                            169.56896593896926
                        ],
                        [
                            244.59840427756285,
                            171.52477476202688
                        ],
                        [
                            243.90143058840863,
                            174.3865187062333
                        ],
                        [
                            245.6101402779484,
                            175.13529203776187
                        ],
                        [
                            246.57691023387224,
                            174.8403568237876
                        ],
                        [
                            248.60038223464312,
                            175.83840999723265
                        ],
                        [
                            249.61211823502867,
                            177.24384860279855
                        ],
                        [
                            251.99531859149215,
                            177.33448564016356
                        ],
                        [
                            251.792971391415,
                            178.51236652149305
                        ],
                        [
                            252.9171225029545,
                            179.23684728289294
                        ],
                        [
                            254.0862396589555,
                            179.44055695286954
                        ],
                        [
                            257.16641370457387,
                            177.85556311471964
                        ],
                        [
                            258.62781014957477,
                            177.60637031353116
                        ],
                        [
                            259.88685939449897,
                            178.33120244739496
                        ],
                        [
                            259.79692730557576,
                            180.54925448541366
                        ],
                        [
                            261.16839166165414,
                            181.77032623342234
                        ],
                        [
                            261.7979162841159,
                            182.29017166189567
                        ],
                        [
                            263.281795751348,
                            180.93375156843007
                        ],
                        [
                            264.29353175173355,
                            180.6849683311683
                        ],
                        [
                            266.67673210819703,
                            181.5216537309779
                        ],
                        [
                            268.88006828681455,
                            181.65729735342507
                        ],
                        [
                            271.8478272212783,
                            182.78728088915636
                        ],
                        [
                            272.2525216214324,
                            183.46494598075537
                        ],
                        [
                            274.5008238445114,
                            184.6389898305144
                        ],
                        [
                            276.32194864520534,
                            184.36812132576443
                        ],
                        [
                            279.1997754907461,
                            182.42575991249868
                        ],
                        [
                            279.9192322021315,
                            184.2326725297292
                        ],
                        [
                            280.9534512247476,
                            184.9775209659755
                        ],
                        [
                            283.98865922590426,
                            184.93238697761956
                        ],
                        [
                            285.38260660421315,
                            184.39069518220964
                        ],
                        [
                            287.2262144271376,
                            185.36111646471
                        ],
                        [
                            288.2604334497539,
                            185.38367848827244
                        ],
                        [
                            291.04832820637193,
                            184.45841513554478
                        ],
                        [
                            293.76877389629726,
                            185.18061060814762
                        ],
                        [
                            294.7355438522211,
                            184.14236794298233
                        ],
                        [
                            294.89292500783677,
                            182.62912399451233
                        ],
                        [
                            296.8039818974537,
                            184.09721403232572
                        ],
                        [
                            297.38854047545396,
                            186.1957326708128
                        ],
                        [
                            295.4325175413753,
                            187.50331256193084
                        ],
                        [
                            294.35333247429776,
                            188.74243025542103
                        ],
                        [
                            292.9144190515274,
                            189.26036830341496
                        ],
                        [
                            291.0708112286027,
                            190.97081945604486
                        ],
                        [
                            291.11577727306417,
                            191.12828427175089
                        ],
                        [
                            290.935913095218,
                            191.66806562720058
                        ],
                        [
                            291.02584518414096,
                            191.73552763467018
                        ],
                        [
                            290.66611682844837,
                            192.65727133490464
                        ],
                        [
                            290.7335658951406,
                            193.39884229375588
                        ],
                        [
                            291.70033585106466,
                            193.803215400044
                        ],
                        [
                            292.8020039403732,
                            196.093067013952
                        ],
                        [
                            294.0160871408359,
                            196.49687777229695
                        ],
                        [
                            293.5889097184506,
                            198.20093254423512
                        ],
                        [
                            294.26340038537455,
                            200.03784942442138
                        ],
                        [
                            292.68958882921925,
                            199.14200814601628
                        ],
                        [
                            291.3181244731411,
                            199.8139275615602
                        ],
                        [
                            289.85672802813997,
                            201.13469676161787
                        ],
                        [
                            290.1040412726786,
                            202.36512231870518
                        ],
                        [
                            291.02584518414096,
                            203.05829596306438
                        ],
                        [
                            290.5986677617559,
                            203.97469987046992
                        ],
                        [
                            289.7892789614475,
                            203.59477915918285
                        ],
                        [
                            289.0248562056008,
                            203.70652813366212
                        ],
                        [
                            288.7550599388312,
                            202.83471821043156
                        ],
                        [
                            286.68662189359884,
                            202.52166672601396
                        ],
                        [
                            285.6074368265208,
                            204.73432222357246
                        ],
                        [
                            283.381617625673,
                            204.26517817618077
                        ],
                        [
                            282.90947415882624,
                            206.45356701032227
                        ],
                        [
                            281.15579842482475,
                            206.9668189460379
                        ],
                        [
                            280.7061379802092,
                            207.59146869830084
                        ],
                        [
                            280.88600215805513,
                            209.82076489392534
                        ],
                        [
                            280.1890284689009,
                            210.3108766766228
                        ],
                        [
                            279.334673624131,
                            213.93844011300024
                        ],
                        [
                            277.8732771791297,
                            213.87173407912496
                        ],
                        [
                            277.2887186011292,
                            215.22765805627142
                        ],
                        [
                            274.9504842891274,
                            214.0940789664739
                        ],
                        [
                            273.4666048218953,
                            214.84986885326907
                        ],
                        [
                            272.8595632216641,
                            214.24970584253288
                        ],
                        [
                            271.2183025988163,
                            215.8275315924002
                        ],
                        [
                            270.52132890966186,
                            218.04774503376967
                        ],
                        [
                            269.01496642019924,
                            216.84913051593702
                        ],
                        [
                            267.93578135312123,
                            216.53826343599422
                        ],
                        [
                            268.2955097088138,
                            215.0054376419355
                        ],
                        [
                            265.82237726342737,
                            214.316399410119
                        ],
                        [
                            265.7549281967349,
                            212.78189019270235
                        ],
                        [
                            264.6757431296569,
                            211.46884679468383
                        ],
                        [
                            262.8771013511939,
                            212.13660301011572
                        ],
                        [
                            262.4274409065781,
                            211.69145683424563
                        ],
                        [
                            260.0217575278839,
                            210.82313816905747
                        ],
                        [
                            258.9650554830366,
                            213.3824891955212
                        ],
                        [
                            259.1224366386523,
                            214.51646692505085
                        ],
                        [
                            257.1439306823429,
                            215.4720723871876
                        ],
                        [
                            254.67079823695622,
                            215.1165508941275
                        ],
                        [
                            254.041273614494,
                            215.8275315924002
                        ],
                        [
                            253.41174899203202,
                            215.62759344941736
                        ],
                        [
                            250.4664730797988,
                            218.09212463847297
                        ],
                        [
                            249.58963521279793,
                            217.3597376898589
                        ],
                        [
                            247.74602738987323,
                            218.04774503376967
                        ],
                        [
                            247.52119716756556,
                            216.5826759270039
                        ],
                        [
                            246.26214792264136,
                            215.49429041158243
                        ],
                        [
                            243.06955876586926,
                            217.60389589547043
                        ],
                        [
                            241.7655434764838,
                            219.42306483265537
                        ],
                        [
                            241.22595094294456,
                            218.6246046880375
                        ],
                        [
                            239.49475823117405,
                            217.71486723352376
                        ],
                        [
                            239.26992800886592,
                            216.16071809324785
                        ],
                        [
                            238.23570898624962,
                            215.56094302507728
                        ],
                        [
                            235.8300256075554,
                            215.44985411948062
                        ],
                        [
                            234.59345938486194,
                            213.69384055756348
                        ],
                        [
                            233.53675734001513,
                            215.20543711093757
                        ],
                        [
                            232.5924704063218,
                            215.4720723871876
                        ],
                        [
                            230.32168516101228,
                            215.93859983188412
                        ],
                        [
                            227.15157902647115,
                            215.58316007640576
                        ],
                        [
                            227.46634133770203,
                            215.18321592207457
                        ],
                        [
                            227.55627342662524,
                            213.67160276611997
                        ],
                        [
                            226.949231826394,
                            212.18111221241656
                        ],
                        [
                            225.57776747031585,
                            211.44658443479636
                        ],
                        [
                            224.92575982562312,
                            207.747600522261
                        ],
                        [
                            224.90327680339237,
                            204.91301450679902
                        ],
                        [
                            227.66868853777942,
                            202.87943579019833
                        ],
                        [
                            229.30994916062673,
                            202.38748656767723
                        ],
                        [
                            230.65893049447413,
                            201.22420824429963
                        ],
                        [
                            231.2210060502439,
                            201.42559416036562
                        ],
                        [
                            232.2102590283987,
                            200.84375624283257
                        ],
                        [
                            233.44682525109192,
                            198.82836668351786
                        ],
                        [
                            235.650161429709,
                            197.55087712615182
                        ],
                        [
                            235.56022934078578,
                            196.5866020947537
                        ],
                        [
                            236.52699929670985,
                            196.00331988104625
                        ],
                        [
                            237.49376925263368,
                            197.01273606343653
                        ],
                        [
                            239.15751289771197,
                            197.03516157986718
                        ],
                        [
                            239.40482614225084,
                            195.80137365201693
                        ],
                        [
                            242.66486436571495,
                            196.83332262062044
                        ],
                        [
                            243.29438898817716,
                            195.98088244922798
                        ],
                        [
                            243.65411734386976,
                            195.0158271675752
                        ],
                        [
                            241.40581512079075,
                            193.53364274333688
                        ],
                        [
                            239.67462240902023,
                            192.27513850395394
                        ],
                        [
                            238.82026756425034,
                            192.49993170747035
                        ],
                        [
                            235.29043307401662,
                            189.9807444587998
                        ],
                        [
                            235.7400935186322,
                            188.02158700415328
                        ],
                        [
                            237.06659183024885,
                            187.27793186024098
                        ],
                        [
                            236.7743125412485,
                            185.451362948079
                        ],
                        [
                            233.10957991763007,
                            184.27782320613
                        ],
                        [
                            233.22199502878402,
                            183.37460469415583
                        ],
                        [
                            235.22298400732416,
                            182.5613384056578
                        ],
                        [
                            236.12230489655576,
                            181.27294833814375
                        ],
                        [
                            238.59543734194244,
                            181.77032623342234
                        ],
                        [
                            240.61890934271332,
                            181.2503371251491
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Jharkhand",
        "properties": {
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            287.9681541607538,
                            219.9551986717517
                        ],
                        [
                            287.8557390495996,
                            221.41785565680897
                        ],
                        [
                            285.0453612707513,
                            224.34005821213282
                        ],
                        [
                            283.5165157590577,
                            223.4992416180525
                        ],
                        [
                            282.01015326959487,
                            223.6541545501911
                        ],
                        [
                            282.90947415882624,
                            225.6448612694977
                        ],
                        [
                            282.01015326959487,
                            226.37430927608494
                        ],
                        [
                            280.4813077579013,
                            226.41851003224383
                        ],
                        [
                            279.8293001132083,
                            227.0814089104237
                        ],
                        [
                            277.3336846455909,
                            225.954355289621
                        ],
                        [
                            275.64745797828164,
                            226.72788907314225
                        ],
                        [
                            276.029669356205,
                            227.83243980999953
                        ],
                        [
                            275.64745797828164,
                            228.89225968643308
                        ],
                        [
                            274.34344268889595,
                            228.89225968643308
                        ],
                        [
                            271.0159553987394,
                            229.9074177354044
                        ],
                        [
                            269.2397966425069,
                            231.5835621281135
                        ],
                        [
                            268.4304078421985,
                            233.5006735451497
                        ],
                        [
                            266.4743849081199,
                            232.57538843244515
                        ],
                        [
                            266.2045886413507,
                            231.14260080664485
                        ],
                        [
                            265.0804375298112,
                            231.0323460631859
                        ],
                        [
                            264.4509129073492,
                            232.11259410308043
                        ],
                        [
                            262.18012766203947,
                            232.35502281608967
                        ],
                        [
                            261.7979162841159,
                            233.21431896631873
                        ],
                        [
                            262.1576446398087,
                            234.49160255690322
                        ],
                        [
                            261.21335770611563,
                            235.79012120680818
                        ],
                        [
                            262.18012766203947,
                            237.41765498687136
                        ],
                        [
                            264.5183619740412,
                            237.76939141161546
                        ],
                        [
                            267.41867184181297,
                            240.14210614537546
                        ],
                        [
                            271.2407856210473,
                            240.18602064442905
                        ],
                        [
                            270.2740156651232,
                            241.2616468650195
                        ],
                        [
                            270.02670242058457,
                            243.43322129176278
                        ],
                        [
                            272.09514046581717,
                            244.66062630068006
                        ],
                        [
                            273.30922366627965,
                            245.55882432749638
                        ],
                        [
                            273.5565369108185,
                            246.85070634133768
                        ],
                        [
                            275.1303484669734,
                            246.85070634133768
                        ],
                        [
                            275.64745797828164,
                            248.25121047799308
                        ],
                        [
                            275.10786544474263,
                            249.23540678551217
                        ],
                        [
                            276.2994656229746,
                            249.65082459930676
                        ],
                        [
                            276.1196014451282,
                            250.83105999601986
                        ],
                        [
                            274.56827291120385,
                            252.57840366039642
                        ],
                        [
                            272.0276913991247,
                            251.26802475502126
                        ],
                        [
                            271.1733365543548,
                            250.50327991829283
                        ],
                        [
                            270.1840835762,
                            250.9840074508607
                        ],
                        [
                            269.1498645535837,
                            250.45957224480526
                        ],
                        [
                            268.02571344204466,
                            248.88552063486793
                        ],
                        [
                            265.62003006335,
                            248.27308628169772
                        ],
                        [
                            264.8556073075033,
                            247.15714289738588
                        ],
                        [
                            263.3267617958097,
                            248.29496186813037
                        ],
                        [
                            264.1361505961179,
                            249.23540678551217
                        ],
                        [
                            264.29353175173355,
                            250.69995378116596
                        ],
                        [
                            263.48414295142516,
                            252.0761829820002
                        ],
                        [
                            264.33849779619527,
                            252.99319623514305
                        ],
                        [
                            264.0012524627334,
                            254.17165970201722
                        ],
                        [
                            262.35999183988565,
                            256.2651758670818
                        ],
                        [
                            260.69624819480737,
                            256.24337846140685
                        ],
                        [
                            260.17913868349933,
                            254.82609341554814
                        ],
                        [
                            259.2123687275755,
                            255.0878133931747
                        ],
                        [
                            255.00804357041784,
                            253.4079116870576
                        ],
                        [
                            253.20940179195486,
                            254.69522196799147
                        ],
                        [
                            252.66980925841608,
                            255.8509890366209
                        ],
                        [
                            251.81545441364574,
                            254.84790458056392
                        ],
                        [
                            249.58963521279793,
                            254.06256881444784
                        ],
                        [
                            250.30909192418312,
                            251.92331071393085
                        ],
                        [
                            250.19667681302917,
                            248.2074582187015
                        ],
                        [
                            248.55541619018186,
                            248.9292594374598
                        ],
                        [
                            246.37456303379508,
                            248.79804042796673
                        ],
                        [
                            245.49772516679445,
                            249.34473483659679
                        ],
                        [
                            242.3276190322531,
                            249.21354052577732
                        ],
                        [
                            240.776290498329,
                            250.4158637089795
                        ],
                        [
                            238.75281849755788,
                            250.56883981193528
                        ],
                        [
                            237.358871119249,
                            250.00058958377485
                        ],
                        [
                            236.0998218743248,
                            248.4262108249671
                        ],
                        [
                            234.63842542932343,
                            247.61671758132377
                        ],
                        [
                            234.6159424070927,
                            246.8944856115864
                        ],
                        [
                            235.6951274741707,
                            245.86544157465636
                        ],
                        [
                            236.70686347455626,
                            245.88734116252078
                        ],
                        [
                            237.96591271948046,
                            245.01118655356788
                        ],
                        [
                            238.77530151978885,
                            243.71821626484558
                        ],
                        [
                            239.22496196440443,
                            242.46834553154054
                        ],
                        [
                            240.30414703148222,
                            241.23970066169045
                        ],
                        [
                            239.96690169802037,
                            240.4275344081202
                        ],
                        [
                            237.0890748524796,
                            240.405579728637
                        ],
                        [
                            235.40284818517057,
                            238.27491142164945
                        ],
                        [
                            235.020636807247,
                            237.35169797588736
                        ],
                        [
                            235.56022934078578,
                            234.75577223713685
                        ],
                        [
                            233.91896871793847,
                            233.91912222297583
                        ],
                        [
                            234.68339147378515,
                            232.02443131085795
                        ],
                        [
                            234.7058744960159,
                            229.92948093797713
                        ],
                        [
                            233.55924036224587,
                            230.98824255043223
                        ],
                        [
                            231.15355698355143,
                            230.32657898710443
                        ],
                        [
                            230.1418209831661,
                            226.94884599338133
                        ],
                        [
                            228.16331502685648,
                            226.2638032816111
                        ],
                        [
                            227.33144320431734,
                            224.36218035573995
                        ],
                        [
                            226.11736000385486,
                            222.6802232120328
                        ],
                        [
                            224.610997514392,
                            222.41452584882916
                        ],
                        [
                            225.780114670393,
                            219.75566467309886
                        ],
                        [
                            225.35293724800817,
                            219.0016941412979
                        ],
                        [
                            225.6452165370083,
                            216.02745005059455
                        ],
                        [
                            227.15157902647115,
                            215.58316007640576
                        ],
                        [
                            230.32168516101228,
                            215.93859983188412
                        ],
                        [
                            232.5924704063218,
                            215.4720723871876
                        ],
                        [
                            233.53675734001513,
                            215.20543711093757
                        ],
                        [
                            234.59345938486194,
                            213.69384055756348
                        ],
                        [
                            235.8300256075554,
                            215.44985411948062
                        ],
                        [
                            238.23570898624962,
                            215.56094302507728
                        ],
                        [
                            239.26992800886592,
                            216.16071809324785
                        ],
                        [
                            239.49475823117405,
                            217.71486723352376
                        ],
                        [
                            241.22595094294456,
                            218.6246046880375
                        ],
                        [
                            241.7655434764838,
                            219.42306483265537
                        ],
                        [
                            243.06955876586926,
                            217.60389589547043
                        ],
                        [
                            246.26214792264136,
                            215.49429041158243
                        ],
                        [
                            247.52119716756556,
                            216.5826759270039
                        ],
                        [
                            247.74602738987323,
                            218.04774503376967
                        ],
                        [
                            249.58963521279793,
                            217.3597376898589
                        ],
                        [
                            250.4664730797988,
                            218.09212463847297
                        ],
                        [
                            253.41174899203202,
                            215.62759344941736
                        ],
                        [
                            254.041273614494,
                            215.8275315924002
                        ],
                        [
                            254.67079823695622,
                            215.1165508941275
                        ],
                        [
                            257.1439306823429,
                            215.4720723871876
                        ],
                        [
                            259.1224366386523,
                            214.51646692505085
                        ],
                        [
                            258.9650554830366,
                            213.3824891955212
                        ],
                        [
                            260.0217575278839,
                            210.82313816905747
                        ],
                        [
                            262.4274409065781,
                            211.69145683424563
                        ],
                        [
                            262.8771013511939,
                            212.13660301011572
                        ],
                        [
                            264.6757431296569,
                            211.46884679468383
                        ],
                        [
                            265.7549281967349,
                            212.78189019270235
                        ],
                        [
                            265.82237726342737,
                            214.316399410119
                        ],
                        [
                            268.2955097088138,
                            215.0054376419355
                        ],
                        [
                            267.93578135312123,
                            216.53826343599422
                        ],
                        [
                            269.01496642019924,
                            216.84913051593702
                        ],
                        [
                            270.52132890966186,
                            218.04774503376967
                        ],
                        [
                            271.2183025988163,
                            215.8275315924002
                        ],
                        [
                            272.8595632216641,
                            214.24970584253288
                        ],
                        [
                            273.4666048218953,
                            214.84986885326907
                        ],
                        [
                            274.9504842891274,
                            214.0940789664739
                        ],
                        [
                            277.2887186011292,
                            215.22765805627142
                        ],
                        [
                            277.8732771791297,
                            213.87173407912496
                        ],
                        [
                            279.334673624131,
                            213.93844011300024
                        ],
                        [
                            280.1890284689009,
                            210.3108766766228
                        ],
                        [
                            280.88600215805513,
                            209.82076489392534
                        ],
                        [
                            280.7061379802092,
                            207.59146869830084
                        ],
                        [
                            281.15579842482475,
                            206.9668189460379
                        ],
                        [
                            282.90947415882624,
                            206.45356701032227
                        ],
                        [
                            283.381617625673,
                            204.26517817618077
                        ],
                        [
                            285.6074368265208,
                            204.73432222357246
                        ],
                        [
                            286.68662189359884,
                            202.52166672601396
                        ],
                        [
                            288.7550599388312,
                            202.83471821043156
                        ],
                        [
                            289.0248562056008,
                            203.70652813366212
                        ],
                        [
                            290.01410918375564,
                            203.840618554123
                        ],
                        [
                            290.0365922059864,
                            205.5606389046763
                        ],
                        [
                            290.2614224282943,
                            206.4758850682062
                        ],
                        [
                            292.59965674029627,
                            208.327411674482
                        ],
                        [
                            292.68958882921925,
                            209.17452506761623
                        ],
                        [
                            291.6778528288339,
                            210.24405031093966
                        ],
                        [
                            290.913430072987,
                            212.02532569834705
                        ],
                        [
                            291.92516607337257,
                            212.40364346664177
                        ],
                        [
                            291.65536980660295,
                            214.9387667664539
                        ],
                        [
                            290.23893940606354,
                            214.64983428338735
                        ],
                        [
                            290.3513545172175,
                            217.89240881806478
                        ],
                        [
                            288.95740713890837,
                            219.86651929204368
                        ],
                        [
                            287.9681541607538,
                            219.9551986717517
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Ladakh",
        "properties": {
            "st_nm": "Ladakh",
            "st_code": "38",
            "alpha2code": "LA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            107.78921400321519,
                            49.17832166734854
                        ],
                        [
                            107.65431586983073,
                            48.713026461248546
                        ],
                        [
                            106.8449270695221,
                            47.880030415525084
                        ],
                        [
                            107.38451960306134,
                            46.188104728666985
                        ],
                        [
                            105.65332689129059,
                            44.420560940001224
                        ],
                        [
                            104.07951533513506,
                            44.1011965092199
                        ],
                        [
                            102.43825471228774,
                            44.46968782249576
                        ],
                        [
                            101.04430733397885,
                            43.8063374672231
                        ],
                        [
                            98.21144653289934,
                            43.65888593266857
                        ],
                        [
                            96.27790662105167,
                            42.4787445411356
                        ],
                        [
                            95.71583106528215,
                            41.91292619234332
                        ],
                        [
                            92.1410305305867,
                            41.593019937985446
                        ],
                        [
                            93.51249488666463,
                            41.37150576994429
                        ],
                        [
                            94.16450253135758,
                            40.41122658758843
                        ],
                        [
                            95.7832801319746,
                            40.04172185287791
                        ],
                        [
                            96.07555942097451,
                            37.600661431359185
                        ],
                        [
                            94.59167995374264,
                            35.798085324476006
                        ],
                        [
                            93.04035141981831,
                            36.73668909486253
                        ],
                        [
                            91.1517775524319,
                            37.1070252429389
                        ],
                        [
                            89.60044901850756,
                            36.02044055302349
                        ],
                        [
                            84.4293539054263,
                            33.37469421895037
                        ],
                        [
                            83.4850669717332,
                            33.86959023936231
                        ],
                        [
                            83.0354065271174,
                            31.343863350633796
                        ],
                        [
                            84.40687088319555,
                            29.48391236652924
                        ],
                        [
                            84.27197274981086,
                            28.317128004557162
                        ],
                        [
                            82.06863657119357,
                            27.472485575609653
                        ],
                        [
                            78.7186662588058,
                            27.621575908864884
                        ],
                        [
                            74.7391713239565,
                            24.811124136396074
                        ],
                        [
                            74.98648456849492,
                            22.593723518538468
                        ],
                        [
                            74.04219763480205,
                            21.845514780716144
                        ],
                        [
                            71.68148030056932,
                            22.518920159167465
                        ],
                        [
                            71.276785900415,
                            21.870461346258594
                        ],
                        [
                            68.17412883256611,
                            22.74331857051402
                        ],
                        [
                            65.88086056502561,
                            21.22170983656929
                        ],
                        [
                            66.78018145425744,
                            19.22371000213741
                        ],
                        [
                            65.83589452056435,
                            18.37371576899409
                        ],
                        [
                            66.78018145425744,
                            14.943606856039992
                        ],
                        [
                            69.47814412195203,
                            14.065976695122458
                        ],
                        [
                            71.00698963364539,
                            12.660641038962012
                        ],
                        [
                            70.9620235891839,
                            11.630852103039047
                        ],
                        [
                            72.60328421203167,
                            11.37957062940552
                        ],
                        [
                            74.3794429682639,
                            8.587347799984173
                        ],
                        [
                            74.58179016834083,
                            7.176562581921644
                        ],
                        [
                            77.34720190272787,
                            6.823645711432164
                        ],
                        [
                            79.21329274788332,
                            6.117546703150879
                        ],
                        [
                            83.0354065271174,
                            7.226972066739904
                        ],
                        [
                            84.60921808327248,
                            6.647154133549861
                        ],
                        [
                            85.4186068835811,
                            5.335380983938421
                        ],
                        [
                            83.37265186057925,
                            4.477021425574435
                        ],
                        [
                            82.87802537150174,
                            3.036829193296626
                        ],
                        [
                            85.64343710588878,
                            3.3654241873783803
                        ],
                        [
                            88.25146768466016,
                            4.982002274372007
                        ],
                        [
                            89.53299995181533,
                            4.376003478836424
                        ],
                        [
                            89.9152113297389,
                            3.2137745228911854
                        ],
                        [
                            91.26419266358607,
                            3.517057464263644
                        ],
                        [
                            93.73732510897275,
                            1.620461960669104
                        ],
                        [
                            95.24368759843537,
                            1.6710711692519453
                        ],
                        [
                            96.05307639874377,
                            0.6078932823550076
                        ],
                        [
                            97.85171817720698,
                            -1.1368683772161603e-13
                        ],
                        [
                            98.23392955513009,
                            1.038324510707298
                        ],
                        [
                            99.78525808905488,
                            1.013008692723929
                        ],
                        [
                            100.97685826728639,
                            3.1632209918844865
                        ],
                        [
                            102.39328866782625,
                            1.8734897144871638
                        ],
                        [
                            103.5399228015965,
                            2.480569910103327
                        ],
                        [
                            105.20366644667479,
                            0.75982531231125
                        ],
                        [
                            107.18217240298418,
                            0.4559447428854355
                        ],
                        [
                            107.29458751413813,
                            1.7722840991913245
                        ],
                        [
                            108.35128955898517,
                            2.1517674566573533
                        ],
                        [
                            109.09322929260111,
                            4.906266710568389
                        ],
                        [
                            111.18415036006468,
                            5.814824437557718
                        ],
                        [
                            113.14017329414332,
                            8.990170517877345
                        ],
                        [
                            115.70323782845298,
                            10.550025534075075
                        ],
                        [
                            117.97402307376274,
                            11.077974055100526
                        ],
                        [
                            120.58205365253389,
                            13.388577614560518
                        ],
                        [
                            121.99848405307375,
                            13.16270617370742
                        ],
                        [
                            123.19008423130526,
                            14.116141572180027
                        ],
                        [
                            123.90954094269068,
                            15.870799655081896
                        ],
                        [
                            125.6632166766924,
                            16.697247037228067
                        ],
                        [
                            126.29274129915439,
                            16.37173400031793
                        ],
                        [
                            127.41689241069366,
                            18.198654268137147
                        ],
                        [
                            128.6084925889254,
                            18.84877476101599
                        ],
                        [
                            127.46185845515538,
                            20.572665113038624
                        ],
                        [
                            128.76587374454107,
                            22.54385504447214
                        ],
                        [
                            130.5869985452348,
                            23.640562545304306
                        ],
                        [
                            133.23999516846789,
                            23.391384183404398
                        ],
                        [
                            133.98193490208382,
                            24.53724807140452
                        ],
                        [
                            137.87149774801014,
                            25.98073689692319
                        ],
                        [
                            138.36612423708766,
                            27.447635694429096
                        ],
                        [
                            137.9389468147026,
                            28.466131465904596
                        ],
                        [
                            140.54697739347398,
                            29.335013396218415
                        ],
                        [
                            142.165754994091,
                            29.21091925418341
                        ],
                        [
                            143.5597023723999,
                            28.540627455111917
                        ],
                        [
                            144.77378557286238,
                            29.359830951742197
                        ],
                        [
                            145.74055552878622,
                            27.472485575609653
                        ],
                        [
                            146.84222361809498,
                            27.199113423099107
                        ],
                        [
                            148.2586540186344,
                            28.06875486417755
                        ],
                        [
                            149.157974907866,
                            25.70708262742778
                        ],
                        [
                            150.237159974944,
                            24.761332355114973
                        ],
                        [
                            151.9009036200223,
                            24.512347668798384
                        ],
                        [
                            152.46297917579182,
                            23.615646648654092
                        ],
                        [
                            153.90189259856243,
                            24.01424930324788
                        ],
                        [
                            156.0152966882565,
                            22.294486738018236
                        ],
                        [
                            158.33104797802775,
                            22.34436385824131
                        ],
                        [
                            160.35451997879886,
                            21.670876695915922
                        ],
                        [
                            161.25384086803024,
                            22.469049091870488
                        ],
                        [
                            162.87261846864703,
                            22.693455281324987
                        ],
                        [
                            163.79442238010938,
                            21.77067248589458
                        ],
                        [
                            164.10918469134026,
                            20.0981774741565
                        ],
                        [
                            166.49238504780396,
                            19.798420159464513
                        ],
                        [
                            167.14439269249692,
                            21.59602530474058
                        ],
                        [
                            173.73191820611783,
                            23.665478011052983
                        ],
                        [
                            175.620492073504,
                            22.668522988723453
                        ],
                        [
                            177.21678665189006,
                            25.50802877818819
                        ],
                        [
                            179.91474931958487,
                            26.82624999339498
                        ],
                        [
                            180.45434185312342,
                            29.31019541643866
                        ],
                        [
                            178.9479793636608,
                            33.82010818342826
                        ],
                        [
                            178.3184547411986,
                            34.78470619075506
                        ],
                        [
                            178.20603963004464,
                            37.72404454794969
                        ],
                        [
                            176.6996771405818,
                            40.85451004693152
                        ],
                        [
                            176.6547110961203,
                            42.89681895263084
                        ],
                        [
                            174.8111032731956,
                            43.48684058340018
                        ],
                        [
                            173.57453705050239,
                            43.3393572266541
                        ],
                        [
                            172.33797082780893,
                            44.44512458474571
                        ],
                        [
                            172.60776709457832,
                            46.997526616750065
                        ],
                        [
                            169.6624911823451,
                            46.70324235716225
                        ],
                        [
                            168.40344193742112,
                            47.169165557064844
                        ],
                        [
                            169.64000816011435,
                            50.59780030948525
                        ],
                        [
                            168.38095891519015,
                            51.96705417758608
                        ],
                        [
                            168.20109473734396,
                            53.21296553512548
                        ],
                        [
                            166.1551397143421,
                            54.97014115819752
                        ],
                        [
                            163.83938842457087,
                            54.70181663811411
                        ],
                        [
                            162.91758451310852,
                            55.14086822048182
                        ],
                        [
                            161.25384086803024,
                            54.70181663811411
                        ],
                        [
                            159.54513117849024,
                            56.042961304102846
                        ],
                        [
                            160.93907855679913,
                            59.086590720615845
                        ],
                        [
                            161.61356922372283,
                            59.76752483940447
                        ],
                        [
                            162.51289011295444,
                            62.391125843137274
                        ],
                        [
                            160.7816974011837,
                            62.488209319185785
                        ],
                        [
                            159.68002931187516,
                            63.14336087915535
                        ],
                        [
                            159.94982557864455,
                            65.6400605402414
                        ],
                        [
                            160.7816974011837,
                            67.38290755556807
                        ],
                        [
                            162.55785615741615,
                            68.10850912256831
                        ],
                        [
                            164.46891304703308,
                            68.01178207462499
                        ],
                        [
                            167.61653615934347,
                            68.7129151229044
                        ],
                        [
                            166.46990202557322,
                            71.75551127855681
                        ],
                        [
                            167.14439269249692,
                            73.92512571740326
                        ],
                        [
                            169.28027980442175,
                            76.98153242932756
                        ],
                        [
                            170.1571176714226,
                            77.79876388548615
                        ],
                        [
                            167.52660407042026,
                            81.42306874600308
                        ],
                        [
                            165.63803020303408,
                            80.36784747903289
                        ],
                        [
                            163.79442238010938,
                            82.28590314150932
                        ],
                        [
                            162.94006753533927,
                            82.38174432709638
                        ],
                        [
                            162.6253052241084,
                            84.0101500161054
                        ],
                        [
                            161.43370504587665,
                            83.96227980826217
                        ],
                        [
                            160.66928229002974,
                            84.7997982543663
                        ],
                        [
                            158.8481574893358,
                            83.02851927297297
                        ],
                        [
                            157.65655731110428,
                            80.96749228521412
                        ],
                        [
                            157.43172708879615,
                            79.2879069299322
                        ],
                        [
                            155.9703306437948,
                            79.2879069299322
                        ],
                        [
                            152.98008868710008,
                            80.5837460863412
                        ],
                        [
                            152.10325082009945,
                            81.5429352661858
                        ],
                        [
                            150.84420157517548,
                            80.70367688304464
                        ],
                        [
                            152.2156659312534,
                            79.88796604314695
                        ],
                        [
                            151.94586966448378,
                            77.48634369150966
                        ],
                        [
                            149.4727372190971,
                            79.11984905002049
                        ],
                        [
                            148.3485861075576,
                            78.80769353942111
                        ],
                        [
                            147.69657846286486,
                            79.69597220535945
                        ],
                        [
                            146.48249526240215,
                            79.88796604314695
                        ],
                        [
                            145.04358183963177,
                            78.66360069822059
                        ],
                        [
                            145.29089508417064,
                            78.03904447629282
                        ],
                        [
                            143.4697702834767,
                            74.86434805482429
                        ],
                        [
                            142.4355512608604,
                            73.78057941221118
                        ],
                        [
                            140.4120792600895,
                            74.23826309741764
                        ],
                        [
                            138.43357330378012,
                            75.73081783944122
                        ],
                        [
                            137.51176939231777,
                            75.2976434304428
                        ],
                        [
                            136.52251641416296,
                            76.4525067757196
                        ],
                        [
                            135.57822948046987,
                            75.4420483335183
                        ],
                        [
                            133.75710467977592,
                            73.63601960847268
                        ],
                        [
                            131.68866663454355,
                            73.44325219621123
                        ],
                        [
                            130.56451552300405,
                            72.7442688729434
                        ],
                        [
                            129.8675418338496,
                            71.10403215067163
                        ],
                        [
                            128.85580583346427,
                            70.42813324983808
                        ],
                        [
                            128.2037981887711,
                            68.83376778306638
                        ],
                        [
                            124.22430325392179,
                            64.16192588598028
                        ],
                        [
                            121.39144245284228,
                            63.1918794136177
                        ],
                        [
                            120.82936689707276,
                            61.92989470587679
                        ],
                        [
                            120.73943480814955,
                            60.180799903666184
                        ],
                        [
                            119.34548742984066,
                            59.23253094553536
                        ],
                        [
                            117.54684565137768,
                            56.8714359911105
                        ],
                        [
                            117.16463427345434,
                            55.652933286405755
                        ],
                        [
                            114.84888298368287,
                            55.99421332157408
                        ],
                        [
                            112.24085240491172,
                            52.773350273745336
                        ],
                        [
                            110.89187107106432,
                            52.08924813068256
                        ],
                        [
                            109.45295764829393,
                            51.453730717698704
                        ],
                        [
                            108.21639142560048,
                            50.573337958388606
                        ],
                        [
                            107.78921400321519,
                            49.17832166734854
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Jammu and Kashmir",
        "properties": {
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            107.65431586983073,
                            48.713026461248546
                        ],
                        [
                            107.78921400321519,
                            49.17832166734854
                        ],
                        [
                            108.21639142560048,
                            50.573337958388606
                        ],
                        [
                            109.45295764829393,
                            51.453730717698704
                        ],
                        [
                            110.89187107106432,
                            52.08924813068256
                        ],
                        [
                            112.24085240491172,
                            52.773350273745336
                        ],
                        [
                            114.84888298368287,
                            55.99421332157408
                        ],
                        [
                            117.16463427345434,
                            55.652933286405755
                        ],
                        [
                            117.54684565137768,
                            56.8714359911105
                        ],
                        [
                            119.34548742984066,
                            59.23253094553536
                        ],
                        [
                            120.73943480814955,
                            60.180799903666184
                        ],
                        [
                            120.82936689707276,
                            61.92989470587679
                        ],
                        [
                            121.39144245284228,
                            63.1918794136177
                        ],
                        [
                            124.22430325392179,
                            64.16192588598028
                        ],
                        [
                            128.2037981887711,
                            68.83376778306638
                        ],
                        [
                            127.84406983307872,
                            70.09007254820813
                        ],
                        [
                            126.33770734361565,
                            70.40398851811904
                        ],
                        [
                            125.21355623207637,
                            69.55868423644347
                        ],
                        [
                            122.87532192007438,
                            69.96931858990149
                        ],
                        [
                            121.14412920830364,
                            71.41774163535979
                        ],
                        [
                            120.10991018568734,
                            72.86480578518058
                        ],
                        [
                            118.49113258507077,
                            73.12995391464801
                        ],
                        [
                            118.13140422937818,
                            73.99739380976803
                        ],
                        [
                            116.17538129529953,
                            75.46611451101239
                        ],
                        [
                            114.64653578360571,
                            74.47909493760369
                        ],
                        [
                            113.92707907222075,
                            75.24950547619221
                        ],
                        [
                            115.72572085068373,
                            77.65457773821737
                        ],
                        [
                            115.63578876176052,
                            79.64797005916375
                        ],
                        [
                            114.44418858352878,
                            81.78264080016584
                        ],
                        [
                            112.84789400514273,
                            82.81295723342777
                        ],
                        [
                            112.24085240491172,
                            83.89047176438032
                        ],
                        [
                            110.91435409329483,
                            84.10588606130119
                        ],
                        [
                            109.6777878706016,
                            85.3260104983832
                        ],
                        [
                            107.15968938075343,
                            84.65625530931538
                        ],
                        [
                            105.15870040221307,
                            83.41166763925168
                        ],
                        [
                            103.71978697944269,
                            82.66923276206631
                        ],
                        [
                            101.35906964520996,
                            82.90876623794958
                        ],
                        [
                            100.50471480044007,
                            81.61485077657295
                        ],
                        [
                            100.21243551143971,
                            82.21401840921243
                        ],
                        [
                            97.44702377705266,
                            82.11816697405959
                        ],
                        [
                            96.81749915459068,
                            80.00795021391701
                        ],
                        [
                            97.13226146582178,
                            76.95748971230643
                        ],
                        [
                            94.99637435389673,
                            77.72667248041842
                        ],
                        [
                            94.00712137574214,
                            76.93344662368224
                        ],
                        [
                            93.06283444204905,
                            77.48634369150966
                        ],
                        [
                            90.05010946312336,
                            75.2976434304428
                        ],
                        [
                            88.31891675135262,
                            74.84027253966997
                        ],
                        [
                            86.81255426188977,
                            73.46734943608578
                        ],
                        [
                            84.92398039450359,
                            73.10585141800277
                        ],
                        [
                            82.85554234927099,
                            71.70726299935961
                        ],
                        [
                            81.95622146003961,
                            71.6590132115141
                        ],
                        [
                            82.38339888242444,
                            69.58284223280737
                        ],
                        [
                            81.03441754857727,
                            67.2377460317474
                        ],
                        [
                            81.86628937111618,
                            63.14336087915535
                        ],
                        [
                            80.98945150411555,
                            62.318309170878365
                        ],
                        [
                            81.52904403765456,
                            60.69116073294845
                        ],
                        [
                            80.9669684818848,
                            59.57300346157649
                        ],
                        [
                            81.39414590426986,
                            57.67510909065527
                        ],
                        [
                            80.40489292611528,
                            55.872336467055106
                        ],
                        [
                            79.91026643703776,
                            53.04201934623245
                        ],
                        [
                            79.97771550373022,
                            51.69819239932076
                        ],
                        [
                            78.47135301426738,
                            48.95793676279209
                        ],
                        [
                            79.30322483680652,
                            45.40278954661551
                        ],
                        [
                            81.82132332665469,
                            45.18184479861884
                        ],
                        [
                            83.7548632385026,
                            41.593019937985446
                        ],
                        [
                            86.90248635081298,
                            40.6082579107599
                        ],
                        [
                            88.52126395142977,
                            38.85868333764142
                        ],
                        [
                            88.40884884027582,
                            36.90952426538581
                        ],
                        [
                            89.60044901850756,
                            36.02044055302349
                        ],
                        [
                            91.1517775524319,
                            37.1070252429389
                        ],
                        [
                            93.04035141981831,
                            36.73668909486253
                        ],
                        [
                            94.59167995374264,
                            35.798085324476006
                        ],
                        [
                            96.07555942097451,
                            37.600661431359185
                        ],
                        [
                            95.7832801319746,
                            40.04172185287791
                        ],
                        [
                            94.16450253135758,
                            40.41122658758843
                        ],
                        [
                            93.51249488666463,
                            41.37150576994429
                        ],
                        [
                            92.1410305305867,
                            41.593019937985446
                        ],
                        [
                            95.71583106528215,
                            41.91292619234332
                        ],
                        [
                            96.27790662105167,
                            42.4787445411356
                        ],
                        [
                            98.21144653289934,
                            43.65888593266857
                        ],
                        [
                            101.04430733397885,
                            43.8063374672231
                        ],
                        [
                            102.43825471228774,
                            44.46968782249576
                        ],
                        [
                            104.07951533513506,
                            44.1011965092199
                        ],
                        [
                            105.65332689129059,
                            44.420560940001224
                        ],
                        [
                            107.38451960306134,
                            46.188104728666985
                        ],
                        [
                            106.8449270695221,
                            47.880030415525084
                        ],
                        [
                            107.65431586983073,
                            48.713026461248546
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Himachal Pradesh",
        "properties": {
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            150.8217185529445,
                            103.0434857042705
                        ],
                        [
                            149.8774316192514,
                            103.96561999969339
                        ],
                        [
                            147.76402752955732,
                            104.72185228434887
                        ],
                        [
                            145.76303855101696,
                            104.9344792717958
                        ],
                        [
                            144.25667606155434,
                            106.04441375043041
                        ],
                        [
                            143.73956655024608,
                            107.41307954173863
                        ],
                        [
                            143.40232121678423,
                            110.42953698318485
                        ],
                        [
                            141.89595872732139,
                            111.95921188357568
                        ],
                        [
                            143.58218539463064,
                            115.43687846380374
                        ],
                        [
                            143.53721935016893,
                            116.37553481048968
                        ],
                        [
                            140.0073848599352,
                            118.15752119008204
                        ],
                        [
                            140.07483392662766,
                            118.53243306865761
                        ],
                        [
                            139.24296210408852,
                            117.43088900364017
                        ],
                        [
                            137.53425241454852,
                            117.71220363196386
                        ],
                        [
                            134.61145952454604,
                            116.68048380076033
                        ],
                        [
                            133.35241027962184,
                            115.38993169112189
                        ],
                        [
                            134.07186699100703,
                            114.73253709535277
                        ],
                        [
                            134.0044179243148,
                            113.32295396423399
                        ],
                        [
                            131.91349685685122,
                            112.2179393338422
                        ],
                        [
                            130.3172022784654,
                            109.62883177232561
                        ],
                        [
                            129.62022858931095,
                            110.09988213639912
                        ],
                        [
                            128.27124725546355,
                            109.55816255202666
                        ],
                        [
                            127.61923961077105,
                            108.99269911544303
                        ],
                        [
                            126.02294503238477,
                            107.93192878106038
                        ],
                        [
                            126.33770734361565,
                            104.320147682536
                        ],
                        [
                            125.48335249884599,
                            103.20903556689939
                        ],
                        [
                            124.15685418722933,
                            103.27998033778812
                        ],
                        [
                            123.19008423130526,
                            102.31013310405513
                        ],
                        [
                            122.605525653305,
                            100.81874744128868
                        ],
                        [
                            121.09916316384215,
                            102.64136567919769
                        ],
                        [
                            119.79514787445646,
                            102.7123352828504
                        ],
                        [
                            118.62603071845524,
                            99.34968978476525
                        ],
                        [
                            117.09718520676188,
                            97.04863556603516
                        ],
                        [
                            116.26531338422274,
                            94.45897474189468
                        ],
                        [
                            116.26531338422274,
                            93.15067291470228
                        ],
                        [
                            115.52337365060657,
                            91.53165183778276
                        ],
                        [
                            111.76870893806495,
                            89.33858650989373
                        ],
                        [
                            110.9368371155258,
                            89.24316710757074
                        ],
                        [
                            110.9818031599873,
                            89.05231107819549
                        ],
                        [
                            111.85864102698793,
                            88.28865706723838
                        ],
                        [
                            111.5213956935263,
                            86.30621034144457
                        ],
                        [
                            113.47741862760495,
                            85.66078137836263
                        ],
                        [
                            114.98378111706756,
                            83.79472260487671
                        ],
                        [
                            115.83813596183768,
                            83.8186604413624
                        ],
                        [
                            114.44418858352878,
                            81.78264080016584
                        ],
                        [
                            115.63578876176052,
                            79.64797005916375
                        ],
                        [
                            115.72572085068373,
                            77.65457773821737
                        ],
                        [
                            113.92707907222075,
                            75.24950547619221
                        ],
                        [
                            114.64653578360571,
                            74.47909493760369
                        ],
                        [
                            116.17538129529953,
                            75.46611451101239
                        ],
                        [
                            118.13140422937818,
                            73.99739380976803
                        ],
                        [
                            118.49113258507077,
                            73.12995391464801
                        ],
                        [
                            120.10991018568734,
                            72.86480578518058
                        ],
                        [
                            121.14412920830364,
                            71.41774163535979
                        ],
                        [
                            122.87532192007438,
                            69.96931858990149
                        ],
                        [
                            125.21355623207637,
                            69.55868423644347
                        ],
                        [
                            126.33770734361565,
                            70.40398851811904
                        ],
                        [
                            127.84406983307872,
                            70.09007254820813
                        ],
                        [
                            128.2037981887711,
                            68.83376778306638
                        ],
                        [
                            128.85580583346427,
                            70.42813324983808
                        ],
                        [
                            129.8675418338496,
                            71.10403215067163
                        ],
                        [
                            130.56451552300405,
                            72.7442688729434
                        ],
                        [
                            131.68866663454355,
                            73.44325219621123
                        ],
                        [
                            133.75710467977592,
                            73.63601960847268
                        ],
                        [
                            135.57822948046987,
                            75.4420483335183
                        ],
                        [
                            136.52251641416296,
                            76.4525067757196
                        ],
                        [
                            137.51176939231777,
                            75.2976434304428
                        ],
                        [
                            138.43357330378012,
                            75.73081783944122
                        ],
                        [
                            140.4120792600895,
                            74.23826309741764
                        ],
                        [
                            142.4355512608604,
                            73.78057941221118
                        ],
                        [
                            143.4697702834767,
                            74.86434805482429
                        ],
                        [
                            145.29089508417064,
                            78.03904447629282
                        ],
                        [
                            145.04358183963177,
                            78.66360069822059
                        ],
                        [
                            146.48249526240215,
                            79.88796604314695
                        ],
                        [
                            147.69657846286486,
                            79.69597220535945
                        ],
                        [
                            148.3485861075576,
                            78.80769353942111
                        ],
                        [
                            149.4727372190971,
                            79.11984905002049
                        ],
                        [
                            151.94586966448378,
                            77.48634369150966
                        ],
                        [
                            152.2156659312534,
                            79.88796604314695
                        ],
                        [
                            150.84420157517548,
                            80.70367688304464
                        ],
                        [
                            152.10325082009945,
                            81.5429352661858
                        ],
                        [
                            152.9351226426386,
                            82.81295723342777
                        ],
                        [
                            154.10423979863936,
                            83.48349755673365
                        ],
                        [
                            153.31733402056193,
                            84.63233021284816
                        ],
                        [
                            153.33981704279267,
                            85.8281401694577
                        ],
                        [
                            155.20590788794811,
                            86.9513755199576
                        ],
                        [
                            154.93611162117872,
                            88.00219186966535
                        ],
                        [
                            156.1501948216412,
                            89.31473219745055
                        ],
                        [
                            157.74648940002726,
                            90.79302216022876
                        ],
                        [
                            156.62233828848798,
                            94.57785818903375
                        ],
                        [
                            157.47669313325787,
                            96.31255206239564
                        ],
                        [
                            158.69077633372035,
                            97.45215106835911
                        ],
                        [
                            157.0495157108728,
                            98.68580558858912
                        ],
                        [
                            157.97131962233516,
                            100.29762191271487
                        ],
                        [
                            157.27434593318048,
                            101.6237929590356
                        ],
                        [
                            157.88138753341195,
                            102.68867909347927
                        ],
                        [
                            159.2078858450284,
                            103.0434857042705
                        ],
                        [
                            160.7367313567222,
                            104.9344792717958
                        ],
                        [
                            161.07397669018383,
                            106.02080599378047
                        ],
                        [
                            159.27533491172085,
                            106.23326350001224
                        ],
                        [
                            158.15118380018157,
                            104.50919750259402
                        ],
                        [
                            156.12771179941046,
                            104.53282718477067
                        ],
                        [
                            155.20590788794811,
                            103.98925758492987
                        ],
                        [
                            153.36230006502342,
                            104.50919750259402
                        ],
                        [
                            151.69855641994536,
                            102.9961826405252
                        ],
                        [
                            150.8217185529445,
                            103.0434857042705
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Punjab",
        "properties": {
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            114.44418858352878,
                            81.78264080016584
                        ],
                        [
                            115.83813596183768,
                            83.8186604413624
                        ],
                        [
                            114.98378111706756,
                            83.79472260487671
                        ],
                        [
                            113.47741862760495,
                            85.66078137836263
                        ],
                        [
                            111.5213956935263,
                            86.30621034144457
                        ],
                        [
                            111.85864102698793,
                            88.28865706723838
                        ],
                        [
                            110.9818031599873,
                            89.05231107819549
                        ],
                        [
                            110.9368371155258,
                            89.24316710757074
                        ],
                        [
                            111.76870893806495,
                            89.33858650989373
                        ],
                        [
                            115.52337365060657,
                            91.53165183778276
                        ],
                        [
                            116.26531338422274,
                            93.15067291470228
                        ],
                        [
                            116.26531338422274,
                            94.45897474189468
                        ],
                        [
                            117.09718520676188,
                            97.04863556603516
                        ],
                        [
                            118.62603071845524,
                            99.34968978476525
                        ],
                        [
                            119.79514787445646,
                            102.7123352828504
                        ],
                        [
                            121.09916316384215,
                            102.64136567919769
                        ],
                        [
                            122.605525653305,
                            100.81874744128868
                        ],
                        [
                            123.19008423130526,
                            102.31013310405513
                        ],
                        [
                            124.15685418722933,
                            103.27998033778812
                        ],
                        [
                            125.48335249884599,
                            103.20903556689939
                        ],
                        [
                            126.33770734361565,
                            104.320147682536
                        ],
                        [
                            126.02294503238477,
                            107.93192878106038
                        ],
                        [
                            127.61923961077105,
                            108.99269911544303
                        ],
                        [
                            128.27124725546355,
                            109.55816255202666
                        ],
                        [
                            129.2829832558491,
                            110.75912568444716
                        ],
                        [
                            129.17056814469515,
                            112.12386132629348
                        ],
                        [
                            127.28199427730874,
                            112.0062562609989
                        ],
                        [
                            127.7091716996938,
                            113.18192947641796
                        ],
                        [
                            129.0131869890797,
                            113.36995944955044
                        ],
                        [
                            130.4521004118501,
                            114.59163285058702
                        ],
                        [
                            130.18230414508048,
                            117.17297543907432
                        ],
                        [
                            130.04740601169578,
                            117.40744396948793
                        ],
                        [
                            130.24975321177294,
                            117.73564436989062
                        ],
                        [
                            127.84406983307872,
                            117.68876256369498
                        ],
                        [
                            127.30447729953971,
                            119.44592752306795
                        ],
                        [
                            125.10114112092242,
                            120.87372464136291
                        ],
                        [
                            126.29274129915439,
                            122.44055765559403
                        ],
                        [
                            126.22529223246192,
                            122.48730627901114
                        ],
                        [
                            125.32597134323032,
                            123.25847059801043
                        ],
                        [
                            123.70719374261375,
                            123.30519644722006
                        ],
                        [
                            122.87532192007438,
                            122.51068010213277
                        ],
                        [
                            120.58205365253389,
                            123.46872668535616
                        ],
                        [
                            119.3679704520714,
                            126.22299020024639
                        ],
                        [
                            119.99749507453339,
                            127.69164786603676
                        ],
                        [
                            118.28878538499362,
                            128.20421228558297
                        ],
                        [
                            117.56932867360842,
                            129.27543731921543
                        ],
                        [
                            116.06296618414558,
                            129.5780523161945
                        ],
                        [
                            113.5898337387589,
                            127.99454558844337
                        ],
                        [
                            112.73547889398878,
                            128.13432626945752
                        ],
                        [
                            110.66704084875619,
                            129.39183408431268
                        ],
                        [
                            108.9133651147547,
                            128.25080136178462
                        ],
                        [
                            107.2271384474459,
                            130.1365845891346
                        ],
                        [
                            106.59761382498368,
                            132.43859502012322
                        ],
                        [
                            104.61910786867429,
                            130.62514987529534
                        ],
                        [
                            105.69829293575208,
                            129.20559542975303
                        ],
                        [
                            104.95635320213614,
                            127.62174069602156
                        ],
                        [
                            103.87716813505813,
                            127.83145354710103
                        ],
                        [
                            102.10100937882589,
                            127.22554551373196
                        ],
                        [
                            101.0667903562096,
                            125.82646693581137
                        ],
                        [
                            99.38056368890057,
                            125.10315507679042
                        ],
                        [
                            97.80675213274526,
                            125.40651718097536
                        ],
                        [
                            96.95239728797537,
                            126.47951446254183
                        ],
                        [
                            95.17623853174314,
                            125.98975250270416
                        ],
                        [
                            94.02960439797312,
                            125.87312156713011
                        ],
                        [
                            91.8262682193556,
                            125.75648256996413
                        ],
                        [
                            85.89075035042742,
                            125.42985046644867
                        ],
                        [
                            86.02564848381235,
                            124.12268857663673
                        ],
                        [
                            87.08235052865939,
                            122.95472088509376
                        ],
                        [
                            86.96993541750544,
                            121.69240234347961
                        ],
                        [
                            86.85752030635149,
                            120.38232564417501
                        ],
                        [
                            85.66592012811952,
                            118.86041175484712
                        ],
                        [
                            86.92496937304372,
                            117.75908477749823
                        ],
                        [
                            86.90248635081298,
                            116.72739405857305
                        ],
                        [
                            90.61218501889289,
                            113.20543439450836
                        ],
                        [
                            92.8155211975104,
                            109.74660704111204
                        ],
                        [
                            96.21045755435944,
                            107.27154661542465
                        ],
                        [
                            97.85171817720698,
                            106.68169404820247
                        ],
                        [
                            97.58192191043759,
                            105.71387404084538
                        ],
                        [
                            94.95140830943524,
                            105.6430353804256
                        ],
                        [
                            95.55844990966648,
                            101.64746470461534
                        ],
                        [
                            97.01984635466783,
                            100.06069107617088
                        ],
                        [
                            96.43528777666711,
                            99.32598434313269
                        ],
                        [
                            94.83899319828129,
                            95.17214297540556
                        ],
                        [
                            95.67086502082066,
                            94.86314242526413
                        ],
                        [
                            95.85072919866684,
                            93.46000460602579
                        ],
                        [
                            97.24467657697573,
                            92.0080052692386
                        ],
                        [
                            99.29063159997736,
                            91.31724628620015
                        ],
                        [
                            100.86444315613267,
                            89.91098244958312
                        ],
                        [
                            102.3258396011338,
                            90.05404918961057
                        ],
                        [
                            103.94461720175059,
                            89.26702249631381
                        ],
                        [
                            104.7090399575975,
                            89.5055566542701
                        ],
                        [
                            107.72176493652296,
                            87.50075297597738
                        ],
                        [
                            107.7442479587537,
                            85.99548120594443
                        ],
                        [
                            107.15968938075343,
                            84.65625530931538
                        ],
                        [
                            109.6777878706016,
                            85.3260104983832
                        ],
                        [
                            110.91435409329483,
                            84.10588606130119
                        ],
                        [
                            112.24085240491172,
                            83.89047176438032
                        ],
                        [
                            112.84789400514273,
                            82.81295723342777
                        ],
                        [
                            114.44418858352878,
                            81.78264080016584
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Uttarakhand",
        "properties": {
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            177.14933758519783,
                            111.53575198992178
                        ],
                        [
                            178.20603963004464,
                            111.20631895110603
                        ],
                        [
                            179.33019074158415,
                            112.28849431440818
                        ],
                        [
                            178.79059820804537,
                            115.03778847601637
                        ],
                        [
                            181.80332318697083,
                            116.04706552339536
                        ],
                        [
                            183.6019649654338,
                            117.36055290924469
                        ],
                        [
                            184.59121794358862,
                            116.96192547323142
                        ],
                        [
                            186.07509741082072,
                            117.87628186154672
                        ],
                        [
                            187.4690447891296,
                            119.46934387887973
                        ],
                        [
                            190.3468716346706,
                            120.42913177263642
                        ],
                        [
                            191.02136230159408,
                            121.41175794929154
                        ],
                        [
                            189.31265261205408,
                            122.01976138595461
                        ],
                        [
                            186.86200318889814,
                            124.14603965546218
                        ],
                        [
                            185.58047092174343,
                            125.7098247132264
                        ],
                        [
                            184.5012858546654,
                            125.7098247132264
                        ],
                        [
                            183.8043121655112,
                            127.52852664481651
                        ],
                        [
                            182.05063643150947,
                            128.29738915722186
                        ],
                        [
                            181.03890043112415,
                            129.39183408431268
                        ],
                        [
                            181.66842505358613,
                            131.8343275813533
                        ],
                        [
                            180.67917207543155,
                            133.27492019960215
                        ],
                        [
                            179.2177756304302,
                            134.3893847227717
                        ],
                        [
                            180.3419267419697,
                            136.80157628657497
                        ],
                        [
                            179.46508887496884,
                            138.72426744580866
                        ],
                        [
                            177.7788622076598,
                            140.15909908653663
                        ],
                        [
                            177.39665082973625,
                            142.19359639870856
                        ],
                        [
                            176.74464318504351,
                            142.86353408128753
                        ],
                        [
                            176.60974505165882,
                            144.61801145150974
                        ],
                        [
                            176.09263554035056,
                            146.09410009431065
                        ],
                        [
                            174.99096745104202,
                            146.53207438155283
                        ],
                        [
                            173.01246149473263,
                            143.76407850423834
                        ],
                        [
                            170.98898949396175,
                            144.3180285641797
                        ],
                        [
                            169.55007607119114,
                            144.4564886087245
                        ],
                        [
                            167.16687571472767,
                            143.602476591019
                        ],
                        [
                            166.94204549241954,
                            142.67874944981673
                        ],
                        [
                            164.76119233603322,
                            142.00874057959822
                        ],
                        [
                            163.05248264649322,
                            140.73732394611335
                        ],
                        [
                            163.20986380210888,
                            139.92775509321194
                        ],
                        [
                            161.47867109033814,
                            139.0252179003624
                        ],
                        [
                            160.57935020110654,
                            139.67324098968385
                        ],
                        [
                            159.88237651195232,
                            139.88148258510347
                        ],
                        [
                            159.11795375610518,
                            138.4232645502476
                        ],
                        [
                            157.43172708879615,
                            137.42726538470652
                        ],
                        [
                            158.7582254004128,
                            136.01334779309036
                        ],
                        [
                            159.85989348972134,
                            134.4822240383554
                        ],
                        [
                            158.01628566679688,
                            134.04119247998375
                        ],
                        [
                            155.31832299910207,
                            132.46183180410338
                        ],
                        [
                            154.0817567764086,
                            131.09031979261152
                        ],
                        [
                            153.76699446517773,
                            129.64787889024302
                        ],
                        [
                            151.1814469086371,
                            128.4138530436337
                        ],
                        [
                            149.2479069967892,
                            129.438390556466
                        ],
                        [
                            148.16872192971164,
                            130.88100905616938
                        ],
                        [
                            146.54994432909461,
                            132.06676345644382
                        ],
                        [
                            145.1784799730167,
                            129.83406905945645
                        ],
                        [
                            143.60466841686116,
                            130.50883753210553
                        ],
                        [
                            142.0758229051678,
                            126.92251010493908
                        ],
                        [
                            143.334872150092,
                            123.35192099671212
                        ],
                        [
                            144.18922699486188,
                            123.0481881877322
                        ],
                        [
                            145.650623439863,
                            120.87372464136291
                        ],
                        [
                            142.165754994091,
                            119.37567648359311
                        ],
                        [
                            141.2214680603979,
                            118.15752119008204
                        ],
                        [
                            140.0073848599352,
                            118.15752119008204
                        ],
                        [
                            143.53721935016893,
                            116.37553481048968
                        ],
                        [
                            143.58218539463064,
                            115.43687846380374
                        ],
                        [
                            141.89595872732139,
                            111.95921188357568
                        ],
                        [
                            143.40232121678423,
                            110.42953698318485
                        ],
                        [
                            143.73956655024608,
                            107.41307954173863
                        ],
                        [
                            144.25667606155434,
                            106.04441375043041
                        ],
                        [
                            145.76303855101696,
                            104.9344792717958
                        ],
                        [
                            147.76402752955732,
                            104.72185228434887
                        ],
                        [
                            149.8774316192514,
                            103.96561999969339
                        ],
                        [
                            150.8217185529445,
                            103.0434857042705
                        ],
                        [
                            151.69855641994536,
                            102.9961826405252
                        ],
                        [
                            153.36230006502342,
                            104.50919750259402
                        ],
                        [
                            155.20590788794811,
                            103.98925758492987
                        ],
                        [
                            156.12771179941046,
                            104.53282718477067
                        ],
                        [
                            158.15118380018157,
                            104.50919750259402
                        ],
                        [
                            159.27533491172085,
                            106.23326350001224
                        ],
                        [
                            161.07397669018383,
                            106.02080599378047
                        ],
                        [
                            160.7367313567222,
                            104.9344792717958
                        ],
                        [
                            159.2078858450284,
                            103.0434857042705
                        ],
                        [
                            160.12968975649073,
                            101.6237929590356
                        ],
                        [
                            161.05149366795308,
                            101.4107316651909
                        ],
                        [
                            162.01826362387692,
                            100.08438572457953
                        ],
                        [
                            162.98503357980098,
                            100.46345281633813
                        ],
                        [
                            163.41221100218604,
                            101.7184778641564
                        ],
                        [
                            164.37898095810988,
                            102.19181934549187
                        ],
                        [
                            164.22159980249444,
                            103.44550607733402
                        ],
                        [
                            165.30078486957223,
                            104.1783458894518
                        ],
                        [
                            165.63803020303408,
                            105.59580789574449
                        ],
                        [
                            166.94204549241954,
                            106.65809551250425
                        ],
                        [
                            168.40344193742112,
                            107.36590326173149
                        ],
                        [
                            169.7973893157298,
                            109.06339271365539
                        ],
                        [
                            171.70844620534695,
                            108.28559527553597
                        ],
                        [
                            173.66446913942536,
                            108.37989343279736
                        ],
                        [
                            174.0241974951182,
                            109.32257648889066
                        ],
                        [
                            176.45236389604315,
                            110.594339598023
                        ],
                        [
                            177.14933758519783,
                            111.53575198992178
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    },
    {
        "type": "Feature",
        "id": "TT-Chandigarh",
        "properties": {
            "st_nm": "Chandigarh",
            "st_code": "04",
            "alpha2code": "CH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            129.0131869890797,
                            113.36995944955044
                        ],
                        [
                            127.7091716996938,
                            113.18192947641796
                        ],
                        [
                            127.28199427730874,
                            112.0062562609989
                        ],
                        [
                            129.17056814469515,
                            112.12386132629348
                        ],
                        [
                            129.0131869890797,
                            113.36995944955044
                        ]
                    ]
                ]
            ]
        },
        "value": 0
    }
];

export const DISTRICT_WISE_MAP_PLOT = [
    {
        "type": "Feature",
        "properties": {
            "district": "Aizawl",
            "dt_code": "261",
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        367.4456377465908,
                        233.5226992181682
                    ],
                    [
                        366.0067243238202,
                        231.87013756891713
                    ],
                    [
                        365.03995436789637,
                        231.78196465413998
                    ],
                    [
                        363.57855792289547,
                        235.30602039906069
                    ],
                    [
                        362.2295765890476,
                        234.8438215050307
                    ],
                    [
                        361.9822633445092,
                        233.96316465173658
                    ],
                    [
                        361.7124670777398,
                        231.8040082280745
                    ],
                    [
                        362.00474636673994,
                        230.10597822020316
                    ],
                    [
                        361.28528965535475,
                        227.32441905483438
                    ],
                    [
                        361.8023991666628,
                        226.74998581885467
                    ],
                    [
                        361.30777267758526,
                        224.80457366734862
                    ],
                    [
                        364.5453278788191,
                        223.63202484150986
                    ],
                    [
                        365.3547166791275,
                        220.48719446129024
                    ],
                    [
                        364.74767507889624,
                        219.0016941412979
                    ],
                    [
                        365.2647845902043,
                        217.75925407821552
                    ],
                    [
                        366.92852823528256,
                        217.40413226614857
                    ],
                    [
                        367.1308754353597,
                        220.28771220215162
                    ],
                    [
                        366.43390174620527,
                        222.19308523570663
                    ],
                    [
                        369.8288381030543,
                        223.30005079705575
                    ],
                    [
                        368.99696628051515,
                        227.1035019110551
                    ],
                    [
                        367.4456377465908,
                        227.83243980999953
                    ],
                    [
                        367.46812076882156,
                        229.42196863275268
                    ],
                    [
                        367.4456377465908,
                        233.5226992181682
                    ]
                ]
            ]
        },
        "id": "TT-Mizoram-Aizawl",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Champhai",
            "dt_code": "262",
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            367.46812076882156,
                            229.42196863275268
                        ],
                        [
                            367.4456377465908,
                            227.83243980999953
                        ],
                        [
                            368.99696628051515,
                            227.1035019110551
                        ],
                        [
                            369.8288381030543,
                            223.30005079705575
                        ],
                        [
                            370.503328769978,
                            223.7205422561999
                        ],
                        [
                            372.6167328596723,
                            225.24687267504459
                        ],
                        [
                            373.0663933042879,
                            229.6867729644013
                        ],
                        [
                            373.0214272598264,
                            231.64969837594663
                        ],
                        [
                            372.5268007707491,
                            232.13463422627513
                        ],
                        [
                            370.8180910812091,
                            232.3329849912024
                        ],
                        [
                            370.88554014790134,
                            229.55437497387067
                        ],
                        [
                            370.48084574774725,
                            228.25201601959338
                        ],
                        [
                            369.17683045836156,
                            229.51024045486207
                        ],
                        [
                            367.46812076882156,
                            229.42196863275268
                        ]
                    ]
                ],
                [
                    [
                        [
                            369.67145694743886,
                            239.1977278572828
                        ],
                        [
                            369.22179650282305,
                            237.21977785717593
                        ],
                        [
                            367.535569835514,
                            236.6920160239049
                        ],
                        [
                            367.40067170212933,
                            235.17397381222656
                        ],
                        [
                            368.7496530359765,
                            234.93186712868714
                        ],
                        [
                            370.70567597005515,
                            233.8750788802319
                        ],
                        [
                            372.05465730390233,
                            234.7997973266829
                        ],
                        [
                            372.54928379297985,
                            236.56005520728655
                        ],
                        [
                            372.00969125944084,
                            238.91213654592013
                        ],
                        [
                            371.20030245913244,
                            240.0103572813149
                        ],
                        [
                            369.67145694743886,
                            239.1977278572828
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Mizoram-Champhai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kolasib",
            "dt_code": "263",
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        365.2647845902043,
                        217.75925407821552
                    ],
                    [
                        364.74767507889624,
                        219.0016941412979
                    ],
                    [
                        365.3547166791275,
                        220.48719446129024
                    ],
                    [
                        364.5453278788191,
                        223.63202484150986
                    ],
                    [
                        361.30777267758526,
                        224.80457366734862
                    ],
                    [
                        361.3752217442777,
                        223.5877647140225
                    ],
                    [
                        360.0037573881998,
                        222.28166433429038
                    ],
                    [
                        359.9138252992768,
                        221.196307865403
                    ],
                    [
                        360.1836215660462,
                        220.06604250396128
                    ],
                    [
                        361.0829424552776,
                        219.93302918637352
                    ],
                    [
                        361.28528965535475,
                        218.71333787437902
                    ],
                    [
                        362.2295765890476,
                        218.358382012636
                    ],
                    [
                        363.2862786338949,
                        215.7831025955789
                    ],
                    [
                        363.84835418966463,
                        217.29314401206744
                    ],
                    [
                        365.2647845902043,
                        217.75925407821552
                    ]
                ]
            ]
        },
        "id": "TT-Mizoram-Kolasib",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lawngtlai",
            "dt_code": "264",
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        365.82686014597425,
                        247.20091605700287
                    ],
                    [
                        365.78189410151253,
                        249.73827103171578
                    ],
                    [
                        364.230565567588,
                        250.76550785766307
                    ],
                    [
                        364.65774298997303,
                        255.00057679457007
                    ],
                    [
                        362.45440681135574,
                        253.60432896332463
                    ],
                    [
                        362.20709356681687,
                        255.37130891461868
                    ],
                    [
                        360.9030782774312,
                        255.82918761626541
                    ],
                    [
                        360.9930103663546,
                        254.04074999855038
                    ],
                    [
                        360.633282010662,
                        250.8966101966913
                    ],
                    [
                        359.576579965815,
                        244.22234675038993
                    ],
                    [
                        360.92556129966215,
                        244.55106467114013
                    ],
                    [
                        361.6225349888168,
                        245.90924053126577
                    ],
                    [
                        363.1963465449717,
                        247.39788448122584
                    ],
                    [
                        364.38794672320364,
                        246.0844275949662
                    ],
                    [
                        365.82686014597425,
                        247.20091605700287
                    ]
                ]
            ]
        },
        "id": "TT-Mizoram-Lawngtlai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lunglei",
            "dt_code": "265",
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        361.9822633445092,
                        233.96316465173658
                    ],
                    [
                        362.2295765890476,
                        234.8438215050307
                    ],
                    [
                        362.2520596112786,
                        236.20811983777628
                    ],
                    [
                        364.0956674342033,
                        236.4940717426607
                    ],
                    [
                        364.79264112335795,
                        237.28573893281117
                    ],
                    [
                        365.1523694790503,
                        240.93243041966747
                    ],
                    [
                        364.65774298997303,
                        242.00768537087794
                    ],
                    [
                        365.62451294589687,
                        243.5866847626076
                    ],
                    [
                        366.11913943497416,
                        246.04063214317983
                    ],
                    [
                        365.82686014597425,
                        247.20091605700287
                    ],
                    [
                        364.38794672320364,
                        246.0844275949662
                    ],
                    [
                        363.1963465449717,
                        247.39788448122584
                    ],
                    [
                        361.6225349888168,
                        245.90924053126577
                    ],
                    [
                        360.92556129966215,
                        244.55106467114013
                    ],
                    [
                        359.576579965815,
                        244.22234675038993
                    ],
                    [
                        358.67725907658337,
                        242.0734999593161
                    ],
                    [
                        357.55310796504386,
                        241.10801876359346
                    ],
                    [
                        357.8453872540442,
                        239.17576064138086
                    ],
                    [
                        357.1708965871205,
                        236.29610911790297
                    ],
                    [
                        358.00276840965967,
                        235.83412491994062
                    ],
                    [
                        358.7671911655066,
                        235.50407493099118
                    ],
                    [
                        359.1044364989682,
                        233.85305686607296
                    ],
                    [
                        360.29603667720016,
                        234.18336309067217
                    ],
                    [
                        361.9822633445092,
                        233.96316465173658
                    ]
                ]
            ]
        },
        "id": "TT-Mizoram-Lunglei",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mamit",
            "dt_code": "266",
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        361.30777267758526,
                        224.80457366734862
                    ],
                    [
                        361.8023991666628,
                        226.74998581885467
                    ],
                    [
                        361.28528965535475,
                        227.32441905483438
                    ],
                    [
                        362.00474636673994,
                        230.10597822020316
                    ],
                    [
                        361.7124670777398,
                        231.8040082280745
                    ],
                    [
                        361.9822633445092,
                        233.96316465173658
                    ],
                    [
                        360.29603667720016,
                        234.18336309067217
                    ],
                    [
                        359.1044364989682,
                        233.85305686607296
                    ],
                    [
                        358.7671911655066,
                        235.50407493099118
                    ],
                    [
                        358.00276840965967,
                        235.83412491994062
                    ],
                    [
                        357.4406928538899,
                        234.93186712868714
                    ],
                    [
                        356.09171152004274,
                        229.7970982469957
                    ],
                    [
                        355.9792964088888,
                        228.45073327985256
                    ],
                    [
                        355.86688129773506,
                        227.0593156758572
                    ],
                    [
                        356.8561342758894,
                        225.44587649969725
                    ],
                    [
                        356.67627009804323,
                        223.2115153828487
                    ],
                    [
                        356.9460663648126,
                        221.10768206720206
                    ],
                    [
                        356.42895685350436,
                        220.11037835966232
                    ],
                    [
                        358.2950476986598,
                        220.02170568994188
                    ],
                    [
                        358.49739489873696,
                        221.572924919763
                    ],
                    [
                        359.9138252992768,
                        221.196307865403
                    ],
                    [
                        360.0037573881998,
                        222.28166433429038
                    ],
                    [
                        361.3752217442777,
                        223.5877647140225
                    ],
                    [
                        361.30777267758526,
                        224.80457366734862
                    ]
                ]
            ]
        },
        "id": "TT-Mizoram-Mamit",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Saiha",
            "dt_code": "267",
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        364.65774298997303,
                        255.00057679457007
                    ],
                    [
                        364.230565567588,
                        250.76550785766307
                    ],
                    [
                        365.78189410151253,
                        249.73827103171578
                    ],
                    [
                        365.82686014597425,
                        247.20091605700287
                    ],
                    [
                        366.11913943497416,
                        246.04063214317983
                    ],
                    [
                        365.62451294589687,
                        243.5866847626076
                    ],
                    [
                        366.88356219082107,
                        244.02509219700977
                    ],
                    [
                        368.2100605024375,
                        243.4989926765191
                    ],
                    [
                        368.165094457976,
                        244.7482716429098
                    ],
                    [
                        368.8845511693612,
                        246.741254343901
                    ],
                    [
                        368.72717001374576,
                        248.51370578623175
                    ],
                    [
                        369.5590418362849,
                        249.25727282871833
                    ],
                    [
                        369.71642299190034,
                        251.5956918893612
                    ],
                    [
                        369.1543474361306,
                        253.1459951051233
                    ],
                    [
                        367.535569835514,
                        252.81855613663242
                    ],
                    [
                        366.77114707966734,
                        255.15323861573285
                    ],
                    [
                        365.89430921266626,
                        255.74197982002136
                    ],
                    [
                        364.65774298997303,
                        255.00057679457007
                    ]
                ]
            ]
        },
        "id": "TT-Mizoram-Saiha",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Serchhip",
            "dt_code": "268",
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        362.2295765890476,
                        234.8438215050307
                    ],
                    [
                        363.57855792289547,
                        235.30602039906069
                    ],
                    [
                        365.03995436789637,
                        231.78196465413998
                    ],
                    [
                        366.0067243238202,
                        231.87013756891713
                    ],
                    [
                        367.4456377465908,
                        233.5226992181682
                    ],
                    [
                        367.40067170212933,
                        235.17397381222656
                    ],
                    [
                        367.535569835514,
                        236.6920160239049
                    ],
                    [
                        369.22179650282305,
                        237.21977785717593
                    ],
                    [
                        369.67145694743886,
                        239.1977278572828
                    ],
                    [
                        368.5922718803613,
                        239.9883983544513
                    ],
                    [
                        366.20907152389736,
                        238.2309578892378
                    ],
                    [
                        365.69196201258933,
                        237.10983821305052
                    ],
                    [
                        364.79264112335795,
                        237.28573893281117
                    ],
                    [
                        364.0956674342033,
                        236.4940717426607
                    ],
                    [
                        362.2520596112786,
                        236.20811983777628
                    ],
                    [
                        362.2295765890476,
                        234.8438215050307
                    ]
                ]
            ]
        },
        "id": "TT-Mizoram-Serchhip",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Khawzawl",
            "dt_code": "996",
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        367.4456377465908,
                        233.5226992181682
                    ],
                    [
                        367.46812076882156,
                        229.42196863275268
                    ],
                    [
                        369.17683045836156,
                        229.51024045486207
                    ],
                    [
                        370.48084574774725,
                        228.25201601959338
                    ],
                    [
                        370.88554014790134,
                        229.55437497387067
                    ],
                    [
                        370.8180910812091,
                        232.3329849912024
                    ],
                    [
                        372.5268007707491,
                        232.13463422627513
                    ],
                    [
                        372.70666494859506,
                        233.8750788802319
                    ],
                    [
                        372.05465730390233,
                        234.7997973266829
                    ],
                    [
                        370.70567597005515,
                        233.8750788802319
                    ],
                    [
                        368.7496530359765,
                        234.93186712868714
                    ],
                    [
                        367.40067170212933,
                        235.17397381222656
                    ],
                    [
                        367.4456377465908,
                        233.5226992181682
                    ]
                ]
            ]
        },
        "id": "TT-Mizoram-Khawzawl",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hnahthial",
            "dt_code": "994",
            "st_nm": "Mizoram",
            "st_code": "15",
            "alpha2code": "MZ",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        365.62451294589687,
                        243.5866847626076
                    ],
                    [
                        364.65774298997303,
                        242.00768537087794
                    ],
                    [
                        365.1523694790503,
                        240.93243041966747
                    ],
                    [
                        364.79264112335795,
                        237.28573893281117
                    ],
                    [
                        365.69196201258933,
                        237.10983821305052
                    ],
                    [
                        366.20907152389736,
                        238.2309578892378
                    ],
                    [
                        368.5922718803613,
                        239.9883983544513
                    ],
                    [
                        368.9295172138229,
                        241.23970066169045
                    ],
                    [
                        368.2100605024375,
                        243.4989926765191
                    ],
                    [
                        366.88356219082107,
                        244.02509219700977
                    ],
                    [
                        365.62451294589687,
                        243.5866847626076
                    ]
                ]
            ]
        },
        "id": "TT-Mizoram-Hnahthial",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Thiruvallur",
            "dt_code": "602",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        177.53154896312094,
                        394.40898397258684
                    ],
                    [
                        176.83457527396672,
                        394.67868468674453
                    ],
                    [
                        175.5755260290423,
                        394.22225668685724
                    ],
                    [
                        174.0466805173487,
                        395.75729337401526
                    ],
                    [
                        173.0349445169636,
                        395.4254468049935
                    ],
                    [
                        172.56280105011683,
                        394.5542098521778
                    ],
                    [
                        171.79837829427015,
                        393.9317734443283
                    ],
                    [
                        171.77589527203918,
                        392.395984955731
                    ],
                    [
                        170.4718799826535,
                        391.9185155622175
                    ],
                    [
                        170.22456673811485,
                        392.72810121302587
                    ],
                    [
                        168.0437135817283,
                        392.8111256228637
                    ],
                    [
                        167.57157011488198,
                        393.51675809061504
                    ],
                    [
                        166.11017366988062,
                        393.9732724360496
                    ],
                    [
                        165.12092069172581,
                        393.06018769518107
                    ],
                    [
                        167.39170593703557,
                        392.0845988743573
                    ],
                    [
                        166.9195624701888,
                        389.86261367117527
                    ],
                    [
                        168.8531023820367,
                        389.8002956033107
                    ],
                    [
                        168.80813633757498,
                        390.75572264400955
                    ],
                    [
                        170.98898949396175,
                        390.4441977459441
                    ],
                    [
                        171.28126878296212,
                        391.56556368987776
                    ],
                    [
                        172.6302501168093,
                        391.295636495247
                    ],
                    [
                        172.85508033911697,
                        390.13264634981897
                    ],
                    [
                        174.85606931765733,
                        389.4263648472654
                    ],
                    [
                        176.20505065150473,
                        386.7869808065768
                    ],
                    [
                        176.81209225173575,
                        387.5353482202108
                    ],
                    [
                        178.8130812302761,
                        387.51456233296904
                    ],
                    [
                        179.59998700835354,
                        386.391946395858
                    ],
                    [
                        180.45434185312342,
                        388.20043351815
                    ],
                    [
                        180.72413811989304,
                        390.75572264400955
                    ],
                    [
                        180.07213047520008,
                        392.76961365051005
                    ],
                    [
                        178.6332170524297,
                        393.01867851138445
                    ],
                    [
                        178.4083868301218,
                        394.57495594572225
                    ],
                    [
                        177.53154896312094,
                        394.40898397258684
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Thiruvallur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chennai",
            "dt_code": "603",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2018"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        177.53154896312094,
                        394.40898397258684
                    ],
                    [
                        178.4083868301218,
                        394.57495594572225
                    ],
                    [
                        178.6332170524297,
                        393.01867851138445
                    ],
                    [
                        180.07213047520008,
                        392.76961365051005
                    ],
                    [
                        179.33019074158415,
                        397.0636551244569
                    ],
                    [
                        178.34093776342934,
                        396.89779296703034
                    ],
                    [
                        178.5432849635065,
                        395.52915200647413
                    ],
                    [
                        177.53154896312094,
                        394.40898397258684
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Chennai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Krishnagiri",
            "dt_code": "631",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        152.30559802017638,
                        400.54509213272604
                    ],
                    [
                        152.6653263758692,
                        404.0440815756465
                    ],
                    [
                        154.59886628771687,
                        404.1475541930279
                    ],
                    [
                        155.7679834437181,
                        406.09233374793604
                    ],
                    [
                        155.54315322140997,
                        407.33318352812057
                    ],
                    [
                        153.182435887177,
                        407.3745385346517
                    ],
                    [
                        148.82072957440437,
                        404.9131669306299
                    ],
                    [
                        148.12375588524992,
                        403.9199108335247
                    ],
                    [
                        146.4150461957097,
                        402.4088510748908
                    ],
                    [
                        145.1784799730167,
                        405.0993745554523
                    ],
                    [
                        143.35735517232274,
                        407.33318352812057
                    ],
                    [
                        142.5254833497836,
                        407.64333564675314
                    ],
                    [
                        140.52449437124324,
                        406.8782501717279
                    ],
                    [
                        138.9731658373189,
                        407.0023280516838
                    ],
                    [
                        138.4785393482416,
                        406.3198518522173
                    ],
                    [
                        139.6476565042426,
                        405.5958852977216
                    ],
                    [
                        140.5919434379357,
                        404.4579556927715
                    ],
                    [
                        140.88422272693606,
                        402.6365824500783
                    ],
                    [
                        140.1872490377816,
                        402.2432198855558
                    ],
                    [
                        140.38959623785877,
                        399.92363921631704
                    ],
                    [
                        142.41306823862965,
                        399.8614883998184
                    ],
                    [
                        143.19997401670707,
                        398.70115479630374
                    ],
                    [
                        143.76204957247683,
                        396.9392591880378
                    ],
                    [
                        144.2791590837851,
                        396.87705968605474
                    ],
                    [
                        145.20096299524744,
                        396.56604682416724
                    ],
                    [
                        145.94290272886337,
                        397.3953576450434
                    ],
                    [
                        147.08953686263362,
                        397.1465834776219
                    ],
                    [
                        148.2136879741729,
                        398.203761094275
                    ],
                    [
                        149.6526013969435,
                        398.53536410504523
                    ],
                    [
                        150.6868204195598,
                        399.9650725353037
                    ],
                    [
                        152.30559802017638,
                        400.54509213272604
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Krishnagiri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tiruvannamalai",
            "dt_code": "606",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        155.54315322140997,
                        407.33318352812057
                    ],
                    [
                        155.7679834437181,
                        406.09233374793604
                    ],
                    [
                        156.68978735518044,
                        405.40970107890365
                    ],
                    [
                        157.4092440665654,
                        403.31969677509755
                    ],
                    [
                        158.87064051156676,
                        400.4415236148303
                    ],
                    [
                        159.45519908956726,
                        399.6128750709018
                    ],
                    [
                        160.53438415664505,
                        399.88220545040457
                    ],
                    [
                        161.90584851272297,
                        398.7426013437869
                    ],
                    [
                        164.01925260241728,
                        398.1623086882274
                    ],
                    [
                        167.57157011488198,
                        399.88220545040457
                    ],
                    [
                        167.9088154483436,
                        396.8148591610814
                    ],
                    [
                        169.19034771549855,
                        397.1258515596049
                    ],
                    [
                        171.91079340542387,
                        398.78404744134775
                    ],
                    [
                        171.8433443387314,
                        399.53000038067285
                    ],
                    [
                        170.22456673811485,
                        400.4829513561066
                    ],
                    [
                        171.596031094193,
                        401.14573469880634
                    ],
                    [
                        171.88831038319313,
                        401.97405414903693
                    ],
                    [
                        171.34871784965412,
                        403.7336473482399
                    ],
                    [
                        170.35946487149977,
                        404.78902362873015
                    ],
                    [
                        167.59405313711272,
                        404.3544912499575
                    ],
                    [
                        166.58231713672717,
                        403.31969677509755
                    ],
                    [
                        164.35649793587913,
                        403.31969677509755
                    ],
                    [
                        164.62629420264852,
                        405.12006374907463
                    ],
                    [
                        164.04173562464803,
                        407.47792417825804
                    ],
                    [
                        165.05347162503335,
                        408.0981819424885
                    ],
                    [
                        163.4796600688785,
                        409.524411385821
                    ],
                    [
                        162.80516940195457,
                        409.6897386923263
                    ],
                    [
                        160.35451997879886,
                        408.9457125096651
                    ],
                    [
                        159.68002931187516,
                        409.75173468999833
                    ],
                    [
                        157.38676104433466,
                        409.8137297378879
                    ],
                    [
                        156.8022024663344,
                        410.22700581078766
                    ],
                    [
                        156.30757597725687,
                        408.38760272074063
                    ],
                    [
                        155.54315322140997,
                        407.33318352812057
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Tiruvannamalai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dharmapuri",
            "dt_code": "630",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        142.5254833497836,
                        407.64333564675314
                    ],
                    [
                        143.35735517232274,
                        407.33318352812057
                    ],
                    [
                        145.1784799730167,
                        405.0993745554523
                    ],
                    [
                        146.4150461957097,
                        402.4088510748908
                    ],
                    [
                        148.12375588524992,
                        403.9199108335247
                    ],
                    [
                        148.82072957440437,
                        404.9131669306299
                    ],
                    [
                        153.182435887177,
                        407.3745385346517
                    ],
                    [
                        155.54315322140997,
                        407.33318352812057
                    ],
                    [
                        156.30757597725687,
                        408.38760272074063
                    ],
                    [
                        156.8022024663344,
                        410.22700581078766
                    ],
                    [
                        156.66730433294947,
                        411.5078945956185
                    ],
                    [
                        155.47570415471773,
                        411.9416528579864
                    ],
                    [
                        155.00356068787096,
                        412.64383080474386
                    ],
                    [
                        153.33981704279267,
                        412.7264320825517
                    ],
                    [
                        151.06903179748315,
                        411.569862878476
                    ],
                    [
                        149.697567441405,
                        410.28899358864646
                    ],
                    [
                        146.819740595864,
                        410.9294785862965
                    ],
                    [
                        145.83048761770942,
                        411.5492068885982
                    ],
                    [
                        142.75031357209127,
                        411.3426412454762
                    ],
                    [
                        141.4912643271673,
                        410.7022212632785
                    ],
                    [
                        141.96340779401385,
                        409.44174519680416
                    ],
                    [
                        142.95266077216843,
                        408.28424055267146
                    ],
                    [
                        142.5254833497836,
                        407.64333564675314
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Dharmapuri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Salem",
            "dt_code": "608",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        141.4912643271673,
                        410.7022212632785
                    ],
                    [
                        142.75031357209127,
                        411.3426412454762
                    ],
                    [
                        145.83048761770942,
                        411.5492068885982
                    ],
                    [
                        146.819740595864,
                        410.9294785862965
                    ],
                    [
                        149.697567441405,
                        410.28899358864646
                    ],
                    [
                        151.06903179748315,
                        411.569862878476
                    ],
                    [
                        153.33981704279267,
                        412.7264320825517
                    ],
                    [
                        155.00356068787096,
                        412.64383080474386
                    ],
                    [
                        155.47570415471773,
                        411.9416528579864
                    ],
                    [
                        156.44247411064157,
                        412.21014685103535
                    ],
                    [
                        155.70053437702563,
                        413.4491226022839
                    ],
                    [
                        156.55488922179552,
                        414.60516467492243
                    ],
                    [
                        158.0837347334891,
                        414.6670864096091
                    ],
                    [
                        157.8139384667195,
                        415.78152029723515
                    ],
                    [
                        158.64581028925886,
                        417.1432034091324
                    ],
                    [
                        157.9938026445659,
                        419.2055233233269
                    ],
                    [
                        157.13944779979602,
                        418.23635782625524
                    ],
                    [
                        155.83543251041033,
                        418.6487956594407
                    ],
                    [
                        155.99281366602577,
                        419.3910830600895
                    ],
                    [
                        154.6438323321786,
                        420.2569220782164
                    ],
                    [
                        153.40726610948514,
                        418.8756194165201
                    ],
                    [
                        152.64284335363845,
                        417.6176258754274
                    ],
                    [
                        152.7102924203307,
                        416.0084981638063
                    ],
                    [
                        150.70930344179055,
                        416.15293222155736
                    ],
                    [
                        149.83246557478992,
                        416.6893580599665
                    ],
                    [
                        146.93215570701796,
                        417.2050876242406
                    ],
                    [
                        144.07681188370793,
                        418.75189886570195
                    ],
                    [
                        141.78354361616744,
                        418.03012388230127
                    ],
                    [
                        142.32313614970644,
                        417.3082259655667
                    ],
                    [
                        142.54796637201434,
                        415.0179588328381
                    ],
                    [
                        141.69361152724423,
                        415.20370285631327
                    ],
                    [
                        141.9858908162446,
                        412.82968134741645
                    ],
                    [
                        141.4912643271673,
                        410.7022212632785
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Salem",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Erode",
            "dt_code": "610",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        141.4912643271673,
                        410.7022212632785
                    ],
                    [
                        141.9858908162446,
                        412.82968134741645
                    ],
                    [
                        141.69361152724423,
                        415.20370285631327
                    ],
                    [
                        142.54796637201434,
                        415.0179588328381
                    ],
                    [
                        142.32313614970644,
                        417.3082259655667
                    ],
                    [
                        141.78354361616744,
                        418.03012388230127
                    ],
                    [
                        141.60367943832125,
                        418.73127842430927
                    ],
                    [
                        143.42480423901497,
                        420.9783211940409
                    ],
                    [
                        144.23419303932337,
                        421.30806341803884
                    ],
                    [
                        144.68385348393917,
                        423.82152407090587
                    ],
                    [
                        145.26841206193967,
                        423.92450387560325
                    ],
                    [
                        144.34660815047732,
                        424.6247025233166
                    ],
                    [
                        142.9751437943994,
                        424.1098614336079
                    ],
                    [
                        140.9516717936283,
                        423.1417966200068
                    ],
                    [
                        138.90571677062644,
                        423.36838417261436
                    ],
                    [
                        138.50102237047258,
                        422.2971392888637
                    ],
                    [
                        138.54598841493407,
                        420.46304845823977
                    ],
                    [
                        136.4550673474705,
                        420.2363088961034
                    ],
                    [
                        134.74635765793073,
                        419.70033138151757
                    ],
                    [
                        133.71213863531443,
                        419.94771394773795
                    ],
                    [
                        133.17254610177565,
                        418.89623915885323
                    ],
                    [
                        131.01417596761985,
                        419.2055233233269
                    ],
                    [
                        131.6661836123128,
                        416.8131389156205
                    ],
                    [
                        129.30546627807985,
                        416.0703990837003
                    ],
                    [
                        129.12560210023344,
                        415.16242712180235
                    ],
                    [
                        130.20478716731145,
                        413.0568206154486
                    ],
                    [
                        131.73363267900527,
                        412.767732099406
                    ],
                    [
                        133.17254610177565,
                        413.3871826627191
                    ],
                    [
                        135.8480257472395,
                        412.850330889533
                    ],
                    [
                        137.8490147257794,
                        413.46976904236044
                    ],
                    [
                        138.81578468170324,
                        410.82618137602833
                    ],
                    [
                        141.4912643271673,
                        410.7022212632785
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Erode",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nilgiris",
            "dt_code": "611",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        129.30546627807985,
                        416.0703990837003
                    ],
                    [
                        131.6661836123128,
                        416.8131389156205
                    ],
                    [
                        131.01417596761985,
                        419.2055233233269
                    ],
                    [
                        129.35043232254156,
                        419.88586964570777
                    ],
                    [
                        129.21553418915664,
                        420.6691649543123
                    ],
                    [
                        127.23702823284748,
                        421.3904950394153
                    ],
                    [
                        126.09039409907723,
                        422.07049600164925
                    ],
                    [
                        123.34746538692093,
                        421.9674724130309
                    ],
                    [
                        124.51658254292192,
                        420.9371016437707
                    ],
                    [
                        124.89879392084549,
                        419.5972510926923
                    ],
                    [
                        123.61726165369055,
                        419.1642867307584
                    ],
                    [
                        120.28977436353375,
                        417.0813182858652
                    ],
                    [
                        122.40317845322784,
                        415.9672303758259
                    ],
                    [
                        122.98773703122833,
                        414.91476413112946
                    ],
                    [
                        124.35920138730648,
                        414.4193939349537
                    ],
                    [
                        125.07865809869168,
                        415.65770899972654
                    ],
                    [
                        127.97896796646341,
                        415.7196151065575
                    ],
                    [
                        129.30546627807985,
                        416.0703990837003
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Nilgiris",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Namakkal",
            "dt_code": "609",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        141.78354361616744,
                        418.03012388230127
                    ],
                    [
                        144.07681188370793,
                        418.75189886570195
                    ],
                    [
                        146.93215570701796,
                        417.2050876242406
                    ],
                    [
                        149.83246557478992,
                        416.6893580599665
                    ],
                    [
                        150.70930344179055,
                        416.15293222155736
                    ],
                    [
                        152.7102924203307,
                        416.0084981638063
                    ],
                    [
                        152.64284335363845,
                        417.6176258754274
                    ],
                    [
                        153.40726610948514,
                        418.8756194165201
                    ],
                    [
                        152.86767357594613,
                        420.77221949987114
                    ],
                    [
                        151.0015827307907,
                        424.1098614336079
                    ],
                    [
                        149.63011837471254,
                        423.5743626676183
                    ],
                    [
                        148.6633484187887,
                        424.7894389565587
                    ],
                    [
                        147.74154450732635,
                        423.84212022530346
                    ],
                    [
                        146.10028388447904,
                        423.53316774428924
                    ],
                    [
                        145.26841206193967,
                        423.92450387560325
                    ],
                    [
                        144.68385348393917,
                        423.82152407090587
                    ],
                    [
                        144.23419303932337,
                        421.30806341803884
                    ],
                    [
                        143.42480423901497,
                        420.9783211940409
                    ],
                    [
                        141.60367943832125,
                        418.73127842430927
                    ],
                    [
                        141.78354361616744,
                        418.03012388230127
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Namakkal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Perambalur",
            "dt_code": "615",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        155.99281366602577,
                        419.3910830600895
                    ],
                    [
                        155.83543251041033,
                        418.6487956594407
                    ],
                    [
                        157.13944779979602,
                        418.23635782625524
                    ],
                    [
                        157.9938026445659,
                        419.2055233233269
                    ],
                    [
                        158.64581028925886,
                        417.1432034091324
                    ],
                    [
                        159.1629198005669,
                        417.3701077606156
                    ],
                    [
                        160.39948602326035,
                        418.9168588013722
                    ],
                    [
                        163.43469402441679,
                        419.3086130615914
                    ],
                    [
                        162.87261846864703,
                        420.0095573573283
                    ],
                    [
                        161.97329757941543,
                        423.34778578962903
                    ],
                    [
                        161.05149366795308,
                        424.1098614336079
                    ],
                    [
                        160.57935020110654,
                        423.5743626676183
                    ],
                    [
                        157.43172708879615,
                        423.18299523093134
                    ],
                    [
                        156.55488922179552,
                        423.945099546419
                    ],
                    [
                        156.17267784387218,
                        422.52377075435544
                    ],
                    [
                        156.73475339964193,
                        421.7820237844387
                    ],
                    [
                        157.02703268864207,
                        420.13324150057474
                    ],
                    [
                        155.99281366602577,
                        419.3910830600895
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Perambalur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Coimbatore",
            "dt_code": "632",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        131.01417596761985,
                        419.2055233233269
                    ],
                    [
                        133.17254610177565,
                        418.89623915885323
                    ],
                    [
                        133.71213863531443,
                        419.94771394773795
                    ],
                    [
                        133.9144858353916,
                        422.46196334428805
                    ],
                    [
                        135.3983653026237,
                        423.2653912870759
                    ],
                    [
                        133.98193490208382,
                        424.00688597884937
                    ],
                    [
                        134.2292481466227,
                        426.16886562553816
                    ],
                    [
                        135.19601810254653,
                        426.415881835041
                    ],
                    [
                        135.87050876947023,
                        427.7948044786592
                    ],
                    [
                        134.43159534669985,
                        427.93884653495684
                    ],
                    [
                        133.5097914352375,
                        428.72070869145057
                    ],
                    [
                        133.86951979092987,
                        431.2094241954877
                    ],
                    [
                        132.8802668127753,
                        431.2094241954877
                    ],
                    [
                        132.63295356823664,
                        433.01855004528295
                    ],
                    [
                        132.8802668127753,
                        435.2378777966299
                    ],
                    [
                        132.2957082347748,
                        436.01850269529353
                    ],
                    [
                        131.28397223438924,
                        436.6552328033882
                    ],
                    [
                        130.06988903392653,
                        436.16228794387985
                    ],
                    [
                        130.04740601169578,
                        436.1417474616197
                    ],
                    [
                        129.9574739227728,
                        436.0595846408702
                    ],
                    [
                        129.1031190780027,
                        434.86806293483295
                    ],
                    [
                        128.8333228112333,
                        433.61458431250026
                    ],
                    [
                        129.17056814469515,
                        431.0243598654458
                    ],
                    [
                        130.18230414508048,
                        428.2474925455808
                    ],
                    [
                        129.05815303354143,
                        426.9304549575541
                    ],
                    [
                        126.49508849923131,
                        425.9012492447812
                    ],
                    [
                        127.93400192200193,
                        423.80092781975355
                    ],
                    [
                        127.05716405500107,
                        422.4825659118913
                    ],
                    [
                        127.23702823284748,
                        421.3904950394153
                    ],
                    [
                        129.21553418915664,
                        420.6691649543123
                    ],
                    [
                        129.35043232254156,
                        419.88586964570777
                    ],
                    [
                        131.01417596761985,
                        419.2055233233269
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Coimbatore",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tiruchirappalli",
            "dt_code": "614",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        153.40726610948514,
                        418.8756194165201
                    ],
                    [
                        154.6438323321786,
                        420.2569220782164
                    ],
                    [
                        155.99281366602577,
                        419.3910830600895
                    ],
                    [
                        157.02703268864207,
                        420.13324150057474
                    ],
                    [
                        156.73475339964193,
                        421.7820237844387
                    ],
                    [
                        156.17267784387218,
                        422.52377075435544
                    ],
                    [
                        156.55488922179552,
                        423.945099546419
                    ],
                    [
                        157.43172708879615,
                        423.18299523093134
                    ],
                    [
                        160.57935020110654,
                        423.5743626676183
                    ],
                    [
                        161.05149366795308,
                        424.1098614336079
                    ],
                    [
                        160.646799267799,
                        425.4277345687911
                    ],
                    [
                        161.0065276234916,
                        426.5805516948141
                    ],
                    [
                        160.15217277872148,
                        426.6423013236111
                    ],
                    [
                        159.0280216671822,
                        427.5890220962709
                    ],
                    [
                        159.09547073387444,
                        429.3995844254565
                    ],
                    [
                        157.4092440665654,
                        430.1811942785363
                    ],
                    [
                        155.47570415471773,
                        429.4201548289012
                    ],
                    [
                        154.35155304317823,
                        429.6875615886563
                    ],
                    [
                        153.00257170933105,
                        430.79815997436685
                    ],
                    [
                        153.67706237625453,
                        434.31328598060134
                    ],
                    [
                        152.84519055371538,
                        433.8200956660961
                    ],
                    [
                        151.81097153109909,
                        435.29951077898545
                    ],
                    [
                        151.15896388640635,
                        435.4433179331087
                    ],
                    [
                        150.3720581083287,
                        434.37493110996655
                    ],
                    [
                        150.88916761963674,
                        433.3679587133456
                    ],
                    [
                        149.2928730412507,
                        432.8541133329228
                    ],
                    [
                        149.18045793009674,
                        431.7645724182289
                    ],
                    [
                        149.83246557478992,
                        431.6617722508669
                    ],
                    [
                        150.5519222861749,
                        429.44072513905314
                    ],
                    [
                        151.81097153109909,
                        429.09101717001886
                    ],
                    [
                        152.44049615356107,
                        429.91381655291434
                    ],
                    [
                        154.57638326548613,
                        428.86472114854246
                    ],
                    [
                        154.75624744333254,
                        427.218590004774
                    ],
                    [
                        152.17069988679168,
                        425.57185308645984
                    ],
                    [
                        149.2928730412507,
                        425.6130289445675
                    ],
                    [
                        148.6633484187887,
                        424.7894389565587
                    ],
                    [
                        149.63011837471254,
                        423.5743626676183
                    ],
                    [
                        151.0015827307907,
                        424.1098614336079
                    ],
                    [
                        152.86767357594613,
                        420.77221949987114
                    ],
                    [
                        153.40726610948514,
                        418.8756194165201
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Tiruchirappalli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ariyalur",
            "dt_code": "616",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        161.05149366795308,
                        424.1098614336079
                    ],
                    [
                        161.97329757941543,
                        423.34778578962903
                    ],
                    [
                        162.87261846864703,
                        420.0095573573283
                    ],
                    [
                        163.43469402441679,
                        419.3086130615914
                    ],
                    [
                        166.35748691441927,
                        418.7106578829997
                    ],
                    [
                        166.67224922565038,
                        419.47355146648727
                    ],
                    [
                        168.2685438040362,
                        420.2775351613618
                    ],
                    [
                        168.4484079818826,
                        421.5553535663563
                    ],
                    [
                        167.68398522603593,
                        422.729789115149
                    ],
                    [
                        166.80714735903484,
                        423.9862905980294
                    ],
                    [
                        165.23333580288,
                        424.7688472390797
                    ],
                    [
                        164.22159980249444,
                        425.77772855221485
                    ],
                    [
                        161.0065276234916,
                        426.5805516948141
                    ],
                    [
                        160.646799267799,
                        425.4277345687911
                    ],
                    [
                        161.05149366795308,
                        424.1098614336079
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Ariyalur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tiruppur",
            "dt_code": "633",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        133.71213863531443,
                        419.94771394773795
                    ],
                    [
                        134.74635765793073,
                        419.70033138151757
                    ],
                    [
                        136.4550673474705,
                        420.2363088961034
                    ],
                    [
                        138.54598841493407,
                        420.46304845823977
                    ],
                    [
                        138.50102237047258,
                        422.2971392888637
                    ],
                    [
                        138.90571677062644,
                        423.36838417261436
                    ],
                    [
                        140.9516717936283,
                        423.1417966200068
                    ],
                    [
                        142.9751437943994,
                        424.1098614336079
                    ],
                    [
                        142.72783054986053,
                        426.43646590039816
                    ],
                    [
                        143.78453259470757,
                        427.4038098792977
                    ],
                    [
                        143.42480423901497,
                        429.76983742401615
                    ],
                    [
                        142.14327197186026,
                        430.51025302411017
                    ],
                    [
                        140.16476601555087,
                        429.7286996925412
                    ],
                    [
                        139.1080639707036,
                        430.8804157735328
                    ],
                    [
                        137.69163357016419,
                        430.59251400364786
                    ],
                    [
                        136.97217685877877,
                        431.70289259345725
                    ],
                    [
                        137.19700708108667,
                        433.2240877396049
                    ],
                    [
                        136.2976861918553,
                        433.2446410086027
                    ],
                    [
                        136.38761828077827,
                        435.87471307424585
                    ],
                    [
                        135.78057668054703,
                        436.4703844873533
                    ],
                    [
                        134.20676512439172,
                        434.60095630486563
                    ],
                    [
                        132.8802668127753,
                        435.2378777966299
                    ],
                    [
                        132.63295356823664,
                        433.01855004528295
                    ],
                    [
                        132.8802668127753,
                        431.2094241954877
                    ],
                    [
                        133.86951979092987,
                        431.2094241954877
                    ],
                    [
                        133.5097914352375,
                        428.72070869145057
                    ],
                    [
                        134.43159534669985,
                        427.93884653495684
                    ],
                    [
                        135.87050876947023,
                        427.7948044786592
                    ],
                    [
                        135.19601810254653,
                        426.415881835041
                    ],
                    [
                        134.2292481466227,
                        426.16886562553816
                    ],
                    [
                        133.98193490208382,
                        424.00688597884937
                    ],
                    [
                        135.3983653026237,
                        423.2653912870759
                    ],
                    [
                        133.9144858353916,
                        422.46196334428805
                    ],
                    [
                        133.71213863531443,
                        419.94771394773795
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Tiruppur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Thanjavur",
            "dt_code": "620",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        159.09547073387444,
                        429.3995844254565
                    ],
                    [
                        159.0280216671822,
                        427.5890220962709
                    ],
                    [
                        160.15217277872148,
                        426.6423013236111
                    ],
                    [
                        161.0065276234916,
                        426.5805516948141
                    ],
                    [
                        164.22159980249444,
                        425.77772855221485
                    ],
                    [
                        165.23333580288,
                        424.7688472390797
                    ],
                    [
                        166.80714735903484,
                        423.9862905980294
                    ],
                    [
                        167.68398522603593,
                        422.729789115149
                    ],
                    [
                        168.69572122642103,
                        422.2353286563782
                    ],
                    [
                        169.0779326043446,
                        423.03879839188977
                    ],
                    [
                        168.53834007080582,
                        425.4277345687911
                    ],
                    [
                        167.6390191815742,
                        427.0127811518373
                    ],
                    [
                        165.3682339362647,
                        425.94242204510044
                    ],
                    [
                        164.8960904694179,
                        426.66288434317653
                    ],
                    [
                        165.45816602518767,
                        427.60960075920445
                    ],
                    [
                        165.41319998072595,
                        428.8235752005985
                    ],
                    [
                        166.04272460318816,
                        431.0860488057392
                    ],
                    [
                        168.0437135817283,
                        432.62800332404254
                    ],
                    [
                        168.31350984849792,
                        434.4160274116414
                    ],
                    [
                        167.88633242611263,
                        435.217333289769
                    ],
                    [
                        166.64976620341963,
                        435.19678869318244
                    ],
                    [
                        164.87360744718717,
                        436.51146251329055
                    ],
                    [
                        164.2890488691869,
                        437.8873714474537
                    ],
                    [
                        162.64778824633913,
                        436.4293061055891
                    ],
                    [
                        163.18738077987814,
                        434.7242381784333
                    ],
                    [
                        162.89510149087778,
                        432.50466593584724
                    ],
                    [
                        163.68200726895543,
                        431.82625141636754
                    ],
                    [
                        162.89510149087778,
                        430.4896875477058
                    ],
                    [
                        161.97329757941543,
                        429.8932483849955
                    ],
                    [
                        159.7474783785674,
                        429.91381655291434
                    ],
                    [
                        159.09547073387444,
                        429.3995844254565
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Thanjavur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Karur",
            "dt_code": "613",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        142.9751437943994,
                        424.1098614336079
                    ],
                    [
                        144.34660815047732,
                        424.6247025233166
                    ],
                    [
                        145.26841206193967,
                        423.92450387560325
                    ],
                    [
                        146.10028388447904,
                        423.53316774428924
                    ],
                    [
                        147.74154450732635,
                        423.84212022530346
                    ],
                    [
                        148.6633484187887,
                        424.7894389565587
                    ],
                    [
                        149.2928730412507,
                        425.6130289445675
                    ],
                    [
                        152.17069988679168,
                        425.57185308645984
                    ],
                    [
                        154.75624744333254,
                        427.218590004774
                    ],
                    [
                        154.57638326548613,
                        428.86472114854246
                    ],
                    [
                        152.44049615356107,
                        429.91381655291434
                    ],
                    [
                        151.81097153109909,
                        429.09101717001886
                    ],
                    [
                        150.5519222861749,
                        429.44072513905314
                    ],
                    [
                        149.83246557478992,
                        431.6617722508669
                    ],
                    [
                        149.18045793009674,
                        431.7645724182289
                    ],
                    [
                        148.3036200630961,
                        431.5384090168178
                    ],
                    [
                        148.50596726317326,
                        428.80300208614153
                    ],
                    [
                        148.91066166332757,
                        427.81538219805987
                    ],
                    [
                        146.66235944024857,
                        428.26806819368244
                    ],
                    [
                        145.8754536621709,
                        430.3662927435346
                    ],
                    [
                        143.42480423901497,
                        429.76983742401615
                    ],
                    [
                        143.78453259470757,
                        427.4038098792977
                    ],
                    [
                        142.72783054986053,
                        426.43646590039816
                    ],
                    [
                        142.9751437943994,
                        424.1098614336079
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Karur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Thiruvarur",
            "dt_code": "619",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        168.31350984849792,
                        434.4160274116414
                    ],
                    [
                        168.0437135817283,
                        432.62800332404254
                    ],
                    [
                        166.04272460318816,
                        431.0860488057392
                    ],
                    [
                        165.41319998072595,
                        428.8235752005985
                    ],
                    [
                        165.45816602518767,
                        427.60960075920445
                    ],
                    [
                        164.8960904694179,
                        426.66288434317653
                    ],
                    [
                        165.3682339362647,
                        425.94242204510044
                    ],
                    [
                        167.6390191815742,
                        427.0127811518373
                    ],
                    [
                        168.53834007080582,
                        425.4277345687911
                    ],
                    [
                        170.51684602711498,
                        424.7894389565587
                    ],
                    [
                        171.9557594498856,
                        425.0365320698666
                    ],
                    [
                        171.596031094193,
                        426.04535237589914
                    ],
                    [
                        170.7866422938846,
                        426.2717907126719
                    ],
                    [
                        170.53932904934572,
                        427.60960075920445
                    ],
                    [
                        171.0114725161925,
                        428.9881567455285
                    ],
                    [
                        170.76415927165363,
                        431.35335795574434
                    ],
                    [
                        171.46113296080807,
                        432.0729591494288
                    ],
                    [
                        170.22456673811485,
                        434.2927374236016
                    ],
                    [
                        168.31350984849792,
                        434.4160274116414
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Thiruvarur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dindigul",
            "dt_code": "612",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        149.18045793009674,
                        431.7645724182289
                    ],
                    [
                        149.2928730412507,
                        432.8541133329228
                    ],
                    [
                        150.88916761963674,
                        433.3679587133456
                    ],
                    [
                        150.3720581083287,
                        434.37493110996655
                    ],
                    [
                        151.15896388640635,
                        435.4433179331087
                    ],
                    [
                        151.0915148197139,
                        436.5320013928555
                    ],
                    [
                        150.3720581083287,
                        438.0105678255371
                    ],
                    [
                        149.3828051301739,
                        438.81126717316096
                    ],
                    [
                        148.3485861075576,
                        437.209734610317
                    ],
                    [
                        146.54994432909461,
                        437.18919865842923
                    ],
                    [
                        144.9761327729393,
                        438.831796172028
                    ],
                    [
                        143.37983819455349,
                        438.9344398539643
                    ],
                    [
                        142.0308568607063,
                        439.55025609074363
                    ],
                    [
                        141.7160945494752,
                        437.7641718981912
                    ],
                    [
                        140.6369094823972,
                        436.73738530004096
                    ],
                    [
                        138.3886072593184,
                        437.9900353159619
                    ],
                    [
                        136.83727872539384,
                        437.68203710224174
                    ],
                    [
                        135.48829739154667,
                        438.1132290534713
                    ],
                    [
                        135.78057668054703,
                        436.4703844873533
                    ],
                    [
                        136.38761828077827,
                        435.87471307424585
                    ],
                    [
                        136.2976861918553,
                        433.2446410086027
                    ],
                    [
                        137.19700708108667,
                        433.2240877396049
                    ],
                    [
                        136.97217685877877,
                        431.70289259345725
                    ],
                    [
                        137.69163357016419,
                        430.59251400364786
                    ],
                    [
                        139.1080639707036,
                        430.8804157735328
                    ],
                    [
                        140.16476601555087,
                        429.7286996925412
                    ],
                    [
                        142.14327197186026,
                        430.51025302411017
                    ],
                    [
                        143.42480423901497,
                        429.76983742401615
                    ],
                    [
                        145.8754536621709,
                        430.3662927435346
                    ],
                    [
                        146.66235944024857,
                        428.26806819368244
                    ],
                    [
                        148.91066166332757,
                        427.81538219805987
                    ],
                    [
                        148.50596726317326,
                        428.80300208614153
                    ],
                    [
                        148.3036200630961,
                        431.5384090168178
                    ],
                    [
                        149.18045793009674,
                        431.7645724182289
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Dindigul",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Madurai",
            "dt_code": "623",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        151.15896388640635,
                        435.4433179331087
                    ],
                    [
                        151.81097153109909,
                        435.29951077898545
                    ],
                    [
                        152.8901565981771,
                        438.1542929289071
                    ],
                    [
                        152.95760566486933,
                        440.41226724762225
                    ],
                    [
                        150.91165064186748,
                        441.0278959815149
                    ],
                    [
                        150.79923553071376,
                        441.9511937810976
                    ],
                    [
                        148.48348424094252,
                        441.8075810930469
                    ],
                    [
                        148.2586540186344,
                        443.48963879595055
                    ],
                    [
                        147.15698592932608,
                        443.79727048148516
                    ],
                    [
                        146.4600122401714,
                        445.3146427570123
                    ],
                    [
                        142.93017774993768,
                        445.2736387340645
                    ],
                    [
                        141.9858908162446,
                        444.3304537346421
                    ],
                    [
                        141.01912086032075,
                        444.3509596453832
                    ],
                    [
                        139.44530930416568,
                        445.0071044316453
                    ],
                    [
                        138.54598841493407,
                        446.4626200752841
                    ],
                    [
                        140.6369094823972,
                        442.0537717042159
                    ],
                    [
                        141.5362303716288,
                        440.94581663840813
                    ],
                    [
                        142.0308568607063,
                        439.55025609074363
                    ],
                    [
                        143.37983819455349,
                        438.9344398539643
                    ],
                    [
                        144.9761327729393,
                        438.831796172028
                    ],
                    [
                        146.54994432909461,
                        437.18919865842923
                    ],
                    [
                        148.3485861075576,
                        437.209734610317
                    ],
                    [
                        149.3828051301739,
                        438.81126717316096
                    ],
                    [
                        150.3720581083287,
                        438.0105678255371
                    ],
                    [
                        151.0915148197139,
                        436.5320013928555
                    ],
                    [
                        151.15896388640635,
                        435.4433179331087
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Madurai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Theni",
            "dt_code": "624",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        135.48829739154667,
                        438.1132290534713
                    ],
                    [
                        136.83727872539384,
                        437.68203710224174
                    ],
                    [
                        138.3886072593184,
                        437.9900353159619
                    ],
                    [
                        140.6369094823972,
                        436.73738530004096
                    ],
                    [
                        141.7160945494752,
                        437.7641718981912
                    ],
                    [
                        142.0308568607063,
                        439.55025609074363
                    ],
                    [
                        141.5362303716288,
                        440.94581663840813
                    ],
                    [
                        140.6369094823972,
                        442.0537717042159
                    ],
                    [
                        138.54598841493407,
                        446.4626200752841
                    ],
                    [
                        137.46680334785606,
                        447.01601616677704
                    ],
                    [
                        136.23023712516283,
                        445.8681643967666
                    ],
                    [
                        135.5107804137774,
                        446.31913716483075
                    ],
                    [
                        134.0044179243148,
                        445.72466462143973
                    ],
                    [
                        135.1735350803158,
                        442.93585372885093
                    ],
                    [
                        134.67890859123827,
                        441.7665481254312
                    ],
                    [
                        135.4208483248542,
                        439.5092041166811
                    ],
                    [
                        135.48829739154667,
                        438.1132290534713
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Theni",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Virudhunagar",
            "dt_code": "625",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        138.54598841493407,
                        446.4626200752841
                    ],
                    [
                        139.44530930416568,
                        445.0071044316453
                    ],
                    [
                        141.01912086032075,
                        444.3509596453832
                    ],
                    [
                        141.9858908162446,
                        444.3304537346421
                    ],
                    [
                        142.93017774993768,
                        445.2736387340645
                    ],
                    [
                        146.4600122401714,
                        445.3146427570123
                    ],
                    [
                        147.15698592932608,
                        443.79727048148516
                    ],
                    [
                        148.2586540186344,
                        443.48963879595055
                    ],
                    [
                        149.31535606348166,
                        443.20249870340535
                    ],
                    [
                        150.8217185529445,
                        444.0843762383225
                    ],
                    [
                        151.24889597532956,
                        445.02760757238156
                    ],
                    [
                        152.55291126471502,
                        445.7656649735742
                    ],
                    [
                        151.81097153109909,
                        446.23714510585876
                    ],
                    [
                        151.11399784194464,
                        447.9587007237884
                    ],
                    [
                        149.4727372190971,
                        448.18409955676987
                    ],
                    [
                        149.83246557478992,
                        449.1675424792927
                    ],
                    [
                        151.58614130879118,
                        449.63870915393227
                    ],
                    [
                        150.3720581083287,
                        450.6013950548511
                    ],
                    [
                        149.36032210794315,
                        450.29417421684724
                    ],
                    [
                        148.73079748548116,
                        449.3519171554853
                    ],
                    [
                        147.08953686263362,
                        449.4338593469338
                    ],
                    [
                        145.04358183963177,
                        451.8300980617452
                    ],
                    [
                        144.0093628170157,
                        451.19530412193694
                    ],
                    [
                        141.96340779401385,
                        450.519471267253
                    ],
                    [
                        139.01813188178062,
                        449.9050018067927
                    ],
                    [
                        137.9389468147026,
                        449.0241354060845
                    ],
                    [
                        136.5449994363937,
                        448.59389026813096
                    ],
                    [
                        137.46680334785606,
                        447.01601616677704
                    ],
                    [
                        138.54598841493407,
                        446.4626200752841
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Virudhunagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Thoothukkudi",
            "dt_code": "627",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        144.0093628170157,
                        451.19530412193694
                    ],
                    [
                        145.04358183963177,
                        451.8300980617452
                    ],
                    [
                        147.08953686263362,
                        449.4338593469338
                    ],
                    [
                        148.73079748548116,
                        449.3519171554853
                    ],
                    [
                        149.36032210794315,
                        450.29417421684724
                    ],
                    [
                        150.3720581083287,
                        450.6013950548511
                    ],
                    [
                        150.2821260194055,
                        452.62860119070046
                    ],
                    [
                        151.9009036200223,
                        453.44745376980063
                    ],
                    [
                        149.6076353524818,
                        455.2280248903346
                    ],
                    [
                        148.77576352994265,
                        456.5784110524018
                    ],
                    [
                        148.61838237432698,
                        457.74438456554185
                    ],
                    [
                        149.1130088634045,
                        458.5420134706204
                    ],
                    [
                        148.28113704086536,
                        459.6053400813795
                    ],
                    [
                        148.3485861075576,
                        462.2627743655155
                    ],
                    [
                        147.08953686263362,
                        464.08137880401324
                    ],
                    [
                        145.80800459547868,
                        464.7351188130317
                    ],
                    [
                        144.05432886147696,
                        464.408258025501
                    ],
                    [
                        143.53721935016893,
                        461.3430348199447
                    ],
                    [
                        143.6271514390919,
                        458.1329874047413
                    ],
                    [
                        143.37983819455349,
                        456.66024186105943
                    ],
                    [
                        141.67112850501348,
                        454.8392149029483
                    ],
                    [
                        141.87347570509064,
                        453.8977690219109
                    ],
                    [
                        141.46878130493633,
                        452.7923818073556
                    ],
                    [
                        144.18922699486188,
                        451.8915256680982
                    ],
                    [
                        144.0093628170157,
                        451.19530412193694
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Thoothukkudi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kanyakumari",
            "dt_code": "629",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        140.20973206001236,
                        467.6352084979439
                    ],
                    [
                        139.71510557093484,
                        468.57436948598524
                    ],
                    [
                        138.09632797031827,
                        468.3089693854432
                    ],
                    [
                        134.9711878802384,
                        467.0226323939488
                    ],
                    [
                        132.99268192392924,
                        465.36835931896246
                    ],
                    [
                        135.3983653026237,
                        462.18102576606793
                    ],
                    [
                        135.5107804137774,
                        461.1181868946985
                    ],
                    [
                        138.00639588139506,
                        462.18102576606793
                    ],
                    [
                        138.748335615011,
                        463.9383633351559
                    ],
                    [
                        139.8275206820888,
                        465.6134662268048
                    ],
                    [
                        139.49027534862716,
                        467.3289283496708
                    ],
                    [
                        140.20973206001236,
                        467.6352084979439
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Kanyakumari",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Viluppuram",
            "dt_code": "607",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        163.4796600688785,
                        409.524411385821
                    ],
                    [
                        165.05347162503335,
                        408.0981819424885
                    ],
                    [
                        164.04173562464803,
                        407.47792417825804
                    ],
                    [
                        164.62629420264852,
                        405.12006374907463
                    ],
                    [
                        164.35649793587913,
                        403.31969677509755
                    ],
                    [
                        166.58231713672717,
                        403.31969677509755
                    ],
                    [
                        167.59405313711272,
                        404.3544912499575
                    ],
                    [
                        170.35946487149977,
                        404.78902362873015
                    ],
                    [
                        172.6976991835013,
                        404.9131669306299
                    ],
                    [
                        173.79936727281006,
                        406.3198518522173
                    ],
                    [
                        175.77787322911945,
                        406.15438543327946
                    ],
                    [
                        173.95674842842573,
                        409.00771993206286
                    ],
                    [
                        173.55205402827164,
                        409.23507232161984
                    ],
                    [
                        173.55205402827164,
                        409.87572383634495
                    ],
                    [
                        173.30474078373277,
                        410.6402397904577
                    ],
                    [
                        172.49535198342437,
                        410.5782573728726
                    ],
                    [
                        171.03395553842324,
                        411.77641703789357
                    ],
                    [
                        171.07892158288496,
                        412.850330889533
                    ],
                    [
                        170.96650647173078,
                        413.03617210863797
                    ],
                    [
                        167.84136638165114,
                        411.9003445281691
                    ],
                    [
                        167.30177384811236,
                        413.2426525226193
                    ],
                    [
                        165.41319998072595,
                        411.85903578161697
                    ],
                    [
                        164.17663375803272,
                        411.85903578161697
                    ],
                    [
                        164.44643002480234,
                        410.7022212632785
                    ],
                    [
                        163.4796600688785,
                        409.524411385821
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Viluppuram",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Cuddalore",
            "dt_code": "617",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        163.43469402441679,
                        419.3086130615914
                    ],
                    [
                        160.39948602326035,
                        418.9168588013722
                    ],
                    [
                        159.1629198005669,
                        417.3701077606156
                    ],
                    [
                        159.8374104674906,
                        416.48304853733464
                    ],
                    [
                        161.2313578457995,
                        416.0703990837003
                    ],
                    [
                        163.65952424672446,
                        416.19419818128824
                    ],
                    [
                        164.60381118041778,
                        415.24497818235625
                    ],
                    [
                        165.30078486957223,
                        415.45134868915886
                    ],
                    [
                        166.60480015895791,
                        414.77028725187904
                    ],
                    [
                        167.48163802595877,
                        414.99732009701404
                    ],
                    [
                        167.30177384811236,
                        413.2426525226193
                    ],
                    [
                        167.84136638165114,
                        411.9003445281691
                    ],
                    [
                        170.96650647173078,
                        413.03617210863797
                    ],
                    [
                        171.07892158288496,
                        412.850330889533
                    ],
                    [
                        171.03395553842324,
                        411.77641703789357
                    ],
                    [
                        172.83259731688622,
                        412.31340909035265
                    ],
                    [
                        172.81011429465548,
                        412.4786232698357
                    ],
                    [
                        172.65273313904004,
                        413.201357266982
                    ],
                    [
                        172.135623627732,
                        415.6370734271328
                    ],
                    [
                        172.2255557166552,
                        417.1638315817245
                    ],
                    [
                        172.90004638357868,
                        418.62817471833455
                    ],
                    [
                        170.40443091596126,
                        420.73099797767924
                    ],
                    [
                        169.7524232712683,
                        421.72020581100935
                    ],
                    [
                        168.69572122642103,
                        422.2353286563782
                    ],
                    [
                        167.68398522603593,
                        422.729789115149
                    ],
                    [
                        168.4484079818826,
                        421.5553535663563
                    ],
                    [
                        168.2685438040362,
                        420.2775351613618
                    ],
                    [
                        166.67224922565038,
                        419.47355146648727
                    ],
                    [
                        166.35748691441927,
                        418.7106578829997
                    ],
                    [
                        163.43469402441679,
                        419.3086130615914
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Cuddalore",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pudukkottai",
            "dt_code": "621",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        153.67706237625453,
                        434.31328598060134
                    ],
                    [
                        153.00257170933105,
                        430.79815997436685
                    ],
                    [
                        154.35155304317823,
                        429.6875615886563
                    ],
                    [
                        155.47570415471773,
                        429.4201548289012
                    ],
                    [
                        157.4092440665654,
                        430.1811942785363
                    ],
                    [
                        159.09547073387444,
                        429.3995844254565
                    ],
                    [
                        159.7474783785674,
                        429.91381655291434
                    ],
                    [
                        161.97329757941543,
                        429.8932483849955
                    ],
                    [
                        162.89510149087778,
                        430.4896875477058
                    ],
                    [
                        163.68200726895543,
                        431.82625141636754
                    ],
                    [
                        162.89510149087778,
                        432.50466593584724
                    ],
                    [
                        163.18738077987814,
                        434.7242381784333
                    ],
                    [
                        162.64778824633913,
                        436.4293061055891
                    ],
                    [
                        164.2890488691869,
                        437.8873714474537
                    ],
                    [
                        164.64877722487927,
                        439.18077577398367
                    ],
                    [
                        162.78268637972383,
                        441.68448116150796
                    ],
                    [
                        162.7377203352621,
                        441.72551481495145
                    ],
                    [
                        162.71523731303137,
                        442.1358325029054
                    ],
                    [
                        162.6702712685701,
                        442.17686238925717
                    ],
                    [
                        161.5461201570306,
                        441.9511937810976
                    ],
                    [
                        160.8266634456454,
                        440.781653812964
                    ],
                    [
                        159.9273425564138,
                        439.61183339884036
                    ],
                    [
                        160.35451997879886,
                        438.50332168040364
                    ],
                    [
                        159.68002931187516,
                        437.78470537673763
                    ],
                    [
                        156.66730433294947,
                        437.31241304245486
                    ],
                    [
                        155.83543251041033,
                        436.16228794387985
                    ],
                    [
                        153.9243756207934,
                        435.0529740037024
                    ],
                    [
                        153.67706237625453,
                        434.31328598060134
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Pudukkottai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sivaganga",
            "dt_code": "622",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        151.81097153109909,
                        435.29951077898545
                    ],
                    [
                        152.84519055371538,
                        433.8200956660961
                    ],
                    [
                        153.67706237625453,
                        434.31328598060134
                    ],
                    [
                        153.9243756207934,
                        435.0529740037024
                    ],
                    [
                        155.83543251041033,
                        436.16228794387985
                    ],
                    [
                        156.66730433294947,
                        437.31241304245486
                    ],
                    [
                        159.68002931187516,
                        437.78470537673763
                    ],
                    [
                        160.35451997879886,
                        438.50332168040364
                    ],
                    [
                        159.9273425564138,
                        439.61183339884036
                    ],
                    [
                        160.8266634456454,
                        440.781653812964
                    ],
                    [
                        158.48842913364342,
                        442.4025206560999
                    ],
                    [
                        156.93710059971886,
                        441.86912990182043
                    ],
                    [
                        156.91461757748812,
                        444.2484292500329
                    ],
                    [
                        157.9938026445659,
                        444.47399334333954
                    ],
                    [
                        158.33104797802775,
                        445.90916358344845
                    ],
                    [
                        156.93710059971886,
                        446.0731570035548
                    ],
                    [
                        155.90288157710256,
                        446.70858133596846
                    ],
                    [
                        152.55291126471502,
                        445.7656649735742
                    ],
                    [
                        151.24889597532956,
                        445.02760757238156
                    ],
                    [
                        150.8217185529445,
                        444.0843762383225
                    ],
                    [
                        149.31535606348166,
                        443.20249870340535
                    ],
                    [
                        148.2586540186344,
                        443.48963879595055
                    ],
                    [
                        148.48348424094252,
                        441.8075810930469
                    ],
                    [
                        150.79923553071376,
                        441.9511937810976
                    ],
                    [
                        150.91165064186748,
                        441.0278959815149
                    ],
                    [
                        152.95760566486933,
                        440.41226724762225
                    ],
                    [
                        152.8901565981771,
                        438.1542929289071
                    ],
                    [
                        151.81097153109909,
                        435.29951077898545
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Sivaganga",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ramanathapuram",
            "dt_code": "626",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        150.3720581083287,
                        450.6013950548511
                    ],
                    [
                        151.58614130879118,
                        449.63870915393227
                    ],
                    [
                        149.83246557478992,
                        449.1675424792927
                    ],
                    [
                        149.4727372190971,
                        448.18409955676987
                    ],
                    [
                        151.11399784194464,
                        447.9587007237884
                    ],
                    [
                        151.81097153109909,
                        446.23714510585876
                    ],
                    [
                        152.55291126471502,
                        445.7656649735742
                    ],
                    [
                        155.90288157710256,
                        446.70858133596846
                    ],
                    [
                        156.93710059971886,
                        446.0731570035548
                    ],
                    [
                        158.33104797802775,
                        445.90916358344845
                    ],
                    [
                        157.9938026445659,
                        444.47399334333954
                    ],
                    [
                        156.91461757748812,
                        444.2484292500329
                    ],
                    [
                        156.93710059971886,
                        441.86912990182043
                    ],
                    [
                        158.48842913364342,
                        442.4025206560999
                    ],
                    [
                        160.8266634456454,
                        440.781653812964
                    ],
                    [
                        161.5461201570306,
                        441.9511937810976
                    ],
                    [
                        162.6702712685701,
                        442.17686238925717
                    ],
                    [
                        160.66928229002974,
                        444.57651911308255
                    ],
                    [
                        159.9273425564138,
                        445.97066173957364
                    ],
                    [
                        159.477682111798,
                        447.8562434320329
                    ],
                    [
                        160.71424833449146,
                        449.49531513947204
                    ],
                    [
                        161.90584851272297,
                        450.3965831739276
                    ],
                    [
                        163.27731286880135,
                        450.6013950548511
                    ],
                    [
                        165.8853434475725,
                        450.2122456017893
                    ],
                    [
                        165.41319998072595,
                        451.19530412193694
                    ],
                    [
                        163.95180353572482,
                        450.7037979817993
                    ],
                    [
                        162.2655768684158,
                        450.97003620326745
                    ],
                    [
                        160.6917653122605,
                        450.7652387745385
                    ],
                    [
                        159.07298771164392,
                        451.01099472722245
                    ],
                    [
                        154.75624744333254,
                        452.89474212447465
                    ],
                    [
                        152.4854621980228,
                        453.0994568390155
                    ],
                    [
                        151.9009036200223,
                        453.44745376980063
                    ],
                    [
                        150.2821260194055,
                        452.62860119070046
                    ],
                    [
                        150.3720581083287,
                        450.6013950548511
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Ramanathapuram",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nagapattinam",
            "dt_code": "618",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            167.88633242611263,
                            435.217333289769
                        ],
                        [
                            168.31350984849792,
                            434.4160274116414
                        ],
                        [
                            170.22456673811485,
                            434.2927374236016
                        ],
                        [
                            171.46113296080807,
                            432.0729591494288
                        ],
                        [
                            170.76415927165363,
                            431.35335795574434
                        ],
                        [
                            171.0114725161925,
                            428.9881567455285
                        ],
                        [
                            170.53932904934572,
                            427.60960075920445
                        ],
                        [
                            170.7866422938846,
                            426.2717907126719
                        ],
                        [
                            171.596031094193,
                            426.04535237589914
                        ],
                        [
                            172.9899784725019,
                            426.78638046610513
                        ],
                        [
                            173.4396389171177,
                            427.5684433389141
                        ],
                        [
                            173.77688425057931,
                            434.5598616263122
                        ],
                        [
                            173.59702007273313,
                            435.7103767191842
                        ],
                        [
                            172.24803873888573,
                            435.87471307424585
                        ],
                        [
                            167.88633242611263,
                            435.217333289769
                        ]
                    ]
                ],
                [
                    [
                        [
                            168.53834007080582,
                            425.4277345687911
                        ],
                        [
                            169.0779326043446,
                            423.03879839188977
                        ],
                        [
                            168.69572122642103,
                            422.2353286563782
                        ],
                        [
                            169.7524232712683,
                            421.72020581100935
                        ],
                        [
                            170.40443091596126,
                            420.73099797767924
                        ],
                        [
                            172.90004638357868,
                            418.62817471833455
                        ],
                        [
                            173.55205402827164,
                            422.3383458886653
                        ],
                        [
                            173.46212193934844,
                            425.20125313055644
                        ],
                        [
                            171.9557594498856,
                            425.0365320698666
                        ],
                        [
                            170.51684602711498,
                            424.7894389565587
                        ],
                        [
                            168.53834007080582,
                            425.4277345687911
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Nagapattinam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kallakurichi",
            "dt_code": "729",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        156.8022024663344,
                        410.22700581078766
                    ],
                    [
                        157.38676104433466,
                        409.8137297378879
                    ],
                    [
                        159.68002931187516,
                        409.75173468999833
                    ],
                    [
                        160.35451997879886,
                        408.9457125096651
                    ],
                    [
                        162.80516940195457,
                        409.6897386923263
                    ],
                    [
                        163.4796600688785,
                        409.524411385821
                    ],
                    [
                        164.44643002480234,
                        410.7022212632785
                    ],
                    [
                        164.17663375803272,
                        411.85903578161697
                    ],
                    [
                        165.41319998072595,
                        411.85903578161697
                    ],
                    [
                        167.30177384811236,
                        413.2426525226193
                    ],
                    [
                        167.48163802595877,
                        414.99732009701404
                    ],
                    [
                        166.60480015895791,
                        414.77028725187904
                    ],
                    [
                        165.30078486957223,
                        415.45134868915886
                    ],
                    [
                        164.60381118041778,
                        415.24497818235625
                    ],
                    [
                        163.65952424672446,
                        416.19419818128824
                    ],
                    [
                        161.2313578457995,
                        416.0703990837003
                    ],
                    [
                        159.8374104674906,
                        416.48304853733464
                    ],
                    [
                        159.1629198005669,
                        417.3701077606156
                    ],
                    [
                        158.64581028925886,
                        417.1432034091324
                    ],
                    [
                        157.8139384667195,
                        415.78152029723515
                    ],
                    [
                        158.0837347334891,
                        414.6670864096091
                    ],
                    [
                        156.55488922179552,
                        414.60516467492243
                    ],
                    [
                        155.70053437702563,
                        413.4491226022839
                    ],
                    [
                        156.44247411064157,
                        412.21014685103535
                    ],
                    [
                        155.47570415471773,
                        411.9416528579864
                    ],
                    [
                        156.66730433294947,
                        411.5078945956185
                    ],
                    [
                        156.8022024663344,
                        410.22700581078766
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Kallakurichi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tenkasi",
            "dt_code": "0",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        136.5449994363937,
                        448.59389026813096
                    ],
                    [
                        137.9389468147026,
                        449.0241354060845
                    ],
                    [
                        139.01813188178062,
                        449.9050018067927
                    ],
                    [
                        141.96340779401385,
                        450.519471267253
                    ],
                    [
                        144.0093628170157,
                        451.19530412193694
                    ],
                    [
                        144.18922699486188,
                        451.8915256680982
                    ],
                    [
                        141.46878130493633,
                        452.7923818073556
                    ],
                    [
                        141.87347570509064,
                        453.8977690219109
                    ],
                    [
                        140.7268415713204,
                        454.00010810450885
                    ],
                    [
                        139.89496974878125,
                        457.9898211251051
                    ],
                    [
                        138.43357330378012,
                        457.6421161028122
                    ],
                    [
                        138.09632797031827,
                        458.685165450309
                    ],
                    [
                        136.8597617476246,
                        458.5624639799478
                    ],
                    [
                        136.0054069028547,
                        457.94891579112834
                    ],
                    [
                        135.30843321370048,
                        456.6397842739358
                    ],
                    [
                        134.38662930223813,
                        455.9441806029515
                    ],
                    [
                        133.73462165754518,
                        454.69596204523714
                    ],
                    [
                        134.54401045785357,
                        453.7954279807409
                    ],
                    [
                        135.48829739154667,
                        452.5671821544626
                    ],
                    [
                        135.69064459162382,
                        450.3965831739276
                    ],
                    [
                        136.5449994363937,
                        448.59389026813096
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Tenkasi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tirunelveli",
            "dt_code": "628",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        141.87347570509064,
                        453.8977690219109
                    ],
                    [
                        141.67112850501348,
                        454.8392149029483
                    ],
                    [
                        143.37983819455349,
                        456.66024186105943
                    ],
                    [
                        143.6271514390919,
                        458.1329874047413
                    ],
                    [
                        143.53721935016893,
                        461.3430348199447
                    ],
                    [
                        144.05432886147696,
                        464.408258025501
                    ],
                    [
                        145.80800459547868,
                        464.7351188130317
                    ],
                    [
                        143.9194307280925,
                        465.981107348469
                    ],
                    [
                        143.22245703893782,
                        467.28808980312874
                    ],
                    [
                        140.20973206001236,
                        467.6352084979439
                    ],
                    [
                        139.49027534862716,
                        467.3289283496708
                    ],
                    [
                        139.8275206820888,
                        465.6134662268048
                    ],
                    [
                        138.748335615011,
                        463.9383633351559
                    ],
                    [
                        138.00639588139506,
                        462.18102576606793
                    ],
                    [
                        135.5107804137774,
                        461.1181868946985
                    ],
                    [
                        134.04938396877628,
                        458.685165450309
                    ],
                    [
                        134.47656139116134,
                        458.2556984029617
                    ],
                    [
                        135.30843321370048,
                        456.6397842739358
                    ],
                    [
                        136.0054069028547,
                        457.94891579112834
                    ],
                    [
                        136.8597617476246,
                        458.5624639799478
                    ],
                    [
                        138.09632797031827,
                        458.685165450309
                    ],
                    [
                        138.43357330378012,
                        457.6421161028122
                    ],
                    [
                        139.89496974878125,
                        457.9898211251051
                    ],
                    [
                        140.7268415713204,
                        454.00010810450885
                    ],
                    [
                        141.87347570509064,
                        453.8977690219109
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Tirunelveli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Vellore",
            "dt_code": "605",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        164.01925260241728,
                        398.1623086882274
                    ],
                    [
                        161.90584851272297,
                        398.7426013437869
                    ],
                    [
                        160.53438415664505,
                        399.88220545040457
                    ],
                    [
                        159.45519908956726,
                        399.6128750709018
                    ],
                    [
                        158.87064051156676,
                        400.4415236148303
                    ],
                    [
                        157.54414219995033,
                        399.73718374865393
                    ],
                    [
                        158.12870077795083,
                        397.8514012473616
                    ],
                    [
                        157.74648940002726,
                        397.229510014879
                    ],
                    [
                        155.94784762156405,
                        396.9599921280959
                    ],
                    [
                        155.1159757990249,
                        397.2709726028056
                    ],
                    [
                        155.38577206579453,
                        395.05208434784544
                    ],
                    [
                        157.29682895541146,
                        394.0977666392871
                    ],
                    [
                        159.36526700064405,
                        393.4752540092643
                    ],
                    [
                        160.87162949010667,
                        393.620516267623
                    ],
                    [
                        163.34476193549358,
                        394.61644778743164
                    ],
                    [
                        164.40146398034085,
                        393.3299860751252
                    ],
                    [
                        164.31153189141764,
                        392.54128948551477
                    ],
                    [
                        165.27830184734148,
                        394.13926378340994
                    ],
                    [
                        164.87360744718717,
                        395.1350569952107
                    ],
                    [
                        163.72697331341692,
                        396.2342715322832
                    ],
                    [
                        164.01925260241728,
                        398.1623086882274
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Vellore",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ranipet",
            "dt_code": "0",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        165.12092069172581,
                        393.06018769518107
                    ],
                    [
                        166.11017366988062,
                        393.9732724360496
                    ],
                    [
                        167.57157011488198,
                        393.51675809061504
                    ],
                    [
                        168.0437135817283,
                        392.8111256228637
                    ],
                    [
                        170.22456673811485,
                        392.72810121302587
                    ],
                    [
                        170.4718799826535,
                        391.9185155622175
                    ],
                    [
                        171.77589527203918,
                        392.395984955731
                    ],
                    [
                        171.79837829427015,
                        393.9317734443283
                    ],
                    [
                        172.56280105011683,
                        394.5542098521778
                    ],
                    [
                        169.3027628266525,
                        396.44163450851306
                    ],
                    [
                        169.19034771549855,
                        397.1258515596049
                    ],
                    [
                        167.9088154483436,
                        396.8148591610814
                    ],
                    [
                        167.57157011488198,
                        399.88220545040457
                    ],
                    [
                        164.01925260241728,
                        398.1623086882274
                    ],
                    [
                        163.72697331341692,
                        396.2342715322832
                    ],
                    [
                        164.87360744718717,
                        395.1350569952107
                    ],
                    [
                        165.27830184734148,
                        394.13926378340994
                    ],
                    [
                        164.31153189141764,
                        392.54128948551477
                    ],
                    [
                        165.12092069172581,
                        393.06018769518107
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Ranipet",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tirupathur",
            "dt_code": "0",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        155.7679834437181,
                        406.09233374793604
                    ],
                    [
                        154.59886628771687,
                        404.1475541930279
                    ],
                    [
                        152.6653263758692,
                        404.0440815756465
                    ],
                    [
                        152.30559802017638,
                        400.54509213272604
                    ],
                    [
                        154.9585946434097,
                        398.203761094275
                    ],
                    [
                        155.1159757990249,
                        397.2709726028056
                    ],
                    [
                        155.94784762156405,
                        396.9599921280959
                    ],
                    [
                        157.74648940002726,
                        397.229510014879
                    ],
                    [
                        158.12870077795083,
                        397.8514012473616
                    ],
                    [
                        157.54414219995033,
                        399.73718374865393
                    ],
                    [
                        158.87064051156676,
                        400.4415236148303
                    ],
                    [
                        157.4092440665654,
                        403.31969677509755
                    ],
                    [
                        156.68978735518044,
                        405.40970107890365
                    ],
                    [
                        155.7679834437181,
                        406.09233374793604
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Tirupathur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kancheepuram",
            "dt_code": "604",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        172.56280105011683,
                        394.5542098521778
                    ],
                    [
                        173.0349445169636,
                        395.4254468049935
                    ],
                    [
                        174.0466805173487,
                        395.75729337401526
                    ],
                    [
                        175.5755260290423,
                        394.22225668685724
                    ],
                    [
                        176.83457527396672,
                        394.67868468674453
                    ],
                    [
                        177.19430362965932,
                        395.5913737529787
                    ],
                    [
                        176.4298808738124,
                        397.35389641778244
                    ],
                    [
                        175.01345047327277,
                        398.07940252184005
                    ],
                    [
                        174.31647678411832,
                        397.95503988494875
                    ],
                    [
                        174.541307006426,
                        400.46223754116363
                    ],
                    [
                        173.05742753919435,
                        400.64865786718076
                    ],
                    [
                        171.88831038319313,
                        401.97405414903693
                    ],
                    [
                        171.596031094193,
                        401.14573469880634
                    ],
                    [
                        170.22456673811485,
                        400.4829513561066
                    ],
                    [
                        171.8433443387314,
                        399.53000038067285
                    ],
                    [
                        171.91079340542387,
                        398.78404744134775
                    ],
                    [
                        169.19034771549855,
                        397.1258515596049
                    ],
                    [
                        169.3027628266525,
                        396.44163450851306
                    ],
                    [
                        172.56280105011683,
                        394.5542098521778
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Kancheepuram",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chengalpattu",
            "dt_code": "0",
            "st_nm": "Tamil Nadu",
            "st_code": "33",
            "alpha2code": "TN",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        176.83457527396672,
                        394.67868468674453
                    ],
                    [
                        177.53154896312094,
                        394.40898397258684
                    ],
                    [
                        178.5432849635065,
                        395.52915200647413
                    ],
                    [
                        178.34093776342934,
                        396.89779296703034
                    ],
                    [
                        179.33019074158415,
                        397.0636551244569
                    ],
                    [
                        179.3976398082766,
                        398.2452130490322
                    ],
                    [
                        178.09362451889092,
                        402.01546547639055
                    ],
                    [
                        177.6889301187366,
                        403.79573616008565
                    ],
                    [
                        175.77787322911945,
                        406.15438543327946
                    ],
                    [
                        173.79936727281006,
                        406.3198518522173
                    ],
                    [
                        172.6976991835013,
                        404.9131669306299
                    ],
                    [
                        170.35946487149977,
                        404.78902362873015
                    ],
                    [
                        171.34871784965412,
                        403.7336473482399
                    ],
                    [
                        171.88831038319313,
                        401.97405414903693
                    ],
                    [
                        173.05742753919435,
                        400.64865786718076
                    ],
                    [
                        174.541307006426,
                        400.46223754116363
                    ],
                    [
                        174.31647678411832,
                        397.95503988494875
                    ],
                    [
                        175.01345047327277,
                        398.07940252184005
                    ],
                    [
                        176.4298808738124,
                        397.35389641778244
                    ],
                    [
                        177.19430362965932,
                        395.5913737529787
                    ],
                    [
                        176.83457527396672,
                        394.67868468674453
                    ]
                ]
            ]
        },
        "id": "TT-Tamil Nadu-Chengalpattu",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Burhanpur",
            "dt_code": "467",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        128.338696322156,
                        262.99054357083503
                    ],
                    [
                        128.36117934438676,
                        264.4896207419155
                    ],
                    [
                        125.97797898792305,
                        266.5736717289267
                    ],
                    [
                        126.58502058815475,
                        267.3763986401044
                    ],
                    [
                        126.00046201015402,
                        268.8943126460534
                    ],
                    [
                        124.0444390760756,
                        268.78592304068036
                    ],
                    [
                        123.8645748982292,
                        269.479529481531
                    ],
                    [
                        122.47062751992007,
                        270.60620087741404
                    ],
                    [
                        119.30052138537894,
                        270.5628773920299
                    ],
                    [
                        119.18810627422499,
                        267.78850185591307
                    ],
                    [
                        118.2438193405319,
                        265.9443128517437
                    ],
                    [
                        116.64752476214608,
                        265.9877224102602
                    ],
                    [
                        116.98477009560793,
                        264.4027444055754
                    ],
                    [
                        116.13041525083804,
                        263.1643997871097
                    ],
                    [
                        117.97402307376274,
                        262.7732047572199
                    ],
                    [
                        119.43541951876387,
                        263.25132295326404
                    ],
                    [
                        122.3806954309971,
                        262.36020419253236
                    ],
                    [
                        124.38168440953723,
                        262.94707745684065
                    ],
                    [
                        125.84308085453858,
                        262.7514697420866
                    ],
                    [
                        128.338696322156,
                        262.99054357083503
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Burhanpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gondia",
            "dt_code": "507",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        185.37812372166627,
                        266.55197254752545
                    ],
                    [
                        184.68115003251182,
                        268.243899348228
                    ],
                    [
                        182.47781385389453,
                        269.046049627439
                    ],
                    [
                        182.11808549820194,
                        270.28125519830496
                    ],
                    [
                        182.47781385389453,
                        272.92284256151237
                    ],
                    [
                        183.691897054357,
                        272.83628016414497
                    ],
                    [
                        183.91672727666514,
                        274.5885506642957
                    ],
                    [
                        181.39862878681652,
                        274.84803597165313
                    ],
                    [
                        180.54427394204663,
                        274.6318001881801
                    ],
                    [
                        180.18454558635403,
                        276.08020396274134
                    ],
                    [
                        179.2177756304302,
                        277.2253319376753
                    ],
                    [
                        176.49732994050464,
                        276.3395264097009
                    ],
                    [
                        175.39566185119634,
                        276.87969086982673
                    ],
                    [
                        175.5755260290423,
                        275.75601116326436
                    ],
                    [
                        173.64198611719462,
                        275.2372107113525
                    ],
                    [
                        174.76613722873412,
                        272.48999878147566
                    ],
                    [
                        176.6996771405818,
                        272.48999878147566
                    ],
                    [
                        176.58726202942785,
                        268.6341691227043
                    ],
                    [
                        174.7211711842724,
                        267.78850185591307
                    ],
                    [
                        173.0799105614251,
                        267.50654439864485
                    ],
                    [
                        172.65273313904004,
                        265.87919698543567
                    ],
                    [
                        173.8892993617335,
                        265.0759503164828
                    ],
                    [
                        174.45137491750302,
                        263.5338005046621
                    ],
                    [
                        176.63222807388956,
                        263.055741196677
                    ],
                    [
                        177.44161687419773,
                        262.16454633377396
                    ],
                    [
                        179.59998700835354,
                        262.0558402913773
                    ],
                    [
                        181.17379856450884,
                        263.5338005046621
                    ],
                    [
                        181.8707722536633,
                        264.85880861040016
                    ],
                    [
                        183.42210078758762,
                        265.5752986776284
                    ],
                    [
                        184.79356514366577,
                        266.7472578617716
                    ],
                    [
                        185.37812372166627,
                        266.55197254752545
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Gondia",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhandara",
            "dt_code": "506",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        174.45137491750302,
                        263.5338005046621
                    ],
                    [
                        173.8892993617335,
                        265.0759503164828
                    ],
                    [
                        172.65273313904004,
                        265.87919698543567
                    ],
                    [
                        173.0799105614251,
                        267.50654439864485
                    ],
                    [
                        174.7211711842724,
                        267.78850185591307
                    ],
                    [
                        176.58726202942785,
                        268.6341691227043
                    ],
                    [
                        176.6996771405818,
                        272.48999878147566
                    ],
                    [
                        174.76613722873412,
                        272.48999878147566
                    ],
                    [
                        173.64198611719462,
                        275.2372107113525
                    ],
                    [
                        175.5755260290423,
                        275.75601116326436
                    ],
                    [
                        175.39566185119634,
                        276.87969086982673
                    ],
                    [
                        174.67620513981092,
                        276.9445023831133
                    ],
                    [
                        171.97824247211634,
                        276.6852457635207
                    ],
                    [
                        170.67422718273065,
                        277.052517660236
                    ],
                    [
                        168.5833061152673,
                        276.29630796304826
                    ],
                    [
                        168.74068727088274,
                        275.34530348229924
                    ],
                    [
                        170.58429509380744,
                        272.36013013906404
                    ],
                    [
                        169.7524232712683,
                        270.67118460323945
                    ],
                    [
                        168.2235777595747,
                        269.63122841537904
                    ],
                    [
                        169.05544958211385,
                        269.1110767399153
                    ],
                    [
                        169.41517793780645,
                        267.44147243140935
                    ],
                    [
                        167.9537814928051,
                        265.0542370661806
                    ],
                    [
                        167.9537814928051,
                        264.05520625618135
                    ],
                    [
                        168.87558540426744,
                        263.2730532301903
                    ],
                    [
                        171.82086131650067,
                        262.2949867653879
                    ],
                    [
                        174.45137491750302,
                        263.5338005046621
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Bhandara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jalgaon",
            "dt_code": "499",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        116.64752476214608,
                        265.9877224102602
                    ],
                    [
                        118.2438193405319,
                        265.9443128517437
                    ],
                    [
                        119.18810627422499,
                        267.78850185591307
                    ],
                    [
                        119.30052138537894,
                        270.5628773920299
                    ],
                    [
                        122.47062751992007,
                        270.60620087741404
                    ],
                    [
                        122.6954577422282,
                        271.2992677653205
                    ],
                    [
                        121.93103498638129,
                        272.5765738981849
                    ],
                    [
                        119.25555534091745,
                        271.23430144356945
                    ],
                    [
                        117.99650609599348,
                        273.2474232625957
                    ],
                    [
                        118.51361560730152,
                        273.50705569023086
                    ],
                    [
                        116.62504173991533,
                        275.5614742677477
                    ],
                    [
                        116.93980405114644,
                        277.3549344390378
                    ],
                    [
                        115.83813596183768,
                        278.4994515869444
                    ],
                    [
                        114.2643244056826,
                        278.8016855788134
                    ],
                    [
                        113.23010538306607,
                        277.89486922507797
                    ],
                    [
                        110.01503320406346,
                        278.00284155736426
                    ],
                    [
                        109.9026180929095,
                        278.8016855788134
                    ],
                    [
                        107.9690781810616,
                        279.3412944803316
                    ],
                    [
                        107.85666306990765,
                        278.45627219227123
                    ],
                    [
                        105.92312315805975,
                        278.477861986839
                    ],
                    [
                        105.47346271344418,
                        279.75131621316
                    ],
                    [
                        104.4842097352896,
                        280.031817139319
                    ],
                    [
                        103.69730395721194,
                        281.0456635494898
                    ],
                    [
                        103.98958324621208,
                        282.01596891551696
                    ],
                    [
                        102.0560433343644,
                        282.23153951844193
                    ],
                    [
                        101.17920546736354,
                        283.26601184520433
                    ],
                    [
                        100.14498644474725,
                        282.25309552412483
                    ],
                    [
                        99.2231825332849,
                        280.52800799746626
                    ],
                    [
                        99.38056368890057,
                        277.59252076759645
                    ],
                    [
                        101.1567224451328,
                        277.2037308370189
                    ],
                    [
                        101.94362822321045,
                        275.9289194875118
                    ],
                    [
                        101.58389986751786,
                        273.3556069100818
                    ],
                    [
                        100.86444315613267,
                        272.94448266436746
                    ],
                    [
                        100.81947711167118,
                        271.32092280630616
                    ],
                    [
                        100.05505435582404,
                        270.8661249685841
                    ],
                    [
                        100.59464688936305,
                        269.4578574008065
                    ],
                    [
                        101.6063828897486,
                        268.7425657869782
                    ],
                    [
                        102.68556795682662,
                        268.78592304068036
                    ],
                    [
                        104.07951533513506,
                        267.4197813703371
                    ],
                    [
                        103.92213417951984,
                        266.4000725832204
                    ],
                    [
                        105.24863249113628,
                        265.31478257953853
                    ],
                    [
                        107.76673098098445,
                        265.81407928449573
                    ],
                    [
                        111.09421827114147,
                        265.6621308451458
                    ],
                    [
                        116.64752476214608,
                        265.9877224102602
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Jalgaon",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mahasamund",
            "dt_code": "411",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        223.7116766251604,
                        265.8574912889921
                    ],
                    [
                        222.58752551362113,
                        269.60955774234714
                    ],
                    [
                        220.90129884631187,
                        270.0429330249793
                    ],
                    [
                        219.26003822346433,
                        269.046049627439
                    ],
                    [
                        214.4711544883064,
                        269.37116706610857
                    ],
                    [
                        214.44867146607567,
                        270.4112388816923
                    ],
                    [
                        213.30203733230542,
                        272.64150314787855
                    ],
                    [
                        212.24533528745815,
                        273.2474232625957
                    ],
                    [
                        211.81815786507332,
                        274.5453003510488
                    ],
                    [
                        210.04199910884108,
                        273.7882919559541
                    ],
                    [
                        207.9735610636087,
                        272.7713562804235
                    ],
                    [
                        207.43396853006948,
                        271.5591150757416
                    ],
                    [
                        204.89338701799056,
                        270.49789066137873
                    ],
                    [
                        205.47794559599083,
                        268.1354794624092
                    ],
                    [
                        207.2091383077618,
                        266.89913505995895
                    ],
                    [
                        207.90611199691625,
                        265.8357853886843
                    ],
                    [
                        208.76046684168614,
                        266.79065236270134
                    ],
                    [
                        210.78393884245725,
                        267.68006073027414
                    ],
                    [
                        211.99802204291973,
                        266.6387680532398
                    ],
                    [
                        215.30302631084555,
                        266.595370707002
                    ],
                    [
                        215.21309422192235,
                        264.1638175683122
                    ],
                    [
                        217.95602293407865,
                        263.0340088607277
                    ],
                    [
                        218.7878947566178,
                        263.96831351258334
                    ],
                    [
                        220.63150257954248,
                        264.92395326994944
                    ],
                    [
                        223.7116766251604,
                        265.8574912889921
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Mahasamund",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Wardha",
            "dt_code": "504",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        149.94488068594387,
                        266.595370707002
                    ],
                    [
                        151.11399784194464,
                        267.8752511128732
                    ],
                    [
                        152.8002245092539,
                        268.0704251052006
                    ],
                    [
                        155.07100975456342,
                        270.0862661351256
                    ],
                    [
                        155.83543251041033,
                        271.2776125245467
                    ],
                    [
                        156.93710059971886,
                        271.7323306467422
                    ],
                    [
                        157.02703268864207,
                        272.64150314787855
                    ],
                    [
                        158.42098006695096,
                        273.48542074477064
                    ],
                    [
                        160.10720673426,
                        275.45339133376325
                    ],
                    [
                        161.70350131264604,
                        275.9289194875118
                    ],
                    [
                        163.05248264649322,
                        276.8580866408
                    ],
                    [
                        164.19911678026347,
                        278.3699110690601
                    ],
                    [
                        162.89510149087778,
                        280.3338585239357
                    ],
                    [
                        161.72598433487678,
                        280.2044168530489
                    ],
                    [
                        158.2635989113353,
                        281.2828852610544
                    ],
                    [
                        157.9938026445659,
                        282.8134844370718
                    ],
                    [
                        157.11696477756527,
                        282.8996866326599
                    ],
                    [
                        155.74550042148712,
                        280.9378277972141
                    ],
                    [
                        154.50893419879367,
                        280.614291634941
                    ],
                    [
                        153.72202842071624,
                        279.2765478132882
                    ],
                    [
                        152.28311499794563,
                        279.4707825815627
                    ],
                    [
                        150.10226184155908,
                        278.4346822032254
                    ],
                    [
                        150.2821260194055,
                        276.642033586652
                    ],
                    [
                        150.84420157517548,
                        275.95053214431397
                    ],
                    [
                        150.03481277486685,
                        273.59359349079153
                    ],
                    [
                        148.3485861075576,
                        272.5332867375673
                    ],
                    [
                        148.37106912978834,
                        269.60955774234714
                    ],
                    [
                        147.02208779594116,
                        267.78850185591307
                    ],
                    [
                        147.4042991738645,
                        266.20475797947046
                    ],
                    [
                        149.94488068594387,
                        266.595370707002
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Wardha",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Buldhana",
            "dt_code": "500",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        122.47062751992007,
                        270.60620087741404
                    ],
                    [
                        123.8645748982292,
                        269.479529481531
                    ],
                    [
                        124.0444390760756,
                        268.78592304068036
                    ],
                    [
                        126.00046201015402,
                        268.8943126460534
                    ],
                    [
                        126.58502058815475,
                        267.3763986401044
                    ],
                    [
                        127.48434147738612,
                        267.2462455835281
                    ],
                    [
                        128.36117934438676,
                        268.76424451467085
                    ],
                    [
                        128.22628121100183,
                        270.47622801707735
                    ],
                    [
                        128.56352654446414,
                        271.7539816953537
                    ],
                    [
                        128.09138307761737,
                        274.06949477065035
                    ],
                    [
                        128.22628121100183,
                        275.34530348229924
                    ],
                    [
                        127.61923961077105,
                        277.2253319376753
                    ],
                    [
                        128.36117934438676,
                        278.04602912647726
                    ],
                    [
                        128.15883214430983,
                        279.384457955874
                    ],
                    [
                        127.259511255078,
                        279.6650016577091
                    ],
                    [
                        127.77662076638626,
                        282.2099833210595
                    ],
                    [
                        128.90077187792576,
                        283.11517873916574
                    ],
                    [
                        127.82158681084775,
                        284.2785063770114
                    ],
                    [
                        127.97896796646341,
                        285.505861680397
                    ],
                    [
                        126.67495267707773,
                        285.52738873128885
                    ],
                    [
                        126.51757152146229,
                        287.4855599803588
                    ],
                    [
                        125.77563178784635,
                        288.68981446120466
                    ],
                    [
                        126.24777525469267,
                        289.59262036151074
                    ],
                    [
                        124.74141276523005,
                        289.87199361733315
                    ],
                    [
                        124.11188814276761,
                        288.7543115217066
                    ],
                    [
                        122.85283889784364,
                        288.7543115217066
                    ],
                    [
                        121.2115782749961,
                        289.5496370665746
                    ],
                    [
                        118.28878538499362,
                        289.7430560253199
                    ],
                    [
                        118.01898911822423,
                        288.86180288403966
                    ],
                    [
                        116.31027942868423,
                        287.528579196987
                    ],
                    [
                        118.2438193405319,
                        286.0009359891817
                    ],
                    [
                        118.91831000745583,
                        282.79193341003196
                    ],
                    [
                        118.10892120714743,
                        281.75725886535844
                    ],
                    [
                        117.29953240683881,
                        282.7272791811177
                    ],
                    [
                        116.35524547314571,
                        281.71413783573274
                    ],
                    [
                        115.97303409522237,
                        279.643422534919
                    ],
                    [
                        116.49014360653041,
                        279.1686328238742
                    ],
                    [
                        117.36698147353127,
                        278.8016855788134
                    ],
                    [
                        116.93980405114644,
                        277.3549344390378
                    ],
                    [
                        116.62504173991533,
                        275.5614742677477
                    ],
                    [
                        118.51361560730152,
                        273.50705569023086
                    ],
                    [
                        117.99650609599348,
                        273.2474232625957
                    ],
                    [
                        119.25555534091745,
                        271.23430144356945
                    ],
                    [
                        121.93103498638129,
                        272.5765738981849
                    ],
                    [
                        122.6954577422282,
                        271.2992677653205
                    ],
                    [
                        122.47062751992007,
                        270.60620087741404
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Buldhana",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Valsad",
            "dt_code": "491",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        69.43317807749031,
                        285.44127939184284
                    ],
                    [
                        68.91606856618228,
                        283.6969120410993
                    ],
                    [
                        69.47814412195203,
                        282.23153951844193
                    ],
                    [
                        70.22008385556796,
                        281.73569844657766
                    ],
                    [
                        71.07443870033785,
                        280.2691385576315
                    ],
                    [
                        71.32175194487672,
                        278.477861986839
                    ],
                    [
                        70.6022952334913,
                        275.7343967387643
                    ],
                    [
                        72.33348794526205,
                        275.8208532579784
                    ],
                    [
                        73.43515603457081,
                        276.53399971737156
                    ],
                    [
                        73.81736741249415,
                        278.0892159164661
                    ],
                    [
                        75.95325452441898,
                        278.06762261885416
                    ],
                    [
                        76.53781310241948,
                        278.4130920196855
                    ],
                    [
                        78.7186662588058,
                        277.72210335817573
                    ],
                    [
                        79.68543621472986,
                        278.3483203019426
                    ],
                    [
                        79.6179871480374,
                        279.7297378646218
                    ],
                    [
                        78.67370021434431,
                        281.4553955188571
                    ],
                    [
                        78.85356439219072,
                        284.30004424326154
                    ],
                    [
                        77.14485470265072,
                        284.2138916369992
                    ],
                    [
                        76.40291496903478,
                        285.59196874830667
                    ],
                    [
                        75.84083941326503,
                        285.61349504214576
                    ],
                    [
                        74.71668830172575,
                        284.3861938065795
                    ],
                    [
                        75.1888317685723,
                        282.98588576889335
                    ],
                    [
                        73.36770696787835,
                        282.4039821976396
                    ],
                    [
                        71.74892936726178,
                        282.8781363706169
                    ],
                    [
                        72.17610678964661,
                        284.23543007389594
                    ],
                    [
                        70.87209150026092,
                        283.95541555045895
                    ],
                    [
                        69.43317807749031,
                        285.44127939184284
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Valsad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Akola",
            "dt_code": "501",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        128.90077187792576,
                        283.11517873916574
                    ],
                    [
                        127.77662076638626,
                        282.2099833210595
                    ],
                    [
                        127.259511255078,
                        279.6650016577091
                    ],
                    [
                        128.15883214430983,
                        279.384457955874
                    ],
                    [
                        128.36117934438676,
                        278.04602912647726
                    ],
                    [
                        127.61923961077105,
                        277.2253319376753
                    ],
                    [
                        128.22628121100183,
                        275.34530348229924
                    ],
                    [
                        128.09138307761737,
                        274.06949477065035
                    ],
                    [
                        128.56352654446414,
                        271.7539816953537
                    ],
                    [
                        128.22628121100183,
                        270.47622801707735
                    ],
                    [
                        128.36117934438676,
                        268.76424451467085
                    ],
                    [
                        131.5312854789281,
                        268.72088685758655
                    ],
                    [
                        134.0044179243148,
                        267.983683131342
                    ],
                    [
                        134.13931605769926,
                        270.67118460323945
                    ],
                    [
                        133.44234236854504,
                        273.96134379837184
                    ],
                    [
                        135.75809365831628,
                        274.22089782779216
                    ],
                    [
                        139.33289419301173,
                        274.1776407997751
                    ],
                    [
                        140.52449437124324,
                        274.95614646933177
                    ],
                    [
                        140.52449437124324,
                        276.23147882246866
                    ],
                    [
                        138.59095445939533,
                        277.1173244802001
                    ],
                    [
                        138.2986751703952,
                        277.8300834871229
                    ],
                    [
                        136.5449994363937,
                        278.477861986839
                    ],
                    [
                        136.5449994363937,
                        279.92393603596037
                    ],
                    [
                        134.92622183577714,
                        280.07496822733407
                    ],
                    [
                        134.2292481466227,
                        281.26132061321135
                    ],
                    [
                        131.756115701236,
                        281.0456635494898
                    ],
                    [
                        128.90077187792576,
                        283.11517873916574
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Akola",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhadrak",
            "dt_code": "378",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        273.0169443772795,
                        275.0210104029956
                    ],
                    [
                        272.6796990438179,
                        274.09112437186326
                    ],
                    [
                        271.1508535321241,
                        273.8964508414559
                    ],
                    [
                        269.1723475758147,
                        274.1992694125984
                    ],
                    [
                        268.0032304198137,
                        273.2474232625957
                    ],
                    [
                        268.07067948650615,
                        271.5591150757416
                    ],
                    [
                        269.26227966473766,
                        268.0704251052006
                    ],
                    [
                        271.4431328211242,
                        269.6745691583571
                    ],
                    [
                        273.4666048218953,
                        270.10793238914977
                    ],
                    [
                        273.75888411089545,
                        269.1327520412059
                    ],
                    [
                        275.64745797828164,
                        268.1788480230847
                    ],
                    [
                        276.50181282305175,
                        268.4390424134878
                    ],
                    [
                        276.41188073412854,
                        270.32458389478785
                    ],
                    [
                        278.23300553482227,
                        274.0046047805903
                    ],
                    [
                        278.0981074014378,
                        275.0858725634809
                    ],
                    [
                        276.59174491197496,
                        275.215591566649
                    ],
                    [
                        275.7149070449741,
                        275.97214460478534
                    ],
                    [
                        275.0404163780504,
                        275.47500831389436
                    ],
                    [
                        273.0169443772795,
                        275.0210104029956
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Bhadrak",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dhenkanal",
            "dt_code": "383",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        259.6395461499603,
                        269.63122841537904
                    ],
                    [
                        260.2016217057301,
                        271.9271829054315
                    ],
                    [
                        261.59556908403897,
                        272.8579210614112
                    ],
                    [
                        262.652271128886,
                        272.94448266436746
                    ],
                    [
                        262.3824748621166,
                        274.22089782779216
                    ],
                    [
                        264.3160147739643,
                        274.8047903926571
                    ],
                    [
                        262.62978810665527,
                        276.51239235588304
                    ],
                    [
                        262.2250937065012,
                        277.18212954095895
                    ],
                    [
                        260.74121423926886,
                        276.966105829664
                    ],
                    [
                        259.5496140610371,
                        277.72210335817573
                    ],
                    [
                        258.8076743274212,
                        278.88803115397053
                    ],
                    [
                        257.7734553048049,
                        279.4492017157752
                    ],
                    [
                        256.58185512657315,
                        279.1038815029213
                    ],
                    [
                        255.88488143741847,
                        279.9023592351638
                    ],
                    [
                        252.872156458493,
                        279.384457955874
                    ],
                    [
                        251.0285486355683,
                        277.8516789280271
                    ],
                    [
                        250.84868445772213,
                        276.79327277970197
                    ],
                    [
                        251.95035254703066,
                        276.51239235588304
                    ],
                    [
                        252.9171225029545,
                        275.0210104029956
                    ],
                    [
                        252.55739414726213,
                        270.67118460323945
                    ],
                    [
                        253.16443574749337,
                        269.046049627439
                    ],
                    [
                        254.96307752595612,
                        268.9159899623066
                    ],
                    [
                        256.49192303764994,
                        269.9995991119276
                    ],
                    [
                        257.1888967268046,
                        269.1761020395825
                    ],
                    [
                        259.5496140610371,
                        269.1544271410896
                    ],
                    [
                        259.6395461499603,
                        269.63122841537904
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Dhenkanal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jajpur",
            "dt_code": "382",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        268.07067948650615,
                        271.5591150757416
                    ],
                    [
                        268.0032304198137,
                        273.2474232625957
                    ],
                    [
                        269.1723475758147,
                        274.1992694125984
                    ],
                    [
                        271.1508535321241,
                        273.8964508414559
                    ],
                    [
                        272.6796990438179,
                        274.09112437186326
                    ],
                    [
                        273.0169443772795,
                        275.0210104029956
                    ],
                    [
                        272.1625895325096,
                        275.34530348229924
                    ],
                    [
                        271.6005139767399,
                        276.70685155826664
                    ],
                    [
                        270.52132890966186,
                        276.9228987409674
                    ],
                    [
                        269.41966082035333,
                        278.19717948360915
                    ],
                    [
                        267.98074739758295,
                        278.3915016416357
                    ],
                    [
                        266.00224144127355,
                        277.44133220056887
                    ],
                    [
                        264.47339592957997,
                        278.21877161296595
                    ],
                    [
                        262.62978810665527,
                        276.51239235588304
                    ],
                    [
                        264.3160147739643,
                        274.8047903926571
                    ],
                    [
                        262.3824748621166,
                        274.22089782779216
                    ],
                    [
                        262.652271128886,
                        272.94448266436746
                    ],
                    [
                        261.59556908403897,
                        272.8579210614112
                    ],
                    [
                        260.2016217057301,
                        271.9271829054315
                    ],
                    [
                        259.6395461499603,
                        269.63122841537904
                    ],
                    [
                        261.2583237505771,
                        270.3679117892069
                    ],
                    [
                        262.652271128886,
                        270.34624794224726
                    ],
                    [
                        264.2710487295028,
                        269.63122841537904
                    ],
                    [
                        264.60829406296443,
                        271.47250250049075
                    ],
                    [
                        265.8448602856581,
                        270.60620087741404
                    ],
                    [
                        268.07067948650615,
                        271.5591150757416
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Jajpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Subarnapur",
            "dt_code": "392",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        238.5729543197117,
                        273.0310410903229
                    ],
                    [
                        238.3930901418653,
                        273.68012812207587
                    ],
                    [
                        237.29142205255653,
                        273.55032498673506
                    ],
                    [
                        235.0656028517085,
                        274.6318001881801
                    ],
                    [
                        233.53675734001513,
                        274.28578188775515
                    ],
                    [
                        232.07536089501377,
                        274.5453003510488
                    ],
                    [
                        230.97369280570524,
                        275.2588296591968
                    ],
                    [
                        230.90624373901278,
                        276.25308873209656
                    ],
                    [
                        229.82705867193476,
                        278.73692435815735
                    ],
                    [
                        228.61297547147228,
                        279.1902162095988
                    ],
                    [
                        228.50056036031856,
                        278.00284155736426
                    ],
                    [
                        229.53477938293463,
                        276.4475690958654
                    ],
                    [
                        228.23076409354894,
                        273.80992412886025
                    ],
                    [
                        229.1525680050113,
                        272.1003713590227
                    ],
                    [
                        227.2639941376251,
                        272.1220190191925
                    ],
                    [
                        226.47708835954745,
                        270.88778400840516
                    ],
                    [
                        227.82606969339463,
                        270.60620087741404
                    ],
                    [
                        228.65794151593377,
                        269.7829174985557
                    ],
                    [
                        230.25423609432005,
                        269.9345967366193
                    ],
                    [
                        231.28845511693635,
                        269.41451263585
                    ],
                    [
                        232.1203269394755,
                        270.32458389478785
                    ],
                    [
                        232.90723271755292,
                        269.046049627439
                    ],
                    [
                        234.23373102916935,
                        269.7395787653518
                    ],
                    [
                        234.72835751824664,
                        270.9960762064032
                    ],
                    [
                        236.57196534117134,
                        271.2776125245467
                    ],
                    [
                        237.96591271948046,
                        273.0310410903229
                    ],
                    [
                        238.5729543197117,
                        273.0310410903229
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Subarnapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nuapada",
            "dt_code": "394",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        210.04199910884108,
                        273.7882919559541
                    ],
                    [
                        211.81815786507332,
                        274.5453003510488
                    ],
                    [
                        212.24533528745815,
                        273.2474232625957
                    ],
                    [
                        213.30203733230542,
                        272.64150314787855
                    ],
                    [
                        214.44867146607567,
                        270.4112388816923
                    ],
                    [
                        215.25806026638406,
                        271.4941559437516
                    ],
                    [
                        215.07819608853765,
                        273.615227445692
                    ],
                    [
                        215.370475377538,
                        276.08020396274134
                    ],
                    [
                        215.97751697776926,
                        277.63571574474975
                    ],
                    [
                        215.12316213299914,
                        280.29071207285375
                    ],
                    [
                        215.79765279992307,
                        281.52008369264746
                    ],
                    [
                        217.01173600038555,
                        282.4039821976396
                    ],
                    [
                        217.73119271177075,
                        283.91233353733065
                    ],
                    [
                        217.1241511115395,
                        284.9030273411172
                    ],
                    [
                        215.61778862207666,
                        287.6791405394448
                    ],
                    [
                        210.91883697584194,
                        286.62501767972105
                    ],
                    [
                        210.73897279799576,
                        285.225992786545
                    ],
                    [
                        211.2560823093038,
                        284.30004424326154
                    ],
                    [
                        210.9413199980727,
                        282.166870351132
                    ],
                    [
                        211.5033955538422,
                        280.700572186207
                    ],
                    [
                        209.9520670199181,
                        278.6937492397244
                    ],
                    [
                        210.60407466461083,
                        277.59252076759645
                    ],
                    [
                        210.24434630891824,
                        276.51239235588304
                    ],
                    [
                        210.04199910884108,
                        273.7882919559541
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Nuapada",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Balangir",
            "dt_code": "393",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        226.47708835954745,
                        270.88778400840516
                    ],
                    [
                        227.2639941376251,
                        272.1220190191925
                    ],
                    [
                        229.1525680050113,
                        272.1003713590227
                    ],
                    [
                        228.23076409354894,
                        273.80992412886025
                    ],
                    [
                        229.53477938293463,
                        276.4475690958654
                    ],
                    [
                        228.50056036031856,
                        278.00284155736426
                    ],
                    [
                        228.61297547147228,
                        279.1902162095988
                    ],
                    [
                        228.3431792047029,
                        279.85920505331836
                    ],
                    [
                        229.55726240516537,
                        280.89469214786413
                    ],
                    [
                        229.3324321828577,
                        281.41226910834155
                    ],
                    [
                        228.86028871601093,
                        281.9944108002667
                    ],
                    [
                        227.10661298200966,
                        280.9809626759636
                    ],
                    [
                        225.9599788482392,
                        281.1966255151452
                    ],
                    [
                        225.08314098123856,
                        282.5333061605195
                    ],
                    [
                        223.26201618054483,
                        283.4814714823169
                    ],
                    [
                        223.08215200269842,
                        284.30004424326154
                    ],
                    [
                        221.89055182446646,
                        284.85996205725456
                    ],
                    [
                        221.10364604638903,
                        284.3646567009709
                    ],
                    [
                        220.00197795708027,
                        284.8168960142938
                    ],
                    [
                        217.1241511115395,
                        284.9030273411172
                    ],
                    [
                        217.73119271177075,
                        283.91233353733065
                    ],
                    [
                        217.01173600038555,
                        282.4039821976396
                    ],
                    [
                        215.79765279992307,
                        281.52008369264746
                    ],
                    [
                        215.12316213299914,
                        280.29071207285375
                    ],
                    [
                        215.97751697776926,
                        277.63571574474975
                    ],
                    [
                        215.370475377538,
                        276.08020396274134
                    ],
                    [
                        218.13588711192506,
                        273.26906038870294
                    ],
                    [
                        219.48486844577246,
                        274.24252604537196
                    ],
                    [
                        221.68820462438953,
                        274.047864971711
                    ],
                    [
                        222.94725386931373,
                        272.9012022600788
                    ],
                    [
                        223.95898986969905,
                        274.1127537753662
                    ],
                    [
                        225.71266560370077,
                        273.55032498673506
                    ],
                    [
                        225.44286933693115,
                        271.9704812144272
                    ],
                    [
                        226.47708835954745,
                        270.88778400840516
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Balangir",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nashik",
            "dt_code": "516",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        99.38056368890057,
                        277.59252076759645
                    ],
                    [
                        99.2231825332849,
                        280.52800799746626
                    ],
                    [
                        100.14498644474725,
                        282.25309552412483
                    ],
                    [
                        101.17920546736354,
                        283.26601184520433
                    ],
                    [
                        100.61712991159402,
                        284.6876933295635
                    ],
                    [
                        99.47049577782377,
                        283.58919414551303
                    ],
                    [
                        97.94165026613018,
                        285.3982235860842
                    ],
                    [
                        98.25641257736106,
                        286.2376753148614
                    ],
                    [
                        97.289642621437,
                        288.3458017068942
                    ],
                    [
                        92.11854750835596,
                        287.7436640129897
                    ],
                    [
                        91.466539863663,
                        288.96928957493594
                    ],
                    [
                        92.77055515304869,
                        289.50665302620706
                    ],
                    [
                        92.92793630866436,
                        290.6025135068488
                    ],
                    [
                        89.89272830750792,
                        291.7193644410022
                    ],
                    [
                        89.19575461835325,
                        293.11472571975173
                    ],
                    [
                        87.75684119558287,
                        292.5996060456084
                    ],
                    [
                        86.34041079504345,
                        292.6210714815808
                    ],
                    [
                        85.08136155011903,
                        291.5475737972338
                    ],
                    [
                        84.15955763865668,
                        293.11472571975173
                    ],
                    [
                        83.08037257157912,
                        293.8013873257715
                    ],
                    [
                        81.79884030442417,
                        293.9730233826373
                    ],
                    [
                        79.86530039257627,
                        291.80525532755274
                    ],
                    [
                        79.37067390349898,
                        291.1824793761067
                    ],
                    [
                        78.7186662588058,
                        287.20591675044864
                    ],
                    [
                        77.27975283603541,
                        286.62501767972105
                    ],
                    [
                        76.40291496903478,
                        285.59196874830667
                    ],
                    [
                        77.14485470265072,
                        284.2138916369992
                    ],
                    [
                        78.85356439219072,
                        284.30004424326154
                    ],
                    [
                        78.67370021434431,
                        281.4553955188571
                    ],
                    [
                        79.6179871480374,
                        279.7297378646218
                    ],
                    [
                        79.68543621472986,
                        278.3483203019426
                    ],
                    [
                        78.7186662588058,
                        277.72210335817573
                    ],
                    [
                        79.7079192369606,
                        276.209868716718
                    ],
                    [
                        81.7763572821932,
                        277.70050674738934
                    ],
                    [
                        82.33843283796296,
                        278.65057334408
                    ],
                    [
                        83.68741417181013,
                        278.65057334408
                    ],
                    [
                        85.05887852788828,
                        277.7652959947034
                    ],
                    [
                        85.59847106142706,
                        276.0585924836837
                    ],
                    [
                        86.2279956838895,
                        276.14503722256995
                    ],
                    [
                        86.58772403958187,
                        274.3290369398761
                    ],
                    [
                        87.73435817335212,
                        274.4155446738464
                    ],
                    [
                        89.75783017412323,
                        273.9829743883762
                    ],
                    [
                        93.04035141981831,
                        274.3290369398761
                    ],
                    [
                        93.8497402201267,
                        274.91290286157647
                    ],
                    [
                        95.35610270958932,
                        274.3722912018637
                    ],
                    [
                        97.22219355474499,
                        275.34530348229924
                    ],
                    [
                        96.99736333243686,
                        276.62042720449733
                    ],
                    [
                        99.38056368890057,
                        277.59252076759645
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Nashik",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Boudh",
            "dt_code": "391",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        228.61297547147228,
                        279.1902162095988
                    ],
                    [
                        229.82705867193476,
                        278.73692435815735
                    ],
                    [
                        230.90624373901278,
                        276.25308873209656
                    ],
                    [
                        230.97369280570524,
                        275.2588296591968
                    ],
                    [
                        232.07536089501377,
                        274.5453003510488
                    ],
                    [
                        233.53675734001513,
                        274.28578188775515
                    ],
                    [
                        235.0656028517085,
                        274.6318001881801
                    ],
                    [
                        237.29142205255653,
                        273.55032498673506
                    ],
                    [
                        238.3930901418653,
                        273.68012812207587
                    ],
                    [
                        239.58469032009702,
                        274.43717111362605
                    ],
                    [
                        239.76455449794344,
                        275.49662509734236
                    ],
                    [
                        242.70983041017666,
                        276.6852457635207
                    ],
                    [
                        243.8564645439469,
                        277.72210335817573
                    ],
                    [
                        245.74503841133333,
                        278.04602912647726
                    ],
                    [
                        244.4859891664089,
                        280.7221418419224
                    ],
                    [
                        241.96789067656073,
                        281.3044497165008
                    ],
                    [
                        240.16924889809752,
                        279.1902162095988
                    ],
                    [
                        239.89945263132813,
                        277.2901340673841
                    ],
                    [
                        238.52798827524998,
                        276.966105829664
                    ],
                    [
                        237.78604854163405,
                        278.3915016416357
                    ],
                    [
                        232.61495342855278,
                        278.3699110690601
                    ],
                    [
                        231.46831929478253,
                        279.53552401660875
                    ],
                    [
                        231.73811556155192,
                        280.67900233766704
                    ],
                    [
                        229.55726240516537,
                        280.89469214786413
                    ],
                    [
                        228.3431792047029,
                        279.85920505331836
                    ],
                    [
                        228.61297547147228,
                        279.1902162095988
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Boudh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gadchiroli",
            "dt_code": "508",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        175.39566185119634,
                        276.87969086982673
                    ],
                    [
                        176.49732994050464,
                        276.3395264097009
                    ],
                    [
                        179.2177756304302,
                        277.2253319376753
                    ],
                    [
                        180.18454558635403,
                        276.08020396274134
                    ],
                    [
                        180.54427394204663,
                        274.6318001881801
                    ],
                    [
                        181.39862878681652,
                        274.84803597165313
                    ],
                    [
                        183.91672727666514,
                        274.5885506642957
                    ],
                    [
                        184.1865235434343,
                        276.9445023831133
                    ],
                    [
                        183.30968567643367,
                        278.4130920196855
                    ],
                    [
                        184.72611607697354,
                        278.32672934026704
                    ],
                    [
                        184.34390469904997,
                        281.34757805026777
                    ],
                    [
                        184.86101421035823,
                        282.29620696045447
                    ],
                    [
                        181.46607785350898,
                        283.67536884329616
                    ],
                    [
                        182.00567038704799,
                        285.3766953991411
                    ],
                    [
                        183.08485545412577,
                        285.24752229959677
                    ],
                    [
                        183.66941403212627,
                        285.9363667516583
                    ],
                    [
                        183.44458380981837,
                        288.4533108385035
                    ],
                    [
                        182.54526292058677,
                        290.2157944473971
                    ],
                    [
                        183.691897054357,
                        290.3447074875358
                    ],
                    [
                        183.9392102988959,
                        291.2683887574663
                    ],
                    [
                        185.5355048772817,
                        292.14878932807136
                    ],
                    [
                        186.16502949974392,
                        293.5009959963532
                    ],
                    [
                        188.8180261229768,
                        295.00259383908593
                    ],
                    [
                        188.18850150051458,
                        297.4247180004906
                    ],
                    [
                        186.70462203328293,
                        298.6242063924207
                    ],
                    [
                        184.65866701028108,
                        298.2815530809514
                    ],
                    [
                        184.14155749897282,
                        296.9104782590516
                    ],
                    [
                        182.74761012066392,
                        297.85317161362866
                    ],
                    [
                        181.44359483127823,
                        300.18697895462606
                    ],
                    [
                        180.54427394204663,
                        300.8503336990302
                    ],
                    [
                        180.4993078975849,
                        302.0054461237708
                    ],
                    [
                        179.64495305281525,
                        303.9935951878714
                    ],
                    [
                        180.7915871865855,
                        305.4676893045989
                    ],
                    [
                        179.68991909727674,
                        306.748833118846
                    ],
                    [
                        179.71240211950771,
                        307.45319497145044
                    ],
                    [
                        177.39665082973625,
                        308.0720236147391
                    ],
                    [
                        175.93525438473512,
                        306.6634430578111
                    ],
                    [
                        174.38392585081078,
                        305.80938909539
                    ],
                    [
                        175.08089953996523,
                        305.1900252530876
                    ],
                    [
                        174.76613722873412,
                        302.5614267274125
                    ],
                    [
                        173.57453705050239,
                        301.5135171753282
                    ],
                    [
                        174.60875607311846,
                        300.6363670325046
                    ],
                    [
                        175.32821278450388,
                        296.8890493416166
                    ],
                    [
                        174.83358629542658,
                        295.77449577791907
                    ],
                    [
                        173.66446913942536,
                        295.1527043752976
                    ],
                    [
                        172.81011429465548,
                        294.0802899650972
                    ],
                    [
                        172.45038593896288,
                        291.76231025378485
                    ],
                    [
                        171.79837829427015,
                        290.3876770145025
                    ],
                    [
                        172.76514825019376,
                        289.1842489502302
                    ],
                    [
                        172.47286896119363,
                        287.3564978213635
                    ],
                    [
                        173.48460496157918,
                        287.03381281391506
                    ],
                    [
                        174.40640887304153,
                        287.98023563521764
                    ],
                    [
                        175.50807696235006,
                        286.7971502339144
                    ],
                    [
                        175.17083162888844,
                        285.6565470621676
                    ],
                    [
                        175.46311091788834,
                        284.1492751847635
                    ],
                    [
                        174.8111032731956,
                        282.96433627159433
                    ],
                    [
                        175.23828069558067,
                        281.97285249314234
                    ],
                    [
                        174.541307006426,
                        278.8016855788134
                    ],
                    [
                        174.67620513981092,
                        276.9445023831133
                    ],
                    [
                        175.39566185119634,
                        276.87969086982673
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Gadchiroli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Washim",
            "dt_code": "502",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        126.24777525469267,
                        289.59262036151074
                    ],
                    [
                        125.77563178784635,
                        288.68981446120466
                    ],
                    [
                        126.51757152146229,
                        287.4855599803588
                    ],
                    [
                        126.67495267707773,
                        285.52738873128885
                    ],
                    [
                        127.97896796646341,
                        285.505861680397
                    ],
                    [
                        127.82158681084775,
                        284.2785063770114
                    ],
                    [
                        128.90077187792576,
                        283.11517873916574
                    ],
                    [
                        131.756115701236,
                        281.0456635494898
                    ],
                    [
                        134.2292481466227,
                        281.26132061321135
                    ],
                    [
                        134.92622183577714,
                        280.07496822733407
                    ],
                    [
                        136.5449994363937,
                        279.92393603596037
                    ],
                    [
                        136.5449994363937,
                        278.477861986839
                    ],
                    [
                        138.2986751703952,
                        277.8300834871229
                    ],
                    [
                        138.59095445939533,
                        277.1173244802001
                    ],
                    [
                        140.52449437124324,
                        276.23147882246866
                    ],
                    [
                        141.19898503816717,
                        277.4845299099044
                    ],
                    [
                        141.10905294924396,
                        278.65057334408
                    ],
                    [
                        141.44629828270558,
                        279.0607129856434
                    ],
                    [
                        139.98490183770446,
                        281.2397557729561
                    ],
                    [
                        140.52449437124324,
                        283.37374404953823
                    ],
                    [
                        141.33388317155163,
                        283.6322818757504
                    ],
                    [
                        141.4013322382441,
                        285.91484329452817
                    ],
                    [
                        140.07483392662766,
                        287.0553264644745
                    ],
                    [
                        139.26544512631926,
                        285.9794130989691
                    ],
                    [
                        137.01714290324026,
                        286.77563432406623
                    ],
                    [
                        135.73561063608554,
                        288.92629545902486
                    ],
                    [
                        134.70139161346924,
                        287.9157183527426
                    ],
                    [
                        133.06013099062147,
                        288.56081529125186
                    ],
                    [
                        132.0708780124669,
                        287.3780086509866
                    ],
                    [
                        128.2037981887711,
                        288.51781407149065
                    ],
                    [
                        127.73165472192477,
                        289.4851607264475
                    ],
                    [
                        126.24777525469267,
                        289.59262036151074
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Washim",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Cuttack",
            "dt_code": "381",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        251.0285486355683,
                        277.8516789280271
                    ],
                    [
                        252.872156458493,
                        279.384457955874
                    ],
                    [
                        255.88488143741847,
                        279.9023592351638
                    ],
                    [
                        256.58185512657315,
                        279.1038815029213
                    ],
                    [
                        257.7734553048049,
                        279.4492017157752
                    ],
                    [
                        258.8076743274212,
                        278.88803115397053
                    ],
                    [
                        259.5496140610371,
                        277.72210335817573
                    ],
                    [
                        260.74121423926886,
                        276.966105829664
                    ],
                    [
                        262.2250937065012,
                        277.18212954095895
                    ],
                    [
                        262.62978810665527,
                        276.51239235588304
                    ],
                    [
                        264.47339592957997,
                        278.21877161296595
                    ],
                    [
                        266.00224144127355,
                        277.44133220056887
                    ],
                    [
                        267.98074739758295,
                        278.3915016416357
                    ],
                    [
                        267.5310869529669,
                        279.0607129856434
                    ],
                    [
                        267.89081530865974,
                        281.433832409742
                    ],
                    [
                        267.5310869529669,
                        281.77881909209145
                    ],
                    [
                        264.02373548496394,
                        281.58477013643846
                    ],
                    [
                        264.4509129073492,
                        283.0074350750463
                    ],
                    [
                        266.09217353019653,
                        284.1492751847635
                    ],
                    [
                        265.95727539681184,
                        285.24752229959677
                    ],
                    [
                        266.54183397481233,
                        286.6680519488704
                    ],
                    [
                        265.7099621522732,
                        286.9262417379867
                    ],
                    [
                        265.05795450758023,
                        285.8717958132408
                    ],
                    [
                        262.0901955731165,
                        281.9081764203686
                    ],
                    [
                        260.7187312170381,
                        280.700572186207
                    ],
                    [
                        259.70699521665256,
                        281.1750600975579
                    ],
                    [
                        258.33553086057464,
                        282.9212367034932
                    ],
                    [
                        256.8741344155733,
                        282.7057273888285
                    ],
                    [
                        254.01879059226326,
                        283.1367268987713
                    ],
                    [
                        254.55838312580204,
                        281.75725886535844
                    ],
                    [
                        251.5006921024151,
                        281.56320818070395
                    ],
                    [
                        246.93663858956484,
                        279.2333823992534
                    ],
                    [
                        247.65609530095026,
                        278.3699110690601
                    ],
                    [
                        250.26412587972163,
                        278.3915016416357
                    ],
                    [
                        251.0285486355683,
                        277.8516789280271
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Cuttack",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chandrapur",
            "dt_code": "509",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        168.5833061152673,
                        276.29630796304826
                    ],
                    [
                        170.67422718273065,
                        277.052517660236
                    ],
                    [
                        171.97824247211634,
                        276.6852457635207
                    ],
                    [
                        174.67620513981092,
                        276.9445023831133
                    ],
                    [
                        174.541307006426,
                        278.8016855788134
                    ],
                    [
                        175.23828069558067,
                        281.97285249314234
                    ],
                    [
                        174.8111032731956,
                        282.96433627159433
                    ],
                    [
                        175.46311091788834,
                        284.1492751847635
                    ],
                    [
                        175.17083162888844,
                        285.6565470621676
                    ],
                    [
                        175.50807696235006,
                        286.7971502339144
                    ],
                    [
                        174.40640887304153,
                        287.98023563521764
                    ],
                    [
                        173.48460496157918,
                        287.03381281391506
                    ],
                    [
                        172.47286896119363,
                        287.3564978213635
                    ],
                    [
                        172.76514825019376,
                        289.1842489502302
                    ],
                    [
                        171.79837829427015,
                        290.3876770145025
                    ],
                    [
                        172.45038593896288,
                        291.76231025378485
                    ],
                    [
                        172.81011429465548,
                        294.0802899650972
                    ],
                    [
                        171.88831038319313,
                        293.60828274047594
                    ],
                    [
                        167.88633242611263,
                        295.3028059785232
                    ],
                    [
                        166.3350038921883,
                        294.12319531642106
                    ],
                    [
                        164.67126024711,
                        293.865752221271
                    ],
                    [
                        163.74945633564766,
                        295.9459975097123
                    ],
                    [
                        162.37799197956974,
                        294.80958144369424
                    ],
                    [
                        160.01727464533678,
                        294.53075965343004
                    ],
                    [
                        160.30955393433715,
                        292.87864235534573
                    ],
                    [
                        158.8931235337975,
                        292.3634740927217
                    ],
                    [
                        158.6233272670279,
                        291.22543443707144
                    ],
                    [
                        159.54513117849024,
                        291.6119966750383
                    ],
                    [
                        160.53438415664505,
                        290.5165811379093
                    ],
                    [
                        162.01826362387692,
                        290.3447074875358
                    ],
                    [
                        162.53537313518518,
                        287.3564978213635
                    ],
                    [
                        160.26458788987543,
                        284.8168960142938
                    ],
                    [
                        159.7924444230291,
                        283.52456111983486
                    ],
                    [
                        158.24111588910455,
                        283.5676499943458
                    ],
                    [
                        157.9938026445659,
                        282.8134844370718
                    ],
                    [
                        158.2635989113353,
                        281.2828852610544
                    ],
                    [
                        161.72598433487678,
                        280.2044168530489
                    ],
                    [
                        162.89510149087778,
                        280.3338585239357
                    ],
                    [
                        164.19911678026347,
                        278.3699110690601
                    ],
                    [
                        165.66051322526482,
                        278.32672934026704
                    ],
                    [
                        166.1551397143421,
                        277.268533552842
                    ],
                    [
                        167.3692229148046,
                        277.13892636256554
                    ],
                    [
                        168.5833061152673,
                        276.29630796304826
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Chandrapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Yavatmal",
            "dt_code": "510",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        150.2821260194055,
                        276.642033586652
                    ],
                    [
                        150.10226184155908,
                        278.4346822032254
                    ],
                    [
                        152.28311499794563,
                        279.4707825815627
                    ],
                    [
                        153.72202842071624,
                        279.2765478132882
                    ],
                    [
                        154.50893419879367,
                        280.614291634941
                    ],
                    [
                        155.74550042148712,
                        280.9378277972141
                    ],
                    [
                        157.11696477756527,
                        282.8996866326599
                    ],
                    [
                        157.9938026445659,
                        282.8134844370718
                    ],
                    [
                        158.24111588910455,
                        283.5676499943458
                    ],
                    [
                        159.7924444230291,
                        283.52456111983486
                    ],
                    [
                        160.26458788987543,
                        284.8168960142938
                    ],
                    [
                        162.53537313518518,
                        287.3564978213635
                    ],
                    [
                        162.01826362387692,
                        290.3447074875358
                    ],
                    [
                        160.53438415664505,
                        290.5165811379093
                    ],
                    [
                        159.54513117849024,
                        291.6119966750383
                    ],
                    [
                        158.6233272670279,
                        291.22543443707144
                    ],
                    [
                        154.71128139887082,
                        290.3447074875358
                    ],
                    [
                        152.59787730917674,
                        290.40916149935356
                    ],
                    [
                        151.8559375755608,
                        289.3776964312693
                    ],
                    [
                        150.75426948625227,
                        288.88330059587133
                    ],
                    [
                        149.27039001901994,
                        289.0982674404649
                    ],
                    [
                        145.5606913509398,
                        288.92629545902486
                    ],
                    [
                        144.03184583924644,
                        290.6884429062864
                    ],
                    [
                        144.23419303932337,
                        291.48314925524636
                    ],
                    [
                        146.70732548471005,
                        292.3205386148391
                    ],
                    [
                        147.56168032948017,
                        293.17910823170104
                    ],
                    [
                        148.88817864109683,
                        292.9644934256579
                    ],
                    [
                        149.02307677448152,
                        294.402062258386
                    ],
                    [
                        147.2469180182493,
                        295.9674344082179
                    ],
                    [
                        144.88620068401633,
                        294.78813470942504
                    ],
                    [
                        143.28990610563028,
                        296.0960519836851
                    ],
                    [
                        142.5704493942451,
                        295.1741480082667
                    ],
                    [
                        141.08656992701322,
                        294.42351228125335
                    ],
                    [
                        140.457045304551,
                        293.0074178574455
                    ],
                    [
                        138.27619214816445,
                        292.4708095621201
                    ],
                    [
                        136.8597617476246,
                        289.7000753384096
                    ],
                    [
                        135.73561063608554,
                        288.92629545902486
                    ],
                    [
                        137.01714290324026,
                        286.77563432406623
                    ],
                    [
                        139.26544512631926,
                        285.9794130989691
                    ],
                    [
                        140.07483392662766,
                        287.0553264644745
                    ],
                    [
                        141.4013322382441,
                        285.91484329452817
                    ],
                    [
                        141.33388317155163,
                        283.6322818757504
                    ],
                    [
                        140.52449437124324,
                        283.37374404953823
                    ],
                    [
                        139.98490183770446,
                        281.2397557729561
                    ],
                    [
                        141.44629828270558,
                        279.0607129856434
                    ],
                    [
                        141.10905294924396,
                        278.65057334408
                    ],
                    [
                        142.99762681663015,
                        279.0391284359643
                    ],
                    [
                        145.85297063994017,
                        278.2835468331793
                    ],
                    [
                        146.79725757363326,
                        277.0741201290513
                    ],
                    [
                        148.14623890748044,
                        277.3549344390378
                    ],
                    [
                        150.2821260194055,
                        276.642033586652
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Yavatmal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kandhamal",
            "dt_code": "390",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        229.3324321828577,
                        281.41226910834155
                    ],
                    [
                        229.55726240516537,
                        280.89469214786413
                    ],
                    [
                        231.73811556155192,
                        280.67900233766704
                    ],
                    [
                        231.46831929478253,
                        279.53552401660875
                    ],
                    [
                        232.61495342855278,
                        278.3699110690601
                    ],
                    [
                        237.78604854163405,
                        278.3915016416357
                    ],
                    [
                        238.52798827524998,
                        276.966105829664
                    ],
                    [
                        239.89945263132813,
                        277.2901340673841
                    ],
                    [
                        240.16924889809752,
                        279.1902162095988
                    ],
                    [
                        241.96789067656073,
                        281.3044497165008
                    ],
                    [
                        242.44003414340705,
                        283.5676499943458
                    ],
                    [
                        243.09204178810023,
                        284.773829212109
                    ],
                    [
                        241.63064534309888,
                        285.2905807572421
                    ],
                    [
                        241.1360188540216,
                        284.8814947940652
                    ],
                    [
                        239.80952054240515,
                        287.09835320109283
                    ],
                    [
                        240.19173192032827,
                        288.195281058432
                    ],
                    [
                        241.27091698740628,
                        288.2382878944687
                    ],
                    [
                        241.24843396517554,
                        289.7430560253199
                    ],
                    [
                        240.03435076471283,
                        289.91497132551416
                    ],
                    [
                        238.50550525301924,
                        289.3776964312693
                    ],
                    [
                        237.1790069414028,
                        289.67858471558935
                    ],
                    [
                        236.8192785857102,
                        291.5905225673202
                    ],
                    [
                        237.9883957417112,
                        292.7283958997647
                    ],
                    [
                        237.898463652788,
                        294.16609993555306
                    ],
                    [
                        236.36961814109418,
                        293.28640874390254
                    ],
                    [
                        233.58172338447662,
                        293.20056870153803
                    ],
                    [
                        232.18777600616772,
                        292.04144002893736
                    ],
                    [
                        231.04114187239747,
                        291.93408611600915
                    ],
                    [
                        229.28746613839598,
                        292.8571791279405
                    ],
                    [
                        228.86028871601093,
                        290.40916149935356
                    ],
                    [
                        227.5337904043945,
                        289.7645460894411
                    ],
                    [
                        228.00593387124104,
                        288.6038157627079
                    ],
                    [
                        227.06164693754795,
                        288.1307693992719
                    ],
                    [
                        228.41062827139535,
                        285.95789001979983
                    ],
                    [
                        230.20927004985833,
                        285.91484329452817
                    ],
                    [
                        231.26597209470538,
                        284.1708141924488
                    ],
                    [
                        230.34416818324303,
                        283.78308292621455
                    ],
                    [
                        230.1418209831661,
                        281.73569844657766
                    ],
                    [
                        229.3324321828577,
                        281.41226910834155
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Kandhamal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jalna",
            "dt_code": "514",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        116.49014360653041,
                        279.1686328238742
                    ],
                    [
                        115.97303409522237,
                        279.643422534919
                    ],
                    [
                        116.35524547314571,
                        281.71413783573274
                    ],
                    [
                        117.29953240683881,
                        282.7272791811177
                    ],
                    [
                        118.10892120714743,
                        281.75725886535844
                    ],
                    [
                        118.91831000745583,
                        282.79193341003196
                    ],
                    [
                        118.2438193405319,
                        286.0009359891817
                    ],
                    [
                        116.31027942868423,
                        287.528579196987
                    ],
                    [
                        118.01898911822423,
                        288.86180288403966
                    ],
                    [
                        118.28878538499362,
                        289.7430560253199
                    ],
                    [
                        121.2115782749961,
                        289.5496370665746
                    ],
                    [
                        122.85283889784364,
                        288.7543115217066
                    ],
                    [
                        124.11188814276761,
                        288.7543115217066
                    ],
                    [
                        124.74141276523005,
                        289.87199361733315
                    ],
                    [
                        124.38168440953723,
                        290.2372804186819
                    ],
                    [
                        123.97699000938314,
                        290.6669608347632
                    ],
                    [
                        124.02195605384463,
                        292.55667462120357
                    ],
                    [
                        123.45988049807488,
                        292.79278834189074
                    ],
                    [
                        123.34746538692093,
                        294.6808982992421
                    ],
                    [
                        122.35821240876612,
                        294.7237934111446
                    ],
                    [
                        122.20083125315091,
                        295.77449577791907
                    ],
                    [
                        121.0766801416114,
                        296.3532675222513
                    ],
                    [
                        120.71695178591881,
                        297.9174334300182
                    ],
                    [
                        119.90756298561064,
                        298.8383413325893
                    ],
                    [
                        118.37871747391682,
                        298.30297026269494
                    ],
                    [
                        116.01800013968409,
                        298.5599624047422
                    ],
                    [
                        115.54585667283732,
                        296.9961921185327
                    ],
                    [
                        113.18513933860459,
                        297.5318381741705
                    ],
                    [
                        111.04925222667976,
                        297.10333037095444
                    ],
                    [
                        111.76870893806495,
                        294.2304554916628
                    ],
                    [
                        110.9368371155258,
                        293.7584764790638
                    ],
                    [
                        111.4314636046031,
                        292.68546668476483
                    ],
                    [
                        111.31904849344937,
                        291.09656703196987
                    ],
                    [
                        111.76870893806495,
                        288.9477926103778
                    ],
                    [
                        111.61132778244951,
                        287.39951929267255
                    ],
                    [
                        110.8469050266026,
                        287.39951929267255
                    ],
                    [
                        111.61132778244951,
                        284.6015544086105
                    ],
                    [
                        113.38748653868174,
                        280.42014910909415
                    ],
                    [
                        114.46667160575953,
                        280.3338585239357
                    ],
                    [
                        115.09619622822174,
                        278.71533689608424
                    ],
                    [
                        116.49014360653041,
                        279.1686328238742
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Jalna",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nayagarh",
            "dt_code": "385",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        241.96789067656073,
                        281.3044497165008
                    ],
                    [
                        244.4859891664089,
                        280.7221418419224
                    ],
                    [
                        245.74503841133333,
                        278.04602912647726
                    ],
                    [
                        246.93663858956484,
                        279.2333823992534
                    ],
                    [
                        251.5006921024151,
                        281.56320818070395
                    ],
                    [
                        254.55838312580204,
                        281.75725886535844
                    ],
                    [
                        254.01879059226326,
                        283.1367268987713
                    ],
                    [
                        253.9063754811093,
                        283.91233353733065
                    ],
                    [
                        252.44497903610818,
                        284.0415772916067
                    ],
                    [
                        252.2875978804925,
                        285.44127939184284
                    ],
                    [
                        254.49093405911003,
                        284.55848380841593
                    ],
                    [
                        254.7607303258792,
                        286.388315719296
                    ],
                    [
                        255.86239841518795,
                        287.9587300619121
                    ],
                    [
                        255.32280588164917,
                        288.68981446120466
                    ],
                    [
                        251.54565814687658,
                        288.99078635271474
                    ],
                    [
                        250.98358259110682,
                        288.5393147749173
                    ],
                    [
                        248.28561992341224,
                        289.0982674404649
                    ],
                    [
                        247.7235443676425,
                        287.63612395185214
                    ],
                    [
                        246.1946988559489,
                        286.08702566077744
                    ],
                    [
                        246.68932534502642,
                        284.10619659849414
                    ],
                    [
                        244.82323449987098,
                        283.1367268987713
                    ],
                    [
                        243.09204178810023,
                        284.773829212109
                    ],
                    [
                        242.44003414340705,
                        283.5676499943458
                    ],
                    [
                        241.96789067656073,
                        281.3044497165008
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Nayagarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Uttar Bastar Kanker",
            "dt_code": "413",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        185.5355048772817,
                        292.14878932807136
                    ],
                    [
                        183.9392102988959,
                        291.2683887574663
                    ],
                    [
                        183.691897054357,
                        290.3447074875358
                    ],
                    [
                        182.54526292058677,
                        290.2157944473971
                    ],
                    [
                        183.44458380981837,
                        288.4533108385035
                    ],
                    [
                        183.66941403212627,
                        285.9363667516583
                    ],
                    [
                        184.25397261012677,
                        284.7307616505742
                    ],
                    [
                        185.26570861051232,
                        283.95541555045895
                    ],
                    [
                        186.16502949974392,
                        284.5154124482417
                    ],
                    [
                        186.6821390110522,
                        282.94278658313283
                    ],
                    [
                        187.85125616705295,
                        282.6626232301788
                    ],
                    [
                        188.30091661166875,
                        281.433832409742
                    ],
                    [
                        190.23445652351643,
                        280.31228539494987
                    ],
                    [
                        192.5502078132879,
                        281.60633189999845
                    ],
                    [
                        193.62939288036546,
                        280.9378277972141
                    ],
                    [
                        195.1357553698283,
                        278.6721613890616
                    ],
                    [
                        196.3948046147525,
                        278.8232722638979
                    ],
                    [
                        197.0692952816762,
                        279.4492017157752
                    ],
                    [
                        199.1377333269088,
                        279.3628763150075
                    ],
                    [
                        200.53168070521747,
                        279.9670890573811
                    ],
                    [
                        202.03804319468054,
                        282.3824275334123
                    ],
                    [
                        201.74576390568018,
                        283.86925076233115
                    ],
                    [
                        202.33032248368067,
                        284.8814947940652
                    ],
                    [
                        200.3518165273715,
                        285.46280701068963
                    ],
                    [
                        198.9578691490624,
                        284.4508039827424
                    ],
                    [
                        196.979363192753,
                        285.2044630839863
                    ],
                    [
                        196.05755928129065,
                        286.7541182258419
                    ],
                    [
                        194.641128880751,
                        287.16289189500327
                    ],
                    [
                        195.15823839205927,
                        288.4533108385035
                    ],
                    [
                        196.32735554806004,
                        289.3776964312693
                    ],
                    [
                        195.85521208121372,
                        290.9462132975514
                    ],
                    [
                        192.6851059466726,
                        291.332818849762
                    ],
                    [
                        191.60592087959458,
                        292.0843803021376
                    ],
                    [
                        190.4817697680553,
                        290.43064579847066
                    ],
                    [
                        189.60493190105444,
                        290.1513354182346
                    ],
                    [
                        188.70561101182284,
                        288.4748121032996
                    ],
                    [
                        188.0536033671301,
                        288.3242993189368
                    ],
                    [
                        186.6596559888212,
                        289.807525659094
                    ],
                    [
                        185.5355048772817,
                        292.14878932807136
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Uttar Bastar Kanker",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Khordha",
            "dt_code": "386",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        254.01879059226326,
                        283.1367268987713
                    ],
                    [
                        256.8741344155733,
                        282.7057273888285
                    ],
                    [
                        258.33553086057464,
                        282.9212367034932
                    ],
                    [
                        259.70699521665256,
                        281.1750600975579
                    ],
                    [
                        260.7187312170381,
                        280.700572186207
                    ],
                    [
                        262.0901955731165,
                        281.9081764203686
                    ],
                    [
                        265.05795450758023,
                        285.8717958132408
                    ],
                    [
                        262.3824748621166,
                        285.7856985821157
                    ],
                    [
                        262.20261068427044,
                        284.6230894237545
                    ],
                    [
                        259.50464801657586,
                        285.26905162315717
                    ],
                    [
                        259.14491966088303,
                        286.4528730624345
                    ],
                    [
                        257.2788288157276,
                        288.0877606895794
                    ],
                    [
                        257.4586929935738,
                        289.807525659094
                    ],
                    [
                        255.61508517064908,
                        289.3347094079287
                    ],
                    [
                        254.01879059226326,
                        290.860292800641
                    ],
                    [
                        252.872156458493,
                        291.1395235744479
                    ],
                    [
                        251.86042045810746,
                        292.6210714815808
                    ],
                    [
                        251.18592979118398,
                        291.5905225673202
                    ],
                    [
                        249.22990685710533,
                        290.5595476936292
                    ],
                    [
                        248.28561992341224,
                        289.0982674404649
                    ],
                    [
                        250.98358259110682,
                        288.5393147749173
                    ],
                    [
                        251.54565814687658,
                        288.99078635271474
                    ],
                    [
                        255.32280588164917,
                        288.68981446120466
                    ],
                    [
                        255.86239841518795,
                        287.9587300619121
                    ],
                    [
                        254.7607303258792,
                        286.388315719296
                    ],
                    [
                        254.49093405911003,
                        284.55848380841593
                    ],
                    [
                        252.2875978804925,
                        285.44127939184284
                    ],
                    [
                        252.44497903610818,
                        284.0415772916067
                    ],
                    [
                        253.9063754811093,
                        283.91233353733065
                    ],
                    [
                        254.01879059226326,
                        283.1367268987713
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Khordha",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kalahandi",
            "dt_code": "395",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        215.61778862207666,
                        287.6791405394448
                    ],
                    [
                        217.1241511115395,
                        284.9030273411172
                    ],
                    [
                        220.00197795708027,
                        284.8168960142938
                    ],
                    [
                        221.10364604638903,
                        284.3646567009709
                    ],
                    [
                        221.89055182446646,
                        284.85996205725456
                    ],
                    [
                        223.08215200269842,
                        284.30004424326154
                    ],
                    [
                        223.26201618054483,
                        283.4814714823169
                    ],
                    [
                        225.08314098123856,
                        282.5333061605195
                    ],
                    [
                        225.9599788482392,
                        281.1966255151452
                    ],
                    [
                        227.10661298200966,
                        280.9809626759636
                    ],
                    [
                        228.86028871601093,
                        281.9944108002667
                    ],
                    [
                        229.3324321828577,
                        281.41226910834155
                    ],
                    [
                        230.1418209831661,
                        281.73569844657766
                    ],
                    [
                        230.34416818324303,
                        283.78308292621455
                    ],
                    [
                        231.26597209470538,
                        284.1708141924488
                    ],
                    [
                        230.20927004985833,
                        285.91484329452817
                    ],
                    [
                        228.41062827139535,
                        285.95789001979983
                    ],
                    [
                        227.06164693754795,
                        288.1307693992719
                    ],
                    [
                        225.71266560370077,
                        288.73281268849576
                    ],
                    [
                        226.43212231508573,
                        291.6119966750383
                    ],
                    [
                        225.1955560923925,
                        293.4366217480406
                    ],
                    [
                        223.64422755846795,
                        294.5736573229145
                    ],
                    [
                        222.49759342469793,
                        295.98887112500404
                    ],
                    [
                        220.78888373515792,
                        294.35916166409544
                    ],
                    [
                        219.46238542354172,
                        296.0960519836851
                    ],
                    [
                        218.56306453431012,
                        299.395008135298
                    ],
                    [
                        217.86609084515544,
                        300.05856789303004
                    ],
                    [
                        217.64126062284777,
                        299.908746852903
                    ],
                    [
                        217.14663413377025,
                        298.08879034083986
                    ],
                    [
                        216.1798641778464,
                        298.0031133274846
                    ],
                    [
                        215.1906111996916,
                        296.5675938446549
                    ],
                    [
                        213.39196942122862,
                        294.61655426150656
                    ],
                    [
                        213.0996901322285,
                        293.5653685931935
                    ],
                    [
                        213.79666382138294,
                        291.01065172405964
                    ],
                    [
                        215.30302631084555,
                        290.1513354182346
                    ],
                    [
                        215.61778862207666,
                        287.6791405394448
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Kalahandi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jagatsinghpur",
            "dt_code": "380",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        266.54183397481233,
                        286.6680519488704
                    ],
                    [
                        265.95727539681184,
                        285.24752229959677
                    ],
                    [
                        266.09217353019653,
                        284.1492751847635
                    ],
                    [
                        264.4509129073492,
                        283.0074350750463
                    ],
                    [
                        264.02373548496394,
                        281.58477013643846
                    ],
                    [
                        267.5310869529669,
                        281.77881909209145
                    ],
                    [
                        269.666974064892,
                        281.77881909209145
                    ],
                    [
                        270.52132890966186,
                        282.33931763017455
                    ],
                    [
                        272.7471481105099,
                        282.166870351132
                    ],
                    [
                        274.5008238445114,
                        282.8350352728385
                    ],
                    [
                        275.28772962258904,
                        282.25309552412483
                    ],
                    [
                        273.8712992220494,
                        283.37374404953823
                    ],
                    [
                        271.7129290878938,
                        284.66615888421546
                    ],
                    [
                        269.3072457091994,
                        287.7866787236396
                    ],
                    [
                        267.84584926419825,
                        286.8616968333602
                    ],
                    [
                        266.54183397481233,
                        286.6680519488704
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Jagatsinghpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Puri",
            "dt_code": "387",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        265.05795450758023,
                        285.8717958132408
                    ],
                    [
                        265.7099621522732,
                        286.9262417379867
                    ],
                    [
                        266.54183397481233,
                        286.6680519488704
                    ],
                    [
                        267.84584926419825,
                        286.8616968333602
                    ],
                    [
                        269.3072457091994,
                        287.7866787236396
                    ],
                    [
                        267.21632464173626,
                        289.05527556553113
                    ],
                    [
                        262.18012766203947,
                        290.43064579847066
                    ],
                    [
                        259.14491966088303,
                        291.48314925524636
                    ],
                    [
                        254.3560359257251,
                        293.9301154676676
                    ],
                    [
                        251.70303930249202,
                        295.81737230170785
                    ],
                    [
                        251.86042045810746,
                        292.6210714815808
                    ],
                    [
                        252.872156458493,
                        291.1395235744479
                    ],
                    [
                        254.01879059226326,
                        290.860292800641
                    ],
                    [
                        255.61508517064908,
                        289.3347094079287
                    ],
                    [
                        257.4586929935738,
                        289.807525659094
                    ],
                    [
                        257.2788288157276,
                        288.0877606895794
                    ],
                    [
                        259.14491966088303,
                        286.4528730624345
                    ],
                    [
                        259.50464801657586,
                        285.26905162315717
                    ],
                    [
                        262.20261068427044,
                        284.6230894237545
                    ],
                    [
                        262.3824748621166,
                        285.7856985821157
                    ],
                    [
                        265.05795450758023,
                        285.8717958132408
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Puri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ahmednagar",
            "dt_code": "522",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        81.79884030442417,
                        293.9730233826373
                    ],
                    [
                        83.08037257157912,
                        293.8013873257715
                    ],
                    [
                        84.15955763865668,
                        293.11472571975173
                    ],
                    [
                        85.08136155011903,
                        291.5475737972338
                    ],
                    [
                        86.34041079504345,
                        292.6210714815808
                    ],
                    [
                        87.75684119558287,
                        292.5996060456084
                    ],
                    [
                        89.19575461835325,
                        293.11472571975173
                    ],
                    [
                        89.89272830750792,
                        291.7193644410022
                    ],
                    [
                        92.92793630866436,
                        290.6025135068488
                    ],
                    [
                        92.77055515304869,
                        289.50665302620706
                    ],
                    [
                        91.466539863663,
                        288.96928957493594
                    ],
                    [
                        92.11854750835596,
                        287.7436640129897
                    ],
                    [
                        97.289642621437,
                        288.3458017068942
                    ],
                    [
                        96.14300848766698,
                        290.92473345138
                    ],
                    [
                        97.98661631059167,
                        290.9462132975514
                    ],
                    [
                        99.92015622243957,
                        293.0288797975515
                    ],
                    [
                        100.43726573374761,
                        292.87864235534573
                    ],
                    [
                        104.39427764636639,
                        293.9301154676676
                    ],
                    [
                        105.90064013582901,
                        294.95970347295986
                    ],
                    [
                        106.97982520290702,
                        295.2384778134933
                    ],
                    [
                        107.51941773644603,
                        296.9104782590516
                    ],
                    [
                        109.13819533706283,
                        296.46043295051305
                    ],
                    [
                        109.94758413737122,
                        297.2533163268572
                    ],
                    [
                        109.79020298175556,
                        298.7741027376476
                    ],
                    [
                        108.08149329221555,
                        300.01576277571775
                    ],
                    [
                        108.75598395913926,
                        301.8129633734967
                    ],
                    [
                        106.68754591390689,
                        301.57768714915767
                    ],
                    [
                        106.32781755821406,
                        302.5614267274125
                    ],
                    [
                        104.88890413544368,
                        301.6418555236946
                    ],
                    [
                        104.57414182421257,
                        302.5186632400256
                    ],
                    [
                        103.18019444590368,
                        302.2406833063334
                    ],
                    [
                        101.2916205785175,
                        301.02149419977854
                    ],
                    [
                        100.88692617836318,
                        302.5186632400256
                    ],
                    [
                        99.56042786674675,
                        304.1431790714887
                    ],
                    [
                        102.73053400128788,
                        306.770180199013
                    ],
                    [
                        104.4392436908281,
                        308.66937521357755
                    ],
                    [
                        105.20366644667479,
                        307.1543979015685
                    ],
                    [
                        106.21540244706034,
                        307.303800690407
                    ],
                    [
                        106.97982520290702,
                        306.1296920331829
                    ],
                    [
                        107.7442479587537,
                        306.68479083415525
                    ],
                    [
                        110.73448991544865,
                        307.81597426562416
                    ],
                    [
                        108.93584813698567,
                        309.2239376249488
                    ],
                    [
                        108.23887444783122,
                        310.37534929349073
                    ],
                    [
                        106.73251195836838,
                        310.5458862211261
                    ],
                    [
                        105.69829293575208,
                        310.24743939694156
                    ],
                    [
                        103.69730395721194,
                        311.01480625373557
                    ],
                    [
                        103.47247373490404,
                        311.93109293582165
                    ],
                    [
                        100.88692617836318,
                        312.74057242856406
                    ],
                    [
                        99.6728429779007,
                        313.4220491678665
                    ],
                    [
                        99.33559764443885,
                        312.6979743644775
                    ],
                    [
                        95.46851782074327,
                        310.73772715774567
                    ],
                    [
                        95.94066128758982,
                        310.0982033786218
                    ],
                    [
                        94.36684973143474,
                        308.66937521357755
                    ],
                    [
                        94.43429879812697,
                        307.965339416422
                    ],
                    [
                        93.0853174642798,
                        306.15104416611945
                    ],
                    [
                        90.00514341866165,
                        302.60418950680895
                    ],
                    [
                        90.88198128566273,
                        301.1926433023628
                    ],
                    [
                        90.6571510633546,
                        299.9515537585971
                    ],
                    [
                        87.89173932896779,
                        299.2023295075449
                    ],
                    [
                        87.41959586212101,
                        297.51041450068635
                    ],
                    [
                        86.49779195065867,
                        297.8317506474568
                    ],
                    [
                        84.2045236831184,
                        297.0819030823372
                    ],
                    [
                        82.92299141596345,
                        296.331833892335
                    ],
                    [
                        83.23775372719456,
                        295.5601022450153
                    ],
                    [
                        81.88877239334715,
                        294.65945046932984
                    ],
                    [
                        81.79884030442417,
                        293.9730233826373
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Ahmednagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nabarangapur",
            "dt_code": "397",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        213.79666382138294,
                        291.01065172405964
                    ],
                    [
                        213.0996901322285,
                        293.5653685931935
                    ],
                    [
                        213.39196942122862,
                        294.61655426150656
                    ],
                    [
                        215.1906111996916,
                        296.5675938446549
                    ],
                    [
                        216.1798641778464,
                        298.0031133274846
                    ],
                    [
                        217.14663413377025,
                        298.08879034083986
                    ],
                    [
                        217.64126062284777,
                        299.908746852903
                    ],
                    [
                        216.87683786700063,
                        300.74335259445513
                    ],
                    [
                        215.370475377538,
                        300.122774228152
                    ],
                    [
                        214.1114261326136,
                        300.6363670325046
                    ],
                    [
                        212.6725127098432,
                        299.9515537585971
                    ],
                    [
                        210.96380302030343,
                        300.5293770112668
                    ],
                    [
                        210.24434630891824,
                        300.2297812121192
                    ],
                    [
                        208.08597617476266,
                        301.214036139142
                    ],
                    [
                        207.43396853006948,
                        299.9515537585971
                    ],
                    [
                        207.8386629302238,
                        296.43900022765195
                    ],
                    [
                        206.91685901876144,
                        296.4175673234107
                    ],
                    [
                        206.5346476408381,
                        295.04548347555584
                    ],
                    [
                        205.47794559599083,
                        295.1955914589453
                    ],
                    [
                        205.92760604060686,
                        293.3078682953254
                    ],
                    [
                        206.01753812953007,
                        290.7743693372183
                    ],
                    [
                        204.84842097352907,
                        290.6025135068488
                    ],
                    [
                        204.6011077289902,
                        289.7000753384096
                    ],
                    [
                        203.1397112839893,
                        289.03377934799676
                    ],
                    [
                        202.77998292829625,
                        288.25979103151303
                    ],
                    [
                        203.22964337291228,
                        286.71108546420237
                    ],
                    [
                        203.22964337291228,
                        286.689568800756
                    ],
                    [
                        204.26386239552858,
                        285.85027178905193
                    ],
                    [
                        205.32056444037562,
                        286.7326019392257
                    ],
                    [
                        206.0849871962223,
                        286.60350026242526
                    ],
                    [
                        207.77121386353156,
                        287.7436640129897
                    ],
                    [
                        209.007780086225,
                        287.89421221684734
                    ],
                    [
                        210.1319311977643,
                        290.06538744305016
                    ],
                    [
                        211.6158106649964,
                        288.9047981208613
                    ],
                    [
                        212.8748599099206,
                        289.3991896632078
                    ],
                    [
                        213.79666382138294,
                        291.01065172405964
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Nabarangapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hingoli",
            "dt_code": "512",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        124.74141276523005,
                        289.87199361733315
                    ],
                    [
                        126.24777525469267,
                        289.59262036151074
                    ],
                    [
                        127.73165472192477,
                        289.4851607264475
                    ],
                    [
                        128.2037981887711,
                        288.51781407149065
                    ],
                    [
                        132.0708780124669,
                        287.3780086509866
                    ],
                    [
                        133.06013099062147,
                        288.56081529125186
                    ],
                    [
                        134.70139161346924,
                        287.9157183527426
                    ],
                    [
                        135.73561063608554,
                        288.92629545902486
                    ],
                    [
                        136.8597617476246,
                        289.7000753384096
                    ],
                    [
                        138.27619214816445,
                        292.4708095621201
                    ],
                    [
                        138.45605632601087,
                        295.43145738966115
                    ],
                    [
                        137.1295580143942,
                        297.8317506474568
                    ],
                    [
                        136.14030503623962,
                        297.5746849793688
                    ],
                    [
                        134.18428210216098,
                        299.54485925111555
                    ],
                    [
                        133.86951979092987,
                        301.79157551379126
                    ],
                    [
                        132.79033472385208,
                        301.40656366384263
                    ],
                    [
                        131.19404014546603,
                        298.15304620875037
                    ],
                    [
                        129.91250787831132,
                        297.5746849793688
                    ],
                    [
                        129.3279493003106,
                        295.1741480082667
                    ],
                    [
                        130.24975321177294,
                        293.0932645149043
                    ],
                    [
                        128.47359445554093,
                        292.64253673342074
                    ],
                    [
                        126.92226592161637,
                        291.28986563998876
                    ],
                    [
                        124.38168440953723,
                        290.2372804186819
                    ],
                    [
                        124.74141276523005,
                        289.87199361733315
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Hingoli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rayagada",
            "dt_code": "396",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        227.06164693754795,
                        288.1307693992719
                    ],
                    [
                        228.00593387124104,
                        288.6038157627079
                    ],
                    [
                        227.5337904043945,
                        289.7645460894411
                    ],
                    [
                        228.86028871601093,
                        290.40916149935356
                    ],
                    [
                        229.28746613839598,
                        292.8571791279405
                    ],
                    [
                        231.04114187239747,
                        291.93408611600915
                    ],
                    [
                        232.18777600616772,
                        292.04144002893736
                    ],
                    [
                        233.58172338447662,
                        293.20056870153803
                    ],
                    [
                        234.16628196247711,
                        294.83102799541064
                    ],
                    [
                        233.69413849563034,
                        296.93190699544544
                    ],
                    [
                        234.68339147378515,
                        297.23189030423674
                    ],
                    [
                        234.77332356270836,
                        298.5813772471388
                    ],
                    [
                        234.16628196247711,
                        299.7803218419284
                    ],
                    [
                        234.95318774055454,
                        301.04288846077816
                    ],
                    [
                        233.73910454009206,
                        303.13866456231113
                    ],
                    [
                        231.42335325032082,
                        302.81799278820716
                    ],
                    [
                        230.7488625833971,
                        303.2027941476589
                    ],
                    [
                        230.88376071678204,
                        304.48505273924457
                    ],
                    [
                        229.5122963607041,
                        301.5562973356006
                    ],
                    [
                        228.7029075603955,
                        301.5990767850153
                    ],
                    [
                        226.83681671523982,
                        302.5614267274125
                    ],
                    [
                        226.52205440400894,
                        303.7798889661604
                    ],
                    [
                        224.8807937811614,
                        302.81799278820716
                    ],
                    [
                        223.86905778077607,
                        301.1926433023628
                    ],
                    [
                        223.21705013608312,
                        302.6897129421193
                    ],
                    [
                        221.755653691082,
                        300.9787051434748
                    ],
                    [
                        220.09191004600348,
                        302.1551449949085
                    ],
                    [
                        219.84459680146483,
                        301.214036139142
                    ],
                    [
                        217.86609084515544,
                        300.05856789303004
                    ],
                    [
                        218.56306453431012,
                        299.395008135298
                    ],
                    [
                        219.46238542354172,
                        296.0960519836851
                    ],
                    [
                        220.78888373515792,
                        294.35916166409544
                    ],
                    [
                        222.49759342469793,
                        295.98887112500404
                    ],
                    [
                        223.64422755846795,
                        294.5736573229145
                    ],
                    [
                        225.1955560923925,
                        293.4366217480406
                    ],
                    [
                        226.43212231508573,
                        291.6119966750383
                    ],
                    [
                        225.71266560370077,
                        288.73281268849576
                    ],
                    [
                        227.06164693754795,
                        288.1307693992719
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Rayagada",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nanded",
            "dt_code": "511",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        138.27619214816445,
                        292.4708095621201
                    ],
                    [
                        140.457045304551,
                        293.0074178574455
                    ],
                    [
                        141.08656992701322,
                        294.42351228125335
                    ],
                    [
                        142.5704493942451,
                        295.1741480082667
                    ],
                    [
                        143.28990610563028,
                        296.0960519836851
                    ],
                    [
                        144.88620068401633,
                        294.78813470942504
                    ],
                    [
                        147.2469180182493,
                        295.9674344082179
                    ],
                    [
                        149.02307677448152,
                        294.402062258386
                    ],
                    [
                        148.88817864109683,
                        292.9644934256579
                    ],
                    [
                        147.56168032948017,
                        293.17910823170104
                    ],
                    [
                        146.70732548471005,
                        292.3205386148391
                    ],
                    [
                        144.23419303932337,
                        291.48314925524636
                    ],
                    [
                        144.03184583924644,
                        290.6884429062864
                    ],
                    [
                        145.5606913509398,
                        288.92629545902486
                    ],
                    [
                        149.27039001901994,
                        289.0982674404649
                    ],
                    [
                        150.75426948625227,
                        288.88330059587133
                    ],
                    [
                        151.60862433102193,
                        290.92473345138
                    ],
                    [
                        151.29386201979105,
                        291.97702823496
                    ],
                    [
                        150.30460904163624,
                        292.3205386148391
                    ],
                    [
                        150.75426948625227,
                        293.65119615408014
                    ],
                    [
                        150.79923553071376,
                        295.6029824072024
                    ],
                    [
                        149.36032210794315,
                        296.43900022765195
                    ],
                    [
                        149.09052584117376,
                        298.17446447106767
                    ],
                    [
                        148.82072957440437,
                        299.395008135298
                    ],
                    [
                        146.86470664032572,
                        298.88116616423434
                    ],
                    [
                        145.42579321755534,
                        297.76748666660114
                    ],
                    [
                        144.23419303932337,
                        298.36722072773574
                    ],
                    [
                        144.18922699486188,
                        300.2939832584313
                    ],
                    [
                        143.26742308339954,
                        301.9626734214149
                    ],
                    [
                        143.94191375032324,
                        304.52778378512915
                    ],
                    [
                        145.49324228424734,
                        305.5317614246396
                    ],
                    [
                        143.89694770586152,
                        306.1296920331829
                    ],
                    [
                        143.1325249500146,
                        308.02935045477244
                    ],
                    [
                        142.27817010524473,
                        308.28537903189954
                    ],
                    [
                        142.41306823862965,
                        309.991601073371
                    ],
                    [
                        140.3221471711663,
                        310.0982033786218
                    ],
                    [
                        140.1422829933199,
                        311.69672404844084
                    ],
                    [
                        139.26544512631926,
                        312.8470646176613
                    ],
                    [
                        139.6476565042426,
                        314.0820644185967
                    ],
                    [
                        138.3886072593184,
                        314.5503638720703
                    ],
                    [
                        137.55673543677926,
                        313.9330429162161
                    ],
                    [
                        137.62418450347172,
                        312.52757531400954
                    ],
                    [
                        136.20775410293209,
                        311.7606448813965
                    ],
                    [
                        135.4433313470854,
                        312.05892182099956
                    ],
                    [
                        135.9154748139315,
                        310.14084309904155
                    ],
                    [
                        135.48829739154667,
                        309.1173000597603
                    ],
                    [
                        133.80207072423764,
                        308.5840475664051
                    ],
                    [
                        133.7795877020069,
                        307.7519580308808
                    ],
                    [
                        132.22825916808233,
                        308.02935045477244
                    ],
                    [
                        132.3406742792365,
                        306.68479083415525
                    ],
                    [
                        131.3963873455432,
                        305.7666790684422
                    ],
                    [
                        130.90176085646613,
                        304.1431790714887
                    ],
                    [
                        131.89101383462048,
                        304.16454749411287
                    ],
                    [
                        132.79033472385208,
                        301.40656366384263
                    ],
                    [
                        133.86951979092987,
                        301.79157551379126
                    ],
                    [
                        134.18428210216098,
                        299.54485925111555
                    ],
                    [
                        136.14030503623962,
                        297.5746849793688
                    ],
                    [
                        137.1295580143942,
                        297.8317506474568
                    ],
                    [
                        138.45605632601087,
                        295.43145738966115
                    ],
                    [
                        138.27619214816445,
                        292.4708095621201
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Nanded",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Narayanpur",
            "dt_code": "415",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        188.18850150051458,
                        297.4247180004906
                    ],
                    [
                        188.8180261229768,
                        295.00259383908593
                    ],
                    [
                        186.16502949974392,
                        293.5009959963532
                    ],
                    [
                        185.5355048772817,
                        292.14878932807136
                    ],
                    [
                        186.6596559888212,
                        289.807525659094
                    ],
                    [
                        188.0536033671301,
                        288.3242993189368
                    ],
                    [
                        188.70561101182284,
                        288.4748121032996
                    ],
                    [
                        189.60493190105444,
                        290.1513354182346
                    ],
                    [
                        190.4817697680553,
                        290.43064579847066
                    ],
                    [
                        191.60592087959458,
                        292.0843803021376
                    ],
                    [
                        192.6851059466726,
                        291.332818849762
                    ],
                    [
                        195.85521208121372,
                        290.9462132975514
                    ],
                    [
                        195.85521208121372,
                        291.7193644410022
                    ],
                    [
                        197.49647270406126,
                        292.3849415551768
                    ],
                    [
                        196.64211785929137,
                        295.6244222153156
                    ],
                    [
                        197.36157457067634,
                        295.7530572432453
                    ],
                    [
                        197.27164248175336,
                        297.23189030423674
                    ],
                    [
                        195.4055516365977,
                        298.1316277662833
                    ],
                    [
                        194.73106096967422,
                        297.8745923994357
                    ],
                    [
                        192.25792852428754,
                        299.11668989820856
                    ],
                    [
                        189.69486398997765,
                        299.4164159752135
                    ],
                    [
                        189.64989794551593,
                        298.26013571915047
                    ],
                    [
                        188.18850150051458,
                        297.4247180004906
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Narayanpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Parbhani",
            "dt_code": "513",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        119.90756298561064,
                        298.8383413325893
                    ],
                    [
                        120.71695178591881,
                        297.9174334300182
                    ],
                    [
                        121.0766801416114,
                        296.3532675222513
                    ],
                    [
                        122.20083125315091,
                        295.77449577791907
                    ],
                    [
                        122.35821240876612,
                        294.7237934111446
                    ],
                    [
                        123.34746538692093,
                        294.6808982992421
                    ],
                    [
                        123.45988049807488,
                        292.79278834189074
                    ],
                    [
                        124.02195605384463,
                        292.55667462120357
                    ],
                    [
                        123.97699000938314,
                        290.6669608347632
                    ],
                    [
                        124.38168440953723,
                        290.2372804186819
                    ],
                    [
                        126.92226592161637,
                        291.28986563998876
                    ],
                    [
                        128.47359445554093,
                        292.64253673342074
                    ],
                    [
                        130.24975321177294,
                        293.0932645149043
                    ],
                    [
                        129.3279493003106,
                        295.1741480082667
                    ],
                    [
                        129.91250787831132,
                        297.5746849793688
                    ],
                    [
                        131.19404014546603,
                        298.15304620875037
                    ],
                    [
                        132.79033472385208,
                        301.40656366384263
                    ],
                    [
                        131.89101383462048,
                        304.16454749411287
                    ],
                    [
                        130.90176085646613,
                        304.1431790714887
                    ],
                    [
                        131.3963873455432,
                        305.7666790684422
                    ],
                    [
                        129.6427116115417,
                        305.72396834262247
                    ],
                    [
                        127.46185845515538,
                        306.9836414328229
                    ],
                    [
                        125.46086947661502,
                        305.0832235082095
                    ],
                    [
                        125.46086947661502,
                        303.9935951878714
                    ],
                    [
                        123.8645748982292,
                        303.82263161905456
                    ],
                    [
                        122.15586520868919,
                        300.65776450157654
                    ],
                    [
                        122.20083125315091,
                        299.480638419556
                    ],
                    [
                        119.90756298561064,
                        298.8383413325893
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Parbhani",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pune",
            "dt_code": "521",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        84.2045236831184,
                        297.0819030823372
                    ],
                    [
                        86.49779195065867,
                        297.8317506474568
                    ],
                    [
                        87.41959586212101,
                        297.51041450068635
                    ],
                    [
                        87.89173932896779,
                        299.2023295075449
                    ],
                    [
                        90.6571510633546,
                        299.9515537585971
                    ],
                    [
                        90.88198128566273,
                        301.1926433023628
                    ],
                    [
                        90.00514341866165,
                        302.60418950680895
                    ],
                    [
                        93.0853174642798,
                        306.15104416611945
                    ],
                    [
                        94.43429879812697,
                        307.965339416422
                    ],
                    [
                        94.36684973143474,
                        308.66937521357755
                    ],
                    [
                        95.94066128758982,
                        310.0982033786218
                    ],
                    [
                        95.46851782074327,
                        310.73772715774567
                    ],
                    [
                        99.33559764443885,
                        312.6979743644775
                    ],
                    [
                        99.6728429779007,
                        313.4220491678665
                    ],
                    [
                        99.83022413351637,
                        314.80576554880116
                    ],
                    [
                        101.3815526674407,
                        314.5077945673256
                    ],
                    [
                        102.46073773451872,
                        315.67820519539083
                    ],
                    [
                        103.78723604613515,
                        315.5505487372081
                    ],
                    [
                        103.51743977936576,
                        317.5498078553928
                    ],
                    [
                        102.8654321346728,
                        318.2725813464457
                    ],
                    [
                        103.78723604613515,
                        319.5475964461087
                    ],
                    [
                        102.91039817913429,
                        320.07867720860395
                    ],
                    [
                        100.14498644474725,
                        319.3351352736165
                    ],
                    [
                        97.87420119943772,
                        318.14504703718785
                    ],
                    [
                        97.06481239912932,
                        317.5498078553928
                    ],
                    [
                        94.20946857581907,
                        317.88996048652825
                    ],
                    [
                        92.635657019664,
                        317.1032928616189
                    ],
                    [
                        91.84875124158634,
                        317.5710686423891
                    ],
                    [
                        89.19575461835325,
                        316.55036288322094
                    ],
                    [
                        86.04813150604309,
                        316.14622723818906
                    ],
                    [
                        85.35115781688864,
                        318.1237907380342
                    ],
                    [
                        82.40588190465519,
                        318.14504703718785
                    ],
                    [
                        81.4615949709621,
                        316.93317257769513
                    ],
                    [
                        82.33843283796296,
                        315.93349999984366
                    ],
                    [
                        80.69717221511542,
                        315.4016085665323
                    ],
                    [
                        80.62972314842295,
                        314.84833013741405
                    ],
                    [
                        78.92101345888295,
                        313.91175345445816
                    ],
                    [
                        78.60625114765185,
                        312.399768888976
                    ],
                    [
                        78.74114928103677,
                        310.8656134106931
                    ],
                    [
                        77.48210003611257,
                        309.9702800971946
                    ],
                    [
                        78.44886999203663,
                        308.47738412106406
                    ],
                    [
                        77.95424350295912,
                        307.43185345199026
                    ],
                    [
                        78.15659070303627,
                        306.2791533009168
                    ],
                    [
                        79.05591159226788,
                        305.0618626336847
                    ],
                    [
                        79.50557203688368,
                        303.6516567798775
                    ],
                    [
                        80.5622740817305,
                        302.62557063104896
                    ],
                    [
                        80.47234199280751,
                        301.0856764485497
                    ],
                    [
                        80.58475710396146,
                        300.3367837295326
                    ],
                    [
                        82.0011875045011,
                        299.9301503951884
                    ],
                    [
                        84.2045236831184,
                        297.0819030823372
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Pune",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Beed",
            "dt_code": "523",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        111.04925222667976,
                        297.10333037095444
                    ],
                    [
                        113.18513933860459,
                        297.5318381741705
                    ],
                    [
                        115.54585667283732,
                        296.9961921185327
                    ],
                    [
                        116.01800013968409,
                        298.5599624047422
                    ],
                    [
                        118.37871747391682,
                        298.30297026269494
                    ],
                    [
                        119.90756298561064,
                        298.8383413325893
                    ],
                    [
                        122.20083125315091,
                        299.480638419556
                    ],
                    [
                        122.15586520868919,
                        300.65776450157654
                    ],
                    [
                        123.8645748982292,
                        303.82263161905456
                    ],
                    [
                        125.46086947661502,
                        303.9935951878714
                    ],
                    [
                        125.46086947661502,
                        305.0832235082095
                    ],
                    [
                        127.46185845515538,
                        306.9836414328229
                    ],
                    [
                        127.64172263300179,
                        307.77329694908633
                    ],
                    [
                        125.5732845877692,
                        308.17870348593624
                    ],
                    [
                        124.13437116499858,
                        309.1173000597603
                    ],
                    [
                        120.87433294153448,
                        309.948958949274
                    ],
                    [
                        118.3337514294551,
                        309.50117514346925
                    ],
                    [
                        117.68174378476238,
                        309.7570608870349
                    ],
                    [
                        115.95055107299163,
                        308.66937521357755
                    ],
                    [
                        110.73448991544865,
                        307.81597426562416
                    ],
                    [
                        107.7442479587537,
                        306.68479083415525
                    ],
                    [
                        106.97982520290702,
                        306.1296920331829
                    ],
                    [
                        106.21540244706034,
                        307.303800690407
                    ],
                    [
                        105.20366644667479,
                        307.1543979015685
                    ],
                    [
                        104.4392436908281,
                        308.66937521357755
                    ],
                    [
                        102.73053400128788,
                        306.770180199013
                    ],
                    [
                        99.56042786674675,
                        304.1431790714887
                    ],
                    [
                        100.88692617836318,
                        302.5186632400256
                    ],
                    [
                        101.2916205785175,
                        301.02149419977854
                    ],
                    [
                        103.18019444590368,
                        302.2406833063334
                    ],
                    [
                        104.57414182421257,
                        302.5186632400256
                    ],
                    [
                        104.88890413544368,
                        301.6418555236946
                    ],
                    [
                        106.32781755821406,
                        302.5614267274125
                    ],
                    [
                        106.68754591390689,
                        301.57768714915767
                    ],
                    [
                        108.75598395913926,
                        301.8129633734967
                    ],
                    [
                        108.08149329221555,
                        300.01576277571775
                    ],
                    [
                        109.79020298175556,
                        298.7741027376476
                    ],
                    [
                        109.94758413737122,
                        297.2533163268572
                    ],
                    [
                        109.13819533706283,
                        296.46043295051305
                    ],
                    [
                        111.04925222667976,
                        297.10333037095444
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Beed",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mumbai",
            "dt_code": "519",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        70.33249896672191,
                        305.2113850766203
                    ],
                    [
                        69.83787247764462,
                        304.5705141286223
                    ],
                    [
                        70.3774650111834,
                        302.3476022081399
                    ],
                    [
                        69.59055923310598,
                        301.0856764485497
                    ],
                    [
                        69.568076210875,
                        298.9882251001346
                    ],
                    [
                        71.34423496710747,
                        299.2451482357674
                    ],
                    [
                        72.445903056416,
                        300.7005589046672
                    ],
                    [
                        72.0861747007234,
                        303.2882977899585
                    ],
                    [
                        71.1868538114918,
                        303.395173372154
                    ],
                    [
                        71.43416705603067,
                        303.9081148117712
                    ],
                    [
                        70.33249896672191,
                        305.2113850766203
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Mumbai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Koraput",
            "dt_code": "398",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        217.64126062284777,
                        299.908746852903
                    ],
                    [
                        217.86609084515544,
                        300.05856789303004
                    ],
                    [
                        219.84459680146483,
                        301.214036139142
                    ],
                    [
                        220.09191004600348,
                        302.1551449949085
                    ],
                    [
                        221.755653691082,
                        300.9787051434748
                    ],
                    [
                        223.21705013608312,
                        302.6897129421193
                    ],
                    [
                        223.86905778077607,
                        301.1926433023628
                    ],
                    [
                        224.8807937811614,
                        302.81799278820716
                    ],
                    [
                        224.43113333654583,
                        303.5020445442596
                    ],
                    [
                        225.82508071485472,
                        305.0618626336847
                    ],
                    [
                        222.76738969146732,
                        306.94095057738656
                    ],
                    [
                        221.755653691082,
                        306.42860602527935
                    ],
                    [
                        219.75466471254163,
                        309.18128311579073
                    ],
                    [
                        220.4066723572348,
                        310.1621627018085
                    ],
                    [
                        220.04694400154176,
                        311.52626098958984
                    ],
                    [
                        220.90129884631187,
                        312.3571653871958
                    ],
                    [
                        219.30500426792582,
                        313.01744329564167
                    ],
                    [
                        218.20333617861706,
                        312.6979743644775
                    ],
                    [
                        216.92180391146235,
                        311.61149388371393
                    ],
                    [
                        216.42717742238506,
                        313.31557986852647
                    ],
                    [
                        213.68424871022876,
                        314.31622435089565
                    ],
                    [
                        213.97652799922912,
                        312.9322553139194
                    ],
                    [
                        212.20036924299689,
                        310.4179845538481
                    ],
                    [
                        211.346014398227,
                        311.07874347819785
                    ],
                    [
                        211.1661502203806,
                        309.88499447489954
                    ],
                    [
                        210.40172746453345,
                        308.946670992684
                    ],
                    [
                        209.65978773091774,
                        309.6930917727324
                    ],
                    [
                        207.5688666634544,
                        308.7120380011287
                    ],
                    [
                        206.39974950745318,
                        307.17574167819885
                    ],
                    [
                        207.389002485608,
                        306.38590611835997
                    ],
                    [
                        207.6587987523776,
                        304.6132437698453
                    ],
                    [
                        208.67053475276293,
                        304.3568553860261
                    ],
                    [
                        208.08597617476266,
                        301.214036139142
                    ],
                    [
                        210.24434630891824,
                        300.2297812121192
                    ],
                    [
                        210.96380302030343,
                        300.5293770112668
                    ],
                    [
                        212.6725127098432,
                        299.9515537585971
                    ],
                    [
                        214.1114261326136,
                        300.6363670325046
                    ],
                    [
                        215.370475377538,
                        300.122774228152
                    ],
                    [
                        216.87683786700063,
                        300.74335259445513
                    ],
                    [
                        217.64126062284777,
                        299.908746852903
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Koraput",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Srikakulam",
            "dt_code": "542",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        230.88376071678204,
                        304.48505273924457
                    ],
                    [
                        230.7488625833971,
                        303.2027941476589
                    ],
                    [
                        231.42335325032082,
                        302.81799278820716
                    ],
                    [
                        232.86226667309143,
                        305.9588686878389
                    ],
                    [
                        234.99815378501626,
                        305.93751498421256
                    ],
                    [
                        235.7850595630939,
                        306.85556677976746
                    ],
                    [
                        237.7635655194033,
                        306.21509951831786
                    ],
                    [
                        239.15751289771197,
                        306.38590611835997
                    ],
                    [
                        240.8662225872522,
                        304.2713869696947
                    ],
                    [
                        240.68635840940578,
                        303.88674427768785
                    ],
                    [
                        242.03533974325296,
                        302.3262187821396
                    ],
                    [
                        243.15949085479224,
                        302.7324735982772
                    ],
                    [
                        243.631634321639,
                        301.7701874765389
                    ],
                    [
                        244.77826845540926,
                        301.2568212788392
                    ],
                    [
                        245.7000723668716,
                        301.94128680410773
                    ],
                    [
                        243.33935503263865,
                        304.80551846801353
                    ],
                    [
                        242.57493227679197,
                        306.3645559034897
                    ],
                    [
                        241.72057743202208,
                        307.1330539512041
                    ],
                    [
                        239.67462240902023,
                        310.0129218778187
                    ],
                    [
                        236.6394144078638,
                        312.82576651933846
                    ],
                    [
                        236.34713511886343,
                        313.76272249013573
                    ],
                    [
                        233.51427431778416,
                        315.3803307277066
                    ],
                    [
                        229.5122963607041,
                        317.37971556690445
                    ],
                    [
                        228.63545849370303,
                        316.16749904104955
                    ],
                    [
                        229.66967751631933,
                        314.2310776472677
                    ],
                    [
                        228.36566222693364,
                        313.1239244569379
                    ],
                    [
                        228.2982131602414,
                        310.8656134106931
                    ],
                    [
                        229.06263591608808,
                        309.991601073371
                    ],
                    [
                        227.82606969339463,
                        309.3732229812664
                    ],
                    [
                        227.66868853777942,
                        307.60258074988104
                    ],
                    [
                        229.89450773862723,
                        306.1083397257858
                    ],
                    [
                        231.71563253932118,
                        305.6385447938814
                    ],
                    [
                        230.88376071678204,
                        304.48505273924457
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-Srikakulam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Vizianagaram",
            "dt_code": "543",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        224.8807937811614,
                        302.81799278820716
                    ],
                    [
                        226.52205440400894,
                        303.7798889661604
                    ],
                    [
                        226.83681671523982,
                        302.5614267274125
                    ],
                    [
                        228.7029075603955,
                        301.5990767850153
                    ],
                    [
                        229.5122963607041,
                        301.5562973356006
                    ],
                    [
                        230.88376071678204,
                        304.48505273924457
                    ],
                    [
                        231.71563253932118,
                        305.6385447938814
                    ],
                    [
                        229.89450773862723,
                        306.1083397257858
                    ],
                    [
                        227.66868853777942,
                        307.60258074988104
                    ],
                    [
                        227.82606969339463,
                        309.3732229812664
                    ],
                    [
                        229.06263591608808,
                        309.991601073371
                    ],
                    [
                        228.2982131602414,
                        310.8656134106931
                    ],
                    [
                        228.36566222693364,
                        313.1239244569379
                    ],
                    [
                        229.66967751631933,
                        314.2310776472677
                    ],
                    [
                        228.63545849370303,
                        316.16749904104955
                    ],
                    [
                        229.5122963607041,
                        317.37971556690445
                    ],
                    [
                        227.3988922710098,
                        319.37762882907356
                    ],
                    [
                        225.73514862593152,
                        319.1651544434419
                    ],
                    [
                        225.6901825814698,
                        317.719889492734
                    ],
                    [
                        223.77912569185287,
                        318.48512525654473
                    ],
                    [
                        222.78987271369806,
                        320.9706613288411
                    ],
                    [
                        221.91303484669743,
                        320.99189551476985
                    ],
                    [
                        221.3959253353894,
                        319.5475964461087
                    ],
                    [
                        219.8670798236958,
                        318.95266352806374
                    ],
                    [
                        219.8670798236958,
                        318.060020844373
                    ],
                    [
                        222.0029669356204,
                        315.65692953844547
                    ],
                    [
                        223.68919360292966,
                        315.2526601674658
                    ],
                    [
                        220.90129884631187,
                        312.3571653871958
                    ],
                    [
                        220.04694400154176,
                        311.52626098958984
                    ],
                    [
                        220.4066723572348,
                        310.1621627018085
                    ],
                    [
                        219.75466471254163,
                        309.18128311579073
                    ],
                    [
                        221.755653691082,
                        306.42860602527935
                    ],
                    [
                        222.76738969146732,
                        306.94095057738656
                    ],
                    [
                        225.82508071485472,
                        305.0618626336847
                    ],
                    [
                        224.43113333654583,
                        303.5020445442596
                    ],
                    [
                        224.8807937811614,
                        302.81799278820716
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-Vizianagaram",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Latur",
            "dt_code": "524",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        131.3963873455432,
                        305.7666790684422
                    ],
                    [
                        132.3406742792365,
                        306.68479083415525
                    ],
                    [
                        132.22825916808233,
                        308.02935045477244
                    ],
                    [
                        133.7795877020069,
                        307.7519580308808
                    ],
                    [
                        133.80207072423764,
                        308.5840475664051
                    ],
                    [
                        135.48829739154667,
                        309.1173000597603
                    ],
                    [
                        135.9154748139315,
                        310.14084309904155
                    ],
                    [
                        135.4433313470854,
                        312.05892182099956
                    ],
                    [
                        135.1735350803158,
                        312.86836254626604
                    ],
                    [
                        132.49805543485195,
                        316.27385554479633
                    ],
                    [
                        130.83431178977366,
                        315.6994806846033
                    ],
                    [
                        130.38465134515786,
                        316.78430856963826
                    ],
                    [
                        130.8567948120044,
                        317.9749919944694
                    ],
                    [
                        130.15982112284973,
                        318.4638716117512
                    ],
                    [
                        130.36216832292712,
                        319.9087425726369
                    ],
                    [
                        128.2487642332328,
                        320.50346770989375
                    ],
                    [
                        127.73165472192477,
                        320.1636405742418
                    ],
                    [
                        126.76488476600093,
                        319.86625726614454
                    ],
                    [
                        126.83233383269317,
                        318.6764006017493
                    ],
                    [
                        125.64073365446143,
                        318.1237907380342
                    ],
                    [
                        123.10015214238229,
                        318.0812776416416
                    ],
                    [
                        122.42566147545858,
                        317.25213935615
                    ],
                    [
                        120.94178200822671,
                        316.91190679151333
                    ],
                    [
                        121.50385756399623,
                        313.5072215595887
                    ],
                    [
                        120.78440085261127,
                        312.0802260391547
                    ],
                    [
                        120.87433294153448,
                        309.948958949274
                    ],
                    [
                        124.13437116499858,
                        309.1173000597603
                    ],
                    [
                        125.5732845877692,
                        308.17870348593624
                    ],
                    [
                        127.64172263300179,
                        307.77329694908633
                    ],
                    [
                        127.46185845515538,
                        306.9836414328229
                    ],
                    [
                        129.6427116115417,
                        305.72396834262247
                    ],
                    [
                        131.3963873455432,
                        305.7666790684422
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Latur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Osmanabad",
            "dt_code": "525",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        106.73251195836838,
                        310.5458862211261
                    ],
                    [
                        108.23887444783122,
                        310.37534929349073
                    ],
                    [
                        108.93584813698567,
                        309.2239376249488
                    ],
                    [
                        110.73448991544865,
                        307.81597426562416
                    ],
                    [
                        115.95055107299163,
                        308.66937521357755
                    ],
                    [
                        117.68174378476238,
                        309.7570608870349
                    ],
                    [
                        118.3337514294551,
                        309.50117514346925
                    ],
                    [
                        120.87433294153448,
                        309.948958949274
                    ],
                    [
                        120.78440085261127,
                        312.0802260391547
                    ],
                    [
                        121.50385756399623,
                        313.5072215595887
                    ],
                    [
                        120.94178200822671,
                        316.91190679151333
                    ],
                    [
                        122.42566147545858,
                        317.25213935615
                    ],
                    [
                        123.10015214238229,
                        318.0812776416416
                    ],
                    [
                        125.64073365446143,
                        318.1237907380342
                    ],
                    [
                        126.83233383269317,
                        318.6764006017493
                    ],
                    [
                        126.76488476600093,
                        319.86625726614454
                    ],
                    [
                        127.73165472192477,
                        320.1636405742418
                    ],
                    [
                        128.47359445554093,
                        321.28915690173204
                    ],
                    [
                        127.3494433440012,
                        321.9897174869891
                    ],
                    [
                        126.92226592161637,
                        323.5175966334206
                    ],
                    [
                        126.49508849923131,
                        322.9659598241531
                    ],
                    [
                        124.53906556515267,
                        322.3080952848776
                    ],
                    [
                        124.0444390760756,
                        323.8570108987007
                    ],
                    [
                        123.145118186844,
                        324.1115443718832
                    ],
                    [
                        120.80688387484201,
                        322.8174234456531
                    ],
                    [
                        119.2780383631482,
                        323.2417919570605
                    ],
                    [
                        118.73844582960942,
                        322.201973432508
                    ],
                    [
                        116.89483800668472,
                        322.4566590278947
                    ],
                    [
                        117.11966822899262,
                        321.2679250108277
                    ],
                    [
                        115.00626413929854,
                        320.99189551476985
                    ],
                    [
                        114.4891546279905,
                        318.9101633601154
                    ],
                    [
                        115.50089062837583,
                        318.54888519635637
                    ],
                    [
                        116.55759267322287,
                        319.03766187873197
                    ],
                    [
                        117.11966822899262,
                        317.9537343667123
                    ],
                    [
                        115.92806805076088,
                        316.6567043372407
                    ],
                    [
                        116.06296618414558,
                        315.0398624563554
                    ],
                    [
                        115.32102645052964,
                        313.0600362688513
                    ],
                    [
                        112.51064867168088,
                        312.4849738522627
                    ],
                    [
                        110.50965969314075,
                        314.95473866371367
                    ],
                    [
                        109.92510111514025,
                        316.52909409110913
                    ],
                    [
                        108.57611978129307,
                        315.4441637404192
                    ],
                    [
                        107.85666306990765,
                        313.54980673986216
                    ],
                    [
                        106.59761382498368,
                        311.8671761935425
                    ],
                    [
                        106.73251195836838,
                        310.5458862211261
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Osmanabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Malkangiri",
            "dt_code": "399",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        206.39974950745318,
                        307.17574167819885
                    ],
                    [
                        207.5688666634544,
                        308.7120380011287
                    ],
                    [
                        209.65978773091774,
                        309.6930917727324
                    ],
                    [
                        210.40172746453345,
                        308.946670992684
                    ],
                    [
                        211.1661502203806,
                        309.88499447489954
                    ],
                    [
                        211.346014398227,
                        311.07874347819785
                    ],
                    [
                        210.37924444230293,
                        312.0802260391547
                    ],
                    [
                        210.69400675353404,
                        312.91095789438015
                    ],
                    [
                        209.6822707531485,
                        315.74203115988996
                    ],
                    [
                        210.2218632866875,
                        315.93349999984366
                    ],
                    [
                        209.74971981984095,
                        317.91121861275644
                    ],
                    [
                        208.7379838194554,
                        318.7189044116174
                    ],
                    [
                        207.52390061899268,
                        317.7836673622894
                    ],
                    [
                        205.52291164045255,
                        317.52854690197216
                    ],
                    [
                        204.75848888460587,
                        318.40010968259304
                    ],
                    [
                        202.2628734169882,
                        319.2713937015509
                    ],
                    [
                        200.8914090609103,
                        320.5247055081348
                    ],
                    [
                        199.43001261590916,
                        321.3316201920964
                    ],
                    [
                        196.19245741467557,
                        321.41654480746115
                    ],
                    [
                        196.2374234591373,
                        320.2486013062439
                    ],
                    [
                        197.45150665959954,
                        318.95266352806374
                    ],
                    [
                        197.4290236373688,
                        317.80492631959953
                    ],
                    [
                        198.08103128206176,
                        316.2313134453493
                    ],
                    [
                        198.148480348754,
                        314.89089405277133
                    ],
                    [
                        200.0370542161404,
                        313.23040138013846
                    ],
                    [
                        201.38603554998758,
                        313.1239244569379
                    ],
                    [
                        201.67831483898772,
                        312.1228339647078
                    ],
                    [
                        203.7467528842203,
                        309.7783835813269
                    ],
                    [
                        203.58937172860487,
                        308.3707163557692
                    ],
                    [
                        206.39974950745318,
                        307.17574167819885
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Malkangiri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Solapur",
            "dt_code": "526",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        99.6728429779007,
                        313.4220491678665
                    ],
                    [
                        100.88692617836318,
                        312.74057242856406
                    ],
                    [
                        103.47247373490404,
                        311.93109293582165
                    ],
                    [
                        103.69730395721194,
                        311.01480625373557
                    ],
                    [
                        105.69829293575208,
                        310.24743939694156
                    ],
                    [
                        106.73251195836838,
                        310.5458862211261
                    ],
                    [
                        106.59761382498368,
                        311.8671761935425
                    ],
                    [
                        107.85666306990765,
                        313.54980673986216
                    ],
                    [
                        108.57611978129307,
                        315.4441637404192
                    ],
                    [
                        109.92510111514025,
                        316.52909409110913
                    ],
                    [
                        110.50965969314075,
                        314.95473866371367
                    ],
                    [
                        112.51064867168088,
                        312.4849738522627
                    ],
                    [
                        115.32102645052964,
                        313.0600362688513
                    ],
                    [
                        116.06296618414558,
                        315.0398624563554
                    ],
                    [
                        115.92806805076088,
                        316.6567043372407
                    ],
                    [
                        117.11966822899262,
                        317.9537343667123
                    ],
                    [
                        116.55759267322287,
                        319.03766187873197
                    ],
                    [
                        115.50089062837583,
                        318.54888519635637
                    ],
                    [
                        114.4891546279905,
                        318.9101633601154
                    ],
                    [
                        115.00626413929854,
                        320.99189551476985
                    ],
                    [
                        117.11966822899262,
                        321.2679250108277
                    ],
                    [
                        116.89483800668472,
                        322.4566590278947
                    ],
                    [
                        118.73844582960942,
                        322.201973432508
                    ],
                    [
                        119.2780383631482,
                        323.2417919570605
                    ],
                    [
                        120.80688387484201,
                        322.8174234456531
                    ],
                    [
                        123.145118186844,
                        324.1115443718832
                    ],
                    [
                        121.72868778630436,
                        324.8325960536707
                    ],
                    [
                        121.70620476407362,
                        326.78274345349035
                    ],
                    [
                        122.31324636430463,
                        327.3124403099387
                    ],
                    [
                        122.47062751992007,
                        329.17618098117003
                    ],
                    [
                        120.22232529684129,
                        328.392712737739
                    ],
                    [
                        117.95154005153177,
                        328.8374105520343
                    ],
                    [
                        115.83813596183768,
                        329.0491468244974
                    ],
                    [
                        115.2985434282989,
                        327.84203738422815
                    ],
                    [
                        114.10694325006693,
                        328.2868219818396
                    ],
                    [
                        111.45394662683384,
                        326.67679208825695
                    ],
                    [
                        110.57710875983321,
                        328.1173885046705
                    ],
                    [
                        111.02676920444901,
                        329.3243802917495
                    ],
                    [
                        109.74523693729384,
                        330.3192322116866
                    ],
                    [
                        108.32880653675443,
                        329.93826630476786
                    ],
                    [
                        105.65332689129059,
                        330.7001470617296
                    ],
                    [
                        103.3150925792886,
                        330.2345775365666
                    ],
                    [
                        100.97685826728639,
                        331.4618239300008
                    ],
                    [
                        100.45974875597835,
                        330.61550372525875
                    ],
                    [
                        101.53893382305637,
                        329.5149108680092
                    ],
                    [
                        102.66308493459587,
                        327.5666593443644
                    ],
                    [
                        101.22417151182526,
                        326.1681697916757
                    ],
                    [
                        101.92114520097948,
                        325.63825662644234
                    ],
                    [
                        100.77451106720946,
                        324.36605453687986
                    ],
                    [
                        100.21243551143971,
                        322.838643416455
                    ],
                    [
                        97.55943888820684,
                        321.628844886756
                    ],
                    [
                        96.7950161323597,
                        320.5671806115781
                    ],
                    [
                        97.87420119943772,
                        318.14504703718785
                    ],
                    [
                        100.14498644474725,
                        319.3351352736165
                    ],
                    [
                        102.91039817913429,
                        320.07867720860395
                    ],
                    [
                        103.78723604613515,
                        319.5475964461087
                    ],
                    [
                        102.8654321346728,
                        318.2725813464457
                    ],
                    [
                        103.51743977936576,
                        317.5498078553928
                    ],
                    [
                        103.78723604613515,
                        315.5505487372081
                    ],
                    [
                        102.46073773451872,
                        315.67820519539083
                    ],
                    [
                        101.3815526674407,
                        314.5077945673256
                    ],
                    [
                        99.83022413351637,
                        314.80576554880116
                    ],
                    [
                        99.6728429779007,
                        313.4220491678665
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Solapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Visakhapatnam",
            "dt_code": "544",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        211.346014398227,
                        311.07874347819785
                    ],
                    [
                        212.20036924299689,
                        310.4179845538481
                    ],
                    [
                        213.97652799922912,
                        312.9322553139194
                    ],
                    [
                        213.68424871022876,
                        314.31622435089565
                    ],
                    [
                        216.42717742238506,
                        313.31557986852647
                    ],
                    [
                        216.92180391146235,
                        311.61149388371393
                    ],
                    [
                        218.20333617861706,
                        312.6979743644775
                    ],
                    [
                        219.30500426792582,
                        313.01744329564167
                    ],
                    [
                        220.90129884631187,
                        312.3571653871958
                    ],
                    [
                        223.68919360292966,
                        315.2526601674658
                    ],
                    [
                        222.0029669356204,
                        315.65692953844547
                    ],
                    [
                        219.8670798236958,
                        318.060020844373
                    ],
                    [
                        219.8670798236958,
                        318.95266352806374
                    ],
                    [
                        221.3959253353894,
                        319.5475964461087
                    ],
                    [
                        221.91303484669743,
                        320.99189551476985
                    ],
                    [
                        222.78987271369806,
                        320.9706613288411
                    ],
                    [
                        223.77912569185287,
                        318.48512525654473
                    ],
                    [
                        225.6901825814698,
                        317.719889492734
                    ],
                    [
                        225.73514862593152,
                        319.1651544434419
                    ],
                    [
                        227.3988922710098,
                        319.37762882907356
                    ],
                    [
                        225.93749582600844,
                        320.82201743336566
                    ],
                    [
                        224.85831075893066,
                        322.838643416455
                    ],
                    [
                        222.13786506900533,
                        325.4050630075431
                    ],
                    [
                        220.06942702377273,
                        326.2105585099651
                    ],
                    [
                        219.64224960138768,
                        326.7403633875493
                    ],
                    [
                        215.39295839976876,
                        328.7315364691631
                    ],
                    [
                        213.03224106553603,
                        330.19224925321834
                    ],
                    [
                        212.6725127098432,
                        329.4302321938791
                    ],
                    [
                        213.93156195476763,
                        327.0158223781092
                    ],
                    [
                        211.90808995399652,
                        326.0621951879836
                    ],
                    [
                        211.09870115368813,
                        326.8251228793646
                    ],
                    [
                        210.1319311977643,
                        326.4012998035872
                    ],
                    [
                        209.52488959753282,
                        324.9386173189293
                    ],
                    [
                        208.4232215082243,
                        324.1751740972838
                    ],
                    [
                        205.09573421806772,
                        324.1751740972838
                    ],
                    [
                        205.29808141814487,
                        322.4142130594419
                    ],
                    [
                        204.48869261783648,
                        321.41654480746115
                    ],
                    [
                        205.5004286182218,
                        319.5475964461087
                    ],
                    [
                        204.75848888460587,
                        318.40010968259304
                    ],
                    [
                        205.52291164045255,
                        317.52854690197216
                    ],
                    [
                        207.52390061899268,
                        317.7836673622894
                    ],
                    [
                        208.7379838194554,
                        318.7189044116174
                    ],
                    [
                        209.74971981984095,
                        317.91121861275644
                    ],
                    [
                        210.2218632866875,
                        315.93349999984366
                    ],
                    [
                        209.6822707531485,
                        315.74203115988996
                    ],
                    [
                        210.69400675353404,
                        312.91095789438015
                    ],
                    [
                        210.37924444230293,
                        312.0802260391547
                    ],
                    [
                        211.346014398227,
                        311.07874347819785
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-Visakhapatnam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bidar",
            "dt_code": "558",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        135.4433313470854,
                        312.05892182099956
                    ],
                    [
                        136.20775410293209,
                        311.7606448813965
                    ],
                    [
                        137.62418450347172,
                        312.52757531400954
                    ],
                    [
                        137.55673543677926,
                        313.9330429162161
                    ],
                    [
                        138.3886072593184,
                        314.5503638720703
                    ],
                    [
                        139.6476565042426,
                        314.0820644185967
                    ],
                    [
                        140.38959623785877,
                        314.3375106050478
                    ],
                    [
                        139.8275206820888,
                        315.57182523303993
                    ],
                    [
                        140.34463019339705,
                        317.16708521653703
                    ],
                    [
                        139.6026904597809,
                        317.7411489488714
                    ],
                    [
                        141.19898503816717,
                        319.0801600616893
                    ],
                    [
                        140.16476601555087,
                        320.03619453787593
                    ],
                    [
                        139.44530930416568,
                        322.0109438162972
                    ],
                    [
                        138.20874308147222,
                        323.32665785726863
                    ],
                    [
                        138.0738449480873,
                        325.0234314278245
                    ],
                    [
                        136.11782201400888,
                        324.8538006293907
                    ],
                    [
                        135.57822948046987,
                        324.36605453687986
                    ],
                    [
                        132.81281774608283,
                        323.8782229123282
                    ],
                    [
                        130.06988903392653,
                        324.0903341397111
                    ],
                    [
                        126.92226592161637,
                        323.5175966334206
                    ],
                    [
                        127.3494433440012,
                        321.9897174869891
                    ],
                    [
                        128.47359445554093,
                        321.28915690173204
                    ],
                    [
                        127.73165472192477,
                        320.1636405742418
                    ],
                    [
                        128.2487642332328,
                        320.50346770989375
                    ],
                    [
                        130.36216832292712,
                        319.9087425726369
                    ],
                    [
                        130.15982112284973,
                        318.4638716117512
                    ],
                    [
                        130.8567948120044,
                        317.9749919944694
                    ],
                    [
                        130.38465134515786,
                        316.78430856963826
                    ],
                    [
                        130.83431178977366,
                        315.6994806846033
                    ],
                    [
                        132.49805543485195,
                        316.27385554479633
                    ],
                    [
                        135.1735350803158,
                        312.86836254626604
                    ],
                    [
                        135.4433313470854,
                        312.05892182099956
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Bidar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Satara",
            "dt_code": "527",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        82.40588190465519,
                        318.14504703718785
                    ],
                    [
                        85.35115781688864,
                        318.1237907380342
                    ],
                    [
                        86.04813150604309,
                        316.14622723818906
                    ],
                    [
                        89.19575461835325,
                        316.55036288322094
                    ],
                    [
                        91.84875124158634,
                        317.5710686423891
                    ],
                    [
                        92.635657019664,
                        317.1032928616189
                    ],
                    [
                        94.20946857581907,
                        317.88996048652825
                    ],
                    [
                        97.06481239912932,
                        317.5498078553928
                    ],
                    [
                        97.87420119943772,
                        318.14504703718785
                    ],
                    [
                        96.7950161323597,
                        320.5671806115781
                    ],
                    [
                        97.55943888820684,
                        321.628844886756
                    ],
                    [
                        100.21243551143971,
                        322.838643416455
                    ],
                    [
                        100.77451106720946,
                        324.36605453687986
                    ],
                    [
                        100.07753737805479,
                        326.14697519187723
                    ],
                    [
                        99.29063159997736,
                        327.03701041182853
                    ],
                    [
                        97.64937097713005,
                        326.63441042163754
                    ],
                    [
                        97.51447284374512,
                        327.947944845301
                    ],
                    [
                        95.19872155397388,
                        327.5666593443644
                    ],
                    [
                        94.74906110935831,
                        328.1173885046705
                    ],
                    [
                        91.62392101927844,
                        327.54547530229615
                    ],
                    [
                        91.19674359689361,
                        329.5149108680092
                    ],
                    [
                        91.62392101927844,
                        330.8694261841697
                    ],
                    [
                        91.06184546350892,
                        331.8425861175034
                    ],
                    [
                        87.89173932896779,
                        332.62510476328214
                    ],
                    [
                        86.56524101735113,
                        331.9694955717574
                    ],
                    [
                        84.99142946119605,
                        330.5308578707153
                    ],
                    [
                        82.9454744381942,
                        330.00176417180086
                    ],
                    [
                        82.54078003804011,
                        327.990306715133
                    ],
                    [
                        82.9454744381942,
                        326.52845345275097
                    ],
                    [
                        83.68741417181013,
                        325.4686631980362
                    ],
                    [
                        82.83305932704025,
                        323.45395183569565
                    ],
                    [
                        81.10186661526973,
                        322.85986322451265
                    ],
                    [
                        81.59649310434679,
                        321.77744520815463
                    ],
                    [
                        81.14683265973122,
                        320.5247055081348
                    ],
                    [
                        82.40588190465519,
                        318.14504703718785
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Satara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ratnagiri",
            "dt_code": "528",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        81.14683265973122,
                        320.5247055081348
                    ],
                    [
                        81.59649310434679,
                        321.77744520815463
                    ],
                    [
                        81.10186661526973,
                        322.85986322451265
                    ],
                    [
                        82.83305932704025,
                        323.45395183569565
                    ],
                    [
                        83.68741417181013,
                        325.4686631980362
                    ],
                    [
                        82.9454744381942,
                        326.52845345275097
                    ],
                    [
                        82.54078003804011,
                        327.990306715133
                    ],
                    [
                        82.9454744381942,
                        330.00176417180086
                    ],
                    [
                        83.12533861604061,
                        331.39835862853187
                    ],
                    [
                        84.58673506104174,
                        332.73083412978826
                    ],
                    [
                        84.96894643896508,
                        333.63994595140616
                    ],
                    [
                        84.4293539054263,
                        335.11928169985777
                    ],
                    [
                        83.17030466050232,
                        336.64009546378065
                    ],
                    [
                        84.67666714996494,
                        337.56909018876064
                    ],
                    [
                        85.26122572796544,
                        339.42619439549026
                    ],
                    [
                        84.02465950527221,
                        340.1223057453328
                    ],
                    [
                        81.86628937111618,
                        340.43866564243183
                    ],
                    [
                        81.5515270598853,
                        341.02911344507356
                    ],
                    [
                        79.6179871480374,
                        341.7880875415033
                    ],
                    [
                        77.84182839180517,
                        341.239959090874
                    ],
                    [
                        77.90927745849763,
                        339.93247351358445
                    ],
                    [
                        77.23478679157415,
                        338.16011472352744
                    ],
                    [
                        77.41465096942034,
                        337.0201745538279
                    ],
                    [
                        76.80760936918887,
                        335.68968030816086
                    ],
                    [
                        77.09988865818923,
                        334.25290566578303
                    ],
                    [
                        76.98747354703528,
                        332.24444673844187
                    ],
                    [
                        76.02070359111121,
                        329.93826630476786
                    ],
                    [
                        76.33546590234232,
                        329.07031957999726
                    ],
                    [
                        75.43614501311072,
                        328.09620860424155
                    ],
                    [
                        75.34621292418797,
                        325.0446345519327
                    ],
                    [
                        73.39018999010932,
                        318.888913027936
                    ],
                    [
                        74.42440901272562,
                        318.9101633601154
                    ],
                    [
                        75.2787638574955,
                        317.6986298702913
                    ],
                    [
                        76.9649905248043,
                        317.50728578211266
                    ],
                    [
                        78.22403976972873,
                        319.760041115494
                    ],
                    [
                        80.18006270380738,
                        320.90695778675
                    ],
                    [
                        81.14683265973122,
                        320.5247055081348
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Ratnagiri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kalaburagi",
            "dt_code": "579",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        123.145118186844,
                        324.1115443718832
                    ],
                    [
                        124.0444390760756,
                        323.8570108987007
                    ],
                    [
                        124.53906556515267,
                        322.3080952848776
                    ],
                    [
                        126.49508849923131,
                        322.9659598241531
                    ],
                    [
                        126.92226592161637,
                        323.5175966334206
                    ],
                    [
                        130.06988903392653,
                        324.0903341397111
                    ],
                    [
                        132.81281774608283,
                        323.8782229123282
                    ],
                    [
                        135.57822948046987,
                        324.36605453687986
                    ],
                    [
                        136.11782201400888,
                        324.8538006293907
                    ],
                    [
                        138.0738449480873,
                        325.0234314278245
                    ],
                    [
                        138.41109028154938,
                        325.12944543634273
                    ],
                    [
                        138.81578468170324,
                        325.2990594705033
                    ],
                    [
                        141.626162460552,
                        326.14697519187723
                    ],
                    [
                        139.08558094847285,
                        327.3971825412574
                    ],
                    [
                        138.6584035260878,
                        328.5621296847546
                    ],
                    [
                        137.1295580143942,
                        330.5943424977285
                    ],
                    [
                        136.9047277920863,
                        331.58875029975655
                    ],
                    [
                        138.36612423708766,
                        332.32904178301135
                    ],
                    [
                        138.86075072616495,
                        333.36512852903206
                    ],
                    [
                        138.41109028154938,
                        334.71782267543756
                    ],
                    [
                        138.2986751703952,
                        335.79529735895176
                    ],
                    [
                        136.56748245862468,
                        335.03476864274455
                    ],
                    [
                        133.98193490208382,
                        334.95025311595
                    ],
                    [
                        131.80108174569727,
                        335.584059409662
                    ],
                    [
                        131.28397223438924,
                        336.9568314836253
                    ],
                    [
                        130.2272701895422,
                        336.8301412106184
                    ],
                    [
                        129.6427116115417,
                        335.6263082309016
                    ],
                    [
                        127.64172263300179,
                        337.4213153704136
                    ],
                    [
                        125.61825063223068,
                        337.63241996405463
                    ],
                    [
                        123.63974467592129,
                        338.4555822166601
                    ],
                    [
                        122.29076334207389,
                        337.56909018876064
                    ],
                    [
                        122.35821240876612,
                        336.1966070926037
                    ],
                    [
                        123.32498236469019,
                        334.0626907453732
                    ],
                    [
                        122.17834823092016,
                        332.24444673844187
                    ],
                    [
                        122.26828031984337,
                        331.4618239300008
                    ],
                    [
                        120.4021894746877,
                        331.6945179648401
                    ],
                    [
                        118.71596280737867,
                        330.25574144177176
                    ],
                    [
                        117.95154005153177,
                        328.8374105520343
                    ],
                    [
                        120.22232529684129,
                        328.392712737739
                    ],
                    [
                        122.47062751992007,
                        329.17618098117003
                    ],
                    [
                        122.31324636430463,
                        327.3124403099387
                    ],
                    [
                        121.70620476407362,
                        326.78274345349035
                    ],
                    [
                        121.72868778630436,
                        324.8325960536707
                    ],
                    [
                        123.145118186844,
                        324.1115443718832
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Kalaburagi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sangli",
            "dt_code": "531",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        100.77451106720946,
                        324.36605453687986
                    ],
                    [
                        101.92114520097948,
                        325.63825662644234
                    ],
                    [
                        101.22417151182526,
                        326.1681697916757
                    ],
                    [
                        102.66308493459587,
                        327.5666593443644
                    ],
                    [
                        101.53893382305637,
                        329.5149108680092
                    ],
                    [
                        100.45974875597835,
                        330.61550372525875
                    ],
                    [
                        100.97685826728639,
                        331.4618239300008
                    ],
                    [
                        103.3150925792886,
                        330.2345775365666
                    ],
                    [
                        105.65332689129059,
                        330.7001470617296
                    ],
                    [
                        108.32880653675443,
                        329.93826630476786
                    ],
                    [
                        109.74523693729384,
                        330.3192322116866
                    ],
                    [
                        111.02676920444901,
                        329.3243802917495
                    ],
                    [
                        111.76870893806495,
                        329.8112663085058
                    ],
                    [
                        111.34153151567989,
                        331.35604764336426
                    ],
                    [
                        112.03850520483456,
                        332.24444673844187
                    ],
                    [
                        111.92609009368039,
                        334.33744160267224
                    ],
                    [
                        109.92510111514025,
                        334.4853735317836
                    ],
                    [
                        108.98081418144716,
                        334.1895020868333
                    ],
                    [
                        107.15968938075343,
                        334.696691708251
                    ],
                    [
                        106.26036849152183,
                        334.696691708251
                    ],
                    [
                        106.0355382692137,
                        336.13324608585606
                    ],
                    [
                        104.82145506875145,
                        336.42891896263467
                    ],
                    [
                        103.4499907126733,
                        334.7600841461107
                    ],
                    [
                        100.70706200051723,
                        336.13324608585606
                    ],
                    [
                        100.95437524505564,
                        337.50575903981166
                    ],
                    [
                        99.94263924467032,
                        337.50575903981166
                    ],
                    [
                        97.67185399936079,
                        338.3922702636092
                    ],
                    [
                        96.66011799897524,
                        337.27353306835386
                    ],
                    [
                        94.41181577589623,
                        336.0487625929756
                    ],
                    [
                        93.42256279774165,
                        336.3866818220121
                    ],
                    [
                        91.73633613043262,
                        335.6051838972646
                    ],
                    [
                        88.40884884027582,
                        335.52068502291377
                    ],
                    [
                        87.77932421781361,
                        334.25290566578303
                    ],
                    [
                        85.21625968350395,
                        331.6945179648401
                    ],
                    [
                        83.12533861604061,
                        331.39835862853187
                    ],
                    [
                        82.9454744381942,
                        330.00176417180086
                    ],
                    [
                        84.99142946119605,
                        330.5308578707153
                    ],
                    [
                        86.56524101735113,
                        331.9694955717574
                    ],
                    [
                        87.89173932896779,
                        332.62510476328214
                    ],
                    [
                        91.06184546350892,
                        331.8425861175034
                    ],
                    [
                        91.62392101927844,
                        330.8694261841697
                    ],
                    [
                        91.19674359689361,
                        329.5149108680092
                    ],
                    [
                        91.62392101927844,
                        327.54547530229615
                    ],
                    [
                        94.74906110935831,
                        328.1173885046705
                    ],
                    [
                        95.19872155397388,
                        327.5666593443644
                    ],
                    [
                        97.51447284374512,
                        327.947944845301
                    ],
                    [
                        97.64937097713005,
                        326.63441042163754
                    ],
                    [
                        99.29063159997736,
                        327.03701041182853
                    ],
                    [
                        100.07753737805479,
                        326.14697519187723
                    ],
                    [
                        100.77451106720946,
                        324.36605453687986
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Sangli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kolhapur",
            "dt_code": "530",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        85.26122572796544,
                        339.42619439549026
                    ],
                    [
                        84.67666714996494,
                        337.56909018876064
                    ],
                    [
                        83.17030466050232,
                        336.64009546378065
                    ],
                    [
                        84.4293539054263,
                        335.11928169985777
                    ],
                    [
                        84.96894643896508,
                        333.63994595140616
                    ],
                    [
                        84.58673506104174,
                        332.73083412978826
                    ],
                    [
                        83.12533861604061,
                        331.39835862853187
                    ],
                    [
                        85.21625968350395,
                        331.6945179648401
                    ],
                    [
                        87.77932421781361,
                        334.25290566578303
                    ],
                    [
                        88.40884884027582,
                        335.52068502291377
                    ],
                    [
                        91.73633613043262,
                        335.6051838972646
                    ],
                    [
                        93.42256279774165,
                        336.3866818220121
                    ],
                    [
                        94.41181577589623,
                        336.0487625929756
                    ],
                    [
                        96.66011799897524,
                        337.27353306835386
                    ],
                    [
                        97.67185399936079,
                        338.3922702636092
                    ],
                    [
                        96.70508404343695,
                        340.4175760379173
                    ],
                    [
                        95.8282461764361,
                        340.86042610897977
                    ],
                    [
                        95.44603479851253,
                        339.616059271383
                    ],
                    [
                        92.07358146389424,
                        340.86042610897977
                    ],
                    [
                        92.32089470843289,
                        342.39934215564455
                    ],
                    [
                        92.09606448612521,
                        344.3587269167076
                    ],
                    [
                        92.92793630866436,
                        345.3484658903204
                    ],
                    [
                        94.50174786481944,
                        345.53795319410347
                    ],
                    [
                        94.50174786481944,
                        347.09550870021513
                    ],
                    [
                        92.90545328643361,
                        348.14745754022647
                    ],
                    [
                        94.25443462028079,
                        348.75742110718363
                    ],
                    [
                        93.13028350874151,
                        350.81777654426855
                    ],
                    [
                        92.52324190851004,
                        352.96076267340675
                    ],
                    [
                        90.94943035235497,
                        353.25478217715886
                    ],
                    [
                        90.52225292996968,
                        352.70872376518497
                    ],
                    [
                        89.03837346273804,
                        352.07853671335124
                    ],
                    [
                        88.52126395142977,
                        350.14515980249377
                    ],
                    [
                        88.83602626266088,
                        349.2410985465435
                    ],
                    [
                        87.41959586212101,
                        348.75742110718363
                    ],
                    [
                        87.32966377319781,
                        347.93709692466564
                    ],
                    [
                        86.09309755050458,
                        347.26384502799635
                    ],
                    [
                        86.56524101735113,
                        345.6221659448537
                    ],
                    [
                        85.82330128373519,
                        345.4326839427854
                    ],
                    [
                        84.96894643896508,
                        344.0849116596351
                    ],
                    [
                        85.77833523927347,
                        342.48364327180514
                    ],
                    [
                        84.94646341673456,
                        340.9447709797214
                    ],
                    [
                        85.26122572796544,
                        339.42619439549026
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Kolhapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Krishna",
            "dt_code": "547",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        187.8737391892837,
                        353.8427376910373
                    ],
                    [
                        188.8180261229768,
                        350.54454376467095
                    ],
                    [
                        188.61567892289963,
                        348.3157355574629
                    ],
                    [
                        187.40159572243715,
                        345.0326271870764
                    ],
                    [
                        185.55798789951268,
                        342.86296874719585
                    ],
                    [
                        185.42308976612776,
                        342.1253470199539
                    ],
                    [
                        182.59022896504848,
                        340.33321611277574
                    ],
                    [
                        181.08386647558564,
                        340.3543063201522
                    ],
                    [
                        177.71141314096735,
                        339.3418060712969
                    ],
                    [
                        177.53154896312094,
                        337.062402502263
                    ],
                    [
                        176.72216016281277,
                        336.8301412106184
                    ],
                    [
                        176.22753367373548,
                        335.28830040873225
                    ],
                    [
                        176.4748469182739,
                        334.46424086335946
                    ],
                    [
                        178.4083868301218,
                        333.7033616280257
                    ],
                    [
                        180.1620625641233,
                        334.4219750622625
                    ],
                    [
                        180.3868927864312,
                        335.8797882304449
                    ],
                    [
                        184.0291423878191,
                        337.59020026646783
                    ],
                    [
                        183.9392102988959,
                        336.5978613900968
                    ],
                    [
                        184.5012858546654,
                        335.2671736099114
                    ],
                    [
                        183.51203287651083,
                        334.612166293502
                    ],
                    [
                        180.99393438666243,
                        334.37970864207193
                    ],
                    [
                        183.08485545412577,
                        332.96342501683444
                    ],
                    [
                        184.1865235434343,
                        331.67336474516026
                    ],
                    [
                        185.9401992774358,
                        332.73083412978826
                    ],
                    [
                        188.07608638936085,
                        333.4074098421323
                    ],
                    [
                        188.54822985620763,
                        332.94228116988154
                    ],
                    [
                        189.73983003443914,
                        333.2382808573715
                    ],
                    [
                        189.4025847009775,
                        334.654429310106
                    ],
                    [
                        190.3019055902089,
                        336.66121227072443
                    ],
                    [
                        189.96466025674727,
                        339.76372348279176
                    ],
                    [
                        190.61666790144,
                        340.7549916365816
                    ],
                    [
                        193.13476639128817,
                        339.82700587747627
                    ],
                    [
                        193.85422310267336,
                        340.2910352457546
                    ],
                    [
                        195.20320443652076,
                        338.7932228678196
                    ],
                    [
                        195.4055516365977,
                        341.239959090874
                    ],
                    [
                        196.7994990149066,
                        343.2422459339323
                    ],
                    [
                        198.48572568221584,
                        344.04278401304305
                    ],
                    [
                        196.1250083479831,
                        344.1059752606034
                    ],
                    [
                        194.25891750282767,
                        345.09579758253983
                    ],
                    [
                        193.494494746981,
                        346.1694914659786
                    ],
                    [
                        192.52772479105693,
                        349.74572380574756
                    ],
                    [
                        190.57170185697828,
                        351.637329305188
                    ],
                    [
                        190.4817697680553,
                        353.10777590818884
                    ],
                    [
                        189.447550745439,
                        353.6957592372588
                    ],
                    [
                        187.8737391892837,
                        353.8427376910373
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-Krishna",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Belagavi",
            "dt_code": "555",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        97.67185399936079,
                        338.3922702636092
                    ],
                    [
                        99.94263924467032,
                        337.50575903981166
                    ],
                    [
                        100.95437524505564,
                        337.50575903981166
                    ],
                    [
                        100.70706200051723,
                        336.13324608585606
                    ],
                    [
                        103.4499907126733,
                        334.7600841461107
                    ],
                    [
                        104.82145506875145,
                        336.42891896263467
                    ],
                    [
                        106.0355382692137,
                        336.13324608585606
                    ],
                    [
                        106.26036849152183,
                        334.696691708251
                    ],
                    [
                        107.15968938075343,
                        334.696691708251
                    ],
                    [
                        107.06975729183023,
                        336.02764133563284
                    ],
                    [
                        107.94659515883086,
                        336.13324608585606
                    ],
                    [
                        107.78921400321519,
                        337.6535295839633
                    ],
                    [
                        107.47445169198431,
                        338.4133744000088
                    ],
                    [
                        106.05802129144467,
                        338.75101991277575
                    ],
                    [
                        105.94560618029072,
                        340.0590296933131
                    ],
                    [
                        104.46172671305885,
                        340.4175760379173
                    ],
                    [
                        103.83220209059664,
                        341.5983622399557
                    ],
                    [
                        102.25839053444156,
                        341.11345350595036
                    ],
                    [
                        101.9661112454412,
                        342.4204176586777
                    ],
                    [
                        102.7755000457496,
                        344.4850947496478
                    ],
                    [
                        104.05703231290454,
                        344.29554100203495
                    ],
                    [
                        104.32682857967416,
                        345.0326271870764
                    ],
                    [
                        105.58587782459813,
                        345.39057521122027
                    ],
                    [
                        106.35030058044481,
                        346.2747349884875
                    ],
                    [
                        106.19291942482937,
                        347.5163319931478
                    ],
                    [
                        108.59860280352359,
                        347.57945045065185
                    ],
                    [
                        108.30632351452346,
                        348.5471026418212
                    ],
                    [
                        108.77846698137,
                        351.09098504029396
                    ],
                    [
                        107.49693471421529,
                        352.33062694031116
                    ],
                    [
                        106.59761382498368,
                        352.22559184400643
                    ],
                    [
                        106.39526662490653,
                        354.0736898092896
                    ],
                    [
                        105.11373435775158,
                        354.7244628041459
                    ],
                    [
                        103.74227000167343,
                        354.3676041061723
                    ],
                    [
                        102.97784724582675,
                        354.97633850928946
                    ],
                    [
                        101.65134893421032,
                        354.64049972234716
                    ],
                    [
                        99.65035995566996,
                        355.31214122228795
                    ],
                    [
                        99.40304671113131,
                        356.9276890533505
                    ],
                    [
                        98.30137862182255,
                        358.29081861045495
                    ],
                    [
                        96.45777079889808,
                        359.15033713121096
                    ],
                    [
                        95.44603479851253,
                        358.26985183505246
                    ],
                    [
                        93.71484208674201,
                        358.5424090997881
                    ],
                    [
                        93.64739302004955,
                        357.5568988388942
                    ],
                    [
                        92.47827586404833,
                        356.717924553541
                    ],
                    [
                        91.53398893035524,
                        356.65499247900243
                    ],
                    [
                        90.926947330124,
                        354.4305821362681
                    ],
                    [
                        89.1732715961225,
                        354.68248154546563
                    ],
                    [
                        89.24072066281497,
                        353.63276634512624
                    ],
                    [
                        90.52225292996968,
                        352.70872376518497
                    ],
                    [
                        90.94943035235497,
                        353.25478217715886
                    ],
                    [
                        92.52324190851004,
                        352.96076267340675
                    ],
                    [
                        93.13028350874151,
                        350.81777654426855
                    ],
                    [
                        94.25443462028079,
                        348.75742110718363
                    ],
                    [
                        92.90545328643361,
                        348.14745754022647
                    ],
                    [
                        94.50174786481944,
                        347.09550870021513
                    ],
                    [
                        94.50174786481944,
                        345.53795319410347
                    ],
                    [
                        92.92793630866436,
                        345.3484658903204
                    ],
                    [
                        92.09606448612521,
                        344.3587269167076
                    ],
                    [
                        92.32089470843289,
                        342.39934215564455
                    ],
                    [
                        92.07358146389424,
                        340.86042610897977
                    ],
                    [
                        95.44603479851253,
                        339.616059271383
                    ],
                    [
                        95.8282461764361,
                        340.86042610897977
                    ],
                    [
                        96.70508404343695,
                        340.4175760379173
                    ],
                    [
                        97.67185399936079,
                        338.3922702636092
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Belagavi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Yadgir",
            "dt_code": "580",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        123.63974467592129,
                        338.4555822166601
                    ],
                    [
                        125.61825063223068,
                        337.63241996405463
                    ],
                    [
                        127.64172263300179,
                        337.4213153704136
                    ],
                    [
                        129.6427116115417,
                        335.6263082309016
                    ],
                    [
                        130.2272701895422,
                        336.8301412106184
                    ],
                    [
                        131.28397223438924,
                        336.9568314836253
                    ],
                    [
                        131.80108174569727,
                        335.584059409662
                    ],
                    [
                        133.98193490208382,
                        334.95025311595
                    ],
                    [
                        136.56748245862468,
                        335.03476864274455
                    ],
                    [
                        138.2986751703952,
                        335.79529735895176
                    ],
                    [
                        138.54598841493407,
                        337.27353306835386
                    ],
                    [
                        137.8040486813179,
                        338.3078521971803
                    ],
                    [
                        138.20874308147222,
                        339.9113802884659
                    ],
                    [
                        137.6691505479332,
                        341.429707334981
                    ],
                    [
                        135.10608601362333,
                        342.1042694246895
                    ],
                    [
                        135.78057668054703,
                        343.094754982906
                    ],
                    [
                        134.09435001323777,
                        342.7365322915728
                    ],
                    [
                        133.84703676869913,
                        342.1253470199539
                    ],
                    [
                        131.68866663454355,
                        341.00802805419085
                    ],
                    [
                        131.1265910787738,
                        341.64052446697127
                    ],
                    [
                        129.6427116115417,
                        341.8724060032154
                    ],
                    [
                        129.21553418915664,
                        342.7576054069048
                    ],
                    [
                        127.77662076638626,
                        343.094754982906
                    ],
                    [
                        123.90954094269068,
                        344.88522443187924
                    ],
                    [
                        121.72868778630436,
                        346.37997484253697
                    ],
                    [
                        121.25654431945782,
                        345.01157009345764
                    ],
                    [
                        121.72868778630436,
                        342.8208238582962
                    ],
                    [
                        123.23505027576698,
                        342.65223833885756
                    ],
                    [
                        122.80787285338215,
                        339.51058029782024
                    ],
                    [
                        123.63974467592129,
                        338.4555822166601
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Yadgir",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bagalkote",
            "dt_code": "556",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        108.77846698137,
                        351.09098504029396
                    ],
                    [
                        108.30632351452346,
                        348.5471026418212
                    ],
                    [
                        108.59860280352359,
                        347.57945045065185
                    ],
                    [
                        106.19291942482937,
                        347.5163319931478
                    ],
                    [
                        106.35030058044481,
                        346.2747349884875
                    ],
                    [
                        105.58587782459813,
                        345.39057521122027
                    ],
                    [
                        104.32682857967416,
                        345.0326271870764
                    ],
                    [
                        104.05703231290454,
                        344.29554100203495
                    ],
                    [
                        102.7755000457496,
                        344.4850947496478
                    ],
                    [
                        101.9661112454412,
                        342.4204176586777
                    ],
                    [
                        102.25839053444156,
                        341.11345350595036
                    ],
                    [
                        103.83220209059664,
                        341.5983622399557
                    ],
                    [
                        104.46172671305885,
                        340.4175760379173
                    ],
                    [
                        105.94560618029072,
                        340.0590296933131
                    ],
                    [
                        106.05802129144467,
                        338.75101991277575
                    ],
                    [
                        107.47445169198431,
                        338.4133744000088
                    ],
                    [
                        107.78921400321519,
                        337.6535295839633
                    ],
                    [
                        109.40799160383222,
                        340.2699445860815
                    ],
                    [
                        109.47544067052468,
                        342.39934215564455
                    ],
                    [
                        111.45394662683384,
                        342.567941999239
                    ],
                    [
                        116.19786431753028,
                        344.80099103753236
                    ],
                    [
                        117.23208334014635,
                        344.7588734540297
                    ],
                    [
                        117.83912494037781,
                        346.1694914659786
                    ],
                    [
                        120.35722343022621,
                        346.82194220991585
                    ],
                    [
                        121.3015103639193,
                        348.2315977122299
                    ],
                    [
                        121.6612387196119,
                        350.37638843235527
                    ],
                    [
                        120.9867480526882,
                        350.83879344416926
                    ],
                    [
                        120.1773592523798,
                        350.2502658861329
                    ],
                    [
                        118.98575907414829,
                        350.96489182687554
                    ],
                    [
                        117.70422680699312,
                        350.77574231338394
                    ],
                    [
                        117.99650609599348,
                        349.19904268090437
                    ],
                    [
                        116.46766058429967,
                        349.55649909152476
                    ],
                    [
                        116.46766058429967,
                        351.4692336951882
                    ],
                    [
                        115.02874716152928,
                        350.39740835269515
                    ],
                    [
                        114.60156973914422,
                        351.8264259164634
                    ],
                    [
                        112.98279213852766,
                        352.20458439655624
                    ],
                    [
                        111.58884476021854,
                        351.78440544875787
                    ],
                    [
                        109.92510111514025,
                        351.88945554544273
                    ],
                    [
                        108.77846698137,
                        351.09098504029396
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Bagalkote",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sindhudurg",
            "dt_code": "529",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        77.84182839180517,
                        341.239959090874
                    ],
                    [
                        79.6179871480374,
                        341.7880875415033
                    ],
                    [
                        81.5515270598853,
                        341.02911344507356
                    ],
                    [
                        81.86628937111618,
                        340.43866564243183
                    ],
                    [
                        84.02465950527221,
                        340.1223057453328
                    ],
                    [
                        85.26122572796544,
                        339.42619439549026
                    ],
                    [
                        84.94646341673456,
                        340.9447709797214
                    ],
                    [
                        85.77833523927347,
                        342.48364327180514
                    ],
                    [
                        84.96894643896508,
                        344.0849116596351
                    ],
                    [
                        85.82330128373519,
                        345.4326839427854
                    ],
                    [
                        86.56524101735113,
                        345.6221659448537
                    ],
                    [
                        86.09309755050458,
                        347.26384502799635
                    ],
                    [
                        87.32966377319781,
                        347.93709692466564
                    ],
                    [
                        87.41959586212101,
                        348.75742110718363
                    ],
                    [
                        88.83602626266088,
                        349.2410985465435
                    ],
                    [
                        88.52126395142977,
                        350.14515980249377
                    ],
                    [
                        89.03837346273804,
                        352.07853671335124
                    ],
                    [
                        90.52225292996968,
                        352.70872376518497
                    ],
                    [
                        89.24072066281497,
                        353.63276634512624
                    ],
                    [
                        89.1732715961225,
                        354.68248154546563
                    ],
                    [
                        87.30718075096706,
                        355.29115460849647
                    ],
                    [
                        86.54275799512038,
                        353.2967826895309
                    ],
                    [
                        85.59847106142706,
                        353.17077944756636
                    ],
                    [
                        82.81057630480927,
                        353.5067767316349
                    ],
                    [
                        81.39414590426986,
                        350.7337075076276
                    ],
                    [
                        80.24751177049961,
                        350.3133278073674
                    ],
                    [
                        78.78611532549826,
                        345.18002271110595
                    ],
                    [
                        78.15659070303627,
                        343.79000567715116
                    ],
                    [
                        77.84182839180517,
                        341.239959090874
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Sindhudurg",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Guntur",
            "dt_code": "548",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        176.72216016281277,
                        336.8301412106184
                    ],
                    [
                        177.53154896312094,
                        337.062402502263
                    ],
                    [
                        177.71141314096735,
                        339.3418060712969
                    ],
                    [
                        181.08386647558564,
                        340.3543063201522
                    ],
                    [
                        182.59022896504848,
                        340.33321611277574
                    ],
                    [
                        185.42308976612776,
                        342.1253470199539
                    ],
                    [
                        185.55798789951268,
                        342.86296874719585
                    ],
                    [
                        187.40159572243715,
                        345.0326271870764
                    ],
                    [
                        188.61567892289963,
                        348.3157355574629
                    ],
                    [
                        188.8180261229768,
                        350.54454376467095
                    ],
                    [
                        187.8737391892837,
                        353.8427376910373
                    ],
                    [
                        187.04186736674455,
                        351.38518245254227
                    ],
                    [
                        185.6479199884359,
                        351.11200007350715
                    ],
                    [
                        183.53451589874157,
                        351.61631785508115
                    ],
                    [
                        181.80332318697083,
                        352.4356584696585
                    ],
                    [
                        181.21876460897033,
                        350.94387578881816
                    ],
                    [
                        181.24124763120108,
                        349.76674805176856
                    ],
                    [
                        180.09461349743083,
                        348.50503720666353
                    ],
                    [
                        179.037911452584,
                        348.8625748988473
                    ],
                    [
                        177.66644709650586,
                        348.6522636887629
                    ],
                    [
                        175.6654581179655,
                        347.22176182277315
                    ],
                    [
                        173.86681633950252,
                        346.9902937447554
                    ],
                    [
                        173.73191820611783,
                        348.88360522212434
                    ],
                    [
                        174.36144282858004,
                        349.85084359205734
                    ],
                    [
                        173.73191820611783,
                        350.62861797743534
                    ],
                    [
                        172.58528407234758,
                        350.27128667059526
                    ],
                    [
                        171.93327642765485,
                        351.5112584569312
                    ],
                    [
                        170.58429509380744,
                        350.5655625336358
                    ],
                    [
                        169.1678646932678,
                        350.14515980249377
                    ],
                    [
                        169.37021189334496,
                        349.28315383319915
                    ],
                    [
                        168.8531023820367,
                        346.8640309715645
                    ],
                    [
                        169.28027980442175,
                        345.13791044183074
                    ],
                    [
                        167.52660407042026,
                        344.7167552794896
                    ],
                    [
                        166.40245295888076,
                        345.7905843827333
                    ],
                    [
                        164.10918469134026,
                        345.89584112635896
                    ],
                    [
                        164.04173562464803,
                        343.8953325795894
                    ],
                    [
                        164.17663375803272,
                        341.5140359870794
                    ],
                    [
                        164.73870931380247,
                        340.691729147672
                    ],
                    [
                        167.0994266480352,
                        340.4597550962666
                    ],
                    [
                        167.52660407042026,
                        339.8691933851028
                    ],
                    [
                        168.92055144872916,
                        339.6582498020447
                    ],
                    [
                        170.94402344950004,
                        338.7299182075236
                    ],
                    [
                        172.60776709457832,
                        338.24453705008614
                    ],
                    [
                        173.84433331727178,
                        338.66661218067463
                    ],
                    [
                        174.90103536211882,
                        339.616059271383
                    ],
                    [
                        176.4748469182739,
                        337.9490482492745
                    ],
                    [
                        176.72216016281277,
                        336.8301412106184
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-Guntur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "East Godavari",
            "dt_code": "545",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        196.19245741467557,
                        321.41654480746115
                    ],
                    [
                        199.43001261590916,
                        321.3316201920964
                    ],
                    [
                        200.8914090609103,
                        320.5247055081348
                    ],
                    [
                        202.2628734169882,
                        319.2713937015509
                    ],
                    [
                        204.75848888460587,
                        318.40010968259304
                    ],
                    [
                        205.5004286182218,
                        319.5475964461087
                    ],
                    [
                        204.48869261783648,
                        321.41654480746115
                    ],
                    [
                        205.29808141814487,
                        322.4142130594419
                    ],
                    [
                        205.09573421806772,
                        324.1751740972838
                    ],
                    [
                        208.4232215082243,
                        324.1751740972838
                    ],
                    [
                        209.52488959753282,
                        324.9386173189293
                    ],
                    [
                        210.1319311977643,
                        326.4012998035872
                    ],
                    [
                        211.09870115368813,
                        326.8251228793646
                    ],
                    [
                        211.90808995399652,
                        326.0621951879836
                    ],
                    [
                        213.93156195476763,
                        327.0158223781092
                    ],
                    [
                        212.6725127098432,
                        329.4302321938791
                    ],
                    [
                        213.03224106553603,
                        330.19224925321834
                    ],
                    [
                        209.74971981984095,
                        333.1325701859349
                    ],
                    [
                        208.71550079722465,
                        335.01363999261446
                    ],
                    [
                        208.71550079722465,
                        335.9220327434618
                    ],
                    [
                        210.37924444230293,
                        336.2388469959311
                    ],
                    [
                        209.7272367976102,
                        339.21521904200824
                    ],
                    [
                        209.6822707531485,
                        340.79716587683697
                    ],
                    [
                        208.49067057491675,
                        341.05019868567683
                    ],
                    [
                        204.42124355114402,
                        343.2843848663954
                    ],
                    [
                        202.8474319949887,
                        343.6425417799953
                    ],
                    [
                        200.86892603867955,
                        344.5903972085086
                    ],
                    [
                        200.64409581637142,
                        342.88404096807994
                    ],
                    [
                        201.4759676389108,
                        342.86296874719585
                    ],
                    [
                        202.80246595052722,
                        341.7670075516963
                    ],
                    [
                        202.98233012837363,
                        340.1855804393047
                    ],
                    [
                        201.2961034610646,
                        337.18908267667496
                    ],
                    [
                        201.00382417206424,
                        335.330553543842
                    ],
                    [
                        201.31858648329535,
                        334.569902658324
                    ],
                    [
                        199.7672579493708,
                        330.82710734708195
                    ],
                    [
                        200.3518165273715,
                        329.3667215272988
                    ],
                    [
                        199.4075295936782,
                        328.9221069634199
                    ],
                    [
                        198.64310683783128,
                        327.2488819601609
                    ],
                    [
                        197.72130292636894,
                        326.6132193481567
                    ],
                    [
                        196.349838570291,
                        326.86750166528935
                    ],
                    [
                        194.97837421421264,
                        326.5072615784559
                    ],
                    [
                        194.101536347212,
                        325.32026049993493
                    ],
                    [
                        192.3703436354415,
                        325.0658375148239
                    ],
                    [
                        190.45928674582433,
                        324.069123745662
                    ],
                    [
                        189.04285634528492,
                        324.19638368209445
                    ],
                    [
                        188.6831279895921,
                        322.69010020238863
                    ],
                    [
                        189.96466025674727,
                        322.0109438162972
                    ],
                    [
                        191.22370950167124,
                        322.7325419347086
                    ],
                    [
                        191.60592087959458,
                        321.7349887910929
                    ],
                    [
                        192.79752105782632,
                        320.80078193469564
                    ],
                    [
                        194.25891750282767,
                        321.52269689282633
                    ],
                    [
                        196.19245741467557,
                        321.41654480746115
                    ]
                ],
                [
                    [
                        207.7936968857623,
                        338.0545833901276
                    ],
                    [
                        207.99604408583946,
                        338.3078521971803
                    ],
                    [
                        208.58060266383995,
                        338.37116597514057
                    ],
                    [
                        208.98529706399427,
                        338.7088163504323
                    ],
                    [
                        209.03026310845576,
                        338.28674730029394
                    ],
                    [
                        207.7936968857623,
                        338.0545833901276
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-East Godavari",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Raichur",
            "dt_code": "559",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        121.72868778630436,
                        346.37997484253697
                    ],
                    [
                        123.90954094269068,
                        344.88522443187924
                    ],
                    [
                        127.77662076638626,
                        343.094754982906
                    ],
                    [
                        129.21553418915664,
                        342.7576054069048
                    ],
                    [
                        129.6427116115417,
                        341.8724060032154
                    ],
                    [
                        131.1265910787738,
                        341.64052446697127
                    ],
                    [
                        131.68866663454355,
                        341.00802805419085
                    ],
                    [
                        133.84703676869913,
                        342.1253470199539
                    ],
                    [
                        134.09435001323777,
                        342.7365322915728
                    ],
                    [
                        135.78057668054703,
                        343.094754982906
                    ],
                    [
                        137.64666752570247,
                        343.7268077548465
                    ],
                    [
                        138.748335615011,
                        343.45293464721277
                    ],
                    [
                        140.27718112670482,
                        344.1059752606034
                    ],
                    [
                        138.90571677062644,
                        345.95899340849985
                    ],
                    [
                        139.1080639707036,
                        350.4604672499446
                    ],
                    [
                        135.19601810254653,
                        349.8928904961221
                    ],
                    [
                        132.6104705460059,
                        350.7337075076276
                    ],
                    [
                        131.9359798790822,
                        351.8474359357862
                    ],
                    [
                        130.69941365638874,
                        353.0027671658505
                    ],
                    [
                        130.3172022784654,
                        353.9477180505241
                    ],
                    [
                        129.1031190780027,
                        355.0393042640707
                    ],
                    [
                        128.76587374454107,
                        356.2773735969248
                    ],
                    [
                        126.96723196607786,
                        355.52199962454284
                    ],
                    [
                        126.9897149883086,
                        354.7244628041459
                    ],
                    [
                        125.61825063223068,
                        353.9477180505241
                    ],
                    [
                        123.41491445361339,
                        353.42278081829613
                    ],
                    [
                        123.12263516461326,
                        351.02793907955595
                    ],
                    [
                        120.9867480526882,
                        350.83879344416926
                    ],
                    [
                        121.6612387196119,
                        350.37638843235527
                    ],
                    [
                        121.3015103639193,
                        348.2315977122299
                    ],
                    [
                        120.35722343022621,
                        346.82194220991585
                    ],
                    [
                        121.72868778630436,
                        346.37997484253697
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Raichur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Prakasam",
            "dt_code": "549",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        164.10918469134026,
                        345.89584112635896
                    ],
                    [
                        166.40245295888076,
                        345.7905843827333
                    ],
                    [
                        167.52660407042026,
                        344.7167552794896
                    ],
                    [
                        169.28027980442175,
                        345.13791044183074
                    ],
                    [
                        168.8531023820367,
                        346.8640309715645
                    ],
                    [
                        169.37021189334496,
                        349.28315383319915
                    ],
                    [
                        169.1678646932678,
                        350.14515980249377
                    ],
                    [
                        170.58429509380744,
                        350.5655625336358
                    ],
                    [
                        171.93327642765485,
                        351.5112584569312
                    ],
                    [
                        172.58528407234758,
                        350.27128667059526
                    ],
                    [
                        173.73191820611783,
                        350.62861797743534
                    ],
                    [
                        174.36144282858004,
                        349.85084359205734
                    ],
                    [
                        173.73191820611783,
                        348.88360522212434
                    ],
                    [
                        173.86681633950252,
                        346.9902937447554
                    ],
                    [
                        175.6654581179655,
                        347.22176182277315
                    ],
                    [
                        177.66644709650586,
                        348.6522636887629
                    ],
                    [
                        179.037911452584,
                        348.8625748988473
                    ],
                    [
                        180.09461349743083,
                        348.50503720666353
                    ],
                    [
                        181.24124763120108,
                        349.76674805176856
                    ],
                    [
                        181.21876460897033,
                        350.94387578881816
                    ],
                    [
                        181.80332318697083,
                        352.4356584696585
                    ],
                    [
                        180.29696069750798,
                        353.5907703746818
                    ],
                    [
                        179.3526737638149,
                        354.9553496425532
                    ],
                    [
                        178.6781830968912,
                        357.3891218277098
                    ],
                    [
                        177.4640998964287,
                        359.00360653197083
                    ],
                    [
                        176.25001669596622,
                        364.47002058696734
                    ],
                    [
                        175.10338256219597,
                        364.28167762750155
                    ],
                    [
                        172.4054198945014,
                        364.9094451020452
                    ],
                    [
                        171.75341224980843,
                        364.1351811134498
                    ],
                    [
                        169.23531375996026,
                        363.94681874279684
                    ],
                    [
                        164.78367535826396,
                        362.983906782112
                    ],
                    [
                        162.8501354464163,
                        363.0885850013326
                    ],
                    [
                        162.2655768684158,
                        363.88402885690874
                    ],
                    [
                        162.1756447794926,
                        361.9788231335506
                    ],
                    [
                        157.67904033333502,
                        361.58089087159954
                    ],
                    [
                        157.4092440665654,
                        361.81127870884177
                    ],
                    [
                        157.07199873310356,
                        359.65336211213094
                    ],
                    [
                        157.58910824441182,
                        358.2069506764391
                    ],
                    [
                        157.58910824441182,
                        354.47256678325516
                    ],
                    [
                        158.15118380018157,
                        351.38518245254227
                    ],
                    [
                        159.63506326741344,
                        349.85084359205734
                    ],
                    [
                        159.81492744525985,
                        347.4742522919842
                    ],
                    [
                        159.54513117849024,
                        346.5483509830394
                    ],
                    [
                        161.38873900141493,
                        345.66427143710615
                    ],
                    [
                        163.27731286880135,
                        346.1484423211059
                    ],
                    [
                        164.10918469134026,
                        345.89584112635896
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-Prakasam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kurnool",
            "dt_code": "552",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        131.9359798790822,
                        351.8474359357862
                    ],
                    [
                        132.6104705460059,
                        350.7337075076276
                    ],
                    [
                        135.19601810254653,
                        349.8928904961221
                    ],
                    [
                        139.1080639707036,
                        350.4604672499446
                    ],
                    [
                        141.24395108262866,
                        351.11200007350715
                    ],
                    [
                        143.35735517232274,
                        351.28011517464984
                    ],
                    [
                        144.59392139501597,
                        350.79675950067804
                    ],
                    [
                        147.47174824055696,
                        351.78440544875787
                    ],
                    [
                        148.82072957440437,
                        351.5322706229566
                    ],
                    [
                        149.89991464148216,
                        349.78777215339574
                    ],
                    [
                        150.0123297526361,
                        349.22007068610407
                    ],
                    [
                        152.28311499794563,
                        348.2105628873989
                    ],
                    [
                        154.35155304317823,
                        348.6943270914546
                    ],
                    [
                        155.09349277679416,
                        348.0633150405487
                    ],
                    [
                        157.13944779979602,
                        349.22007068610407
                    ],
                    [
                        159.81492744525985,
                        347.4742522919842
                    ],
                    [
                        159.63506326741344,
                        349.85084359205734
                    ],
                    [
                        158.15118380018157,
                        351.38518245254227
                    ],
                    [
                        157.58910824441182,
                        354.47256678325516
                    ],
                    [
                        157.58910824441182,
                        358.2069506764391
                    ],
                    [
                        157.07199873310356,
                        359.65336211213094
                    ],
                    [
                        157.4092440665654,
                        361.81127870884177
                    ],
                    [
                        157.45421011102712,
                        363.7793763466385
                    ],
                    [
                        155.52067019917922,
                        365.7462803919617
                    ],
                    [
                        153.87940957633168,
                        365.30696861124306
                    ],
                    [
                        153.38478308725416,
                        364.05146585334967
                    ],
                    [
                        152.41801313133033,
                        364.1351811134498
                    ],
                    [
                        150.50695624171317,
                        363.52819654028303
                    ],
                    [
                        149.49522024132807,
                        365.2860475288019
                    ],
                    [
                        148.2136879741729,
                        365.2023618579952
                    ],
                    [
                        146.75229152917177,
                        364.61650201577925
                    ],
                    [
                        146.4600122401714,
                        362.9001617658915
                    ],
                    [
                        143.53721935016893,
                        362.43952535355766
                    ],
                    [
                        142.54796637201434,
                        362.62797546791217
                    ],
                    [
                        141.31140014932112,
                        361.03627209596493
                    ],
                    [
                        140.0973169488584,
                        361.7484472996687
                    ],
                    [
                        139.28792814855,
                        361.20385694886454
                    ],
                    [
                        136.88224476985556,
                        361.22480443981067
                    ],
                    [
                        135.8480257472395,
                        362.62797546791217
                    ],
                    [
                        133.84703676869913,
                        362.48140410815665
                    ],
                    [
                        133.39737632408355,
                        361.1619615566112
                    ],
                    [
                        133.7795877020069,
                        360.15630771551014
                    ],
                    [
                        132.4755724126212,
                        359.61144639814796
                    ],
                    [
                        132.02591196800518,
                        357.9763024069549
                    ],
                    [
                        132.00342894577443,
                        354.93436063488946
                    ],
                    [
                        132.76785170162134,
                        354.55653438234856
                    ],
                    [
                        131.9359798790822,
                        351.8474359357862
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-Kurnool",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Koppal",
            "dt_code": "560",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        114.60156973914422,
                        351.8264259164634
                    ],
                    [
                        115.02874716152928,
                        350.39740835269515
                    ],
                    [
                        116.46766058429967,
                        351.4692336951882
                    ],
                    [
                        116.46766058429967,
                        349.55649909152476
                    ],
                    [
                        117.99650609599348,
                        349.19904268090437
                    ],
                    [
                        117.70422680699312,
                        350.77574231338394
                    ],
                    [
                        118.98575907414829,
                        350.96489182687554
                    ],
                    [
                        120.1773592523798,
                        350.2502658861329
                    ],
                    [
                        120.9867480526882,
                        350.83879344416926
                    ],
                    [
                        123.12263516461326,
                        351.02793907955595
                    ],
                    [
                        123.41491445361339,
                        353.42278081829613
                    ],
                    [
                        125.61825063223068,
                        353.9477180505241
                    ],
                    [
                        126.9897149883086,
                        354.7244628041459
                    ],
                    [
                        126.96723196607786,
                        355.52199962454284
                    ],
                    [
                        128.76587374454107,
                        356.2773735969248
                    ],
                    [
                        128.47359445554093,
                        356.9906156801468
                    ],
                    [
                        125.84308085453858,
                        357.87145672878194
                    ],
                    [
                        124.08940512053687,
                        359.35994053214347
                    ],
                    [
                        122.20083125315091,
                        359.61144639814796
                    ],
                    [
                        121.1216461860729,
                        361.22480443981067
                    ],
                    [
                        119.5928006743793,
                        361.03627209596493
                    ],
                    [
                        118.22133631830116,
                        362.12541735073194
                    ],
                    [
                        116.13041525083804,
                        362.50234328154124
                    ],
                    [
                        115.2086113393757,
                        360.1982162721238
                    ],
                    [
                        114.87136600591384,
                        358.39565040707737
                    ],
                    [
                        114.2643244056826,
                        357.72466694139507
                    ],
                    [
                        115.34350947276039,
                        355.3541140278142
                    ],
                    [
                        114.9388150726063,
                        353.7167565844166
                    ],
                    [
                        116.35524547314571,
                        354.26263789610664
                    ],
                    [
                        117.41194751799276,
                        352.8977548677744
                    ],
                    [
                        115.79316991737596,
                        352.56169159896075
                    ],
                    [
                        114.60156973914422,
                        351.8264259164634
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Koppal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gadag",
            "dt_code": "561",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        106.39526662490653,
                        354.0736898092896
                    ],
                    [
                        106.59761382498368,
                        352.22559184400643
                    ],
                    [
                        107.49693471421529,
                        352.33062694031116
                    ],
                    [
                        108.77846698137,
                        351.09098504029396
                    ],
                    [
                        109.92510111514025,
                        351.88945554544273
                    ],
                    [
                        111.58884476021854,
                        351.78440544875787
                    ],
                    [
                        112.98279213852766,
                        352.20458439655624
                    ],
                    [
                        114.60156973914422,
                        351.8264259164634
                    ],
                    [
                        115.79316991737596,
                        352.56169159896075
                    ],
                    [
                        117.41194751799276,
                        352.8977548677744
                    ],
                    [
                        116.35524547314571,
                        354.26263789610664
                    ],
                    [
                        114.9388150726063,
                        353.7167565844166
                    ],
                    [
                        115.34350947276039,
                        355.3541140278142
                    ],
                    [
                        114.2643244056826,
                        357.72466694139507
                    ],
                    [
                        114.87136600591384,
                        358.39565040707737
                    ],
                    [
                        115.2086113393757,
                        360.1982162721238
                    ],
                    [
                        116.13041525083804,
                        362.50234328154124
                    ],
                    [
                        115.50089062837583,
                        363.4444656114014
                    ],
                    [
                        112.44319960498865,
                        365.3906516003767
                    ],
                    [
                        111.4314636046031,
                        364.7420522792946
                    ],
                    [
                        110.10496529298666,
                        364.93036860022175
                    ],
                    [
                        109.97006715960197,
                        364.0933237531512
                    ],
                    [
                        108.26135747006197,
                        363.3188651587028
                    ],
                    [
                        108.50867071460084,
                        361.58089087159954
                    ],
                    [
                        108.21639142560048,
                        360.05153391791674
                    ],
                    [
                        109.38550858160147,
                        359.00360653197083
                    ],
                    [
                        109.2281274259858,
                        356.508146078193
                    ],
                    [
                        109.92510111514025,
                        356.4242307717806
                    ],
                    [
                        110.28482947083307,
                        354.7454532218567
                    ],
                    [
                        109.31805951490901,
                        353.9057263316249
                    ],
                    [
                        108.21639142560048,
                        354.6195085990888
                    ],
                    [
                        106.39526662490653,
                        354.0736898092896
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Gadag",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "North Goa",
            "dt_code": "585",
            "st_nm": "Goa",
            "st_code": "30",
            "alpha2code": "GA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        89.1732715961225,
                        354.68248154546563
                    ],
                    [
                        90.926947330124,
                        354.4305821362681
                    ],
                    [
                        91.53398893035524,
                        356.65499247900243
                    ],
                    [
                        91.12929453020115,
                        357.1164651656124
                    ],
                    [
                        90.07259248535411,
                        357.95533354921577
                    ],
                    [
                        88.25146768466016,
                        358.39565040707737
                    ],
                    [
                        87.89173932896779,
                        357.32620315029124
                    ],
                    [
                        86.88000332858223,
                        356.3822722789265
                    ],
                    [
                        86.47530892842792,
                        358.01823970568796
                    ],
                    [
                        84.00217648304147,
                        357.0745158952351
                    ],
                    [
                        82.81057630480927,
                        353.5067767316349
                    ],
                    [
                        85.59847106142706,
                        353.17077944756636
                    ],
                    [
                        86.54275799512038,
                        353.2967826895309
                    ],
                    [
                        87.30718075096706,
                        355.29115460849647
                    ],
                    [
                        89.1732715961225,
                        354.68248154546563
                    ]
                ]
            ]
        },
        "id": "TT-Goa-North Goa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ballari",
            "dt_code": "565",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        128.76587374454107,
                        356.2773735969248
                    ],
                    [
                        129.1031190780027,
                        355.0393042640707
                    ],
                    [
                        130.3172022784654,
                        353.9477180505241
                    ],
                    [
                        130.69941365638874,
                        353.0027671658505
                    ],
                    [
                        131.9359798790822,
                        351.8474359357862
                    ],
                    [
                        132.76785170162134,
                        354.55653438234856
                    ],
                    [
                        132.00342894577443,
                        354.93436063488946
                    ],
                    [
                        132.02591196800518,
                        357.9763024069549
                    ],
                    [
                        132.4755724126212,
                        359.61144639814796
                    ],
                    [
                        133.7795877020069,
                        360.15630771551014
                    ],
                    [
                        133.39737632408355,
                        361.1619615566112
                    ],
                    [
                        133.84703676869913,
                        362.48140410815665
                    ],
                    [
                        133.21751214623714,
                        364.0933237531512
                    ],
                    [
                        131.32893827885096,
                        364.386313951856
                    ],
                    [
                        128.6084925889254,
                        363.1513903062983
                    ],
                    [
                        128.02393401092513,
                        364.7839012913978
                    ],
                    [
                        126.80985081046265,
                        366.33193767646435
                    ],
                    [
                        127.32696032177046,
                        366.6665520047229
                    ],
                    [
                        126.31522432138513,
                        368.9870032294939
                    ],
                    [
                        125.19107320984563,
                        370.88814419678073
                    ],
                    [
                        124.35920138730648,
                        371.3058322548062
                    ],
                    [
                        123.07766912015154,
                        370.9716859782297
                    ],
                    [
                        121.75117080853511,
                        369.36313926581863
                    ],
                    [
                        119.54783462991759,
                        369.17507657493456
                    ],
                    [
                        118.73844582960942,
                        366.6038144169406
                    ],
                    [
                        116.35524547314571,
                        365.99728919706536
                    ],
                    [
                        113.92707907222075,
                        366.6038144169406
                    ],
                    [
                        114.2643244056826,
                        367.9837650314423
                    ],
                    [
                        113.29755444975854,
                        368.652624276802
                    ],
                    [
                        111.90360707144964,
                        367.5447525443785
                    ],
                    [
                        112.44319960498865,
                        365.3906516003767
                    ],
                    [
                        115.50089062837583,
                        363.4444656114014
                    ],
                    [
                        116.13041525083804,
                        362.50234328154124
                    ],
                    [
                        118.22133631830116,
                        362.12541735073194
                    ],
                    [
                        119.5928006743793,
                        361.03627209596493
                    ],
                    [
                        121.1216461860729,
                        361.22480443981067
                    ],
                    [
                        122.20083125315091,
                        359.61144639814796
                    ],
                    [
                        124.08940512053687,
                        359.35994053214347
                    ],
                    [
                        125.84308085453858,
                        357.87145672878194
                    ],
                    [
                        128.47359445554093,
                        356.9906156801468
                    ],
                    [
                        128.76587374454107,
                        356.2773735969248
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Ballari",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dharwad",
            "dt_code": "562",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        98.30137862182255,
                        358.29081861045495
                    ],
                    [
                        99.40304671113131,
                        356.9276890533505
                    ],
                    [
                        99.65035995566996,
                        355.31214122228795
                    ],
                    [
                        101.65134893421032,
                        354.64049972234716
                    ],
                    [
                        102.97784724582675,
                        354.97633850928946
                    ],
                    [
                        103.74227000167343,
                        354.3676041061723
                    ],
                    [
                        105.11373435775158,
                        354.7244628041459
                    ],
                    [
                        106.39526662490653,
                        354.0736898092896
                    ],
                    [
                        108.21639142560048,
                        354.6195085990888
                    ],
                    [
                        109.31805951490901,
                        353.9057263316249
                    ],
                    [
                        110.28482947083307,
                        354.7454532218567
                    ],
                    [
                        109.92510111514025,
                        356.4242307717806
                    ],
                    [
                        109.2281274259858,
                        356.508146078193
                    ],
                    [
                        109.38550858160147,
                        359.00360653197083
                    ],
                    [
                        108.21639142560048,
                        360.05153391791674
                    ],
                    [
                        108.50867071460084,
                        361.58089087159954
                    ],
                    [
                        108.26135747006197,
                        363.3188651587028
                    ],
                    [
                        105.65332689129059,
                        362.96297073150197
                    ],
                    [
                        103.36005862375009,
                        363.4653985465424
                    ],
                    [
                        100.50471480044007,
                        363.84216825793806
                    ],
                    [
                        99.6728429779007,
                        362.54422122054996
                    ],
                    [
                        100.81947711167118,
                        360.78487838721423
                    ],
                    [
                        100.50471480044007,
                        359.9258008216798
                    ],
                    [
                        98.30137862182255,
                        358.29081861045495
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Dharwad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Uttara Kannada",
            "dt_code": "563",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        91.53398893035524,
                        356.65499247900243
                    ],
                    [
                        92.47827586404833,
                        356.717924553541
                    ],
                    [
                        93.64739302004955,
                        357.5568988388942
                    ],
                    [
                        93.71484208674201,
                        358.5424090997881
                    ],
                    [
                        95.44603479851253,
                        358.26985183505246
                    ],
                    [
                        96.45777079889808,
                        359.15033713121096
                    ],
                    [
                        98.30137862182255,
                        358.29081861045495
                    ],
                    [
                        100.50471480044007,
                        359.9258008216798
                    ],
                    [
                        100.81947711167118,
                        360.78487838721423
                    ],
                    [
                        99.6728429779007,
                        362.54422122054996
                    ],
                    [
                        100.50471480044007,
                        363.84216825793806
                    ],
                    [
                        103.36005862375009,
                        363.4653985465424
                    ],
                    [
                        103.65233791275023,
                        365.6207687663519
                    ],
                    [
                        102.75301702351862,
                        368.04647633459444
                    ],
                    [
                        102.88791515690355,
                        369.9481551651429
                    ],
                    [
                        102.48322075674946,
                        371.1805313084078
                    ],
                    [
                        100.68457897828625,
                        371.7652289963702
                    ],
                    [
                        100.36981666705515,
                        373.18478587870777
                    ],
                    [
                        101.3815526674407,
                        373.74826788902874
                    ],
                    [
                        100.52719782267081,
                        376.5225758653962
                    ],
                    [
                        99.0882843999002,
                        376.3557638888576
                    ],
                    [
                        97.22219355474499,
                        377.00211513742425
                    ],
                    [
                        96.81749915459068,
                        378.35697376036944
                    ],
                    [
                        97.24467657697573,
                        380.00293428897646
                    ],
                    [
                        96.00811035428228,
                        380.9193345064175
                    ],
                    [
                        95.01885737612747,
                        380.00293428897646
                    ],
                    [
                        94.344366709204,
                        376.93957033325773
                    ],
                    [
                        93.93967230904991,
                        376.39746764384256
                    ],
                    [
                        93.24269861989524,
                        373.456103746806
                    ],
                    [
                        92.65814004189497,
                        372.07841800695917
                    ],
                    [
                        91.39909079697054,
                        370.2824038223705
                    ],
                    [
                        91.30915870804756,
                        368.88251343008875
                    ],
                    [
                        89.1732715961225,
                        367.8792435463023
                    ],
                    [
                        88.70112812927596,
                        366.12278638250007
                    ],
                    [
                        90.40983781881596,
                        365.7462803919617
                    ],
                    [
                        91.80378519712508,
                        363.98867799183813
                    ],
                    [
                        91.44405684143203,
                        363.13045534015816
                    ],
                    [
                        92.09606448612521,
                        361.7693912391307
                    ],
                    [
                        91.26419266358607,
                        360.93152711432356
                    ],
                    [
                        92.23096261950991,
                        360.30298525856836
                    ],
                    [
                        92.18599657504842,
                        359.00360653197083
                    ],
                    [
                        91.48902288589375,
                        358.6472325808302
                    ],
                    [
                        91.12929453020115,
                        357.1164651656124
                    ],
                    [
                        91.53398893035524,
                        356.65499247900243
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Uttara Kannada",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "South Goa",
            "dt_code": "586",
            "st_nm": "Goa",
            "st_code": "30",
            "alpha2code": "GA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        86.47530892842792,
                        358.01823970568796
                    ],
                    [
                        86.88000332858223,
                        356.3822722789265
                    ],
                    [
                        87.89173932896779,
                        357.32620315029124
                    ],
                    [
                        88.25146768466016,
                        358.39565040707737
                    ],
                    [
                        90.07259248535411,
                        357.95533354921577
                    ],
                    [
                        91.12929453020115,
                        357.1164651656124
                    ],
                    [
                        91.48902288589375,
                        358.6472325808302
                    ],
                    [
                        92.18599657504842,
                        359.00360653197083
                    ],
                    [
                        92.23096261950991,
                        360.30298525856836
                    ],
                    [
                        91.26419266358607,
                        360.93152711432356
                    ],
                    [
                        92.09606448612521,
                        361.7693912391307
                    ],
                    [
                        91.44405684143203,
                        363.13045534015816
                    ],
                    [
                        91.80378519712508,
                        363.98867799183813
                    ],
                    [
                        90.40983781881596,
                        365.7462803919617
                    ],
                    [
                        88.70112812927596,
                        366.12278638250007
                    ],
                    [
                        87.1722826175826,
                        363.7793763466385
                    ],
                    [
                        85.73336919481198,
                        359.21321960134367
                    ],
                    [
                        86.47530892842792,
                        358.01823970568796
                    ]
                ]
            ]
        },
        "id": "TT-Goa-South Goa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Haveri",
            "dt_code": "564",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        108.26135747006197,
                        363.3188651587028
                    ],
                    [
                        109.97006715960197,
                        364.0933237531512
                    ],
                    [
                        110.10496529298666,
                        364.93036860022175
                    ],
                    [
                        111.4314636046031,
                        364.7420522792946
                    ],
                    [
                        112.44319960498865,
                        365.3906516003767
                    ],
                    [
                        111.90360707144964,
                        367.5447525443785
                    ],
                    [
                        113.29755444975854,
                        368.652624276802
                    ],
                    [
                        113.88211302775903,
                        369.4676139383835
                    ],
                    [
                        114.1743923167594,
                        371.2222988112977
                    ],
                    [
                        113.85963000552829,
                        372.05753964757037
                    ],
                    [
                        112.28581844937298,
                        372.32894821165905
                    ],
                    [
                        111.58884476021854,
                        373.26827061878737
                    ],
                    [
                        111.00428618221827,
                        375.35472129556433
                    ],
                    [
                        109.97006715960197,
                        375.58415278271366
                    ],
                    [
                        107.27210449190716,
                        373.9152075126327
                    ],
                    [
                        107.2271384474459,
                        372.43333029288044
                    ],
                    [
                        106.0355382692137,
                        370.9925710976522
                    ],
                    [
                        104.91138715767443,
                        370.3450717157444
                    ],
                    [
                        102.88791515690355,
                        369.9481551651429
                    ],
                    [
                        102.75301702351862,
                        368.04647633459444
                    ],
                    [
                        103.65233791275023,
                        365.6207687663519
                    ],
                    [
                        103.36005862375009,
                        363.4653985465424
                    ],
                    [
                        105.65332689129059,
                        362.96297073150197
                    ],
                    [
                        108.26135747006197,
                        363.3188651587028
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Haveri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Anantapur",
            "dt_code": "553",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        133.84703676869913,
                        362.48140410815665
                    ],
                    [
                        135.8480257472395,
                        362.62797546791217
                    ],
                    [
                        136.88224476985556,
                        361.22480443981067
                    ],
                    [
                        139.28792814855,
                        361.20385694886454
                    ],
                    [
                        140.0973169488584,
                        361.7484472996687
                    ],
                    [
                        141.31140014932112,
                        361.03627209596493
                    ],
                    [
                        142.54796637201434,
                        362.62797546791217
                    ],
                    [
                        143.53721935016893,
                        362.43952535355766
                    ],
                    [
                        146.4600122401714,
                        362.9001617658915
                    ],
                    [
                        146.75229152917177,
                        364.61650201577925
                    ],
                    [
                        148.2136879741729,
                        365.2023618579952
                    ],
                    [
                        146.93215570701796,
                        368.04647633459444
                    ],
                    [
                        148.23617099640364,
                        369.2586613078897
                    ],
                    [
                        146.25766504009448,
                        372.3915778487103
                    ],
                    [
                        147.8089935740188,
                        373.83173872879695
                    ],
                    [
                        150.43950717502116,
                        375.3755793407373
                    ],
                    [
                        152.44049615356107,
                        375.5007249349442
                    ],
                    [
                        152.2156659312534,
                        376.75192908967995
                    ],
                    [
                        153.0250547315618,
                        378.16940967485624
                    ],
                    [
                        153.09250379825403,
                        379.87795197527373
                    ],
                    [
                        151.94586966448378,
                        380.73190855846724
                    ],
                    [
                        150.25964299717475,
                        381.3982663583955
                    ],
                    [
                        149.74253348586672,
                        383.1468546060611
                    ],
                    [
                        148.10127286301918,
                        383.02198419484705
                    ],
                    [
                        147.9438917074035,
                        381.8771328332061
                    ],
                    [
                        146.25766504009448,
                        381.73139777365213
                    ],
                    [
                        145.80800459547868,
                        380.4195096506329
                    ],
                    [
                        143.51473632793818,
                        381.0859269238159
                    ],
                    [
                        143.04259286109186,
                        382.5432733933859
                    ],
                    [
                        140.6818755268589,
                        383.3133416305616
                    ],
                    [
                        138.2537091259337,
                        384.6033510946153
                    ],
                    [
                        138.45605632601087,
                        383.45901135338335
                    ],
                    [
                        137.91646379247186,
                        382.1685848352115
                    ],
                    [
                        136.34265223631655,
                        382.31430178688527
                    ],
                    [
                        134.20676512439172,
                        381.7730369787499
                    ],
                    [
                        133.86951979092987,
                        382.7306027416779
                    ],
                    [
                        132.49805543485195,
                        383.68790881622556
                    ],
                    [
                        131.21652316769678,
                        382.4183816336855
                    ],
                    [
                        132.1608101013901,
                        380.81521021936544
                    ],
                    [
                        130.5420325007733,
                        379.3363100909173
                    ],
                    [
                        130.78934574531195,
                        378.35697376036944
                    ],
                    [
                        130.0024399672343,
                        377.2522829787066
                    ],
                    [
                        131.59873454562035,
                        376.7936280296344
                    ],
                    [
                        131.95846290131294,
                        378.8570949621379
                    ],
                    [
                        134.09435001323777,
                        379.60714157403197
                    ],
                    [
                        136.27520316962455,
                        379.3363100909173
                    ],
                    [
                        137.39935428116382,
                        381.2108660373848
                    ],
                    [
                        137.9389468147026,
                        380.58612587672064
                    ],
                    [
                        137.28693917000987,
                        379.5446438732567
                    ],
                    [
                        137.33190521447136,
                        377.1480485892632
                    ],
                    [
                        138.95068281508816,
                        377.4190514364095
                    ],
                    [
                        138.59095445939533,
                        375.3755793407373
                    ],
                    [
                        137.55673543677926,
                        374.68719645370254
                    ],
                    [
                        136.9047277920863,
                        375.6258659424137
                    ],
                    [
                        133.57724050192974,
                        374.68719645370254
                    ],
                    [
                        133.26247819069863,
                        376.3766158297702
                    ],
                    [
                        131.6212175678511,
                        376.23064957898316
                    ],
                    [
                        129.84505881161886,
                        374.52029479042596
                    ],
                    [
                        130.0923720561575,
                        372.37070143247956
                    ],
                    [
                        128.6984246778486,
                        371.7443486907565
                    ],
                    [
                        128.09138307761737,
                        370.6583935357795
                    ],
                    [
                        128.2487642332328,
                        369.4676139383835
                    ],
                    [
                        129.14808512246418,
                        367.7956239753053
                    ],
                    [
                        129.5977455670802,
                        365.0768293271419
                    ],
                    [
                        128.02393401092513,
                        364.7839012913978
                    ],
                    [
                        128.6084925889254,
                        363.1513903062983
                    ],
                    [
                        131.32893827885096,
                        364.386313951856
                    ],
                    [
                        133.21751214623714,
                        364.0933237531512
                    ],
                    [
                        133.84703676869913,
                        362.48140410815665
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-Anantapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "S.P.S. Nellore",
            "dt_code": "550",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        179.59998700835354,
                        386.391946395858
                    ],
                    [
                        178.8130812302761,
                        387.51456233296904
                    ],
                    [
                        176.81209225173575,
                        387.5353482202108
                    ],
                    [
                        176.20505065150473,
                        386.7869808065768
                    ],
                    [
                        175.01345047327277,
                        384.2288804809424
                    ],
                    [
                        173.73191820611783,
                        384.2496854381628
                    ],
                    [
                        173.46212193934844,
                        383.29253118086547
                    ],
                    [
                        169.887321404653,
                        382.0228618521887
                    ],
                    [
                        169.6624911823451,
                        380.9609833538731
                    ],
                    [
                        167.50412104818952,
                        381.4399095143807
                    ],
                    [
                        167.3692229148046,
                        379.44047854274595
                    ],
                    [
                        167.6390191815742,
                        378.27361320071884
                    ],
                    [
                        167.34673989257385,
                        376.10553069945354
                    ],
                    [
                        165.25581882511074,
                        372.45420632084733
                    ],
                    [
                        164.5138790914948,
                        370.80460032866256
                    ],
                    [
                        163.72697331341692,
                        370.4495155894442
                    ],
                    [
                        163.70449029118618,
                        369.0079007943372
                    ],
                    [
                        162.15316175726184,
                        364.88852146953013
                    ],
                    [
                        162.2655768684158,
                        363.88402885690874
                    ],
                    [
                        162.8501354464163,
                        363.0885850013326
                    ],
                    [
                        164.78367535826396,
                        362.983906782112
                    ],
                    [
                        169.23531375996026,
                        363.94681874279684
                    ],
                    [
                        171.75341224980843,
                        364.1351811134498
                    ],
                    [
                        172.4054198945014,
                        364.9094451020452
                    ],
                    [
                        175.10338256219597,
                        364.28167762750155
                    ],
                    [
                        176.25001669596622,
                        364.47002058696734
                    ],
                    [
                        177.26175269635155,
                        368.5899244662831
                    ],
                    [
                        178.5432849635065,
                        370.90902983773424
                    ],
                    [
                        178.13859056335218,
                        374.4785680944054
                    ],
                    [
                        177.57651500758266,
                        376.3349118210916
                    ],
                    [
                        177.80134522989056,
                        379.1696340714409
                    ],
                    [
                        179.3976398082766,
                        383.08441995169403
                    ],
                    [
                        179.0828774970455,
                        384.99858291896464
                    ],
                    [
                        179.59998700835354,
                        386.391946395858
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-S.P.S. Nellore",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chitradurga",
            "dt_code": "566",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        124.35920138730648,
                        371.3058322548062
                    ],
                    [
                        125.19107320984563,
                        370.88814419678073
                    ],
                    [
                        126.31522432138513,
                        368.9870032294939
                    ],
                    [
                        127.32696032177046,
                        366.6665520047229
                    ],
                    [
                        126.80985081046265,
                        366.33193767646435
                    ],
                    [
                        128.02393401092513,
                        364.7839012913978
                    ],
                    [
                        129.5977455670802,
                        365.0768293271419
                    ],
                    [
                        129.14808512246418,
                        367.7956239753053
                    ],
                    [
                        128.2487642332328,
                        369.4676139383835
                    ],
                    [
                        128.09138307761737,
                        370.6583935357795
                    ],
                    [
                        128.6984246778486,
                        371.7443486907565
                    ],
                    [
                        130.0923720561575,
                        372.37070143247956
                    ],
                    [
                        129.84505881161886,
                        374.52029479042596
                    ],
                    [
                        131.6212175678511,
                        376.23064957898316
                    ],
                    [
                        131.59873454562035,
                        376.7936280296344
                    ],
                    [
                        130.0024399672343,
                        377.2522829787066
                    ],
                    [
                        130.78934574531195,
                        378.35697376036944
                    ],
                    [
                        129.91250787831132,
                        379.06545749629254
                    ],
                    [
                        129.35043232254156,
                        380.3570265166055
                    ],
                    [
                        127.46185845515538,
                        381.33580069748643
                    ],
                    [
                        128.09138307761737,
                        383.1052316256296
                    ],
                    [
                        125.97797898792305,
                        383.8335631440639
                    ],
                    [
                        123.52732956476734,
                        385.9968685322332
                    ],
                    [
                        122.04345009753524,
                        386.1632224084706
                    ],
                    [
                        120.85184991930373,
                        385.76811934599573
                    ],
                    [
                        120.06494414122585,
                        384.1456594343256
                    ],
                    [
                        119.03072511860978,
                        383.8543704170172
                    ],
                    [
                        118.28878538499362,
                        381.8563139089225
                    ],
                    [
                        117.27704938460806,
                        381.33580069748643
                    ],
                    [
                        117.50187960691596,
                        380.66943101066175
                    ],
                    [
                        117.74919285145461,
                        376.4183193310889
                    ],
                    [
                        118.82837791853262,
                        375.91784380565286
                    ],
                    [
                        119.1431402297635,
                        374.93753359814815
                    ],
                    [
                        118.85086094076337,
                        373.33088282073777
                    ],
                    [
                        119.90756298561064,
                        372.85082628288563
                    ],
                    [
                        120.9867480526882,
                        373.6647949924575
                    ],
                    [
                        123.59477863145958,
                        372.9551922051788
                    ],
                    [
                        124.35920138730648,
                        371.3058322548062
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Chitradurga",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Davanagere",
            "dt_code": "567",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        113.29755444975854,
                        368.652624276802
                    ],
                    [
                        114.2643244056826,
                        367.9837650314423
                    ],
                    [
                        113.92707907222075,
                        366.6038144169406
                    ],
                    [
                        116.35524547314571,
                        365.99728919706536
                    ],
                    [
                        118.73844582960942,
                        366.6038144169406
                    ],
                    [
                        119.54783462991759,
                        369.17507657493456
                    ],
                    [
                        121.75117080853511,
                        369.36313926581863
                    ],
                    [
                        123.07766912015154,
                        370.9716859782297
                    ],
                    [
                        124.35920138730648,
                        371.3058322548062
                    ],
                    [
                        123.59477863145958,
                        372.9551922051788
                    ],
                    [
                        120.9867480526882,
                        373.6647949924575
                    ],
                    [
                        119.90756298561064,
                        372.85082628288563
                    ],
                    [
                        118.85086094076337,
                        373.33088282073777
                    ],
                    [
                        119.1431402297635,
                        374.93753359814815
                    ],
                    [
                        118.82837791853262,
                        375.91784380565286
                    ],
                    [
                        117.74919285145461,
                        376.4183193310889
                    ],
                    [
                        117.50187960691596,
                        380.66943101066175
                    ],
                    [
                        117.27704938460806,
                        381.33580069748643
                    ],
                    [
                        116.15289827306879,
                        382.5849029970343
                    ],
                    [
                        115.05123018376003,
                        382.6681607305982
                    ],
                    [
                        113.99452813891321,
                        378.31529373170775
                    ],
                    [
                        111.02676920444901,
                        378.94044147781096
                    ],
                    [
                        109.36302555937073,
                        378.21109146213576
                    ],
                    [
                        108.01404422552332,
                        378.0235194598635
                    ],
                    [
                        109.97006715960197,
                        375.58415278271366
                    ],
                    [
                        111.00428618221827,
                        375.35472129556433
                    ],
                    [
                        111.58884476021854,
                        373.26827061878737
                    ],
                    [
                        112.28581844937298,
                        372.32894821165905
                    ],
                    [
                        113.85963000552829,
                        372.05753964757037
                    ],
                    [
                        114.1743923167594,
                        371.2222988112977
                    ],
                    [
                        113.88211302775903,
                        369.4676139383835
                    ],
                    [
                        113.29755444975854,
                        368.652624276802
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Davanagere",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shivamogga",
            "dt_code": "568",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        97.24467657697573,
                        380.00293428897646
                    ],
                    [
                        96.81749915459068,
                        378.35697376036944
                    ],
                    [
                        97.22219355474499,
                        377.00211513742425
                    ],
                    [
                        99.0882843999002,
                        376.3557638888576
                    ],
                    [
                        100.52719782267081,
                        376.5225758653962
                    ],
                    [
                        101.3815526674407,
                        373.74826788902874
                    ],
                    [
                        100.36981666705515,
                        373.18478587870777
                    ],
                    [
                        100.68457897828625,
                        371.7652289963702
                    ],
                    [
                        102.48322075674946,
                        371.1805313084078
                    ],
                    [
                        102.88791515690355,
                        369.9481551651429
                    ],
                    [
                        104.91138715767443,
                        370.3450717157444
                    ],
                    [
                        106.0355382692137,
                        370.9925710976522
                    ],
                    [
                        107.2271384474459,
                        372.43333029288044
                    ],
                    [
                        107.27210449190716,
                        373.9152075126327
                    ],
                    [
                        109.97006715960197,
                        375.58415278271366
                    ],
                    [
                        108.01404422552332,
                        378.0235194598635
                    ],
                    [
                        109.36302555937073,
                        378.21109146213576
                    ],
                    [
                        111.02676920444901,
                        378.94044147781096
                    ],
                    [
                        113.99452813891321,
                        378.31529373170775
                    ],
                    [
                        115.05123018376003,
                        382.6681607305982
                    ],
                    [
                        110.10496529298666,
                        383.91679150392315
                    ],
                    [
                        109.5878557816784,
                        383.3549621629173
                    ],
                    [
                        108.30632351452346,
                        384.2496854381628
                    ],
                    [
                        108.23887444783122,
                        385.06098393978095
                    ],
                    [
                        107.20465542521492,
                        386.14242859588455
                    ],
                    [
                        105.7432589802138,
                        385.76811934599573
                    ],
                    [
                        105.47346271344418,
                        386.95329810713923
                    ],
                    [
                        104.21441346851998,
                        387.95104085971775
                    ],
                    [
                        102.66308493459587,
                        386.3503613001676
                    ],
                    [
                        102.12349240105664,
                        383.7711405930612
                    ],
                    [
                        101.08927337844034,
                        383.29253118086547
                    ],
                    [
                        100.7969940894402,
                        381.960407297705
                    ],
                    [
                        99.78525808905488,
                        381.3566227080647
                    ],
                    [
                        97.89668422166847,
                        381.231688789925
                    ],
                    [
                        97.24467657697573,
                        380.00293428897646
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Shivamogga",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Udupi",
            "dt_code": "569",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        96.00811035428228,
                        380.9193345064175
                    ],
                    [
                        97.24467657697573,
                        380.00293428897646
                    ],
                    [
                        97.89668422166847,
                        381.231688789925
                    ],
                    [
                        99.78525808905488,
                        381.3566227080647
                    ],
                    [
                        100.7969940894402,
                        381.960407297705
                    ],
                    [
                        101.08927337844034,
                        383.29253118086547
                    ],
                    [
                        102.12349240105664,
                        383.7711405930612
                    ],
                    [
                        102.66308493459587,
                        386.3503613001676
                    ],
                    [
                        104.21441346851998,
                        387.95104085971775
                    ],
                    [
                        103.78723604613515,
                        389.53023834708625
                    ],
                    [
                        104.59662484644332,
                        391.85623239289635
                    ],
                    [
                        105.15870040221307,
                        392.25067471640716
                    ],
                    [
                        104.3493116019049,
                        393.039433161338
                    ],
                    [
                        102.0560433343644,
                        392.54128948551477
                    ],
                    [
                        100.3473336448244,
                        393.4752540092643
                    ],
                    [
                        99.58291088897772,
                        392.9564138647291
                    ],
                    [
                        98.86345417759253,
                        393.84877407428723
                    ],
                    [
                        97.98661631059167,
                        390.1741880685811
                    ],
                    [
                        97.289642621437,
                        385.3313757606395
                    ],
                    [
                        96.00811035428228,
                        380.9193345064175
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Udupi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chikkamagaluru",
            "dt_code": "570",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        117.27704938460806,
                        381.33580069748643
                    ],
                    [
                        118.28878538499362,
                        381.8563139089225
                    ],
                    [
                        119.03072511860978,
                        383.8543704170172
                    ],
                    [
                        120.06494414122585,
                        384.1456594343256
                    ],
                    [
                        120.85184991930373,
                        385.76811934599573
                    ],
                    [
                        122.04345009753524,
                        386.1632224084706
                    ],
                    [
                        122.20083125315091,
                        386.66223778672816
                    ],
                    [
                        120.24480831907226,
                        387.8471221802673
                    ],
                    [
                        119.23307231868671,
                        387.8263380861298
                    ],
                    [
                        118.89582698522509,
                        388.7822830051173
                    ],
                    [
                        117.45691356245447,
                        389.2601610889717
                    ],
                    [
                        117.05221916230039,
                        390.984157420909
                    ],
                    [
                        115.36599249499113,
                        390.90109187417045
                    ],
                    [
                        112.84789400514273,
                        392.1468781901623
                    ],
                    [
                        113.23010538306607,
                        392.87339270950633
                    ],
                    [
                        112.57809773837334,
                        394.3467425816175
                    ],
                    [
                        111.58884476021854,
                        394.4504776570268
                    ],
                    [
                        110.35227853752531,
                        396.1927975685663
                    ],
                    [
                        109.70027089283235,
                        395.2387702220977
                    ],
                    [
                        109.7227539150631,
                        393.9317734443283
                    ],
                    [
                        108.12645933667727,
                        393.22621978754245
                    ],
                    [
                        107.72176493652296,
                        392.2091564558442
                    ],
                    [
                        106.46271569159899,
                        393.039433161338
                    ],
                    [
                        105.15870040221307,
                        392.25067471640716
                    ],
                    [
                        104.59662484644332,
                        391.85623239289635
                    ],
                    [
                        103.78723604613515,
                        389.53023834708625
                    ],
                    [
                        104.21441346851998,
                        387.95104085971775
                    ],
                    [
                        105.47346271344418,
                        386.95329810713923
                    ],
                    [
                        105.7432589802138,
                        385.76811934599573
                    ],
                    [
                        107.20465542521492,
                        386.14242859588455
                    ],
                    [
                        108.23887444783122,
                        385.06098393978095
                    ],
                    [
                        108.30632351452346,
                        384.2496854381628
                    ],
                    [
                        109.5878557816784,
                        383.3549621629173
                    ],
                    [
                        110.10496529298666,
                        383.91679150392315
                    ],
                    [
                        115.05123018376003,
                        382.6681607305982
                    ],
                    [
                        116.15289827306879,
                        382.5849029970343
                    ],
                    [
                        117.27704938460806,
                        381.33580069748643
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Chikkamagaluru",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chikkaballapura",
            "dt_code": "582",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        138.2537091259337,
                        384.6033510946153
                    ],
                    [
                        140.6818755268589,
                        383.3133416305616
                    ],
                    [
                        143.04259286109186,
                        382.5432733933859
                    ],
                    [
                        143.51473632793818,
                        381.0859269238159
                    ],
                    [
                        145.80800459547868,
                        380.4195096506329
                    ],
                    [
                        146.25766504009448,
                        381.73139777365213
                    ],
                    [
                        147.9438917074035,
                        381.8771328332061
                    ],
                    [
                        148.10127286301918,
                        383.02198419484705
                    ],
                    [
                        148.03382379632671,
                        384.1040481801888
                    ],
                    [
                        149.2479069967892,
                        385.41456912690865
                    ],
                    [
                        149.13549188563525,
                        386.3295685717369
                    ],
                    [
                        149.3378390857124,
                        387.8055538725455
                    ],
                    [
                        148.23617099640364,
                        388.8030616191097
                    ],
                    [
                        148.2136879741729,
                        390.5688108824253
                    ],
                    [
                        145.67310646209376,
                        390.6311158616795
                    ],
                    [
                        145.29089508417064,
                        391.21257798954514
                    ],
                    [
                        143.44728726124595,
                        390.963391210393
                    ],
                    [
                        143.7170835280151,
                        389.9872466174437
                    ],
                    [
                        141.2214680603979,
                        388.9069529070325
                    ],
                    [
                        137.91646379247186,
                        387.90947374613154
                    ],
                    [
                        137.21949010331764,
                        387.16118393297256
                    ],
                    [
                        136.9047277920863,
                        385.41456912690865
                    ],
                    [
                        138.2537091259337,
                        384.6033510946153
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Chikkaballapura",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hassan",
            "dt_code": "574",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        110.35227853752531,
                        396.1927975685663
                    ],
                    [
                        111.58884476021854,
                        394.4504776570268
                    ],
                    [
                        112.57809773837334,
                        394.3467425816175
                    ],
                    [
                        113.23010538306607,
                        392.87339270950633
                    ],
                    [
                        112.84789400514273,
                        392.1468781901623
                    ],
                    [
                        115.36599249499113,
                        390.90109187417045
                    ],
                    [
                        117.05221916230039,
                        390.984157420909
                    ],
                    [
                        117.45691356245447,
                        389.2601610889717
                    ],
                    [
                        118.89582698522509,
                        388.7822830051173
                    ],
                    [
                        119.23307231868671,
                        387.8263380861298
                    ],
                    [
                        120.24480831907226,
                        387.8471221802673
                    ],
                    [
                        122.20083125315091,
                        386.66223778672816
                    ],
                    [
                        123.0102200534593,
                        388.13808696373474
                    ],
                    [
                        121.95351800861204,
                        389.4886893023142
                    ],
                    [
                        122.29076334207389,
                        391.8977546226213
                    ],
                    [
                        123.79712583153673,
                        392.6658316844481
                    ],
                    [
                        123.79712583153673,
                        393.51675809061504
                    ],
                    [
                        125.73066574338463,
                        393.84877407428723
                    ],
                    [
                        126.13536014353872,
                        394.86538917260566
                    ],
                    [
                        125.34845436546107,
                        397.229510014879
                    ],
                    [
                        124.0444390760756,
                        397.8306731807577
                    ],
                    [
                        122.51559356438179,
                        397.25024136555646
                    ],
                    [
                        121.97600103084301,
                        398.1623086882274
                    ],
                    [
                        121.8860689419198,
                        400.11008563373235
                    ],
                    [
                        121.34647640838102,
                        400.7936452223589
                    ],
                    [
                        120.06494414122585,
                        400.2758081925457
                    ],
                    [
                        118.51361560730152,
                        402.0568763621168
                    ],
                    [
                        117.11966822899262,
                        401.3942491365217
                    ],
                    [
                        115.90558502852991,
                        400.5036650596727
                    ],
                    [
                        116.3327624509152,
                        399.28136555644403
                    ],
                    [
                        115.07371320599077,
                        397.41608808866306
                    ],
                    [
                        114.3992225390673,
                        399.88220545040457
                    ],
                    [
                        111.94857311591136,
                        399.4885623637248
                    ],
                    [
                        111.18415036006468,
                        397.9757672734718
                    ],
                    [
                        111.27408244898766,
                        397.10511952808054
                    ],
                    [
                        110.35227853752531,
                        396.1927975685663
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Hassan",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kolar",
            "dt_code": "581",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        149.6526013969435,
                        398.53536410504523
                    ],
                    [
                        148.2136879741729,
                        398.203761094275
                    ],
                    [
                        147.08953686263362,
                        397.1465834776219
                    ],
                    [
                        145.94290272886337,
                        397.3953576450434
                    ],
                    [
                        145.20096299524744,
                        396.56604682416724
                    ],
                    [
                        144.2791590837851,
                        396.87705968605474
                    ],
                    [
                        144.25667606155434,
                        395.3217387387822
                    ],
                    [
                        144.79626859509312,
                        393.371491778591
                    ],
                    [
                        145.60565739540152,
                        392.9564138647291
                    ],
                    [
                        145.29089508417064,
                        391.21257798954514
                    ],
                    [
                        145.67310646209376,
                        390.6311158616795
                    ],
                    [
                        148.2136879741729,
                        390.5688108824253
                    ],
                    [
                        148.23617099640364,
                        388.8030616191097
                    ],
                    [
                        149.3378390857124,
                        387.8055538725455
                    ],
                    [
                        149.13549188563525,
                        386.3295685717369
                    ],
                    [
                        151.0465487752524,
                        385.9344838387466
                    ],
                    [
                        152.14821686456094,
                        386.0384577247579
                    ],
                    [
                        151.74352246440685,
                        387.2235473425733
                    ],
                    [
                        151.60862433102193,
                        389.4471397840182
                    ],
                    [
                        152.77774148702315,
                        390.2572700909077
                    ],
                    [
                        154.8686625544865,
                        390.75572264400955
                    ],
                    [
                        153.81196050963945,
                        393.9940217586318
                    ],
                    [
                        153.114986820485,
                        395.30099678162685
                    ],
                    [
                        151.47372619763723,
                        395.81951134430466
                    ],
                    [
                        150.84420157517548,
                        397.0429227523314
                    ],
                    [
                        149.9673637081744,
                        397.0221902666455
                    ],
                    [
                        149.6526013969435,
                        398.53536410504523
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Kolar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bengaluru Rural",
            "dt_code": "583",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        144.2791590837851,
                        396.87705968605474
                    ],
                    [
                        143.76204957247683,
                        396.9392591880378
                    ],
                    [
                        142.79527961655276,
                        395.77803281180525
                    ],
                    [
                        143.0201098388609,
                        394.40898397258684
                    ],
                    [
                        142.21072103855272,
                        392.00155815247297
                    ],
                    [
                        139.5577244153194,
                        391.42022073733193
                    ],
                    [
                        138.61343748162653,
                        391.5448007622043
                    ],
                    [
                        137.8490147257794,
                        393.620516267623
                    ],
                    [
                        136.41010130300901,
                        395.30099678162685
                    ],
                    [
                        136.16278805847037,
                        393.3092330495115
                    ],
                    [
                        134.54401045785357,
                        392.12611853492547
                    ],
                    [
                        134.2292481466227,
                        391.46174788072705
                    ],
                    [
                        134.90373881354617,
                        390.19495875104235
                    ],
                    [
                        136.52251641416296,
                        389.94570277440937
                    ],
                    [
                        136.67989756977863,
                        388.01339063487865
                    ],
                    [
                        137.21949010331764,
                        387.16118393297256
                    ],
                    [
                        137.91646379247186,
                        387.90947374613154
                    ],
                    [
                        141.2214680603979,
                        388.9069529070325
                    ],
                    [
                        143.7170835280151,
                        389.9872466174437
                    ],
                    [
                        143.44728726124595,
                        390.963391210393
                    ],
                    [
                        145.29089508417064,
                        391.21257798954514
                    ],
                    [
                        145.60565739540152,
                        392.9564138647291
                    ],
                    [
                        144.79626859509312,
                        393.371491778591
                    ],
                    [
                        144.25667606155434,
                        395.3217387387822
                    ],
                    [
                        144.2791590837851,
                        396.87705968605474
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Bengaluru Rural",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dakshina Kannada",
            "dt_code": "575",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        98.86345417759253,
                        393.84877407428723
                    ],
                    [
                        99.58291088897772,
                        392.9564138647291
                    ],
                    [
                        100.3473336448244,
                        393.4752540092643
                    ],
                    [
                        102.0560433343644,
                        392.54128948551477
                    ],
                    [
                        104.3493116019049,
                        393.039433161338
                    ],
                    [
                        105.15870040221307,
                        392.25067471640716
                    ],
                    [
                        106.46271569159899,
                        393.039433161338
                    ],
                    [
                        107.72176493652296,
                        392.2091564558442
                    ],
                    [
                        108.12645933667727,
                        393.22621978754245
                    ],
                    [
                        109.7227539150631,
                        393.9317734443283
                    ],
                    [
                        109.70027089283235,
                        395.2387702220977
                    ],
                    [
                        110.35227853752531,
                        396.1927975685663
                    ],
                    [
                        111.27408244898766,
                        397.10511952808054
                    ],
                    [
                        111.18415036006468,
                        397.9757672734718
                    ],
                    [
                        111.94857311591136,
                        399.4885623637248
                    ],
                    [
                        111.58884476021854,
                        400.46223754116363
                    ],
                    [
                        111.99353916037285,
                        401.6841624831921
                    ],
                    [
                        111.18415036006468,
                        402.30533240816715
                    ],
                    [
                        109.38550858160147,
                        402.6986886959194
                    ],
                    [
                        108.26135747006197,
                        402.4709609529235
                    ],
                    [
                        106.26036849152183,
                        402.0982868063205
                    ],
                    [
                        103.78723604613515,
                        399.73718374865393
                    ],
                    [
                        101.87617915651799,
                        398.8669382871243
                    ],
                    [
                        100.21243551143971,
                        398.5560883354567
                    ],
                    [
                        99.537944844516,
                        397.229510014879
                    ],
                    [
                        98.86345417759253,
                        393.84877407428723
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Dakshina Kannada",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bengaluru Urban",
            "dt_code": "572",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        143.76204957247683,
                        396.9392591880378
                    ],
                    [
                        143.19997401670707,
                        398.70115479630374
                    ],
                    [
                        142.41306823862965,
                        399.8614883998184
                    ],
                    [
                        140.38959623785877,
                        399.92363921631704
                    ],
                    [
                        140.5919434379357,
                        399.3020857415794
                    ],
                    [
                        139.53524139308888,
                        398.30739013522896
                    ],
                    [
                        137.7141165923947,
                        397.54046837106057
                    ],
                    [
                        136.58996548085543,
                        396.6075166850045
                    ],
                    [
                        136.41010130300901,
                        395.30099678162685
                    ],
                    [
                        137.8490147257794,
                        393.620516267623
                    ],
                    [
                        138.61343748162653,
                        391.5448007622043
                    ],
                    [
                        139.5577244153194,
                        391.42022073733193
                    ],
                    [
                        142.21072103855272,
                        392.00155815247297
                    ],
                    [
                        143.0201098388609,
                        394.40898397258684
                    ],
                    [
                        142.79527961655276,
                        395.77803281180525
                    ],
                    [
                        143.76204957247683,
                        396.9392591880378
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Bengaluru Urban",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kasaragod",
            "dt_code": "588",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        100.21243551143971,
                        398.5560883354567
                    ],
                    [
                        101.87617915651799,
                        398.8669382871243
                    ],
                    [
                        103.78723604613515,
                        399.73718374865393
                    ],
                    [
                        106.26036849152183,
                        402.0982868063205
                    ],
                    [
                        108.26135747006197,
                        402.4709609529235
                    ],
                    [
                        107.51941773644603,
                        403.7750399987695
                    ],
                    [
                        108.35128955898517,
                        404.37518435632614
                    ],
                    [
                        108.23887444783122,
                        405.6165718922423
                    ],
                    [
                        105.13621737998233,
                        407.1057233352127
                    ],
                    [
                        104.57414182421257,
                        408.63566107557654
                    ],
                    [
                        102.75301702351862,
                        404.126859887723
                    ],
                    [
                        102.37080564559551,
                        403.69225426042715
                    ],
                    [
                        100.21243551143971,
                        398.5560883354567
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Kasaragod",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kodagu",
            "dt_code": "576",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        111.94857311591136,
                        399.4885623637248
                    ],
                    [
                        114.3992225390673,
                        399.88220545040457
                    ],
                    [
                        115.07371320599077,
                        397.41608808866306
                    ],
                    [
                        116.3327624509152,
                        399.28136555644403
                    ],
                    [
                        115.90558502852991,
                        400.5036650596727
                    ],
                    [
                        117.11966822899262,
                        401.3942491365217
                    ],
                    [
                        115.50089062837583,
                        403.9819966958238
                    ],
                    [
                        116.87235498445398,
                        405.01661669624195
                    ],
                    [
                        117.20960031791583,
                        406.2371195046828
                    ],
                    [
                        118.80589489630188,
                        407.1057233352127
                    ],
                    [
                        119.6377667188408,
                        409.23507232161984
                    ],
                    [
                        118.51361560730152,
                        410.28899358864646
                    ],
                    [
                        116.89483800668472,
                        411.01211446817126
                    ],
                    [
                        115.90558502852991,
                        410.8675005746163
                    ],
                    [
                        114.9388150726063,
                        410.7022212632785
                    ],
                    [
                        113.81466396106657,
                        409.1317319152436
                    ],
                    [
                        111.49891267129556,
                        407.7467143413026
                    ],
                    [
                        110.6220748042947,
                        407.62265958732576
                    ],
                    [
                        109.27309347044752,
                        405.6165718922423
                    ],
                    [
                        108.23887444783122,
                        405.6165718922423
                    ],
                    [
                        108.35128955898517,
                        404.37518435632614
                    ],
                    [
                        107.51941773644603,
                        403.7750399987695
                    ],
                    [
                        108.26135747006197,
                        402.4709609529235
                    ],
                    [
                        109.38550858160147,
                        402.6986886959194
                    ],
                    [
                        111.18415036006468,
                        402.30533240816715
                    ],
                    [
                        111.99353916037285,
                        401.6841624831921
                    ],
                    [
                        111.58884476021854,
                        400.46223754116363
                    ],
                    [
                        111.94857311591136,
                        399.4885623637248
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Kodagu",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chamarajanagara",
            "dt_code": "578",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        142.5254833497836,
                        407.64333564675314
                    ],
                    [
                        142.95266077216843,
                        408.28424055267146
                    ],
                    [
                        141.96340779401385,
                        409.44174519680416
                    ],
                    [
                        141.4912643271673,
                        410.7022212632785
                    ],
                    [
                        138.81578468170324,
                        410.82618137602833
                    ],
                    [
                        137.8490147257794,
                        413.46976904236044
                    ],
                    [
                        135.8480257472395,
                        412.850330889533
                    ],
                    [
                        133.17254610177565,
                        413.3871826627191
                    ],
                    [
                        131.73363267900527,
                        412.767732099406
                    ],
                    [
                        130.20478716731145,
                        413.0568206154486
                    ],
                    [
                        129.12560210023344,
                        415.16242712180235
                    ],
                    [
                        129.30546627807985,
                        416.0703990837003
                    ],
                    [
                        127.97896796646341,
                        415.7196151065575
                    ],
                    [
                        125.07865809869168,
                        415.65770899972654
                    ],
                    [
                        124.35920138730648,
                        414.4193939349537
                    ],
                    [
                        122.98773703122833,
                        414.91476413112946
                    ],
                    [
                        122.9202879645361,
                        413.6142912339898
                    ],
                    [
                        125.32597134323032,
                        411.1360651490263
                    ],
                    [
                        125.34845436546107,
                        410.1650170858313
                    ],
                    [
                        128.51856050000242,
                        410.1856800994047
                    ],
                    [
                        130.29471925623466,
                        409.5864099179596
                    ],
                    [
                        131.756115701236,
                        407.5192776868852
                    ],
                    [
                        133.35241027962184,
                        407.0230073228573
                    ],
                    [
                        133.15006307954468,
                        406.36121737979386
                    ],
                    [
                        134.0044179243148,
                        405.30626161317605
                    ],
                    [
                        136.34265223631655,
                        405.34763772436156
                    ],
                    [
                        137.87149774801014,
                        405.6579447568983
                    ],
                    [
                        138.4785393482416,
                        406.3198518522173
                    ],
                    [
                        138.9731658373189,
                        407.0023280516838
                    ],
                    [
                        140.52449437124324,
                        406.8782501717279
                    ],
                    [
                        142.5254833497836,
                        407.64333564675314
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Chamarajanagara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Wayanad",
            "dt_code": "590",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        122.98773703122833,
                        414.91476413112946
                    ],
                    [
                        122.40317845322784,
                        415.9672303758259
                    ],
                    [
                        120.28977436353375,
                        417.0813182858652
                    ],
                    [
                        118.94079302968657,
                        418.23635782625524
                    ],
                    [
                        117.38946449576201,
                        417.45261541003003
                    ],
                    [
                        115.50089062837583,
                        415.98786432062974
                    ],
                    [
                        115.36599249499113,
                        414.8528460826368
                    ],
                    [
                        113.90459604998978,
                        413.6968730747743
                    ],
                    [
                        113.81466396106657,
                        413.1394136082257
                    ],
                    [
                        114.0394941833747,
                        412.1481882596728
                    ],
                    [
                        115.77068689514522,
                        412.3547132585145
                    ],
                    [
                        115.90558502852991,
                        410.8675005746163
                    ],
                    [
                        116.89483800668472,
                        411.01211446817126
                    ],
                    [
                        118.51361560730152,
                        410.28899358864646
                    ],
                    [
                        118.51361560730152,
                        412.10688201197706
                    ],
                    [
                        119.88507996337967,
                        412.0655753480642
                    ],
                    [
                        121.52634058622698,
                        413.71751827729065
                    ],
                    [
                        122.9202879645361,
                        413.6142912339898
                    ],
                    [
                        122.98773703122833,
                        414.91476413112946
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Wayanad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kozhikode",
            "dt_code": "591",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        113.81466396106657,
                        413.1394136082257
                    ],
                    [
                        113.90459604998978,
                        413.6968730747743
                    ],
                    [
                        115.36599249499113,
                        414.8528460826368
                    ],
                    [
                        115.50089062837583,
                        415.98786432062974
                    ],
                    [
                        117.38946449576201,
                        417.45261541003003
                    ],
                    [
                        118.94079302968657,
                        418.23635782625524
                    ],
                    [
                        118.06395516268594,
                        420.38059909302217
                    ],
                    [
                        114.73646787252892,
                        421.9262622923395
                    ],
                    [
                        114.28680742791335,
                        423.1417966200068
                    ],
                    [
                        112.39823356052693,
                        418.60755367724744
                    ],
                    [
                        111.22911640452617,
                        417.8238799029757
                    ],
                    [
                        110.28482947083307,
                        414.46067703953213
                    ],
                    [
                        111.92609009368039,
                        413.6142912339898
                    ],
                    [
                        113.81466396106657,
                        413.1394136082257
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Kozhikode",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Malappuram",
            "dt_code": "592",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        117.86160796260879,
                        429.02930119591394
                    ],
                    [
                        116.22034733976125,
                        429.6875615886563
                    ],
                    [
                        115.52337365060657,
                        428.08288397510495
                    ],
                    [
                        114.28680742791335,
                        423.1417966200068
                    ],
                    [
                        114.73646787252892,
                        421.9262622923395
                    ],
                    [
                        118.06395516268594,
                        420.38059909302217
                    ],
                    [
                        118.94079302968657,
                        418.23635782625524
                    ],
                    [
                        120.28977436353375,
                        417.0813182858652
                    ],
                    [
                        123.61726165369055,
                        419.1642867307584
                    ],
                    [
                        124.89879392084549,
                        419.5972510926923
                    ],
                    [
                        124.51658254292192,
                        420.9371016437707
                    ],
                    [
                        123.34746538692093,
                        421.9674724130309
                    ],
                    [
                        122.85283889784364,
                        423.20359439066146
                    ],
                    [
                        121.90855196415055,
                        423.6567513514839
                    ],
                    [
                        121.54882360845772,
                        425.94242204510044
                    ],
                    [
                        120.26729134130301,
                        426.7040500973327
                    ],
                    [
                        119.34548742984066,
                        426.2717907126719
                    ],
                    [
                        117.50187960691596,
                        427.25975063912875
                    ],
                    [
                        117.86160796260879,
                        429.02930119591394
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Malappuram",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Palakkad",
            "dt_code": "593",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        123.34746538692093,
                        421.9674724130309
                    ],
                    [
                        126.09039409907723,
                        422.07049600164925
                    ],
                    [
                        127.23702823284748,
                        421.3904950394153
                    ],
                    [
                        127.05716405500107,
                        422.4825659118913
                    ],
                    [
                        127.93400192200193,
                        423.80092781975355
                    ],
                    [
                        126.49508849923131,
                        425.9012492447812
                    ],
                    [
                        129.05815303354143,
                        426.9304549575541
                    ],
                    [
                        130.18230414508048,
                        428.2474925455808
                    ],
                    [
                        129.17056814469515,
                        431.0243598654458
                    ],
                    [
                        128.8333228112333,
                        433.61458431250026
                    ],
                    [
                        129.1031190780027,
                        434.86806293483295
                    ],
                    [
                        126.00046201015402,
                        434.2721887763086
                    ],
                    [
                        125.50583552107673,
                        433.42961633891395
                    ],
                    [
                        123.8196088537677,
                        432.6691150561056
                    ],
                    [
                        122.9202879645361,
                        431.0449229378306
                    ],
                    [
                        123.7296767648445,
                        428.70013510849867
                    ],
                    [
                        122.04345009753524,
                        428.84414822139473
                    ],
                    [
                        119.86259694114892,
                        428.45324479795045
                    ],
                    [
                        119.23307231868671,
                        429.3790139287061
                    ],
                    [
                        117.86160796260879,
                        429.02930119591394
                    ],
                    [
                        117.50187960691596,
                        427.25975063912875
                    ],
                    [
                        119.34548742984066,
                        426.2717907126719
                    ],
                    [
                        120.26729134130301,
                        426.7040500973327
                    ],
                    [
                        121.54882360845772,
                        425.94242204510044
                    ],
                    [
                        121.90855196415055,
                        423.6567513514839
                    ],
                    [
                        122.85283889784364,
                        423.20359439066146
                    ],
                    [
                        123.34746538692093,
                        421.9674724130309
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Palakkad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Thrissur",
            "dt_code": "594",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        129.1031190780027,
                        434.86806293483295
                    ],
                    [
                        129.9574739227728,
                        436.0595846408702
                    ],
                    [
                        128.7433907223101,
                        435.6692917364413
                    ],
                    [
                        127.07964707723181,
                        435.9979615886606
                    ],
                    [
                        122.94277098676685,
                        435.54603464105384
                    ],
                    [
                        121.43640849730377,
                        437.23027047370135
                    ],
                    [
                        119.99749507453339,
                        437.04544451606046
                    ],
                    [
                        119.23307231868671,
                        437.18919865842923
                    ],
                    [
                        118.3337514294551,
                        434.23109121079204
                    ],
                    [
                        116.22034733976125,
                        429.6875615886563
                    ],
                    [
                        117.86160796260879,
                        429.02930119591394
                    ],
                    [
                        119.23307231868671,
                        429.3790139287061
                    ],
                    [
                        119.86259694114892,
                        428.45324479795045
                    ],
                    [
                        122.04345009753524,
                        428.84414822139473
                    ],
                    [
                        123.7296767648445,
                        428.70013510849867
                    ],
                    [
                        122.9202879645361,
                        431.0449229378306
                    ],
                    [
                        123.8196088537677,
                        432.6691150561056
                    ],
                    [
                        125.50583552107673,
                        433.42961633891395
                    ],
                    [
                        126.00046201015402,
                        434.2721887763086
                    ],
                    [
                        129.1031190780027,
                        434.86806293483295
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Thrissur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Idukki",
            "dt_code": "596",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        132.2957082347748,
                        436.01850269529353
                    ],
                    [
                        132.8802668127753,
                        435.2378777966299
                    ],
                    [
                        134.20676512439172,
                        434.60095630486563
                    ],
                    [
                        135.78057668054703,
                        436.4703844873533
                    ],
                    [
                        135.48829739154667,
                        438.1132290534713
                    ],
                    [
                        135.4208483248542,
                        439.5092041166811
                    ],
                    [
                        134.67890859123827,
                        441.7665481254312
                    ],
                    [
                        135.1735350803158,
                        442.93585372885093
                    ],
                    [
                        134.0044179243148,
                        445.72466462143973
                    ],
                    [
                        135.5107804137774,
                        446.31913716483075
                    ],
                    [
                        136.23023712516283,
                        445.8681643967666
                    ],
                    [
                        137.46680334785606,
                        447.01601616677704
                    ],
                    [
                        136.5449994363937,
                        448.59389026813096
                    ],
                    [
                        135.69064459162382,
                        450.3965831739276
                    ],
                    [
                        134.63394254677678,
                        448.16360916305564
                    ],
                    [
                        133.82455374646838,
                        447.83575172771293
                    ],
                    [
                        132.45308939039046,
                        448.18409955676987
                    ],
                    [
                        131.14907410100454,
                        447.8972265946668
                    ],
                    [
                        129.8675418338496,
                        446.8110616784283
                    ],
                    [
                        129.89002485608057,
                        444.16640341819294
                    ],
                    [
                        128.72090770007935,
                        443.09994464225474
                    ],
                    [
                        126.09039409907723,
                        442.1153174315
                    ],
                    [
                        128.36117934438676,
                        439.4065726571332
                    ],
                    [
                        129.57526254484947,
                        438.5238519934294
                    ],
                    [
                        130.47458343408084,
                        437.2713419350097
                    ],
                    [
                        132.63295356823664,
                        437.29187753295884
                    ],
                    [
                        132.2957082347748,
                        436.01850269529353
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Idukki",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ernakulam",
            "dt_code": "595",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        132.2957082347748,
                        436.01850269529353
                    ],
                    [
                        132.63295356823664,
                        437.29187753295884
                    ],
                    [
                        130.47458343408084,
                        437.2713419350097
                    ],
                    [
                        129.57526254484947,
                        438.5238519934294
                    ],
                    [
                        128.36117934438676,
                        439.4065726571332
                    ],
                    [
                        126.09039409907723,
                        442.1153174315
                    ],
                    [
                        125.37093738769204,
                        442.6076555752021
                    ],
                    [
                        123.57229560922883,
                        442.21789193374724
                    ],
                    [
                        122.33572938653538,
                        442.3820066950941
                    ],
                    [
                        121.2115782749961,
                        441.6024128253282
                    ],
                    [
                        120.96426503045745,
                        443.0794335753683
                    ],
                    [
                        119.30052138537894,
                        437.23027047370135
                    ],
                    [
                        119.99749507453339,
                        437.04544451606046
                    ],
                    [
                        121.43640849730377,
                        437.23027047370135
                    ],
                    [
                        122.94277098676685,
                        435.54603464105384
                    ],
                    [
                        127.07964707723181,
                        435.9979615886606
                    ],
                    [
                        128.7433907223101,
                        435.6692917364413
                    ],
                    [
                        129.9574739227728,
                        436.0595846408702
                    ],
                    [
                        130.04740601169578,
                        436.1417474616197
                    ],
                    [
                        130.06988903392653,
                        436.16228794387985
                    ],
                    [
                        131.28397223438924,
                        436.6552328033882
                    ],
                    [
                        132.2957082347748,
                        436.01850269529353
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Ernakulam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Alappuzha",
            "dt_code": "598",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        120.96426503045745,
                        443.0794335753683
                    ],
                    [
                        121.2115782749961,
                        441.6024128253282
                    ],
                    [
                        122.33572938653538,
                        442.3820066950941
                    ],
                    [
                        122.605525653305,
                        446.6880850194142
                    ],
                    [
                        123.70719374261375,
                        446.48311730240596
                    ],
                    [
                        124.40416743176797,
                        448.8807243493329
                    ],
                    [
                        124.44913347622969,
                        449.59773984032006
                    ],
                    [
                        126.27025827692341,
                        449.78209920313014
                    ],
                    [
                        126.9897149883086,
                        452.21912748406686
                    ],
                    [
                        126.15784316576946,
                        452.7719095069457
                    ],
                    [
                        125.05617507646093,
                        453.22228188329836
                    ],
                    [
                        123.88705792045994,
                        453.01757189980543
                    ],
                    [
                        121.97600103084301,
                        449.08559606784866
                    ],
                    [
                        121.54882360845772,
                        447.65132269649564
                    ],
                    [
                        120.96426503045745,
                        443.0794335753683
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Alappuzha",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kottayam",
            "dt_code": "597",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        126.09039409907723,
                        442.1153174315
                    ],
                    [
                        128.72090770007935,
                        443.09994464225474
                    ],
                    [
                        129.89002485608057,
                        444.16640341819294
                    ],
                    [
                        129.8675418338496,
                        446.8110616784283
                    ],
                    [
                        131.14907410100454,
                        447.8972265946668
                    ],
                    [
                        130.42961738961935,
                        448.65535605864795
                    ],
                    [
                        129.39539836700305,
                        448.040665082126
                    ],
                    [
                        126.29274129915439,
                        447.7127997789637
                    ],
                    [
                        124.40416743176797,
                        448.8807243493329
                    ],
                    [
                        123.70719374261375,
                        446.48311730240596
                    ],
                    [
                        122.605525653305,
                        446.6880850194142
                    ],
                    [
                        122.33572938653538,
                        442.3820066950941
                    ],
                    [
                        123.57229560922883,
                        442.21789193374724
                    ],
                    [
                        125.37093738769204,
                        442.6076555752021
                    ],
                    [
                        126.09039409907723,
                        442.1153174315
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Kottayam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pathanamthitta",
            "dt_code": "599",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        135.69064459162382,
                        450.3965831739276
                    ],
                    [
                        135.48829739154667,
                        452.5671821544626
                    ],
                    [
                        134.54401045785357,
                        453.7954279807409
                    ],
                    [
                        133.12758005731393,
                        453.44745376980063
                    ],
                    [
                        130.51954947854256,
                        452.2600762815573
                    ],
                    [
                        129.21553418915664,
                        453.4679234693607
                    ],
                    [
                        127.01219801053958,
                        453.50886263268467
                    ],
                    [
                        126.15784316576946,
                        452.7719095069457
                    ],
                    [
                        126.9897149883086,
                        452.21912748406686
                    ],
                    [
                        126.27025827692341,
                        449.78209920313014
                    ],
                    [
                        124.44913347622969,
                        449.59773984032006
                    ],
                    [
                        124.40416743176797,
                        448.8807243493329
                    ],
                    [
                        126.29274129915439,
                        447.7127997789637
                    ],
                    [
                        129.39539836700305,
                        448.040665082126
                    ],
                    [
                        130.42961738961935,
                        448.65535605864795
                    ],
                    [
                        131.14907410100454,
                        447.8972265946668
                    ],
                    [
                        132.45308939039046,
                        448.18409955676987
                    ],
                    [
                        133.82455374646838,
                        447.83575172771293
                    ],
                    [
                        134.63394254677678,
                        448.16360916305564
                    ],
                    [
                        135.69064459162382,
                        450.3965831739276
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Pathanamthitta",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kollam",
            "dt_code": "600",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        134.54401045785357,
                        453.7954279807409
                    ],
                    [
                        133.73462165754518,
                        454.69596204523714
                    ],
                    [
                        134.38662930223813,
                        455.9441806029515
                    ],
                    [
                        135.30843321370048,
                        456.6397842739358
                    ],
                    [
                        134.47656139116134,
                        458.2556984029617
                    ],
                    [
                        132.2957082347748,
                        457.1307452280922
                    ],
                    [
                        131.91349685685122,
                        458.19434324471496
                    ],
                    [
                        130.5869985452348,
                        458.419308827932
                    ],
                    [
                        128.8333228112333,
                        456.9466400357221
                    ],
                    [
                        128.02393401092513,
                        457.94891579112834
                    ],
                    [
                        126.87729987715466,
                        458.1738913738211
                    ],
                    [
                        124.89879392084549,
                        456.29199354985946
                    ],
                    [
                        123.88705792045994,
                        453.01757189980543
                    ],
                    [
                        125.05617507646093,
                        453.22228188329836
                    ],
                    [
                        126.15784316576946,
                        452.7719095069457
                    ],
                    [
                        127.01219801053958,
                        453.50886263268467
                    ],
                    [
                        129.21553418915664,
                        453.4679234693607
                    ],
                    [
                        130.51954947854256,
                        452.2600762815573
                    ],
                    [
                        133.12758005731393,
                        453.44745376980063
                    ],
                    [
                        134.54401045785357,
                        453.7954279807409
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Kollam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Thiruvananthapuram",
            "dt_code": "601",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        132.99268192392924,
                        465.36835931896246
                    ],
                    [
                        130.9467269009274,
                        463.4479976670746
                    ],
                    [
                        127.12461312169353,
                        458.7260653366865
                    ],
                    [
                        126.87729987715466,
                        458.1738913738211
                    ],
                    [
                        128.02393401092513,
                        457.94891579112834
                    ],
                    [
                        128.8333228112333,
                        456.9466400357221
                    ],
                    [
                        130.5869985452348,
                        458.419308827932
                    ],
                    [
                        131.91349685685122,
                        458.19434324471496
                    ],
                    [
                        132.2957082347748,
                        457.1307452280922
                    ],
                    [
                        134.47656139116134,
                        458.2556984029617
                    ],
                    [
                        134.04938396877628,
                        458.685165450309
                    ],
                    [
                        135.5107804137774,
                        461.1181868946985
                    ],
                    [
                        135.3983653026237,
                        462.18102576606793
                    ],
                    [
                        132.99268192392924,
                        465.36835931896246
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Thiruvananthapuram",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Godavari",
            "dt_code": "546",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        198.48572568221584,
                        344.04278401304305
                    ],
                    [
                        196.7994990149066,
                        343.2422459339323
                    ],
                    [
                        195.4055516365977,
                        341.239959090874
                    ],
                    [
                        195.20320443652076,
                        338.7932228678196
                    ],
                    [
                        193.85422310267336,
                        340.2910352457546
                    ],
                    [
                        193.13476639128817,
                        339.82700587747627
                    ],
                    [
                        190.61666790144,
                        340.7549916365816
                    ],
                    [
                        189.96466025674727,
                        339.76372348279176
                    ],
                    [
                        190.3019055902089,
                        336.66121227072443
                    ],
                    [
                        189.4025847009775,
                        334.654429310106
                    ],
                    [
                        189.73983003443914,
                        333.2382808573715
                    ],
                    [
                        188.54822985620763,
                        332.94228116988154
                    ],
                    [
                        188.59319590066912,
                        331.5464421368405
                    ],
                    [
                        189.49251678990072,
                        330.6578257081953
                    ],
                    [
                        190.27942256797814,
                        331.081010939613
                    ],
                    [
                        192.90993616898027,
                        330.46737182676316
                    ],
                    [
                        193.1572494135189,
                        329.0279739105419
                    ],
                    [
                        194.3938156362126,
                        329.13383689590773
                    ],
                    [
                        194.91092514752063,
                        327.71494317432547
                    ],
                    [
                        190.66163394590149,
                        326.019804223012
                    ],
                    [
                        189.04285634528492,
                        324.19638368209445
                    ],
                    [
                        190.45928674582433,
                        324.069123745662
                    ],
                    [
                        192.3703436354415,
                        325.0658375148239
                    ],
                    [
                        194.101536347212,
                        325.32026049993493
                    ],
                    [
                        194.97837421421264,
                        326.5072615784559
                    ],
                    [
                        196.349838570291,
                        326.86750166528935
                    ],
                    [
                        197.72130292636894,
                        326.6132193481567
                    ],
                    [
                        198.64310683783128,
                        327.2488819601609
                    ],
                    [
                        199.4075295936782,
                        328.9221069634199
                    ],
                    [
                        200.3518165273715,
                        329.3667215272988
                    ],
                    [
                        199.7672579493708,
                        330.82710734708195
                    ],
                    [
                        201.31858648329535,
                        334.569902658324
                    ],
                    [
                        201.00382417206424,
                        335.330553543842
                    ],
                    [
                        201.2961034610646,
                        337.18908267667496
                    ],
                    [
                        202.98233012837363,
                        340.1855804393047
                    ],
                    [
                        202.80246595052722,
                        341.7670075516963
                    ],
                    [
                        201.4759676389108,
                        342.86296874719585
                    ],
                    [
                        200.64409581637142,
                        342.88404096807994
                    ],
                    [
                        200.86892603867955,
                        344.5903972085086
                    ],
                    [
                        198.48572568221584,
                        344.04278401304305
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-West Godavari",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dakshin Bastar Dantewada",
            "dt_code": "416",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        192.25792852428754,
                        299.11668989820856
                    ],
                    [
                        194.73106096967422,
                        297.8745923994357
                    ],
                    [
                        195.4055516365977,
                        298.1316277662833
                    ],
                    [
                        195.87769510344424,
                        298.3458040860717
                    ],
                    [
                        195.4055516365977,
                        299.502045542587
                    ],
                    [
                        196.25990648136803,
                        300.122774228152
                    ],
                    [
                        197.74378594859968,
                        299.9301503951884
                    ],
                    [
                        197.56392177075372,
                        301.7701874765389
                    ],
                    [
                        198.148480348754,
                        302.5186632400256
                    ],
                    [
                        197.20419341506113,
                        303.92948516981613
                    ],
                    [
                        198.53069172667756,
                        304.46368695286753
                    ],
                    [
                        199.58739377152438,
                        306.00157557136214
                    ],
                    [
                        200.82395999421783,
                        305.6171884697116
                    ],
                    [
                        201.7907299501419,
                        306.62074698286176
                    ],
                    [
                        200.2618844384483,
                        308.4987171556683
                    ],
                    [
                        199.58739377152438,
                        307.21842871031697
                    ],
                    [
                        198.7330389267545,
                        307.1330539512041
                    ],
                    [
                        198.35082754883115,
                        309.2026104565127
                    ],
                    [
                        197.54143874852275,
                        308.946670992684
                    ],
                    [
                        195.78776301452126,
                        311.9097875254445
                    ],
                    [
                        194.16898541390447,
                        311.18530209991957
                    ],
                    [
                        193.04483430236496,
                        310.22612048051855
                    ],
                    [
                        192.7075889689031,
                        307.9013268196294
                    ],
                    [
                        193.2696645246731,
                        307.0049865997505
                    ],
                    [
                        193.83174008044261,
                        303.6089113081773
                    ],
                    [
                        193.44952870251927,
                        300.25118207286505
                    ],
                    [
                        192.48275874659544,
                        300.27258275496393
                    ],
                    [
                        192.25792852428754,
                        299.11668989820856
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Dakshin Bastar Dantewada",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bastar",
            "dt_code": "414",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        205.47794559599083,
                        295.1955914589453
                    ],
                    [
                        206.5346476408381,
                        295.04548347555584
                    ],
                    [
                        206.91685901876144,
                        296.4175673234107
                    ],
                    [
                        207.8386629302238,
                        296.43900022765195
                    ],
                    [
                        207.43396853006948,
                        299.9515537585971
                    ],
                    [
                        208.08597617476266,
                        301.214036139142
                    ],
                    [
                        208.67053475276293,
                        304.3568553860261
                    ],
                    [
                        207.6587987523776,
                        304.6132437698453
                    ],
                    [
                        207.389002485608,
                        306.38590611835997
                    ],
                    [
                        206.39974950745318,
                        307.17574167819885
                    ],
                    [
                        203.58937172860487,
                        308.3707163557692
                    ],
                    [
                        201.63334879452623,
                        309.41587439050096
                    ],
                    [
                        200.2618844384483,
                        308.4987171556683
                    ],
                    [
                        201.7907299501419,
                        306.62074698286176
                    ],
                    [
                        200.82395999421783,
                        305.6171884697116
                    ],
                    [
                        199.58739377152438,
                        306.00157557136214
                    ],
                    [
                        198.53069172667756,
                        304.46368695286753
                    ],
                    [
                        197.20419341506113,
                        303.92948516981613
                    ],
                    [
                        198.148480348754,
                        302.5186632400256
                    ],
                    [
                        197.56392177075372,
                        301.7701874765389
                    ],
                    [
                        197.74378594859968,
                        299.9301503951884
                    ],
                    [
                        196.25990648136803,
                        300.122774228152
                    ],
                    [
                        195.4055516365977,
                        299.502045542587
                    ],
                    [
                        195.87769510344424,
                        298.3458040860717
                    ],
                    [
                        198.2384124376772,
                        299.63048451846913
                    ],
                    [
                        199.29511448252424,
                        298.8597538382333
                    ],
                    [
                        200.14946932729436,
                        297.4032934238001
                    ],
                    [
                        202.03804319468054,
                        297.6175310623097
                    ],
                    [
                        201.99307715021905,
                        296.0317440134804
                    ],
                    [
                        203.0497791950661,
                        294.50931054456464
                    ],
                    [
                        205.47794559599083,
                        295.1955914589453
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Bastar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kondagaon",
            "dt_code": "722",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        195.85521208121372,
                        290.9462132975514
                    ],
                    [
                        196.32735554806004,
                        289.3776964312693
                    ],
                    [
                        195.15823839205927,
                        288.4533108385035
                    ],
                    [
                        194.641128880751,
                        287.16289189500327
                    ],
                    [
                        196.05755928129065,
                        286.7541182258419
                    ],
                    [
                        196.979363192753,
                        285.2044630839863
                    ],
                    [
                        198.9578691490624,
                        284.4508039827424
                    ],
                    [
                        200.3518165273715,
                        285.46280701068963
                    ],
                    [
                        202.33032248368067,
                        284.8814947940652
                    ],
                    [
                        203.22964337291228,
                        286.689568800756
                    ],
                    [
                        203.22964337291228,
                        286.71108546420237
                    ],
                    [
                        202.77998292829625,
                        288.25979103151303
                    ],
                    [
                        203.1397112839893,
                        289.03377934799676
                    ],
                    [
                        204.6011077289902,
                        289.7000753384096
                    ],
                    [
                        204.84842097352907,
                        290.6025135068488
                    ],
                    [
                        206.01753812953007,
                        290.7743693372183
                    ],
                    [
                        205.92760604060686,
                        293.3078682953254
                    ],
                    [
                        205.47794559599083,
                        295.1955914589453
                    ],
                    [
                        203.0497791950661,
                        294.50931054456464
                    ],
                    [
                        201.99307715021905,
                        296.0317440134804
                    ],
                    [
                        202.03804319468054,
                        297.6175310623097
                    ],
                    [
                        200.14946932729436,
                        297.4032934238001
                    ],
                    [
                        199.29511448252424,
                        298.8597538382333
                    ],
                    [
                        198.2384124376772,
                        299.63048451846913
                    ],
                    [
                        195.87769510344424,
                        298.3458040860717
                    ],
                    [
                        195.4055516365977,
                        298.1316277662833
                    ],
                    [
                        197.27164248175336,
                        297.23189030423674
                    ],
                    [
                        197.36157457067634,
                        295.7530572432453
                    ],
                    [
                        196.64211785929137,
                        295.6244222153156
                    ],
                    [
                        197.49647270406126,
                        292.3849415551768
                    ],
                    [
                        195.85521208121372,
                        291.7193644410022
                    ],
                    [
                        195.85521208121372,
                        290.9462132975514
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Kondagaon",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gariaband",
            "dt_code": "720",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        204.89338701799056,
                        270.49789066137873
                    ],
                    [
                        207.43396853006948,
                        271.5591150757416
                    ],
                    [
                        207.9735610636087,
                        272.7713562804235
                    ],
                    [
                        210.04199910884108,
                        273.7882919559541
                    ],
                    [
                        210.24434630891824,
                        276.51239235588304
                    ],
                    [
                        210.60407466461083,
                        277.59252076759645
                    ],
                    [
                        209.9520670199181,
                        278.6937492397244
                    ],
                    [
                        211.5033955538422,
                        280.700572186207
                    ],
                    [
                        210.9413199980727,
                        282.166870351132
                    ],
                    [
                        211.2560823093038,
                        284.30004424326154
                    ],
                    [
                        210.73897279799576,
                        285.225992786545
                    ],
                    [
                        210.91883697584194,
                        286.62501767972105
                    ],
                    [
                        215.61778862207666,
                        287.6791405394448
                    ],
                    [
                        215.30302631084555,
                        290.1513354182346
                    ],
                    [
                        213.79666382138294,
                        291.01065172405964
                    ],
                    [
                        212.8748599099206,
                        289.3991896632078
                    ],
                    [
                        211.6158106649964,
                        288.9047981208613
                    ],
                    [
                        210.1319311977643,
                        290.06538744305016
                    ],
                    [
                        209.007780086225,
                        287.89421221684734
                    ],
                    [
                        207.77121386353156,
                        287.7436640129897
                    ],
                    [
                        206.0849871962223,
                        286.60350026242526
                    ],
                    [
                        205.32056444037562,
                        286.7326019392257
                    ],
                    [
                        206.5346476408381,
                        285.85027178905193
                    ],
                    [
                        207.7037647968391,
                        283.4814714823169
                    ],
                    [
                        206.64706275199205,
                        282.94278658313283
                    ],
                    [
                        206.30981741853043,
                        281.06723012216895
                    ],
                    [
                        205.3655304848371,
                        279.2333823992534
                    ],
                    [
                        204.7809719068366,
                        276.14503722256995
                    ],
                    [
                        203.92661706206673,
                        275.71278211777366
                    ],
                    [
                        203.09474523952758,
                        272.4034204823528
                    ],
                    [
                        204.89338701799056,
                        270.49789066137873
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Gariaband",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Raipur",
            "dt_code": "410",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        207.90611199691625,
                        265.8357853886843
                    ],
                    [
                        207.2091383077618,
                        266.89913505995895
                    ],
                    [
                        205.47794559599083,
                        268.1354794624092
                    ],
                    [
                        204.89338701799056,
                        270.49789066137873
                    ],
                    [
                        203.09474523952758,
                        272.4034204823528
                    ],
                    [
                        202.33032248368067,
                        272.66314583351203
                    ],
                    [
                        201.3635525277566,
                        271.62407241138146
                    ],
                    [
                        199.3625635492167,
                        271.45084885757086
                    ],
                    [
                        198.89042008236993,
                        269.89126081573767
                    ],
                    [
                        199.56491074929363,
                        269.26279961997034
                    ],
                    [
                        198.3283445266004,
                        267.91862452719386
                    ],
                    [
                        198.50820870444682,
                        266.5736717289267
                    ],
                    [
                        199.74477492714004,
                        265.445044303727
                    ],
                    [
                        199.81222399383228,
                        263.18613088744826
                    ],
                    [
                        200.57664674967918,
                        262.31672611429855
                    ],
                    [
                        201.40851857221833,
                        262.5993188614637
                    ],
                    [
                        203.6118547508354,
                        262.33846525667445
                    ],
                    [
                        204.03903217322045,
                        264.0334833781556
                    ],
                    [
                        207.90611199691625,
                        265.8357853886843
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Raipur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Durg",
            "dt_code": "409",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        198.50820870444682,
                        266.5736717289267
                    ],
                    [
                        198.3283445266004,
                        267.91862452719386
                    ],
                    [
                        199.56491074929363,
                        269.26279961997034
                    ],
                    [
                        198.89042008236993,
                        269.89126081573767
                    ],
                    [
                        199.3625635492167,
                        271.45084885757086
                    ],
                    [
                        199.02531821575485,
                        272.74971458848853
                    ],
                    [
                        197.2941255039841,
                        273.85318788093167
                    ],
                    [
                        196.4847367036757,
                        274.1776407997751
                    ],
                    [
                        196.5297027481372,
                        271.7106793986653
                    ],
                    [
                        192.64013990221088,
                        270.60620087741404
                    ],
                    [
                        193.40456265805778,
                        270.1729299472209
                    ],
                    [
                        193.2696645246731,
                        268.30894885471724
                    ],
                    [
                        192.5502078132879,
                        268.46072396644576
                    ],
                    [
                        193.17973243574988,
                        265.68383837695603
                    ],
                    [
                        193.3595966135963,
                        263.6424364620873
                    ],
                    [
                        195.5854158144441,
                        263.2295924705091
                    ],
                    [
                        196.84446505936808,
                        264.1203736589941
                    ],
                    [
                        197.6538538596767,
                        266.2264604163047
                    ],
                    [
                        198.50820870444682,
                        266.5736717289267
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Durg",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Thane",
            "dt_code": "517",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        79.86530039257627,
                        291.80525532755274
                    ],
                    [
                        81.79884030442417,
                        293.9730233826373
                    ],
                    [
                        81.88877239334715,
                        294.65945046932984
                    ],
                    [
                        83.23775372719456,
                        295.5601022450153
                    ],
                    [
                        82.92299141596345,
                        296.331833892335
                    ],
                    [
                        84.2045236831184,
                        297.0819030823372
                    ],
                    [
                        82.0011875045011,
                        299.9301503951884
                    ],
                    [
                        80.58475710396146,
                        300.3367837295326
                    ],
                    [
                        80.47234199280751,
                        301.0856764485497
                    ],
                    [
                        77.93176048072837,
                        301.06428254369683
                    ],
                    [
                        76.42539799126553,
                        302.1551449949085
                    ],
                    [
                        75.323729901957,
                        301.40656366384263
                    ],
                    [
                        73.43515603457081,
                        301.57768714915767
                    ],
                    [
                        73.39018999010932,
                        302.81799278820716
                    ],
                    [
                        72.0861747007234,
                        303.2882977899585
                    ],
                    [
                        72.445903056416,
                        300.7005589046672
                    ],
                    [
                        71.34423496710747,
                        299.2451482357674
                    ],
                    [
                        69.568076210875,
                        298.9882251001346
                    ],
                    [
                        69.77042341095216,
                        298.36722072773574
                    ],
                    [
                        71.25430287818426,
                        298.6242063924207
                    ],
                    [
                        72.10865772295415,
                        296.86762024312446
                    ],
                    [
                        73.5700541679555,
                        295.6458618414626
                    ],
                    [
                        75.84083941326503,
                        294.35916166409544
                    ],
                    [
                        76.94250750257356,
                        292.29907059938074
                    ],
                    [
                        79.86530039257627,
                        291.80525532755274
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Thane",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Adilabad",
            "dt_code": "532",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        158.6233272670279,
                        291.22543443707144
                    ],
                    [
                        158.8931235337975,
                        292.3634740927217
                    ],
                    [
                        160.30955393433715,
                        292.87864235534573
                    ],
                    [
                        160.01727464533678,
                        294.53075965343004
                    ],
                    [
                        159.88237651195232,
                        295.9031231674811
                    ],
                    [
                        158.8031914448743,
                        296.1389230556617
                    ],
                    [
                        158.82567446710505,
                        298.7741027376476
                    ],
                    [
                        157.4092440665654,
                        298.7741027376476
                    ],
                    [
                        156.17267784387218,
                        298.17446447106767
                    ],
                    [
                        154.6438323321786,
                        298.64562069533645
                    ],
                    [
                        153.744511442947,
                        299.7589170470792
                    ],
                    [
                        151.96835268671475,
                        300.3581836971971
                    ],
                    [
                        150.66433739732884,
                        299.0738690168491
                    ],
                    [
                        149.09052584117376,
                        298.17446447106767
                    ],
                    [
                        149.36032210794315,
                        296.43900022765195
                    ],
                    [
                        150.79923553071376,
                        295.6029824072024
                    ],
                    [
                        150.75426948625227,
                        293.65119615408014
                    ],
                    [
                        150.30460904163624,
                        292.3205386148391
                    ],
                    [
                        151.29386201979105,
                        291.97702823496
                    ],
                    [
                        151.60862433102193,
                        290.92473345138
                    ],
                    [
                        150.75426948625227,
                        288.88330059587133
                    ],
                    [
                        151.8559375755608,
                        289.3776964312693
                    ],
                    [
                        152.59787730917674,
                        290.40916149935356
                    ],
                    [
                        154.71128139887082,
                        290.3447074875358
                    ],
                    [
                        158.6233272670279,
                        291.22543443707144
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Adilabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hyderabad",
            "dt_code": "536",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        154.01430770971638,
                        328.2656433537773
                    ],
                    [
                        152.8901565981771,
                        329.0491468244974
                    ],
                    [
                        152.05828477563773,
                        328.0750285447009
                    ],
                    [
                        152.23814895348391,
                        327.2700682364293
                    ],
                    [
                        153.85692655410094,
                        327.2276955241973
                    ],
                    [
                        154.01430770971638,
                        328.2656433537773
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Hyderabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jagtial",
            "dt_code": "737",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        165.39071695849543,
                        305.9588686878389
                    ],
                    [
                        164.3340149136484,
                        306.9836414328229
                    ],
                    [
                        163.34476193549358,
                        306.89825902640837
                    ],
                    [
                        162.5803391796469,
                        308.3707163557692
                    ],
                    [
                        160.75921437895295,
                        308.2640442686352
                    ],
                    [
                        160.3320369565679,
                        309.6717683907727
                    ],
                    [
                        158.7582254004128,
                        309.50117514346925
                    ],
                    [
                        156.62233828848798,
                        308.69070669367665
                    ],
                    [
                        156.10522877717972,
                        308.946670992684
                    ],
                    [
                        154.80121348779403,
                        307.41051175899247
                    ],
                    [
                        154.21665490979353,
                        305.98022221688376
                    ],
                    [
                        154.12672282087033,
                        303.9081148117712
                    ],
                    [
                        155.61060228810243,
                        303.4379223701006
                    ],
                    [
                        156.4649571328723,
                        302.60418950680895
                    ],
                    [
                        157.45421011102712,
                        302.9248877974037
                    ],
                    [
                        158.6682933114896,
                        302.0054461237708
                    ],
                    [
                        160.17465580095222,
                        301.9626734214149
                    ],
                    [
                        161.27632389026098,
                        301.9199000093608
                    ],
                    [
                        162.19812780172333,
                        302.7324735982772
                    ],
                    [
                        162.6253052241084,
                        304.03633431999424
                    ],
                    [
                        163.63704122449371,
                        305.14730508062456
                    ],
                    [
                        165.39071695849543,
                        305.9588686878389
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Jagtial",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jangaon",
            "dt_code": "752",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        168.51585704857507,
                        321.77744520815463
                    ],
                    [
                        168.15612869288225,
                        323.8145863853151
                    ],
                    [
                        169.37021189334496,
                        324.45088608333305
                    ],
                    [
                        168.47089100411335,
                        324.9810246958987
                    ],
                    [
                        168.87558540426744,
                        325.9562165714439
                    ],
                    [
                        166.58231713672717,
                        326.14697519187723
                    ],
                    [
                        166.40245295888076,
                        326.5920281145424
                    ],
                    [
                        164.71622629157173,
                        326.7615535005354
                    ],
                    [
                        163.18738077987814,
                        324.9810246958987
                    ],
                    [
                        162.87261846864703,
                        323.666095482553
                    ],
                    [
                        161.3212899347227,
                        323.0296158319483
                    ],
                    [
                        160.8266634456454,
                        321.7986731714571
                    ],
                    [
                        159.00553864495146,
                        321.3103886288166
                    ],
                    [
                        159.7924444230291,
                        320.6946019786644
                    ],
                    [
                        162.31054291287728,
                        320.5671806115781
                    ],
                    [
                        163.20986380210888,
                        318.7189044116174
                    ],
                    [
                        164.31153189141764,
                        318.74015606813134
                    ],
                    [
                        164.8960904694179,
                        319.3351352736165
                    ],
                    [
                        168.3584758929594,
                        320.6946019786644
                    ],
                    [
                        168.51585704857507,
                        321.77744520815463
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Jangaon",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mulugu",
            "dt_code": "780",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        181.12883252004713,
                        309.13862791742974
                    ],
                    [
                        182.3429157205096,
                        308.8400222210896
                    ],
                    [
                        185.01839536597345,
                        310.5032530175455
                    ],
                    [
                        186.030131366359,
                        311.8032579175971
                    ],
                    [
                        186.6596559888212,
                        313.8265939175697
                    ],
                    [
                        186.77207109997494,
                        315.97604678746836
                    ],
                    [
                        183.62444798766478,
                        316.8055753572616
                    ],
                    [
                        182.00567038704799,
                        317.6773700815282
                    ],
                    [
                        181.42111180904726,
                        317.3371908296497
                    ],
                    [
                        179.62247003058428,
                        319.4838598278604
                    ],
                    [
                        177.84631127435205,
                        317.315928211171
                    ],
                    [
                        176.81209225173575,
                        317.50728578211266
                    ],
                    [
                        175.5305599845808,
                        316.93317257769513
                    ],
                    [
                        174.7211711842724,
                        317.315928211171
                    ],
                    [
                        173.30474078373277,
                        316.55036288322094
                    ],
                    [
                        173.50708798380992,
                        314.59293250272367
                    ],
                    [
                        174.51882398419525,
                        312.31456120516475
                    ],
                    [
                        178.83556425250686,
                        312.5701760959843
                    ],
                    [
                        179.06039447481453,
                        309.991601073371
                    ],
                    [
                        181.12883252004713,
                        309.13862791742974
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Mulugu",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jogulamba Gadwal",
            "dt_code": "744",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        139.1080639707036,
                        350.4604672499446
                    ],
                    [
                        138.90571677062644,
                        345.95899340849985
                    ],
                    [
                        140.27718112670482,
                        344.1059752606034
                    ],
                    [
                        141.2664341048594,
                        343.8110713544078
                    ],
                    [
                        142.95266077216843,
                        344.6535769092359
                    ],
                    [
                        145.24592903970893,
                        346.40102237328745
                    ],
                    [
                        145.4707592620166,
                        347.53737162486726
                    ],
                    [
                        148.57341632986572,
                        350.10311636015615
                    ],
                    [
                        149.89991464148216,
                        349.78777215339574
                    ],
                    [
                        148.82072957440437,
                        351.5322706229566
                    ],
                    [
                        147.47174824055696,
                        351.78440544875787
                    ],
                    [
                        144.59392139501597,
                        350.79675950067804
                    ],
                    [
                        143.35735517232274,
                        351.28011517464984
                    ],
                    [
                        141.24395108262866,
                        351.11200007350715
                    ],
                    [
                        139.1080639707036,
                        350.4604672499446
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Jogulamba Gadwal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kamareddy",
            "dt_code": "736",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        139.6476565042426,
                        314.0820644185967
                    ],
                    [
                        139.26544512631926,
                        312.8470646176613
                    ],
                    [
                        140.1422829933199,
                        311.69672404844084
                    ],
                    [
                        140.3221471711663,
                        310.0982033786218
                    ],
                    [
                        142.41306823862965,
                        309.991601073371
                    ],
                    [
                        143.1550079722458,
                        310.9082407926145
                    ],
                    [
                        145.33586112863213,
                        310.99349350358625
                    ],
                    [
                        146.57242735132536,
                        311.8458702719879
                    ],
                    [
                        146.819740595864,
                        310.58851873935475
                    ],
                    [
                        150.07977881932834,
                        311.59018591610123
                    ],
                    [
                        151.87842059779155,
                        311.59018591610123
                    ],
                    [
                        152.8002245092539,
                        311.121367439669
                    ],
                    [
                        154.03679073194735,
                        311.3984065286839
                    ],
                    [
                        154.32907002094748,
                        313.400755646687
                    ],
                    [
                        153.9243756207934,
                        314.2949379280565
                    ],
                    [
                        154.59886628771687,
                        315.1675431002242
                    ],
                    [
                        154.59886628771687,
                        315.7633061459938
                    ],
                    [
                        152.62036033140748,
                        316.0824108251167
                    ],
                    [
                        151.9009036200223,
                        316.69923974940735
                    ],
                    [
                        150.86668459740622,
                        315.8271300983264
                    ],
                    [
                        148.88817864109683,
                        317.1032928616189
                    ],
                    [
                        147.29188406271055,
                        317.6773700815282
                    ],
                    [
                        145.89793668440166,
                        316.8268419779406
                    ],
                    [
                        144.7513025506314,
                        317.1883490014404
                    ],
                    [
                        144.32412512824658,
                        315.67820519539083
                    ],
                    [
                        142.86272868324522,
                        315.6994806846033
                    ],
                    [
                        142.165754994091,
                        314.61421656530337
                    ],
                    [
                        140.38959623785877,
                        314.3375106050478
                    ],
                    [
                        139.6476565042426,
                        314.0820644185967
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Kamareddy",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Karimnagar",
            "dt_code": "534",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        160.3320369565679,
                        309.6717683907727
                    ],
                    [
                        160.75921437895295,
                        308.2640442686352
                    ],
                    [
                        162.5803391796469,
                        308.3707163557692
                    ],
                    [
                        164.3340149136484,
                        309.82102845428824
                    ],
                    [
                        164.4239470025716,
                        311.6754167630876
                    ],
                    [
                        165.45816602518767,
                        311.1639907174102
                    ],
                    [
                        168.08867962619001,
                        312.634075994637
                    ],
                    [
                        169.28027980442175,
                        312.03761743257195
                    ],
                    [
                        169.23531375996026,
                        314.5290793039669
                    ],
                    [
                        167.9088154483436,
                        315.0398624563554
                    ],
                    [
                        167.75143429272794,
                        316.0611383525464
                    ],
                    [
                        165.39071695849543,
                        316.5928999660924
                    ],
                    [
                        164.55884513595606,
                        315.7633061459938
                    ],
                    [
                        163.34476193549358,
                        316.16749904104955
                    ],
                    [
                        162.53537313518518,
                        315.1888226191328
                    ],
                    [
                        162.91758451310852,
                        314.4013683555319
                    ],
                    [
                        161.2313578457995,
                        313.69884954102423
                    ],
                    [
                        161.3212899347227,
                        312.9322553139194
                    ],
                    [
                        159.81492744525985,
                        311.24923522218376
                    ],
                    [
                        160.3320369565679,
                        309.6717683907727
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Karimnagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Khammam",
            "dt_code": "541",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        188.54822985620763,
                        332.94228116988154
                    ],
                    [
                        188.07608638936085,
                        333.4074098421323
                    ],
                    [
                        185.9401992774358,
                        332.73083412978826
                    ],
                    [
                        184.1865235434343,
                        331.67336474516026
                    ],
                    [
                        183.08485545412577,
                        332.96342501683444
                    ],
                    [
                        180.99393438666243,
                        334.37970864207193
                    ],
                    [
                        183.51203287651083,
                        334.612166293502
                    ],
                    [
                        184.5012858546654,
                        335.2671736099114
                    ],
                    [
                        183.9392102988959,
                        336.5978613900968
                    ],
                    [
                        184.0291423878191,
                        337.59020026646783
                    ],
                    [
                        180.3868927864312,
                        335.8797882304449
                    ],
                    [
                        180.1620625641233,
                        334.4219750622625
                    ],
                    [
                        178.4083868301218,
                        333.7033616280257
                    ],
                    [
                        176.4748469182739,
                        334.46424086335946
                    ],
                    [
                        176.25001669596622,
                        333.2382808573715
                    ],
                    [
                        173.91178238396424,
                        331.35604764336426
                    ],
                    [
                        174.20406167296437,
                        330.10759079436394
                    ],
                    [
                        172.76514825019376,
                        329.0491468244974
                    ],
                    [
                        175.5305599845808,
                        327.8844008464147
                    ],
                    [
                        176.4298808738124,
                        328.6891857253973
                    ],
                    [
                        177.26175269635155,
                        328.4985995209551
                    ],
                    [
                        179.01542843035327,
                        325.8502339402269
                    ],
                    [
                        178.6332170524297,
                        325.42626323198914
                    ],
                    [
                        181.08386647558564,
                        325.2778582800308
                    ],
                    [
                        182.00567038704799,
                        326.35891397178324
                    ],
                    [
                        182.23050060935566,
                        327.69376024828415
                    ],
                    [
                        183.01740638743354,
                        328.3715349044321
                    ],
                    [
                        184.36638772128072,
                        328.1385682460024
                    ],
                    [
                        185.69288603289738,
                        329.45140209966587
                    ],
                    [
                        188.27843358943778,
                        328.41389041213876
                    ],
                    [
                        188.54822985620763,
                        329.93826630476786
                    ],
                    [
                        189.49251678990072,
                        330.6578257081953
                    ],
                    [
                        188.59319590066912,
                        331.5464421368405
                    ],
                    [
                        188.54822985620763,
                        332.94228116988154
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Khammam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Komaram Bheem",
            "dt_code": "733",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        172.81011429465548,
                        294.0802899650972
                    ],
                    [
                        173.66446913942536,
                        295.1527043752976
                    ],
                    [
                        174.83358629542658,
                        295.77449577791907
                    ],
                    [
                        175.32821278450388,
                        296.8890493416166
                    ],
                    [
                        174.60875607311846,
                        300.6363670325046
                    ],
                    [
                        170.89905740503832,
                        299.9943599488463
                    ],
                    [
                        169.9772534935762,
                        298.7955157823119
                    ],
                    [
                        168.56082309303656,
                        298.64562069533645
                    ],
                    [
                        169.01048353765236,
                        299.9515537585971
                    ],
                    [
                        167.75143429272794,
                        300.42238252883027
                    ],
                    [
                        166.11017366988062,
                        299.8231308946913
                    ],
                    [
                        165.59306415857236,
                        301.6632446265462
                    ],
                    [
                        164.87360744718717,
                        302.58280820560174
                    ],
                    [
                        163.1648977576474,
                        301.6632446265462
                    ],
                    [
                        161.79343340156902,
                        299.84453515263533
                    ],
                    [
                        160.26458788987543,
                        299.22373896134707
                    ],
                    [
                        158.82567446710505,
                        298.7741027376476
                    ],
                    [
                        158.8031914448743,
                        296.1389230556617
                    ],
                    [
                        159.88237651195232,
                        295.9031231674811
                    ],
                    [
                        160.01727464533678,
                        294.53075965343004
                    ],
                    [
                        162.37799197956974,
                        294.80958144369424
                    ],
                    [
                        163.74945633564766,
                        295.9459975097123
                    ],
                    [
                        164.67126024711,
                        293.865752221271
                    ],
                    [
                        166.3350038921883,
                        294.12319531642106
                    ],
                    [
                        167.88633242611263,
                        295.3028059785232
                    ],
                    [
                        171.88831038319313,
                        293.60828274047594
                    ],
                    [
                        172.81011429465548,
                        294.0802899650972
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Komaram Bheem",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mahabubabad",
            "dt_code": "751",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        168.87558540426744,
                        325.9562165714439
                    ],
                    [
                        168.47089100411335,
                        324.9810246958987
                    ],
                    [
                        169.37021189334496,
                        324.45088608333305
                    ],
                    [
                        170.5618120715767,
                        324.34484624608785
                    ],
                    [
                        171.12388762734645,
                        323.45395183569565
                    ],
                    [
                        174.09164656181042,
                        322.7749830157573
                    ],
                    [
                        174.33895980634907,
                        321.4802365497558
                    ],
                    [
                        175.5755260290423,
                        320.31232012749115
                    ],
                    [
                        175.5305599845808,
                        316.93317257769513
                    ],
                    [
                        176.81209225173575,
                        317.50728578211266
                    ],
                    [
                        177.84631127435205,
                        317.315928211171
                    ],
                    [
                        179.62247003058428,
                        319.4838598278604
                    ],
                    [
                        179.3077077193534,
                        321.3103886288166
                    ],
                    [
                        179.46508887496884,
                        322.4566590278947
                    ],
                    [
                        178.6332170524297,
                        325.42626323198914
                    ],
                    [
                        179.01542843035327,
                        325.8502339402269
                    ],
                    [
                        177.26175269635155,
                        328.4985995209551
                    ],
                    [
                        176.4298808738124,
                        328.6891857253973
                    ],
                    [
                        175.5305599845808,
                        327.8844008464147
                    ],
                    [
                        172.76514825019376,
                        329.0491468244974
                    ],
                    [
                        172.0007254943473,
                        327.2488819601609
                    ],
                    [
                        168.87558540426744,
                        325.9562165714439
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Mahabubabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mahabubnagar",
            "dt_code": "538",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        142.59293241647583,
                        343.17903641933117
                    ],
                    [
                        143.1325249500146,
                        340.5652001057441
                    ],
                    [
                        142.23320406078346,
                        336.32332496029346
                    ],
                    [
                        142.72783054986053,
                        333.9781467508817
                    ],
                    [
                        144.99861579517028,
                        335.2249195496663
                    ],
                    [
                        146.23518201786374,
                        334.33744160267224
                    ],
                    [
                        146.61739339578708,
                        333.8724632684343
                    ],
                    [
                        150.30460904163624,
                        335.20379228821287
                    ],
                    [
                        151.38379410871403,
                        337.4002040712779
                    ],
                    [
                        151.38379410871403,
                        338.89872759924674
                    ],
                    [
                        149.697567441405,
                        337.9912627626249
                    ],
                    [
                        148.03382379632671,
                        340.2699445860815
                    ],
                    [
                        146.34759712901746,
                        340.01684490397133
                    ],
                    [
                        145.98786877332486,
                        341.6616053556732
                    ],
                    [
                        145.02109881740103,
                        342.58901630796566
                    ],
                    [
                        142.59293241647583,
                        343.17903641933117
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Mahabubnagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mancherial",
            "dt_code": "735",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        174.60875607311846,
                        300.6363670325046
                    ],
                    [
                        173.57453705050239,
                        301.5135171753282
                    ],
                    [
                        174.76613722873412,
                        302.5614267274125
                    ],
                    [
                        175.08089953996523,
                        305.1900252530876
                    ],
                    [
                        174.38392585081078,
                        305.80938909539
                    ],
                    [
                        173.6869521616561,
                        305.6599009432421
                    ],
                    [
                        172.85508033911697,
                        307.7092796745207
                    ],
                    [
                        170.94402344950004,
                        308.17870348593624
                    ],
                    [
                        170.2470497603456,
                        307.303800690407
                    ],
                    [
                        167.27929082588162,
                        305.4676893045989
                    ],
                    [
                        165.39071695849543,
                        305.9588686878389
                    ],
                    [
                        163.63704122449371,
                        305.14730508062456
                    ],
                    [
                        162.6253052241084,
                        304.03633431999424
                    ],
                    [
                        162.19812780172333,
                        302.7324735982772
                    ],
                    [
                        161.27632389026098,
                        301.9199000093608
                    ],
                    [
                        160.17465580095222,
                        301.9626734214149
                    ],
                    [
                        159.1629198005669,
                        300.6149693850611
                    ],
                    [
                        160.26458788987543,
                        299.22373896134707
                    ],
                    [
                        161.79343340156902,
                        299.84453515263533
                    ],
                    [
                        163.1648977576474,
                        301.6632446265462
                    ],
                    [
                        164.87360744718717,
                        302.58280820560174
                    ],
                    [
                        165.59306415857236,
                        301.6632446265462
                    ],
                    [
                        166.11017366988062,
                        299.8231308946913
                    ],
                    [
                        167.75143429272794,
                        300.42238252883027
                    ],
                    [
                        169.01048353765236,
                        299.9515537585971
                    ],
                    [
                        168.56082309303656,
                        298.64562069533645
                    ],
                    [
                        169.9772534935762,
                        298.7955157823119
                    ],
                    [
                        170.89905740503832,
                        299.9943599488463
                    ],
                    [
                        174.60875607311846,
                        300.6363670325046
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Mancherial",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Medak",
            "dt_code": "535",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        144.7513025506314,
                        317.1883490014404
                    ],
                    [
                        145.89793668440166,
                        316.8268419779406
                    ],
                    [
                        147.29188406271055,
                        317.6773700815282
                    ],
                    [
                        148.88817864109683,
                        317.1032928616189
                    ],
                    [
                        150.86668459740622,
                        315.8271300983264
                    ],
                    [
                        151.9009036200223,
                        316.69923974940735
                    ],
                    [
                        152.62036033140748,
                        316.0824108251167
                    ],
                    [
                        154.59886628771687,
                        315.7633061459938
                    ],
                    [
                        154.75624744333254,
                        316.9544381970217
                    ],
                    [
                        154.0592737541781,
                        319.35638213392724
                    ],
                    [
                        154.19417188756256,
                        321.11929718630785
                    ],
                    [
                        153.58713028733155,
                        323.45395183569565
                    ],
                    [
                        152.84519055371538,
                        323.178140826076
                    ],
                    [
                        150.93413366409845,
                        322.56277109662386
                    ],
                    [
                        150.9566166863292,
                        323.53881124142873
                    ],
                    [
                        148.4385181964808,
                        321.6076156151965
                    ],
                    [
                        147.7190614850956,
                        321.45900613269765
                    ],
                    [
                        147.4042991738645,
                        320.07867720860395
                    ],
                    [
                        145.3133781064014,
                        320.31232012749115
                    ],
                    [
                        143.67211748355362,
                        318.80391004417044
                    ],
                    [
                        144.7513025506314,
                        317.1883490014404
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Medak",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Medchal Malkajgiri",
            "dt_code": "742",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        154.01430770971638,
                        328.2656433537773
                    ],
                    [
                        153.85692655410094,
                        327.2276955241973
                    ],
                    [
                        152.23814895348391,
                        327.2700682364293
                    ],
                    [
                        151.36131108648328,
                        325.9774126158675
                    ],
                    [
                        151.8559375755608,
                        324.1327544421928
                    ],
                    [
                        152.84519055371538,
                        323.178140826076
                    ],
                    [
                        153.58713028733155,
                        323.45395183569565
                    ],
                    [
                        156.08274575494897,
                        323.26300867577316
                    ],
                    [
                        156.89213455525714,
                        323.7297354136108
                    ],
                    [
                        156.19516086610292,
                        325.15064775451305
                    ],
                    [
                        157.29682895541146,
                        326.7615535005354
                    ],
                    [
                        157.09448175533453,
                        328.0750285447009
                    ],
                    [
                        154.01430770971638,
                        328.2656433537773
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Medchal Malkajgiri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nagarkurnool",
            "dt_code": "746",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        164.04173562464803,
                        343.8953325795894
                    ],
                    [
                        164.10918469134026,
                        345.89584112635896
                    ],
                    [
                        163.27731286880135,
                        346.1484423211059
                    ],
                    [
                        161.38873900141493,
                        345.66427143710615
                    ],
                    [
                        159.54513117849024,
                        346.5483509830394
                    ],
                    [
                        159.81492744525985,
                        347.4742522919842
                    ],
                    [
                        157.13944779979602,
                        349.22007068610407
                    ],
                    [
                        155.09349277679416,
                        348.0633150405487
                    ],
                    [
                        154.35155304317823,
                        348.6943270914546
                    ],
                    [
                        152.28311499794563,
                        348.2105628873989
                    ],
                    [
                        150.0123297526361,
                        349.22007068610407
                    ],
                    [
                        149.22542397455845,
                        348.52606999685526
                    ],
                    [
                        149.58515233025105,
                        346.67462693273967
                    ],
                    [
                        149.13549188563525,
                        346.1694914659786
                    ],
                    [
                        150.86668459740622,
                        343.53720596979355
                    ],
                    [
                        147.7190614850956,
                        341.8934852443356
                    ],
                    [
                        148.03382379632671,
                        340.2699445860815
                    ],
                    [
                        149.697567441405,
                        337.9912627626249
                    ],
                    [
                        151.38379410871403,
                        338.89872759924674
                    ],
                    [
                        151.38379410871403,
                        337.4002040712779
                    ],
                    [
                        154.14920584310107,
                        336.7667940070374
                    ],
                    [
                        155.83543251041033,
                        337.611310191561
                    ],
                    [
                        157.34179499987295,
                        337.8646173940199
                    ],
                    [
                        156.50992317733403,
                        339.8691933851028
                    ],
                    [
                        156.89213455525714,
                        341.0923687161008
                    ],
                    [
                        158.01628566679688,
                        341.4929540490173
                    ],
                    [
                        158.82567446710505,
                        340.92368498757696
                    ],
                    [
                        163.18738077987814,
                        343.2422459339323
                    ],
                    [
                        164.04173562464803,
                        343.8953325795894
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Nagarkurnool",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nalgonda",
            "dt_code": "539",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        170.94402344950004,
                        338.7299182075236
                    ],
                    [
                        168.92055144872916,
                        339.6582498020447
                    ],
                    [
                        167.52660407042026,
                        339.8691933851028
                    ],
                    [
                        167.0994266480352,
                        340.4597550962666
                    ],
                    [
                        164.73870931380247,
                        340.691729147672
                    ],
                    [
                        164.17663375803272,
                        341.5140359870794
                    ],
                    [
                        164.04173562464803,
                        343.8953325795894
                    ],
                    [
                        163.18738077987814,
                        343.2422459339323
                    ],
                    [
                        158.82567446710505,
                        340.92368498757696
                    ],
                    [
                        158.01628566679688,
                        341.4929540490173
                    ],
                    [
                        156.89213455525714,
                        341.0923687161008
                    ],
                    [
                        156.50992317733403,
                        339.8691933851028
                    ],
                    [
                        157.34179499987295,
                        337.8646173940199
                    ],
                    [
                        158.03876868902762,
                        336.68232892442194
                    ],
                    [
                        156.98206664418035,
                        336.11212544308086
                    ],
                    [
                        158.6233272670279,
                        332.92113716714334
                    ],
                    [
                        159.3427839784133,
                        333.34398763924565
                    ],
                    [
                        160.24210486764468,
                        331.9906465998031
                    ],
                    [
                        160.6917653122605,
                        329.72659648588865
                    ],
                    [
                        162.46792406849295,
                        330.29806877931736
                    ],
                    [
                        163.97428655795557,
                        329.9805983739435
                    ],
                    [
                        164.64877722487927,
                        328.4985995209551
                    ],
                    [
                        167.57157011488198,
                        328.7103611766247
                    ],
                    [
                        168.67323820419028,
                        331.165640443293
                    ],
                    [
                        169.6624911823451,
                        332.73083412978826
                    ],
                    [
                        170.0222195380377,
                        335.20379228821287
                    ],
                    [
                        170.76415927165363,
                        336.45003730283213
                    ],
                    [
                        170.94402344950004,
                        338.7299182075236
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Nalgonda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nirmal",
            "dt_code": "734",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        145.49324228424734,
                        305.5317614246396
                    ],
                    [
                        143.94191375032324,
                        304.52778378512915
                    ],
                    [
                        143.26742308339954,
                        301.9626734214149
                    ],
                    [
                        144.18922699486188,
                        300.2939832584313
                    ],
                    [
                        144.23419303932337,
                        298.36722072773574
                    ],
                    [
                        145.42579321755534,
                        297.76748666660114
                    ],
                    [
                        146.86470664032572,
                        298.88116616423434
                    ],
                    [
                        148.82072957440437,
                        299.395008135298
                    ],
                    [
                        149.09052584117376,
                        298.17446447106767
                    ],
                    [
                        150.66433739732884,
                        299.0738690168491
                    ],
                    [
                        151.96835268671475,
                        300.3581836971971
                    ],
                    [
                        153.744511442947,
                        299.7589170470792
                    ],
                    [
                        154.6438323321786,
                        298.64562069533645
                    ],
                    [
                        156.17267784387218,
                        298.17446447106767
                    ],
                    [
                        157.4092440665654,
                        298.7741027376476
                    ],
                    [
                        158.82567446710505,
                        298.7741027376476
                    ],
                    [
                        160.26458788987543,
                        299.22373896134707
                    ],
                    [
                        159.1629198005669,
                        300.6149693850611
                    ],
                    [
                        160.17465580095222,
                        301.9626734214149
                    ],
                    [
                        158.6682933114896,
                        302.0054461237708
                    ],
                    [
                        157.45421011102712,
                        302.9248877974037
                    ],
                    [
                        156.4649571328723,
                        302.60418950680895
                    ],
                    [
                        155.61060228810243,
                        303.4379223701006
                    ],
                    [
                        154.12672282087033,
                        303.9081148117712
                    ],
                    [
                        152.95760566486933,
                        303.80126038066476
                    ],
                    [
                        152.28311499794563,
                        303.03177838813707
                    ],
                    [
                        150.86668459740622,
                        303.8653735675508
                    ],
                    [
                        149.7874995303282,
                        302.839372143615
                    ],
                    [
                        147.02208779594116,
                        304.03633431999424
                    ],
                    [
                        146.79725757363326,
                        304.9977789587398
                    ],
                    [
                        145.49324228424734,
                        305.5317614246396
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Nirmal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nizamabad",
            "dt_code": "533",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        142.41306823862965,
                        309.991601073371
                    ],
                    [
                        142.27817010524473,
                        308.28537903189954
                    ],
                    [
                        143.1325249500146,
                        308.02935045477244
                    ],
                    [
                        143.89694770586152,
                        306.1296920331829
                    ],
                    [
                        145.49324228424734,
                        305.5317614246396
                    ],
                    [
                        146.79725757363326,
                        304.9977789587398
                    ],
                    [
                        147.02208779594116,
                        304.03633431999424
                    ],
                    [
                        149.7874995303282,
                        302.839372143615
                    ],
                    [
                        150.86668459740622,
                        303.8653735675508
                    ],
                    [
                        152.28311499794563,
                        303.03177838813707
                    ],
                    [
                        152.95760566486933,
                        303.80126038066476
                    ],
                    [
                        154.12672282087033,
                        303.9081148117712
                    ],
                    [
                        154.21665490979353,
                        305.98022221688376
                    ],
                    [
                        154.80121348779403,
                        307.41051175899247
                    ],
                    [
                        156.10522877717972,
                        308.946670992684
                    ],
                    [
                        154.39651908763972,
                        310.396667009391
                    ],
                    [
                        154.03679073194735,
                        311.3984065286839
                    ],
                    [
                        152.8002245092539,
                        311.121367439669
                    ],
                    [
                        151.87842059779155,
                        311.59018591610123
                    ],
                    [
                        150.07977881932834,
                        311.59018591610123
                    ],
                    [
                        146.819740595864,
                        310.58851873935475
                    ],
                    [
                        146.57242735132536,
                        311.8458702719879
                    ],
                    [
                        145.33586112863213,
                        310.99349350358625
                    ],
                    [
                        143.1550079722458,
                        310.9082407926145
                    ],
                    [
                        142.41306823862965,
                        309.991601073371
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Nizamabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Peddapalli",
            "dt_code": "738",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        162.5803391796469,
                        308.3707163557692
                    ],
                    [
                        163.34476193549358,
                        306.89825902640837
                    ],
                    [
                        164.3340149136484,
                        306.9836414328229
                    ],
                    [
                        165.39071695849543,
                        305.9588686878389
                    ],
                    [
                        167.27929082588162,
                        305.4676893045989
                    ],
                    [
                        170.2470497603456,
                        307.303800690407
                    ],
                    [
                        170.94402344950004,
                        308.17870348593624
                    ],
                    [
                        172.85508033911697,
                        307.7092796745207
                    ],
                    [
                        173.16984265034807,
                        308.45605091366184
                    ],
                    [
                        172.58528407234758,
                        309.7357380208484
                    ],
                    [
                        171.61851411642374,
                        309.7144149827526
                    ],
                    [
                        170.80912531611534,
                        311.1639907174102
                    ],
                    [
                        169.28027980442175,
                        312.03761743257195
                    ],
                    [
                        168.08867962619001,
                        312.634075994637
                    ],
                    [
                        165.45816602518767,
                        311.1639907174102
                    ],
                    [
                        164.4239470025716,
                        311.6754167630876
                    ],
                    [
                        164.3340149136484,
                        309.82102845428824
                    ],
                    [
                        162.5803391796469,
                        308.3707163557692
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Peddapalli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rajanna Sircilla",
            "dt_code": "739",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        156.10522877717972,
                        308.946670992684
                    ],
                    [
                        156.62233828848798,
                        308.69070669367665
                    ],
                    [
                        158.7582254004128,
                        309.50117514346925
                    ],
                    [
                        160.3320369565679,
                        309.6717683907727
                    ],
                    [
                        159.81492744525985,
                        311.24923522218376
                    ],
                    [
                        161.3212899347227,
                        312.9322553139194
                    ],
                    [
                        161.2313578457995,
                        313.69884954102423
                    ],
                    [
                        160.7816974011837,
                        314.59293250272367
                    ],
                    [
                        157.27434593318048,
                        315.1888226191328
                    ],
                    [
                        155.70053437702563,
                        314.6355004594053
                    ],
                    [
                        154.59886628771687,
                        315.1675431002242
                    ],
                    [
                        153.9243756207934,
                        314.2949379280565
                    ],
                    [
                        154.32907002094748,
                        313.400755646687
                    ],
                    [
                        154.03679073194735,
                        311.3984065286839
                    ],
                    [
                        154.39651908763972,
                        310.396667009391
                    ],
                    [
                        156.10522877717972,
                        308.946670992684
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Rajanna Sircilla",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ranga Reddy",
            "dt_code": "537",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        152.23814895348391,
                        327.2700682364293
                    ],
                    [
                        152.05828477563773,
                        328.0750285447009
                    ],
                    [
                        152.8901565981771,
                        329.0491468244974
                    ],
                    [
                        154.01430770971638,
                        328.2656433537773
                    ],
                    [
                        157.09448175533453,
                        328.0750285447009
                    ],
                    [
                        157.3193119776422,
                        330.00176417180086
                    ],
                    [
                        158.28608193356604,
                        330.44620949716926
                    ],
                    [
                        157.65655731110428,
                        331.6945179648401
                    ],
                    [
                        158.6233272670279,
                        332.92113716714334
                    ],
                    [
                        156.98206664418035,
                        336.11212544308086
                    ],
                    [
                        158.03876868902762,
                        336.68232892442194
                    ],
                    [
                        157.34179499987295,
                        337.8646173940199
                    ],
                    [
                        155.83543251041033,
                        337.611310191561
                    ],
                    [
                        154.14920584310107,
                        336.7667940070374
                    ],
                    [
                        151.38379410871403,
                        337.4002040712779
                    ],
                    [
                        150.30460904163624,
                        335.20379228821287
                    ],
                    [
                        146.61739339578708,
                        333.8724632684343
                    ],
                    [
                        146.23518201786374,
                        334.33744160267224
                    ],
                    [
                        145.11103090632423,
                        333.0479988470861
                    ],
                    [
                        146.10028388447904,
                        332.4347820742237
                    ],
                    [
                        147.2694010404798,
                        330.46737182676316
                    ],
                    [
                        146.32511410678694,
                        328.9644542177961
                    ],
                    [
                        147.17946895155683,
                        327.10057355399744
                    ],
                    [
                        147.29188406271055,
                        326.3801069678324
                    ],
                    [
                        149.45025419686635,
                        327.2700682364293
                    ],
                    [
                        150.48447321948242,
                        327.3759972228538
                    ],
                    [
                        151.36131108648328,
                        325.9774126158675
                    ],
                    [
                        152.23814895348391,
                        327.2700682364293
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Ranga Reddy",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sangareddy",
            "dt_code": "740",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        138.0738449480873,
                        325.0234314278245
                    ],
                    [
                        138.20874308147222,
                        323.32665785726863
                    ],
                    [
                        139.44530930416568,
                        322.0109438162972
                    ],
                    [
                        140.16476601555087,
                        320.03619453787593
                    ],
                    [
                        141.19898503816717,
                        319.0801600616893
                    ],
                    [
                        139.6026904597809,
                        317.7411489488714
                    ],
                    [
                        140.34463019339705,
                        317.16708521653703
                    ],
                    [
                        139.8275206820888,
                        315.57182523303993
                    ],
                    [
                        140.38959623785877,
                        314.3375106050478
                    ],
                    [
                        142.165754994091,
                        314.61421656530337
                    ],
                    [
                        142.86272868324522,
                        315.6994806846033
                    ],
                    [
                        144.32412512824658,
                        315.67820519539083
                    ],
                    [
                        144.7513025506314,
                        317.1883490014404
                    ],
                    [
                        143.67211748355362,
                        318.80391004417044
                    ],
                    [
                        145.3133781064014,
                        320.31232012749115
                    ],
                    [
                        147.4042991738645,
                        320.07867720860395
                    ],
                    [
                        147.7190614850956,
                        321.45900613269765
                    ],
                    [
                        148.4385181964808,
                        321.6076156151965
                    ],
                    [
                        150.9566166863292,
                        323.53881124142873
                    ],
                    [
                        150.93413366409845,
                        322.56277109662386
                    ],
                    [
                        152.84519055371538,
                        323.178140826076
                    ],
                    [
                        151.8559375755608,
                        324.1327544421928
                    ],
                    [
                        151.36131108648328,
                        325.9774126158675
                    ],
                    [
                        150.48447321948242,
                        327.3759972228538
                    ],
                    [
                        149.45025419686635,
                        327.2700682364293
                    ],
                    [
                        147.29188406271055,
                        326.3801069678324
                    ],
                    [
                        147.17946895155683,
                        327.10057355399744
                    ],
                    [
                        145.96538575109412,
                        326.57083672077977
                    ],
                    [
                        145.22344601747818,
                        325.25665692850254
                    ],
                    [
                        143.64963446132288,
                        324.28122040344687
                    ],
                    [
                        141.626162460552,
                        326.14697519187723
                    ],
                    [
                        138.81578468170324,
                        325.2990594705033
                    ],
                    [
                        138.41109028154938,
                        325.12944543634273
                    ],
                    [
                        138.0738449480873,
                        325.0234314278245
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Sangareddy",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Siddipet",
            "dt_code": "741",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        164.31153189141764,
                        318.74015606813134
                    ],
                    [
                        163.20986380210888,
                        318.7189044116174
                    ],
                    [
                        162.31054291287728,
                        320.5671806115781
                    ],
                    [
                        159.7924444230291,
                        320.6946019786644
                    ],
                    [
                        159.00553864495146,
                        321.3103886288166
                    ],
                    [
                        158.24111588910455,
                        323.19935803222694
                    ],
                    [
                        156.89213455525714,
                        323.7297354136108
                    ],
                    [
                        156.08274575494897,
                        323.26300867577316
                    ],
                    [
                        153.58713028733155,
                        323.45395183569565
                    ],
                    [
                        154.19417188756256,
                        321.11929718630785
                    ],
                    [
                        154.0592737541781,
                        319.35638213392724
                    ],
                    [
                        154.75624744333254,
                        316.9544381970217
                    ],
                    [
                        154.59886628771687,
                        315.7633061459938
                    ],
                    [
                        154.59886628771687,
                        315.1675431002242
                    ],
                    [
                        155.70053437702563,
                        314.6355004594053
                    ],
                    [
                        157.27434593318048,
                        315.1888226191328
                    ],
                    [
                        160.7816974011837,
                        314.59293250272367
                    ],
                    [
                        161.2313578457995,
                        313.69884954102423
                    ],
                    [
                        162.91758451310852,
                        314.4013683555319
                    ],
                    [
                        162.53537313518518,
                        315.1888226191328
                    ],
                    [
                        163.34476193549358,
                        316.16749904104955
                    ],
                    [
                        164.55884513595606,
                        315.7633061459938
                    ],
                    [
                        165.39071695849543,
                        316.5928999660924
                    ],
                    [
                        164.31153189141764,
                        318.74015606813134
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Siddipet",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Suryapet",
            "dt_code": "748",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        176.4748469182739,
                        334.46424086335946
                    ],
                    [
                        176.22753367373548,
                        335.28830040873225
                    ],
                    [
                        176.72216016281277,
                        336.8301412106184
                    ],
                    [
                        176.4748469182739,
                        337.9490482492745
                    ],
                    [
                        174.90103536211882,
                        339.616059271383
                    ],
                    [
                        173.84433331727178,
                        338.66661218067463
                    ],
                    [
                        172.60776709457832,
                        338.24453705008614
                    ],
                    [
                        170.94402344950004,
                        338.7299182075236
                    ],
                    [
                        170.76415927165363,
                        336.45003730283213
                    ],
                    [
                        170.0222195380377,
                        335.20379228821287
                    ],
                    [
                        169.6624911823451,
                        332.73083412978826
                    ],
                    [
                        168.67323820419028,
                        331.165640443293
                    ],
                    [
                        167.57157011488198,
                        328.7103611766247
                    ],
                    [
                        166.40245295888076,
                        326.5920281145424
                    ],
                    [
                        166.58231713672717,
                        326.14697519187723
                    ],
                    [
                        168.87558540426744,
                        325.9562165714439
                    ],
                    [
                        172.0007254943473,
                        327.2488819601609
                    ],
                    [
                        172.76514825019376,
                        329.0491468244974
                    ],
                    [
                        174.20406167296437,
                        330.10759079436394
                    ],
                    [
                        173.91178238396424,
                        331.35604764336426
                    ],
                    [
                        176.25001669596622,
                        333.2382808573715
                    ],
                    [
                        176.4748469182739,
                        334.46424086335946
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Suryapet",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Vikarabad",
            "dt_code": "743",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        138.41109028154938,
                        334.71782267543756
                    ],
                    [
                        138.86075072616495,
                        333.36512852903206
                    ],
                    [
                        138.36612423708766,
                        332.32904178301135
                    ],
                    [
                        136.9047277920863,
                        331.58875029975655
                    ],
                    [
                        137.1295580143942,
                        330.5943424977285
                    ],
                    [
                        138.6584035260878,
                        328.5621296847546
                    ],
                    [
                        139.08558094847285,
                        327.3971825412574
                    ],
                    [
                        141.626162460552,
                        326.14697519187723
                    ],
                    [
                        143.64963446132288,
                        324.28122040344687
                    ],
                    [
                        145.22344601747818,
                        325.25665692850254
                    ],
                    [
                        145.96538575109412,
                        326.57083672077977
                    ],
                    [
                        147.17946895155683,
                        327.10057355399744
                    ],
                    [
                        146.32511410678694,
                        328.9644542177961
                    ],
                    [
                        147.2694010404798,
                        330.46737182676316
                    ],
                    [
                        146.10028388447904,
                        332.4347820742237
                    ],
                    [
                        145.11103090632423,
                        333.0479988470861
                    ],
                    [
                        146.23518201786374,
                        334.33744160267224
                    ],
                    [
                        144.99861579517028,
                        335.2249195496663
                    ],
                    [
                        142.72783054986053,
                        333.9781467508817
                    ],
                    [
                        142.05333988293705,
                        333.4074098421323
                    ],
                    [
                        140.88422272693606,
                        333.8724632684343
                    ],
                    [
                        140.3221471711663,
                        335.330553543842
                    ],
                    [
                        138.41109028154938,
                        334.71782267543756
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Vikarabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Wanaparthy",
            "dt_code": "745",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        150.0123297526361,
                        349.22007068610407
                    ],
                    [
                        149.89991464148216,
                        349.78777215339574
                    ],
                    [
                        148.57341632986572,
                        350.10311636015615
                    ],
                    [
                        145.4707592620166,
                        347.53737162486726
                    ],
                    [
                        145.24592903970893,
                        346.40102237328745
                    ],
                    [
                        142.95266077216843,
                        344.6535769092359
                    ],
                    [
                        141.2664341048594,
                        343.8110713544078
                    ],
                    [
                        142.59293241647583,
                        343.17903641933117
                    ],
                    [
                        145.02109881740103,
                        342.58901630796566
                    ],
                    [
                        145.98786877332486,
                        341.6616053556732
                    ],
                    [
                        146.34759712901746,
                        340.01684490397133
                    ],
                    [
                        148.03382379632671,
                        340.2699445860815
                    ],
                    [
                        147.7190614850956,
                        341.8934852443356
                    ],
                    [
                        150.86668459740622,
                        343.53720596979355
                    ],
                    [
                        149.13549188563525,
                        346.1694914659786
                    ],
                    [
                        149.58515233025105,
                        346.67462693273967
                    ],
                    [
                        149.22542397455845,
                        348.52606999685526
                    ],
                    [
                        150.0123297526361,
                        349.22007068610407
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Wanaparthy",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Warangal Rural",
            "dt_code": "749",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        169.37021189334496,
                        324.45088608333305
                    ],
                    [
                        168.15612869288225,
                        323.8145863853151
                    ],
                    [
                        168.51585704857507,
                        321.77744520815463
                    ],
                    [
                        170.08966860473015,
                        321.24669295608896
                    ],
                    [
                        170.8316083383461,
                        318.18755913748385
                    ],
                    [
                        169.81987233796053,
                        317.1032928616189
                    ],
                    [
                        169.95477047134546,
                        315.6994806846033
                    ],
                    [
                        169.46014398226816,
                        314.84833013741405
                    ],
                    [
                        171.1913366940389,
                        314.31622435089565
                    ],
                    [
                        171.596031094193,
                        315.5931015610648
                    ],
                    [
                        173.30474078373277,
                        316.55036288322094
                    ],
                    [
                        174.7211711842724,
                        317.315928211171
                    ],
                    [
                        175.5305599845808,
                        316.93317257769513
                    ],
                    [
                        175.5755260290423,
                        320.31232012749115
                    ],
                    [
                        174.33895980634907,
                        321.4802365497558
                    ],
                    [
                        174.09164656181042,
                        322.7749830157573
                    ],
                    [
                        171.12388762734645,
                        323.45395183569565
                    ],
                    [
                        170.5618120715767,
                        324.34484624608785
                    ],
                    [
                        169.37021189334496,
                        324.45088608333305
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Warangal Rural",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Warangal Urban",
            "dt_code": "540",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        168.51585704857507,
                        321.77744520815463
                    ],
                    [
                        168.3584758929594,
                        320.6946019786644
                    ],
                    [
                        164.8960904694179,
                        319.3351352736165
                    ],
                    [
                        164.31153189141764,
                        318.74015606813134
                    ],
                    [
                        165.39071695849543,
                        316.5928999660924
                    ],
                    [
                        167.75143429272794,
                        316.0611383525464
                    ],
                    [
                        167.9088154483436,
                        315.0398624563554
                    ],
                    [
                        169.23531375996026,
                        314.5290793039669
                    ],
                    [
                        169.46014398226816,
                        314.84833013741405
                    ],
                    [
                        169.95477047134546,
                        315.6994806846033
                    ],
                    [
                        169.81987233796053,
                        317.1032928616189
                    ],
                    [
                        170.8316083383461,
                        318.18755913748385
                    ],
                    [
                        170.08966860473015,
                        321.24669295608896
                    ],
                    [
                        168.51585704857507,
                        321.77744520815463
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Warangal Urban",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Yadadri Bhuvanagiri",
            "dt_code": "747",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        159.00553864495146,
                        321.3103886288166
                    ],
                    [
                        160.8266634456454,
                        321.7986731714571
                    ],
                    [
                        161.3212899347227,
                        323.0296158319483
                    ],
                    [
                        162.87261846864703,
                        323.666095482553
                    ],
                    [
                        163.18738077987814,
                        324.9810246958987
                    ],
                    [
                        164.71622629157173,
                        326.7615535005354
                    ],
                    [
                        166.40245295888076,
                        326.5920281145424
                    ],
                    [
                        167.57157011488198,
                        328.7103611766247
                    ],
                    [
                        164.64877722487927,
                        328.4985995209551
                    ],
                    [
                        163.97428655795557,
                        329.9805983739435
                    ],
                    [
                        162.46792406849295,
                        330.29806877931736
                    ],
                    [
                        160.6917653122605,
                        329.72659648588865
                    ],
                    [
                        160.24210486764468,
                        331.9906465998031
                    ],
                    [
                        159.3427839784133,
                        333.34398763924565
                    ],
                    [
                        158.6233272670279,
                        332.92113716714334
                    ],
                    [
                        157.65655731110428,
                        331.6945179648401
                    ],
                    [
                        158.28608193356604,
                        330.44620949716926
                    ],
                    [
                        157.3193119776422,
                        330.00176417180086
                    ],
                    [
                        157.09448175533453,
                        328.0750285447009
                    ],
                    [
                        157.29682895541146,
                        326.7615535005354
                    ],
                    [
                        156.19516086610292,
                        325.15064775451305
                    ],
                    [
                        156.89213455525714,
                        323.7297354136108
                    ],
                    [
                        158.24111588910455,
                        323.19935803222694
                    ],
                    [
                        159.00553864495146,
                        321.3103886288166
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Yadadri Bhuvanagiri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "South 24 Parganas",
            "dt_code": "343",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            295.11775523014444,
                            261.1642556092064
                        ],
                        [
                            293.7462908740663,
                            260.9467430852385
                        ],
                        [
                            294.80299291891356,
                            258.5091808522876
                        ],
                        [
                            295.7247968303757,
                            258.8140193186129
                        ],
                        [
                            295.79224589706814,
                            260.1635260111431
                        ],
                        [
                            295.11775523014444,
                            261.1642556092064
                        ]
                    ]
                ],
                [
                    [
                        [
                            308.20287416846327,
                            259.510639064621
                        ],
                        [
                            309.34950830223374,
                            261.51223251374955
                        ],
                        [
                            308.54011950192535,
                            262.16454633377396
                        ],
                        [
                            308.20287416846327,
                            259.510639064621
                        ]
                    ]
                ],
                [
                    [
                        [
                            294.48823060768245,
                            251.8577908110779
                        ],
                        [
                            294.6006457188362,
                            250.2410209396034
                        ],
                        [
                            295.7697628748374,
                            248.33871238924826
                        ],
                        [
                            296.7814988752227,
                            247.35411524636334
                        ],
                        [
                            298.3103443869163,
                            247.98868388018843
                        ],
                        [
                            299.36704643176336,
                            247.15714289738588
                        ],
                        [
                            300.94085798791866,
                            247.06959396039434
                        ],
                        [
                            301.52541656591916,
                            246.0844275949662
                        ],
                        [
                            302.4472204773815,
                            246.7850357981289
                        ],
                        [
                            303.6163376333825,
                            249.1042059785869
                        ],
                        [
                            305.370013367384,
                            250.10987980142187
                        ],
                        [
                            305.1002171006144,
                            250.7873587857751
                        ],
                        [
                            308.78743274646376,
                            253.97529227378982
                        ],
                        [
                            308.9897799465409,
                            256.81004243412303
                        ],
                        [
                            308.09045905730954,
                            257.55084966630375
                        ],
                        [
                            306.9887909680008,
                            256.9189999354585
                        ],
                        [
                            305.8196738119998,
                            257.22405293154367
                        ],
                        [
                            304.49317550038313,
                            256.7228726402522
                        ],
                        [
                            304.49317550038313,
                            255.76378208633906
                        ],
                        [
                            302.58211861076643,
                            256.7228726402522
                        ],
                        [
                            301.0982391435341,
                            258.3349690166874
                        ],
                        [
                            299.8167068763794,
                            257.31120335399106
                        ],
                        [
                            299.0298010983015,
                            257.6379874741467
                        ],
                        [
                            298.55765763145496,
                            259.0970468948286
                        ],
                        [
                            297.208676297608,
                            260.3158393248062
                        ],
                        [
                            297.72578580891604,
                            261.2295053212104
                        ],
                        [
                            297.09626118645406,
                            263.0340088607277
                        ],
                        [
                            296.3992874972994,
                            259.44534004859435
                        ],
                        [
                            295.4100345191446,
                            256.7228726402522
                        ],
                        [
                            296.3318384306069,
                            255.58935803196744
                        ],
                        [
                            296.48921958622236,
                            254.36800989885
                        ],
                        [
                            295.9721100749143,
                            253.03685412306936
                        ],
                        [
                            294.48823060768245,
                            251.8577908110779
                        ]
                    ]
                ],
                [
                    [
                        [
                            304.6955227004603,
                            258.09540584249646
                        ],
                        [
                            305.16766616730683,
                            261.25125481047445
                        ],
                        [
                            304.2458622558445,
                            261.3599991470084
                        ],
                        [
                            303.4364734555361,
                            259.9459178675629
                        ],
                        [
                            303.7962018112287,
                            257.50727950153583
                        ],
                        [
                            304.6955227004603,
                            258.09540584249646
                        ]
                    ]
                ],
                [
                    [
                        [
                            307.1461721236162,
                            257.35477730314017
                        ],
                        [
                            307.2136211903087,
                            259.6629961329742
                        ],
                        [
                            305.34753034515325,
                            257.1586879055543
                        ],
                        [
                            306.24685123438485,
                            257.37656396225395
                        ],
                        [
                            307.1461721236162,
                            257.35477730314017
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-South 24 Parganas",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Amreli",
            "dt_code": "480",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        49.10852598085762,
                        256.3087700450503
                    ],
                    [
                        50.187711047935636,
                        257.1368991426779
                    ],
                    [
                        50.007846870089224,
                        258.05184617579437
                    ],
                    [
                        52.00883584862936,
                        260.9467430852385
                    ],
                    [
                        51.3568282039364,
                        262.99054357083503
                    ],
                    [
                        50.36757522578205,
                        264.2072606570708
                    ],
                    [
                        52.368564204321956,
                        264.85880861040016
                    ],
                    [
                        52.525945359937396,
                        266.6387680532398
                    ],
                    [
                        51.15448100385947,
                        267.2462455835281
                    ],
                    [
                        51.06454891493627,
                        268.8726351282146
                    ],
                    [
                        52.480979315475906,
                        270.02126616882464
                    ],
                    [
                        53.290368115784304,
                        271.86223394699664
                    ],
                    [
                        50.83971869262837,
                        272.68478832037374
                    ],
                    [
                        49.76053362555058,
                        273.8748194601293
                    ],
                    [
                        48.05182393601058,
                        274.22089782779216
                    ],
                    [
                        46.36559726870155,
                        275.0210104029956
                    ],
                    [
                        45.96090286854724,
                        273.68012812207587
                    ],
                    [
                        46.567944468778705,
                        272.48999878147566
                    ],
                    [
                        45.15151406823884,
                        272.38177541019905
                    ],
                    [
                        45.308895223854506,
                        270.73616652675184
                    ],
                    [
                        43.42032135646832,
                        269.19777673670075
                    ],
                    [
                        41.19450215562006,
                        269.1761020395825
                    ],
                    [
                        40.45256242200412,
                        268.1137948788485
                    ],
                    [
                        42.49851744500597,
                        266.89913505995895
                    ],
                    [
                        42.20623815600561,
                        264.51133931372397
                    ],
                    [
                        40.85725682215843,
                        264.598211552343
                    ],
                    [
                        41.32940028900498,
                        262.36020419253236
                    ],
                    [
                        40.295181266388454,
                        261.2947531669961
                    ],
                    [
                        40.40759637754263,
                        260.0982457545604
                    ],
                    [
                        43.01562695631401,
                        260.3375975372823
                    ],
                    [
                        44.02736295669956,
                        259.88063136278845
                    ],
                    [
                        43.78004971216069,
                        258.00828567001014
                    ],
                    [
                        45.2189631349313,
                        256.83183435575734
                    ],
                    [
                        48.18672206939527,
                        255.50214092574572
                    ],
                    [
                        49.10852598085762,
                        256.3087700450503
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Amreli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Angul",
            "dt_code": "384",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        254.96307752595612,
                        268.9159899623066
                    ],
                    [
                        253.16443574749337,
                        269.046049627439
                    ],
                    [
                        252.55739414726213,
                        270.67118460323945
                    ],
                    [
                        252.9171225029545,
                        275.0210104029956
                    ],
                    [
                        251.95035254703066,
                        276.51239235588304
                    ],
                    [
                        250.84868445772213,
                        276.79327277970197
                    ],
                    [
                        251.0285486355683,
                        277.8516789280271
                    ],
                    [
                        250.26412587972163,
                        278.3915016416357
                    ],
                    [
                        247.65609530095026,
                        278.3699110690601
                    ],
                    [
                        246.93663858956484,
                        279.2333823992534
                    ],
                    [
                        245.74503841133333,
                        278.04602912647726
                    ],
                    [
                        243.8564645439469,
                        277.72210335817573
                    ],
                    [
                        242.70983041017666,
                        276.6852457635207
                    ],
                    [
                        239.76455449794344,
                        275.49662509734236
                    ],
                    [
                        239.58469032009702,
                        274.43717111362605
                    ],
                    [
                        238.3930901418653,
                        273.68012812207587
                    ],
                    [
                        238.5729543197117,
                        273.0310410903229
                    ],
                    [
                        239.96690169802037,
                        272.51164285896385
                    ],
                    [
                        241.54071325417567,
                        270.5412153488389
                    ],
                    [
                        243.06955876586926,
                        270.8444657286518
                    ],
                    [
                        244.28364196633174,
                        270.34624794224726
                    ],
                    [
                        245.40779307787125,
                        269.1327520412059
                    ],
                    [
                        247.3638160119499,
                        269.2194512324606
                    ],
                    [
                        248.28561992341224,
                        268.72088685758655
                    ],
                    [
                        247.65609530095026,
                        267.7017493603277
                    ],
                    [
                        249.34232196825928,
                        267.11608522539916
                    ],
                    [
                        249.05004267925915,
                        265.31478257953853
                    ],
                    [
                        249.54466916833644,
                        264.75023008527967
                    ],
                    [
                        251.43324303572263,
                        264.51133931372397
                    ],
                    [
                        252.15269974710782,
                        263.57725550436294
                    ],
                    [
                        251.2983449023377,
                        262.42541976116024
                    ],
                    [
                        251.9728355692614,
                        261.38174739247296
                    ],
                    [
                        252.9171225029545,
                        261.1642556092064
                    ],
                    [
                        254.0862396589555,
                        264.2072606570708
                    ],
                    [
                        253.6365792143397,
                        265.2496489599834
                    ],
                    [
                        254.19865477010967,
                        266.0311311537111
                    ],
                    [
                        253.34429992533978,
                        267.0943911222577
                    ],
                    [
                        254.96307752595612,
                        268.9159899623066
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Angul",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dang",
            "dt_code": "489",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            85.21625968350395,
                            271.8189336445579
                        ],
                        [
                            85.21625968350395,
                            271.84058389546976
                        ],
                        [
                            84.60921808327248,
                            271.6673763040509
                        ],
                        [
                            85.21625968350395,
                            271.8189336445579
                        ]
                    ]
                ],
                [
                    [
                        [
                            86.58772403958187,
                            274.3290369398761
                        ],
                        [
                            86.2279956838895,
                            276.14503722256995
                        ],
                        [
                            85.59847106142706,
                            276.0585924836837
                        ],
                        [
                            85.05887852788828,
                            277.7652959947034
                        ],
                        [
                            83.68741417181013,
                            278.65057334408
                        ],
                        [
                            82.33843283796296,
                            278.65057334408
                        ],
                        [
                            81.7763572821932,
                            277.70050674738934
                        ],
                        [
                            79.7079192369606,
                            276.209868716718
                        ],
                        [
                            80.02268154819171,
                            274.6534246541515
                        ],
                        [
                            79.9327494592685,
                            273.160872774634
                        ],
                        [
                            81.03441754857727,
                            271.645724457481
                        ],
                        [
                            83.79982928296408,
                            271.7106793986653
                        ],
                        [
                            84.45183692765704,
                            272.4683545050863
                        ],
                        [
                            85.80081826150422,
                            272.8579210614112
                        ],
                        [
                            86.58772403958187,
                            274.3290369398761
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Dang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dhamtari",
            "dt_code": "412",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        202.33032248368067,
                        284.8814947940652
                    ],
                    [
                        201.74576390568018,
                        283.86925076233115
                    ],
                    [
                        202.03804319468054,
                        282.3824275334123
                    ],
                    [
                        200.53168070521747,
                        279.9670890573811
                    ],
                    [
                        199.1377333269088,
                        279.3628763150075
                    ],
                    [
                        197.0692952816762,
                        279.4492017157752
                    ],
                    [
                        196.3948046147525,
                        278.8232722638979
                    ],
                    [
                        197.67633688190767,
                        277.93805874255867
                    ],
                    [
                        197.94613314867684,
                        276.317917284404
                    ],
                    [
                        197.3840575929073,
                        275.3236853217327
                    ],
                    [
                        197.2941255039841,
                        273.85318788093167
                    ],
                    [
                        199.02531821575485,
                        272.74971458848853
                    ],
                    [
                        199.3625635492167,
                        271.45084885757086
                    ],
                    [
                        201.3635525277566,
                        271.62407241138146
                    ],
                    [
                        202.33032248368067,
                        272.66314583351203
                    ],
                    [
                        203.09474523952758,
                        272.4034204823528
                    ],
                    [
                        203.92661706206673,
                        275.71278211777366
                    ],
                    [
                        204.7809719068366,
                        276.14503722256995
                    ],
                    [
                        205.3655304848371,
                        279.2333823992534
                    ],
                    [
                        206.30981741853043,
                        281.06723012216895
                    ],
                    [
                        206.64706275199205,
                        282.94278658313283
                    ],
                    [
                        207.7037647968391,
                        283.4814714823169
                    ],
                    [
                        206.5346476408381,
                        285.85027178905193
                    ],
                    [
                        205.32056444037562,
                        286.7326019392257
                    ],
                    [
                        204.26386239552858,
                        285.85027178905193
                    ],
                    [
                        203.22964337291228,
                        286.689568800756
                    ],
                    [
                        202.33032248368067,
                        284.8814947940652
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Dhamtari",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kendrapara",
            "dt_code": "379",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        273.0169443772795,
                        275.0210104029956
                    ],
                    [
                        275.0404163780504,
                        275.47500831389436
                    ],
                    [
                        275.7149070449741,
                        275.97214460478534
                    ],
                    [
                        276.5242958452825,
                        275.34530348229924
                    ],
                    [
                        278.90749620174597,
                        275.4101567833875
                    ],
                    [
                        279.4021226908235,
                        276.29630796304826
                    ],
                    [
                        275.46759380043545,
                        279.1254654705514
                    ],
                    [
                        274.70317104458877,
                        280.2691385576315
                    ],
                    [
                        275.28772962258904,
                        282.25309552412483
                    ],
                    [
                        274.5008238445114,
                        282.8350352728385
                    ],
                    [
                        272.7471481105099,
                        282.166870351132
                    ],
                    [
                        270.52132890966186,
                        282.33931763017455
                    ],
                    [
                        269.666974064892,
                        281.77881909209145
                    ],
                    [
                        267.5310869529669,
                        281.77881909209145
                    ],
                    [
                        267.89081530865974,
                        281.433832409742
                    ],
                    [
                        267.5310869529669,
                        279.0607129856434
                    ],
                    [
                        267.98074739758295,
                        278.3915016416357
                    ],
                    [
                        269.41966082035333,
                        278.19717948360915
                    ],
                    [
                        270.52132890966186,
                        276.9228987409674
                    ],
                    [
                        271.6005139767399,
                        276.70685155826664
                    ],
                    [
                        272.1625895325096,
                        275.34530348229924
                    ],
                    [
                        273.0169443772795,
                        275.0210104029956
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Kendrapara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dadra and Nagar Haveli",
            "dt_code": "496",
            "st_nm": "Dadra and Nagar Haveli and Daman and Diu",
            "st_code": "26",
            "alpha2code": "DN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        72.17610678964661,
                        284.23543007389594
                    ],
                    [
                        71.74892936726178,
                        282.8781363706169
                    ],
                    [
                        73.36770696787835,
                        282.4039821976396
                    ],
                    [
                        75.1888317685723,
                        282.98588576889335
                    ],
                    [
                        74.71668830172575,
                        284.3861938065795
                    ],
                    [
                        75.84083941326503,
                        285.61349504214576
                    ],
                    [
                        74.76165434618724,
                        286.1515909311974
                    ],
                    [
                        72.2210728341081,
                        285.505861680397
                    ],
                    [
                        72.17610678964661,
                        284.23543007389594
                    ]
                ]
            ]
        },
        "id": "TT-Dadra and Nagar Haveli and Daman and Diu-Dadra and Nagar Haveli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Palghar",
            "dt_code": "732",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        75.84083941326503,
                        285.61349504214576
                    ],
                    [
                        76.40291496903478,
                        285.59196874830667
                    ],
                    [
                        77.27975283603541,
                        286.62501767972105
                    ],
                    [
                        78.7186662588058,
                        287.20591675044864
                    ],
                    [
                        79.37067390349898,
                        291.1824793761067
                    ],
                    [
                        79.86530039257627,
                        291.80525532755274
                    ],
                    [
                        76.94250750257356,
                        292.29907059938074
                    ],
                    [
                        75.84083941326503,
                        294.35916166409544
                    ],
                    [
                        73.5700541679555,
                        295.6458618414626
                    ],
                    [
                        72.10865772295415,
                        296.86762024312446
                    ],
                    [
                        71.25430287818426,
                        298.6242063924207
                    ],
                    [
                        69.77042341095216,
                        298.36722072773574
                    ],
                    [
                        68.6013062549514,
                        294.7023459465084
                    ],
                    [
                        68.55634021048968,
                        293.0288797975515
                    ],
                    [
                        67.6570193212583,
                        290.06538744305016
                    ],
                    [
                        67.79191745464277,
                        288.5393147749173
                    ],
                    [
                        68.80365345502832,
                        287.9372243010922
                    ],
                    [
                        68.44392509933573,
                        286.34527654727094
                    ],
                    [
                        69.43317807749031,
                        285.44127939184284
                    ],
                    [
                        70.87209150026092,
                        283.95541555045895
                    ],
                    [
                        72.17610678964661,
                        284.23543007389594
                    ],
                    [
                        72.2210728341081,
                        285.505861680397
                    ],
                    [
                        74.76165434618724,
                        286.1515909311974
                    ],
                    [
                        75.84083941326503,
                        285.61349504214576
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Palghar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ganjam",
            "dt_code": "388",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        237.898463652788,
                        294.16609993555306
                    ],
                    [
                        237.9883957417112,
                        292.7283958997647
                    ],
                    [
                        236.8192785857102,
                        291.5905225673202
                    ],
                    [
                        237.1790069414028,
                        289.67858471558935
                    ],
                    [
                        238.50550525301924,
                        289.3776964312693
                    ],
                    [
                        240.03435076471283,
                        289.91497132551416
                    ],
                    [
                        241.24843396517554,
                        289.7430560253199
                    ],
                    [
                        241.27091698740628,
                        288.2382878944687
                    ],
                    [
                        240.19173192032827,
                        288.195281058432
                    ],
                    [
                        239.80952054240515,
                        287.09835320109283
                    ],
                    [
                        241.1360188540216,
                        284.8814947940652
                    ],
                    [
                        241.63064534309888,
                        285.2905807572421
                    ],
                    [
                        243.09204178810023,
                        284.773829212109
                    ],
                    [
                        244.82323449987098,
                        283.1367268987713
                    ],
                    [
                        246.68932534502642,
                        284.10619659849414
                    ],
                    [
                        246.1946988559489,
                        286.08702566077744
                    ],
                    [
                        247.7235443676425,
                        287.63612395185214
                    ],
                    [
                        248.28561992341224,
                        289.0982674404649
                    ],
                    [
                        249.22990685710533,
                        290.5595476936292
                    ],
                    [
                        251.18592979118398,
                        291.5905225673202
                    ],
                    [
                        251.86042045810746,
                        292.6210714815808
                    ],
                    [
                        251.70303930249202,
                        295.81737230170785
                    ],
                    [
                        250.3315749464141,
                        297.2533163268572
                    ],
                    [
                        248.89266152364348,
                        298.17446447106767
                    ],
                    [
                        246.39704605602606,
                        300.65776450157654
                    ],
                    [
                        245.72255538910235,
                        301.89851303715903
                    ],
                    [
                        244.77826845540926,
                        301.2568212788392
                    ],
                    [
                        243.631634321639,
                        301.7701874765389
                    ],
                    [
                        243.15949085479224,
                        302.7324735982772
                    ],
                    [
                        242.03533974325296,
                        302.3262187821396
                    ],
                    [
                        240.68635840940578,
                        303.88674427768785
                    ],
                    [
                        239.67462240902023,
                        302.9676445638061
                    ],
                    [
                        240.14676587586678,
                        301.214036139142
                    ],
                    [
                        239.76455449794344,
                        300.16557755784766
                    ],
                    [
                        240.7313244538675,
                        299.05245830694304
                    ],
                    [
                        240.66387538717504,
                        297.6817988327573
                    ],
                    [
                        240.03435076471283,
                        297.10333037095444
                    ],
                    [
                        239.96690169802037,
                        295.49578063660283
                    ],
                    [
                        240.37159609817468,
                        294.1446477175033
                    ],
                    [
                        239.40482614225084,
                        293.84429743935857
                    ],
                    [
                        237.898463652788,
                        294.16609993555306
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Ganjam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gajapati",
            "dt_code": "389",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        233.58172338447662,
                        293.20056870153803
                    ],
                    [
                        236.36961814109418,
                        293.28640874390254
                    ],
                    [
                        237.898463652788,
                        294.16609993555306
                    ],
                    [
                        239.40482614225084,
                        293.84429743935857
                    ],
                    [
                        240.37159609817468,
                        294.1446477175033
                    ],
                    [
                        239.96690169802037,
                        295.49578063660283
                    ],
                    [
                        240.03435076471283,
                        297.10333037095444
                    ],
                    [
                        240.66387538717504,
                        297.6817988327573
                    ],
                    [
                        240.7313244538675,
                        299.05245830694304
                    ],
                    [
                        239.76455449794344,
                        300.16557755784766
                    ],
                    [
                        240.14676587586678,
                        301.214036139142
                    ],
                    [
                        239.67462240902023,
                        302.9676445638061
                    ],
                    [
                        240.68635840940578,
                        303.88674427768785
                    ],
                    [
                        240.8662225872522,
                        304.2713869696947
                    ],
                    [
                        239.15751289771197,
                        306.38590611835997
                    ],
                    [
                        237.7635655194033,
                        306.21509951831786
                    ],
                    [
                        235.7850595630939,
                        306.85556677976746
                    ],
                    [
                        234.99815378501626,
                        305.93751498421256
                    ],
                    [
                        232.86226667309143,
                        305.9588686878389
                    ],
                    [
                        231.42335325032082,
                        302.81799278820716
                    ],
                    [
                        233.73910454009206,
                        303.13866456231113
                    ],
                    [
                        234.95318774055454,
                        301.04288846077816
                    ],
                    [
                        234.16628196247711,
                        299.7803218419284
                    ],
                    [
                        234.77332356270836,
                        298.5813772471388
                    ],
                    [
                        234.68339147378515,
                        297.23189030423674
                    ],
                    [
                        233.69413849563034,
                        296.93190699544544
                    ],
                    [
                        234.16628196247711,
                        294.83102799541064
                    ],
                    [
                        233.58172338447662,
                        293.20056870153803
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Gajapati",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Y.S.R. Kadapa",
            "dt_code": "551",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        157.4092440665654,
                        361.81127870884177
                    ],
                    [
                        157.67904033333502,
                        361.58089087159954
                    ],
                    [
                        162.1756447794926,
                        361.9788231335506
                    ],
                    [
                        162.2655768684158,
                        363.88402885690874
                    ],
                    [
                        162.15316175726184,
                        364.88852146953013
                    ],
                    [
                        163.70449029118618,
                        369.0079007943372
                    ],
                    [
                        163.72697331341692,
                        370.4495155894442
                    ],
                    [
                        164.5138790914948,
                        370.80460032866256
                    ],
                    [
                        165.25581882511074,
                        372.45420632084733
                    ],
                    [
                        167.34673989257385,
                        376.10553069945354
                    ],
                    [
                        167.6390191815742,
                        378.27361320071884
                    ],
                    [
                        167.3692229148046,
                        379.44047854274595
                    ],
                    [
                        167.50412104818952,
                        381.4399095143807
                    ],
                    [
                        167.66150220380496,
                        382.8138570383493
                    ],
                    [
                        165.45816602518767,
                        383.9792114931791
                    ],
                    [
                        164.26656584695593,
                        383.45901135338335
                    ],
                    [
                        164.131667713571,
                        382.62653210940266
                    ],
                    [
                        163.14241473541642,
                        381.8354948613486
                    ],
                    [
                        159.25285188948988,
                        381.31497856328116
                    ],
                    [
                        155.70053437702563,
                        381.7938563962757
                    ],
                    [
                        155.04852673233245,
                        380.39868206352753
                    ],
                    [
                        153.09250379825403,
                        379.87795197527373
                    ],
                    [
                        153.0250547315618,
                        378.16940967485624
                    ],
                    [
                        152.2156659312534,
                        376.75192908967995
                    ],
                    [
                        152.44049615356107,
                        375.5007249349442
                    ],
                    [
                        150.43950717502116,
                        375.3755793407373
                    ],
                    [
                        147.8089935740188,
                        373.83173872879695
                    ],
                    [
                        146.25766504009448,
                        372.3915778487103
                    ],
                    [
                        148.23617099640364,
                        369.2586613078897
                    ],
                    [
                        146.93215570701796,
                        368.04647633459444
                    ],
                    [
                        148.2136879741729,
                        365.2023618579952
                    ],
                    [
                        149.49522024132807,
                        365.2860475288019
                    ],
                    [
                        150.50695624171317,
                        363.52819654028303
                    ],
                    [
                        152.41801313133033,
                        364.1351811134498
                    ],
                    [
                        153.38478308725416,
                        364.05146585334967
                    ],
                    [
                        153.87940957633168,
                        365.30696861124306
                    ],
                    [
                        155.52067019917922,
                        365.7462803919617
                    ],
                    [
                        157.45421011102712,
                        363.7793763466385
                    ],
                    [
                        157.4092440665654,
                        361.81127870884177
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-Y.S.R. Kadapa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tumakuru",
            "dt_code": "571",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            130.78934574531195,
                            378.35697376036944
                        ],
                        [
                            130.5420325007733,
                            379.3363100909173
                        ],
                        [
                            132.1608101013901,
                            380.81521021936544
                        ],
                        [
                            131.21652316769678,
                            382.4183816336855
                        ],
                        [
                            132.49805543485195,
                            383.68790881622556
                        ],
                        [
                            133.86951979092987,
                            382.7306027416779
                        ],
                        [
                            134.20676512439172,
                            381.7730369787499
                        ],
                        [
                            136.34265223631655,
                            382.31430178688527
                        ],
                        [
                            137.91646379247186,
                            382.1685848352115
                        ],
                        [
                            138.45605632601087,
                            383.45901135338335
                        ],
                        [
                            138.2537091259337,
                            384.6033510946153
                        ],
                        [
                            136.9047277920863,
                            385.41456912690865
                        ],
                        [
                            137.21949010331764,
                            387.16118393297256
                        ],
                        [
                            136.67989756977863,
                            388.01339063487865
                        ],
                        [
                            136.52251641416296,
                            389.94570277440937
                        ],
                        [
                            134.90373881354617,
                            390.19495875104235
                        ],
                        [
                            134.2292481466227,
                            391.46174788072705
                        ],
                        [
                            134.54401045785357,
                            392.12611853492547
                        ],
                        [
                            133.93696885762233,
                            392.02231850807084
                        ],
                        [
                            132.63295356823664,
                            393.039433161338
                        ],
                        [
                            132.5655045015444,
                            394.30524774461105
                        ],
                        [
                            133.86951979092987,
                            395.54989270314786
                        ],
                        [
                            133.4648253907758,
                            397.0429227523314
                        ],
                        [
                            133.75710467977592,
                            398.1415823159369
                        ],
                        [
                            132.67791961269836,
                            398.70115479630374
                        ],
                        [
                            130.42961738961935,
                            397.872129200919
                        ],
                        [
                            129.39539836700305,
                            395.2387702220977
                        ],
                        [
                            128.13634912207908,
                            394.40898397258684
                        ],
                        [
                            126.13536014353872,
                            394.86538917260566
                        ],
                        [
                            125.73066574338463,
                            393.84877407428723
                        ],
                        [
                            123.79712583153673,
                            393.51675809061504
                        ],
                        [
                            123.79712583153673,
                            392.6658316844481
                        ],
                        [
                            122.29076334207389,
                            391.8977546226213
                        ],
                        [
                            121.95351800861204,
                            389.4886893023142
                        ],
                        [
                            123.0102200534593,
                            388.13808696373474
                        ],
                        [
                            122.20083125315091,
                            386.66223778672816
                        ],
                        [
                            122.04345009753524,
                            386.1632224084706
                        ],
                        [
                            123.52732956476734,
                            385.9968685322332
                        ],
                        [
                            125.97797898792305,
                            383.8335631440639
                        ],
                        [
                            128.09138307761737,
                            383.1052316256296
                        ],
                        [
                            127.46185845515538,
                            381.33580069748643
                        ],
                        [
                            129.35043232254156,
                            380.3570265166055
                        ],
                        [
                            129.91250787831132,
                            379.06545749629254
                        ],
                        [
                            130.78934574531195,
                            378.35697376036944
                        ]
                    ]
                ],
                [
                    [
                        [
                            131.6212175678511,
                            376.23064957898316
                        ],
                        [
                            133.26247819069863,
                            376.3766158297702
                        ],
                        [
                            133.57724050192974,
                            374.68719645370254
                        ],
                        [
                            136.9047277920863,
                            375.6258659424137
                        ],
                        [
                            137.55673543677926,
                            374.68719645370254
                        ],
                        [
                            138.59095445939533,
                            375.3755793407373
                        ],
                        [
                            138.95068281508816,
                            377.4190514364095
                        ],
                        [
                            137.33190521447136,
                            377.1480485892632
                        ],
                        [
                            137.28693917000987,
                            379.5446438732567
                        ],
                        [
                            137.9389468147026,
                            380.58612587672064
                        ],
                        [
                            137.39935428116382,
                            381.2108660373848
                        ],
                        [
                            136.27520316962455,
                            379.3363100909173
                        ],
                        [
                            134.09435001323777,
                            379.60714157403197
                        ],
                        [
                            131.95846290131294,
                            378.8570949621379
                        ],
                        [
                            131.59873454562035,
                            376.7936280296344
                        ],
                        [
                            131.6212175678511,
                            376.23064957898316
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Tumakuru",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chittoor",
            "dt_code": "554",
            "st_nm": "Andhra Pradesh",
            "st_code": "37",
            "alpha2code": "AP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        165.12092069172581,
                        393.06018769518107
                    ],
                    [
                        164.31153189141764,
                        392.54128948551477
                    ],
                    [
                        164.40146398034085,
                        393.3299860751252
                    ],
                    [
                        163.34476193549358,
                        394.61644778743164
                    ],
                    [
                        160.87162949010667,
                        393.620516267623
                    ],
                    [
                        159.36526700064405,
                        393.4752540092643
                    ],
                    [
                        157.29682895541146,
                        394.0977666392871
                    ],
                    [
                        155.38577206579453,
                        395.05208434784544
                    ],
                    [
                        155.1159757990249,
                        397.2709726028056
                    ],
                    [
                        154.9585946434097,
                        398.203761094275
                    ],
                    [
                        152.30559802017638,
                        400.54509213272604
                    ],
                    [
                        150.6868204195598,
                        399.9650725353037
                    ],
                    [
                        149.6526013969435,
                        398.53536410504523
                    ],
                    [
                        149.9673637081744,
                        397.0221902666455
                    ],
                    [
                        150.84420157517548,
                        397.0429227523314
                    ],
                    [
                        151.47372619763723,
                        395.81951134430466
                    ],
                    [
                        153.114986820485,
                        395.30099678162685
                    ],
                    [
                        153.81196050963945,
                        393.9940217586318
                    ],
                    [
                        154.8686625544865,
                        390.75572264400955
                    ],
                    [
                        152.77774148702315,
                        390.2572700909077
                    ],
                    [
                        151.60862433102193,
                        389.4471397840182
                    ],
                    [
                        151.74352246440685,
                        387.2235473425733
                    ],
                    [
                        152.14821686456094,
                        386.0384577247579
                    ],
                    [
                        151.0465487752524,
                        385.9344838387466
                    ],
                    [
                        149.13549188563525,
                        386.3295685717369
                    ],
                    [
                        149.2479069967892,
                        385.41456912690865
                    ],
                    [
                        148.03382379632671,
                        384.1040481801888
                    ],
                    [
                        148.10127286301918,
                        383.02198419484705
                    ],
                    [
                        149.74253348586672,
                        383.1468546060611
                    ],
                    [
                        150.25964299717475,
                        381.3982663583955
                    ],
                    [
                        151.94586966448378,
                        380.73190855846724
                    ],
                    [
                        153.09250379825403,
                        379.87795197527373
                    ],
                    [
                        155.04852673233245,
                        380.39868206352753
                    ],
                    [
                        155.70053437702563,
                        381.7938563962757
                    ],
                    [
                        159.25285188948988,
                        381.31497856328116
                    ],
                    [
                        163.14241473541642,
                        381.8354948613486
                    ],
                    [
                        164.131667713571,
                        382.62653210940266
                    ],
                    [
                        164.26656584695593,
                        383.45901135338335
                    ],
                    [
                        165.45816602518767,
                        383.9792114931791
                    ],
                    [
                        167.66150220380496,
                        382.8138570383493
                    ],
                    [
                        167.50412104818952,
                        381.4399095143807
                    ],
                    [
                        169.6624911823451,
                        380.9609833538731
                    ],
                    [
                        169.887321404653,
                        382.0228618521887
                    ],
                    [
                        173.46212193934844,
                        383.29253118086547
                    ],
                    [
                        173.73191820611783,
                        384.2496854381628
                    ],
                    [
                        175.01345047327277,
                        384.2288804809424
                    ],
                    [
                        176.20505065150473,
                        386.7869808065768
                    ],
                    [
                        174.85606931765733,
                        389.4263648472654
                    ],
                    [
                        172.85508033911697,
                        390.13264634981897
                    ],
                    [
                        172.6302501168093,
                        391.295636495247
                    ],
                    [
                        171.28126878296212,
                        391.56556368987776
                    ],
                    [
                        170.98898949396175,
                        390.4441977459441
                    ],
                    [
                        168.80813633757498,
                        390.75572264400955
                    ],
                    [
                        168.8531023820367,
                        389.8002956033107
                    ],
                    [
                        166.9195624701888,
                        389.86261367117527
                    ],
                    [
                        167.39170593703557,
                        392.0845988743573
                    ],
                    [
                        165.12092069172581,
                        393.06018769518107
                    ]
                ]
            ]
        },
        "id": "TT-Andhra Pradesh-Chittoor",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ramanagara",
            "dt_code": "584",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        140.38959623785877,
                        399.92363921631704
                    ],
                    [
                        140.1872490377816,
                        402.2432198855558
                    ],
                    [
                        140.88422272693606,
                        402.6365824500783
                    ],
                    [
                        140.5919434379357,
                        404.4579556927715
                    ],
                    [
                        139.6476565042426,
                        405.5958852977216
                    ],
                    [
                        138.4785393482416,
                        406.3198518522173
                    ],
                    [
                        137.87149774801014,
                        405.6579447568983
                    ],
                    [
                        136.34265223631655,
                        405.34763772436156
                    ],
                    [
                        135.5107804137774,
                        402.9885047618873
                    ],
                    [
                        133.39737632408355,
                        402.71939055789966
                    ],
                    [
                        133.44234236854504,
                        401.3942491365217
                    ],
                    [
                        132.63295356823664,
                        399.73718374865393
                    ],
                    [
                        132.67791961269836,
                        398.70115479630374
                    ],
                    [
                        133.75710467977592,
                        398.1415823159369
                    ],
                    [
                        133.4648253907758,
                        397.0429227523314
                    ],
                    [
                        133.86951979092987,
                        395.54989270314786
                    ],
                    [
                        132.5655045015444,
                        394.30524774461105
                    ],
                    [
                        132.63295356823664,
                        393.039433161338
                    ],
                    [
                        133.93696885762233,
                        392.02231850807084
                    ],
                    [
                        134.54401045785357,
                        392.12611853492547
                    ],
                    [
                        136.16278805847037,
                        393.3092330495115
                    ],
                    [
                        136.41010130300901,
                        395.30099678162685
                    ],
                    [
                        136.58996548085543,
                        396.6075166850045
                    ],
                    [
                        137.7141165923947,
                        397.54046837106057
                    ],
                    [
                        139.53524139308888,
                        398.30739013522896
                    ],
                    [
                        140.5919434379357,
                        399.3020857415794
                    ],
                    [
                        140.38959623785877,
                        399.92363921631704
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Ramanagara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mandya",
            "dt_code": "573",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        121.8860689419198,
                        400.11008563373235
                    ],
                    [
                        121.97600103084301,
                        398.1623086882274
                    ],
                    [
                        122.51559356438179,
                        397.25024136555646
                    ],
                    [
                        124.0444390760756,
                        397.8306731807577
                    ],
                    [
                        125.34845436546107,
                        397.229510014879
                    ],
                    [
                        126.13536014353872,
                        394.86538917260566
                    ],
                    [
                        128.13634912207908,
                        394.40898397258684
                    ],
                    [
                        129.39539836700305,
                        395.2387702220977
                    ],
                    [
                        130.42961738961935,
                        397.872129200919
                    ],
                    [
                        132.67791961269836,
                        398.70115479630374
                    ],
                    [
                        132.63295356823664,
                        399.73718374865393
                    ],
                    [
                        133.44234236854504,
                        401.3942491365217
                    ],
                    [
                        133.39737632408355,
                        402.71939055789966
                    ],
                    [
                        135.5107804137774,
                        402.9885047618873
                    ],
                    [
                        136.34265223631655,
                        405.34763772436156
                    ],
                    [
                        134.0044179243148,
                        405.30626161317605
                    ],
                    [
                        133.15006307954468,
                        406.36121737979386
                    ],
                    [
                        131.4863194344664,
                        406.340534669844
                    ],
                    [
                        131.50880245669714,
                        405.2648850686768
                    ],
                    [
                        130.6319645896965,
                        403.96130151757234
                    ],
                    [
                        129.39539836700305,
                        403.83712815488593
                    ],
                    [
                        128.65345863338712,
                        404.64418483252507
                    ],
                    [
                        127.59675658854007,
                        404.830405163843
                    ],
                    [
                        123.48236352030563,
                        402.6986886959194
                    ],
                    [
                        121.8860689419198,
                        400.11008563373235
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Mandya",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mysuru",
            "dt_code": "577",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        117.11966822899262,
                        401.3942491365217
                    ],
                    [
                        118.51361560730152,
                        402.0568763621168
                    ],
                    [
                        120.06494414122585,
                        400.2758081925457
                    ],
                    [
                        121.34647640838102,
                        400.7936452223589
                    ],
                    [
                        121.8860689419198,
                        400.11008563373235
                    ],
                    [
                        123.48236352030563,
                        402.6986886959194
                    ],
                    [
                        127.59675658854007,
                        404.830405163843
                    ],
                    [
                        128.65345863338712,
                        404.64418483252507
                    ],
                    [
                        129.39539836700305,
                        403.83712815488593
                    ],
                    [
                        130.6319645896965,
                        403.96130151757234
                    ],
                    [
                        131.50880245669714,
                        405.2648850686768
                    ],
                    [
                        131.4863194344664,
                        406.340534669844
                    ],
                    [
                        133.15006307954468,
                        406.36121737979386
                    ],
                    [
                        133.35241027962184,
                        407.0230073228573
                    ],
                    [
                        131.756115701236,
                        407.5192776868852
                    ],
                    [
                        130.29471925623466,
                        409.5864099179596
                    ],
                    [
                        128.51856050000242,
                        410.1856800994047
                    ],
                    [
                        125.34845436546107,
                        410.1650170858313
                    ],
                    [
                        125.32597134323032,
                        411.1360651490263
                    ],
                    [
                        122.9202879645361,
                        413.6142912339898
                    ],
                    [
                        121.52634058622698,
                        413.71751827729065
                    ],
                    [
                        119.88507996337967,
                        412.0655753480642
                    ],
                    [
                        118.51361560730152,
                        412.10688201197706
                    ],
                    [
                        118.51361560730152,
                        410.28899358864646
                    ],
                    [
                        119.6377667188408,
                        409.23507232161984
                    ],
                    [
                        118.80589489630188,
                        407.1057233352127
                    ],
                    [
                        117.20960031791583,
                        406.2371195046828
                    ],
                    [
                        116.87235498445398,
                        405.01661669624195
                    ],
                    [
                        115.50089062837583,
                        403.9819966958238
                    ],
                    [
                        117.11966822899262,
                        401.3942491365217
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Mysuru",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kannur",
            "dt_code": "589",
            "st_nm": "Kerala",
            "st_code": "32",
            "alpha2code": "KL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        104.57414182421257,
                        408.63566107557654
                    ],
                    [
                        105.13621737998233,
                        407.1057233352127
                    ],
                    [
                        108.23887444783122,
                        405.6165718922423
                    ],
                    [
                        109.27309347044752,
                        405.6165718922423
                    ],
                    [
                        110.6220748042947,
                        407.62265958732576
                    ],
                    [
                        111.49891267129556,
                        407.7467143413026
                    ],
                    [
                        113.81466396106657,
                        409.1317319152436
                    ],
                    [
                        114.9388150726063,
                        410.7022212632785
                    ],
                    [
                        115.90558502852991,
                        410.8675005746163
                    ],
                    [
                        115.77068689514522,
                        412.3547132585145
                    ],
                    [
                        114.0394941833747,
                        412.1481882596728
                    ],
                    [
                        113.81466396106657,
                        413.1394136082257
                    ],
                    [
                        111.92609009368039,
                        413.6142912339898
                    ],
                    [
                        110.28482947083307,
                        414.46067703953213
                    ],
                    [
                        110.0375162262942,
                        414.3987522287599
                    ],
                    [
                        109.97006715960197,
                        414.3987522287599
                    ],
                    [
                        107.81169702544616,
                        412.12753518784575
                    ],
                    [
                        107.38451960306134,
                        412.08622873205434
                    ],
                    [
                        105.83319106913677,
                        409.8550592423301
                    ],
                    [
                        105.09125133552061,
                        409.87572383634495
                    ],
                    [
                        104.57414182421257,
                        408.63566107557654
                    ]
                ],
                [
                    [
                        110.30731249306382,
                        414.15104374638247
                    ],
                    [
                        110.10496529298666,
                        413.90332046441563
                    ],
                    [
                        110.0375162262942,
                        414.19232952059235
                    ],
                    [
                        110.30731249306382,
                        414.15104374638247
                    ]
                ]
            ]
        },
        "id": "TT-Kerala-Kannur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Puducherry",
            "dt_code": "635",
            "st_nm": "Puducherry",
            "st_code": "34",
            "alpha2code": "PY",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        171.03395553842324,
                        411.77641703789357
                    ],
                    [
                        172.49535198342437,
                        410.5782573728726
                    ],
                    [
                        173.30474078373277,
                        410.6402397904577
                    ],
                    [
                        172.83259731688622,
                        412.31340909035265
                    ],
                    [
                        171.03395553842324,
                        411.77641703789357
                    ]
                ]
            ]
        },
        "id": "TT-Puducherry-Puducherry",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Balod",
            "dt_code": "719",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        196.3948046147525,
                        278.8232722638979
                    ],
                    [
                        195.1357553698283,
                        278.6721613890616
                    ],
                    [
                        193.62939288036546,
                        280.9378277972141
                    ],
                    [
                        192.5502078132879,
                        281.60633189999845
                    ],
                    [
                        190.23445652351643,
                        280.31228539494987
                    ],
                    [
                        189.6723809677469,
                        279.01754369223715
                    ],
                    [
                        189.73983003443914,
                        277.6141183537231
                    ],
                    [
                        187.67139198920677,
                        276.70685155826664
                    ],
                    [
                        187.69387501143729,
                        275.5830902646232
                    ],
                    [
                        189.20023750090013,
                        275.2372107113525
                    ],
                    [
                        189.8522451455931,
                        273.2474232625957
                    ],
                    [
                        189.6723809677469,
                        271.7539816953537
                    ],
                    [
                        192.1230303909026,
                        271.7323306467422
                    ],
                    [
                        192.64013990221088,
                        270.60620087741404
                    ],
                    [
                        196.5297027481372,
                        271.7106793986653
                    ],
                    [
                        196.4847367036757,
                        274.1776407997751
                    ],
                    [
                        197.2941255039841,
                        273.85318788093167
                    ],
                    [
                        197.3840575929073,
                        275.3236853217327
                    ],
                    [
                        197.94613314867684,
                        276.317917284404
                    ],
                    [
                        197.67633688190767,
                        277.93805874255867
                    ],
                    [
                        196.3948046147525,
                        278.8232722638979
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Balod",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhadradri Kothagudem",
            "dt_code": "753",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        191.60592087959458,
                        321.7349887910929
                    ],
                    [
                        191.22370950167124,
                        322.7325419347086
                    ],
                    [
                        189.96466025674727,
                        322.0109438162972
                    ],
                    [
                        188.6831279895921,
                        322.69010020238863
                    ],
                    [
                        189.04285634528492,
                        324.19638368209445
                    ],
                    [
                        190.66163394590149,
                        326.019804223012
                    ],
                    [
                        194.91092514752063,
                        327.71494317432547
                    ],
                    [
                        194.3938156362126,
                        329.13383689590773
                    ],
                    [
                        193.1572494135189,
                        329.0279739105419
                    ],
                    [
                        192.90993616898027,
                        330.46737182676316
                    ],
                    [
                        190.27942256797814,
                        331.081010939613
                    ],
                    [
                        189.49251678990072,
                        330.6578257081953
                    ],
                    [
                        188.54822985620763,
                        329.93826630476786
                    ],
                    [
                        188.27843358943778,
                        328.41389041213876
                    ],
                    [
                        185.69288603289738,
                        329.45140209966587
                    ],
                    [
                        184.36638772128072,
                        328.1385682460024
                    ],
                    [
                        183.01740638743354,
                        328.3715349044321
                    ],
                    [
                        182.23050060935566,
                        327.69376024828415
                    ],
                    [
                        182.00567038704799,
                        326.35891397178324
                    ],
                    [
                        181.08386647558564,
                        325.2778582800308
                    ],
                    [
                        178.6332170524297,
                        325.42626323198914
                    ],
                    [
                        179.46508887496884,
                        322.4566590278947
                    ],
                    [
                        179.3077077193534,
                        321.3103886288166
                    ],
                    [
                        179.62247003058428,
                        319.4838598278604
                    ],
                    [
                        181.42111180904726,
                        317.3371908296497
                    ],
                    [
                        182.00567038704799,
                        317.6773700815282
                    ],
                    [
                        183.62444798766478,
                        316.8055753572616
                    ],
                    [
                        186.77207109997494,
                        315.97604678746836
                    ],
                    [
                        188.18850150051458,
                        315.42288623743144
                    ],
                    [
                        188.39084870059196,
                        316.44401725113886
                    ],
                    [
                        190.05459234567002,
                        316.0398657125112
                    ],
                    [
                        189.91969421228555,
                        317.8687021941131
                    ],
                    [
                        190.59418487920925,
                        321.77744520815463
                    ],
                    [
                        191.60592087959458,
                        321.7349887910929
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Bhadradri Kothagudem",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Vadodara",
            "dt_code": "486",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        81.01193452634652,
                        250.76550785766307
                    ],
                    [
                        80.15757968157641,
                        252.84038689696752
                    ],
                    [
                        80.53979105949975,
                        255.19685437083308
                    ],
                    [
                        80.13509665934566,
                        256.5921116256144
                    ],
                    [
                        77.21230376934318,
                        257.7904705502104
                    ],
                    [
                        75.79587336880354,
                        258.79224650272477
                    ],
                    [
                        74.58179016834083,
                        257.94294333776406
                    ],
                    [
                        73.61502021241722,
                        257.8775991168366
                    ],
                    [
                        73.45763905680155,
                        256.37415972880035
                    ],
                    [
                        71.34423496710747,
                        254.93514711863696
                    ],
                    [
                        70.78215941133772,
                        252.6002367731303
                    ],
                    [
                        71.95127656733871,
                        251.35540738433673
                    ],
                    [
                        73.61502021241722,
                        251.02770478679383
                    ],
                    [
                        73.43515603457081,
                        250.2410209396034
                    ],
                    [
                        75.03145061295663,
                        246.65368881382233
                    ],
                    [
                        75.95325452441898,
                        246.01873408879453
                    ],
                    [
                        76.69519425803514,
                        245.40549958281355
                    ],
                    [
                        76.78512634695835,
                        243.56476207249
                    ],
                    [
                        77.88679443626665,
                        243.411297054812
                    ],
                    [
                        78.87604741442146,
                        245.49311503921888
                    ],
                    [
                        78.92101345888295,
                        249.1916740494974
                    ],
                    [
                        79.57302110357591,
                        250.28473292612796
                    ],
                    [
                        81.01193452634652,
                        250.76550785766307
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Vadodara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Paschim Medinipur",
            "dt_code": "344",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        286.259444471214,
                        241.23970066169045
                    ],
                    [
                        286.0795802933676,
                        241.72246571256994
                    ],
                    [
                        287.83325602736886,
                        243.08240696564593
                    ],
                    [
                        289.47451665021663,
                        243.6524515075816
                    ],
                    [
                        290.53121869506367,
                        246.23770477966417
                    ],
                    [
                        291.11577727306417,
                        246.54422698428078
                    ],
                    [
                        291.70033585106466,
                        247.7480069868888
                    ],
                    [
                        288.08056927190773,
                        249.08233841932707
                    ],
                    [
                        288.08056927190773,
                        250.72180535537507
                    ],
                    [
                        289.20472038344724,
                        250.94030925420458
                    ],
                    [
                        288.88995807221613,
                        253.99711172823817
                    ],
                    [
                        286.4842746935217,
                        254.69522196799147
                    ],
                    [
                        285.33764055975166,
                        255.872790245528
                    ],
                    [
                        285.5849538042901,
                        257.594418990463
                    ],
                    [
                        285.06784429298204,
                        259.68476059413837
                    ],
                    [
                        282.72960998097983,
                        259.1188167835528
                    ],
                    [
                        282.23498349190277,
                        258.31319159441324
                    ],
                    [
                        282.14505140297956,
                        256.5703173851811
                    ],
                    [
                        280.5937228690552,
                        253.99711172823817
                    ],
                    [
                        281.24573051374796,
                        252.97136697073336
                    ],
                    [
                        281.5829758472098,
                        251.18063868631214
                    ],
                    [
                        280.9534512247476,
                        249.25727282871833
                    ],
                    [
                        279.78433406874683,
                        248.51370578623175
                    ],
                    [
                        280.03164731328525,
                        244.9673696005836
                    ],
                    [
                        279.6944019798236,
                        244.4634114047775
                    ],
                    [
                        279.5145378019772,
                        242.46834553154054
                    ],
                    [
                        282.5497458031339,
                        241.63469825914996
                    ],
                    [
                        283.8312780702886,
                        240.82268045844154
                    ],
                    [
                        285.2926745152902,
                        241.48109665143312
                    ],
                    [
                        286.259444471214,
                        241.23970066169045
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Paschim Medinipur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Seoni",
            "dt_code": "456",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        175.12586558442672,
                        242.44641155246512
                    ],
                    [
                        176.60974505165882,
                        243.89357923669525
                    ],
                    [
                        179.77985118619995,
                        243.54283916144743
                    ],
                    [
                        179.91474931958487,
                        244.4634114047775
                    ],
                    [
                        177.9137603410445,
                        246.47854726337175
                    ],
                    [
                        176.18256762927376,
                        246.34718191725125
                    ],
                    [
                        176.3849148293507,
                        248.75429902338698
                    ],
                    [
                        177.12685456296708,
                        250.67810199155736
                    ],
                    [
                        177.1043715407361,
                        252.0980210199413
                    ],
                    [
                        176.13760158481227,
                        253.12416733581836
                    ],
                    [
                        176.63222807388956,
                        254.78247044880334
                    ],
                    [
                        174.67620513981092,
                        254.9133368023837
                    ],
                    [
                        174.51882398419525,
                        256.4177517960258
                    ],
                    [
                        173.61950309496365,
                        256.54852293384806
                    ],
                    [
                        172.27052176111647,
                        259.0317359747364
                    ],
                    [
                        171.1014046051157,
                        259.1623559341407
                    ],
                    [
                        170.85409136057683,
                        260.8162256084651
                    ],
                    [
                        168.4484079818826,
                        262.4036814447588
                    ],
                    [
                        168.24606078180545,
                        261.1642556092064
                    ],
                    [
                        166.71721527011186,
                        261.1642556092064
                    ],
                    [
                        164.40146398034085,
                        260.46814244401054
                    ],
                    [
                        165.95279251426496,
                        256.7228726402522
                    ],
                    [
                        166.8296303812656,
                        256.0471923066227
                    ],
                    [
                        166.7396982923426,
                        254.36800989885
                    ],
                    [
                        166.1551397143421,
                        253.93165272622548
                    ],
                    [
                        165.18836975841828,
                        250.80920949855505
                    ],
                    [
                        166.0202415809572,
                        248.31683723730816
                    ],
                    [
                        165.77292833641877,
                        246.21580869567185
                    ],
                    [
                        167.009494559112,
                        244.5291516848281
                    ],
                    [
                        168.1336456706515,
                        244.3976691426967
                    ],
                    [
                        169.43766096003742,
                        242.75346708380675
                    ],
                    [
                        170.2020837158841,
                        240.88853110423526
                    ],
                    [
                        171.9557594498856,
                        241.34942945128807
                    ],
                    [
                        172.49535198342437,
                        242.534146138158
                    ],
                    [
                        175.12586558442672,
                        242.44641155246512
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Seoni",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Alirajpur",
            "dt_code": "465",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        98.34634466628427,
                        247.11336886522827
                    ],
                    [
                        97.60440493266833,
                        250.15359437738522
                    ],
                    [
                        96.97488031020612,
                        251.6612195172429
                    ],
                    [
                        95.17623853174314,
                        252.25088128539875
                    ],
                    [
                        94.36684973143474,
                        254.21529456779535
                    ],
                    [
                        94.81651017605054,
                        255.28408333778236
                    ],
                    [
                        93.80477417566499,
                        255.52394551983252
                    ],
                    [
                        91.57895497481695,
                        257.04974198633124
                    ],
                    [
                        89.60044901850756,
                        256.78825030177217
                    ],
                    [
                        88.83602626266088,
                        255.74197982002136
                    ],
                    [
                        89.26320368504571,
                        254.45527112511826
                    ],
                    [
                        89.24072066281497,
                        252.64390235680003
                    ],
                    [
                        88.22898466242941,
                        251.26802475502126
                    ],
                    [
                        88.45381488473731,
                        250.35029928806267
                    ],
                    [
                        90.22997364096977,
                        250.91845983306354
                    ],
                    [
                        90.61218501889289,
                        248.99486601565394
                    ],
                    [
                        89.19575461835325,
                        249.34473483659679
                    ],
                    [
                        88.29643372912187,
                        247.2665741607115
                    ],
                    [
                        89.64541506296905,
                        247.72612596358795
                    ],
                    [
                        91.30915870804756,
                        245.77784103166402
                    ],
                    [
                        93.01786839758756,
                        245.90924053126577
                    ],
                    [
                        93.93967230904991,
                        246.741254343901
                    ],
                    [
                        96.39032173220562,
                        247.13525599038303
                    ],
                    [
                        98.34634466628427,
                        247.11336886522827
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Alirajpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chhindwara",
            "dt_code": "455",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        165.77292833641877,
                        246.21580869567185
                    ],
                    [
                        166.0202415809572,
                        248.31683723730816
                    ],
                    [
                        165.18836975841828,
                        250.80920949855505
                    ],
                    [
                        166.1551397143421,
                        253.93165272622548
                    ],
                    [
                        166.7396982923426,
                        254.36800989885
                    ],
                    [
                        166.8296303812656,
                        256.0471923066227
                    ],
                    [
                        165.95279251426496,
                        256.7228726402522
                    ],
                    [
                        164.40146398034085,
                        260.46814244401054
                    ],
                    [
                        164.24408282472518,
                        261.5774722777289
                    ],
                    [
                        162.08571269056938,
                        262.25150744789653
                    ],
                    [
                        160.57935020110654,
                        262.0558402913773
                    ],
                    [
                        159.72499535633665,
                        262.49063347171574
                    ],
                    [
                        159.477682111798,
                        263.9031417994257
                    ],
                    [
                        154.84617953225575,
                        264.1203736589941
                    ],
                    [
                        153.76699446517773,
                        263.4686164629562
                    ],
                    [
                        152.62036033140748,
                        263.96831351258334
                    ],
                    [
                        152.41801313133033,
                        262.79493956616363
                    ],
                    [
                        151.29386201979105,
                        262.5123709624061
                    ],
                    [
                        152.035801753407,
                        261.20775562458863
                    ],
                    [
                        153.00257170933105,
                        260.46814244401054
                    ],
                    [
                        153.114986820485,
                        258.9664231734171
                    ],
                    [
                        154.32907002094748,
                        258.00828567001014
                    ],
                    [
                        153.81196050963945,
                        257.00616214502014
                    ],
                    [
                        150.3270920638672,
                        254.5643428764664
                    ],
                    [
                        149.85494859702067,
                        250.91845983306354
                    ],
                    [
                        150.21467695271303,
                        249.628962450727
                    ],
                    [
                        151.8559375755608,
                        249.32286965933548
                    ],
                    [
                        153.15995286494626,
                        250.04430631853046
                    ],
                    [
                        154.48645117656292,
                        249.34473483659679
                    ],
                    [
                        154.3065869987165,
                        247.48542033570135
                    ],
                    [
                        153.56464726510058,
                        246.5880123718086
                    ],
                    [
                        153.9243756207934,
                        245.42740377611239
                    ],
                    [
                        155.25087393240983,
                        245.6902369814017
                    ],
                    [
                        156.39750806617985,
                        244.79209299393227
                    ],
                    [
                        156.89213455525714,
                        245.75594034794761
                    ],
                    [
                        159.7474783785674,
                        245.31788061451334
                    ],
                    [
                        161.11894273464554,
                        245.77784103166402
                    ],
                    [
                        161.56860317926134,
                        243.93741777262716
                    ],
                    [
                        164.4913960692636,
                        243.23589520016924
                    ],
                    [
                        164.53636211372532,
                        244.57297743729498
                    ],
                    [
                        165.77292833641877,
                        246.21580869567185
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Chhindwara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Harda",
            "dt_code": "448",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        137.28693917000987,
                        252.4692348870663
                    ],
                    [
                        138.50102237047258,
                        253.4079116870576
                    ],
                    [
                        138.2986751703952,
                        254.67340931699925
                    ],
                    [
                        138.9731658373189,
                        255.52394551983252
                    ],
                    [
                        135.6231955249316,
                        256.5703173851811
                    ],
                    [
                        134.79132370239222,
                        257.48549410388233
                    ],
                    [
                        133.57724050192974,
                        256.0907907085739
                    ],
                    [
                        131.19404014546603,
                        255.9599929669999
                    ],
                    [
                        129.89002485608057,
                        256.2651758670818
                    ],
                    [
                        129.1930511669259,
                        255.0441955180035
                    ],
                    [
                        128.99070396684897,
                        253.51703452849938
                    ],
                    [
                        129.68767765600342,
                        251.4864748817996
                    ],
                    [
                        128.338696322156,
                        250.54698672957738
                    ],
                    [
                        128.51856050000242,
                        249.23540678551217
                    ],
                    [
                        130.36216832292712,
                        248.25121047799308
                    ],
                    [
                        132.7004026349291,
                        247.92304733831753
                    ],
                    [
                        133.4648253907758,
                        247.17902958625342
                    ],
                    [
                        134.79132370239222,
                        246.9601528794622
                    ],
                    [
                        135.69064459162382,
                        248.64494171589382
                    ],
                    [
                        137.28693917000987,
                        252.4692348870663
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Harda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Khargone",
            "dt_code": "440",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        117.97402307376274,
                        262.7732047572199
                    ],
                    [
                        116.13041525083804,
                        263.1643997871097
                    ],
                    [
                        116.98477009560793,
                        264.4027444055754
                    ],
                    [
                        116.64752476214608,
                        265.9877224102602
                    ],
                    [
                        111.09421827114147,
                        265.6621308451458
                    ],
                    [
                        110.7120068932179,
                        264.31586478988004
                    ],
                    [
                        109.47544067052468,
                        264.1203736589941
                    ],
                    [
                        108.98081418144716,
                        263.0340088607277
                    ],
                    [
                        109.52040671498594,
                        262.12106453687977
                    ],
                    [
                        107.90162911436937,
                        261.0555019408399
                    ],
                    [
                        106.44023266936802,
                        261.79492469904255
                    ],
                    [
                        105.33856458005971,
                        260.8379790403413
                    ],
                    [
                        106.19291942482937,
                        260.14176613380334
                    ],
                    [
                        107.29458751413813,
                        256.286973061624
                    ],
                    [
                        108.21639142560048,
                        255.0660045616135
                    ],
                    [
                        106.79996102506084,
                        253.88801232699302
                    ],
                    [
                        109.36302555937073,
                        253.56068217231507
                    ],
                    [
                        110.01503320406346,
                        252.29455371884785
                    ],
                    [
                        109.76771995952481,
                        250.83105999601986
                    ],
                    [
                        110.39724458198702,
                        250.02244805911346
                    ],
                    [
                        111.18415036006468,
                        250.63439776607493
                    ],
                    [
                        113.32003747198928,
                        250.2410209396034
                    ],
                    [
                        114.3992225390673,
                        249.51964846411897
                    ],
                    [
                        116.75993987330003,
                        249.43219338137033
                    ],
                    [
                        118.06395516268594,
                        247.46353669894637
                    ],
                    [
                        118.3337514294551,
                        248.53557898379137
                    ],
                    [
                        119.97501205230265,
                        249.36659979741415
                    ],
                    [
                        119.95252903007213,
                        250.80920949855505
                    ],
                    [
                        118.06395516268594,
                        253.05868274661952
                    ],
                    [
                        118.87334396299411,
                        254.25892858277388
                    ],
                    [
                        120.15487623014906,
                        254.86971553336377
                    ],
                    [
                        118.94079302968657,
                        256.37415972880035
                    ],
                    [
                        119.32300440760991,
                        257.8122530067801
                    ],
                    [
                        118.3337514294551,
                        258.31319159441324
                    ],
                    [
                        118.42368351837831,
                        260.9032380901102
                    ],
                    [
                        117.86160796260879,
                        261.338250694285
                    ],
                    [
                        117.97402307376274,
                        262.7732047572199
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Khargone",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Purba Medinipur",
            "dt_code": "345",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        285.06784429298204,
                        259.68476059413837
                    ],
                    [
                        285.5849538042901,
                        257.594418990463
                    ],
                    [
                        285.33764055975166,
                        255.872790245528
                    ],
                    [
                        286.4842746935217,
                        254.69522196799147
                    ],
                    [
                        288.88995807221613,
                        253.99711172823817
                    ],
                    [
                        289.20472038344724,
                        250.94030925420458
                    ],
                    [
                        288.08056927190773,
                        250.72180535537507
                    ],
                    [
                        288.08056927190773,
                        249.08233841932707
                    ],
                    [
                        291.70033585106466,
                        247.7480069868888
                    ],
                    [
                        292.3523434957574,
                        249.30100426561302
                    ],
                    [
                        292.5771737180653,
                        251.61753464667612
                    ],
                    [
                        293.251664384989,
                        252.11985884355863
                    ],
                    [
                        293.49897762952764,
                        252.79672516252657
                    ],
                    [
                        295.2751363857601,
                        253.05868274661952
                    ],
                    [
                        296.01707611937604,
                        254.3461940608674
                    ],
                    [
                        294.21843434091284,
                        255.7201773421719
                    ],
                    [
                        292.622139762527,
                        258.68337928484306
                    ],
                    [
                        290.1714903393711,
                        260.7727181216838
                    ],
                    [
                        288.4852636720618,
                        261.5339793088642
                    ],
                    [
                        285.69736891544426,
                        262.09932332841703
                    ],
                    [
                        285.06784429298204,
                        259.68476059413837
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Purba Medinipur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mayurbhanj",
            "dt_code": "376",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        274.56827291120385,
                        252.57840366039642
                    ],
                    [
                        276.18705051182064,
                        254.45527112511826
                    ],
                    [
                        278.0531413569761,
                        254.65159645365782
                    ],
                    [
                        278.9974282906692,
                        255.3931147792477
                    ],
                    [
                        278.59273389051486,
                        257.37656396225395
                    ],
                    [
                        280.0541303355162,
                        258.1607437695603
                    ],
                    [
                        280.9084851802861,
                        259.6194665849923
                    ],
                    [
                        279.2447415352078,
                        260.533412090034
                    ],
                    [
                        278.6601829572073,
                        262.31672611429855
                    ],
                    [
                        274.74813708905026,
                        262.25150744789653
                    ],
                    [
                        273.39915575520286,
                        262.6427915730003
                    ],
                    [
                        272.4323857992788,
                        263.55552810731183
                    ],
                    [
                        271.66796304343234,
                        265.0759503164828
                    ],
                    [
                        269.1723475758147,
                        267.0293075951583
                    ],
                    [
                        267.8233662419673,
                        265.81407928449573
                    ],
                    [
                        266.94652837496665,
                        267.3763986401044
                    ],
                    [
                        265.57506401888827,
                        267.50654439864485
                    ],
                    [
                        265.4401658855036,
                        266.29156650535117
                    ],
                    [
                        266.2945207302739,
                        265.11937620374584
                    ],
                    [
                        265.7998942411964,
                        264.38102480913204
                    ],
                    [
                        264.0012524627334,
                        263.3817015279703
                    ],
                    [
                        265.05795450758023,
                        261.8818998726214
                    ],
                    [
                        264.24856570727184,
                        260.8379790403413
                    ],
                    [
                        262.0452295286548,
                        259.2494317283371
                    ],
                    [
                        261.1009425949617,
                        259.0099652500108
                    ],
                    [
                        260.8761123726538,
                        256.78825030177217
                    ],
                    [
                        259.0325045497291,
                        255.78558414114093
                    ],
                    [
                        259.2123687275755,
                        255.0878133931747
                    ],
                    [
                        260.17913868349933,
                        254.82609341554814
                    ],
                    [
                        260.69624819480737,
                        256.24337846140685
                    ],
                    [
                        262.35999183988565,
                        256.2651758670818
                    ],
                    [
                        264.0012524627334,
                        254.17165970201722
                    ],
                    [
                        264.33849779619527,
                        252.99319623514305
                    ],
                    [
                        263.48414295142516,
                        252.0761829820002
                    ],
                    [
                        264.29353175173355,
                        250.69995378116596
                    ],
                    [
                        264.1361505961179,
                        249.23540678551217
                    ],
                    [
                        263.3267617958097,
                        248.29496186813037
                    ],
                    [
                        264.8556073075033,
                        247.15714289738588
                    ],
                    [
                        265.62003006335,
                        248.27308628169772
                    ],
                    [
                        268.02571344204466,
                        248.88552063486793
                    ],
                    [
                        269.1498645535837,
                        250.45957224480526
                    ],
                    [
                        270.1840835762,
                        250.9840074508607
                    ],
                    [
                        271.1733365543548,
                        250.50327991829283
                    ],
                    [
                        272.0276913991247,
                        251.26802475502126
                    ],
                    [
                        274.56827291120385,
                        252.57840366039642
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Mayurbhanj",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bharuch",
            "dt_code": "488",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        70.78215941133772,
                        252.6002367731303
                    ],
                    [
                        71.34423496710747,
                        254.93514711863696
                    ],
                    [
                        73.45763905680155,
                        256.37415972880035
                    ],
                    [
                        73.61502021241722,
                        257.8775991168366
                    ],
                    [
                        74.58179016834083,
                        257.94294333776406
                    ],
                    [
                        75.79587336880354,
                        258.79224650272477
                    ],
                    [
                        77.21230376934318,
                        257.7904705502104
                    ],
                    [
                        77.27975283603541,
                        258.35674622940627
                    ],
                    [
                        80.09013061488417,
                        261.0555019408399
                    ],
                    [
                        79.39315692572973,
                        263.4686164629562
                    ],
                    [
                        76.42539799126553,
                        264.0117602948915
                    ],
                    [
                        71.88382750064625,
                        263.7293415283318
                    ],
                    [
                        71.36671798933821,
                        265.0325236114096
                    ],
                    [
                        69.61304225533672,
                        265.16280127333005
                    ],
                    [
                        68.84861949948981,
                        264.31586478988004
                    ],
                    [
                        68.42144207710498,
                        263.4903446825006
                    ],
                    [
                        67.00501167656512,
                        262.6427915730003
                    ],
                    [
                        68.33150998818155,
                        261.62096441821745
                    ],
                    [
                        66.01575869841054,
                        260.22880439423415
                    ],
                    [
                        67.18487585441153,
                        259.0317359747364
                    ],
                    [
                        65.52113220933325,
                        256.8972088565251
                    ],
                    [
                        66.3754870541029,
                        253.12416733581836
                    ],
                    [
                        67.5670872323351,
                        252.57840366039642
                    ],
                    [
                        69.09593274402846,
                        253.25513074919206
                    ],
                    [
                        70.78215941133772,
                        252.6002367731303
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Bharuch",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sundargarh",
            "dt_code": "374",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        251.9728355692614,
                        261.38174739247296
                    ],
                    [
                        251.2983449023377,
                        262.42541976116024
                    ],
                    [
                        249.8819145017983,
                        262.49063347171574
                    ],
                    [
                        249.54466916833644,
                        261.1860057205928
                    ],
                    [
                        246.08228374479495,
                        261.25125481047445
                    ],
                    [
                        244.6208872997936,
                        260.59867986509107
                    ],
                    [
                        244.19370987740876,
                        259.79357977235304
                    ],
                    [
                        243.11452481033098,
                        259.83710598439757
                    ],
                    [
                        242.19272089886863,
                        258.94465182151635
                    ],
                    [
                        241.96789067656073,
                        255.0878133931747
                    ],
                    [
                        241.4732641874832,
                        252.99319623514305
                    ],
                    [
                        238.3930901418653,
                        254.3461940608674
                    ],
                    [
                        238.43805618632678,
                        255.76378208633906
                    ],
                    [
                        235.49278027409355,
                        256.17798497742064
                    ],
                    [
                        234.14379894024614,
                        257.50727950153583
                    ],
                    [
                        232.95219876201463,
                        256.87541756694174
                    ],
                    [
                        230.34416818324303,
                        259.40180632771614
                    ],
                    [
                        228.45559431585684,
                        258.7704734776293
                    ],
                    [
                        227.69117156001016,
                        256.54852293384806
                    ],
                    [
                        227.66868853777942,
                        255.28408333778236
                    ],
                    [
                        228.99518684939585,
                        252.7748939746333
                    ],
                    [
                        231.5582513837055,
                        251.33356204939224
                    ],
                    [
                        232.4575722729371,
                        250.54698672957738
                    ],
                    [
                        234.6159424070927,
                        250.10987980142187
                    ],
                    [
                        235.1555349406317,
                        248.55745196428205
                    ],
                    [
                        234.63842542932343,
                        247.61671758132377
                    ],
                    [
                        236.0998218743248,
                        248.4262108249671
                    ],
                    [
                        237.358871119249,
                        250.00058958377485
                    ],
                    [
                        238.75281849755788,
                        250.56883981193528
                    ],
                    [
                        240.776290498329,
                        250.4158637089795
                    ],
                    [
                        242.3276190322531,
                        249.21354052577732
                    ],
                    [
                        245.49772516679445,
                        249.34473483659679
                    ],
                    [
                        246.37456303379508,
                        248.79804042796673
                    ],
                    [
                        248.55541619018186,
                        248.9292594374598
                    ],
                    [
                        250.19667681302917,
                        248.2074582187015
                    ],
                    [
                        250.30909192418312,
                        251.92331071393085
                    ],
                    [
                        249.58963521279793,
                        254.06256881444784
                    ],
                    [
                        251.81545441364574,
                        254.84790458056392
                    ],
                    [
                        252.66980925841608,
                        255.8509890366209
                    ],
                    [
                        253.27685085864732,
                        256.6574930816825
                    ],
                    [
                        254.7157642814177,
                        257.02795217095087
                    ],
                    [
                        254.1761717478787,
                        258.7704734776293
                    ],
                    [
                        253.43423201426276,
                        258.57450683470944
                    ],
                    [
                        252.1077337026461,
                        259.7718163536691
                    ],
                    [
                        251.9728355692614,
                        261.38174739247296
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Sundargarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kabeerdham",
            "dt_code": "407",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        197.3390915484456,
                        248.22933445700005
                    ],
                    [
                        197.87868408198437,
                        249.95687198526684
                    ],
                    [
                        198.53069172667756,
                        250.54698672957738
                    ],
                    [
                        197.94613314867684,
                        253.56068217231507
                    ],
                    [
                        197.72130292636894,
                        255.93819260372197
                    ],
                    [
                        197.04681225944546,
                        257.37656396225395
                    ],
                    [
                        195.06830630313607,
                        258.68337928484306
                    ],
                    [
                        194.6860949252125,
                        259.6629961329742
                    ],
                    [
                        192.88745314674952,
                        260.685700655545
                    ],
                    [
                        191.85323412413322,
                        260.2070451413421
                    ],
                    [
                        190.3468716346706,
                        260.3158393248062
                    ],
                    [
                        189.33513563428505,
                        258.79224650272477
                    ],
                    [
                        188.0985694115916,
                        258.6180564427656
                    ],
                    [
                        187.89622221151444,
                        257.6379874741467
                    ],
                    [
                        188.9978903008232,
                        254.25892858277388
                    ],
                    [
                        190.41432070136307,
                        255.0660045616135
                    ],
                    [
                        191.02136230159408,
                        252.1853710286345
                    ],
                    [
                        192.10054736867187,
                        251.3772525043865
                    ],
                    [
                        191.98813225751792,
                        249.08233841932707
                    ],
                    [
                        193.20221545798063,
                        248.2074582187015
                    ],
                    [
                        194.01160425828903,
                        248.7105567512076
                    ],
                    [
                        195.18072141429002,
                        247.6604815874491
                    ],
                    [
                        197.3390915484456,
                        248.22933445700005
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Kabeerdham",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Khandwa",
            "dt_code": "466",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        128.338696322156,
                        262.99054357083503
                    ],
                    [
                        125.84308085453858,
                        262.7514697420866
                    ],
                    [
                        124.38168440953723,
                        262.94707745684065
                    ],
                    [
                        122.3806954309971,
                        262.36020419253236
                    ],
                    [
                        119.43541951876387,
                        263.25132295326404
                    ],
                    [
                        117.97402307376274,
                        262.7732047572199
                    ],
                    [
                        117.86160796260879,
                        261.338250694285
                    ],
                    [
                        118.42368351837831,
                        260.9032380901102
                    ],
                    [
                        118.3337514294551,
                        258.31319159441324
                    ],
                    [
                        119.32300440760991,
                        257.8122530067801
                    ],
                    [
                        118.94079302968657,
                        256.37415972880035
                    ],
                    [
                        120.15487623014906,
                        254.86971553336377
                    ],
                    [
                        118.87334396299411,
                        254.25892858277388
                    ],
                    [
                        118.06395516268594,
                        253.05868274661952
                    ],
                    [
                        119.95252903007213,
                        250.80920949855505
                    ],
                    [
                        121.63875569738116,
                        251.26802475502126
                    ],
                    [
                        124.15685418722933,
                        249.4759213554305
                    ],
                    [
                        125.73066574338463,
                        249.56337470757148
                    ],
                    [
                        126.87729987715466,
                        251.28987073475145
                    ],
                    [
                        128.338696322156,
                        250.54698672957738
                    ],
                    [
                        129.68767765600342,
                        251.4864748817996
                    ],
                    [
                        128.99070396684897,
                        253.51703452849938
                    ],
                    [
                        129.1930511669259,
                        255.0441955180035
                    ],
                    [
                        129.89002485608057,
                        256.2651758670818
                    ],
                    [
                        131.19404014546603,
                        255.9599929669999
                    ],
                    [
                        133.57724050192974,
                        256.0907907085739
                    ],
                    [
                        134.79132370239222,
                        257.48549410388233
                    ],
                    [
                        133.98193490208382,
                        258.09540584249646
                    ],
                    [
                        133.75710467977592,
                        259.6194665849923
                    ],
                    [
                        132.45308939039046,
                        260.5551682229161
                    ],
                    [
                        132.13832707915913,
                        260.79447196891806
                    ],
                    [
                        131.89101383462048,
                        260.9467430852385
                    ],
                    [
                        131.64370059008206,
                        261.0990040305394
                    ],
                    [
                        131.5312854789281,
                        260.8814852811474
                    ],
                    [
                        130.11485507838825,
                        262.38194292188825
                    ],
                    [
                        129.41788138923403,
                        262.12106453687977
                    ],
                    [
                        128.338696322156,
                        262.99054357083503
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Khandwa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Porbandar",
            "dt_code": "478",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        25.906047038683937,
                        258.8357919253105
                    ],
                    [
                        25.951013083145654,
                        260.0982457545604
                    ],
                    [
                        28.51407761745554,
                        259.0535064904369
                    ],
                    [
                        29.70567779568728,
                        260.14176613380334
                    ],
                    [
                        30.80734588499604,
                        261.3165020342865
                    ],
                    [
                        30.402651484841726,
                        261.0555019408399
                    ],
                    [
                        28.491594595224797,
                        262.66452761932806
                    ],
                    [
                        28.581526684147775,
                        264.31586478988004
                    ],
                    [
                        27.817103928301094,
                        265.53188136947523
                    ],
                    [
                        28.356696461839874,
                        268.2222157752747
                    ],
                    [
                        24.33223548252886,
                        263.3599722799399
                    ],
                    [
                        19.38597059175538,
                        258.7051531469332
                    ],
                    [
                        19.745698947447863,
                        257.7686878836714
                    ],
                    [
                        20.51012170329477,
                        258.3349690166874
                    ],
                    [
                        22.151382326142425,
                        256.94079080375786
                    ],
                    [
                        23.72519388229739,
                        257.18047645797947
                    ],
                    [
                        23.567812726682178,
                        258.57450683470944
                    ],
                    [
                        25.906047038683937,
                        258.8357919253105
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Porbandar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Balaghat",
            "dt_code": "457",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        185.37812372166627,
                        266.55197254752545
                    ],
                    [
                        184.79356514366577,
                        266.7472578617716
                    ],
                    [
                        183.42210078758762,
                        265.5752986776284
                    ],
                    [
                        181.8707722536633,
                        264.85880861040016
                    ],
                    [
                        181.17379856450884,
                        263.5338005046621
                    ],
                    [
                        179.59998700835354,
                        262.0558402913773
                    ],
                    [
                        177.44161687419773,
                        262.16454633377396
                    ],
                    [
                        176.63222807388956,
                        263.055741196677
                    ],
                    [
                        174.45137491750302,
                        263.5338005046621
                    ],
                    [
                        171.82086131650067,
                        262.2949867653879
                    ],
                    [
                        168.87558540426744,
                        263.2730532301903
                    ],
                    [
                        168.4484079818826,
                        262.4036814447588
                    ],
                    [
                        170.85409136057683,
                        260.8162256084651
                    ],
                    [
                        171.1014046051157,
                        259.1623559341407
                    ],
                    [
                        172.27052176111647,
                        259.0317359747364
                    ],
                    [
                        173.61950309496365,
                        256.54852293384806
                    ],
                    [
                        174.51882398419525,
                        256.4177517960258
                    ],
                    [
                        174.67620513981092,
                        254.9133368023837
                    ],
                    [
                        176.63222807388956,
                        254.78247044880334
                    ],
                    [
                        176.13760158481227,
                        253.12416733581836
                    ],
                    [
                        177.1043715407361,
                        252.0980210199413
                    ],
                    [
                        177.12685456296708,
                        250.67810199155736
                    ],
                    [
                        180.58923998650835,
                        251.26802475502126
                    ],
                    [
                        181.53352692020144,
                        251.18063868631214
                    ],
                    [
                        184.1865235434343,
                        252.40373105473668
                    ],
                    [
                        186.030131366359,
                        252.79672516252657
                    ],
                    [
                        186.00764834412826,
                        251.7049035291709
                    ],
                    [
                        188.39084870059196,
                        249.9787308924984
                    ],
                    [
                        189.11030541197692,
                        251.57384891733625
                    ],
                    [
                        190.45928674582433,
                        251.508318712833
                    ],
                    [
                        191.02136230159408,
                        252.1853710286345
                    ],
                    [
                        190.41432070136307,
                        255.0660045616135
                    ],
                    [
                        188.9978903008232,
                        254.25892858277388
                    ],
                    [
                        187.89622221151444,
                        257.6379874741467
                    ],
                    [
                        188.0985694115916,
                        258.6180564427656
                    ],
                    [
                        187.67139198920677,
                        259.7718163536691
                    ],
                    [
                        186.77207109997494,
                        259.7718163536691
                    ],
                    [
                        186.16502949974392,
                        261.3599991470084
                    ],
                    [
                        186.50227483320577,
                        264.33758500130074
                    ],
                    [
                        185.37812372166627,
                        266.55197254752545
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Balaghat",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Narmada",
            "dt_code": "487",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        77.21230376934318,
                        257.7904705502104
                    ],
                    [
                        80.13509665934566,
                        256.5921116256144
                    ],
                    [
                        80.53979105949975,
                        255.19685437083308
                    ],
                    [
                        82.2709837712705,
                        254.86971553336377
                    ],
                    [
                        83.10285559380986,
                        256.37415972880035
                    ],
                    [
                        85.30619177242716,
                        256.7664579586875
                    ],
                    [
                        86.54275799512038,
                        258.00828567001014
                    ],
                    [
                        84.74411621665718,
                        258.530956389162
                    ],
                    [
                        84.85653132781113,
                        259.79357977235304
                    ],
                    [
                        85.68840315035027,
                        260.533412090034
                    ],
                    [
                        84.69915017219569,
                        261.64271017786166
                    ],
                    [
                        85.0139124834268,
                        263.59898269583147
                    ],
                    [
                        87.1722826175826,
                        263.57725550436294
                    ],
                    [
                        87.01490146196693,
                        264.53305768065474
                    ],
                    [
                        84.54176901658002,
                        265.31478257953853
                    ],
                    [
                        83.01292350488666,
                        265.510172409243
                    ],
                    [
                        82.6756781714248,
                        264.35930500771246
                    ],
                    [
                        80.00019852596097,
                        264.1420957162312
                    ],
                    [
                        79.39315692572973,
                        263.4686164629562
                    ],
                    [
                        80.09013061488417,
                        261.0555019408399
                    ],
                    [
                        77.27975283603541,
                        258.35674622940627
                    ],
                    [
                        77.21230376934318,
                        257.7904705502104
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Narmada",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Barwani",
            "dt_code": "441",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        111.09421827114147,
                        265.6621308451458
                    ],
                    [
                        107.76673098098445,
                        265.81407928449573
                    ],
                    [
                        105.24863249113628,
                        265.31478257953853
                    ],
                    [
                        103.71978697944269,
                        264.51133931372397
                    ],
                    [
                        102.91039817913429,
                        262.86014275602156
                    ],
                    [
                        100.1225034225165,
                        261.79492469904255
                    ],
                    [
                        98.84097115536156,
                        262.09932332841703
                    ],
                    [
                        96.00811035428228,
                        261.3165020342865
                    ],
                    [
                        94.86147622051226,
                        260.3375975372823
                    ],
                    [
                        95.1537555095124,
                        257.398350411083
                    ],
                    [
                        94.09705346466512,
                        256.61390565516456
                    ],
                    [
                        93.80477417566499,
                        255.52394551983252
                    ],
                    [
                        94.81651017605054,
                        255.28408333778236
                    ],
                    [
                        95.71583106528215,
                        255.6329653151194
                    ],
                    [
                        97.42454075482192,
                        255.43672587312636
                    ],
                    [
                        98.88593719982327,
                        254.7606586470406
                    ],
                    [
                        104.91138715767443,
                        254.3461940608674
                    ],
                    [
                        106.79996102506084,
                        253.88801232699302
                    ],
                    [
                        108.21639142560048,
                        255.0660045616135
                    ],
                    [
                        107.29458751413813,
                        256.286973061624
                    ],
                    [
                        106.19291942482937,
                        260.14176613380334
                    ],
                    [
                        105.33856458005971,
                        260.8379790403413
                    ],
                    [
                        106.44023266936802,
                        261.79492469904255
                    ],
                    [
                        107.90162911436937,
                        261.0555019408399
                    ],
                    [
                        109.52040671498594,
                        262.12106453687977
                    ],
                    [
                        108.98081418144716,
                        263.0340088607277
                    ],
                    [
                        109.47544067052468,
                        264.1203736589941
                    ],
                    [
                        110.7120068932179,
                        264.31586478988004
                    ],
                    [
                        111.09421827114147,
                        265.6621308451458
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Barwani",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Janjgir Champa",
            "dt_code": "405",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        218.83286080107928,
                        254.06256881444784
                    ],
                    [
                        219.97949493484953,
                        254.8042820383002
                    ],
                    [
                        219.8221137792341,
                        256.54852293384806
                    ],
                    [
                        221.10364604638903,
                        256.39595586792933
                    ],
                    [
                        222.52007644692867,
                        257.1586879055543
                    ],
                    [
                        222.38517831354397,
                        258.3785232325867
                    ],
                    [
                        223.64422755846795,
                        260.5116557492667
                    ],
                    [
                        219.0127249789257,
                        260.3811133381504
                    ],
                    [
                        217.86609084515544,
                        260.85973226456315
                    ],
                    [
                        216.67449066692393,
                        260.3811133381504
                    ],
                    [
                        214.78591679953752,
                        260.59867986509107
                    ],
                    [
                        211.6158106649964,
                        260.05472454245665
                    ],
                    [
                        210.69400675353404,
                        259.79357977235304
                    ],
                    [
                        209.54737261976356,
                        257.4637084960267
                    ],
                    [
                        209.97455004214862,
                        254.3243780102851
                    ],
                    [
                        211.2560823093038,
                        253.82255013097296
                    ],
                    [
                        211.79567484284257,
                        252.6002367731303
                    ],
                    [
                        213.79666382138294,
                        251.83595041446563
                    ],
                    [
                        214.83088284399923,
                        253.015025285916
                    ],
                    [
                        214.89833191069147,
                        254.71703440665135
                    ],
                    [
                        217.73119271177075,
                        253.27695723763173
                    ],
                    [
                        218.83286080107928,
                        254.06256881444784
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Janjgir Champa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nandurbar",
            "dt_code": "497",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        85.21625968350395,
                        271.8189336445579
                    ],
                    [
                        84.60921808327248,
                        271.6673763040509
                    ],
                    [
                        84.5192859943495,
                        271.34257764751965
                    ],
                    [
                        83.57499906065641,
                        270.5628773920299
                    ],
                    [
                        82.54078003804011,
                        269.37116706610857
                    ],
                    [
                        84.72163319442643,
                        269.06772553304796
                    ],
                    [
                        84.83404830558038,
                        267.5499246969801
                    ],
                    [
                        86.58772403958187,
                        267.072696816181
                    ],
                    [
                        86.83503728412052,
                        265.5752986776284
                    ],
                    [
                        88.11656955127569,
                        264.7719461996339
                    ],
                    [
                        90.52225292996968,
                        264.51133931372397
                    ],
                    [
                        92.16351355281745,
                        263.94658981350665
                    ],
                    [
                        91.48902288589375,
                        262.94707745684065
                    ],
                    [
                        89.84776226304643,
                        262.8384085655481
                    ],
                    [
                        87.1722826175826,
                        263.55552810731183
                    ],
                    [
                        87.1722826175826,
                        263.57725550436294
                    ],
                    [
                        85.0139124834268,
                        263.59898269583147
                    ],
                    [
                        84.69915017219569,
                        261.64271017786166
                    ],
                    [
                        85.68840315035027,
                        260.533412090034
                    ],
                    [
                        84.85653132781113,
                        259.79357977235304
                    ],
                    [
                        84.74411621665718,
                        258.530956389162
                    ],
                    [
                        86.54275799512038,
                        258.00828567001014
                    ],
                    [
                        89.60044901850756,
                        256.78825030177217
                    ],
                    [
                        91.57895497481695,
                        257.04974198633124
                    ],
                    [
                        93.80477417566499,
                        255.52394551983252
                    ],
                    [
                        94.09705346466512,
                        256.61390565516456
                    ],
                    [
                        95.1537555095124,
                        257.398350411083
                    ],
                    [
                        94.86147622051226,
                        260.3375975372823
                    ],
                    [
                        96.00811035428228,
                        261.3165020342865
                    ],
                    [
                        98.84097115536156,
                        262.09932332841703
                    ],
                    [
                        98.50372582189993,
                        264.2507029254012
                    ],
                    [
                        97.55943888820684,
                        265.3364933774896
                    ],
                    [
                        94.81651017605054,
                        265.2496489599834
                    ],
                    [
                        94.52423088705018,
                        266.9425267171776
                    ],
                    [
                        92.4108267973561,
                        268.2005320002322
                    ],
                    [
                        91.06184546350892,
                        267.89693792119994
                    ],
                    [
                        89.19575461835325,
                        268.4390424134878
                    ],
                    [
                        86.67765612850508,
                        270.49789066137873
                    ],
                    [
                        86.67765612850508,
                        271.2992677653205
                    ],
                    [
                        85.21625968350395,
                        271.8189336445579
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Nandurbar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kendujhar",
            "dt_code": "375",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        269.26227966473766,
                        268.0704251052006
                    ],
                    [
                        268.07067948650615,
                        271.5591150757416
                    ],
                    [
                        265.8448602856581,
                        270.60620087741404
                    ],
                    [
                        264.60829406296443,
                        271.47250250049075
                    ],
                    [
                        264.2710487295028,
                        269.63122841537904
                    ],
                    [
                        262.652271128886,
                        270.34624794224726
                    ],
                    [
                        261.2583237505771,
                        270.3679117892069
                    ],
                    [
                        259.6395461499603,
                        269.63122841537904
                    ],
                    [
                        259.5496140610371,
                        269.1544271410896
                    ],
                    [
                        257.1888967268046,
                        269.1761020395825
                    ],
                    [
                        256.49192303764994,
                        269.9995991119276
                    ],
                    [
                        254.96307752595612,
                        268.9159899623066
                    ],
                    [
                        253.34429992533978,
                        267.0943911222577
                    ],
                    [
                        254.19865477010967,
                        266.0311311537111
                    ],
                    [
                        253.6365792143397,
                        265.2496489599834
                    ],
                    [
                        254.0862396589555,
                        264.2072606570708
                    ],
                    [
                        252.9171225029545,
                        261.1642556092064
                    ],
                    [
                        251.9728355692614,
                        261.38174739247296
                    ],
                    [
                        252.1077337026461,
                        259.7718163536691
                    ],
                    [
                        253.43423201426276,
                        258.57450683470944
                    ],
                    [
                        254.1761717478787,
                        258.7704734776293
                    ],
                    [
                        254.7157642814177,
                        257.02795217095087
                    ],
                    [
                        253.27685085864732,
                        256.6574930816825
                    ],
                    [
                        252.66980925841608,
                        255.8509890366209
                    ],
                    [
                        253.20940179195486,
                        254.69522196799147
                    ],
                    [
                        255.00804357041784,
                        253.4079116870576
                    ],
                    [
                        259.2123687275755,
                        255.0878133931747
                    ],
                    [
                        259.0325045497291,
                        255.78558414114093
                    ],
                    [
                        260.8761123726538,
                        256.78825030177217
                    ],
                    [
                        261.1009425949617,
                        259.0099652500108
                    ],
                    [
                        262.0452295286548,
                        259.2494317283371
                    ],
                    [
                        264.24856570727184,
                        260.8379790403413
                    ],
                    [
                        265.05795450758023,
                        261.8818998726214
                    ],
                    [
                        264.0012524627334,
                        263.3817015279703
                    ],
                    [
                        265.7998942411964,
                        264.38102480913204
                    ],
                    [
                        266.2945207302739,
                        265.11937620374584
                    ],
                    [
                        265.4401658855036,
                        266.29156650535117
                    ],
                    [
                        265.57506401888827,
                        267.50654439864485
                    ],
                    [
                        266.94652837496665,
                        267.3763986401044
                    ],
                    [
                        267.8233662419673,
                        265.81407928449573
                    ],
                    [
                        269.1723475758147,
                        267.0293075951583
                    ],
                    [
                        269.26227966473766,
                        268.0704251052006
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Kendujhar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Balasore",
            "dt_code": "377",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        276.50181282305175,
                        268.4390424134878
                    ],
                    [
                        275.64745797828164,
                        268.1788480230847
                    ],
                    [
                        273.75888411089545,
                        269.1327520412059
                    ],
                    [
                        273.4666048218953,
                        270.10793238914977
                    ],
                    [
                        271.4431328211242,
                        269.6745691583571
                    ],
                    [
                        269.26227966473766,
                        268.0704251052006
                    ],
                    [
                        269.1723475758147,
                        267.0293075951583
                    ],
                    [
                        271.66796304343234,
                        265.0759503164828
                    ],
                    [
                        272.4323857992788,
                        263.55552810731183
                    ],
                    [
                        273.39915575520286,
                        262.6427915730003
                    ],
                    [
                        274.74813708905026,
                        262.25150744789653
                    ],
                    [
                        278.6601829572073,
                        262.31672611429855
                    ],
                    [
                        279.2447415352078,
                        260.533412090034
                    ],
                    [
                        280.9084851802861,
                        259.6194665849923
                    ],
                    [
                        280.0541303355162,
                        258.1607437695603
                    ],
                    [
                        280.16654544667017,
                        257.2022647999703
                    ],
                    [
                        282.14505140297956,
                        256.5703173851811
                    ],
                    [
                        282.23498349190277,
                        258.31319159441324
                    ],
                    [
                        282.72960998097983,
                        259.1188167835528
                    ],
                    [
                        285.06784429298204,
                        259.68476059413837
                    ],
                    [
                        285.69736891544426,
                        262.09932332841703
                    ],
                    [
                        284.5732178039045,
                        263.20786178190934
                    ],
                    [
                        281.8078060695175,
                        263.3599722799399
                    ],
                    [
                        280.75110402467067,
                        263.7727928285829
                    ],
                    [
                        277.8283111346682,
                        266.16135249497614
                    ],
                    [
                        276.50181282305175,
                        268.4390424134878
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Balasore",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jharsuguda",
            "dt_code": "371",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        228.45559431585684,
                        258.7704734776293
                    ],
                    [
                        230.34416818324303,
                        259.40180632771614
                    ],
                    [
                        232.95219876201463,
                        256.87541756694174
                    ],
                    [
                        234.14379894024614,
                        257.50727950153583
                    ],
                    [
                        235.49278027409355,
                        256.17798497742064
                    ],
                    [
                        238.43805618632678,
                        255.76378208633906
                    ],
                    [
                        239.7420714757127,
                        255.4149204320779
                    ],
                    [
                        239.96690169802037,
                        256.70107966486756
                    ],
                    [
                        238.7078524530964,
                        259.6194665849923
                    ],
                    [
                        237.96591271948046,
                        259.07527679712877
                    ],
                    [
                        235.7850595630939,
                        259.6412314632642
                    ],
                    [
                        234.18876498470786,
                        259.48887293462894
                    ],
                    [
                        233.98641778463093,
                        262.6427915730003
                    ],
                    [
                        231.51328533924402,
                        262.9253440907642
                    ],
                    [
                        230.00692284978118,
                        260.7727181216838
                    ],
                    [
                        228.09586596016425,
                        260.0764852526244
                    ],
                    [
                        226.92674880416303,
                        261.64271017786166
                    ],
                    [
                        226.5445374262399,
                        260.79447196891806
                    ],
                    [
                        228.45559431585684,
                        258.7704734776293
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Jharsuguda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bametara",
            "dt_code": "718",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        200.57664674967918,
                        262.31672611429855
                    ],
                    [
                        199.81222399383228,
                        263.18613088744826
                    ],
                    [
                        199.74477492714004,
                        265.445044303727
                    ],
                    [
                        198.50820870444682,
                        266.5736717289267
                    ],
                    [
                        197.6538538596767,
                        266.2264604163047
                    ],
                    [
                        196.84446505936808,
                        264.1203736589941
                    ],
                    [
                        195.5854158144441,
                        263.2295924705091
                    ],
                    [
                        193.3595966135963,
                        263.6424364620873
                    ],
                    [
                        192.88745314674952,
                        260.685700655545
                    ],
                    [
                        194.6860949252125,
                        259.6629961329742
                    ],
                    [
                        195.06830630313607,
                        258.68337928484306
                    ],
                    [
                        197.04681225944546,
                        257.37656396225395
                    ],
                    [
                        197.72130292636894,
                        255.93819260372197
                    ],
                    [
                        201.43100159444907,
                        256.6356994738483
                    ],
                    [
                        204.12896426214365,
                        258.94465182151635
                    ],
                    [
                        202.82494897275797,
                        259.8153429825902
                    ],
                    [
                        200.62161279414067,
                        259.9894411623972
                    ],
                    [
                        200.9813411498335,
                        260.9684952714364
                    ],
                    [
                        200.57664674967918,
                        262.31672611429855
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Bametara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rajnandgaon",
            "dt_code": "408",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        183.91672727666514,
                        274.5885506642957
                    ],
                    [
                        183.691897054357,
                        272.83628016414497
                    ],
                    [
                        182.47781385389453,
                        272.92284256151237
                    ],
                    [
                        182.11808549820194,
                        270.28125519830496
                    ],
                    [
                        182.47781385389453,
                        269.046049627439
                    ],
                    [
                        184.68115003251182,
                        268.243899348228
                    ],
                    [
                        185.37812372166627,
                        266.55197254752545
                    ],
                    [
                        186.50227483320577,
                        264.33758500130074
                    ],
                    [
                        186.16502949974392,
                        261.3599991470084
                    ],
                    [
                        186.77207109997494,
                        259.7718163536691
                    ],
                    [
                        187.67139198920677,
                        259.7718163536691
                    ],
                    [
                        188.0985694115916,
                        258.6180564427656
                    ],
                    [
                        189.33513563428505,
                        258.79224650272477
                    ],
                    [
                        190.3468716346706,
                        260.3158393248062
                    ],
                    [
                        191.85323412413322,
                        260.2070451413421
                    ],
                    [
                        192.88745314674952,
                        260.685700655545
                    ],
                    [
                        193.3595966135963,
                        263.6424364620873
                    ],
                    [
                        193.17973243574988,
                        265.68383837695603
                    ],
                    [
                        192.5502078132879,
                        268.46072396644576
                    ],
                    [
                        193.2696645246731,
                        268.30894885471724
                    ],
                    [
                        193.40456265805778,
                        270.1729299472209
                    ],
                    [
                        192.64013990221088,
                        270.60620087741404
                    ],
                    [
                        192.1230303909026,
                        271.7323306467422
                    ],
                    [
                        189.6723809677469,
                        271.7539816953537
                    ],
                    [
                        189.8522451455931,
                        273.2474232625957
                    ],
                    [
                        189.20023750090013,
                        275.2372107113525
                    ],
                    [
                        187.69387501143729,
                        275.5830902646232
                    ],
                    [
                        187.67139198920677,
                        276.70685155826664
                    ],
                    [
                        189.73983003443914,
                        277.6141183537231
                    ],
                    [
                        189.6723809677469,
                        279.01754369223715
                    ],
                    [
                        190.23445652351643,
                        280.31228539494987
                    ],
                    [
                        188.30091661166875,
                        281.433832409742
                    ],
                    [
                        187.85125616705295,
                        282.6626232301788
                    ],
                    [
                        186.6821390110522,
                        282.94278658313283
                    ],
                    [
                        186.16502949974392,
                        284.5154124482417
                    ],
                    [
                        185.26570861051232,
                        283.95541555045895
                    ],
                    [
                        184.25397261012677,
                        284.7307616505742
                    ],
                    [
                        183.66941403212627,
                        285.9363667516583
                    ],
                    [
                        183.08485545412577,
                        285.24752229959677
                    ],
                    [
                        182.00567038704799,
                        285.3766953991411
                    ],
                    [
                        181.46607785350898,
                        283.67536884329616
                    ],
                    [
                        184.86101421035823,
                        282.29620696045447
                    ],
                    [
                        184.34390469904997,
                        281.34757805026777
                    ],
                    [
                        184.72611607697354,
                        278.32672934026704
                    ],
                    [
                        183.30968567643367,
                        278.4130920196855
                    ],
                    [
                        184.1865235434343,
                        276.9445023831133
                    ],
                    [
                        183.91672727666514,
                        274.5885506642957
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Rajnandgaon",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Amravati",
            "dt_code": "503",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        126.58502058815475,
                        267.3763986401044
                    ],
                    [
                        125.97797898792305,
                        266.5736717289267
                    ],
                    [
                        128.36117934438676,
                        264.4896207419155
                    ],
                    [
                        128.338696322156,
                        262.99054357083503
                    ],
                    [
                        129.41788138923403,
                        262.12106453687977
                    ],
                    [
                        130.11485507838825,
                        262.38194292188825
                    ],
                    [
                        131.5312854789281,
                        260.8814852811474
                    ],
                    [
                        131.64370059008206,
                        261.0990040305394
                    ],
                    [
                        131.89101383462048,
                        260.9467430852385
                    ],
                    [
                        132.13832707915913,
                        260.79447196891806
                    ],
                    [
                        132.45308939039046,
                        260.5551682229161
                    ],
                    [
                        134.63394254677678,
                        260.9249906914684
                    ],
                    [
                        135.66816156939308,
                        259.83710598439757
                    ],
                    [
                        138.59095445939533,
                        259.6412314632642
                    ],
                    [
                        139.53524139308888,
                        260.685700655545
                    ],
                    [
                        140.0073848599352,
                        263.4903446825006
                    ],
                    [
                        138.23122610370297,
                        263.07747332665
                    ],
                    [
                        138.72585259278026,
                        265.90090247803136
                    ],
                    [
                        139.80503765985804,
                        265.5535901255982
                    ],
                    [
                        143.334872150092,
                        265.3582039711842
                    ],
                    [
                        144.54895535055425,
                        265.74895974848255
                    ],
                    [
                        146.6848424624793,
                        265.16280127333005
                    ],
                    [
                        148.8432125966351,
                        263.92486590912625
                    ],
                    [
                        149.54018628578933,
                        263.09920525066366
                    ],
                    [
                        151.29386201979105,
                        262.5123709624061
                    ],
                    [
                        152.41801313133033,
                        262.79493956616363
                    ],
                    [
                        152.62036033140748,
                        263.96831351258334
                    ],
                    [
                        152.82270753148464,
                        265.445044303727
                    ],
                    [
                        151.60862433102193,
                        265.5970070255818
                    ],
                    [
                        149.94488068594387,
                        266.595370707002
                    ],
                    [
                        147.4042991738645,
                        266.20475797947046
                    ],
                    [
                        147.02208779594116,
                        267.78850185591307
                    ],
                    [
                        148.37106912978834,
                        269.60955774234714
                    ],
                    [
                        148.3485861075576,
                        272.5332867375673
                    ],
                    [
                        150.03481277486685,
                        273.59359349079153
                    ],
                    [
                        150.84420157517548,
                        275.95053214431397
                    ],
                    [
                        150.2821260194055,
                        276.642033586652
                    ],
                    [
                        148.14623890748044,
                        277.3549344390378
                    ],
                    [
                        146.79725757363326,
                        277.0741201290513
                    ],
                    [
                        145.85297063994017,
                        278.2835468331793
                    ],
                    [
                        142.99762681663015,
                        279.0391284359643
                    ],
                    [
                        141.10905294924396,
                        278.65057334408
                    ],
                    [
                        141.19898503816717,
                        277.4845299099044
                    ],
                    [
                        140.52449437124324,
                        276.23147882246866
                    ],
                    [
                        140.52449437124324,
                        274.95614646933177
                    ],
                    [
                        139.33289419301173,
                        274.1776407997751
                    ],
                    [
                        135.75809365831628,
                        274.22089782779216
                    ],
                    [
                        133.44234236854504,
                        273.96134379837184
                    ],
                    [
                        134.13931605769926,
                        270.67118460323945
                    ],
                    [
                        134.0044179243148,
                        267.983683131342
                    ],
                    [
                        131.5312854789281,
                        268.72088685758655
                    ],
                    [
                        128.36117934438676,
                        268.76424451467085
                    ],
                    [
                        127.48434147738612,
                        267.2462455835281
                    ],
                    [
                        126.58502058815475,
                        267.3763986401044
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Amravati",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Surat",
            "dt_code": "492",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        68.84861949948981,
                        264.31586478988004
                    ],
                    [
                        69.61304225533672,
                        265.16280127333005
                    ],
                    [
                        71.36671798933821,
                        265.0325236114096
                    ],
                    [
                        71.88382750064625,
                        263.7293415283318
                    ],
                    [
                        76.42539799126553,
                        264.0117602948915
                    ],
                    [
                        79.39315692572973,
                        263.4686164629562
                    ],
                    [
                        80.00019852596097,
                        264.1420957162312
                    ],
                    [
                        82.6756781714248,
                        264.35930500771246
                    ],
                    [
                        83.01292350488666,
                        265.510172409243
                    ],
                    [
                        81.41662892650061,
                        266.0311311537111
                    ],
                    [
                        79.41563994796047,
                        265.74895974848255
                    ],
                    [
                        78.24652279195948,
                        268.2655827191089
                    ],
                    [
                        75.66097523541885,
                        270.5845392348828
                    ],
                    [
                        75.81835639103429,
                        272.4683545050863
                    ],
                    [
                        76.98747354703528,
                        272.8795617600505
                    ],
                    [
                        77.39216794718959,
                        273.70176128481825
                    ],
                    [
                        77.14485470265072,
                        274.69667299423213
                    ],
                    [
                        74.58179016834083,
                        273.4205147193642
                    ],
                    [
                        73.09791070110896,
                        271.4941559437516
                    ],
                    [
                        72.04120865626169,
                        270.71450608581114
                    ],
                    [
                        71.38920101156896,
                        271.1476768834453
                    ],
                    [
                        69.07344972179794,
                        270.82280628859183
                    ],
                    [
                        67.16239283218079,
                        270.2379256996293
                    ],
                    [
                        67.40970607671943,
                        268.48240531749315
                    ],
                    [
                        66.82514749871893,
                        266.8123493084137
                    ],
                    [
                        67.6570193212583,
                        266.3566707625562
                    ],
                    [
                        68.2190948770276,
                        264.53305768065474
                    ],
                    [
                        68.84861949948981,
                        264.31586478988004
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Surat",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dhule",
            "dt_code": "498",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        105.24863249113628,
                        265.31478257953853
                    ],
                    [
                        103.92213417951984,
                        266.4000725832204
                    ],
                    [
                        104.07951533513506,
                        267.4197813703371
                    ],
                    [
                        102.68556795682662,
                        268.78592304068036
                    ],
                    [
                        101.6063828897486,
                        268.7425657869782
                    ],
                    [
                        100.59464688936305,
                        269.4578574008065
                    ],
                    [
                        100.05505435582404,
                        270.8661249685841
                    ],
                    [
                        100.81947711167118,
                        271.32092280630616
                    ],
                    [
                        100.86444315613267,
                        272.94448266436746
                    ],
                    [
                        101.58389986751786,
                        273.3556069100818
                    ],
                    [
                        101.94362822321045,
                        275.9289194875118
                    ],
                    [
                        101.1567224451328,
                        277.2037308370189
                    ],
                    [
                        99.38056368890057,
                        277.59252076759645
                    ],
                    [
                        96.99736333243686,
                        276.62042720449733
                    ],
                    [
                        97.22219355474499,
                        275.34530348229924
                    ],
                    [
                        95.35610270958932,
                        274.3722912018637
                    ],
                    [
                        93.8497402201267,
                        274.91290286157647
                    ],
                    [
                        93.04035141981831,
                        274.3290369398761
                    ],
                    [
                        89.75783017412323,
                        273.9829743883762
                    ],
                    [
                        87.73435817335212,
                        274.4155446738464
                    ],
                    [
                        86.58772403958187,
                        274.3290369398761
                    ],
                    [
                        85.80081826150422,
                        272.8579210614112
                    ],
                    [
                        85.21625968350395,
                        271.84058389546976
                    ],
                    [
                        85.21625968350395,
                        271.8189336445579
                    ],
                    [
                        86.67765612850508,
                        271.2992677653205
                    ],
                    [
                        86.67765612850508,
                        270.49789066137873
                    ],
                    [
                        89.19575461835325,
                        268.4390424134878
                    ],
                    [
                        91.06184546350892,
                        267.89693792119994
                    ],
                    [
                        92.4108267973561,
                        268.2005320002322
                    ],
                    [
                        94.52423088705018,
                        266.9425267171776
                    ],
                    [
                        94.81651017605054,
                        265.2496489599834
                    ],
                    [
                        97.55943888820684,
                        265.3364933774896
                    ],
                    [
                        98.50372582189993,
                        264.2507029254012
                    ],
                    [
                        98.84097115536156,
                        262.09932332841703
                    ],
                    [
                        100.1225034225165,
                        261.79492469904255
                    ],
                    [
                        102.91039817913429,
                        262.86014275602156
                    ],
                    [
                        103.71978697944269,
                        264.51133931372397
                    ],
                    [
                        105.24863249113628,
                        265.31478257953853
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Dhule",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tapi",
            "dt_code": "493",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        84.60921808327248,
                        271.6673763040509
                    ],
                    [
                        85.21625968350395,
                        271.84058389546976
                    ],
                    [
                        85.80081826150422,
                        272.8579210614112
                    ],
                    [
                        84.45183692765704,
                        272.4683545050863
                    ],
                    [
                        83.79982928296408,
                        271.7106793986653
                    ],
                    [
                        81.03441754857727,
                        271.645724457481
                    ],
                    [
                        79.9327494592685,
                        273.160872774634
                    ],
                    [
                        80.02268154819171,
                        274.6534246541515
                    ],
                    [
                        78.33645488088268,
                        274.5669256063506
                    ],
                    [
                        77.39216794718959,
                        273.70176128481825
                    ],
                    [
                        76.98747354703528,
                        272.8795617600505
                    ],
                    [
                        75.81835639103429,
                        272.4683545050863
                    ],
                    [
                        75.66097523541885,
                        270.5845392348828
                    ],
                    [
                        78.24652279195948,
                        268.2655827191089
                    ],
                    [
                        79.41563994796047,
                        265.74895974848255
                    ],
                    [
                        81.41662892650061,
                        266.0311311537111
                    ],
                    [
                        83.01292350488666,
                        265.510172409243
                    ],
                    [
                        84.54176901658002,
                        265.31478257953853
                    ],
                    [
                        87.01490146196693,
                        264.53305768065474
                    ],
                    [
                        87.1722826175826,
                        263.57725550436294
                    ],
                    [
                        87.1722826175826,
                        263.55552810731183
                    ],
                    [
                        89.84776226304643,
                        262.8384085655481
                    ],
                    [
                        91.48902288589375,
                        262.94707745684065
                    ],
                    [
                        92.16351355281745,
                        263.94658981350665
                    ],
                    [
                        90.52225292996968,
                        264.51133931372397
                    ],
                    [
                        88.11656955127569,
                        264.7719461996339
                    ],
                    [
                        86.83503728412052,
                        265.5752986776284
                    ],
                    [
                        86.58772403958187,
                        267.072696816181
                    ],
                    [
                        84.83404830558038,
                        267.5499246969801
                    ],
                    [
                        84.72163319442643,
                        269.06772553304796
                    ],
                    [
                        82.54078003804011,
                        269.37116706610857
                    ],
                    [
                        83.57499906065641,
                        270.5628773920299
                    ],
                    [
                        84.5192859943495,
                        271.34257764751965
                    ],
                    [
                        84.60921808327248,
                        271.6673763040509
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Tapi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bargarh",
            "dt_code": "370",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        214.44867146607567,
                        270.4112388816923
                    ],
                    [
                        214.4711544883064,
                        269.37116706610857
                    ],
                    [
                        219.26003822346433,
                        269.046049627439
                    ],
                    [
                        220.90129884631187,
                        270.0429330249793
                    ],
                    [
                        222.58752551362113,
                        269.60955774234714
                    ],
                    [
                        223.7116766251604,
                        265.8574912889921
                    ],
                    [
                        223.7566426696219,
                        265.8574912889921
                    ],
                    [
                        223.8016087140836,
                        265.8574912889921
                    ],
                    [
                        225.53280142585436,
                        266.4000725832204
                    ],
                    [
                        225.62273351477756,
                        265.423334526894
                    ],
                    [
                        224.72341262554596,
                        263.9031417994257
                    ],
                    [
                        225.39790329246944,
                        262.0123564274094
                    ],
                    [
                        226.92674880416303,
                        261.64271017786166
                    ],
                    [
                        228.09586596016425,
                        260.0764852526244
                    ],
                    [
                        230.00692284978118,
                        260.7727181216838
                    ],
                    [
                        231.51328533924402,
                        262.9253440907642
                    ],
                    [
                        232.79481760639896,
                        264.9022385880217
                    ],
                    [
                        232.5924704063218,
                        267.00761268017953
                    ],
                    [
                        233.15454596209156,
                        268.04873991508055
                    ],
                    [
                        232.90723271755292,
                        269.046049627439
                    ],
                    [
                        232.1203269394755,
                        270.32458389478785
                    ],
                    [
                        231.28845511693635,
                        269.41451263585
                    ],
                    [
                        230.25423609432005,
                        269.9345967366193
                    ],
                    [
                        228.65794151593377,
                        269.7829174985557
                    ],
                    [
                        227.82606969339463,
                        270.60620087741404
                    ],
                    [
                        226.47708835954745,
                        270.88778400840516
                    ],
                    [
                        225.44286933693115,
                        271.9704812144272
                    ],
                    [
                        225.71266560370077,
                        273.55032498673506
                    ],
                    [
                        223.95898986969905,
                        274.1127537753662
                    ],
                    [
                        222.94725386931373,
                        272.9012022600788
                    ],
                    [
                        221.68820462438953,
                        274.047864971711
                    ],
                    [
                        219.48486844577246,
                        274.24252604537196
                    ],
                    [
                        218.13588711192506,
                        273.26906038870294
                    ],
                    [
                        215.370475377538,
                        276.08020396274134
                    ],
                    [
                        215.07819608853765,
                        273.615227445692
                    ],
                    [
                        215.25806026638406,
                        271.4941559437516
                    ],
                    [
                        214.44867146607567,
                        270.4112388816923
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Bargarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nagpur",
            "dt_code": "505",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        168.87558540426744,
                        263.2730532301903
                    ],
                    [
                        167.9537814928051,
                        264.05520625618135
                    ],
                    [
                        167.9537814928051,
                        265.0542370661806
                    ],
                    [
                        169.41517793780645,
                        267.44147243140935
                    ],
                    [
                        169.05544958211385,
                        269.1110767399153
                    ],
                    [
                        168.2235777595747,
                        269.63122841537904
                    ],
                    [
                        169.7524232712683,
                        270.67118460323945
                    ],
                    [
                        170.58429509380744,
                        272.36013013906404
                    ],
                    [
                        168.74068727088274,
                        275.34530348229924
                    ],
                    [
                        168.5833061152673,
                        276.29630796304826
                    ],
                    [
                        167.3692229148046,
                        277.13892636256554
                    ],
                    [
                        166.1551397143421,
                        277.268533552842
                    ],
                    [
                        165.66051322526482,
                        278.32672934026704
                    ],
                    [
                        164.19911678026347,
                        278.3699110690601
                    ],
                    [
                        163.05248264649322,
                        276.8580866408
                    ],
                    [
                        161.70350131264604,
                        275.9289194875118
                    ],
                    [
                        160.10720673426,
                        275.45339133376325
                    ],
                    [
                        158.42098006695096,
                        273.48542074477064
                    ],
                    [
                        157.02703268864207,
                        272.64150314787855
                    ],
                    [
                        156.93710059971886,
                        271.7323306467422
                    ],
                    [
                        155.83543251041033,
                        271.2776125245467
                    ],
                    [
                        155.07100975456342,
                        270.0862661351256
                    ],
                    [
                        152.8002245092539,
                        268.0704251052006
                    ],
                    [
                        151.11399784194464,
                        267.8752511128732
                    ],
                    [
                        149.94488068594387,
                        266.595370707002
                    ],
                    [
                        151.60862433102193,
                        265.5970070255818
                    ],
                    [
                        152.82270753148464,
                        265.445044303727
                    ],
                    [
                        152.62036033140748,
                        263.96831351258334
                    ],
                    [
                        153.76699446517773,
                        263.4686164629562
                    ],
                    [
                        154.84617953225575,
                        264.1203736589941
                    ],
                    [
                        159.477682111798,
                        263.9031417994257
                    ],
                    [
                        159.72499535633665,
                        262.49063347171574
                    ],
                    [
                        160.57935020110654,
                        262.0558402913773
                    ],
                    [
                        162.08571269056938,
                        262.25150744789653
                    ],
                    [
                        164.24408282472518,
                        261.5774722777289
                    ],
                    [
                        164.40146398034085,
                        260.46814244401054
                    ],
                    [
                        166.71721527011186,
                        261.1642556092064
                    ],
                    [
                        168.24606078180545,
                        261.1642556092064
                    ],
                    [
                        168.4484079818826,
                        262.4036814447588
                    ],
                    [
                        168.87558540426744,
                        263.2730532301903
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Nagpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Baloda Bazar",
            "dt_code": "721",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        218.7878947566178,
                        263.96831351258334
                    ],
                    [
                        217.95602293407865,
                        263.0340088607277
                    ],
                    [
                        215.21309422192235,
                        264.1638175683122
                    ],
                    [
                        215.30302631084555,
                        266.595370707002
                    ],
                    [
                        211.99802204291973,
                        266.6387680532398
                    ],
                    [
                        210.78393884245725,
                        267.68006073027414
                    ],
                    [
                        208.76046684168614,
                        266.79065236270134
                    ],
                    [
                        207.90611199691625,
                        265.8357853886843
                    ],
                    [
                        204.03903217322045,
                        264.0334833781556
                    ],
                    [
                        203.6118547508354,
                        262.33846525667445
                    ],
                    [
                        201.40851857221833,
                        262.5993188614637
                    ],
                    [
                        200.57664674967918,
                        262.31672611429855
                    ],
                    [
                        200.9813411498335,
                        260.9684952714364
                    ],
                    [
                        200.62161279414067,
                        259.9894411623972
                    ],
                    [
                        202.82494897275797,
                        259.8153429825902
                    ],
                    [
                        204.12896426214365,
                        258.94465182151635
                    ],
                    [
                        205.61284372937575,
                        257.8558172900766
                    ],
                    [
                        208.0185271080702,
                        259.79357977235304
                    ],
                    [
                        210.82890488691896,
                        260.5551682229161
                    ],
                    [
                        211.6158106649964,
                        260.05472454245665
                    ],
                    [
                        214.78591679953752,
                        260.59867986509107
                    ],
                    [
                        216.67449066692393,
                        260.3811133381504
                    ],
                    [
                        217.86609084515544,
                        260.85973226456315
                    ],
                    [
                        219.0127249789257,
                        260.3811133381504
                    ],
                    [
                        219.5523175124647,
                        261.5774722777289
                    ],
                    [
                        218.63051360100235,
                        263.1643997871097
                    ],
                    [
                        218.7878947566178,
                        263.96831351258334
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Baloda Bazar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mungeli",
            "dt_code": "717",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        197.72130292636894,
                        255.93819260372197
                    ],
                    [
                        197.94613314867684,
                        253.56068217231507
                    ],
                    [
                        198.53069172667756,
                        250.54698672957738
                    ],
                    [
                        197.87868408198437,
                        249.95687198526684
                    ],
                    [
                        197.3390915484456,
                        248.22933445700005
                    ],
                    [
                        199.49746168260117,
                        247.46353669894637
                    ],
                    [
                        199.90215608275548,
                        246.23770477966417
                    ],
                    [
                        201.0712732387567,
                        245.3616905377184
                    ],
                    [
                        201.34106950552587,
                        246.60990473769982
                    ],
                    [
                        204.71352284014415,
                        250.28473292612796
                    ],
                    [
                        203.54440568414338,
                        251.1587916316035
                    ],
                    [
                        201.58838275006474,
                        251.399097409558
                    ],
                    [
                        201.61086577229548,
                        252.16353384788846
                    ],
                    [
                        204.89338701799056,
                        256.0907907085739
                    ],
                    [
                        205.61284372937575,
                        257.8558172900766
                    ],
                    [
                        204.12896426214365,
                        258.94465182151635
                    ],
                    [
                        201.43100159444907,
                        256.6356994738483
                    ],
                    [
                        197.72130292636894,
                        255.93819260372197
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Mungeli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Botad",
            "dt_code": "726",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        52.00883584862936,
                        260.9467430852385
                    ],
                    [
                        50.007846870089224,
                        258.05184617579437
                    ],
                    [
                        50.187711047935636,
                        257.1368991426779
                    ],
                    [
                        49.10852598085762,
                        256.3087700450503
                    ],
                    [
                        50.68233753701293,
                        255.15323861573285
                    ],
                    [
                        50.47999033693577,
                        253.03685412306936
                    ],
                    [
                        49.76053362555058,
                        251.8577908110779
                    ],
                    [
                        51.15448100385947,
                        250.56883981193528
                    ],
                    [
                        56.01081380570963,
                        250.10987980142187
                    ],
                    [
                        55.606119405555546,
                        251.770427937409
                    ],
                    [
                        58.9111236734816,
                        252.0761829820002
                    ],
                    [
                        59.96782571832864,
                        252.84038689696752
                    ],
                    [
                        58.93360669571234,
                        254.38982552424983
                    ],
                    [
                        56.865168650479745,
                        253.93165272622548
                    ],
                    [
                        56.0332968279406,
                        255.0878133931747
                    ],
                    [
                        54.14472296055419,
                        255.82918761626541
                    ],
                    [
                        53.38030020470751,
                        258.05184617579437
                    ],
                    [
                        52.413530248783445,
                        258.6398309327759
                    ],
                    [
                        52.00883584862936,
                        260.9467430852385
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Botad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chhota Udaipur",
            "dt_code": "731",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        80.53979105949975,
                        255.19685437083308
                    ],
                    [
                        80.15757968157641,
                        252.84038689696752
                    ],
                    [
                        81.01193452634652,
                        250.76550785766307
                    ],
                    [
                        82.3609158601937,
                        250.65624998653277
                    ],
                    [
                        83.4850669717332,
                        251.4427865753343
                    ],
                    [
                        83.46258394950246,
                        247.8136487507927
                    ],
                    [
                        84.54176901658002,
                        247.28845975915794
                    ],
                    [
                        85.62095408365803,
                        248.0105622924728
                    ],
                    [
                        87.66690910665989,
                        247.11336886522827
                    ],
                    [
                        88.29643372912187,
                        247.2665741607115
                    ],
                    [
                        89.19575461835325,
                        249.34473483659679
                    ],
                    [
                        90.61218501889289,
                        248.99486601565394
                    ],
                    [
                        90.22997364096977,
                        250.91845983306354
                    ],
                    [
                        88.45381488473731,
                        250.35029928806267
                    ],
                    [
                        88.22898466242941,
                        251.26802475502126
                    ],
                    [
                        89.24072066281497,
                        252.64390235680003
                    ],
                    [
                        89.26320368504571,
                        254.45527112511826
                    ],
                    [
                        88.83602626266088,
                        255.74197982002136
                    ],
                    [
                        89.60044901850756,
                        256.78825030177217
                    ],
                    [
                        86.54275799512038,
                        258.00828567001014
                    ],
                    [
                        85.30619177242716,
                        256.7664579586875
                    ],
                    [
                        83.10285559380986,
                        256.37415972880035
                    ],
                    [
                        82.2709837712705,
                        254.86971553336377
                    ],
                    [
                        80.53979105949975,
                        255.19685437083308
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Chhota Udaipur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gir Somnath",
            "dt_code": "729",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        41.19450215562006,
                        269.1761020395825
                    ],
                    [
                        43.42032135646832,
                        269.19777673670075
                    ],
                    [
                        45.308895223854506,
                        270.73616652675184
                    ],
                    [
                        45.15151406823884,
                        272.38177541019905
                    ],
                    [
                        46.567944468778705,
                        272.48999878147566
                    ],
                    [
                        45.96090286854724,
                        273.68012812207587
                    ],
                    [
                        46.36559726870155,
                        275.0210104029956
                    ],
                    [
                        44.072329001160824,
                        276.0369808083752
                    ],
                    [
                        43.330389267545115,
                        275.8640803389601
                    ],
                    [
                        42.16127211154412,
                        275.8640803389601
                    ],
                    [
                        40.78980775546597,
                        276.6852457635207
                    ],
                    [
                        35.214018242230395,
                        274.1992694125984
                    ],
                    [
                        32.15632721884322,
                        271.9271829054315
                    ],
                    [
                        34.51704455307595,
                        270.97441816689735
                    ],
                    [
                        35.84354286469261,
                        269.5445445168843
                    ],
                    [
                        37.349905354155226,
                        267.3763986401044
                    ],
                    [
                        39.62069059946498,
                        268.04873991508055
                    ],
                    [
                        39.598207577234234,
                        269.71790909732806
                    ],
                    [
                        41.19450215562006,
                        269.1761020395825
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Gir Somnath",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jhargram",
            "dt_code": "776",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2017_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        279.6944019798236,
                        244.4634114047775
                    ],
                    [
                        280.03164731328525,
                        244.9673696005836
                    ],
                    [
                        279.78433406874683,
                        248.51370578623175
                    ],
                    [
                        280.9534512247476,
                        249.25727282871833
                    ],
                    [
                        281.5829758472098,
                        251.18063868631214
                    ],
                    [
                        281.24573051374796,
                        252.97136697073336
                    ],
                    [
                        280.5937228690552,
                        253.99711172823817
                    ],
                    [
                        282.14505140297956,
                        256.5703173851811
                    ],
                    [
                        280.16654544667017,
                        257.2022647999703
                    ],
                    [
                        280.0541303355162,
                        258.1607437695603
                    ],
                    [
                        278.59273389051486,
                        257.37656396225395
                    ],
                    [
                        278.9974282906692,
                        255.3931147792477
                    ],
                    [
                        278.0531413569761,
                        254.65159645365782
                    ],
                    [
                        276.18705051182064,
                        254.45527112511826
                    ],
                    [
                        274.56827291120385,
                        252.57840366039642
                    ],
                    [
                        276.1196014451282,
                        250.83105999601986
                    ],
                    [
                        276.2994656229746,
                        249.65082459930676
                    ],
                    [
                        275.10786544474263,
                        249.23540678551217
                    ],
                    [
                        275.64745797828164,
                        248.25121047799308
                    ],
                    [
                        275.1303484669734,
                        246.85070634133768
                    ],
                    [
                        273.5565369108185,
                        246.85070634133768
                    ],
                    [
                        273.30922366627965,
                        245.55882432749638
                    ],
                    [
                        272.09514046581717,
                        244.66062630068006
                    ],
                    [
                        272.8370801994331,
                        243.30167255404905
                    ],
                    [
                        274.0961294443571,
                        243.7839790348607
                    ],
                    [
                        277.0189223343598,
                        245.646433640704
                    ],
                    [
                        278.795081090592,
                        245.66833542069753
                    ],
                    [
                        279.6944019798236,
                        244.4634114047775
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Jhargram",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rajkot",
            "dt_code": "476",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        30.80734588499604,
                        261.3165020342865
                    ],
                    [
                        29.70567779568728,
                        260.14176613380334
                    ],
                    [
                        29.076153173225066,
                        259.2712001546953
                    ],
                    [
                        31.819081885381365,
                        257.48549410388233
                    ],
                    [
                        32.53853859676656,
                        255.98179311891238
                    ],
                    [
                        33.48282553045965,
                        255.19685437083308
                    ],
                    [
                        36.697897709462495,
                        255.26227641390966
                    ],
                    [
                        37.192524198539786,
                        254.43345613736705
                    ],
                    [
                        37.1475581540783,
                        252.2072079951239
                    ],
                    [
                        36.27072028707744,
                        252.11985884355863
                    ],
                    [
                        35.59622962015396,
                        251.0932491771332
                    ],
                    [
                        35.483814508999785,
                        248.53557898379137
                    ],
                    [
                        37.25997326523225,
                        248.2074582187015
                    ],
                    [
                        36.72038073169347,
                        247.15714289738588
                    ],
                    [
                        37.17004117630904,
                        246.41286557451468
                    ],
                    [
                        38.833784821387326,
                        249.060470643421
                    ],
                    [
                        40.317664288619426,
                        248.4480848909712
                    ],
                    [
                        43.0605930007755,
                        249.41032906978398
                    ],
                    [
                        43.60018553431473,
                        250.69995378116596
                    ],
                    [
                        45.6686235795471,
                        253.38608647888077
                    ],
                    [
                        46.792774691086606,
                        251.61753464667612
                    ],
                    [
                        49.76053362555058,
                        251.8577908110779
                    ],
                    [
                        50.47999033693577,
                        253.03685412306936
                    ],
                    [
                        50.68233753701293,
                        255.15323861573285
                    ],
                    [
                        49.10852598085762,
                        256.3087700450503
                    ],
                    [
                        48.18672206939527,
                        255.50214092574572
                    ],
                    [
                        45.2189631349313,
                        256.83183435575734
                    ],
                    [
                        43.78004971216069,
                        258.00828567001014
                    ],
                    [
                        44.02736295669956,
                        259.88063136278845
                    ],
                    [
                        43.01562695631401,
                        260.3375975372823
                    ],
                    [
                        40.40759637754263,
                        260.0982457545604
                    ],
                    [
                        40.295181266388454,
                        261.2947531669961
                    ],
                    [
                        40.07035104408055,
                        261.3599991470084
                    ],
                    [
                        39.55324153277252,
                        261.6862010760327
                    ],
                    [
                        36.72038073169347,
                        261.79492469904255
                    ],
                    [
                        35.05663708661473,
                        261.40349543069544
                    ],
                    [
                        33.10061415253631,
                        262.49063347171574
                    ],
                    [
                        30.80734588499604,
                        261.3165020342865
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Rajkot",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhavnagar",
            "dt_code": "481",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            63.27282998625424,
                            259.2058942490245
                        ],
                        [
                            62.59833931933076,
                            258.530956389162
                        ],
                        [
                            62.328543052561145,
                            256.5267282715989
                        ],
                        [
                            63.542626253023855,
                            255.54574990222073
                        ],
                        [
                            64.4644301644862,
                            257.1586879055543
                        ],
                        [
                            63.27282998625424,
                            259.2058942490245
                        ]
                    ]
                ],
                [
                    [
                        [
                            53.290368115784304,
                            271.86223394699664
                        ],
                        [
                            52.480979315475906,
                            270.02126616882464
                        ],
                        [
                            51.06454891493627,
                            268.8726351282146
                        ],
                        [
                            51.15448100385947,
                            267.2462455835281
                        ],
                        [
                            52.525945359937396,
                            266.6387680532398
                        ],
                        [
                            52.368564204321956,
                            264.85880861040016
                        ],
                        [
                            50.36757522578205,
                            264.2072606570708
                        ],
                        [
                            51.3568282039364,
                            262.99054357083503
                        ],
                        [
                            52.00883584862936,
                            260.9467430852385
                        ],
                        [
                            52.413530248783445,
                            258.6398309327759
                        ],
                        [
                            53.38030020470751,
                            258.05184617579437
                        ],
                        [
                            54.14472296055419,
                            255.82918761626541
                        ],
                        [
                            56.0332968279406,
                            255.0878133931747
                        ],
                        [
                            56.865168650479745,
                            253.93165272622548
                        ],
                        [
                            58.93360669571234,
                            254.38982552424983
                        ],
                        [
                            60.52990127409794,
                            256.0907907085739
                        ],
                        [
                            61.6315693634067,
                            256.0907907085739
                        ],
                        [
                            61.0470107854062,
                            257.7251219206184
                        ],
                        [
                            61.58660331894521,
                            258.79224650272477
                        ],
                        [
                            60.88962962979099,
                            259.96767961913724
                        ],
                        [
                            62.57585629709979,
                            261.94712908067964
                        ],
                        [
                            61.496671230022,
                            264.61992909992597
                        ],
                        [
                            59.63058038486656,
                            267.05100230715357
                        ],
                        [
                            59.69802945155902,
                            268.67752839364096
                        ],
                        [
                            55.42625522770936,
                            270.5412153488389
                        ],
                        [
                            53.290368115784304,
                            271.86223394699664
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Bhavnagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Betul",
            "dt_code": "447",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        150.21467695271303,
                        249.628962450727
                    ],
                    [
                        149.85494859702067,
                        250.91845983306354
                    ],
                    [
                        150.3270920638672,
                        254.5643428764664
                    ],
                    [
                        153.81196050963945,
                        257.00616214502014
                    ],
                    [
                        154.32907002094748,
                        258.00828567001014
                    ],
                    [
                        153.114986820485,
                        258.9664231734171
                    ],
                    [
                        153.00257170933105,
                        260.46814244401054
                    ],
                    [
                        152.035801753407,
                        261.20775562458863
                    ],
                    [
                        151.29386201979105,
                        262.5123709624061
                    ],
                    [
                        149.54018628578933,
                        263.09920525066366
                    ],
                    [
                        148.8432125966351,
                        263.92486590912625
                    ],
                    [
                        146.6848424624793,
                        265.16280127333005
                    ],
                    [
                        144.54895535055425,
                        265.74895974848255
                    ],
                    [
                        143.334872150092,
                        265.3582039711842
                    ],
                    [
                        139.80503765985804,
                        265.5535901255982
                    ],
                    [
                        138.72585259278026,
                        265.90090247803136
                    ],
                    [
                        138.23122610370297,
                        263.07747332665
                    ],
                    [
                        140.0073848599352,
                        263.4903446825006
                    ],
                    [
                        139.53524139308888,
                        260.685700655545
                    ],
                    [
                        138.59095445939533,
                        259.6412314632642
                    ],
                    [
                        135.66816156939308,
                        259.83710598439757
                    ],
                    [
                        134.63394254677678,
                        260.9249906914684
                    ],
                    [
                        132.45308939039046,
                        260.5551682229161
                    ],
                    [
                        133.75710467977592,
                        259.6194665849923
                    ],
                    [
                        133.98193490208382,
                        258.09540584249646
                    ],
                    [
                        134.79132370239222,
                        257.48549410388233
                    ],
                    [
                        135.6231955249316,
                        256.5703173851811
                    ],
                    [
                        138.9731658373189,
                        255.52394551983252
                    ],
                    [
                        138.2986751703952,
                        254.67340931699925
                    ],
                    [
                        138.50102237047258,
                        253.4079116870576
                    ],
                    [
                        137.28693917000987,
                        252.4692348870663
                    ],
                    [
                        138.20874308147222,
                        252.55657033370727
                    ],
                    [
                        139.6026904597809,
                        250.4158637089795
                    ],
                    [
                        141.60367943832125,
                        250.8966101966913
                    ],
                    [
                        144.12177792816942,
                        250.15359437738522
                    ],
                    [
                        146.72980850694103,
                        250.76550785766307
                    ],
                    [
                        149.00059375225078,
                        249.6726865316786
                    ],
                    [
                        150.21467695271303,
                        249.628962450727
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Betul",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Junagadh",
            "dt_code": "479",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        40.295181266388454,
                        261.2947531669961
                    ],
                    [
                        41.32940028900498,
                        262.36020419253236
                    ],
                    [
                        40.85725682215843,
                        264.598211552343
                    ],
                    [
                        42.20623815600561,
                        264.51133931372397
                    ],
                    [
                        42.49851744500597,
                        266.89913505995895
                    ],
                    [
                        40.45256242200412,
                        268.1137948788485
                    ],
                    [
                        41.19450215562006,
                        269.1761020395825
                    ],
                    [
                        39.598207577234234,
                        269.71790909732806
                    ],
                    [
                        39.62069059946498,
                        268.04873991508055
                    ],
                    [
                        37.349905354155226,
                        267.3763986401044
                    ],
                    [
                        35.84354286469261,
                        269.5445445168843
                    ],
                    [
                        34.51704455307595,
                        270.97441816689735
                    ],
                    [
                        32.15632721884322,
                        271.9271829054315
                    ],
                    [
                        29.975474062456897,
                        269.9995991119276
                    ],
                    [
                        28.356696461839874,
                        268.2222157752747
                    ],
                    [
                        27.817103928301094,
                        265.53188136947523
                    ],
                    [
                        28.581526684147775,
                        264.31586478988004
                    ],
                    [
                        28.491594595224797,
                        262.66452761932806
                    ],
                    [
                        30.402651484841726,
                        261.0555019408399
                    ],
                    [
                        30.80734588499604,
                        261.3165020342865
                    ],
                    [
                        33.10061415253631,
                        262.49063347171574
                    ],
                    [
                        35.05663708661473,
                        261.40349543069544
                    ],
                    [
                        36.72038073169347,
                        261.79492469904255
                    ],
                    [
                        39.55324153277252,
                        261.6862010760327
                    ],
                    [
                        40.07035104408055,
                        261.3599991470084
                    ],
                    [
                        40.295181266388454,
                        261.2947531669961
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Junagadh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Deogarh",
            "dt_code": "373",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        251.2983449023377,
                        262.42541976116024
                    ],
                    [
                        252.15269974710782,
                        263.57725550436294
                    ],
                    [
                        251.43324303572263,
                        264.51133931372397
                    ],
                    [
                        249.54466916833644,
                        264.75023008527967
                    ],
                    [
                        249.05004267925915,
                        265.31478257953853
                    ],
                    [
                        249.34232196825928,
                        267.11608522539916
                    ],
                    [
                        247.65609530095026,
                        267.7017493603277
                    ],
                    [
                        248.28561992341224,
                        268.72088685758655
                    ],
                    [
                        247.3638160119499,
                        269.2194512324606
                    ],
                    [
                        245.40779307787125,
                        269.1327520412059
                    ],
                    [
                        245.6551063224099,
                        267.44147243140935
                    ],
                    [
                        244.91316658879396,
                        266.79065236270134
                    ],
                    [
                        242.79976249909987,
                        267.8752511128732
                    ],
                    [
                        242.5074832100995,
                        265.92260776679524
                    ],
                    [
                        240.39407912040565,
                        265.8357853886843
                    ],
                    [
                        240.48401120932863,
                        264.2941443734342
                    ],
                    [
                        242.35010205448407,
                        262.86014275602156
                    ],
                    [
                        241.85547556540655,
                        261.03375058476445
                    ],
                    [
                        243.74404943279296,
                        260.79447196891806
                    ],
                    [
                        244.19370987740876,
                        259.79357977235304
                    ],
                    [
                        244.6208872997936,
                        260.59867986509107
                    ],
                    [
                        246.08228374479495,
                        261.25125481047445
                    ],
                    [
                        249.54466916833644,
                        261.1860057205928
                    ],
                    [
                        249.8819145017983,
                        262.49063347171574
                    ],
                    [
                        251.2983449023377,
                        262.42541976116024
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Deogarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jamnagar",
            "dt_code": "477",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        29.70567779568728,
                        260.14176613380334
                    ],
                    [
                        28.51407761745554,
                        259.0535064904369
                    ],
                    [
                        25.951013083145654,
                        260.0982457545604
                    ],
                    [
                        25.906047038683937,
                        258.8357919253105
                    ],
                    [
                        27.29999441699283,
                        258.530956389162
                    ],
                    [
                        27.389926505916037,
                        257.1586879055543
                    ],
                    [
                        26.558054683376895,
                        254.60797008986054
                    ],
                    [
                        25.861080994222448,
                        253.97529227378982
                    ],
                    [
                        24.691963838221454,
                        251.11509687703096
                    ],
                    [
                        25.00672614945256,
                        249.36659979741415
                    ],
                    [
                        26.62550375006913,
                        248.64494171589382
                    ],
                    [
                        28.26676437291667,
                        247.37599997277755
                    ],
                    [
                        30.47010055153396,
                        247.22280230965094
                    ],
                    [
                        31.90901397430457,
                        245.49311503921888
                    ],
                    [
                        31.84156490761211,
                        244.5948899833091
                    ],
                    [
                        32.920749974690125,
                        243.82781977773777
                    ],
                    [
                        34.4945615308452,
                        241.6127558397618
                    ],
                    [
                        35.23650126446114,
                        242.57801210056658
                    ],
                    [
                        35.93347495361559,
                        241.15191362081123
                    ],
                    [
                        37.1475581540783,
                        241.415264053069
                    ],
                    [
                        38.114328110002134,
                        242.2709317337413
                    ],
                    [
                        38.114328110002134,
                        244.77018242841444
                    ],
                    [
                        37.17004117630904,
                        246.41286557451468
                    ],
                    [
                        36.72038073169347,
                        247.15714289738588
                    ],
                    [
                        37.25997326523225,
                        248.2074582187015
                    ],
                    [
                        35.483814508999785,
                        248.53557898379137
                    ],
                    [
                        35.59622962015396,
                        251.0932491771332
                    ],
                    [
                        36.27072028707744,
                        252.11985884355863
                    ],
                    [
                        37.1475581540783,
                        252.2072079951239
                    ],
                    [
                        37.192524198539786,
                        254.43345613736705
                    ],
                    [
                        36.697897709462495,
                        255.26227641390966
                    ],
                    [
                        33.48282553045965,
                        255.19685437083308
                    ],
                    [
                        32.53853859676656,
                        255.98179311891238
                    ],
                    [
                        31.819081885381365,
                        257.48549410388233
                    ],
                    [
                        29.076153173225066,
                        259.2712001546953
                    ],
                    [
                        29.70567779568728,
                        260.14176613380334
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Jamnagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Devbhumi Dwarka",
            "dt_code": "728",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        25.906047038683937,
                        258.8357919253105
                    ],
                    [
                        23.567812726682178,
                        258.57450683470944
                    ],
                    [
                        23.72519388229739,
                        257.18047645797947
                    ],
                    [
                        22.151382326142425,
                        256.94079080375786
                    ],
                    [
                        20.51012170329477,
                        258.3349690166874
                    ],
                    [
                        19.745698947447863,
                        257.7686878836714
                    ],
                    [
                        19.09369130275502,
                        258.55273171663043
                    ],
                    [
                        17.115185346445855,
                        256.67928647868337
                    ],
                    [
                        13.427969700596464,
                        252.29455371884785
                    ],
                    [
                        13.00079227821152,
                        251.0714012621392
                    ],
                    [
                        14.012528278597074,
                        249.01673444160116
                    ],
                    [
                        16.328279568368202,
                        250.48142618933292
                    ],
                    [
                        16.55310979067599,
                        252.62206967192594
                    ],
                    [
                        18.936310147139693,
                        251.0495531320318
                    ],
                    [
                        21.13964632575687,
                        250.65624998653277
                    ],
                    [
                        21.881586059372808,
                        250.02244805911346
                    ],
                    [
                        23.05070321537403,
                        250.10987980142187
                    ],
                    [
                        25.00672614945256,
                        249.36659979741415
                    ],
                    [
                        24.691963838221454,
                        251.11509687703096
                    ],
                    [
                        25.861080994222448,
                        253.97529227378982
                    ],
                    [
                        26.558054683376895,
                        254.60797008986054
                    ],
                    [
                        27.389926505916037,
                        257.1586879055543
                    ],
                    [
                        27.29999441699283,
                        258.530956389162
                    ],
                    [
                        25.906047038683937,
                        258.8357919253105
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Devbhumi Dwarka",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Aurangabad",
            "dt_code": "515",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        101.17920546736354,
                        283.26601184520433
                    ],
                    [
                        102.0560433343644,
                        282.23153951844193
                    ],
                    [
                        103.98958324621208,
                        282.01596891551696
                    ],
                    [
                        103.69730395721194,
                        281.0456635494898
                    ],
                    [
                        104.4842097352896,
                        280.031817139319
                    ],
                    [
                        105.47346271344418,
                        279.75131621316
                    ],
                    [
                        105.92312315805975,
                        278.477861986839
                    ],
                    [
                        107.85666306990765,
                        278.45627219227123
                    ],
                    [
                        107.9690781810616,
                        279.3412944803316
                    ],
                    [
                        109.9026180929095,
                        278.8016855788134
                    ],
                    [
                        110.01503320406346,
                        278.00284155736426
                    ],
                    [
                        113.23010538306607,
                        277.89486922507797
                    ],
                    [
                        114.2643244056826,
                        278.8016855788134
                    ],
                    [
                        115.83813596183768,
                        278.4994515869444
                    ],
                    [
                        116.93980405114644,
                        277.3549344390378
                    ],
                    [
                        117.36698147353127,
                        278.8016855788134
                    ],
                    [
                        116.49014360653041,
                        279.1686328238742
                    ],
                    [
                        115.09619622822174,
                        278.71533689608424
                    ],
                    [
                        114.46667160575953,
                        280.3338585239357
                    ],
                    [
                        113.38748653868174,
                        280.42014910909415
                    ],
                    [
                        111.61132778244951,
                        284.6015544086105
                    ],
                    [
                        110.8469050266026,
                        287.39951929267255
                    ],
                    [
                        111.61132778244951,
                        287.39951929267255
                    ],
                    [
                        111.76870893806495,
                        288.9477926103778
                    ],
                    [
                        111.31904849344937,
                        291.09656703196987
                    ],
                    [
                        111.4314636046031,
                        292.68546668476483
                    ],
                    [
                        110.9368371155258,
                        293.7584764790638
                    ],
                    [
                        111.76870893806495,
                        294.2304554916628
                    ],
                    [
                        111.04925222667976,
                        297.10333037095444
                    ],
                    [
                        109.13819533706283,
                        296.46043295051305
                    ],
                    [
                        107.51941773644603,
                        296.9104782590516
                    ],
                    [
                        106.97982520290702,
                        295.2384778134933
                    ],
                    [
                        105.90064013582901,
                        294.95970347295986
                    ],
                    [
                        104.39427764636639,
                        293.9301154676676
                    ],
                    [
                        100.43726573374761,
                        292.87864235534573
                    ],
                    [
                        99.92015622243957,
                        293.0288797975515
                    ],
                    [
                        97.98661631059167,
                        290.9462132975514
                    ],
                    [
                        96.14300848766698,
                        290.92473345138
                    ],
                    [
                        97.289642621437,
                        288.3458017068942
                    ],
                    [
                        98.25641257736106,
                        286.2376753148614
                    ],
                    [
                        97.94165026613018,
                        285.3982235860842
                    ],
                    [
                        99.47049577782377,
                        283.58919414551303
                    ],
                    [
                        100.61712991159402,
                        284.6876933295635
                    ],
                    [
                        101.17920546736354,
                        283.26601184520433
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Aurangabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Vijayapura",
            "dt_code": "557",
            "st_nm": "Karnataka",
            "st_code": "29",
            "alpha2code": "KA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        111.02676920444901,
                        329.3243802917495
                    ],
                    [
                        110.57710875983321,
                        328.1173885046705
                    ],
                    [
                        111.45394662683384,
                        326.67679208825695
                    ],
                    [
                        114.10694325006693,
                        328.2868219818396
                    ],
                    [
                        115.2985434282989,
                        327.84203738422815
                    ],
                    [
                        115.83813596183768,
                        329.0491468244974
                    ],
                    [
                        117.95154005153177,
                        328.8374105520343
                    ],
                    [
                        118.71596280737867,
                        330.25574144177176
                    ],
                    [
                        120.4021894746877,
                        331.6945179648401
                    ],
                    [
                        122.26828031984337,
                        331.4618239300008
                    ],
                    [
                        122.17834823092016,
                        332.24444673844187
                    ],
                    [
                        123.32498236469019,
                        334.0626907453732
                    ],
                    [
                        122.35821240876612,
                        336.1966070926037
                    ],
                    [
                        122.29076334207389,
                        337.56909018876064
                    ],
                    [
                        123.63974467592129,
                        338.4555822166601
                    ],
                    [
                        122.80787285338215,
                        339.51058029782024
                    ],
                    [
                        123.23505027576698,
                        342.65223833885756
                    ],
                    [
                        121.72868778630436,
                        342.8208238582962
                    ],
                    [
                        121.25654431945782,
                        345.01157009345764
                    ],
                    [
                        121.72868778630436,
                        346.37997484253697
                    ],
                    [
                        120.35722343022621,
                        346.82194220991585
                    ],
                    [
                        117.83912494037781,
                        346.1694914659786
                    ],
                    [
                        117.23208334014635,
                        344.7588734540297
                    ],
                    [
                        116.19786431753028,
                        344.80099103753236
                    ],
                    [
                        111.45394662683384,
                        342.567941999239
                    ],
                    [
                        109.47544067052468,
                        342.39934215564455
                    ],
                    [
                        109.40799160383222,
                        340.2699445860815
                    ],
                    [
                        107.78921400321519,
                        337.6535295839633
                    ],
                    [
                        107.94659515883086,
                        336.13324608585606
                    ],
                    [
                        107.06975729183023,
                        336.02764133563284
                    ],
                    [
                        107.15968938075343,
                        334.696691708251
                    ],
                    [
                        108.98081418144716,
                        334.1895020868333
                    ],
                    [
                        109.92510111514025,
                        334.4853735317836
                    ],
                    [
                        111.92609009368039,
                        334.33744160267224
                    ],
                    [
                        112.03850520483456,
                        332.24444673844187
                    ],
                    [
                        111.34153151567989,
                        331.35604764336426
                    ],
                    [
                        111.76870893806495,
                        329.8112663085058
                    ],
                    [
                        111.02676920444901,
                        329.3243802917495
                    ]
                ]
            ]
        },
        "id": "TT-Karnataka-Vijayapura",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bijapur",
            "dt_code": "417",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        179.71240211950771,
                        307.45319497145044
                    ],
                    [
                        179.68991909727674,
                        306.748833118846
                    ],
                    [
                        180.7915871865855,
                        305.4676893045989
                    ],
                    [
                        179.64495305281525,
                        303.9935951878714
                    ],
                    [
                        180.4993078975849,
                        302.0054461237708
                    ],
                    [
                        180.54427394204663,
                        300.8503336990302
                    ],
                    [
                        181.44359483127823,
                        300.18697895462606
                    ],
                    [
                        182.74761012066392,
                        297.85317161362866
                    ],
                    [
                        184.14155749897282,
                        296.9104782590516
                    ],
                    [
                        184.65866701028108,
                        298.2815530809514
                    ],
                    [
                        186.70462203328293,
                        298.6242063924207
                    ],
                    [
                        188.18850150051458,
                        297.4247180004906
                    ],
                    [
                        189.64989794551593,
                        298.26013571915047
                    ],
                    [
                        189.69486398997765,
                        299.4164159752135
                    ],
                    [
                        192.25792852428754,
                        299.11668989820856
                    ],
                    [
                        192.48275874659544,
                        300.27258275496393
                    ],
                    [
                        193.44952870251927,
                        300.25118207286505
                    ],
                    [
                        193.83174008044261,
                        303.6089113081773
                    ],
                    [
                        193.2696645246731,
                        307.0049865997505
                    ],
                    [
                        192.7075889689031,
                        307.9013268196294
                    ],
                    [
                        193.04483430236496,
                        310.22612048051855
                    ],
                    [
                        190.8639811459784,
                        310.3327133473006
                    ],
                    [
                        190.68411696813223,
                        311.3984065286839
                    ],
                    [
                        189.11030541197692,
                        312.6979743644775
                    ],
                    [
                        190.95391323490162,
                        313.31557986852647
                    ],
                    [
                        191.47102274620988,
                        315.63565371375273
                    ],
                    [
                        190.05459234567002,
                        316.0398657125112
                    ],
                    [
                        188.39084870059196,
                        316.44401725113886
                    ],
                    [
                        188.18850150051458,
                        315.42288623743144
                    ],
                    [
                        186.77207109997494,
                        315.97604678746836
                    ],
                    [
                        186.6596559888212,
                        313.8265939175697
                    ],
                    [
                        186.030131366359,
                        311.8032579175971
                    ],
                    [
                        185.01839536597345,
                        310.5032530175455
                    ],
                    [
                        182.3429157205096,
                        308.8400222210896
                    ],
                    [
                        181.12883252004713,
                        309.13862791742974
                    ],
                    [
                        179.71240211950771,
                        307.45319497145044
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Bijapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Raigad",
            "dt_code": "520",
            "st_nm": "Maharashtra",
            "st_code": "27",
            "alpha2code": "MH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        80.47234199280751,
                        301.0856764485497
                    ],
                    [
                        80.5622740817305,
                        302.62557063104896
                    ],
                    [
                        79.50557203688368,
                        303.6516567798775
                    ],
                    [
                        79.05591159226788,
                        305.0618626336847
                    ],
                    [
                        78.15659070303627,
                        306.2791533009168
                    ],
                    [
                        77.95424350295912,
                        307.43185345199026
                    ],
                    [
                        78.44886999203663,
                        308.47738412106406
                    ],
                    [
                        77.48210003611257,
                        309.9702800971946
                    ],
                    [
                        78.74114928103677,
                        310.8656134106931
                    ],
                    [
                        78.60625114765185,
                        312.399768888976
                    ],
                    [
                        78.92101345888295,
                        313.91175345445816
                    ],
                    [
                        80.62972314842295,
                        314.84833013741405
                    ],
                    [
                        80.69717221511542,
                        315.4016085665323
                    ],
                    [
                        82.33843283796296,
                        315.93349999984366
                    ],
                    [
                        81.4615949709621,
                        316.93317257769513
                    ],
                    [
                        82.40588190465519,
                        318.14504703718785
                    ],
                    [
                        81.14683265973122,
                        320.5247055081348
                    ],
                    [
                        80.18006270380738,
                        320.90695778675
                    ],
                    [
                        78.22403976972873,
                        319.760041115494
                    ],
                    [
                        76.9649905248043,
                        317.50728578211266
                    ],
                    [
                        75.2787638574955,
                        317.6986298702913
                    ],
                    [
                        74.42440901272562,
                        318.9101633601154
                    ],
                    [
                        73.39018999010932,
                        318.888913027936
                    ],
                    [
                        72.33348794526205,
                        316.6779721268407
                    ],
                    [
                        72.28852190080056,
                        314.84833013741405
                    ],
                    [
                        73.16535976780119,
                        314.5077945673256
                    ],
                    [
                        71.47913310049216,
                        313.1239244569379
                    ],
                    [
                        71.1868538114918,
                        311.4410253651083
                    ],
                    [
                        71.77141238949253,
                        310.22612048051855
                    ],
                    [
                        70.62477825572228,
                        307.77329694908633
                    ],
                    [
                        70.80464243356846,
                        306.21509951831786
                    ],
                    [
                        70.33249896672191,
                        305.2113850766203
                    ],
                    [
                        71.43416705603067,
                        303.9081148117712
                    ],
                    [
                        71.1868538114918,
                        303.395173372154
                    ],
                    [
                        72.0861747007234,
                        303.2882977899585
                    ],
                    [
                        73.39018999010932,
                        302.81799278820716
                    ],
                    [
                        73.43515603457081,
                        301.57768714915767
                    ],
                    [
                        75.323729901957,
                        301.40656366384263
                    ],
                    [
                        76.42539799126553,
                        302.1551449949085
                    ],
                    [
                        77.93176048072837,
                        301.06428254369683
                    ],
                    [
                        80.47234199280751,
                        301.0856764485497
                    ]
                ]
            ]
        },
        "id": "TT-Maharashtra-Raigad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Raigarh",
            "dt_code": "403",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        223.7116766251604,
                        265.8574912889921
                    ],
                    [
                        220.63150257954248,
                        264.92395326994944
                    ],
                    [
                        218.7878947566178,
                        263.96831351258334
                    ],
                    [
                        218.63051360100235,
                        263.1643997871097
                    ],
                    [
                        219.5523175124647,
                        261.5774722777289
                    ],
                    [
                        219.0127249789257,
                        260.3811133381504
                    ],
                    [
                        223.64422755846795,
                        260.5116557492667
                    ],
                    [
                        222.38517831354397,
                        258.3785232325867
                    ],
                    [
                        222.52007644692867,
                        257.1586879055543
                    ],
                    [
                        221.10364604638903,
                        256.39595586792933
                    ],
                    [
                        219.8221137792341,
                        256.54852293384806
                    ],
                    [
                        219.97949493484953,
                        254.8042820383002
                    ],
                    [
                        218.83286080107928,
                        254.06256881444784
                    ],
                    [
                        220.20432515715743,
                        253.32060957427115
                    ],
                    [
                        220.20432515715743,
                        252.2072079951239
                    ],
                    [
                        220.94626489077336,
                        251.0714012621392
                    ],
                    [
                        221.44089137985088,
                        249.30100426561302
                    ],
                    [
                        221.03619697969657,
                        248.53557898379137
                    ],
                    [
                        221.3959253353894,
                        245.33978568588805
                    ],
                    [
                        222.0928990245436,
                        243.69629489991922
                    ],
                    [
                        222.96973689154447,
                        243.47706910259916
                    ],
                    [
                        224.92575982562312,
                        244.3976691426967
                    ],
                    [
                        225.780114670393,
                        245.82164174150097
                    ],
                    [
                        225.6452165370083,
                        247.20091605700287
                    ],
                    [
                        228.23076409354894,
                        248.7105567512076
                    ],
                    [
                        229.30994916062673,
                        249.78199295101012
                    ],
                    [
                        230.61396445001242,
                        250.00058958377485
                    ],
                    [
                        231.5582513837055,
                        251.33356204939224
                    ],
                    [
                        228.99518684939585,
                        252.7748939746333
                    ],
                    [
                        227.66868853777942,
                        255.28408333778236
                    ],
                    [
                        227.69117156001016,
                        256.54852293384806
                    ],
                    [
                        228.45559431585684,
                        258.7704734776293
                    ],
                    [
                        226.5445374262399,
                        260.79447196891806
                    ],
                    [
                        226.92674880416303,
                        261.64271017786166
                    ],
                    [
                        225.39790329246944,
                        262.0123564274094
                    ],
                    [
                        224.72341262554596,
                        263.9031417994257
                    ],
                    [
                        225.62273351477756,
                        265.423334526894
                    ],
                    [
                        225.53280142585436,
                        266.4000725832204
                    ],
                    [
                        223.8016087140836,
                        265.8574912889921
                    ],
                    [
                        223.7566426696219,
                        265.8574912889921
                    ],
                    [
                        223.7116766251604,
                        265.8574912889921
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Raigarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bilaspur",
            "dt_code": "406",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        211.79567484284257,
                        252.6002367731303
                    ],
                    [
                        211.2560823093038,
                        253.82255013097296
                    ],
                    [
                        209.97455004214862,
                        254.3243780102851
                    ],
                    [
                        209.54737261976356,
                        257.4637084960267
                    ],
                    [
                        210.69400675353404,
                        259.79357977235304
                    ],
                    [
                        211.6158106649964,
                        260.05472454245665
                    ],
                    [
                        210.82890488691896,
                        260.5551682229161
                    ],
                    [
                        208.0185271080702,
                        259.79357977235304
                    ],
                    [
                        205.61284372937575,
                        257.8558172900766
                    ],
                    [
                        204.89338701799056,
                        256.0907907085739
                    ],
                    [
                        201.61086577229548,
                        252.16353384788846
                    ],
                    [
                        201.58838275006474,
                        251.399097409558
                    ],
                    [
                        203.54440568414338,
                        251.1587916316035
                    ],
                    [
                        204.71352284014415,
                        250.28473292612796
                    ],
                    [
                        201.34106950552587,
                        246.60990473769982
                    ],
                    [
                        201.0712732387567,
                        245.3616905377184
                    ],
                    [
                        201.1162392832182,
                        245.25216408295194
                    ],
                    [
                        202.03804319468054,
                        243.82781977773777
                    ],
                    [
                        201.76824692791092,
                        242.09543771126374
                    ],
                    [
                        203.02729617283535,
                        241.8541102221863
                    ],
                    [
                        204.3313114622208,
                        240.36166969947817
                    ],
                    [
                        204.21889635106686,
                        238.82425466291818
                    ],
                    [
                        206.87189297429995,
                        238.42874169653413
                    ],
                    [
                        208.24335733037788,
                        240.976328843182
                    ],
                    [
                        207.02927412991562,
                        242.42447735159266
                    ],
                    [
                        207.6138327079159,
                        245.33978568588805
                    ],
                    [
                        208.58060266383995,
                        245.6245316414043
                    ],
                    [
                        207.36651946337724,
                        247.35411524636334
                    ],
                    [
                        207.09672319660785,
                        248.77616983411832
                    ],
                    [
                        207.7487308413008,
                        249.49778501793776
                    ],
                    [
                        208.06349315253192,
                        251.0495531320318
                    ],
                    [
                        210.2218632866875,
                        250.80920949855505
                    ],
                    [
                        211.79567484284257,
                        252.6002367731303
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Bilaspur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Karaikal",
            "dt_code": "637",
            "st_nm": "Puducherry",
            "st_code": "34",
            "alpha2code": "PY",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        171.596031094193,
                        426.04535237589914
                    ],
                    [
                        171.9557594498856,
                        425.0365320698666
                    ],
                    [
                        173.46212193934844,
                        425.20125313055644
                    ],
                    [
                        173.4396389171177,
                        427.5684433389141
                    ],
                    [
                        172.9899784725019,
                        426.78638046610513
                    ],
                    [
                        171.596031094193,
                        426.04535237589914
                    ]
                ]
            ]
        },
        "id": "TT-Puducherry-Karaikal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mahe",
            "dt_code": "636",
            "st_nm": "Puducherry",
            "st_code": "34",
            "alpha2code": "PY",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        110.30731249306382,
                        414.15104374638247
                    ],
                    [
                        110.0375162262942,
                        414.19232952059235
                    ],
                    [
                        110.10496529298666,
                        413.90332046441563
                    ],
                    [
                        110.30731249306382,
                        414.15104374638247
                    ]
                ]
            ]
        },
        "id": "TT-Puducherry-Mahe",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Yanam",
            "dt_code": "634",
            "st_nm": "Puducherry",
            "st_code": "34",
            "alpha2code": "PY",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        207.7936968857623,
                        338.0545833901276
                    ],
                    [
                        209.03026310845576,
                        338.28674730029394
                    ],
                    [
                        208.98529706399427,
                        338.7088163504323
                    ],
                    [
                        208.58060266383995,
                        338.37116597514057
                    ],
                    [
                        207.99604408583946,
                        338.3078521971803
                    ],
                    [
                        207.7936968857623,
                        338.0545833901276
                    ]
                ]
            ]
        },
        "id": "TT-Puducherry-Yanam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sambalpur",
            "dt_code": "372",
            "st_nm": "Odisha",
            "st_code": "21",
            "alpha2code": "OR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        238.5729543197117,
                        273.0310410903229
                    ],
                    [
                        237.96591271948046,
                        273.0310410903229
                    ],
                    [
                        236.57196534117134,
                        271.2776125245467
                    ],
                    [
                        234.72835751824664,
                        270.9960762064032
                    ],
                    [
                        234.23373102916935,
                        269.7395787653518
                    ],
                    [
                        232.90723271755292,
                        269.046049627439
                    ],
                    [
                        233.15454596209156,
                        268.04873991508055
                    ],
                    [
                        232.5924704063218,
                        267.00761268017953
                    ],
                    [
                        232.79481760639896,
                        264.9022385880217
                    ],
                    [
                        231.51328533924402,
                        262.9253440907642
                    ],
                    [
                        233.98641778463093,
                        262.6427915730003
                    ],
                    [
                        234.18876498470786,
                        259.48887293462894
                    ],
                    [
                        235.7850595630939,
                        259.6412314632642
                    ],
                    [
                        237.96591271948046,
                        259.07527679712877
                    ],
                    [
                        238.7078524530964,
                        259.6194665849923
                    ],
                    [
                        239.96690169802037,
                        256.70107966486756
                    ],
                    [
                        239.7420714757127,
                        255.4149204320779
                    ],
                    [
                        238.43805618632678,
                        255.76378208633906
                    ],
                    [
                        238.3930901418653,
                        254.3461940608674
                    ],
                    [
                        241.4732641874832,
                        252.99319623514305
                    ],
                    [
                        241.96789067656073,
                        255.0878133931747
                    ],
                    [
                        242.19272089886863,
                        258.94465182151635
                    ],
                    [
                        243.11452481033098,
                        259.83710598439757
                    ],
                    [
                        244.19370987740876,
                        259.79357977235304
                    ],
                    [
                        243.74404943279296,
                        260.79447196891806
                    ],
                    [
                        241.85547556540655,
                        261.03375058476445
                    ],
                    [
                        242.35010205448407,
                        262.86014275602156
                    ],
                    [
                        240.48401120932863,
                        264.2941443734342
                    ],
                    [
                        240.39407912040565,
                        265.8357853886843
                    ],
                    [
                        242.5074832100995,
                        265.92260776679524
                    ],
                    [
                        242.79976249909987,
                        267.8752511128732
                    ],
                    [
                        244.91316658879396,
                        266.79065236270134
                    ],
                    [
                        245.6551063224099,
                        267.44147243140935
                    ],
                    [
                        245.40779307787125,
                        269.1327520412059
                    ],
                    [
                        244.28364196633174,
                        270.34624794224726
                    ],
                    [
                        243.06955876586926,
                        270.8444657286518
                    ],
                    [
                        241.54071325417567,
                        270.5412153488389
                    ],
                    [
                        239.96690169802037,
                        272.51164285896385
                    ],
                    [
                        238.5729543197117,
                        273.0310410903229
                    ]
                ]
            ]
        },
        "id": "TT-Odisha-Sambalpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Navsari",
            "dt_code": "490",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        78.7186662588058,
                        277.72210335817573
                    ],
                    [
                        76.53781310241948,
                        278.4130920196855
                    ],
                    [
                        75.95325452441898,
                        278.06762261885416
                    ],
                    [
                        73.81736741249415,
                        278.0892159164661
                    ],
                    [
                        73.43515603457081,
                        276.53399971737156
                    ],
                    [
                        72.33348794526205,
                        275.8208532579784
                    ],
                    [
                        70.6022952334913,
                        275.7343967387643
                    ],
                    [
                        68.64627229941266,
                        271.0393916853882
                    ],
                    [
                        69.07344972179794,
                        270.82280628859183
                    ],
                    [
                        71.38920101156896,
                        271.1476768834453
                    ],
                    [
                        72.04120865626169,
                        270.71450608581114
                    ],
                    [
                        73.09791070110896,
                        271.4941559437516
                    ],
                    [
                        74.58179016834083,
                        273.4205147193642
                    ],
                    [
                        77.14485470265072,
                        274.69667299423213
                    ],
                    [
                        77.39216794718959,
                        273.70176128481825
                    ],
                    [
                        78.33645488088268,
                        274.5669256063506
                    ],
                    [
                        80.02268154819171,
                        274.6534246541515
                    ],
                    [
                        79.7079192369606,
                        276.209868716718
                    ],
                    [
                        78.7186662588058,
                        277.72210335817573
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Navsari",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lakshadweep",
            "dt_code": "587",
            "st_nm": "Lakshadweep",
            "st_code": "31",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        73.52508812349402,
                        465.65431637956203
                    ],
                    [
                        73.36770696787835,
                        465.79728967017735
                    ],
                    [
                        73.00797861218575,
                        465.6134662268048
                    ],
                    [
                        73.52508812349402,
                        465.65431637956203
                    ]
                ]
            ]
        },
        "id": "TT-Lakshadweep-Lakshadweep",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Daman",
            "dt_code": "495",
            "st_nm": "Dadra and Nagar Haveli and Daman and Diu",
            "st_code": "26",
            "alpha2code": "DN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        71.07443870033785,
                        280.2691385576315
                    ],
                    [
                        70.22008385556796,
                        281.73569844657766
                    ],
                    [
                        70.33249896672191,
                        280.39857675239057
                    ],
                    [
                        71.07443870033785,
                        280.2691385576315
                    ]
                ]
            ]
        },
        "id": "TT-Dadra and Nagar Haveli and Daman and Diu-Daman",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Diu",
            "dt_code": "494",
            "st_nm": "Dadra and Nagar Haveli and Daman and Diu",
            "st_code": "26",
            "alpha2code": "DN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        42.16127211154412,
                        275.8640803389601
                    ],
                    [
                        43.330389267545115,
                        275.8640803389601
                    ],
                    [
                        42.993143934083264,
                        276.4907847984798
                    ],
                    [
                        42.16127211154412,
                        275.8640803389601
                    ]
                ]
            ]
        },
        "id": "TT-Dadra and Nagar Haveli and Daman and Diu-Diu",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jayashankar Bhupalapally",
            "dt_code": "750",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        174.38392585081078,
                        305.80938909539
                    ],
                    [
                        175.93525438473512,
                        306.6634430578111
                    ],
                    [
                        177.39665082973625,
                        308.0720236147391
                    ],
                    [
                        179.71240211950771,
                        307.45319497145044
                    ],
                    [
                        181.12883252004713,
                        309.13862791742974
                    ],
                    [
                        179.06039447481453,
                        309.991601073371
                    ],
                    [
                        178.83556425250686,
                        312.5701760959843
                    ],
                    [
                        174.51882398419525,
                        312.31456120516475
                    ],
                    [
                        173.50708798380992,
                        314.59293250272367
                    ],
                    [
                        173.30474078373277,
                        316.55036288322094
                    ],
                    [
                        171.596031094193,
                        315.5931015610648
                    ],
                    [
                        171.1913366940389,
                        314.31622435089565
                    ],
                    [
                        169.46014398226816,
                        314.84833013741405
                    ],
                    [
                        169.23531375996026,
                        314.5290793039669
                    ],
                    [
                        169.28027980442175,
                        312.03761743257195
                    ],
                    [
                        170.80912531611534,
                        311.1639907174102
                    ],
                    [
                        171.61851411642374,
                        309.7144149827526
                    ],
                    [
                        172.58528407234758,
                        309.7357380208484
                    ],
                    [
                        173.16984265034807,
                        308.45605091366184
                    ],
                    [
                        172.85508033911697,
                        307.7092796745207
                    ],
                    [
                        173.6869521616561,
                        305.6599009432421
                    ],
                    [
                        174.38392585081078,
                        305.80938909539
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Jayashankar Bhupalapally",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Narayanpet",
            "dt_code": "781",
            "st_nm": "Telangana",
            "st_code": "36",
            "alpha2code": "TG",
            "year": "2019"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        138.2986751703952,
                        335.79529735895176
                    ],
                    [
                        138.41109028154938,
                        334.71782267543756
                    ],
                    [
                        140.3221471711663,
                        335.330553543842
                    ],
                    [
                        140.88422272693606,
                        333.8724632684343
                    ],
                    [
                        142.05333988293705,
                        333.4074098421323
                    ],
                    [
                        142.72783054986053,
                        333.9781467508817
                    ],
                    [
                        142.23320406078346,
                        336.32332496029346
                    ],
                    [
                        143.1325249500146,
                        340.5652001057441
                    ],
                    [
                        142.59293241647583,
                        343.17903641933117
                    ],
                    [
                        141.2664341048594,
                        343.8110713544078
                    ],
                    [
                        140.27718112670482,
                        344.1059752606034
                    ],
                    [
                        138.748335615011,
                        343.45293464721277
                    ],
                    [
                        137.64666752570247,
                        343.7268077548465
                    ],
                    [
                        135.78057668054703,
                        343.094754982906
                    ],
                    [
                        135.10608601362333,
                        342.1042694246895
                    ],
                    [
                        137.6691505479332,
                        341.429707334981
                    ],
                    [
                        138.20874308147222,
                        339.9113802884659
                    ],
                    [
                        137.8040486813179,
                        338.3078521971803
                    ],
                    [
                        138.54598841493407,
                        337.27353306835386
                    ],
                    [
                        138.2986751703952,
                        335.79529735895176
                    ]
                ]
            ]
        },
        "id": "TT-Telangana-Narayanpet",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sukma",
            "dt_code": "723",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        203.58937172860487,
                        308.3707163557692
                    ],
                    [
                        203.7467528842203,
                        309.7783835813269
                    ],
                    [
                        201.67831483898772,
                        312.1228339647078
                    ],
                    [
                        201.38603554998758,
                        313.1239244569379
                    ],
                    [
                        200.0370542161404,
                        313.23040138013846
                    ],
                    [
                        198.148480348754,
                        314.89089405277133
                    ],
                    [
                        198.08103128206176,
                        316.2313134453493
                    ],
                    [
                        197.4290236373688,
                        317.80492631959953
                    ],
                    [
                        197.45150665959954,
                        318.95266352806374
                    ],
                    [
                        196.2374234591373,
                        320.2486013062439
                    ],
                    [
                        196.19245741467557,
                        321.41654480746115
                    ],
                    [
                        194.25891750282767,
                        321.52269689282633
                    ],
                    [
                        192.79752105782632,
                        320.80078193469564
                    ],
                    [
                        191.60592087959458,
                        321.7349887910929
                    ],
                    [
                        190.59418487920925,
                        321.77744520815463
                    ],
                    [
                        189.91969421228555,
                        317.8687021941131
                    ],
                    [
                        190.05459234567002,
                        316.0398657125112
                    ],
                    [
                        191.47102274620988,
                        315.63565371375273
                    ],
                    [
                        190.95391323490162,
                        313.31557986852647
                    ],
                    [
                        189.11030541197692,
                        312.6979743644775
                    ],
                    [
                        190.68411696813223,
                        311.3984065286839
                    ],
                    [
                        190.8639811459784,
                        310.3327133473006
                    ],
                    [
                        193.04483430236496,
                        310.22612048051855
                    ],
                    [
                        194.16898541390447,
                        311.18530209991957
                    ],
                    [
                        195.78776301452126,
                        311.9097875254445
                    ],
                    [
                        197.54143874852275,
                        308.946670992684
                    ],
                    [
                        198.35082754883115,
                        309.2026104565127
                    ],
                    [
                        198.7330389267545,
                        307.1330539512041
                    ],
                    [
                        199.58739377152438,
                        307.21842871031697
                    ],
                    [
                        200.2618844384483,
                        308.4987171556683
                    ],
                    [
                        201.63334879452623,
                        309.41587439050096
                    ],
                    [
                        203.58937172860487,
                        308.3707163557692
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Sukma",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Upper Subansiri",
            "dt_code": "249",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        387.38807846529994,
                        152.9508589611914
                    ],
                    [
                        386.73607082060744,
                        155.2917128998102
                    ],
                    [
                        387.25318033191525,
                        157.21716907341352
                    ],
                    [
                        388.6246446879936,
                        158.24781135224168
                    ],
                    [
                        390.2434222886102,
                        160.7646704586636
                    ],
                    [
                        386.17399526483746,
                        162.38732278742117
                    ],
                    [
                        384.33038744191276,
                        161.74757773640283
                    ],
                    [
                        383.67837979722003,
                        160.83326277274404
                    ],
                    [
                        382.5767117079115,
                        161.10760593024867
                    ],
                    [
                        382.4867796189883,
                        159.62108040190952
                    ],
                    [
                        380.93545108506373,
                        158.95746483565352
                    ],
                    [
                        379.36163952890865,
                        157.240078680894
                    ],
                    [
                        379.90123206244766,
                        156.46098735860056
                    ],
                    [
                        379.13680930660075,
                        154.64943214841605
                    ],
                    [
                        376.7760919723678,
                        153.22641358803685
                    ],
                    [
                        375.78683899421344,
                        154.0757713504595
                    ],
                    [
                        373.49357072667294,
                        154.0757713504595
                    ],
                    [
                        371.73989499267145,
                        152.72119741780205
                    ],
                    [
                        370.07615134759294,
                        152.9049290305806
                    ],
                    [
                        369.4691097473619,
                        151.38857336438866
                    ],
                    [
                        370.88554014790134,
                        150.19295309658827
                    ],
                    [
                        371.7848610371327,
                        147.91443352609042
                    ],
                    [
                        374.0331632602117,
                        147.17731080527108
                    ],
                    [
                        375.9442201498289,
                        147.63804875693927
                    ],
                    [
                        379.22674139552396,
                        146.78558842923786
                    ],
                    [
                        380.93545108506373,
                        146.69340574378674
                    ],
                    [
                        381.83477197429534,
                        147.73018184409915
                    ],
                    [
                        381.52000966306423,
                        148.9504896729539
                    ],
                    [
                        383.09382121921976,
                        149.8939215250873
                    ],
                    [
                        383.67837979722003,
                        151.273645009427
                    ],
                    [
                        385.0498441532982,
                        151.15870918685573
                    ],
                    [
                        386.3313764204529,
                        152.62932447486878
                    ],
                    [
                        387.38807846529994,
                        152.9508589611914
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Upper Subansiri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Anjaw",
            "dt_code": "260",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        423.56326123463896,
                        164.73872668270548
                    ],
                    [
                        421.180060878175,
                        164.25956168684763
                    ],
                    [
                        420.14584185555873,
                        163.84874810949867
                    ],
                    [
                        420.19080790002045,
                        161.74757773640283
                    ],
                    [
                        418.66196238832686,
                        160.26158033940476
                    ],
                    [
                        417.0207017654793,
                        159.87273257371726
                    ],
                    [
                        415.1321278980929,
                        157.58368761059052
                    ],
                    [
                        415.82910158724735,
                        155.72741450017486
                    ],
                    [
                        415.24454300924685,
                        154.44293556353824
                    ],
                    [
                        415.3794411426318,
                        152.44556430921898
                    ],
                    [
                        415.51433927601624,
                        152.2158373137446
                    ],
                    [
                        418.3696830993265,
                        151.71033305293332
                    ],
                    [
                        419.4039021219428,
                        150.97479633156206
                    ],
                    [
                        420.64046834463625,
                        150.9288151283842
                    ],
                    [
                        422.37166105640677,
                        152.53744677245413
                    ],
                    [
                        424.08037074594677,
                        152.2617850949249
                    ],
                    [
                        425.5192841687174,
                        153.43305149689223
                    ],
                    [
                        426.91323154702604,
                        152.56041664433235
                    ],
                    [
                        428.104831725258,
                        153.73148604711088
                    ],
                    [
                        428.84677145887395,
                        153.73148604711088
                    ],
                    [
                        431.05010763749124,
                        154.97060146732542
                    ],
                    [
                        430.420583015029,
                        156.02546482505977
                    ],
                    [
                        430.420583015029,
                        157.65240148400216
                    ],
                    [
                        431.2974208820301,
                        158.54544214672222
                    ],
                    [
                        431.1175567041837,
                        160.1243495452551
                    ],
                    [
                        429.3189149257207,
                        160.26158033940476
                    ],
                    [
                        427.90248452518085,
                        161.70187298979982
                    ],
                    [
                        426.05887670225616,
                        162.77562898611973
                    ],
                    [
                        424.17030283486974,
                        165.03528929179924
                    ],
                    [
                        423.56326123463896,
                        164.73872668270548
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Anjaw",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tinsukia",
            "dt_code": "309",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        407.57783242854816,
                        163.0496773077897
                    ],
                    [
                        407.66776451747137,
                        164.8984202562649
                    ],
                    [
                        409.0392288735493,
                        166.1754641972754
                    ],
                    [
                        409.1741270069342,
                        167.97548791718424
                    ],
                    [
                        410.0284818517041,
                        167.95271389129755
                    ],
                    [
                        411.13014994101286,
                        169.09106807400508
                    ],
                    [
                        410.2982781184735,
                        170.04673932318514
                    ],
                    [
                        408.79191562901065,
                        170.70631731515624
                    ],
                    [
                        407.1056889617016,
                        170.7745360121379
                    ],
                    [
                        405.50939438331557,
                        171.36565876284556
                    ],
                    [
                        404.04799793831444,
                        170.82001373739962
                    ],
                    [
                        403.3060582046983,
                        171.18379506517118
                    ],
                    [
                        402.7439826489285,
                        170.3196968678941
                    ],
                    [
                        403.1711600713136,
                        169.11382794063474
                    ],
                    [
                        400.7429936703884,
                        168.749636140292
                    ],
                    [
                        399.4389783810029,
                        167.8388395059713
                    ],
                    [
                        399.55139349215665,
                        166.92758879115172
                    ],
                    [
                        400.8778918037731,
                        165.46863993108872
                    ],
                    [
                        400.1584350923879,
                        163.91722350120472
                    ],
                    [
                        401.95707687085087,
                        163.14101752858807
                    ],
                    [
                        403.7557186493141,
                        160.60461157100235
                    ],
                    [
                        403.82316771600654,
                        160.53601055146703
                    ],
                    [
                        405.1046999831615,
                        159.3465105819941
                    ],
                    [
                        408.2298400732409,
                        159.07189880268027
                    ],
                    [
                        410.45565927408893,
                        159.27786157224932
                    ],
                    [
                        407.57783242854816,
                        163.0496773077897
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Tinsukia",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dhemaji",
            "dt_code": "308",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        403.7557186493141,
                        160.60461157100235
                    ],
                    [
                        401.95707687085087,
                        163.14101752858807
                    ],
                    [
                        400.1584350923879,
                        163.91722350120472
                    ],
                    [
                        399.0792500253099,
                        163.52916207809204
                    ],
                    [
                        397.5504045136165,
                        164.25956168684763
                    ],
                    [
                        396.13397411307665,
                        164.2823819359362
                    ],
                    [
                        392.46924148945845,
                        167.95271389129755
                    ],
                    [
                        391.6823357113808,
                        168.20321257703216
                    ],
                    [
                        390.6930827332262,
                        169.6144735262256
                    ],
                    [
                        390.1984562441487,
                        168.93174109110487
                    ],
                    [
                        388.82699188807055,
                        169.72823755570954
                    ],
                    [
                        387.2306973096845,
                        169.38692429756748
                    ],
                    [
                        386.03909713145276,
                        169.9102453332444
                    ],
                    [
                        386.016614109222,
                        167.97548791718424
                    ],
                    [
                        385.40957250899055,
                        166.5857524416062
                    ],
                    [
                        384.6226667309131,
                        165.14933894821905
                    ],
                    [
                        384.9823950866057,
                        164.7159121689955
                    ],
                    [
                        386.84848593176116,
                        165.6738752539506
                    ],
                    [
                        388.28739935453154,
                        165.99308420472312
                    ],
                    [
                        394.0205700233828,
                        163.14101752858807
                    ],
                    [
                        398.20241215830924,
                        161.67902018260446
                    ],
                    [
                        400.7429936703884,
                        160.81039895812654
                    ],
                    [
                        401.77721269300446,
                        161.29047818221875
                    ],
                    [
                        403.7557186493141,
                        160.60461157100235
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Dhemaji",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Changlang",
            "dt_code": "253",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        421.180060878175,
                        164.25956168684763
                    ],
                    [
                        423.56326123463896,
                        164.73872668270548
                    ],
                    [
                        424.6424463017165,
                        166.76807313455453
                    ],
                    [
                        424.17030283486974,
                        167.70218087795195
                    ],
                    [
                        425.0471407018706,
                        168.54474642110074
                    ],
                    [
                        426.9357145692568,
                        171.66114896269323
                    ],
                    [
                        428.0148996363348,
                        172.8426362842216
                    ],
                    [
                        426.5310201691027,
                        173.66014632986042
                    ],
                    [
                        425.3619030131017,
                        172.638202233593
                    ],
                    [
                        423.540778212408,
                        172.04748772263684
                    ],
                    [
                        423.9005065681006,
                        170.88822821660284
                    ],
                    [
                        422.61897430094564,
                        169.455192030766
                    ],
                    [
                        419.8085965220971,
                        169.1365875245927
                    ],
                    [
                        418.3696830993265,
                        170.25146128475848
                    ],
                    [
                        415.4019241648623,
                        170.3196968678941
                    ],
                    [
                        412.2093350080904,
                        171.50204474679418
                    ],
                    [
                        411.22008202993584,
                        172.20654485581207
                    ],
                    [
                        410.09593091839656,
                        174.34112878041174
                    ],
                    [
                        407.9825268287025,
                        174.93111111578168
                    ],
                    [
                        407.15065500616333,
                        176.9039204386164
                    ],
                    [
                        406.25133411693173,
                        176.76793179930314
                    ],
                    [
                        406.20636807247,
                        175.43018037519943
                    ],
                    [
                        405.4419453166231,
                        174.7269077118808
                    ],
                    [
                        406.00402087239286,
                        172.34286862329645
                    ],
                    [
                        405.50939438331557,
                        171.36565876284556
                    ],
                    [
                        407.1056889617016,
                        170.7745360121379
                    ],
                    [
                        408.79191562901065,
                        170.70631731515624
                    ],
                    [
                        410.2982781184735,
                        170.04673932318514
                    ],
                    [
                        411.13014994101286,
                        169.09106807400508
                    ],
                    [
                        410.0284818517041,
                        167.95271389129755
                    ],
                    [
                        409.1741270069342,
                        167.97548791718424
                    ],
                    [
                        409.0392288735493,
                        166.1754641972754
                    ],
                    [
                        410.9278027409355,
                        165.97028542139276
                    ],
                    [
                        411.80464060793634,
                        165.14933894821905
                    ],
                    [
                        414.21032398663056,
                        165.78788487652326
                    ],
                    [
                        415.46937323155475,
                        165.74228188449985
                    ],
                    [
                        416.36869412078636,
                        164.5105686472586
                    ],
                    [
                        420.32570603340514,
                        164.7843548504801
                    ],
                    [
                        421.180060878175,
                        164.25956168684763
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Changlang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dibrugarh",
            "dt_code": "310",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        400.1584350923879,
                        163.91722350120472
                    ],
                    [
                        400.8778918037731,
                        165.46863993108872
                    ],
                    [
                        399.55139349215665,
                        166.92758879115172
                    ],
                    [
                        399.4389783810029,
                        167.8388395059713
                    ],
                    [
                        400.7429936703884,
                        168.749636140292
                    ],
                    [
                        403.1711600713136,
                        169.11382794063474
                    ],
                    [
                        402.7439826489285,
                        170.3196968678941
                    ],
                    [
                        403.3060582046983,
                        171.18379506517118
                    ],
                    [
                        402.78894869339,
                        173.00162489949003
                    ],
                    [
                        401.7322466485432,
                        173.59203429960752
                    ],
                    [
                        401.61983153738925,
                        173.36497607844342
                    ],
                    [
                        401.4624503817738,
                        172.4337455381941
                    ],
                    [
                        400.3607822924653,
                        171.91114211402777
                    ],
                    [
                        398.4497254028481,
                        173.13788996458675
                    ],
                    [
                        395.9765929574612,
                        172.3883076403149
                    ],
                    [
                        395.72927971292256,
                        171.47931445099704
                    ],
                    [
                        393.7732567788439,
                        173.66014632986042
                    ],
                    [
                        392.6715886895356,
                        173.0470477046261
                    ],
                    [
                        392.8064868229201,
                        171.25199605758314
                    ],
                    [
                        390.9179129555339,
                        171.1155915451581
                    ],
                    [
                        390.22093926637945,
                        172.77449411586622
                    ],
                    [
                        389.7712788217639,
                        172.18382324820897
                    ],
                    [
                        390.6930827332262,
                        169.6144735262256
                    ],
                    [
                        391.6823357113808,
                        168.20321257703216
                    ],
                    [
                        392.46924148945845,
                        167.95271389129755
                    ],
                    [
                        396.13397411307665,
                        164.2823819359362
                    ],
                    [
                        397.5504045136165,
                        164.25956168684763
                    ],
                    [
                        399.0792500253099,
                        163.52916207809204
                    ],
                    [
                        400.1584350923879,
                        163.91722350120472
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Dibrugarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tawang",
            "dt_code": "245",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        352.2920807630394,
                        167.08709050193585
                    ],
                    [
                        351.2128956959616,
                        167.56551202876574
                    ],
                    [
                        347.0085705388042,
                        167.20101176094283
                    ],
                    [
                        345.6820722271875,
                        165.51424978142933
                    ],
                    [
                        345.6146231604953,
                        164.69309736870974
                    ],
                    [
                        346.5813931164191,
                        163.50633234782367
                    ],
                    [
                        346.6488421831116,
                        161.907535239894
                    ],
                    [
                        349.52666902865235,
                        161.77042967585152
                    ],
                    [
                        350.336057828961,
                        163.23235314168426
                    ],
                    [
                        351.8873863628853,
                        162.43301019751112
                    ],
                    [
                        352.9216053855016,
                        162.61574829492355
                    ],
                    [
                        354.4054848527335,
                        161.31333591012793
                    ],
                    [
                        355.86688129773506,
                        160.9018524763885
                    ],
                    [
                        356.6313040535815,
                        162.27309921036692
                    ],
                    [
                        358.96953836558373,
                        161.67902018260446
                    ],
                    [
                        358.8571232544298,
                        162.22740775838446
                    ],
                    [
                        357.86787027627497,
                        164.0541665283901
                    ],
                    [
                        355.3497717864268,
                        164.80716850458504
                    ],
                    [
                        353.7309941858098,
                        166.49458525789288
                    ],
                    [
                        352.2920807630394,
                        167.08709050193585
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Tawang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Papum Pare",
            "dt_code": "248",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        380.6431717960636,
                        166.72249467022237
                    ],
                    [
                        383.04885517475805,
                        167.01873433309134
                    ],
                    [
                        384.71259881983633,
                        166.83643869547728
                    ],
                    [
                        381.3626285074488,
                        169.7509895151403
                    ],
                    [
                        378.64218281752323,
                        172.72906460643605
                    ],
                    [
                        378.9794281509853,
                        174.06876586194176
                    ],
                    [
                        377.04588823913764,
                        175.2714001696047
                    ],
                    [
                        376.57374477229087,
                        175.7023434256688
                    ],
                    [
                        373.7184009489806,
                        176.22387785735907
                    ],
                    [
                        372.63921588190306,
                        175.88376330678574
                    ],
                    [
                        371.7848610371327,
                        175.29408388773015
                    ],
                    [
                        371.38016663697863,
                        173.86446737469805
                    ],
                    [
                        369.941253214208,
                        172.91077593810752
                    ],
                    [
                        371.38016663697863,
                        172.32014869506054
                    ],
                    [
                        370.0986343698237,
                        171.02464958571426
                    ],
                    [
                        371.64996290374825,
                        169.3186540224981
                    ],
                    [
                        372.23452148174874,
                        168.31706427529485
                    ],
                    [
                        370.16608343651615,
                        165.1949568073179
                    ],
                    [
                        371.6274798815175,
                        164.0998119065743
                    ],
                    [
                        374.34792557144306,
                        164.41929740497386
                    ],
                    [
                        374.77510299382766,
                        164.8984202562649
                    ],
                    [
                        375.8767710831364,
                        166.38061986575707
                    ],
                    [
                        375.1348313495205,
                        167.38327098938493
                    ],
                    [
                        375.606974816367,
                        169.11382794063474
                    ],
                    [
                        376.57374477229087,
                        169.6144735262256
                    ],
                    [
                        379.0693602399085,
                        169.1821058445753
                    ],
                    [
                        379.31667348444716,
                        167.49717376987172
                    ],
                    [
                        380.6431717960636,
                        166.72249467022237
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Papum Pare",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lakhimpur",
            "dt_code": "307",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        385.40957250899055,
                        166.5857524416062
                    ],
                    [
                        386.016614109222,
                        167.97548791718424
                    ],
                    [
                        386.03909713145276,
                        169.9102453332444
                    ],
                    [
                        387.2306973096845,
                        169.38692429756748
                    ],
                    [
                        388.82699188807055,
                        169.72823755570954
                    ],
                    [
                        390.1984562441487,
                        168.93174109110487
                    ],
                    [
                        390.6930827332262,
                        169.6144735262256
                    ],
                    [
                        389.7712788217639,
                        172.18382324820897
                    ],
                    [
                        388.9394069992245,
                        173.1833094197301
                    ],
                    [
                        386.0840631759145,
                        174.00066887169754
                    ],
                    [
                        384.93742904214446,
                        174.00066887169754
                    ],
                    [
                        384.26293837522053,
                        175.11260637842145
                    ],
                    [
                        380.9579341072947,
                        176.4279167295623
                    ],
                    [
                        379.90123206244766,
                        177.40246052583012
                    ],
                    [
                        378.1025902839847,
                        177.74229778624596
                    ],
                    [
                        378.2599714396001,
                        177.06256130112206
                    ],
                    [
                        377.04588823913764,
                        175.2714001696047
                    ],
                    [
                        378.9794281509853,
                        174.06876586194176
                    ],
                    [
                        378.64218281752323,
                        172.72906460643605
                    ],
                    [
                        381.3626285074488,
                        169.7509895151403
                    ],
                    [
                        384.71259881983633,
                        166.83643869547728
                    ],
                    [
                        385.40957250899055,
                        166.5857524416062
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Lakhimpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tirap",
            "dt_code": "254",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        403.3060582046983,
                        171.18379506517118
                    ],
                    [
                        404.04799793831444,
                        170.82001373739962
                    ],
                    [
                        405.50939438331557,
                        171.36565876284556
                    ],
                    [
                        406.00402087239286,
                        172.34286862329645
                    ],
                    [
                        405.4419453166231,
                        174.7269077118808
                    ],
                    [
                        406.20636807247,
                        175.43018037519943
                    ],
                    [
                        406.25133411693173,
                        176.76793179930314
                    ],
                    [
                        405.1271830053922,
                        178.2632613894408
                    ],
                    [
                        403.4859223825447,
                        178.19531785965967
                    ],
                    [
                        401.5973485151585,
                        176.24654994859424
                    ],
                    [
                        401.7322466485432,
                        173.59203429960752
                    ],
                    [
                        402.78894869339,
                        173.00162489949003
                    ],
                    [
                        403.3060582046983,
                        171.18379506517118
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Tirap",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sivasagar",
            "dt_code": "311",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        390.22093926637945,
                        172.77449411586622
                    ],
                    [
                        390.9179129555339,
                        171.1155915451581
                    ],
                    [
                        392.8064868229201,
                        171.25199605758314
                    ],
                    [
                        392.6715886895356,
                        173.0470477046261
                    ],
                    [
                        393.7732567788439,
                        173.66014632986042
                    ],
                    [
                        393.68332468992094,
                        174.90842295916474
                    ],
                    [
                        392.2893773116118,
                        176.29189330186995
                    ],
                    [
                        395.0772720682296,
                        176.01981659475757
                    ],
                    [
                        394.2229172234597,
                        176.67726718769262
                    ],
                    [
                        393.12124913415096,
                        178.53501079560965
                    ],
                    [
                        392.31186033384256,
                        179.30475296972304
                    ],
                    [
                        390.1085241552255,
                        179.71213537195842
                    ],
                    [
                        389.0293390881475,
                        178.94256082447095
                    ],
                    [
                        388.46726353237796,
                        176.24654994859424
                    ],
                    [
                        387.3206293986077,
                        175.99714173838458
                    ],
                    [
                        389.50148255499425,
                        173.9098689874411
                    ],
                    [
                        390.22093926637945,
                        172.77449411586622
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Sivasagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jorhat",
            "dt_code": "312",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        387.3206293986077,
                        175.99714173838458
                    ],
                    [
                        388.46726353237796,
                        176.24654994859424
                    ],
                    [
                        389.0293390881475,
                        178.94256082447095
                    ],
                    [
                        390.1085241552255,
                        179.71213537195842
                    ],
                    [
                        387.4105614875309,
                        181.5894767651336
                    ],
                    [
                        387.38807846529994,
                        182.8550583541218
                    ],
                    [
                        386.1065461981452,
                        184.052059043273
                    ],
                    [
                        385.67936877576017,
                        182.4031625484456
                    ],
                    [
                        384.19548930852807,
                        184.14236794298233
                    ],
                    [
                        383.90321001952816,
                        185.85741899107563
                    ],
                    [
                        382.91395704137335,
                        183.6681980740978
                    ],
                    [
                        382.9364400636041,
                        181.0694376255284
                    ],
                    [
                        381.0928322406794,
                        179.01047719338823
                    ],
                    [
                        381.4975266408335,
                        177.37980250603113
                    ],
                    [
                        384.55521766422066,
                        176.38257669219524
                    ],
                    [
                        385.97164806476053,
                        176.67726718769262
                    ],
                    [
                        387.3206293986077,
                        175.99714173838458
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Jorhat",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mon",
            "dt_code": "261",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        393.12124913415096,
                        178.53501079560965
                    ],
                    [
                        394.2229172234597,
                        176.67726718769262
                    ],
                    [
                        395.0772720682296,
                        176.01981659475757
                    ],
                    [
                        395.9316269129997,
                        176.60926583074888
                    ],
                    [
                        397.685302647001,
                        175.81573292732423
                    ],
                    [
                        399.0792500253099,
                        174.6134516566417
                    ],
                    [
                        399.5738765143874,
                        176.45058633386583
                    ],
                    [
                        399.1691821142333,
                        177.71964389532712
                    ],
                    [
                        399.64132558107985,
                        180.4135308237888
                    ],
                    [
                        397.1007440690007,
                        184.2326725297292
                    ],
                    [
                        398.0000649582323,
                        185.90253096540033
                    ],
                    [
                        396.2014231797691,
                        186.80454549797548
                    ],
                    [
                        395.3470683349992,
                        186.60162955688787
                    ],
                    [
                        394.9198909126144,
                        185.38367848827244
                    ],
                    [
                        394.4252644235369,
                        182.15457364963203
                    ],
                    [
                        392.941384956305,
                        180.79805570744838
                    ],
                    [
                        393.75077375661317,
                        179.82528142560636
                    ],
                    [
                        393.12124913415096,
                        178.53501079560965
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Mon",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sonitpur",
            "dt_code": "306",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        367.69295099112946,
                        176.4732556619427
                    ],
                    [
                        367.78288308005267,
                        179.35002205886548
                    ],
                    [
                        368.90703419159195,
                        181.86074444838772
                    ],
                    [
                        368.2100605024375,
                        182.15457364963203
                    ],
                    [
                        366.07417339051267,
                        181.43121921317925
                    ],
                    [
                        362.7017200558944,
                        182.80987364818014
                    ],
                    [
                        360.5433499217388,
                        183.41977587782077
                    ],
                    [
                        358.2500816541983,
                        183.17132098944757
                    ],
                    [
                        357.10344752042806,
                        183.37460469415583
                    ],
                    [
                        357.37324378719745,
                        182.04156784621455
                    ],
                    [
                        356.90110032035113,
                        178.2632613894408
                    ],
                    [
                        357.37324378719745,
                        176.4052468490122
                    ],
                    [
                        358.6547760543526,
                        175.86108679037966
                    ],
                    [
                        360.3634857438924,
                        175.88376330678574
                    ],
                    [
                        361.7349500999708,
                        175.04454773594563
                    ],
                    [
                        361.9822633445092,
                        174.65883491209587
                    ],
                    [
                        365.1298864568196,
                        175.18066252433488
                    ],
                    [
                        367.10839241312897,
                        176.60926583074888
                    ],
                    [
                        367.69295099112946,
                        176.4732556619427
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Sonitpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Golaghat",
            "dt_code": "313",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        378.1025902839847,
                        177.74229778624596
                    ],
                    [
                        379.90123206244766,
                        177.40246052583012
                    ],
                    [
                        380.9579341072947,
                        176.4279167295623
                    ],
                    [
                        381.4975266408335,
                        177.37980250603113
                    ],
                    [
                        381.0928322406794,
                        179.01047719338823
                    ],
                    [
                        382.9364400636041,
                        181.0694376255284
                    ],
                    [
                        382.91395704137335,
                        183.6681980740978
                    ],
                    [
                        383.90321001952816,
                        185.85741899107563
                    ],
                    [
                        383.0263721525273,
                        186.37614199197338
                    ],
                    [
                        381.4975266408335,
                        188.78747392112888
                    ],
                    [
                        380.8230359739098,
                        192.11776763738686
                    ],
                    [
                        381.1827643296026,
                        192.97191204042917
                    ],
                    [
                        379.743850906832,
                        194.16258708031688
                    ],
                    [
                        378.2150053951384,
                        194.07275042752798
                    ],
                    [
                        378.19252237290766,
                        192.11776763738686
                    ],
                    [
                        378.3499035285231,
                        190.7233486351173
                    ],
                    [
                        379.9237150846784,
                        185.51904499197417
                    ],
                    [
                        378.46231863967705,
                        184.39069518220964
                    ],
                    [
                        377.0908542835991,
                        181.65729735342507
                    ],
                    [
                        374.07812930467344,
                        181.5894767651336
                    ],
                    [
                        373.5160537489037,
                        182.15457364963203
                    ],
                    [
                        371.15533641467096,
                        182.17717399641492
                    ],
                    [
                        371.2902345480559,
                        180.16467860276208
                    ],
                    [
                        372.6167328596723,
                        179.28211801454836
                    ],
                    [
                        374.21302743805813,
                        179.19157545603173
                    ],
                    [
                        376.34891454998296,
                        180.02892710650917
                    ],
                    [
                        376.3264315277522,
                        178.67087067836383
                    ],
                    [
                        378.1025902839847,
                        177.74229778624596
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Golaghat",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Longleng",
            "dt_code": "268",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        392.31186033384256,
                        179.30475296972304
                    ],
                    [
                        393.12124913415096,
                        178.53501079560965
                    ],
                    [
                        393.75077375661317,
                        179.82528142560636
                    ],
                    [
                        392.941384956305,
                        180.79805570744838
                    ],
                    [
                        394.4252644235369,
                        182.15457364963203
                    ],
                    [
                        394.9198909126144,
                        185.38367848827244
                    ],
                    [
                        393.9531209566903,
                        185.33855417263595
                    ],
                    [
                        392.7615207784586,
                        184.07463667260862
                    ],
                    [
                        391.9296489559192,
                        184.30039814015527
                    ],
                    [
                        391.61488664468857,
                        183.08096564677084
                    ],
                    [
                        392.26689428938107,
                        181.7477210007135
                    ],
                    [
                        392.31186033384256,
                        179.30475296972304
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Longleng",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mokokchung",
            "dt_code": "262",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        390.1085241552255,
                        179.71213537195842
                    ],
                    [
                        392.31186033384256,
                        179.30475296972304
                    ],
                    [
                        392.26689428938107,
                        181.7477210007135
                    ],
                    [
                        391.61488664468857,
                        183.08096564677084
                    ],
                    [
                        391.9296489559192,
                        184.30039814015527
                    ],
                    [
                        391.0528110889186,
                        186.06041443306088
                    ],
                    [
                        389.7937618439946,
                        186.89472341072343
                    ],
                    [
                        390.063558110764,
                        188.02158700415328
                    ],
                    [
                        386.9609010429151,
                        188.4045688859024
                    ],
                    [
                        386.8709689539919,
                        186.10552158375248
                    ],
                    [
                        386.1065461981452,
                        184.052059043273
                    ],
                    [
                        387.38807846529994,
                        182.8550583541218
                    ],
                    [
                        387.4105614875309,
                        181.5894767651336
                    ],
                    [
                        390.1085241552255,
                        179.71213537195842
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Mokokchung",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Udalguri",
            "dt_code": "326",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        357.37324378719745,
                        176.4052468490122
                    ],
                    [
                        356.90110032035113,
                        178.2632613894408
                    ],
                    [
                        357.37324378719745,
                        182.04156784621455
                    ],
                    [
                        355.64205107542693,
                        181.72511549637915
                    ],
                    [
                        354.69776414173384,
                        180.93375156843007
                    ],
                    [
                        353.1464356078093,
                        181.00159582220056
                    ],
                    [
                        352.13469960742395,
                        182.69690714558584
                    ],
                    [
                        349.5491520508831,
                        182.6743130327264
                    ],
                    [
                        348.8296953394979,
                        183.19390914907586
                    ],
                    [
                        348.22265373926666,
                        181.67990367275445
                    ],
                    [
                        348.3350688504206,
                        180.32304292735358
                    ],
                    [
                        347.72802725018914,
                        178.2632613894408
                    ],
                    [
                        350.51592200680716,
                        176.51859348949495
                    ],
                    [
                        351.8424203184236,
                        177.5157464942671
                    ],
                    [
                        352.9216053855016,
                        177.71964389532712
                    ],
                    [
                        353.75347720804075,
                        176.949247778193
                    ],
                    [
                        354.9450773862727,
                        176.99457401460182
                    ],
                    [
                        357.37324378719745,
                        176.4052468490122
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Udalguri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chirang",
            "dt_code": "320",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        335.54222920110215,
                        182.380564913333
                    ],
                    [
                        335.13753480094783,
                        182.94542451812487
                    ],
                    [
                        333.631172311485,
                        183.9843245373603
                    ],
                    [
                        332.70936840002264,
                        183.41977587782077
                    ],
                    [
                        330.32616804355916,
                        183.26167200577532
                    ],
                    [
                        330.10133782125126,
                        184.61641893581202
                    ],
                    [
                        328.5500092873267,
                        184.36812132576443
                    ],
                    [
                        327.898001642634,
                        182.26757267119166
                    ],
                    [
                        328.2352469760958,
                        179.82528142560636
                    ],
                    [
                        327.6282053758646,
                        177.44777573925705
                    ],
                    [
                        328.8198055540963,
                        176.74526606037483
                    ],
                    [
                        330.70837942148273,
                        178.19531785965967
                    ],
                    [
                        333.2264779113307,
                        178.98783867777342
                    ],
                    [
                        336.6663803126414,
                        178.85200182902577
                    ],
                    [
                        335.9019575567945,
                        180.30042027114575
                    ],
                    [
                        335.54222920110215,
                        182.380564913333
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Chirang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Baksa",
            "dt_code": "324",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        347.72802725018914,
                        178.2632613894408
                    ],
                    [
                        348.3350688504206,
                        180.32304292735358
                    ],
                    [
                        348.22265373926666,
                        181.67990367275445
                    ],
                    [
                        348.8296953394979,
                        183.19390914907586
                    ],
                    [
                        348.85217836172865,
                        183.7585253177803
                    ],
                    [
                        348.0427895614205,
                        183.3520186970008
                    ],
                    [
                        346.5813931164191,
                        184.27782320613
                    ],
                    [
                        345.8844194272647,
                        182.94542451812487
                    ],
                    [
                        345.8844194272647,
                        182.22237387623483
                    ],
                    [
                        341.9723735591074,
                        183.12614385898098
                    ],
                    [
                        341.05056964764503,
                        183.0357863526624
                    ],
                    [
                        340.82573942533736,
                        181.63469076239113
                    ],
                    [
                        339.61165622487465,
                        181.1372769789429
                    ],
                    [
                        339.0046146246434,
                        182.4031625484456
                    ],
                    [
                        337.2509388906419,
                        183.01319629984738
                    ],
                    [
                        337.2284558684112,
                        181.9285552584974
                    ],
                    [
                        335.54222920110215,
                        182.380564913333
                    ],
                    [
                        335.9019575567945,
                        180.30042027114575
                    ],
                    [
                        336.6663803126414,
                        178.85200182902577
                    ],
                    [
                        338.17274280210427,
                        178.7840797040859
                    ],
                    [
                        338.8247504467972,
                        178.10472264063327
                    ],
                    [
                        340.8707054697986,
                        178.28590868338034
                    ],
                    [
                        342.3321019148002,
                        178.80672068656747
                    ],
                    [
                        343.38880395964725,
                        177.81025780847506
                    ],
                    [
                        344.67033622680196,
                        178.62558514816146
                    ],
                    [
                        346.5813931164191,
                        178.1273718575187
                    ],
                    [
                        347.72802725018914,
                        178.2632613894408
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Baksa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nagaon",
            "dt_code": "305",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        358.2500816541983,
                        183.17132098944757
                    ],
                    [
                        360.5433499217388,
                        183.41977587782077
                    ],
                    [
                        362.7017200558944,
                        182.80987364818014
                    ],
                    [
                        366.07417339051267,
                        181.43121921317925
                    ],
                    [
                        368.2100605024375,
                        182.15457364963203
                    ],
                    [
                        368.90703419159195,
                        181.86074444838772
                    ],
                    [
                        371.2902345480559,
                        180.16467860276208
                    ],
                    [
                        371.15533641467096,
                        182.17717399641492
                    ],
                    [
                        369.6040078807464,
                        182.31277038144202
                    ],
                    [
                        366.9734942797443,
                        183.28425908401118
                    ],
                    [
                        365.939275257128,
                        186.1957326708128
                    ],
                    [
                        364.05070138974156,
                        187.30047113099954
                    ],
                    [
                        363.8708372118956,
                        188.31446241899278
                    ],
                    [
                        362.431923789125,
                        189.55305382999273
                    ],
                    [
                        363.15138050050996,
                        190.7233486351173
                    ],
                    [
                        361.7124670777398,
                        191.60060125160982
                    ],
                    [
                        360.11617249935375,
                        192.07280216825586
                    ],
                    [
                        360.83562921073917,
                        190.29582380136418
                    ],
                    [
                        359.8688592548151,
                        189.1477850165018
                    ],
                    [
                        357.8903532985057,
                        189.75565604139712
                    ],
                    [
                        358.4299458320445,
                        188.4946710983342
                    ],
                    [
                        358.2500816541983,
                        187.18777210832894
                    ],
                    [
                        359.5091308991225,
                        187.14269063077643
                    ],
                    [
                        360.43093481058463,
                        186.08296814235285
                    ],
                    [
                        360.1836215660462,
                        185.33855417263595
                    ],
                    [
                        358.5648439654294,
                        185.5416051364935
                    ],
                    [
                        358.2500816541983,
                        183.17132098944757
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Nagaon",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kokrajhar",
            "dt_code": "300",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        327.6282053758646,
                        177.44777573925705
                    ],
                    [
                        328.2352469760958,
                        179.82528142560636
                    ],
                    [
                        327.898001642634,
                        182.26757267119166
                    ],
                    [
                        328.5500092873267,
                        184.36812132576443
                    ],
                    [
                        328.93222066525027,
                        185.6544018341205
                    ],
                    [
                        326.9087486644794,
                        187.48077569211966
                    ],
                    [
                        326.616469375479,
                        186.1957326708128
                    ],
                    [
                        324.390650174631,
                        186.06041443306088
                    ],
                    [
                        324.6829294636311,
                        188.08917763028973
                    ],
                    [
                        323.0416688407838,
                        189.53054114880302
                    ],
                    [
                        320.95074777332024,
                        189.39545949717137
                    ],
                    [
                        320.6135024398584,
                        187.99905626300256
                    ],
                    [
                        321.2655100845516,
                        186.08296814235285
                    ],
                    [
                        320.36618919532,
                        185.33855417263595
                    ],
                    [
                        320.253774084166,
                        184.81954730098056
                    ],
                    [
                        320.77088359547406,
                        181.54426168082517
                    ],
                    [
                        320.50108732870444,
                        180.16467860276208
                    ],
                    [
                        320.6135024398584,
                        179.55371941397095
                    ],
                    [
                        323.4013971964764,
                        179.62161360815935
                    ],
                    [
                        325.537284308401,
                        178.91992148674427
                    ],
                    [
                        326.07687684194,
                        177.5157464942671
                    ],
                    [
                        327.6282053758646,
                        177.44777573925705
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Kokrajhar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Darrang",
            "dt_code": "325",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        357.37324378719745,
                        182.04156784621455
                    ],
                    [
                        357.10344752042806,
                        183.37460469415583
                    ],
                    [
                        355.3048057419651,
                        184.79697855879772
                    ],
                    [
                        354.4054848527335,
                        184.86468397843328
                    ],
                    [
                        352.67429214096273,
                        187.14269063077643
                    ],
                    [
                        351.7974542739621,
                        187.1201494915336
                    ],
                    [
                        350.31357480673,
                        188.06664768777785
                    ],
                    [
                        348.38003489488233,
                        188.17929473878593
                    ],
                    [
                        349.48170298419063,
                        185.5416051364935
                    ],
                    [
                        348.85217836172865,
                        183.7585253177803
                    ],
                    [
                        348.8296953394979,
                        183.19390914907586
                    ],
                    [
                        349.5491520508831,
                        182.6743130327264
                    ],
                    [
                        352.13469960742395,
                        182.69690714558584
                    ],
                    [
                        353.1464356078093,
                        181.00159582220056
                    ],
                    [
                        354.69776414173384,
                        180.93375156843007
                    ],
                    [
                        355.64205107542693,
                        181.72511549637915
                    ],
                    [
                        357.37324378719745,
                        182.04156784621455
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Darrang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tuensang",
            "dt_code": "267",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        394.9198909126144,
                        185.38367848827244
                    ],
                    [
                        395.3470683349992,
                        186.60162955688787
                    ],
                    [
                        396.2014231797691,
                        186.80454549797548
                    ],
                    [
                        398.0000649582323,
                        185.90253096540033
                    ],
                    [
                        397.9101328693091,
                        189.8456945843066
                    ],
                    [
                        398.92186886969466,
                        190.40833958140684
                    ],
                    [
                        398.0450310026938,
                        191.8254732951113
                    ],
                    [
                        396.40377037984626,
                        193.39884229375588
                    ],
                    [
                        395.796728779615,
                        193.53364274333688
                    ],
                    [
                        393.9306379344596,
                        190.9258271355319
                    ],
                    [
                        391.322607355688,
                        191.28573617187453
                    ],
                    [
                        391.862199889227,
                        190.8133417181943
                    ],
                    [
                        391.862199889227,
                        188.78747392112888
                    ],
                    [
                        390.063558110764,
                        188.02158700415328
                    ],
                    [
                        389.7937618439946,
                        186.89472341072343
                    ],
                    [
                        391.0528110889186,
                        186.06041443306088
                    ],
                    [
                        391.9296489559192,
                        184.30039814015527
                    ],
                    [
                        392.7615207784586,
                        184.07463667260862
                    ],
                    [
                        393.9531209566903,
                        185.33855417263595
                    ],
                    [
                        394.9198909126144,
                        185.38367848827244
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Tuensang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Barpeta",
            "dt_code": "303",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        335.13753480094783,
                        182.94542451812487
                    ],
                    [
                        335.54222920110215,
                        182.380564913333
                    ],
                    [
                        337.2284558684112,
                        181.9285552584974
                    ],
                    [
                        337.2509388906419,
                        183.01319629984738
                    ],
                    [
                        339.0046146246434,
                        182.4031625484456
                    ],
                    [
                        339.61165622487465,
                        181.1372769789429
                    ],
                    [
                        340.82573942533736,
                        181.63469076239113
                    ],
                    [
                        341.05056964764503,
                        183.0357863526624
                    ],
                    [
                        340.78077338087587,
                        184.41326876930844
                    ],
                    [
                        341.45526404779935,
                        186.01530621072186
                    ],
                    [
                        340.55594315856797,
                        187.2328525180992
                    ],
                    [
                        340.6683582697219,
                        188.67486276468867
                    ],
                    [
                        338.57743720225835,
                        188.96763796238548
                    ],
                    [
                        336.5539652014875,
                        190.07077241700466
                    ],
                    [
                        333.1815118668692,
                        189.48551499157264
                    ],
                    [
                        332.52950422217646,
                        188.29193513735834
                    ],
                    [
                        332.2821909776376,
                        187.72866659606564
                    ],
                    [
                        334.28317995617795,
                        186.21828477304
                    ],
                    [
                        335.339882001025,
                        184.54870463671347
                    ],
                    [
                        335.13753480094783,
                        182.94542451812487
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Barpeta",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nalbari",
            "dt_code": "323",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        341.05056964764503,
                        183.0357863526624
                    ],
                    [
                        341.9723735591074,
                        183.12614385898098
                    ],
                    [
                        345.8844194272647,
                        182.22237387623483
                    ],
                    [
                        345.8844194272647,
                        182.94542451812487
                    ],
                    [
                        345.09751364918725,
                        186.10552158375248
                    ],
                    [
                        343.4562530263397,
                        187.2553923226139
                    ],
                    [
                        342.57941515933885,
                        188.87755806576473
                    ],
                    [
                        340.6683582697219,
                        188.67486276468867
                    ],
                    [
                        340.55594315856797,
                        187.2328525180992
                    ],
                    [
                        341.45526404779935,
                        186.01530621072186
                    ],
                    [
                        340.78077338087587,
                        184.41326876930844
                    ],
                    [
                        341.05056964764503,
                        183.0357863526624
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Nalbari",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Morigaon",
            "dt_code": "304",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        357.10344752042806,
                        183.37460469415583
                    ],
                    [
                        358.2500816541983,
                        183.17132098944757
                    ],
                    [
                        358.5648439654294,
                        185.5416051364935
                    ],
                    [
                        360.1836215660462,
                        185.33855417263595
                    ],
                    [
                        360.43093481058463,
                        186.08296814235285
                    ],
                    [
                        359.5091308991225,
                        187.14269063077643
                    ],
                    [
                        358.2500816541983,
                        187.18777210832894
                    ],
                    [
                        358.4299458320445,
                        188.4946710983342
                    ],
                    [
                        357.8903532985057,
                        189.75565604139712
                    ],
                    [
                        356.1141945422735,
                        190.54334979920532
                    ],
                    [
                        354.6303150750414,
                        190.3633340621115
                    ],
                    [
                        354.5403829861182,
                        188.92259854499986
                    ],
                    [
                        352.9665714299631,
                        188.4045688859024
                    ],
                    [
                        351.7974542739621,
                        187.1201494915336
                    ],
                    [
                        352.67429214096273,
                        187.14269063077643
                    ],
                    [
                        354.4054848527335,
                        184.86468397843328
                    ],
                    [
                        355.3048057419651,
                        184.79697855879772
                    ],
                    [
                        357.10344752042806,
                        183.37460469415583
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Morigaon",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kamrup",
            "dt_code": "321",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        345.8844194272647,
                        182.94542451812487
                    ],
                    [
                        346.5813931164191,
                        184.27782320613
                    ],
                    [
                        348.0427895614205,
                        183.3520186970008
                    ],
                    [
                        348.85217836172865,
                        183.7585253177803
                    ],
                    [
                        349.48170298419063,
                        185.5416051364935
                    ],
                    [
                        348.38003489488233,
                        188.17929473878593
                    ],
                    [
                        345.74952129388,
                        189.1477850165018
                    ],
                    [
                        345.6820722271875,
                        189.9132207123326
                    ],
                    [
                        348.02030653918973,
                        191.03830595898228
                    ],
                    [
                        347.997823516959,
                        192.16273205554762
                    ],
                    [
                        345.9069024494954,
                        191.0608009326163
                    ],
                    [
                        343.928396493186,
                        194.2299618292372
                    ],
                    [
                        342.30961889256923,
                        194.20750384057538
                    ],
                    [
                        340.64587524749095,
                        196.1379390240507
                    ],
                    [
                        339.5442071581822,
                        194.0502906116634
                    ],
                    [
                        337.4982521351808,
                        194.47698248579837
                    ],
                    [
                        337.76804840194995,
                        193.46624369574357
                    ],
                    [
                        338.84723346902797,
                        191.44317516297508
                    ],
                    [
                        336.5539652014875,
                        190.07077241700466
                    ],
                    [
                        338.57743720225835,
                        188.96763796238548
                    ],
                    [
                        340.6683582697219,
                        188.67486276468867
                    ],
                    [
                        342.57941515933885,
                        188.87755806576473
                    ],
                    [
                        343.4562530263397,
                        187.2553923226139
                    ],
                    [
                        345.09751364918725,
                        186.10552158375248
                    ],
                    [
                        345.8844194272647,
                        182.94542451812487
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Kamrup",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bongaigaon",
            "dt_code": "319",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        328.5500092873267,
                        184.36812132576443
                    ],
                    [
                        330.10133782125126,
                        184.61641893581202
                    ],
                    [
                        330.32616804355916,
                        183.26167200577532
                    ],
                    [
                        332.70936840002264,
                        183.41977587782077
                    ],
                    [
                        333.631172311485,
                        183.9843245373603
                    ],
                    [
                        335.13753480094783,
                        182.94542451812487
                    ],
                    [
                        335.339882001025,
                        184.54870463671347
                    ],
                    [
                        334.28317995617795,
                        186.21828477304
                    ],
                    [
                        332.2821909776376,
                        187.72866659606564
                    ],
                    [
                        332.52950422217646,
                        188.29193513735834
                    ],
                    [
                        330.3486510657899,
                        188.3369894346448
                    ],
                    [
                        329.5842283099432,
                        188.85503742790547
                    ],
                    [
                        328.415111153942,
                        188.26940758972313
                    ],
                    [
                        329.7865755100204,
                        186.75945493803567
                    ],
                    [
                        328.93222066525027,
                        185.6544018341205
                    ],
                    [
                        328.5500092873267,
                        184.36812132576443
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Bongaigaon",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dhubri",
            "dt_code": "301",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        320.36618919532,
                        185.33855417263595
                    ],
                    [
                        321.2655100845516,
                        186.08296814235285
                    ],
                    [
                        320.6135024398584,
                        187.99905626300256
                    ],
                    [
                        320.95074777332024,
                        189.39545949717137
                    ],
                    [
                        323.0416688407838,
                        189.53054114880302
                    ],
                    [
                        324.6829294636311,
                        188.08917763028973
                    ],
                    [
                        324.390650174631,
                        186.06041443306088
                    ],
                    [
                        326.616469375479,
                        186.1957326708128
                    ],
                    [
                        326.9087486644794,
                        187.48077569211966
                    ],
                    [
                        328.93222066525027,
                        185.6544018341205
                    ],
                    [
                        329.7865755100204,
                        186.75945493803567
                    ],
                    [
                        328.415111153942,
                        188.26940758972313
                    ],
                    [
                        326.5715033310173,
                        188.6072928828898
                    ],
                    [
                        325.2450050194011,
                        189.9132207123326
                    ],
                    [
                        325.31245408609334,
                        190.8133417181943
                    ],
                    [
                        323.8960236855537,
                        190.94832342765636
                    ],
                    [
                        320.36618919532,
                        193.53364274333688
                    ],
                    [
                        321.0181968400127,
                        192.49993170747035
                    ],
                    [
                        318.70244555024146,
                        188.80999535560653
                    ],
                    [
                        318.27526812785663,
                        188.0441174790722
                    ],
                    [
                        319.4668683060884,
                        185.83486260179404
                    ],
                    [
                        320.36618919532,
                        185.33855417263595
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Dhubri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kamrup Metropolitan",
            "dt_code": "322",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        348.38003489488233,
                        188.17929473878593
                    ],
                    [
                        350.31357480673,
                        188.06664768777785
                    ],
                    [
                        351.7974542739621,
                        187.1201494915336
                    ],
                    [
                        352.9665714299631,
                        188.4045688859024
                    ],
                    [
                        354.5403829861182,
                        188.92259854499986
                    ],
                    [
                        354.6303150750414,
                        190.3633340621115
                    ],
                    [
                        354.0457564970409,
                        190.88083375999736
                    ],
                    [
                        350.1337106288838,
                        190.9258271355319
                    ],
                    [
                        349.52666902865235,
                        189.66561326245682
                    ],
                    [
                        348.02030653918973,
                        191.03830595898228
                    ],
                    [
                        345.6820722271875,
                        189.9132207123326
                    ],
                    [
                        345.74952129388,
                        189.1477850165018
                    ],
                    [
                        348.38003489488233,
                        188.17929473878593
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Kamrup Metropolitan",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kiphire",
            "dt_code": "269",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        391.322607355688,
                        191.28573617187453
                    ],
                    [
                        393.9306379344596,
                        190.9258271355319
                    ],
                    [
                        395.796728779615,
                        193.53364274333688
                    ],
                    [
                        396.40377037984626,
                        193.39884229375588
                    ],
                    [
                        396.7859817577696,
                        195.82381316015983
                    ],
                    [
                        395.257136246076,
                        197.03516157986718
                    ],
                    [
                        394.47023046799836,
                        198.357809996968
                    ],
                    [
                        394.088019090075,
                        196.22767993229462
                    ],
                    [
                        393.5259435343055,
                        195.46474986417815
                    ],
                    [
                        391.97461500038094,
                        195.55452192297844
                    ],
                    [
                        390.4682525109181,
                        194.58925417113375
                    ],
                    [
                        389.86121091068685,
                        193.42130968938255
                    ],
                    [
                        391.322607355688,
                        191.28573617187453
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Kiphire",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Goalpara",
            "dt_code": "302",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        332.52950422217646,
                        188.29193513735834
                    ],
                    [
                        333.1815118668692,
                        189.48551499157264
                    ],
                    [
                        336.5539652014875,
                        190.07077241700466
                    ],
                    [
                        338.84723346902797,
                        191.44317516297508
                    ],
                    [
                        337.76804840194995,
                        193.46624369574357
                    ],
                    [
                        336.62141426817993,
                        192.45497516672435
                    ],
                    [
                        333.2264779113307,
                        192.34257922153648
                    ],
                    [
                        331.9674286664067,
                        192.92696651364815
                    ],
                    [
                        329.06711879863497,
                        191.75801444433387
                    ],
                    [
                        325.42486919724706,
                        192.5224095842063
                    ],
                    [
                        324.4805822635542,
                        192.27513850395394
                    ],
                    [
                        325.31245408609334,
                        190.8133417181943
                    ],
                    [
                        325.2450050194011,
                        189.9132207123326
                    ],
                    [
                        326.5715033310173,
                        188.6072928828898
                    ],
                    [
                        328.415111153942,
                        188.26940758972313
                    ],
                    [
                        329.5842283099432,
                        188.85503742790547
                    ],
                    [
                        330.3486510657899,
                        188.3369894346448
                    ],
                    [
                        332.52950422217646,
                        188.29193513735834
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Goalpara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ribhoi",
            "dt_code": "297",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        354.6303150750414,
                        190.3633340621115
                    ],
                    [
                        356.1141945422735,
                        190.54334979920532
                    ],
                    [
                        354.49541694165646,
                        192.09528503420222
                    ],
                    [
                        355.05749249742644,
                        193.12921313159035
                    ],
                    [
                        354.3155527638105,
                        194.6117077261772
                    ],
                    [
                        354.3155527638105,
                        196.96788425474108
                    ],
                    [
                        354.02327347481037,
                        197.48361763046006
                    ],
                    [
                        349.6390841398063,
                        197.416355810887
                    ],
                    [
                        349.57163507311384,
                        196.94545796243835
                    ],
                    [
                        348.9645934728828,
                        195.03827577388836
                    ],
                    [
                        346.01931756064937,
                        194.67906682760236
                    ],
                    [
                        344.1307436932632,
                        195.06072411993205
                    ],
                    [
                        342.30961889256923,
                        194.20750384057538
                    ],
                    [
                        343.928396493186,
                        194.2299618292372
                    ],
                    [
                        345.9069024494954,
                        191.0608009326163
                    ],
                    [
                        347.997823516959,
                        192.16273205554762
                    ],
                    [
                        348.02030653918973,
                        191.03830595898228
                    ],
                    [
                        349.52666902865235,
                        189.66561326245682
                    ],
                    [
                        350.1337106288838,
                        190.9258271355319
                    ],
                    [
                        354.0457564970409,
                        190.88083375999736
                    ],
                    [
                        354.6303150750414,
                        190.3633340621115
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-Ribhoi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dima Hasao",
            "dt_code": "315",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        367.73791703559095,
                        195.28519326969462
                    ],
                    [
                        368.8845511693612,
                        196.20524509418044
                    ],
                    [
                        369.85132112528527,
                        199.074803500421
                    ],
                    [
                        371.6274798815175,
                        199.074803500421
                    ],
                    [
                        373.49357072667294,
                        200.84375624283257
                    ],
                    [
                        373.69591792674987,
                        202.9241523551957
                    ],
                    [
                        372.3919026373644,
                        203.99704587168753
                    ],
                    [
                        371.8523101038252,
                        205.8955340300563
                    ],
                    [
                        370.4133966810548,
                        208.34970873749205
                    ],
                    [
                        369.4241437029002,
                        207.88141806323574
                    ],
                    [
                        367.69295099112946,
                        207.8591157637033
                    ],
                    [
                        366.995977301975,
                        206.8998805551363
                    ],
                    [
                        365.287267612435,
                        206.60978815135434
                    ],
                    [
                        364.36546370097267,
                        207.0560666298129
                    ],
                    [
                        362.7017200558944,
                        206.07412165053262
                    ],
                    [
                        360.02624041043055,
                        206.2973335838446
                    ],
                    [
                        361.2178405886625,
                        206.0294762520095
                    ],
                    [
                        361.8473652111245,
                        205.02468899318632
                    ],
                    [
                        363.0164823671255,
                        204.53327411505444
                    ],
                    [
                        363.7359390785107,
                        203.30420220743213
                    ],
                    [
                        361.68998405550906,
                        200.84375624283257
                    ],
                    [
                        360.4534178328156,
                        200.328909567128
                    ],
                    [
                        361.8023991666628,
                        198.60430622092792
                    ],
                    [
                        362.8815842337408,
                        198.24575596133587
                    ],
                    [
                        364.1631165008955,
                        195.84625240869593
                    ],
                    [
                        367.06342636866725,
                        194.85867963423493
                    ],
                    [
                        367.73791703559095,
                        195.28519326969462
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Dima Hasao",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kamjong",
            "dt_code": "770",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        384.03810815291286,
                        213.87173407912496
                    ],
                    [
                        384.2404553529898,
                        212.49264908432622
                    ],
                    [
                        383.54348166383534,
                        209.9990012348939
                    ],
                    [
                        383.94817606398965,
                        209.08537215057964
                    ],
                    [
                        386.3763424649146,
                        208.12672689234802
                    ],
                    [
                        389.2991353549171,
                        207.34609395837174
                    ],
                    [
                        391.0977771333803,
                        206.0294762520095
                    ],
                    [
                        392.31186033384256,
                        206.0517990767994
                    ],
                    [
                        392.42427544499674,
                        207.30147712021056
                    ],
                    [
                        391.5924036224576,
                        209.68707722511004
                    ],
                    [
                        390.7830148221492,
                        210.51134240851565
                    ],
                    [
                        390.7380487776877,
                        211.84726919975043
                    ],
                    [
                        389.50148255499425,
                        212.5816507700439
                    ],
                    [
                        389.3441013993786,
                        213.69384055756348
                    ],
                    [
                        387.0058670873768,
                        216.09408516454317
                    ],
                    [
                        385.6344027312987,
                        214.36086056720256
                    ],
                    [
                        384.03810815291286,
                        213.87173407912496
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Kamjong",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Khasi Hills",
            "dt_code": "296",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        337.4982521351808,
                        194.47698248579837
                    ],
                    [
                        339.5442071581822,
                        194.0502906116634
                    ],
                    [
                        340.64587524749095,
                        196.1379390240507
                    ],
                    [
                        342.30961889256923,
                        194.20750384057538
                    ],
                    [
                        344.1307436932632,
                        195.06072411993205
                    ],
                    [
                        346.01931756064937,
                        194.67906682760236
                    ],
                    [
                        348.9645934728828,
                        195.03827577388836
                    ],
                    [
                        349.57163507311384,
                        196.94545796243835
                    ],
                    [
                        349.144457650729,
                        197.97679999935474
                    ],
                    [
                        345.3448268937259,
                        199.58998007338192
                    ],
                    [
                        345.4122759604181,
                        200.0602402020371
                    ],
                    [
                        344.98509853803307,
                        200.48561634919048
                    ],
                    [
                        341.9274075146459,
                        200.68707808871193
                    ],
                    [
                        339.8589694694135,
                        200.328909567128
                    ],
                    [
                        339.4093090247977,
                        201.40321896733911
                    ],
                    [
                        337.7455653797192,
                        202.40985056254692
                    ],
                    [
                        336.26168591248734,
                        202.18619917700693
                    ],
                    [
                        335.0251196897939,
                        202.90179419953387
                    ],
                    [
                        333.6761383559465,
                        201.73882010620457
                    ],
                    [
                        334.30566297840846,
                        199.65716701160977
                    ],
                    [
                        333.87848555602386,
                        198.91798366927844
                    ],
                    [
                        335.54222920110215,
                        198.02162857008324
                    ],
                    [
                        337.13852377948797,
                        195.80137365201693
                    ],
                    [
                        336.5314821792567,
                        195.62184823875543
                    ],
                    [
                        337.4982521351808,
                        194.47698248579837
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-West Khasi Hills",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Peren",
            "dt_code": "271",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        373.69591792674987,
                        202.9241523551957
                    ],
                    [
                        373.49357072667294,
                        200.84375624283257
                    ],
                    [
                        371.6274798815175,
                        199.074803500421
                    ],
                    [
                        373.0663933042879,
                        197.61813429847217
                    ],
                    [
                        374.3254425492121,
                        196.92303141147397
                    ],
                    [
                        376.48381268336766,
                        197.32666976898452
                    ],
                    [
                        380.44082459598644,
                        196.9903102884008
                    ],
                    [
                        380.5532397071404,
                        198.80596179442637
                    ],
                    [
                        378.0351412172922,
                        199.14200814601628
                    ],
                    [
                        378.6196997952925,
                        200.3736842122609
                    ],
                    [
                        376.5962277945216,
                        202.85707712717016
                    ],
                    [
                        375.69690690529,
                        204.66730845741046
                    ],
                    [
                        374.16806139359664,
                        203.95235361647195
                    ],
                    [
                        373.69591792674987,
                        202.9241523551957
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Peren",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Senapati",
            "dt_code": "272",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        378.6196997952925,
                        200.3736842122609
                    ],
                    [
                        378.0351412172922,
                        199.14200814601628
                    ],
                    [
                        380.5532397071404,
                        198.80596179442637
                    ],
                    [
                        381.5874587297567,
                        198.6715270601573
                    ],
                    [
                        383.0713381969888,
                        199.4108036200027
                    ],
                    [
                        384.2854213974513,
                        198.9851937099886
                    ],
                    [
                        384.55521766422066,
                        199.8363209006764
                    ],
                    [
                        386.2189613092992,
                        199.7243516423776
                    ],
                    [
                        387.61290868760807,
                        199.1868099589674
                    ],
                    [
                        388.6696107324551,
                        198.0664561097849
                    ],
                    [
                        387.07331615406906,
                        201.2689624551254
                    ],
                    [
                        387.0508331318383,
                        202.09673150427045
                    ],
                    [
                        384.64514975314387,
                        205.0470231346652
                    ],
                    [
                        382.5542286856805,
                        205.136357181871
                    ],
                    [
                        381.45256059637177,
                        204.77899680690325
                    ],
                    [
                        381.07034921844865,
                        203.1477199638639
                    ],
                    [
                        381.3176624629871,
                        201.80593345658457
                    ],
                    [
                        380.3284094848327,
                        201.47034378172208
                    ],
                    [
                        378.6196997952925,
                        200.3736842122609
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Senapati",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "East Jaintia Hills",
            "dt_code": "714",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        360.4534178328156,
                        200.328909567128
                    ],
                    [
                        361.68998405550906,
                        200.84375624283257
                    ],
                    [
                        363.7359390785107,
                        203.30420220743213
                    ],
                    [
                        363.0164823671255,
                        204.53327411505444
                    ],
                    [
                        361.8473652111245,
                        205.02468899318632
                    ],
                    [
                        361.2178405886625,
                        206.0294762520095
                    ],
                    [
                        360.02624041043055,
                        206.2973335838446
                    ],
                    [
                        359.1494025434299,
                        206.14108786558035
                    ],
                    [
                        358.3400137431215,
                        207.41301734077405
                    ],
                    [
                        355.0125264529647,
                        205.87320944735495
                    ],
                    [
                        355.1024585418879,
                        204.22049198502236
                    ],
                    [
                        354.6527980972721,
                        202.14146584925368
                    ],
                    [
                        356.046745475581,
                        201.47034378172208
                    ],
                    [
                        357.5755909872746,
                        201.31371564580792
                    ],
                    [
                        360.4534178328156,
                        200.328909567128
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-East Jaintia Hills",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "East Khasi Hills",
            "dt_code": "298",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        349.57163507311384,
                        196.94545796243835
                    ],
                    [
                        349.6390841398063,
                        197.416355810887
                    ],
                    [
                        354.02327347481037,
                        197.48361763046006
                    ],
                    [
                        353.48368094127113,
                        200.57515745618628
                    ],
                    [
                        353.7309941858098,
                        202.9241523551957
                    ],
                    [
                        351.90986938511605,
                        203.32655580015336
                    ],
                    [
                        352.3370468075009,
                        204.82367038178552
                    ],
                    [
                        348.35755187265136,
                        205.11402404783905
                    ],
                    [
                        346.82870636095777,
                        205.87320944735495
                    ],
                    [
                        346.2441477829575,
                        205.06935702425443
                    ],
                    [
                        344.6253701823405,
                        205.71693033369343
                    ],
                    [
                        342.9616265372622,
                        205.02468899318632
                    ],
                    [
                        342.6018981815696,
                        204.10877208671786
                    ],
                    [
                        345.4347589826489,
                        203.2371399091628
                    ],
                    [
                        345.09751364918725,
                        202.2309314876798
                    ],
                    [
                        345.4122759604181,
                        200.0602402020371
                    ],
                    [
                        345.3448268937259,
                        199.58998007338192
                    ],
                    [
                        349.144457650729,
                        197.97679999935474
                    ],
                    [
                        349.57163507311384,
                        196.94545796243835
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-East Khasi Hills",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tamenglong",
            "dt_code": "273",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        370.4133966810548,
                        208.34970873749205
                    ],
                    [
                        371.8523101038252,
                        205.8955340300563
                    ],
                    [
                        372.3919026373644,
                        203.99704587168753
                    ],
                    [
                        373.69591792674987,
                        202.9241523551957
                    ],
                    [
                        374.16806139359664,
                        203.95235361647195
                    ],
                    [
                        375.69690690529,
                        204.66730845741046
                    ],
                    [
                        376.5962277945216,
                        202.85707712717016
                    ],
                    [
                        378.6196997952925,
                        200.3736842122609
                    ],
                    [
                        380.3284094848327,
                        201.47034378172208
                    ],
                    [
                        380.0586132180631,
                        203.48302385728886
                    ],
                    [
                        378.1475563284464,
                        205.40433515395307
                    ],
                    [
                        378.7995639731389,
                        207.5245513131003
                    ],
                    [
                        377.945209128369,
                        209.39738996950808
                    ],
                    [
                        377.92272610613827,
                        211.26847667634127
                    ],
                    [
                        372.7741140152875,
                        210.73405861060803
                    ],
                    [
                        370.88554014790134,
                        213.4269709005917
                    ],
                    [
                        369.941253214208,
                        212.80413778964464
                    ],
                    [
                        369.356694636208,
                        211.17941687564286
                    ],
                    [
                        370.43587970328554,
                        209.28596062335555
                    ],
                    [
                        370.4133966810548,
                        208.34970873749205
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Tamenglong",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "South Garo Hills",
            "dt_code": "295",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        333.87848555602386,
                        198.91798366927844
                    ],
                    [
                        334.30566297840846,
                        199.65716701160977
                    ],
                    [
                        333.6761383559465,
                        201.73882010620457
                    ],
                    [
                        335.0251196897939,
                        202.90179419953387
                    ],
                    [
                        336.7787954237954,
                        203.88531333760847
                    ],
                    [
                        336.9361765794108,
                        205.0916906619729
                    ],
                    [
                        335.2499499121018,
                        205.3150131903286
                    ],
                    [
                        334.148281822793,
                        204.91301450679902
                    ],
                    [
                        332.8667495556381,
                        205.3150131903286
                    ],
                    [
                        330.19126991017447,
                        205.15869006408747
                    ],
                    [
                        329.2244999542504,
                        205.5606389046763
                    ],
                    [
                        327.5607563091721,
                        204.77899680690325
                    ],
                    [
                        326.12184288640174,
                        204.60029242140507
                    ],
                    [
                        326.5265372865558,
                        202.6781986877915
                    ],
                    [
                        327.2235109757105,
                        201.96252236444724
                    ],
                    [
                        327.3808921313257,
                        200.35129701759593
                    ],
                    [
                        329.1795339097889,
                        200.4184578342552
                    ],
                    [
                        332.1472928442531,
                        201.13469676161787
                    ],
                    [
                        333.87848555602386,
                        198.91798366927844
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-South Garo Hills",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Cachar",
            "dt_code": "316",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        365.2647845902043,
                        217.75925407821552
                    ],
                    [
                        363.84835418966463,
                        217.29314401206744
                    ],
                    [
                        363.2862786338949,
                        215.7831025955789
                    ],
                    [
                        361.91481427781696,
                        214.51646692505085
                    ],
                    [
                        361.33025569981623,
                        210.24405031093966
                    ],
                    [
                        360.61079898843127,
                        209.9767225597518
                    ],
                    [
                        359.6665120547382,
                        209.84304530415403
                    ],
                    [
                        358.3400137431215,
                        207.41301734077405
                    ],
                    [
                        359.1494025434299,
                        206.14108786558035
                    ],
                    [
                        360.02624041043055,
                        206.2973335838446
                    ],
                    [
                        362.7017200558944,
                        206.07412165053262
                    ],
                    [
                        364.36546370097267,
                        207.0560666298129
                    ],
                    [
                        365.287267612435,
                        206.60978815135434
                    ],
                    [
                        366.995977301975,
                        206.8998805551363
                    ],
                    [
                        367.69295099112946,
                        207.8591157637033
                    ],
                    [
                        369.4241437029002,
                        207.88141806323574
                    ],
                    [
                        370.4133966810548,
                        208.34970873749205
                    ],
                    [
                        370.43587970328554,
                        209.28596062335555
                    ],
                    [
                        369.356694636208,
                        211.17941687564286
                    ],
                    [
                        368.3899246802839,
                        210.95675009354903
                    ],
                    [
                        368.05267934682206,
                        212.60390057724038
                    ],
                    [
                        368.0751623690528,
                        214.82764376546936
                    ],
                    [
                        367.5130868132833,
                        215.27209921642333
                    ],
                    [
                        366.92852823528256,
                        217.40413226614857
                    ],
                    [
                        365.2647845902043,
                        217.75925407821552
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Cachar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Imphal West",
            "dt_code": "277",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        380.2384773959095,
                        209.35281897605074
                    ],
                    [
                        380.6881378405251,
                        210.6449750970949
                    ],
                    [
                        380.6431717960636,
                        212.4481467670526
                    ],
                    [
                        379.85626601798594,
                        214.11631211050508
                    ],
                    [
                        380.21599437367877,
                        215.1165508941275
                    ],
                    [
                        379.5639867289856,
                        215.56094302507728
                    ],
                    [
                        378.64218281752323,
                        214.0940789664739
                    ],
                    [
                        378.1025902839847,
                        212.2033664445533
                    ],
                    [
                        378.64218281752323,
                        210.0881134576826
                    ],
                    [
                        379.6988848623705,
                        208.10442733676877
                    ],
                    [
                        380.2384773959095,
                        209.35281897605074
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Imphal West",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Karimganj",
            "dt_code": "317",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        359.6665120547382,
                        209.84304530415403
                    ],
                    [
                        360.61079898843127,
                        209.9767225597518
                    ],
                    [
                        359.53161392135326,
                        213.33800651037507
                    ],
                    [
                        359.576579965815,
                        216.71590758284384
                    ],
                    [
                        358.90208929889127,
                        219.2234790014403
                    ],
                    [
                        358.2950476986598,
                        220.02170568994188
                    ],
                    [
                        356.42895685350436,
                        220.11037835966232
                    ],
                    [
                        355.3048057419651,
                        219.44523983538272
                    ],
                    [
                        355.9118473421963,
                        218.26963341153646
                    ],
                    [
                        355.23735667527285,
                        215.84974572626857
                    ],
                    [
                        355.82191525327335,
                        213.11557837840127
                    ],
                    [
                        356.42895685350436,
                        212.15885773427692
                    ],
                    [
                        355.52963596427276,
                        209.53109699146876
                    ],
                    [
                        356.47392289796585,
                        209.2636740090482
                    ],
                    [
                        357.755455165121,
                        210.3108766766228
                    ],
                    [
                        359.6665120547382,
                        209.84304530415403
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Karimganj",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hailakandi",
            "dt_code": "318",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        363.2862786338949,
                        215.7831025955789
                    ],
                    [
                        362.2295765890476,
                        218.358382012636
                    ],
                    [
                        361.28528965535475,
                        218.71333787437902
                    ],
                    [
                        361.0829424552776,
                        219.93302918637352
                    ],
                    [
                        360.1836215660462,
                        220.06604250396128
                    ],
                    [
                        359.9138252992768,
                        221.196307865403
                    ],
                    [
                        358.49739489873696,
                        221.572924919763
                    ],
                    [
                        358.2950476986598,
                        220.02170568994188
                    ],
                    [
                        358.90208929889127,
                        219.2234790014403
                    ],
                    [
                        359.576579965815,
                        216.71590758284384
                    ],
                    [
                        359.53161392135326,
                        213.33800651037507
                    ],
                    [
                        360.61079898843127,
                        209.9767225597518
                    ],
                    [
                        361.33025569981623,
                        210.24405031093966
                    ],
                    [
                        361.91481427781696,
                        214.51646692505085
                    ],
                    [
                        363.2862786338949,
                        215.7831025955789
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Hailakandi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kakching",
            "dt_code": "768",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        379.5639867289856,
                        215.56094302507728
                    ],
                    [
                        380.21599437367877,
                        215.1165508941275
                    ],
                    [
                        381.83477197429534,
                        215.67202584968862
                    ],
                    [
                        382.05960219660346,
                        216.82692729933535
                    ],
                    [
                        381.25021339629507,
                        216.22734883719738
                    ],
                    [
                        379.90123206244766,
                        218.40275486733248
                    ],
                    [
                        380.0586132180631,
                        219.9551986717517
                    ],
                    [
                        379.4740546400624,
                        220.70881868876097
                    ],
                    [
                        379.58646975121655,
                        219.66697666109485
                    ],
                    [
                        379.54150370675484,
                        219.40088958982778
                    ],
                    [
                        379.5190206845241,
                        219.33436242056325
                    ],
                    [
                        379.11432628437,
                        217.18214971226251
                    ],
                    [
                        379.5639867289856,
                        215.56094302507728
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Kakching",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bishnupur",
            "dt_code": "275",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        378.1025902839847,
                        212.2033664445533
                    ],
                    [
                        378.64218281752323,
                        214.0940789664739
                    ],
                    [
                        379.5639867289856,
                        215.56094302507728
                    ],
                    [
                        379.11432628437,
                        217.18214971226251
                    ],
                    [
                        379.5190206845241,
                        219.33436242056325
                    ],
                    [
                        378.73211490644644,
                        217.7370607766037
                    ],
                    [
                        377.04588823913764,
                        217.20434905598972
                    ],
                    [
                        377.51803170598396,
                        216.22734883719738
                    ],
                    [
                        377.5854807726764,
                        214.0940789664739
                    ],
                    [
                        377.63044681713814,
                        213.98290957939838
                    ],
                    [
                        378.1025902839847,
                        212.2033664445533
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Bishnupur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chandel",
            "dt_code": "280",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        379.4740546400624,
                        220.70881868876097
                    ],
                    [
                        380.0586132180631,
                        219.9551986717517
                    ],
                    [
                        379.90123206244766,
                        218.40275486733248
                    ],
                    [
                        381.25021339629507,
                        216.22734883719738
                    ],
                    [
                        382.05960219660346,
                        216.82692729933535
                    ],
                    [
                        383.65589677498906,
                        217.89240881806478
                    ],
                    [
                        383.7458288639125,
                        219.20130159690171
                    ],
                    [
                        384.60018370868215,
                        220.11037835966232
                    ],
                    [
                        384.3753534863745,
                        221.90517687572276
                    ],
                    [
                        385.2072253089136,
                        222.21523036709993
                    ],
                    [
                        384.9823950866057,
                        223.47711025208213
                    ],
                    [
                        383.99314210845114,
                        225.20264701390266
                    ],
                    [
                        383.790794908374,
                        226.5069087311166
                    ],
                    [
                        381.74483988537213,
                        225.22475996218492
                    ],
                    [
                        379.9237150846784,
                        224.80457366734862
                    ],
                    [
                        378.6646658397542,
                        225.24687267504459
                    ],
                    [
                        377.6754128615994,
                        223.96394562796934
                    ],
                    [
                        378.2599714396001,
                        221.8608797114597
                    ],
                    [
                        379.4740546400624,
                        220.70881868876097
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Chandel",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Churachandpur",
            "dt_code": "274",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        379.4740546400624,
                        220.70881868876097
                    ],
                    [
                        378.2599714396001,
                        221.8608797114597
                    ],
                    [
                        377.6754128615994,
                        223.96394562796934
                    ],
                    [
                        375.9667031720596,
                        223.87543862230092
                    ],
                    [
                        374.21302743805813,
                        224.98150457894116
                    ],
                    [
                        372.90901214867245,
                        222.9016115585212
                    ],
                    [
                        372.3469365929027,
                        222.63594269368355
                    ],
                    [
                        372.59424983744134,
                        221.06336773574913
                    ],
                    [
                        373.8308160601348,
                        220.04387421675023
                    ],
                    [
                        374.010680237981,
                        218.15869223672456
                    ],
                    [
                        372.1670724150565,
                        217.87021696488313
                    ],
                    [
                        372.50431774851836,
                        214.8943182974283
                    ],
                    [
                        373.381155615519,
                        215.33875913063395
                    ],
                    [
                        375.1348313495205,
                        214.49423817777915
                    ],
                    [
                        377.5854807726764,
                        214.0940789664739
                    ],
                    [
                        377.51803170598396,
                        216.22734883719738
                    ],
                    [
                        377.04588823913764,
                        217.20434905598972
                    ],
                    [
                        378.73211490644644,
                        217.7370607766037
                    ],
                    [
                        379.5190206845241,
                        219.33436242056325
                    ],
                    [
                        379.54150370675484,
                        219.40088958982778
                    ],
                    [
                        379.58646975121655,
                        219.66697666109485
                    ],
                    [
                        379.4740546400624,
                        220.70881868876097
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Churachandpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "North Tripura",
            "dt_code": "292",
            "st_nm": "Tripura",
            "st_code": "16",
            "alpha2code": "TR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        356.42895685350436,
                        220.11037835966232
                    ],
                    [
                        356.9460663648126,
                        221.10768206720206
                    ],
                    [
                        356.67627009804323,
                        223.2115153828487
                    ],
                    [
                        356.8561342758894,
                        225.44587649969725
                    ],
                    [
                        355.86688129773506,
                        227.0593156758572
                    ],
                    [
                        355.9792964088888,
                        228.45073327985256
                    ],
                    [
                        354.67528111950287,
                        228.75981153538788
                    ],
                    [
                        354.360518808272,
                        226.44061005861602
                    ],
                    [
                        353.3262997856557,
                        224.22944395174773
                    ],
                    [
                        354.1806546304256,
                        223.41071473313826
                    ],
                    [
                        355.0799755196572,
                        221.12983887481346
                    ],
                    [
                        353.101469563348,
                        219.6891490239051
                    ],
                    [
                        353.12395258557876,
                        217.98117381755134
                    ],
                    [
                        354.1356885859641,
                        217.20434905598972
                    ],
                    [
                        354.38300183050274,
                        215.58316007640576
                    ],
                    [
                        355.23735667527285,
                        215.84974572626857
                    ],
                    [
                        355.9118473421963,
                        218.26963341153646
                    ],
                    [
                        355.3048057419651,
                        219.44523983538272
                    ],
                    [
                        356.42895685350436,
                        220.11037835966232
                    ]
                ]
            ]
        },
        "id": "TT-Tripura-North Tripura",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dhalai",
            "dt_code": "291",
            "st_nm": "Tripura",
            "st_code": "16",
            "alpha2code": "TR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        353.3262997856557,
                        224.22944395174773
                    ],
                    [
                        354.360518808272,
                        226.44061005861602
                    ],
                    [
                        354.67528111950287,
                        228.75981153538788
                    ],
                    [
                        353.84340929696396,
                        228.36241682668714
                    ],
                    [
                        352.9665714299631,
                        229.7970982469957
                    ],
                    [
                        351.1679296515001,
                        229.04677195135702
                    ],
                    [
                        351.7075221850389,
                        232.3990977766784
                    ],
                    [
                        351.23537871819235,
                        233.25837603553828
                    ],
                    [
                        350.1112276066531,
                        232.94995730561402
                    ],
                    [
                        349.21190671742147,
                        231.8480946854454
                    ],
                    [
                        348.67231418388246,
                        229.06884420245814
                    ],
                    [
                        348.9196274284211,
                        226.97094039788783
                    ],
                    [
                        348.38003489488233,
                        224.14094829155437
                    ],
                    [
                        348.4924500060363,
                        221.77228252524048
                    ],
                    [
                        348.35755187265136,
                        220.11037835966232
                    ],
                    [
                        350.02129551772964,
                        221.10768206720206
                    ],
                    [
                        350.96558245142296,
                        222.30380851441726
                    ],
                    [
                        351.64007311834644,
                        222.83519754693287
                    ],
                    [
                        353.16891863004,
                        222.5252372411743
                    ],
                    [
                        353.3262997856557,
                        224.22944395174773
                    ]
                ]
            ]
        },
        "id": "TT-Tripura-Dhalai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sipahijala",
            "dt_code": "707",
            "st_nm": "Tripura",
            "st_code": "16",
            "alpha2code": "TR",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        343.56866813749366,
                        234.09528645551427
                    ],
                    [
                        342.08478867026133,
                        235.6581046184242
                    ],
                    [
                        341.14050173656824,
                        232.3109469365628
                    ],
                    [
                        339.7465543582596,
                        230.56921311091634
                    ],
                    [
                        339.6791052915671,
                        228.20785406588323
                    ],
                    [
                        340.7133243141834,
                        228.36241682668714
                    ],
                    [
                        343.0965246706469,
                        227.72201102286675
                    ],
                    [
                        344.3555739155713,
                        228.119527361905
                    ],
                    [
                        345.6820722271875,
                        227.4790471468616
                    ],
                    [
                        345.74952129388,
                        227.92077863957758
                    ],
                    [
                        343.2314228040316,
                        230.72360208014402
                    ],
                    [
                        342.9166604928007,
                        232.9719887045366
                    ],
                    [
                        343.56866813749366,
                        234.09528645551427
                    ]
                ]
            ]
        },
        "id": "TT-Tripura-Sipahijala",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gomati",
            "dt_code": "709",
            "st_nm": "Tripura",
            "st_code": "16",
            "alpha2code": "TR",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        348.67231418388246,
                        229.06884420245814
                    ],
                    [
                        349.21190671742147,
                        231.8480946854454
                    ],
                    [
                        349.70653320649876,
                        234.16134427435645
                    ],
                    [
                        348.53741605049777,
                        235.83412491994062
                    ],
                    [
                        346.31159684964996,
                        233.63282415146728
                    ],
                    [
                        343.56866813749366,
                        234.09528645551427
                    ],
                    [
                        342.9166604928007,
                        232.9719887045366
                    ],
                    [
                        343.2314228040316,
                        230.72360208014402
                    ],
                    [
                        345.74952129388,
                        227.92077863957758
                    ],
                    [
                        347.9303744502665,
                        227.7882689952736
                    ],
                    [
                        348.67231418388246,
                        229.06884420245814
                    ]
                ]
            ]
        },
        "id": "TT-Tripura-Gomati",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Wokha",
            "dt_code": "264",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        383.90321001952816,
                        185.85741899107563
                    ],
                    [
                        384.19548930852807,
                        184.14236794298233
                    ],
                    [
                        385.67936877576017,
                        182.4031625484456
                    ],
                    [
                        386.1065461981452,
                        184.052059043273
                    ],
                    [
                        386.8709689539919,
                        186.10552158375248
                    ],
                    [
                        386.9609010429151,
                        188.4045688859024
                    ],
                    [
                        387.18573126522324,
                        189.71063518150726
                    ],
                    [
                        386.75855384283796,
                        191.26324383378682
                    ],
                    [
                        385.54447064237525,
                        191.5331345073826
                    ],
                    [
                        381.52000966306423,
                        191.75801444433387
                    ],
                    [
                        380.8230359739098,
                        192.11776763738686
                    ],
                    [
                        381.4975266408335,
                        188.78747392112888
                    ],
                    [
                        383.0263721525273,
                        186.37614199197338
                    ],
                    [
                        383.90321001952816,
                        185.85741899107563
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Wokha",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Zunheboto",
            "dt_code": "263",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        386.9609010429151,
                        188.4045688859024
                    ],
                    [
                        390.063558110764,
                        188.02158700415328
                    ],
                    [
                        391.862199889227,
                        188.78747392112888
                    ],
                    [
                        391.862199889227,
                        190.8133417181943
                    ],
                    [
                        391.322607355688,
                        191.28573617187453
                    ],
                    [
                        389.86121091068685,
                        193.42130968938255
                    ],
                    [
                        390.4682525109181,
                        194.58925417113375
                    ],
                    [
                        391.97461500038094,
                        195.55452192297844
                    ],
                    [
                        390.4232864664564,
                        195.2403015208393
                    ],
                    [
                        388.19746726560834,
                        194.0053701966005
                    ],
                    [
                        385.49950459791376,
                        194.9709291740636
                    ],
                    [
                        384.64514975314387,
                        193.64596925873474
                    ],
                    [
                        385.6568857535294,
                        192.5898416400952
                    ],
                    [
                        385.54447064237525,
                        191.5331345073826
                    ],
                    [
                        386.75855384283796,
                        191.26324383378682
                    ],
                    [
                        387.18573126522324,
                        189.71063518150726
                    ],
                    [
                        386.9609010429151,
                        188.4045688859024
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Zunheboto",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Phek",
            "dt_code": "266",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        391.97461500038094,
                        195.55452192297844
                    ],
                    [
                        393.5259435343055,
                        195.46474986417815
                    ],
                    [
                        394.088019090075,
                        196.22767993229462
                    ],
                    [
                        394.47023046799836,
                        198.357809996968
                    ],
                    [
                        393.36856237868983,
                        199.88110681022363
                    ],
                    [
                        391.4125394446112,
                        200.6646944733265
                    ],
                    [
                        389.63638068837895,
                        199.70195702180837
                    ],
                    [
                        389.59141464391746,
                        198.4698575957417
                    ],
                    [
                        388.6696107324551,
                        198.0664561097849
                    ],
                    [
                        387.61290868760807,
                        199.1868099589674
                    ],
                    [
                        386.2189613092992,
                        199.7243516423776
                    ],
                    [
                        384.55521766422066,
                        199.8363209006764
                    ],
                    [
                        384.2854213974513,
                        198.9851937099886
                    ],
                    [
                        384.6226667309131,
                        196.96788425474108
                    ],
                    [
                        385.49950459791376,
                        194.9709291740636
                    ],
                    [
                        388.19746726560834,
                        194.0053701966005
                    ],
                    [
                        390.4232864664564,
                        195.2403015208393
                    ],
                    [
                        391.97461500038094,
                        195.55452192297844
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Phek",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Garo Hills",
            "dt_code": "293",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        324.4805822635542,
                        192.27513850395394
                    ],
                    [
                        325.42486919724706,
                        192.5224095842063
                    ],
                    [
                        329.06711879863497,
                        191.75801444433387
                    ],
                    [
                        328.30269604278806,
                        193.758289237954
                    ],
                    [
                        328.5724923095577,
                        195.55452192297844
                    ],
                    [
                        328.7523564874041,
                        197.1921329555234
                    ],
                    [
                        328.415111153942,
                        199.00759654297138
                    ],
                    [
                        327.3808921313257,
                        200.35129701759593
                    ],
                    [
                        327.2235109757105,
                        201.96252236444724
                    ],
                    [
                        326.5265372865558,
                        202.6781986877915
                    ],
                    [
                        326.12184288640174,
                        204.60029242140507
                    ],
                    [
                        324.32320110793876,
                        204.26517817618077
                    ],
                    [
                        322.29972910716765,
                        203.03593932905312
                    ],
                    [
                        323.199049996399,
                        202.29802804698835
                    ],
                    [
                        324.90775968593925,
                        201.80593345658457
                    ],
                    [
                        325.2000389749394,
                        199.052401438169
                    ],
                    [
                        322.97421977409135,
                        198.02162857008324
                    ],
                    [
                        321.1980610178591,
                        196.07063061984258
                    ],
                    [
                        323.55877835209185,
                        193.01685651913726
                    ],
                    [
                        324.4805822635542,
                        192.27513850395394
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-West Garo Hills",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "East Garo Hills",
            "dt_code": "294",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        336.5314821792567,
                        195.62184823875543
                    ],
                    [
                        337.13852377948797,
                        195.80137365201693
                    ],
                    [
                        335.54222920110215,
                        198.02162857008324
                    ],
                    [
                        333.87848555602386,
                        198.91798366927844
                    ],
                    [
                        332.1472928442531,
                        201.13469676161787
                    ],
                    [
                        329.1795339097889,
                        200.4184578342552
                    ],
                    [
                        327.3808921313257,
                        200.35129701759593
                    ],
                    [
                        328.415111153942,
                        199.00759654297138
                    ],
                    [
                        328.7523564874041,
                        197.1921329555234
                    ],
                    [
                        328.5724923095577,
                        195.55452192297844
                    ],
                    [
                        330.0338887545588,
                        196.20524509418044
                    ],
                    [
                        330.663413377021,
                        195.17296194676436
                    ],
                    [
                        331.85501355525275,
                        195.91356859685237
                    ],
                    [
                        332.6644023555614,
                        195.15051490178593
                    ],
                    [
                        334.91270457864016,
                        194.56680035544105
                    ],
                    [
                        336.5314821792567,
                        195.62184823875543
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-East Garo Hills",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kohima",
            "dt_code": "270",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        380.5532397071404,
                        198.80596179442637
                    ],
                    [
                        380.44082459598644,
                        196.9903102884008
                    ],
                    [
                        380.9579341072947,
                        196.6090325279107
                    ],
                    [
                        381.74483988537213,
                        194.14012830868222
                    ],
                    [
                        381.16028130737186,
                        193.53364274333688
                    ],
                    [
                        381.52000966306423,
                        191.75801444433387
                    ],
                    [
                        385.54447064237525,
                        191.5331345073826
                    ],
                    [
                        385.6568857535294,
                        192.5898416400952
                    ],
                    [
                        384.64514975314387,
                        193.64596925873474
                    ],
                    [
                        385.49950459791376,
                        194.9709291740636
                    ],
                    [
                        384.6226667309131,
                        196.96788425474108
                    ],
                    [
                        384.2854213974513,
                        198.9851937099886
                    ],
                    [
                        383.0713381969888,
                        199.4108036200027
                    ],
                    [
                        381.5874587297567,
                        198.6715270601573
                    ],
                    [
                        380.5532397071404,
                        198.80596179442637
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Kohima",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dimapur",
            "dt_code": "265",
            "st_nm": "Nagaland",
            "st_code": "13",
            "alpha2code": "NL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        378.19252237290766,
                        192.11776763738686
                    ],
                    [
                        378.2150053951384,
                        194.07275042752798
                    ],
                    [
                        379.743850906832,
                        194.16258708031688
                    ],
                    [
                        381.1827643296026,
                        192.97191204042917
                    ],
                    [
                        380.8230359739098,
                        192.11776763738686
                    ],
                    [
                        381.52000966306423,
                        191.75801444433387
                    ],
                    [
                        381.16028130737186,
                        193.53364274333688
                    ],
                    [
                        381.74483988537213,
                        194.14012830868222
                    ],
                    [
                        380.9579341072947,
                        196.6090325279107
                    ],
                    [
                        380.44082459598644,
                        196.9903102884008
                    ],
                    [
                        376.48381268336766,
                        197.32666976898452
                    ],
                    [
                        374.3254425492121,
                        196.92303141147397
                    ],
                    [
                        374.77510299382766,
                        196.00331988104625
                    ],
                    [
                        377.1133373058299,
                        194.02783053469386
                    ],
                    [
                        376.79857499459854,
                        193.1741539432444
                    ],
                    [
                        378.19252237290766,
                        192.11776763738686
                    ]
                ]
            ]
        },
        "id": "TT-Nagaland-Dimapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "East Siang",
            "dt_code": "251",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        403.82316771600654,
                        160.53601055146703
                    ],
                    [
                        403.7557186493141,
                        160.60461157100235
                    ],
                    [
                        401.77721269300446,
                        161.29047818221875
                    ],
                    [
                        400.7429936703884,
                        160.81039895812654
                    ],
                    [
                        398.20241215830924,
                        161.67902018260446
                    ],
                    [
                        397.1906761579239,
                        158.22491462969964
                    ],
                    [
                        399.73125767000306,
                        157.81272360434923
                    ],
                    [
                        400.54064647031146,
                        156.5984987343606
                    ],
                    [
                        399.55139349215665,
                        156.00253964386997
                    ],
                    [
                        401.21513713723516,
                        154.53471478075954
                    ],
                    [
                        401.8221787374662,
                        153.41009291646344
                    ],
                    [
                        403.0362619379289,
                        154.3052578627541
                    ],
                    [
                        404.4751753606995,
                        154.35115161323165
                    ],
                    [
                        404.4302093162378,
                        157.74401588121646
                    ],
                    [
                        403.55337144923715,
                        159.43803851569697
                    ],
                    [
                        403.82316771600654,
                        160.53601055146703
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-East Siang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lohit",
            "dt_code": "259",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        415.3794411426318,
                        152.44556430921898
                    ],
                    [
                        415.24454300924685,
                        154.44293556353824
                    ],
                    [
                        415.82910158724735,
                        155.72741450017486
                    ],
                    [
                        415.1321278980929,
                        157.58368761059052
                    ],
                    [
                        417.0207017654793,
                        159.87273257371726
                    ],
                    [
                        418.66196238832686,
                        160.26158033940476
                    ],
                    [
                        420.19080790002045,
                        161.74757773640283
                    ],
                    [
                        420.14584185555873,
                        163.84874810949867
                    ],
                    [
                        421.180060878175,
                        164.25956168684763
                    ],
                    [
                        420.32570603340514,
                        164.7843548504801
                    ],
                    [
                        416.36869412078636,
                        164.5105686472586
                    ],
                    [
                        415.46937323155475,
                        165.74228188449985
                    ],
                    [
                        414.7274334979388,
                        163.27801922075287
                    ],
                    [
                        414.90729767578523,
                        162.1360213886719
                    ],
                    [
                        413.96301074209214,
                        160.65034413272303
                    ],
                    [
                        411.2425650521668,
                        161.10760593024867
                    ],
                    [
                        407.57783242854816,
                        163.0496773077897
                    ],
                    [
                        410.45565927408893,
                        159.27786157224932
                    ],
                    [
                        411.93953874132126,
                        156.5984987343606
                    ],
                    [
                        414.0529428310151,
                        154.7641421253138
                    ],
                    [
                        415.3794411426318,
                        152.44556430921898
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Lohit",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kurung Kumey",
            "dt_code": "256",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        370.07615134759294,
                        152.9049290305806
                    ],
                    [
                        371.73989499267145,
                        152.72119741780205
                    ],
                    [
                        373.49357072667294,
                        154.0757713504595
                    ],
                    [
                        373.5610197933654,
                        156.71308346603894
                    ],
                    [
                        375.17979739398197,
                        157.65240148400216
                    ],
                    [
                        375.8767710831364,
                        159.36939300244546
                    ],
                    [
                        375.4945597052131,
                        161.3819073562314
                    ],
                    [
                        374.2804765047506,
                        162.88982083018118
                    ],
                    [
                        374.34792557144306,
                        164.41929740497386
                    ],
                    [
                        371.6274798815175,
                        164.0998119065743
                    ],
                    [
                        370.16608343651615,
                        165.1949568073179
                    ],
                    [
                        369.67145694743886,
                        164.00852000154208
                    ],
                    [
                        370.30098156990107,
                        161.907535239894
                    ],
                    [
                        369.3791776584387,
                        161.56475180687005
                    ],
                    [
                        366.478867790667,
                        161.54189726362836
                    ],
                    [
                        365.78189410151253,
                        159.8498565585209
                    ],
                    [
                        362.8815842337408,
                        159.00323929752477
                    ],
                    [
                        361.8248821888935,
                        157.49206501097768
                    ],
                    [
                        362.20709356681687,
                        156.50682565958732
                    ],
                    [
                        363.8708372118956,
                        155.24584337992928
                    ],
                    [
                        365.2647845902043,
                        155.19997267969188
                    ],
                    [
                        367.1533584575907,
                        153.54783994841722
                    ],
                    [
                        370.07615134759294,
                        152.9049290305806
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Kurung Kumey",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lower Subansiri",
            "dt_code": "255",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        380.6431717960636,
                        166.72249467022237
                    ],
                    [
                        379.31667348444716,
                        167.49717376987172
                    ],
                    [
                        379.0693602399085,
                        169.1821058445753
                    ],
                    [
                        376.57374477229087,
                        169.6144735262256
                    ],
                    [
                        375.606974816367,
                        169.11382794063474
                    ],
                    [
                        375.1348313495205,
                        167.38327098938493
                    ],
                    [
                        375.8767710831364,
                        166.38061986575707
                    ],
                    [
                        374.77510299382766,
                        164.8984202562649
                    ],
                    [
                        375.2697294829054,
                        164.96685606261622
                    ],
                    [
                        378.08010726175394,
                        163.095347994233
                    ],
                    [
                        378.08010726175394,
                        162.11317407409388
                    ],
                    [
                        379.8787490402169,
                        161.58760606071945
                    ],
                    [
                        380.62068877383285,
                        164.62465124819516
                    ],
                    [
                        382.26194939668017,
                        165.1265295893951
                    ],
                    [
                        382.5542286856805,
                        166.6541248377451
                    ],
                    [
                        380.6431717960636,
                        166.72249467022237
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Lower Subansiri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "East Kameng",
            "dt_code": "247",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        358.8571232544298,
                        162.22740775838446
                    ],
                    [
                        358.96953836558373,
                        161.67902018260446
                    ],
                    [
                        360.34100272166165,
                        161.58760606071945
                    ],
                    [
                        361.9372973000475,
                        160.28445112134392
                    ],
                    [
                        362.8815842337408,
                        159.00323929752477
                    ],
                    [
                        365.78189410151253,
                        159.8498565585209
                    ],
                    [
                        366.478867790667,
                        161.54189726362836
                    ],
                    [
                        369.3791776584387,
                        161.56475180687005
                    ],
                    [
                        370.30098156990107,
                        161.907535239894
                    ],
                    [
                        369.67145694743886,
                        164.00852000154208
                    ],
                    [
                        370.16608343651615,
                        165.1949568073179
                    ],
                    [
                        372.23452148174874,
                        168.31706427529485
                    ],
                    [
                        371.64996290374825,
                        169.3186540224981
                    ],
                    [
                        371.20030245913244,
                        168.56751307788642
                    ],
                    [
                        369.6489739252081,
                        168.93174109110487
                    ],
                    [
                        369.22179650282305,
                        170.02399102959714
                    ],
                    [
                        366.8161131241286,
                        171.0473854970026
                    ],
                    [
                        366.9734942797443,
                        172.81992250751534
                    ],
                    [
                        365.2423015679735,
                        172.95620097737043
                    ],
                    [
                        363.98325232304956,
                        172.25198723120485
                    ],
                    [
                        363.84835418966463,
                        170.88822821660284
                    ],
                    [
                        364.18559952312626,
                        168.56751307788642
                    ],
                    [
                        363.89332023412635,
                        167.77051146933468
                    ],
                    [
                        361.8023991666628,
                        167.20101176094283
                    ],
                    [
                        360.83562921073917,
                        164.73872668270548
                    ],
                    [
                        359.8913422770461,
                        164.7843548504801
                    ],
                    [
                        359.6665120547382,
                        163.02684153248373
                    ],
                    [
                        358.8571232544298,
                        162.22740775838446
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-East Kameng",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Kameng",
            "dt_code": "246",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        352.2920807630394,
                        167.08709050193585
                    ],
                    [
                        353.7309941858098,
                        166.49458525789288
                    ],
                    [
                        355.3497717864268,
                        164.80716850458504
                    ],
                    [
                        357.86787027627497,
                        164.0541665283901
                    ],
                    [
                        358.8571232544298,
                        162.22740775838446
                    ],
                    [
                        359.6665120547382,
                        163.02684153248373
                    ],
                    [
                        359.8913422770461,
                        164.7843548504801
                    ],
                    [
                        360.83562921073917,
                        164.73872668270548
                    ],
                    [
                        361.8023991666628,
                        167.20101176094283
                    ],
                    [
                        363.89332023412635,
                        167.77051146933468
                    ],
                    [
                        364.18559952312626,
                        168.56751307788642
                    ],
                    [
                        363.84835418966463,
                        170.88822821660284
                    ],
                    [
                        363.98325232304956,
                        172.25198723120485
                    ],
                    [
                        363.15138050050996,
                        171.9793161789815
                    ],
                    [
                        361.1279084997393,
                        172.81992250751534
                    ],
                    [
                        360.88059525520043,
                        174.29573774230573
                    ],
                    [
                        361.7349500999708,
                        175.04454773594563
                    ],
                    [
                        360.3634857438924,
                        175.88376330678574
                    ],
                    [
                        358.6547760543526,
                        175.86108679037966
                    ],
                    [
                        357.37324378719745,
                        176.4052468490122
                    ],
                    [
                        354.9450773862727,
                        176.99457401460182
                    ],
                    [
                        353.75347720804075,
                        176.949247778193
                    ],
                    [
                        353.6185790746558,
                        175.0672342275334
                    ],
                    [
                        352.58436005203976,
                        174.1141624638558
                    ],
                    [
                        352.44946191865483,
                        172.5019002869775
                    ],
                    [
                        353.12395258557876,
                        170.61535511470396
                    ],
                    [
                        352.89912236327086,
                        168.56751307788642
                    ],
                    [
                        352.2920807630394,
                        167.08709050193585
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-West Kameng",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Jaintia Hills",
            "dt_code": "299",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        354.02327347481037,
                        197.48361763046006
                    ],
                    [
                        354.3155527638105,
                        196.96788425474108
                    ],
                    [
                        355.3947378308883,
                        196.25011451114244
                    ],
                    [
                        358.6098100098909,
                        196.18280999678103
                    ],
                    [
                        359.5091308991225,
                        197.86472406080117
                    ],
                    [
                        360.6782480551235,
                        198.94038727316092
                    ],
                    [
                        361.8023991666628,
                        198.60430622092792
                    ],
                    [
                        360.4534178328156,
                        200.328909567128
                    ],
                    [
                        357.5755909872746,
                        201.31371564580792
                    ],
                    [
                        356.046745475581,
                        201.47034378172208
                    ],
                    [
                        354.6527980972721,
                        202.14146584925368
                    ],
                    [
                        355.1024585418879,
                        204.22049198502236
                    ],
                    [
                        355.0125264529647,
                        205.87320944735495
                    ],
                    [
                        353.281333741194,
                        204.91301450679902
                    ],
                    [
                        352.3370468075009,
                        204.82367038178552
                    ],
                    [
                        351.90986938511605,
                        203.32655580015336
                    ],
                    [
                        353.7309941858098,
                        202.9241523551957
                    ],
                    [
                        353.48368094127113,
                        200.57515745618628
                    ],
                    [
                        354.02327347481037,
                        197.48361763046006
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-West Jaintia Hills",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "South West Khasi Hills",
            "dt_code": "713",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        335.0251196897939,
                        202.90179419953387
                    ],
                    [
                        336.26168591248734,
                        202.18619917700693
                    ],
                    [
                        337.7455653797192,
                        202.40985056254692
                    ],
                    [
                        339.4093090247977,
                        201.40321896733911
                    ],
                    [
                        339.8589694694135,
                        200.328909567128
                    ],
                    [
                        341.9274075146459,
                        200.68707808871193
                    ],
                    [
                        344.98509853803307,
                        200.48561634919048
                    ],
                    [
                        345.4122759604181,
                        200.0602402020371
                    ],
                    [
                        345.09751364918725,
                        202.2309314876798
                    ],
                    [
                        345.4347589826489,
                        203.2371399091628
                    ],
                    [
                        342.6018981815696,
                        204.10877208671786
                    ],
                    [
                        341.09553569210675,
                        204.68964663164456
                    ],
                    [
                        338.57743720225835,
                        204.62263135230944
                    ],
                    [
                        336.9361765794108,
                        205.0916906619729
                    ],
                    [
                        336.7787954237954,
                        203.88531333760847
                    ],
                    [
                        335.0251196897939,
                        202.90179419953387
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-South West Khasi Hills",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "North Garo Hills",
            "dt_code": "712",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        337.76804840194995,
                        193.46624369574357
                    ],
                    [
                        337.4982521351808,
                        194.47698248579837
                    ],
                    [
                        336.5314821792567,
                        195.62184823875543
                    ],
                    [
                        334.91270457864016,
                        194.56680035544105
                    ],
                    [
                        332.6644023555614,
                        195.15051490178593
                    ],
                    [
                        331.85501355525275,
                        195.91356859685237
                    ],
                    [
                        330.663413377021,
                        195.17296194676436
                    ],
                    [
                        330.0338887545588,
                        196.20524509418044
                    ],
                    [
                        328.5724923095577,
                        195.55452192297844
                    ],
                    [
                        328.30269604278806,
                        193.758289237954
                    ],
                    [
                        329.06711879863497,
                        191.75801444433387
                    ],
                    [
                        331.9674286664067,
                        192.92696651364815
                    ],
                    [
                        333.2264779113307,
                        192.34257922153648
                    ],
                    [
                        336.62141426817993,
                        192.45497516672435
                    ],
                    [
                        337.76804840194995,
                        193.46624369574357
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-North Garo Hills",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "South West Garo Hills",
            "dt_code": "711",
            "st_nm": "Meghalaya",
            "st_code": "17",
            "alpha2code": "ML",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        322.97421977409135,
                        198.02162857008324
                    ],
                    [
                        325.2000389749394,
                        199.052401438169
                    ],
                    [
                        324.90775968593925,
                        201.80593345658457
                    ],
                    [
                        323.199049996399,
                        202.29802804698835
                    ],
                    [
                        322.29972910716765,
                        203.03593932905312
                    ],
                    [
                        320.253774084166,
                        203.0806523435325
                    ],
                    [
                        319.96149479516566,
                        201.58221337525544
                    ],
                    [
                        320.7933666177048,
                        200.14980075221837
                    ],
                    [
                        320.883298706628,
                        198.96279062005817
                    ],
                    [
                        322.97421977409135,
                        198.02162857008324
                    ]
                ]
            ]
        },
        "id": "TT-Meghalaya-South West Garo Hills",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Khowai",
            "dt_code": "708",
            "st_nm": "Tripura",
            "st_code": "16",
            "alpha2code": "TR",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        348.4924500060363,
                        221.77228252524048
                    ],
                    [
                        348.38003489488233,
                        224.14094829155437
                    ],
                    [
                        348.9196274284211,
                        226.97094039788783
                    ],
                    [
                        348.67231418388246,
                        229.06884420245814
                    ],
                    [
                        347.9303744502665,
                        227.7882689952736
                    ],
                    [
                        345.74952129388,
                        227.92077863957758
                    ],
                    [
                        345.6820722271875,
                        227.4790471468616
                    ],
                    [
                        345.47972502711036,
                        225.5122068728648
                    ],
                    [
                        344.28812484887885,
                        223.05656928123642
                    ],
                    [
                        346.6488421831116,
                        222.3923828573836
                    ],
                    [
                        347.0310535610349,
                        221.06336773574913
                    ],
                    [
                        348.4924500060363,
                        221.77228252524048
                    ]
                ]
            ]
        },
        "id": "TT-Tripura-Khowai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Tripura",
            "dt_code": "289",
            "st_nm": "Tripura",
            "st_code": "16",
            "alpha2code": "TR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        345.6820722271875,
                        227.4790471468616
                    ],
                    [
                        344.3555739155713,
                        228.119527361905
                    ],
                    [
                        343.0965246706469,
                        227.72201102286675
                    ],
                    [
                        340.7133243141834,
                        228.36241682668714
                    ],
                    [
                        341.0280866254143,
                        226.7941786076969
                    ],
                    [
                        340.60090920302946,
                        225.99856496253483
                    ],
                    [
                        341.79250938126097,
                        224.09669904377125
                    ],
                    [
                        342.871694448339,
                        224.03032339958867
                    ],
                    [
                        344.28812484887885,
                        223.05656928123642
                    ],
                    [
                        345.47972502711036,
                        225.5122068728648
                    ],
                    [
                        345.6820722271875,
                        227.4790471468616
                    ]
                ]
            ]
        },
        "id": "TT-Tripura-West Tripura",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "South Tripura",
            "dt_code": "290",
            "st_nm": "Tripura",
            "st_code": "16",
            "alpha2code": "TR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        342.08478867026133,
                        235.6581046184242
                    ],
                    [
                        343.56866813749366,
                        234.09528645551427
                    ],
                    [
                        346.31159684964996,
                        233.63282415146728
                    ],
                    [
                        348.53741605049777,
                        235.83412491994062
                    ],
                    [
                        349.3692878730369,
                        237.76939141161546
                    ],
                    [
                        349.2343897396522,
                        239.08788953404837
                    ],
                    [
                        347.975340494728,
                        240.29580298000235
                    ],
                    [
                        345.8619364050337,
                        240.64706892019518
                    ],
                    [
                        345.36730991595664,
                        240.09819075193013
                    ],
                    [
                        344.8502004046484,
                        237.1538147485146
                    ],
                    [
                        344.06329462657095,
                        235.92212962254501
                    ],
                    [
                        342.08478867026133,
                        235.6581046184242
                    ]
                ]
            ]
        },
        "id": "TT-Tripura-South Tripura",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Unokoti",
            "dt_code": "710",
            "st_nm": "Tripura",
            "st_code": "16",
            "alpha2code": "TR",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        353.12395258557876,
                        217.98117381755134
                    ],
                    [
                        353.101469563348,
                        219.6891490239051
                    ],
                    [
                        355.0799755196572,
                        221.12983887481346
                    ],
                    [
                        354.1806546304256,
                        223.41071473313826
                    ],
                    [
                        353.3262997856557,
                        224.22944395174773
                    ],
                    [
                        353.16891863004,
                        222.5252372411743
                    ],
                    [
                        351.64007311834644,
                        222.83519754693287
                    ],
                    [
                        350.96558245142296,
                        222.30380851441726
                    ],
                    [
                        351.23537871819235,
                        219.82217816381473
                    ],
                    [
                        350.943099429192,
                        218.60242078962796
                    ],
                    [
                        351.6625561405774,
                        217.89240881806478
                    ],
                    [
                        353.12395258557876,
                        217.98117381755134
                    ]
                ]
            ]
        },
        "id": "TT-Tripura-Unokoti",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Charaideo",
            "dt_code": "755",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2015_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        393.7732567788439,
                        173.66014632986042
                    ],
                    [
                        395.72927971292256,
                        171.47931445099704
                    ],
                    [
                        395.9765929574612,
                        172.3883076403149
                    ],
                    [
                        398.4497254028481,
                        173.13788996458675
                    ],
                    [
                        400.3607822924653,
                        171.91114211402777
                    ],
                    [
                        401.4624503817738,
                        172.4337455381941
                    ],
                    [
                        401.61983153738925,
                        173.36497607844342
                    ],
                    [
                        400.0010539367722,
                        174.68152612312417
                    ],
                    [
                        399.0792500253099,
                        174.6134516566417
                    ],
                    [
                        397.685302647001,
                        175.81573292732423
                    ],
                    [
                        395.9316269129997,
                        176.60926583074888
                    ],
                    [
                        395.0772720682296,
                        176.01981659475757
                    ],
                    [
                        392.2893773116118,
                        176.29189330186995
                    ],
                    [
                        393.68332468992094,
                        174.90842295916474
                    ],
                    [
                        393.7732567788439,
                        173.66014632986042
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Charaideo",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Majuli",
            "dt_code": "760",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        389.7712788217639,
                        172.18382324820897
                    ],
                    [
                        390.22093926637945,
                        172.77449411586622
                    ],
                    [
                        389.50148255499425,
                        173.9098689874411
                    ],
                    [
                        387.3206293986077,
                        175.99714173838458
                    ],
                    [
                        385.97164806476053,
                        176.67726718769262
                    ],
                    [
                        384.55521766422066,
                        176.38257669219524
                    ],
                    [
                        381.4975266408335,
                        177.37980250603113
                    ],
                    [
                        380.9579341072947,
                        176.4279167295623
                    ],
                    [
                        384.26293837522053,
                        175.11260637842145
                    ],
                    [
                        384.93742904214446,
                        174.00066887169754
                    ],
                    [
                        386.0840631759145,
                        174.00066887169754
                    ],
                    [
                        388.9394069992245,
                        173.1833094197301
                    ],
                    [
                        389.7712788217639,
                        172.18382324820897
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Majuli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Biswanath",
            "dt_code": "756",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2015_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        372.63921588190306,
                        175.88376330678574
                    ],
                    [
                        373.7184009489806,
                        176.22387785735907
                    ],
                    [
                        376.57374477229087,
                        175.7023434256688
                    ],
                    [
                        377.04588823913764,
                        175.2714001696047
                    ],
                    [
                        378.2599714396001,
                        177.06256130112206
                    ],
                    [
                        378.1025902839847,
                        177.74229778624596
                    ],
                    [
                        376.3264315277522,
                        178.67087067836383
                    ],
                    [
                        376.34891454998296,
                        180.02892710650917
                    ],
                    [
                        374.21302743805813,
                        179.19157545603173
                    ],
                    [
                        372.6167328596723,
                        179.28211801454836
                    ],
                    [
                        371.2902345480559,
                        180.16467860276208
                    ],
                    [
                        368.90703419159195,
                        181.86074444838772
                    ],
                    [
                        367.78288308005267,
                        179.35002205886548
                    ],
                    [
                        367.69295099112946,
                        176.4732556619427
                    ],
                    [
                        372.63921588190306,
                        175.88376330678574
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Biswanath",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hojai",
            "dt_code": "757",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2015_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        363.15138050050996,
                        190.7233486351173
                    ],
                    [
                        362.431923789125,
                        189.55305382999273
                    ],
                    [
                        363.8708372118956,
                        188.31446241899278
                    ],
                    [
                        365.89430921266626,
                        188.29193513735834
                    ],
                    [
                        365.78189410151253,
                        189.12526756338514
                    ],
                    [
                        368.3899246802839,
                        190.34083090612216
                    ],
                    [
                        369.4691097473619,
                        193.19662395629217
                    ],
                    [
                        370.3234645921318,
                        194.11766927601815
                    ],
                    [
                        370.12111739205466,
                        196.07063061984258
                    ],
                    [
                        368.8845511693612,
                        196.20524509418044
                    ],
                    [
                        367.73791703559095,
                        195.28519326969462
                    ],
                    [
                        366.52383383512847,
                        193.64596925873474
                    ],
                    [
                        364.8600901900502,
                        193.758289237954
                    ],
                    [
                        363.3087616561256,
                        192.25265773956704
                    ],
                    [
                        363.15138050050996,
                        190.7233486351173
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Hojai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "South Salmara Mankachar",
            "dt_code": "758",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2015_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        320.36618919532,
                        193.53364274333688
                    ],
                    [
                        323.8960236855537,
                        190.94832342765636
                    ],
                    [
                        325.31245408609334,
                        190.8133417181943
                    ],
                    [
                        324.4805822635542,
                        192.27513850395394
                    ],
                    [
                        323.55877835209185,
                        193.01685651913726
                    ],
                    [
                        321.1980610178591,
                        196.07063061984258
                    ],
                    [
                        322.97421977409135,
                        198.02162857008324
                    ],
                    [
                        320.883298706628,
                        198.96279062005817
                    ],
                    [
                        320.7933666177048,
                        200.14980075221837
                    ],
                    [
                        320.50108732870444,
                        200.328909567128
                    ],
                    [
                        320.95074777332024,
                        197.86472406080117
                    ],
                    [
                        320.1413589730121,
                        195.98088244922798
                    ],
                    [
                        320.36618919532,
                        193.53364274333688
                    ]
                ]
            ]
        },
        "id": "TT-Assam-South Salmara Mankachar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Karbi Anglong",
            "dt_code": "314",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        371.15533641467096,
                        182.17717399641492
                    ],
                    [
                        373.5160537489037,
                        182.15457364963203
                    ],
                    [
                        374.07812930467344,
                        181.5894767651336
                    ],
                    [
                        377.0908542835991,
                        181.65729735342507
                    ],
                    [
                        378.46231863967705,
                        184.39069518220964
                    ],
                    [
                        379.9237150846784,
                        185.51904499197417
                    ],
                    [
                        378.3499035285231,
                        190.7233486351173
                    ],
                    [
                        378.19252237290766,
                        192.11776763738686
                    ],
                    [
                        376.79857499459854,
                        193.1741539432444
                    ],
                    [
                        377.1133373058299,
                        194.02783053469386
                    ],
                    [
                        374.77510299382766,
                        196.00331988104625
                    ],
                    [
                        374.3254425492121,
                        196.92303141147397
                    ],
                    [
                        373.0663933042879,
                        197.61813429847217
                    ],
                    [
                        371.6274798815175,
                        199.074803500421
                    ],
                    [
                        369.85132112528527,
                        199.074803500421
                    ],
                    [
                        368.8845511693612,
                        196.20524509418044
                    ],
                    [
                        370.12111739205466,
                        196.07063061984258
                    ],
                    [
                        370.3234645921318,
                        194.11766927601815
                    ],
                    [
                        369.4691097473619,
                        193.19662395629217
                    ],
                    [
                        368.3899246802839,
                        190.34083090612216
                    ],
                    [
                        365.78189410151253,
                        189.12526756338514
                    ],
                    [
                        365.89430921266626,
                        188.29193513735834
                    ],
                    [
                        363.8708372118956,
                        188.31446241899278
                    ],
                    [
                        364.05070138974156,
                        187.30047113099954
                    ],
                    [
                        365.939275257128,
                        186.1957326708128
                    ],
                    [
                        366.9734942797443,
                        183.28425908401118
                    ],
                    [
                        369.6040078807464,
                        182.31277038144202
                    ],
                    [
                        371.15533641467096,
                        182.17717399641492
                    ]
                ]
            ]
        },
        "id": "TT-Assam-Karbi Anglong",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Karbi Anglong",
            "dt_code": "759",
            "st_nm": "Assam",
            "st_code": "18",
            "alpha2code": "AS",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        357.8903532985057,
                        189.75565604139712
                    ],
                    [
                        359.8688592548151,
                        189.1477850165018
                    ],
                    [
                        360.83562921073917,
                        190.29582380136418
                    ],
                    [
                        360.11617249935375,
                        192.07280216825586
                    ],
                    [
                        361.7124670777398,
                        191.60060125160982
                    ],
                    [
                        363.15138050050996,
                        190.7233486351173
                    ],
                    [
                        363.3087616561256,
                        192.25265773956704
                    ],
                    [
                        364.8600901900502,
                        193.758289237954
                    ],
                    [
                        366.52383383512847,
                        193.64596925873474
                    ],
                    [
                        367.73791703559095,
                        195.28519326969462
                    ],
                    [
                        367.06342636866725,
                        194.85867963423493
                    ],
                    [
                        364.1631165008955,
                        195.84625240869593
                    ],
                    [
                        362.8815842337408,
                        198.24575596133587
                    ],
                    [
                        361.8023991666628,
                        198.60430622092792
                    ],
                    [
                        360.6782480551235,
                        198.94038727316092
                    ],
                    [
                        359.5091308991225,
                        197.86472406080117
                    ],
                    [
                        358.6098100098909,
                        196.18280999678103
                    ],
                    [
                        355.3947378308883,
                        196.25011451114244
                    ],
                    [
                        354.3155527638105,
                        196.96788425474108
                    ],
                    [
                        354.3155527638105,
                        194.6117077261772
                    ],
                    [
                        355.05749249742644,
                        193.12921313159035
                    ],
                    [
                        354.49541694165646,
                        192.09528503420222
                    ],
                    [
                        356.1141945422735,
                        190.54334979920532
                    ],
                    [
                        357.8903532985057,
                        189.75565604139712
                    ]
                ]
            ]
        },
        "id": "TT-Assam-West Karbi Anglong",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kra Daadi",
            "dt_code": "763",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2015_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        373.49357072667294,
                        154.0757713504595
                    ],
                    [
                        375.78683899421344,
                        154.0757713504595
                    ],
                    [
                        376.7760919723678,
                        153.22641358803685
                    ],
                    [
                        379.13680930660075,
                        154.64943214841605
                    ],
                    [
                        379.90123206244766,
                        156.46098735860056
                    ],
                    [
                        379.36163952890865,
                        157.240078680894
                    ],
                    [
                        380.93545108506373,
                        158.95746483565352
                    ],
                    [
                        379.8787490402169,
                        161.58760606071945
                    ],
                    [
                        378.08010726175394,
                        162.11317407409388
                    ],
                    [
                        378.08010726175394,
                        163.095347994233
                    ],
                    [
                        375.2697294829054,
                        164.96685606261622
                    ],
                    [
                        374.77510299382766,
                        164.8984202562649
                    ],
                    [
                        374.34792557144306,
                        164.41929740497386
                    ],
                    [
                        374.2804765047506,
                        162.88982083018118
                    ],
                    [
                        375.4945597052131,
                        161.3819073562314
                    ],
                    [
                        375.8767710831364,
                        159.36939300244546
                    ],
                    [
                        375.17979739398197,
                        157.65240148400216
                    ],
                    [
                        373.5610197933654,
                        156.71308346603894
                    ],
                    [
                        373.49357072667294,
                        154.0757713504595
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Kra Daadi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Namsai",
            "dt_code": "762",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2014_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        409.0392288735493,
                        166.1754641972754
                    ],
                    [
                        407.66776451747137,
                        164.8984202562649
                    ],
                    [
                        407.57783242854816,
                        163.0496773077897
                    ],
                    [
                        411.2425650521668,
                        161.10760593024867
                    ],
                    [
                        413.96301074209214,
                        160.65034413272303
                    ],
                    [
                        414.90729767578523,
                        162.1360213886719
                    ],
                    [
                        414.7274334979388,
                        163.27801922075287
                    ],
                    [
                        415.46937323155475,
                        165.74228188449985
                    ],
                    [
                        414.21032398663056,
                        165.78788487652326
                    ],
                    [
                        411.80464060793634,
                        165.14933894821905
                    ],
                    [
                        410.9278027409355,
                        165.97028542139276
                    ],
                    [
                        409.0392288735493,
                        166.1754641972754
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Namsai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Longding",
            "dt_code": "761",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2012_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        401.61983153738925,
                        173.36497607844342
                    ],
                    [
                        401.7322466485432,
                        173.59203429960752
                    ],
                    [
                        401.5973485151585,
                        176.24654994859424
                    ],
                    [
                        403.4859223825447,
                        178.19531785965967
                    ],
                    [
                        402.47418638215913,
                        180.16467860276208
                    ],
                    [
                        400.6530615814654,
                        180.93375156843007
                    ],
                    [
                        399.64132558107985,
                        180.4135308237888
                    ],
                    [
                        399.1691821142333,
                        177.71964389532712
                    ],
                    [
                        399.5738765143874,
                        176.45058633386583
                    ],
                    [
                        399.0792500253099,
                        174.6134516566417
                    ],
                    [
                        400.0010539367722,
                        174.68152612312417
                    ],
                    [
                        401.61983153738925,
                        173.36497607844342
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Longding",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Imphal East",
            "dt_code": "278",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        380.6431717960636,
                        212.4481467670526
                    ],
                    [
                        380.6881378405251,
                        210.6449750970949
                    ],
                    [
                        380.2384773959095,
                        209.35281897605074
                    ],
                    [
                        381.9022210409878,
                        208.48348588392741
                    ],
                    [
                        383.251202374835,
                        209.19681267564113
                    ],
                    [
                        382.4867796189883,
                        212.02532569834705
                    ],
                    [
                        382.6666437968345,
                        212.9376182165912
                    ],
                    [
                        381.0928322406794,
                        213.31576480022784
                    ],
                    [
                        380.6431717960636,
                        212.4481467670526
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Imphal East",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jiribam",
            "dt_code": "766",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        369.356694636208,
                        211.17941687564286
                    ],
                    [
                        369.941253214208,
                        212.80413778964464
                    ],
                    [
                        369.98621925866973,
                        214.4720091863975
                    ],
                    [
                        369.40166068066947,
                        215.40541685405896
                    ],
                    [
                        368.0751623690528,
                        214.82764376546936
                    ],
                    [
                        368.05267934682206,
                        212.60390057724038
                    ],
                    [
                        368.3899246802839,
                        210.95675009354903
                    ],
                    [
                        369.356694636208,
                        211.17941687564286
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Jiribam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kangpokpi",
            "dt_code": "767",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        383.94817606398965,
                        209.08537215057964
                    ],
                    [
                        383.54348166383534,
                        209.9990012348939
                    ],
                    [
                        384.2404553529898,
                        212.49264908432622
                    ],
                    [
                        384.03810815291286,
                        213.87173407912496
                    ],
                    [
                        383.251202374835,
                        213.96067496847235
                    ],
                    [
                        382.6666437968345,
                        212.9376182165912
                    ],
                    [
                        382.4867796189883,
                        212.02532569834705
                    ],
                    [
                        383.251202374835,
                        209.19681267564113
                    ],
                    [
                        381.9022210409878,
                        208.48348588392741
                    ],
                    [
                        380.2384773959095,
                        209.35281897605074
                    ],
                    [
                        379.6988848623705,
                        208.10442733676877
                    ],
                    [
                        378.64218281752323,
                        210.0881134576826
                    ],
                    [
                        378.1025902839847,
                        212.2033664445533
                    ],
                    [
                        377.63044681713814,
                        213.98290957939838
                    ],
                    [
                        377.47306566152247,
                        212.0030694976731
                    ],
                    [
                        377.92272610613827,
                        211.26847667634127
                    ],
                    [
                        377.945209128369,
                        209.39738996950808
                    ],
                    [
                        378.7995639731389,
                        207.5245513131003
                    ],
                    [
                        378.1475563284464,
                        205.40433515395307
                    ],
                    [
                        380.0586132180631,
                        203.48302385728886
                    ],
                    [
                        380.3284094848327,
                        201.47034378172208
                    ],
                    [
                        381.3176624629871,
                        201.80593345658457
                    ],
                    [
                        381.07034921844865,
                        203.1477199638639
                    ],
                    [
                        381.45256059637177,
                        204.77899680690325
                    ],
                    [
                        382.5542286856805,
                        205.136357181871
                    ],
                    [
                        384.64514975314387,
                        205.0470231346652
                    ],
                    [
                        383.4310665526814,
                        205.98482984918786
                    ],
                    [
                        383.94817606398965,
                        209.08537215057964
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Kangpokpi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Thoubal",
            "dt_code": "276",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        380.21599437367877,
                        215.1165508941275
                    ],
                    [
                        379.85626601798594,
                        214.11631211050508
                    ],
                    [
                        380.6431717960636,
                        212.4481467670526
                    ],
                    [
                        381.0928322406794,
                        213.31576480022784
                    ],
                    [
                        382.6666437968345,
                        212.9376182165912
                    ],
                    [
                        383.251202374835,
                        213.96067496847235
                    ],
                    [
                        382.64416077460373,
                        215.2943194312777
                    ],
                    [
                        381.83477197429534,
                        215.67202584968862
                    ],
                    [
                        380.21599437367877,
                        215.1165508941275
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Thoubal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tengnoupal",
            "dt_code": "769",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        384.03810815291286,
                        213.87173407912496
                    ],
                    [
                        385.6344027312987,
                        214.36086056720256
                    ],
                    [
                        387.0058670873768,
                        216.09408516454317
                    ],
                    [
                        387.47801055422315,
                        217.00454625183642
                    ],
                    [
                        385.7693008646834,
                        220.30987785497337
                    ],
                    [
                        385.2072253089136,
                        222.21523036709993
                    ],
                    [
                        384.3753534863745,
                        221.90517687572276
                    ],
                    [
                        384.60018370868215,
                        220.11037835966232
                    ],
                    [
                        383.7458288639125,
                        219.20130159690171
                    ],
                    [
                        383.65589677498906,
                        217.89240881806478
                    ],
                    [
                        382.05960219660346,
                        216.82692729933535
                    ],
                    [
                        381.83477197429534,
                        215.67202584968862
                    ],
                    [
                        382.64416077460373,
                        215.2943194312777
                    ],
                    [
                        383.251202374835,
                        213.96067496847235
                    ],
                    [
                        384.03810815291286,
                        213.87173407912496
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Tengnoupal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ukhrul",
            "dt_code": "279",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        391.0977771333803,
                        206.0294762520095
                    ],
                    [
                        389.2991353549171,
                        207.34609395837174
                    ],
                    [
                        386.3763424649146,
                        208.12672689234802
                    ],
                    [
                        383.94817606398965,
                        209.08537215057964
                    ],
                    [
                        383.4310665526814,
                        205.98482984918786
                    ],
                    [
                        384.64514975314387,
                        205.0470231346652
                    ],
                    [
                        387.0508331318383,
                        202.09673150427045
                    ],
                    [
                        387.07331615406906,
                        201.2689624551254
                    ],
                    [
                        388.6696107324551,
                        198.0664561097849
                    ],
                    [
                        389.59141464391746,
                        198.4698575957417
                    ],
                    [
                        389.63638068837895,
                        199.70195702180837
                    ],
                    [
                        391.4125394446112,
                        200.6646944733265
                    ],
                    [
                        389.95114299960983,
                        204.42157189123185
                    ],
                    [
                        391.0977771333803,
                        206.0294762520095
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Ukhrul",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Noney",
            "dt_code": "771",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        369.941253214208,
                        212.80413778964464
                    ],
                    [
                        370.88554014790134,
                        213.4269709005917
                    ],
                    [
                        372.7741140152875,
                        210.73405861060803
                    ],
                    [
                        377.92272610613827,
                        211.26847667634127
                    ],
                    [
                        377.47306566152247,
                        212.0030694976731
                    ],
                    [
                        377.63044681713814,
                        213.98290957939838
                    ],
                    [
                        377.5854807726764,
                        214.0940789664739
                    ],
                    [
                        375.1348313495205,
                        214.49423817777915
                    ],
                    [
                        373.381155615519,
                        215.33875913063395
                    ],
                    [
                        372.50431774851836,
                        214.8943182974283
                    ],
                    [
                        370.9305061923628,
                        214.8943182974283
                    ],
                    [
                        369.40166068066947,
                        215.40541685405896
                    ],
                    [
                        369.98621925866973,
                        214.4720091863975
                    ],
                    [
                        369.941253214208,
                        212.80413778964464
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Noney",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pherzawl",
            "dt_code": "772",
            "st_nm": "Manipur",
            "st_code": "14",
            "alpha2code": "MN",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        369.8288381030543,
                        223.30005079705575
                    ],
                    [
                        366.43390174620527,
                        222.19308523570663
                    ],
                    [
                        367.1308754353597,
                        220.28771220215162
                    ],
                    [
                        366.92852823528256,
                        217.40413226614857
                    ],
                    [
                        367.5130868132833,
                        215.27209921642333
                    ],
                    [
                        368.0751623690528,
                        214.82764376546936
                    ],
                    [
                        369.40166068066947,
                        215.40541685405896
                    ],
                    [
                        370.9305061923628,
                        214.8943182974283
                    ],
                    [
                        372.50431774851836,
                        214.8943182974283
                    ],
                    [
                        372.1670724150565,
                        217.87021696488313
                    ],
                    [
                        374.010680237981,
                        218.15869223672456
                    ],
                    [
                        373.8308160601348,
                        220.04387421675023
                    ],
                    [
                        372.59424983744134,
                        221.06336773574913
                    ],
                    [
                        372.3469365929027,
                        222.63594269368355
                    ],
                    [
                        370.503328769978,
                        223.7205422561999
                    ],
                    [
                        369.8288381030543,
                        223.30005079705575
                    ]
                ]
            ]
        },
        "id": "TT-Manipur-Pherzawl",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Upper Siang",
            "dt_code": "252",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        401.8221787374662,
                        153.41009291646344
                    ],
                    [
                        401.21513713723516,
                        154.53471478075954
                    ],
                    [
                        399.55139349215665,
                        156.00253964386997
                    ],
                    [
                        397.75275171369344,
                        154.2134668103186
                    ],
                    [
                        397.8202007803859,
                        150.88283272912895
                    ],
                    [
                        397.43798940246256,
                        149.82490704185022
                    ],
                    [
                        396.0215590019227,
                        149.77889588624691
                    ],
                    [
                        393.54842655653624,
                        149.11159916251592
                    ],
                    [
                        392.64910566730487,
                        149.68686997401164
                    ],
                    [
                        392.4467584672275,
                        147.13123035877499
                    ],
                    [
                        390.603150644303,
                        145.33314745878602
                    ],
                    [
                        389.2092032659939,
                        145.03328733715534
                    ],
                    [
                        387.29814637637696,
                        143.02520454500353
                    ],
                    [
                        386.53372362053005,
                        142.14738428677163
                    ],
                    [
                        384.2404553529898,
                        141.22288384587006
                    ],
                    [
                        385.7693008646834,
                        139.39554654007213
                    ],
                    [
                        386.7810368650689,
                        139.30297181579255
                    ],
                    [
                        387.8602219321467,
                        138.05272738766092
                    ],
                    [
                        389.72631277730216,
                        138.02956617301504
                    ],
                    [
                        390.0185920663023,
                        137.14920940294525
                    ],
                    [
                        391.47998851130365,
                        136.36114018728517
                    ],
                    [
                        393.1437321563817,
                        138.0758882913767
                    ],
                    [
                        394.33533233461367,
                        138.79372220350137
                    ],
                    [
                        399.91112184784924,
                        140.04343095324793
                    ],
                    [
                        400.1584350923879,
                        141.03792467127755
                    ],
                    [
                        399.7987067366953,
                        141.84697560813737
                    ],
                    [
                        401.0127899371578,
                        143.07139334907464
                    ],
                    [
                        400.9453408704653,
                        144.50263951691142
                    ],
                    [
                        401.97955989308184,
                        146.20936710077928
                    ],
                    [
                        401.39500131508134,
                        146.80863334349493
                    ],
                    [
                        402.0020429153126,
                        149.75588985834844
                    ],
                    [
                        401.17017109277344,
                        150.65290278579897
                    ],
                    [
                        401.17017109277344,
                        152.5144766030246
                    ],
                    [
                        401.8221787374662,
                        153.41009291646344
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Upper Siang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lower Siang",
            "dt_code": "779",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2018"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        386.17399526483746,
                        162.38732278742117
                    ],
                    [
                        390.2434222886102,
                        160.7646704586636
                    ],
                    [
                        392.0420640670734,
                        161.5190424309739
                    ],
                    [
                        392.46924148945845,
                        162.38732278742117
                    ],
                    [
                        394.740026734768,
                        161.54189726362836
                    ],
                    [
                        395.5494155350764,
                        158.93457716712567
                    ],
                    [
                        394.62761162361403,
                        158.33939531887165
                    ],
                    [
                        395.14472113492207,
                        157.74401588121646
                    ],
                    [
                        397.1906761579239,
                        158.22491462969964
                    ],
                    [
                        398.20241215830924,
                        161.67902018260446
                    ],
                    [
                        394.0205700233828,
                        163.14101752858807
                    ],
                    [
                        388.28739935453154,
                        165.99308420472312
                    ],
                    [
                        386.84848593176116,
                        165.6738752539506
                    ],
                    [
                        384.9823950866057,
                        164.7159121689955
                    ],
                    [
                        384.6226667309131,
                        165.14933894821905
                    ],
                    [
                        385.09481019775967,
                        164.4421156457542
                    ],
                    [
                        386.6911047761457,
                        163.20951967031078
                    ],
                    [
                        386.17399526483746,
                        162.38732278742117
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Lower Siang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Upper Dibang Valley",
            "dt_code": "257",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        402.0020429153126,
                        149.75588985834844
                    ],
                    [
                        401.39500131508134,
                        146.80863334349493
                    ],
                    [
                        401.97955989308184,
                        146.20936710077928
                    ],
                    [
                        400.9453408704653,
                        144.50263951691142
                    ],
                    [
                        401.0127899371578,
                        143.07139334907464
                    ],
                    [
                        399.7987067366953,
                        141.84697560813737
                    ],
                    [
                        400.1584350923879,
                        141.03792467127755
                    ],
                    [
                        402.8788807823132,
                        141.2922384542278
                    ],
                    [
                        404.1154470050067,
                        138.30748023215165
                    ],
                    [
                        404.83490371639186,
                        138.23800591316729
                    ],
                    [
                        406.54361340593186,
                        136.84793142449104
                    ],
                    [
                        409.1741270069342,
                        135.3175517621517
                    ],
                    [
                        410.27579509624275,
                        135.45673352128586
                    ],
                    [
                        412.2093350080904,
                        134.13405066506897
                    ],
                    [
                        413.4908672752456,
                        135.7814470961356
                    ],
                    [
                        414.48012025339995,
                        137.96008066327965
                    ],
                    [
                        416.68345643201746,
                        137.61261111562084
                    ],
                    [
                        415.986482742863,
                        139.04836576457882
                    ],
                    [
                        414.0979088754766,
                        139.60382155584546
                    ],
                    [
                        413.10865589732225,
                        141.03792467127755
                    ],
                    [
                        414.5475693200924,
                        142.88663078098676
                    ],
                    [
                        415.986482742863,
                        141.38470695477963
                    ],
                    [
                        417.31298105447945,
                        141.2922384542278
                    ],
                    [
                        418.52706425494216,
                        142.42463853771574
                    ],
                    [
                        418.79686052171155,
                        143.87949927347097
                    ],
                    [
                        419.83107954432785,
                        145.33314745878602
                    ],
                    [
                        419.493834210866,
                        146.624265549261
                    ],
                    [
                        417.8300905657877,
                        147.17731080527108
                    ],
                    [
                        418.1448528770186,
                        148.02958101872025
                    ],
                    [
                        415.6942034538629,
                        150.07794695008005
                    ],
                    [
                        414.65998443124636,
                        149.6408552170431
                    ],
                    [
                        412.4116822081676,
                        150.44594024966932
                    ],
                    [
                        411.1076669187819,
                        150.12395030792908
                    ],
                    [
                        410.14089696285805,
                        150.56092243003673
                    ],
                    [
                        410.09593091839656,
                        151.89416947751215
                    ],
                    [
                        408.70198354008744,
                        152.49150613602285
                    ],
                    [
                        408.43218727331805,
                        154.0298704948509
                    ],
                    [
                        406.1389190057778,
                        152.56041664433235
                    ],
                    [
                        404.6325565163147,
                        152.4225929493748
                    ],
                    [
                        404.7224886052379,
                        150.88283272912895
                    ],
                    [
                        402.0020429153126,
                        149.75588985834844
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Upper Dibang Valley",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lower Dibang Valley",
            "dt_code": "258",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        415.51433927601624,
                        152.2158373137446
                    ],
                    [
                        415.3794411426318,
                        152.44556430921898
                    ],
                    [
                        414.0529428310151,
                        154.7641421253138
                    ],
                    [
                        411.93953874132126,
                        156.5984987343606
                    ],
                    [
                        410.45565927408893,
                        159.27786157224932
                    ],
                    [
                        408.2298400732409,
                        159.07189880268027
                    ],
                    [
                        405.1046999831615,
                        159.3465105819941
                    ],
                    [
                        403.82316771600654,
                        160.53601055146703
                    ],
                    [
                        403.55337144923715,
                        159.43803851569697
                    ],
                    [
                        404.4302093162378,
                        157.74401588121646
                    ],
                    [
                        404.4751753606995,
                        154.35115161323165
                    ],
                    [
                        403.0362619379289,
                        154.3052578627541
                    ],
                    [
                        401.8221787374662,
                        153.41009291646344
                    ],
                    [
                        401.17017109277344,
                        152.5144766030246
                    ],
                    [
                        401.17017109277344,
                        150.65290278579897
                    ],
                    [
                        402.0020429153126,
                        149.75588985834844
                    ],
                    [
                        404.7224886052379,
                        150.88283272912895
                    ],
                    [
                        404.6325565163147,
                        152.4225929493748
                    ],
                    [
                        406.1389190057778,
                        152.56041664433235
                    ],
                    [
                        408.43218727331805,
                        154.0298704948509
                    ],
                    [
                        408.70198354008744,
                        152.49150613602285
                    ],
                    [
                        410.09593091839656,
                        151.89416947751215
                    ],
                    [
                        410.14089696285805,
                        150.56092243003673
                    ],
                    [
                        411.1076669187819,
                        150.12395030792908
                    ],
                    [
                        412.4116822081676,
                        150.44594024966932
                    ],
                    [
                        414.65998443124636,
                        149.6408552170431
                    ],
                    [
                        415.6942034538629,
                        150.07794695008005
                    ],
                    [
                        416.36869412078636,
                        150.56092243003673
                    ],
                    [
                        415.51433927601624,
                        152.2158373137446
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Lower Dibang Valley",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Siang",
            "dt_code": "764",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2015_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        399.55139349215665,
                        156.00253964386997
                    ],
                    [
                        400.54064647031146,
                        156.5984987343606
                    ],
                    [
                        399.73125767000306,
                        157.81272360434923
                    ],
                    [
                        397.1906761579239,
                        158.22491462969964
                    ],
                    [
                        395.14472113492207,
                        157.74401588121646
                    ],
                    [
                        395.4370004239224,
                        156.46098735860056
                    ],
                    [
                        394.6500946458448,
                        154.92472368145167
                    ],
                    [
                        392.87393588961254,
                        155.49811113681648
                    ],
                    [
                        390.5132185553798,
                        154.3052578627541
                    ],
                    [
                        388.6246446879936,
                        152.00905755271043
                    ],
                    [
                        389.7038297550714,
                        150.35394911520325
                    ],
                    [
                        389.36658442160956,
                        148.76634649174292
                    ],
                    [
                        388.37733144345475,
                        148.14472096943678
                    ],
                    [
                        388.6246446879936,
                        147.062107420208
                    ],
                    [
                        387.0508331318383,
                        143.6948223755283
                    ],
                    [
                        387.29814637637696,
                        143.02520454500353
                    ],
                    [
                        389.2092032659939,
                        145.03328733715534
                    ],
                    [
                        390.603150644303,
                        145.33314745878602
                    ],
                    [
                        392.4467584672275,
                        147.13123035877499
                    ],
                    [
                        392.64910566730487,
                        149.68686997401164
                    ],
                    [
                        393.54842655653624,
                        149.11159916251592
                    ],
                    [
                        396.0215590019227,
                        149.77889588624691
                    ],
                    [
                        397.43798940246256,
                        149.82490704185022
                    ],
                    [
                        397.8202007803859,
                        150.88283272912895
                    ],
                    [
                        397.75275171369344,
                        154.2134668103186
                    ],
                    [
                        399.55139349215665,
                        156.00253964386997
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Siang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shi Yomi",
            "dt_code": "785",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2018"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        387.38807846529994,
                        152.9508589611914
                    ],
                    [
                        386.3313764204529,
                        152.62932447486878
                    ],
                    [
                        385.0498441532982,
                        151.15870918685573
                    ],
                    [
                        383.67837979722003,
                        151.273645009427
                    ],
                    [
                        383.09382121921976,
                        149.8939215250873
                    ],
                    [
                        381.52000966306423,
                        148.9504896729539
                    ],
                    [
                        381.83477197429534,
                        147.73018184409915
                    ],
                    [
                        380.93545108506373,
                        146.69340574378674
                    ],
                    [
                        381.45256059637177,
                        144.54878920538607
                    ],
                    [
                        383.8132779306047,
                        142.63255022619722
                    ],
                    [
                        384.2404553529898,
                        141.22288384587006
                    ],
                    [
                        386.53372362053005,
                        142.14738428677163
                    ],
                    [
                        387.29814637637696,
                        143.02520454500353
                    ],
                    [
                        387.0508331318383,
                        143.6948223755283
                    ],
                    [
                        388.6246446879936,
                        147.062107420208
                    ],
                    [
                        388.37733144345475,
                        148.14472096943678
                    ],
                    [
                        389.36658442160956,
                        148.76634649174292
                    ],
                    [
                        389.7038297550714,
                        150.35394911520325
                    ],
                    [
                        388.6246446879936,
                        152.00905755271043
                    ],
                    [
                        387.38807846529994,
                        152.9508589611914
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Shi Yomi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pakke Kessang",
            "dt_code": "783",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2018"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        371.64996290374825,
                        169.3186540224981
                    ],
                    [
                        370.0986343698237,
                        171.02464958571426
                    ],
                    [
                        371.38016663697863,
                        172.32014869506054
                    ],
                    [
                        369.941253214208,
                        172.91077593810752
                    ],
                    [
                        371.38016663697863,
                        173.86446737469805
                    ],
                    [
                        371.7848610371327,
                        175.29408388773015
                    ],
                    [
                        372.63921588190306,
                        175.88376330678574
                    ],
                    [
                        367.69295099112946,
                        176.4732556619427
                    ],
                    [
                        367.10839241312897,
                        176.60926583074888
                    ],
                    [
                        365.1298864568196,
                        175.18066252433488
                    ],
                    [
                        361.9822633445092,
                        174.65883491209587
                    ],
                    [
                        361.7349500999708,
                        175.04454773594563
                    ],
                    [
                        360.88059525520043,
                        174.29573774230573
                    ],
                    [
                        361.1279084997393,
                        172.81992250751534
                    ],
                    [
                        363.15138050050996,
                        171.9793161789815
                    ],
                    [
                        363.98325232304956,
                        172.25198723120485
                    ],
                    [
                        365.2423015679735,
                        172.95620097737043
                    ],
                    [
                        366.9734942797443,
                        172.81992250751534
                    ],
                    [
                        366.8161131241286,
                        171.0473854970026
                    ],
                    [
                        369.22179650282305,
                        170.02399102959714
                    ],
                    [
                        369.6489739252081,
                        168.93174109110487
                    ],
                    [
                        371.20030245913244,
                        168.56751307788642
                    ],
                    [
                        371.64996290374825,
                        169.3186540224981
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Pakke Kessang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Siang",
            "dt_code": "250",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        388.6246446879936,
                        158.24781135224168
                    ],
                    [
                        387.25318033191525,
                        157.21716907341352
                    ],
                    [
                        386.73607082060744,
                        155.2917128998102
                    ],
                    [
                        387.38807846529994,
                        152.9508589611914
                    ],
                    [
                        388.6246446879936,
                        152.00905755271043
                    ],
                    [
                        390.5132185553798,
                        154.3052578627541
                    ],
                    [
                        392.87393588961254,
                        155.49811113681648
                    ],
                    [
                        394.6500946458448,
                        154.92472368145167
                    ],
                    [
                        395.4370004239224,
                        156.46098735860056
                    ],
                    [
                        395.14472113492207,
                        157.74401588121646
                    ],
                    [
                        394.62761162361403,
                        158.33939531887165
                    ],
                    [
                        393.54842655653624,
                        158.84302357525218
                    ],
                    [
                        390.35583739976414,
                        157.58368761059052
                    ],
                    [
                        388.6246446879936,
                        158.24781135224168
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-West Siang",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lepa Rada",
            "dt_code": "784",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2018"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        390.2434222886102,
                        160.7646704586636
                    ],
                    [
                        388.6246446879936,
                        158.24781135224168
                    ],
                    [
                        390.35583739976414,
                        157.58368761059052
                    ],
                    [
                        393.54842655653624,
                        158.84302357525218
                    ],
                    [
                        394.62761162361403,
                        158.33939531887165
                    ],
                    [
                        395.5494155350764,
                        158.93457716712567
                    ],
                    [
                        394.740026734768,
                        161.54189726362836
                    ],
                    [
                        392.46924148945845,
                        162.38732278742117
                    ],
                    [
                        392.0420640670734,
                        161.5190424309739
                    ],
                    [
                        390.2434222886102,
                        160.7646704586636
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Lepa Rada",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kamle",
            "dt_code": "778",
            "st_nm": "Arunachal Pradesh",
            "st_code": "12",
            "alpha2code": "AR",
            "year": "2018"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        380.93545108506373,
                        158.95746483565352
                    ],
                    [
                        382.4867796189883,
                        159.62108040190952
                    ],
                    [
                        382.5767117079115,
                        161.10760593024867
                    ],
                    [
                        383.67837979722003,
                        160.83326277274404
                    ],
                    [
                        384.33038744191276,
                        161.74757773640283
                    ],
                    [
                        386.17399526483746,
                        162.38732278742117
                    ],
                    [
                        386.6911047761457,
                        163.20951967031078
                    ],
                    [
                        385.09481019775967,
                        164.4421156457542
                    ],
                    [
                        384.6226667309131,
                        165.14933894821905
                    ],
                    [
                        385.40957250899055,
                        166.5857524416062
                    ],
                    [
                        384.71259881983633,
                        166.83643869547728
                    ],
                    [
                        383.04885517475805,
                        167.01873433309134
                    ],
                    [
                        380.6431717960636,
                        166.72249467022237
                    ],
                    [
                        382.5542286856805,
                        166.6541248377451
                    ],
                    [
                        382.26194939668017,
                        165.1265295893951
                    ],
                    [
                        380.62068877383285,
                        164.62465124819516
                    ],
                    [
                        379.8787490402169,
                        161.58760606071945
                    ],
                    [
                        380.93545108506373,
                        158.95746483565352
                    ]
                ]
            ]
        },
        "id": "TT-Arunachal Pradesh-Kamle",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "North and Middle Andaman",
            "dt_code": "639",
            "st_nm": "Andaman and Nicobar Islands",
            "st_code": "35",
            "alpha2code": "AN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            364.8151241455887,
                            406.4439471430881
                        ],
                        [
                            364.65774298997303,
                            407.5813071478491
                        ],
                        [
                            363.39869374504883,
                            407.2297941381608
                        ],
                        [
                            362.971516322664,
                            406.2784858938817
                        ],
                        [
                            364.0057353452803,
                            405.2648850686768
                        ],
                        [
                            364.3204976565112,
                            405.4510761072603
                        ],
                        [
                            364.9050562345119,
                            405.4303886472072
                        ],
                        [
                            364.8151241455887,
                            406.4439471430881
                        ]
                    ]
                ],
                [
                    [
                        [
                            364.92753925674265,
                            404.9959269601941
                        ],
                        [
                            364.29801463428043,
                            405.4303886472072
                        ],
                        [
                            363.98325232304956,
                            405.2235080907593
                        ],
                        [
                            363.53359187843375,
                            405.2648850686768
                        ],
                        [
                            362.6567540114331,
                            405.4717634590764
                        ],
                        [
                            362.36447472243253,
                            405.0373063237787
                        ],
                        [
                            362.5668219225099,
                            401.7876977079968
                        ],
                        [
                            363.03896538935624,
                            399.0948788424058
                        ],
                        [
                            362.81413516704833,
                            397.3953576450434
                        ],
                        [
                            363.7584221007414,
                            397.0014576673865
                        ],
                        [
                            363.6684900118182,
                            394.61644778743164
                        ],
                        [
                            364.1631165008955,
                            393.22621978754245
                        ],
                        [
                            364.4553957898961,
                            389.0108412261104
                        ],
                        [
                            364.88257321228093,
                            387.7847695395011
                        ],
                        [
                            365.8043771237433,
                            387.3898444964634
                        ],
                        [
                            366.6137659240517,
                            386.24639645379756
                        ],
                        [
                            367.4231547243601,
                            386.93250886473515
                        ],
                        [
                            367.24329054651366,
                            389.71720319155605
                        ],
                        [
                            367.73791703559095,
                            390.7972572974195
                        ],
                        [
                            367.24329054651366,
                            392.2299156444393
                        ],
                        [
                            367.2882565909754,
                            393.68276978484676
                        ],
                        [
                            366.1865885016666,
                            394.67868468674453
                        ],
                        [
                            365.4896148125122,
                            394.0977666392871
                        ],
                        [
                            364.7027090344345,
                            396.4001628252291
                        ],
                        [
                            365.6694789903586,
                            396.669720622516
                        ],
                        [
                            366.3214866350513,
                            398.84621574431543
                        ],
                        [
                            366.1416224572049,
                            400.58651876042904
                        ],
                        [
                            366.4114187239745,
                            401.8084044212527
                        ],
                        [
                            364.92753925674265,
                            404.9959269601941
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Andaman and Nicobar Islands-North and Middle Andaman",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "South Andaman",
            "dt_code": "640",
            "st_nm": "Andaman and Nicobar Islands",
            "st_code": "35",
            "alpha2code": "AN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            359.7339611214304,
                            426.5188012105983
                        ],
                        [
                            360.7007310773545,
                            428.16518901288157
                        ],
                        [
                            360.8581122329697,
                            429.74926860482367
                        ],
                        [
                            359.8913422770461,
                            432.15519212630477
                        ],
                        [
                            358.45242885427547,
                            431.70289259345725
                        ],
                        [
                            357.7329721428903,
                            429.91381655291434
                        ],
                        [
                            357.64304005396707,
                            428.2474925455808
                        ],
                        [
                            359.7339611214304,
                            426.5188012105983
                        ]
                    ]
                ],
                [
                    [
                        [
                            361.4876368554319,
                            417.32885333136943
                        ],
                        [
                            362.2295765890476,
                            419.37046570975656
                        ],
                        [
                            360.88059525520043,
                            419.7827938248516
                        ],
                        [
                            360.7007310773545,
                            418.97871713017173
                        ],
                        [
                            361.4876368554319,
                            417.32885333136943
                        ]
                    ]
                ],
                [
                    [
                        [
                            363.1963465449717,
                            407.31250586428405
                        ],
                        [
                            363.1963465449717,
                            407.3538610849056
                        ],
                        [
                            362.8815842337408,
                            408.7183437933055
                        ],
                        [
                            363.57855792289547,
                            409.25574008527565
                        ],
                        [
                            362.4768898335865,
                            410.37164248646695
                        ],
                        [
                            363.71345605627994,
                            411.69379662598817
                        ],
                        [
                            363.17386352274093,
                            414.46067703953213
                        ],
                        [
                            361.8023991666628,
                            415.18306504012276
                        ],
                        [
                            363.1288974782792,
                            415.8434245721072
                        ],
                        [
                            361.86984823335524,
                            417.4113617868014
                        ],
                        [
                            360.1836215660462,
                            414.19232952059235
                        ],
                        [
                            359.9587913437381,
                            411.30132686286674
                        ],
                        [
                            360.92556129966215,
                            412.25145205864067
                        ],
                        [
                            361.24032361089326,
                            408.4496187448165
                        ],
                        [
                            361.8023991666628,
                            406.75416842760245
                        ],
                        [
                            363.1963465449717,
                            407.31250586428405
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Andaman and Nicobar Islands-South Andaman",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nicobars",
            "dt_code": "638",
            "st_nm": "Andaman and Nicobar Islands",
            "st_code": "35",
            "alpha2code": "AN",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            379.09184326213904,
                            480.97426246712837
                        ],
                        [
                            379.5639867289856,
                            481.5039897153546
                        ],
                        [
                            380.2384773959095,
                            485.29237127867646
                        ],
                        [
                            379.6988848623705,
                            487.5929039587656
                        ],
                        [
                            378.55225072860026,
                            487.99999999999994
                        ],
                        [
                            377.3381675281378,
                            484.6611195168338
                        ],
                        [
                            376.5962277945216,
                            484.19273424316384
                        ],
                        [
                            376.28146548329073,
                            482.6651689510825
                        ],
                        [
                            376.7086429056758,
                            481.70771982956404
                        ],
                        [
                            379.09184326213904,
                            480.97426246712837
                        ]
                    ]
                ],
                [
                    [
                        [
                            377.0908542835991,
                            477.9377015917818
                        ],
                        [
                            377.7203789060609,
                            478.9568379575161
                        ],
                        [
                            377.2707184614453,
                            479.91468179481956
                        ],
                        [
                            376.14656734990604,
                            480.91313739450254
                        ],
                        [
                            375.7418729497517,
                            479.91468179481956
                        ],
                        [
                            377.0908542835991,
                            477.9377015917818
                        ]
                    ]
                ],
                [
                    [
                        [
                            363.7359390785107,
                            451.2976954302485
                        ],
                        [
                            364.230565567588,
                            452.7514371275113
                        ],
                        [
                            362.7916521448176,
                            452.95615736724744
                        ],
                        [
                            362.67923703366364,
                            451.5843804694513
                        ],
                        [
                            363.7359390785107,
                            451.2976954302485
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Andaman and Nicobar Islands-Nicobars",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Amroha",
            "dt_code": "137",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        147.3143670849413,
                        140.48292877639454
                    ],
                    [
                        149.40528815240486,
                        141.38470695477963
                    ],
                    [
                        151.92338664225304,
                        141.52340047142246
                    ],
                    [
                        154.0817567764086,
                        140.78357360299344
                    ],
                    [
                        154.01430770971638,
                        142.44774106356573
                    ],
                    [
                        155.27335695464058,
                        142.79424214342447
                    ],
                    [
                        155.94784762156405,
                        144.41033648065383
                    ],
                    [
                        155.40825508802527,
                        145.1255574676676
                    ],
                    [
                        152.23814895348391,
                        145.54071281289816
                    ],
                    [
                        152.7102924203307,
                        148.76634649174292
                    ],
                    [
                        152.30559802017638,
                        150.88283272912895
                    ],
                    [
                        150.7317864640213,
                        151.29663127774393
                    ],
                    [
                        149.3378390857124,
                        149.77889588624691
                    ],
                    [
                        149.3378390857124,
                        148.14472096943678
                    ],
                    [
                        148.19120495194215,
                        145.54071281289816
                    ],
                    [
                        148.03382379632671,
                        143.92566544248007
                    ],
                    [
                        147.38181615163376,
                        142.26291226365396
                    ],
                    [
                        147.3143670849413,
                        140.48292877639454
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Amroha",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Churu",
            "dt_code": "102",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        109.11571231483185,
                        143.11758092813028
                    ],
                    [
                        109.79020298175556,
                        144.66415809144922
                    ],
                    [
                        110.39724458198702,
                        148.37497825570864
                    ],
                    [
                        111.29656547121863,
                        148.5591623858453
                    ],
                    [
                        111.99353916037285,
                        149.9399296807117
                    ],
                    [
                        109.49792369275542,
                        152.99678770310396
                    ],
                    [
                        107.76673098098445,
                        152.97382348072472
                    ],
                    [
                        105.78822502467528,
                        150.85984108091958
                    ],
                    [
                        105.45097969121343,
                        152.00905755271043
                    ],
                    [
                        104.28186253521244,
                        152.23881135324655
                    ],
                    [
                        104.30434555744318,
                        153.6855763016411
                    ],
                    [
                        102.79798306798034,
                        154.35115161323165
                    ],
                    [
                        102.5057037789802,
                        155.22290817736558
                    ],
                    [
                        100.4822317782091,
                        155.4522469264835
                    ],
                    [
                        99.29063159997736,
                        157.56078240019946
                    ],
                    [
                        98.97586928874648,
                        159.36939300244546
                    ],
                    [
                        98.25641257736106,
                        160.42166971232882
                    ],
                    [
                        99.47049577782377,
                        161.907535239894
                    ],
                    [
                        98.34634466628427,
                        162.47869645314313
                    ],
                    [
                        97.98661631059167,
                        163.96287232586815
                    ],
                    [
                        96.66011799897524,
                        164.4421156457542
                    ],
                    [
                        94.6591290204351,
                        163.8030964116494
                    ],
                    [
                        92.45579284181758,
                        163.89439865795111
                    ],
                    [
                        91.17426057466287,
                        165.24057352182103
                    ],
                    [
                        90.31990572989275,
                        166.92758879115172
                    ],
                    [
                        90.43232084104693,
                        168.20321257703216
                    ],
                    [
                        88.6336790625835,
                        167.26936110379586
                    ],
                    [
                        87.86925630673704,
                        166.33503171338492
                    ],
                    [
                        86.09309755050458,
                        167.08709050193585
                    ],
                    [
                        85.59847106142706,
                        166.28944242047413
                    ],
                    [
                        85.89075035042742,
                        165.21776530763384
                    ],
                    [
                        85.35115781688864,
                        164.25956168684763
                    ],
                    [
                        85.48605595027311,
                        162.27309921036692
                    ],
                    [
                        87.32966377319781,
                        161.74757773640283
                    ],
                    [
                        87.39711283989027,
                        163.02684153248373
                    ],
                    [
                        88.40884884027582,
                        163.07251279503254
                    ],
                    [
                        89.19575461835325,
                        161.9760841189896
                    ],
                    [
                        90.76956617450878,
                        161.6561670861194
                    ],
                    [
                        90.94943035235497,
                        158.61411916401772
                    ],
                    [
                        91.78130217489411,
                        157.85852728923498
                    ],
                    [
                        91.75881915266336,
                        156.1859328518346
                    ],
                    [
                        92.70310608635646,
                        155.17703688688727
                    ],
                    [
                        91.08432848573966,
                        154.48882576370147
                    ],
                    [
                        90.34238875212372,
                        153.0886416215007
                    ],
                    [
                        88.34139977358336,
                        153.0656785875277
                    ],
                    [
                        87.84677328450607,
                        152.3307045332287
                    ],
                    [
                        88.22898466242941,
                        149.82490704185022
                    ],
                    [
                        89.03837346273804,
                        148.83540244161594
                    ],
                    [
                        88.61119604035298,
                        147.40769489352203
                    ],
                    [
                        90.27493968543126,
                        146.7625432121597
                    ],
                    [
                        89.80279621858472,
                        145.63295617727795
                    ],
                    [
                        91.3766077747398,
                        145.28701847931939
                    ],
                    [
                        93.60242697558783,
                        143.8102477286168
                    ],
                    [
                        94.43429879812697,
                        144.9871504453593
                    ],
                    [
                        98.45875977743822,
                        144.34110600089326
                    ],
                    [
                        99.24566555551564,
                        143.07139334907464
                    ],
                    [
                        101.0218243117481,
                        143.6948223755283
                    ],
                    [
                        102.0560433343644,
                        143.39468068674125
                    ],
                    [
                        105.36104760229045,
                        144.1333980851142
                    ],
                    [
                        105.72077595798282,
                        143.02520454500353
                    ],
                    [
                        107.04727426959926,
                        142.17049049628508
                    ],
                    [
                        107.51941773644603,
                        143.30231899762867
                    ],
                    [
                        109.11571231483185,
                        143.11758092813028
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Churu",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hapur",
            "dt_code": "705",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        148.03382379632671,
                        143.92566544248007
                    ],
                    [
                        148.19120495194215,
                        145.54071281289816
                    ],
                    [
                        149.3378390857124,
                        148.14472096943678
                    ],
                    [
                        148.16872192971164,
                        147.98352292684626
                    ],
                    [
                        145.69558948432473,
                        146.64731258371097
                    ],
                    [
                        144.79626859509312,
                        148.02958101872025
                    ],
                    [
                        144.12177792816942,
                        147.66108248173094
                    ],
                    [
                        142.54796637201434,
                        149.2726939185995
                    ],
                    [
                        141.60367943832125,
                        149.29570625287198
                    ],
                    [
                        139.8724867265505,
                        147.70714902528988
                    ],
                    [
                        139.58020743755014,
                        147.15427073326396
                    ],
                    [
                        141.69361152724423,
                        146.16326120839642
                    ],
                    [
                        146.61739339578708,
                        143.94874806878067
                    ],
                    [
                        148.03382379632671,
                        143.92566544248007
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Hapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Delhi",
            "st_nm": "Delhi",
            "st_code": "07",
            "alpha2code": "DL",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        136.56748245862468,
                        148.42102609597526
                    ],
                    [
                        135.9154748139315,
                        149.13461360098
                    ],
                    [
                        136.6574145475479,
                        149.9169257528721
                    ],
                    [
                        135.28595019146974,
                        150.53792659275922
                    ],
                    [
                        134.11683303546852,
                        151.8482121606139
                    ],
                    [
                        133.30744423516035,
                        150.44594024966932
                    ],
                    [
                        132.9701989016985,
                        150.10094877889287
                    ],
                    [
                        131.64370059008206,
                        149.5718308300514
                    ],
                    [
                        129.84505881161886,
                        150.2159534267281
                    ],
                    [
                        129.2829832558491,
                        149.41076341694048
                    ],
                    [
                        129.91250787831132,
                        148.0526096121319
                    ],
                    [
                        130.8567948120044,
                        147.45376808351426
                    ],
                    [
                        131.1265910787738,
                        146.9468964709858
                    ],
                    [
                        130.69941365638874,
                        145.263953533292
                    ],
                    [
                        131.08162503431208,
                        145.03328733715534
                    ],
                    [
                        133.3299272573911,
                        144.24879442335947
                    ],
                    [
                        134.72387463569999,
                        144.34110600089326
                    ],
                    [
                        134.8138067246232,
                        144.71030351227415
                    ],
                    [
                        134.61145952454604,
                        145.37927522161073
                    ],
                    [
                        134.90373881354617,
                        145.7482535455273
                    ],
                    [
                        135.93795783616224,
                        146.7625432121597
                    ],
                    [
                        136.20775410293209,
                        147.89140312230205
                    ],
                    [
                        136.56748245862468,
                        148.42102609597526
                    ]
                ]
            ]
        },
        "id": "TT-Delhi-Delhi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jhajjar",
            "dt_code": "083",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        129.84505881161886,
                        145.07942301120164
                    ],
                    [
                        130.69941365638874,
                        145.263953533292
                    ],
                    [
                        131.1265910787738,
                        146.9468964709858
                    ],
                    [
                        130.8567948120044,
                        147.45376808351426
                    ],
                    [
                        129.91250787831132,
                        148.0526096121319
                    ],
                    [
                        129.2829832558491,
                        149.41076341694048
                    ],
                    [
                        129.84505881161886,
                        150.2159534267281
                    ],
                    [
                        127.39440938846292,
                        151.3196172473776
                    ],
                    [
                        126.67495267707773,
                        152.2847585388007
                    ],
                    [
                        126.06791107684626,
                        151.52647753679298
                    ],
                    [
                        121.14412920830364,
                        150.79086434171194
                    ],
                    [
                        122.33572938653538,
                        148.5821840465348
                    ],
                    [
                        123.68471072038278,
                        148.35195388354703
                    ],
                    [
                        123.0551860979208,
                        146.87776626955264
                    ],
                    [
                        123.77464280930599,
                        146.20936710077928
                    ],
                    [
                        125.34845436546107,
                        146.32462652344714
                    ],
                    [
                        125.73066574338463,
                        145.35621149226813
                    ],
                    [
                        127.97896796646341,
                        145.7482535455273
                    ],
                    [
                        129.84505881161886,
                        145.07942301120164
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Jhajjar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bareilly",
            "dt_code": "150",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        170.98898949396175,
                        144.3180285641797
                    ],
                    [
                        171.1014046051157,
                        146.02493624910005
                    ],
                    [
                        170.08966860473015,
                        147.86837241671014
                    ],
                    [
                        172.45038593896288,
                        150.19295309658827
                    ],
                    [
                        172.27052176111647,
                        151.36558829067917
                    ],
                    [
                        171.1913366940389,
                        153.43305149689223
                    ],
                    [
                        171.28126878296212,
                        154.32820488595405
                    ],
                    [
                        169.7973893157298,
                        157.1713489785168
                    ],
                    [
                        168.3584758929594,
                        158.33939531887165
                    ],
                    [
                        168.24606078180545,
                        156.5755809065153
                    ],
                    [
                        167.1893587369584,
                        155.7044854901194
                    ],
                    [
                        163.54710913557074,
                        155.49811113681648
                    ],
                    [
                        162.4454410462622,
                        154.92472368145167
                    ],
                    [
                        161.81591642379976,
                        152.2158373137446
                    ],
                    [
                        160.53438415664505,
                        151.57244320624926
                    ],
                    [
                        162.06322966833864,
                        150.12395030792908
                    ],
                    [
                        163.97428655795557,
                        149.06556938351673
                    ],
                    [
                        164.40146398034085,
                        147.5919804012256
                    ],
                    [
                        165.45816602518767,
                        146.1863143062747
                    ],
                    [
                        166.24507180326532,
                        146.02493624910005
                    ],
                    [
                        167.16687571472767,
                        143.602476591019
                    ],
                    [
                        169.55007607119114,
                        144.4564886087245
                    ],
                    [
                        170.98898949396175,
                        144.3180285641797
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Bareilly",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pilibhit",
            "dt_code": "151",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        171.28126878296212,
                        154.32820488595405
                    ],
                    [
                        171.1913366940389,
                        153.43305149689223
                    ],
                    [
                        172.27052176111647,
                        151.36558829067917
                    ],
                    [
                        172.45038593896288,
                        150.19295309658827
                    ],
                    [
                        170.08966860473015,
                        147.86837241671014
                    ],
                    [
                        171.1014046051157,
                        146.02493624910005
                    ],
                    [
                        170.98898949396175,
                        144.3180285641797
                    ],
                    [
                        173.01246149473263,
                        143.76407850423834
                    ],
                    [
                        174.99096745104202,
                        146.53207438155283
                    ],
                    [
                        176.09263554035056,
                        146.09410009431065
                    ],
                    [
                        176.60974505165882,
                        144.61801145150974
                    ],
                    [
                        177.419133851967,
                        144.77951935804305
                    ],
                    [
                        179.73488514173846,
                        146.71645186945568
                    ],
                    [
                        180.27447767527724,
                        148.0986658940107
                    ],
                    [
                        182.23050060935566,
                        149.9629333086272
                    ],
                    [
                        180.88151927550848,
                        151.61840768197982
                    ],
                    [
                        180.9264853199702,
                        153.11160435842453
                    ],
                    [
                        178.92549634143006,
                        153.59375325246106
                    ],
                    [
                        177.84631127435205,
                        152.83603190548757
                    ],
                    [
                        175.8902883402734,
                        152.2847585388007
                    ],
                    [
                        175.4406278956576,
                        153.9151131720855
                    ],
                    [
                        175.4406278956576,
                        156.82766085370406
                    ],
                    [
                        174.0241974951182,
                        155.38344839916596
                    ],
                    [
                        172.67521616127078,
                        155.9566883982585
                    ],
                    [
                        171.28126878296212,
                        154.32820488595405
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Pilibhit",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bulandshahr",
            "dt_code": "142",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        149.3378390857124,
                        148.14472096943678
                    ],
                    [
                        149.3378390857124,
                        149.77889588624691
                    ],
                    [
                        150.7317864640213,
                        151.29663127774393
                    ],
                    [
                        150.34957508609796,
                        152.1239381770821
                    ],
                    [
                        150.84420157517548,
                        154.0298704948509
                    ],
                    [
                        153.29485099833119,
                        156.73599953101012
                    ],
                    [
                        152.30559802017638,
                        157.21716907341352
                    ],
                    [
                        151.24889597532956,
                        156.16300973088806
                    ],
                    [
                        148.88817864109683,
                        157.33171417818147
                    ],
                    [
                        146.70732548471005,
                        156.46098735860056
                    ],
                    [
                        146.16773295117127,
                        157.3088057437136
                    ],
                    [
                        144.99861579517028,
                        156.94223089994586
                    ],
                    [
                        143.9194307280925,
                        155.63569669182777
                    ],
                    [
                        142.32313614970644,
                        156.46098735860056
                    ],
                    [
                        141.67112850501348,
                        153.9380652291503
                    ],
                    [
                        142.39058521639868,
                        153.29529556255108
                    ],
                    [
                        140.86173970470531,
                        151.48051067344232
                    ],
                    [
                        141.60367943832125,
                        149.29570625287198
                    ],
                    [
                        142.54796637201434,
                        149.2726939185995
                    ],
                    [
                        144.12177792816942,
                        147.66108248173094
                    ],
                    [
                        144.79626859509312,
                        148.02958101872025
                    ],
                    [
                        145.69558948432473,
                        146.64731258371097
                    ],
                    [
                        148.16872192971164,
                        147.98352292684626
                    ],
                    [
                        149.3378390857124,
                        148.14472096943678
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Bulandshahr",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gautam Buddha Nagar",
            "dt_code": "141",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        139.8724867265505,
                        147.70714902528988
                    ],
                    [
                        141.60367943832125,
                        149.29570625287198
                    ],
                    [
                        140.86173970470531,
                        151.48051067344232
                    ],
                    [
                        142.39058521639868,
                        153.29529556255108
                    ],
                    [
                        141.67112850501348,
                        153.9380652291503
                    ],
                    [
                        142.32313614970644,
                        156.46098735860056
                    ],
                    [
                        139.89496974878125,
                        157.14843849105966
                    ],
                    [
                        138.54598841493407,
                        157.1942591726281
                    ],
                    [
                        138.90571677062644,
                        155.17703688688727
                    ],
                    [
                        138.9731658373189,
                        152.4225929493748
                    ],
                    [
                        136.6574145475479,
                        149.9169257528721
                    ],
                    [
                        135.9154748139315,
                        149.13461360098
                    ],
                    [
                        136.56748245862468,
                        148.42102609597526
                    ],
                    [
                        139.8724867265505,
                        147.70714902528988
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Gautam Buddha Nagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lakhimpur Kheri",
            "dt_code": "153",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        180.9264853199702,
                        153.11160435842453
                    ],
                    [
                        180.88151927550848,
                        151.61840768197982
                    ],
                    [
                        182.23050060935566,
                        149.9629333086272
                    ],
                    [
                        180.27447767527724,
                        148.0986658940107
                    ],
                    [
                        182.41036478720207,
                        148.1677480547832
                    ],
                    [
                        183.78182914328022,
                        147.062107420208
                    ],
                    [
                        185.46805581058948,
                        147.86837241671014
                    ],
                    [
                        186.16502949974392,
                        149.11159916251592
                    ],
                    [
                        191.13377741274803,
                        151.8482121606139
                    ],
                    [
                        190.95391323490162,
                        153.84625522302457
                    ],
                    [
                        193.24718150244212,
                        156.896403762254
                    ],
                    [
                        193.22469848021137,
                        158.47676250047056
                    ],
                    [
                        194.84347608082817,
                        160.14722207087277
                    ],
                    [
                        194.50623074736654,
                        163.89439865795111
                    ],
                    [
                        192.99986825790347,
                        162.18171515120747
                    ],
                    [
                        190.21197350128568,
                        161.5190424309739
                    ],
                    [
                        189.73983003443914,
                        160.28445112134392
                    ],
                    [
                        187.80629012259124,
                        162.09032647061485
                    ],
                    [
                        185.0858444326659,
                        161.29047818221875
                    ],
                    [
                        184.54625189912713,
                        162.7527897524651
                    ],
                    [
                        183.26471963197196,
                        161.77042967585152
                    ],
                    [
                        182.27546665381738,
                        162.77562898611973
                    ],
                    [
                        180.22951163081575,
                        163.91722350120472
                    ],
                    [
                        179.37515678604564,
                        162.63858925885557
                    ],
                    [
                        176.99195642958216,
                        162.20456159920553
                    ],
                    [
                        176.54229598496636,
                        162.59290704254033
                    ],
                    [
                        176.09263554035056,
                        160.23870926690432
                    ],
                    [
                        177.48658291865945,
                        158.5683347778994
                    ],
                    [
                        179.3976398082766,
                        157.10261663602358
                    ],
                    [
                        180.04964745296934,
                        155.88790932145122
                    ],
                    [
                        180.22951163081575,
                        153.98396845447013
                    ],
                    [
                        180.9264853199702,
                        153.11160435842453
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Lakhimpur Kheri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jhunjhunu",
            "dt_code": "103",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        102.5057037789802,
                        155.22290817736558
                    ],
                    [
                        102.79798306798034,
                        154.35115161323165
                    ],
                    [
                        104.30434555744318,
                        153.6855763016411
                    ],
                    [
                        104.28186253521244,
                        152.23881135324655
                    ],
                    [
                        105.45097969121343,
                        152.00905755271043
                    ],
                    [
                        105.78822502467528,
                        150.85984108091958
                    ],
                    [
                        107.76673098098445,
                        152.97382348072472
                    ],
                    [
                        109.49792369275542,
                        152.99678770310396
                    ],
                    [
                        111.99353916037285,
                        149.9399296807117
                    ],
                    [
                        115.27606040606793,
                        152.1009626481482
                    ],
                    [
                        117.65926076253163,
                        154.92472368145167
                    ],
                    [
                        116.82738893999226,
                        156.55266278478518
                    ],
                    [
                        116.96228707337718,
                        157.629497152471
                    ],
                    [
                        116.44517756206892,
                        159.73547211814935
                    ],
                    [
                        115.86061898406842,
                        159.8041036553122
                    ],
                    [
                        113.43245258314323,
                        161.4276202058183
                    ],
                    [
                        112.24085240491172,
                        163.9400480571705
                    ],
                    [
                        110.08248227075592,
                        163.39217938124915
                    ],
                    [
                        108.44122164790838,
                        164.55620254776693
                    ],
                    [
                        106.32781755821406,
                        163.27801922075287
                    ],
                    [
                        106.35030058044481,
                        161.58760606071945
                    ],
                    [
                        105.72077595798282,
                        161.15332573164432
                    ],
                    [
                        103.56240582382725,
                        157.4004377224598
                    ],
                    [
                        104.01206626844305,
                        156.32346539925197
                    ],
                    [
                        102.5057037789802,
                        155.22290817736558
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Jhunjhunu",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gurugram",
            "dt_code": "086",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        126.67495267707773,
                        152.2847585388007
                    ],
                    [
                        127.39440938846292,
                        151.3196172473776
                    ],
                    [
                        129.84505881161886,
                        150.2159534267281
                    ],
                    [
                        131.64370059008206,
                        149.5718308300514
                    ],
                    [
                        132.9701989016985,
                        150.10094877889287
                    ],
                    [
                        133.30744423516035,
                        150.44594024966932
                    ],
                    [
                        134.11683303546852,
                        151.8482121606139
                    ],
                    [
                        133.84703676869913,
                        153.3182556269722
                    ],
                    [
                        134.83628974685394,
                        154.16756950802608
                    ],
                    [
                        134.3641462800074,
                        155.131164415802
                    ],
                    [
                        131.86853081238974,
                        154.465880811518
                    ],
                    [
                        131.23900618992775,
                        153.0197516283501
                    ],
                    [
                        129.46284743369552,
                        154.7870832340314
                    ],
                    [
                        126.80985081046265,
                        154.2134668103186
                    ],
                    [
                        126.67495267707773,
                        152.2847585388007
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Gurugram",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Faridabad",
            "dt_code": "088",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        136.6574145475479,
                        149.9169257528721
                    ],
                    [
                        138.9731658373189,
                        152.4225929493748
                    ],
                    [
                        138.90571677062644,
                        155.17703688688727
                    ],
                    [
                        134.83628974685394,
                        154.16756950802608
                    ],
                    [
                        133.84703676869913,
                        153.3182556269722
                    ],
                    [
                        134.11683303546852,
                        151.8482121606139
                    ],
                    [
                        135.28595019146974,
                        150.53792659275922
                    ],
                    [
                        136.6574145475479,
                        149.9169257528721
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Faridabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mahendragarh",
            "dt_code": "084",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        115.86061898406842,
                        159.8041036553122
                    ],
                    [
                        116.44517756206892,
                        159.73547211814935
                    ],
                    [
                        116.96228707337718,
                        157.629497152471
                    ],
                    [
                        116.82738893999226,
                        156.55266278478518
                    ],
                    [
                        117.65926076253163,
                        154.92472368145167
                    ],
                    [
                        115.27606040606793,
                        152.1009626481482
                    ],
                    [
                        115.45592458391411,
                        151.38857336438866
                    ],
                    [
                        116.60255871768436,
                        151.18169694890474
                    ],
                    [
                        117.09718520676188,
                        152.16988834118627
                    ],
                    [
                        118.46864956284003,
                        151.08974410776875
                    ],
                    [
                        121.03171409714969,
                        151.4345426160304
                    ],
                    [
                        122.24579729761217,
                        153.22641358803685
                    ],
                    [
                        121.43640849730377,
                        155.63569669182777
                    ],
                    [
                        122.13338218645845,
                        156.5297443691494
                    ],
                    [
                        121.52634058622698,
                        158.33939531887165
                    ],
                    [
                        120.67198574145709,
                        157.49206501097768
                    ],
                    [
                        119.18810627422499,
                        158.63701091900003
                    ],
                    [
                        119.32300440760991,
                        160.21583790382152
                    ],
                    [
                        120.15487623014906,
                        161.3819073562314
                    ],
                    [
                        119.43541951876387,
                        161.95323478167535
                    ],
                    [
                        117.97402307376274,
                        161.2447618574655
                    ],
                    [
                        116.42269453983818,
                        161.0618849696438
                    ],
                    [
                        115.86061898406842,
                        159.8041036553122
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Mahendragarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rewari",
            "dt_code": "085",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        121.14412920830364,
                        150.79086434171194
                    ],
                    [
                        126.06791107684626,
                        151.52647753679298
                    ],
                    [
                        126.67495267707773,
                        152.2847585388007
                    ],
                    [
                        126.80985081046265,
                        154.2134668103186
                    ],
                    [
                        129.46284743369552,
                        154.7870832340314
                    ],
                    [
                        129.39539836700305,
                        154.92472368145167
                    ],
                    [
                        128.54104352223317,
                        156.14008631568345
                    ],
                    [
                        126.94474894384712,
                        157.14843849105966
                    ],
                    [
                        126.67495267707773,
                        158.38518554835213
                    ],
                    [
                        124.7638957874608,
                        159.0261260909095
                    ],
                    [
                        124.83134485415303,
                        158.0417303214009
                    ],
                    [
                        121.43640849730377,
                        155.63569669182777
                    ],
                    [
                        122.24579729761217,
                        153.22641358803685
                    ],
                    [
                        121.03171409714969,
                        151.4345426160304
                    ],
                    [
                        121.14412920830364,
                        150.79086434171194
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Rewari",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Budaun",
            "dt_code": "149",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        160.53438415664505,
                        151.57244320624926
                    ],
                    [
                        161.81591642379976,
                        152.2158373137446
                    ],
                    [
                        162.4454410462622,
                        154.92472368145167
                    ],
                    [
                        163.54710913557074,
                        155.49811113681648
                    ],
                    [
                        167.1893587369584,
                        155.7044854901194
                    ],
                    [
                        168.24606078180545,
                        156.5755809065153
                    ],
                    [
                        168.3584758929594,
                        158.33939531887165
                    ],
                    [
                        167.3242568703431,
                        161.1990443739952
                    ],
                    [
                        166.22258878103457,
                        161.88468503538553
                    ],
                    [
                        166.11017366988062,
                        164.2823819359362
                    ],
                    [
                        163.65952424672446,
                        163.36934792474534
                    ],
                    [
                        163.36724495772455,
                        161.9760841189896
                    ],
                    [
                        162.37799197956974,
                        161.4276202058183
                    ],
                    [
                        159.7474783785674,
                        161.10760593024867
                    ],
                    [
                        159.2978179339516,
                        160.00998255647812
                    ],
                    [
                        155.18342486571737,
                        158.2936039202234
                    ],
                    [
                        154.75624744333254,
                        157.56078240019946
                    ],
                    [
                        155.83543251041033,
                        153.29529556255108
                    ],
                    [
                        157.92635357787367,
                        151.96310321687696
                    ],
                    [
                        159.07298771164392,
                        150.79086434171194
                    ],
                    [
                        160.06224068979827,
                        150.9288151283842
                    ],
                    [
                        160.53438415664505,
                        151.57244320624926
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Budaun",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jaisalmer",
            "dt_code": "114",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        56.28061007247925,
                        160.0328565357271
                    ],
                    [
                        58.46146322886557,
                        159.4151569692762
                    ],
                    [
                        60.08024082948236,
                        160.60461157100235
                    ],
                    [
                        60.417486162944215,
                        162.0674785782145
                    ],
                    [
                        59.42823318478963,
                        162.86698303786255
                    ],
                    [
                        61.18190891879112,
                        163.9400480571705
                    ],
                    [
                        63.00303371948485,
                        163.7117895671082
                    ],
                    [
                        62.126195852484216,
                        166.03868091508775
                    ],
                    [
                        61.60908634117595,
                        167.10987532258997
                    ],
                    [
                        61.60908634117595,
                        167.17822807795392
                    ],
                    [
                        60.5973503407904,
                        167.54273289320355
                    ],
                    [
                        58.75374251786593,
                        164.98966742533537
                    ],
                    [
                        55.223908027632206,
                        167.88439011136103
                    ],
                    [
                        55.336323138786156,
                        169.54621172194743
                    ],
                    [
                        56.34805913917171,
                        170.3651858485856
                    ],
                    [
                        57.47221025071099,
                        172.8426362842216
                    ],
                    [
                        58.64132740671175,
                        173.43309647261856
                    ],
                    [
                        58.59636136225049,
                        176.24654994859424
                    ],
                    [
                        59.540648295943356,
                        177.03989914800133
                    ],
                    [
                        58.843674606789136,
                        178.42178668052077
                    ],
                    [
                        59.31581807363568,
                        179.5989824835898
                    ],
                    [
                        58.59636136225049,
                        180.4361521158973
                    ],
                    [
                        59.47319922925112,
                        181.72511549637915
                    ],
                    [
                        58.438980206634824,
                        183.39719042108936
                    ],
                    [
                        59.158436918020016,
                        184.18752077539858
                    ],
                    [
                        58.97857274017383,
                        185.85741899107563
                    ],
                    [
                        56.46047425032543,
                        186.7369092571068
                    ],
                    [
                        55.651085450017035,
                        185.47392389778616
                    ],
                    [
                        54.9091457164011,
                        185.9250865504817
                    ],
                    [
                        52.66084349332232,
                        184.9775209659755
                    ],
                    [
                        51.761522604090715,
                        183.30684589194766
                    ],
                    [
                        50.502473359166515,
                        184.11979112244381
                    ],
                    [
                        49.73805060331961,
                        183.46494598075537
                    ],
                    [
                        48.141756024933784,
                        184.70670089990205
                    ],
                    [
                        47.489748380240826,
                        183.9617458293563
                    ],
                    [
                        46.45552935762453,
                        184.34554719995333
                    ],
                    [
                        45.39882731277771,
                        186.55653418410571
                    ],
                    [
                        44.432057356853875,
                        186.33104126791648
                    ],
                    [
                        43.487770423160555,
                        187.029982264333
                    ],
                    [
                        44.072329001160824,
                        189.32791509331787
                    ],
                    [
                        42.45355140054426,
                        190.31832748587937
                    ],
                    [
                        41.37436633346647,
                        189.46300151549337
                    ],
                    [
                        39.530758510542,
                        189.30539976182575
                    ],
                    [
                        38.24922624338683,
                        188.4045688859024
                    ],
                    [
                        34.96670499769198,
                        188.74243025542103
                    ],
                    [
                        33.95496899730642,
                        189.28288416519786
                    ],
                    [
                        30.69493077384186,
                        189.05771361222082
                    ],
                    [
                        31.18955726291938,
                        187.50331256193084
                    ],
                    [
                        31.34693841853459,
                        185.47392389778616
                    ],
                    [
                        31.18955726291938,
                        182.5613384056578
                    ],
                    [
                        29.458364551148634,
                        181.77032623342234
                    ],
                    [
                        26.962749083531207,
                        182.1093721422248
                    ],
                    [
                        25.568801705222086,
                        181.79293119452444
                    ],
                    [
                        24.51209966037527,
                        180.86590486368925
                    ],
                    [
                        21.43192561475712,
                        179.4179236398544
                    ],
                    [
                        21.049714236833665,
                        178.39914103405306
                    ],
                    [
                        21.47689165921861,
                        175.02186096690298
                    ],
                    [
                        22.556076726296624,
                        172.13837919308935
                    ],
                    [
                        26.62550375006913,
                        168.52197948114008
                    ],
                    [
                        27.659722772685427,
                        166.95037560349863
                    ],
                    [
                        29.00870410653306,
                        165.87908743327858
                    ],
                    [
                        30.58251566268791,
                        161.83898375903215
                    ],
                    [
                        31.95398001876606,
                        160.26158033940476
                    ],
                    [
                        34.08986713069089,
                        158.40808022470634
                    ],
                    [
                        36.090856109231254,
                        157.99593131913525
                    ],
                    [
                        37.282456287462765,
                        158.43097460883035
                    ],
                    [
                        38.58647157684891,
                        159.91848373139692
                    ],
                    [
                        38.676403665771886,
                        161.47333189734434
                    ],
                    [
                        39.77807175508042,
                        163.23235314168426
                    ],
                    [
                        41.958924911466966,
                        163.43784143100714
                    ],
                    [
                        46.34311424647058,
                        161.33619334841933
                    ],
                    [
                        48.95114482524218,
                        160.71894079876108
                    ],
                    [
                        53.132986960168864,
                        160.62747799697928
                    ],
                    [
                        56.19067798355604,
                        159.3465105819941
                    ],
                    [
                        56.28061007247925,
                        160.0328565357271
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Jaisalmer",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bahraich",
            "dt_code": "180",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        194.50623074736654,
                        163.89439865795111
                    ],
                    [
                        194.84347608082817,
                        160.14722207087277
                    ],
                    [
                        193.22469848021137,
                        158.47676250047056
                    ],
                    [
                        193.24718150244212,
                        156.896403762254
                    ],
                    [
                        190.95391323490162,
                        153.84625522302457
                    ],
                    [
                        191.13377741274803,
                        151.8482121606139
                    ],
                    [
                        193.494494746981,
                        152.62932447486878
                    ],
                    [
                        193.78677403598113,
                        153.7773946067232
                    ],
                    [
                        195.06830630313607,
                        155.31464721716867
                    ],
                    [
                        196.6196348370604,
                        155.75034321557786
                    ],
                    [
                        197.3840575929073,
                        157.21716907341352
                    ],
                    [
                        201.27362043883363,
                        159.39227513153259
                    ],
                    [
                        200.3068504829098,
                        160.83326277274404
                    ],
                    [
                        199.83470701606302,
                        162.34163422270927
                    ],
                    [
                        200.59912977190993,
                        163.14101752858807
                    ],
                    [
                        200.37429954960226,
                        165.7194799600054
                    ],
                    [
                        201.76824692791092,
                        166.49458525789288
                    ],
                    [
                        203.63433777306614,
                        166.69970501088068
                    ],
                    [
                        204.7809719068366,
                        167.9982616593923
                    ],
                    [
                        202.33032248368067,
                        170.79727501536155
                    ],
                    [
                        201.27362043883363,
                        169.79649258769638
                    ],
                    [
                        200.12698630506338,
                        171.32019452293417
                    ],
                    [
                        199.78974097160153,
                        172.57005251859766
                    ],
                    [
                        197.9236501264461,
                        173.79636286686815
                    ],
                    [
                        196.77701599267607,
                        172.2292661834707
                    ],
                    [
                        196.41728763698325,
                        169.65997998456538
                    ],
                    [
                        194.25891750282767,
                        165.40022301123764
                    ],
                    [
                        194.50623074736654,
                        163.89439865795111
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Bahraich",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nuh",
            "dt_code": "087",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        129.46284743369552,
                        154.7870832340314
                    ],
                    [
                        131.23900618992775,
                        153.0197516283501
                    ],
                    [
                        131.86853081238974,
                        154.465880811518
                    ],
                    [
                        134.3641462800074,
                        155.131164415802
                    ],
                    [
                        133.3299272573911,
                        156.6672504547945
                    ],
                    [
                        133.86951979092987,
                        159.46091977081585
                    ],
                    [
                        136.61244850308617,
                        161.10760593024867
                    ],
                    [
                        135.71312761385457,
                        161.95323478167535
                    ],
                    [
                        132.2057761458516,
                        161.58760606071945
                    ],
                    [
                        131.21652316769678,
                        164.35084096151058
                    ],
                    [
                        129.9574739227728,
                        163.0496773077897
                    ],
                    [
                        130.69941365638874,
                        161.22190326057995
                    ],
                    [
                        130.51954947854256,
                        158.70568443188802
                    ],
                    [
                        131.10410805654283,
                        156.30054404341928
                    ],
                    [
                        129.39539836700305,
                        154.92472368145167
                    ],
                    [
                        129.46284743369552,
                        154.7870832340314
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Nuh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shahjahanpur",
            "dt_code": "152",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        168.3584758929594,
                        158.33939531887165
                    ],
                    [
                        169.7973893157298,
                        157.1713489785168
                    ],
                    [
                        171.28126878296212,
                        154.32820488595405
                    ],
                    [
                        172.67521616127078,
                        155.9566883982585
                    ],
                    [
                        174.0241974951182,
                        155.38344839916596
                    ],
                    [
                        175.4406278956576,
                        156.82766085370406
                    ],
                    [
                        175.4406278956576,
                        153.9151131720855
                    ],
                    [
                        175.8902883402734,
                        152.2847585388007
                    ],
                    [
                        177.84631127435205,
                        152.83603190548757
                    ],
                    [
                        178.92549634143006,
                        153.59375325246106
                    ],
                    [
                        180.9264853199702,
                        153.11160435842453
                    ],
                    [
                        180.22951163081575,
                        153.98396845447013
                    ],
                    [
                        180.04964745296934,
                        155.88790932145122
                    ],
                    [
                        179.3976398082766,
                        157.10261663602358
                    ],
                    [
                        177.48658291865945,
                        158.5683347778994
                    ],
                    [
                        176.09263554035056,
                        160.23870926690432
                    ],
                    [
                        176.54229598496636,
                        162.59290704254033
                    ],
                    [
                        175.14834860665746,
                        163.0496773077897
                    ],
                    [
                        173.61950309496365,
                        164.4421156457542
                    ],
                    [
                        172.20307269442424,
                        163.36934792474534
                    ],
                    [
                        171.03395553842324,
                        164.62465124819516
                    ],
                    [
                        171.79837829427015,
                        166.81365045988605
                    ],
                    [
                        171.77589527203918,
                        167.81606377758595
                    ],
                    [
                        170.5618120715767,
                        166.83643869547728
                    ],
                    [
                        169.64000816011435,
                        165.2861890918914
                    ],
                    [
                        166.11017366988062,
                        164.2823819359362
                    ],
                    [
                        166.22258878103457,
                        161.88468503538553
                    ],
                    [
                        167.3242568703431,
                        161.1990443739952
                    ],
                    [
                        168.3584758929594,
                        158.33939531887165
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Shahjahanpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sikar",
            "dt_code": "111",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        97.98661631059167,
                        163.96287232586815
                    ],
                    [
                        98.34634466628427,
                        162.47869645314313
                    ],
                    [
                        99.47049577782377,
                        161.907535239894
                    ],
                    [
                        98.25641257736106,
                        160.42166971232882
                    ],
                    [
                        98.97586928874648,
                        159.36939300244546
                    ],
                    [
                        99.29063159997736,
                        157.56078240019946
                    ],
                    [
                        100.4822317782091,
                        155.4522469264835
                    ],
                    [
                        102.5057037789802,
                        155.22290817736558
                    ],
                    [
                        104.01206626844305,
                        156.32346539925197
                    ],
                    [
                        103.56240582382725,
                        157.4004377224598
                    ],
                    [
                        105.72077595798282,
                        161.15332573164432
                    ],
                    [
                        106.35030058044481,
                        161.58760606071945
                    ],
                    [
                        106.32781755821406,
                        163.27801922075287
                    ],
                    [
                        108.44122164790838,
                        164.55620254776693
                    ],
                    [
                        110.08248227075592,
                        163.39217938124915
                    ],
                    [
                        112.24085240491172,
                        163.9400480571705
                    ],
                    [
                        113.43245258314323,
                        161.4276202058183
                    ],
                    [
                        115.86061898406842,
                        159.8041036553122
                    ],
                    [
                        116.42269453983818,
                        161.0618849696438
                    ],
                    [
                        117.97402307376274,
                        161.2447618574655
                    ],
                    [
                        117.83912494037781,
                        162.47869645314313
                    ],
                    [
                        115.97303409522237,
                        164.94404441354504
                    ],
                    [
                        116.15289827306879,
                        165.87908743327858
                    ],
                    [
                        115.63578876176052,
                        167.7249580256406
                    ],
                    [
                        113.85963000552829,
                        168.9772645000798
                    ],
                    [
                        113.02775818298915,
                        168.56751307788642
                    ],
                    [
                        110.95932013775655,
                        170.20596948770128
                    ],
                    [
                        109.88013507067876,
                        169.6372268965
                    ],
                    [
                        109.11571231483185,
                        170.5016460337123
                    ],
                    [
                        107.90162911436937,
                        170.56987232648459
                    ],
                    [
                        106.95734218067605,
                        171.70660478597415
                    ],
                    [
                        106.7774780028301,
                        172.7972084514032
                    ],
                    [
                        105.04628529105912,
                        173.09246939293888
                    ],
                    [
                        103.15771142367294,
                        171.61569201771516
                    ],
                    [
                        104.50669275752057,
                        169.93299503637013
                    ],
                    [
                        102.66308493459587,
                        169.3186540224981
                    ],
                    [
                        100.66209595605551,
                        169.70548531415028
                    ],
                    [
                        100.07753737805479,
                        167.74773488942805
                    ],
                    [
                        98.05406537728413,
                        166.8820143126121
                    ],
                    [
                        98.6386239552844,
                        165.83348672606246
                    ],
                    [
                        97.98661631059167,
                        163.96287232586815
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Sikar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Palwal",
            "dt_code": "089",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        138.90571677062644,
                        155.17703688688727
                    ],
                    [
                        138.54598841493407,
                        157.1942591726281
                    ],
                    [
                        139.51275837085768,
                        158.7743553171195
                    ],
                    [
                        139.33289419301173,
                        159.8269802523929
                    ],
                    [
                        137.73659961462545,
                        161.01616284966627
                    ],
                    [
                        136.61244850308617,
                        161.10760593024867
                    ],
                    [
                        133.86951979092987,
                        159.46091977081585
                    ],
                    [
                        133.3299272573911,
                        156.6672504547945
                    ],
                    [
                        134.3641462800074,
                        155.131164415802
                    ],
                    [
                        134.83628974685394,
                        154.16756950802608
                    ],
                    [
                        138.90571677062644,
                        155.17703688688727
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Palwal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Alwar",
            "dt_code": "104",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        120.15487623014906,
                        161.3819073562314
                    ],
                    [
                        119.32300440760991,
                        160.21583790382152
                    ],
                    [
                        119.18810627422499,
                        158.63701091900003
                    ],
                    [
                        120.67198574145709,
                        157.49206501097768
                    ],
                    [
                        121.52634058622698,
                        158.33939531887165
                    ],
                    [
                        122.13338218645845,
                        156.5297443691494
                    ],
                    [
                        121.43640849730377,
                        155.63569669182777
                    ],
                    [
                        124.83134485415303,
                        158.0417303214009
                    ],
                    [
                        124.7638957874608,
                        159.0261260909095
                    ],
                    [
                        126.67495267707773,
                        158.38518554835213
                    ],
                    [
                        126.94474894384712,
                        157.14843849105966
                    ],
                    [
                        128.54104352223317,
                        156.14008631568345
                    ],
                    [
                        129.39539836700305,
                        154.92472368145167
                    ],
                    [
                        131.10410805654283,
                        156.30054404341928
                    ],
                    [
                        130.51954947854256,
                        158.70568443188802
                    ],
                    [
                        130.69941365638874,
                        161.22190326057995
                    ],
                    [
                        129.9574739227728,
                        163.0496773077897
                    ],
                    [
                        131.21652316769678,
                        164.35084096151058
                    ],
                    [
                        130.5869985452348,
                        165.1949568073179
                    ],
                    [
                        132.0708780124669,
                        166.67691506672844
                    ],
                    [
                        131.6661836123128,
                        168.6358113493958
                    ],
                    [
                        132.58798752377515,
                        168.6585768736831
                    ],
                    [
                        133.6896556130837,
                        169.88749534815088
                    ],
                    [
                        132.3406742792365,
                        172.57005251859766
                    ],
                    [
                        130.90176085646613,
                        172.81992250751534
                    ],
                    [
                        129.5977455670802,
                        171.38839046176446
                    ],
                    [
                        128.94573792238748,
                        173.00162489949003
                    ],
                    [
                        126.85481685492391,
                        173.29685317392546
                    ],
                    [
                        126.33770734361565,
                        172.47918231709446
                    ],
                    [
                        124.96624298753795,
                        172.34286862329645
                    ],
                    [
                        123.52732956476734,
                        173.93256937612904
                    ],
                    [
                        120.96426503045745,
                        174.1141624638558
                    ],
                    [
                        120.73943480814955,
                        172.13837919308935
                    ],
                    [
                        119.43541951876387,
                        172.13837919308935
                    ],
                    [
                        118.558581651763,
                        171.32019452293417
                    ],
                    [
                        119.30052138537894,
                        170.0694873349259
                    ],
                    [
                        120.33474040799547,
                        167.33770788790304
                    ],
                    [
                        119.68273276330251,
                        165.0124785017232
                    ],
                    [
                        120.42467249691867,
                        164.76154090986057
                    ],
                    [
                        120.15487623014906,
                        161.3819073562314
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Alwar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "North Sikkim",
            "dt_code": "241",
            "st_nm": "Sikkim",
            "st_code": "11",
            "alpha2code": "SK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        300.3787824321489,
                        168.9772645000798
                    ],
                    [
                        299.36704643176336,
                        168.24875410662833
                    ],
                    [
                        298.51269158699347,
                        166.56296107309646
                    ],
                    [
                        297.4110234976847,
                        166.69970501088068
                    ],
                    [
                        296.35432145283767,
                        165.5598584883116
                    ],
                    [
                        294.9603740745292,
                        164.94404441354504
                    ],
                    [
                        296.1744572749917,
                        162.11317407409388
                    ],
                    [
                        296.28687238614566,
                        159.5753216775937
                    ],
                    [
                        300.153952209841,
                        158.31649976570378
                    ],
                    [
                        300.5586466099953,
                        157.69820926835
                    ],
                    [
                        302.37977141068905,
                        156.96514402845133
                    ],
                    [
                        304.40324341146015,
                        157.33171417818147
                    ],
                    [
                        305.6847756786151,
                        158.7285750188995
                    ],
                    [
                        305.57236056746115,
                        159.78122676725843
                    ],
                    [
                        306.3367833233078,
                        160.9933013548614
                    ],
                    [
                        305.8646398564615,
                        161.6561670861194
                    ],
                    [
                        305.75222474530756,
                        164.1226341649687
                    ],
                    [
                        304.5156585226141,
                        165.83348672606246
                    ],
                    [
                        304.94283594499916,
                        168.29429450247613
                    ],
                    [
                        300.3787824321489,
                        168.9772645000798
                    ]
                ]
            ]
        },
        "id": "TT-Sikkim-North Sikkim",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Aligarh",
            "dt_code": "143",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        142.32313614970644,
                        156.46098735860056
                    ],
                    [
                        143.9194307280925,
                        155.63569669182777
                    ],
                    [
                        144.99861579517028,
                        156.94223089994586
                    ],
                    [
                        146.16773295117127,
                        157.3088057437136
                    ],
                    [
                        146.70732548471005,
                        156.46098735860056
                    ],
                    [
                        148.88817864109683,
                        157.33171417818147
                    ],
                    [
                        151.24889597532956,
                        156.16300973088806
                    ],
                    [
                        152.30559802017638,
                        157.21716907341352
                    ],
                    [
                        153.29485099833119,
                        156.73599953101012
                    ],
                    [
                        154.75624744333254,
                        157.56078240019946
                    ],
                    [
                        155.18342486571737,
                        158.2936039202234
                    ],
                    [
                        153.22740193163872,
                        161.7932813260926
                    ],
                    [
                        152.2156659312534,
                        161.40476392579268
                    ],
                    [
                        151.69855641994536,
                        162.7984679314663
                    ],
                    [
                        149.7874995303282,
                        164.1682778204633
                    ],
                    [
                        148.23617099640364,
                        162.38732278742117
                    ],
                    [
                        146.5949103735561,
                        162.52438155447993
                    ],
                    [
                        146.5949103735561,
                        164.1226341649687
                    ],
                    [
                        145.83048761770942,
                        165.33180351769062
                    ],
                    [
                        144.77378557286238,
                        165.60546605189722
                    ],
                    [
                        143.31238912786102,
                        163.57482067574546
                    ],
                    [
                        143.51473632793818,
                        161.70187298979982
                    ],
                    [
                        142.79527961655276,
                        160.21583790382152
                    ],
                    [
                        140.99663783809,
                        159.14055568314882
                    ],
                    [
                        139.33289419301173,
                        159.8269802523929
                    ],
                    [
                        139.51275837085768,
                        158.7743553171195
                    ],
                    [
                        138.54598841493407,
                        157.1942591726281
                    ],
                    [
                        139.89496974878125,
                        157.14843849105966
                    ],
                    [
                        142.32313614970644,
                        156.46098735860056
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Aligarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kasganj",
            "dt_code": "202",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        155.18342486571737,
                        158.2936039202234
                    ],
                    [
                        159.2978179339516,
                        160.00998255647812
                    ],
                    [
                        159.7474783785674,
                        161.10760593024867
                    ],
                    [
                        162.37799197956974,
                        161.4276202058183
                    ],
                    [
                        163.36724495772455,
                        161.9760841189896
                    ],
                    [
                        163.65952424672446,
                        163.36934792474534
                    ],
                    [
                        162.8501354464163,
                        164.67028228182795
                    ],
                    [
                        164.131667713571,
                        165.60546605189722
                    ],
                    [
                        162.06322966833864,
                        165.9474863526035
                    ],
                    [
                        160.89411251233787,
                        164.46493359971532
                    ],
                    [
                        160.24210486764468,
                        165.6738752539506
                    ],
                    [
                        159.27533491172085,
                        165.46863993108872
                    ],
                    [
                        156.48744015510306,
                        163.82592240425873
                    ],
                    [
                        156.35254202171836,
                        162.88982083018118
                    ],
                    [
                        155.07100975456342,
                        162.41016663678363
                    ],
                    [
                        153.76699446517773,
                        162.91265833429384
                    ],
                    [
                        153.22740193163872,
                        161.7932813260926
                    ],
                    [
                        155.18342486571737,
                        158.2936039202234
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Kasganj",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mathura",
            "dt_code": "145",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        135.71312761385457,
                        161.95323478167535
                    ],
                    [
                        136.61244850308617,
                        161.10760593024867
                    ],
                    [
                        137.73659961462545,
                        161.01616284966627
                    ],
                    [
                        139.33289419301173,
                        159.8269802523929
                    ],
                    [
                        140.99663783809,
                        159.14055568314882
                    ],
                    [
                        142.79527961655276,
                        160.21583790382152
                    ],
                    [
                        143.51473632793818,
                        161.70187298979982
                    ],
                    [
                        143.31238912786102,
                        163.57482067574546
                    ],
                    [
                        144.77378557286238,
                        165.60546605189722
                    ],
                    [
                        144.59392139501597,
                        169.59171987372224
                    ],
                    [
                        145.98786877332486,
                        169.79649258769638
                    ],
                    [
                        145.20096299524744,
                        170.79727501536155
                    ],
                    [
                        144.07681188370793,
                        170.27420676077685
                    ],
                    [
                        142.4355512608604,
                        171.2065290101267
                    ],
                    [
                        141.0416038825515,
                        171.18379506517118
                    ],
                    [
                        137.9839128591641,
                        167.63384773127706
                    ],
                    [
                        136.5449994363937,
                        166.47179274953226
                    ],
                    [
                        136.72486361424012,
                        164.3964788773534
                    ],
                    [
                        135.71312761385457,
                        161.95323478167535
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Mathura",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jaipur",
            "dt_code": "110",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        117.97402307376274,
                        161.2447618574655
                    ],
                    [
                        119.43541951876387,
                        161.95323478167535
                    ],
                    [
                        120.15487623014906,
                        161.3819073562314
                    ],
                    [
                        120.42467249691867,
                        164.76154090986057
                    ],
                    [
                        119.68273276330251,
                        165.0124785017232
                    ],
                    [
                        120.33474040799547,
                        167.33770788790304
                    ],
                    [
                        119.30052138537894,
                        170.0694873349259
                    ],
                    [
                        118.558581651763,
                        171.32019452293417
                    ],
                    [
                        119.43541951876387,
                        172.13837919308935
                    ],
                    [
                        120.73943480814955,
                        172.13837919308935
                    ],
                    [
                        120.96426503045745,
                        174.1141624638558
                    ],
                    [
                        120.35722343022621,
                        174.9537989948446
                    ],
                    [
                        120.0424611189951,
                        176.67726718769262
                    ],
                    [
                        120.67198574145709,
                        177.92351901161936
                    ],
                    [
                        119.84011391891818,
                        178.46707714994767
                    ],
                    [
                        118.96327605191732,
                        180.2099269173329
                    ],
                    [
                        119.52535160768707,
                        181.56686935887058
                    ],
                    [
                        119.09817418530201,
                        183.0583761349638
                    ],
                    [
                        118.3337514294551,
                        182.78728088915636
                    ],
                    [
                        116.96228707337718,
                        183.41977587782077
                    ],
                    [
                        114.10694325006693,
                        183.08096564677084
                    ],
                    [
                        112.5556147161426,
                        184.30039814015527
                    ],
                    [
                        110.50965969314075,
                        184.25524800266112
                    ],
                    [
                        108.82343302583172,
                        183.46494598075537
                    ],
                    [
                        107.51941773644603,
                        183.6004498067341
                    ],
                    [
                        106.95734218067605,
                        182.4031625484456
                    ],
                    [
                        105.11373435775158,
                        183.12614385898098
                    ],
                    [
                        104.59662484644332,
                        184.00690297566803
                    ],
                    [
                        103.04529631251899,
                        182.51614665850502
                    ],
                    [
                        103.0902623569807,
                        180.86590486368925
                    ],
                    [
                        101.6063828897486,
                        180.39090925889997
                    ],
                    [
                        100.86444315613267,
                        177.85556311471964
                    ],
                    [
                        101.76376404536427,
                        176.97191103428338
                    ],
                    [
                        102.2808735566723,
                        176.06516547771406
                    ],
                    [
                        104.50669275752057,
                        176.29189330186995
                    ],
                    [
                        105.09125133552061,
                        175.58894700200864
                    ],
                    [
                        105.33856458005971,
                        173.84176615060346
                    ],
                    [
                        107.00230822513777,
                        173.66014632986042
                    ],
                    [
                        106.7774780028301,
                        172.7972084514032
                    ],
                    [
                        106.95734218067605,
                        171.70660478597415
                    ],
                    [
                        107.90162911436937,
                        170.56987232648459
                    ],
                    [
                        109.11571231483185,
                        170.5016460337123
                    ],
                    [
                        109.88013507067876,
                        169.6372268965
                    ],
                    [
                        110.95932013775655,
                        170.20596948770128
                    ],
                    [
                        113.02775818298915,
                        168.56751307788642
                    ],
                    [
                        113.85963000552829,
                        168.9772645000798
                    ],
                    [
                        115.63578876176052,
                        167.7249580256406
                    ],
                    [
                        116.15289827306879,
                        165.87908743327858
                    ],
                    [
                        115.97303409522237,
                        164.94404441354504
                    ],
                    [
                        117.83912494037781,
                        162.47869645314313
                    ],
                    [
                        117.97402307376274,
                        161.2447618574655
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Jaipur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shrawasti",
            "dt_code": "181",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        204.7809719068366,
                        167.9982616593923
                    ],
                    [
                        203.63433777306614,
                        166.69970501088068
                    ],
                    [
                        201.76824692791092,
                        166.49458525789288
                    ],
                    [
                        200.37429954960226,
                        165.7194799600054
                    ],
                    [
                        200.59912977190993,
                        163.14101752858807
                    ],
                    [
                        199.83470701606302,
                        162.34163422270927
                    ],
                    [
                        200.3068504829098,
                        160.83326277274404
                    ],
                    [
                        201.27362043883363,
                        159.39227513153259
                    ],
                    [
                        203.36454150629697,
                        160.9933013548614
                    ],
                    [
                        204.48869261783648,
                        159.87273257371726
                    ],
                    [
                        206.0849871962223,
                        159.87273257371726
                    ],
                    [
                        206.8494099520692,
                        160.83326277274404
                    ],
                    [
                        208.1084591969934,
                        161.1990443739952
                    ],
                    [
                        206.8943759965307,
                        163.00400546909435
                    ],
                    [
                        206.30981741853043,
                        164.69309736870974
                    ],
                    [
                        205.38801350706808,
                        165.58266241300635
                    ],
                    [
                        206.15243626291476,
                        168.54474642110074
                    ],
                    [
                        204.7809719068366,
                        167.9982616593923
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Shrawasti",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jodhpur",
            "dt_code": "113",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        58.97857274017383,
                        185.85741899107563
                    ],
                    [
                        59.158436918020016,
                        184.18752077539858
                    ],
                    [
                        58.438980206634824,
                        183.39719042108936
                    ],
                    [
                        59.47319922925112,
                        181.72511549637915
                    ],
                    [
                        58.59636136225049,
                        180.4361521158973
                    ],
                    [
                        59.31581807363568,
                        179.5989824835898
                    ],
                    [
                        58.843674606789136,
                        178.42178668052077
                    ],
                    [
                        59.540648295943356,
                        177.03989914800133
                    ],
                    [
                        58.59636136225049,
                        176.24654994859424
                    ],
                    [
                        58.64132740671175,
                        173.43309647261856
                    ],
                    [
                        57.47221025071099,
                        172.8426362842216
                    ],
                    [
                        56.34805913917171,
                        170.3651858485856
                    ],
                    [
                        55.336323138786156,
                        169.54621172194743
                    ],
                    [
                        55.223908027632206,
                        167.88439011136103
                    ],
                    [
                        58.75374251786593,
                        164.98966742533537
                    ],
                    [
                        60.5973503407904,
                        167.54273289320355
                    ],
                    [
                        61.60908634117595,
                        167.17822807795392
                    ],
                    [
                        66.17313985402598,
                        164.92123247810133
                    ],
                    [
                        67.67950234348905,
                        166.3122372095071
                    ],
                    [
                        70.17511781110625,
                        165.49144499920158
                    ],
                    [
                        70.17511781110625,
                        167.42883295405016
                    ],
                    [
                        72.42342003418526,
                        168.8634538563478
                    ],
                    [
                        73.81736741249415,
                        168.08935379183606
                    ],
                    [
                        74.58179016834083,
                        168.54474642110074
                    ],
                    [
                        74.69420527949478,
                        170.09223506483931
                    ],
                    [
                        74.22206181264823,
                        171.50204474679418
                    ],
                    [
                        74.51434110164882,
                        176.1558599250268
                    ],
                    [
                        75.72842430211108,
                        177.35714421081582
                    ],
                    [
                        77.50458305834354,
                        177.5837147715933
                    ],
                    [
                        78.76363230326751,
                        177.06256130112206
                    ],
                    [
                        81.4615949709621,
                        177.42511827023213
                    ],
                    [
                        82.72064421588607,
                        179.1689391318501
                    ],
                    [
                        82.31594981573221,
                        179.87053793389333
                    ],
                    [
                        83.32768581611754,
                        182.76468785940466
                    ],
                    [
                        84.00217648304147,
                        182.83246613649567
                    ],
                    [
                        85.53102199473483,
                        184.57127633893123
                    ],
                    [
                        84.65418412773397,
                        185.7671918252396
                    ],
                    [
                        84.65418412773397,
                        189.80067584227885
                    ],
                    [
                        82.3609158601937,
                        190.47584587892896
                    ],
                    [
                        81.95622146003961,
                        189.2378521764577
                    ],
                    [
                        79.55053808134517,
                        190.22831116199103
                    ],
                    [
                        78.65121719211356,
                        188.96763796238548
                    ],
                    [
                        77.72941328065122,
                        189.71063518150726
                    ],
                    [
                        77.25726981380467,
                        191.10579008898173
                    ],
                    [
                        75.57104314649564,
                        190.47584587892896
                    ],
                    [
                        75.12138270187984,
                        191.08329564260998
                    ],
                    [
                        73.50260510126327,
                        191.37570288992254
                    ],
                    [
                        73.36770696787835,
                        192.18521387056148
                    ],
                    [
                        72.1311407451849,
                        193.24156319692275
                    ],
                    [
                        70.31001594449117,
                        193.82567808910835
                    ],
                    [
                        67.16239283218079,
                        192.02783564800166
                    ],
                    [
                        68.24157789925835,
                        190.99331522071571
                    ],
                    [
                        68.17412883256611,
                        188.56224496639715
                    ],
                    [
                        66.78018145425744,
                        187.05252447170585
                    ],
                    [
                        64.77919247571731,
                        186.69181709323357
                    ],
                    [
                        64.08221878656263,
                        187.72866659606564
                    ],
                    [
                        63.07048278617731,
                        187.59345737461115
                    ],
                    [
                        61.96881469686855,
                        188.3820426680805
                    ],
                    [
                        58.97857274017383,
                        185.85741899107563
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Jodhpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sitapur",
            "dt_code": "154",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        180.22951163081575,
                        163.91722350120472
                    ],
                    [
                        182.27546665381738,
                        162.77562898611973
                    ],
                    [
                        183.26471963197196,
                        161.77042967585152
                    ],
                    [
                        184.54625189912713,
                        162.7527897524651
                    ],
                    [
                        185.0858444326659,
                        161.29047818221875
                    ],
                    [
                        187.80629012259124,
                        162.09032647061485
                    ],
                    [
                        189.73983003443914,
                        160.28445112134392
                    ],
                    [
                        190.21197350128568,
                        161.5190424309739
                    ],
                    [
                        192.99986825790347,
                        162.18171515120747
                    ],
                    [
                        194.50623074736654,
                        163.89439865795111
                    ],
                    [
                        194.25891750282767,
                        165.40022301123764
                    ],
                    [
                        196.41728763698325,
                        169.65997998456538
                    ],
                    [
                        195.63038185890582,
                        170.93370313051338
                    ],
                    [
                        194.14650239167372,
                        169.6372268965
                    ],
                    [
                        193.04483430236496,
                        171.1383263327071
                    ],
                    [
                        192.01061527974866,
                        170.63809608678525
                    ],
                    [
                        191.3361246128252,
                        171.5702339508794
                    ],
                    [
                        189.8972111900548,
                        171.2292626742571
                    ],
                    [
                        189.33513563428505,
                        172.57005251859766
                    ],
                    [
                        187.80629012259124,
                        173.50121435698998
                    ],
                    [
                        187.3791127002064,
                        171.88841686532794
                    ],
                    [
                        185.85026718851282,
                        171.61569201771516
                    ],
                    [
                        184.5012858546654,
                        170.84275217827252
                    ],
                    [
                        183.26471963197196,
                        170.75179672770935
                    ],
                    [
                        182.29794967604812,
                        168.54474642110074
                    ],
                    [
                        180.4318588308929,
                        166.54016941965443
                    ],
                    [
                        180.22951163081575,
                        163.91722350120472
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Sitapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bharatpur",
            "dt_code": "105",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        131.21652316769678,
                        164.35084096151058
                    ],
                    [
                        132.2057761458516,
                        161.58760606071945
                    ],
                    [
                        135.71312761385457,
                        161.95323478167535
                    ],
                    [
                        136.72486361424012,
                        164.3964788773534
                    ],
                    [
                        136.5449994363937,
                        166.47179274953226
                    ],
                    [
                        137.9839128591641,
                        167.63384773127706
                    ],
                    [
                        141.0416038825515,
                        171.18379506517118
                    ],
                    [
                        141.2214680603979,
                        172.3655882717078
                    ],
                    [
                        139.6476565042426,
                        173.34226872255766
                    ],
                    [
                        141.2664341048594,
                        175.65698568698423
                    ],
                    [
                        138.748335615011,
                        176.8132624493296
                    ],
                    [
                        137.39935428116382,
                        177.96882156813717
                    ],
                    [
                        138.23122610370297,
                        179.4179236398544
                    ],
                    [
                        136.6574145475479,
                        179.93842064698754
                    ],
                    [
                        135.8480257472395,
                        178.80672068656747
                    ],
                    [
                        132.9701989016985,
                        177.44777573925705
                    ],
                    [
                        131.91349685685122,
                        176.1558599250268
                    ],
                    [
                        131.7111496567743,
                        174.47729522165986
                    ],
                    [
                        130.90176085646613,
                        172.81992250751534
                    ],
                    [
                        132.3406742792365,
                        172.57005251859766
                    ],
                    [
                        133.6896556130837,
                        169.88749534815088
                    ],
                    [
                        132.58798752377515,
                        168.6585768736831
                    ],
                    [
                        131.6661836123128,
                        168.6358113493958
                    ],
                    [
                        132.0708780124669,
                        166.67691506672844
                    ],
                    [
                        130.5869985452348,
                        165.1949568073179
                    ],
                    [
                        131.21652316769678,
                        164.35084096151058
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Bharatpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hathras",
            "dt_code": "144",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        144.77378557286238,
                        165.60546605189722
                    ],
                    [
                        145.83048761770942,
                        165.33180351769062
                    ],
                    [
                        146.5949103735561,
                        164.1226341649687
                    ],
                    [
                        146.5949103735561,
                        162.52438155447993
                    ],
                    [
                        148.23617099640364,
                        162.38732278742117
                    ],
                    [
                        149.7874995303282,
                        164.1682778204633
                    ],
                    [
                        151.69855641994536,
                        162.7984679314663
                    ],
                    [
                        152.2156659312534,
                        161.40476392579268
                    ],
                    [
                        153.22740193163872,
                        161.7932813260926
                    ],
                    [
                        153.76699446517773,
                        162.91265833429384
                    ],
                    [
                        153.8344435318702,
                        164.0998119065743
                    ],
                    [
                        152.35056406463787,
                        165.58266241300635
                    ],
                    [
                        151.13648086417538,
                        165.53705427779244
                    ],
                    [
                        149.2479069967892,
                        166.8820143126121
                    ],
                    [
                        149.157974907866,
                        168.45367696200634
                    ],
                    [
                        148.23617099640364,
                        169.15934682589983
                    ],
                    [
                        145.98786877332486,
                        169.79649258769638
                    ],
                    [
                        144.59392139501597,
                        169.59171987372224
                    ],
                    [
                        144.77378557286238,
                        165.60546605189722
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Hathras",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nagaur",
            "dt_code": "112",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        85.59847106142706,
                        166.28944242047413
                    ],
                    [
                        86.09309755050458,
                        167.08709050193585
                    ],
                    [
                        87.86925630673704,
                        166.33503171338492
                    ],
                    [
                        88.6336790625835,
                        167.26936110379586
                    ],
                    [
                        90.43232084104693,
                        168.20321257703216
                    ],
                    [
                        90.31990572989275,
                        166.92758879115172
                    ],
                    [
                        91.17426057466287,
                        165.24057352182103
                    ],
                    [
                        92.45579284181758,
                        163.89439865795111
                    ],
                    [
                        94.6591290204351,
                        163.8030964116494
                    ],
                    [
                        96.66011799897524,
                        164.4421156457542
                    ],
                    [
                        97.98661631059167,
                        163.96287232586815
                    ],
                    [
                        98.6386239552844,
                        165.83348672606246
                    ],
                    [
                        98.05406537728413,
                        166.8820143126121
                    ],
                    [
                        100.07753737805479,
                        167.74773488942805
                    ],
                    [
                        100.66209595605551,
                        169.70548531415028
                    ],
                    [
                        102.66308493459587,
                        169.3186540224981
                    ],
                    [
                        104.50669275752057,
                        169.93299503637013
                    ],
                    [
                        103.15771142367294,
                        171.61569201771516
                    ],
                    [
                        105.04628529105912,
                        173.09246939293888
                    ],
                    [
                        106.7774780028301,
                        172.7972084514032
                    ],
                    [
                        107.00230822513777,
                        173.66014632986042
                    ],
                    [
                        105.33856458005971,
                        173.84176615060346
                    ],
                    [
                        105.09125133552061,
                        175.58894700200864
                    ],
                    [
                        104.50669275752057,
                        176.29189330186995
                    ],
                    [
                        102.2808735566723,
                        176.06516547771406
                    ],
                    [
                        101.76376404536427,
                        176.97191103428338
                    ],
                    [
                        101.85369613428725,
                        176.11051325441753
                    ],
                    [
                        99.96512226690083,
                        175.52090582422
                    ],
                    [
                        98.84097115536156,
                        178.648228050419
                    ],
                    [
                        97.69433702159154,
                        178.89728187494097
                    ],
                    [
                        98.05406537728413,
                        180.11942919620543
                    ],
                    [
                        97.289642621437,
                        181.83814030198755
                    ],
                    [
                        96.09804244320549,
                        181.27294833814375
                    ],
                    [
                        94.23195159805005,
                        183.62303283252294
                    ],
                    [
                        92.8604872419719,
                        183.62303283252294
                    ],
                    [
                        91.71385310820187,
                        184.25524800266112
                    ],
                    [
                        90.926947330124,
                        184.052059043273
                    ],
                    [
                        89.78031319635397,
                        184.88725191374158
                    ],
                    [
                        85.53102199473483,
                        184.57127633893123
                    ],
                    [
                        84.00217648304147,
                        182.83246613649567
                    ],
                    [
                        83.32768581611754,
                        182.76468785940466
                    ],
                    [
                        82.31594981573221,
                        179.87053793389333
                    ],
                    [
                        82.72064421588607,
                        179.1689391318501
                    ],
                    [
                        81.4615949709621,
                        177.42511827023213
                    ],
                    [
                        78.76363230326751,
                        177.06256130112206
                    ],
                    [
                        77.50458305834354,
                        177.5837147715933
                    ],
                    [
                        75.72842430211108,
                        177.35714421081582
                    ],
                    [
                        74.51434110164882,
                        176.1558599250268
                    ],
                    [
                        74.22206181264823,
                        171.50204474679418
                    ],
                    [
                        74.69420527949478,
                        170.09223506483931
                    ],
                    [
                        76.08815265780368,
                        169.6372268965
                    ],
                    [
                        76.53781310241948,
                        170.88822821660284
                    ],
                    [
                        77.99920954742083,
                        170.88822821660284
                    ],
                    [
                        79.88778341480702,
                        169.2731390933668
                    ],
                    [
                        80.7421382595769,
                        166.99594837444795
                    ],
                    [
                        83.10285559380986,
                        166.5857524416062
                    ],
                    [
                        84.4293539054263,
                        166.95037560349863
                    ],
                    [
                        85.59847106142706,
                        166.28944242047413
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Nagaur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Etah",
            "dt_code": "201",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        153.76699446517773,
                        162.91265833429384
                    ],
                    [
                        155.07100975456342,
                        162.41016663678363
                    ],
                    [
                        156.35254202171836,
                        162.88982083018118
                    ],
                    [
                        156.48744015510306,
                        163.82592240425873
                    ],
                    [
                        159.27533491172085,
                        165.46863993108872
                    ],
                    [
                        160.24210486764468,
                        165.6738752539506
                    ],
                    [
                        160.89411251233787,
                        164.46493359971532
                    ],
                    [
                        162.06322966833864,
                        165.9474863526035
                    ],
                    [
                        164.131667713571,
                        165.60546605189722
                    ],
                    [
                        164.96353953611037,
                        166.67691506672844
                    ],
                    [
                        164.69374326934098,
                        169.7509895151403
                    ],
                    [
                        161.97329757941543,
                        168.27152444626068
                    ],
                    [
                        160.03975766756753,
                        168.0438082928511
                    ],
                    [
                        159.2978179339516,
                        167.3604895807561
                    ],
                    [
                        158.51091215587417,
                        169.2048645806396
                    ],
                    [
                        156.8022024663344,
                        169.50070244101585
                    ],
                    [
                        156.0152966882565,
                        167.3604895807561
                    ],
                    [
                        155.0260437101017,
                        166.76807313455453
                    ],
                    [
                        153.47471517617737,
                        168.77240024940983
                    ],
                    [
                        151.94586966448378,
                        169.2048645806396
                    ],
                    [
                        150.50695624171317,
                        168.79516407555445
                    ],
                    [
                        149.157974907866,
                        168.45367696200634
                    ],
                    [
                        149.2479069967892,
                        166.8820143126121
                    ],
                    [
                        151.13648086417538,
                        165.53705427779244
                    ],
                    [
                        152.35056406463787,
                        165.58266241300635
                    ],
                    [
                        153.8344435318702,
                        164.0998119065743
                    ],
                    [
                        153.76699446517773,
                        162.91265833429384
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Etah",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hardoi",
            "dt_code": "155",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        176.54229598496636,
                        162.59290704254033
                    ],
                    [
                        176.99195642958216,
                        162.20456159920553
                    ],
                    [
                        179.37515678604564,
                        162.63858925885557
                    ],
                    [
                        180.22951163081575,
                        163.91722350120472
                    ],
                    [
                        180.4318588308929,
                        166.54016941965443
                    ],
                    [
                        182.29794967604812,
                        168.54474642110074
                    ],
                    [
                        183.26471963197196,
                        170.75179672770935
                    ],
                    [
                        184.5012858546654,
                        170.84275217827252
                    ],
                    [
                        185.85026718851282,
                        171.61569201771516
                    ],
                    [
                        187.3791127002064,
                        171.88841686532794
                    ],
                    [
                        187.80629012259124,
                        173.50121435698998
                    ],
                    [
                        185.15329349935837,
                        173.88716832030417
                    ],
                    [
                        183.73686309881873,
                        174.9537989948446
                    ],
                    [
                        180.99393438666243,
                        176.4732556619427
                    ],
                    [
                        180.18454558635403,
                        174.74959808964928
                    ],
                    [
                        178.2734886967371,
                        175.88376330678574
                    ],
                    [
                        177.35168478527476,
                        175.2714001696047
                    ],
                    [
                        176.2949827404277,
                        176.4279167295623
                    ],
                    [
                        175.8453222958119,
                        175.90643954646964
                    ],
                    [
                        174.60875607311846,
                        172.81992250751534
                    ],
                    [
                        172.7876312724245,
                        172.34286862329645
                    ],
                    [
                        171.57354807196202,
                        171.52477476202688
                    ],
                    [
                        171.056438560654,
                        168.93174109110487
                    ],
                    [
                        171.77589527203918,
                        167.81606377758595
                    ],
                    [
                        171.79837829427015,
                        166.81365045988605
                    ],
                    [
                        171.03395553842324,
                        164.62465124819516
                    ],
                    [
                        172.20307269442424,
                        163.36934792474534
                    ],
                    [
                        173.61950309496365,
                        164.4421156457542
                    ],
                    [
                        175.14834860665746,
                        163.0496773077897
                    ],
                    [
                        176.54229598496636,
                        162.59290704254033
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Hardoi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Farrukhabad",
            "dt_code": "159",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        163.65952424672446,
                        163.36934792474534
                    ],
                    [
                        166.11017366988062,
                        164.2823819359362
                    ],
                    [
                        169.64000816011435,
                        165.2861890918914
                    ],
                    [
                        170.5618120715767,
                        166.83643869547728
                    ],
                    [
                        171.77589527203918,
                        167.81606377758595
                    ],
                    [
                        171.056438560654,
                        168.93174109110487
                    ],
                    [
                        171.57354807196202,
                        171.52477476202688
                    ],
                    [
                        170.7866422938846,
                        172.4337455381941
                    ],
                    [
                        168.5833061152673,
                        171.72933227702867
                    ],
                    [
                        166.7396982923426,
                        171.93386708252342
                    ],
                    [
                        165.59306415857236,
                        171.16106083937143
                    ],
                    [
                        164.69374326934098,
                        169.7509895151403
                    ],
                    [
                        164.96353953611037,
                        166.67691506672844
                    ],
                    [
                        164.131667713571,
                        165.60546605189722
                    ],
                    [
                        162.8501354464163,
                        164.67028228182795
                    ],
                    [
                        163.65952424672446,
                        163.36934792474534
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Farrukhabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Sikkim",
            "dt_code": "242",
            "st_nm": "Sikkim",
            "st_code": "11",
            "alpha2code": "SK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        294.9603740745292,
                        164.94404441354504
                    ],
                    [
                        296.35432145283767,
                        165.5598584883116
                    ],
                    [
                        297.4110234976847,
                        166.69970501088068
                    ],
                    [
                        297.32109140876173,
                        168.47644475161286
                    ],
                    [
                        297.72578580891604,
                        171.41112188001915
                    ],
                    [
                        297.4110234976847,
                        173.00162489949003
                    ],
                    [
                        295.54493265252927,
                        173.36497607844342
                    ],
                    [
                        294.6905778077594,
                        172.9789130780631
                    ],
                    [
                        293.8362229629895,
                        171.54750449671536
                    ],
                    [
                        293.92615505191293,
                        167.26936110379586
                    ],
                    [
                        294.9603740745292,
                        164.94404441354504
                    ]
                ]
            ]
        },
        "id": "TT-Sikkim-West Sikkim",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "South Sikkim",
            "dt_code": "243",
            "st_nm": "Sikkim",
            "st_code": "11",
            "alpha2code": "SK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        297.4110234976847,
                        166.69970501088068
                    ],
                    [
                        298.51269158699347,
                        166.56296107309646
                    ],
                    [
                        299.36704643176336,
                        168.24875410662833
                    ],
                    [
                        300.3787824321489,
                        168.9772645000798
                    ],
                    [
                        299.7267747874562,
                        170.82001373739962
                    ],
                    [
                        301.0982391435341,
                        172.2747079990349
                    ],
                    [
                        299.524427587379,
                        174.00066887169754
                    ],
                    [
                        297.4110234976847,
                        173.00162489949003
                    ],
                    [
                        297.72578580891604,
                        171.41112188001915
                    ],
                    [
                        297.32109140876173,
                        168.47644475161286
                    ],
                    [
                        297.4110234976847,
                        166.69970501088068
                    ]
                ]
            ]
        },
        "id": "TT-Sikkim-South Sikkim",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "East Sikkim",
            "dt_code": "244",
            "st_nm": "Sikkim",
            "st_code": "11",
            "alpha2code": "SK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        300.3787824321489,
                        168.9772645000798
                    ],
                    [
                        304.94283594499916,
                        168.29429450247613
                    ],
                    [
                        306.78644376792363,
                        169.88749534815088
                    ],
                    [
                        306.5616135456157,
                        170.6835771869915
                    ],
                    [
                        305.0777340783836,
                        171.0928564767043
                    ],
                    [
                        304.9203529227684,
                        172.11565674553248
                    ],
                    [
                        303.8861339001521,
                        172.88806299949783
                    ],
                    [
                        302.06500909945817,
                        172.07021101032637
                    ],
                    [
                        301.0982391435341,
                        172.2747079990349
                    ],
                    [
                        299.7267747874562,
                        170.82001373739962
                    ],
                    [
                        300.3787824321489,
                        168.9772645000798
                    ]
                ]
            ]
        },
        "id": "TT-Sikkim-East Sikkim",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Firozabad",
            "dt_code": "147",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        150.50695624171317,
                        168.79516407555445
                    ],
                    [
                        151.94586966448378,
                        169.2048645806396
                    ],
                    [
                        153.47471517617737,
                        168.77240024940983
                    ],
                    [
                        155.0260437101017,
                        166.76807313455453
                    ],
                    [
                        156.0152966882565,
                        167.3604895807561
                    ],
                    [
                        156.8022024663344,
                        169.50070244101585
                    ],
                    [
                        156.91461757748812,
                        171.95659177083468
                    ],
                    [
                        158.21863286687358,
                        172.72906460643605
                    ],
                    [
                        157.47669313325787,
                        174.31843340040427
                    ],
                    [
                        158.15118380018157,
                        175.88376330678574
                    ],
                    [
                        157.11696477756527,
                        176.49592471381243
                    ],
                    [
                        156.12771179941046,
                        177.1532071568726
                    ],
                    [
                        152.91263962040784,
                        175.04454773594563
                    ],
                    [
                        151.15896388640635,
                        173.54662488583386
                    ],
                    [
                        149.6076353524818,
                        173.61473858839332
                    ],
                    [
                        149.1130088634045,
                        172.0247641548629
                    ],
                    [
                        149.72005046363574,
                        170.00124245414185
                    ],
                    [
                        150.50695624171317,
                        168.79516407555445
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Firozabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Champaran",
            "dt_code": "203",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        241.85547556540655,
                        181.72511549637915
                    ],
                    [
                        240.61890934271332,
                        181.2503371251491
                    ],
                    [
                        239.31489405332763,
                        180.39090925889997
                    ],
                    [
                        238.28067503071134,
                        178.39914103405306
                    ],
                    [
                        235.35788214070885,
                        177.03989914800133
                    ],
                    [
                        235.2005009850934,
                        174.45460150978084
                    ],
                    [
                        233.94145174016944,
                        173.79636286686815
                    ],
                    [
                        234.36862916255427,
                        172.29742848697998
                    ],
                    [
                        233.10957991763007,
                        170.63809608678525
                    ],
                    [
                        232.43508925070637,
                        169.6372268965
                    ],
                    [
                        231.9854288060908,
                        168.112126115951
                    ],
                    [
                        234.7058744960159,
                        167.95271389129755
                    ],
                    [
                        235.89747467424786,
                        166.6313343239101
                    ],
                    [
                        238.2581920084806,
                        167.7249580256406
                    ],
                    [
                        238.5729543197117,
                        168.6585768736831
                    ],
                    [
                        243.5866682771773,
                        169.56896593896926
                    ],
                    [
                        244.59840427756285,
                        171.52477476202688
                    ],
                    [
                        243.90143058840863,
                        174.3865187062333
                    ],
                    [
                        245.6101402779484,
                        175.13529203776187
                    ],
                    [
                        244.4410231219474,
                        176.60926583074888
                    ],
                    [
                        245.40779307787125,
                        178.21796597760044
                    ],
                    [
                        245.1604798333326,
                        179.4179236398544
                    ],
                    [
                        242.46251716563802,
                        180.8885207042989
                    ],
                    [
                        241.85547556540655,
                        181.72511549637915
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-West Champaran",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Siddharthnagar",
            "dt_code": "184",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        221.93551786892817,
                        173.29685317392546
                    ],
                    [
                        221.5308234687741,
                        173.52391976080554
                    ],
                    [
                        218.36071733423296,
                        175.11260637842145
                    ],
                    [
                        216.71945671138542,
                        174.3865187062333
                    ],
                    [
                        216.29227928900036,
                        173.34226872255766
                    ],
                    [
                        213.661765687998,
                        173.45580271291612
                    ],
                    [
                        213.27955431007445,
                        173.8190646480001
                    ],
                    [
                        211.77319182061206,
                        172.68363397906506
                    ],
                    [
                        212.58258062092023,
                        171.68387701453582
                    ],
                    [
                        212.3127843541506,
                        169.25038120501512
                    ],
                    [
                        213.54935057684406,
                        168.20321257703216
                    ],
                    [
                        215.57282257761517,
                        168.54474642110074
                    ],
                    [
                        215.82013582215382,
                        167.13265985879747
                    ],
                    [
                        218.72044568992533,
                        166.9048016941964
                    ],
                    [
                        220.2717742238499,
                        167.77051146933468
                    ],
                    [
                        222.54255946915964,
                        167.6794034463416
                    ],
                    [
                        224.04892195862226,
                        169.6372268965
                    ],
                    [
                        222.20531413569756,
                        171.45658387461515
                    ],
                    [
                        221.93551786892817,
                        173.29685317392546
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Siddharthnagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mainpuri",
            "dt_code": "148",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        156.8022024663344,
                        169.50070244101585
                    ],
                    [
                        158.51091215587417,
                        169.2048645806396
                    ],
                    [
                        159.2978179339516,
                        167.3604895807561
                    ],
                    [
                        160.03975766756753,
                        168.0438082928511
                    ],
                    [
                        161.97329757941543,
                        168.27152444626068
                    ],
                    [
                        164.69374326934098,
                        169.7509895151403
                    ],
                    [
                        165.59306415857236,
                        171.16106083937143
                    ],
                    [
                        166.7396982923426,
                        171.93386708252342
                    ],
                    [
                        167.03197758134274,
                        172.88806299949783
                    ],
                    [
                        166.55983411449643,
                        175.38481598806032
                    ],
                    [
                        165.72796229195728,
                        175.83840999723265
                    ],
                    [
                        163.59207518003245,
                        175.08992044168525
                    ],
                    [
                        158.15118380018157,
                        175.88376330678574
                    ],
                    [
                        157.47669313325787,
                        174.31843340040427
                    ],
                    [
                        158.21863286687358,
                        172.72906460643605
                    ],
                    [
                        156.91461757748812,
                        171.95659177083468
                    ],
                    [
                        156.8022024663344,
                        169.50070244101585
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Mainpuri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Maharajganj",
            "dt_code": "187",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        232.43508925070637,
                        169.6372268965
                    ],
                    [
                        233.10957991763007,
                        170.63809608678525
                    ],
                    [
                        231.78308160601364,
                        171.2065290101267
                    ],
                    [
                        231.53576836147477,
                        173.36497607844342
                    ],
                    [
                        230.41161724993526,
                        175.97446660538935
                    ],
                    [
                        228.95022080493413,
                        175.90643954646964
                    ],
                    [
                        227.78110364893314,
                        177.01723671916784
                    ],
                    [
                        226.20729209277806,
                        175.86108679037966
                    ],
                    [
                        224.5210654254688,
                        175.7023434256688
                    ],
                    [
                        225.48783538139264,
                        173.8190646480001
                    ],
                    [
                        223.0371859582367,
                        173.68284978258208
                    ],
                    [
                        221.93551786892817,
                        173.29685317392546
                    ],
                    [
                        222.20531413569756,
                        171.45658387461515
                    ],
                    [
                        224.04892195862226,
                        169.6372268965
                    ],
                    [
                        224.81334471446917,
                        169.6372268965
                    ],
                    [
                        225.780114670393,
                        168.33983376473708
                    ],
                    [
                        225.57776747031585,
                        167.2465782738435
                    ],
                    [
                        228.79283964931847,
                        167.42883295405016
                    ],
                    [
                        231.9854288060908,
                        169.06830792468395
                    ],
                    [
                        232.43508925070637,
                        169.6372268965
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Maharajganj",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Agra",
            "dt_code": "146",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        141.0416038825515,
                        171.18379506517118
                    ],
                    [
                        142.4355512608604,
                        171.2065290101267
                    ],
                    [
                        144.07681188370793,
                        170.27420676077685
                    ],
                    [
                        145.20096299524744,
                        170.79727501536155
                    ],
                    [
                        145.98786877332486,
                        169.79649258769638
                    ],
                    [
                        148.23617099640364,
                        169.15934682589983
                    ],
                    [
                        149.157974907866,
                        168.45367696200634
                    ],
                    [
                        150.50695624171317,
                        168.79516407555445
                    ],
                    [
                        149.72005046363574,
                        170.00124245414185
                    ],
                    [
                        149.1130088634045,
                        172.0247641548629
                    ],
                    [
                        149.6076353524818,
                        173.61473858839332
                    ],
                    [
                        151.15896388640635,
                        173.54662488583386
                    ],
                    [
                        152.91263962040784,
                        175.04454773594563
                    ],
                    [
                        156.12771179941046,
                        177.1532071568726
                    ],
                    [
                        157.11696477756527,
                        176.49592471381243
                    ],
                    [
                        158.8031914448743,
                        178.28590868338034
                    ],
                    [
                        157.7240063777965,
                        178.98783867777342
                    ],
                    [
                        154.26162095425502,
                        179.0783910963238
                    ],
                    [
                        152.05828477563773,
                        178.19531785965967
                    ],
                    [
                        151.51869224209872,
                        177.33448564016356
                    ],
                    [
                        149.51770326355881,
                        177.94617042733995
                    ],
                    [
                        150.237159974944,
                        176.49592471381243
                    ],
                    [
                        148.4385181964808,
                        176.01981659475757
                    ],
                    [
                        147.87644264071128,
                        176.9039204386164
                    ],
                    [
                        146.05531784001732,
                        177.01723671916784
                    ],
                    [
                        142.72783054986053,
                        176.29189330186995
                    ],
                    [
                        142.165754994091,
                        177.24384860279855
                    ],
                    [
                        139.26544512631926,
                        178.17266946701233
                    ],
                    [
                        138.23122610370297,
                        179.4179236398544
                    ],
                    [
                        137.39935428116382,
                        177.96882156813717
                    ],
                    [
                        138.748335615011,
                        176.8132624493296
                    ],
                    [
                        141.2664341048594,
                        175.65698568698423
                    ],
                    [
                        139.6476565042426,
                        173.34226872255766
                    ],
                    [
                        141.2214680603979,
                        172.3655882717078
                    ],
                    [
                        141.0416038825515,
                        171.18379506517118
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Agra",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gonda",
            "dt_code": "183",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        197.9236501264461,
                        173.79636286686815
                    ],
                    [
                        199.78974097160153,
                        172.57005251859766
                    ],
                    [
                        200.12698630506338,
                        171.32019452293417
                    ],
                    [
                        201.27362043883363,
                        169.79649258769638
                    ],
                    [
                        202.33032248368067,
                        170.79727501536155
                    ],
                    [
                        204.7809719068366,
                        167.9982616593923
                    ],
                    [
                        206.15243626291476,
                        168.54474642110074
                    ],
                    [
                        209.43495750860984,
                        171.0928564767043
                    ],
                    [
                        208.4232215082243,
                        172.3883076403149
                    ],
                    [
                        209.83965190876415,
                        174.09146430204362
                    ],
                    [
                        211.2560823093038,
                        173.77366080718775
                    ],
                    [
                        211.77319182061206,
                        172.68363397906506
                    ],
                    [
                        213.27955431007445,
                        173.8190646480001
                    ],
                    [
                        213.32452035453616,
                        175.77037795714432
                    ],
                    [
                        212.24533528745815,
                        176.76793179930314
                    ],
                    [
                        209.70475377537946,
                        175.5435864938285
                    ],
                    [
                        208.44570453045503,
                        177.35714421081582
                    ],
                    [
                        208.4232215082243,
                        178.28590868338034
                    ],
                    [
                        207.8836289746855,
                        177.87821535532873
                    ],
                    [
                        204.71352284014415,
                        178.7840797040859
                    ],
                    [
                        203.47695661745092,
                        178.0367733411859
                    ],
                    [
                        201.3635525277566,
                        177.35714421081582
                    ],
                    [
                        200.9813411498335,
                        176.4052468490122
                    ],
                    [
                        199.38504657144745,
                        174.65883491209587
                    ],
                    [
                        197.9236501264461,
                        173.79636286686815
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Gonda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Barabanki",
            "dt_code": "176",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        196.41728763698325,
                        169.65997998456538
                    ],
                    [
                        196.77701599267607,
                        172.2292661834707
                    ],
                    [
                        197.9236501264461,
                        173.79636286686815
                    ],
                    [
                        199.38504657144745,
                        174.65883491209587
                    ],
                    [
                        200.9813411498335,
                        176.4052468490122
                    ],
                    [
                        201.3635525277566,
                        177.35714421081582
                    ],
                    [
                        199.78974097160153,
                        178.10472264063327
                    ],
                    [
                        198.57565777113905,
                        181.09205101551964
                    ],
                    [
                        198.2384124376772,
                        182.69690714558584
                    ],
                    [
                        195.96762719236744,
                        181.9285552584974
                    ],
                    [
                        195.4280346588289,
                        182.69690714558584
                    ],
                    [
                        193.02235128013422,
                        181.65729735342507
                    ],
                    [
                        192.73007199113385,
                        181.97376110785615
                    ],
                    [
                        193.53946079144248,
                        180.0063009013486
                    ],
                    [
                        189.9421772345163,
                        174.2730418060964
                    ],
                    [
                        190.5267358125168,
                        172.8426362842216
                    ],
                    [
                        189.33513563428505,
                        172.57005251859766
                    ],
                    [
                        189.8972111900548,
                        171.2292626742571
                    ],
                    [
                        191.3361246128252,
                        171.5702339508794
                    ],
                    [
                        192.01061527974866,
                        170.63809608678525
                    ],
                    [
                        193.04483430236496,
                        171.1383263327071
                    ],
                    [
                        194.14650239167372,
                        169.6372268965
                    ],
                    [
                        195.63038185890582,
                        170.93370313051338
                    ],
                    [
                        196.41728763698325,
                        169.65997998456538
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Barabanki",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Darjeeling",
            "dt_code": "327",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        293.8362229629895,
                        171.54750449671536
                    ],
                    [
                        294.6905778077594,
                        172.9789130780631
                    ],
                    [
                        295.54493265252927,
                        173.36497607844342
                    ],
                    [
                        297.4110234976847,
                        173.00162489949003
                    ],
                    [
                        299.524427587379,
                        174.00066887169754
                    ],
                    [
                        299.5019445651483,
                        175.90643954646964
                    ],
                    [
                        300.3113333654567,
                        177.1532071568726
                    ],
                    [
                        299.61435967630223,
                        178.15002079963824
                    ],
                    [
                        299.8841559430716,
                        180.07417869750697
                    ],
                    [
                        299.1646992316864,
                        181.04682396332447
                    ],
                    [
                        298.1754462535316,
                        183.46494598075537
                    ],
                    [
                        296.8039818974537,
                        184.09721403232572
                    ],
                    [
                        294.89292500783677,
                        182.62912399451233
                    ],
                    [
                        295.6348647414525,
                        181.15988955241363
                    ],
                    [
                        296.01707611937604,
                        178.94256082447095
                    ],
                    [
                        295.050306163452,
                        175.99714173838458
                    ],
                    [
                        293.1617322960658,
                        173.38768315540204
                    ],
                    [
                        293.8362229629895,
                        171.54750449671536
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Darjeeling",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dausa",
            "dt_code": "109",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        120.96426503045745,
                        174.1141624638558
                    ],
                    [
                        123.52732956476734,
                        173.93256937612904
                    ],
                    [
                        124.96624298753795,
                        172.34286862329645
                    ],
                    [
                        126.33770734361565,
                        172.47918231709446
                    ],
                    [
                        126.85481685492391,
                        173.29685317392546
                    ],
                    [
                        128.94573792238748,
                        173.00162489949003
                    ],
                    [
                        129.5977455670802,
                        171.38839046176446
                    ],
                    [
                        130.90176085646613,
                        172.81992250751534
                    ],
                    [
                        131.7111496567743,
                        174.47729522165986
                    ],
                    [
                        131.91349685685122,
                        176.1558599250268
                    ],
                    [
                        132.9701989016985,
                        177.44777573925705
                    ],
                    [
                        130.83431178977366,
                        177.28916767247216
                    ],
                    [
                        130.0923720561575,
                        175.65698568698423
                    ],
                    [
                        128.54104352223317,
                        175.43018037519943
                    ],
                    [
                        128.2037981887711,
                        176.97191103428338
                    ],
                    [
                        125.8655638767691,
                        178.7614384474096
                    ],
                    [
                        124.71892974299908,
                        178.7840797040859
                    ],
                    [
                        123.8196088537677,
                        179.84790981638537
                    ],
                    [
                        122.76290680892043,
                        182.33536882985067
                    ],
                    [
                        123.8645748982292,
                        183.41977587782077
                    ],
                    [
                        122.89780494230536,
                        184.79697855879772
                    ],
                    [
                        121.59378965291944,
                        184.11979112244381
                    ],
                    [
                        119.41293649653312,
                        183.64561558830457
                    ],
                    [
                        119.09817418530201,
                        183.0583761349638
                    ],
                    [
                        119.52535160768707,
                        181.56686935887058
                    ],
                    [
                        118.96327605191732,
                        180.2099269173329
                    ],
                    [
                        119.84011391891818,
                        178.46707714994767
                    ],
                    [
                        120.67198574145709,
                        177.92351901161936
                    ],
                    [
                        120.0424611189951,
                        176.67726718769262
                    ],
                    [
                        120.35722343022621,
                        174.9537989948446
                    ],
                    [
                        120.96426503045745,
                        174.1141624638558
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Dausa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kushinagar",
            "dt_code": "189",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        233.10957991763007,
                        170.63809608678525
                    ],
                    [
                        234.36862916255427,
                        172.29742848697998
                    ],
                    [
                        233.94145174016944,
                        173.79636286686815
                    ],
                    [
                        235.2005009850934,
                        174.45460150978084
                    ],
                    [
                        235.35788214070885,
                        177.03989914800133
                    ],
                    [
                        238.28067503071134,
                        178.39914103405306
                    ],
                    [
                        239.31489405332763,
                        180.39090925889997
                    ],
                    [
                        240.61890934271332,
                        181.2503371251491
                    ],
                    [
                        238.59543734194244,
                        181.77032623342234
                    ],
                    [
                        236.12230489655576,
                        181.27294833814375
                    ],
                    [
                        235.22298400732416,
                        182.5613384056578
                    ],
                    [
                        232.1203269394755,
                        179.14630253380824
                    ],
                    [
                        231.0861079168592,
                        180.11942919620543
                    ],
                    [
                        228.99518684939585,
                        180.75282157441626
                    ],
                    [
                        228.90525476047242,
                        179.23684728289294
                    ],
                    [
                        227.46634133770203,
                        177.60637031353116
                    ],
                    [
                        227.78110364893314,
                        177.01723671916784
                    ],
                    [
                        228.95022080493413,
                        175.90643954646964
                    ],
                    [
                        230.41161724993526,
                        175.97446660538935
                    ],
                    [
                        231.53576836147477,
                        173.36497607844342
                    ],
                    [
                        231.78308160601364,
                        171.2065290101267
                    ],
                    [
                        233.10957991763007,
                        170.63809608678525
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Kushinagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kannauj",
            "dt_code": "160",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        171.57354807196202,
                        171.52477476202688
                    ],
                    [
                        172.7876312724245,
                        172.34286862329645
                    ],
                    [
                        174.60875607311846,
                        172.81992250751534
                    ],
                    [
                        175.8453222958119,
                        175.90643954646964
                    ],
                    [
                        174.78862025096487,
                        176.17853284559516
                    ],
                    [
                        174.1366126062719,
                        178.19531785965967
                    ],
                    [
                        173.32722380596374,
                        178.91992148674427
                    ],
                    [
                        172.06817456103954,
                        178.58029852062936
                    ],
                    [
                        169.77490629349904,
                        176.949247778193
                    ],
                    [
                        168.24606078180545,
                        177.13054610640546
                    ],
                    [
                        167.3692229148046,
                        176.1558599250268
                    ],
                    [
                        166.04272460318816,
                        176.33723554968407
                    ],
                    [
                        165.72796229195728,
                        175.83840999723265
                    ],
                    [
                        166.55983411449643,
                        175.38481598806032
                    ],
                    [
                        167.03197758134274,
                        172.88806299949783
                    ],
                    [
                        166.7396982923426,
                        171.93386708252342
                    ],
                    [
                        168.5833061152673,
                        171.72933227702867
                    ],
                    [
                        170.7866422938846,
                        172.4337455381941
                    ],
                    [
                        171.57354807196202,
                        171.52477476202688
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Kannauj",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lucknow",
            "dt_code": "157",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        187.80629012259124,
                        173.50121435698998
                    ],
                    [
                        189.33513563428505,
                        172.57005251859766
                    ],
                    [
                        190.5267358125168,
                        172.8426362842216
                    ],
                    [
                        189.9421772345163,
                        174.2730418060964
                    ],
                    [
                        193.53946079144248,
                        180.0063009013486
                    ],
                    [
                        192.73007199113385,
                        181.97376110785615
                    ],
                    [
                        191.06632834605557,
                        183.32943242960425
                    ],
                    [
                        190.3468716346706,
                        182.87765030107818
                    ],
                    [
                        188.03112034489936,
                        180.6849683311683
                    ],
                    [
                        187.31166363351394,
                        181.0242100288885
                    ],
                    [
                        186.23247856643616,
                        180.09680408338374
                    ],
                    [
                        186.2549615886669,
                        178.46707714994767
                    ],
                    [
                        184.88349723258898,
                        177.17586793172558
                    ],
                    [
                        184.47880283243467,
                        175.40749832019878
                    ],
                    [
                        183.73686309881873,
                        174.9537989948446
                    ],
                    [
                        185.15329349935837,
                        173.88716832030417
                    ],
                    [
                        187.80629012259124,
                        173.50121435698998
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Lucknow",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jalpaiguri",
            "dt_code": "328",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        299.1646992316864,
                        181.04682396332447
                    ],
                    [
                        299.8841559430716,
                        180.07417869750697
                    ],
                    [
                        299.61435967630223,
                        178.15002079963824
                    ],
                    [
                        300.3113333654567,
                        177.1532071568726
                    ],
                    [
                        302.1324581661504,
                        177.1532071568726
                    ],
                    [
                        303.031779055382,
                        176.4052468490122
                    ],
                    [
                        304.44820945592164,
                        176.4052468490122
                    ],
                    [
                        304.8753868783067,
                        175.31676732861854
                    ],
                    [
                        306.04450403430747,
                        175.74770005683268
                    ],
                    [
                        306.8089267901544,
                        175.2714001696047
                    ],
                    [
                        309.2820592355413,
                        177.01723671916784
                    ],
                    [
                        309.304542257772,
                        177.81025780847506
                    ],
                    [
                        308.6075685686178,
                        181.2503371251491
                    ],
                    [
                        309.5293724800799,
                        181.88334832325967
                    ],
                    [
                        309.73171968015686,
                        183.01319629984738
                    ],
                    [
                        307.41596839038584,
                        182.78728088915636
                    ],
                    [
                        306.8988588790776,
                        184.18752077539858
                    ],
                    [
                        304.44820945592164,
                        186.21828477304
                    ],
                    [
                        303.59385461115176,
                        185.2708656851467
                    ],
                    [
                        301.07575612130336,
                        183.7585253177803
                    ],
                    [
                        299.45697852068656,
                        182.380564913333
                    ],
                    [
                        299.1646992316864,
                        181.04682396332447
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Jalpaiguri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Basti",
            "dt_code": "185",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        213.27955431007445,
                        173.8190646480001
                    ],
                    [
                        213.661765687998,
                        173.45580271291612
                    ],
                    [
                        216.29227928900036,
                        173.34226872255766
                    ],
                    [
                        216.71945671138542,
                        174.3865187062333
                    ],
                    [
                        218.36071733423296,
                        175.11260637842145
                    ],
                    [
                        217.8436078229247,
                        176.17853284559516
                    ],
                    [
                        218.09092106746334,
                        178.8293613948744
                    ],
                    [
                        219.43990240131075,
                        180.0063009013486
                    ],
                    [
                        218.20333617861706,
                        181.61208389963394
                    ],
                    [
                        217.10166808930876,
                        182.29017166189567
                    ],
                    [
                        215.52785653315345,
                        182.51614665850502
                    ],
                    [
                        214.80839982176826,
                        181.7025097203993
                    ],
                    [
                        213.54935057684406,
                        181.7025097203993
                    ],
                    [
                        212.02050506515047,
                        180.9111362725393
                    ],
                    [
                        210.82890488691896,
                        180.9111362725393
                    ],
                    [
                        209.21012728630194,
                        179.5084552502468
                    ],
                    [
                        208.4232215082243,
                        178.28590868338034
                    ],
                    [
                        208.44570453045503,
                        177.35714421081582
                    ],
                    [
                        209.70475377537946,
                        175.5435864938285
                    ],
                    [
                        212.24533528745815,
                        176.76793179930314
                    ],
                    [
                        213.32452035453616,
                        175.77037795714432
                    ],
                    [
                        213.27955431007445,
                        173.8190646480001
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Basti",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gorakhpur",
            "dt_code": "188",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        221.5308234687741,
                        173.52391976080554
                    ],
                    [
                        221.93551786892817,
                        173.29685317392546
                    ],
                    [
                        223.0371859582367,
                        173.68284978258208
                    ],
                    [
                        225.48783538139264,
                        173.8190646480001
                    ],
                    [
                        224.5210654254688,
                        175.7023434256688
                    ],
                    [
                        226.20729209277806,
                        175.86108679037966
                    ],
                    [
                        227.78110364893314,
                        177.01723671916784
                    ],
                    [
                        227.46634133770203,
                        177.60637031353116
                    ],
                    [
                        228.90525476047242,
                        179.23684728289294
                    ],
                    [
                        228.99518684939585,
                        180.75282157441626
                    ],
                    [
                        228.59049244924176,
                        183.17132098944757
                    ],
                    [
                        227.06164693754795,
                        183.53269910912866
                    ],
                    [
                        227.19654507093287,
                        184.77440954761772
                    ],
                    [
                        228.9277377827034,
                        186.35359176377506
                    ],
                    [
                        229.0176698716266,
                        188.02158700415328
                    ],
                    [
                        227.6237224933177,
                        187.1201494915336
                    ],
                    [
                        226.63446951516312,
                        187.09760808528705
                    ],
                    [
                        225.91501280377747,
                        187.1201494915336
                    ],
                    [
                        224.72341262554596,
                        185.87997511227115
                    ],
                    [
                        223.1271180471599,
                        185.13548145772234
                    ],
                    [
                        221.71068764662027,
                        185.29342878287366
                    ],
                    [
                        220.8113667573889,
                        184.88725191374158
                    ],
                    [
                        222.04793298008212,
                        182.76468785940466
                    ],
                    [
                        221.3959253353894,
                        181.45382825042935
                    ],
                    [
                        221.193578135312,
                        178.37649511305597
                    ],
                    [
                        222.0029669356204,
                        177.28916767247216
                    ],
                    [
                        222.94725386931373,
                        177.13054610640546
                    ],
                    [
                        223.05966898046768,
                        175.49822487757262
                    ],
                    [
                        221.5308234687741,
                        173.52391976080554
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Gorakhpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sant Kabir Nagar",
            "dt_code": "186",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        218.36071733423296,
                        175.11260637842145
                    ],
                    [
                        221.5308234687741,
                        173.52391976080554
                    ],
                    [
                        223.05966898046768,
                        175.49822487757262
                    ],
                    [
                        222.94725386931373,
                        177.13054610640546
                    ],
                    [
                        222.0029669356204,
                        177.28916767247216
                    ],
                    [
                        221.193578135312,
                        178.37649511305597
                    ],
                    [
                        221.3959253353894,
                        181.45382825042935
                    ],
                    [
                        222.04793298008212,
                        182.76468785940466
                    ],
                    [
                        220.8113667573889,
                        184.88725191374158
                    ],
                    [
                        220.04694400154176,
                        183.8262679166221
                    ],
                    [
                        218.2932682675405,
                        183.17132098944757
                    ],
                    [
                        217.10166808930876,
                        182.29017166189567
                    ],
                    [
                        218.20333617861706,
                        181.61208389963394
                    ],
                    [
                        219.43990240131075,
                        180.0063009013486
                    ],
                    [
                        218.09092106746334,
                        178.8293613948744
                    ],
                    [
                        217.8436078229247,
                        176.17853284559516
                    ],
                    [
                        218.36071733423296,
                        175.11260637842145
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Sant Kabir Nagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Karauli",
            "dt_code": "107",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        132.9701989016985,
                        177.44777573925705
                    ],
                    [
                        135.8480257472395,
                        178.80672068656747
                    ],
                    [
                        136.6574145475479,
                        179.93842064698754
                    ],
                    [
                        137.08459196993272,
                        181.45382825042935
                    ],
                    [
                        134.90373881354617,
                        182.92283338306027
                    ],
                    [
                        135.30843321370048,
                        184.52613266528562
                    ],
                    [
                        136.43258432523976,
                        184.52613266528562
                    ],
                    [
                        137.06210894770197,
                        185.6318430311929
                    ],
                    [
                        135.53326343600838,
                        187.2328525180992
                    ],
                    [
                        133.30744423516035,
                        188.11170730662718
                    ],
                    [
                        131.5312854789281,
                        189.48551499157264
                    ],
                    [
                        130.0923720561575,
                        190.09327874529941
                    ],
                    [
                        129.3729153447723,
                        191.08329564260998
                    ],
                    [
                        127.16957916615502,
                        188.65233973645508
                    ],
                    [
                        125.32597134323032,
                        188.08917763028973
                    ],
                    [
                        125.68569969892314,
                        186.10552158375248
                    ],
                    [
                        129.62022858931095,
                        183.26167200577532
                    ],
                    [
                        131.10410805654283,
                        182.5613384056578
                    ],
                    [
                        131.2614892121585,
                        181.72511549637915
                    ],
                    [
                        130.3172022784654,
                        180.39090925889997
                    ],
                    [
                        129.05815303354143,
                        180.9111362725393
                    ],
                    [
                        127.97896796646341,
                        182.29017166189567
                    ],
                    [
                        123.8196088537677,
                        179.84790981638537
                    ],
                    [
                        124.71892974299908,
                        178.7840797040859
                    ],
                    [
                        125.8655638767691,
                        178.7614384474096
                    ],
                    [
                        128.2037981887711,
                        176.97191103428338
                    ],
                    [
                        128.54104352223317,
                        175.43018037519943
                    ],
                    [
                        130.0923720561575,
                        175.65698568698423
                    ],
                    [
                        130.83431178977366,
                        177.28916767247216
                    ],
                    [
                        132.9701989016985,
                        177.44777573925705
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Karauli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "East Champaran",
            "dt_code": "204",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        241.85547556540655,
                        181.72511549637915
                    ],
                    [
                        242.46251716563802,
                        180.8885207042989
                    ],
                    [
                        245.1604798333326,
                        179.4179236398544
                    ],
                    [
                        245.40779307787125,
                        178.21796597760044
                    ],
                    [
                        244.4410231219474,
                        176.60926583074888
                    ],
                    [
                        245.6101402779484,
                        175.13529203776187
                    ],
                    [
                        246.57691023387224,
                        174.8403568237876
                    ],
                    [
                        248.60038223464312,
                        175.83840999723265
                    ],
                    [
                        249.61211823502867,
                        177.24384860279855
                    ],
                    [
                        251.99531859149215,
                        177.33448564016356
                    ],
                    [
                        251.792971391415,
                        178.51236652149305
                    ],
                    [
                        252.9171225029545,
                        179.23684728289294
                    ],
                    [
                        253.07450365857017,
                        181.00159582220056
                    ],
                    [
                        253.0070545918777,
                        181.97376110785615
                    ],
                    [
                        251.792971391415,
                        183.62303283252294
                    ],
                    [
                        253.43423201426276,
                        185.81230594440717
                    ],
                    [
                        253.38926596980127,
                        185.83486260179404
                    ],
                    [
                        253.29933388087807,
                        185.87997511227115
                    ],
                    [
                        252.1077337026461,
                        186.93981076384023
                    ],
                    [
                        249.83694845733658,
                        186.01530621072186
                    ],
                    [
                        247.7235443676425,
                        186.26338817418434
                    ],
                    [
                        247.1839518341037,
                        187.34554887198885
                    ],
                    [
                        244.03632872179332,
                        183.9617458293563
                    ],
                    [
                        243.15949085479224,
                        184.43584208708057
                    ],
                    [
                        241.96789067656073,
                        182.87765030107818
                    ],
                    [
                        241.85547556540655,
                        181.72511549637915
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-East Champaran",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Unnao",
            "dt_code": "156",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        176.2949827404277,
                        176.4279167295623
                    ],
                    [
                        177.35168478527476,
                        175.2714001696047
                    ],
                    [
                        178.2734886967371,
                        175.88376330678574
                    ],
                    [
                        180.18454558635403,
                        174.74959808964928
                    ],
                    [
                        180.99393438666243,
                        176.4732556619427
                    ],
                    [
                        183.73686309881873,
                        174.9537989948446
                    ],
                    [
                        184.47880283243467,
                        175.40749832019878
                    ],
                    [
                        184.88349723258898,
                        177.17586793172558
                    ],
                    [
                        186.2549615886669,
                        178.46707714994767
                    ],
                    [
                        186.23247856643616,
                        180.09680408338374
                    ],
                    [
                        187.31166363351394,
                        181.0242100288885
                    ],
                    [
                        188.03112034489936,
                        180.6849683311683
                    ],
                    [
                        190.3468716346706,
                        182.87765030107818
                    ],
                    [
                        190.50425279028605,
                        184.66156045608375
                    ],
                    [
                        191.08881136828631,
                        185.451362948079
                    ],
                    [
                        187.69387501143729,
                        188.31446241899278
                    ],
                    [
                        185.85026718851282,
                        188.96763796238548
                    ],
                    [
                        185.55798789951268,
                        189.59807839761783
                    ],
                    [
                        184.34390469904997,
                        188.71990802415218
                    ],
                    [
                        184.34390469904997,
                        187.81880074712382
                    ],
                    [
                        183.26471963197196,
                        185.7446343634199
                    ],
                    [
                        180.20702860858478,
                        182.71950098763887
                    ],
                    [
                        179.28522469712243,
                        180.32304292735358
                    ],
                    [
                        178.07114149666018,
                        179.89316577815032
                    ],
                    [
                        177.3741678075055,
                        178.85200182902577
                    ],
                    [
                        177.75637918542907,
                        177.49308985125492
                    ],
                    [
                        176.2949827404277,
                        176.4279167295623
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Unnao",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Etawah",
            "dt_code": "161",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        157.11696477756527,
                        176.49592471381243
                    ],
                    [
                        158.15118380018157,
                        175.88376330678574
                    ],
                    [
                        163.59207518003245,
                        175.08992044168525
                    ],
                    [
                        165.72796229195728,
                        175.83840999723265
                    ],
                    [
                        166.04272460318816,
                        176.33723554968407
                    ],
                    [
                        165.63803020303408,
                        180.79805570744838
                    ],
                    [
                        164.17663375803272,
                        182.35796700714116
                    ],
                    [
                        164.91857349164889,
                        183.73594391174447
                    ],
                    [
                        164.10918469134026,
                        184.45841513554478
                    ],
                    [
                        162.94006753533927,
                        184.39069518220964
                    ],
                    [
                        160.80418042341466,
                        182.1093721422248
                    ],
                    [
                        161.05149366795308,
                        180.59449352444204
                    ],
                    [
                        159.07298771164392,
                        180.16467860276208
                    ],
                    [
                        157.7240063777965,
                        178.98783867777342
                    ],
                    [
                        158.8031914448743,
                        178.28590868338034
                    ],
                    [
                        157.11696477756527,
                        176.49592471381243
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Etawah",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dholpur",
            "dt_code": "106",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        136.6574145475479,
                        179.93842064698754
                    ],
                    [
                        138.23122610370297,
                        179.4179236398544
                    ],
                    [
                        139.26544512631926,
                        178.17266946701233
                    ],
                    [
                        142.165754994091,
                        177.24384860279855
                    ],
                    [
                        142.72783054986053,
                        176.29189330186995
                    ],
                    [
                        146.05531784001732,
                        177.01723671916784
                    ],
                    [
                        147.87644264071128,
                        176.9039204386164
                    ],
                    [
                        148.4385181964808,
                        176.01981659475757
                    ],
                    [
                        150.237159974944,
                        176.49592471381243
                    ],
                    [
                        149.51770326355881,
                        177.94617042733995
                    ],
                    [
                        147.89892566294202,
                        178.55765479531544
                    ],
                    [
                        147.56168032948017,
                        180.52663455691493
                    ],
                    [
                        146.25766504009448,
                        180.2099269173329
                    ],
                    [
                        144.8412346395546,
                        180.79805570744838
                    ],
                    [
                        143.64963446132288,
                        182.58393387283974
                    ],
                    [
                        142.50300032755263,
                        182.78728088915636
                    ],
                    [
                        139.46779232639642,
                        184.86468397843328
                    ],
                    [
                        137.9839128591641,
                        185.00008755688083
                    ],
                    [
                        137.06210894770197,
                        185.6318430311929
                    ],
                    [
                        136.43258432523976,
                        184.52613266528562
                    ],
                    [
                        135.30843321370048,
                        184.52613266528562
                    ],
                    [
                        134.90373881354617,
                        182.92283338306027
                    ],
                    [
                        137.08459196993272,
                        181.45382825042935
                    ],
                    [
                        136.6574145475479,
                        179.93842064698754
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Dholpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kanpur Nagar",
            "dt_code": "164",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        175.8453222958119,
                        175.90643954646964
                    ],
                    [
                        176.2949827404277,
                        176.4279167295623
                    ],
                    [
                        177.75637918542907,
                        177.49308985125492
                    ],
                    [
                        177.3741678075055,
                        178.85200182902577
                    ],
                    [
                        178.07114149666018,
                        179.89316577815032
                    ],
                    [
                        179.28522469712243,
                        180.32304292735358
                    ],
                    [
                        180.20702860858478,
                        182.71950098763887
                    ],
                    [
                        183.26471963197196,
                        185.7446343634199
                    ],
                    [
                        184.34390469904997,
                        187.81880074712382
                    ],
                    [
                        180.4318588308929,
                        189.7331457438375
                    ],
                    [
                        179.3976398082766,
                        190.85833667654896
                    ],
                    [
                        179.127843541507,
                        192.81459811037888
                    ],
                    [
                        176.90202434065895,
                        191.6455777651538
                    ],
                    [
                        177.30671874081304,
                        190.85833667654896
                    ],
                    [
                        175.01345047327277,
                        190.34083090612216
                    ],
                    [
                        175.10338256219597,
                        189.1703022043481
                    ],
                    [
                        175.77787322911945,
                        187.34554887198885
                    ],
                    [
                        178.25100567450613,
                        185.97019691658016
                    ],
                    [
                        177.419133851967,
                        183.9843245373603
                    ],
                    [
                        178.2285226522754,
                        183.3520186970008
                    ],
                    [
                        177.62148105204415,
                        182.24497340931134
                    ],
                    [
                        175.9802204291966,
                        181.61208389963394
                    ],
                    [
                        175.71042416242722,
                        180.16467860276208
                    ],
                    [
                        174.1366126062719,
                        178.19531785965967
                    ],
                    [
                        174.78862025096487,
                        176.17853284559516
                    ],
                    [
                        175.8453222958119,
                        175.90643954646964
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Kanpur Nagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Auraiya",
            "dt_code": "162",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        166.04272460318816,
                        176.33723554968407
                    ],
                    [
                        167.3692229148046,
                        176.1558599250268
                    ],
                    [
                        168.24606078180545,
                        177.13054610640546
                    ],
                    [
                        169.77490629349904,
                        176.949247778193
                    ],
                    [
                        172.06817456103954,
                        178.58029852062936
                    ],
                    [
                        171.5060990052698,
                        180.82067236528337
                    ],
                    [
                        170.35946487149977,
                        181.65729735342507
                    ],
                    [
                        169.7524232712683,
                        183.9617458293563
                    ],
                    [
                        168.40344193742112,
                        185.6318430311929
                    ],
                    [
                        167.9088154483436,
                        184.52613266528562
                    ],
                    [
                        165.72796229195728,
                        185.09035123247253
                    ],
                    [
                        164.10918469134026,
                        184.45841513554478
                    ],
                    [
                        164.91857349164889,
                        183.73594391174447
                    ],
                    [
                        164.17663375803272,
                        182.35796700714116
                    ],
                    [
                        165.63803020303408,
                        180.79805570744838
                    ],
                    [
                        166.04272460318816,
                        176.33723554968407
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Auraiya",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Morena",
            "dt_code": "419",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        149.51770326355881,
                        177.94617042733995
                    ],
                    [
                        151.51869224209872,
                        177.33448564016356
                    ],
                    [
                        152.05828477563773,
                        178.19531785965967
                    ],
                    [
                        154.26162095425502,
                        179.0783910963238
                    ],
                    [
                        153.5196812206391,
                        180.61711263501036
                    ],
                    [
                        151.65359037548365,
                        182.24497340931134
                    ],
                    [
                        149.4277711746356,
                        182.92283338306027
                    ],
                    [
                        150.5519222861749,
                        184.9775209659755
                    ],
                    [
                        150.03481277486685,
                        186.21828477304
                    ],
                    [
                        148.46100121871154,
                        186.01530621072186
                    ],
                    [
                        146.66235944024857,
                        186.55653418410571
                    ],
                    [
                        146.4150461957097,
                        187.14269063077643
                    ],
                    [
                        143.53721935016893,
                        187.43570115239447
                    ],
                    [
                        142.48051730532188,
                        188.96763796238548
                    ],
                    [
                        142.25568708301398,
                        190.85833667654896
                    ],
                    [
                        141.37884921601335,
                        192.14024997782917
                    ],
                    [
                        141.78354361616744,
                        193.21909370751172
                    ],
                    [
                        140.99663783809,
                        193.26403242454398
                    ],
                    [
                        141.08656992701322,
                        192.23017671255218
                    ],
                    [
                        137.8040486813179,
                        189.59807839761783
                    ],
                    [
                        136.16278805847037,
                        189.28288416519786
                    ],
                    [
                        135.263467169239,
                        190.1833014140285
                    ],
                    [
                        133.30744423516035,
                        188.11170730662718
                    ],
                    [
                        135.53326343600838,
                        187.2328525180992
                    ],
                    [
                        137.06210894770197,
                        185.6318430311929
                    ],
                    [
                        137.9839128591641,
                        185.00008755688083
                    ],
                    [
                        139.46779232639642,
                        184.86468397843328
                    ],
                    [
                        142.50300032755263,
                        182.78728088915636
                    ],
                    [
                        143.64963446132288,
                        182.58393387283974
                    ],
                    [
                        144.8412346395546,
                        180.79805570744838
                    ],
                    [
                        146.25766504009448,
                        180.2099269173329
                    ],
                    [
                        147.56168032948017,
                        180.52663455691493
                    ],
                    [
                        147.89892566294202,
                        178.55765479531544
                    ],
                    [
                        149.51770326355881,
                        177.94617042733995
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Morena",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Barmer",
            "dt_code": "115",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        30.69493077384186,
                        189.05771361222082
                    ],
                    [
                        33.95496899730642,
                        189.28288416519786
                    ],
                    [
                        34.96670499769198,
                        188.74243025542103
                    ],
                    [
                        38.24922624338683,
                        188.4045688859024
                    ],
                    [
                        39.530758510542,
                        189.30539976182575
                    ],
                    [
                        41.37436633346647,
                        189.46300151549337
                    ],
                    [
                        42.45355140054426,
                        190.31832748587937
                    ],
                    [
                        44.072329001160824,
                        189.32791509331787
                    ],
                    [
                        43.487770423160555,
                        187.029982264333
                    ],
                    [
                        44.432057356853875,
                        186.33104126791648
                    ],
                    [
                        45.39882731277771,
                        186.55653418410571
                    ],
                    [
                        46.45552935762453,
                        184.34554719995333
                    ],
                    [
                        47.489748380240826,
                        183.9617458293563
                    ],
                    [
                        48.141756024933784,
                        184.70670089990205
                    ],
                    [
                        49.73805060331961,
                        183.46494598075537
                    ],
                    [
                        50.502473359166515,
                        184.11979112244381
                    ],
                    [
                        51.761522604090715,
                        183.30684589194766
                    ],
                    [
                        52.66084349332232,
                        184.9775209659755
                    ],
                    [
                        54.9091457164011,
                        185.9250865504817
                    ],
                    [
                        55.651085450017035,
                        185.47392389778616
                    ],
                    [
                        56.46047425032543,
                        186.7369092571068
                    ],
                    [
                        58.97857274017383,
                        185.85741899107563
                    ],
                    [
                        61.96881469686855,
                        188.3820426680805
                    ],
                    [
                        63.07048278617731,
                        187.59345737461115
                    ],
                    [
                        64.08221878656263,
                        187.72866659606564
                    ],
                    [
                        64.77919247571731,
                        186.69181709323357
                    ],
                    [
                        66.78018145425744,
                        187.05252447170585
                    ],
                    [
                        68.17412883256611,
                        188.56224496639715
                    ],
                    [
                        68.24157789925835,
                        190.99331522071571
                    ],
                    [
                        67.16239283218079,
                        192.02783564800166
                    ],
                    [
                        70.31001594449117,
                        193.82567808910835
                    ],
                    [
                        69.47814412195203,
                        194.81377799548545
                    ],
                    [
                        69.20834785518241,
                        196.11550314868197
                    ],
                    [
                        68.12916278810462,
                        197.01273606343653
                    ],
                    [
                        67.97178163248918,
                        197.93197039744808
                    ],
                    [
                        66.71273238756521,
                        197.99921441360675
                    ],
                    [
                        66.28555496517993,
                        199.27641050367447
                    ],
                    [
                        64.2395999421783,
                        200.75422740180898
                    ],
                    [
                        62.9805506972541,
                        200.75422740180898
                    ],
                    [
                        62.7557204749462,
                        201.58221337525544
                    ],
                    [
                        58.506429273327285,
                        200.1945794914024
                    ],
                    [
                        56.797719583787284,
                        202.27566278142064
                    ],
                    [
                        57.24738002840286,
                        202.76763993776422
                    ],
                    [
                        55.763500561170986,
                        204.66730845741046
                    ],
                    [
                        54.95411176086259,
                        206.63210445469207
                    ],
                    [
                        54.167205982784935,
                        206.78831156297593
                    ],
                    [
                        53.357817182476765,
                        208.12672689234802
                    ],
                    [
                        52.66084349332232,
                        207.34609395837174
                    ],
                    [
                        51.4917263373211,
                        208.1936240631212
                    ],
                    [
                        50.74978660370516,
                        207.747600522261
                    ],
                    [
                        48.321620202780196,
                        208.88476354534936
                    ],
                    [
                        48.141756024933784,
                        210.44452272491606
                    ],
                    [
                        46.09580100193193,
                        213.56041013680016
                    ],
                    [
                        44.971649890392655,
                        213.16006596611408
                    ],
                    [
                        42.40858535608277,
                        208.88476354534936
                    ],
                    [
                        41.689128644697576,
                        205.49365309208832
                    ],
                    [
                        39.66565664392647,
                        203.3936150584725
                    ],
                    [
                        38.40660739900227,
                        201.44796909848702
                    ],
                    [
                        38.54150553238719,
                        196.90060460182877
                    ],
                    [
                        37.574735576463354,
                        196.2725488307433
                    ],
                    [
                        36.42810144269288,
                        196.7884666727324
                    ],
                    [
                        34.31469735299879,
                        196.94545796243835
                    ],
                    [
                        32.583504641228046,
                        196.29498289111513
                    ],
                    [
                        30.08788917361062,
                        192.6123184673483
                    ],
                    [
                        29.863058951302946,
                        190.25081563943877
                    ],
                    [
                        30.69493077384186,
                        189.05771361222082
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Barmer",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ayodhya",
            "dt_code": "177",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        201.3635525277566,
                        177.35714421081582
                    ],
                    [
                        203.47695661745092,
                        178.0367733411859
                    ],
                    [
                        204.71352284014415,
                        178.7840797040859
                    ],
                    [
                        207.8836289746855,
                        177.87821535532873
                    ],
                    [
                        208.4232215082243,
                        178.28590868338034
                    ],
                    [
                        209.21012728630194,
                        179.5084552502468
                    ],
                    [
                        210.82890488691896,
                        180.9111362725393
                    ],
                    [
                        212.02050506515047,
                        180.9111362725393
                    ],
                    [
                        211.346014398227,
                        182.65171864904187
                    ],
                    [
                        209.9970330643796,
                        182.13197303157534
                    ],
                    [
                        208.7829498639171,
                        184.70670089990205
                    ],
                    [
                        208.33328941930108,
                        184.88725191374158
                    ],
                    [
                        206.48968159637639,
                        183.78110645392593
                    ],
                    [
                        204.17393030660537,
                        183.28425908401118
                    ],
                    [
                        200.62161279414067,
                        182.69690714558584
                    ],
                    [
                        199.78974097160153,
                        181.11466413331829
                    ],
                    [
                        198.57565777113905,
                        181.09205101551964
                    ],
                    [
                        199.78974097160153,
                        178.10472264063327
                    ],
                    [
                        201.3635525277566,
                        177.35714421081582
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Ayodhya",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kanpur Dehat",
            "dt_code": "163",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        172.06817456103954,
                        178.58029852062936
                    ],
                    [
                        173.32722380596374,
                        178.91992148674427
                    ],
                    [
                        174.1366126062719,
                        178.19531785965967
                    ],
                    [
                        175.71042416242722,
                        180.16467860276208
                    ],
                    [
                        175.9802204291966,
                        181.61208389963394
                    ],
                    [
                        177.62148105204415,
                        182.24497340931134
                    ],
                    [
                        178.2285226522754,
                        183.3520186970008
                    ],
                    [
                        177.419133851967,
                        183.9843245373603
                    ],
                    [
                        178.25100567450613,
                        185.97019691658016
                    ],
                    [
                        175.77787322911945,
                        187.34554887198885
                    ],
                    [
                        175.10338256219597,
                        189.1703022043481
                    ],
                    [
                        174.42889189527227,
                        190.1382906085155
                    ],
                    [
                        172.45038593896288,
                        189.80067584227885
                    ],
                    [
                        172.15810664996275,
                        188.6072928828898
                    ],
                    [
                        170.49436300488424,
                        188.15676586085618
                    ],
                    [
                        169.0779326043446,
                        187.18777210832894
                    ],
                    [
                        168.40344193742112,
                        185.6318430311929
                    ],
                    [
                        169.7524232712683,
                        183.9617458293563
                    ],
                    [
                        170.35946487149977,
                        181.65729735342507
                    ],
                    [
                        171.5060990052698,
                        180.82067236528337
                    ],
                    [
                        172.06817456103954,
                        178.58029852062936
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Kanpur Dehat",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhind",
            "dt_code": "420",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        154.26162095425502,
                        179.0783910963238
                    ],
                    [
                        157.7240063777965,
                        178.98783867777342
                    ],
                    [
                        159.07298771164392,
                        180.16467860276208
                    ],
                    [
                        161.05149366795308,
                        180.59449352444204
                    ],
                    [
                        160.80418042341466,
                        182.1093721422248
                    ],
                    [
                        162.94006753533927,
                        184.39069518220964
                    ],
                    [
                        162.98503357980098,
                        186.06041443306088
                    ],
                    [
                        160.87162949010667,
                        188.3820426680805
                    ],
                    [
                        161.11894273464554,
                        190.25081563943877
                    ],
                    [
                        160.1971388231832,
                        191.15077819093682
                    ],
                    [
                        160.03975766756753,
                        192.38753838713353
                    ],
                    [
                        159.27533491172085,
                        193.03932836551178
                    ],
                    [
                        158.0837347334891,
                        192.5224095842063
                    ],
                    [
                        157.6115912666428,
                        191.30822824651284
                    ],
                    [
                        158.6682933114896,
                        189.77816607420442
                    ],
                    [
                        157.8589045111812,
                        187.9539939819271
                    ],
                    [
                        156.48744015510306,
                        188.0441174790722
                    ],
                    [
                        155.47570415471773,
                        189.0351950978308
                    ],
                    [
                        152.30559802017638,
                        186.75945493803567
                    ],
                    [
                        150.52943926394414,
                        185.90253096540033
                    ],
                    [
                        150.03481277486685,
                        186.21828477304
                    ],
                    [
                        150.5519222861749,
                        184.9775209659755
                    ],
                    [
                        149.4277711746356,
                        182.92283338306027
                    ],
                    [
                        151.65359037548365,
                        182.24497340931134
                    ],
                    [
                        153.5196812206391,
                        180.61711263501036
                    ],
                    [
                        154.26162095425502,
                        179.0783910963238
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Bhind",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sawai Madhopur",
            "dt_code": "108",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        119.41293649653312,
                        183.64561558830457
                    ],
                    [
                        121.59378965291944,
                        184.11979112244381
                    ],
                    [
                        122.89780494230536,
                        184.79697855879772
                    ],
                    [
                        123.8645748982292,
                        183.41977587782077
                    ],
                    [
                        122.76290680892043,
                        182.33536882985067
                    ],
                    [
                        123.8196088537677,
                        179.84790981638537
                    ],
                    [
                        127.97896796646341,
                        182.29017166189567
                    ],
                    [
                        129.05815303354143,
                        180.9111362725393
                    ],
                    [
                        130.3172022784654,
                        180.39090925889997
                    ],
                    [
                        131.2614892121585,
                        181.72511549637915
                    ],
                    [
                        131.10410805654283,
                        182.5613384056578
                    ],
                    [
                        129.62022858931095,
                        183.26167200577532
                    ],
                    [
                        125.68569969892314,
                        186.10552158375248
                    ],
                    [
                        125.32597134323032,
                        188.08917763028973
                    ],
                    [
                        127.16957916615502,
                        188.65233973645508
                    ],
                    [
                        129.3729153447723,
                        191.08329564260998
                    ],
                    [
                        128.42862841107922,
                        192.5224095842063
                    ],
                    [
                        127.37192636623195,
                        193.21909370751172
                    ],
                    [
                        126.29274129915439,
                        193.10674233294566
                    ],
                    [
                        124.87631089861475,
                        194.252419556946
                    ],
                    [
                        123.8645748982292,
                        194.20750384057538
                    ],
                    [
                        123.34746538692093,
                        195.30763875402073
                    ],
                    [
                        122.08841614199696,
                        195.84625240869593
                    ],
                    [
                        121.43640849730377,
                        194.65661405439232
                    ],
                    [
                        119.93004600784138,
                        193.89306458860796
                    ],
                    [
                        120.37970645245696,
                        191.2407512322306
                    ],
                    [
                        118.2438193405319,
                        191.60060125160982
                    ],
                    [
                        118.13140422937818,
                        189.9132207123326
                    ],
                    [
                        116.75993987330003,
                        189.71063518150726
                    ],
                    [
                        116.69249080660757,
                        187.97652525560028
                    ],
                    [
                        118.3337514294551,
                        187.66106318477114
                    ],
                    [
                        119.41293649653312,
                        183.64561558830457
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Sawai Madhopur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Deoria",
            "dt_code": "190",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        228.99518684939585,
                        180.75282157441626
                    ],
                    [
                        231.0861079168592,
                        180.11942919620543
                    ],
                    [
                        232.1203269394755,
                        179.14630253380824
                    ],
                    [
                        235.22298400732416,
                        182.5613384056578
                    ],
                    [
                        233.22199502878402,
                        183.37460469415583
                    ],
                    [
                        233.10957991763007,
                        184.27782320613
                    ],
                    [
                        236.7743125412485,
                        185.451362948079
                    ],
                    [
                        237.06659183024885,
                        187.27793186024098
                    ],
                    [
                        235.7400935186322,
                        188.02158700415328
                    ],
                    [
                        235.29043307401662,
                        189.9807444587998
                    ],
                    [
                        233.08709689539933,
                        189.59807839761783
                    ],
                    [
                        232.39012320624488,
                        188.5847690575191
                    ],
                    [
                        232.03039485055228,
                        187.796267609016
                    ],
                    [
                        230.68141351670488,
                        187.2553923226139
                    ],
                    [
                        229.0176698716266,
                        188.02158700415328
                    ],
                    [
                        228.9277377827034,
                        186.35359176377506
                    ],
                    [
                        227.19654507093287,
                        184.77440954761772
                    ],
                    [
                        227.06164693754795,
                        183.53269910912866
                    ],
                    [
                        228.59049244924176,
                        183.17132098944757
                    ],
                    [
                        228.99518684939585,
                        180.75282157441626
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Deoria",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sitamarhi",
            "dt_code": "206",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        253.07450365857017,
                        181.00159582220056
                    ],
                    [
                        252.9171225029545,
                        179.23684728289294
                    ],
                    [
                        254.0862396589555,
                        179.44055695286954
                    ],
                    [
                        257.16641370457387,
                        177.85556311471964
                    ],
                    [
                        258.62781014957477,
                        177.60637031353116
                    ],
                    [
                        259.88685939449897,
                        178.33120244739496
                    ],
                    [
                        259.79692730557576,
                        180.54925448541366
                    ],
                    [
                        261.16839166165414,
                        181.77032623342234
                    ],
                    [
                        261.30328979503884,
                        182.74209455890525
                    ],
                    [
                        260.4489349502687,
                        184.45841513554478
                    ],
                    [
                        259.5496140610371,
                        184.3229728047569
                    ],
                    [
                        259.5271310388064,
                        186.646723859879
                    ],
                    [
                        256.58185512657315,
                        185.36111646471
                    ],
                    [
                        255.95233050411093,
                        186.66927061025115
                    ],
                    [
                        254.69328125918673,
                        186.08296814235285
                    ],
                    [
                        254.46845103687906,
                        183.30684589194766
                    ],
                    [
                        255.0754926371103,
                        183.17132098944757
                    ],
                    [
                        253.83892641441685,
                        180.75282157441626
                    ],
                    [
                        253.07450365857017,
                        181.00159582220056
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Sitamarhi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tonk",
            "dt_code": "120",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        104.59662484644332,
                        184.00690297566803
                    ],
                    [
                        105.11373435775158,
                        183.12614385898098
                    ],
                    [
                        106.95734218067605,
                        182.4031625484456
                    ],
                    [
                        107.51941773644603,
                        183.6004498067341
                    ],
                    [
                        108.82343302583172,
                        183.46494598075537
                    ],
                    [
                        110.50965969314075,
                        184.25524800266112
                    ],
                    [
                        112.5556147161426,
                        184.30039814015527
                    ],
                    [
                        114.10694325006693,
                        183.08096564677084
                    ],
                    [
                        116.96228707337718,
                        183.41977587782077
                    ],
                    [
                        118.3337514294551,
                        182.78728088915636
                    ],
                    [
                        119.09817418530201,
                        183.0583761349638
                    ],
                    [
                        119.41293649653312,
                        183.64561558830457
                    ],
                    [
                        118.3337514294551,
                        187.66106318477114
                    ],
                    [
                        116.69249080660757,
                        187.97652525560028
                    ],
                    [
                        116.75993987330003,
                        189.71063518150726
                    ],
                    [
                        118.13140422937818,
                        189.9132207123326
                    ],
                    [
                        118.2438193405319,
                        191.60060125160982
                    ],
                    [
                        120.37970645245696,
                        191.2407512322306
                    ],
                    [
                        119.93004600784138,
                        193.89306458860796
                    ],
                    [
                        121.43640849730377,
                        194.65661405439232
                    ],
                    [
                        118.87334396299411,
                        195.12806759663243
                    ],
                    [
                        117.50187960691596,
                        194.67906682760236
                    ],
                    [
                        117.61429471806991,
                        193.84814051687778
                    ],
                    [
                        114.82639996145213,
                        193.64596925873474
                    ],
                    [
                        113.40996956091249,
                        194.54434627908023
                    ],
                    [
                        113.23010538306607,
                        195.57696428804604
                    ],
                    [
                        111.76870893806495,
                        195.71161302299788
                    ],
                    [
                        111.18415036006468,
                        196.83332262062044
                    ],
                    [
                        108.89088209252395,
                        196.1379390240507
                    ],
                    [
                        108.03652724775407,
                        195.28519326969462
                    ],
                    [
                        106.79996102506084,
                        195.41986227539462
                    ],
                    [
                        106.62009684721443,
                        193.89306458860796
                    ],
                    [
                        105.76574200244454,
                        192.6123184673483
                    ],
                    [
                        107.29458751413813,
                        191.42068466863196
                    ],
                    [
                        106.59761382498368,
                        190.45334404391912
                    ],
                    [
                        104.88890413544368,
                        189.71063518150726
                    ],
                    [
                        103.78723604613515,
                        186.89472341072343
                    ],
                    [
                        104.0345492906738,
                        185.00008755688083
                    ],
                    [
                        104.59662484644332,
                        184.00690297566803
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Tonk",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sheohar",
            "dt_code": "205",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        253.43423201426276,
                        185.81230594440717
                    ],
                    [
                        251.792971391415,
                        183.62303283252294
                    ],
                    [
                        253.0070545918777,
                        181.97376110785615
                    ],
                    [
                        253.07450365857017,
                        181.00159582220056
                    ],
                    [
                        253.83892641441685,
                        180.75282157441626
                    ],
                    [
                        255.0754926371103,
                        183.17132098944757
                    ],
                    [
                        254.46845103687906,
                        183.30684589194766
                    ],
                    [
                        254.69328125918673,
                        186.08296814235285
                    ],
                    [
                        253.43423201426276,
                        185.81230594440717
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Sheohar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sultanpur",
            "dt_code": "179",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        204.17393030660537,
                        183.28425908401118
                    ],
                    [
                        206.48968159637639,
                        183.78110645392593
                    ],
                    [
                        208.33328941930108,
                        184.88725191374158
                    ],
                    [
                        210.62655768684158,
                        185.38367848827244
                    ],
                    [
                        210.91883697584194,
                        186.69181709323357
                    ],
                    [
                        213.14465617668975,
                        186.89472341072343
                    ],
                    [
                        215.03323004407594,
                        187.41316348244135
                    ],
                    [
                        215.03323004407594,
                        189.12526756338514
                    ],
                    [
                        213.57183359907503,
                        188.56224496639715
                    ],
                    [
                        211.45842950938072,
                        190.0032518451435
                    ],
                    [
                        211.43594648714998,
                        191.5106450661874
                    ],
                    [
                        210.33427839784144,
                        191.5331345073826
                    ],
                    [
                        208.62556870830144,
                        190.67835051008848
                    ],
                    [
                        207.9735610636087,
                        191.30822824651284
                    ],
                    [
                        206.5346476408381,
                        190.43084194475153
                    ],
                    [
                        205.77022488499142,
                        190.65585105163478
                    ],
                    [
                        205.79270790722217,
                        189.1703022043481
                    ],
                    [
                        202.89239803945043,
                        186.96235403965198
                    ],
                    [
                        203.31957546183548,
                        185.24830231883004
                    ],
                    [
                        204.30882843999007,
                        184.7292707181894
                    ],
                    [
                        204.17393030660537,
                        183.28425908401118
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Sultanpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pali",
            "dt_code": "118",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        70.31001594449117,
                        193.82567808910835
                    ],
                    [
                        72.1311407451849,
                        193.24156319692275
                    ],
                    [
                        73.36770696787835,
                        192.18521387056148
                    ],
                    [
                        73.50260510126327,
                        191.37570288992254
                    ],
                    [
                        75.12138270187984,
                        191.08329564260998
                    ],
                    [
                        75.57104314649564,
                        190.47584587892896
                    ],
                    [
                        77.25726981380467,
                        191.10579008898173
                    ],
                    [
                        77.72941328065122,
                        189.71063518150726
                    ],
                    [
                        78.65121719211356,
                        188.96763796238548
                    ],
                    [
                        79.55053808134517,
                        190.22831116199103
                    ],
                    [
                        81.95622146003961,
                        189.2378521764577
                    ],
                    [
                        82.3609158601937,
                        190.47584587892896
                    ],
                    [
                        84.65418412773397,
                        189.80067584227885
                    ],
                    [
                        84.65418412773397,
                        185.7671918252396
                    ],
                    [
                        85.53102199473483,
                        184.57127633893123
                    ],
                    [
                        89.78031319635397,
                        184.88725191374158
                    ],
                    [
                        90.926947330124,
                        184.052059043273
                    ],
                    [
                        91.71385310820187,
                        184.25524800266112
                    ],
                    [
                        91.9162003082788,
                        186.21828477304
                    ],
                    [
                        91.12929453020115,
                        186.91726722087054
                    ],
                    [
                        92.83800421974115,
                        188.47214594401623
                    ],
                    [
                        90.09507550758485,
                        191.08329564260998
                    ],
                    [
                        89.30816972950743,
                        192.25265773956704
                    ],
                    [
                        89.01589044050729,
                        193.96044873696513
                    ],
                    [
                        88.22898466242941,
                        194.3871604442499
                    ],
                    [
                        87.37462981765952,
                        193.803215400044
                    ],
                    [
                        86.07061452827384,
                        195.84625240869593
                    ],
                    [
                        86.09309755050458,
                        196.76603831060646
                    ],
                    [
                        85.44108990581162,
                        197.32666976898452
                    ],
                    [
                        83.64244812734842,
                        201.51509238360768
                    ],
                    [
                        81.70890821550097,
                        203.2594942619557
                    ],
                    [
                        81.4615949709621,
                        204.7566596413032
                    ],
                    [
                        79.91026643703776,
                        206.20805182178844
                    ],
                    [
                        78.74114928103677,
                        206.4758850682062
                    ],
                    [
                        77.79686234734345,
                        207.72529672508693
                    ],
                    [
                        77.59451514726675,
                        209.41967509379015
                    ],
                    [
                        75.81835639103429,
                        211.379795875424
                    ],
                    [
                        74.89655247957194,
                        211.9808130508498
                    ],
                    [
                        74.91903550180268,
                        210.39997503206564
                    ],
                    [
                        72.62576723426241,
                        209.17452506761623
                    ],
                    [
                        72.69321630095487,
                        208.52807627351444
                    ],
                    [
                        74.33447692380241,
                        207.25685928196253
                    ],
                    [
                        74.76165434618724,
                        206.31965339718022
                    ],
                    [
                        72.73818234541613,
                        206.2750135196556
                    ],
                    [
                        71.9962426118002,
                        205.58296700592075
                    ],
                    [
                        71.79389541172327,
                        203.97469987046992
                    ],
                    [
                        72.33348794526205,
                        202.45457779005397
                    ],
                    [
                        74.08716367926331,
                        200.3065218608382
                    ],
                    [
                        73.52508812349402,
                        198.85077131553004
                    ],
                    [
                        71.79389541172327,
                        197.55087712615182
                    ],
                    [
                        71.11940474479934,
                        195.91356859685237
                    ],
                    [
                        69.47814412195203,
                        194.81377799548545
                    ],
                    [
                        70.31001594449117,
                        193.82567808910835
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Pali",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gopalganj",
            "dt_code": "217",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        240.61890934271332,
                        181.2503371251491
                    ],
                    [
                        241.85547556540655,
                        181.72511549637915
                    ],
                    [
                        241.96789067656073,
                        182.87765030107818
                    ],
                    [
                        243.15949085479224,
                        184.43584208708057
                    ],
                    [
                        244.03632872179332,
                        183.9617458293563
                    ],
                    [
                        247.1839518341037,
                        187.34554887198885
                    ],
                    [
                        247.65609530095026,
                        188.08917763028973
                    ],
                    [
                        245.72255538910235,
                        188.17929473878593
                    ],
                    [
                        244.0812947662546,
                        186.71436330884592
                    ],
                    [
                        239.80952054240515,
                        185.56416501265562
                    ],
                    [
                        238.3930901418653,
                        186.53398609649423
                    ],
                    [
                        236.7743125412485,
                        185.451362948079
                    ],
                    [
                        233.10957991763007,
                        184.27782320613
                    ],
                    [
                        233.22199502878402,
                        183.37460469415583
                    ],
                    [
                        235.22298400732416,
                        182.5613384056578
                    ],
                    [
                        236.12230489655576,
                        181.27294833814375
                    ],
                    [
                        238.59543734194244,
                        181.77032623342234
                    ],
                    [
                        240.61890934271332,
                        181.2503371251491
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Gopalganj",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ambedkar Nagar",
            "dt_code": "178",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        212.02050506515047,
                        180.9111362725393
                    ],
                    [
                        213.54935057684406,
                        181.7025097203993
                    ],
                    [
                        214.80839982176826,
                        181.7025097203993
                    ],
                    [
                        215.52785653315345,
                        182.51614665850502
                    ],
                    [
                        217.10166808930876,
                        182.29017166189567
                    ],
                    [
                        218.2932682675405,
                        183.17132098944757
                    ],
                    [
                        220.04694400154176,
                        183.8262679166221
                    ],
                    [
                        220.8113667573889,
                        184.88725191374158
                    ],
                    [
                        221.71068764662027,
                        185.29342878287366
                    ],
                    [
                        221.37344231315842,
                        186.96235403965198
                    ],
                    [
                        220.69895164623495,
                        187.029982264333
                    ],
                    [
                        218.81037777884853,
                        184.88725191374158
                    ],
                    [
                        218.0234720007711,
                        185.33855417263595
                    ],
                    [
                        218.09092106746334,
                        187.59345737461115
                    ],
                    [
                        217.14663413377025,
                        188.5397206095053
                    ],
                    [
                        215.03323004407594,
                        187.41316348244135
                    ],
                    [
                        213.14465617668975,
                        186.89472341072343
                    ],
                    [
                        210.91883697584194,
                        186.69181709323357
                    ],
                    [
                        210.62655768684158,
                        185.38367848827244
                    ],
                    [
                        208.33328941930108,
                        184.88725191374158
                    ],
                    [
                        208.7829498639171,
                        184.70670089990205
                    ],
                    [
                        209.9970330643796,
                        182.13197303157534
                    ],
                    [
                        211.346014398227,
                        182.65171864904187
                    ],
                    [
                        212.02050506515047,
                        180.9111362725393
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Ambedkar Nagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rae Bareli",
            "dt_code": "158",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        192.73007199113385,
                        181.97376110785615
                    ],
                    [
                        193.02235128013422,
                        181.65729735342507
                    ],
                    [
                        195.4280346588289,
                        182.69690714558584
                    ],
                    [
                        196.349838570291,
                        186.44379107079936
                    ],
                    [
                        195.60789883667485,
                        187.05252447170585
                    ],
                    [
                        195.85521208121372,
                        188.51719598682405
                    ],
                    [
                        195.3381025699057,
                        190.83583932928775
                    ],
                    [
                        194.8659591030589,
                        191.30822824651284
                    ],
                    [
                        196.91191412606054,
                        192.67974737524634
                    ],
                    [
                        195.24817048098248,
                        193.73582576489042
                    ],
                    [
                        195.3381025699057,
                        194.7015193402387
                    ],
                    [
                        193.5844268359042,
                        193.803215400044
                    ],
                    [
                        193.53946079144248,
                        192.5898416400952
                    ],
                    [
                        191.2911585683637,
                        191.60060125160982
                    ],
                    [
                        190.57170185697828,
                        190.38583695386671
                    ],
                    [
                        188.52574683397688,
                        190.7008497045765
                    ],
                    [
                        185.55798789951268,
                        189.59807839761783
                    ],
                    [
                        185.85026718851282,
                        188.96763796238548
                    ],
                    [
                        187.69387501143729,
                        188.31446241899278
                    ],
                    [
                        191.08881136828631,
                        185.451362948079
                    ],
                    [
                        190.50425279028605,
                        184.66156045608375
                    ],
                    [
                        190.3468716346706,
                        182.87765030107818
                    ],
                    [
                        191.06632834605557,
                        183.32943242960425
                    ],
                    [
                        192.73007199113385,
                        181.97376110785615
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Rae Bareli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jalaun",
            "dt_code": "165",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        162.94006753533927,
                        184.39069518220964
                    ],
                    [
                        164.10918469134026,
                        184.45841513554478
                    ],
                    [
                        165.72796229195728,
                        185.09035123247253
                    ],
                    [
                        167.9088154483436,
                        184.52613266528562
                    ],
                    [
                        168.40344193742112,
                        185.6318430311929
                    ],
                    [
                        169.0779326043446,
                        187.18777210832894
                    ],
                    [
                        170.49436300488424,
                        188.15676586085618
                    ],
                    [
                        172.15810664996275,
                        188.6072928828898
                    ],
                    [
                        172.45038593896288,
                        189.80067584227885
                    ],
                    [
                        174.42889189527227,
                        190.1382906085155
                    ],
                    [
                        174.99096745104202,
                        191.21825836718608
                    ],
                    [
                        173.6869521616561,
                        192.65727133490464
                    ],
                    [
                        173.75440122834857,
                        193.53364274333688
                    ],
                    [
                        172.49535198342437,
                        193.84814051687778
                    ],
                    [
                        171.9557594498856,
                        193.10674233294566
                    ],
                    [
                        169.9322874491147,
                        193.2865013903948
                    ],
                    [
                        169.05544958211385,
                        194.52189194203225
                    ],
                    [
                        168.2685438040362,
                        193.93798761538574
                    ],
                    [
                        166.80714735903484,
                        195.06072411993205
                    ],
                    [
                        165.72796229195728,
                        195.41986227539462
                    ],
                    [
                        164.06421864687877,
                        195.06072411993205
                    ],
                    [
                        161.52363713479986,
                        193.758289237954
                    ],
                    [
                        160.03975766756753,
                        192.38753838713353
                    ],
                    [
                        160.1971388231832,
                        191.15077819093682
                    ],
                    [
                        161.11894273464554,
                        190.25081563943877
                    ],
                    [
                        160.87162949010667,
                        188.3820426680805
                    ],
                    [
                        162.98503357980098,
                        186.06041443306088
                    ],
                    [
                        162.94006753533927,
                        184.39069518220964
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Jalaun",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Azamgarh",
            "dt_code": "191",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        221.71068764662027,
                        185.29342878287366
                    ],
                    [
                        223.1271180471599,
                        185.13548145772234
                    ],
                    [
                        224.72341262554596,
                        185.87997511227115
                    ],
                    [
                        225.91501280377747,
                        187.1201494915336
                    ],
                    [
                        226.63446951516312,
                        187.09760808528705
                    ],
                    [
                        225.780114670393,
                        188.85503742790547
                    ],
                    [
                        225.91501280377747,
                        189.80067584227885
                    ],
                    [
                        224.45361635877657,
                        190.8133417181943
                    ],
                    [
                        224.11637102531472,
                        192.49993170747035
                    ],
                    [
                        224.8807937811614,
                        194.56680035544105
                    ],
                    [
                        222.96973689154447,
                        195.55452192297844
                    ],
                    [
                        222.52007644692867,
                        196.87817753348384
                    ],
                    [
                        220.74391769069643,
                        197.28182519806245
                    ],
                    [
                        219.50735146800298,
                        196.83332262062044
                    ],
                    [
                        218.27078524530953,
                        195.28519326969462
                    ],
                    [
                        217.19160017823197,
                        195.37497364682082
                    ],
                    [
                        216.15738115561567,
                        194.27487702372116
                    ],
                    [
                        216.42717742238506,
                        193.30897009449382
                    ],
                    [
                        215.03323004407594,
                        189.12526756338514
                    ],
                    [
                        215.03323004407594,
                        187.41316348244135
                    ],
                    [
                        217.14663413377025,
                        188.5397206095053
                    ],
                    [
                        218.09092106746334,
                        187.59345737461115
                    ],
                    [
                        218.0234720007711,
                        185.33855417263595
                    ],
                    [
                        218.81037777884853,
                        184.88725191374158
                    ],
                    [
                        220.69895164623495,
                        187.029982264333
                    ],
                    [
                        221.37344231315842,
                        186.96235403965198
                    ],
                    [
                        221.71068764662027,
                        185.29342878287366
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Azamgarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gwalior",
            "dt_code": "421",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        141.78354361616744,
                        193.21909370751172
                    ],
                    [
                        141.37884921601335,
                        192.14024997782917
                    ],
                    [
                        142.25568708301398,
                        190.85833667654896
                    ],
                    [
                        142.48051730532188,
                        188.96763796238548
                    ],
                    [
                        143.53721935016893,
                        187.43570115239447
                    ],
                    [
                        146.4150461957097,
                        187.14269063077643
                    ],
                    [
                        146.66235944024857,
                        186.55653418410571
                    ],
                    [
                        148.46100121871154,
                        186.01530621072186
                    ],
                    [
                        150.03481277486685,
                        186.21828477304
                    ],
                    [
                        150.52943926394414,
                        185.90253096540033
                    ],
                    [
                        152.30559802017638,
                        186.75945493803567
                    ],
                    [
                        155.47570415471773,
                        189.0351950978308
                    ],
                    [
                        154.84617953225575,
                        191.5106450661874
                    ],
                    [
                        153.33981704279267,
                        192.23017671255218
                    ],
                    [
                        153.20491890940798,
                        193.08427127239668
                    ],
                    [
                        151.11399784194464,
                        194.34224785863154
                    ],
                    [
                        150.5968883306366,
                        195.1056200312849
                    ],
                    [
                        149.40528815240486,
                        195.89113012702336
                    ],
                    [
                        147.04457081817213,
                        196.3622835170484
                    ],
                    [
                        145.02109881740103,
                        195.62184823875543
                    ],
                    [
                        141.78354361616744,
                        193.21909370751172
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Gwalior",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sheopur",
            "dt_code": "418",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        129.3729153447723,
                        191.08329564260998
                    ],
                    [
                        130.0923720561575,
                        190.09327874529941
                    ],
                    [
                        131.5312854789281,
                        189.48551499157264
                    ],
                    [
                        133.30744423516035,
                        188.11170730662718
                    ],
                    [
                        135.263467169239,
                        190.1833014140285
                    ],
                    [
                        136.16278805847037,
                        189.28288416519786
                    ],
                    [
                        137.8040486813179,
                        189.59807839761783
                    ],
                    [
                        141.08656992701322,
                        192.23017671255218
                    ],
                    [
                        140.99663783809,
                        193.26403242454398
                    ],
                    [
                        139.22047908185755,
                        194.0502906116634
                    ],
                    [
                        137.78156565908694,
                        193.37637463647263
                    ],
                    [
                        137.55673543677926,
                        194.67906682760236
                    ],
                    [
                        136.38761828077827,
                        195.03827577388836
                    ],
                    [
                        135.6231955249316,
                        196.18280999678103
                    ],
                    [
                        135.9604408583932,
                        199.20921048026537
                    ],
                    [
                        134.18428210216098,
                        199.58998007338192
                    ],
                    [
                        132.76785170162134,
                        202.43221430333296
                    ],
                    [
                        130.74437970085046,
                        203.28184836137234
                    ],
                    [
                        129.44036441146477,
                        202.499304001376
                    ],
                    [
                        128.04641703315588,
                        202.70055938102786
                    ],
                    [
                        126.6974356993087,
                        202.2309314876798
                    ],
                    [
                        125.5732845877692,
                        201.2689624551254
                    ],
                    [
                        124.42665045399872,
                        199.3212092357315
                    ],
                    [
                        124.24678627615253,
                        196.9903102884008
                    ],
                    [
                        124.87631089861475,
                        194.252419556946
                    ],
                    [
                        126.29274129915439,
                        193.10674233294566
                    ],
                    [
                        127.37192636623195,
                        193.21909370751172
                    ],
                    [
                        128.42862841107922,
                        192.5224095842063
                    ],
                    [
                        129.3729153447723,
                        191.08329564260998
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Sheopur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mau",
            "dt_code": "192",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        226.63446951516312,
                        187.09760808528705
                    ],
                    [
                        227.6237224933177,
                        187.1201494915336
                    ],
                    [
                        229.0176698716266,
                        188.02158700415328
                    ],
                    [
                        230.68141351670488,
                        187.2553923226139
                    ],
                    [
                        232.03039485055228,
                        187.796267609016
                    ],
                    [
                        232.39012320624488,
                        188.5847690575191
                    ],
                    [
                        231.44583627255156,
                        189.8456945843066
                    ],
                    [
                        229.78209262747328,
                        190.40833958140684
                    ],
                    [
                        229.71464356078081,
                        191.42068466863196
                    ],
                    [
                        231.35590418362835,
                        191.98286807647088
                    ],
                    [
                        231.49080231701328,
                        193.19662395629217
                    ],
                    [
                        230.366651205474,
                        193.96044873696513
                    ],
                    [
                        229.26498311616524,
                        193.24156319692275
                    ],
                    [
                        224.8807937811614,
                        194.56680035544105
                    ],
                    [
                        224.11637102531472,
                        192.49993170747035
                    ],
                    [
                        224.45361635877657,
                        190.8133417181943
                    ],
                    [
                        225.91501280377747,
                        189.80067584227885
                    ],
                    [
                        225.780114670393,
                        188.85503742790547
                    ],
                    [
                        226.63446951516312,
                        187.09760808528705
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Mau",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Fatehpur",
            "dt_code": "172",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        184.34390469904997,
                        187.81880074712382
                    ],
                    [
                        184.34390469904997,
                        188.71990802415218
                    ],
                    [
                        185.55798789951268,
                        189.59807839761783
                    ],
                    [
                        188.52574683397688,
                        190.7008497045765
                    ],
                    [
                        190.57170185697828,
                        190.38583695386671
                    ],
                    [
                        191.2911585683637,
                        191.60060125160982
                    ],
                    [
                        193.53946079144248,
                        192.5898416400952
                    ],
                    [
                        193.5844268359042,
                        193.803215400044
                    ],
                    [
                        195.3381025699057,
                        194.7015193402387
                    ],
                    [
                        193.69684194705792,
                        196.2725488307433
                    ],
                    [
                        193.40456265805778,
                        198.17852044928526
                    ],
                    [
                        194.1914684361352,
                        198.9851937099886
                    ],
                    [
                        193.20221545798063,
                        200.6199264759137
                    ],
                    [
                        193.13476639128817,
                        199.92589169498325
                    ],
                    [
                        190.63915092367074,
                        199.00759654297138
                    ],
                    [
                        189.69486398997765,
                        197.99921441360675
                    ],
                    [
                        189.3576186565158,
                        196.63146270212258
                    ],
                    [
                        187.98615430043765,
                        196.093067013952
                    ],
                    [
                        186.29992763312862,
                        196.81089477606395
                    ],
                    [
                        184.07410843228035,
                        196.22767993229462
                    ],
                    [
                        183.33216869866442,
                        195.30763875402073
                    ],
                    [
                        183.78182914328022,
                        193.7133620304553
                    ],
                    [
                        181.62345900912464,
                        193.01685651913726
                    ],
                    [
                        180.746621142124,
                        193.26403242454398
                    ],
                    [
                        179.127843541507,
                        192.81459811037888
                    ],
                    [
                        179.3976398082766,
                        190.85833667654896
                    ],
                    [
                        180.4318588308929,
                        189.7331457438375
                    ],
                    [
                        184.34390469904997,
                        187.81880074712382
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Fatehpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ballia",
            "dt_code": "193",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        232.39012320624488,
                        188.5847690575191
                    ],
                    [
                        233.08709689539933,
                        189.59807839761783
                    ],
                    [
                        235.29043307401662,
                        189.9807444587998
                    ],
                    [
                        238.82026756425034,
                        192.49993170747035
                    ],
                    [
                        239.67462240902023,
                        192.27513850395394
                    ],
                    [
                        241.40581512079075,
                        193.53364274333688
                    ],
                    [
                        243.65411734386976,
                        195.0158271675752
                    ],
                    [
                        243.29438898817716,
                        195.98088244922798
                    ],
                    [
                        242.66486436571495,
                        196.83332262062044
                    ],
                    [
                        239.40482614225084,
                        195.80137365201693
                    ],
                    [
                        239.15751289771197,
                        197.03516157986718
                    ],
                    [
                        237.49376925263368,
                        197.01273606343653
                    ],
                    [
                        236.52699929670985,
                        196.00331988104625
                    ],
                    [
                        235.56022934078578,
                        196.5866020947537
                    ],
                    [
                        235.650161429709,
                        197.55087712615182
                    ],
                    [
                        233.44682525109192,
                        198.82836668351786
                    ],
                    [
                        233.78407058455355,
                        195.71161302299788
                    ],
                    [
                        231.82804765047513,
                        195.1056200312849
                    ],
                    [
                        230.366651205474,
                        193.96044873696513
                    ],
                    [
                        231.49080231701328,
                        193.19662395629217
                    ],
                    [
                        231.35590418362835,
                        191.98286807647088
                    ],
                    [
                        229.71464356078081,
                        191.42068466863196
                    ],
                    [
                        229.78209262747328,
                        190.40833958140684
                    ],
                    [
                        231.44583627255156,
                        189.8456945843066
                    ],
                    [
                        232.39012320624488,
                        188.5847690575191
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Ballia",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jaunpur",
            "dt_code": "194",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        211.43594648714998,
                        191.5106450661874
                    ],
                    [
                        211.45842950938072,
                        190.0032518451435
                    ],
                    [
                        213.57183359907503,
                        188.56224496639715
                    ],
                    [
                        215.03323004407594,
                        189.12526756338514
                    ],
                    [
                        216.42717742238506,
                        193.30897009449382
                    ],
                    [
                        216.15738115561567,
                        194.27487702372116
                    ],
                    [
                        217.19160017823197,
                        195.37497364682082
                    ],
                    [
                        218.27078524530953,
                        195.28519326969462
                    ],
                    [
                        219.50735146800298,
                        196.83332262062044
                    ],
                    [
                        220.74391769069643,
                        197.28182519806245
                    ],
                    [
                        220.8113667573889,
                        198.9851937099886
                    ],
                    [
                        220.06942702377273,
                        198.51467483345783
                    ],
                    [
                        218.90030986777174,
                        199.25401075259998
                    ],
                    [
                        217.91105688961693,
                        198.6939334921198
                    ],
                    [
                        215.93255093330754,
                        199.00759654297138
                    ],
                    [
                        215.25806026638406,
                        201.49271821008938
                    ],
                    [
                        214.17887519930605,
                        201.49271821008938
                    ],
                    [
                        212.6500296876127,
                        200.7318445529345
                    ],
                    [
                        211.57084462053467,
                        199.47799671393585
                    ],
                    [
                        209.90710097545661,
                        199.23161074480328
                    ],
                    [
                        208.35577244153183,
                        198.31298915526997
                    ],
                    [
                        207.27658737445404,
                        196.94545796243835
                    ],
                    [
                        207.81617990799305,
                        196.3847165406956
                    ],
                    [
                        208.26584035260885,
                        194.2299618292372
                    ],
                    [
                        209.16516124184022,
                        194.18504559094163
                    ],
                    [
                        210.26682933114898,
                        193.1741539432444
                    ],
                    [
                        210.49165955345666,
                        191.89292977897162
                    ],
                    [
                        211.43594648714998,
                        191.5106450661874
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Jaunpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhilwara",
            "dt_code": "122",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        106.79996102506084,
                        195.41986227539462
                    ],
                    [
                        108.03652724775407,
                        195.28519326969462
                    ],
                    [
                        108.89088209252395,
                        196.1379390240507
                    ],
                    [
                        108.46370467013912,
                        197.75264167503315
                    ],
                    [
                        107.40700262529208,
                        199.1644091808904
                    ],
                    [
                        107.47445169198431,
                        200.0602402020371
                    ],
                    [
                        106.32781755821406,
                        201.35846781649565
                    ],
                    [
                        107.15968938075343,
                        202.65583774069506
                    ],
                    [
                        108.6885348924468,
                        203.61712946019736
                    ],
                    [
                        108.71101791467754,
                        205.3820050404829
                    ],
                    [
                        108.05901026998481,
                        206.2303726386462
                    ],
                    [
                        108.77846698137,
                        207.10068897027884
                    ],
                    [
                        107.31707053636887,
                        207.59146869830084
                    ],
                    [
                        104.95635320213614,
                        207.30147712021056
                    ],
                    [
                        105.13621737998233,
                        206.43124870171494
                    ],
                    [
                        101.56141684528711,
                        205.62762245409385
                    ],
                    [
                        100.81947711167118,
                        205.02468899318632
                    ],
                    [
                        99.13325044436169,
                        205.4266650158301
                    ],
                    [
                        97.91916724389921,
                        204.57795323824035
                    ],
                    [
                        96.48025382112883,
                        205.60529485572033
                    ],
                    [
                        94.92892528720449,
                        204.9353499080038
                    ],
                    [
                        93.13028350874151,
                        204.95768505722629
                    ],
                    [
                        91.30915870804756,
                        206.83293991104978
                    ],
                    [
                        90.2074906187388,
                        206.9668189460379
                    ],
                    [
                        89.87024528527718,
                        205.8508846134498
                    ],
                    [
                        88.13905257350643,
                        204.0417371158556
                    ],
                    [
                        87.75684119558287,
                        202.25329726165728
                    ],
                    [
                        89.30816972950743,
                        197.37151330654234
                    ],
                    [
                        89.01589044050729,
                        196.1379390240507
                    ],
                    [
                        90.31990572989275,
                        195.86869139764434
                    ],
                    [
                        90.1175585298156,
                        194.09520998230653
                    ],
                    [
                        90.27493968543126,
                        194.0053701966005
                    ],
                    [
                        90.76956617450878,
                        194.16258708031688
                    ],
                    [
                        91.62392101927844,
                        193.51117665570672
                    ],
                    [
                        93.15276653097226,
                        192.20769542289003
                    ],
                    [
                        93.7598081312035,
                        192.74717392275141
                    ],
                    [
                        96.50273684335957,
                        193.1741539432444
                    ],
                    [
                        97.85171817720698,
                        192.9044933571829
                    ],
                    [
                        99.20069951105415,
                        193.66843377739673
                    ],
                    [
                        100.68457897828625,
                        193.53364274333688
                    ],
                    [
                        102.39328866782625,
                        193.9155262326064
                    ],
                    [
                        102.68556795682662,
                        195.1056200312849
                    ],
                    [
                        103.71978697944269,
                        196.56417140263272
                    ],
                    [
                        104.4842097352896,
                        196.11550314868197
                    ],
                    [
                        105.83319106913677,
                        196.74360968966812
                    ],
                    [
                        106.79996102506084,
                        195.41986227539462
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Bhilwara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Samastipur",
            "dt_code": "221",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        269.6220080204305,
                        196.20524509418044
                    ],
                    [
                        268.1830945976599,
                        197.52845755243214
                    ],
                    [
                        266.54183397481233,
                        197.08001183698883
                    ],
                    [
                        266.4743849081199,
                        196.29498289111513
                    ],
                    [
                        265.10292055204195,
                        195.77893388424832
                    ],
                    [
                        263.84387130711775,
                        196.81089477606395
                    ],
                    [
                        261.5730860618082,
                        197.416355810887
                    ],
                    [
                        259.9767914834222,
                        199.3660069411241
                    ],
                    [
                        258.7402252607287,
                        200.39607115114092
                    ],
                    [
                        257.16641370457387,
                        200.03784942442138
                    ],
                    [
                        256.91910046003477,
                        199.14200814601628
                    ],
                    [
                        257.5486250824972,
                        196.69875167127742
                    ],
                    [
                        258.1556666827282,
                        196.3398502342488
                    ],
                    [
                        258.1331836604975,
                        193.98290959736352
                    ],
                    [
                        258.6502931718055,
                        192.97191204042917
                    ],
                    [
                        258.47042899395933,
                        192.34257922153648
                    ],
                    [
                        259.6395461499603,
                        190.16079614347558
                    ],
                    [
                        259.7519612611143,
                        190.83583932928775
                    ],
                    [
                        261.9328144175008,
                        191.15077819093682
                    ],
                    [
                        262.2475767287317,
                        191.69055322612286
                    ],
                    [
                        265.23781868542665,
                        193.2865013903948
                    ],
                    [
                        267.3961888195822,
                        193.55610856942474
                    ],
                    [
                        267.17135859727455,
                        195.26274752530708
                    ],
                    [
                        269.6220080204305,
                        196.20524509418044
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Samastipur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jalore",
            "dt_code": "116",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        46.09580100193193,
                        213.56041013680016
                    ],
                    [
                        48.141756024933784,
                        210.44452272491606
                    ],
                    [
                        48.321620202780196,
                        208.88476354534936
                    ],
                    [
                        50.74978660370516,
                        207.747600522261
                    ],
                    [
                        51.4917263373211,
                        208.1936240631212
                    ],
                    [
                        52.66084349332232,
                        207.34609395837174
                    ],
                    [
                        53.357817182476765,
                        208.12672689234802
                    ],
                    [
                        54.167205982784935,
                        206.78831156297593
                    ],
                    [
                        54.95411176086259,
                        206.63210445469207
                    ],
                    [
                        55.763500561170986,
                        204.66730845741046
                    ],
                    [
                        57.24738002840286,
                        202.76763993776422
                    ],
                    [
                        56.797719583787284,
                        202.27566278142064
                    ],
                    [
                        58.506429273327285,
                        200.1945794914024
                    ],
                    [
                        62.7557204749462,
                        201.58221337525544
                    ],
                    [
                        62.9805506972541,
                        200.75422740180898
                    ],
                    [
                        64.2395999421783,
                        200.75422740180898
                    ],
                    [
                        66.28555496517993,
                        199.27641050367447
                    ],
                    [
                        66.71273238756521,
                        197.99921441360675
                    ],
                    [
                        67.97178163248918,
                        197.93197039744808
                    ],
                    [
                        68.12916278810462,
                        197.01273606343653
                    ],
                    [
                        69.20834785518241,
                        196.11550314868197
                    ],
                    [
                        69.47814412195203,
                        194.81377799548545
                    ],
                    [
                        71.11940474479934,
                        195.91356859685237
                    ],
                    [
                        71.79389541172327,
                        197.55087712615182
                    ],
                    [
                        73.52508812349402,
                        198.85077131553004
                    ],
                    [
                        74.08716367926331,
                        200.3065218608382
                    ],
                    [
                        72.33348794526205,
                        202.45457779005397
                    ],
                    [
                        71.79389541172327,
                        203.97469987046992
                    ],
                    [
                        70.24256687779871,
                        203.28184836137234
                    ],
                    [
                        67.5670872323351,
                        207.6360790394944
                    ],
                    [
                        65.07147176471744,
                        209.9990012348939
                    ],
                    [
                        65.25133594256363,
                        211.80275261269435
                    ],
                    [
                        63.52014323079288,
                        211.69145683424563
                    ],
                    [
                        62.62082234156151,
                        213.18230939215954
                    ],
                    [
                        62.95806767502336,
                        213.84949824528678
                    ],
                    [
                        60.462452207405704,
                        214.02737806768494
                    ],
                    [
                        58.888640651250626,
                        212.55940071717254
                    ],
                    [
                        56.84268562824877,
                        213.20455257302189
                    ],
                    [
                        54.97659478309333,
                        213.13782229486714
                    ],
                    [
                        52.9756058045532,
                        213.7383154061759
                    ],
                    [
                        52.27863211539875,
                        213.13782229486714
                    ],
                    [
                        50.36757522578205,
                        213.09333421669788
                    ],
                    [
                        48.88369575854972,
                        213.96067496847235
                    ],
                    [
                        47.55719744693329,
                        214.11631211050508
                    ],
                    [
                        46.09580100193193,
                        213.56041013680016
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Jalore",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bundi",
            "dt_code": "121",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        121.43640849730377,
                        194.65661405439232
                    ],
                    [
                        122.08841614199696,
                        195.84625240869593
                    ],
                    [
                        121.23406129722684,
                        196.54174045152894
                    ],
                    [
                        121.05419711938066,
                        199.02999911902515
                    ],
                    [
                        119.86259694114892,
                        199.99306710097454
                    ],
                    [
                        119.1431402297635,
                        202.05199614190758
                    ],
                    [
                        115.90558502852991,
                        203.2371399091628
                    ],
                    [
                        112.78044493845027,
                        205.0470231346652
                    ],
                    [
                        113.07272422745086,
                        206.09644397322825
                    ],
                    [
                        111.54387871575705,
                        207.65838383546418
                    ],
                    [
                        110.66704084875619,
                        207.10068897027884
                    ],
                    [
                        110.0375162262942,
                        207.65838383546418
                    ],
                    [
                        108.77846698137,
                        207.10068897027884
                    ],
                    [
                        108.05901026998481,
                        206.2303726386462
                    ],
                    [
                        108.71101791467754,
                        205.3820050404829
                    ],
                    [
                        108.6885348924468,
                        203.61712946019736
                    ],
                    [
                        107.15968938075343,
                        202.65583774069506
                    ],
                    [
                        106.32781755821406,
                        201.35846781649565
                    ],
                    [
                        107.47445169198431,
                        200.0602402020371
                    ],
                    [
                        107.40700262529208,
                        199.1644091808904
                    ],
                    [
                        108.46370467013912,
                        197.75264167503315
                    ],
                    [
                        108.89088209252395,
                        196.1379390240507
                    ],
                    [
                        111.18415036006468,
                        196.83332262062044
                    ],
                    [
                        111.76870893806495,
                        195.71161302299788
                    ],
                    [
                        113.23010538306607,
                        195.57696428804604
                    ],
                    [
                        113.40996956091249,
                        194.54434627908023
                    ],
                    [
                        114.82639996145213,
                        193.64596925873474
                    ],
                    [
                        117.61429471806991,
                        193.84814051687778
                    ],
                    [
                        117.50187960691596,
                        194.67906682760236
                    ],
                    [
                        118.87334396299411,
                        195.12806759663243
                    ],
                    [
                        121.43640849730377,
                        194.65661405439232
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Bundi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Katihar",
            "dt_code": "212",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        294.26340038537455,
                        200.03784942442138
                    ],
                    [
                        292.68958882921925,
                        199.14200814601628
                    ],
                    [
                        291.3181244731411,
                        199.8139275615602
                    ],
                    [
                        289.85672802813997,
                        201.13469676161787
                    ],
                    [
                        290.1040412726786,
                        202.36512231870518
                    ],
                    [
                        291.02584518414096,
                        203.05829596306438
                    ],
                    [
                        290.5986677617559,
                        203.97469987046992
                    ],
                    [
                        289.7892789614475,
                        203.59477915918285
                    ],
                    [
                        289.0248562056008,
                        203.70652813366212
                    ],
                    [
                        288.7550599388312,
                        202.83471821043156
                    ],
                    [
                        286.68662189359884,
                        202.52166672601396
                    ],
                    [
                        283.9661762036735,
                        201.24658547723982
                    ],
                    [
                        281.9427042029024,
                        201.7835625944037
                    ],
                    [
                        281.7628400250562,
                        200.48561634919048
                    ],
                    [
                        282.52726278090313,
                        198.76115124493015
                    ],
                    [
                        282.1225683807488,
                        196.56417140263272
                    ],
                    [
                        284.66314989282773,
                        196.92303141147397
                    ],
                    [
                        288.12553531636945,
                        196.7884666727324
                    ],
                    [
                        288.64264482767726,
                        195.73405356975752
                    ],
                    [
                        290.7785319396023,
                        195.08317220572445
                    ],
                    [
                        290.7335658951406,
                        193.39884229375588
                    ],
                    [
                        291.70033585106466,
                        193.803215400044
                    ],
                    [
                        292.8020039403732,
                        196.093067013952
                    ],
                    [
                        294.0160871408359,
                        196.49687777229695
                    ],
                    [
                        293.5889097184506,
                        198.20093254423512
                    ],
                    [
                        294.26340038537455,
                        200.03784942442138
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Katihar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jhansi",
            "dt_code": "166",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        159.27533491172085,
                        193.03932836551178
                    ],
                    [
                        160.03975766756753,
                        192.38753838713353
                    ],
                    [
                        161.52363713479986,
                        193.758289237954
                    ],
                    [
                        164.06421864687877,
                        195.06072411993205
                    ],
                    [
                        165.72796229195728,
                        195.41986227539462
                    ],
                    [
                        166.80714735903484,
                        195.06072411993205
                    ],
                    [
                        166.17762273657308,
                        197.416355810887
                    ],
                    [
                        166.7396982923426,
                        199.455599272518
                    ],
                    [
                        165.0759546472641,
                        202.6334765397201
                    ],
                    [
                        164.82864140272568,
                        203.46067203741575
                    ],
                    [
                        165.05347162503335,
                        203.95235361647195
                    ],
                    [
                        165.25581882511074,
                        204.91301450679902
                    ],
                    [
                        165.43568300295715,
                        204.9800199544856
                    ],
                    [
                        165.7054792697263,
                        205.47132398487923
                    ],
                    [
                        164.55884513595606,
                        206.0517990767994
                    ],
                    [
                        162.87261846864703,
                        206.11876604490465
                    ],
                    [
                        161.88336549049222,
                        204.28752089294233
                    ],
                    [
                        159.9273425564138,
                        204.53327411505444
                    ],
                    [
                        159.18540282279764,
                        205.33734405868904
                    ],
                    [
                        159.05050468941317,
                        204.86834294836825
                    ],
                    [
                        160.3770030010296,
                        202.29802804698835
                    ],
                    [
                        159.18540282279764,
                        201.6269594291178
                    ],
                    [
                        160.10720673426,
                        199.9034993806925
                    ],
                    [
                        159.25285188948988,
                        199.52279082718655
                    ],
                    [
                        157.00454966641132,
                        200.4632304332613
                    ],
                    [
                        157.63407428887353,
                        202.16383264027468
                    ],
                    [
                        155.94784762156405,
                        201.67170446403247
                    ],
                    [
                        153.78947748740848,
                        203.41596763802636
                    ],
                    [
                        154.46396815433218,
                        204.44391284074004
                    ],
                    [
                        152.82270753148464,
                        205.9178583615714
                    ],
                    [
                        151.9908357089455,
                        204.55561380279602
                    ],
                    [
                        151.3163450420218,
                        202.566391413337
                    ],
                    [
                        151.24889597532956,
                        201.13469676161787
                    ],
                    [
                        151.24889597532956,
                        200.86613781456367
                    ],
                    [
                        151.47372619763723,
                        200.55277256290827
                    ],
                    [
                        152.05828477563773,
                        200.68707808871193
                    ],
                    [
                        152.37304708686884,
                        200.14980075221837
                    ],
                    [
                        152.41801313133033,
                        199.25401075259998
                    ],
                    [
                        152.8901565981771,
                        198.60430622092792
                    ],
                    [
                        155.9253645993333,
                        198.80596179442637
                    ],
                    [
                        156.8022024663344,
                        198.0664561097849
                    ],
                    [
                        158.1736668224121,
                        197.81989188029127
                    ],
                    [
                        157.47669313325787,
                        196.18280999678103
                    ],
                    [
                        159.27533491172085,
                        193.03932836551178
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Jhansi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shivpuri",
            "dt_code": "423",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        140.99663783809,
                        193.26403242454398
                    ],
                    [
                        141.78354361616744,
                        193.21909370751172
                    ],
                    [
                        145.02109881740103,
                        195.62184823875543
                    ],
                    [
                        147.04457081817213,
                        196.3622835170484
                    ],
                    [
                        149.40528815240486,
                        195.89113012702336
                    ],
                    [
                        150.5968883306366,
                        195.1056200312849
                    ],
                    [
                        149.89991464148216,
                        196.1603746400774
                    ],
                    [
                        151.78848850886857,
                        197.1921329555234
                    ],
                    [
                        152.8901565981771,
                        198.60430622092792
                    ],
                    [
                        152.41801313133033,
                        199.25401075259998
                    ],
                    [
                        152.37304708686884,
                        200.14980075221837
                    ],
                    [
                        152.05828477563773,
                        200.68707808871193
                    ],
                    [
                        151.47372619763723,
                        200.55277256290827
                    ],
                    [
                        151.24889597532956,
                        200.86613781456367
                    ],
                    [
                        151.24889597532956,
                        201.13469676161787
                    ],
                    [
                        151.3163450420218,
                        202.566391413337
                    ],
                    [
                        151.9908357089455,
                        204.55561380279602
                    ],
                    [
                        151.24889597532956,
                        204.28752089294233
                    ],
                    [
                        150.5519222861749,
                        205.80623419195268
                    ],
                    [
                        151.74352246440685,
                        206.2973335838446
                    ],
                    [
                        151.20392993086784,
                        206.52052043187786
                    ],
                    [
                        151.1814469086371,
                        206.78831156297593
                    ],
                    [
                        151.33882806425277,
                        207.7029926782419
                    ],
                    [
                        148.77576352994265,
                        209.909885047366
                    ],
                    [
                        149.04555979671227,
                        210.4667962001878
                    ],
                    [
                        147.89892566294202,
                        210.5558876273455
                    ],
                    [
                        145.83048761770942,
                        209.77620332962118
                    ],
                    [
                        146.5049782846329,
                        208.61725406473317
                    ],
                    [
                        143.76204957247683,
                        207.88141806323574
                    ],
                    [
                        142.41306823862965,
                        210.2217743605475
                    ],
                    [
                        141.4912643271673,
                        210.11039089402715
                    ],
                    [
                        140.29966414893556,
                        209.08537215057964
                    ],
                    [
                        139.85000370431976,
                        209.9767225597518
                    ],
                    [
                        138.59095445939533,
                        209.909885047366
                    ],
                    [
                        138.18626005924125,
                        209.0630832999944
                    ],
                    [
                        136.9946598810095,
                        209.10766075261603
                    ],
                    [
                        136.14030503623962,
                        206.5874715974225
                    ],
                    [
                        137.33190521447136,
                        205.96250627111868
                    ],
                    [
                        137.60170148124098,
                        204.26517817618077
                    ],
                    [
                        136.9496938365478,
                        201.22420824429963
                    ],
                    [
                        136.16278805847037,
                        200.88851913092162
                    ],
                    [
                        134.99367090246915,
                        201.73882010620457
                    ],
                    [
                        134.83628974685394,
                        202.72291982042339
                    ],
                    [
                        132.76785170162134,
                        202.43221430333296
                    ],
                    [
                        134.18428210216098,
                        199.58998007338192
                    ],
                    [
                        135.9604408583932,
                        199.20921048026537
                    ],
                    [
                        135.6231955249316,
                        196.18280999678103
                    ],
                    [
                        136.38761828077827,
                        195.03827577388836
                    ],
                    [
                        137.55673543677926,
                        194.67906682760236
                    ],
                    [
                        137.78156565908694,
                        193.37637463647263
                    ],
                    [
                        139.22047908185755,
                        194.0502906116634
                    ],
                    [
                        140.99663783809,
                        193.26403242454398
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Shivpuri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kota",
            "dt_code": "127",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        122.08841614199696,
                        195.84625240869593
                    ],
                    [
                        123.34746538692093,
                        195.30763875402073
                    ],
                    [
                        123.8645748982292,
                        194.20750384057538
                    ],
                    [
                        124.87631089861475,
                        194.252419556946
                    ],
                    [
                        124.24678627615253,
                        196.9903102884008
                    ],
                    [
                        124.42665045399872,
                        199.3212092357315
                    ],
                    [
                        125.5732845877692,
                        201.2689624551254
                    ],
                    [
                        124.96624298753795,
                        201.04518119656638
                    ],
                    [
                        121.59378965291944,
                        201.6940765994314
                    ],
                    [
                        121.03171409714969,
                        203.840618554123
                    ],
                    [
                        119.727698807764,
                        205.87320944735495
                    ],
                    [
                        121.27902734168856,
                        208.55037109478582
                    ],
                    [
                        122.62800867553574,
                        208.149026198588
                    ],
                    [
                        123.75215978707524,
                        208.99621525676162
                    ],
                    [
                        123.32498236469019,
                        210.6449750970949
                    ],
                    [
                        122.1108991642277,
                        209.9990012348939
                    ],
                    [
                        117.79415889591633,
                        212.0698373613199
                    ],
                    [
                        117.92905702930102,
                        214.82764376546936
                    ],
                    [
                        115.68075480622201,
                        215.2943194312777
                    ],
                    [
                        114.2643244056826,
                        213.29352284500686
                    ],
                    [
                        113.67976582768188,
                        211.7582350403839
                    ],
                    [
                        114.08446022783619,
                        209.9990012348939
                    ],
                    [
                        113.16265631637407,
                        209.75392217550876
                    ],
                    [
                        110.73448991544865,
                        207.5022450183834
                    ],
                    [
                        110.0375162262942,
                        207.65838383546418
                    ],
                    [
                        110.66704084875619,
                        207.10068897027884
                    ],
                    [
                        111.54387871575705,
                        207.65838383546418
                    ],
                    [
                        113.07272422745086,
                        206.09644397322825
                    ],
                    [
                        112.78044493845027,
                        205.0470231346652
                    ],
                    [
                        115.90558502852991,
                        203.2371399091628
                    ],
                    [
                        119.1431402297635,
                        202.05199614190758
                    ],
                    [
                        119.86259694114892,
                        199.99306710097454
                    ],
                    [
                        121.05419711938066,
                        199.02999911902515
                    ],
                    [
                        121.23406129722684,
                        196.54174045152894
                    ],
                    [
                        122.08841614199696,
                        195.84625240869593
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Kota",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Banda",
            "dt_code": "170",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        180.746621142124,
                        193.26403242454398
                    ],
                    [
                        181.62345900912464,
                        193.01685651913726
                    ],
                    [
                        183.78182914328022,
                        193.7133620304553
                    ],
                    [
                        183.33216869866442,
                        195.30763875402073
                    ],
                    [
                        184.07410843228035,
                        196.22767993229462
                    ],
                    [
                        186.29992763312862,
                        196.81089477606395
                    ],
                    [
                        187.98615430043765,
                        196.093067013952
                    ],
                    [
                        189.3576186565158,
                        196.63146270212258
                    ],
                    [
                        189.69486398997765,
                        197.99921441360675
                    ],
                    [
                        190.63915092367074,
                        199.00759654297138
                    ],
                    [
                        190.72908301259395,
                        199.67956214489175
                    ],
                    [
                        188.6381619451306,
                        201.15707501496422
                    ],
                    [
                        187.9636712782069,
                        202.20856545946924
                    ],
                    [
                        185.82778416628207,
                        203.5277267374853
                    ],
                    [
                        186.16502949974392,
                        206.83293991104978
                    ],
                    [
                        184.36638772128072,
                        206.43124870171494
                    ],
                    [
                        183.01740638743354,
                        207.2345499877598
                    ],
                    [
                        182.68016105397146,
                        208.2828168009213
                    ],
                    [
                        181.55600994243218,
                        206.7659970132791
                    ],
                    [
                        180.67917207543155,
                        207.68068838170717
                    ],
                    [
                        179.53253794166108,
                        207.5022450183834
                    ],
                    [
                        179.93723234181562,
                        206.94450639939396
                    ],
                    [
                        180.83655323104722,
                        205.8955340300563
                    ],
                    [
                        181.98318736481724,
                        205.06935702425443
                    ],
                    [
                        181.60097598689367,
                        203.95235361647195
                    ],
                    [
                        180.22951163081575,
                        203.2594942619557
                    ],
                    [
                        180.29696069750798,
                        201.58221337525544
                    ],
                    [
                        178.90301331919932,
                        201.44796909848702
                    ],
                    [
                        177.19430362965932,
                        202.20856545946924
                    ],
                    [
                        177.75637918542907,
                        200.44084426162237
                    ],
                    [
                        178.8130812302761,
                        199.52279082718655
                    ],
                    [
                        179.7573681639692,
                        197.7078069149785
                    ],
                    [
                        180.36440976420045,
                        195.66673115035542
                    ],
                    [
                        179.06039447481453,
                        194.90358023129806
                    ],
                    [
                        180.746621142124,
                        193.26403242454398
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Banda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ghazipur",
            "dt_code": "195",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        220.74391769069643,
                        197.28182519806245
                    ],
                    [
                        222.52007644692867,
                        196.87817753348384
                    ],
                    [
                        222.96973689154447,
                        195.55452192297844
                    ],
                    [
                        224.8807937811614,
                        194.56680035544105
                    ],
                    [
                        229.26498311616524,
                        193.24156319692275
                    ],
                    [
                        230.366651205474,
                        193.96044873696513
                    ],
                    [
                        231.82804765047513,
                        195.1056200312849
                    ],
                    [
                        233.78407058455355,
                        195.71161302299788
                    ],
                    [
                        233.44682525109192,
                        198.82836668351786
                    ],
                    [
                        232.2102590283987,
                        200.84375624283257
                    ],
                    [
                        231.2210060502439,
                        201.42559416036562
                    ],
                    [
                        230.65893049447413,
                        201.22420824429963
                    ],
                    [
                        229.30994916062673,
                        202.38748656767723
                    ],
                    [
                        227.66868853777942,
                        202.87943579019833
                    ],
                    [
                        227.5787564488562,
                        201.51509238360768
                    ],
                    [
                        225.71266560370077,
                        199.88110681022363
                    ],
                    [
                        224.70092960331522,
                        200.3736842122609
                    ],
                    [
                        223.35194826946804,
                        199.38840540886827
                    ],
                    [
                        222.3177292468515,
                        199.34360821675148
                    ],
                    [
                        220.8113667573889,
                        198.9851937099886
                    ],
                    [
                        220.74391769069643,
                        197.28182519806245
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Ghazipur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kaushambi",
            "dt_code": "174",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        193.20221545798063,
                        200.6199264759137
                    ],
                    [
                        194.1914684361352,
                        198.9851937099886
                    ],
                    [
                        193.40456265805778,
                        198.17852044928526
                    ],
                    [
                        193.69684194705792,
                        196.2725488307433
                    ],
                    [
                        195.3381025699057,
                        194.7015193402387
                    ],
                    [
                        196.1250083479831,
                        195.3300839783049
                    ],
                    [
                        195.85521208121372,
                        196.5866020947537
                    ],
                    [
                        197.2491594595224,
                        197.81989188029127
                    ],
                    [
                        199.34008052698596,
                        198.17852044928526
                    ],
                    [
                        200.2169183939866,
                        199.67956214489175
                    ],
                    [
                        199.60987679375512,
                        202.20856545946924
                    ],
                    [
                        198.44075963775435,
                        203.1477199638639
                    ],
                    [
                        197.04681225944546,
                        203.70652813366212
                    ],
                    [
                        196.25990648136803,
                        202.74528000599594
                    ],
                    [
                        192.5951738577494,
                        201.47034378172208
                    ],
                    [
                        193.20221545798063,
                        200.6199264759137
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Kaushambi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Khagaria",
            "dt_code": "223",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        268.1830945976599,
                        197.52845755243214
                    ],
                    [
                        269.6220080204305,
                        196.20524509418044
                    ],
                    [
                        269.9367703316616,
                        196.0257570534087
                    ],
                    [
                        271.12837050989333,
                        197.81989188029127
                    ],
                    [
                        272.6572160215867,
                        197.79747540315674
                    ],
                    [
                        274.52330686674236,
                        197.12486105991593
                    ],
                    [
                        275.60249193382015,
                        197.5732964417191
                    ],
                    [
                        276.2095335340514,
                        197.86472406080117
                    ],
                    [
                        275.96222028951274,
                        200.6646944733265
                    ],
                    [
                        276.479329800821,
                        201.98489119045092
                    ],
                    [
                        274.5457898889731,
                        203.77357448215753
                    ],
                    [
                        273.3766727329721,
                        203.46067203741575
                    ],
                    [
                        273.2867406440487,
                        201.5598399661722
                    ],
                    [
                        271.8253441990478,
                        199.9034993806925
                    ],
                    [
                        271.37568375443175,
                        201.02280166729292
                    ],
                    [
                        270.1166345095078,
                        199.76914011455023
                    ],
                    [
                        269.05993246466096,
                        200.17219024978698
                    ],
                    [
                        268.02571344204466,
                        199.074803500421
                    ],
                    [
                        268.1830945976599,
                        197.52845755243214
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Khagaria",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhojpur",
            "dt_code": "231",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        239.15751289771197,
                        197.03516157986718
                    ],
                    [
                        239.40482614225084,
                        195.80137365201693
                    ],
                    [
                        242.66486436571495,
                        196.83332262062044
                    ],
                    [
                        243.29438898817716,
                        195.98088244922798
                    ],
                    [
                        243.7665324550237,
                        196.3847165406956
                    ],
                    [
                        246.93663858956484,
                        196.51930924142306
                    ],
                    [
                        246.50946116718,
                        198.44744859082283
                    ],
                    [
                        245.5651742334869,
                        199.74674600661865
                    ],
                    [
                        245.92490258917928,
                        200.6199264759137
                    ],
                    [
                        244.86820054433247,
                        202.87943579019833
                    ],
                    [
                        243.72156641056222,
                        204.0417371158556
                    ],
                    [
                        242.1477548544069,
                        204.53327411505444
                    ],
                    [
                        241.27091698740628,
                        205.2926820703011
                    ],
                    [
                        239.17999591994294,
                        204.44391284074004
                    ],
                    [
                        239.20247894217368,
                        202.6781986877915
                    ],
                    [
                        238.50550525301924,
                        202.07436395027065
                    ],
                    [
                        239.8320035646359,
                        200.23935720684904
                    ],
                    [
                        239.2924110310969,
                        199.455599272518
                    ],
                    [
                        239.58469032009702,
                        197.50603772054114
                    ],
                    [
                        239.15751289771197,
                        197.03516157986718
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Bhojpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Prayagraj",
            "dt_code": "175",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        207.27658737445404,
                        196.94545796243835
                    ],
                    [
                        208.35577244153183,
                        198.31298915526997
                    ],
                    [
                        209.90710097545661,
                        199.23161074480328
                    ],
                    [
                        209.45744053084059,
                        202.99122530032582
                    ],
                    [
                        208.33328941930108,
                        203.3936150584725
                    ],
                    [
                        207.9735610636087,
                        204.26517817618077
                    ],
                    [
                        209.007780086225,
                        204.9353499080038
                    ],
                    [
                        209.83965190876415,
                        206.7436822131175
                    ],
                    [
                        208.3782554637628,
                        209.0407942008415
                    ],
                    [
                        207.27658737445404,
                        209.59794715175133
                    ],
                    [
                        206.4447155519149,
                        210.86767646549896
                    ],
                    [
                        204.21889635106686,
                        210.39997503206564
                    ],
                    [
                        203.47695661745092,
                        208.08212753183136
                    ],
                    [
                        202.0155601724498,
                        207.83681321461017
                    ],
                    [
                        200.1719523495251,
                        206.8998805551363
                    ],
                    [
                        199.56491074929363,
                        205.2926820703011
                    ],
                    [
                        198.30586150436966,
                        204.84600679110542
                    ],
                    [
                        198.44075963775435,
                        203.1477199638639
                    ],
                    [
                        199.60987679375512,
                        202.20856545946924
                    ],
                    [
                        200.2169183939866,
                        199.67956214489175
                    ],
                    [
                        199.34008052698596,
                        198.17852044928526
                    ],
                    [
                        201.40851857221833,
                        197.01273606343653
                    ],
                    [
                        202.08300923914203,
                        195.68917221653692
                    ],
                    [
                        203.38702452852772,
                        196.31741669227728
                    ],
                    [
                        205.38801350706808,
                        195.80137365201693
                    ],
                    [
                        205.9950551072991,
                        197.2369795936258
                    ],
                    [
                        207.27658737445404,
                        196.94545796243835
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Prayagraj",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Buxar",
            "dt_code": "232",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        233.44682525109192,
                        198.82836668351786
                    ],
                    [
                        235.650161429709,
                        197.55087712615182
                    ],
                    [
                        235.56022934078578,
                        196.5866020947537
                    ],
                    [
                        236.52699929670985,
                        196.00331988104625
                    ],
                    [
                        237.49376925263368,
                        197.01273606343653
                    ],
                    [
                        239.15751289771197,
                        197.03516157986718
                    ],
                    [
                        239.58469032009702,
                        197.50603772054114
                    ],
                    [
                        239.2924110310969,
                        199.455599272518
                    ],
                    [
                        239.8320035646359,
                        200.23935720684904
                    ],
                    [
                        238.50550525301924,
                        202.07436395027065
                    ],
                    [
                        235.1555349406317,
                        201.80593345658457
                    ],
                    [
                        234.97567076278574,
                        203.5500777978454
                    ],
                    [
                        232.95219876201463,
                        203.1924304433765
                    ],
                    [
                        231.2210060502439,
                        201.42559416036562
                    ],
                    [
                        232.2102590283987,
                        200.84375624283257
                    ],
                    [
                        233.44682525109192,
                        198.82836668351786
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Buxar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dakshin Dinajpur",
            "dt_code": "331",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        299.83918989861013,
                        198.22334438157935
                    ],
                    [
                        302.78446581084336,
                        200.12741099867793
                    ],
                    [
                        305.21263221176855,
                        199.38840540886827
                    ],
                    [
                        305.61732661192264,
                        201.98489119045092
                    ],
                    [
                        307.7981797683092,
                        203.46067203741575
                    ],
                    [
                        306.65154563453916,
                        204.9800199544856
                    ],
                    [
                        305.1227001228451,
                        205.136357181871
                    ],
                    [
                        304.0659980779981,
                        204.66730845741046
                    ],
                    [
                        299.6818087429947,
                        204.6449700309717
                    ],
                    [
                        299.8841559430716,
                        202.90179419953387
                    ],
                    [
                        297.23115931983875,
                        202.96886790557306
                    ],
                    [
                        295.9046610082221,
                        202.29802804698835
                    ],
                    [
                        295.7247968303757,
                        200.97804184300168
                    ],
                    [
                        298.55765763145496,
                        198.6939334921198
                    ],
                    [
                        299.83918989861013,
                        198.22334438157935
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Dakshin Dinajpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Malda",
            "dt_code": "332",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        289.0248562056008,
                        203.70652813366212
                    ],
                    [
                        289.7892789614475,
                        203.59477915918285
                    ],
                    [
                        290.5986677617559,
                        203.97469987046992
                    ],
                    [
                        291.02584518414096,
                        203.05829596306438
                    ],
                    [
                        290.1040412726786,
                        202.36512231870518
                    ],
                    [
                        289.85672802813997,
                        201.13469676161787
                    ],
                    [
                        291.3181244731411,
                        199.8139275615602
                    ],
                    [
                        292.68958882921925,
                        199.14200814601628
                    ],
                    [
                        294.26340038537455,
                        200.03784942442138
                    ],
                    [
                        294.870441985606,
                        202.58875337605951
                    ],
                    [
                        295.47748358583704,
                        203.48302385728886
                    ],
                    [
                        297.23115931983875,
                        202.96886790557306
                    ],
                    [
                        299.8841559430716,
                        202.90179419953387
                    ],
                    [
                        299.6818087429947,
                        204.6449700309717
                    ],
                    [
                        299.92912198753334,
                        206.60978815135434
                    ],
                    [
                        299.0747671427632,
                        208.39430211606344
                    ],
                    [
                        297.3660574532232,
                        209.68707722511004
                    ],
                    [
                        296.6016346973763,
                        208.55037109478582
                    ],
                    [
                        295.34258545245234,
                        209.28596062335555
                    ],
                    [
                        295.83721194152986,
                        210.15494502362048
                    ],
                    [
                        294.2858834076053,
                        211.6246764104614
                    ],
                    [
                        293.67884180737383,
                        212.91537209233132
                    ],
                    [
                        291.6778528288339,
                        210.24405031093966
                    ],
                    [
                        292.68958882921925,
                        209.17452506761623
                    ],
                    [
                        292.59965674029627,
                        208.327411674482
                    ],
                    [
                        290.2614224282943,
                        206.4758850682062
                    ],
                    [
                        290.0365922059864,
                        205.5606389046763
                    ],
                    [
                        290.01410918375564,
                        203.840618554123
                    ],
                    [
                        289.0248562056008,
                        203.70652813366212
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Malda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tikamgarh",
            "dt_code": "424",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        159.18540282279764,
                        205.33734405868904
                    ],
                    [
                        159.9273425564138,
                        204.53327411505444
                    ],
                    [
                        161.88336549049222,
                        204.28752089294233
                    ],
                    [
                        162.87261846864703,
                        206.11876604490465
                    ],
                    [
                        164.55884513595606,
                        206.0517990767994
                    ],
                    [
                        165.7054792697263,
                        205.47132398487923
                    ],
                    [
                        166.0202415809572,
                        209.46424459755315
                    ],
                    [
                        165.43568300295715,
                        211.17941687564286
                    ],
                    [
                        165.41319998072595,
                        212.9598640954677
                    ],
                    [
                        164.15415073580198,
                        213.3602479754668
                    ],
                    [
                        163.81690540234013,
                        214.16077766532902
                    ],
                    [
                        162.28805989064654,
                        213.84949824528678
                    ],
                    [
                        161.29880691249195,
                        215.24987875809387
                    ],
                    [
                        160.7367313567222,
                        216.93794096062277
                    ],
                    [
                        159.7025123341059,
                        216.64929284551118
                    ],
                    [
                        160.26458788987543,
                        215.22765805627142
                    ],
                    [
                        159.23036886725913,
                        213.7160781042428
                    ],
                    [
                        157.29682895541146,
                        214.3830907794068
                    ],
                    [
                        157.7240063777965,
                        212.6706485249627
                    ],
                    [
                        157.56662522218107,
                        210.17722171690622
                    ],
                    [
                        156.19516086610292,
                        209.5533806263865
                    ],
                    [
                        155.54315322140997,
                        208.59495999037682
                    ],
                    [
                        155.65556833256414,
                        206.7436822131175
                    ],
                    [
                        158.6682933114896,
                        206.31965339718022
                    ],
                    [
                        159.18540282279764,
                        205.33734405868904
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Tikamgarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Varanasi",
            "dt_code": "197",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        215.25806026638406,
                        201.49271821008938
                    ],
                    [
                        215.93255093330754,
                        199.00759654297138
                    ],
                    [
                        217.91105688961693,
                        198.6939334921198
                    ],
                    [
                        218.90030986777174,
                        199.25401075259998
                    ],
                    [
                        220.06942702377273,
                        198.51467483345783
                    ],
                    [
                        220.8113667573889,
                        198.9851937099886
                    ],
                    [
                        222.3177292468515,
                        199.34360821675148
                    ],
                    [
                        221.59827253546632,
                        200.57515745618628
                    ],
                    [
                        222.52007644692867,
                        201.13469676161787
                    ],
                    [
                        221.84558578000497,
                        202.6781986877915
                    ],
                    [
                        220.06942702377273,
                        202.78999961574732
                    ],
                    [
                        220.20432515715743,
                        203.95235361647195
                    ],
                    [
                        219.84459680146483,
                        204.55561380279602
                    ],
                    [
                        216.58455857800072,
                        204.60029242140507
                    ],
                    [
                        216.47214346684655,
                        203.50537542397234
                    ],
                    [
                        214.87584888846072,
                        203.7512259522984
                    ],
                    [
                        215.4154414219995,
                        202.65583774069506
                    ],
                    [
                        215.25806026638406,
                        201.49271821008938
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Varanasi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Darbhanga",
            "dt_code": "215",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        259.5271310388064,
                        186.646723859879
                    ],
                    [
                        259.5496140610371,
                        184.3229728047569
                    ],
                    [
                        260.4489349502687,
                        184.45841513554478
                    ],
                    [
                        261.2358407283464,
                        184.90981958013055
                    ],
                    [
                        261.16839166165414,
                        186.80454549797548
                    ],
                    [
                        262.135161617578,
                        187.1201494915336
                    ],
                    [
                        263.5515920181176,
                        186.30849050437809
                    ],
                    [
                        264.06870152942565,
                        187.5709215713868
                    ],
                    [
                        265.4851319299653,
                        188.4496205238513
                    ],
                    [
                        266.0696905079658,
                        187.751200533506
                    ],
                    [
                        268.7226871311989,
                        189.05771361222082
                    ],
                    [
                        268.7226871311989,
                        190.11578480912993
                    ],
                    [
                        269.57704197596877,
                        191.0608009326163
                    ],
                    [
                        269.666974064892,
                        192.0053519936551
                    ],
                    [
                        268.5203399311217,
                        193.89306458860796
                    ],
                    [
                        269.9592533538921,
                        194.9709291740636
                    ],
                    [
                        269.9367703316616,
                        196.0257570534087
                    ],
                    [
                        269.6220080204305,
                        196.20524509418044
                    ],
                    [
                        267.17135859727455,
                        195.26274752530708
                    ],
                    [
                        267.3961888195822,
                        193.55610856942474
                    ],
                    [
                        265.23781868542665,
                        193.2865013903948
                    ],
                    [
                        262.2475767287317,
                        191.69055322612286
                    ],
                    [
                        261.9328144175008,
                        191.15077819093682
                    ],
                    [
                        259.7519612611143,
                        190.83583932928775
                    ],
                    [
                        259.6395461499603,
                        190.16079614347558
                    ],
                    [
                        259.8643763722682,
                        189.55305382999273
                    ],
                    [
                        259.5271310388064,
                        186.646723859879
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Darbhanga",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Saharsa",
            "dt_code": "214",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        275.60249193382015,
                        197.5732964417191
                    ],
                    [
                        274.52330686674236,
                        197.12486105991593
                    ],
                    [
                        272.6572160215867,
                        197.79747540315674
                    ],
                    [
                        271.12837050989333,
                        197.81989188029127
                    ],
                    [
                        269.9367703316616,
                        196.0257570534087
                    ],
                    [
                        269.9592533538921,
                        194.9709291740636
                    ],
                    [
                        268.5203399311217,
                        193.89306458860796
                    ],
                    [
                        269.666974064892,
                        192.0053519936551
                    ],
                    [
                        269.57704197596877,
                        191.0608009326163
                    ],
                    [
                        270.29649868735396,
                        190.43084194475153
                    ],
                    [
                        271.6904460656631,
                        191.75801444433387
                    ],
                    [
                        273.35418971074114,
                        191.30822824651284
                    ],
                    [
                        274.34344268889595,
                        193.803215400044
                    ],
                    [
                        276.27698260074385,
                        193.7133620304553
                    ],
                    [
                        276.68167700089816,
                        196.1379390240507
                    ],
                    [
                        275.60249193382015,
                        197.5732964417191
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Saharsa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sirohi",
            "dt_code": "117",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        71.79389541172327,
                        203.97469987046992
                    ],
                    [
                        71.9962426118002,
                        205.58296700592075
                    ],
                    [
                        72.73818234541613,
                        206.2750135196556
                    ],
                    [
                        74.76165434618724,
                        206.31965339718022
                    ],
                    [
                        74.33447692380241,
                        207.25685928196253
                    ],
                    [
                        72.69321630095487,
                        208.52807627351444
                    ],
                    [
                        72.62576723426241,
                        209.17452506761623
                    ],
                    [
                        74.91903550180268,
                        210.39997503206564
                    ],
                    [
                        74.89655247957194,
                        211.9808130508498
                    ],
                    [
                        74.8291034128797,
                        213.09333421669788
                    ],
                    [
                        72.7606653676471,
                        216.3161864314664
                    ],
                    [
                        72.1311407451849,
                        218.24744565871737
                    ],
                    [
                        68.73620438833586,
                        218.13650327845693
                    ],
                    [
                        68.08419674364313,
                        216.5826759270039
                    ],
                    [
                        65.67851336494891,
                        215.98302542702993
                    ],
                    [
                        65.38623407594855,
                        217.33754003906057
                    ],
                    [
                        63.22786394179275,
                        214.96099063533734
                    ],
                    [
                        62.95806767502336,
                        213.84949824528678
                    ],
                    [
                        62.62082234156151,
                        213.18230939215954
                    ],
                    [
                        63.52014323079288,
                        211.69145683424563
                    ],
                    [
                        65.25133594256363,
                        211.80275261269435
                    ],
                    [
                        65.07147176471744,
                        209.9990012348939
                    ],
                    [
                        67.5670872323351,
                        207.6360790394944
                    ],
                    [
                        70.24256687779871,
                        203.28184836137234
                    ],
                    [
                        71.79389541172327,
                        203.97469987046992
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Sirohi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chitrakoot",
            "dt_code": "171",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        190.63915092367074,
                        199.00759654297138
                    ],
                    [
                        193.13476639128817,
                        199.92589169498325
                    ],
                    [
                        193.20221545798063,
                        200.6199264759137
                    ],
                    [
                        192.5951738577494,
                        201.47034378172208
                    ],
                    [
                        196.25990648136803,
                        202.74528000599594
                    ],
                    [
                        197.04681225944546,
                        203.70652813366212
                    ],
                    [
                        198.44075963775435,
                        203.1477199638639
                    ],
                    [
                        198.30586150436966,
                        204.84600679110542
                    ],
                    [
                        197.69881990413842,
                        205.49365309208832
                    ],
                    [
                        196.1250083479831,
                        205.7615827654164
                    ],
                    [
                        194.43878168067408,
                        205.20335507314826
                    ],
                    [
                        194.23643448059693,
                        207.03375508425228
                    ],
                    [
                        192.7075889689031,
                        209.30824698927938
                    ],
                    [
                        192.1230303909026,
                        209.46424459755315
                    ],
                    [
                        190.8639811459784,
                        208.66184146665967
                    ],
                    [
                        189.55996585659295,
                        208.43889449815975
                    ],
                    [
                        188.30091661166875,
                        209.0630832999944
                    ],
                    [
                        187.8737391892837,
                        207.36840200246638
                    ],
                    [
                        188.57071287843837,
                        206.83293991104978
                    ],
                    [
                        188.07608638936085,
                        204.86834294836825
                    ],
                    [
                        186.29992763312862,
                        205.98482984918786
                    ],
                    [
                        186.16502949974392,
                        206.83293991104978
                    ],
                    [
                        185.82778416628207,
                        203.5277267374853
                    ],
                    [
                        187.9636712782069,
                        202.20856545946924
                    ],
                    [
                        188.6381619451306,
                        201.15707501496422
                    ],
                    [
                        190.72908301259395,
                        199.67956214489175
                    ],
                    [
                        190.63915092367074,
                        199.00759654297138
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Chitrakoot",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chandauli",
            "dt_code": "196",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        222.3177292468515,
                        199.34360821675148
                    ],
                    [
                        223.35194826946804,
                        199.38840540886827
                    ],
                    [
                        224.70092960331522,
                        200.3736842122609
                    ],
                    [
                        225.71266560370077,
                        199.88110681022363
                    ],
                    [
                        227.5787564488562,
                        201.51509238360768
                    ],
                    [
                        227.66868853777942,
                        202.87943579019833
                    ],
                    [
                        224.90327680339237,
                        204.91301450679902
                    ],
                    [
                        224.92575982562312,
                        207.747600522261
                    ],
                    [
                        225.57776747031585,
                        211.44658443479636
                    ],
                    [
                        224.02643893639151,
                        212.42589523974857
                    ],
                    [
                        222.49759342469793,
                        212.4481467670526
                    ],
                    [
                        221.03619697969657,
                        211.44658443479636
                    ],
                    [
                        220.65398560177323,
                        208.59495999037682
                    ],
                    [
                        220.67646862400397,
                        206.8775672574859
                    ],
                    [
                        221.71068764662027,
                        206.92219360243263
                    ],
                    [
                        222.0928990245436,
                        204.82367038178552
                    ],
                    [
                        220.20432515715743,
                        203.95235361647195
                    ],
                    [
                        220.06942702377273,
                        202.78999961574732
                    ],
                    [
                        221.84558578000497,
                        202.6781986877915
                    ],
                    [
                        222.52007644692867,
                        201.13469676161787
                    ],
                    [
                        221.59827253546632,
                        200.57515745618628
                    ],
                    [
                        222.3177292468515,
                        199.34360821675148
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Chandauli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhadohi",
            "dt_code": "198",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        209.90710097545661,
                        199.23161074480328
                    ],
                    [
                        211.57084462053467,
                        199.47799671393585
                    ],
                    [
                        212.6500296876127,
                        200.7318445529345
                    ],
                    [
                        214.17887519930605,
                        201.49271821008938
                    ],
                    [
                        215.25806026638406,
                        201.49271821008938
                    ],
                    [
                        215.4154414219995,
                        202.65583774069506
                    ],
                    [
                        214.87584888846072,
                        203.7512259522984
                    ],
                    [
                        212.6725127098432,
                        204.1311165716425
                    ],
                    [
                        209.52488959753282,
                        203.5277267374853
                    ],
                    [
                        209.007780086225,
                        204.9353499080038
                    ],
                    [
                        207.9735610636087,
                        204.26517817618077
                    ],
                    [
                        208.33328941930108,
                        203.3936150584725
                    ],
                    [
                        209.45744053084059,
                        202.99122530032582
                    ],
                    [
                        209.90710097545661,
                        199.23161074480328
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Bhadohi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Baran",
            "dt_code": "128",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        125.5732845877692,
                        201.2689624551254
                    ],
                    [
                        126.6974356993087,
                        202.2309314876798
                    ],
                    [
                        128.04641703315588,
                        202.70055938102786
                    ],
                    [
                        129.44036441146477,
                        202.499304001376
                    ],
                    [
                        130.74437970085046,
                        203.28184836137234
                    ],
                    [
                        132.76785170162134,
                        202.43221430333296
                    ],
                    [
                        134.83628974685394,
                        202.72291982042339
                    ],
                    [
                        134.99367090246915,
                        201.73882010620457
                    ],
                    [
                        136.16278805847037,
                        200.88851913092162
                    ],
                    [
                        136.9496938365478,
                        201.22420824429963
                    ],
                    [
                        137.60170148124098,
                        204.26517817618077
                    ],
                    [
                        137.33190521447136,
                        205.96250627111868
                    ],
                    [
                        136.14030503623962,
                        206.5874715974225
                    ],
                    [
                        135.5107804137774,
                        206.0294762520095
                    ],
                    [
                        134.11683303546852,
                        206.09644397322825
                    ],
                    [
                        132.81281774608283,
                        207.01144328844862
                    ],
                    [
                        131.55376850115886,
                        206.67673630965936
                    ],
                    [
                        129.7326437004649,
                        207.2345499877598
                    ],
                    [
                        129.50781347815723,
                        208.52807627351444
                    ],
                    [
                        130.81182876754292,
                        210.02127966223958
                    ],
                    [
                        128.67594165561786,
                        211.02355272093314
                    ],
                    [
                        129.41788138923403,
                        212.0475816528899
                    ],
                    [
                        130.7668627230812,
                        211.80275261269435
                    ],
                    [
                        132.04839499023615,
                        212.62615013878042
                    ],
                    [
                        132.5655045015444,
                        215.0721063241832
                    ],
                    [
                        131.21652316769678,
                        216.6270874483855
                    ],
                    [
                        129.50781347815723,
                        215.20543711093757
                    ],
                    [
                        128.85580583346427,
                        215.44985411948062
                    ],
                    [
                        129.26050023361813,
                        217.0267475314228
                    ],
                    [
                        128.02393401092513,
                        216.84913051593702
                    ],
                    [
                        125.77563178784635,
                        217.22654815781567
                    ],
                    [
                        124.71892974299908,
                        215.8275315924002
                    ],
                    [
                        124.62899765407587,
                        213.40473017055706
                    ],
                    [
                        123.84209187599845,
                        213.4269709005917
                    ],
                    [
                        123.32498236469019,
                        210.6449750970949
                    ],
                    [
                        123.75215978707524,
                        208.99621525676162
                    ],
                    [
                        122.62800867553574,
                        208.149026198588
                    ],
                    [
                        121.27902734168856,
                        208.55037109478582
                    ],
                    [
                        119.727698807764,
                        205.87320944735495
                    ],
                    [
                        121.03171409714969,
                        203.840618554123
                    ],
                    [
                        121.59378965291944,
                        201.6940765994314
                    ],
                    [
                        124.96624298753795,
                        201.04518119656638
                    ],
                    [
                        125.5732845877692,
                        201.2689624551254
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Baran",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sahibganj",
            "dt_code": "352",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        286.68662189359884,
                        202.52166672601396
                    ],
                    [
                        288.7550599388312,
                        202.83471821043156
                    ],
                    [
                        289.0248562056008,
                        203.70652813366212
                    ],
                    [
                        290.01410918375564,
                        203.840618554123
                    ],
                    [
                        290.0365922059864,
                        205.5606389046763
                    ],
                    [
                        290.2614224282943,
                        206.4758850682062
                    ],
                    [
                        292.59965674029627,
                        208.327411674482
                    ],
                    [
                        292.68958882921925,
                        209.17452506761623
                    ],
                    [
                        291.6778528288339,
                        210.24405031093966
                    ],
                    [
                        290.913430072987,
                        212.02532569834705
                    ],
                    [
                        289.04733922783157,
                        212.09209282365572
                    ],
                    [
                        286.05709727113685,
                        211.15715130844973
                    ],
                    [
                        285.24770847082846,
                        208.88476354534936
                    ],
                    [
                        286.23696144898327,
                        206.92219360243263
                    ],
                    [
                        285.51750473759785,
                        206.18573075400394
                    ],
                    [
                        285.6074368265208,
                        204.73432222357246
                    ],
                    [
                        286.68662189359884,
                        202.52166672601396
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Sahibganj",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kaimur",
            "dt_code": "233",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        227.66868853777942,
                        202.87943579019833
                    ],
                    [
                        229.30994916062673,
                        202.38748656767723
                    ],
                    [
                        230.65893049447413,
                        201.22420824429963
                    ],
                    [
                        231.2210060502439,
                        201.42559416036562
                    ],
                    [
                        232.95219876201463,
                        203.1924304433765
                    ],
                    [
                        232.05287787278303,
                        203.95235361647195
                    ],
                    [
                        231.60321742816723,
                        206.07412165053262
                    ],
                    [
                        232.05287787278303,
                        207.27916832610663
                    ],
                    [
                        231.69314951709043,
                        208.55037109478582
                    ],
                    [
                        230.2767191165508,
                        210.5558876273455
                    ],
                    [
                        231.3109381391671,
                        211.80275261269435
                    ],
                    [
                        230.50154933885847,
                        213.2267955087196
                    ],
                    [
                        230.65893049447413,
                        214.87209369724945
                    ],
                    [
                        230.00692284978118,
                        215.33875913063395
                    ],
                    [
                        227.46634133770203,
                        215.18321592207457
                    ],
                    [
                        227.55627342662524,
                        213.67160276611997
                    ],
                    [
                        226.949231826394,
                        212.18111221241656
                    ],
                    [
                        225.57776747031585,
                        211.44658443479636
                    ],
                    [
                        224.92575982562312,
                        207.747600522261
                    ],
                    [
                        224.90327680339237,
                        204.91301450679902
                    ],
                    [
                        227.66868853777942,
                        202.87943579019833
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Kaimur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rohtas",
            "dt_code": "234",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        238.50550525301924,
                        202.07436395027065
                    ],
                    [
                        239.20247894217368,
                        202.6781986877915
                    ],
                    [
                        239.17999591994294,
                        204.44391284074004
                    ],
                    [
                        241.27091698740628,
                        205.2926820703011
                    ],
                    [
                        241.04608676509838,
                        205.82855952832188
                    ],
                    [
                        240.68635840940578,
                        206.5874715974225
                    ],
                    [
                        238.95516569763527,
                        207.72529672508693
                    ],
                    [
                        235.71761049640145,
                        211.6246764104614
                    ],
                    [
                        234.59345938486194,
                        213.69384055756348
                    ],
                    [
                        233.53675734001513,
                        215.20543711093757
                    ],
                    [
                        232.5924704063218,
                        215.4720723871876
                    ],
                    [
                        230.32168516101228,
                        215.93859983188412
                    ],
                    [
                        227.15157902647115,
                        215.58316007640576
                    ],
                    [
                        227.46634133770203,
                        215.18321592207457
                    ],
                    [
                        230.00692284978118,
                        215.33875913063395
                    ],
                    [
                        230.65893049447413,
                        214.87209369724945
                    ],
                    [
                        230.50154933885847,
                        213.2267955087196
                    ],
                    [
                        231.3109381391671,
                        211.80275261269435
                    ],
                    [
                        230.2767191165508,
                        210.5558876273455
                    ],
                    [
                        231.69314951709043,
                        208.55037109478582
                    ],
                    [
                        232.05287787278303,
                        207.27916832610663
                    ],
                    [
                        231.60321742816723,
                        206.07412165053262
                    ],
                    [
                        232.05287787278303,
                        203.95235361647195
                    ],
                    [
                        232.95219876201463,
                        203.1924304433765
                    ],
                    [
                        234.97567076278574,
                        203.5500777978454
                    ],
                    [
                        235.1555349406317,
                        201.80593345658457
                    ],
                    [
                        238.50550525301924,
                        202.07436395027065
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Rohtas",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Udaipur",
            "dt_code": "130",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        74.89655247957194,
                        211.9808130508498
                    ],
                    [
                        75.81835639103429,
                        211.379795875424
                    ],
                    [
                        77.59451514726675,
                        209.41967509379015
                    ],
                    [
                        77.79686234734345,
                        207.72529672508693
                    ],
                    [
                        78.74114928103677,
                        206.4758850682062
                    ],
                    [
                        79.91026643703776,
                        206.20805182178844
                    ],
                    [
                        79.68543621472986,
                        207.47993847381093
                    ],
                    [
                        81.68642519327,
                        209.9990012348939
                    ],
                    [
                        82.85554234927099,
                        210.13266808266695
                    ],
                    [
                        82.60822910473235,
                        211.6246764104614
                    ],
                    [
                        84.13707461642593,
                        211.46884679468383
                    ],
                    [
                        84.85653132781113,
                        210.6004318568227
                    ],
                    [
                        87.35214679542878,
                        209.17452506761623
                    ],
                    [
                        88.45381488473731,
                        209.9767225597518
                    ],
                    [
                        89.53299995181533,
                        209.82076489392534
                    ],
                    [
                        88.97092439604558,
                        212.2033664445533
                    ],
                    [
                        89.93769435196964,
                        212.5816507700439
                    ],
                    [
                        91.42157381920129,
                        214.7609670389707
                    ],
                    [
                        91.21922661912436,
                        216.2495585997637
                    ],
                    [
                        91.98364937497126,
                        216.60488180888905
                    ],
                    [
                        92.83800421974115,
                        219.4895891206088
                    ],
                    [
                        93.7598081312035,
                        219.910859461308
                    ],
                    [
                        92.68062306412571,
                        221.37354800699467
                    ],
                    [
                        90.94943035235497,
                        220.73097979638413
                    ],
                    [
                        90.70211710781632,
                        222.4588111186904
                    ],
                    [
                        91.42157381920129,
                        224.87092452690558
                    ],
                    [
                        90.6571510633546,
                        225.55642594565427
                    ],
                    [
                        89.69038110743077,
                        223.87543862230092
                    ],
                    [
                        88.49878092919903,
                        224.6055083557327
                    ],
                    [
                        86.20551266165853,
                        224.29581321650204
                    ],
                    [
                        85.05887852788828,
                        225.11419286619457
                    ],
                    [
                        82.60822910473235,
                        225.02573495030686
                    ],
                    [
                        81.30421381534688,
                        224.6497467426288
                    ],
                    [
                        80.29247781496133,
                        225.1805338301803
                    ],
                    [
                        79.55053808134517,
                        226.63949974793672
                    ],
                    [
                        78.15659070303627,
                        227.03722220733812
                    ],
                    [
                        78.29148883642097,
                        225.60064407781806
                    ],
                    [
                        78.85356439219072,
                        225.2689851524994
                    ],
                    [
                        78.69618323657505,
                        223.38858241975362
                    ],
                    [
                        77.39216794718959,
                        223.18938093673984
                    ],
                    [
                        76.3129828801118,
                        224.03032339958867
                    ],
                    [
                        75.88580545772675,
                        222.81305906840663
                    ],
                    [
                        74.58179016834083,
                        222.0380626548403
                    ],
                    [
                        73.72743532357094,
                        220.93041901026214
                    ],
                    [
                        74.9640015462644,
                        218.33619522385152
                    ],
                    [
                        73.86233345695564,
                        217.67047942288724
                    ],
                    [
                        74.0197146125713,
                        216.02745005059455
                    ],
                    [
                        72.7606653676471,
                        216.3161864314664
                    ],
                    [
                        74.8291034128797,
                        213.09333421669788
                    ],
                    [
                        74.89655247957194,
                        211.9808130508498
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Udaipur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Godda",
            "dt_code": "351",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        285.6074368265208,
                        204.73432222357246
                    ],
                    [
                        285.51750473759785,
                        206.18573075400394
                    ],
                    [
                        286.23696144898327,
                        206.92219360243263
                    ],
                    [
                        285.24770847082846,
                        208.88476354534936
                    ],
                    [
                        286.05709727113685,
                        211.15715130844973
                    ],
                    [
                        285.33764055975166,
                        211.26847667634127
                    ],
                    [
                        285.5399877598286,
                        213.60488792302095
                    ],
                    [
                        284.75308198175094,
                        215.76088773258977
                    ],
                    [
                        284.12355735928895,
                        215.91638666992276
                    ],
                    [
                        283.08933833667265,
                        214.40532074741031
                    ],
                    [
                        282.1675344252103,
                        214.60537947340043
                    ],
                    [
                        280.3688926467473,
                        213.8272621668114
                    ],
                    [
                        279.334673624131,
                        213.93844011300024
                    ],
                    [
                        280.1890284689009,
                        210.3108766766228
                    ],
                    [
                        280.88600215805513,
                        209.82076489392534
                    ],
                    [
                        280.7061379802092,
                        207.59146869830084
                    ],
                    [
                        281.15579842482475,
                        206.9668189460379
                    ],
                    [
                        282.90947415882624,
                        206.45356701032227
                    ],
                    [
                        283.381617625673,
                        204.26517817618077
                    ],
                    [
                        285.6074368265208,
                        204.73432222357246
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Godda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mirzapur",
            "dt_code": "199",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        206.4447155519149,
                        210.86767646549896
                    ],
                    [
                        207.27658737445404,
                        209.59794715175133
                    ],
                    [
                        208.3782554637628,
                        209.0407942008415
                    ],
                    [
                        209.83965190876415,
                        206.7436822131175
                    ],
                    [
                        209.007780086225,
                        204.9353499080038
                    ],
                    [
                        209.52488959753282,
                        203.5277267374853
                    ],
                    [
                        212.6725127098432,
                        204.1311165716425
                    ],
                    [
                        214.87584888846072,
                        203.7512259522984
                    ],
                    [
                        216.47214346684655,
                        203.50537542397234
                    ],
                    [
                        216.58455857800072,
                        204.60029242140507
                    ],
                    [
                        219.84459680146483,
                        204.55561380279602
                    ],
                    [
                        220.20432515715743,
                        203.95235361647195
                    ],
                    [
                        222.0928990245436,
                        204.82367038178552
                    ],
                    [
                        221.71068764662027,
                        206.92219360243263
                    ],
                    [
                        220.67646862400397,
                        206.8775672574859
                    ],
                    [
                        220.65398560177323,
                        208.59495999037682
                    ],
                    [
                        219.08017404561792,
                        210.5558876273455
                    ],
                    [
                        216.92180391146235,
                        210.3108766766228
                    ],
                    [
                        214.74095075507603,
                        211.44658443479636
                    ],
                    [
                        213.07720710999752,
                        211.06808657105813
                    ],
                    [
                        212.7624447987664,
                        213.13782229486714
                    ],
                    [
                        211.21111626484208,
                        213.53817087638868
                    ],
                    [
                        211.21111626484208,
                        214.4720091863975
                    ],
                    [
                        209.43495750860984,
                        214.27193727601372
                    ],
                    [
                        208.24335733037788,
                        211.2462120963409
                    ],
                    [
                        206.4447155519149,
                        210.86767646549896
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Mirzapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lalitpur",
            "dt_code": "167",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        152.82270753148464,
                        205.9178583615714
                    ],
                    [
                        154.46396815433218,
                        204.44391284074004
                    ],
                    [
                        155.65556833256414,
                        206.7436822131175
                    ],
                    [
                        155.54315322140997,
                        208.59495999037682
                    ],
                    [
                        156.19516086610292,
                        209.5533806263865
                    ],
                    [
                        157.56662522218107,
                        210.17722171690622
                    ],
                    [
                        157.7240063777965,
                        212.6706485249627
                    ],
                    [
                        157.29682895541146,
                        214.3830907794068
                    ],
                    [
                        159.23036886725913,
                        213.7160781042428
                    ],
                    [
                        160.26458788987543,
                        215.22765805627142
                    ],
                    [
                        159.7025123341059,
                        216.64929284551118
                    ],
                    [
                        160.7367313567222,
                        216.93794096062277
                    ],
                    [
                        160.8266634456454,
                        217.33754003906057
                    ],
                    [
                        160.53438415664505,
                        218.44712675831806
                    ],
                    [
                        158.10621775571985,
                        221.17415177389626
                    ],
                    [
                        156.5773722440265,
                        220.39853807261665
                    ],
                    [
                        154.8686625544865,
                        218.358382012636
                    ],
                    [
                        153.63209633179304,
                        217.75925407821552
                    ],
                    [
                        151.96835268671475,
                        219.82217816381473
                    ],
                    [
                        151.0915148197139,
                        218.77988523697832
                    ],
                    [
                        151.6760733977144,
                        218.0255548696034
                    ],
                    [
                        150.1921939304823,
                        216.80472384052575
                    ],
                    [
                        150.30460904163624,
                        213.3602479754668
                    ],
                    [
                        149.04555979671227,
                        210.4667962001878
                    ],
                    [
                        148.77576352994265,
                        209.909885047366
                    ],
                    [
                        151.33882806425277,
                        207.7029926782419
                    ],
                    [
                        151.1814469086371,
                        206.78831156297593
                    ],
                    [
                        151.20392993086784,
                        206.52052043187786
                    ],
                    [
                        151.74352246440685,
                        206.2973335838446
                    ],
                    [
                        152.82270753148464,
                        205.9178583615714
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Lalitpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jamui",
            "dt_code": "238",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        271.28575166550877,
                        208.17132525550613
                    ],
                    [
                        272.0726574435864,
                        212.73739426226683
                    ],
                    [
                        272.8595632216641,
                        214.24970584253288
                    ],
                    [
                        271.2183025988163,
                        215.8275315924002
                    ],
                    [
                        270.52132890966186,
                        218.04774503376967
                    ],
                    [
                        269.01496642019924,
                        216.84913051593702
                    ],
                    [
                        267.93578135312123,
                        216.53826343599422
                    ],
                    [
                        268.2955097088138,
                        215.0054376419355
                    ],
                    [
                        265.82237726342737,
                        214.316399410119
                    ],
                    [
                        265.7549281967349,
                        212.78189019270235
                    ],
                    [
                        264.6757431296569,
                        211.46884679468383
                    ],
                    [
                        263.48414295142516,
                        210.04355784180734
                    ],
                    [
                        262.2925427731932,
                        209.93216446600633
                    ],
                    [
                        261.4381879284233,
                        208.10442733676877
                    ],
                    [
                        263.5066259736559,
                        207.94832346465262
                    ],
                    [
                        265.62003006335,
                        208.327411674482
                    ],
                    [
                        266.9690113971974,
                        207.6137739937796
                    ],
                    [
                        269.3297287314301,
                        205.5606389046763
                    ],
                    [
                        269.5320759315073,
                        206.9668189460379
                    ],
                    [
                        271.28575166550877,
                        208.17132525550613
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Jamui",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Neemuch",
            "dt_code": "432",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        104.95635320213614,
                        207.30147712021056
                    ],
                    [
                        107.31707053636887,
                        207.59146869830084
                    ],
                    [
                        107.09224031406097,
                        208.66184146665967
                    ],
                    [
                        104.84393809098219,
                        211.9808130508498
                    ],
                    [
                        108.84591604806246,
                        213.07108980973862
                    ],
                    [
                        110.21738040414061,
                        212.71514592873683
                    ],
                    [
                        110.68952387098716,
                        213.69384055756348
                    ],
                    [
                        110.82442200437185,
                        215.67202584968862
                    ],
                    [
                        108.46370467013912,
                        218.3140081940855
                    ],
                    [
                        107.51941773644603,
                        218.97951433315075
                    ],
                    [
                        105.15870040221307,
                        219.09041096964927
                    ],
                    [
                        102.5057037789802,
                        218.09212463847297
                    ],
                    [
                        100.7969940894402,
                        220.64233393146185
                    ],
                    [
                        100.18995248920874,
                        220.13254592818816
                    ],
                    [
                        98.52620884413068,
                        220.1547132571883
                    ],
                    [
                        98.93090324428476,
                        218.60242078962796
                    ],
                    [
                        100.01008831136255,
                        217.22654815781567
                    ],
                    [
                        99.42552973336205,
                        216.49384997521207
                    ],
                    [
                        97.69433702159154,
                        216.27176811965217
                    ],
                    [
                        98.99835231097722,
                        214.60537947340043
                    ],
                    [
                        98.79600511090007,
                        213.3824891955212
                    ],
                    [
                        99.26814857774662,
                        212.18111221241656
                    ],
                    [
                        100.50471480044007,
                        213.8272621668114
                    ],
                    [
                        102.03356031213343,
                        212.87087910758856
                    ],
                    [
                        101.80873008982553,
                        211.7137164826724
                    ],
                    [
                        100.10002040028576,
                        211.15715130844973
                    ],
                    [
                        99.58291088897772,
                        209.53109699146876
                    ],
                    [
                        100.70706200051723,
                        209.0407942008415
                    ],
                    [
                        101.53893382305637,
                        210.19949816254245
                    ],
                    [
                        102.70805097905713,
                        210.39997503206564
                    ],
                    [
                        103.85468511282738,
                        209.8876053808561
                    ],
                    [
                        103.8097190683659,
                        208.43889449815975
                    ],
                    [
                        104.95635320213614,
                        207.30147712021056
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Neemuch",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Banka",
            "dt_code": "225",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        281.15579842482475,
                        206.9668189460379
                    ],
                    [
                        280.7061379802092,
                        207.59146869830084
                    ],
                    [
                        280.88600215805513,
                        209.82076489392534
                    ],
                    [
                        280.1890284689009,
                        210.3108766766228
                    ],
                    [
                        279.334673624131,
                        213.93844011300024
                    ],
                    [
                        277.8732771791297,
                        213.87173407912496
                    ],
                    [
                        277.2887186011292,
                        215.22765805627142
                    ],
                    [
                        274.9504842891274,
                        214.0940789664739
                    ],
                    [
                        273.4666048218953,
                        214.84986885326907
                    ],
                    [
                        272.8595632216641,
                        214.24970584253288
                    ],
                    [
                        272.0726574435864,
                        212.73739426226683
                    ],
                    [
                        271.28575166550877,
                        208.17132525550613
                    ],
                    [
                        272.45486882150976,
                        208.75101328388303
                    ],
                    [
                        273.44412179966434,
                        208.1936240631212
                    ],
                    [
                        274.0511633998956,
                        206.0294762520095
                    ],
                    [
                        275.82732215612805,
                        206.40893014236525
                    ],
                    [
                        278.7725980683613,
                        206.11876604490465
                    ],
                    [
                        281.15579842482475,
                        206.9668189460379
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Banka",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rewa",
            "dt_code": "430",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        198.30586150436966,
                        204.84600679110542
                    ],
                    [
                        199.56491074929363,
                        205.2926820703011
                    ],
                    [
                        200.1719523495251,
                        206.8998805551363
                    ],
                    [
                        202.0155601724498,
                        207.83681321461017
                    ],
                    [
                        203.47695661745092,
                        208.08212753183136
                    ],
                    [
                        204.21889635106686,
                        210.39997503206564
                    ],
                    [
                        206.4447155519149,
                        210.86767646549896
                    ],
                    [
                        208.24335733037788,
                        211.2462120963409
                    ],
                    [
                        209.43495750860984,
                        214.27193727601372
                    ],
                    [
                        209.52488959753282,
                        214.4720091863975
                    ],
                    [
                        207.7487308413008,
                        214.3386301107795
                    ],
                    [
                        203.09474523952758,
                        215.60537688451308
                    ],
                    [
                        201.99307715021905,
                        215.51650819268366
                    ],
                    [
                        197.76626897083042,
                        217.22654815781567
                    ],
                    [
                        194.84347608082817,
                        218.86861168515838
                    ],
                    [
                        194.641128880751,
                        217.67047942288724
                    ],
                    [
                        192.66262292444185,
                        216.02745005059455
                    ],
                    [
                        192.66262292444185,
                        212.64839945468174
                    ],
                    [
                        191.69585296851778,
                        211.55789376853846
                    ],
                    [
                        192.1230303909026,
                        209.46424459755315
                    ],
                    [
                        192.7075889689031,
                        209.30824698927938
                    ],
                    [
                        194.23643448059693,
                        207.03375508425228
                    ],
                    [
                        194.43878168067408,
                        205.20335507314826
                    ],
                    [
                        196.1250083479831,
                        205.7615827654164
                    ],
                    [
                        197.69881990413842,
                        205.49365309208832
                    ],
                    [
                        198.30586150436966,
                        204.84600679110542
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Rewa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Satna",
            "dt_code": "429",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        182.68016105397146,
                        208.2828168009213
                    ],
                    [
                        183.01740638743354,
                        207.2345499877598
                    ],
                    [
                        184.36638772128072,
                        206.43124870171494
                    ],
                    [
                        186.16502949974392,
                        206.83293991104978
                    ],
                    [
                        186.29992763312862,
                        205.98482984918786
                    ],
                    [
                        188.07608638936085,
                        204.86834294836825
                    ],
                    [
                        188.57071287843837,
                        206.83293991104978
                    ],
                    [
                        187.8737391892837,
                        207.36840200246638
                    ],
                    [
                        188.30091661166875,
                        209.0630832999944
                    ],
                    [
                        189.55996585659295,
                        208.43889449815975
                    ],
                    [
                        190.8639811459784,
                        208.66184146665967
                    ],
                    [
                        192.1230303909026,
                        209.46424459755315
                    ],
                    [
                        191.69585296851778,
                        211.55789376853846
                    ],
                    [
                        192.66262292444185,
                        212.64839945468174
                    ],
                    [
                        192.66262292444185,
                        216.02745005059455
                    ],
                    [
                        194.641128880751,
                        217.67047942288724
                    ],
                    [
                        194.84347608082817,
                        218.86861168515838
                    ],
                    [
                        196.0800423035214,
                        219.77783607647763
                    ],
                    [
                        195.47300070329015,
                        221.04121021187206
                    ],
                    [
                        194.50623074736654,
                        220.99689444772645
                    ],
                    [
                        193.08980034682668,
                        222.28166433429038
                    ],
                    [
                        192.32537759098,
                        221.8830284126468
                    ],
                    [
                        190.3918376791321,
                        222.74664220858733
                    ],
                    [
                        189.73983003443914,
                        223.01229683226933
                    ],
                    [
                        188.43581474505345,
                        222.79092035251875
                    ],
                    [
                        186.90696923335986,
                        224.18519659416575
                    ],
                    [
                        186.50227483320577,
                        221.94947308761192
                    ],
                    [
                        183.8043121655112,
                        223.27791729900258
                    ],
                    [
                        182.54526292058677,
                        224.20732039107668
                    ],
                    [
                        180.9264853199702,
                        224.56126902515837
                    ],
                    [
                        184.95094629928144,
                        221.24061933243752
                    ],
                    [
                        183.84927820997268,
                        219.75566467309886
                    ],
                    [
                        182.43284780943281,
                        217.00454625183642
                    ],
                    [
                        182.56774594281774,
                        214.16077766532902
                    ],
                    [
                        181.1962815867396,
                        213.07108980973862
                    ],
                    [
                        182.07311945374045,
                        212.0030694976731
                    ],
                    [
                        184.52376887689616,
                        210.6449750970949
                    ],
                    [
                        184.11907447674184,
                        209.50881310836945
                    ],
                    [
                        182.92747429851033,
                        209.33053310683863
                    ],
                    [
                        182.68016105397146,
                        208.2828168009213
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Satna",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Guna",
            "dt_code": "458",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        136.14030503623962,
                        206.5874715974225
                    ],
                    [
                        136.9946598810095,
                        209.10766075261603
                    ],
                    [
                        138.18626005924125,
                        209.0630832999944
                    ],
                    [
                        138.59095445939533,
                        209.909885047366
                    ],
                    [
                        139.85000370431976,
                        209.9767225597518
                    ],
                    [
                        139.1979960596268,
                        210.71178810306276
                    ],
                    [
                        139.73758859316558,
                        211.7582350403839
                    ],
                    [
                        138.63592050385705,
                        213.84949824528678
                    ],
                    [
                        138.86075072616495,
                        216.42722796539925
                    ],
                    [
                        138.54598841493407,
                        217.0267475314228
                    ],
                    [
                        140.36711321562802,
                        218.00336446420323
                    ],
                    [
                        141.1315359714747,
                        219.0016941412979
                    ],
                    [
                        139.6926225487041,
                        220.08821055159257
                    ],
                    [
                        138.11881099254902,
                        220.2212138072132
                    ],
                    [
                        136.27520316962455,
                        219.42306483265537
                    ],
                    [
                        136.63493152531692,
                        221.12983887481346
                    ],
                    [
                        135.37588228039294,
                        222.96802343449787
                    ],
                    [
                        136.14030503623962,
                        223.94181923124967
                    ],
                    [
                        136.23023712516283,
                        225.82172063421507
                    ],
                    [
                        134.88125579131543,
                        225.82172063421507
                    ],
                    [
                        133.98193490208382,
                        224.91515725471493
                    ],
                    [
                        134.2292481466227,
                        222.79092035251875
                    ],
                    [
                        131.91349685685122,
                        222.72450278050803
                    ],
                    [
                        131.1265910787738,
                        223.34431708227203
                    ],
                    [
                        129.80009276715737,
                        221.94947308761192
                    ],
                    [
                        130.4071343673886,
                        220.64233393146185
                    ],
                    [
                        129.53029650038775,
                        219.66697666109485
                    ],
                    [
                        129.08063605577195,
                        218.2918209233198
                    ],
                    [
                        129.26050023361813,
                        217.0267475314228
                    ],
                    [
                        128.85580583346427,
                        215.44985411948062
                    ],
                    [
                        129.50781347815723,
                        215.20543711093757
                    ],
                    [
                        131.21652316769678,
                        216.6270874483855
                    ],
                    [
                        132.5655045015444,
                        215.0721063241832
                    ],
                    [
                        132.04839499023615,
                        212.62615013878042
                    ],
                    [
                        130.7668627230812,
                        211.80275261269435
                    ],
                    [
                        129.41788138923403,
                        212.0475816528899
                    ],
                    [
                        128.67594165561786,
                        211.02355272093314
                    ],
                    [
                        130.81182876754292,
                        210.02127966223958
                    ],
                    [
                        129.50781347815723,
                        208.52807627351444
                    ],
                    [
                        129.7326437004649,
                        207.2345499877598
                    ],
                    [
                        131.55376850115886,
                        206.67673630965936
                    ],
                    [
                        132.81281774608283,
                        207.01144328844862
                    ],
                    [
                        134.11683303546852,
                        206.09644397322825
                    ],
                    [
                        135.5107804137774,
                        206.0294762520095
                    ],
                    [
                        136.14030503623962,
                        206.5874715974225
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Guna",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nawada",
            "dt_code": "237",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        261.4381879284233,
                        208.10442733676877
                    ],
                    [
                        262.2925427731932,
                        209.93216446600633
                    ],
                    [
                        263.48414295142516,
                        210.04355784180734
                    ],
                    [
                        264.6757431296569,
                        211.46884679468383
                    ],
                    [
                        262.8771013511939,
                        212.13660301011572
                    ],
                    [
                        262.4274409065781,
                        211.69145683424563
                    ],
                    [
                        260.0217575278839,
                        210.82313816905747
                    ],
                    [
                        258.9650554830366,
                        213.3824891955212
                    ],
                    [
                        259.1224366386523,
                        214.51646692505085
                    ],
                    [
                        257.1439306823429,
                        215.4720723871876
                    ],
                    [
                        254.67079823695622,
                        215.1165508941275
                    ],
                    [
                        253.6365792143397,
                        213.84949824528678
                    ],
                    [
                        254.01879059226326,
                        212.55940071717254
                    ],
                    [
                        253.2318848141856,
                        211.46884679468383
                    ],
                    [
                        254.87314543703314,
                        208.50578120323416
                    ],
                    [
                        257.21137974903536,
                        207.36840200246638
                    ],
                    [
                        258.4929120161901,
                        206.31965339718022
                    ],
                    [
                        260.4264519280382,
                        206.63210445469207
                    ],
                    [
                        260.47141797249947,
                        207.76990406978166
                    ],
                    [
                        261.4381879284233,
                        208.10442733676877
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Nawada",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gaya",
            "dt_code": "236",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        254.87314543703314,
                        208.50578120323416
                    ],
                    [
                        253.2318848141856,
                        211.46884679468383
                    ],
                    [
                        254.01879059226326,
                        212.55940071717254
                    ],
                    [
                        253.6365792143397,
                        213.84949824528678
                    ],
                    [
                        254.67079823695622,
                        215.1165508941275
                    ],
                    [
                        254.041273614494,
                        215.8275315924002
                    ],
                    [
                        253.41174899203202,
                        215.62759344941736
                    ],
                    [
                        250.4664730797988,
                        218.09212463847297
                    ],
                    [
                        249.58963521279793,
                        217.3597376898589
                    ],
                    [
                        247.74602738987323,
                        218.04774503376967
                    ],
                    [
                        247.52119716756556,
                        216.5826759270039
                    ],
                    [
                        246.26214792264136,
                        215.49429041158243
                    ],
                    [
                        243.06955876586926,
                        217.60389589547043
                    ],
                    [
                        241.7655434764838,
                        219.42306483265537
                    ],
                    [
                        241.22595094294456,
                        218.6246046880375
                    ],
                    [
                        239.49475823117405,
                        217.71486723352376
                    ],
                    [
                        239.26992800886592,
                        216.16071809324785
                    ],
                    [
                        240.23669796478998,
                        214.91654265382357
                    ],
                    [
                        241.5856792986374,
                        215.7831025955789
                    ],
                    [
                        242.5074832100995,
                        215.56094302507728
                    ],
                    [
                        243.3168720104079,
                        213.47145162573082
                    ],
                    [
                        245.09303076664014,
                        212.75964235024918
                    ],
                    [
                        245.27289494448655,
                        211.7137164826724
                    ],
                    [
                        243.87894756617766,
                        209.93216446600633
                    ],
                    [
                        245.00309867771693,
                        209.12994910612196
                    ],
                    [
                        245.2504119222558,
                        207.01144328844862
                    ],
                    [
                        247.0490537007188,
                        206.8998805551363
                    ],
                    [
                        247.83595947879644,
                        206.83293991104978
                    ],
                    [
                        250.48895610202953,
                        208.0152266206855
                    ],
                    [
                        251.0285486355683,
                        207.03375508425228
                    ],
                    [
                        252.13021672487707,
                        206.94450639939396
                    ],
                    [
                        254.31106988126362,
                        207.56916315304056
                    ],
                    [
                        254.87314543703314,
                        208.50578120323416
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Gaya",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ashoknagar",
            "dt_code": "459",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        139.85000370431976,
                        209.9767225597518
                    ],
                    [
                        140.29966414893556,
                        209.08537215057964
                    ],
                    [
                        141.4912643271673,
                        210.11039089402715
                    ],
                    [
                        142.41306823862965,
                        210.2217743605475
                    ],
                    [
                        143.76204957247683,
                        207.88141806323574
                    ],
                    [
                        146.5049782846329,
                        208.61725406473317
                    ],
                    [
                        145.83048761770942,
                        209.77620332962118
                    ],
                    [
                        147.89892566294202,
                        210.5558876273455
                    ],
                    [
                        149.04555979671227,
                        210.4667962001878
                    ],
                    [
                        150.30460904163624,
                        213.3602479754668
                    ],
                    [
                        150.1921939304823,
                        216.80472384052575
                    ],
                    [
                        148.6633484187887,
                        217.67047942288724
                    ],
                    [
                        147.60664637394166,
                        218.8907926957844
                    ],
                    [
                        147.47174824055696,
                        219.88868949653738
                    ],
                    [
                        146.4600122401714,
                        220.24338017828967
                    ],
                    [
                        143.08755890555335,
                        218.97951433315075
                    ],
                    [
                        141.1315359714747,
                        219.0016941412979
                    ],
                    [
                        140.36711321562802,
                        218.00336446420323
                    ],
                    [
                        138.54598841493407,
                        217.0267475314228
                    ],
                    [
                        138.86075072616495,
                        216.42722796539925
                    ],
                    [
                        138.63592050385705,
                        213.84949824528678
                    ],
                    [
                        139.73758859316558,
                        211.7582350403839
                    ],
                    [
                        139.1979960596268,
                        210.71178810306276
                    ],
                    [
                        139.85000370431976,
                        209.9767225597518
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Ashoknagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Banaskantha",
            "dt_code": "469",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        47.55719744693329,
                        214.11631211050508
                    ],
                    [
                        48.88369575854972,
                        213.96067496847235
                    ],
                    [
                        50.36757522578205,
                        213.09333421669788
                    ],
                    [
                        52.27863211539875,
                        213.13782229486714
                    ],
                    [
                        52.9756058045532,
                        213.7383154061759
                    ],
                    [
                        54.97659478309333,
                        213.13782229486714
                    ],
                    [
                        56.84268562824877,
                        213.20455257302189
                    ],
                    [
                        58.888640651250626,
                        212.55940071717254
                    ],
                    [
                        60.462452207405704,
                        214.02737806768494
                    ],
                    [
                        62.95806767502336,
                        213.84949824528678
                    ],
                    [
                        63.22786394179275,
                        214.96099063533734
                    ],
                    [
                        65.38623407594855,
                        217.33754003906057
                    ],
                    [
                        65.67851336494891,
                        215.98302542702993
                    ],
                    [
                        68.08419674364313,
                        216.5826759270039
                    ],
                    [
                        68.73620438833586,
                        218.13650327845693
                    ],
                    [
                        72.1311407451849,
                        218.24744565871737
                    ],
                    [
                        73.14287674557045,
                        219.64480405838214
                    ],
                    [
                        72.10865772295415,
                        221.50646809473864
                    ],
                    [
                        71.61403123387686,
                        223.41071473313826
                    ],
                    [
                        70.78215941133772,
                        223.6541545501911
                    ],
                    [
                        69.54559318864426,
                        222.41452584882916
                    ],
                    [
                        67.63453629902756,
                        222.70236311499565
                    ],
                    [
                        67.61205327679659,
                        224.16307256099782
                    ],
                    [
                        66.622800298642,
                        225.0920787405338
                    ],
                    [
                        65.1838868758714,
                        225.0920787405338
                    ],
                    [
                        63.47517718633139,
                        224.51702875076313
                    ],
                    [
                        63.02551674171582,
                        223.45497864928478
                    ],
                    [
                        61.361773096637535,
                        222.9016115585212
                    ],
                    [
                        61.06949380763717,
                        222.21523036709993
                    ],
                    [
                        57.47221025071099,
                        224.20732039107668
                    ],
                    [
                        55.98833078347889,
                        226.52900781980378
                    ],
                    [
                        53.829960649323084,
                        226.15329142240068
                    ],
                    [
                        53.44774927139974,
                        224.53914900594708
                    ],
                    [
                        49.15349202531934,
                        224.6055083557327
                    ],
                    [
                        48.02934091377983,
                        224.31793583239272
                    ],
                    [
                        47.66961255808724,
                        219.20130159690171
                    ],
                    [
                        48.83872971408823,
                        218.66897176263132
                    ],
                    [
                        48.02934091377983,
                        217.58170090320294
                    ],
                    [
                        48.45651833616489,
                        215.71645727736438
                    ],
                    [
                        47.152503046778975,
                        215.44985411948062
                    ],
                    [
                        47.55719744693329,
                        214.11631211050508
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Banaskantha",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jhalawar",
            "dt_code": "129",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        115.68075480622201,
                        215.2943194312777
                    ],
                    [
                        117.92905702930102,
                        214.82764376546936
                    ],
                    [
                        117.79415889591633,
                        212.0698373613199
                    ],
                    [
                        122.1108991642277,
                        209.9990012348939
                    ],
                    [
                        123.32498236469019,
                        210.6449750970949
                    ],
                    [
                        123.84209187599845,
                        213.4269709005917
                    ],
                    [
                        124.62899765407587,
                        213.40473017055706
                    ],
                    [
                        124.71892974299908,
                        215.8275315924002
                    ],
                    [
                        125.77563178784635,
                        217.22654815781567
                    ],
                    [
                        128.02393401092513,
                        216.84913051593702
                    ],
                    [
                        129.26050023361813,
                        217.0267475314228
                    ],
                    [
                        129.08063605577195,
                        218.2918209233198
                    ],
                    [
                        129.53029650038775,
                        219.66697666109485
                    ],
                    [
                        130.4071343673886,
                        220.64233393146185
                    ],
                    [
                        129.80009276715737,
                        221.94947308761192
                    ],
                    [
                        128.63097561115615,
                        222.17093986645227
                    ],
                    [
                        126.78736778823145,
                        220.97473620742227
                    ],
                    [
                        126.31522432138513,
                        219.71132114683058
                    ],
                    [
                        125.23603925430712,
                        220.68665734207815
                    ],
                    [
                        124.26926929838328,
                        220.77530129452293
                    ],
                    [
                        121.5713066306887,
                        220.11037835966232
                    ],
                    [
                        119.97501205230265,
                        220.68665734207815
                    ],
                    [
                        118.58106467399398,
                        220.93041901026214
                    ],
                    [
                        118.71596280737867,
                        222.63594269368355
                    ],
                    [
                        117.45691356245447,
                        222.9901602519932
                    ],
                    [
                        115.92806805076088,
                        225.71118529392055
                    ],
                    [
                        113.81466396106657,
                        226.19749686999472
                    ],
                    [
                        112.28581844937298,
                        228.119527361905
                    ],
                    [
                        110.7120068932179,
                        227.3465094833473
                    ],
                    [
                        110.44221062644829,
                        226.37430927608494
                    ],
                    [
                        108.93584813698567,
                        225.37954400932404
                    ],
                    [
                        109.8126860039863,
                        223.6541545501911
                    ],
                    [
                        112.4656826272194,
                        224.6055083557327
                    ],
                    [
                        114.44418858352878,
                        223.14511133332928
                    ],
                    [
                        113.18513933860459,
                        221.75013263322847
                    ],
                    [
                        114.10694325006693,
                        220.11037835966232
                    ],
                    [
                        112.84789400514273,
                        217.67047942288724
                    ],
                    [
                        113.70224884991262,
                        216.7603161962095
                    ],
                    [
                        115.38847551722188,
                        216.91573871267303
                    ],
                    [
                        115.68075480622201,
                        215.2943194312777
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Jhalawar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Murshidabad",
            "dt_code": "333",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        291.6778528288339,
                        210.24405031093966
                    ],
                    [
                        293.67884180737383,
                        212.91537209233132
                    ],
                    [
                        295.2301703412984,
                        215.8275315924002
                    ],
                    [
                        298.19792927576236,
                        216.91573871267303
                    ],
                    [
                        298.89490296491704,
                        217.67047942288724
                    ],
                    [
                        301.5029335436884,
                        219.09041096964927
                    ],
                    [
                        303.32405834438214,
                        218.8242489422397
                    ],
                    [
                        304.178413189152,
                        219.9551986717517
                    ],
                    [
                        304.2458622558445,
                        221.68368152781005
                    ],
                    [
                        303.5713715889208,
                        222.50309543796368
                    ],
                    [
                        302.55963558853546,
                        223.56563429518133
                    ],
                    [
                        300.80595985453397,
                        224.18519659416575
                    ],
                    [
                        299.2771143428404,
                        226.94884599338133
                    ],
                    [
                        296.7814988752227,
                        227.2360550031994
                    ],
                    [
                        295.88217798599135,
                        227.8986942821232
                    ],
                    [
                        294.17346829645135,
                        228.51696817483634
                    ],
                    [
                        293.40904554060467,
                        226.55110667411373
                    ],
                    [
                        292.77952091814245,
                        227.12559467776953
                    ],
                    [
                        291.6778528288339,
                        226.63949974793672
                    ],
                    [
                        290.9808791396795,
                        225.5122068728648
                    ],
                    [
                        291.2281923842181,
                        223.6541545501911
                    ],
                    [
                        291.7677849177569,
                        223.34431708227203
                    ],
                    [
                        291.79026793998764,
                        221.44000912397678
                    ],
                    [
                        293.04931718491207,
                        220.99689444772645
                    ],
                    [
                        293.5214606517586,
                        219.04605303628523
                    ],
                    [
                        292.64462278475776,
                        218.55805227049456
                    ],
                    [
                        292.95938509598886,
                        217.33754003906057
                    ],
                    [
                        292.50972465137306,
                        215.71645727736438
                    ],
                    [
                        291.65536980660295,
                        214.9387667664539
                    ],
                    [
                        291.92516607337257,
                        212.40364346664177
                    ],
                    [
                        290.913430072987,
                        212.02532569834705
                    ],
                    [
                        291.6778528288339,
                        210.24405031093966
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Murshidabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sonbhadra",
            "dt_code": "200",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        220.65398560177323,
                        208.59495999037682
                    ],
                    [
                        221.03619697969657,
                        211.44658443479636
                    ],
                    [
                        222.49759342469793,
                        212.4481467670526
                    ],
                    [
                        224.02643893639151,
                        212.42589523974857
                    ],
                    [
                        225.57776747031585,
                        211.44658443479636
                    ],
                    [
                        226.949231826394,
                        212.18111221241656
                    ],
                    [
                        227.55627342662524,
                        213.67160276611997
                    ],
                    [
                        227.46634133770203,
                        215.18321592207457
                    ],
                    [
                        227.15157902647115,
                        215.58316007640576
                    ],
                    [
                        225.6452165370083,
                        216.02745005059455
                    ],
                    [
                        225.35293724800817,
                        219.0016941412979
                    ],
                    [
                        225.780114670393,
                        219.75566467309886
                    ],
                    [
                        224.610997514392,
                        222.41452584882916
                    ],
                    [
                        222.8123557359288,
                        225.31320940126614
                    ],
                    [
                        221.10364604638903,
                        226.13118834659565
                    ],
                    [
                        218.99024195669494,
                        226.13118834659565
                    ],
                    [
                        217.01173600038555,
                        224.67186558224176
                    ],
                    [
                        214.83088284399923,
                        221.99376834726996
                    ],
                    [
                        216,
                        221.39570195115408
                    ],
                    [
                        216.38221137792334,
                        218.09212463847297
                    ],
                    [
                        215.5503395553842,
                        217.93679180041983
                    ],
                    [
                        216,
                        216.0496619980794
                    ],
                    [
                        216.78690577807788,
                        214.7609670389707
                    ],
                    [
                        216.42717742238506,
                        213.69384055756348
                    ],
                    [
                        215.46040746646122,
                        213.7827892758766
                    ],
                    [
                        214.9208149329222,
                        212.80413778964464
                    ],
                    [
                        212.7624447987664,
                        213.13782229486714
                    ],
                    [
                        213.07720710999752,
                        211.06808657105813
                    ],
                    [
                        214.74095075507603,
                        211.44658443479636
                    ],
                    [
                        216.92180391146235,
                        210.3108766766228
                    ],
                    [
                        219.08017404561792,
                        210.5558876273455
                    ],
                    [
                        220.65398560177323,
                        208.59495999037682
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Sonbhadra",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pakur",
            "dt_code": "353",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        286.05709727113685,
                        211.15715130844973
                    ],
                    [
                        289.04733922783157,
                        212.09209282365572
                    ],
                    [
                        290.913430072987,
                        212.02532569834705
                    ],
                    [
                        291.92516607337257,
                        212.40364346664177
                    ],
                    [
                        291.65536980660295,
                        214.9387667664539
                    ],
                    [
                        290.23893940606354,
                        214.64983428338735
                    ],
                    [
                        290.3513545172175,
                        217.89240881806478
                    ],
                    [
                        288.95740713890837,
                        219.86651929204368
                    ],
                    [
                        287.9681541607538,
                        219.9551986717517
                    ],
                    [
                        287.13628233821464,
                        219.910859461308
                    ],
                    [
                        286.866486071445,
                        215.60537688451308
                    ],
                    [
                        284.75308198175094,
                        215.76088773258977
                    ],
                    [
                        285.5399877598286,
                        213.60488792302095
                    ],
                    [
                        285.33764055975166,
                        211.26847667634127
                    ],
                    [
                        286.05709727113685,
                        211.15715130844973
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Pakur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mandsaur",
            "dt_code": "433",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        113.67976582768188,
                        211.7582350403839
                    ],
                    [
                        114.2643244056826,
                        213.29352284500686
                    ],
                    [
                        115.68075480622201,
                        215.2943194312777
                    ],
                    [
                        115.38847551722188,
                        216.91573871267303
                    ],
                    [
                        113.70224884991262,
                        216.7603161962095
                    ],
                    [
                        112.84789400514273,
                        217.67047942288724
                    ],
                    [
                        114.10694325006693,
                        220.11037835966232
                    ],
                    [
                        113.18513933860459,
                        221.75013263322847
                    ],
                    [
                        114.44418858352878,
                        223.14511133332928
                    ],
                    [
                        112.4656826272194,
                        224.6055083557327
                    ],
                    [
                        109.8126860039863,
                        223.6541545501911
                    ],
                    [
                        108.93584813698567,
                        225.37954400932404
                    ],
                    [
                        107.06975729183023,
                        226.61740183095816
                    ],
                    [
                        105.90064013582901,
                        226.90465648222767
                    ],
                    [
                        102.91039817913429,
                        226.8604660347683
                    ],
                    [
                        101.26913755628675,
                        227.83243980999953
                    ],
                    [
                        100.70706200051723,
                        226.08698149088036
                    ],
                    [
                        101.76376404536427,
                        223.41071473313826
                    ],
                    [
                        100.36981666705515,
                        221.2627747080008
                    ],
                    [
                        100.7969940894402,
                        220.64233393146185
                    ],
                    [
                        102.5057037789802,
                        218.09212463847297
                    ],
                    [
                        105.15870040221307,
                        219.09041096964927
                    ],
                    [
                        107.51941773644603,
                        218.97951433315075
                    ],
                    [
                        108.46370467013912,
                        218.3140081940855
                    ],
                    [
                        110.82442200437185,
                        215.67202584968862
                    ],
                    [
                        110.68952387098716,
                        213.69384055756348
                    ],
                    [
                        110.21738040414061,
                        212.71514592873683
                    ],
                    [
                        111.09421827114147,
                        213.04884515750535
                    ],
                    [
                        113.67976582768188,
                        211.7582350403839
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Mandsaur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Munger",
            "dt_code": "226",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        271.37568375443175,
                        201.02280166729292
                    ],
                    [
                        271.8253441990478,
                        199.9034993806925
                    ],
                    [
                        273.2867406440487,
                        201.5598399661722
                    ],
                    [
                        273.3766727329721,
                        203.46067203741575
                    ],
                    [
                        274.5457898889731,
                        203.77357448215753
                    ],
                    [
                        274.0511633998956,
                        206.0294762520095
                    ],
                    [
                        273.44412179966434,
                        208.1936240631212
                    ],
                    [
                        272.45486882150976,
                        208.75101328388303
                    ],
                    [
                        271.28575166550877,
                        208.17132525550613
                    ],
                    [
                        269.5320759315073,
                        206.9668189460379
                    ],
                    [
                        269.3297287314301,
                        205.5606389046763
                    ],
                    [
                        269.05993246466096,
                        203.03593932905312
                    ],
                    [
                        267.7334341530443,
                        203.3489091395548
                    ],
                    [
                        271.37568375443175,
                        201.02280166729292
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Munger",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Giridih",
            "dt_code": "349",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        264.6757431296569,
                        211.46884679468383
                    ],
                    [
                        265.7549281967349,
                        212.78189019270235
                    ],
                    [
                        265.82237726342737,
                        214.316399410119
                    ],
                    [
                        268.2955097088138,
                        215.0054376419355
                    ],
                    [
                        267.93578135312123,
                        216.53826343599422
                    ],
                    [
                        269.01496642019924,
                        216.84913051593702
                    ],
                    [
                        270.52132890966186,
                        218.04774503376967
                    ],
                    [
                        271.4431328211242,
                        220.465030723025
                    ],
                    [
                        270.9934723765084,
                        221.59507671802407
                    ],
                    [
                        272.2525216214324,
                        221.77228252524048
                    ],
                    [
                        270.9485063320469,
                        223.69841325751838
                    ],
                    [
                        269.4421438425841,
                        223.10084078154227
                    ],
                    [
                        268.1830945976599,
                        225.401655074723
                    ],
                    [
                        267.1488755750438,
                        225.003619882424
                    ],
                    [
                        265.4851319299653,
                        225.86593312560302
                    ],
                    [
                        264.92305637419577,
                        225.31320940126614
                    ],
                    [
                        263.39421086250195,
                        225.7554001350113
                    ],
                    [
                        262.6073050844245,
                        224.87092452690558
                    ],
                    [
                        261.4606709506543,
                        222.9016115585212
                    ],
                    [
                        259.6170631277296,
                        222.41452584882916
                    ],
                    [
                        259.1898857053445,
                        221.21846371824415
                    ],
                    [
                        261.6180521062697,
                        220.53152122012204
                    ],
                    [
                        261.6630181507312,
                        219.64480405838214
                    ],
                    [
                        261.9328144175008,
                        215.56094302507728
                    ],
                    [
                        260.5388670391919,
                        214.20524224262357
                    ],
                    [
                        261.1908746838849,
                        212.3813914477143
                    ],
                    [
                        262.4274409065781,
                        211.69145683424563
                    ],
                    [
                        262.8771013511939,
                        212.13660301011572
                    ],
                    [
                        264.6757431296569,
                        211.46884679468383
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Giridih",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dumka",
            "dt_code": "362",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        279.334673624131,
                        213.93844011300024
                    ],
                    [
                        280.3688926467473,
                        213.8272621668114
                    ],
                    [
                        282.1675344252103,
                        214.60537947340043
                    ],
                    [
                        283.08933833667265,
                        214.40532074741031
                    ],
                    [
                        284.12355735928895,
                        215.91638666992276
                    ],
                    [
                        284.75308198175094,
                        215.76088773258977
                    ],
                    [
                        286.866486071445,
                        215.60537688451308
                    ],
                    [
                        287.13628233821464,
                        219.910859461308
                    ],
                    [
                        287.9681541607538,
                        219.9551986717517
                    ],
                    [
                        287.8557390495996,
                        221.41785565680897
                    ],
                    [
                        285.0453612707513,
                        224.34005821213282
                    ],
                    [
                        283.5165157590577,
                        223.4992416180525
                    ],
                    [
                        282.01015326959487,
                        223.6541545501911
                    ],
                    [
                        280.5712398468245,
                        223.45497864928478
                    ],
                    [
                        279.98668126882376,
                        222.32595245677226
                    ],
                    [
                        278.7276320238998,
                        221.70583213453125
                    ],
                    [
                        278.1880394903608,
                        222.30380851441726
                    ],
                    [
                        277.6034809123605,
                        221.8608797114597
                    ],
                    [
                        279.2447415352078,
                        220.465030723025
                    ],
                    [
                        278.8850131795152,
                        219.11258957576752
                    ],
                    [
                        278.00817531251437,
                        219.0016941412979
                    ],
                    [
                        276.9289902454366,
                        216.84913051593702
                    ],
                    [
                        277.2887186011292,
                        215.22765805627142
                    ],
                    [
                        277.8732771791297,
                        213.87173407912496
                    ],
                    [
                        279.334673624131,
                        213.93844011300024
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Dumka",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Singrauli",
            "dt_code": "463",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        209.43495750860984,
                        214.27193727601372
                    ],
                    [
                        211.21111626484208,
                        214.4720091863975
                    ],
                    [
                        211.21111626484208,
                        213.53817087638868
                    ],
                    [
                        212.7624447987664,
                        213.13782229486714
                    ],
                    [
                        214.9208149329222,
                        212.80413778964464
                    ],
                    [
                        215.46040746646122,
                        213.7827892758766
                    ],
                    [
                        216.42717742238506,
                        213.69384055756348
                    ],
                    [
                        216.78690577807788,
                        214.7609670389707
                    ],
                    [
                        216,
                        216.0496619980794
                    ],
                    [
                        215.5503395553842,
                        217.93679180041983
                    ],
                    [
                        216.38221137792334,
                        218.09212463847297
                    ],
                    [
                        216,
                        221.39570195115408
                    ],
                    [
                        214.83088284399923,
                        221.99376834726996
                    ],
                    [
                        217.01173600038555,
                        224.67186558224176
                    ],
                    [
                        215.25806026638406,
                        225.35743270862667
                    ],
                    [
                        214.40370542161395,
                        226.66159743062695
                    ],
                    [
                        212.38023342084307,
                        227.5232244989087
                    ],
                    [
                        208.89536497507106,
                        226.92675135483387
                    ],
                    [
                        208.13094221922393,
                        226.41851003224383
                    ],
                    [
                        208.0185271080702,
                        224.87092452690558
                    ],
                    [
                        206.8494099520692,
                        224.11882378581834
                    ],
                    [
                        204.6011077289902,
                        223.85331127966242
                    ],
                    [
                        203.27460941737377,
                        222.01591562005729
                    ],
                    [
                        204.17393030660537,
                        220.24338017828967
                    ],
                    [
                        205.07325119583675,
                        220.26554630992973
                    ],
                    [
                        207.50141759676194,
                        218.2918209233198
                    ],
                    [
                        209.3225423974559,
                        215.96081275091387
                    ],
                    [
                        209.52488959753282,
                        214.4720091863975
                    ],
                    [
                        209.43495750860984,
                        214.27193727601372
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Singrauli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Birbhum",
            "dt_code": "334",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        291.65536980660295,
                        214.9387667664539
                    ],
                    [
                        292.50972465137306,
                        215.71645727736438
                    ],
                    [
                        292.95938509598886,
                        217.33754003906057
                    ],
                    [
                        292.64462278475776,
                        218.55805227049456
                    ],
                    [
                        293.5214606517586,
                        219.04605303628523
                    ],
                    [
                        293.04931718491207,
                        220.99689444772645
                    ],
                    [
                        291.79026793998764,
                        221.44000912397678
                    ],
                    [
                        291.7677849177569,
                        223.34431708227203
                    ],
                    [
                        291.2281923842181,
                        223.6541545501911
                    ],
                    [
                        290.9808791396795,
                        225.5122068728648
                    ],
                    [
                        291.6778528288339,
                        226.63949974793672
                    ],
                    [
                        292.77952091814245,
                        227.12559467776953
                    ],
                    [
                        291.9476490956033,
                        228.5832009747284
                    ],
                    [
                        292.6671058069883,
                        229.99566915579373
                    ],
                    [
                        292.23992838460345,
                        230.9661904478345
                    ],
                    [
                        291.0033621619102,
                        231.27489888598552
                    ],
                    [
                        288.97989016113934,
                        230.17216088045024
                    ],
                    [
                        286.4168256268292,
                        230.34863779110225
                    ],
                    [
                        285.1352933596745,
                        230.260401186965
                    ],
                    [
                        284.5957008261355,
                        229.09091622121178
                    ],
                    [
                        282.45981371421067,
                        228.0974451031915
                    ],
                    [
                        280.8410361135939,
                        228.0974451031915
                    ],
                    [
                        279.8293001132083,
                        227.0814089104237
                    ],
                    [
                        280.4813077579013,
                        226.41851003224383
                    ],
                    [
                        282.01015326959487,
                        226.37430927608494
                    ],
                    [
                        282.90947415882624,
                        225.6448612694977
                    ],
                    [
                        282.01015326959487,
                        223.6541545501911
                    ],
                    [
                        283.5165157590577,
                        223.4992416180525
                    ],
                    [
                        285.0453612707513,
                        224.34005821213282
                    ],
                    [
                        287.8557390495996,
                        221.41785565680897
                    ],
                    [
                        287.9681541607538,
                        219.9551986717517
                    ],
                    [
                        288.95740713890837,
                        219.86651929204368
                    ],
                    [
                        290.3513545172175,
                        217.89240881806478
                    ],
                    [
                        290.23893940606354,
                        214.64983428338735
                    ],
                    [
                        291.65536980660295,
                        214.9387667664539
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Birbhum",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Deoghar",
            "dt_code": "350",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        270.52132890966186,
                        218.04774503376967
                    ],
                    [
                        271.2183025988163,
                        215.8275315924002
                    ],
                    [
                        272.8595632216641,
                        214.24970584253288
                    ],
                    [
                        273.4666048218953,
                        214.84986885326907
                    ],
                    [
                        274.9504842891274,
                        214.0940789664739
                    ],
                    [
                        277.2887186011292,
                        215.22765805627142
                    ],
                    [
                        276.9289902454366,
                        216.84913051593702
                    ],
                    [
                        278.00817531251437,
                        219.0016941412979
                    ],
                    [
                        278.8850131795152,
                        219.11258957576752
                    ],
                    [
                        279.2447415352078,
                        220.465030723025
                    ],
                    [
                        277.6034809123605,
                        221.8608797114597
                    ],
                    [
                        278.1880394903608,
                        222.30380851441726
                    ],
                    [
                        277.8957602013604,
                        223.38858241975362
                    ],
                    [
                        273.8712992220494,
                        221.9716208364609
                    ],
                    [
                        272.2525216214324,
                        221.77228252524048
                    ],
                    [
                        270.9934723765084,
                        221.59507671802407
                    ],
                    [
                        271.4431328211242,
                        220.465030723025
                    ],
                    [
                        270.52132890966186,
                        218.04774503376967
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Deoghar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Palamu",
            "dt_code": "358",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        232.5924704063218,
                        215.4720723871876
                    ],
                    [
                        233.53675734001513,
                        215.20543711093757
                    ],
                    [
                        234.59345938486194,
                        213.69384055756348
                    ],
                    [
                        235.8300256075554,
                        215.44985411948062
                    ],
                    [
                        238.23570898624962,
                        215.56094302507728
                    ],
                    [
                        239.26992800886592,
                        216.16071809324785
                    ],
                    [
                        239.49475823117405,
                        217.71486723352376
                    ],
                    [
                        241.22595094294456,
                        218.6246046880375
                    ],
                    [
                        241.7655434764838,
                        219.42306483265537
                    ],
                    [
                        241.04608676509838,
                        220.1990471966829
                    ],
                    [
                        243.02459272140777,
                        221.77228252524048
                    ],
                    [
                        242.6198983212537,
                        223.91969259807684
                    ],
                    [
                        242.97962667694628,
                        224.25156727619725
                    ],
                    [
                        242.79976249909987,
                        225.22475996218492
                    ],
                    [
                        241.36084907632926,
                        224.73822068595376
                    ],
                    [
                        239.38234312001987,
                        225.5122068728648
                    ],
                    [
                        236.48203325224813,
                        225.60064407781806
                    ],
                    [
                        234.7508405404776,
                        227.2360550031994
                    ],
                    [
                        233.53675734001513,
                        226.17539426352795
                    ],
                    [
                        232.48005529516786,
                        222.94588637976614
                    ],
                    [
                        234.3011800958618,
                        222.348096161373
                    ],
                    [
                        231.78308160601364,
                        219.40088958982778
                    ],
                    [
                        232.5924704063218,
                        215.4720723871876
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Palamu",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sidhi",
            "dt_code": "462",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        194.84347608082817,
                        218.86861168515838
                    ],
                    [
                        197.76626897083042,
                        217.22654815781567
                    ],
                    [
                        201.99307715021905,
                        215.51650819268366
                    ],
                    [
                        203.09474523952758,
                        215.60537688451308
                    ],
                    [
                        207.7487308413008,
                        214.3386301107795
                    ],
                    [
                        209.52488959753282,
                        214.4720091863975
                    ],
                    [
                        209.3225423974559,
                        215.96081275091387
                    ],
                    [
                        207.50141759676194,
                        218.2918209233198
                    ],
                    [
                        205.07325119583675,
                        220.26554630992973
                    ],
                    [
                        204.17393030660537,
                        220.24338017828967
                    ],
                    [
                        203.27460941737377,
                        222.01591562005729
                    ],
                    [
                        204.6011077289902,
                        223.85331127966242
                    ],
                    [
                        206.8494099520692,
                        224.11882378581834
                    ],
                    [
                        208.0185271080702,
                        224.87092452690558
                    ],
                    [
                        208.13094221922393,
                        226.41851003224383
                    ],
                    [
                        206.2648513740687,
                        227.01512850484846
                    ],
                    [
                        205.16318328475995,
                        226.2859049496139
                    ],
                    [
                        203.90413403983598,
                        226.13118834659565
                    ],
                    [
                        202.2628734169882,
                        227.14768721058402
                    ],
                    [
                        201.026307194295,
                        226.63949974793672
                    ],
                    [
                        200.1719523495251,
                        225.2689851524994
                    ],
                    [
                        199.227665415832,
                        225.9101446773575
                    ],
                    [
                        198.35082754883115,
                        225.44587649969725
                    ],
                    [
                        198.2384124376772,
                        222.9901602519932
                    ],
                    [
                        197.27164248175336,
                        221.24061933243752
                    ],
                    [
                        197.45150665959954,
                        220.33204326841292
                    ],
                    [
                        196.0800423035214,
                        219.77783607647763
                    ],
                    [
                        194.84347608082817,
                        218.86861168515838
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Sidhi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chatra",
            "dt_code": "347",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        241.7655434764838,
                        219.42306483265537
                    ],
                    [
                        243.06955876586926,
                        217.60389589547043
                    ],
                    [
                        246.26214792264136,
                        215.49429041158243
                    ],
                    [
                        247.52119716756556,
                        216.5826759270039
                    ],
                    [
                        247.74602738987323,
                        218.04774503376967
                    ],
                    [
                        249.58963521279793,
                        217.3597376898589
                    ],
                    [
                        250.4664730797988,
                        218.09212463847297
                    ],
                    [
                        250.64633725764497,
                        218.7577030234242
                    ],
                    [
                        252.48994508056967,
                        218.51368278815465
                    ],
                    [
                        253.9063754811093,
                        219.06823212316124
                    ],
                    [
                        253.16443574749337,
                        220.5758470221415
                    ],
                    [
                        251.52317512464583,
                        220.35420844248796
                    ],
                    [
                        251.65807325803053,
                        222.4588111186904
                    ],
                    [
                        252.82719041403152,
                        223.4992416180525
                    ],
                    [
                        252.13021672487707,
                        224.7603385822082
                    ],
                    [
                        249.52218614610547,
                        224.18519659416575
                    ],
                    [
                        249.4772201016442,
                        225.954355289621
                    ],
                    [
                        249.94936356849075,
                        228.69358432008153
                    ],
                    [
                        248.71279734579707,
                        228.6273550109517
                    ],
                    [
                        248.26313690118127,
                        228.34033713116156
                    ],
                    [
                        248.46548410125865,
                        226.04277369624054
                    ],
                    [
                        248.1956878344888,
                        225.003619882424
                    ],
                    [
                        244.32860801079346,
                        224.07457406539595
                    ],
                    [
                        242.97962667694628,
                        224.25156727619725
                    ],
                    [
                        242.6198983212537,
                        223.91969259807684
                    ],
                    [
                        243.02459272140777,
                        221.77228252524048
                    ],
                    [
                        241.04608676509838,
                        220.1990471966829
                    ],
                    [
                        241.7655434764838,
                        219.42306483265537
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Chatra",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Garhwa",
            "dt_code": "346",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        227.15157902647115,
                        215.58316007640576
                    ],
                    [
                        230.32168516101228,
                        215.93859983188412
                    ],
                    [
                        232.5924704063218,
                        215.4720723871876
                    ],
                    [
                        231.78308160601364,
                        219.40088958982778
                    ],
                    [
                        234.3011800958618,
                        222.348096161373
                    ],
                    [
                        232.48005529516786,
                        222.94588637976614
                    ],
                    [
                        233.53675734001513,
                        226.17539426352795
                    ],
                    [
                        234.7508405404776,
                        227.2360550031994
                    ],
                    [
                        234.7058744960159,
                        229.92948093797713
                    ],
                    [
                        233.55924036224587,
                        230.98824255043223
                    ],
                    [
                        231.15355698355143,
                        230.32657898710443
                    ],
                    [
                        230.1418209831661,
                        226.94884599338133
                    ],
                    [
                        228.16331502685648,
                        226.2638032816111
                    ],
                    [
                        227.33144320431734,
                        224.36218035573995
                    ],
                    [
                        226.11736000385486,
                        222.6802232120328
                    ],
                    [
                        224.610997514392,
                        222.41452584882916
                    ],
                    [
                        225.780114670393,
                        219.75566467309886
                    ],
                    [
                        225.35293724800817,
                        219.0016941412979
                    ],
                    [
                        225.6452165370083,
                        216.02745005059455
                    ],
                    [
                        227.15157902647115,
                        215.58316007640576
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Garhwa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sagar",
            "dt_code": "427",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        150.1921939304823,
                        216.80472384052575
                    ],
                    [
                        151.6760733977144,
                        218.0255548696034
                    ],
                    [
                        151.0915148197139,
                        218.77988523697832
                    ],
                    [
                        151.96835268671475,
                        219.82217816381473
                    ],
                    [
                        153.63209633179304,
                        217.75925407821552
                    ],
                    [
                        154.8686625544865,
                        218.358382012636
                    ],
                    [
                        156.5773722440265,
                        220.39853807261665
                    ],
                    [
                        158.10621775571985,
                        221.17415177389626
                    ],
                    [
                        160.53438415664505,
                        218.44712675831806
                    ],
                    [
                        160.8266634456454,
                        217.33754003906057
                    ],
                    [
                        162.0407466461079,
                        217.4929185180083
                    ],
                    [
                        163.61455820226297,
                        217.07114936447704
                    ],
                    [
                        164.69374326934098,
                        217.42632919167642
                    ],
                    [
                        164.37898095810988,
                        218.66897176263132
                    ],
                    [
                        162.37799197956974,
                        220.1547132571883
                    ],
                    [
                        161.74846735710776,
                        221.30708474330936
                    ],
                    [
                        163.0749656687242,
                        222.41452584882916
                    ],
                    [
                        163.70449029118618,
                        223.89756572843305
                    ],
                    [
                        162.33302593510825,
                        225.35743270862667
                    ],
                    [
                        163.1648977576474,
                        226.5069087311166
                    ],
                    [
                        164.4239470025716,
                        226.97094039788783
                    ],
                    [
                        165.39071695849543,
                        229.2454138482284
                    ],
                    [
                        165.18836975841828,
                        232.4211349124144
                    ],
                    [
                        163.61455820226297,
                        233.2804042266112
                    ],
                    [
                        164.35649793587913,
                        235.43805879949844
                    ],
                    [
                        163.74945633564766,
                        236.5160664573093
                    ],
                    [
                        162.31054291287728,
                        236.9779031826256
                    ],
                    [
                        158.15118380018157,
                        236.3181058713435
                    ],
                    [
                        156.66730433294947,
                        234.42555500704918
                    ],
                    [
                        155.52067019917922,
                        234.31547119515437
                    ],
                    [
                        155.49818717694848,
                        232.86182941711928
                    ],
                    [
                        157.09448175533453,
                        231.5835621281135
                    ],
                    [
                        153.58713028733155,
                        230.72360208014402
                    ],
                    [
                        153.69954539848527,
                        229.9074177354044
                    ],
                    [
                        152.55291126471502,
                        228.56112360752303
                    ],
                    [
                        151.60862433102193,
                        229.70883848456043
                    ],
                    [
                        150.5519222861749,
                        229.26748400883383
                    ],
                    [
                        149.94488068594387,
                        227.9649466547122
                    ],
                    [
                        150.57440530840563,
                        225.5122068728648
                    ],
                    [
                        150.34957508609796,
                        223.60989489612615
                    ],
                    [
                        148.95562770778906,
                        223.89756572843305
                    ],
                    [
                        147.96637472963448,
                        223.32218405813956
                    ],
                    [
                        148.23617099640364,
                        222.10450233134327
                    ],
                    [
                        147.47174824055696,
                        219.88868949653738
                    ],
                    [
                        147.60664637394166,
                        218.8907926957844
                    ],
                    [
                        148.6633484187887,
                        217.67047942288724
                    ],
                    [
                        150.1921939304823,
                        216.80472384052575
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Sagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Patan",
            "dt_code": "470",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        48.02934091377983,
                        224.31793583239272
                    ],
                    [
                        49.15349202531934,
                        224.6055083557327
                    ],
                    [
                        53.44774927139974,
                        224.53914900594708
                    ],
                    [
                        53.829960649323084,
                        226.15329142240068
                    ],
                    [
                        55.98833078347889,
                        226.52900781980378
                    ],
                    [
                        57.47221025071099,
                        224.20732039107668
                    ],
                    [
                        61.06949380763717,
                        222.21523036709993
                    ],
                    [
                        61.361773096637535,
                        222.9016115585212
                    ],
                    [
                        63.02551674171582,
                        223.45497864928478
                    ],
                    [
                        63.47517718633139,
                        224.51702875076313
                    ],
                    [
                        65.1838868758714,
                        225.0920787405338
                    ],
                    [
                        63.63255834194706,
                        226.19749686999472
                    ],
                    [
                        62.86813558610015,
                        225.97646024348768
                    ],
                    [
                        62.59833931933076,
                        229.28955393724954
                    ],
                    [
                        62.3060600303304,
                        230.17216088045024
                    ],
                    [
                        60.73224847417532,
                        229.81916260814177
                    ],
                    [
                        59.090987851327554,
                        230.74565672291584
                    ],
                    [
                        58.23663300655767,
                        230.01773143182396
                    ],
                    [
                        57.58462536186494,
                        231.67174333127906
                    ],
                    [
                        56.01081380570963,
                        232.55335290411728
                    ],
                    [
                        54.6843154940932,
                        232.59742373121333
                    ],
                    [
                        52.211183048706516,
                        232.13463422627513
                    ],
                    [
                        51.17696402609022,
                        232.4211349124144
                    ],
                    [
                        49.91791478116602,
                        231.51742380715933
                    ],
                    [
                        48.861212736318976,
                        229.6867729644013
                    ],
                    [
                        46.59042749100945,
                        229.57644188542508
                    ],
                    [
                        46.52297842431699,
                        228.40657551902194
                    ],
                    [
                        44.52198944577708,
                        230.50304294287588
                    ],
                    [
                        44.27467620123821,
                        226.66159743062695
                    ],
                    [
                        44.81426873477699,
                        225.62275279120945
                    ],
                    [
                        48.02934091377983,
                        224.31793583239272
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Patan",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Damoh",
            "dt_code": "428",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        163.74945633564766,
                        236.5160664573093
                    ],
                    [
                        164.35649793587913,
                        235.43805879949844
                    ],
                    [
                        163.61455820226297,
                        233.2804042266112
                    ],
                    [
                        165.18836975841828,
                        232.4211349124144
                    ],
                    [
                        165.39071695849543,
                        229.2454138482284
                    ],
                    [
                        164.4239470025716,
                        226.97094039788783
                    ],
                    [
                        163.1648977576474,
                        226.5069087311166
                    ],
                    [
                        162.33302593510825,
                        225.35743270862667
                    ],
                    [
                        163.70449029118618,
                        223.89756572843305
                    ],
                    [
                        163.0749656687242,
                        222.41452584882916
                    ],
                    [
                        165.3682339362647,
                        221.15199544370552
                    ],
                    [
                        166.58231713672717,
                        220.9082600533702
                    ],
                    [
                        167.03197758134274,
                        218.40275486733248
                    ],
                    [
                        169.28027980442175,
                        217.1599501266159
                    ],
                    [
                        170.31449882703805,
                        217.89240881806478
                    ],
                    [
                        172.65273313904004,
                        218.0255548696034
                    ],
                    [
                        173.57453705050239,
                        219.24565616571687
                    ],
                    [
                        172.2255557166552,
                        223.38858241975362
                    ],
                    [
                        173.37218985042523,
                        223.69841325751838
                    ],
                    [
                        173.19232567257882,
                        224.98150457894116
                    ],
                    [
                        173.66446913942536,
                        226.81627465086177
                    ],
                    [
                        173.12487660588636,
                        228.95848062312257
                    ],
                    [
                        174.7211711842724,
                        229.17920197310065
                    ],
                    [
                        174.60875607311846,
                        230.52509989673808
                    ],
                    [
                        172.5403180278861,
                        232.94995730561402
                    ],
                    [
                        171.596031094193,
                        233.2804042266112
                    ],
                    [
                        170.8765743828078,
                        234.68973289401652
                    ],
                    [
                        168.53834007080582,
                        235.79012120680818
                    ],
                    [
                        166.4249359811115,
                        237.4396402057041
                    ],
                    [
                        165.45816602518767,
                        236.38409477212406
                    ],
                    [
                        163.74945633564766,
                        236.5160664573093
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Damoh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shajapur",
            "dt_code": "436",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        132.27322521254405,
                        232.61945880043896
                    ],
                    [
                        131.77859872346676,
                        232.9719887045366
                    ],
                    [
                        131.23900618992775,
                        235.10794744773847
                    ],
                    [
                        130.0923720561575,
                        236.45008163395664
                    ],
                    [
                        129.41788138923403,
                        238.36281578586357
                    ],
                    [
                        128.22628121100183,
                        237.35169797588736
                    ],
                    [
                        124.80886183192229,
                        238.01117671860555
                    ],
                    [
                        124.38168440953723,
                        237.21977785717593
                    ],
                    [
                        122.20083125315091,
                        236.36209869843674
                    ],
                    [
                        119.25555534091745,
                        236.4940717426607
                    ],
                    [
                        118.62603071845524,
                        236.18612195106692
                    ],
                    [
                        119.52535160768707,
                        235.79012120680818
                    ],
                    [
                        119.97501205230265,
                        233.94114355159547
                    ],
                    [
                        119.97501205230265,
                        232.26687013795782
                    ],
                    [
                        119.12065720753276,
                        230.9661904478345
                    ],
                    [
                        121.84110289745809,
                        230.01773143182396
                    ],
                    [
                        123.23505027576698,
                        229.9515439088879
                    ],
                    [
                        123.52732956476734,
                        231.38514094399704
                    ],
                    [
                        124.58403160961439,
                        230.87797972889575
                    ],
                    [
                        126.58502058815475,
                        231.5835621281135
                    ],
                    [
                        127.03468103277032,
                        232.3109469365628
                    ],
                    [
                        128.7433907223101,
                        232.3770604112426
                    ],
                    [
                        130.3172022784654,
                        231.87013756891713
                    ],
                    [
                        132.27322521254405,
                        232.61945880043896
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Shajapur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Vidisha",
            "dt_code": "443",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        136.23023712516283,
                        225.82172063421507
                    ],
                    [
                        136.14030503623962,
                        223.94181923124967
                    ],
                    [
                        135.37588228039294,
                        222.96802343449787
                    ],
                    [
                        136.63493152531692,
                        221.12983887481346
                    ],
                    [
                        136.27520316962455,
                        219.42306483265537
                    ],
                    [
                        138.11881099254902,
                        220.2212138072132
                    ],
                    [
                        139.6926225487041,
                        220.08821055159257
                    ],
                    [
                        141.1315359714747,
                        219.0016941412979
                    ],
                    [
                        143.08755890555335,
                        218.97951433315075
                    ],
                    [
                        146.4600122401714,
                        220.24338017828967
                    ],
                    [
                        147.47174824055696,
                        219.88868949653738
                    ],
                    [
                        148.23617099640364,
                        222.10450233134327
                    ],
                    [
                        147.96637472963448,
                        223.32218405813956
                    ],
                    [
                        148.95562770778906,
                        223.89756572843305
                    ],
                    [
                        150.34957508609796,
                        223.60989489612615
                    ],
                    [
                        150.57440530840563,
                        225.5122068728648
                    ],
                    [
                        149.94488068594387,
                        227.9649466547122
                    ],
                    [
                        150.5519222861749,
                        229.26748400883383
                    ],
                    [
                        149.51770326355881,
                        231.64969837594663
                    ],
                    [
                        148.23617099640364,
                        231.8040082280745
                    ],
                    [
                        147.4942312627877,
                        232.88386173319844
                    ],
                    [
                        146.25766504009448,
                        232.61945880043896
                    ],
                    [
                        143.4697702834767,
                        232.7957310928146
                    ],
                    [
                        141.9409247717831,
                        231.05439747337698
                    ],
                    [
                        140.7268415713204,
                        232.6414936401398
                    ],
                    [
                        139.1530300151653,
                        231.9803485348355
                    ],
                    [
                        138.99564885954965,
                        230.98824255043223
                    ],
                    [
                        141.06408690478224,
                        228.67150811637526
                    ],
                    [
                        139.98490183770446,
                        227.81035451931257
                    ],
                    [
                        138.52350539270333,
                        227.9649466547122
                    ],
                    [
                        137.64666752570247,
                        227.28023749656882
                    ],
                    [
                        137.08459196993272,
                        225.9322501009167
                    ],
                    [
                        136.23023712516283,
                        225.82172063421507
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Vidisha",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mehsana",
            "dt_code": "471",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        65.1838868758714,
                        225.0920787405338
                    ],
                    [
                        66.622800298642,
                        225.0920787405338
                    ],
                    [
                        67.61205327679659,
                        224.16307256099782
                    ],
                    [
                        67.63453629902756,
                        222.70236311499565
                    ],
                    [
                        69.54559318864426,
                        222.41452584882916
                    ],
                    [
                        70.78215941133772,
                        223.6541545501911
                    ],
                    [
                        70.0627026999523,
                        224.8930410086545
                    ],
                    [
                        69.63552527756747,
                        226.74998581885467
                    ],
                    [
                        70.31001594449117,
                        229.33369309758342
                    ],
                    [
                        70.26504990002945,
                        231.36309299347363
                    ],
                    [
                        69.77042341095216,
                        232.0685131668929
                    ],
                    [
                        67.20735887664227,
                        231.1867010890266
                    ],
                    [
                        65.45368314264078,
                        233.94114355159547
                    ],
                    [
                        65.70099638717966,
                        234.49160255690322
                    ],
                    [
                        64.10470180879338,
                        235.5920932583183
                    ],
                    [
                        64.32953203110128,
                        237.7913730210214
                    ],
                    [
                        62.9805506972541,
                        238.38479131439072
                    ],
                    [
                        62.19364491917668,
                        238.80228363063338
                    ],
                    [
                        60.79969754086778,
                        237.7913730210214
                    ],
                    [
                        60.5973503407904,
                        235.72411393443127
                    ],
                    [
                        61.7215014523299,
                        234.77778489581834
                    ],
                    [
                        62.41847514148435,
                        233.45662151261507
                    ],
                    [
                        60.84466358532927,
                        233.43459515306392
                    ],
                    [
                        60.28258802955952,
                        232.86182941711928
                    ],
                    [
                        58.79870856232742,
                        232.99401987421373
                    ],
                    [
                        57.8769046508653,
                        232.26687013795782
                    ],
                    [
                        57.58462536186494,
                        231.67174333127906
                    ],
                    [
                        58.23663300655767,
                        230.01773143182396
                    ],
                    [
                        59.090987851327554,
                        230.74565672291584
                    ],
                    [
                        60.73224847417532,
                        229.81916260814177
                    ],
                    [
                        62.3060600303304,
                        230.17216088045024
                    ],
                    [
                        62.59833931933076,
                        229.28955393724954
                    ],
                    [
                        62.86813558610015,
                        225.97646024348768
                    ],
                    [
                        63.63255834194706,
                        226.19749686999472
                    ],
                    [
                        65.1838868758714,
                        225.0920787405338
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Mehsana",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rajgarh",
            "dt_code": "442",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        129.80009276715737,
                        221.94947308761192
                    ],
                    [
                        131.1265910787738,
                        223.34431708227203
                    ],
                    [
                        131.91349685685122,
                        222.72450278050803
                    ],
                    [
                        134.2292481466227,
                        222.79092035251875
                    ],
                    [
                        133.98193490208382,
                        224.91515725471493
                    ],
                    [
                        134.88125579131543,
                        225.82172063421507
                    ],
                    [
                        134.83628974685394,
                        227.0593156758572
                    ],
                    [
                        134.0044179243148,
                        229.31162363349347
                    ],
                    [
                        132.94771587946752,
                        232.24483139395727
                    ],
                    [
                        132.27322521254405,
                        232.61945880043896
                    ],
                    [
                        130.3172022784654,
                        231.87013756891713
                    ],
                    [
                        128.7433907223101,
                        232.3770604112426
                    ],
                    [
                        127.03468103277032,
                        232.3109469365628
                    ],
                    [
                        126.58502058815475,
                        231.5835621281135
                    ],
                    [
                        124.58403160961439,
                        230.87797972889575
                    ],
                    [
                        123.52732956476734,
                        231.38514094399704
                    ],
                    [
                        123.23505027576698,
                        229.9515439088879
                    ],
                    [
                        122.44814449768933,
                        227.1035019110551
                    ],
                    [
                        121.68372174184265,
                        226.2417013790186
                    ],
                    [
                        121.3015103639193,
                        224.69398418598854
                    ],
                    [
                        119.66024974107177,
                        222.6580830716013
                    ],
                    [
                        119.97501205230265,
                        220.68665734207815
                    ],
                    [
                        121.5713066306887,
                        220.11037835966232
                    ],
                    [
                        124.26926929838328,
                        220.77530129452293
                    ],
                    [
                        125.23603925430712,
                        220.68665734207815
                    ],
                    [
                        126.31522432138513,
                        219.71132114683058
                    ],
                    [
                        126.78736778823145,
                        220.97473620742227
                    ],
                    [
                        128.63097561115615,
                        222.17093986645227
                    ],
                    [
                        129.80009276715737,
                        221.94947308761192
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Rajgarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shahdol",
            "dt_code": "460",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        190.3918376791321,
                        222.74664220858733
                    ],
                    [
                        192.32537759098,
                        221.8830284126468
                    ],
                    [
                        193.08980034682668,
                        222.28166433429038
                    ],
                    [
                        194.50623074736654,
                        220.99689444772645
                    ],
                    [
                        195.47300070329015,
                        221.04121021187206
                    ],
                    [
                        196.0800423035214,
                        219.77783607647763
                    ],
                    [
                        197.45150665959954,
                        220.33204326841292
                    ],
                    [
                        197.27164248175336,
                        221.24061933243752
                    ],
                    [
                        198.2384124376772,
                        222.9901602519932
                    ],
                    [
                        198.35082754883115,
                        225.44587649969725
                    ],
                    [
                        199.227665415832,
                        225.9101446773575
                    ],
                    [
                        199.34008052698596,
                        226.68369487904596
                    ],
                    [
                        200.59912977190993,
                        228.5390460075647
                    ],
                    [
                        199.38504657144745,
                        229.4661050078696
                    ],
                    [
                        199.34008052698596,
                        231.89218022226925
                    ],
                    [
                        201.49845066114153,
                        231.29694775870172
                    ],
                    [
                        202.42025457260388,
                        231.8480946854454
                    ],
                    [
                        203.83668497314352,
                        231.53947014451995
                    ],
                    [
                        204.84842097352907,
                        233.45662151261507
                    ],
                    [
                        203.7917189286818,
                        235.28401320318955
                    ],
                    [
                        202.71253386160424,
                        235.35003410862095
                    ],
                    [
                        201.4759676389108,
                        236.6700231203377
                    ],
                    [
                        199.3625635492167,
                        236.91193261553798
                    ],
                    [
                        197.96861617090758,
                        238.7803123737002
                    ],
                    [
                        196.7994990149066,
                        239.17576064138086
                    ],
                    [
                        195.0458232809051,
                        238.42874169653413
                    ],
                    [
                        195.18072141429002,
                        237.725427516408
                    ],
                    [
                        194.05657030275052,
                        236.6920160239049
                    ],
                    [
                        194.52871376959706,
                        236.56005520728655
                    ],
                    [
                        195.54044976998262,
                        234.42555500704918
                    ],
                    [
                        194.88844212528966,
                        233.32445992177094
                    ],
                    [
                        194.73106096967422,
                        231.14260080664485
                    ],
                    [
                        194.01160425828903,
                        230.78976531543913
                    ],
                    [
                        194.01160425828903,
                        228.51696817483634
                    ],
                    [
                        192.99986825790347,
                        227.32441905483438
                    ],
                    [
                        193.62939288036546,
                        226.396409771406
                    ],
                    [
                        192.25792852428754,
                        224.51702875076313
                    ],
                    [
                        191.38109065728668,
                        224.6055083557327
                    ],
                    [
                        190.3918376791321,
                        222.74664220858733
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Shahdol",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nadia",
            "dt_code": "336",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        295.88217798599135,
                        227.8986942821232
                    ],
                    [
                        296.7814988752227,
                        227.2360550031994
                    ],
                    [
                        299.2771143428404,
                        226.94884599338133
                    ],
                    [
                        300.80595985453397,
                        224.18519659416575
                    ],
                    [
                        302.55963558853546,
                        223.56563429518133
                    ],
                    [
                        303.5713715889208,
                        222.50309543796368
                    ],
                    [
                        304.20089621138277,
                        223.4992416180525
                    ],
                    [
                        304.04351505576733,
                        225.49009698369122
                    ],
                    [
                        302.19990723284286,
                        226.2859049496139
                    ],
                    [
                        301.5703826103809,
                        228.67150811637526
                    ],
                    [
                        301.9975600327657,
                        230.21628149657494
                    ],
                    [
                        303.90861692238286,
                        231.89218022226925
                    ],
                    [
                        304.49317550038313,
                        232.94995730561402
                    ],
                    [
                        303.7062697223057,
                        236.38409477212406
                    ],
                    [
                        303.18916021099744,
                        238.5825609492851
                    ],
                    [
                        303.3915074110746,
                        239.6809498801141
                    ],
                    [
                        302.8968809219973,
                        241.37137454117885
                    ],
                    [
                        302.2673562995351,
                        241.56887033362534
                    ],
                    [
                        299.2546313206094,
                        240.998277720513
                    ],
                    [
                        299.2995973650711,
                        240.25189071632616
                    ],
                    [
                        300.6485786989185,
                        238.40676661794174
                    ],
                    [
                        299.36704643176336,
                        236.7360011521351
                    ],
                    [
                        298.0630311423779,
                        235.0419190352439
                    ],
                    [
                        298.96235203160927,
                        232.13463422627513
                    ],
                    [
                        297.05129514199234,
                        230.17216088045024
                    ],
                    [
                        295.25265336352936,
                        229.4440369363354
                    ],
                    [
                        295.88217798599135,
                        227.8986942821232
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Nadia",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jamtara",
            "dt_code": "363",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        270.9485063320469,
                        223.69841325751838
                    ],
                    [
                        272.2525216214324,
                        221.77228252524048
                    ],
                    [
                        273.8712992220494,
                        221.9716208364609
                    ],
                    [
                        277.8957602013604,
                        223.38858241975362
                    ],
                    [
                        278.1880394903608,
                        222.30380851441726
                    ],
                    [
                        278.7276320238998,
                        221.70583213453125
                    ],
                    [
                        279.98668126882376,
                        222.32595245677226
                    ],
                    [
                        280.5712398468245,
                        223.45497864928478
                    ],
                    [
                        282.01015326959487,
                        223.6541545501911
                    ],
                    [
                        282.90947415882624,
                        225.6448612694977
                    ],
                    [
                        282.01015326959487,
                        226.37430927608494
                    ],
                    [
                        280.4813077579013,
                        226.41851003224383
                    ],
                    [
                        279.8293001132083,
                        227.0814089104237
                    ],
                    [
                        277.3336846455909,
                        225.954355289621
                    ],
                    [
                        275.64745797828164,
                        226.72788907314225
                    ],
                    [
                        272.4323857992788,
                        224.34005821213282
                    ],
                    [
                        270.9485063320469,
                        223.69841325751838
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Jamtara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dungarpur",
            "dt_code": "124",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        78.15659070303627,
                        227.03722220733812
                    ],
                    [
                        79.55053808134517,
                        226.63949974793672
                    ],
                    [
                        80.29247781496133,
                        225.1805338301803
                    ],
                    [
                        81.30421381534688,
                        224.6497467426288
                    ],
                    [
                        82.60822910473235,
                        225.02573495030686
                    ],
                    [
                        85.05887852788828,
                        225.11419286619457
                    ],
                    [
                        86.20551266165853,
                        224.29581321650204
                    ],
                    [
                        88.49878092919903,
                        224.6055083557327
                    ],
                    [
                        89.69038110743077,
                        223.87543862230092
                    ],
                    [
                        90.6571510633546,
                        225.55642594565427
                    ],
                    [
                        91.42157381920129,
                        224.87092452690558
                    ],
                    [
                        92.97290235312585,
                        225.60064407781806
                    ],
                    [
                        91.44405684143203,
                        226.74998581885467
                    ],
                    [
                        89.60044901850756,
                        227.54531282455076
                    ],
                    [
                        89.1732715961225,
                        228.5390460075647
                    ],
                    [
                        89.24072066281497,
                        230.34863779110225
                    ],
                    [
                        88.45381488473731,
                        230.480985757808
                    ],
                    [
                        86.83503728412052,
                        234.00720616665353
                    ],
                    [
                        85.84578430596594,
                        234.73375935062114
                    ],
                    [
                        84.94646341673456,
                        233.2804042266112
                    ],
                    [
                        83.84479532742557,
                        232.81776409694464
                    ],
                    [
                        81.95622146003961,
                        232.9058938199626
                    ],
                    [
                        82.38339888242444,
                        230.15010022519328
                    ],
                    [
                        81.14683265973122,
                        229.62057501272216
                    ],
                    [
                        80.42737594834603,
                        230.37069636377163
                    ],
                    [
                        79.7079192369606,
                        228.80396184925877
                    ],
                    [
                        78.15659070303627,
                        227.03722220733812
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Dungarpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Katni",
            "dt_code": "450",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        180.9264853199702,
                        224.56126902515837
                    ],
                    [
                        182.54526292058677,
                        224.20732039107668
                    ],
                    [
                        183.8043121655112,
                        223.27791729900258
                    ],
                    [
                        186.50227483320577,
                        221.94947308761192
                    ],
                    [
                        186.90696923335986,
                        224.18519659416575
                    ],
                    [
                        188.43581474505345,
                        222.79092035251875
                    ],
                    [
                        189.73983003443914,
                        223.01229683226933
                    ],
                    [
                        188.0985694115916,
                        224.6055083557327
                    ],
                    [
                        188.07608638936085,
                        225.55642594565427
                    ],
                    [
                        189.15527145643864,
                        227.43486886034663
                    ],
                    [
                        188.97540727859246,
                        229.2454138482284
                    ],
                    [
                        186.99690132228307,
                        229.33369309758342
                    ],
                    [
                        185.80530114405133,
                        228.36241682668714
                    ],
                    [
                        184.8385311881275,
                        230.59126937126712
                    ],
                    [
                        183.91672727666514,
                        231.0102944222101
                    ],
                    [
                        183.64693100989552,
                        233.78698945223817
                    ],
                    [
                        182.38788176497133,
                        234.57966276418625
                    ],
                    [
                        180.47682487535417,
                        235.06392873366076
                    ],
                    [
                        180.0271644307386,
                        234.66771932389315
                    ],
                    [
                        179.127843541507,
                        230.90003275494786
                    ],
                    [
                        176.99195642958216,
                        230.59126937126712
                    ],
                    [
                        175.21579767334993,
                        230.90003275494786
                    ],
                    [
                        174.60875607311846,
                        230.52509989673808
                    ],
                    [
                        174.7211711842724,
                        229.17920197310065
                    ],
                    [
                        173.12487660588636,
                        228.95848062312257
                    ],
                    [
                        173.66446913942536,
                        226.81627465086177
                    ],
                    [
                        175.46311091788834,
                        225.7554001350113
                    ],
                    [
                        176.4298808738124,
                        224.18519659416575
                    ],
                    [
                        177.80134522989056,
                        225.0699643793444
                    ],
                    [
                        179.06039447481453,
                        224.91515725471493
                    ],
                    [
                        180.1620625641233,
                        225.5122068728648
                    ],
                    [
                        180.9264853199702,
                        224.56126902515837
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Katni",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dhanbad",
            "dt_code": "354",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        265.4851319299653,
                        225.86593312560302
                    ],
                    [
                        267.1488755750438,
                        225.003619882424
                    ],
                    [
                        268.1830945976599,
                        225.401655074723
                    ],
                    [
                        269.4421438425841,
                        223.10084078154227
                    ],
                    [
                        270.9485063320469,
                        223.69841325751838
                    ],
                    [
                        272.4323857992788,
                        224.34005821213282
                    ],
                    [
                        275.64745797828164,
                        226.72788907314225
                    ],
                    [
                        276.029669356205,
                        227.83243980999953
                    ],
                    [
                        275.64745797828164,
                        228.89225968643308
                    ],
                    [
                        274.34344268889595,
                        228.89225968643308
                    ],
                    [
                        271.0159553987394,
                        229.9074177354044
                    ],
                    [
                        269.3297287314301,
                        228.69358432008153
                    ],
                    [
                        267.4636378862747,
                        228.67150811637526
                    ],
                    [
                        266.67673210819703,
                        228.07536261135505
                    ],
                    [
                        265.4851319299653,
                        225.86593312560302
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Dhanbad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Banswara",
            "dt_code": "125",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        86.83503728412052,
                        234.00720616665353
                    ],
                    [
                        88.45381488473731,
                        230.480985757808
                    ],
                    [
                        89.24072066281497,
                        230.34863779110225
                    ],
                    [
                        89.1732715961225,
                        228.5390460075647
                    ],
                    [
                        89.60044901850756,
                        227.54531282455076
                    ],
                    [
                        91.44405684143203,
                        226.74998581885467
                    ],
                    [
                        92.97290235312585,
                        225.60064407781806
                    ],
                    [
                        94.50174786481944,
                        225.2689851524994
                    ],
                    [
                        95.6933480430514,
                        227.19187157458504
                    ],
                    [
                        95.06382342058919,
                        229.9074177354044
                    ],
                    [
                        96.99736333243686,
                        229.77503365408177
                    ],
                    [
                        98.43627675520747,
                        230.260401186965
                    ],
                    [
                        98.79600511090007,
                        231.4071886640163
                    ],
                    [
                        95.85072919866684,
                        233.36851470106518
                    ],
                    [
                        95.22120457620463,
                        234.95387796531952
                    ],
                    [
                        98.23392955513009,
                        236.56005520728655
                    ],
                    [
                        94.883959242743,
                        238.73636918582042
                    ],
                    [
                        93.10780048651077,
                        238.2968878502532
                    ],
                    [
                        92.1410305305867,
                        239.13182553649392
                    ],
                    [
                        91.08432848573966,
                        237.1538147485146
                    ],
                    [
                        89.30816972950743,
                        237.28573893281117
                    ],
                    [
                        89.48803390735361,
                        235.83412491994062
                    ],
                    [
                        87.1722826175826,
                        234.68973289401652
                    ],
                    [
                        86.83503728412052,
                        234.00720616665353
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Banswara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Umaria",
            "dt_code": "431",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        189.73983003443914,
                        223.01229683226933
                    ],
                    [
                        190.3918376791321,
                        222.74664220858733
                    ],
                    [
                        191.38109065728668,
                        224.6055083557327
                    ],
                    [
                        192.25792852428754,
                        224.51702875076313
                    ],
                    [
                        193.62939288036546,
                        226.396409771406
                    ],
                    [
                        192.99986825790347,
                        227.32441905483438
                    ],
                    [
                        194.01160425828903,
                        228.51696817483634
                    ],
                    [
                        194.01160425828903,
                        230.78976531543913
                    ],
                    [
                        194.73106096967422,
                        231.14260080664485
                    ],
                    [
                        194.88844212528966,
                        233.32445992177094
                    ],
                    [
                        195.54044976998262,
                        234.42555500704918
                    ],
                    [
                        194.52871376959706,
                        236.56005520728655
                    ],
                    [
                        194.05657030275052,
                        236.6920160239049
                    ],
                    [
                        192.95490221344198,
                        234.99789895556813
                    ],
                    [
                        192.21296247982582,
                        236.7360011521351
                    ],
                    [
                        191.35860763505593,
                        236.9779031826256
                    ],
                    [
                        189.20023750090013,
                        235.54808454907237
                    ],
                    [
                        188.368365678361,
                        236.36209869843674
                    ],
                    [
                        186.32241065535936,
                        235.92212962254501
                    ],
                    [
                        186.6821390110522,
                        234.8218095297479
                    ],
                    [
                        185.96268229966654,
                        234.09528645551427
                    ],
                    [
                        184.11907447674184,
                        234.46958693501824
                    ],
                    [
                        183.64693100989552,
                        233.78698945223817
                    ],
                    [
                        183.91672727666514,
                        231.0102944222101
                    ],
                    [
                        184.8385311881275,
                        230.59126937126712
                    ],
                    [
                        185.80530114405133,
                        228.36241682668714
                    ],
                    [
                        186.99690132228307,
                        229.33369309758342
                    ],
                    [
                        188.97540727859246,
                        229.2454138482284
                    ],
                    [
                        189.15527145643864,
                        227.43486886034663
                    ],
                    [
                        188.07608638936085,
                        225.55642594565427
                    ],
                    [
                        188.0985694115916,
                        224.6055083557327
                    ],
                    [
                        189.73983003443914,
                        223.01229683226933
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Umaria",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Latehar",
            "dt_code": "359",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        234.7508405404776,
                        227.2360550031994
                    ],
                    [
                        236.48203325224813,
                        225.60064407781806
                    ],
                    [
                        239.38234312001987,
                        225.5122068728648
                    ],
                    [
                        241.36084907632926,
                        224.73822068595376
                    ],
                    [
                        242.79976249909987,
                        225.22475996218492
                    ],
                    [
                        242.97962667694628,
                        224.25156727619725
                    ],
                    [
                        244.32860801079346,
                        224.07457406539595
                    ],
                    [
                        248.1956878344888,
                        225.003619882424
                    ],
                    [
                        248.46548410125865,
                        226.04277369624054
                    ],
                    [
                        248.26313690118127,
                        228.34033713116156
                    ],
                    [
                        248.71279734579707,
                        228.6273550109517
                    ],
                    [
                        248.0383066788736,
                        229.3778313293708
                    ],
                    [
                        248.3980350345662,
                        230.34863779110225
                    ],
                    [
                        247.47623112310384,
                        231.14260080664485
                    ],
                    [
                        247.07153672294976,
                        231.56151625149903
                    ],
                    [
                        243.90143058840863,
                        229.3778313293708
                    ],
                    [
                        241.36084907632926,
                        229.55437497387067
                    ],
                    [
                        240.16924889809752,
                        230.41481281519577
                    ],
                    [
                        239.13502987548122,
                        230.78976531543913
                    ],
                    [
                        239.2924110310969,
                        232.1787137829536
                    ],
                    [
                        238.0783278306344,
                        232.94995730561402
                    ],
                    [
                        238.28067503071134,
                        233.98518552341682
                    ],
                    [
                        237.29142205255653,
                        234.88784477231889
                    ],
                    [
                        235.56022934078578,
                        234.75577223713685
                    ],
                    [
                        233.91896871793847,
                        233.91912222297583
                    ],
                    [
                        234.68339147378515,
                        232.02443131085795
                    ],
                    [
                        234.7058744960159,
                        229.92948093797713
                    ],
                    [
                        234.7508405404776,
                        227.2360550031994
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Latehar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ratlam",
            "dt_code": "434",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        108.93584813698567,
                        225.37954400932404
                    ],
                    [
                        110.44221062644829,
                        226.37430927608494
                    ],
                    [
                        110.7120068932179,
                        227.3465094833473
                    ],
                    [
                        112.28581844937298,
                        228.119527361905
                    ],
                    [
                        111.27408244898766,
                        229.53230783035553
                    ],
                    [
                        109.8126860039863,
                        229.42196863275268
                    ],
                    [
                        109.07074627037036,
                        231.5835621281135
                    ],
                    [
                        107.9690781810616,
                        230.4589283415172
                    ],
                    [
                        106.26036849152183,
                        230.65743676557793
                    ],
                    [
                        105.06876831328987,
                        231.51742380715933
                    ],
                    [
                        104.55165880198183,
                        233.69889636663987
                    ],
                    [
                        105.96808920252147,
                        235.8121231768746
                    ],
                    [
                        105.45097969121343,
                        236.77998537527503
                    ],
                    [
                        105.69829293575208,
                        237.92325794377348
                    ],
                    [
                        105.63084386905985,
                        238.71439725483896
                    ],
                    [
                        103.74227000167343,
                        238.2529347679834
                    ],
                    [
                        102.23590751221059,
                        238.34084003234307
                    ],
                    [
                        98.90842022205402,
                        237.6594799823476
                    ],
                    [
                        98.23392955513009,
                        236.56005520728655
                    ],
                    [
                        95.22120457620463,
                        234.95387796531952
                    ],
                    [
                        95.85072919866684,
                        233.36851470106518
                    ],
                    [
                        98.79600511090007,
                        231.4071886640163
                    ],
                    [
                        99.89767320020883,
                        231.25284978267752
                    ],
                    [
                        100.99934128951713,
                        229.42196863275268
                    ],
                    [
                        101.26913755628675,
                        227.83243980999953
                    ],
                    [
                        102.91039817913429,
                        226.8604660347683
                    ],
                    [
                        105.90064013582901,
                        226.90465648222767
                    ],
                    [
                        107.06975729183023,
                        226.61740183095816
                    ],
                    [
                        108.93584813698567,
                        225.37954400932404
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Ratlam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bokaro",
            "dt_code": "355",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        262.6073050844245,
                        224.87092452690558
                    ],
                    [
                        263.39421086250195,
                        225.7554001350113
                    ],
                    [
                        264.92305637419577,
                        225.31320940126614
                    ],
                    [
                        265.4851319299653,
                        225.86593312560302
                    ],
                    [
                        266.67673210819703,
                        228.07536261135505
                    ],
                    [
                        267.4636378862747,
                        228.67150811637526
                    ],
                    [
                        269.3297287314301,
                        228.69358432008153
                    ],
                    [
                        271.0159553987394,
                        229.9074177354044
                    ],
                    [
                        269.2397966425069,
                        231.5835621281135
                    ],
                    [
                        268.4304078421985,
                        233.5006735451497
                    ],
                    [
                        266.4743849081199,
                        232.57538843244515
                    ],
                    [
                        266.2045886413507,
                        231.14260080664485
                    ],
                    [
                        265.0804375298112,
                        231.0323460631859
                    ],
                    [
                        264.4509129073492,
                        232.11259410308043
                    ],
                    [
                        262.18012766203947,
                        232.35502281608967
                    ],
                    [
                        260.26907077242254,
                        229.4661050078696
                    ],
                    [
                        258.20063272718994,
                        229.9515439088879
                    ],
                    [
                        258.1781497049592,
                        227.30232839258082
                    ],
                    [
                        257.72848926034317,
                        226.41851003224383
                    ],
                    [
                        258.62781014957477,
                        224.78245624266714
                    ],
                    [
                        259.9992745056529,
                        224.84880780945014
                    ],
                    [
                        261.4381879284233,
                        226.61740183095816
                    ],
                    [
                        262.6073050844245,
                        224.87092452690558
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Bokaro",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ramgarh",
            "dt_code": "361",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        258.62781014957477,
                        224.78245624266714
                    ],
                    [
                        257.72848926034317,
                        226.41851003224383
                    ],
                    [
                        258.1781497049592,
                        227.30232839258082
                    ],
                    [
                        258.20063272718994,
                        229.9515439088879
                    ],
                    [
                        260.26907077242254,
                        229.4661050078696
                    ],
                    [
                        262.18012766203947,
                        232.35502281608967
                    ],
                    [
                        261.7979162841159,
                        233.21431896631873
                    ],
                    [
                        260.5613500614227,
                        232.70759678226733
                    ],
                    [
                        259.66202917219107,
                        232.9719887045366
                    ],
                    [
                        257.9982855271128,
                        232.26687013795782
                    ],
                    [
                        256.94158348226574,
                        231.09849960147528
                    ],
                    [
                        254.82817939257166,
                        231.27489888598552
                    ],
                    [
                        253.0969866808009,
                        230.8338729840525
                    ],
                    [
                        252.17518276933856,
                        230.03979347626262
                    ],
                    [
                        252.55739414726213,
                        229.13505956174623
                    ],
                    [
                        254.10872268118646,
                        228.80396184925877
                    ],
                    [
                        255.39025494834118,
                        229.13505956174623
                    ],
                    [
                        256.0647456152651,
                        227.83243980999953
                    ],
                    [
                        255.4352209928029,
                        227.36859967813757
                    ],
                    [
                        258.62781014957477,
                        224.78245624266714
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Ramgarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhopal",
            "dt_code": "444",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        134.88125579131543,
                        225.82172063421507
                    ],
                    [
                        136.23023712516283,
                        225.82172063421507
                    ],
                    [
                        137.08459196993272,
                        225.9322501009167
                    ],
                    [
                        137.64666752570247,
                        227.28023749656882
                    ],
                    [
                        138.52350539270333,
                        227.9649466547122
                    ],
                    [
                        139.98490183770446,
                        227.81035451931257
                    ],
                    [
                        141.06408690478224,
                        228.67150811637526
                    ],
                    [
                        138.99564885954965,
                        230.98824255043223
                    ],
                    [
                        139.1530300151653,
                        231.9803485348355
                    ],
                    [
                        138.748335615011,
                        233.43459515306392
                    ],
                    [
                        140.74932459355114,
                        235.0419190352439
                    ],
                    [
                        139.80503765985804,
                        237.90127768676194
                    ],
                    [
                        137.1295580143942,
                        238.9341064552222
                    ],
                    [
                        135.53326343600838,
                        238.5825609492851
                    ],
                    [
                        134.8138067246232,
                        237.30772550632008
                    ],
                    [
                        134.70139161346924,
                        235.5260798536449
                    ],
                    [
                        134.04938396877628,
                        235.26200577990096
                    ],
                    [
                        135.78057668054703,
                        233.2804042266112
                    ],
                    [
                        135.6231955249316,
                        230.480985757808
                    ],
                    [
                        134.0044179243148,
                        229.31162363349347
                    ],
                    [
                        134.83628974685394,
                        227.0593156758572
                    ],
                    [
                        134.88125579131543,
                        225.82172063421507
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Bhopal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Purba Bardhaman",
            "dt_code": "335",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        292.77952091814245,
                        227.12559467776953
                    ],
                    [
                        293.40904554060467,
                        226.55110667411373
                    ],
                    [
                        294.17346829645135,
                        228.51696817483634
                    ],
                    [
                        295.88217798599135,
                        227.8986942821232
                    ],
                    [
                        295.25265336352936,
                        229.4440369363354
                    ],
                    [
                        297.05129514199234,
                        230.17216088045024
                    ],
                    [
                        298.96235203160927,
                        232.13463422627513
                    ],
                    [
                        298.0630311423779,
                        235.0419190352439
                    ],
                    [
                        299.36704643176336,
                        236.7360011521351
                    ],
                    [
                        298.58014065368593,
                        238.2089807853951
                    ],
                    [
                        295.7697628748374,
                        237.59353041837363
                    ],
                    [
                        295.4325175413753,
                        238.8462254705721
                    ],
                    [
                        294.0160871408359,
                        240.25189071632616
                    ],
                    [
                        292.3973095402191,
                        240.998277720513
                    ],
                    [
                        290.913430072987,
                        241.10801876359346
                    ],
                    [
                        289.4969996724476,
                        240.64706892019518
                    ],
                    [
                        289.04733922783157,
                        239.9225202314821
                    ],
                    [
                        289.81176198367825,
                        238.8901664120558
                    ],
                    [
                        288.058086249677,
                        236.9559132197017
                    ],
                    [
                        286.7540709602913,
                        235.0859382044864
                    ],
                    [
                        285.180259404136,
                        233.65484845189036
                    ],
                    [
                        285.180259404136,
                        232.3990977766784
                    ],
                    [
                        286.4168256268292,
                        230.34863779110225
                    ],
                    [
                        288.97989016113934,
                        230.17216088045024
                    ],
                    [
                        291.0033621619102,
                        231.27489888598552
                    ],
                    [
                        292.23992838460345,
                        230.9661904478345
                    ],
                    [
                        292.6671058069883,
                        229.99566915579373
                    ],
                    [
                        291.9476490956033,
                        228.5832009747284
                    ],
                    [
                        292.77952091814245,
                        227.12559467776953
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Purba Bardhaman",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Koriya",
            "dt_code": "400",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        208.24335733037788,
                        240.976328843182
                    ],
                    [
                        206.87189297429995,
                        238.42874169653413
                    ],
                    [
                        207.99604408583946,
                        235.06392873366076
                    ],
                    [
                        206.5796136852996,
                        233.72091998100075
                    ],
                    [
                        204.84842097352907,
                        233.45662151261507
                    ],
                    [
                        203.83668497314352,
                        231.53947014451995
                    ],
                    [
                        202.42025457260388,
                        231.8480946854454
                    ],
                    [
                        201.49845066114153,
                        231.29694775870172
                    ],
                    [
                        199.34008052698596,
                        231.89218022226925
                    ],
                    [
                        199.38504657144745,
                        229.4661050078696
                    ],
                    [
                        200.59912977190993,
                        228.5390460075647
                    ],
                    [
                        199.34008052698596,
                        226.68369487904596
                    ],
                    [
                        199.227665415832,
                        225.9101446773575
                    ],
                    [
                        200.1719523495251,
                        225.2689851524994
                    ],
                    [
                        201.026307194295,
                        226.63949974793672
                    ],
                    [
                        202.2628734169882,
                        227.14768721058402
                    ],
                    [
                        203.90413403983598,
                        226.13118834659565
                    ],
                    [
                        205.16318328475995,
                        226.2859049496139
                    ],
                    [
                        206.2648513740687,
                        227.01512850484846
                    ],
                    [
                        208.13094221922393,
                        226.41851003224383
                    ],
                    [
                        208.89536497507106,
                        226.92675135483387
                    ],
                    [
                        212.38023342084307,
                        227.5232244989087
                    ],
                    [
                        213.9090789325369,
                        229.26748400883383
                    ],
                    [
                        213.61679964353652,
                        231.36309299347363
                    ],
                    [
                        214.49363751053716,
                        232.0685131668929
                    ],
                    [
                        214.62853564392208,
                        233.94114355159547
                    ],
                    [
                        216.06744906669246,
                        234.42555500704918
                    ],
                    [
                        216,
                        235.79012120680818
                    ],
                    [
                        213.54935057684406,
                        238.0551347543356
                    ],
                    [
                        213.97652799922912,
                        239.7029119405991
                    ],
                    [
                        213.48190151015183,
                        240.71292492058484
                    ],
                    [
                        211.75070879838108,
                        240.62511647368285
                    ],
                    [
                        210.71648977576479,
                        240.09819075193013
                    ],
                    [
                        208.8728819528401,
                        241.08607100073027
                    ],
                    [
                        208.24335733037788,
                        240.976328843182
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Koriya",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ujjain",
            "dt_code": "435",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        112.28581844937298,
                        228.119527361905
                    ],
                    [
                        112.87037702737348,
                        227.9649466547122
                    ],
                    [
                        115.09619622822174,
                        231.16465106317548
                    ],
                    [
                        116.84987196222323,
                        230.90003275494786
                    ],
                    [
                        118.558581651763,
                        231.42923615354852
                    ],
                    [
                        119.12065720753276,
                        230.9661904478345
                    ],
                    [
                        119.97501205230265,
                        232.26687013795782
                    ],
                    [
                        119.97501205230265,
                        233.94114355159547
                    ],
                    [
                        119.52535160768707,
                        235.79012120680818
                    ],
                    [
                        118.62603071845524,
                        236.18612195106692
                    ],
                    [
                        118.2438193405319,
                        236.8679511068379
                    ],
                    [
                        118.06395516268594,
                        239.32952644219944
                    ],
                    [
                        116.75993987330003,
                        235.87812772513996
                    ],
                    [
                        116.06296618414558,
                        237.59353041837363
                    ],
                    [
                        116.55759267322287,
                        239.46131695466835
                    ],
                    [
                        115.34350947276039,
                        240.0323159844773
                    ],
                    [
                        115.00626413929854,
                        239.46131695466835
                    ],
                    [
                        112.01602218260359,
                        239.8566400945839
                    ],
                    [
                        111.34153151567989,
                        238.7803123737002
                    ],
                    [
                        110.46469364867926,
                        238.71439725483896
                    ],
                    [
                        109.11571231483185,
                        240.31775877661568
                    ],
                    [
                        108.62108582575479,
                        242.20512314028542
                    ],
                    [
                        107.60934982536924,
                        242.18318649851943
                    ],
                    [
                        106.64257986944517,
                        241.17386071519962
                    ],
                    [
                        107.06975729183023,
                        239.46131695466835
                    ],
                    [
                        106.8449270695221,
                        238.36281578586357
                    ],
                    [
                        105.69829293575208,
                        237.92325794377348
                    ],
                    [
                        105.45097969121343,
                        236.77998537527503
                    ],
                    [
                        105.96808920252147,
                        235.8121231768746
                    ],
                    [
                        104.55165880198183,
                        233.69889636663987
                    ],
                    [
                        105.06876831328987,
                        231.51742380715933
                    ],
                    [
                        106.26036849152183,
                        230.65743676557793
                    ],
                    [
                        107.9690781810616,
                        230.4589283415172
                    ],
                    [
                        109.07074627037036,
                        231.5835621281135
                    ],
                    [
                        109.8126860039863,
                        229.42196863275268
                    ],
                    [
                        111.27408244898766,
                        229.53230783035553
                    ],
                    [
                        112.28581844937298,
                        228.119527361905
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Ujjain",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gandhinagar",
            "dt_code": "473",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        62.9805506972541,
                        238.38479131439072
                    ],
                    [
                        64.32953203110128,
                        237.7913730210214
                    ],
                    [
                        64.10470180879338,
                        235.5920932583183
                    ],
                    [
                        65.70099638717966,
                        234.49160255690322
                    ],
                    [
                        65.45368314264078,
                        233.94114355159547
                    ],
                    [
                        67.20735887664227,
                        231.1867010890266
                    ],
                    [
                        69.77042341095216,
                        232.0685131668929
                    ],
                    [
                        69.70297434425993,
                        233.25837603553828
                    ],
                    [
                        68.82613647725907,
                        233.63282415146728
                    ],
                    [
                        71.97375958956945,
                        236.05412986938256
                    ],
                    [
                        72.98549558995501,
                        235.6361010588675
                    ],
                    [
                        72.46838607864697,
                        236.5380609455123
                    ],
                    [
                        72.91804652326255,
                        237.5275788240191
                    ],
                    [
                        72.85059745657009,
                        238.45071655018512
                    ],
                    [
                        71.63651425610783,
                        238.80228363063338
                    ],
                    [
                        70.35498198895266,
                        240.14210614537546
                    ],
                    [
                        69.29827994410562,
                        238.56058744492105
                    ],
                    [
                        67.81440047687352,
                        238.5825609492851
                    ],
                    [
                        66.98252865433437,
                        238.0331558490966
                    ],
                    [
                        65.13892083140968,
                        237.7913730210214
                    ],
                    [
                        64.64429434233261,
                        239.13182553649392
                    ],
                    [
                        62.9805506972541,
                        238.38479131439072
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Gandhinagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ahmedabad",
            "dt_code": "474",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        58.93360669571234,
                        254.38982552424983
                    ],
                    [
                        59.96782571832864,
                        252.84038689696752
                    ],
                    [
                        58.9111236734816,
                        252.0761829820002
                    ],
                    [
                        55.606119405555546,
                        251.770427937409
                    ],
                    [
                        56.01081380570963,
                        250.10987980142187
                    ],
                    [
                        57.0675158505569,
                        248.79804042796673
                    ],
                    [
                        56.752753539325795,
                        247.6385996932678
                    ],
                    [
                        58.52891229555803,
                        247.94492640312774
                    ],
                    [
                        59.270852029173966,
                        249.08233841932707
                    ],
                    [
                        60.43996918517519,
                        248.38246204148197
                    ],
                    [
                        60.23762198509803,
                        246.71936328910954
                    ],
                    [
                        59.02353878463555,
                        246.60990473769982
                    ],
                    [
                        58.86615762901988,
                        243.3455230176241
                    ],
                    [
                        57.8769046508653,
                        242.2709317337413
                    ],
                    [
                        58.25911602878841,
                        239.17576064138086
                    ],
                    [
                        56.84268562824877,
                        239.04395263390592
                    ],
                    [
                        56.66282145040259,
                        237.26375213346256
                    ],
                    [
                        57.85442162863433,
                        236.7360011521351
                    ],
                    [
                        56.1681949613253,
                        235.6361010588675
                    ],
                    [
                        56.12322891686381,
                        233.98518552341682
                    ],
                    [
                        57.38227816178778,
                        233.89710066586065
                    ],
                    [
                        57.8769046508653,
                        232.26687013795782
                    ],
                    [
                        58.79870856232742,
                        232.99401987421373
                    ],
                    [
                        60.28258802955952,
                        232.86182941711928
                    ],
                    [
                        60.84466358532927,
                        233.43459515306392
                    ],
                    [
                        62.41847514148435,
                        233.45662151261507
                    ],
                    [
                        61.7215014523299,
                        234.77778489581834
                    ],
                    [
                        60.5973503407904,
                        235.72411393443127
                    ],
                    [
                        60.79969754086778,
                        237.7913730210214
                    ],
                    [
                        62.19364491917668,
                        238.80228363063338
                    ],
                    [
                        62.9805506972541,
                        238.38479131439072
                    ],
                    [
                        64.64429434233261,
                        239.13182553649392
                    ],
                    [
                        65.13892083140968,
                        237.7913730210214
                    ],
                    [
                        66.98252865433437,
                        238.0331558490966
                    ],
                    [
                        67.81440047687352,
                        238.5825609492851
                    ],
                    [
                        69.29827994410562,
                        238.56058744492105
                    ],
                    [
                        70.35498198895266,
                        240.14210614537546
                    ],
                    [
                        69.65800829979821,
                        241.56887033362534
                    ],
                    [
                        67.49963816564264,
                        241.72246571256994
                    ],
                    [
                        65.70099638717966,
                        243.3455230176241
                    ],
                    [
                        65.52113220933325,
                        246.30339171862346
                    ],
                    [
                        64.30704900887054,
                        246.9382640084806
                    ],
                    [
                        64.48691318671695,
                        247.92304733831753
                    ],
                    [
                        63.5651092752546,
                        248.4918323715866
                    ],
                    [
                        63.5651092752546,
                        251.00585622640926
                    ],
                    [
                        63.00303371948485,
                        253.1896500031919
                    ],
                    [
                        61.6315693634067,
                        256.0907907085739
                    ],
                    [
                        60.52990127409794,
                        256.0907907085739
                    ],
                    [
                        58.93360669571234,
                        254.38982552424983
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Ahmedabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Raisen",
            "dt_code": "446",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        150.5519222861749,
                        229.26748400883383
                    ],
                    [
                        151.60862433102193,
                        229.70883848456043
                    ],
                    [
                        152.55291126471502,
                        228.56112360752303
                    ],
                    [
                        153.69954539848527,
                        229.9074177354044
                    ],
                    [
                        153.58713028733155,
                        230.72360208014402
                    ],
                    [
                        157.09448175533453,
                        231.5835621281135
                    ],
                    [
                        155.49818717694848,
                        232.86182941711928
                    ],
                    [
                        155.52067019917922,
                        234.31547119515437
                    ],
                    [
                        156.66730433294947,
                        234.42555500704918
                    ],
                    [
                        158.15118380018157,
                        236.3181058713435
                    ],
                    [
                        157.83642148895046,
                        238.2089807853951
                    ],
                    [
                        157.00454966641132,
                        239.37345750977738
                    ],
                    [
                        153.15995286494626,
                        240.0103572813149
                    ],
                    [
                        152.82270753148464,
                        240.36166969947817
                    ],
                    [
                        150.9566166863292,
                        241.415264053069
                    ],
                    [
                        149.4277711746356,
                        241.7882889677751
                    ],
                    [
                        146.66235944024857,
                        243.23589520016924
                    ],
                    [
                        145.67310646209376,
                        240.5812109109819
                    ],
                    [
                        144.12177792816942,
                        240.91048087344967
                    ],
                    [
                        141.69361152724423,
                        241.9418687831876
                    ],
                    [
                        140.9516717936283,
                        242.66574136519154
                    ],
                    [
                        139.35537721524247,
                        241.63469825914996
                    ],
                    [
                        137.96142983693335,
                        241.19580758679763
                    ],
                    [
                        136.52251641416296,
                        239.46131695466835
                    ],
                    [
                        137.1295580143942,
                        238.9341064552222
                    ],
                    [
                        139.80503765985804,
                        237.90127768676194
                    ],
                    [
                        140.74932459355114,
                        235.0419190352439
                    ],
                    [
                        138.748335615011,
                        233.43459515306392
                    ],
                    [
                        139.1530300151653,
                        231.9803485348355
                    ],
                    [
                        140.7268415713204,
                        232.6414936401398
                    ],
                    [
                        141.9409247717831,
                        231.05439747337698
                    ],
                    [
                        143.4697702834767,
                        232.7957310928146
                    ],
                    [
                        146.25766504009448,
                        232.61945880043896
                    ],
                    [
                        147.4942312627877,
                        232.88386173319844
                    ],
                    [
                        148.23617099640364,
                        231.8040082280745
                    ],
                    [
                        149.51770326355881,
                        231.64969837594663
                    ],
                    [
                        150.5519222861749,
                        229.26748400883383
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Raisen",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Purulia",
            "dt_code": "340",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        272.8370801994331,
                        243.30167255404905
                    ],
                    [
                        272.09514046581717,
                        244.66062630068006
                    ],
                    [
                        270.02670242058457,
                        243.43322129176278
                    ],
                    [
                        270.2740156651232,
                        241.2616468650195
                    ],
                    [
                        271.2407856210473,
                        240.18602064442905
                    ],
                    [
                        267.41867184181297,
                        240.14210614537546
                    ],
                    [
                        264.5183619740412,
                        237.76939141161546
                    ],
                    [
                        262.18012766203947,
                        237.41765498687136
                    ],
                    [
                        261.21335770611563,
                        235.79012120680818
                    ],
                    [
                        262.1576446398087,
                        234.49160255690322
                    ],
                    [
                        261.7979162841159,
                        233.21431896631873
                    ],
                    [
                        262.18012766203947,
                        232.35502281608967
                    ],
                    [
                        264.4509129073492,
                        232.11259410308043
                    ],
                    [
                        265.0804375298112,
                        231.0323460631859
                    ],
                    [
                        266.2045886413507,
                        231.14260080664485
                    ],
                    [
                        266.4743849081199,
                        232.57538843244515
                    ],
                    [
                        268.4304078421985,
                        233.5006735451497
                    ],
                    [
                        269.2397966425069,
                        231.5835621281135
                    ],
                    [
                        271.0159553987394,
                        229.9074177354044
                    ],
                    [
                        274.34344268889595,
                        228.89225968643308
                    ],
                    [
                        275.64745797828164,
                        228.89225968643308
                    ],
                    [
                        277.13133744551374,
                        229.8632906352026
                    ],
                    [
                        277.0189223343598,
                        231.89218022226925
                    ],
                    [
                        276.3893977118978,
                        233.89710066586065
                    ],
                    [
                        275.4451107782047,
                        234.66771932389315
                    ],
                    [
                        275.37766171151225,
                        235.92212962254501
                    ],
                    [
                        274.4108917555884,
                        238.14304812311372
                    ],
                    [
                        274.9280012668967,
                        238.67045271870722
                    ],
                    [
                        274.0061973554343,
                        241.19580758679763
                    ],
                    [
                        274.25351059997274,
                        242.00768537087794
                    ],
                    [
                        272.8370801994331,
                        243.30167255404905
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Purulia",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sehore",
            "dt_code": "445",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        134.79132370239222,
                        246.9601528794622
                    ],
                    [
                        133.4648253907758,
                        247.17902958625342
                    ],
                    [
                        132.83530076831357,
                        246.150119130439
                    ],
                    [
                        131.64370059008206,
                        243.06047918993346
                    ],
                    [
                        131.59873454562035,
                        242.0296237891402
                    ],
                    [
                        128.8333228112333,
                        242.31480301968162
                    ],
                    [
                        128.54104352223317,
                        242.77539795952464
                    ],
                    [
                        126.33770734361565,
                        243.21396897320307
                    ],
                    [
                        125.30348832099958,
                        243.74013740896663
                    ],
                    [
                        123.52732956476734,
                        241.32748413874373
                    ],
                    [
                        123.7296767648445,
                        239.02198384715552
                    ],
                    [
                        124.80886183192229,
                        238.01117671860555
                    ],
                    [
                        128.22628121100183,
                        237.35169797588736
                    ],
                    [
                        129.41788138923403,
                        238.36281578586357
                    ],
                    [
                        130.0923720561575,
                        236.45008163395664
                    ],
                    [
                        131.23900618992775,
                        235.10794744773847
                    ],
                    [
                        131.77859872346676,
                        232.9719887045366
                    ],
                    [
                        132.27322521254405,
                        232.61945880043896
                    ],
                    [
                        132.94771587946752,
                        232.24483139395727
                    ],
                    [
                        134.0044179243148,
                        229.31162363349347
                    ],
                    [
                        135.6231955249316,
                        230.480985757808
                    ],
                    [
                        135.78057668054703,
                        233.2804042266112
                    ],
                    [
                        134.04938396877628,
                        235.26200577990096
                    ],
                    [
                        134.70139161346924,
                        235.5260798536449
                    ],
                    [
                        134.8138067246232,
                        237.30772550632008
                    ],
                    [
                        135.53326343600838,
                        238.5825609492851
                    ],
                    [
                        137.1295580143942,
                        238.9341064552222
                    ],
                    [
                        136.52251641416296,
                        239.46131695466835
                    ],
                    [
                        137.96142983693335,
                        241.19580758679763
                    ],
                    [
                        139.35537721524247,
                        241.63469825914996
                    ],
                    [
                        140.9516717936283,
                        242.66574136519154
                    ],
                    [
                        141.69361152724423,
                        241.9418687831876
                    ],
                    [
                        144.12177792816942,
                        240.91048087344967
                    ],
                    [
                        145.67310646209376,
                        240.5812109109819
                    ],
                    [
                        146.66235944024857,
                        243.23589520016924
                    ],
                    [
                        144.0093628170157,
                        242.11737524114102
                    ],
                    [
                        142.45803428309114,
                        243.9154986149871
                    ],
                    [
                        140.70435854908965,
                        244.17851394852357
                    ],
                    [
                        140.0073848599352,
                        245.25216408295194
                    ],
                    [
                        138.3886072593184,
                        245.71213832283337
                    ],
                    [
                        136.43258432523976,
                        247.04770618068176
                    ],
                    [
                        134.79132370239222,
                        246.9601528794622
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Sehore",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ranchi",
            "dt_code": "364",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        248.71279734579707,
                        228.6273550109517
                    ],
                    [
                        249.94936356849075,
                        228.69358432008153
                    ],
                    [
                        250.28660890195238,
                        229.39990009710374
                    ],
                    [
                        252.55739414726213,
                        229.13505956174623
                    ],
                    [
                        252.17518276933856,
                        230.03979347626262
                    ],
                    [
                        253.0969866808009,
                        230.8338729840525
                    ],
                    [
                        254.82817939257166,
                        231.27489888598552
                    ],
                    [
                        256.94158348226574,
                        231.09849960147528
                    ],
                    [
                        257.9982855271128,
                        232.26687013795782
                    ],
                    [
                        259.66202917219107,
                        232.9719887045366
                    ],
                    [
                        260.5613500614227,
                        232.70759678226733
                    ],
                    [
                        261.7979162841159,
                        233.21431896631873
                    ],
                    [
                        262.1576446398087,
                        234.49160255690322
                    ],
                    [
                        261.21335770611563,
                        235.79012120680818
                    ],
                    [
                        262.18012766203947,
                        237.41765498687136
                    ],
                    [
                        261.07845957273094,
                        238.86819605361245
                    ],
                    [
                        261.77543326188515,
                        241.6785824306524
                    ],
                    [
                        259.88685939449897,
                        240.976328843182
                    ],
                    [
                        257.72848926034317,
                        241.7663481050401
                    ],
                    [
                        257.2338627712659,
                        241.19580758679763
                    ],
                    [
                        258.8076743274212,
                        240.0103572813149
                    ],
                    [
                        258.20063272718994,
                        238.2529347679834
                    ],
                    [
                        256.26709281534227,
                        238.09909188912565
                    ],
                    [
                        254.82817939257166,
                        236.99989291946753
                    ],
                    [
                        252.42249601387743,
                        236.75799337683276
                    ],
                    [
                        252.5124281028004,
                        235.8121231768746
                    ],
                    [
                        250.4664730797988,
                        236.20811983777628
                    ],
                    [
                        249.85943147956732,
                        236.9779031826256
                    ],
                    [
                        249.9718465907215,
                        238.1650259023504
                    ],
                    [
                        248.33058596787373,
                        240.054274463956
                    ],
                    [
                        247.74602738987323,
                        239.1977278572828
                    ],
                    [
                        247.5436801897963,
                        237.41765498687136
                    ],
                    [
                        247.7235443676425,
                        234.86583325254855
                    ],
                    [
                        247.47623112310384,
                        233.63282415146728
                    ],
                    [
                        248.3530689901047,
                        232.11259410308043
                    ],
                    [
                        247.47623112310384,
                        231.14260080664485
                    ],
                    [
                        248.3980350345662,
                        230.34863779110225
                    ],
                    [
                        248.0383066788736,
                        229.3778313293708
                    ],
                    [
                        248.71279734579707,
                        228.6273550109517
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Ranchi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bankura",
            "dt_code": "339",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        286.259444471214,
                        241.23970066169045
                    ],
                    [
                        285.2926745152902,
                        241.48109665143312
                    ],
                    [
                        283.8312780702886,
                        240.82268045844154
                    ],
                    [
                        282.5497458031339,
                        241.63469825914996
                    ],
                    [
                        279.5145378019772,
                        242.46834553154054
                    ],
                    [
                        279.6944019798236,
                        244.4634114047775
                    ],
                    [
                        278.795081090592,
                        245.66833542069753
                    ],
                    [
                        277.0189223343598,
                        245.646433640704
                    ],
                    [
                        274.0961294443571,
                        243.7839790348607
                    ],
                    [
                        272.8370801994331,
                        243.30167255404905
                    ],
                    [
                        274.25351059997274,
                        242.00768537087794
                    ],
                    [
                        274.0061973554343,
                        241.19580758679763
                    ],
                    [
                        274.9280012668967,
                        238.67045271870722
                    ],
                    [
                        274.4108917555884,
                        238.14304812311372
                    ],
                    [
                        275.37766171151225,
                        235.92212962254501
                    ],
                    [
                        275.4451107782047,
                        234.66771932389315
                    ],
                    [
                        276.3893977118978,
                        233.89710066586065
                    ],
                    [
                        277.0189223343598,
                        231.89218022226925
                    ],
                    [
                        277.13133744551374,
                        229.8632906352026
                    ],
                    [
                        278.795081090592,
                        229.97360664815426
                    ],
                    [
                        281.65042491390227,
                        231.16465106317548
                    ],
                    [
                        282.6621609142878,
                        232.09055374995876
                    ],
                    [
                        285.180259404136,
                        233.65484845189036
                    ],
                    [
                        286.7540709602913,
                        235.0859382044864
                    ],
                    [
                        288.058086249677,
                        236.9559132197017
                    ],
                    [
                        289.81176198367825,
                        238.8901664120558
                    ],
                    [
                        289.04733922783157,
                        239.9225202314821
                    ],
                    [
                        288.0131202052153,
                        240.38362482576207
                    ],
                    [
                        287.8557390495996,
                        241.70052418280056
                    ],
                    [
                        286.259444471214,
                        241.23970066169045
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Bankura",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lohardaga",
            "dt_code": "356",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        240.16924889809752,
                        230.41481281519577
                    ],
                    [
                        241.36084907632926,
                        229.55437497387067
                    ],
                    [
                        243.90143058840863,
                        229.3778313293708
                    ],
                    [
                        247.07153672294976,
                        231.56151625149903
                    ],
                    [
                        247.47623112310384,
                        231.14260080664485
                    ],
                    [
                        248.3530689901047,
                        232.11259410308043
                    ],
                    [
                        247.47623112310384,
                        233.63282415146728
                    ],
                    [
                        247.7235443676425,
                        234.86583325254855
                    ],
                    [
                        246.12724978925644,
                        235.6361010588675
                    ],
                    [
                        243.15949085479224,
                        234.99789895556813
                    ],
                    [
                        242.01285672102222,
                        233.94114355159547
                    ],
                    [
                        240.95615467617517,
                        233.6107996223215
                    ],
                    [
                        241.45078116525247,
                        232.3109469365628
                    ],
                    [
                        241.38333209856,
                        230.81181926522584
                    ],
                    [
                        240.16924889809752,
                        230.41481281519577
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Lohardaga",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Panchmahal",
            "dt_code": "484",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        81.01193452634652,
                        250.76550785766307
                    ],
                    [
                        79.57302110357591,
                        250.28473292612796
                    ],
                    [
                        78.92101345888295,
                        249.1916740494974
                    ],
                    [
                        78.87604741442146,
                        245.49311503921888
                    ],
                    [
                        77.88679443626665,
                        243.411297054812
                    ],
                    [
                        78.94349648111393,
                        240.976328843182
                    ],
                    [
                        79.14584368119108,
                        240.0323159844773
                    ],
                    [
                        81.32669683757763,
                        240.29580298000235
                    ],
                    [
                        82.65319514919406,
                        238.45071655018512
                    ],
                    [
                        83.64244812734842,
                        239.90056040964214
                    ],
                    [
                        84.63170110550323,
                        238.34084003234307
                    ],
                    [
                        86.00316546158137,
                        239.28559447768305
                    ],
                    [
                        86.18302963942779,
                        239.87860036401514
                    ],
                    [
                        85.30619177242716,
                        242.62187717619855
                    ],
                    [
                        84.54176901658002,
                        247.28845975915794
                    ],
                    [
                        83.46258394950246,
                        247.8136487507927
                    ],
                    [
                        83.4850669717332,
                        251.4427865753343
                    ],
                    [
                        82.3609158601937,
                        250.65624998653277
                    ],
                    [
                        81.01193452634652,
                        250.76550785766307
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Panchmahal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jabalpur",
            "dt_code": "451",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        175.12586558442672,
                        242.44641155246512
                    ],
                    [
                        172.49535198342437,
                        242.534146138158
                    ],
                    [
                        171.9557594498856,
                        241.34942945128807
                    ],
                    [
                        170.2020837158841,
                        240.88853110423526
                    ],
                    [
                        169.887321404653,
                        238.51663976166117
                    ],
                    [
                        168.51585704857507,
                        237.81335440498498
                    ],
                    [
                        167.68398522603593,
                        238.51663976166117
                    ],
                    [
                        166.04272460318816,
                        238.38479131439072
                    ],
                    [
                        166.4249359811115,
                        237.4396402057041
                    ],
                    [
                        168.53834007080582,
                        235.79012120680818
                    ],
                    [
                        170.8765743828078,
                        234.68973289401652
                    ],
                    [
                        171.596031094193,
                        233.2804042266112
                    ],
                    [
                        172.5403180278861,
                        232.94995730561402
                    ],
                    [
                        174.60875607311846,
                        230.52509989673808
                    ],
                    [
                        175.21579767334993,
                        230.90003275494786
                    ],
                    [
                        176.99195642958216,
                        230.59126937126712
                    ],
                    [
                        179.127843541507,
                        230.90003275494786
                    ],
                    [
                        180.0271644307386,
                        234.66771932389315
                    ],
                    [
                        180.47682487535417,
                        235.06392873366076
                    ],
                    [
                        182.38788176497133,
                        234.57966276418625
                    ],
                    [
                        183.64693100989552,
                        233.78698945223817
                    ],
                    [
                        184.11907447674184,
                        234.46958693501824
                    ],
                    [
                        184.20900656566505,
                        235.54808454907237
                    ],
                    [
                        183.06237243189503,
                        236.84596001324343
                    ],
                    [
                        183.44458380981837,
                        238.75834089210173
                    ],
                    [
                        182.41036478720207,
                        237.81335440498498
                    ],
                    [
                        180.94896834220094,
                        237.85731649665445
                    ],
                    [
                        180.09461349743083,
                        238.51663976166117
                    ],
                    [
                        179.3077077193534,
                        237.6594799823476
                    ],
                    [
                        175.55304300681155,
                        239.63702508724907
                    ],
                    [
                        175.73290718465796,
                        240.4275344081202
                    ],
                    [
                        175.12586558442672,
                        242.44641155246512
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Jabalpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gumla",
            "dt_code": "366",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        235.56022934078578,
                        234.75577223713685
                    ],
                    [
                        237.29142205255653,
                        234.88784477231889
                    ],
                    [
                        238.28067503071134,
                        233.98518552341682
                    ],
                    [
                        238.0783278306344,
                        232.94995730561402
                    ],
                    [
                        239.2924110310969,
                        232.1787137829536
                    ],
                    [
                        239.13502987548122,
                        230.78976531543913
                    ],
                    [
                        240.16924889809752,
                        230.41481281519577
                    ],
                    [
                        241.38333209856,
                        230.81181926522584
                    ],
                    [
                        241.45078116525247,
                        232.3109469365628
                    ],
                    [
                        240.95615467617517,
                        233.6107996223215
                    ],
                    [
                        242.01285672102222,
                        233.94114355159547
                    ],
                    [
                        243.15949085479224,
                        234.99789895556813
                    ],
                    [
                        246.12724978925644,
                        235.6361010588675
                    ],
                    [
                        247.7235443676425,
                        234.86583325254855
                    ],
                    [
                        247.5436801897963,
                        237.41765498687136
                    ],
                    [
                        247.74602738987323,
                        239.1977278572828
                    ],
                    [
                        248.33058596787373,
                        240.054274463956
                    ],
                    [
                        248.96011059033594,
                        241.37137454117885
                    ],
                    [
                        248.87017850141274,
                        243.52091602946268
                    ],
                    [
                        247.07153672294976,
                        244.55106467114013
                    ],
                    [
                        245.2504119222558,
                        243.2578212059542
                    ],
                    [
                        244.68833636648606,
                        244.22234675038993
                    ],
                    [
                        242.66486436571495,
                        244.353839866621
                    ],
                    [
                        242.64238134348443,
                        243.47706910259916
                    ],
                    [
                        241.06856978732912,
                        242.7096046676818
                    ],
                    [
                        240.57394329825183,
                        243.84973981808764
                    ],
                    [
                        238.77530151978885,
                        243.71821626484558
                    ],
                    [
                        239.22496196440443,
                        242.46834553154054
                    ],
                    [
                        240.30414703148222,
                        241.23970066169045
                    ],
                    [
                        239.96690169802037,
                        240.4275344081202
                    ],
                    [
                        237.0890748524796,
                        240.405579728637
                    ],
                    [
                        235.40284818517057,
                        238.27491142164945
                    ],
                    [
                        235.020636807247,
                        237.35169797588736
                    ],
                    [
                        235.56022934078578,
                        234.75577223713685
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Gumla",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dahod",
            "dt_code": "485",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        93.01786839758756,
                        245.90924053126577
                    ],
                    [
                        91.30915870804756,
                        245.77784103166402
                    ],
                    [
                        89.64541506296905,
                        247.72612596358795
                    ],
                    [
                        88.29643372912187,
                        247.2665741607115
                    ],
                    [
                        87.66690910665989,
                        247.11336886522827
                    ],
                    [
                        85.62095408365803,
                        248.0105622924728
                    ],
                    [
                        84.54176901658002,
                        247.28845975915794
                    ],
                    [
                        85.30619177242716,
                        242.62187717619855
                    ],
                    [
                        86.18302963942779,
                        239.87860036401514
                    ],
                    [
                        86.00316546158137,
                        239.28559447768305
                    ],
                    [
                        86.83503728412052,
                        238.27491142164945
                    ],
                    [
                        87.39711283989027,
                        235.46006440393523
                    ],
                    [
                        87.1722826175826,
                        234.68973289401652
                    ],
                    [
                        89.48803390735361,
                        235.83412491994062
                    ],
                    [
                        89.30816972950743,
                        237.28573893281117
                    ],
                    [
                        91.08432848573966,
                        237.1538147485146
                    ],
                    [
                        92.1410305305867,
                        239.13182553649392
                    ],
                    [
                        93.22021559766449,
                        241.74440701997742
                    ],
                    [
                        94.20946857581907,
                        241.56887033362534
                    ],
                    [
                        94.27691764251153,
                        243.01662297453777
                    ],
                    [
                        93.35511373104919,
                        244.37575461480566
                    ],
                    [
                        93.01786839758756,
                        245.90924053126577
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Dahod",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dewas",
            "dt_code": "437",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        133.4648253907758,
                        247.17902958625342
                    ],
                    [
                        132.7004026349291,
                        247.92304733831753
                    ],
                    [
                        130.36216832292712,
                        248.25121047799308
                    ],
                    [
                        128.51856050000242,
                        249.23540678551217
                    ],
                    [
                        128.338696322156,
                        250.54698672957738
                    ],
                    [
                        126.87729987715466,
                        251.28987073475145
                    ],
                    [
                        125.73066574338463,
                        249.56337470757148
                    ],
                    [
                        124.15685418722933,
                        249.4759213554305
                    ],
                    [
                        121.63875569738116,
                        251.26802475502126
                    ],
                    [
                        119.95252903007213,
                        250.80920949855505
                    ],
                    [
                        119.97501205230265,
                        249.36659979741415
                    ],
                    [
                        118.3337514294551,
                        248.53557898379137
                    ],
                    [
                        118.06395516268594,
                        247.46353669894637
                    ],
                    [
                        118.60354769622472,
                        246.39097124082798
                    ],
                    [
                        120.46963854137994,
                        245.77784103166402
                    ],
                    [
                        119.6377667188408,
                        245.0330947003519
                    ],
                    [
                        118.35623445168585,
                        244.85782337073329
                    ],
                    [
                        117.18711729568508,
                        242.33673832979423
                    ],
                    [
                        115.34350947276039,
                        240.0323159844773
                    ],
                    [
                        116.55759267322287,
                        239.46131695466835
                    ],
                    [
                        116.06296618414558,
                        237.59353041837363
                    ],
                    [
                        116.75993987330003,
                        235.87812772513996
                    ],
                    [
                        118.06395516268594,
                        239.32952644219944
                    ],
                    [
                        118.2438193405319,
                        236.8679511068379
                    ],
                    [
                        118.62603071845524,
                        236.18612195106692
                    ],
                    [
                        119.25555534091745,
                        236.4940717426607
                    ],
                    [
                        122.20083125315091,
                        236.36209869843674
                    ],
                    [
                        124.38168440953723,
                        237.21977785717593
                    ],
                    [
                        124.80886183192229,
                        238.01117671860555
                    ],
                    [
                        123.7296767648445,
                        239.02198384715552
                    ],
                    [
                        123.52732956476734,
                        241.32748413874373
                    ],
                    [
                        125.30348832099958,
                        243.74013740896663
                    ],
                    [
                        126.33770734361565,
                        243.21396897320307
                    ],
                    [
                        128.54104352223317,
                        242.77539795952464
                    ],
                    [
                        128.8333228112333,
                        242.31480301968162
                    ],
                    [
                        131.59873454562035,
                        242.0296237891402
                    ],
                    [
                        131.64370059008206,
                        243.06047918993346
                    ],
                    [
                        132.83530076831357,
                        246.150119130439
                    ],
                    [
                        133.4648253907758,
                        247.17902958625342
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Dewas",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Anuppur",
            "dt_code": "461",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        206.87189297429995,
                        238.42874169653413
                    ],
                    [
                        204.21889635106686,
                        238.82425466291818
                    ],
                    [
                        204.3313114622208,
                        240.36166969947817
                    ],
                    [
                        203.02729617283535,
                        241.8541102221863
                    ],
                    [
                        201.76824692791092,
                        242.09543771126374
                    ],
                    [
                        202.03804319468054,
                        243.82781977773777
                    ],
                    [
                        201.1162392832182,
                        245.25216408295194
                    ],
                    [
                        200.53168070521747,
                        243.5866847626076
                    ],
                    [
                        199.09276728244708,
                        244.02509219700977
                    ],
                    [
                        197.0243292372145,
                        243.76205833229943
                    ],
                    [
                        196.66460088152212,
                        242.7096046676818
                    ],
                    [
                        193.29214754690383,
                        241.48109665143312
                    ],
                    [
                        193.3595966135963,
                        240.0323159844773
                    ],
                    [
                        194.23643448059693,
                        238.95607613997976
                    ],
                    [
                        192.21296247982582,
                        236.7360011521351
                    ],
                    [
                        192.95490221344198,
                        234.99789895556813
                    ],
                    [
                        194.05657030275052,
                        236.6920160239049
                    ],
                    [
                        195.18072141429002,
                        237.725427516408
                    ],
                    [
                        195.0458232809051,
                        238.42874169653413
                    ],
                    [
                        196.7994990149066,
                        239.17576064138086
                    ],
                    [
                        197.96861617090758,
                        238.7803123737002
                    ],
                    [
                        199.3625635492167,
                        236.91193261553798
                    ],
                    [
                        201.4759676389108,
                        236.6700231203377
                    ],
                    [
                        202.71253386160424,
                        235.35003410862095
                    ],
                    [
                        203.7917189286818,
                        235.28401320318955
                    ],
                    [
                        204.84842097352907,
                        233.45662151261507
                    ],
                    [
                        206.5796136852996,
                        233.72091998100075
                    ],
                    [
                        207.99604408583946,
                        235.06392873366076
                    ],
                    [
                        206.87189297429995,
                        238.42874169653413
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Anuppur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jhabua",
            "dt_code": "464",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        98.34634466628427,
                        247.11336886522827
                    ],
                    [
                        96.39032173220562,
                        247.13525599038303
                    ],
                    [
                        93.93967230904991,
                        246.741254343901
                    ],
                    [
                        93.01786839758756,
                        245.90924053126577
                    ],
                    [
                        93.35511373104919,
                        244.37575461480566
                    ],
                    [
                        94.27691764251153,
                        243.01662297453777
                    ],
                    [
                        94.20946857581907,
                        241.56887033362534
                    ],
                    [
                        93.22021559766449,
                        241.74440701997742
                    ],
                    [
                        92.1410305305867,
                        239.13182553649392
                    ],
                    [
                        93.10780048651077,
                        238.2968878502532
                    ],
                    [
                        94.883959242743,
                        238.73636918582042
                    ],
                    [
                        98.23392955513009,
                        236.56005520728655
                    ],
                    [
                        98.90842022205402,
                        237.6594799823476
                    ],
                    [
                        102.23590751221059,
                        238.34084003234307
                    ],
                    [
                        102.1909414677491,
                        241.48109665143312
                    ],
                    [
                        100.59464688936305,
                        244.11276309232738
                    ],
                    [
                        98.61614093305366,
                        244.04701025164655
                    ],
                    [
                        98.88593719982327,
                        246.80692619759918
                    ],
                    [
                        98.34634466628427,
                        247.11336886522827
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Jhabua",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "North 24 Parganas",
            "dt_code": "337",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        308.78743274646376,
                        253.97529227378982
                    ],
                    [
                        305.1002171006144,
                        250.7873587857751
                    ],
                    [
                        305.370013367384,
                        250.10987980142187
                    ],
                    [
                        303.6163376333825,
                        249.1042059785869
                    ],
                    [
                        302.4472204773815,
                        246.7850357981289
                    ],
                    [
                        301.52541656591916,
                        246.0844275949662
                    ],
                    [
                        300.94085798791866,
                        247.06959396039434
                    ],
                    [
                        299.36704643176336,
                        247.15714289738588
                    ],
                    [
                        298.53517460922444,
                        245.95303861146573
                    ],
                    [
                        298.42275949807026,
                        245.6026294227811
                    ],
                    [
                        298.467725542532,
                        243.3455230176241
                    ],
                    [
                        299.2546313206094,
                        240.998277720513
                    ],
                    [
                        302.2673562995351,
                        241.56887033362534
                    ],
                    [
                        302.8968809219973,
                        241.37137454117885
                    ],
                    [
                        303.3915074110746,
                        239.6809498801141
                    ],
                    [
                        303.18916021099744,
                        238.5825609492851
                    ],
                    [
                        303.7062697223057,
                        236.38409477212406
                    ],
                    [
                        305.1901491895376,
                        236.01013069452756
                    ],
                    [
                        307.1236891013855,
                        236.80197714747953
                    ],
                    [
                        305.887122878692,
                        240.6031638039509
                    ],
                    [
                        307.5283835015398,
                        242.57801210056658
                    ],
                    [
                        306.69651167900065,
                        243.95933670963234
                    ],
                    [
                        307.4384514126166,
                        244.98927818698422
                    ],
                    [
                        306.8988588790776,
                        245.66833542069753
                    ],
                    [
                        307.97804394615537,
                        249.1916740494974
                    ],
                    [
                        307.91059487946313,
                        251.4427865753343
                    ],
                    [
                        308.94481390207943,
                        252.70939912806188
                    ],
                    [
                        308.78743274646376,
                        253.97529227378982
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-North 24 Parganas",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dindori",
            "dt_code": "453",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        197.3390915484456,
                        248.22933445700005
                    ],
                    [
                        195.18072141429002,
                        247.6604815874491
                    ],
                    [
                        194.01160425828903,
                        248.7105567512076
                    ],
                    [
                        193.20221545798063,
                        248.2074582187015
                    ],
                    [
                        192.1230303909026,
                        246.56611978734105
                    ],
                    [
                        190.54921883474753,
                        246.60990473769982
                    ],
                    [
                        188.4133317228227,
                        243.95933670963234
                    ],
                    [
                        188.45829776728442,
                        242.51221282437
                    ],
                    [
                        186.3898597220516,
                        243.1043345200577
                    ],
                    [
                        183.35465172089516,
                        242.13931254896443
                    ],
                    [
                        183.96169332112663,
                        240.18602064442905
                    ],
                    [
                        183.44458380981837,
                        238.75834089210173
                    ],
                    [
                        183.06237243189503,
                        236.84596001324343
                    ],
                    [
                        184.20900656566505,
                        235.54808454907237
                    ],
                    [
                        184.11907447674184,
                        234.46958693501824
                    ],
                    [
                        185.96268229966654,
                        234.09528645551427
                    ],
                    [
                        186.6821390110522,
                        234.8218095297479
                    ],
                    [
                        186.32241065535936,
                        235.92212962254501
                    ],
                    [
                        188.368365678361,
                        236.36209869843674
                    ],
                    [
                        189.20023750090013,
                        235.54808454907237
                    ],
                    [
                        191.35860763505593,
                        236.9779031826256
                    ],
                    [
                        192.21296247982582,
                        236.7360011521351
                    ],
                    [
                        194.23643448059693,
                        238.95607613997976
                    ],
                    [
                        193.3595966135963,
                        240.0323159844773
                    ],
                    [
                        193.29214754690383,
                        241.48109665143312
                    ],
                    [
                        196.66460088152212,
                        242.7096046676818
                    ],
                    [
                        197.0243292372145,
                        243.76205833229943
                    ],
                    [
                        199.09276728244708,
                        244.02509219700977
                    ],
                    [
                        200.53168070521747,
                        243.5866847626076
                    ],
                    [
                        201.1162392832182,
                        245.25216408295194
                    ],
                    [
                        201.0712732387567,
                        245.3616905377184
                    ],
                    [
                        199.90215608275548,
                        246.23770477966417
                    ],
                    [
                        199.49746168260117,
                        247.46353669894637
                    ],
                    [
                        197.3390915484456,
                        248.22933445700005
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Dindori",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hooghly",
            "dt_code": "338",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        291.11577727306417,
                        246.54422698428078
                    ],
                    [
                        290.53121869506367,
                        246.23770477966417
                    ],
                    [
                        289.47451665021663,
                        243.6524515075816
                    ],
                    [
                        287.83325602736886,
                        243.08240696564593
                    ],
                    [
                        286.0795802933676,
                        241.72246571256994
                    ],
                    [
                        286.259444471214,
                        241.23970066169045
                    ],
                    [
                        287.8557390495996,
                        241.70052418280056
                    ],
                    [
                        288.0131202052153,
                        240.38362482576207
                    ],
                    [
                        289.04733922783157,
                        239.9225202314821
                    ],
                    [
                        289.4969996724476,
                        240.64706892019518
                    ],
                    [
                        290.913430072987,
                        241.10801876359346
                    ],
                    [
                        292.3973095402191,
                        240.998277720513
                    ],
                    [
                        294.0160871408359,
                        240.25189071632616
                    ],
                    [
                        295.4325175413753,
                        238.8462254705721
                    ],
                    [
                        295.7697628748374,
                        237.59353041837363
                    ],
                    [
                        298.58014065368593,
                        238.2089807853951
                    ],
                    [
                        299.36704643176336,
                        236.7360011521351
                    ],
                    [
                        300.6485786989185,
                        238.40676661794174
                    ],
                    [
                        299.2995973650711,
                        240.25189071632616
                    ],
                    [
                        299.2546313206094,
                        240.998277720513
                    ],
                    [
                        298.467725542532,
                        243.3455230176241
                    ],
                    [
                        298.42275949807026,
                        245.6026294227811
                    ],
                    [
                        296.1744572749917,
                        245.47121150429803
                    ],
                    [
                        293.1617322960658,
                        244.81400333947983
                    ],
                    [
                        291.11577727306417,
                        246.54422698428078
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Hooghly",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Khunti",
            "dt_code": "365",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        248.33058596787373,
                        240.054274463956
                    ],
                    [
                        249.9718465907215,
                        238.1650259023504
                    ],
                    [
                        249.85943147956732,
                        236.9779031826256
                    ],
                    [
                        250.4664730797988,
                        236.20811983777628
                    ],
                    [
                        252.5124281028004,
                        235.8121231768746
                    ],
                    [
                        252.42249601387743,
                        236.75799337683276
                    ],
                    [
                        254.82817939257166,
                        236.99989291946753
                    ],
                    [
                        256.26709281534227,
                        238.09909188912565
                    ],
                    [
                        258.20063272718994,
                        238.2529347679834
                    ],
                    [
                        258.8076743274212,
                        240.0103572813149
                    ],
                    [
                        257.2338627712659,
                        241.19580758679763
                    ],
                    [
                        257.72848926034317,
                        241.7663481050401
                    ],
                    [
                        256.76171930441956,
                        243.17011585565933
                    ],
                    [
                        254.1761717478787,
                        241.96380786791855
                    ],
                    [
                        252.82719041403152,
                        242.73153598653147
                    ],
                    [
                        251.47820908018412,
                        242.77539795952464
                    ],
                    [
                        250.2416428574909,
                        246.98204153215642
                    ],
                    [
                        249.76949939064434,
                        245.16453896621522
                    ],
                    [
                        249.8819145017983,
                        244.02509219700977
                    ],
                    [
                        248.87017850141274,
                        243.52091602946268
                    ],
                    [
                        248.96011059033594,
                        241.37137454117885
                    ],
                    [
                        248.33058596787373,
                        240.054274463956
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Khunti",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Narsinghpur",
            "dt_code": "452",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        170.2020837158841,
                        240.88853110423526
                    ],
                    [
                        169.43766096003742,
                        242.75346708380675
                    ],
                    [
                        168.1336456706515,
                        244.3976691426967
                    ],
                    [
                        167.009494559112,
                        244.5291516848281
                    ],
                    [
                        165.77292833641877,
                        246.21580869567185
                    ],
                    [
                        164.53636211372532,
                        244.57297743729498
                    ],
                    [
                        164.4913960692636,
                        243.23589520016924
                    ],
                    [
                        161.56860317926134,
                        243.93741777262716
                    ],
                    [
                        161.11894273464554,
                        245.77784103166402
                    ],
                    [
                        159.7474783785674,
                        245.31788061451334
                    ],
                    [
                        156.89213455525714,
                        245.75594034794761
                    ],
                    [
                        156.39750806617985,
                        244.79209299393227
                    ],
                    [
                        156.53240619956478,
                        243.89357923669525
                    ],
                    [
                        154.7337644211018,
                        242.2928674876697
                    ],
                    [
                        154.6438323321786,
                        240.80072979706927
                    ],
                    [
                        152.82270753148464,
                        240.36166969947817
                    ],
                    [
                        153.15995286494626,
                        240.0103572813149
                    ],
                    [
                        157.00454966641132,
                        239.37345750977738
                    ],
                    [
                        157.83642148895046,
                        238.2089807853951
                    ],
                    [
                        158.15118380018157,
                        236.3181058713435
                    ],
                    [
                        162.31054291287728,
                        236.9779031826256
                    ],
                    [
                        163.74945633564766,
                        236.5160664573093
                    ],
                    [
                        165.45816602518767,
                        236.38409477212406
                    ],
                    [
                        166.4249359811115,
                        237.4396402057041
                    ],
                    [
                        166.04272460318816,
                        238.38479131439072
                    ],
                    [
                        167.68398522603593,
                        238.51663976166117
                    ],
                    [
                        168.51585704857507,
                        237.81335440498498
                    ],
                    [
                        169.887321404653,
                        238.51663976166117
                    ],
                    [
                        170.2020837158841,
                        240.88853110423526
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Narsinghpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dhar",
            "dt_code": "438",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        94.81651017605054,
                        255.28408333778236
                    ],
                    [
                        94.36684973143474,
                        254.21529456779535
                    ],
                    [
                        95.17623853174314,
                        252.25088128539875
                    ],
                    [
                        96.97488031020612,
                        251.6612195172429
                    ],
                    [
                        97.60440493266833,
                        250.15359437738522
                    ],
                    [
                        98.34634466628427,
                        247.11336886522827
                    ],
                    [
                        98.88593719982327,
                        246.80692619759918
                    ],
                    [
                        98.61614093305366,
                        244.04701025164655
                    ],
                    [
                        100.59464688936305,
                        244.11276309232738
                    ],
                    [
                        102.1909414677491,
                        241.48109665143312
                    ],
                    [
                        102.23590751221059,
                        238.34084003234307
                    ],
                    [
                        103.74227000167343,
                        238.2529347679834
                    ],
                    [
                        105.63084386905985,
                        238.71439725483896
                    ],
                    [
                        105.69829293575208,
                        237.92325794377348
                    ],
                    [
                        106.8449270695221,
                        238.36281578586357
                    ],
                    [
                        107.06975729183023,
                        239.46131695466835
                    ],
                    [
                        106.64257986944517,
                        241.17386071519962
                    ],
                    [
                        107.60934982536924,
                        242.18318649851943
                    ],
                    [
                        108.62108582575479,
                        242.20512314028542
                    ],
                    [
                        108.59860280352359,
                        243.69629489991922
                    ],
                    [
                        109.36302555937073,
                        243.8716596377343
                    ],
                    [
                        109.92510111514025,
                        245.99683581539176
                    ],
                    [
                        111.76870893806495,
                        245.86544157465636
                    ],
                    [
                        112.37575053829619,
                        246.54422698428078
                    ],
                    [
                        110.32979551529456,
                        247.8136487507927
                    ],
                    [
                        110.39724458198702,
                        250.02244805911346
                    ],
                    [
                        109.76771995952481,
                        250.83105999601986
                    ],
                    [
                        110.01503320406346,
                        252.29455371884785
                    ],
                    [
                        109.36302555937073,
                        253.56068217231507
                    ],
                    [
                        106.79996102506084,
                        253.88801232699302
                    ],
                    [
                        104.91138715767443,
                        254.3461940608674
                    ],
                    [
                        98.88593719982327,
                        254.7606586470406
                    ],
                    [
                        97.42454075482192,
                        255.43672587312636
                    ],
                    [
                        95.71583106528215,
                        255.6329653151194
                    ],
                    [
                        94.81651017605054,
                        255.28408333778236
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Dhar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jashpur",
            "dt_code": "402",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        234.63842542932343,
                        247.61671758132377
                    ],
                    [
                        235.1555349406317,
                        248.55745196428205
                    ],
                    [
                        234.6159424070927,
                        250.10987980142187
                    ],
                    [
                        232.4575722729371,
                        250.54698672957738
                    ],
                    [
                        231.5582513837055,
                        251.33356204939224
                    ],
                    [
                        230.61396445001242,
                        250.00058958377485
                    ],
                    [
                        229.30994916062673,
                        249.78199295101012
                    ],
                    [
                        228.23076409354894,
                        248.7105567512076
                    ],
                    [
                        225.6452165370083,
                        247.20091605700287
                    ],
                    [
                        225.780114670393,
                        245.82164174150097
                    ],
                    [
                        229.0176698716266,
                        244.6825379663032
                    ],
                    [
                        229.26498311616524,
                        242.86311924716665
                    ],
                    [
                        228.5230433825493,
                        242.49027928883646
                    ],
                    [
                        227.17406204870213,
                        240.86658111200734
                    ],
                    [
                        227.82606969339463,
                        239.59309939840972
                    ],
                    [
                        227.10661298200966,
                        239.04395263390592
                    ],
                    [
                        227.91600178231784,
                        237.37368387198012
                    ],
                    [
                        228.95022080493413,
                        236.58204924264965
                    ],
                    [
                        231.40087022809007,
                        236.07612911658657
                    ],
                    [
                        233.37937618439946,
                        237.39566954230253
                    ],
                    [
                        235.020636807247,
                        237.35169797588736
                    ],
                    [
                        235.40284818517057,
                        238.27491142164945
                    ],
                    [
                        237.0890748524796,
                        240.405579728637
                    ],
                    [
                        239.96690169802037,
                        240.4275344081202
                    ],
                    [
                        240.30414703148222,
                        241.23970066169045
                    ],
                    [
                        239.22496196440443,
                        242.46834553154054
                    ],
                    [
                        238.77530151978885,
                        243.71821626484558
                    ],
                    [
                        237.96591271948046,
                        245.01118655356788
                    ],
                    [
                        236.70686347455626,
                        245.88734116252078
                    ],
                    [
                        235.6951274741707,
                        245.86544157465636
                    ],
                    [
                        234.6159424070927,
                        246.8944856115864
                    ],
                    [
                        234.63842542932343,
                        247.61671758132377
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Jashpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Indore",
            "dt_code": "439",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        118.06395516268594,
                        247.46353669894637
                    ],
                    [
                        116.75993987330003,
                        249.43219338137033
                    ],
                    [
                        114.3992225390673,
                        249.51964846411897
                    ],
                    [
                        113.32003747198928,
                        250.2410209396034
                    ],
                    [
                        111.18415036006468,
                        250.63439776607493
                    ],
                    [
                        110.39724458198702,
                        250.02244805911346
                    ],
                    [
                        110.32979551529456,
                        247.8136487507927
                    ],
                    [
                        112.37575053829619,
                        246.54422698428078
                    ],
                    [
                        111.76870893806495,
                        245.86544157465636
                    ],
                    [
                        109.92510111514025,
                        245.99683581539176
                    ],
                    [
                        109.36302555937073,
                        243.8716596377343
                    ],
                    [
                        108.59860280352359,
                        243.69629489991922
                    ],
                    [
                        108.62108582575479,
                        242.20512314028542
                    ],
                    [
                        109.11571231483185,
                        240.31775877661568
                    ],
                    [
                        110.46469364867926,
                        238.71439725483896
                    ],
                    [
                        111.34153151567989,
                        238.7803123737002
                    ],
                    [
                        112.01602218260359,
                        239.8566400945839
                    ],
                    [
                        115.00626413929854,
                        239.46131695466835
                    ],
                    [
                        115.34350947276039,
                        240.0323159844773
                    ],
                    [
                        117.18711729568508,
                        242.33673832979423
                    ],
                    [
                        118.35623445168585,
                        244.85782337073329
                    ],
                    [
                        119.6377667188408,
                        245.0330947003519
                    ],
                    [
                        120.46963854137994,
                        245.77784103166402
                    ],
                    [
                        118.60354769622472,
                        246.39097124082798
                    ],
                    [
                        118.06395516268594,
                        247.46353669894637
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Indore",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Saraikela-Kharsawan",
            "dt_code": "369",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        264.8556073075033,
                        247.15714289738588
                    ],
                    [
                        263.3267617958097,
                        248.29496186813037
                    ],
                    [
                        262.40495788434737,
                        247.6604815874491
                    ],
                    [
                        262.6073050844245,
                        246.39097124082798
                    ],
                    [
                        261.88784837303933,
                        244.6825379663032
                    ],
                    [
                        259.8194103278065,
                        245.12072508983152
                    ],
                    [
                        259.4821649943449,
                        243.17011585565933
                    ],
                    [
                        258.67277619403626,
                        242.46834553154054
                    ],
                    [
                        256.76171930441956,
                        243.17011585565933
                    ],
                    [
                        257.72848926034317,
                        241.7663481050401
                    ],
                    [
                        259.88685939449897,
                        240.976328843182
                    ],
                    [
                        261.77543326188515,
                        241.6785824306524
                    ],
                    [
                        261.07845957273094,
                        238.86819605361245
                    ],
                    [
                        262.18012766203947,
                        237.41765498687136
                    ],
                    [
                        264.5183619740412,
                        237.76939141161546
                    ],
                    [
                        267.41867184181297,
                        240.14210614537546
                    ],
                    [
                        266.63176606373554,
                        240.93243041966747
                    ],
                    [
                        266.789147219351,
                        242.9946945348205
                    ],
                    [
                        266.2045886413507,
                        244.04701025164655
                    ],
                    [
                        266.0696905079658,
                        245.97493732295436
                    ],
                    [
                        264.8556073075033,
                        247.15714289738588
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Saraikela-Kharsawan",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mandla",
            "dt_code": "454",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        177.12685456296708,
                        250.67810199155736
                    ],
                    [
                        176.3849148293507,
                        248.75429902338698
                    ],
                    [
                        176.18256762927376,
                        246.34718191725125
                    ],
                    [
                        177.9137603410445,
                        246.47854726337175
                    ],
                    [
                        179.91474931958487,
                        244.4634114047775
                    ],
                    [
                        179.77985118619995,
                        243.54283916144743
                    ],
                    [
                        176.60974505165882,
                        243.89357923669525
                    ],
                    [
                        175.12586558442672,
                        242.44641155246512
                    ],
                    [
                        175.73290718465796,
                        240.4275344081202
                    ],
                    [
                        175.55304300681155,
                        239.63702508724907
                    ],
                    [
                        179.3077077193534,
                        237.6594799823476
                    ],
                    [
                        180.09461349743083,
                        238.51663976166117
                    ],
                    [
                        180.94896834220094,
                        237.85731649665445
                    ],
                    [
                        182.41036478720207,
                        237.81335440498498
                    ],
                    [
                        183.44458380981837,
                        238.75834089210173
                    ],
                    [
                        183.96169332112663,
                        240.18602064442905
                    ],
                    [
                        183.35465172089516,
                        242.13931254896443
                    ],
                    [
                        186.3898597220516,
                        243.1043345200577
                    ],
                    [
                        188.45829776728442,
                        242.51221282437
                    ],
                    [
                        188.4133317228227,
                        243.95933670963234
                    ],
                    [
                        190.54921883474753,
                        246.60990473769982
                    ],
                    [
                        192.1230303909026,
                        246.56611978734105
                    ],
                    [
                        193.20221545798063,
                        248.2074582187015
                    ],
                    [
                        191.98813225751792,
                        249.08233841932707
                    ],
                    [
                        192.10054736867187,
                        251.3772525043865
                    ],
                    [
                        191.02136230159408,
                        252.1853710286345
                    ],
                    [
                        190.45928674582433,
                        251.508318712833
                    ],
                    [
                        189.11030541197692,
                        251.57384891733625
                    ],
                    [
                        188.39084870059196,
                        249.9787308924984
                    ],
                    [
                        186.00764834412826,
                        251.7049035291709
                    ],
                    [
                        186.030131366359,
                        252.79672516252657
                    ],
                    [
                        184.1865235434343,
                        252.40373105473668
                    ],
                    [
                        181.53352692020144,
                        251.18063868631214
                    ],
                    [
                        180.58923998650835,
                        251.26802475502126
                    ],
                    [
                        177.12685456296708,
                        250.67810199155736
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Mandla",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "East Singhbhum",
            "dt_code": "357",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        274.56827291120385,
                        252.57840366039642
                    ],
                    [
                        272.0276913991247,
                        251.26802475502126
                    ],
                    [
                        271.1733365543548,
                        250.50327991829283
                    ],
                    [
                        270.1840835762,
                        250.9840074508607
                    ],
                    [
                        269.1498645535837,
                        250.45957224480526
                    ],
                    [
                        268.02571344204466,
                        248.88552063486793
                    ],
                    [
                        265.62003006335,
                        248.27308628169772
                    ],
                    [
                        264.8556073075033,
                        247.15714289738588
                    ],
                    [
                        266.0696905079658,
                        245.97493732295436
                    ],
                    [
                        266.2045886413507,
                        244.04701025164655
                    ],
                    [
                        266.789147219351,
                        242.9946945348205
                    ],
                    [
                        266.63176606373554,
                        240.93243041966747
                    ],
                    [
                        267.41867184181297,
                        240.14210614537546
                    ],
                    [
                        271.2407856210473,
                        240.18602064442905
                    ],
                    [
                        270.2740156651232,
                        241.2616468650195
                    ],
                    [
                        270.02670242058457,
                        243.43322129176278
                    ],
                    [
                        272.09514046581717,
                        244.66062630068006
                    ],
                    [
                        273.30922366627965,
                        245.55882432749638
                    ],
                    [
                        273.5565369108185,
                        246.85070634133768
                    ],
                    [
                        275.1303484669734,
                        246.85070634133768
                    ],
                    [
                        275.64745797828164,
                        248.25121047799308
                    ],
                    [
                        275.10786544474263,
                        249.23540678551217
                    ],
                    [
                        276.2994656229746,
                        249.65082459930676
                    ],
                    [
                        276.1196014451282,
                        250.83105999601986
                    ],
                    [
                        274.56827291120385,
                        252.57840366039642
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-East Singhbhum",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hoshangabad",
            "dt_code": "449",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        156.39750806617985,
                        244.79209299393227
                    ],
                    [
                        155.25087393240983,
                        245.6902369814017
                    ],
                    [
                        153.9243756207934,
                        245.42740377611239
                    ],
                    [
                        153.56464726510058,
                        246.5880123718086
                    ],
                    [
                        154.3065869987165,
                        247.48542033570135
                    ],
                    [
                        154.48645117656292,
                        249.34473483659679
                    ],
                    [
                        153.15995286494626,
                        250.04430631853046
                    ],
                    [
                        151.8559375755608,
                        249.32286965933548
                    ],
                    [
                        150.21467695271303,
                        249.628962450727
                    ],
                    [
                        149.00059375225078,
                        249.6726865316786
                    ],
                    [
                        146.72980850694103,
                        250.76550785766307
                    ],
                    [
                        144.12177792816942,
                        250.15359437738522
                    ],
                    [
                        141.60367943832125,
                        250.8966101966913
                    ],
                    [
                        139.6026904597809,
                        250.4158637089795
                    ],
                    [
                        138.20874308147222,
                        252.55657033370727
                    ],
                    [
                        137.28693917000987,
                        252.4692348870663
                    ],
                    [
                        135.69064459162382,
                        248.64494171589382
                    ],
                    [
                        134.79132370239222,
                        246.9601528794622
                    ],
                    [
                        136.43258432523976,
                        247.04770618068176
                    ],
                    [
                        138.3886072593184,
                        245.71213832283337
                    ],
                    [
                        140.0073848599352,
                        245.25216408295194
                    ],
                    [
                        140.70435854908965,
                        244.17851394852357
                    ],
                    [
                        142.45803428309114,
                        243.9154986149871
                    ],
                    [
                        144.0093628170157,
                        242.11737524114102
                    ],
                    [
                        146.66235944024857,
                        243.23589520016924
                    ],
                    [
                        149.4277711746356,
                        241.7882889677751
                    ],
                    [
                        150.9566166863292,
                        241.415264053069
                    ],
                    [
                        152.82270753148464,
                        240.36166969947817
                    ],
                    [
                        154.6438323321786,
                        240.80072979706927
                    ],
                    [
                        154.7337644211018,
                        242.2928674876697
                    ],
                    [
                        156.53240619956478,
                        243.89357923669525
                    ],
                    [
                        156.39750806617985,
                        244.79209299393227
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Hoshangabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Korba",
            "dt_code": "404",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        218.83286080107928,
                        254.06256881444784
                    ],
                    [
                        217.73119271177075,
                        253.27695723763173
                    ],
                    [
                        214.89833191069147,
                        254.71703440665135
                    ],
                    [
                        214.83088284399923,
                        253.015025285916
                    ],
                    [
                        213.79666382138294,
                        251.83595041446563
                    ],
                    [
                        211.79567484284257,
                        252.6002367731303
                    ],
                    [
                        210.2218632866875,
                        250.80920949855505
                    ],
                    [
                        208.06349315253192,
                        251.0495531320318
                    ],
                    [
                        207.7487308413008,
                        249.49778501793776
                    ],
                    [
                        207.09672319660785,
                        248.77616983411832
                    ],
                    [
                        207.36651946337724,
                        247.35411524636334
                    ],
                    [
                        208.58060266383995,
                        245.6245316414043
                    ],
                    [
                        207.6138327079159,
                        245.33978568588805
                    ],
                    [
                        207.02927412991562,
                        242.42447735159266
                    ],
                    [
                        208.24335733037788,
                        240.976328843182
                    ],
                    [
                        208.8728819528401,
                        241.08607100073027
                    ],
                    [
                        210.71648977576479,
                        240.09819075193013
                    ],
                    [
                        211.75070879838108,
                        240.62511647368285
                    ],
                    [
                        213.48190151015183,
                        240.71292492058484
                    ],
                    [
                        213.27955431007445,
                        242.38060828438967
                    ],
                    [
                        214.62853564392208,
                        243.12626185318572
                    ],
                    [
                        216.20234720007716,
                        243.32359789639304
                    ],
                    [
                        217.91105688961693,
                        244.13468026486828
                    ],
                    [
                        219.957011912619,
                        245.84354176765532
                    ],
                    [
                        221.3959253353894,
                        245.33978568588805
                    ],
                    [
                        221.03619697969657,
                        248.53557898379137
                    ],
                    [
                        221.44089137985088,
                        249.30100426561302
                    ],
                    [
                        220.94626489077336,
                        251.0714012621392
                    ],
                    [
                        220.20432515715743,
                        252.2072079951239
                    ],
                    [
                        220.20432515715743,
                        253.32060957427115
                    ],
                    [
                        218.83286080107928,
                        254.06256881444784
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Korba",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "West Singhbhum",
            "dt_code": "368",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        263.3267617958097,
                        248.29496186813037
                    ],
                    [
                        264.1361505961179,
                        249.23540678551217
                    ],
                    [
                        264.29353175173355,
                        250.69995378116596
                    ],
                    [
                        263.48414295142516,
                        252.0761829820002
                    ],
                    [
                        264.33849779619527,
                        252.99319623514305
                    ],
                    [
                        264.0012524627334,
                        254.17165970201722
                    ],
                    [
                        262.35999183988565,
                        256.2651758670818
                    ],
                    [
                        260.69624819480737,
                        256.24337846140685
                    ],
                    [
                        260.17913868349933,
                        254.82609341554814
                    ],
                    [
                        259.2123687275755,
                        255.0878133931747
                    ],
                    [
                        255.00804357041784,
                        253.4079116870576
                    ],
                    [
                        253.20940179195486,
                        254.69522196799147
                    ],
                    [
                        252.66980925841608,
                        255.8509890366209
                    ],
                    [
                        251.81545441364574,
                        254.84790458056392
                    ],
                    [
                        249.58963521279793,
                        254.06256881444784
                    ],
                    [
                        250.30909192418312,
                        251.92331071393085
                    ],
                    [
                        250.19667681302917,
                        248.2074582187015
                    ],
                    [
                        250.2416428574909,
                        246.98204153215642
                    ],
                    [
                        251.47820908018412,
                        242.77539795952464
                    ],
                    [
                        252.82719041403152,
                        242.73153598653147
                    ],
                    [
                        254.1761717478787,
                        241.96380786791855
                    ],
                    [
                        256.76171930441956,
                        243.17011585565933
                    ],
                    [
                        258.67277619403626,
                        242.46834553154054
                    ],
                    [
                        259.4821649943449,
                        243.17011585565933
                    ],
                    [
                        259.8194103278065,
                        245.12072508983152
                    ],
                    [
                        261.88784837303933,
                        244.6825379663032
                    ],
                    [
                        262.6073050844245,
                        246.39097124082798
                    ],
                    [
                        262.40495788434737,
                        247.6604815874491
                    ],
                    [
                        263.3267617958097,
                        248.29496186813037
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-West Singhbhum",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Howrah",
            "dt_code": "341",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        296.7814988752227,
                        247.35411524636334
                    ],
                    [
                        295.7697628748374,
                        248.33871238924826
                    ],
                    [
                        294.6006457188362,
                        250.2410209396034
                    ],
                    [
                        294.48823060768245,
                        251.8577908110779
                    ],
                    [
                        293.251664384989,
                        252.11985884355863
                    ],
                    [
                        292.5771737180653,
                        251.61753464667612
                    ],
                    [
                        292.3523434957574,
                        249.30100426561302
                    ],
                    [
                        291.70033585106466,
                        247.7480069868888
                    ],
                    [
                        291.11577727306417,
                        246.54422698428078
                    ],
                    [
                        293.1617322960658,
                        244.81400333947983
                    ],
                    [
                        296.1744572749917,
                        245.47121150429803
                    ],
                    [
                        298.42275949807026,
                        245.6026294227811
                    ],
                    [
                        298.53517460922444,
                        245.95303861146573
                    ],
                    [
                        297.99558207568543,
                        247.22280230965094
                    ],
                    [
                        296.7814988752227,
                        247.35411524636334
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Howrah",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Simdega",
            "dt_code": "367",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        250.19667681302917,
                        248.2074582187015
                    ],
                    [
                        248.55541619018186,
                        248.9292594374598
                    ],
                    [
                        246.37456303379508,
                        248.79804042796673
                    ],
                    [
                        245.49772516679445,
                        249.34473483659679
                    ],
                    [
                        242.3276190322531,
                        249.21354052577732
                    ],
                    [
                        240.776290498329,
                        250.4158637089795
                    ],
                    [
                        238.75281849755788,
                        250.56883981193528
                    ],
                    [
                        237.358871119249,
                        250.00058958377485
                    ],
                    [
                        236.0998218743248,
                        248.4262108249671
                    ],
                    [
                        234.63842542932343,
                        247.61671758132377
                    ],
                    [
                        234.6159424070927,
                        246.8944856115864
                    ],
                    [
                        235.6951274741707,
                        245.86544157465636
                    ],
                    [
                        236.70686347455626,
                        245.88734116252078
                    ],
                    [
                        237.96591271948046,
                        245.01118655356788
                    ],
                    [
                        238.77530151978885,
                        243.71821626484558
                    ],
                    [
                        240.57394329825183,
                        243.84973981808764
                    ],
                    [
                        241.06856978732912,
                        242.7096046676818
                    ],
                    [
                        242.64238134348443,
                        243.47706910259916
                    ],
                    [
                        242.66486436571495,
                        244.353839866621
                    ],
                    [
                        244.68833636648606,
                        244.22234675038993
                    ],
                    [
                        245.2504119222558,
                        243.2578212059542
                    ],
                    [
                        247.07153672294976,
                        244.55106467114013
                    ],
                    [
                        248.87017850141274,
                        243.52091602946268
                    ],
                    [
                        249.8819145017983,
                        244.02509219700977
                    ],
                    [
                        249.76949939064434,
                        245.16453896621522
                    ],
                    [
                        250.2416428574909,
                        246.98204153215642
                    ],
                    [
                        250.19667681302917,
                        248.2074582187015
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Simdega",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kolkata",
            "dt_code": "342",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        299.36704643176336,
                        247.15714289738588
                    ],
                    [
                        298.3103443869163,
                        247.98868388018843
                    ],
                    [
                        296.7814988752227,
                        247.35411524636334
                    ],
                    [
                        297.99558207568543,
                        247.22280230965094
                    ],
                    [
                        298.53517460922444,
                        245.95303861146573
                    ],
                    [
                        299.36704643176336,
                        247.15714289738588
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Kolkata",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Koderma",
            "dt_code": "348",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        254.67079823695622,
                        215.1165508941275
                    ],
                    [
                        257.1439306823429,
                        215.4720723871876
                    ],
                    [
                        259.1224366386523,
                        214.51646692505085
                    ],
                    [
                        258.9650554830366,
                        213.3824891955212
                    ],
                    [
                        260.0217575278839,
                        210.82313816905747
                    ],
                    [
                        262.4274409065781,
                        211.69145683424563
                    ],
                    [
                        261.1908746838849,
                        212.3813914477143
                    ],
                    [
                        260.5388670391919,
                        214.20524224262357
                    ],
                    [
                        261.9328144175008,
                        215.56094302507728
                    ],
                    [
                        261.6630181507312,
                        219.64480405838214
                    ],
                    [
                        258.60532712734425,
                        218.71333787437902
                    ],
                    [
                        256.76171930441956,
                        218.93515399545913
                    ],
                    [
                        255.00804357041784,
                        218.22525766484478
                    ],
                    [
                        255.23287379272597,
                        217.4929185180083
                    ],
                    [
                        254.041273614494,
                        215.8275315924002
                    ],
                    [
                        254.67079823695622,
                        215.1165508941275
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Koderma",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hazaribagh",
            "dt_code": "360",
            "st_nm": "Jharkhand",
            "st_code": "20",
            "alpha2code": "JH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        250.4664730797988,
                        218.09212463847297
                    ],
                    [
                        253.41174899203202,
                        215.62759344941736
                    ],
                    [
                        254.041273614494,
                        215.8275315924002
                    ],
                    [
                        255.23287379272597,
                        217.4929185180083
                    ],
                    [
                        255.00804357041784,
                        218.22525766484478
                    ],
                    [
                        256.76171930441956,
                        218.93515399545913
                    ],
                    [
                        258.60532712734425,
                        218.71333787437902
                    ],
                    [
                        261.6630181507312,
                        219.64480405838214
                    ],
                    [
                        261.6180521062697,
                        220.53152122012204
                    ],
                    [
                        259.1898857053445,
                        221.21846371824415
                    ],
                    [
                        259.6170631277296,
                        222.41452584882916
                    ],
                    [
                        261.4606709506543,
                        222.9016115585212
                    ],
                    [
                        262.6073050844245,
                        224.87092452690558
                    ],
                    [
                        261.4381879284233,
                        226.61740183095816
                    ],
                    [
                        259.9992745056529,
                        224.84880780945014
                    ],
                    [
                        258.62781014957477,
                        224.78245624266714
                    ],
                    [
                        255.4352209928029,
                        227.36859967813757
                    ],
                    [
                        256.0647456152651,
                        227.83243980999953
                    ],
                    [
                        255.39025494834118,
                        229.13505956174623
                    ],
                    [
                        254.10872268118646,
                        228.80396184925877
                    ],
                    [
                        252.55739414726213,
                        229.13505956174623
                    ],
                    [
                        250.28660890195238,
                        229.39990009710374
                    ],
                    [
                        249.94936356849075,
                        228.69358432008153
                    ],
                    [
                        249.4772201016442,
                        225.954355289621
                    ],
                    [
                        249.52218614610547,
                        224.18519659416575
                    ],
                    [
                        252.13021672487707,
                        224.7603385822082
                    ],
                    [
                        252.82719041403152,
                        223.4992416180525
                    ],
                    [
                        251.65807325803053,
                        222.4588111186904
                    ],
                    [
                        251.52317512464583,
                        220.35420844248796
                    ],
                    [
                        253.16443574749337,
                        220.5758470221415
                    ],
                    [
                        253.9063754811093,
                        219.06823212316124
                    ],
                    [
                        252.48994508056967,
                        218.51368278815465
                    ],
                    [
                        250.64633725764497,
                        218.7577030234242
                    ],
                    [
                        250.4664730797988,
                        218.09212463847297
                    ]
                ]
            ]
        },
        "id": "TT-Jharkhand-Hazaribagh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Vaishali",
            "dt_code": "220",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        258.6502931718055,
                        192.97191204042917
                    ],
                    [
                        258.1331836604975,
                        193.98290959736352
                    ],
                    [
                        258.1556666827282,
                        196.3398502342488
                    ],
                    [
                        257.5486250824972,
                        196.69875167127742
                    ],
                    [
                        256.91910046003477,
                        199.14200814601628
                    ],
                    [
                        255.4801870372644,
                        199.8363209006764
                    ],
                    [
                        253.74899432549387,
                        199.56758391444941
                    ],
                    [
                        252.55739414726213,
                        198.13369548649183
                    ],
                    [
                        251.99531859149215,
                        196.9903102884008
                    ],
                    [
                        251.59062419133807,
                        194.252419556946
                    ],
                    [
                        250.84868445772213,
                        193.7807524496655
                    ],
                    [
                        250.17419379079843,
                        192.02783564800166
                    ],
                    [
                        251.74800534695373,
                        191.42068466863196
                    ],
                    [
                        253.34429992533978,
                        192.34257922153648
                    ],
                    [
                        255.18790774826425,
                        192.49993170747035
                    ],
                    [
                        256.6493041932656,
                        193.1741539432444
                    ],
                    [
                        258.6502931718055,
                        192.97191204042917
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Vaishali",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Begusarai",
            "dt_code": "222",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        259.9767914834222,
                        199.3660069411241
                    ],
                    [
                        261.5730860618082,
                        197.416355810887
                    ],
                    [
                        263.84387130711775,
                        196.81089477606395
                    ],
                    [
                        265.10292055204195,
                        195.77893388424832
                    ],
                    [
                        266.4743849081199,
                        196.29498289111513
                    ],
                    [
                        266.54183397481233,
                        197.08001183698883
                    ],
                    [
                        268.1830945976599,
                        197.52845755243214
                    ],
                    [
                        268.02571344204466,
                        199.074803500421
                    ],
                    [
                        269.05993246466096,
                        200.17219024978698
                    ],
                    [
                        270.1166345095078,
                        199.76914011455023
                    ],
                    [
                        271.37568375443175,
                        201.02280166729292
                    ],
                    [
                        267.7334341530443,
                        203.3489091395548
                    ],
                    [
                        266.72169815265875,
                        203.840618554123
                    ],
                    [
                        264.8780903297343,
                        202.45457779005397
                    ],
                    [
                        263.2368297068865,
                        201.17945301318048
                    ],
                    [
                        262.022746506424,
                        200.75422740180898
                    ],
                    [
                        262.2475767287317,
                        199.7915339661912
                    ],
                    [
                        260.6512821503459,
                        198.96279062005817
                    ],
                    [
                        259.9767914834222,
                        199.3660069411241
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Begusarai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhagalpur",
            "dt_code": "224",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        281.7628400250562,
                        200.48561634919048
                    ],
                    [
                        281.9427042029024,
                        201.7835625944037
                    ],
                    [
                        283.9661762036735,
                        201.24658547723982
                    ],
                    [
                        286.68662189359884,
                        202.52166672601396
                    ],
                    [
                        285.6074368265208,
                        204.73432222357246
                    ],
                    [
                        283.381617625673,
                        204.26517817618077
                    ],
                    [
                        282.90947415882624,
                        206.45356701032227
                    ],
                    [
                        281.15579842482475,
                        206.9668189460379
                    ],
                    [
                        278.7725980683613,
                        206.11876604490465
                    ],
                    [
                        275.82732215612805,
                        206.40893014236525
                    ],
                    [
                        274.0511633998956,
                        206.0294762520095
                    ],
                    [
                        274.5457898889731,
                        203.77357448215753
                    ],
                    [
                        276.479329800821,
                        201.98489119045092
                    ],
                    [
                        275.96222028951274,
                        200.6646944733265
                    ],
                    [
                        277.40113371228335,
                        199.88110681022363
                    ],
                    [
                        278.8625301572845,
                        200.39607115114092
                    ],
                    [
                        280.0541303355162,
                        199.455599272518
                    ],
                    [
                        280.0990963799777,
                        200.86613781456367
                    ],
                    [
                        281.7628400250562,
                        200.48561634919048
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Bhagalpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lakhisarai",
            "dt_code": "227",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        269.3297287314301,
                        205.5606389046763
                    ],
                    [
                        266.9690113971974,
                        207.6137739937796
                    ],
                    [
                        265.62003006335,
                        208.327411674482
                    ],
                    [
                        263.5066259736559,
                        207.94832346465262
                    ],
                    [
                        263.1468976179633,
                        206.3642922713674
                    ],
                    [
                        263.5965580625789,
                        204.9800199544856
                    ],
                    [
                        262.4274409065781,
                        203.72887716947275
                    ],
                    [
                        262.4948899732706,
                        203.2147853029761
                    ],
                    [
                        264.15863361834886,
                        203.0806523435325
                    ],
                    [
                        264.8780903297343,
                        202.45457779005397
                    ],
                    [
                        266.72169815265875,
                        203.840618554123
                    ],
                    [
                        267.7334341530443,
                        203.3489091395548
                    ],
                    [
                        269.05993246466096,
                        203.03593932905312
                    ],
                    [
                        269.3297287314301,
                        205.5606389046763
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Lakhisarai",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sheikhpura",
            "dt_code": "228",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        263.5066259736559,
                        207.94832346465262
                    ],
                    [
                        261.4381879284233,
                        208.10442733676877
                    ],
                    [
                        260.47141797249947,
                        207.76990406978166
                    ],
                    [
                        260.4264519280382,
                        206.63210445469207
                    ],
                    [
                        258.4929120161901,
                        206.31965339718022
                    ],
                    [
                        259.27981779426773,
                        205.58296700592075
                    ],
                    [
                        259.4821649943449,
                        203.61712946019736
                    ],
                    [
                        261.4381879284233,
                        204.39923068931444
                    ],
                    [
                        262.4274409065781,
                        203.72887716947275
                    ],
                    [
                        263.5965580625789,
                        204.9800199544856
                    ],
                    [
                        263.1468976179633,
                        206.3642922713674
                    ],
                    [
                        263.5066259736559,
                        207.94832346465262
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Sheikhpura",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Arwal",
            "dt_code": "240",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        241.27091698740628,
                        205.2926820703011
                    ],
                    [
                        242.1477548544069,
                        204.53327411505444
                    ],
                    [
                        243.72156641056222,
                        204.0417371158556
                    ],
                    [
                        244.86820054433247,
                        202.87943579019833
                    ],
                    [
                        246.39704605602606,
                        204.3768892349696
                    ],
                    [
                        247.07153672294976,
                        204.22049198502236
                    ],
                    [
                        246.71180836725716,
                        204.62263135230944
                    ],
                    [
                        247.0490537007188,
                        206.8998805551363
                    ],
                    [
                        245.2504119222558,
                        207.01144328844862
                    ],
                    [
                        244.53095521087062,
                        206.2750135196556
                    ],
                    [
                        241.04608676509838,
                        205.82855952832188
                    ],
                    [
                        241.27091698740628,
                        205.2926820703011
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Arwal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jehanabad",
            "dt_code": "239",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        252.13021672487707,
                        206.94450639939396
                    ],
                    [
                        251.0285486355683,
                        207.03375508425228
                    ],
                    [
                        250.48895610202953,
                        208.0152266206855
                    ],
                    [
                        247.83595947879644,
                        206.83293991104978
                    ],
                    [
                        247.0490537007188,
                        206.8998805551363
                    ],
                    [
                        246.71180836725716,
                        204.62263135230944
                    ],
                    [
                        247.07153672294976,
                        204.22049198502236
                    ],
                    [
                        248.42051805679694,
                        203.12536434391336
                    ],
                    [
                        250.28660890195238,
                        203.46067203741575
                    ],
                    [
                        251.61310721356904,
                        202.90179419953387
                    ],
                    [
                        251.59062419133807,
                        205.58296700592075
                    ],
                    [
                        252.13021672487707,
                        206.94450639939396
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Jehanabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nalanda",
            "dt_code": "229",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        258.4929120161901,
                        206.31965339718022
                    ],
                    [
                        257.21137974903536,
                        207.36840200246638
                    ],
                    [
                        254.87314543703314,
                        208.50578120323416
                    ],
                    [
                        254.31106988126362,
                        207.56916315304056
                    ],
                    [
                        252.13021672487707,
                        206.94450639939396
                    ],
                    [
                        251.59062419133807,
                        205.58296700592075
                    ],
                    [
                        251.61310721356904,
                        202.90179419953387
                    ],
                    [
                        251.792971391415,
                        201.35846781649565
                    ],
                    [
                        254.46845103687906,
                        201.3808435193884
                    ],
                    [
                        255.2553568149567,
                        200.39607115114092
                    ],
                    [
                        257.61607414918944,
                        201.04518119656638
                    ],
                    [
                        258.67277619403626,
                        202.45457779005397
                    ],
                    [
                        259.72947823888353,
                        202.02962807916236
                    ],
                    [
                        261.0559765505002,
                        203.2147853029761
                    ],
                    [
                        262.4948899732706,
                        203.2147853029761
                    ],
                    [
                        262.4274409065781,
                        203.72887716947275
                    ],
                    [
                        261.4381879284233,
                        204.39923068931444
                    ],
                    [
                        259.4821649943449,
                        203.61712946019736
                    ],
                    [
                        259.27981779426773,
                        205.58296700592075
                    ],
                    [
                        258.4929120161901,
                        206.31965339718022
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Nalanda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Patna",
            "dt_code": "230",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        256.91910046003477,
                        199.14200814601628
                    ],
                    [
                        257.16641370457387,
                        200.03784942442138
                    ],
                    [
                        258.7402252607287,
                        200.39607115114092
                    ],
                    [
                        259.9767914834222,
                        199.3660069411241
                    ],
                    [
                        260.6512821503459,
                        198.96279062005817
                    ],
                    [
                        262.2475767287317,
                        199.7915339661912
                    ],
                    [
                        262.022746506424,
                        200.75422740180898
                    ],
                    [
                        263.2368297068865,
                        201.17945301318048
                    ],
                    [
                        264.8780903297343,
                        202.45457779005397
                    ],
                    [
                        264.15863361834886,
                        203.0806523435325
                    ],
                    [
                        262.4948899732706,
                        203.2147853029761
                    ],
                    [
                        261.0559765505002,
                        203.2147853029761
                    ],
                    [
                        259.72947823888353,
                        202.02962807916236
                    ],
                    [
                        258.67277619403626,
                        202.45457779005397
                    ],
                    [
                        257.61607414918944,
                        201.04518119656638
                    ],
                    [
                        255.2553568149567,
                        200.39607115114092
                    ],
                    [
                        254.46845103687906,
                        201.3808435193884
                    ],
                    [
                        251.792971391415,
                        201.35846781649565
                    ],
                    [
                        251.61310721356904,
                        202.90179419953387
                    ],
                    [
                        250.28660890195238,
                        203.46067203741575
                    ],
                    [
                        248.42051805679694,
                        203.12536434391336
                    ],
                    [
                        247.07153672294976,
                        204.22049198502236
                    ],
                    [
                        246.39704605602606,
                        204.3768892349696
                    ],
                    [
                        244.86820054433247,
                        202.87943579019833
                    ],
                    [
                        245.92490258917928,
                        200.6199264759137
                    ],
                    [
                        245.5651742334869,
                        199.74674600661865
                    ],
                    [
                        246.50946116718,
                        198.44744859082283
                    ],
                    [
                        246.93663858956484,
                        196.51930924142306
                    ],
                    [
                        249.0950087237204,
                        196.0257570534087
                    ],
                    [
                        250.19667681302917,
                        196.3847165406956
                    ],
                    [
                        251.2758618801072,
                        197.7750586680769
                    ],
                    [
                        252.55739414726213,
                        198.13369548649183
                    ],
                    [
                        253.74899432549387,
                        199.56758391444941
                    ],
                    [
                        255.4801870372644,
                        199.8363209006764
                    ],
                    [
                        256.91910046003477,
                        199.14200814601628
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Patna",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Saran",
            "dt_code": "219",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        245.72255538910235,
                        188.17929473878593
                    ],
                    [
                        247.65609530095026,
                        188.08917763028973
                    ],
                    [
                        248.24065387895052,
                        191.21825836718608
                    ],
                    [
                        250.17419379079843,
                        192.02783564800166
                    ],
                    [
                        250.84868445772213,
                        193.7807524496655
                    ],
                    [
                        251.59062419133807,
                        194.252419556946
                    ],
                    [
                        251.99531859149215,
                        196.9903102884008
                    ],
                    [
                        252.55739414726213,
                        198.13369548649183
                    ],
                    [
                        251.2758618801072,
                        197.7750586680769
                    ],
                    [
                        250.19667681302917,
                        196.3847165406956
                    ],
                    [
                        249.0950087237204,
                        196.0257570534087
                    ],
                    [
                        246.93663858956484,
                        196.51930924142306
                    ],
                    [
                        243.7665324550237,
                        196.3847165406956
                    ],
                    [
                        243.29438898817716,
                        195.98088244922798
                    ],
                    [
                        243.65411734386976,
                        195.0158271675752
                    ],
                    [
                        241.40581512079075,
                        193.53364274333688
                    ],
                    [
                        240.48401120932863,
                        191.08329564260998
                    ],
                    [
                        242.7772794768689,
                        191.10579008898173
                    ],
                    [
                        243.09204178810023,
                        190.38583695386671
                    ],
                    [
                        244.82323449987098,
                        190.2733198525567
                    ],
                    [
                        245.72255538910235,
                        188.17929473878593
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Saran",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Siwan",
            "dt_code": "218",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        235.29043307401662,
                        189.9807444587998
                    ],
                    [
                        235.7400935186322,
                        188.02158700415328
                    ],
                    [
                        237.06659183024885,
                        187.27793186024098
                    ],
                    [
                        236.7743125412485,
                        185.451362948079
                    ],
                    [
                        238.3930901418653,
                        186.53398609649423
                    ],
                    [
                        239.80952054240515,
                        185.56416501265562
                    ],
                    [
                        244.0812947662546,
                        186.71436330884592
                    ],
                    [
                        245.72255538910235,
                        188.17929473878593
                    ],
                    [
                        244.82323449987098,
                        190.2733198525567
                    ],
                    [
                        243.09204178810023,
                        190.38583695386671
                    ],
                    [
                        242.7772794768689,
                        191.10579008898173
                    ],
                    [
                        240.48401120932863,
                        191.08329564260998
                    ],
                    [
                        241.40581512079075,
                        193.53364274333688
                    ],
                    [
                        239.67462240902023,
                        192.27513850395394
                    ],
                    [
                        238.82026756425034,
                        192.49993170747035
                    ],
                    [
                        235.29043307401662,
                        189.9807444587998
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Siwan",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Muzaffarpur",
            "dt_code": "216",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        253.43423201426276,
                        185.81230594440717
                    ],
                    [
                        254.69328125918673,
                        186.08296814235285
                    ],
                    [
                        255.95233050411093,
                        186.66927061025115
                    ],
                    [
                        256.58185512657315,
                        185.36111646471
                    ],
                    [
                        259.5271310388064,
                        186.646723859879
                    ],
                    [
                        259.8643763722682,
                        189.55305382999273
                    ],
                    [
                        259.6395461499603,
                        190.16079614347558
                    ],
                    [
                        258.47042899395933,
                        192.34257922153648
                    ],
                    [
                        258.6502931718055,
                        192.97191204042917
                    ],
                    [
                        256.6493041932656,
                        193.1741539432444
                    ],
                    [
                        255.18790774826425,
                        192.49993170747035
                    ],
                    [
                        253.34429992533978,
                        192.34257922153648
                    ],
                    [
                        251.74800534695373,
                        191.42068466863196
                    ],
                    [
                        250.17419379079843,
                        192.02783564800166
                    ],
                    [
                        248.24065387895052,
                        191.21825836718608
                    ],
                    [
                        247.65609530095026,
                        188.08917763028973
                    ],
                    [
                        247.1839518341037,
                        187.34554887198885
                    ],
                    [
                        247.7235443676425,
                        186.26338817418434
                    ],
                    [
                        249.83694845733658,
                        186.01530621072186
                    ],
                    [
                        252.1077337026461,
                        186.93981076384023
                    ],
                    [
                        253.29933388087807,
                        185.87997511227115
                    ],
                    [
                        253.38926596980127,
                        185.83486260179404
                    ],
                    [
                        253.43423201426276,
                        185.81230594440717
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Muzaffarpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Madhepura",
            "dt_code": "213",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        275.96222028951274,
                        200.6646944733265
                    ],
                    [
                        276.2095335340514,
                        197.86472406080117
                    ],
                    [
                        275.60249193382015,
                        197.5732964417191
                    ],
                    [
                        276.68167700089816,
                        196.1379390240507
                    ],
                    [
                        276.27698260074385,
                        193.7133620304553
                    ],
                    [
                        274.34344268889595,
                        193.803215400044
                    ],
                    [
                        273.35418971074114,
                        191.30822824651284
                    ],
                    [
                        275.46759380043545,
                        190.1382906085155
                    ],
                    [
                        279.9417152243625,
                        190.63335132919593
                    ],
                    [
                        279.7393680242851,
                        192.18521387056148
                    ],
                    [
                        278.4128697126687,
                        192.49993170747035
                    ],
                    [
                        279.1997754907461,
                        194.09520998230653
                    ],
                    [
                        278.68266597943807,
                        195.77893388424832
                    ],
                    [
                        278.9524622462077,
                        197.2369795936258
                    ],
                    [
                        280.0541303355162,
                        199.455599272518
                    ],
                    [
                        278.8625301572845,
                        200.39607115114092
                    ],
                    [
                        277.40113371228335,
                        199.88110681022363
                    ],
                    [
                        275.96222028951274,
                        200.6646944733265
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Madhepura",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Araria",
            "dt_code": "209",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        279.7393680242851,
                        192.18521387056148
                    ],
                    [
                        279.9417152243625,
                        190.63335132919593
                    ],
                    [
                        280.30144358005487,
                        188.69738552724783
                    ],
                    [
                        279.2222585129771,
                        186.42124164546595
                    ],
                    [
                        279.7618510465161,
                        184.9775209659755
                    ],
                    [
                        279.1997754907461,
                        182.42575991249868
                    ],
                    [
                        279.9192322021315,
                        184.2326725297292
                    ],
                    [
                        280.9534512247476,
                        184.9775209659755
                    ],
                    [
                        283.98865922590426,
                        184.93238697761956
                    ],
                    [
                        285.38260660421315,
                        184.39069518220964
                    ],
                    [
                        287.2262144271376,
                        185.36111646471
                    ],
                    [
                        288.2604334497539,
                        185.38367848827244
                    ],
                    [
                        287.7658069606766,
                        185.9476418675353
                    ],
                    [
                        288.95740713890837,
                        188.96763796238548
                    ],
                    [
                        288.64264482767726,
                        189.9357288924137
                    ],
                    [
                        287.9007050940613,
                        191.33072005772067
                    ],
                    [
                        286.19199540452155,
                        192.02783564800166
                    ],
                    [
                        285.4950217153669,
                        191.78050099094895
                    ],
                    [
                        282.5722288253646,
                        191.8479590526967
                    ],
                    [
                        280.9984172692093,
                        192.544887198543
                    ],
                    [
                        279.7393680242851,
                        192.18521387056148
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Araria",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Supaul",
            "dt_code": "208",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        273.35418971074114,
                        191.30822824651284
                    ],
                    [
                        271.6904460656631,
                        191.75801444433387
                    ],
                    [
                        270.29649868735396,
                        190.43084194475153
                    ],
                    [
                        271.4431328211242,
                        188.0441174790722
                    ],
                    [
                        271.73541211012457,
                        186.646723859879
                    ],
                    [
                        274.5008238445114,
                        184.6389898305144
                    ],
                    [
                        276.32194864520534,
                        184.36812132576443
                    ],
                    [
                        279.1997754907461,
                        182.42575991249868
                    ],
                    [
                        279.7618510465161,
                        184.9775209659755
                    ],
                    [
                        279.2222585129771,
                        186.42124164546595
                    ],
                    [
                        280.30144358005487,
                        188.69738552724783
                    ],
                    [
                        279.9417152243625,
                        190.63335132919593
                    ],
                    [
                        275.46759380043545,
                        190.1382906085155
                    ],
                    [
                        273.35418971074114,
                        191.30822824651284
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Supaul",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Madhubani",
            "dt_code": "207",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        260.4489349502687,
                        184.45841513554478
                    ],
                    [
                        261.30328979503884,
                        182.74209455890525
                    ],
                    [
                        261.16839166165414,
                        181.77032623342234
                    ],
                    [
                        261.7979162841159,
                        182.29017166189567
                    ],
                    [
                        263.281795751348,
                        180.93375156843007
                    ],
                    [
                        264.29353175173355,
                        180.6849683311683
                    ],
                    [
                        266.67673210819703,
                        181.5216537309779
                    ],
                    [
                        268.88006828681455,
                        181.65729735342507
                    ],
                    [
                        271.8478272212783,
                        182.78728088915636
                    ],
                    [
                        272.2525216214324,
                        183.46494598075537
                    ],
                    [
                        274.5008238445114,
                        184.6389898305144
                    ],
                    [
                        271.73541211012457,
                        186.646723859879
                    ],
                    [
                        271.4431328211242,
                        188.0441174790722
                    ],
                    [
                        270.29649868735396,
                        190.43084194475153
                    ],
                    [
                        269.57704197596877,
                        191.0608009326163
                    ],
                    [
                        268.7226871311989,
                        190.11578480912993
                    ],
                    [
                        268.7226871311989,
                        189.05771361222082
                    ],
                    [
                        266.0696905079658,
                        187.751200533506
                    ],
                    [
                        265.4851319299653,
                        188.4496205238513
                    ],
                    [
                        264.06870152942565,
                        187.5709215713868
                    ],
                    [
                        263.5515920181176,
                        186.30849050437809
                    ],
                    [
                        262.135161617578,
                        187.1201494915336
                    ],
                    [
                        261.16839166165414,
                        186.80454549797548
                    ],
                    [
                        261.2358407283464,
                        184.90981958013055
                    ],
                    [
                        260.4489349502687,
                        184.45841513554478
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Madhubani",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Purnia",
            "dt_code": "211",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        290.7335658951406,
                        193.39884229375588
                    ],
                    [
                        290.7785319396023,
                        195.08317220572445
                    ],
                    [
                        288.64264482767726,
                        195.73405356975752
                    ],
                    [
                        288.12553531636945,
                        196.7884666727324
                    ],
                    [
                        284.66314989282773,
                        196.92303141147397
                    ],
                    [
                        282.1225683807488,
                        196.56417140263272
                    ],
                    [
                        282.52726278090313,
                        198.76115124493015
                    ],
                    [
                        281.7628400250562,
                        200.48561634919048
                    ],
                    [
                        280.0990963799777,
                        200.86613781456367
                    ],
                    [
                        280.0541303355162,
                        199.455599272518
                    ],
                    [
                        278.9524622462077,
                        197.2369795936258
                    ],
                    [
                        278.68266597943807,
                        195.77893388424832
                    ],
                    [
                        279.1997754907461,
                        194.09520998230653
                    ],
                    [
                        278.4128697126687,
                        192.49993170747035
                    ],
                    [
                        279.7393680242851,
                        192.18521387056148
                    ],
                    [
                        280.9984172692093,
                        192.544887198543
                    ],
                    [
                        282.5722288253646,
                        191.8479590526967
                    ],
                    [
                        285.4950217153669,
                        191.78050099094895
                    ],
                    [
                        286.19199540452155,
                        192.02783564800166
                    ],
                    [
                        287.9007050940613,
                        191.33072005772067
                    ],
                    [
                        288.64264482767726,
                        189.9357288924137
                    ],
                    [
                        291.25067540644886,
                        190.07077241700466
                    ],
                    [
                        291.0708112286027,
                        190.97081945604486
                    ],
                    [
                        291.11577727306417,
                        191.12828427175089
                    ],
                    [
                        290.935913095218,
                        191.66806562720058
                    ],
                    [
                        291.02584518414096,
                        191.73552763467018
                    ],
                    [
                        290.66611682844837,
                        192.65727133490464
                    ],
                    [
                        290.7335658951406,
                        193.39884229375588
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Purnia",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Surajpur",
            "dt_code": "715",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        212.38023342084307,
                        227.5232244989087
                    ],
                    [
                        214.40370542161395,
                        226.66159743062695
                    ],
                    [
                        215.25806026638406,
                        225.35743270862667
                    ],
                    [
                        216.4046944001543,
                        226.04277369624054
                    ],
                    [
                        216.56207555576975,
                        226.99303456837112
                    ],
                    [
                        218.4281664009252,
                        227.69992456524244
                    ],
                    [
                        220.7214346684657,
                        227.76618323786454
                    ],
                    [
                        222.67745760254434,
                        229.11298800763524
                    ],
                    [
                        222.69994062477485,
                        230.56921311091634
                    ],
                    [
                        223.86905778077607,
                        230.85592647193658
                    ],
                    [
                        224.8807937811614,
                        232.57538843244515
                    ],
                    [
                        222.8123557359288,
                        234.05124676786642
                    ],
                    [
                        222.83483875815978,
                        236.1641238376518
                    ],
                    [
                        223.37443129169878,
                        236.38409477212406
                    ],
                    [
                        221.23854417977373,
                        238.0331558490966
                    ],
                    [
                        217.28153226715494,
                        239.8346796013309
                    ],
                    [
                        217.59629457838628,
                        240.29580298000235
                    ],
                    [
                        216.1798641778464,
                        242.57801210056658
                    ],
                    [
                        216.20234720007716,
                        243.32359789639304
                    ],
                    [
                        214.62853564392208,
                        243.12626185318572
                    ],
                    [
                        213.27955431007445,
                        242.38060828438967
                    ],
                    [
                        213.48190151015183,
                        240.71292492058484
                    ],
                    [
                        213.97652799922912,
                        239.7029119405991
                    ],
                    [
                        213.54935057684406,
                        238.0551347543356
                    ],
                    [
                        216,
                        235.79012120680818
                    ],
                    [
                        216.06744906669246,
                        234.42555500704918
                    ],
                    [
                        214.62853564392208,
                        233.94114355159547
                    ],
                    [
                        214.49363751053716,
                        232.0685131668929
                    ],
                    [
                        213.61679964353652,
                        231.36309299347363
                    ],
                    [
                        213.9090789325369,
                        229.26748400883383
                    ],
                    [
                        212.38023342084307,
                        227.5232244989087
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Surajpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Surguja",
            "dt_code": "401",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        225.780114670393,
                        245.82164174150097
                    ],
                    [
                        224.92575982562312,
                        244.3976691426967
                    ],
                    [
                        222.96973689154447,
                        243.47706910259916
                    ],
                    [
                        222.0928990245436,
                        243.69629489991922
                    ],
                    [
                        221.3959253353894,
                        245.33978568588805
                    ],
                    [
                        219.957011912619,
                        245.84354176765532
                    ],
                    [
                        217.91105688961693,
                        244.13468026486828
                    ],
                    [
                        216.20234720007716,
                        243.32359789639304
                    ],
                    [
                        216.1798641778464,
                        242.57801210056658
                    ],
                    [
                        217.59629457838628,
                        240.29580298000235
                    ],
                    [
                        217.28153226715494,
                        239.8346796013309
                    ],
                    [
                        221.23854417977373,
                        238.0331558490966
                    ],
                    [
                        223.37443129169878,
                        236.38409477212406
                    ],
                    [
                        224.97072587008438,
                        237.3297118540064
                    ],
                    [
                        225.53280142585436,
                        235.76811900972365
                    ],
                    [
                        226.7693676485478,
                        235.96613061229482
                    ],
                    [
                        227.91600178231784,
                        237.37368387198012
                    ],
                    [
                        227.10661298200966,
                        239.04395263390592
                    ],
                    [
                        227.82606969339463,
                        239.59309939840972
                    ],
                    [
                        227.17406204870213,
                        240.86658111200734
                    ],
                    [
                        228.5230433825493,
                        242.49027928883646
                    ],
                    [
                        229.26498311616524,
                        242.86311924716665
                    ],
                    [
                        229.0176698716266,
                        244.6825379663032
                    ],
                    [
                        225.780114670393,
                        245.82164174150097
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Surguja",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Aravalli",
            "dt_code": "725",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        78.29148883642097,
                        225.60064407781806
                    ],
                    [
                        78.15659070303627,
                        227.03722220733812
                    ],
                    [
                        79.7079192369606,
                        228.80396184925877
                    ],
                    [
                        80.42737594834603,
                        230.37069636377163
                    ],
                    [
                        81.14683265973122,
                        229.62057501272216
                    ],
                    [
                        82.38339888242444,
                        230.15010022519328
                    ],
                    [
                        81.95622146003961,
                        232.9058938199626
                    ],
                    [
                        82.0011875045011,
                        233.47864764330456
                    ],
                    [
                        80.44985897057654,
                        235.5260798536449
                    ],
                    [
                        79.37067390349898,
                        235.57008901729102
                    ],
                    [
                        77.75189630288196,
                        236.88994197426365
                    ],
                    [
                        78.24652279195948,
                        237.35169797588736
                    ],
                    [
                        77.52706608057429,
                        238.7803123737002
                    ],
                    [
                        76.74016030249663,
                        239.15379320112373
                    ],
                    [
                        75.70594127988034,
                        237.74740957675016
                    ],
                    [
                        74.51434110164882,
                        237.10983821305052
                    ],
                    [
                        72.91804652326255,
                        237.5275788240191
                    ],
                    [
                        72.46838607864697,
                        236.5380609455123
                    ],
                    [
                        72.98549558995501,
                        235.6361010588675
                    ],
                    [
                        74.2445448348792,
                        234.9098560643592
                    ],
                    [
                        75.81835639103429,
                        230.32657898710443
                    ],
                    [
                        74.58179016834083,
                        228.75981153538788
                    ],
                    [
                        74.80662039064873,
                        227.6557509496547
                    ],
                    [
                        76.04318661334196,
                        226.37430927608494
                    ],
                    [
                        76.98747354703528,
                        226.74998581885467
                    ],
                    [
                        78.29148883642097,
                        225.60064407781806
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Aravalli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mahisagar",
            "dt_code": "730",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        81.95622146003961,
                        232.9058938199626
                    ],
                    [
                        83.84479532742557,
                        232.81776409694464
                    ],
                    [
                        84.94646341673456,
                        233.2804042266112
                    ],
                    [
                        85.84578430596594,
                        234.73375935062114
                    ],
                    [
                        86.83503728412052,
                        234.00720616665353
                    ],
                    [
                        87.1722826175826,
                        234.68973289401652
                    ],
                    [
                        87.39711283989027,
                        235.46006440393523
                    ],
                    [
                        86.83503728412052,
                        238.27491142164945
                    ],
                    [
                        86.00316546158137,
                        239.28559447768305
                    ],
                    [
                        84.63170110550323,
                        238.34084003234307
                    ],
                    [
                        83.64244812734842,
                        239.90056040964214
                    ],
                    [
                        82.65319514919406,
                        238.45071655018512
                    ],
                    [
                        81.32669683757763,
                        240.29580298000235
                    ],
                    [
                        79.14584368119108,
                        240.0323159844773
                    ],
                    [
                        78.94349648111393,
                        240.976328843182
                    ],
                    [
                        77.86431141403591,
                        241.30553860352904
                    ],
                    [
                        76.29049985788106,
                        239.7907579432922
                    ],
                    [
                        76.74016030249663,
                        239.15379320112373
                    ],
                    [
                        77.52706608057429,
                        238.7803123737002
                    ],
                    [
                        78.24652279195948,
                        237.35169797588736
                    ],
                    [
                        77.75189630288196,
                        236.88994197426365
                    ],
                    [
                        79.37067390349898,
                        235.57008901729102
                    ],
                    [
                        80.44985897057654,
                        235.5260798536449
                    ],
                    [
                        82.0011875045011,
                        233.47864764330456
                    ],
                    [
                        81.95622146003961,
                        232.9058938199626
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Mahisagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Amethi",
            "dt_code": "706",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        195.4280346588289,
                        182.69690714558584
                    ],
                    [
                        195.96762719236744,
                        181.9285552584974
                    ],
                    [
                        198.2384124376772,
                        182.69690714558584
                    ],
                    [
                        198.57565777113905,
                        181.09205101551964
                    ],
                    [
                        199.78974097160153,
                        181.11466413331829
                    ],
                    [
                        200.62161279414067,
                        182.69690714558584
                    ],
                    [
                        204.17393030660537,
                        183.28425908401118
                    ],
                    [
                        204.30882843999007,
                        184.7292707181894
                    ],
                    [
                        203.31957546183548,
                        185.24830231883004
                    ],
                    [
                        202.89239803945043,
                        186.96235403965198
                    ],
                    [
                        205.79270790722217,
                        189.1703022043481
                    ],
                    [
                        205.77022488499142,
                        190.65585105163478
                    ],
                    [
                        203.36454150629697,
                        190.2733198525567
                    ],
                    [
                        201.65583181675697,
                        190.49834744980058
                    ],
                    [
                        199.1826993713703,
                        189.26036830341496
                    ],
                    [
                        198.68807288229277,
                        190.40833958140684
                    ],
                    [
                        198.7330389267545,
                        192.0053519936551
                    ],
                    [
                        196.91191412606054,
                        192.67974737524634
                    ],
                    [
                        194.8659591030589,
                        191.30822824651284
                    ],
                    [
                        195.3381025699057,
                        190.83583932928775
                    ],
                    [
                        195.85521208121372,
                        188.51719598682405
                    ],
                    [
                        195.60789883667485,
                        187.05252447170585
                    ],
                    [
                        196.349838570291,
                        186.44379107079936
                    ],
                    [
                        195.4280346588289,
                        182.69690714558584
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Amethi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ghaziabad",
            "dt_code": "140",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        141.69361152724423,
                        146.16326120839642
                    ],
                    [
                        139.58020743755014,
                        147.15427073326396
                    ],
                    [
                        139.8724867265505,
                        147.70714902528988
                    ],
                    [
                        136.56748245862468,
                        148.42102609597526
                    ],
                    [
                        136.20775410293209,
                        147.89140312230205
                    ],
                    [
                        135.93795783616224,
                        146.7625432121597
                    ],
                    [
                        134.90373881354617,
                        145.7482535455273
                    ],
                    [
                        134.61145952454604,
                        145.37927522161073
                    ],
                    [
                        134.8138067246232,
                        144.71030351227415
                    ],
                    [
                        137.28693917000987,
                        144.75644771415404
                    ],
                    [
                        138.11881099254902,
                        143.09448729171868
                    ],
                    [
                        139.35537721524247,
                        143.76407850423834
                    ],
                    [
                        141.60367943832125,
                        143.9949124050816
                    ],
                    [
                        141.69361152724423,
                        146.16326120839642
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Ghaziabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Agar Malwa",
            "dt_code": "724",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        112.28581844937298,
                        228.119527361905
                    ],
                    [
                        113.81466396106657,
                        226.19749686999472
                    ],
                    [
                        115.92806805076088,
                        225.71118529392055
                    ],
                    [
                        117.45691356245447,
                        222.9901602519932
                    ],
                    [
                        118.71596280737867,
                        222.63594269368355
                    ],
                    [
                        118.58106467399398,
                        220.93041901026214
                    ],
                    [
                        119.97501205230265,
                        220.68665734207815
                    ],
                    [
                        119.66024974107177,
                        222.6580830716013
                    ],
                    [
                        121.3015103639193,
                        224.69398418598854
                    ],
                    [
                        121.68372174184265,
                        226.2417013790186
                    ],
                    [
                        122.44814449768933,
                        227.1035019110551
                    ],
                    [
                        123.23505027576698,
                        229.9515439088879
                    ],
                    [
                        121.84110289745809,
                        230.01773143182396
                    ],
                    [
                        119.12065720753276,
                        230.9661904478345
                    ],
                    [
                        118.558581651763,
                        231.42923615354852
                    ],
                    [
                        116.84987196222323,
                        230.90003275494786
                    ],
                    [
                        115.09619622822174,
                        231.16465106317548
                    ],
                    [
                        112.87037702737348,
                        227.9649466547122
                    ],
                    [
                        112.28581844937298,
                        228.119527361905
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Agar Malwa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sambhal",
            "dt_code": "754",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        150.7317864640213,
                        151.29663127774393
                    ],
                    [
                        152.30559802017638,
                        150.88283272912895
                    ],
                    [
                        152.7102924203307,
                        148.76634649174292
                    ],
                    [
                        152.23814895348391,
                        145.54071281289816
                    ],
                    [
                        155.40825508802527,
                        145.1255574676676
                    ],
                    [
                        156.26260993279516,
                        145.84048597200717
                    ],
                    [
                        155.72301739925638,
                        147.43073163963157
                    ],
                    [
                        157.34179499987295,
                        147.84534140929372
                    ],
                    [
                        158.03876868902762,
                        149.68686997401164
                    ],
                    [
                        159.07298771164392,
                        150.79086434171194
                    ],
                    [
                        157.92635357787367,
                        151.96310321687696
                    ],
                    [
                        155.83543251041033,
                        153.29529556255108
                    ],
                    [
                        154.75624744333254,
                        157.56078240019946
                    ],
                    [
                        153.29485099833119,
                        156.73599953101012
                    ],
                    [
                        150.84420157517548,
                        154.0298704948509
                    ],
                    [
                        150.34957508609796,
                        152.1239381770821
                    ],
                    [
                        150.7317864640213,
                        151.29663127774393
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Sambhal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Charkhi Dadri",
            "dt_code": "765",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2016_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        123.0551860979208,
                        146.87776626955264
                    ],
                    [
                        123.68471072038278,
                        148.35195388354703
                    ],
                    [
                        122.33572938653538,
                        148.5821840465348
                    ],
                    [
                        121.14412920830364,
                        150.79086434171194
                    ],
                    [
                        121.03171409714969,
                        151.4345426160304
                    ],
                    [
                        118.46864956284003,
                        151.08974410776875
                    ],
                    [
                        117.09718520676188,
                        152.16988834118627
                    ],
                    [
                        116.60255871768436,
                        151.18169694890474
                    ],
                    [
                        115.45592458391411,
                        151.38857336438866
                    ],
                    [
                        114.15190929452842,
                        150.07794695008005
                    ],
                    [
                        114.60156973914422,
                        148.81238409262312
                    ],
                    [
                        116.19786431753028,
                        148.51311816087468
                    ],
                    [
                        118.80589489630188,
                        147.13123035877499
                    ],
                    [
                        120.26729134130301,
                        147.29250662882566
                    ],
                    [
                        121.14412920830364,
                        145.10249039162136
                    ],
                    [
                        121.63875569738116,
                        145.03328733715534
                    ],
                    [
                        122.51559356438179,
                        145.51765121193375
                    ],
                    [
                        123.0551860979208,
                        146.87776626955264
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Charkhi Dadri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Alipurduar",
            "dt_code": "774",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2014_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        320.50108732870444,
                        180.16467860276208
                    ],
                    [
                        320.77088359547406,
                        181.54426168082517
                    ],
                    [
                        320.253774084166,
                        184.81954730098056
                    ],
                    [
                        318.9947248392418,
                        183.9843245373603
                    ],
                    [
                        318.2303020833949,
                        184.59384777195754
                    ],
                    [
                        315.86958474916196,
                        184.36812132576443
                    ],
                    [
                        314.610535504238,
                        183.62303283252294
                    ],
                    [
                        313.7336976372371,
                        184.02948114429933
                    ],
                    [
                        309.73171968015686,
                        183.01319629984738
                    ],
                    [
                        309.5293724800799,
                        181.88334832325967
                    ],
                    [
                        308.6075685686178,
                        181.2503371251491
                    ],
                    [
                        309.304542257772,
                        177.81025780847506
                    ],
                    [
                        309.8666178135418,
                        178.37649511305597
                    ],
                    [
                        311.7327086586972,
                        178.35384891750977
                    ],
                    [
                        313.4863843926987,
                        177.5837147715933
                    ],
                    [
                        314.6555015486995,
                        178.51236652149305
                    ],
                    [
                        316.0269659047776,
                        178.2632613894408
                    ],
                    [
                        317.3309811941633,
                        179.28211801454836
                    ],
                    [
                        319.197072039319,
                        180.14205403599158
                    ],
                    [
                        320.50108732870444,
                        180.16467860276208
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Alipurduar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Paschim Bardhaman",
            "dt_code": "777",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2017_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        279.8293001132083,
                        227.0814089104237
                    ],
                    [
                        280.8410361135939,
                        228.0974451031915
                    ],
                    [
                        282.45981371421067,
                        228.0974451031915
                    ],
                    [
                        284.5957008261355,
                        229.09091622121178
                    ],
                    [
                        285.1352933596745,
                        230.260401186965
                    ],
                    [
                        286.4168256268292,
                        230.34863779110225
                    ],
                    [
                        285.180259404136,
                        232.3990977766784
                    ],
                    [
                        285.180259404136,
                        233.65484845189036
                    ],
                    [
                        282.6621609142878,
                        232.09055374995876
                    ],
                    [
                        281.65042491390227,
                        231.16465106317548
                    ],
                    [
                        278.795081090592,
                        229.97360664815426
                    ],
                    [
                        277.13133744551374,
                        229.8632906352026
                    ],
                    [
                        275.64745797828164,
                        228.89225968643308
                    ],
                    [
                        276.029669356205,
                        227.83243980999953
                    ],
                    [
                        275.64745797828164,
                        226.72788907314225
                    ],
                    [
                        277.3336846455909,
                        225.954355289621
                    ],
                    [
                        279.8293001132083,
                        227.0814089104237
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Paschim Bardhaman",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kalimpong",
            "dt_code": "775",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2017_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        299.524427587379,
                        174.00066887169754
                    ],
                    [
                        301.0982391435341,
                        172.2747079990349
                    ],
                    [
                        302.06500909945817,
                        172.07021101032637
                    ],
                    [
                        303.8861339001521,
                        172.88806299949783
                    ],
                    [
                        305.999537989846,
                        173.38768315540204
                    ],
                    [
                        306.04450403430747,
                        175.74770005683268
                    ],
                    [
                        304.8753868783067,
                        175.31676732861854
                    ],
                    [
                        304.44820945592164,
                        176.4052468490122
                    ],
                    [
                        303.031779055382,
                        176.4052468490122
                    ],
                    [
                        302.1324581661504,
                        177.1532071568726
                    ],
                    [
                        300.3113333654567,
                        177.1532071568726
                    ],
                    [
                        299.5019445651483,
                        175.90643954646964
                    ],
                    [
                        299.524427587379,
                        174.00066887169754
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Kalimpong",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ajmer",
            "dt_code": "119",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            86.09309755050458,
                            196.76603831060646
                        ],
                        [
                            86.07061452827384,
                            195.84625240869593
                        ],
                        [
                            87.37462981765952,
                            193.803215400044
                        ],
                        [
                            88.22898466242941,
                            194.3871604442499
                        ],
                        [
                            88.79106021819916,
                            195.37497364682082
                        ],
                        [
                            87.55449399550594,
                            195.82381316015983
                        ],
                        [
                            87.2397316842746,
                            197.05758683771154
                        ],
                        [
                            86.09309755050458,
                            196.76603831060646
                        ]
                    ]
                ],
                [
                    [
                        [
                            101.76376404536427,
                            176.97191103428338
                        ],
                        [
                            100.86444315613267,
                            177.85556311471964
                        ],
                        [
                            101.6063828897486,
                            180.39090925889997
                        ],
                        [
                            103.0902623569807,
                            180.86590486368925
                        ],
                        [
                            103.04529631251899,
                            182.51614665850502
                        ],
                        [
                            104.59662484644332,
                            184.00690297566803
                        ],
                        [
                            104.0345492906738,
                            185.00008755688083
                        ],
                        [
                            103.78723604613515,
                            186.89472341072343
                        ],
                        [
                            104.88890413544368,
                            189.71063518150726
                        ],
                        [
                            106.59761382498368,
                            190.45334404391912
                        ],
                        [
                            107.29458751413813,
                            191.42068466863196
                        ],
                        [
                            105.76574200244454,
                            192.6123184673483
                        ],
                        [
                            106.62009684721443,
                            193.89306458860796
                        ],
                        [
                            106.79996102506084,
                            195.41986227539462
                        ],
                        [
                            105.83319106913677,
                            196.74360968966812
                        ],
                        [
                            104.4842097352896,
                            196.11550314868197
                        ],
                        [
                            103.71978697944269,
                            196.56417140263272
                        ],
                        [
                            102.68556795682662,
                            195.1056200312849
                        ],
                        [
                            102.39328866782625,
                            193.9155262326064
                        ],
                        [
                            100.68457897828625,
                            193.53364274333688
                        ],
                        [
                            99.20069951105415,
                            193.66843377739673
                        ],
                        [
                            97.85171817720698,
                            192.9044933571829
                        ],
                        [
                            96.50273684335957,
                            193.1741539432444
                        ],
                        [
                            93.7598081312035,
                            192.74717392275141
                        ],
                        [
                            93.15276653097226,
                            192.20769542289003
                        ],
                        [
                            92.16351355281745,
                            191.62308963996338
                        ],
                        [
                            91.35412475250928,
                            192.67974737524634
                        ],
                        [
                            91.62392101927844,
                            193.51117665570672
                        ],
                        [
                            90.76956617450878,
                            194.16258708031688
                        ],
                        [
                            90.27493968543126,
                            194.0053701966005
                        ],
                        [
                            90.1175585298156,
                            194.09520998230653
                        ],
                        [
                            89.01589044050729,
                            193.96044873696513
                        ],
                        [
                            89.30816972950743,
                            192.25265773956704
                        ],
                        [
                            90.09507550758485,
                            191.08329564260998
                        ],
                        [
                            92.83800421974115,
                            188.47214594401623
                        ],
                        [
                            91.12929453020115,
                            186.91726722087054
                        ],
                        [
                            91.9162003082788,
                            186.21828477304
                        ],
                        [
                            91.71385310820187,
                            184.25524800266112
                        ],
                        [
                            92.8604872419719,
                            183.62303283252294
                        ],
                        [
                            94.23195159805005,
                            183.62303283252294
                        ],
                        [
                            96.09804244320549,
                            181.27294833814375
                        ],
                        [
                            97.289642621437,
                            181.83814030198755
                        ],
                        [
                            98.05406537728413,
                            180.11942919620543
                        ],
                        [
                            97.69433702159154,
                            178.89728187494097
                        ],
                        [
                            98.84097115536156,
                            178.648228050419
                        ],
                        [
                            99.96512226690083,
                            175.52090582422
                        ],
                        [
                            101.85369613428725,
                            176.11051325441753
                        ],
                        [
                            101.76376404536427,
                            176.97191103428338
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Ajmer",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Cooch Behar",
            "dt_code": "329",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        320.253774084166,
                        184.81954730098056
                    ],
                    [
                        320.36618919532,
                        185.33855417263595
                    ],
                    [
                        319.4668683060884,
                        185.83486260179404
                    ],
                    [
                        318.27526812785663,
                        188.0441174790722
                    ],
                    [
                        318.70244555024146,
                        188.80999535560653
                    ],
                    [
                        317.30849817193257,
                        187.86386622414176
                    ],
                    [
                        316.92628679400923,
                        189.10274984497897
                    ],
                    [
                        317.53332839424047,
                        190.56585057777647
                    ],
                    [
                        315.9370338158544,
                        191.5106450661874
                    ],
                    [
                        313.10417301477514,
                        191.44317516297508
                    ],
                    [
                        311.35049728077365,
                        189.575566246258
                    ],
                    [
                        310.2488291914651,
                        189.32791509331787
                    ],
                    [
                        309.39447434669523,
                        185.20317478034963
                    ],
                    [
                        307.7307307016167,
                        184.27782320613
                    ],
                    [
                        306.65154563453916,
                        185.38367848827244
                    ],
                    [
                        307.7756967460782,
                        185.87997511227115
                    ],
                    [
                        306.8988588790776,
                        186.96235403965198
                    ],
                    [
                        304.44820945592164,
                        186.21828477304
                    ],
                    [
                        306.8988588790776,
                        184.18752077539858
                    ],
                    [
                        307.41596839038584,
                        182.78728088915636
                    ],
                    [
                        309.73171968015686,
                        183.01319629984738
                    ],
                    [
                        313.7336976372371,
                        184.02948114429933
                    ],
                    [
                        314.610535504238,
                        183.62303283252294
                    ],
                    [
                        315.86958474916196,
                        184.36812132576443
                    ],
                    [
                        318.2303020833949,
                        184.59384777195754
                    ],
                    [
                        318.9947248392418,
                        183.9843245373603
                    ],
                    [
                        320.253774084166,
                        184.81954730098056
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Cooch Behar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Uttar Dinajpur",
            "dt_code": "330",
            "st_nm": "West Bengal",
            "st_code": "19",
            "alpha2code": "WB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        296.8039818974537,
                        184.09721403232572
                    ],
                    [
                        298.1754462535316,
                        183.46494598075537
                    ],
                    [
                        300.40126545437965,
                        184.052059043273
                    ],
                    [
                        300.8509258989957,
                        185.6318430311929
                    ],
                    [
                        299.1871822539172,
                        186.1957326708128
                    ],
                    [
                        298.445242520301,
                        187.97652525560028
                    ],
                    [
                        295.9046610082221,
                        189.1703022043481
                    ],
                    [
                        294.6456117632979,
                        192.74717392275141
                    ],
                    [
                        294.75802687445184,
                        194.3871604442499
                    ],
                    [
                        295.38755149691406,
                        194.9709291740636
                    ],
                    [
                        297.208676297608,
                        195.17296194676436
                    ],
                    [
                        299.95160500976385,
                        197.10243657771719
                    ],
                    [
                        299.83918989861013,
                        198.22334438157935
                    ],
                    [
                        298.55765763145496,
                        198.6939334921198
                    ],
                    [
                        295.7247968303757,
                        200.97804184300168
                    ],
                    [
                        295.9046610082221,
                        202.29802804698835
                    ],
                    [
                        297.23115931983875,
                        202.96886790557306
                    ],
                    [
                        295.47748358583704,
                        203.48302385728886
                    ],
                    [
                        294.870441985606,
                        202.58875337605951
                    ],
                    [
                        294.26340038537455,
                        200.03784942442138
                    ],
                    [
                        293.5889097184506,
                        198.20093254423512
                    ],
                    [
                        294.0160871408359,
                        196.49687777229695
                    ],
                    [
                        292.8020039403732,
                        196.093067013952
                    ],
                    [
                        291.70033585106466,
                        193.803215400044
                    ],
                    [
                        290.7335658951406,
                        193.39884229375588
                    ],
                    [
                        290.66611682844837,
                        192.65727133490464
                    ],
                    [
                        291.02584518414096,
                        191.73552763467018
                    ],
                    [
                        290.935913095218,
                        191.66806562720058
                    ],
                    [
                        291.11577727306417,
                        191.12828427175089
                    ],
                    [
                        291.0708112286027,
                        190.97081945604486
                    ],
                    [
                        292.9144190515274,
                        189.26036830341496
                    ],
                    [
                        294.35333247429776,
                        188.74243025542103
                    ],
                    [
                        295.4325175413753,
                        187.50331256193084
                    ],
                    [
                        297.38854047545396,
                        186.1957326708128
                    ],
                    [
                        296.8039818974537,
                        184.09721403232572
                    ]
                ]
            ]
        },
        "id": "TT-West Bengal-Uttar Dinajpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Datia",
            "dt_code": "422",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            151.9908357089455,
                            204.55561380279602
                        ],
                        [
                            152.82270753148464,
                            205.9178583615714
                        ],
                        [
                            151.74352246440685,
                            206.2973335838446
                        ],
                        [
                            150.5519222861749,
                            205.80623419195268
                        ],
                        [
                            151.24889597532956,
                            204.28752089294233
                        ],
                        [
                            151.9908357089455,
                            204.55561380279602
                        ]
                    ]
                ],
                [
                    [
                        [
                            155.47570415471773,
                            189.0351950978308
                        ],
                        [
                            156.48744015510306,
                            188.0441174790722
                        ],
                        [
                            157.8589045111812,
                            187.9539939819271
                        ],
                        [
                            158.6682933114896,
                            189.77816607420442
                        ],
                        [
                            157.6115912666428,
                            191.30822824651284
                        ],
                        [
                            158.0837347334891,
                            192.5224095842063
                        ],
                        [
                            159.27533491172085,
                            193.03932836551178
                        ],
                        [
                            157.47669313325787,
                            196.18280999678103
                        ],
                        [
                            158.1736668224121,
                            197.81989188029127
                        ],
                        [
                            156.8022024663344,
                            198.0664561097849
                        ],
                        [
                            155.9253645993333,
                            198.80596179442637
                        ],
                        [
                            152.8901565981771,
                            198.60430622092792
                        ],
                        [
                            151.78848850886857,
                            197.1921329555234
                        ],
                        [
                            149.89991464148216,
                            196.1603746400774
                        ],
                        [
                            150.5968883306366,
                            195.1056200312849
                        ],
                        [
                            151.11399784194464,
                            194.34224785863154
                        ],
                        [
                            153.20491890940798,
                            193.08427127239668
                        ],
                        [
                            153.33981704279267,
                            192.23017671255218
                        ],
                        [
                            154.84617953225575,
                            191.5106450661874
                        ],
                        [
                            155.47570415471773,
                            189.0351950978308
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Datia",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rajsamand",
            "dt_code": "123",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            88.22898466242941,
                            194.3871604442499
                        ],
                        [
                            89.01589044050729,
                            193.96044873696513
                        ],
                        [
                            90.1175585298156,
                            194.09520998230653
                        ],
                        [
                            90.31990572989275,
                            195.86869139764434
                        ],
                        [
                            89.01589044050729,
                            196.1379390240507
                        ],
                        [
                            89.30816972950743,
                            197.37151330654234
                        ],
                        [
                            87.75684119558287,
                            202.25329726165728
                        ],
                        [
                            88.13905257350643,
                            204.0417371158556
                        ],
                        [
                            89.87024528527718,
                            205.8508846134498
                        ],
                        [
                            90.2074906187388,
                            206.9668189460379
                        ],
                        [
                            91.30915870804756,
                            206.83293991104978
                        ],
                        [
                            92.11854750835596,
                            207.41301734077405
                        ],
                        [
                            90.29742270766201,
                            208.6395478901547
                        ],
                        [
                            89.53299995181533,
                            209.82076489392534
                        ],
                        [
                            88.45381488473731,
                            209.9767225597518
                        ],
                        [
                            87.35214679542878,
                            209.17452506761623
                        ],
                        [
                            84.85653132781113,
                            210.6004318568227
                        ],
                        [
                            84.13707461642593,
                            211.46884679468383
                        ],
                        [
                            82.60822910473235,
                            211.6246764104614
                        ],
                        [
                            82.85554234927099,
                            210.13266808266695
                        ],
                        [
                            81.68642519327,
                            209.9990012348939
                        ],
                        [
                            79.68543621472986,
                            207.47993847381093
                        ],
                        [
                            79.91026643703776,
                            206.20805182178844
                        ],
                        [
                            81.4615949709621,
                            204.7566596413032
                        ],
                        [
                            81.70890821550097,
                            203.2594942619557
                        ],
                        [
                            83.64244812734842,
                            201.51509238360768
                        ],
                        [
                            85.44108990581162,
                            197.32666976898452
                        ],
                        [
                            86.09309755050458,
                            196.76603831060646
                        ],
                        [
                            87.2397316842746,
                            197.05758683771154
                        ],
                        [
                            87.55449399550594,
                            195.82381316015983
                        ],
                        [
                            88.79106021819916,
                            195.37497364682082
                        ],
                        [
                            88.22898466242941,
                            194.3871604442499
                        ]
                    ]
                ],
                [
                    [
                        [
                            93.15276653097226,
                            192.20769542289003
                        ],
                        [
                            91.62392101927844,
                            193.51117665570672
                        ],
                        [
                            91.35412475250928,
                            192.67974737524634
                        ],
                        [
                            92.16351355281745,
                            191.62308963996338
                        ],
                        [
                            93.15276653097226,
                            192.20769542289003
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Rajsamand",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mahoba",
            "dt_code": "169",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        165.0759546472641,
                        202.6334765397201
                    ],
                    [
                        166.7396982923426,
                        199.455599272518
                    ],
                    [
                        168.718204248652,
                        200.0602402020371
                    ],
                    [
                        170.35946487149977,
                        200.01545839074015
                    ],
                    [
                        171.2363027385004,
                        198.94038727316092
                    ],
                    [
                        172.51783500565534,
                        198.31298915526997
                    ],
                    [
                        173.75440122834857,
                        198.51467483345783
                    ],
                    [
                        176.74464318504351,
                        198.3353997048656
                    ],
                    [
                        178.8130812302761,
                        199.52279082718655
                    ],
                    [
                        177.75637918542907,
                        200.44084426162237
                    ],
                    [
                        177.19430362965932,
                        202.20856545946924
                    ],
                    [
                        176.13760158481227,
                        202.34275781561223
                    ],
                    [
                        173.52957100604067,
                        204.7566596413032
                    ],
                    [
                        173.30474078373277,
                        206.2973335838446
                    ],
                    [
                        171.9557594498856,
                        205.62762245409385
                    ],
                    [
                        170.85409136057683,
                        205.9401824419195
                    ],
                    [
                        169.32524584888324,
                        205.11402404783905
                    ],
                    [
                        168.178611715113,
                        206.60978815135434
                    ],
                    [
                        166.9195624701888,
                        206.16340943527427
                    ],
                    [
                        166.69473224788112,
                        205.136357181871
                    ],
                    [
                        167.3692229148046,
                        203.77357448215753
                    ],
                    [
                        166.13265669211137,
                        202.54402919666137
                    ],
                    [
                        165.0759546472641,
                        202.6334765397201
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Mahoba",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chhatarpur",
            "dt_code": "425",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        165.0759546472641,
                        202.6334765397201
                    ],
                    [
                        166.13265669211137,
                        202.54402919666137
                    ],
                    [
                        167.3692229148046,
                        203.77357448215753
                    ],
                    [
                        166.69473224788112,
                        205.136357181871
                    ],
                    [
                        166.9195624701888,
                        206.16340943527427
                    ],
                    [
                        168.178611715113,
                        206.60978815135434
                    ],
                    [
                        169.32524584888324,
                        205.11402404783905
                    ],
                    [
                        170.85409136057683,
                        205.9401824419195
                    ],
                    [
                        171.9557594498856,
                        205.62762245409385
                    ],
                    [
                        173.30474078373277,
                        206.2973335838446
                    ],
                    [
                        173.52957100604067,
                        204.7566596413032
                    ],
                    [
                        176.13760158481227,
                        202.34275781561223
                    ],
                    [
                        177.19430362965932,
                        202.20856545946924
                    ],
                    [
                        178.90301331919932,
                        201.44796909848702
                    ],
                    [
                        180.29696069750798,
                        201.58221337525544
                    ],
                    [
                        180.22951163081575,
                        203.2594942619557
                    ],
                    [
                        181.60097598689367,
                        203.95235361647195
                    ],
                    [
                        181.98318736481724,
                        205.06935702425443
                    ],
                    [
                        180.83655323104722,
                        205.8955340300563
                    ],
                    [
                        179.93723234181562,
                        206.94450639939396
                    ],
                    [
                        179.53253794166108,
                        207.5022450183834
                    ],
                    [
                        177.86879429658302,
                        208.95163531819247
                    ],
                    [
                        176.13760158481227,
                        209.17452506761623
                    ],
                    [
                        176.81209225173575,
                        211.15715130844973
                    ],
                    [
                        175.55304300681155,
                        212.35913918294767
                    ],
                    [
                        174.09164656181042,
                        212.80413778964464
                    ],
                    [
                        173.82185029504103,
                        214.7609670389707
                    ],
                    [
                        173.9792314506567,
                        218.0255548696034
                    ],
                    [
                        172.65273313904004,
                        218.0255548696034
                    ],
                    [
                        170.31449882703805,
                        217.89240881806478
                    ],
                    [
                        169.28027980442175,
                        217.1599501266159
                    ],
                    [
                        167.03197758134274,
                        218.40275486733248
                    ],
                    [
                        166.58231713672717,
                        220.9082600533702
                    ],
                    [
                        165.3682339362647,
                        221.15199544370552
                    ],
                    [
                        163.0749656687242,
                        222.41452584882916
                    ],
                    [
                        161.74846735710776,
                        221.30708474330936
                    ],
                    [
                        162.37799197956974,
                        220.1547132571883
                    ],
                    [
                        164.37898095810988,
                        218.66897176263132
                    ],
                    [
                        164.69374326934098,
                        217.42632919167642
                    ],
                    [
                        163.61455820226297,
                        217.07114936447704
                    ],
                    [
                        162.0407466461079,
                        217.4929185180083
                    ],
                    [
                        160.8266634456454,
                        217.33754003906057
                    ],
                    [
                        160.7367313567222,
                        216.93794096062277
                    ],
                    [
                        161.29880691249195,
                        215.24987875809387
                    ],
                    [
                        162.28805989064654,
                        213.84949824528678
                    ],
                    [
                        163.81690540234013,
                        214.16077766532902
                    ],
                    [
                        164.15415073580198,
                        213.3602479754668
                    ],
                    [
                        165.41319998072595,
                        212.9598640954677
                    ],
                    [
                        165.43568300295715,
                        211.17941687564286
                    ],
                    [
                        166.0202415809572,
                        209.46424459755315
                    ],
                    [
                        165.7054792697263,
                        205.47132398487923
                    ],
                    [
                        165.43568300295715,
                        204.9800199544856
                    ],
                    [
                        165.25581882511074,
                        204.91301450679902
                    ],
                    [
                        165.05347162503335,
                        203.95235361647195
                    ],
                    [
                        164.82864140272568,
                        203.46067203741575
                    ],
                    [
                        165.0759546472641,
                        202.6334765397201
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Chhatarpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chittorgarh",
            "dt_code": "126",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            107.31707053636887,
                            207.59146869830084
                        ],
                        [
                            108.77846698137,
                            207.10068897027884
                        ],
                        [
                            110.0375162262942,
                            207.65838383546418
                        ],
                        [
                            110.73448991544865,
                            207.5022450183834
                        ],
                        [
                            113.16265631637407,
                            209.75392217550876
                        ],
                        [
                            114.08446022783619,
                            209.9990012348939
                        ],
                        [
                            113.67976582768188,
                            211.7582350403839
                        ],
                        [
                            111.09421827114147,
                            213.04884515750535
                        ],
                        [
                            110.21738040414061,
                            212.71514592873683
                        ],
                        [
                            108.84591604806246,
                            213.07108980973862
                        ],
                        [
                            104.84393809098219,
                            211.9808130508498
                        ],
                        [
                            107.09224031406097,
                            208.66184146665967
                        ],
                        [
                            107.31707053636887,
                            207.59146869830084
                        ]
                    ]
                ],
                [
                    [
                        [
                            91.30915870804756,
                            206.83293991104978
                        ],
                        [
                            93.13028350874151,
                            204.95768505722629
                        ],
                        [
                            94.92892528720449,
                            204.9353499080038
                        ],
                        [
                            96.48025382112883,
                            205.60529485572033
                        ],
                        [
                            97.91916724389921,
                            204.57795323824035
                        ],
                        [
                            99.13325044436169,
                            205.4266650158301
                        ],
                        [
                            100.81947711167118,
                            205.02468899318632
                        ],
                        [
                            101.56141684528711,
                            205.62762245409385
                        ],
                        [
                            105.13621737998233,
                            206.43124870171494
                        ],
                        [
                            104.95635320213614,
                            207.30147712021056
                        ],
                        [
                            103.8097190683659,
                            208.43889449815975
                        ],
                        [
                            103.85468511282738,
                            209.8876053808561
                        ],
                        [
                            102.70805097905713,
                            210.39997503206564
                        ],
                        [
                            101.53893382305637,
                            210.19949816254245
                        ],
                        [
                            100.70706200051723,
                            209.0407942008415
                        ],
                        [
                            99.58291088897772,
                            209.53109699146876
                        ],
                        [
                            100.10002040028576,
                            211.15715130844973
                        ],
                        [
                            101.80873008982553,
                            211.7137164826724
                        ],
                        [
                            102.03356031213343,
                            212.87087910758856
                        ],
                        [
                            100.50471480044007,
                            213.8272621668114
                        ],
                        [
                            99.26814857774662,
                            212.18111221241656
                        ],
                        [
                            98.79600511090007,
                            213.3824891955212
                        ],
                        [
                            98.99835231097722,
                            214.60537947340043
                        ],
                        [
                            97.69433702159154,
                            216.27176811965217
                        ],
                        [
                            96.16549150989772,
                            215.84974572626857
                        ],
                        [
                            95.06382342058919,
                            219.44523983538272
                        ],
                        [
                            93.7598081312035,
                            219.910859461308
                        ],
                        [
                            92.83800421974115,
                            219.4895891206088
                        ],
                        [
                            91.98364937497126,
                            216.60488180888905
                        ],
                        [
                            91.21922661912436,
                            216.2495585997637
                        ],
                        [
                            91.42157381920129,
                            214.7609670389707
                        ],
                        [
                            89.93769435196964,
                            212.5816507700439
                        ],
                        [
                            88.97092439604558,
                            212.2033664445533
                        ],
                        [
                            89.53299995181533,
                            209.82076489392534
                        ],
                        [
                            90.29742270766201,
                            208.6395478901547
                        ],
                        [
                            92.11854750835596,
                            207.41301734077405
                        ],
                        [
                            91.30915870804756,
                            206.83293991104978
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Chittorgarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Panna",
            "dt_code": "426",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        179.53253794166108,
                        207.5022450183834
                    ],
                    [
                        180.67917207543155,
                        207.68068838170717
                    ],
                    [
                        181.55600994243218,
                        206.7659970132791
                    ],
                    [
                        182.68016105397146,
                        208.2828168009213
                    ],
                    [
                        182.92747429851033,
                        209.33053310683863
                    ],
                    [
                        184.11907447674184,
                        209.50881310836945
                    ],
                    [
                        184.52376887689616,
                        210.6449750970949
                    ],
                    [
                        182.07311945374045,
                        212.0030694976731
                    ],
                    [
                        181.1962815867396,
                        213.07108980973862
                    ],
                    [
                        182.56774594281774,
                        214.16077766532902
                    ],
                    [
                        182.43284780943281,
                        217.00454625183642
                    ],
                    [
                        183.84927820997268,
                        219.75566467309886
                    ],
                    [
                        184.95094629928144,
                        221.24061933243752
                    ],
                    [
                        180.9264853199702,
                        224.56126902515837
                    ],
                    [
                        180.1620625641233,
                        225.5122068728648
                    ],
                    [
                        179.06039447481453,
                        224.91515725471493
                    ],
                    [
                        177.80134522989056,
                        225.0699643793444
                    ],
                    [
                        176.4298808738124,
                        224.18519659416575
                    ],
                    [
                        175.46311091788834,
                        225.7554001350113
                    ],
                    [
                        173.66446913942536,
                        226.81627465086177
                    ],
                    [
                        173.19232567257882,
                        224.98150457894116
                    ],
                    [
                        173.37218985042523,
                        223.69841325751838
                    ],
                    [
                        172.2255557166552,
                        223.38858241975362
                    ],
                    [
                        173.57453705050239,
                        219.24565616571687
                    ],
                    [
                        172.65273313904004,
                        218.0255548696034
                    ],
                    [
                        173.9792314506567,
                        218.0255548696034
                    ],
                    [
                        173.82185029504103,
                        214.7609670389707
                    ],
                    [
                        174.09164656181042,
                        212.80413778964464
                    ],
                    [
                        175.55304300681155,
                        212.35913918294767
                    ],
                    [
                        176.81209225173575,
                        211.15715130844973
                    ],
                    [
                        176.13760158481227,
                        209.17452506761623
                    ],
                    [
                        177.86879429658302,
                        208.95163531819247
                    ],
                    [
                        179.53253794166108,
                        207.5022450183834
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Panna",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sabarkantha",
            "dt_code": "472",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        72.1311407451849,
                        218.24744565871737
                    ],
                    [
                        72.7606653676471,
                        216.3161864314664
                    ],
                    [
                        74.0197146125713,
                        216.02745005059455
                    ],
                    [
                        73.86233345695564,
                        217.67047942288724
                    ],
                    [
                        74.9640015462644,
                        218.33619522385152
                    ],
                    [
                        73.72743532357094,
                        220.93041901026214
                    ],
                    [
                        74.58179016834083,
                        222.0380626548403
                    ],
                    [
                        75.88580545772675,
                        222.81305906840663
                    ],
                    [
                        76.3129828801118,
                        224.03032339958867
                    ],
                    [
                        77.39216794718959,
                        223.18938093673984
                    ],
                    [
                        78.69618323657505,
                        223.38858241975362
                    ],
                    [
                        78.85356439219072,
                        225.2689851524994
                    ],
                    [
                        78.29148883642097,
                        225.60064407781806
                    ],
                    [
                        76.98747354703528,
                        226.74998581885467
                    ],
                    [
                        76.04318661334196,
                        226.37430927608494
                    ],
                    [
                        74.80662039064873,
                        227.6557509496547
                    ],
                    [
                        74.58179016834083,
                        228.75981153538788
                    ],
                    [
                        75.81835639103429,
                        230.32657898710443
                    ],
                    [
                        74.2445448348792,
                        234.9098560643592
                    ],
                    [
                        72.98549558995501,
                        235.6361010588675
                    ],
                    [
                        71.97375958956945,
                        236.05412986938256
                    ],
                    [
                        68.82613647725907,
                        233.63282415146728
                    ],
                    [
                        69.70297434425993,
                        233.25837603553828
                    ],
                    [
                        69.77042341095216,
                        232.0685131668929
                    ],
                    [
                        70.26504990002945,
                        231.36309299347363
                    ],
                    [
                        70.31001594449117,
                        229.33369309758342
                    ],
                    [
                        69.63552527756747,
                        226.74998581885467
                    ],
                    [
                        70.0627026999523,
                        224.8930410086545
                    ],
                    [
                        70.78215941133772,
                        223.6541545501911
                    ],
                    [
                        71.61403123387686,
                        223.41071473313826
                    ],
                    [
                        72.10865772295415,
                        221.50646809473864
                    ],
                    [
                        73.14287674557045,
                        219.64480405838214
                    ],
                    [
                        72.1311407451849,
                        218.24744565871737
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Sabarkantha",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kutch",
            "dt_code": "468",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        44.971649890392655,
                        213.16006596611408
                    ],
                    [
                        46.09580100193193,
                        213.56041013680016
                    ],
                    [
                        47.55719744693329,
                        214.11631211050508
                    ],
                    [
                        47.152503046778975,
                        215.44985411948062
                    ],
                    [
                        48.45651833616489,
                        215.71645727736438
                    ],
                    [
                        48.02934091377983,
                        217.58170090320294
                    ],
                    [
                        48.83872971408823,
                        218.66897176263132
                    ],
                    [
                        47.66961255808724,
                        219.20130159690171
                    ],
                    [
                        48.02934091377983,
                        224.31793583239272
                    ],
                    [
                        44.81426873477699,
                        225.62275279120945
                    ],
                    [
                        44.27467620123821,
                        226.66159743062695
                    ],
                    [
                        44.52198944577708,
                        230.50304294287588
                    ],
                    [
                        46.52297842431699,
                        228.40657551902194
                    ],
                    [
                        46.59042749100945,
                        229.57644188542508
                    ],
                    [
                        48.861212736318976,
                        229.6867729644013
                    ],
                    [
                        49.91791478116602,
                        231.51742380715933
                    ],
                    [
                        51.17696402609022,
                        232.4211349124144
                    ],
                    [
                        51.62662447070602,
                        234.11730595679484
                    ],
                    [
                        53.470232293630716,
                        237.17580267732228
                    ],
                    [
                        53.17795300463035,
                        237.81335440498498
                    ],
                    [
                        50.90716775932083,
                        237.54956291443068
                    ],
                    [
                        49.018593891934415,
                        237.74740957675016
                    ],
                    [
                        47.87195975816417,
                        237.35169797588736
                    ],
                    [
                        45.129031046008095,
                        236.80197714747953
                    ],
                    [
                        42.81327975623685,
                        237.61551383193972
                    ],
                    [
                        40.63242659985053,
                        237.4616251988179
                    ],
                    [
                        38.76633575469509,
                        238.2089807853951
                    ],
                    [
                        39.19351317707992,
                        237.0658607736736
                    ],
                    [
                        36.04589006476954,
                        237.26375213346256
                    ],
                    [
                        34.87677290876877,
                        236.62603663421203
                    ],
                    [
                        33.21302926369026,
                        236.64802999044565
                    ],
                    [
                        31.054659129534457,
                        239.02198384715552
                    ],
                    [
                        29.81809290684123,
                        241.37137454117885
                    ],
                    [
                        27.547307661531477,
                        241.74440701997742
                    ],
                    [
                        25.231556371760462,
                        242.86311924716665
                    ],
                    [
                        23.455397615528,
                        243.3455230176241
                    ],
                    [
                        20.060461258678856,
                        243.1043345200577
                    ],
                    [
                        15.85613610152177,
                        242.09543771126374
                    ],
                    [
                        8.526670854284589,
                        237.37368387198012
                    ],
                    [
                        7.042791387052603,
                        235.6140972721712
                    ],
                    [
                        7.312587653821879,
                        234.4035387009302
                    ],
                    [
                        5.109251475204701,
                        233.36851470106518
                    ],
                    [
                        5.334081697512602,
                        232.3109469365628
                    ],
                    [
                        4.030066408126913,
                        232.1566741195603
                    ],
                    [
                        3.782753163588154,
                        230.8338729840525
                    ],
                    [
                        1.309620718201586,
                        230.4589283415172
                    ],
                    [
                        0.702579117970231,
                        229.22334345541645
                    ],
                    [
                        1.759281162817274,
                        228.9364072100185
                    ],
                    [
                        2.298873696356168,
                        226.06487771093498
                    ],
                    [
                        4.2099305859730975,
                        224.6055083557327
                    ],
                    [
                        10.302829610516824,
                        224.51702875076313
                    ],
                    [
                        10.460210766132263,
                        219.29000977355628
                    ],
                    [
                        11.696776988825832,
                        220.1990471966829
                    ],
                    [
                        13.135690411596215,
                        219.17912395208293
                    ],
                    [
                        13.967562234135357,
                        220.465030723025
                    ],
                    [
                        15.316543567982649,
                        219.64480405838214
                    ],
                    [
                        16.77794001298389,
                        220.24338017828967
                    ],
                    [
                        18.531615746985608,
                        219.53393744564943
                    ],
                    [
                        21.162129347987616,
                        219.75566467309886
                    ],
                    [
                        22.668491837450574,
                        219.33436242056325
                    ],
                    [
                        24.691963838221454,
                        221.30708474330936
                    ],
                    [
                        29.00870410653306,
                        221.30708474330936
                    ],
                    [
                        30.245270329226287,
                        219.31218621715476
                    ],
                    [
                        37.05762606515509,
                        217.24874701775877
                    ],
                    [
                        37.035143042924346,
                        219.9995369235179
                    ],
                    [
                        38.451573443463985,
                        220.53152122012204
                    ],
                    [
                        40.475045444234865,
                        220.50935796031672
                    ],
                    [
                        41.48678144462042,
                        219.31218621715476
                    ],
                    [
                        43.12804206746796,
                        218.15869223672456
                    ],
                    [
                        44.04984597893031,
                        218.3140081940855
                    ],
                    [
                        44.83675175700796,
                        217.0267475314228
                    ],
                    [
                        43.30790624531437,
                        216.89353622258778
                    ],
                    [
                        43.12804206746796,
                        214.4497799508872
                    ],
                    [
                        44.971649890392655,
                        213.16006596611408
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Kutch",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Surendranagar",
            "dt_code": "475",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        56.01081380570963,
                        250.10987980142187
                    ],
                    [
                        51.15448100385947,
                        250.56883981193528
                    ],
                    [
                        49.76053362555058,
                        251.8577908110779
                    ],
                    [
                        46.792774691086606,
                        251.61753464667612
                    ],
                    [
                        45.6686235795471,
                        253.38608647888077
                    ],
                    [
                        43.60018553431473,
                        250.69995378116596
                    ],
                    [
                        43.0605930007755,
                        249.41032906978398
                    ],
                    [
                        44.5444724680076,
                        249.60710008592224
                    ],
                    [
                        45.736072646239336,
                        247.98868388018843
                    ],
                    [
                        44.72433664585401,
                        247.04770618068176
                    ],
                    [
                        44.94916686816191,
                        245.79974149617613
                    ],
                    [
                        45.71358962400859,
                        245.14263213787228
                    ],
                    [
                        45.173997090469584,
                        243.32359789639304
                    ],
                    [
                        47.06257095785577,
                        242.68767312724083
                    ],
                    [
                        48.434035313934146,
                        238.51663976166117
                    ],
                    [
                        47.87195975816417,
                        237.35169797588736
                    ],
                    [
                        49.018593891934415,
                        237.74740957675016
                    ],
                    [
                        50.90716775932083,
                        237.54956291443068
                    ],
                    [
                        53.17795300463035,
                        237.81335440498498
                    ],
                    [
                        53.470232293630716,
                        237.17580267732228
                    ],
                    [
                        51.62662447070602,
                        234.11730595679484
                    ],
                    [
                        51.17696402609022,
                        232.4211349124144
                    ],
                    [
                        52.211183048706516,
                        232.13463422627513
                    ],
                    [
                        54.6843154940932,
                        232.59742373121333
                    ],
                    [
                        56.01081380570963,
                        232.55335290411728
                    ],
                    [
                        57.58462536186494,
                        231.67174333127906
                    ],
                    [
                        57.8769046508653,
                        232.26687013795782
                    ],
                    [
                        57.38227816178778,
                        233.89710066586065
                    ],
                    [
                        56.12322891686381,
                        233.98518552341682
                    ],
                    [
                        56.1681949613253,
                        235.6361010588675
                    ],
                    [
                        57.85442162863433,
                        236.7360011521351
                    ],
                    [
                        56.66282145040259,
                        237.26375213346256
                    ],
                    [
                        56.84268562824877,
                        239.04395263390592
                    ],
                    [
                        58.25911602878841,
                        239.17576064138086
                    ],
                    [
                        57.8769046508653,
                        242.2709317337413
                    ],
                    [
                        58.86615762901988,
                        243.3455230176241
                    ],
                    [
                        59.02353878463555,
                        246.60990473769982
                    ],
                    [
                        60.23762198509803,
                        246.71936328910954
                    ],
                    [
                        60.43996918517519,
                        248.38246204148197
                    ],
                    [
                        59.270852029173966,
                        249.08233841932707
                    ],
                    [
                        58.52891229555803,
                        247.94492640312774
                    ],
                    [
                        56.752753539325795,
                        247.6385996932678
                    ],
                    [
                        57.0675158505569,
                        248.79804042796673
                    ],
                    [
                        56.01081380570963,
                        250.10987980142187
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Surendranagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kheda",
            "dt_code": "483",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        77.88679443626665,
                        243.411297054812
                    ],
                    [
                        76.78512634695835,
                        243.56476207249
                    ],
                    [
                        76.69519425803514,
                        245.40549958281355
                    ],
                    [
                        75.95325452441898,
                        246.01873408879453
                    ],
                    [
                        74.15461274595577,
                        244.41958345031088
                    ],
                    [
                        72.78314838987785,
                        244.50723847834172
                    ],
                    [
                        72.51335212310846,
                        245.6245316414043
                    ],
                    [
                        71.52409914495365,
                        246.54422698428078
                    ],
                    [
                        70.24256687779871,
                        246.56611978734105
                    ],
                    [
                        68.91606856618228,
                        245.73403944500956
                    ],
                    [
                        68.1516458103356,
                        246.39097124082798
                    ],
                    [
                        67.63453629902756,
                        248.05431846463466
                    ],
                    [
                        66.51038518748783,
                        247.98868388018843
                    ],
                    [
                        65.52113220933325,
                        246.30339171862346
                    ],
                    [
                        65.70099638717966,
                        243.3455230176241
                    ],
                    [
                        67.49963816564264,
                        241.72246571256994
                    ],
                    [
                        69.65800829979821,
                        241.56887033362534
                    ],
                    [
                        70.35498198895266,
                        240.14210614537546
                    ],
                    [
                        71.63651425610783,
                        238.80228363063338
                    ],
                    [
                        72.85059745657009,
                        238.45071655018512
                    ],
                    [
                        72.91804652326255,
                        237.5275788240191
                    ],
                    [
                        74.51434110164882,
                        237.10983821305052
                    ],
                    [
                        75.70594127988034,
                        237.74740957675016
                    ],
                    [
                        76.74016030249663,
                        239.15379320112373
                    ],
                    [
                        76.29049985788106,
                        239.7907579432922
                    ],
                    [
                        77.86431141403591,
                        241.30553860352904
                    ],
                    [
                        78.94349648111393,
                        240.976328843182
                    ],
                    [
                        77.88679443626665,
                        243.411297054812
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Kheda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Anand",
            "dt_code": "482",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        75.95325452441898,
                        246.01873408879453
                    ],
                    [
                        75.03145061295663,
                        246.65368881382233
                    ],
                    [
                        73.43515603457081,
                        250.2410209396034
                    ],
                    [
                        73.61502021241722,
                        251.02770478679383
                    ],
                    [
                        71.95127656733871,
                        251.35540738433673
                    ],
                    [
                        67.72446838795031,
                        251.57384891733625
                    ],
                    [
                        65.61106429825645,
                        250.8966101966913
                    ],
                    [
                        64.80167549794805,
                        251.92331071393085
                    ],
                    [
                        63.5651092752546,
                        251.00585622640926
                    ],
                    [
                        63.5651092752546,
                        248.4918323715866
                    ],
                    [
                        64.48691318671695,
                        247.92304733831753
                    ],
                    [
                        64.30704900887054,
                        246.9382640084806
                    ],
                    [
                        65.52113220933325,
                        246.30339171862346
                    ],
                    [
                        66.51038518748783,
                        247.98868388018843
                    ],
                    [
                        67.63453629902756,
                        248.05431846463466
                    ],
                    [
                        68.1516458103356,
                        246.39097124082798
                    ],
                    [
                        68.91606856618228,
                        245.73403944500956
                    ],
                    [
                        70.24256687779871,
                        246.56611978734105
                    ],
                    [
                        71.52409914495365,
                        246.54422698428078
                    ],
                    [
                        72.51335212310846,
                        245.6245316414043
                    ],
                    [
                        72.78314838987785,
                        244.50723847834172
                    ],
                    [
                        74.15461274595577,
                        244.41958345031088
                    ],
                    [
                        75.95325452441898,
                        246.01873408879453
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Anand",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kishanganj",
            "dt_code": "210",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        294.89292500783677,
                        182.62912399451233
                    ],
                    [
                        296.8039818974537,
                        184.09721403232572
                    ],
                    [
                        297.38854047545396,
                        186.1957326708128
                    ],
                    [
                        295.4325175413753,
                        187.50331256193084
                    ],
                    [
                        294.35333247429776,
                        188.74243025542103
                    ],
                    [
                        292.9144190515274,
                        189.26036830341496
                    ],
                    [
                        291.0708112286027,
                        190.97081945604486
                    ],
                    [
                        291.25067540644886,
                        190.07077241700466
                    ],
                    [
                        288.64264482767726,
                        189.9357288924137
                    ],
                    [
                        288.95740713890837,
                        188.96763796238548
                    ],
                    [
                        287.7658069606766,
                        185.9476418675353
                    ],
                    [
                        288.2604334497539,
                        185.38367848827244
                    ],
                    [
                        291.04832820637193,
                        184.45841513554478
                    ],
                    [
                        293.76877389629726,
                        185.18061060814762
                    ],
                    [
                        294.7355438522211,
                        184.14236794298233
                    ],
                    [
                        294.89292500783677,
                        182.62912399451233
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Kishanganj",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Morbi",
            "dt_code": "727",
            "st_nm": "Gujarat",
            "st_code": "24",
            "alpha2code": "GJ",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        43.0605930007755,
                        249.41032906978398
                    ],
                    [
                        40.317664288619426,
                        248.4480848909712
                    ],
                    [
                        38.833784821387326,
                        249.060470643421
                    ],
                    [
                        37.17004117630904,
                        246.41286557451468
                    ],
                    [
                        38.114328110002134,
                        244.77018242841444
                    ],
                    [
                        38.114328110002134,
                        242.2709317337413
                    ],
                    [
                        37.1475581540783,
                        241.415264053069
                    ],
                    [
                        35.93347495361559,
                        241.15191362081123
                    ],
                    [
                        36.87776188730868,
                        239.61506235483483
                    ],
                    [
                        38.76633575469509,
                        238.2089807853951
                    ],
                    [
                        40.63242659985053,
                        237.4616251988179
                    ],
                    [
                        42.81327975623685,
                        237.61551383193972
                    ],
                    [
                        45.129031046008095,
                        236.80197714747953
                    ],
                    [
                        47.87195975816417,
                        237.35169797588736
                    ],
                    [
                        48.434035313934146,
                        238.51663976166117
                    ],
                    [
                        47.06257095785577,
                        242.68767312724083
                    ],
                    [
                        45.173997090469584,
                        243.32359789639304
                    ],
                    [
                        45.71358962400859,
                        245.14263213787228
                    ],
                    [
                        44.94916686816191,
                        245.79974149617613
                    ],
                    [
                        44.72433664585401,
                        247.04770618068176
                    ],
                    [
                        45.736072646239336,
                        247.98868388018843
                    ],
                    [
                        44.5444724680076,
                        249.60710008592224
                    ],
                    [
                        43.0605930007755,
                        249.41032906978398
                    ]
                ]
            ]
        },
        "id": "TT-Gujarat-Morbi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Leh",
            "dt_code": "003",
            "st_nm": "Ladakh",
            "st_code": "38",
            "alpha2code": "LA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        152.10325082009945,
                        81.5429352661858
                    ],
                    [
                        150.84420157517548,
                        80.70367688304464
                    ],
                    [
                        152.2156659312534,
                        79.88796604314695
                    ],
                    [
                        151.94586966448378,
                        77.48634369150966
                    ],
                    [
                        149.4727372190971,
                        79.11984905002049
                    ],
                    [
                        148.3485861075576,
                        78.80769353942111
                    ],
                    [
                        147.69657846286486,
                        79.69597220535945
                    ],
                    [
                        146.48249526240215,
                        79.88796604314695
                    ],
                    [
                        145.04358183963177,
                        78.66360069822059
                    ],
                    [
                        145.29089508417064,
                        78.03904447629282
                    ],
                    [
                        143.4697702834767,
                        74.86434805482429
                    ],
                    [
                        142.4355512608604,
                        73.78057941221118
                    ],
                    [
                        140.4120792600895,
                        74.23826309741764
                    ],
                    [
                        138.43357330378012,
                        75.73081783944122
                    ],
                    [
                        137.51176939231777,
                        75.2976434304428
                    ],
                    [
                        136.52251641416296,
                        76.4525067757196
                    ],
                    [
                        135.57822948046987,
                        75.4420483335183
                    ],
                    [
                        136.23023712516283,
                        75.08101087273508
                    ],
                    [
                        135.6231955249316,
                        73.32276036656424
                    ],
                    [
                        136.5449994363937,
                        71.75551127855681
                    ],
                    [
                        137.55673543677926,
                        71.75551127855681
                    ],
                    [
                        137.7141165923947,
                        69.77609254645984
                    ],
                    [
                        138.56847143716482,
                        68.42282993569256
                    ],
                    [
                        138.7933016594725,
                        66.41490417812133
                    ],
                    [
                        138.05136192585655,
                        64.59824559473816
                    ],
                    [
                        135.9604408583932,
                        64.71942338222118
                    ],
                    [
                        134.61145952454604,
                        62.027007622070755
                    ],
                    [
                        132.3406742792365,
                        60.7883528609932
                    ],
                    [
                        130.676930634158,
                        60.61826255229994
                    ],
                    [
                        129.91250787831132,
                        59.475733407796156
                    ],
                    [
                        128.00145098869416,
                        59.06226598225953
                    ],
                    [
                        126.33770734361565,
                        57.26114909067701
                    ],
                    [
                        125.28100529876883,
                        58.01593218837138
                    ],
                    [
                        124.42665045399872,
                        56.579085115988164
                    ],
                    [
                        125.37093738769204,
                        54.79939472707974
                    ],
                    [
                        126.96723196607786,
                        54.18942779188262
                    ],
                    [
                        126.71991872153944,
                        51.69819239932076
                    ],
                    [
                        127.48434147738612,
                        49.839281235976046
                    ],
                    [
                        126.04542805461551,
                        48.908957918894316
                    ],
                    [
                        125.03369205422996,
                        46.9239610094794
                    ],
                    [
                        125.10114112092242,
                        45.451883913690835
                    ],
                    [
                        123.79712583153673,
                        44.32230229052266
                    ],
                    [
                        123.03270307569005,
                        42.773868158704545
                    ],
                    [
                        118.80589489630188,
                        43.8063374672231
                    ],
                    [
                        116.44517756206892,
                        42.52793590154454
                    ],
                    [
                        115.27606040606793,
                        45.91819946225496
                    ],
                    [
                        113.74721489437434,
                        46.72776826934114
                    ],
                    [
                        110.95932013775655,
                        46.67871604052277
                    ],
                    [
                        109.61033880390914,
                        47.48787108408908
                    ],
                    [
                        109.0032972036779,
                        48.78650377372867
                    ],
                    [
                        107.78921400321519,
                        49.17832166734854
                    ],
                    [
                        107.65431586983073,
                        48.713026461248546
                    ],
                    [
                        106.8449270695221,
                        47.880030415525084
                    ],
                    [
                        107.38451960306134,
                        46.188104728666985
                    ],
                    [
                        105.65332689129059,
                        44.420560940001224
                    ],
                    [
                        104.07951533513506,
                        44.1011965092199
                    ],
                    [
                        102.43825471228774,
                        44.46968782249576
                    ],
                    [
                        101.04430733397885,
                        43.8063374672231
                    ],
                    [
                        98.21144653289934,
                        43.65888593266857
                    ],
                    [
                        96.27790662105167,
                        42.4787445411356
                    ],
                    [
                        95.71583106528215,
                        41.91292619234332
                    ],
                    [
                        92.1410305305867,
                        41.593019937985446
                    ],
                    [
                        93.51249488666463,
                        41.37150576994429
                    ],
                    [
                        94.16450253135758,
                        40.41122658758843
                    ],
                    [
                        95.7832801319746,
                        40.04172185287791
                    ],
                    [
                        96.07555942097451,
                        37.600661431359185
                    ],
                    [
                        94.59167995374264,
                        35.798085324476006
                    ],
                    [
                        93.04035141981831,
                        36.73668909486253
                    ],
                    [
                        91.1517775524319,
                        37.1070252429389
                    ],
                    [
                        89.60044901850756,
                        36.02044055302349
                    ],
                    [
                        84.4293539054263,
                        33.37469421895037
                    ],
                    [
                        83.4850669717332,
                        33.86959023936231
                    ],
                    [
                        83.0354065271174,
                        31.343863350633796
                    ],
                    [
                        84.40687088319555,
                        29.48391236652924
                    ],
                    [
                        84.27197274981086,
                        28.317128004557162
                    ],
                    [
                        82.06863657119357,
                        27.472485575609653
                    ],
                    [
                        78.7186662588058,
                        27.621575908864884
                    ],
                    [
                        74.7391713239565,
                        24.811124136396074
                    ],
                    [
                        74.98648456849492,
                        22.593723518538468
                    ],
                    [
                        74.04219763480205,
                        21.845514780716144
                    ],
                    [
                        71.68148030056932,
                        22.518920159167465
                    ],
                    [
                        71.276785900415,
                        21.870461346258594
                    ],
                    [
                        68.17412883256611,
                        22.74331857051402
                    ],
                    [
                        65.88086056502561,
                        21.22170983656929
                    ],
                    [
                        66.78018145425744,
                        19.22371000213741
                    ],
                    [
                        65.83589452056435,
                        18.37371576899409
                    ],
                    [
                        66.78018145425744,
                        14.943606856039992
                    ],
                    [
                        69.47814412195203,
                        14.065976695122458
                    ],
                    [
                        71.00698963364539,
                        12.660641038962012
                    ],
                    [
                        70.9620235891839,
                        11.630852103039047
                    ],
                    [
                        72.60328421203167,
                        11.37957062940552
                    ],
                    [
                        74.3794429682639,
                        8.587347799984173
                    ],
                    [
                        74.58179016834083,
                        7.176562581921644
                    ],
                    [
                        77.34720190272787,
                        6.823645711432164
                    ],
                    [
                        79.21329274788332,
                        6.117546703150879
                    ],
                    [
                        83.0354065271174,
                        7.226972066739904
                    ],
                    [
                        84.60921808327248,
                        6.647154133549861
                    ],
                    [
                        85.4186068835811,
                        5.335380983938421
                    ],
                    [
                        83.37265186057925,
                        4.477021425574435
                    ],
                    [
                        82.87802537150174,
                        3.036829193296626
                    ],
                    [
                        85.64343710588878,
                        3.3654241873783803
                    ],
                    [
                        88.25146768466016,
                        4.982002274372007
                    ],
                    [
                        89.53299995181533,
                        4.376003478836424
                    ],
                    [
                        89.9152113297389,
                        3.2137745228911854
                    ],
                    [
                        91.26419266358607,
                        3.517057464263644
                    ],
                    [
                        93.73732510897275,
                        1.620461960669104
                    ],
                    [
                        95.24368759843537,
                        1.6710711692519453
                    ],
                    [
                        96.05307639874377,
                        0.6078932823550076
                    ],
                    [
                        97.85171817720698,
                        -1.1368683772161603e-13
                    ],
                    [
                        98.23392955513009,
                        1.038324510707298
                    ],
                    [
                        99.78525808905488,
                        1.013008692723929
                    ],
                    [
                        100.97685826728639,
                        3.1632209918844865
                    ],
                    [
                        102.39328866782625,
                        1.8734897144871638
                    ],
                    [
                        103.5399228015965,
                        2.480569910103327
                    ],
                    [
                        105.20366644667479,
                        0.75982531231125
                    ],
                    [
                        107.18217240298418,
                        0.4559447428854355
                    ],
                    [
                        107.29458751413813,
                        1.7722840991913245
                    ],
                    [
                        108.35128955898517,
                        2.1517674566573533
                    ],
                    [
                        109.09322929260111,
                        4.906266710568389
                    ],
                    [
                        111.18415036006468,
                        5.814824437557718
                    ],
                    [
                        113.14017329414332,
                        8.990170517877345
                    ],
                    [
                        115.70323782845298,
                        10.550025534075075
                    ],
                    [
                        117.97402307376274,
                        11.077974055100526
                    ],
                    [
                        120.58205365253389,
                        13.388577614560518
                    ],
                    [
                        121.99848405307375,
                        13.16270617370742
                    ],
                    [
                        123.19008423130526,
                        14.116141572180027
                    ],
                    [
                        123.90954094269068,
                        15.870799655081896
                    ],
                    [
                        125.6632166766924,
                        16.697247037228067
                    ],
                    [
                        126.29274129915439,
                        16.37173400031793
                    ],
                    [
                        127.41689241069366,
                        18.198654268137147
                    ],
                    [
                        128.6084925889254,
                        18.84877476101599
                    ],
                    [
                        127.46185845515538,
                        20.572665113038624
                    ],
                    [
                        128.76587374454107,
                        22.54385504447214
                    ],
                    [
                        130.5869985452348,
                        23.640562545304306
                    ],
                    [
                        133.23999516846789,
                        23.391384183404398
                    ],
                    [
                        133.98193490208382,
                        24.53724807140452
                    ],
                    [
                        137.87149774801014,
                        25.98073689692319
                    ],
                    [
                        138.36612423708766,
                        27.447635694429096
                    ],
                    [
                        137.9389468147026,
                        28.466131465904596
                    ],
                    [
                        140.54697739347398,
                        29.335013396218415
                    ],
                    [
                        142.165754994091,
                        29.21091925418341
                    ],
                    [
                        143.5597023723999,
                        28.540627455111917
                    ],
                    [
                        144.77378557286238,
                        29.359830951742197
                    ],
                    [
                        145.74055552878622,
                        27.472485575609653
                    ],
                    [
                        146.84222361809498,
                        27.199113423099107
                    ],
                    [
                        148.2586540186344,
                        28.06875486417755
                    ],
                    [
                        149.157974907866,
                        25.70708262742778
                    ],
                    [
                        150.237159974944,
                        24.761332355114973
                    ],
                    [
                        151.9009036200223,
                        24.512347668798384
                    ],
                    [
                        152.46297917579182,
                        23.615646648654092
                    ],
                    [
                        153.90189259856243,
                        24.01424930324788
                    ],
                    [
                        156.0152966882565,
                        22.294486738018236
                    ],
                    [
                        158.33104797802775,
                        22.34436385824131
                    ],
                    [
                        160.35451997879886,
                        21.670876695915922
                    ],
                    [
                        161.25384086803024,
                        22.469049091870488
                    ],
                    [
                        162.87261846864703,
                        22.693455281324987
                    ],
                    [
                        163.79442238010938,
                        21.77067248589458
                    ],
                    [
                        164.10918469134026,
                        20.0981774741565
                    ],
                    [
                        166.49238504780396,
                        19.798420159464513
                    ],
                    [
                        167.14439269249692,
                        21.59602530474058
                    ],
                    [
                        173.73191820611783,
                        23.665478011052983
                    ],
                    [
                        175.620492073504,
                        22.668522988723453
                    ],
                    [
                        177.21678665189006,
                        25.50802877818819
                    ],
                    [
                        179.91474931958487,
                        26.82624999339498
                    ],
                    [
                        180.45434185312342,
                        29.31019541643866
                    ],
                    [
                        178.9479793636608,
                        33.82010818342826
                    ],
                    [
                        178.3184547411986,
                        34.78470619075506
                    ],
                    [
                        178.20603963004464,
                        37.72404454794969
                    ],
                    [
                        176.6996771405818,
                        40.85451004693152
                    ],
                    [
                        176.6547110961203,
                        42.89681895263084
                    ],
                    [
                        174.8111032731956,
                        43.48684058340018
                    ],
                    [
                        173.57453705050239,
                        43.3393572266541
                    ],
                    [
                        172.33797082780893,
                        44.44512458474571
                    ],
                    [
                        172.60776709457832,
                        46.997526616750065
                    ],
                    [
                        169.6624911823451,
                        46.70324235716225
                    ],
                    [
                        168.40344193742112,
                        47.169165557064844
                    ],
                    [
                        169.64000816011435,
                        50.59780030948525
                    ],
                    [
                        168.38095891519015,
                        51.96705417758608
                    ],
                    [
                        168.20109473734396,
                        53.21296553512548
                    ],
                    [
                        166.1551397143421,
                        54.97014115819752
                    ],
                    [
                        163.83938842457087,
                        54.70181663811411
                    ],
                    [
                        162.91758451310852,
                        55.14086822048182
                    ],
                    [
                        161.25384086803024,
                        54.70181663811411
                    ],
                    [
                        159.54513117849024,
                        56.042961304102846
                    ],
                    [
                        160.93907855679913,
                        59.086590720615845
                    ],
                    [
                        161.61356922372283,
                        59.76752483940447
                    ],
                    [
                        162.51289011295444,
                        62.391125843137274
                    ],
                    [
                        160.7816974011837,
                        62.488209319185785
                    ],
                    [
                        159.68002931187516,
                        63.14336087915535
                    ],
                    [
                        159.94982557864455,
                        65.6400605402414
                    ],
                    [
                        160.7816974011837,
                        67.38290755556807
                    ],
                    [
                        162.55785615741615,
                        68.10850912256831
                    ],
                    [
                        164.46891304703308,
                        68.01178207462499
                    ],
                    [
                        167.61653615934347,
                        68.7129151229044
                    ],
                    [
                        166.46990202557322,
                        71.75551127855681
                    ],
                    [
                        167.14439269249692,
                        73.92512571740326
                    ],
                    [
                        169.28027980442175,
                        76.98153242932756
                    ],
                    [
                        170.1571176714226,
                        77.79876388548615
                    ],
                    [
                        167.52660407042026,
                        81.42306874600308
                    ],
                    [
                        165.63803020303408,
                        80.36784747903289
                    ],
                    [
                        163.79442238010938,
                        82.28590314150932
                    ],
                    [
                        162.94006753533927,
                        82.38174432709638
                    ],
                    [
                        162.6253052241084,
                        84.0101500161054
                    ],
                    [
                        161.43370504587665,
                        83.96227980826217
                    ],
                    [
                        160.66928229002974,
                        84.7997982543663
                    ],
                    [
                        158.8481574893358,
                        83.02851927297297
                    ],
                    [
                        157.65655731110428,
                        80.96749228521412
                    ],
                    [
                        157.43172708879615,
                        79.2879069299322
                    ],
                    [
                        155.9703306437948,
                        79.2879069299322
                    ],
                    [
                        152.98008868710008,
                        80.5837460863412
                    ],
                    [
                        152.10325082009945,
                        81.5429352661858
                    ]
                ]
            ]
        },
        "id": "TT-Ladakh-Leh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kargil",
            "dt_code": "004",
            "st_nm": "Ladakh",
            "st_code": "38",
            "alpha2code": "LA",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        107.78921400321519,
                        49.17832166734854
                    ],
                    [
                        109.0032972036779,
                        48.78650377372867
                    ],
                    [
                        109.61033880390914,
                        47.48787108408908
                    ],
                    [
                        110.95932013775655,
                        46.67871604052277
                    ],
                    [
                        113.74721489437434,
                        46.72776826934114
                    ],
                    [
                        115.27606040606793,
                        45.91819946225496
                    ],
                    [
                        116.44517756206892,
                        42.52793590154454
                    ],
                    [
                        118.80589489630188,
                        43.8063374672231
                    ],
                    [
                        123.03270307569005,
                        42.773868158704545
                    ],
                    [
                        123.79712583153673,
                        44.32230229052266
                    ],
                    [
                        125.10114112092242,
                        45.451883913690835
                    ],
                    [
                        125.03369205422996,
                        46.9239610094794
                    ],
                    [
                        126.04542805461551,
                        48.908957918894316
                    ],
                    [
                        127.48434147738612,
                        49.839281235976046
                    ],
                    [
                        126.71991872153944,
                        51.69819239932076
                    ],
                    [
                        126.96723196607786,
                        54.18942779188262
                    ],
                    [
                        125.37093738769204,
                        54.79939472707974
                    ],
                    [
                        124.42665045399872,
                        56.579085115988164
                    ],
                    [
                        125.28100529876883,
                        58.01593218837138
                    ],
                    [
                        126.33770734361565,
                        57.26114909067701
                    ],
                    [
                        128.00145098869416,
                        59.06226598225953
                    ],
                    [
                        129.91250787831132,
                        59.475733407796156
                    ],
                    [
                        130.676930634158,
                        60.61826255229994
                    ],
                    [
                        132.3406742792365,
                        60.7883528609932
                    ],
                    [
                        134.61145952454604,
                        62.027007622070755
                    ],
                    [
                        135.9604408583932,
                        64.71942338222118
                    ],
                    [
                        138.05136192585655,
                        64.59824559473816
                    ],
                    [
                        138.7933016594725,
                        66.41490417812133
                    ],
                    [
                        138.56847143716482,
                        68.42282993569256
                    ],
                    [
                        137.7141165923947,
                        69.77609254645984
                    ],
                    [
                        137.55673543677926,
                        71.75551127855681
                    ],
                    [
                        136.5449994363937,
                        71.75551127855681
                    ],
                    [
                        135.6231955249316,
                        73.32276036656424
                    ],
                    [
                        136.23023712516283,
                        75.08101087273508
                    ],
                    [
                        135.57822948046987,
                        75.4420483335183
                    ],
                    [
                        133.75710467977592,
                        73.63601960847268
                    ],
                    [
                        131.68866663454355,
                        73.44325219621123
                    ],
                    [
                        130.56451552300405,
                        72.7442688729434
                    ],
                    [
                        129.8675418338496,
                        71.10403215067163
                    ],
                    [
                        128.85580583346427,
                        70.42813324983808
                    ],
                    [
                        128.2037981887711,
                        68.83376778306638
                    ],
                    [
                        124.22430325392179,
                        64.16192588598028
                    ],
                    [
                        121.39144245284228,
                        63.1918794136177
                    ],
                    [
                        120.82936689707276,
                        61.92989470587679
                    ],
                    [
                        120.73943480814955,
                        60.180799903666184
                    ],
                    [
                        119.34548742984066,
                        59.23253094553536
                    ],
                    [
                        117.54684565137768,
                        56.8714359911105
                    ],
                    [
                        117.16463427345434,
                        55.652933286405755
                    ],
                    [
                        114.84888298368287,
                        55.99421332157408
                    ],
                    [
                        112.24085240491172,
                        52.773350273745336
                    ],
                    [
                        110.89187107106432,
                        52.08924813068256
                    ],
                    [
                        109.45295764829393,
                        51.453730717698704
                    ],
                    [
                        108.21639142560048,
                        50.573337958388606
                    ],
                    [
                        107.78921400321519,
                        49.17832166734854
                    ]
                ]
            ]
        },
        "id": "TT-Ladakh-Kargil",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kupwara",
            "dt_code": "001",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        92.1410305305867,
                        41.593019937985446
                    ],
                    [
                        95.71583106528215,
                        41.91292619234332
                    ],
                    [
                        96.27790662105167,
                        42.4787445411356
                    ],
                    [
                        95.49100084297402,
                        45.74641603131249
                    ],
                    [
                        93.7598081312035,
                        47.90453694584539
                    ],
                    [
                        92.54572493074079,
                        49.44763681051427
                    ],
                    [
                        90.14004155204657,
                        49.937176299553016
                    ],
                    [
                        89.51051692958458,
                        51.28258379170086
                    ],
                    [
                        87.35214679542878,
                        51.038054235443155
                    ],
                    [
                        86.52027497288941,
                        49.69242661124292
                    ],
                    [
                        83.91224439411803,
                        49.34970965264256
                    ],
                    [
                        84.63170110550323,
                        48.05156766326081
                    ],
                    [
                        85.86826732819668,
                        47.61043196842115
                    ],
                    [
                        86.9474523952747,
                        45.32914495154398
                    ],
                    [
                        87.10483355089013,
                        43.3885199782336
                    ],
                    [
                        89.75783017412323,
                        43.43768109707537
                    ],
                    [
                        92.1410305305867,
                        41.593019937985446
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Kupwara",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bandipora",
            "dt_code": "009",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        96.27790662105167,
                        42.4787445411356
                    ],
                    [
                        98.21144653289934,
                        43.65888593266857
                    ],
                    [
                        101.04430733397885,
                        43.8063374672231
                    ],
                    [
                        102.43825471228774,
                        44.46968782249576
                    ],
                    [
                        104.07951533513506,
                        44.1011965092199
                    ],
                    [
                        105.65332689129059,
                        44.420560940001224
                    ],
                    [
                        107.38451960306134,
                        46.188104728666985
                    ],
                    [
                        106.8449270695221,
                        47.880030415525084
                    ],
                    [
                        107.65431586983073,
                        48.713026461248546
                    ],
                    [
                        104.32682857967416,
                        49.47211759642266
                    ],
                    [
                        102.39328866782625,
                        47.85552348205988
                    ],
                    [
                        100.66209595605551,
                        47.634942934840524
                    ],
                    [
                        100.5721638671323,
                        48.933447541827945
                    ],
                    [
                        98.39131071074576,
                        50.03506494949954
                    ],
                    [
                        97.24467657697573,
                        49.88822956957699
                    ],
                    [
                        97.13226146582178,
                        51.57596654641134
                    ],
                    [
                        97.73930306605303,
                        52.33360615550191
                    ],
                    [
                        97.51447284374512,
                        52.45577023398937
                    ],
                    [
                        95.53596688743573,
                        51.30703455041305
                    ],
                    [
                        95.04134039835822,
                        50.32869243497669
                    ],
                    [
                        95.40106875405104,
                        48.541565319990355
                    ],
                    [
                        93.7598081312035,
                        47.90453694584539
                    ],
                    [
                        95.49100084297402,
                        45.74641603131249
                    ],
                    [
                        96.27790662105167,
                        42.4787445411356
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Bandipora",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Baramulla",
            "dt_code": "008",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        87.35214679542878,
                        51.038054235443155
                    ],
                    [
                        89.51051692958458,
                        51.28258379170086
                    ],
                    [
                        90.14004155204657,
                        49.937176299553016
                    ],
                    [
                        92.54572493074079,
                        49.44763681051427
                    ],
                    [
                        93.7598081312035,
                        47.90453694584539
                    ],
                    [
                        95.40106875405104,
                        48.541565319990355
                    ],
                    [
                        95.04134039835822,
                        50.32869243497669
                    ],
                    [
                        95.53596688743573,
                        51.30703455041305
                    ],
                    [
                        97.51447284374512,
                        52.45577023398937
                    ],
                    [
                        97.6268879548993,
                        53.16412575337711
                    ],
                    [
                        96.50273684335957,
                        53.57921326754706
                    ],
                    [
                        94.43429879812697,
                        55.531028823152724
                    ],
                    [
                        93.55746093112634,
                        57.23679496883187
                    ],
                    [
                        91.06184546350892,
                        55.238417849120765
                    ],
                    [
                        86.76758821742828,
                        55.3115759231423
                    ],
                    [
                        85.89075035042742,
                        54.45784372149035
                    ],
                    [
                        86.45282590619718,
                        53.11528438253083
                    ],
                    [
                        87.48704492881348,
                        52.33360615550191
                    ],
                    [
                        87.35214679542878,
                        51.038054235443155
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Baramulla",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ganderbal",
            "dt_code": "011",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        107.65431586983073,
                        48.713026461248546
                    ],
                    [
                        107.78921400321519,
                        49.17832166734854
                    ],
                    [
                        108.21639142560048,
                        50.573337958388606
                    ],
                    [
                        109.45295764829393,
                        51.453730717698704
                    ],
                    [
                        107.67679889206147,
                        51.94261419127952
                    ],
                    [
                        106.10298733590616,
                        51.06250898895041
                    ],
                    [
                        103.69730395721194,
                        52.65121206197705
                    ],
                    [
                        102.30335657890305,
                        52.748923426912484
                    ],
                    [
                        101.13423942290206,
                        52.01593295443149
                    ],
                    [
                        99.94263924467032,
                        53.40830877546398
                    ],
                    [
                        97.73930306605303,
                        52.33360615550191
                    ],
                    [
                        97.13226146582178,
                        51.57596654641134
                    ],
                    [
                        97.24467657697573,
                        49.88822956957699
                    ],
                    [
                        98.39131071074576,
                        50.03506494949954
                    ],
                    [
                        100.5721638671323,
                        48.933447541827945
                    ],
                    [
                        100.66209595605551,
                        47.634942934840524
                    ],
                    [
                        102.39328866782625,
                        47.85552348205988
                    ],
                    [
                        104.32682857967416,
                        49.47211759642266
                    ],
                    [
                        107.65431586983073,
                        48.713026461248546
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Ganderbal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Anantnag",
            "dt_code": "014",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        109.45295764829393,
                        51.453730717698704
                    ],
                    [
                        110.89187107106432,
                        52.08924813068256
                    ],
                    [
                        109.97006715960197,
                        53.505970866660164
                    ],
                    [
                        109.8126860039863,
                        54.77500079811102
                    ],
                    [
                        108.71101791467754,
                        54.89696648852066
                    ],
                    [
                        107.94659515883086,
                        57.33420909972472
                    ],
                    [
                        109.8126860039863,
                        60.64256233491335
                    ],
                    [
                        109.92510111514025,
                        62.658090501183324
                    ],
                    [
                        108.86839907029321,
                        64.47705818764962
                    ],
                    [
                        109.09322929260111,
                        66.22123005331662
                    ],
                    [
                        107.76673098098445,
                        66.85058139792181
                    ],
                    [
                        104.37179462413565,
                        63.45870373798448
                    ],
                    [
                        103.69730395721194,
                        62.12411433717796
                    ],
                    [
                        103.8996511572891,
                        59.76752483940447
                    ],
                    [
                        102.6406019123649,
                        59.50005150669119
                    ],
                    [
                        102.61811889013416,
                        59.50005150669119
                    ],
                    [
                        102.59563586790341,
                        59.50005150669119
                    ],
                    [
                        102.7755000457496,
                        58.91630934448631
                    ],
                    [
                        104.97883622436666,
                        57.309856156096885
                    ],
                    [
                        105.47346271344418,
                        56.384153054324656
                    ],
                    [
                        105.15870040221307,
                        54.384644128717355
                    ],
                    [
                        104.3493116019049,
                        54.09181011690566
                    ],
                    [
                        103.69730395721194,
                        52.65121206197705
                    ],
                    [
                        106.10298733590616,
                        51.06250898895041
                    ],
                    [
                        107.67679889206147,
                        51.94261419127952
                    ],
                    [
                        109.45295764829393,
                        51.453730717698704
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Anantnag",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kishtwar",
            "dt_code": "018",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        110.89187107106432,
                        52.08924813068256
                    ],
                    [
                        112.24085240491172,
                        52.773350273745336
                    ],
                    [
                        114.84888298368287,
                        55.99421332157408
                    ],
                    [
                        117.16463427345434,
                        55.652933286405755
                    ],
                    [
                        117.54684565137768,
                        56.8714359911105
                    ],
                    [
                        119.34548742984066,
                        59.23253094553536
                    ],
                    [
                        120.73943480814955,
                        60.180799903666184
                    ],
                    [
                        120.82936689707276,
                        61.92989470587679
                    ],
                    [
                        121.39144245284228,
                        63.1918794136177
                    ],
                    [
                        124.22430325392179,
                        64.16192588598028
                    ],
                    [
                        128.2037981887711,
                        68.83376778306638
                    ],
                    [
                        127.84406983307872,
                        70.09007254820813
                    ],
                    [
                        126.33770734361565,
                        70.40398851811904
                    ],
                    [
                        125.21355623207637,
                        69.55868423644347
                    ],
                    [
                        122.87532192007438,
                        69.96931858990149
                    ],
                    [
                        121.14412920830364,
                        71.41774163535979
                    ],
                    [
                        120.10991018568734,
                        72.86480578518058
                    ],
                    [
                        119.97501205230265,
                        71.68313829403075
                    ],
                    [
                        118.98575907414829,
                        70.42813324983808
                    ],
                    [
                        116.82738893999226,
                        71.70726299935961
                    ],
                    [
                        114.78143391699064,
                        71.05576348737031
                    ],
                    [
                        112.3307844938347,
                        69.05127861904077
                    ],
                    [
                        111.02676920444901,
                        68.73708641555243
                    ],
                    [
                        109.09322929260111,
                        66.22123005331662
                    ],
                    [
                        108.86839907029321,
                        64.47705818764962
                    ],
                    [
                        109.92510111514025,
                        62.658090501183324
                    ],
                    [
                        109.8126860039863,
                        60.64256233491335
                    ],
                    [
                        107.94659515883086,
                        57.33420909972472
                    ],
                    [
                        108.71101791467754,
                        54.89696648852066
                    ],
                    [
                        109.8126860039863,
                        54.77500079811102
                    ],
                    [
                        109.97006715960197,
                        53.505970866660164
                    ],
                    [
                        110.89187107106432,
                        52.08924813068256
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Kishtwar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Srinagar",
            "dt_code": "010",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        97.51447284374512,
                        52.45577023398937
                    ],
                    [
                        97.73930306605303,
                        52.33360615550191
                    ],
                    [
                        99.94263924467032,
                        53.40830877546398
                    ],
                    [
                        101.13423942290206,
                        52.01593295443149
                    ],
                    [
                        102.30335657890305,
                        52.748923426912484
                    ],
                    [
                        103.69730395721194,
                        52.65121206197705
                    ],
                    [
                        104.3493116019049,
                        54.09181011690566
                    ],
                    [
                        103.24764351259614,
                        53.53038539714987
                    ],
                    [
                        100.88692617836318,
                        55.238417849120765
                    ],
                    [
                        97.6268879548993,
                        53.16412575337711
                    ],
                    [
                        97.51447284374512,
                        52.45577023398937
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Srinagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Budgam",
            "dt_code": "002",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        93.55746093112634,
                        57.23679496883187
                    ],
                    [
                        94.43429879812697,
                        55.531028823152724
                    ],
                    [
                        96.50273684335957,
                        53.57921326754706
                    ],
                    [
                        97.6268879548993,
                        53.16412575337711
                    ],
                    [
                        100.88692617836318,
                        55.238417849120765
                    ],
                    [
                        99.47049577782377,
                        58.01593218837138
                    ],
                    [
                        97.96413328836093,
                        59.06226598225953
                    ],
                    [
                        95.7832801319746,
                        61.055599132796715
                    ],
                    [
                        95.42355177628178,
                        61.24993052036393
                    ],
                    [
                        94.09705346466512,
                        59.98633155314997
                    ],
                    [
                        93.55746093112634,
                        57.23679496883187
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Budgam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pulwama",
            "dt_code": "012",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        104.3493116019049,
                        54.09181011690566
                    ],
                    [
                        105.15870040221307,
                        54.384644128717355
                    ],
                    [
                        105.47346271344418,
                        56.384153054324656
                    ],
                    [
                        104.97883622436666,
                        57.309856156096885
                    ],
                    [
                        102.7755000457496,
                        58.91630934448631
                    ],
                    [
                        100.32485062259366,
                        59.3541370576271
                    ],
                    [
                        97.96413328836093,
                        59.06226598225953
                    ],
                    [
                        99.47049577782377,
                        58.01593218837138
                    ],
                    [
                        100.88692617836318,
                        55.238417849120765
                    ],
                    [
                        103.24764351259614,
                        53.53038539714987
                    ],
                    [
                        104.3493116019049,
                        54.09181011690566
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Pulwama",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Punch",
            "dt_code": "005",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        85.89075035042742,
                        54.45784372149035
                    ],
                    [
                        86.76758821742828,
                        55.3115759231423
                    ],
                    [
                        91.06184546350892,
                        55.238417849120765
                    ],
                    [
                        93.55746093112634,
                        57.23679496883187
                    ],
                    [
                        94.09705346466512,
                        59.98633155314997
                    ],
                    [
                        95.42355177628178,
                        61.24993052036393
                    ],
                    [
                        94.99637435389673,
                        62.7066244994096
                    ],
                    [
                        95.87321222089736,
                        64.30737965055675
                    ],
                    [
                        95.19872155397388,
                        65.0102108437593
                    ],
                    [
                        93.64739302004955,
                        63.26465431808879
                    ],
                    [
                        90.52225292996968,
                        63.6527284528471
                    ],
                    [
                        90.76956617450878,
                        64.64671786390113
                    ],
                    [
                        89.89272830750792,
                        66.00331736010207
                    ],
                    [
                        89.60044901850756,
                        63.74973155009002
                    ],
                    [
                        88.38636581804508,
                        63.55571918270505
                    ],
                    [
                        86.74510519519754,
                        62.29403617232413
                    ],
                    [
                        85.1937766612732,
                        62.80368785410292
                    ],
                    [
                        83.08037257157912,
                        62.29403617232413
                    ],
                    [
                        81.52904403765456,
                        60.69116073294845
                    ],
                    [
                        80.9669684818848,
                        59.57300346157649
                    ],
                    [
                        81.39414590426986,
                        57.67510909065527
                    ],
                    [
                        80.40489292611528,
                        55.872336467055106
                    ],
                    [
                        79.91026643703776,
                        53.04201934623245
                    ],
                    [
                        80.58475710396146,
                        52.91990300385555
                    ],
                    [
                        82.33843283796296,
                        54.482242794018816
                    ],
                    [
                        84.58673506104174,
                        54.165023967419415
                    ],
                    [
                        85.89075035042742,
                        54.45784372149035
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Punch",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shopiyan",
            "dt_code": "013",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        102.7755000457496,
                        58.91630934448631
                    ],
                    [
                        102.59563586790341,
                        59.50005150669119
                    ],
                    [
                        101.42651871190242,
                        60.22941309500993
                    ],
                    [
                        101.62886591197935,
                        61.24993052036393
                    ],
                    [
                        100.23491853367045,
                        62.2697627864909
                    ],
                    [
                        97.44702377705266,
                        60.88553876641345
                    ],
                    [
                        95.7832801319746,
                        61.055599132796715
                    ],
                    [
                        97.96413328836093,
                        59.06226598225953
                    ],
                    [
                        100.32485062259366,
                        59.3541370576271
                    ],
                    [
                        102.7755000457496,
                        58.91630934448631
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Shopiyan",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kulgam",
            "dt_code": "015",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        104.37179462413565,
                        63.45870373798448
                    ],
                    [
                        102.48322075674946,
                        64.47705818764962
                    ],
                    [
                        99.13325044436169,
                        65.05867003870787
                    ],
                    [
                        97.89668422166847,
                        64.01645825220157
                    ],
                    [
                        96.6376349767445,
                        64.52553430504702
                    ],
                    [
                        95.87321222089736,
                        64.30737965055675
                    ],
                    [
                        94.99637435389673,
                        62.7066244994096
                    ],
                    [
                        95.42355177628178,
                        61.24993052036393
                    ],
                    [
                        95.7832801319746,
                        61.055599132796715
                    ],
                    [
                        97.44702377705266,
                        60.88553876641345
                    ],
                    [
                        100.23491853367045,
                        62.2697627864909
                    ],
                    [
                        101.62886591197935,
                        61.24993052036393
                    ],
                    [
                        101.42651871190242,
                        60.22941309500993
                    ],
                    [
                        102.59563586790341,
                        59.50005150669119
                    ],
                    [
                        102.61811889013416,
                        59.50005150669119
                    ],
                    [
                        102.6406019123649,
                        59.50005150669119
                    ],
                    [
                        103.8996511572891,
                        59.76752483940447
                    ],
                    [
                        103.69730395721194,
                        62.12411433717796
                    ],
                    [
                        104.37179462413565,
                        63.45870373798448
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Kulgam",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rajouri",
            "dt_code": "006",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        89.89272830750792,
                        66.00331736010207
                    ],
                    [
                        90.76956617450878,
                        64.64671786390113
                    ],
                    [
                        90.52225292996968,
                        63.6527284528471
                    ],
                    [
                        93.64739302004955,
                        63.26465431808879
                    ],
                    [
                        95.19872155397388,
                        65.0102108437593
                    ],
                    [
                        95.87321222089736,
                        64.30737965055675
                    ],
                    [
                        96.6376349767445,
                        64.52553430504702
                    ],
                    [
                        97.51447284374512,
                        66.92318221952564
                    ],
                    [
                        97.60440493266833,
                        69.00294553835704
                    ],
                    [
                        95.8282461764361,
                        72.7442688729434
                    ],
                    [
                        92.34337773066363,
                        74.04557066432005
                    ],
                    [
                        92.1410305305867,
                        72.72016036241996
                    ],
                    [
                        89.84776226304643,
                        71.92436837810192
                    ],
                    [
                        89.60044901850756,
                        70.79025881697237
                    ],
                    [
                        87.46456190658273,
                        69.41372828881254
                    ],
                    [
                        87.50952795104422,
                        68.6887434498974
                    ],
                    [
                        89.37561879619966,
                        67.84249506695915
                    ],
                    [
                        89.89272830750792,
                        66.00331736010207
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Rajouri",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ramban",
            "dt_code": "017",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        104.37179462413565,
                        63.45870373798448
                    ],
                    [
                        107.76673098098445,
                        66.85058139792181
                    ],
                    [
                        107.27210449190716,
                        68.01178207462499
                    ],
                    [
                        107.67679889206147,
                        69.67947042423589
                    ],
                    [
                        107.06975729183023,
                        70.76611976043341
                    ],
                    [
                        107.38451960306134,
                        72.02084985622002
                    ],
                    [
                        105.58587782459813,
                        70.62127747883119
                    ],
                    [
                        103.49495675713501,
                        69.96931858990149
                    ],
                    [
                        102.68556795682662,
                        69.60699984974303
                    ],
                    [
                        102.39328866782625,
                        67.76993777337759
                    ],
                    [
                        103.02281329028824,
                        66.94738172879977
                    ],
                    [
                        102.2808735566723,
                        65.9306728977914
                    ],
                    [
                        102.48322075674946,
                        64.47705818764962
                    ],
                    [
                        104.37179462413565,
                        63.45870373798448
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Ramban",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Reasi",
            "dt_code": "020",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        96.6376349767445,
                        64.52553430504702
                    ],
                    [
                        97.89668422166847,
                        64.01645825220157
                    ],
                    [
                        99.13325044436169,
                        65.05867003870787
                    ],
                    [
                        102.48322075674946,
                        64.47705818764962
                    ],
                    [
                        102.2808735566723,
                        65.9306728977914
                    ],
                    [
                        103.02281329028824,
                        66.94738172879977
                    ],
                    [
                        102.39328866782625,
                        67.76993777337759
                    ],
                    [
                        102.68556795682662,
                        69.60699984974303
                    ],
                    [
                        103.49495675713501,
                        69.96931858990149
                    ],
                    [
                        102.57315284567267,
                        71.49012707047734
                    ],
                    [
                        103.36005862375009,
                        72.09320700802027
                    ],
                    [
                        101.85369613428725,
                        74.50317606299359
                    ],
                    [
                        101.17920546736354,
                        75.20136602832349
                    ],
                    [
                        99.94263924467032,
                        73.41915458101846
                    ],
                    [
                        98.2788955995918,
                        72.18967793601871
                    ],
                    [
                        96.57018591005203,
                        73.25046076363788
                    ],
                    [
                        95.8282461764361,
                        72.7442688729434
                    ],
                    [
                        97.60440493266833,
                        69.00294553835704
                    ],
                    [
                        97.51447284374512,
                        66.92318221952564
                    ],
                    [
                        96.6376349767445,
                        64.52553430504702
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Reasi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Doda",
            "dt_code": "016",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        107.76673098098445,
                        66.85058139792181
                    ],
                    [
                        109.09322929260111,
                        66.22123005331662
                    ],
                    [
                        111.02676920444901,
                        68.73708641555243
                    ],
                    [
                        112.3307844938347,
                        69.05127861904077
                    ],
                    [
                        114.78143391699064,
                        71.05576348737031
                    ],
                    [
                        116.82738893999226,
                        71.70726299935961
                    ],
                    [
                        118.98575907414829,
                        70.42813324983808
                    ],
                    [
                        119.97501205230265,
                        71.68313829403075
                    ],
                    [
                        120.10991018568734,
                        72.86480578518058
                    ],
                    [
                        118.49113258507077,
                        73.12995391464801
                    ],
                    [
                        118.13140422937818,
                        73.99739380976803
                    ],
                    [
                        116.17538129529953,
                        75.46611451101239
                    ],
                    [
                        114.64653578360571,
                        74.47909493760369
                    ],
                    [
                        113.92707907222075,
                        75.24950547619221
                    ],
                    [
                        112.17340333821926,
                        75.65863049877316
                    ],
                    [
                        111.79119196029546,
                        74.57541719410096
                    ],
                    [
                        110.39724458198702,
                        73.85285425178108
                    ],
                    [
                        108.77846698137,
                        73.94921545629734
                    ],
                    [
                        107.38451960306134,
                        72.02084985622002
                    ],
                    [
                        107.06975729183023,
                        70.76611976043341
                    ],
                    [
                        107.67679889206147,
                        69.67947042423589
                    ],
                    [
                        107.27210449190716,
                        68.01178207462499
                    ],
                    [
                        107.76673098098445,
                        66.85058139792181
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Doda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Lahaul and Spiti",
            "dt_code": "025",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        135.57822948046987,
                        75.4420483335183
                    ],
                    [
                        136.52251641416296,
                        76.4525067757196
                    ],
                    [
                        137.51176939231777,
                        75.2976434304428
                    ],
                    [
                        138.43357330378012,
                        75.73081783944122
                    ],
                    [
                        140.4120792600895,
                        74.23826309741764
                    ],
                    [
                        142.4355512608604,
                        73.78057941221118
                    ],
                    [
                        143.4697702834767,
                        74.86434805482429
                    ],
                    [
                        145.29089508417064,
                        78.03904447629282
                    ],
                    [
                        145.04358183963177,
                        78.66360069822059
                    ],
                    [
                        146.48249526240215,
                        79.88796604314695
                    ],
                    [
                        147.69657846286486,
                        79.69597220535945
                    ],
                    [
                        148.3485861075576,
                        78.80769353942111
                    ],
                    [
                        149.4727372190971,
                        79.11984905002049
                    ],
                    [
                        151.94586966448378,
                        77.48634369150966
                    ],
                    [
                        152.2156659312534,
                        79.88796604314695
                    ],
                    [
                        150.84420157517548,
                        80.70367688304464
                    ],
                    [
                        152.10325082009945,
                        81.5429352661858
                    ],
                    [
                        152.9351226426386,
                        82.81295723342777
                    ],
                    [
                        154.10423979863936,
                        83.48349755673365
                    ],
                    [
                        153.31733402056193,
                        84.63233021284816
                    ],
                    [
                        153.33981704279267,
                        85.8281401694577
                    ],
                    [
                        155.20590788794811,
                        86.9513755199576
                    ],
                    [
                        154.93611162117872,
                        88.00219186966535
                    ],
                    [
                        156.1501948216412,
                        89.31473219745055
                    ],
                    [
                        153.72202842071624,
                        89.83944424514954
                    ],
                    [
                        152.84519055371538,
                        89.62481028623688
                    ],
                    [
                        152.41801313133033,
                        91.79366385778826
                    ],
                    [
                        149.2029409523277,
                        91.84129777532996
                    ],
                    [
                        146.66235944024857,
                        94.83937068136856
                    ],
                    [
                        144.23419303932337,
                        94.45897474189468
                    ],
                    [
                        142.81776263878373,
                        91.65075355558417
                    ],
                    [
                        143.28990610563028,
                        90.88834849014859
                    ],
                    [
                        142.48051730532188,
                        89.100027239121
                    ],
                    [
                        138.43357330378012,
                        86.7841357756879
                    ],
                    [
                        137.73659961462545,
                        87.3813393320554
                    ],
                    [
                        136.61244850308617,
                        86.37790837878072
                    ],
                    [
                        136.23023712516283,
                        85.06292634583912
                    ],
                    [
                        133.7795877020069,
                        83.45955461571117
                    ],
                    [
                        132.63295356823664,
                        83.74684583853576
                    ],
                    [
                        131.2614892121585,
                        83.65108793168264
                    ],
                    [
                        129.57526254484947,
                        82.62132168025937
                    ],
                    [
                        129.5977455670802,
                        81.39909434131044
                    ],
                    [
                        127.3494433440012,
                        81.30319305285428
                    ],
                    [
                        125.59576760999994,
                        79.59996643786678
                    ],
                    [
                        123.70719374261375,
                        79.33592014429604
                    ],
                    [
                        122.76290680892043,
                        77.9909913226524
                    ],
                    [
                        122.42566147545858,
                        76.40440460731139
                    ],
                    [
                        124.06692209830635,
                        76.71704209978793
                    ],
                    [
                        125.82059783230784,
                        75.82706240657302
                    ],
                    [
                        126.83233383269317,
                        75.92330100899295
                    ],
                    [
                        127.37192636623195,
                        74.67173346471645
                    ],
                    [
                        128.40614538884847,
                        74.06965852956489
                    ],
                    [
                        127.73165472192477,
                        71.41774163535979
                    ],
                    [
                        127.84406983307872,
                        70.09007254820813
                    ],
                    [
                        128.2037981887711,
                        68.83376778306638
                    ],
                    [
                        128.85580583346427,
                        70.42813324983808
                    ],
                    [
                        129.8675418338496,
                        71.10403215067163
                    ],
                    [
                        130.56451552300405,
                        72.7442688729434
                    ],
                    [
                        131.68866663454355,
                        73.44325219621123
                    ],
                    [
                        133.75710467977592,
                        73.63601960847268
                    ],
                    [
                        135.57822948046987,
                        75.4420483335183
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Lahaul and Spiti",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chamba",
            "dt_code": "023",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        120.10991018568734,
                        72.86480578518058
                    ],
                    [
                        121.14412920830364,
                        71.41774163535979
                    ],
                    [
                        122.87532192007438,
                        69.96931858990149
                    ],
                    [
                        125.21355623207637,
                        69.55868423644347
                    ],
                    [
                        126.33770734361565,
                        70.40398851811904
                    ],
                    [
                        127.84406983307872,
                        70.09007254820813
                    ],
                    [
                        127.73165472192477,
                        71.41774163535979
                    ],
                    [
                        128.40614538884847,
                        74.06965852956489
                    ],
                    [
                        127.37192636623195,
                        74.67173346471645
                    ],
                    [
                        126.83233383269317,
                        75.92330100899295
                    ],
                    [
                        125.82059783230784,
                        75.82706240657302
                    ],
                    [
                        124.06692209830635,
                        76.71704209978793
                    ],
                    [
                        122.42566147545858,
                        76.40440460731139
                    ],
                    [
                        122.76290680892043,
                        77.9909913226524
                    ],
                    [
                        123.70719374261375,
                        79.33592014429604
                    ],
                    [
                        125.59576760999994,
                        79.59996643786678
                    ],
                    [
                        127.3494433440012,
                        81.30319305285428
                    ],
                    [
                        129.5977455670802,
                        81.39909434131044
                    ],
                    [
                        129.57526254484947,
                        82.62132168025937
                    ],
                    [
                        127.88903587754021,
                        84.46484435599666
                    ],
                    [
                        128.06890005538662,
                        87.04693314166292
                    ],
                    [
                        126.62998663261624,
                        87.62015760463862
                    ],
                    [
                        125.46086947661502,
                        86.80802825157986
                    ],
                    [
                        123.145118186844,
                        86.13890225528996
                    ],
                    [
                        122.06593311976599,
                        85.01508814381424
                    ],
                    [
                        120.10991018568734,
                        83.8186604413624
                    ],
                    [
                        119.23307231868671,
                        85.46949243731137
                    ],
                    [
                        117.29953240683881,
                        85.99548120594443
                    ],
                    [
                        117.18711729568508,
                        84.871564820596
                    ],
                    [
                        115.83813596183768,
                        83.8186604413624
                    ],
                    [
                        114.44418858352878,
                        81.78264080016584
                    ],
                    [
                        115.63578876176052,
                        79.64797005916375
                    ],
                    [
                        115.72572085068373,
                        77.65457773821737
                    ],
                    [
                        113.92707907222075,
                        75.24950547619221
                    ],
                    [
                        114.64653578360571,
                        74.47909493760369
                    ],
                    [
                        116.17538129529953,
                        75.46611451101239
                    ],
                    [
                        118.13140422937818,
                        73.99739380976803
                    ],
                    [
                        118.49113258507077,
                        73.12995391464801
                    ],
                    [
                        120.10991018568734,
                        72.86480578518058
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Chamba",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jammu",
            "dt_code": "021",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        92.34337773066363,
                        74.04557066432005
                    ],
                    [
                        95.8282461764361,
                        72.7442688729434
                    ],
                    [
                        96.57018591005203,
                        73.25046076363788
                    ],
                    [
                        98.2788955995918,
                        72.18967793601871
                    ],
                    [
                        99.94263924467032,
                        73.41915458101846
                    ],
                    [
                        101.17920546736354,
                        75.20136602832349
                    ],
                    [
                        101.85369613428725,
                        74.50317606299359
                    ],
                    [
                        104.57414182421257,
                        77.34212860960434
                    ],
                    [
                        103.49495675713501,
                        78.20721884574266
                    ],
                    [
                        102.21342448997984,
                        77.77473378788056
                    ],
                    [
                        100.63961293382476,
                        79.43194214399551
                    ],
                    [
                        101.2916205785175,
                        80.4877948288048
                    ],
                    [
                        100.50471480044007,
                        81.61485077657295
                    ],
                    [
                        100.21243551143971,
                        82.21401840921243
                    ],
                    [
                        97.44702377705266,
                        82.11816697405959
                    ],
                    [
                        96.81749915459068,
                        80.00795021391701
                    ],
                    [
                        97.13226146582178,
                        76.95748971230643
                    ],
                    [
                        94.99637435389673,
                        77.72667248041842
                    ],
                    [
                        94.00712137574214,
                        76.93344662368224
                    ],
                    [
                        93.49001186443388,
                        75.05693872097106
                    ],
                    [
                        92.34337773066363,
                        74.04557066432005
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Jammu",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kathua",
            "dt_code": "007",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        112.17340333821926,
                        75.65863049877316
                    ],
                    [
                        113.92707907222075,
                        75.24950547619221
                    ],
                    [
                        115.72572085068373,
                        77.65457773821737
                    ],
                    [
                        115.63578876176052,
                        79.64797005916375
                    ],
                    [
                        114.44418858352878,
                        81.78264080016584
                    ],
                    [
                        112.84789400514273,
                        82.81295723342777
                    ],
                    [
                        112.24085240491172,
                        83.89047176438032
                    ],
                    [
                        110.91435409329483,
                        84.10588606130119
                    ],
                    [
                        109.6777878706016,
                        85.3260104983832
                    ],
                    [
                        107.15968938075343,
                        84.65625530931538
                    ],
                    [
                        105.15870040221307,
                        83.41166763925168
                    ],
                    [
                        105.94560618029072,
                        82.21401840921243
                    ],
                    [
                        106.32781755821406,
                        79.50395476916225
                    ],
                    [
                        108.21639142560048,
                        78.85572152896617
                    ],
                    [
                        109.40799160383222,
                        77.36616538443388
                    ],
                    [
                        111.76870893806495,
                        77.24597779806129
                    ],
                    [
                        112.17340333821926,
                        75.65863049877316
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Kathua",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Gurdaspur",
            "dt_code": "035",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        107.72176493652296,
                        87.50075297597738
                    ],
                    [
                        108.53115373683158,
                        88.0738130286581
                    ],
                    [
                        110.77945595991036,
                        88.21704562678059
                    ],
                    [
                        110.9818031599873,
                        89.05231107819549
                    ],
                    [
                        110.9368371155258,
                        89.24316710757074
                    ],
                    [
                        109.94758413737122,
                        91.69839174811068
                    ],
                    [
                        110.48717667091,
                        94.12605406735725
                    ],
                    [
                        109.13819533706283,
                        96.73999647798706
                    ],
                    [
                        108.08149329221555,
                        97.71319535893451
                    ],
                    [
                        105.49594573567492,
                        96.14629292150761
                    ],
                    [
                        104.9338701799054,
                        95.17214297540556
                    ],
                    [
                        103.18019444590368,
                        94.00713702348406
                    ],
                    [
                        101.69631497867181,
                        93.6027528693283
                    ],
                    [
                        100.52719782267081,
                        91.91274598056816
                    ],
                    [
                        100.86444315613267,
                        89.91098244958312
                    ],
                    [
                        102.3258396011338,
                        90.05404918961057
                    ],
                    [
                        103.94461720175059,
                        89.26702249631381
                    ],
                    [
                        104.7090399575975,
                        89.5055566542701
                    ],
                    [
                        107.72176493652296,
                        87.50075297597738
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Gurdaspur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kangra",
            "dt_code": "024",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        129.57526254484947,
                        82.62132168025937
                    ],
                    [
                        131.2614892121585,
                        83.65108793168264
                    ],
                    [
                        132.63295356823664,
                        83.74684583853576
                    ],
                    [
                        132.5655045015444,
                        84.871564820596
                    ],
                    [
                        130.60948156746576,
                        89.60096027678878
                    ],
                    [
                        128.18131516654034,
                        89.57710990889211
                    ],
                    [
                        126.58502058815475,
                        90.43549750574141
                    ],
                    [
                        127.07964707723181,
                        91.8174809946467
                    ],
                    [
                        126.24777525469267,
                        92.88888388109496
                    ],
                    [
                        125.03369205422996,
                        92.65085656791331
                    ],
                    [
                        123.23505027576698,
                        94.24496226574757
                    ],
                    [
                        121.25654431945782,
                        94.48275213818687
                    ],
                    [
                        121.43640849730377,
                        95.48108390993377
                    ],
                    [
                        118.46864956284003,
                        94.22118133356292
                    ],
                    [
                        117.47939658468522,
                        93.07928016465962
                    ],
                    [
                        116.26531338422274,
                        93.15067291470228
                    ],
                    [
                        115.52337365060657,
                        91.53165183778276
                    ],
                    [
                        111.76870893806495,
                        89.33858650989373
                    ],
                    [
                        110.9368371155258,
                        89.24316710757074
                    ],
                    [
                        110.9818031599873,
                        89.05231107819549
                    ],
                    [
                        111.85864102698793,
                        88.28865706723838
                    ],
                    [
                        111.5213956935263,
                        86.30621034144457
                    ],
                    [
                        113.47741862760495,
                        85.66078137836263
                    ],
                    [
                        114.98378111706756,
                        83.79472260487671
                    ],
                    [
                        115.83813596183768,
                        83.8186604413624
                    ],
                    [
                        117.18711729568508,
                        84.871564820596
                    ],
                    [
                        117.29953240683881,
                        85.99548120594443
                    ],
                    [
                        119.23307231868671,
                        85.46949243731137
                    ],
                    [
                        120.10991018568734,
                        83.8186604413624
                    ],
                    [
                        122.06593311976599,
                        85.01508814381424
                    ],
                    [
                        123.145118186844,
                        86.13890225528996
                    ],
                    [
                        125.46086947661502,
                        86.80802825157986
                    ],
                    [
                        126.62998663261624,
                        87.62015760463862
                    ],
                    [
                        128.06890005538662,
                        87.04693314166292
                    ],
                    [
                        127.88903587754021,
                        84.46484435599666
                    ],
                    [
                        129.57526254484947,
                        82.62132168025937
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Kangra",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kullu",
            "dt_code": "026",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        132.63295356823664,
                        83.74684583853576
                    ],
                    [
                        133.7795877020069,
                        83.45955461571117
                    ],
                    [
                        136.23023712516283,
                        85.06292634583912
                    ],
                    [
                        136.61244850308617,
                        86.37790837878072
                    ],
                    [
                        137.73659961462545,
                        87.3813393320554
                    ],
                    [
                        138.43357330378012,
                        86.7841357756879
                    ],
                    [
                        142.48051730532188,
                        89.100027239121
                    ],
                    [
                        143.28990610563028,
                        90.88834849014859
                    ],
                    [
                        142.81776263878373,
                        91.65075355558417
                    ],
                    [
                        144.23419303932337,
                        94.45897474189468
                    ],
                    [
                        142.54796637201434,
                        95.62365194120326
                    ],
                    [
                        141.0416038825515,
                        96.50254141376314
                    ],
                    [
                        141.08656992701322,
                        97.87929238964199
                    ],
                    [
                        141.9409247717831,
                        99.27857241419167
                    ],
                    [
                        140.7718076157821,
                        100.15546758317947
                    ],
                    [
                        140.79429063801285,
                        101.22132029015381
                    ],
                    [
                        139.1530300151653,
                        101.93149865354474
                    ],
                    [
                        137.17452405885592,
                        101.7184778641564
                    ],
                    [
                        135.93795783616224,
                        98.42491906654294
                    ],
                    [
                        136.0054069028547,
                        96.40754955008794
                    ],
                    [
                        134.67890859123827,
                        95.43355841357209
                    ],
                    [
                        134.16179907993023,
                        93.41241901586619
                    ],
                    [
                        132.40812334592874,
                        93.10307810286122
                    ],
                    [
                        130.60948156746576,
                        89.60096027678878
                    ],
                    [
                        132.5655045015444,
                        84.871564820596
                    ],
                    [
                        132.63295356823664,
                        83.74684583853576
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Kullu",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hoshiarpur",
            "dt_code": "038",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        109.13819533706283,
                        96.73999647798706
                    ],
                    [
                        110.48717667091,
                        94.12605406735725
                    ],
                    [
                        109.94758413737122,
                        91.69839174811068
                    ],
                    [
                        110.9368371155258,
                        89.24316710757074
                    ],
                    [
                        111.76870893806495,
                        89.33858650989373
                    ],
                    [
                        115.52337365060657,
                        91.53165183778276
                    ],
                    [
                        116.26531338422274,
                        93.15067291470228
                    ],
                    [
                        116.26531338422274,
                        94.45897474189468
                    ],
                    [
                        117.09718520676188,
                        97.04863556603516
                    ],
                    [
                        118.62603071845524,
                        99.34968978476525
                    ],
                    [
                        119.79514787445646,
                        102.7123352828504
                    ],
                    [
                        121.09916316384215,
                        102.64136567919769
                    ],
                    [
                        121.70620476407362,
                        104.08380448740309
                    ],
                    [
                        120.3122573857645,
                        103.7292252292313
                    ],
                    [
                        119.93004600784138,
                        105.57219364001253
                    ],
                    [
                        118.87334396299411,
                        105.40688426439777
                    ],
                    [
                        116.73745685106928,
                        103.46915123370786
                    ],
                    [
                        115.23109436160644,
                        103.18538662066618
                    ],
                    [
                        114.62405276137497,
                        101.50542903706963
                    ],
                    [
                        113.72473187214337,
                        101.64746470461534
                    ],
                    [
                        113.90459604998978,
                        100.10808002520105
                    ],
                    [
                        112.4656826272194,
                        98.8043764015656
                    ],
                    [
                        111.99353916037285,
                        97.47588411923783
                    ],
                    [
                        110.9368371155258,
                        97.49961681969722
                    ],
                    [
                        109.13819533706283,
                        96.73999647798706
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Hoshiarpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Amritsar",
            "dt_code": "049",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        100.86444315613267,
                        89.91098244958312
                    ],
                    [
                        100.52719782267081,
                        91.91274598056816
                    ],
                    [
                        101.69631497867181,
                        93.6027528693283
                    ],
                    [
                        103.18019444590368,
                        94.00713702348406
                    ],
                    [
                        104.9338701799054,
                        95.17214297540556
                    ],
                    [
                        105.49594573567492,
                        96.14629292150761
                    ],
                    [
                        108.08149329221555,
                        97.71319535893451
                    ],
                    [
                        106.41774964713727,
                        99.46821176550532
                    ],
                    [
                        103.18019444590368,
                        98.828089516835
                    ],
                    [
                        99.85270715574711,
                        98.78066293721952
                    ],
                    [
                        99.11076742213095,
                        98.09282051518915
                    ],
                    [
                        96.97488031020612,
                        98.61465891063739
                    ],
                    [
                        96.43528777666711,
                        99.32598434313269
                    ],
                    [
                        94.83899319828129,
                        95.17214297540556
                    ],
                    [
                        95.67086502082066,
                        94.86314242526413
                    ],
                    [
                        95.85072919866684,
                        93.46000460602579
                    ],
                    [
                        97.24467657697573,
                        92.0080052692386
                    ],
                    [
                        99.29063159997736,
                        91.31724628620015
                    ],
                    [
                        100.86444315613267,
                        89.91098244958312
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Amritsar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mandi",
            "dt_code": "027",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        126.24777525469267,
                        92.88888388109496
                    ],
                    [
                        127.07964707723181,
                        91.8174809946467
                    ],
                    [
                        126.58502058815475,
                        90.43549750574141
                    ],
                    [
                        128.18131516654034,
                        89.57710990889211
                    ],
                    [
                        130.60948156746576,
                        89.60096027678878
                    ],
                    [
                        132.40812334592874,
                        93.10307810286122
                    ],
                    [
                        134.16179907993023,
                        93.41241901586619
                    ],
                    [
                        134.67890859123827,
                        95.43355841357209
                    ],
                    [
                        136.0054069028547,
                        96.40754955008794
                    ],
                    [
                        135.93795783616224,
                        98.42491906654294
                    ],
                    [
                        137.17452405885592,
                        101.7184778641564
                    ],
                    [
                        136.02788992508545,
                        103.30362790555961
                    ],
                    [
                        133.82455374646838,
                        103.96561999969339
                    ],
                    [
                        132.00342894577443,
                        103.23268416848174
                    ],
                    [
                        129.7326437004649,
                        101.88416312247182
                    ],
                    [
                        128.63097561115615,
                        100.67663896610509
                    ],
                    [
                        127.59675658854007,
                        97.90301913664956
                    ],
                    [
                        127.48434147738612,
                        96.47879397501185
                    ],
                    [
                        126.22529223246192,
                        95.05330366796773
                    ],
                    [
                        126.24777525469267,
                        92.88888388109496
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Mandi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kinnaur",
            "dt_code": "034",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        144.23419303932337,
                        94.45897474189468
                    ],
                    [
                        146.66235944024857,
                        94.83937068136856
                    ],
                    [
                        149.2029409523277,
                        91.84129777532996
                    ],
                    [
                        152.41801313133033,
                        91.79366385778826
                    ],
                    [
                        152.84519055371538,
                        89.62481028623688
                    ],
                    [
                        153.72202842071624,
                        89.83944424514954
                    ],
                    [
                        156.1501948216412,
                        89.31473219745055
                    ],
                    [
                        157.74648940002726,
                        90.79302216022876
                    ],
                    [
                        156.62233828848798,
                        94.57785818903375
                    ],
                    [
                        157.47669313325787,
                        96.31255206239564
                    ],
                    [
                        158.69077633372035,
                        97.45215106835911
                    ],
                    [
                        157.0495157108728,
                        98.68580558858912
                    ],
                    [
                        157.97131962233516,
                        100.29762191271487
                    ],
                    [
                        157.27434593318048,
                        101.6237929590356
                    ],
                    [
                        157.88138753341195,
                        102.68867909347927
                    ],
                    [
                        159.2078858450284,
                        103.0434857042705
                    ],
                    [
                        160.7367313567222,
                        104.9344792717958
                    ],
                    [
                        161.07397669018383,
                        106.02080599378047
                    ],
                    [
                        159.27533491172085,
                        106.23326350001224
                    ],
                    [
                        158.15118380018157,
                        104.50919750259402
                    ],
                    [
                        156.12771179941046,
                        104.53282718477067
                    ],
                    [
                        155.20590788794811,
                        103.98925758492987
                    ],
                    [
                        153.36230006502342,
                        104.50919750259402
                    ],
                    [
                        151.69855641994536,
                        102.9961826405252
                    ],
                    [
                        150.8217185529445,
                        103.0434857042705
                    ],
                    [
                        150.52943926394414,
                        102.31013310405513
                    ],
                    [
                        148.64086539655796,
                        102.00249935573368
                    ],
                    [
                        147.74154450732635,
                        100.70032457980727
                    ],
                    [
                        145.74055552878622,
                        101.00820597440179
                    ],
                    [
                        143.89694770586152,
                        99.20745190623177
                    ],
                    [
                        143.49225330570744,
                        96.50254141376314
                    ],
                    [
                        142.54796637201434,
                        95.62365194120326
                    ],
                    [
                        144.23419303932337,
                        94.45897474189468
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Kinnaur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Una",
            "dt_code": "029",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        116.26531338422274,
                        93.15067291470228
                    ],
                    [
                        117.47939658468522,
                        93.07928016465962
                    ],
                    [
                        118.46864956284003,
                        94.22118133356292
                    ],
                    [
                        121.43640849730377,
                        95.48108390993377
                    ],
                    [
                        122.74042378668969,
                        97.45215106835911
                    ],
                    [
                        123.63974467592129,
                        99.53932077287101
                    ],
                    [
                        122.605525653305,
                        100.81874744128868
                    ],
                    [
                        121.09916316384215,
                        102.64136567919769
                    ],
                    [
                        119.79514787445646,
                        102.7123352828504
                    ],
                    [
                        118.62603071845524,
                        99.34968978476525
                    ],
                    [
                        117.09718520676188,
                        97.04863556603516
                    ],
                    [
                        116.26531338422274,
                        94.45897474189468
                    ],
                    [
                        116.26531338422274,
                        93.15067291470228
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Una",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shimla",
            "dt_code": "033",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        137.17452405885592,
                        101.7184778641564
                    ],
                    [
                        139.1530300151653,
                        101.93149865354474
                    ],
                    [
                        140.79429063801285,
                        101.22132029015381
                    ],
                    [
                        140.7718076157821,
                        100.15546758317947
                    ],
                    [
                        141.9409247717831,
                        99.27857241419167
                    ],
                    [
                        141.08656992701322,
                        97.87929238964199
                    ],
                    [
                        141.0416038825515,
                        96.50254141376314
                    ],
                    [
                        142.54796637201434,
                        95.62365194120326
                    ],
                    [
                        143.49225330570744,
                        96.50254141376314
                    ],
                    [
                        143.89694770586152,
                        99.20745190623177
                    ],
                    [
                        145.74055552878622,
                        101.00820597440179
                    ],
                    [
                        147.74154450732635,
                        100.70032457980727
                    ],
                    [
                        148.64086539655796,
                        102.00249935573368
                    ],
                    [
                        150.52943926394414,
                        102.31013310405513
                    ],
                    [
                        150.8217185529445,
                        103.0434857042705
                    ],
                    [
                        149.8774316192514,
                        103.96561999969339
                    ],
                    [
                        147.76402752955732,
                        104.72185228434887
                    ],
                    [
                        145.76303855101696,
                        104.9344792717958
                    ],
                    [
                        144.25667606155434,
                        106.04441375043041
                    ],
                    [
                        143.73956655024608,
                        107.41307954173863
                    ],
                    [
                        143.40232121678423,
                        110.42953698318485
                    ],
                    [
                        141.89595872732139,
                        111.95921188357568
                    ],
                    [
                        141.24395108262866,
                        111.22985207032013
                    ],
                    [
                        139.58020743755014,
                        111.79454597832057
                    ],
                    [
                        138.7933016594725,
                        111.41810490520851
                    ],
                    [
                        137.1295580143942,
                        107.5546002114238
                    ],
                    [
                        135.1735350803158,
                        109.29901602082401
                    ],
                    [
                        132.2507421903133,
                        105.94998067266465
                    ],
                    [
                        132.00342894577443,
                        103.23268416848174
                    ],
                    [
                        133.82455374646838,
                        103.96561999969339
                    ],
                    [
                        136.02788992508545,
                        103.30362790555961
                    ],
                    [
                        137.17452405885592,
                        101.7184778641564
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Shimla",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jalandhar",
            "dt_code": "037",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        110.9368371155258,
                        97.49961681969722
                    ],
                    [
                        111.99353916037285,
                        97.47588411923783
                    ],
                    [
                        112.4656826272194,
                        98.8043764015656
                    ],
                    [
                        113.90459604998978,
                        100.10808002520105
                    ],
                    [
                        113.72473187214337,
                        101.64746470461534
                    ],
                    [
                        112.17340333821926,
                        102.49941715033293
                    ],
                    [
                        112.03850520483456,
                        103.30362790555961
                    ],
                    [
                        113.88211302775903,
                        104.20198038070043
                    ],
                    [
                        115.34350947276039,
                        105.24155810840392
                    ],
                    [
                        116.15289827306879,
                        107.74327538054075
                    ],
                    [
                        113.85963000552829,
                        108.00266819648203
                    ],
                    [
                        113.11769027191235,
                        107.69610862953544
                    ],
                    [
                        110.86938804883357,
                        108.4506134858762
                    ],
                    [
                        107.42948564752282,
                        108.0262473218371
                    ],
                    [
                        104.30434555744318,
                        106.3512834896298
                    ],
                    [
                        103.29260955705786,
                        105.3832672696708
                    ],
                    [
                        103.98958324621208,
                        105.00534876073408
                    ],
                    [
                        106.10298733590616,
                        105.3832672696708
                    ],
                    [
                        106.21540244706034,
                        104.69822534813767
                    ],
                    [
                        108.28384049229271,
                        103.63465768670767
                    ],
                    [
                        108.9133651147547,
                        103.87106621956553
                    ],
                    [
                        109.36302555937073,
                        101.31602875005927
                    ],
                    [
                        108.62108582575479,
                        99.39709962247406
                    ],
                    [
                        109.79020298175556,
                        99.32598434313269
                    ],
                    [
                        110.9368371155258,
                        97.49961681969722
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Jalandhar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tarn Taran",
            "dt_code": "050",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        96.43528777666711,
                        99.32598434313269
                    ],
                    [
                        96.97488031020612,
                        98.61465891063739
                    ],
                    [
                        99.11076742213095,
                        98.09282051518915
                    ],
                    [
                        99.85270715574711,
                        98.78066293721952
                    ],
                    [
                        103.18019444590368,
                        98.828089516835
                    ],
                    [
                        106.41774964713727,
                        99.46821176550532
                    ],
                    [
                        103.71978697944269,
                        102.47575785356173
                    ],
                    [
                        102.82046609021108,
                        104.65097044620245
                    ],
                    [
                        101.62886591197935,
                        105.28879586572782
                    ],
                    [
                        98.34634466628427,
                        105.879152274305
                    ],
                    [
                        97.85171817720698,
                        106.68169404820247
                    ],
                    [
                        97.58192191043759,
                        105.71387404084538
                    ],
                    [
                        94.95140830943524,
                        105.6430353804256
                    ],
                    [
                        95.55844990966648,
                        101.64746470461534
                    ],
                    [
                        97.01984635466783,
                        100.06069107617088
                    ],
                    [
                        96.43528777666711,
                        99.32598434313269
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Tarn Taran",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rupnagar",
            "dt_code": "051",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        121.70620476407362,
                        104.08380448740309
                    ],
                    [
                        121.09916316384215,
                        102.64136567919769
                    ],
                    [
                        122.605525653305,
                        100.81874744128868
                    ],
                    [
                        123.19008423130526,
                        102.31013310405513
                    ],
                    [
                        124.15685418722933,
                        103.27998033778812
                    ],
                    [
                        125.48335249884599,
                        103.20903556689939
                    ],
                    [
                        126.33770734361565,
                        104.320147682536
                    ],
                    [
                        126.02294503238477,
                        107.93192878106038
                    ],
                    [
                        127.61923961077105,
                        108.99269911544303
                    ],
                    [
                        125.77563178784635,
                        109.79371478140456
                    ],
                    [
                        124.65148067630685,
                        111.88864279760742
                    ],
                    [
                        123.36994840915168,
                        112.02977794581295
                    ],
                    [
                        122.24579729761217,
                        110.09988213639912
                    ],
                    [
                        121.54882360845772,
                        110.00568287921715
                    ],
                    [
                        121.34647640838102,
                        108.07340455300982
                    ],
                    [
                        124.11188814276761,
                        108.33274503326663
                    ],
                    [
                        121.70620476407362,
                        104.08380448740309
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Rupnagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Uttarkashi",
            "dt_code": "056",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        150.8217185529445,
                        103.0434857042705
                    ],
                    [
                        151.69855641994536,
                        102.9961826405252
                    ],
                    [
                        153.36230006502342,
                        104.50919750259402
                    ],
                    [
                        155.20590788794811,
                        103.98925758492987
                    ],
                    [
                        156.12771179941046,
                        104.53282718477067
                    ],
                    [
                        158.15118380018157,
                        104.50919750259402
                    ],
                    [
                        159.27533491172085,
                        106.23326350001224
                    ],
                    [
                        161.07397669018383,
                        106.02080599378047
                    ],
                    [
                        160.7367313567222,
                        104.9344792717958
                    ],
                    [
                        159.2078858450284,
                        103.0434857042705
                    ],
                    [
                        160.12968975649073,
                        101.6237929590356
                    ],
                    [
                        161.05149366795308,
                        101.4107316651909
                    ],
                    [
                        162.01826362387692,
                        100.08438572457953
                    ],
                    [
                        162.98503357980098,
                        100.46345281633813
                    ],
                    [
                        163.41221100218604,
                        101.7184778641564
                    ],
                    [
                        164.37898095810988,
                        102.19181934549187
                    ],
                    [
                        164.22159980249444,
                        103.44550607733402
                    ],
                    [
                        165.30078486957223,
                        104.1783458894518
                    ],
                    [
                        165.63803020303408,
                        105.59580789574449
                    ],
                    [
                        166.94204549241954,
                        106.65809551250425
                    ],
                    [
                        165.25581882511074,
                        108.4506134858762
                    ],
                    [
                        164.26656584695593,
                        111.15925170252046
                    ],
                    [
                        164.9860225583409,
                        112.64122391746457
                    ],
                    [
                        163.8843544690326,
                        112.5001411337002
                    ],
                    [
                        161.43370504587665,
                        111.18278549518055
                    ],
                    [
                        161.0065276234916,
                        110.33535663643397
                    ],
                    [
                        159.18540282279764,
                        110.05278318350634
                    ],
                    [
                        158.7132593559511,
                        112.40607923606626
                    ],
                    [
                        156.8246854885649,
                        112.47662616250699
                    ],
                    [
                        155.07100975456342,
                        113.53446810996354
                    ],
                    [
                        154.28410397648577,
                        115.90627305756885
                    ],
                    [
                        152.91263962040784,
                        115.74200006977969
                    ],
                    [
                        152.035801753407,
                        116.37553481048968
                    ],
                    [
                        150.3270920638672,
                        114.56814764307137
                    ],
                    [
                        149.27039001901994,
                        115.13170065509416
                    ],
                    [
                        148.57341632986572,
                        114.28629914032189
                    ],
                    [
                        146.43752921794066,
                        114.47420347867921
                    ],
                    [
                        147.4042991738645,
                        112.35904627415744
                    ],
                    [
                        145.8754536621709,
                        109.81726814438173
                    ],
                    [
                        145.71807250655547,
                        108.30917032418807
                    ],
                    [
                        144.09929490593868,
                        108.70989421559386
                    ],
                    [
                        143.73956655024608,
                        107.41307954173863
                    ],
                    [
                        144.25667606155434,
                        106.04441375043041
                    ],
                    [
                        145.76303855101696,
                        104.9344792717958
                    ],
                    [
                        147.76402752955732,
                        104.72185228434887
                    ],
                    [
                        149.8774316192514,
                        103.96561999969339
                    ],
                    [
                        150.8217185529445,
                        103.0434857042705
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Uttarkashi",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Solan",
            "dt_code": "031",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        129.7326437004649,
                        101.88416312247182
                    ],
                    [
                        132.00342894577443,
                        103.23268416848174
                    ],
                    [
                        132.2507421903133,
                        105.94998067266465
                    ],
                    [
                        135.1735350803158,
                        109.29901602082401
                    ],
                    [
                        133.23999516846789,
                        110.4766251316043
                    ],
                    [
                        131.91349685685122,
                        112.2179393338422
                    ],
                    [
                        130.3172022784654,
                        109.62883177232561
                    ],
                    [
                        129.62022858931095,
                        110.09988213639912
                    ],
                    [
                        128.27124725546355,
                        109.55816255202666
                    ],
                    [
                        127.61923961077105,
                        108.99269911544303
                    ],
                    [
                        126.02294503238477,
                        107.93192878106038
                    ],
                    [
                        126.33770734361565,
                        104.320147682536
                    ],
                    [
                        127.61923961077105,
                        103.77650693561367
                    ],
                    [
                        129.1930511669259,
                        103.94198207056235
                    ],
                    [
                        130.47458343408084,
                        102.68867909347927
                    ],
                    [
                        129.7326437004649,
                        101.88416312247182
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Solan",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shahid Bhagat Singh Nagar",
            "dt_code": "039",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        115.23109436160644,
                        103.18538662066618
                    ],
                    [
                        116.73745685106928,
                        103.46915123370786
                    ],
                    [
                        118.87334396299411,
                        105.40688426439777
                    ],
                    [
                        119.93004600784138,
                        105.57219364001253
                    ],
                    [
                        120.3122573857645,
                        103.7292252292313
                    ],
                    [
                        121.70620476407362,
                        104.08380448740309
                    ],
                    [
                        124.11188814276761,
                        108.33274503326663
                    ],
                    [
                        121.34647640838102,
                        108.07340455300982
                    ],
                    [
                        120.91929898599574,
                        107.64894051780573
                    ],
                    [
                        118.85086094076337,
                        107.95550892611709
                    ],
                    [
                        116.15289827306879,
                        107.74327538054075
                    ],
                    [
                        115.34350947276039,
                        105.24155810840392
                    ],
                    [
                        113.88211302775903,
                        104.20198038070043
                    ],
                    [
                        115.23109436160644,
                        103.18538662066618
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Shahid Bhagat Singh Nagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Fazilka",
            "dt_code": "701",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        94.02960439797312,
                        125.87312156713011
                    ],
                    [
                        91.8262682193556,
                        125.75648256996413
                    ],
                    [
                        85.89075035042742,
                        125.42985046644867
                    ],
                    [
                        86.02564848381235,
                        124.12268857663673
                    ],
                    [
                        87.08235052865939,
                        122.95472088509376
                    ],
                    [
                        86.96993541750544,
                        121.69240234347961
                    ],
                    [
                        86.85752030635149,
                        120.38232564417501
                    ],
                    [
                        85.66592012811952,
                        118.86041175484712
                    ],
                    [
                        86.92496937304372,
                        117.75908477749823
                    ],
                    [
                        86.90248635081298,
                        116.72739405857305
                    ],
                    [
                        90.61218501889289,
                        113.20543439450836
                    ],
                    [
                        93.55746093112634,
                        114.52117622778758
                    ],
                    [
                        92.83800421974115,
                        114.99083038886516
                    ],
                    [
                        92.70310608635646,
                        118.13408639441303
                    ],
                    [
                        91.30915870804756,
                        120.78013596615119
                    ],
                    [
                        92.70310608635646,
                        120.89712099192059
                    ],
                    [
                        93.15276653097226,
                        123.72567062438304
                    ],
                    [
                        94.02960439797312,
                        125.87312156713011
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Fazilka",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Moga",
            "dt_code": "042",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        104.30434555744318,
                        106.3512834896298
                    ],
                    [
                        107.42948564752282,
                        108.0262473218371
                    ],
                    [
                        107.9690781810616,
                        111.25338485284664
                    ],
                    [
                        107.63183284759998,
                        114.30978835027662
                    ],
                    [
                        108.14894235890802,
                        115.03778847601637
                    ],
                    [
                        107.45196866975357,
                        116.25823181606808
                    ],
                    [
                        106.50768173606048,
                        116.18784603849508
                    ],
                    [
                        104.82145506875145,
                        116.281693078378
                    ],
                    [
                        104.57414182421257,
                        115.13170065509416
                    ],
                    [
                        102.43825471228774,
                        116.63357221756002
                    ],
                    [
                        101.33658662297921,
                        115.2490833897894
                    ],
                    [
                        101.49396777859465,
                        113.44046516820038
                    ],
                    [
                        100.75202804497872,
                        112.42959521371313
                    ],
                    [
                        101.42651871190242,
                        109.77016108032268
                    ],
                    [
                        103.29260955705786,
                        108.82773552380814
                    ],
                    [
                        103.5399228015965,
                        107.10640937029473
                    ],
                    [
                        104.30434555744318,
                        106.3512834896298
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Moga",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ludhiana",
            "dt_code": "041",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        107.42948564752282,
                        108.0262473218371
                    ],
                    [
                        110.86938804883357,
                        108.4506134858762
                    ],
                    [
                        113.11769027191235,
                        107.69610862953544
                    ],
                    [
                        113.85963000552829,
                        108.00266819648203
                    ],
                    [
                        116.15289827306879,
                        107.74327538054075
                    ],
                    [
                        118.85086094076337,
                        107.95550892611709
                    ],
                    [
                        120.91929898599574,
                        107.64894051780573
                    ],
                    [
                        121.34647640838102,
                        108.07340455300982
                    ],
                    [
                        121.54882360845772,
                        110.00568287921715
                    ],
                    [
                        120.9867480526882,
                        110.82974323516936
                    ],
                    [
                        121.3015103639193,
                        113.41696359652963
                    ],
                    [
                        119.07569116307104,
                        113.81644483655327
                    ],
                    [
                        118.10892120714743,
                        115.41340524375477
                    ],
                    [
                        116.98477009560793,
                        114.49769001997294
                    ],
                    [
                        115.90558502852991,
                        115.31950903749447
                    ],
                    [
                        115.07371320599077,
                        113.46396640535647
                    ],
                    [
                        113.70224884991262,
                        113.79294861435852
                    ],
                    [
                        112.19588636045,
                        114.92039076060706
                    ],
                    [
                        110.64455782652544,
                        115.27255893840436
                    ],
                    [
                        108.98081418144716,
                        114.66208647290182
                    ],
                    [
                        108.14894235890802,
                        115.03778847601637
                    ],
                    [
                        107.63183284759998,
                        114.30978835027662
                    ],
                    [
                        107.9690781810616,
                        111.25338485284664
                    ],
                    [
                        107.42948564752282,
                        108.0262473218371
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Ludhiana",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chamoli",
            "dt_code": "057",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        164.9860225583409,
                        112.64122391746457
                    ],
                    [
                        164.26656584695593,
                        111.15925170252046
                    ],
                    [
                        165.25581882511074,
                        108.4506134858762
                    ],
                    [
                        166.94204549241954,
                        106.65809551250425
                    ],
                    [
                        168.40344193742112,
                        107.36590326173149
                    ],
                    [
                        169.7973893157298,
                        109.06339271365539
                    ],
                    [
                        171.70844620534695,
                        108.28559527553597
                    ],
                    [
                        173.66446913942536,
                        108.37989343279736
                    ],
                    [
                        174.0241974951182,
                        109.32257648889066
                    ],
                    [
                        176.45236389604315,
                        110.594339598023
                    ],
                    [
                        177.14933758519783,
                        111.53575198992178
                    ],
                    [
                        175.41814487342708,
                        113.22893897785258
                    ],
                    [
                        176.2949827404277,
                        114.80298471856094
                    ],
                    [
                        175.59800905127327,
                        115.83587234158671
                    ],
                    [
                        176.20505065150473,
                        117.43088900364017
                    ],
                    [
                        175.46311091788834,
                        119.56300601600066
                    ],
                    [
                        173.79936727281006,
                        120.38232564417501
                    ],
                    [
                        172.1805896721935,
                        122.72102985665862
                    ],
                    [
                        172.90004638357868,
                        124.58964859577088
                    ],
                    [
                        171.55106504973128,
                        124.96312337841164
                    ],
                    [
                        169.9322874491147,
                        124.636337473443
                    ],
                    [
                        167.88633242611263,
                        125.3365153870003
                    ],
                    [
                        167.1893587369584,
                        126.083051448694
                    ],
                    [
                        165.72796229195728,
                        126.17634523848182
                    ],
                    [
                        164.3340149136484,
                        125.40651718097536
                    ],
                    [
                        163.79442238010938,
                        123.56216539023814
                    ],
                    [
                        162.08571269056938,
                        121.22463554847906
                    ],
                    [
                        163.2997958910321,
                        119.39909382544715
                    ],
                    [
                        163.41221100218604,
                        117.38399860469707
                    ],
                    [
                        164.55884513595606,
                        115.4603513512921
                    ],
                    [
                        165.8853434475725,
                        114.82646659323888
                    ],
                    [
                        166.0652076254189,
                        113.91042638409863
                    ],
                    [
                        164.9860225583409,
                        112.64122391746457
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Chamoli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Dehradun",
            "dt_code": "060",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        141.89595872732139,
                        111.95921188357568
                    ],
                    [
                        143.40232121678423,
                        110.42953698318485
                    ],
                    [
                        143.73956655024608,
                        107.41307954173863
                    ],
                    [
                        144.09929490593868,
                        108.70989421559386
                    ],
                    [
                        145.71807250655547,
                        108.30917032418807
                    ],
                    [
                        145.8754536621709,
                        109.81726814438173
                    ],
                    [
                        147.4042991738645,
                        112.35904627415744
                    ],
                    [
                        146.43752921794066,
                        114.47420347867921
                    ],
                    [
                        145.85297063994017,
                        116.70393909532862
                    ],
                    [
                        147.8089935740188,
                        116.84466390573692
                    ],
                    [
                        149.54018628578933,
                        118.08721581325716
                    ],
                    [
                        148.2136879741729,
                        119.14148498136478
                    ],
                    [
                        150.3270920638672,
                        120.3589220883295
                    ],
                    [
                        149.697567441405,
                        121.83270690798099
                    ],
                    [
                        150.6868204195598,
                        123.071554200393
                    ],
                    [
                        149.22542397455845,
                        125.54651205093637
                    ],
                    [
                        148.4385181964808,
                        125.52318037968172
                    ],
                    [
                        147.2694010404798,
                        124.26279018822515
                    ],
                    [
                        145.650623439863,
                        120.87372464136291
                    ],
                    [
                        142.165754994091,
                        119.37567648359311
                    ],
                    [
                        141.2214680603979,
                        118.15752119008204
                    ],
                    [
                        140.0073848599352,
                        118.15752119008204
                    ],
                    [
                        143.53721935016893,
                        116.37553481048968
                    ],
                    [
                        143.58218539463064,
                        115.43687846380374
                    ],
                    [
                        141.89595872732139,
                        111.95921188357568
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Dehradun",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sirmaur",
            "dt_code": "032",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        135.1735350803158,
                        109.29901602082401
                    ],
                    [
                        137.1295580143942,
                        107.5546002114238
                    ],
                    [
                        138.7933016594725,
                        111.41810490520851
                    ],
                    [
                        139.58020743755014,
                        111.79454597832057
                    ],
                    [
                        141.24395108262866,
                        111.22985207032013
                    ],
                    [
                        141.89595872732139,
                        111.95921188357568
                    ],
                    [
                        143.58218539463064,
                        115.43687846380374
                    ],
                    [
                        143.53721935016893,
                        116.37553481048968
                    ],
                    [
                        140.0073848599352,
                        118.15752119008204
                    ],
                    [
                        140.07483392662766,
                        118.53243306865761
                    ],
                    [
                        139.24296210408852,
                        117.43088900364017
                    ],
                    [
                        137.53425241454852,
                        117.71220363196386
                    ],
                    [
                        134.61145952454604,
                        116.68048380076033
                    ],
                    [
                        133.35241027962184,
                        115.38993169112189
                    ],
                    [
                        134.07186699100703,
                        114.73253709535277
                    ],
                    [
                        134.0044179243148,
                        113.32295396423399
                    ],
                    [
                        131.91349685685122,
                        112.2179393338422
                    ],
                    [
                        133.23999516846789,
                        110.4766251316043
                    ],
                    [
                        135.1735350803158,
                        109.29901602082401
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Sirmaur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "S.A.S. Nagar",
            "dt_code": "052",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        124.65148067630685,
                        111.88864279760742
                    ],
                    [
                        125.77563178784635,
                        109.79371478140456
                    ],
                    [
                        127.61923961077105,
                        108.99269911544303
                    ],
                    [
                        128.27124725546355,
                        109.55816255202666
                    ],
                    [
                        129.2829832558491,
                        110.75912568444716
                    ],
                    [
                        129.17056814469515,
                        112.12386132629348
                    ],
                    [
                        127.28199427730874,
                        112.0062562609989
                    ],
                    [
                        127.7091716996938,
                        113.18192947641796
                    ],
                    [
                        129.0131869890797,
                        113.36995944955044
                    ],
                    [
                        130.4521004118501,
                        114.59163285058702
                    ],
                    [
                        130.18230414508048,
                        117.17297543907432
                    ],
                    [
                        130.04740601169578,
                        117.40744396948793
                    ],
                    [
                        130.24975321177294,
                        117.73564436989062
                    ],
                    [
                        127.84406983307872,
                        117.68876256369498
                    ],
                    [
                        128.15883214430983,
                        116.4224536865895
                    ],
                    [
                        127.64172263300179,
                        115.22560750843036
                    ],
                    [
                        126.18032618800044,
                        114.42722939556216
                    ],
                    [
                        124.69644672076834,
                        112.87633506889233
                    ],
                    [
                        124.65148067630685,
                        111.88864279760742
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-S.A.S. Nagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Faridkot",
            "dt_code": "045",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        100.75202804497872,
                        112.42959521371313
                    ],
                    [
                        101.49396777859465,
                        113.44046516820038
                    ],
                    [
                        101.33658662297921,
                        115.2490833897894
                    ],
                    [
                        102.43825471228774,
                        116.63357221756002
                    ],
                    [
                        102.07852635659515,
                        118.08721581325716
                    ],
                    [
                        100.97685826728639,
                        118.74328392060897
                    ],
                    [
                        99.51546182228549,
                        118.29812303680569
                    ],
                    [
                        98.5711748885924,
                        118.27469022024871
                    ],
                    [
                        97.58192191043759,
                        116.09399368937608
                    ],
                    [
                        97.87420119943772,
                        115.4603513512921
                    ],
                    [
                        95.85072919866684,
                        113.6754624903819
                    ],
                    [
                        94.7940271538198,
                        113.6284657009598
                    ],
                    [
                        94.36684973143474,
                        112.2179393338422
                    ],
                    [
                        96.61515195451375,
                        110.71204563157744
                    ],
                    [
                        98.77352208866932,
                        111.0886483039011
                    ],
                    [
                        100.75202804497872,
                        112.42959521371313
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Faridkot",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Fatehgarh Sahib",
            "dt_code": "040",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        121.54882360845772,
                        110.00568287921715
                    ],
                    [
                        122.24579729761217,
                        110.09988213639912
                    ],
                    [
                        123.36994840915168,
                        112.02977794581295
                    ],
                    [
                        124.65148067630685,
                        111.88864279760742
                    ],
                    [
                        124.69644672076834,
                        112.87633506889233
                    ],
                    [
                        126.18032618800044,
                        114.42722939556216
                    ],
                    [
                        124.71892974299908,
                        115.4603513512921
                    ],
                    [
                        123.23505027576698,
                        117.43088900364017
                    ],
                    [
                        120.22232529684129,
                        115.27255893840436
                    ],
                    [
                        118.10892120714743,
                        115.83587234158671
                    ],
                    [
                        118.10892120714743,
                        115.41340524375477
                    ],
                    [
                        119.07569116307104,
                        113.81644483655327
                    ],
                    [
                        121.3015103639193,
                        113.41696359652963
                    ],
                    [
                        120.9867480526882,
                        110.82974323516936
                    ],
                    [
                        121.54882360845772,
                        110.00568287921715
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Fatehgarh Sahib",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Tehri Garhwal",
            "dt_code": "059",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        146.43752921794066,
                        114.47420347867921
                    ],
                    [
                        148.57341632986572,
                        114.28629914032189
                    ],
                    [
                        149.27039001901994,
                        115.13170065509416
                    ],
                    [
                        150.3270920638672,
                        114.56814764307137
                    ],
                    [
                        152.035801753407,
                        116.37553481048968
                    ],
                    [
                        152.91263962040784,
                        115.74200006977969
                    ],
                    [
                        154.28410397648577,
                        115.90627305756885
                    ],
                    [
                        155.07100975456342,
                        113.53446810996354
                    ],
                    [
                        156.8246854885649,
                        112.47662616250699
                    ],
                    [
                        158.7132593559511,
                        112.40607923606626
                    ],
                    [
                        159.18540282279764,
                        110.05278318350634
                    ],
                    [
                        161.0065276234916,
                        110.33535663643397
                    ],
                    [
                        161.43370504587665,
                        111.18278549518055
                    ],
                    [
                        161.3662559791842,
                        111.88864279760742
                    ],
                    [
                        159.63506326741344,
                        113.20543439450836
                    ],
                    [
                        158.938089578259,
                        115.31950903749447
                    ],
                    [
                        158.53339517810514,
                        118.90726058380062
                    ],
                    [
                        159.477682111798,
                        120.82693095839863
                    ],
                    [
                        158.10621775571985,
                        121.24802699261994
                    ],
                    [
                        156.7797194441032,
                        120.9439127113007
                    ],
                    [
                        155.7679834437181,
                        121.45853528148899
                    ],
                    [
                        154.75624744333254,
                        123.21174344890295
                    ],
                    [
                        153.76699446517773,
                        124.0526333952127
                    ],
                    [
                        152.28311499794563,
                        122.67428774578713
                    ],
                    [
                        150.6868204195598,
                        123.071554200393
                    ],
                    [
                        149.697567441405,
                        121.83270690798099
                    ],
                    [
                        150.3270920638672,
                        120.3589220883295
                    ],
                    [
                        148.2136879741729,
                        119.14148498136478
                    ],
                    [
                        149.54018628578933,
                        118.08721581325716
                    ],
                    [
                        147.8089935740188,
                        116.84466390573692
                    ],
                    [
                        145.85297063994017,
                        116.70393909532862
                    ],
                    [
                        146.43752921794066,
                        114.47420347867921
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Tehri Garhwal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Chandigarh",
            "dt_code": "055",
            "st_nm": "Chandigarh",
            "st_code": "04",
            "alpha2code": "CH",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        129.0131869890797,
                        113.36995944955044
                    ],
                    [
                        127.7091716996938,
                        113.18192947641796
                    ],
                    [
                        127.28199427730874,
                        112.0062562609989
                    ],
                    [
                        129.17056814469515,
                        112.12386132629348
                    ],
                    [
                        129.0131869890797,
                        113.36995944955044
                    ]
                ]
            ]
        },
        "id": "TT-Chandigarh-Chandigarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sri Muktsar Sahib",
            "dt_code": "044",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        94.02960439797312,
                        125.87312156713011
                    ],
                    [
                        93.15276653097226,
                        123.72567062438304
                    ],
                    [
                        92.70310608635646,
                        120.89712099192059
                    ],
                    [
                        91.30915870804756,
                        120.78013596615119
                    ],
                    [
                        92.70310608635646,
                        118.13408639441303
                    ],
                    [
                        92.83800421974115,
                        114.99083038886516
                    ],
                    [
                        93.55746093112634,
                        114.52117622778758
                    ],
                    [
                        94.7940271538198,
                        113.6284657009598
                    ],
                    [
                        95.85072919866684,
                        113.6754624903819
                    ],
                    [
                        97.87420119943772,
                        115.4603513512921
                    ],
                    [
                        97.58192191043759,
                        116.09399368937608
                    ],
                    [
                        98.5711748885924,
                        118.27469022024871
                    ],
                    [
                        99.51546182228549,
                        118.29812303680569
                    ],
                    [
                        98.07654839951488,
                        119.72690210787113
                    ],
                    [
                        97.78426911051451,
                        122.25355012962376
                    ],
                    [
                        97.80675213274526,
                        125.40651718097536
                    ],
                    [
                        96.95239728797537,
                        126.47951446254183
                    ],
                    [
                        95.17623853174314,
                        125.98975250270416
                    ],
                    [
                        94.02960439797312,
                        125.87312156713011
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Sri Muktsar Sahib",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rudraprayag",
            "dt_code": "058",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        161.43370504587665,
                        111.18278549518055
                    ],
                    [
                        163.8843544690326,
                        112.5001411337002
                    ],
                    [
                        164.9860225583409,
                        112.64122391746457
                    ],
                    [
                        166.0652076254189,
                        113.91042638409863
                    ],
                    [
                        165.8853434475725,
                        114.82646659323888
                    ],
                    [
                        164.55884513595606,
                        115.4603513512921
                    ],
                    [
                        163.41221100218604,
                        117.38399860469707
                    ],
                    [
                        163.2997958910321,
                        119.39909382544715
                    ],
                    [
                        162.08571269056938,
                        121.22463554847906
                    ],
                    [
                        160.6917653122605,
                        121.94961840359758
                    ],
                    [
                        159.477682111798,
                        120.82693095839863
                    ],
                    [
                        158.53339517810514,
                        118.90726058380062
                    ],
                    [
                        158.938089578259,
                        115.31950903749447
                    ],
                    [
                        159.63506326741344,
                        113.20543439450836
                    ],
                    [
                        161.3662559791842,
                        111.88864279760742
                    ],
                    [
                        161.43370504587665,
                        111.18278549518055
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Rudraprayag",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pithoragarh",
            "dt_code": "062",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        175.46311091788834,
                        119.56300601600066
                    ],
                    [
                        176.20505065150473,
                        117.43088900364017
                    ],
                    [
                        175.59800905127327,
                        115.83587234158671
                    ],
                    [
                        176.2949827404277,
                        114.80298471856094
                    ],
                    [
                        175.41814487342708,
                        113.22893897785258
                    ],
                    [
                        177.14933758519783,
                        111.53575198992178
                    ],
                    [
                        178.20603963004464,
                        111.20631895110603
                    ],
                    [
                        179.33019074158415,
                        112.28849431440818
                    ],
                    [
                        178.79059820804537,
                        115.03778847601637
                    ],
                    [
                        181.80332318697083,
                        116.04706552339536
                    ],
                    [
                        183.6019649654338,
                        117.36055290924469
                    ],
                    [
                        184.59121794358862,
                        116.96192547323142
                    ],
                    [
                        186.07509741082072,
                        117.87628186154672
                    ],
                    [
                        187.4690447891296,
                        119.46934387887973
                    ],
                    [
                        190.3468716346706,
                        120.42913177263642
                    ],
                    [
                        191.02136230159408,
                        121.41175794929154
                    ],
                    [
                        189.31265261205408,
                        122.01976138595461
                    ],
                    [
                        186.86200318889814,
                        124.14603965546218
                    ],
                    [
                        185.58047092174343,
                        125.7098247132264
                    ],
                    [
                        184.5012858546654,
                        125.7098247132264
                    ],
                    [
                        183.8043121655112,
                        127.52852664481651
                    ],
                    [
                        182.05063643150947,
                        128.29738915722186
                    ],
                    [
                        181.03890043112415,
                        129.39183408431268
                    ],
                    [
                        181.66842505358613,
                        131.8343275813533
                    ],
                    [
                        180.67917207543155,
                        133.27492019960215
                    ],
                    [
                        179.2177756304302,
                        134.3893847227717
                    ],
                    [
                        176.76712620727426,
                        133.089105523145
                    ],
                    [
                        175.64297509573476,
                        132.43859502012322
                    ],
                    [
                        174.94600140658054,
                        130.39251723785122
                    ],
                    [
                        174.0466805173487,
                        129.85734139629898
                    ],
                    [
                        175.46311091788834,
                        128.97276837488238
                    ],
                    [
                        176.45236389604315,
                        126.64273689439841
                    ],
                    [
                        177.98120940773697,
                        126.59610352283732
                    ],
                    [
                        176.6547110961203,
                        124.65968142679304
                    ],
                    [
                        177.35168478527476,
                        121.13106650232771
                    ],
                    [
                        175.46311091788834,
                        119.56300601600066
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Pithoragarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sangrur",
            "dt_code": "053",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        112.19588636045,
                        114.92039076060706
                    ],
                    [
                        113.70224884991262,
                        113.79294861435852
                    ],
                    [
                        115.07371320599077,
                        113.46396640535647
                    ],
                    [
                        115.90558502852991,
                        115.31950903749447
                    ],
                    [
                        116.98477009560793,
                        114.49769001997294
                    ],
                    [
                        118.10892120714743,
                        115.41340524375477
                    ],
                    [
                        118.10892120714743,
                        115.83587234158671
                    ],
                    [
                        118.13140422937818,
                        117.19642378063986
                    ],
                    [
                        116.67000778437682,
                        119.18832591271041
                    ],
                    [
                        118.13140422937818,
                        119.70348936579961
                    ],
                    [
                        119.30052138537894,
                        119.51617560461983
                    ],
                    [
                        119.2780383631482,
                        120.89712099192059
                    ],
                    [
                        117.56932867360842,
                        121.80932362990131
                    ],
                    [
                        118.37871747391682,
                        123.21174344890295
                    ],
                    [
                        116.93980405114644,
                        125.26651068622425
                    ],
                    [
                        116.60255871768436,
                        126.57278635441065
                    ],
                    [
                        118.28878538499362,
                        128.20421228558297
                    ],
                    [
                        117.56932867360842,
                        129.27543731921543
                    ],
                    [
                        116.06296618414558,
                        129.5780523161945
                    ],
                    [
                        113.5898337387589,
                        127.99454558844337
                    ],
                    [
                        112.73547889398878,
                        128.13432626945752
                    ],
                    [
                        113.38748653868174,
                        126.36291735516716
                    ],
                    [
                        112.08347124929605,
                        126.5261510521533
                    ],
                    [
                        112.26333542714224,
                        124.77639633958177
                    ],
                    [
                        110.6220748042947,
                        122.53405359955906
                    ],
                    [
                        110.32979551529456,
                        121.62224565399515
                    ],
                    [
                        112.84789400514273,
                        120.33551820471052
                    ],
                    [
                        112.19588636045,
                        119.00095429172092
                    ],
                    [
                        111.72374289360346,
                        115.74200006977969
                    ],
                    [
                        112.19588636045,
                        114.92039076060706
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Sangrur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Barnala",
            "dt_code": "054",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        106.50768173606048,
                        116.18784603849508
                    ],
                    [
                        107.45196866975357,
                        116.25823181606808
                    ],
                    [
                        108.14894235890802,
                        115.03778847601637
                    ],
                    [
                        108.98081418144716,
                        114.66208647290182
                    ],
                    [
                        110.64455782652544,
                        115.27255893840436
                    ],
                    [
                        112.19588636045,
                        114.92039076060706
                    ],
                    [
                        111.72374289360346,
                        115.74200006977969
                    ],
                    [
                        112.19588636045,
                        119.00095429172092
                    ],
                    [
                        112.84789400514273,
                        120.33551820471052
                    ],
                    [
                        110.32979551529456,
                        121.62224565399515
                    ],
                    [
                        110.6220748042947,
                        122.53405359955906
                    ],
                    [
                        108.95833115921641,
                        121.69240234347961
                    ],
                    [
                        107.56438378090752,
                        121.57547289494988
                    ],
                    [
                        107.60934982536924,
                        120.54614135933866
                    ],
                    [
                        106.7774780028301,
                        119.39909382544715
                    ],
                    [
                        107.04727426959926,
                        118.55586225897167
                    ],
                    [
                        105.87815711359849,
                        117.0791787639144
                    ],
                    [
                        106.50768173606048,
                        116.18784603849508
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Barnala",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bathinda",
            "dt_code": "046",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        102.43825471228774,
                        116.63357221756002
                    ],
                    [
                        104.57414182421257,
                        115.13170065509416
                    ],
                    [
                        104.82145506875145,
                        116.281693078378
                    ],
                    [
                        106.50768173606048,
                        116.18784603849508
                    ],
                    [
                        105.87815711359849,
                        117.0791787639144
                    ],
                    [
                        107.04727426959926,
                        118.55586225897167
                    ],
                    [
                        106.7774780028301,
                        119.39909382544715
                    ],
                    [
                        107.60934982536924,
                        120.54614135933866
                    ],
                    [
                        107.56438378090752,
                        121.57547289494988
                    ],
                    [
                        107.20465542521492,
                        122.7444004238451
                    ],
                    [
                        105.85567409136775,
                        123.18837938683117
                    ],
                    [
                        105.63084386905985,
                        125.6631655660081
                    ],
                    [
                        104.82145506875145,
                        125.49984838562756
                    ],
                    [
                        104.95635320213614,
                        127.62174069602156
                    ],
                    [
                        103.87716813505813,
                        127.83145354710103
                    ],
                    [
                        102.10100937882589,
                        127.22554551373196
                    ],
                    [
                        101.0667903562096,
                        125.82646693581137
                    ],
                    [
                        99.38056368890057,
                        125.10315507679042
                    ],
                    [
                        97.80675213274526,
                        125.40651718097536
                    ],
                    [
                        97.78426911051451,
                        122.25355012962376
                    ],
                    [
                        98.07654839951488,
                        119.72690210787113
                    ],
                    [
                        99.51546182228549,
                        118.29812303680569
                    ],
                    [
                        100.97685826728639,
                        118.74328392060897
                    ],
                    [
                        102.07852635659515,
                        118.08721581325716
                    ],
                    [
                        102.43825471228774,
                        116.63357221756002
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Bathinda",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Patiala",
            "dt_code": "048",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        126.18032618800044,
                        114.42722939556216
                    ],
                    [
                        127.64172263300179,
                        115.22560750843036
                    ],
                    [
                        128.15883214430983,
                        116.4224536865895
                    ],
                    [
                        127.84406983307872,
                        117.68876256369498
                    ],
                    [
                        127.30447729953971,
                        119.44592752306795
                    ],
                    [
                        125.10114112092242,
                        120.87372464136291
                    ],
                    [
                        126.29274129915439,
                        122.44055765559403
                    ],
                    [
                        126.22529223246192,
                        122.48730627901114
                    ],
                    [
                        125.32597134323032,
                        123.25847059801043
                    ],
                    [
                        123.70719374261375,
                        123.30519644722006
                    ],
                    [
                        122.87532192007438,
                        122.51068010213277
                    ],
                    [
                        120.58205365253389,
                        123.46872668535616
                    ],
                    [
                        119.3679704520714,
                        126.22299020024639
                    ],
                    [
                        119.99749507453339,
                        127.69164786603676
                    ],
                    [
                        118.28878538499362,
                        128.20421228558297
                    ],
                    [
                        116.60255871768436,
                        126.57278635441065
                    ],
                    [
                        116.93980405114644,
                        125.26651068622425
                    ],
                    [
                        118.37871747391682,
                        123.21174344890295
                    ],
                    [
                        117.56932867360842,
                        121.80932362990131
                    ],
                    [
                        119.2780383631482,
                        120.89712099192059
                    ],
                    [
                        119.30052138537894,
                        119.51617560461983
                    ],
                    [
                        118.13140422937818,
                        119.70348936579961
                    ],
                    [
                        116.67000778437682,
                        119.18832591271041
                    ],
                    [
                        118.13140422937818,
                        117.19642378063986
                    ],
                    [
                        118.10892120714743,
                        115.83587234158671
                    ],
                    [
                        120.22232529684129,
                        115.27255893840436
                    ],
                    [
                        123.23505027576698,
                        117.43088900364017
                    ],
                    [
                        124.71892974299908,
                        115.4603513512921
                    ],
                    [
                        126.18032618800044,
                        114.42722939556216
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Patiala",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Yamunanagar",
            "dt_code": "071",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        134.61145952454604,
                        116.68048380076033
                    ],
                    [
                        137.53425241454852,
                        117.71220363196386
                    ],
                    [
                        139.24296210408852,
                        117.43088900364017
                    ],
                    [
                        140.07483392662766,
                        118.53243306865761
                    ],
                    [
                        140.11979997108915,
                        119.75031452155741
                    ],
                    [
                        138.2537091259337,
                        122.20679498915416
                    ],
                    [
                        134.88125579131543,
                        126.01307772267364
                    ],
                    [
                        133.30744423516035,
                        125.12649256185
                    ],
                    [
                        132.49805543485195,
                        122.83787943812041
                    ],
                    [
                        132.90274983500603,
                        121.15445925434881
                    ],
                    [
                        134.16179907993023,
                        119.46934387887973
                    ],
                    [
                        134.0044179243148,
                        117.52466583432033
                    ],
                    [
                        134.61145952454604,
                        116.68048380076033
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Yamunanagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Saharanpur",
            "dt_code": "132",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        140.0073848599352,
                        118.15752119008204
                    ],
                    [
                        141.2214680603979,
                        118.15752119008204
                    ],
                    [
                        142.165754994091,
                        119.37567648359311
                    ],
                    [
                        145.650623439863,
                        120.87372464136291
                    ],
                    [
                        144.18922699486188,
                        123.0481881877322
                    ],
                    [
                        143.334872150092,
                        123.35192099671212
                    ],
                    [
                        142.0758229051678,
                        126.92251010493908
                    ],
                    [
                        143.60466841686116,
                        130.50883753210553
                    ],
                    [
                        142.4355512608604,
                        131.6018600277062
                    ],
                    [
                        138.70336957054928,
                        131.18333853087069
                    ],
                    [
                        138.00639588139506,
                        130.57862589197015
                    ],
                    [
                        136.50003339193222,
                        131.0205524053414
                    ],
                    [
                        134.83628974685394,
                        130.1365845891346
                    ],
                    [
                        133.7795877020069,
                        130.27618898977073
                    ],
                    [
                        133.30744423516035,
                        129.13575066675685
                    ],
                    [
                        134.09435001323777,
                        128.29738915722186
                    ],
                    [
                        134.88125579131543,
                        126.01307772267364
                    ],
                    [
                        138.2537091259337,
                        122.20679498915416
                    ],
                    [
                        140.11979997108915,
                        119.75031452155741
                    ],
                    [
                        140.07483392662766,
                        118.53243306865761
                    ],
                    [
                        140.0073848599352,
                        118.15752119008204
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Saharanpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ganganagar",
            "dt_code": "099",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        86.96993541750544,
                        121.69240234347961
                    ],
                    [
                        87.08235052865939,
                        122.95472088509376
                    ],
                    [
                        86.02564848381235,
                        124.12268857663673
                    ],
                    [
                        85.89075035042742,
                        125.42985046644867
                    ],
                    [
                        91.8262682193556,
                        125.75648256996413
                    ],
                    [
                        91.17426057466287,
                        126.78262928834658
                    ],
                    [
                        91.57895497481695,
                        127.64504340678843
                    ],
                    [
                        90.14004155204657,
                        129.2288763788261
                    ],
                    [
                        87.12731657312088,
                        129.7409765250274
                    ],
                    [
                        86.67765612850508,
                        130.64841139004835
                    ],
                    [
                        84.94646341673456,
                        130.81123309307497
                    ],
                    [
                        85.68840315035027,
                        131.9273057315836
                    ],
                    [
                        86.85752030635149,
                        132.29916767498764
                    ],
                    [
                        87.71187515112138,
                        137.0565141006938
                    ],
                    [
                        88.79106021819916,
                        137.54310880219163
                    ],
                    [
                        89.89272830750792,
                        136.38432384595558
                    ],
                    [
                        90.61218501889289,
                        137.79793692063032
                    ],
                    [
                        90.40983781881596,
                        139.34925979736818
                    ],
                    [
                        88.04912048458323,
                        141.7083079303584
                    ],
                    [
                        87.82429026227533,
                        143.002109683534
                    ],
                    [
                        85.64343710588878,
                        142.88663078098676
                    ],
                    [
                        84.47431994988779,
                        141.8007542786691
                    ],
                    [
                        81.82132332665469,
                        141.2922384542278
                    ],
                    [
                        80.38240990388454,
                        141.9856322198537
                    ],
                    [
                        77.68444723618973,
                        141.66208291117118
                    ],
                    [
                        76.87505843588133,
                        142.95591904161932
                    ],
                    [
                        75.79587336880354,
                        143.18686000018573
                    ],
                    [
                        75.7733903465728,
                        144.41033648065383
                    ],
                    [
                        72.87308047880106,
                        146.4859769794565
                    ],
                    [
                        70.62477825572228,
                        146.4859769794565
                    ],
                    [
                        69.05096669956697,
                        145.51765121193375
                    ],
                    [
                        67.76943443241203,
                        143.6717363879684
                    ],
                    [
                        68.80365345502832,
                        142.74804598566402
                    ],
                    [
                        71.92879354510774,
                        141.40782331031255
                    ],
                    [
                        72.8056314121086,
                        139.30297181579255
                    ],
                    [
                        73.97474856810959,
                        137.91375543507496
                    ],
                    [
                        76.89754145811207,
                        132.22944973205313
                    ],
                    [
                        78.58376812542133,
                        125.89644839908289
                    ],
                    [
                        81.50656101542381,
                        124.65968142679304
                    ],
                    [
                        84.58673506104174,
                        123.8424503411461
                    ],
                    [
                        86.96993541750544,
                        121.69240234347961
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Ganganagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mansa",
            "dt_code": "047",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        110.6220748042947,
                        122.53405359955906
                    ],
                    [
                        112.26333542714224,
                        124.77639633958177
                    ],
                    [
                        112.08347124929605,
                        126.5261510521533
                    ],
                    [
                        113.38748653868174,
                        126.36291735516716
                    ],
                    [
                        112.73547889398878,
                        128.13432626945752
                    ],
                    [
                        110.66704084875619,
                        129.39183408431268
                    ],
                    [
                        108.9133651147547,
                        128.25080136178462
                    ],
                    [
                        107.2271384474459,
                        130.1365845891346
                    ],
                    [
                        106.59761382498368,
                        132.43859502012322
                    ],
                    [
                        104.61910786867429,
                        130.62514987529534
                    ],
                    [
                        105.69829293575208,
                        129.20559542975303
                    ],
                    [
                        104.95635320213614,
                        127.62174069602156
                    ],
                    [
                        104.82145506875145,
                        125.49984838562756
                    ],
                    [
                        105.63084386905985,
                        125.6631655660081
                    ],
                    [
                        105.85567409136775,
                        123.18837938683117
                    ],
                    [
                        107.20465542521492,
                        122.7444004238451
                    ],
                    [
                        107.56438378090752,
                        121.57547289494988
                    ],
                    [
                        108.95833115921641,
                        121.69240234347961
                    ],
                    [
                        110.6220748042947,
                        122.53405359955906
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Mansa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kurukshetra",
            "dt_code": "072",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        126.29274129915439,
                        122.44055765559403
                    ],
                    [
                        126.8997828993854,
                        121.80932362990131
                    ],
                    [
                        128.85580583346427,
                        121.27141810984722
                    ],
                    [
                        130.96920992315813,
                        121.69240234347961
                    ],
                    [
                        132.49805543485195,
                        122.83787943812041
                    ],
                    [
                        133.30744423516035,
                        125.12649256185
                    ],
                    [
                        132.9701989016985,
                        125.82646693581137
                    ],
                    [
                        131.3064552566202,
                        125.68649530093847
                    ],
                    [
                        129.80009276715737,
                        127.01575755318083
                    ],
                    [
                        129.1031190780027,
                        126.64273689439841
                    ],
                    [
                        127.57427356630933,
                        127.17892821570359
                    ],
                    [
                        126.56253756592378,
                        126.47951446254183
                    ],
                    [
                        123.68471072038278,
                        126.45619568492214
                    ],
                    [
                        123.0551860979208,
                        124.44957419336657
                    ],
                    [
                        123.70719374261375,
                        123.30519644722006
                    ],
                    [
                        125.32597134323032,
                        123.25847059801043
                    ],
                    [
                        126.22529223246192,
                        122.48730627901114
                    ],
                    [
                        126.29274129915439,
                        122.44055765559403
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Kurukshetra",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bageshwar",
            "dt_code": "063",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        167.88633242611263,
                        125.3365153870003
                    ],
                    [
                        169.9322874491147,
                        124.636337473443
                    ],
                    [
                        171.55106504973128,
                        124.96312337841164
                    ],
                    [
                        172.90004638357868,
                        124.58964859577088
                    ],
                    [
                        172.1805896721935,
                        122.72102985665862
                    ],
                    [
                        173.79936727281006,
                        120.38232564417501
                    ],
                    [
                        175.46311091788834,
                        119.56300601600066
                    ],
                    [
                        177.35168478527476,
                        121.13106650232771
                    ],
                    [
                        176.6547110961203,
                        124.65968142679304
                    ],
                    [
                        177.98120940773697,
                        126.59610352283732
                    ],
                    [
                        176.45236389604315,
                        126.64273689439841
                    ],
                    [
                        175.46311091788834,
                        128.97276837488238
                    ],
                    [
                        174.0466805173487,
                        129.85734139629898
                    ],
                    [
                        172.11314060550126,
                        130.04350862108203
                    ],
                    [
                        171.1913366940389,
                        128.9494839124934
                    ],
                    [
                        171.3712008718851,
                        127.92465092235523
                    ],
                    [
                        168.42592495965187,
                        127.13230963281723
                    ],
                    [
                        167.88633242611263,
                        125.3365153870003
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Bageshwar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Haridwar",
            "dt_code": "068",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        145.650623439863,
                        120.87372464136291
                    ],
                    [
                        147.2694010404798,
                        124.26279018822515
                    ],
                    [
                        148.4385181964808,
                        125.52318037968172
                    ],
                    [
                        149.22542397455845,
                        125.54651205093637
                    ],
                    [
                        150.88916761963674,
                        127.31877625592915
                    ],
                    [
                        151.1814469086371,
                        128.4138530436337
                    ],
                    [
                        149.2479069967892,
                        129.438390556466
                    ],
                    [
                        148.16872192971164,
                        130.88100905616938
                    ],
                    [
                        146.54994432909461,
                        132.06676345644382
                    ],
                    [
                        145.1784799730167,
                        129.83406905945645
                    ],
                    [
                        143.60466841686116,
                        130.50883753210553
                    ],
                    [
                        142.0758229051678,
                        126.92251010493908
                    ],
                    [
                        143.334872150092,
                        123.35192099671212
                    ],
                    [
                        144.18922699486188,
                        123.0481881877322
                    ],
                    [
                        145.650623439863,
                        120.87372464136291
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Haridwar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pauri Garhwal",
            "dt_code": "061",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        162.08571269056938,
                        121.22463554847906
                    ],
                    [
                        163.79442238010938,
                        123.56216539023814
                    ],
                    [
                        164.3340149136484,
                        125.40651718097536
                    ],
                    [
                        162.19812780172333,
                        126.87588445215027
                    ],
                    [
                        162.7377203352621,
                        129.76425013673799
                    ],
                    [
                        161.83839944603073,
                        131.9505494773423
                    ],
                    [
                        161.09645971241457,
                        131.99703601885562
                    ],
                    [
                        159.85989348972134,
                        134.4822240383554
                    ],
                    [
                        158.01628566679688,
                        134.04119247998375
                    ],
                    [
                        155.31832299910207,
                        132.46183180410338
                    ],
                    [
                        154.0817567764086,
                        131.09031979261152
                    ],
                    [
                        153.76699446517773,
                        129.64787889024302
                    ],
                    [
                        151.1814469086371,
                        128.4138530436337
                    ],
                    [
                        150.88916761963674,
                        127.31877625592915
                    ],
                    [
                        149.22542397455845,
                        125.54651205093637
                    ],
                    [
                        150.6868204195598,
                        123.071554200393
                    ],
                    [
                        152.28311499794563,
                        122.67428774578713
                    ],
                    [
                        153.76699446517773,
                        124.0526333952127
                    ],
                    [
                        154.75624744333254,
                        123.21174344890295
                    ],
                    [
                        155.7679834437181,
                        121.45853528148899
                    ],
                    [
                        156.7797194441032,
                        120.9439127113007
                    ],
                    [
                        158.10621775571985,
                        121.24802699261994
                    ],
                    [
                        159.477682111798,
                        120.82693095839863
                    ],
                    [
                        160.6917653122605,
                        121.94961840359758
                    ],
                    [
                        162.08571269056938,
                        121.22463554847906
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Pauri Garhwal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kaithal",
            "dt_code": "073",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        119.99749507453339,
                        127.69164786603676
                    ],
                    [
                        119.3679704520714,
                        126.22299020024639
                    ],
                    [
                        120.58205365253389,
                        123.46872668535616
                    ],
                    [
                        122.87532192007438,
                        122.51068010213277
                    ],
                    [
                        123.70719374261375,
                        123.30519644722006
                    ],
                    [
                        123.0551860979208,
                        124.44957419336657
                    ],
                    [
                        123.68471072038278,
                        126.45619568492214
                    ],
                    [
                        126.56253756592378,
                        126.47951446254183
                    ],
                    [
                        127.57427356630933,
                        127.17892821570359
                    ],
                    [
                        126.8997828993854,
                        130.4390463098572
                    ],
                    [
                        124.78637880969154,
                        131.53211358100089
                    ],
                    [
                        124.24678627615253,
                        133.18201538545907
                    ],
                    [
                        122.33572938653538,
                        132.97296109412127
                    ],
                    [
                        121.99848405307375,
                        132.09000530247965
                    ],
                    [
                        120.35722343022621,
                        131.8808172899519
                    ],
                    [
                        119.32300440760991,
                        130.25292238545262
                    ],
                    [
                        119.99749507453339,
                        127.69164786603676
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Kaithal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sirsa",
            "dt_code": "079",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        95.17623853174314,
                        125.98975250270416
                    ],
                    [
                        96.95239728797537,
                        126.47951446254183
                    ],
                    [
                        97.80675213274526,
                        125.40651718097536
                    ],
                    [
                        99.38056368890057,
                        125.10315507679042
                    ],
                    [
                        101.0667903562096,
                        125.82646693581137
                    ],
                    [
                        102.10100937882589,
                        127.22554551373196
                    ],
                    [
                        103.87716813505813,
                        127.83145354710103
                    ],
                    [
                        104.95635320213614,
                        127.62174069602156
                    ],
                    [
                        105.69829293575208,
                        129.20559542975303
                    ],
                    [
                        104.61910786867429,
                        130.62514987529534
                    ],
                    [
                        106.59761382498368,
                        132.43859502012322
                    ],
                    [
                        106.57513080275294,
                        134.4822240383554
                    ],
                    [
                        105.3835306245212,
                        135.17835871711827
                    ],
                    [
                        105.67580991352133,
                        137.49677236957177
                    ],
                    [
                        103.33757560151935,
                        138.02956617301504
                    ],
                    [
                        102.88791515690355,
                        137.0565141006938
                    ],
                    [
                        101.56141684528711,
                        137.14920940294525
                    ],
                    [
                        101.22417151182526,
                        135.7814470961356
                    ],
                    [
                        99.94263924467032,
                        135.08555708148975
                    ],
                    [
                        99.02083533320797,
                        135.7814470961356
                    ],
                    [
                        96.30038964328264,
                        135.73506320071198
                    ],
                    [
                        96.03059337651302,
                        134.1572644247558
                    ],
                    [
                        96.592668932283,
                        132.9265011127074
                    ],
                    [
                        95.89569524312856,
                        132.3688827708129
                    ],
                    [
                        96.50273684335957,
                        129.18231416139866
                    ],
                    [
                        94.50174786481944,
                        129.29871731057636
                    ],
                    [
                        94.5691969315119,
                        128.13432626945752
                    ],
                    [
                        95.6933480430514,
                        127.20223702531388
                    ],
                    [
                        95.17623853174314,
                        125.98975250270416
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Sirsa",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hanumangarh",
            "dt_code": "100",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        109.11571231483185,
                        143.11758092813028
                    ],
                    [
                        107.51941773644603,
                        143.30231899762867
                    ],
                    [
                        107.04727426959926,
                        142.17049049628508
                    ],
                    [
                        105.72077595798282,
                        143.02520454500353
                    ],
                    [
                        105.36104760229045,
                        144.1333980851142
                    ],
                    [
                        102.0560433343644,
                        143.39468068674125
                    ],
                    [
                        101.0218243117481,
                        143.6948223755283
                    ],
                    [
                        99.24566555551564,
                        143.07139334907464
                    ],
                    [
                        98.45875977743822,
                        144.34110600089326
                    ],
                    [
                        94.43429879812697,
                        144.9871504453593
                    ],
                    [
                        93.60242697558783,
                        143.8102477286168
                    ],
                    [
                        91.3766077747398,
                        145.28701847931939
                    ],
                    [
                        89.80279621858472,
                        145.63295617727795
                    ],
                    [
                        88.2739507068909,
                        145.6098957920616
                    ],
                    [
                        89.37561879619966,
                        143.94874806878067
                    ],
                    [
                        89.12830555166101,
                        142.88663078098676
                    ],
                    [
                        88.04912048458323,
                        141.7083079303584
                    ],
                    [
                        90.40983781881596,
                        139.34925979736818
                    ],
                    [
                        90.61218501889289,
                        137.79793692063032
                    ],
                    [
                        89.89272830750792,
                        136.38432384595558
                    ],
                    [
                        88.79106021819916,
                        137.54310880219163
                    ],
                    [
                        87.71187515112138,
                        137.0565141006938
                    ],
                    [
                        86.85752030635149,
                        132.29916767498764
                    ],
                    [
                        85.68840315035027,
                        131.9273057315836
                    ],
                    [
                        84.94646341673456,
                        130.81123309307497
                    ],
                    [
                        86.67765612850508,
                        130.64841139004835
                    ],
                    [
                        87.12731657312088,
                        129.7409765250274
                    ],
                    [
                        90.14004155204657,
                        129.2288763788261
                    ],
                    [
                        91.57895497481695,
                        127.64504340678843
                    ],
                    [
                        91.17426057466287,
                        126.78262928834658
                    ],
                    [
                        91.8262682193556,
                        125.75648256996413
                    ],
                    [
                        94.02960439797312,
                        125.87312156713011
                    ],
                    [
                        95.17623853174314,
                        125.98975250270416
                    ],
                    [
                        95.6933480430514,
                        127.20223702531388
                    ],
                    [
                        94.5691969315119,
                        128.13432626945752
                    ],
                    [
                        94.50174786481944,
                        129.29871731057636
                    ],
                    [
                        96.50273684335957,
                        129.18231416139866
                    ],
                    [
                        95.89569524312856,
                        132.3688827708129
                    ],
                    [
                        96.592668932283,
                        132.9265011127074
                    ],
                    [
                        96.03059337651302,
                        134.1572644247558
                    ],
                    [
                        96.30038964328264,
                        135.73506320071198
                    ],
                    [
                        99.02083533320797,
                        135.7814470961356
                    ],
                    [
                        99.94263924467032,
                        135.08555708148975
                    ],
                    [
                        101.22417151182526,
                        135.7814470961356
                    ],
                    [
                        101.56141684528711,
                        137.14920940294525
                    ],
                    [
                        102.88791515690355,
                        137.0565141006938
                    ],
                    [
                        103.33757560151935,
                        138.02956617301504
                    ],
                    [
                        105.67580991352133,
                        137.49677236957177
                    ],
                    [
                        107.2271384474459,
                        137.10286237545995
                    ],
                    [
                        108.10397631444653,
                        137.49677236957177
                    ],
                    [
                        107.90162911436937,
                        140.82982202635327
                    ],
                    [
                        108.6435688479853,
                        140.89919234891937
                    ],
                    [
                        109.9026180929095,
                        142.3553291189566
                    ],
                    [
                        109.11571231483185,
                        143.11758092813028
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Hanumangarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Karnal",
            "dt_code": "074",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        133.30744423516035,
                        125.12649256185
                    ],
                    [
                        134.88125579131543,
                        126.01307772267364
                    ],
                    [
                        134.09435001323777,
                        128.29738915722186
                    ],
                    [
                        133.30744423516035,
                        129.13575066675685
                    ],
                    [
                        133.7795877020069,
                        130.27618898977073
                    ],
                    [
                        133.1050970350832,
                        131.2065924218739
                    ],
                    [
                        133.48730841300676,
                        133.7161491708291
                    ],
                    [
                        131.6661836123128,
                        134.3661741080021
                    ],
                    [
                        128.87828885569502,
                        133.20524206220597
                    ],
                    [
                        128.02393401092513,
                        134.0876222018381
                    ],
                    [
                        127.21454521061673,
                        133.6464890025406
                    ],
                    [
                        124.65148067630685,
                        134.0644074982498
                    ],
                    [
                        124.24678627615253,
                        133.18201538545907
                    ],
                    [
                        124.78637880969154,
                        131.53211358100089
                    ],
                    [
                        126.8997828993854,
                        130.4390463098572
                    ],
                    [
                        127.57427356630933,
                        127.17892821570359
                    ],
                    [
                        129.1031190780027,
                        126.64273689439841
                    ],
                    [
                        129.80009276715737,
                        127.01575755318083
                    ],
                    [
                        131.3064552566202,
                        125.68649530093847
                    ],
                    [
                        132.9701989016985,
                        125.82646693581137
                    ],
                    [
                        133.30744423516035,
                        125.12649256185
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Karnal",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Almora",
            "dt_code": "064",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        164.3340149136484,
                        125.40651718097536
                    ],
                    [
                        165.72796229195728,
                        126.17634523848182
                    ],
                    [
                        167.1893587369584,
                        126.083051448694
                    ],
                    [
                        167.88633242611263,
                        125.3365153870003
                    ],
                    [
                        168.42592495965187,
                        127.13230963281723
                    ],
                    [
                        171.3712008718851,
                        127.92465092235523
                    ],
                    [
                        171.1913366940389,
                        128.9494839124934
                    ],
                    [
                        172.11314060550126,
                        130.04350862108203
                    ],
                    [
                        174.0466805173487,
                        129.85734139629898
                    ],
                    [
                        174.94600140658054,
                        130.39251723785122
                    ],
                    [
                        175.64297509573476,
                        132.43859502012322
                    ],
                    [
                        176.76712620727426,
                        133.089105523145
                    ],
                    [
                        173.4396389171177,
                        134.598266111613
                    ],
                    [
                        171.07892158288496,
                        133.9018957609279
                    ],
                    [
                        170.08966860473015,
                        132.50830442351133
                    ],
                    [
                        168.69572122642103,
                        132.62448043983193
                    ],
                    [
                        168.0437135817283,
                        133.5071601584246
                    ],
                    [
                        165.8853434475725,
                        131.55536271361177
                    ],
                    [
                        164.17663375803272,
                        133.06587726862517
                    ],
                    [
                        161.83839944603073,
                        131.9505494773423
                    ],
                    [
                        162.7377203352621,
                        129.76425013673799
                    ],
                    [
                        162.19812780172333,
                        126.87588445215027
                    ],
                    [
                        164.3340149136484,
                        125.40651718097536
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Almora",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Jind",
            "dt_code": "077",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        116.06296618414558,
                        129.5780523161945
                    ],
                    [
                        117.56932867360842,
                        129.27543731921543
                    ],
                    [
                        118.28878538499362,
                        128.20421228558297
                    ],
                    [
                        119.99749507453339,
                        127.69164786603676
                    ],
                    [
                        119.32300440760991,
                        130.25292238545262
                    ],
                    [
                        120.35722343022621,
                        131.8808172899519
                    ],
                    [
                        121.99848405307375,
                        132.09000530247965
                    ],
                    [
                        122.33572938653538,
                        132.97296109412127
                    ],
                    [
                        124.24678627615253,
                        133.18201538545907
                    ],
                    [
                        124.65148067630685,
                        134.0644074982498
                    ],
                    [
                        127.21454521061673,
                        133.6464890025406
                    ],
                    [
                        128.02393401092513,
                        134.0876222018381
                    ],
                    [
                        127.64172263300179,
                        135.34074950562302
                    ],
                    [
                        126.40515641030811,
                        135.68867805287
                    ],
                    [
                        126.78736778823145,
                        137.14920940294525
                    ],
                    [
                        125.10114112092242,
                        137.14920940294525
                    ],
                    [
                        125.19107320984563,
                        138.0758882913767
                    ],
                    [
                        124.13437116499858,
                        138.88632420387614
                    ],
                    [
                        123.8645748982292,
                        140.32102149733697
                    ],
                    [
                        121.27902734168856,
                        140.41354179490486
                    ],
                    [
                        121.25654431945782,
                        138.70111523933633
                    ],
                    [
                        120.15487623014906,
                        138.19168814676425
                    ],
                    [
                        119.66024974107177,
                        134.96954797819086
                    ],
                    [
                        118.78341187407091,
                        135.29435370518314
                    ],
                    [
                        117.05221916230039,
                        133.7625877079534
                    ],
                    [
                        116.31027942868423,
                        132.39212050349977
                    ],
                    [
                        116.06296618414558,
                        129.5780523161945
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Jind",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Fatehabad",
            "dt_code": "078",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        112.73547889398878,
                        128.13432626945752
                    ],
                    [
                        113.5898337387589,
                        127.99454558844337
                    ],
                    [
                        116.06296618414558,
                        129.5780523161945
                    ],
                    [
                        116.31027942868423,
                        132.39212050349977
                    ],
                    [
                        114.75895089475966,
                        132.60124586873116
                    ],
                    [
                        111.40898058237235,
                        134.85353102927274
                    ],
                    [
                        111.18415036006468,
                        135.61909798244233
                    ],
                    [
                        108.44122164790838,
                        135.6654850092379
                    ],
                    [
                        107.2271384474459,
                        137.10286237545995
                    ],
                    [
                        105.67580991352133,
                        137.49677236957177
                    ],
                    [
                        105.3835306245212,
                        135.17835871711827
                    ],
                    [
                        106.57513080275294,
                        134.4822240383554
                    ],
                    [
                        106.59761382498368,
                        132.43859502012322
                    ],
                    [
                        107.2271384474459,
                        130.1365845891346
                    ],
                    [
                        108.9133651147547,
                        128.25080136178462
                    ],
                    [
                        110.66704084875619,
                        129.39183408431268
                    ],
                    [
                        112.73547889398878,
                        128.13432626945752
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Fatehabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bijnor",
            "dt_code": "134",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        154.0817567764086,
                        140.78357360299344
                    ],
                    [
                        151.92338664225304,
                        141.52340047142246
                    ],
                    [
                        149.40528815240486,
                        141.38470695477963
                    ],
                    [
                        147.3143670849413,
                        140.48292877639454
                    ],
                    [
                        148.07878984078843,
                        138.9789212418466
                    ],
                    [
                        147.92140868517276,
                        137.5662765513796
                    ],
                    [
                        146.63987641801782,
                        135.36394693561897
                    ],
                    [
                        146.05531784001732,
                        132.97296109412127
                    ],
                    [
                        146.54994432909461,
                        132.06676345644382
                    ],
                    [
                        148.16872192971164,
                        130.88100905616938
                    ],
                    [
                        149.2479069967892,
                        129.438390556466
                    ],
                    [
                        151.1814469086371,
                        128.4138530436337
                    ],
                    [
                        153.76699446517773,
                        129.64787889024302
                    ],
                    [
                        154.0817567764086,
                        131.09031979261152
                    ],
                    [
                        155.31832299910207,
                        132.46183180410338
                    ],
                    [
                        158.01628566679688,
                        134.04119247998375
                    ],
                    [
                        159.85989348972134,
                        134.4822240383554
                    ],
                    [
                        158.7582254004128,
                        136.01334779309036
                    ],
                    [
                        157.43172708879615,
                        137.42726538470652
                    ],
                    [
                        156.3750250439491,
                        137.8442652597622
                    ],
                    [
                        155.81294948817958,
                        139.81207150372984
                    ],
                    [
                        154.0817567764086,
                        140.78357360299344
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Bijnor",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Muzaffarnagar",
            "dt_code": "133",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        138.70336957054928,
                        131.18333853087069
                    ],
                    [
                        142.4355512608604,
                        131.6018600277062
                    ],
                    [
                        143.60466841686116,
                        130.50883753210553
                    ],
                    [
                        145.1784799730167,
                        129.83406905945645
                    ],
                    [
                        146.54994432909461,
                        132.06676345644382
                    ],
                    [
                        146.05531784001732,
                        132.97296109412127
                    ],
                    [
                        146.63987641801782,
                        135.36394693561897
                    ],
                    [
                        147.92140868517276,
                        137.5662765513796
                    ],
                    [
                        146.10028388447904,
                        137.5662765513796
                    ],
                    [
                        143.2449400611688,
                        138.7705709278693
                    ],
                    [
                        141.01912086032075,
                        138.0758882913767
                    ],
                    [
                        140.16476601555087,
                        137.40409576674807
                    ],
                    [
                        138.9731658373189,
                        138.65480989539964
                    ],
                    [
                        137.48928637008703,
                        137.68211062696935
                    ],
                    [
                        135.6231955249316,
                        137.35775559619742
                    ],
                    [
                        137.78156565908694,
                        135.29435370518314
                    ],
                    [
                        138.36612423708766,
                        134.2733285047728
                    ],
                    [
                        138.70336957054928,
                        131.18333853087069
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Muzaffarnagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hisar",
            "dt_code": "080",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        109.9026180929095,
                        142.3553291189566
                    ],
                    [
                        108.6435688479853,
                        140.89919234891937
                    ],
                    [
                        107.90162911436937,
                        140.82982202635327
                    ],
                    [
                        108.10397631444653,
                        137.49677236957177
                    ],
                    [
                        107.2271384474459,
                        137.10286237545995
                    ],
                    [
                        108.44122164790838,
                        135.6654850092379
                    ],
                    [
                        111.18415036006468,
                        135.61909798244233
                    ],
                    [
                        111.40898058237235,
                        134.85353102927274
                    ],
                    [
                        114.75895089475966,
                        132.60124586873116
                    ],
                    [
                        116.31027942868423,
                        132.39212050349977
                    ],
                    [
                        117.05221916230039,
                        133.7625877079534
                    ],
                    [
                        118.78341187407091,
                        135.29435370518314
                    ],
                    [
                        119.66024974107177,
                        134.96954797819086
                    ],
                    [
                        120.15487623014906,
                        138.19168814676425
                    ],
                    [
                        121.25654431945782,
                        138.70111523933633
                    ],
                    [
                        121.27902734168856,
                        140.41354179490486
                    ],
                    [
                        120.19984227461055,
                        142.0318486321026
                    ],
                    [
                        119.6377667188408,
                        141.01480338803498
                    ],
                    [
                        116.37772849537646,
                        140.80669796885263
                    ],
                    [
                        114.37673951683655,
                        143.37159072344787
                    ],
                    [
                        112.17340333821926,
                        143.37159072344787
                    ],
                    [
                        111.40898058237235,
                        142.23980728237132
                    ],
                    [
                        109.9026180929095,
                        142.3553291189566
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Hisar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Nainital",
            "dt_code": "066",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        159.85989348972134,
                        134.4822240383554
                    ],
                    [
                        161.09645971241457,
                        131.99703601885562
                    ],
                    [
                        161.83839944603073,
                        131.9505494773423
                    ],
                    [
                        164.17663375803272,
                        133.06587726862517
                    ],
                    [
                        165.8853434475725,
                        131.55536271361177
                    ],
                    [
                        168.0437135817283,
                        133.5071601584246
                    ],
                    [
                        168.69572122642103,
                        132.62448043983193
                    ],
                    [
                        170.08966860473015,
                        132.50830442351133
                    ],
                    [
                        171.07892158288496,
                        133.9018957609279
                    ],
                    [
                        173.4396389171177,
                        134.598266111613
                    ],
                    [
                        174.0691635395799,
                        135.64229165243603
                    ],
                    [
                        173.52957100604067,
                        137.2187276059036
                    ],
                    [
                        174.5862730508877,
                        138.26116433022344
                    ],
                    [
                        173.86681633950252,
                        139.21039213533675
                    ],
                    [
                        175.2832467400424,
                        139.83520884011136
                    ],
                    [
                        175.23828069558067,
                        140.94543768923205
                    ],
                    [
                        174.1366126062719,
                        141.9856322198537
                    ],
                    [
                        172.72018220573227,
                        141.45405509800315
                    ],
                    [
                        167.34673989257385,
                        140.45980009125378
                    ],
                    [
                        165.0309886028026,
                        137.8442652597622
                    ],
                    [
                        161.1414257568763,
                        136.98698934951426
                    ],
                    [
                        159.88237651195232,
                        136.12928640859673
                    ],
                    [
                        158.7582254004128,
                        136.01334779309036
                    ],
                    [
                        159.85989348972134,
                        134.4822240383554
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Nainital",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Panipat",
            "dt_code": "075",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        128.02393401092513,
                        134.0876222018381
                    ],
                    [
                        128.87828885569502,
                        133.20524206220597
                    ],
                    [
                        131.6661836123128,
                        134.3661741080021
                    ],
                    [
                        133.48730841300676,
                        133.7161491708291
                    ],
                    [
                        133.4198593463143,
                        135.9901591315476
                    ],
                    [
                        133.82455374646838,
                        136.87110852534937
                    ],
                    [
                        133.64468956862197,
                        139.07151331879635
                    ],
                    [
                        131.28397223438924,
                        138.70111523933633
                    ],
                    [
                        130.33968530069615,
                        138.19168814676425
                    ],
                    [
                        128.76587374454107,
                        138.21484718535487
                    ],
                    [
                        128.2937302776943,
                        137.1260360451019
                    ],
                    [
                        126.78736778823145,
                        137.14920940294525
                    ],
                    [
                        126.40515641030811,
                        135.68867805287
                    ],
                    [
                        127.64172263300179,
                        135.34074950562302
                    ],
                    [
                        128.02393401092513,
                        134.0876222018381
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Panipat",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Champawat",
            "dt_code": "065",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        176.76712620727426,
                        133.089105523145
                    ],
                    [
                        179.2177756304302,
                        134.3893847227717
                    ],
                    [
                        180.3419267419697,
                        136.80157628657497
                    ],
                    [
                        179.46508887496884,
                        138.72426744580866
                    ],
                    [
                        177.7788622076598,
                        140.15909908653663
                    ],
                    [
                        177.39665082973625,
                        142.19359639870856
                    ],
                    [
                        176.74464318504351,
                        142.86353408128753
                    ],
                    [
                        176.5198129627356,
                        141.8007542786691
                    ],
                    [
                        175.23828069558067,
                        140.94543768923205
                    ],
                    [
                        175.2832467400424,
                        139.83520884011136
                    ],
                    [
                        173.86681633950252,
                        139.21039213533675
                    ],
                    [
                        174.5862730508877,
                        138.26116433022344
                    ],
                    [
                        173.52957100604067,
                        137.2187276059036
                    ],
                    [
                        174.0691635395799,
                        135.64229165243603
                    ],
                    [
                        173.4396389171177,
                        134.598266111613
                    ],
                    [
                        176.76712620727426,
                        133.089105523145
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Champawat",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Udham Singh Nagar",
            "dt_code": "067",
            "st_nm": "Uttarakhand",
            "st_code": "05",
            "alpha2code": "UT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        170.98898949396175,
                        144.3180285641797
                    ],
                    [
                        169.55007607119114,
                        144.4564886087245
                    ],
                    [
                        167.16687571472767,
                        143.602476591019
                    ],
                    [
                        166.94204549241954,
                        142.67874944981673
                    ],
                    [
                        164.76119233603322,
                        142.00874057959822
                    ],
                    [
                        163.05248264649322,
                        140.73732394611335
                    ],
                    [
                        163.20986380210888,
                        139.92775509321194
                    ],
                    [
                        161.47867109033814,
                        139.0252179003624
                    ],
                    [
                        160.57935020110654,
                        139.67324098968385
                    ],
                    [
                        159.88237651195232,
                        139.88148258510347
                    ],
                    [
                        159.11795375610518,
                        138.4232645502476
                    ],
                    [
                        157.43172708879615,
                        137.42726538470652
                    ],
                    [
                        158.7582254004128,
                        136.01334779309036
                    ],
                    [
                        159.88237651195232,
                        136.12928640859673
                    ],
                    [
                        161.1414257568763,
                        136.98698934951426
                    ],
                    [
                        165.0309886028026,
                        137.8442652597622
                    ],
                    [
                        167.34673989257385,
                        140.45980009125378
                    ],
                    [
                        172.72018220573227,
                        141.45405509800315
                    ],
                    [
                        174.1366126062719,
                        141.9856322198537
                    ],
                    [
                        175.23828069558067,
                        140.94543768923205
                    ],
                    [
                        176.5198129627356,
                        141.8007542786691
                    ],
                    [
                        176.74464318504351,
                        142.86353408128753
                    ],
                    [
                        176.60974505165882,
                        144.61801145150974
                    ],
                    [
                        176.09263554035056,
                        146.09410009431065
                    ],
                    [
                        174.99096745104202,
                        146.53207438155283
                    ],
                    [
                        173.01246149473263,
                        143.76407850423834
                    ],
                    [
                        170.98898949396175,
                        144.3180285641797
                    ]
                ]
            ]
        },
        "id": "TT-Uttarakhand-Udham Singh Nagar",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Sonipat",
            "dt_code": "076",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        134.72387463569999,
                        144.34110600089326
                    ],
                    [
                        133.3299272573911,
                        144.24879442335947
                    ],
                    [
                        131.08162503431208,
                        145.03328733715534
                    ],
                    [
                        130.69941365638874,
                        145.263953533292
                    ],
                    [
                        129.84505881161886,
                        145.07942301120164
                    ],
                    [
                        127.66420565523254,
                        141.75453171946685
                    ],
                    [
                        126.45012245476983,
                        140.94543768923205
                    ],
                    [
                        123.8645748982292,
                        140.32102149733697
                    ],
                    [
                        124.13437116499858,
                        138.88632420387614
                    ],
                    [
                        125.19107320984563,
                        138.0758882913767
                    ],
                    [
                        125.10114112092242,
                        137.14920940294525
                    ],
                    [
                        126.78736778823145,
                        137.14920940294525
                    ],
                    [
                        128.2937302776943,
                        137.1260360451019
                    ],
                    [
                        128.76587374454107,
                        138.21484718535487
                    ],
                    [
                        130.33968530069615,
                        138.19168814676425
                    ],
                    [
                        131.28397223438924,
                        138.70111523933633
                    ],
                    [
                        133.64468956862197,
                        139.07151331879635
                    ],
                    [
                        134.0044179243148,
                        141.3153560411718
                    ],
                    [
                        134.9711878802384,
                        143.2330445174532
                    ],
                    [
                        134.72387463569999,
                        144.34110600089326
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Sonipat",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Baghpat",
            "dt_code": "139",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        134.8138067246232,
                        144.71030351227415
                    ],
                    [
                        134.72387463569999,
                        144.34110600089326
                    ],
                    [
                        134.9711878802384,
                        143.2330445174532
                    ],
                    [
                        134.0044179243148,
                        141.3153560411718
                    ],
                    [
                        133.64468956862197,
                        139.07151331879635
                    ],
                    [
                        133.82455374646838,
                        136.87110852534937
                    ],
                    [
                        135.6231955249316,
                        137.35775559619742
                    ],
                    [
                        137.48928637008703,
                        137.68211062696935
                    ],
                    [
                        138.9731658373189,
                        138.65480989539964
                    ],
                    [
                        137.87149774801014,
                        140.08969913362228
                    ],
                    [
                        138.54598841493407,
                        142.44774106356573
                    ],
                    [
                        138.11881099254902,
                        143.09448729171868
                    ],
                    [
                        137.28693917000987,
                        144.75644771415404
                    ],
                    [
                        134.8138067246232,
                        144.71030351227415
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Baghpat",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Meerut",
            "dt_code": "138",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        147.3143670849413,
                        140.48292877639454
                    ],
                    [
                        147.38181615163376,
                        142.26291226365396
                    ],
                    [
                        148.03382379632671,
                        143.92566544248007
                    ],
                    [
                        146.61739339578708,
                        143.94874806878067
                    ],
                    [
                        141.69361152724423,
                        146.16326120839642
                    ],
                    [
                        141.60367943832125,
                        143.9949124050816
                    ],
                    [
                        139.35537721524247,
                        143.76407850423834
                    ],
                    [
                        138.11881099254902,
                        143.09448729171868
                    ],
                    [
                        138.54598841493407,
                        142.44774106356573
                    ],
                    [
                        137.87149774801014,
                        140.08969913362228
                    ],
                    [
                        138.9731658373189,
                        138.65480989539964
                    ],
                    [
                        140.16476601555087,
                        137.40409576674807
                    ],
                    [
                        141.01912086032075,
                        138.0758882913767
                    ],
                    [
                        143.2449400611688,
                        138.7705709278693
                    ],
                    [
                        146.10028388447904,
                        137.5662765513796
                    ],
                    [
                        147.92140868517276,
                        137.5662765513796
                    ],
                    [
                        148.07878984078843,
                        138.9789212418466
                    ],
                    [
                        147.3143670849413,
                        140.48292877639454
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Meerut",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Moradabad",
            "dt_code": "135",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        155.40825508802527,
                        145.1255574676676
                    ],
                    [
                        155.94784762156405,
                        144.41033648065383
                    ],
                    [
                        155.27335695464058,
                        142.79424214342447
                    ],
                    [
                        154.01430770971638,
                        142.44774106356573
                    ],
                    [
                        154.0817567764086,
                        140.78357360299344
                    ],
                    [
                        155.81294948817958,
                        139.81207150372984
                    ],
                    [
                        156.3750250439491,
                        137.8442652597622
                    ],
                    [
                        157.43172708879615,
                        137.42726538470652
                    ],
                    [
                        159.11795375610518,
                        138.4232645502476
                    ],
                    [
                        159.88237651195232,
                        139.88148258510347
                    ],
                    [
                        160.57935020110654,
                        139.67324098968385
                    ],
                    [
                        159.5226481562595,
                        141.93941457855743
                    ],
                    [
                        159.7474783785674,
                        143.602476591019
                    ],
                    [
                        160.96156157902988,
                        145.84048597200717
                    ],
                    [
                        160.35451997879886,
                        146.87776626955264
                    ],
                    [
                        158.8931235337975,
                        147.5919804012256
                    ],
                    [
                        160.35451997879886,
                        149.43377394844305
                    ],
                    [
                        160.06224068979827,
                        150.9288151283842
                    ],
                    [
                        159.07298771164392,
                        150.79086434171194
                    ],
                    [
                        158.03876868902762,
                        149.68686997401164
                    ],
                    [
                        157.34179499987295,
                        147.84534140929372
                    ],
                    [
                        155.72301739925638,
                        147.43073163963157
                    ],
                    [
                        156.26260993279516,
                        145.84048597200717
                    ],
                    [
                        155.40825508802527,
                        145.1255574676676
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Moradabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bikaner",
            "dt_code": "101",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        89.80279621858472,
                        145.63295617727795
                    ],
                    [
                        90.27493968543126,
                        146.7625432121597
                    ],
                    [
                        88.61119604035298,
                        147.40769489352203
                    ],
                    [
                        89.03837346273804,
                        148.83540244161594
                    ],
                    [
                        88.22898466242941,
                        149.82490704185022
                    ],
                    [
                        87.84677328450607,
                        152.3307045332287
                    ],
                    [
                        88.34139977358336,
                        153.0656785875277
                    ],
                    [
                        90.34238875212372,
                        153.0886416215007
                    ],
                    [
                        91.08432848573966,
                        154.48882576370147
                    ],
                    [
                        92.70310608635646,
                        155.17703688688727
                    ],
                    [
                        91.75881915266336,
                        156.1859328518346
                    ],
                    [
                        91.78130217489411,
                        157.85852728923498
                    ],
                    [
                        90.94943035235497,
                        158.61411916401772
                    ],
                    [
                        90.76956617450878,
                        161.6561670861194
                    ],
                    [
                        89.19575461835325,
                        161.9760841189896
                    ],
                    [
                        88.40884884027582,
                        163.07251279503254
                    ],
                    [
                        87.39711283989027,
                        163.02684153248373
                    ],
                    [
                        87.32966377319781,
                        161.74757773640283
                    ],
                    [
                        85.48605595027311,
                        162.27309921036692
                    ],
                    [
                        85.35115781688864,
                        164.25956168684763
                    ],
                    [
                        85.89075035042742,
                        165.21776530763384
                    ],
                    [
                        85.59847106142706,
                        166.28944242047413
                    ],
                    [
                        84.4293539054263,
                        166.95037560349863
                    ],
                    [
                        83.10285559380986,
                        166.5857524416062
                    ],
                    [
                        80.7421382595769,
                        166.99594837444795
                    ],
                    [
                        79.88778341480702,
                        169.2731390933668
                    ],
                    [
                        77.99920954742083,
                        170.88822821660284
                    ],
                    [
                        76.53781310241948,
                        170.88822821660284
                    ],
                    [
                        76.08815265780368,
                        169.6372268965
                    ],
                    [
                        74.69420527949478,
                        170.09223506483931
                    ],
                    [
                        74.58179016834083,
                        168.54474642110074
                    ],
                    [
                        73.81736741249415,
                        168.08935379183606
                    ],
                    [
                        72.42342003418526,
                        168.8634538563478
                    ],
                    [
                        70.17511781110625,
                        167.42883295405016
                    ],
                    [
                        70.17511781110625,
                        165.49144499920158
                    ],
                    [
                        67.67950234348905,
                        166.3122372095071
                    ],
                    [
                        66.17313985402598,
                        164.92123247810133
                    ],
                    [
                        61.60908634117595,
                        167.17822807795392
                    ],
                    [
                        61.60908634117595,
                        167.10987532258997
                    ],
                    [
                        62.126195852484216,
                        166.03868091508775
                    ],
                    [
                        63.00303371948485,
                        163.7117895671082
                    ],
                    [
                        61.18190891879112,
                        163.9400480571705
                    ],
                    [
                        59.42823318478963,
                        162.86698303786255
                    ],
                    [
                        60.417486162944215,
                        162.0674785782145
                    ],
                    [
                        60.08024082948236,
                        160.60461157100235
                    ],
                    [
                        58.46146322886557,
                        159.4151569692762
                    ],
                    [
                        56.28061007247925,
                        160.0328565357271
                    ],
                    [
                        56.977583761633696,
                        156.5755809065153
                    ],
                    [
                        57.96683673978828,
                        155.131164415802
                    ],
                    [
                        59.96782571832864,
                        153.38713403927807
                    ],
                    [
                        61.06949380763717,
                        152.03203427356738
                    ],
                    [
                        62.440958163715095,
                        147.43073163963157
                    ],
                    [
                        63.96980367540891,
                        145.51765121193375
                    ],
                    [
                        67.76943443241203,
                        143.6717363879684
                    ],
                    [
                        69.05096669956697,
                        145.51765121193375
                    ],
                    [
                        70.62477825572228,
                        146.4859769794565
                    ],
                    [
                        72.87308047880106,
                        146.4859769794565
                    ],
                    [
                        75.7733903465728,
                        144.41033648065383
                    ],
                    [
                        75.79587336880354,
                        143.18686000018573
                    ],
                    [
                        76.87505843588133,
                        142.95591904161932
                    ],
                    [
                        77.68444723618973,
                        141.66208291117118
                    ],
                    [
                        80.38240990388454,
                        141.9856322198537
                    ],
                    [
                        81.82132332665469,
                        141.2922384542278
                    ],
                    [
                        84.47431994988779,
                        141.8007542786691
                    ],
                    [
                        85.64343710588878,
                        142.88663078098676
                    ],
                    [
                        87.82429026227533,
                        143.002109683534
                    ],
                    [
                        88.04912048458323,
                        141.7083079303584
                    ],
                    [
                        89.12830555166101,
                        142.88663078098676
                    ],
                    [
                        89.37561879619966,
                        143.94874806878067
                    ],
                    [
                        88.2739507068909,
                        145.6098957920616
                    ],
                    [
                        89.80279621858472,
                        145.63295617727795
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Bikaner",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rampur",
            "dt_code": "136",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        167.16687571472767,
                        143.602476591019
                    ],
                    [
                        166.24507180326532,
                        146.02493624910005
                    ],
                    [
                        165.45816602518767,
                        146.1863143062747
                    ],
                    [
                        164.40146398034085,
                        147.5919804012256
                    ],
                    [
                        163.97428655795557,
                        149.06556938351673
                    ],
                    [
                        162.06322966833864,
                        150.12395030792908
                    ],
                    [
                        160.53438415664505,
                        151.57244320624926
                    ],
                    [
                        160.06224068979827,
                        150.9288151283842
                    ],
                    [
                        160.35451997879886,
                        149.43377394844305
                    ],
                    [
                        158.8931235337975,
                        147.5919804012256
                    ],
                    [
                        160.35451997879886,
                        146.87776626955264
                    ],
                    [
                        160.96156157902988,
                        145.84048597200717
                    ],
                    [
                        159.7474783785674,
                        143.602476591019
                    ],
                    [
                        159.5226481562595,
                        141.93941457855743
                    ],
                    [
                        160.57935020110654,
                        139.67324098968385
                    ],
                    [
                        161.47867109033814,
                        139.0252179003624
                    ],
                    [
                        163.20986380210888,
                        139.92775509321194
                    ],
                    [
                        163.05248264649322,
                        140.73732394611335
                    ],
                    [
                        164.76119233603322,
                        142.00874057959822
                    ],
                    [
                        166.94204549241954,
                        142.67874944981673
                    ],
                    [
                        167.16687571472767,
                        143.602476591019
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Rampur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bhiwani",
            "dt_code": "081",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        111.99353916037285,
                        149.9399296807117
                    ],
                    [
                        111.29656547121863,
                        148.5591623858453
                    ],
                    [
                        110.39724458198702,
                        148.37497825570864
                    ],
                    [
                        109.79020298175556,
                        144.66415809144922
                    ],
                    [
                        109.11571231483185,
                        143.11758092813028
                    ],
                    [
                        109.9026180929095,
                        142.3553291189566
                    ],
                    [
                        111.40898058237235,
                        142.23980728237132
                    ],
                    [
                        112.17340333821926,
                        143.37159072344787
                    ],
                    [
                        114.37673951683655,
                        143.37159072344787
                    ],
                    [
                        116.37772849537646,
                        140.80669796885263
                    ],
                    [
                        119.6377667188408,
                        141.01480338803498
                    ],
                    [
                        120.19984227461055,
                        142.0318486321026
                    ],
                    [
                        120.3122573857645,
                        143.37159072344787
                    ],
                    [
                        122.0209670753045,
                        143.62556349573538
                    ],
                    [
                        121.63875569738116,
                        145.03328733715534
                    ],
                    [
                        121.14412920830364,
                        145.10249039162136
                    ],
                    [
                        120.26729134130301,
                        147.29250662882566
                    ],
                    [
                        118.80589489630188,
                        147.13123035877499
                    ],
                    [
                        116.19786431753028,
                        148.51311816087468
                    ],
                    [
                        114.60156973914422,
                        148.81238409262312
                    ],
                    [
                        114.15190929452842,
                        150.07794695008005
                    ],
                    [
                        115.45592458391411,
                        151.38857336438866
                    ],
                    [
                        115.27606040606793,
                        152.1009626481482
                    ],
                    [
                        111.99353916037285,
                        149.9399296807117
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Bhiwani",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Rohtak",
            "dt_code": "082",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        129.84505881161886,
                        145.07942301120164
                    ],
                    [
                        127.97896796646341,
                        145.7482535455273
                    ],
                    [
                        125.73066574338463,
                        145.35621149226813
                    ],
                    [
                        125.34845436546107,
                        146.32462652344714
                    ],
                    [
                        123.77464280930599,
                        146.20936710077928
                    ],
                    [
                        123.0551860979208,
                        146.87776626955264
                    ],
                    [
                        122.51559356438179,
                        145.51765121193375
                    ],
                    [
                        121.63875569738116,
                        145.03328733715534
                    ],
                    [
                        122.0209670753045,
                        143.62556349573538
                    ],
                    [
                        120.3122573857645,
                        143.37159072344787
                    ],
                    [
                        120.19984227461055,
                        142.0318486321026
                    ],
                    [
                        121.27902734168856,
                        140.41354179490486
                    ],
                    [
                        123.8645748982292,
                        140.32102149733697
                    ],
                    [
                        126.45012245476983,
                        140.94543768923205
                    ],
                    [
                        127.66420565523254,
                        141.75453171946685
                    ],
                    [
                        129.84505881161886,
                        145.07942301120164
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Rohtak",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Shamli",
            "dt_code": "704",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "update2014"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        133.7795877020069,
                        130.27618898977073
                    ],
                    [
                        134.83628974685394,
                        130.1365845891346
                    ],
                    [
                        136.50003339193222,
                        131.0205524053414
                    ],
                    [
                        138.00639588139506,
                        130.57862589197015
                    ],
                    [
                        138.70336957054928,
                        131.18333853087069
                    ],
                    [
                        138.36612423708766,
                        134.2733285047728
                    ],
                    [
                        137.78156565908694,
                        135.29435370518314
                    ],
                    [
                        135.6231955249316,
                        137.35775559619742
                    ],
                    [
                        133.82455374646838,
                        136.87110852534937
                    ],
                    [
                        133.4198593463143,
                        135.9901591315476
                    ],
                    [
                        133.48730841300676,
                        133.7161491708291
                    ],
                    [
                        133.1050970350832,
                        131.2065924218739
                    ],
                    [
                        133.7795877020069,
                        130.27618898977073
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Shamli",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ferozepur",
            "dt_code": "043",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        103.29260955705786,
                        105.3832672696708
                    ],
                    [
                        104.30434555744318,
                        106.3512834896298
                    ],
                    [
                        103.5399228015965,
                        107.10640937029473
                    ],
                    [
                        103.29260955705786,
                        108.82773552380814
                    ],
                    [
                        101.42651871190242,
                        109.77016108032268
                    ],
                    [
                        100.75202804497872,
                        112.42959521371313
                    ],
                    [
                        98.77352208866932,
                        111.0886483039011
                    ],
                    [
                        96.61515195451375,
                        110.71204563157744
                    ],
                    [
                        94.36684973143474,
                        112.2179393338422
                    ],
                    [
                        94.7940271538198,
                        113.6284657009598
                    ],
                    [
                        93.55746093112634,
                        114.52117622778758
                    ],
                    [
                        90.61218501889289,
                        113.20543439450836
                    ],
                    [
                        92.8155211975104,
                        109.74660704111204
                    ],
                    [
                        96.21045755435944,
                        107.27154661542465
                    ],
                    [
                        97.85171817720698,
                        106.68169404820247
                    ],
                    [
                        98.34634466628427,
                        105.879152274305
                    ],
                    [
                        101.62886591197935,
                        105.28879586572782
                    ],
                    [
                        103.29260955705786,
                        105.3832672696708
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Ferozepur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pathankot",
            "dt_code": "773",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        114.44418858352878,
                        81.78264080016584
                    ],
                    [
                        115.83813596183768,
                        83.8186604413624
                    ],
                    [
                        114.98378111706756,
                        83.79472260487671
                    ],
                    [
                        113.47741862760495,
                        85.66078137836263
                    ],
                    [
                        111.5213956935263,
                        86.30621034144457
                    ],
                    [
                        111.85864102698793,
                        88.28865706723838
                    ],
                    [
                        110.9818031599873,
                        89.05231107819549
                    ],
                    [
                        110.77945595991036,
                        88.21704562678059
                    ],
                    [
                        108.53115373683158,
                        88.0738130286581
                    ],
                    [
                        107.72176493652296,
                        87.50075297597738
                    ],
                    [
                        107.7442479587537,
                        85.99548120594443
                    ],
                    [
                        107.15968938075343,
                        84.65625530931538
                    ],
                    [
                        109.6777878706016,
                        85.3260104983832
                    ],
                    [
                        110.91435409329483,
                        84.10588606130119
                    ],
                    [
                        112.24085240491172,
                        83.89047176438032
                    ],
                    [
                        112.84789400514273,
                        82.81295723342777
                    ],
                    [
                        114.44418858352878,
                        81.78264080016584
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Pathankot",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Udhampur",
            "dt_code": "019",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        103.49495675713501,
                        69.96931858990149
                    ],
                    [
                        105.58587782459813,
                        70.62127747883119
                    ],
                    [
                        107.38451960306134,
                        72.02084985622002
                    ],
                    [
                        108.77846698137,
                        73.94921545629734
                    ],
                    [
                        110.39724458198702,
                        73.85285425178108
                    ],
                    [
                        111.79119196029546,
                        74.57541719410096
                    ],
                    [
                        112.17340333821926,
                        75.65863049877316
                    ],
                    [
                        111.76870893806495,
                        77.24597779806129
                    ],
                    [
                        109.40799160383222,
                        77.36616538443388
                    ],
                    [
                        108.21639142560048,
                        78.85572152896617
                    ],
                    [
                        106.32781755821406,
                        79.50395476916225
                    ],
                    [
                        104.50669275752057,
                        78.08709614807702
                    ],
                    [
                        103.49495675713501,
                        78.20721884574266
                    ],
                    [
                        104.57414182421257,
                        77.34212860960434
                    ],
                    [
                        101.85369613428725,
                        74.50317606299359
                    ],
                    [
                        103.36005862375009,
                        72.09320700802027
                    ],
                    [
                        102.57315284567267,
                        71.49012707047734
                    ],
                    [
                        103.49495675713501,
                        69.96931858990149
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Udhampur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Samba",
            "dt_code": "022",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        100.50471480044007,
                        81.61485077657295
                    ],
                    [
                        101.2916205785175,
                        80.4877948288048
                    ],
                    [
                        100.63961293382476,
                        79.43194214399551
                    ],
                    [
                        102.21342448997984,
                        77.77473378788056
                    ],
                    [
                        103.49495675713501,
                        78.20721884574266
                    ],
                    [
                        104.50669275752057,
                        78.08709614807702
                    ],
                    [
                        106.32781755821406,
                        79.50395476916225
                    ],
                    [
                        105.94560618029072,
                        82.21401840921243
                    ],
                    [
                        105.15870040221307,
                        83.41166763925168
                    ],
                    [
                        103.71978697944269,
                        82.66923276206631
                    ],
                    [
                        101.35906964520996,
                        82.90876623794958
                    ],
                    [
                        100.50471480044007,
                        81.61485077657295
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Samba",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Kapurthala",
            "dt_code": "036",
            "st_nm": "Punjab",
            "st_code": "03",
            "alpha2code": "PB",
            "year": "2011_c"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            113.72473187214337,
                            101.64746470461534
                        ],
                        [
                            114.62405276137497,
                            101.50542903706963
                        ],
                        [
                            115.23109436160644,
                            103.18538662066618
                        ],
                        [
                            113.88211302775903,
                            104.20198038070043
                        ],
                        [
                            112.03850520483456,
                            103.30362790555961
                        ],
                        [
                            112.17340333821926,
                            102.49941715033293
                        ],
                        [
                            113.72473187214337,
                            101.64746470461534
                        ]
                    ]
                ],
                [
                    [
                        [
                            108.08149329221555,
                            97.71319535893451
                        ],
                        [
                            109.13819533706283,
                            96.73999647798706
                        ],
                        [
                            110.9368371155258,
                            97.49961681969722
                        ],
                        [
                            109.79020298175556,
                            99.32598434313269
                        ],
                        [
                            108.62108582575479,
                            99.39709962247406
                        ],
                        [
                            109.36302555937073,
                            101.31602875005927
                        ],
                        [
                            108.9133651147547,
                            103.87106621956553
                        ],
                        [
                            108.28384049229271,
                            103.63465768670767
                        ],
                        [
                            106.21540244706034,
                            104.69822534813767
                        ],
                        [
                            106.10298733590616,
                            105.3832672696708
                        ],
                        [
                            103.98958324621208,
                            105.00534876073408
                        ],
                        [
                            103.29260955705786,
                            105.3832672696708
                        ],
                        [
                            101.62886591197935,
                            105.28879586572782
                        ],
                        [
                            102.82046609021108,
                            104.65097044620245
                        ],
                        [
                            103.71978697944269,
                            102.47575785356173
                        ],
                        [
                            106.41774964713727,
                            99.46821176550532
                        ],
                        [
                            108.08149329221555,
                            97.71319535893451
                        ]
                    ]
                ]
            ]
        },
        "id": "TT-Punjab-Kapurthala",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Panchkula",
            "dt_code": "069",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        128.27124725546355,
                        109.55816255202666
                    ],
                    [
                        129.62022858931095,
                        110.09988213639912
                    ],
                    [
                        130.3172022784654,
                        109.62883177232561
                    ],
                    [
                        131.91349685685122,
                        112.2179393338422
                    ],
                    [
                        134.0044179243148,
                        113.32295396423399
                    ],
                    [
                        134.07186699100703,
                        114.73253709535277
                    ],
                    [
                        133.35241027962184,
                        115.38993169112189
                    ],
                    [
                        130.18230414508048,
                        117.17297543907432
                    ],
                    [
                        130.4521004118501,
                        114.59163285058702
                    ],
                    [
                        129.0131869890797,
                        113.36995944955044
                    ],
                    [
                        129.17056814469515,
                        112.12386132629348
                    ],
                    [
                        129.2829832558491,
                        110.75912568444716
                    ],
                    [
                        128.27124725546355,
                        109.55816255202666
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Panchkula",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Ambala",
            "dt_code": "070",
            "st_nm": "Haryana",
            "st_code": "06",
            "alpha2code": "HR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        133.35241027962184,
                        115.38993169112189
                    ],
                    [
                        134.61145952454604,
                        116.68048380076033
                    ],
                    [
                        134.0044179243148,
                        117.52466583432033
                    ],
                    [
                        134.16179907993023,
                        119.46934387887973
                    ],
                    [
                        132.90274983500603,
                        121.15445925434881
                    ],
                    [
                        132.49805543485195,
                        122.83787943812041
                    ],
                    [
                        130.96920992315813,
                        121.69240234347961
                    ],
                    [
                        128.85580583346427,
                        121.27141810984722
                    ],
                    [
                        126.8997828993854,
                        121.80932362990131
                    ],
                    [
                        126.29274129915439,
                        122.44055765559403
                    ],
                    [
                        125.10114112092242,
                        120.87372464136291
                    ],
                    [
                        127.30447729953971,
                        119.44592752306795
                    ],
                    [
                        127.84406983307872,
                        117.68876256369498
                    ],
                    [
                        130.24975321177294,
                        117.73564436989062
                    ],
                    [
                        130.04740601169578,
                        117.40744396948793
                    ],
                    [
                        130.18230414508048,
                        117.17297543907432
                    ],
                    [
                        133.35241027962184,
                        115.38993169112189
                    ]
                ]
            ]
        },
        "id": "TT-Haryana-Ambala",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Aurangabad",
            "dt_code": "235",
            "st_nm": "Bihar",
            "st_code": "10",
            "alpha2code": "BR",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        234.59345938486194,
                        213.69384055756348
                    ],
                    [
                        235.71761049640145,
                        211.6246764104614
                    ],
                    [
                        238.95516569763527,
                        207.72529672508693
                    ],
                    [
                        240.68635840940578,
                        206.5874715974225
                    ],
                    [
                        241.04608676509838,
                        205.82855952832188
                    ],
                    [
                        244.53095521087062,
                        206.2750135196556
                    ],
                    [
                        245.2504119222558,
                        207.01144328844862
                    ],
                    [
                        245.00309867771693,
                        209.12994910612196
                    ],
                    [
                        243.87894756617766,
                        209.93216446600633
                    ],
                    [
                        245.27289494448655,
                        211.7137164826724
                    ],
                    [
                        245.09303076664014,
                        212.75964235024918
                    ],
                    [
                        243.3168720104079,
                        213.47145162573082
                    ],
                    [
                        242.5074832100995,
                        215.56094302507728
                    ],
                    [
                        241.5856792986374,
                        215.7831025955789
                    ],
                    [
                        240.23669796478998,
                        214.91654265382357
                    ],
                    [
                        239.26992800886592,
                        216.16071809324785
                    ],
                    [
                        238.23570898624962,
                        215.56094302507728
                    ],
                    [
                        235.8300256075554,
                        215.44985411948062
                    ],
                    [
                        234.59345938486194,
                        213.69384055756348
                    ]
                ]
            ]
        },
        "id": "TT-Bihar-Aurangabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Balrampur",
            "dt_code": "716",
            "st_nm": "Chhattisgarh",
            "st_code": "22",
            "alpha2code": "CT",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        217.01173600038555,
                        224.67186558224176
                    ],
                    [
                        218.99024195669494,
                        226.13118834659565
                    ],
                    [
                        221.10364604638903,
                        226.13118834659565
                    ],
                    [
                        222.8123557359288,
                        225.31320940126614
                    ],
                    [
                        224.610997514392,
                        222.41452584882916
                    ],
                    [
                        226.11736000385486,
                        222.6802232120328
                    ],
                    [
                        227.33144320431734,
                        224.36218035573995
                    ],
                    [
                        228.16331502685648,
                        226.2638032816111
                    ],
                    [
                        230.1418209831661,
                        226.94884599338133
                    ],
                    [
                        231.15355698355143,
                        230.32657898710443
                    ],
                    [
                        233.55924036224587,
                        230.98824255043223
                    ],
                    [
                        234.7058744960159,
                        229.92948093797713
                    ],
                    [
                        234.68339147378515,
                        232.02443131085795
                    ],
                    [
                        233.91896871793847,
                        233.91912222297583
                    ],
                    [
                        235.56022934078578,
                        234.75577223713685
                    ],
                    [
                        235.020636807247,
                        237.35169797588736
                    ],
                    [
                        233.37937618439946,
                        237.39566954230253
                    ],
                    [
                        231.40087022809007,
                        236.07612911658657
                    ],
                    [
                        228.95022080493413,
                        236.58204924264965
                    ],
                    [
                        227.91600178231784,
                        237.37368387198012
                    ],
                    [
                        226.7693676485478,
                        235.96613061229482
                    ],
                    [
                        225.53280142585436,
                        235.76811900972365
                    ],
                    [
                        224.97072587008438,
                        237.3297118540064
                    ],
                    [
                        223.37443129169878,
                        236.38409477212406
                    ],
                    [
                        222.83483875815978,
                        236.1641238376518
                    ],
                    [
                        222.8123557359288,
                        234.05124676786642
                    ],
                    [
                        224.8807937811614,
                        232.57538843244515
                    ],
                    [
                        223.86905778077607,
                        230.85592647193658
                    ],
                    [
                        222.69994062477485,
                        230.56921311091634
                    ],
                    [
                        222.67745760254434,
                        229.11298800763524
                    ],
                    [
                        220.7214346684657,
                        227.76618323786454
                    ],
                    [
                        218.4281664009252,
                        227.69992456524244
                    ],
                    [
                        216.56207555576975,
                        226.99303456837112
                    ],
                    [
                        216.4046944001543,
                        226.04277369624054
                    ],
                    [
                        215.25806026638406,
                        225.35743270862667
                    ],
                    [
                        217.01173600038555,
                        224.67186558224176
                    ]
                ]
            ]
        },
        "id": "TT-Chhattisgarh-Balrampur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Balrampur",
            "dt_code": "182",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        206.15243626291476,
                        168.54474642110074
                    ],
                    [
                        205.38801350706808,
                        165.58266241300635
                    ],
                    [
                        206.30981741853043,
                        164.69309736870974
                    ],
                    [
                        206.8943759965307,
                        163.00400546909435
                    ],
                    [
                        208.1084591969934,
                        161.1990443739952
                    ],
                    [
                        211.66077670945788,
                        163.9400480571705
                    ],
                    [
                        214.83088284399923,
                        163.20951967031078
                    ],
                    [
                        215.43792444423025,
                        163.52916207809204
                    ],
                    [
                        216.1348981333847,
                        165.46863993108872
                    ],
                    [
                        215.82013582215382,
                        167.13265985879747
                    ],
                    [
                        215.57282257761517,
                        168.54474642110074
                    ],
                    [
                        213.54935057684406,
                        168.20321257703216
                    ],
                    [
                        212.3127843541506,
                        169.25038120501512
                    ],
                    [
                        212.58258062092023,
                        171.68387701453582
                    ],
                    [
                        211.77319182061206,
                        172.68363397906506
                    ],
                    [
                        211.2560823093038,
                        173.77366080718775
                    ],
                    [
                        209.83965190876415,
                        174.09146430204362
                    ],
                    [
                        208.4232215082243,
                        172.3883076403149
                    ],
                    [
                        209.43495750860984,
                        171.0928564767043
                    ],
                    [
                        206.15243626291476,
                        168.54474642110074
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Balrampur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hamirpur",
            "dt_code": "168",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        175.10338256219597,
                        189.1703022043481
                    ],
                    [
                        175.01345047327277,
                        190.34083090612216
                    ],
                    [
                        177.30671874081304,
                        190.85833667654896
                    ],
                    [
                        176.90202434065895,
                        191.6455777651538
                    ],
                    [
                        179.127843541507,
                        192.81459811037888
                    ],
                    [
                        180.746621142124,
                        193.26403242454398
                    ],
                    [
                        179.06039447481453,
                        194.90358023129806
                    ],
                    [
                        180.36440976420045,
                        195.66673115035542
                    ],
                    [
                        179.7573681639692,
                        197.7078069149785
                    ],
                    [
                        178.8130812302761,
                        199.52279082718655
                    ],
                    [
                        176.74464318504351,
                        198.3353997048656
                    ],
                    [
                        173.75440122834857,
                        198.51467483345783
                    ],
                    [
                        172.51783500565534,
                        198.31298915526997
                    ],
                    [
                        171.2363027385004,
                        198.94038727316092
                    ],
                    [
                        170.35946487149977,
                        200.01545839074015
                    ],
                    [
                        168.718204248652,
                        200.0602402020371
                    ],
                    [
                        166.7396982923426,
                        199.455599272518
                    ],
                    [
                        166.17762273657308,
                        197.416355810887
                    ],
                    [
                        166.80714735903484,
                        195.06072411993205
                    ],
                    [
                        168.2685438040362,
                        193.93798761538574
                    ],
                    [
                        169.05544958211385,
                        194.52189194203225
                    ],
                    [
                        169.9322874491147,
                        193.2865013903948
                    ],
                    [
                        171.9557594498856,
                        193.10674233294566
                    ],
                    [
                        172.49535198342437,
                        193.84814051687778
                    ],
                    [
                        173.75440122834857,
                        193.53364274333688
                    ],
                    [
                        173.6869521616561,
                        192.65727133490464
                    ],
                    [
                        174.99096745104202,
                        191.21825836718608
                    ],
                    [
                        174.42889189527227,
                        190.1382906085155
                    ],
                    [
                        175.10338256219597,
                        189.1703022043481
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Hamirpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Hamirpur",
            "dt_code": "028",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        121.43640849730377,
                        95.48108390993377
                    ],
                    [
                        121.25654431945782,
                        94.48275213818687
                    ],
                    [
                        123.23505027576698,
                        94.24496226574757
                    ],
                    [
                        125.03369205422996,
                        92.65085656791331
                    ],
                    [
                        126.24777525469267,
                        92.88888388109496
                    ],
                    [
                        126.22529223246192,
                        95.05330366796773
                    ],
                    [
                        127.48434147738612,
                        96.47879397501185
                    ],
                    [
                        127.59675658854007,
                        97.90301913664956
                    ],
                    [
                        125.95549596569231,
                        98.02164762236089
                    ],
                    [
                        125.82059783230784,
                        100.74769476573431
                    ],
                    [
                        123.63974467592129,
                        99.53932077287101
                    ],
                    [
                        122.74042378668969,
                        97.45215106835911
                    ],
                    [
                        121.43640849730377,
                        95.48108390993377
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Hamirpur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pratapgarh",
            "dt_code": "131",
            "st_nm": "Rajasthan",
            "st_code": "08",
            "alpha2code": "RJ",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        91.42157381920129,
                        224.87092452690558
                    ],
                    [
                        90.70211710781632,
                        222.4588111186904
                    ],
                    [
                        90.94943035235497,
                        220.73097979638413
                    ],
                    [
                        92.68062306412571,
                        221.37354800699467
                    ],
                    [
                        93.7598081312035,
                        219.910859461308
                    ],
                    [
                        95.06382342058919,
                        219.44523983538272
                    ],
                    [
                        96.16549150989772,
                        215.84974572626857
                    ],
                    [
                        97.69433702159154,
                        216.27176811965217
                    ],
                    [
                        99.42552973336205,
                        216.49384997521207
                    ],
                    [
                        100.01008831136255,
                        217.22654815781567
                    ],
                    [
                        98.93090324428476,
                        218.60242078962796
                    ],
                    [
                        98.52620884413068,
                        220.1547132571883
                    ],
                    [
                        100.18995248920874,
                        220.13254592818816
                    ],
                    [
                        100.7969940894402,
                        220.64233393146185
                    ],
                    [
                        100.36981666705515,
                        221.2627747080008
                    ],
                    [
                        101.76376404536427,
                        223.41071473313826
                    ],
                    [
                        100.70706200051723,
                        226.08698149088036
                    ],
                    [
                        101.26913755628675,
                        227.83243980999953
                    ],
                    [
                        100.99934128951713,
                        229.42196863275268
                    ],
                    [
                        99.89767320020883,
                        231.25284978267752
                    ],
                    [
                        98.79600511090007,
                        231.4071886640163
                    ],
                    [
                        98.43627675520747,
                        230.260401186965
                    ],
                    [
                        96.99736333243686,
                        229.77503365408177
                    ],
                    [
                        95.06382342058919,
                        229.9074177354044
                    ],
                    [
                        95.6933480430514,
                        227.19187157458504
                    ],
                    [
                        94.50174786481944,
                        225.2689851524994
                    ],
                    [
                        92.97290235312585,
                        225.60064407781806
                    ],
                    [
                        91.42157381920129,
                        224.87092452690558
                    ]
                ]
            ]
        },
        "id": "TT-Rajasthan-Pratapgarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Pratapgarh",
            "dt_code": "173",
            "st_nm": "Uttar Pradesh",
            "st_code": "09",
            "alpha2code": "UP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        205.77022488499142,
                        190.65585105163478
                    ],
                    [
                        206.5346476408381,
                        190.43084194475153
                    ],
                    [
                        207.9735610636087,
                        191.30822824651284
                    ],
                    [
                        208.62556870830144,
                        190.67835051008848
                    ],
                    [
                        210.33427839784144,
                        191.5331345073826
                    ],
                    [
                        211.43594648714998,
                        191.5106450661874
                    ],
                    [
                        210.49165955345666,
                        191.89292977897162
                    ],
                    [
                        210.26682933114898,
                        193.1741539432444
                    ],
                    [
                        209.16516124184022,
                        194.18504559094163
                    ],
                    [
                        208.26584035260885,
                        194.2299618292372
                    ],
                    [
                        207.81617990799305,
                        196.3847165406956
                    ],
                    [
                        207.27658737445404,
                        196.94545796243835
                    ],
                    [
                        205.9950551072991,
                        197.2369795936258
                    ],
                    [
                        205.38801350706808,
                        195.80137365201693
                    ],
                    [
                        203.38702452852772,
                        196.31741669227728
                    ],
                    [
                        202.08300923914203,
                        195.68917221653692
                    ],
                    [
                        201.40851857221833,
                        197.01273606343653
                    ],
                    [
                        199.34008052698596,
                        198.17852044928526
                    ],
                    [
                        197.2491594595224,
                        197.81989188029127
                    ],
                    [
                        195.85521208121372,
                        196.5866020947537
                    ],
                    [
                        196.1250083479831,
                        195.3300839783049
                    ],
                    [
                        195.3381025699057,
                        194.7015193402387
                    ],
                    [
                        195.24817048098248,
                        193.73582576489042
                    ],
                    [
                        196.91191412606054,
                        192.67974737524634
                    ],
                    [
                        198.7330389267545,
                        192.0053519936551
                    ],
                    [
                        198.68807288229277,
                        190.40833958140684
                    ],
                    [
                        199.1826993713703,
                        189.26036830341496
                    ],
                    [
                        201.65583181675697,
                        190.49834744980058
                    ],
                    [
                        203.36454150629697,
                        190.2733198525567
                    ],
                    [
                        205.77022488499142,
                        190.65585105163478
                    ]
                ]
            ]
        },
        "id": "TT-Uttar Pradesh-Pratapgarh",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Bilaspur",
            "dt_code": "030",
            "st_nm": "Himachal Pradesh",
            "st_code": "02",
            "alpha2code": "HP",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        127.59675658854007,
                        97.90301913664956
                    ],
                    [
                        128.63097561115615,
                        100.67663896610509
                    ],
                    [
                        129.7326437004649,
                        101.88416312247182
                    ],
                    [
                        130.47458343408084,
                        102.68867909347927
                    ],
                    [
                        129.1930511669259,
                        103.94198207056235
                    ],
                    [
                        127.61923961077105,
                        103.77650693561367
                    ],
                    [
                        126.33770734361565,
                        104.320147682536
                    ],
                    [
                        125.48335249884599,
                        103.20903556689939
                    ],
                    [
                        124.15685418722933,
                        103.27998033778812
                    ],
                    [
                        123.19008423130526,
                        102.31013310405513
                    ],
                    [
                        122.605525653305,
                        100.81874744128868
                    ],
                    [
                        123.63974467592129,
                        99.53932077287101
                    ],
                    [
                        125.82059783230784,
                        100.74769476573431
                    ],
                    [
                        125.95549596569231,
                        98.02164762236089
                    ],
                    [
                        127.59675658854007,
                        97.90301913664956
                    ]
                ]
            ]
        },
        "id": "TT-Himachal Pradesh-Bilaspur",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Niwari",
            "dt_code": "782",
            "st_nm": "Madhya Pradesh",
            "st_code": "23",
            "alpha2code": "MP",
            "year": "2018"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        154.46396815433218,
                        204.44391284074004
                    ],
                    [
                        153.78947748740848,
                        203.41596763802636
                    ],
                    [
                        155.94784762156405,
                        201.67170446403247
                    ],
                    [
                        157.63407428887353,
                        202.16383264027468
                    ],
                    [
                        157.00454966641132,
                        200.4632304332613
                    ],
                    [
                        159.25285188948988,
                        199.52279082718655
                    ],
                    [
                        160.10720673426,
                        199.9034993806925
                    ],
                    [
                        159.18540282279764,
                        201.6269594291178
                    ],
                    [
                        160.3770030010296,
                        202.29802804698835
                    ],
                    [
                        159.05050468941317,
                        204.86834294836825
                    ],
                    [
                        159.18540282279764,
                        205.33734405868904
                    ],
                    [
                        158.6682933114896,
                        206.31965339718022
                    ],
                    [
                        155.65556833256414,
                        206.7436822131175
                    ],
                    [
                        154.46396815433218,
                        204.44391284074004
                    ]
                ]
            ]
        },
        "id": "TT-Madhya Pradesh-Niwari",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Muzaffarabad",
            "dt_code": "992",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        89.60044901850756,
                        36.02044055302349
                    ],
                    [
                        91.1517775524319,
                        37.1070252429389
                    ],
                    [
                        93.04035141981831,
                        36.73668909486253
                    ],
                    [
                        94.59167995374264,
                        35.798085324476006
                    ],
                    [
                        96.07555942097451,
                        37.600661431359185
                    ],
                    [
                        95.7832801319746,
                        40.04172185287791
                    ],
                    [
                        94.16450253135758,
                        40.41122658758843
                    ],
                    [
                        93.51249488666463,
                        41.37150576994429
                    ],
                    [
                        92.1410305305867,
                        41.593019937985446
                    ],
                    [
                        89.75783017412323,
                        43.43768109707537
                    ],
                    [
                        87.10483355089013,
                        43.3885199782336
                    ],
                    [
                        86.9474523952747,
                        45.32914495154398
                    ],
                    [
                        85.86826732819668,
                        47.61043196842115
                    ],
                    [
                        84.63170110550323,
                        48.05156766326081
                    ],
                    [
                        83.91224439411803,
                        49.34970965264256
                    ],
                    [
                        86.52027497288941,
                        49.69242661124292
                    ],
                    [
                        87.35214679542878,
                        51.038054235443155
                    ],
                    [
                        87.48704492881348,
                        52.33360615550191
                    ],
                    [
                        86.45282590619718,
                        53.11528438253083
                    ],
                    [
                        85.89075035042742,
                        54.45784372149035
                    ],
                    [
                        84.58673506104174,
                        54.165023967419415
                    ],
                    [
                        82.33843283796296,
                        54.482242794018816
                    ],
                    [
                        80.58475710396146,
                        52.91990300385555
                    ],
                    [
                        79.91026643703776,
                        53.04201934623245
                    ],
                    [
                        79.97771550373022,
                        51.69819239932076
                    ],
                    [
                        78.47135301426738,
                        48.95793676279209
                    ],
                    [
                        79.30322483680652,
                        45.40278954661551
                    ],
                    [
                        81.82132332665469,
                        45.18184479861884
                    ],
                    [
                        83.7548632385026,
                        41.593019937985446
                    ],
                    [
                        86.90248635081298,
                        40.6082579107599
                    ],
                    [
                        88.52126395142977,
                        38.85868333764142
                    ],
                    [
                        88.40884884027582,
                        36.90952426538581
                    ],
                    [
                        89.60044901850756,
                        36.02044055302349
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Muzaffarabad",
        "value": 0
    },
    {
        "type": "Feature",
        "properties": {
            "district": "Mirpur",
            "dt_code": "991",
            "st_nm": "Jammu and Kashmir",
            "st_code": "01",
            "alpha2code": "JK",
            "year": "2011_c"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        81.52904403765456,
                        60.69116073294845
                    ],
                    [
                        83.08037257157912,
                        62.29403617232413
                    ],
                    [
                        85.1937766612732,
                        62.80368785410292
                    ],
                    [
                        86.74510519519754,
                        62.29403617232413
                    ],
                    [
                        88.38636581804508,
                        63.55571918270505
                    ],
                    [
                        89.60044901850756,
                        63.74973155009002
                    ],
                    [
                        89.89272830750792,
                        66.00331736010207
                    ],
                    [
                        89.37561879619966,
                        67.84249506695915
                    ],
                    [
                        87.50952795104422,
                        68.6887434498974
                    ],
                    [
                        87.46456190658273,
                        69.41372828881254
                    ],
                    [
                        89.60044901850756,
                        70.79025881697237
                    ],
                    [
                        89.84776226304643,
                        71.92436837810192
                    ],
                    [
                        92.1410305305867,
                        72.72016036241996
                    ],
                    [
                        92.34337773066363,
                        74.04557066432005
                    ],
                    [
                        93.49001186443388,
                        75.05693872097106
                    ],
                    [
                        94.00712137574214,
                        76.93344662368224
                    ],
                    [
                        93.06283444204905,
                        77.48634369150966
                    ],
                    [
                        90.05010946312336,
                        75.2976434304428
                    ],
                    [
                        88.31891675135262,
                        74.84027253966997
                    ],
                    [
                        86.81255426188977,
                        73.46734943608578
                    ],
                    [
                        84.92398039450359,
                        73.10585141800277
                    ],
                    [
                        82.85554234927099,
                        71.70726299935961
                    ],
                    [
                        81.95622146003961,
                        71.6590132115141
                    ],
                    [
                        82.38339888242444,
                        69.58284223280737
                    ],
                    [
                        81.03441754857727,
                        67.2377460317474
                    ],
                    [
                        81.86628937111618,
                        63.14336087915535
                    ],
                    [
                        80.98945150411555,
                        62.318309170878365
                    ],
                    [
                        81.52904403765456,
                        60.69116073294845
                    ]
                ]
            ]
        },
        "id": "TT-Jammu and Kashmir-Mirpur",
        "value": 0
    }
];
