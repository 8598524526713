import React, { useEffect, useMemo } from "react";
//import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
//import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
    // FormattedMessage, 
    injectIntl
} from "react-intl";
//import { useHistory } from "react-router-dom";
//import { Dropdown } from "react-bootstrap";
//import { DropdownMenu2 } from "../../../../_metronic/_partials/dropdowns";
import { useHtmlClassService } from "../../../../_metronic/layout";
import '../css/Chart.scss';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ColumnChart from './ColumnChart';
import { useDispatch, useSelector } from "react-redux";
import { portfolioProfitCounts, portfolios } from "../../../store/financierDashboard";
import millify from 'millify';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: theme.palette.background.paper
        //width: 100%,
    },
}));



function AreaChart(props) {
    // console.log(" props ===== ", props);
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const user = useSelector(state => state.entities.auth.data);
    const portfolioProfitCount = props.portfolioProfitCount;
    const portfolio = props.portfolio;
    const noContact = props.noContact;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseColor: objectPath.get(
                uiService.config,
                `js.colors.theme.base.danger`
            ),
            colorsThemeLightColor: objectPath.get(
                uiService.config,
                `js.colors.theme.light.danger`
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);

    // useEffect(()=>{
    //     dispatch(portfolioProfitCounts(user.userId))
    //     dispatch(portfolios(user.userId));
    // },[])

    

    useEffect(() => {
        const element = document.getElementById(`area_chart2`);

        if (!element) {
            return;
        }
        if(portfolio) {
            const options = getChartOption(portfolio);
            // console.log(" options ====== ", options);
            const chart = new ApexCharts(element, options);
            chart.render();
            return function cleanUp() {
                chart.destroy();
            };
        }
        
    });



    return (
        <div>
            <div className="report-card-div areaChart">
                <div className={`card card-custom card-stretch gutter-b card-div`}>
                    {/* Body */}
                    <div className="card-body position-relative overflow-hidden borderTopRadius tablmoloik">
                        {/* Title */}
                        {/* <div
                            id={`area_chart`}
                            className="card-rounded-bottom"
                            style={{ height: "150px" }}>
                        </div> */}
                        <div className={classes.root}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                    style={{ backgroundColor: '#ffffff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflow: 'hidden' }}
                                >
                                    <Tab label="Portfolio" {...a11yProps(0)} />
                                    <Tab label="No Contact" {...a11yProps(1)} />
                                </Tabs>
                                <div className="staticContentDiv">
                                    <div className="staticContentChild">
                                        <div className="title">Total accounts</div>
                                        <div className="value">{portfolioProfitCount ? portfolioProfitCount.totalAccounts : 0}</div>
                                    </div>
                                    <div className="staticContentChild">
                                        <div className="title">POS</div>
                                        <div className="value">{(portfolioProfitCount && portfolioProfitCount.pos && portfolioProfitCount.pos > 0) ? millify(portfolioProfitCount.pos, { space: true }) : 0}</div>
                                    </div>
                                    <div className="staticContentChild">
                                        <div className="title">Last Month ROR%</div>
                                        <div className="value">{(portfolioProfitCount && portfolioProfitCount.lastMonthResolution && portfolioProfitCount.lastMonthResolution > 0) ? portfolioProfitCount.lastMonthResolution : 0}</div>
                                    </div>
                                    <div className="staticContentChild">
                                        <div className="title">Last Week Contact</div>
                                        <div className="value">{portfolioProfitCount ? portfolioProfitCount.lastWeekData : 0}</div>
                                    </div>
                                    <div className="staticContentChild">
                                        <div className="title">Today Contact</div>
                                        <div className="value">{portfolioProfitCount ? portfolioProfitCount.todayData : 0}</div>
                                    </div>
                                </div>
                            </AppBar>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    <div
                                        id={`area_chart2`}
                                        className="card-rounded-bottom"
                                        style={{ width: "100%" }}
                                    >
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <ColumnChart noContact={noContact} />
                                </TabPanel>
                            </SwipeableViews>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(AreaChart);

function getChartOption(portfolio) {
    // console.log(" portfolio ======= ", portfolio);
    var generateDayWiseTimeSeries = function (baseval, count, yrange) {
        var i = 0;
        var series = [];
        while (i < count) {
            var x = baseval;
            // console.log(" x ==== ", x);
            var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

            series.push([x, y]);
            baseval += 86400000;
            i++;
        }
        return series;
    }

    const options = {
        // series: [
        //     {
        //         name: portfolio[0].name,
        //         // data: generateDayWiseTimeSeries(new Date('01 April 2022 GMT').getTime(), 30, {
        //         //     min: 10,
        //         //     max: 60
        //         // })
        //         data:portfolio[0].data
        //     },
        //     {
        //         // name: 'Positive Pool',
        //         // data: generateDayWiseTimeSeries(new Date('01 April 2022 GMT').getTime(), 30, {
        //         //     min: 10,
        //         //     max: 20
        //         // })
        //         name:portfolio[1].name,
        //         data:portfolio[1].data
        //     },
        //     {
        //         // name: 'Resolution',
        //         // data: generateDayWiseTimeSeries(new Date('01 April 2022 GMT').getTime(), 30, {
        //         //     min: 10,
        //         //     max: 15
        //         // })
        //         name:portfolio[2].name,
        //         data:portfolio[2].data
        //     }
        // ],

        series:portfolio,

        chart: {
            type: 'area',
            height: 388,
            stacked: true,
            events: {
                selection: function (chart, e) {
                    console.log(new Date(e.xaxis.min))
                }
            },
        },
        colors: ['#008FFB', '#00E396', '#CED4DC'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.6,
                opacityTo: 0.8,
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        },
        xaxis: {
            type: 'datetime'
        },

    };

    // console.log(" options ==== ", options);
    return options;
}

