import React, { useState, useEffect } from "react";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from "react-table";
import matchSorter from "match-sorter";
import { FaRegFileExcel, FaRegTimesCircle } from "react-icons/fa";
import Loader from "../Loader/Loader";
import { useHistory } from "react-router-dom";
import { getData, postData } from '../../store/utils.js';
import ReactModal from "react-modal";
import Select from "react-select";
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import Papa from 'papaparse'; // Import papaparse
import { useDebounce } from 'use-debounce';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";
import DatePicker from "react-date-picker";

function SmsCampaign() {
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [csvData, setCsvData] = useState([]); // State to store CSV data
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [columns, setColumns] = useState([]);  // Holds the dynamically generated columns
  const [debouncedValue] = useDebounce(searchBoxValue, 1000); // 500ms debounce delay
  const [closeBtn, setCloseBtn] = useState(false)
  const [file, setFile] = useState(null)
  const [templateType, setTemplateType] = useState([]);
  const [templateTypeValue, setTemplateTypeValue] = useState(null)
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [templateValue, setTemplateValue] = useState([])
  const [templateId, setTemplateId] = useState([])
  const [templateBody, setTemplateBody]= useState();
  const [senderId, setSenderId]= useState();
  const [campaignName, setCampaignName] = useState("");
  const [campaignDetail, setCampaignDetails] = useState([]);
  const [campaignId, setCampaignId] = useState()
  const [fileName, setFileName] = useState(''); // State to store file name

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "none",
      padding: "0px",
      width: "25%",
    },
  };

  const history = useHistory();
  let token = localStorage.getItem("isAuthenticated");

  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
      setPageIndex(page);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleInputChange = (e) => {
    const searchVal = e.target.value;
    setSearchBoxValue(searchVal)
    if (searchVal.length > 0) {
      setCloseBtn(true)
    }
  };

  const handleSort = (column) => {
    let newOrderBy = "asc";
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
  };

  const handleSubmit = async () => {
    if (!templateValue?.id) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please select the template"
      })
    } else {
      let filterData = {
        templateId: templateId,
        smsFileName: campaignName,
        campaignId: campaignId
      };
      console.log("filterData === ", filterData)
      const tempData = await postData("sms/runSmsCampaign", filterData);

      if (tempData.statusCode == 200) {
        setIsOpen(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: tempData?.message
        })
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: tempData?.message || "Something went wrong!!!"
        })
      }
    }
  }

  const runCampaign = (id) => {
    setIsOpen(true);
    showTemplateList();
    setCampaignId(id)
  }

  const showTemplateList = async () => {
    try {
      const response = await getData('sms/getTemplate');
      const data = response?.data;
      console.log("templateData === ", data);      
      const newData = data.map(item => ({
        id: item.id,
        label: item.templateName,
        templateBody: item.templateBody,
        senderId: item.senderId,
      }));
      setTemplateOptions(newData || [])

    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('Error fetching data');
    } finally {
      setIsLoadingOn(false);
    }
  }

  const formatDate = (dateString) => {
    return moment(dateString).utc().format("DD-MM-YYYY HH:mm:ss");
  }

  const getIploadedFileList = async () => {
    try {
      const currentPage = pageIndex + 1;
      
      const response = await getData(`sms/getCampaignList?page=${currentPage}&pageSize=${pageSize}&search=${searchBoxValue}&sortBy=${orderBy}&orderBy=${sortBy}`);
      console.log("response === ", response);
      const data = response?.data

      const columnsToShow = [
        // { Header: 'S.No', accessor: (row, i) => i + 1 + pageIndex * pageSize, disableSortBy: true }, // Serial Number Column
        // { Header: 'id', accessor: 'id' },
        { Header: 'Campaign Name', accessor: 'campaignName' },
        { Header: 'Total Records', accessor: 'totalRecord' },
        { Header: 'Status', accessor: 'status' },
        // { Header: 'Created At', accessor: 'createdAt', Cell: ({ value }) => formatDate(value) },
        {
          Header: 'Download', accessor: 'campaignUrl', disableSortBy: true,
          Cell: ({ row }) => (
            <a
              href={row.original.campaignUrl} // Ensure this URL does not have a leading slash
              target="_blank"
              rel="noopener noreferrer"
              download // This allows the download
              style={{ color: '#2e1f7a', textDecoration: 'underline', cursor: 'pointer' }}
            >
              <FaRegFileExcel style={{ fontSize: 24 }} />
            </a>
          ),
        },
        {
          Header: 'Action', // Custom column for download button
          accessor: 'action', // You can use any accessor; it won't affect the display
          Cell: ({ row }) => (
            <button className="btn btn-primary font-weight-bold transformBtn agentTransformBtn" type="button" onClick={() => runCampaign(row.original.id)}>Run Campaign</button>
          ),
          disableSortBy: true
        },
      ];
      
      // Filter out any columns that don't exist in data[0], to avoid errors
      const columnHeader = columnsToShow.filter(column => data[0]?.hasOwnProperty(column.accessor) || column.Header === 'S.No' || column.Header === 'Action');

      setColumns(columnHeader);
      setTotalRecords(response?.pagination?.totalRecords)
      setPageCount(response?.pagination?.totalPages)
      setCsvData(data);

    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('Error fetching data');
    } finally {
      setIsLoadingOn(false);
    }
  };


  const handleLinkClick = (id) => {
    history.push("/loanDetails?loanId=" + id);
  }

  const resetPage = () => {
    window.location.reload(); // Refresh the page
  };

  useEffect(() => {
    getIploadedFileList();
  }, [pageIndex, pageSize, sortBy, orderBy, debouncedValue]);

  const handleTemplateValue = async (selectedOption) => {
    console.log(selectedOption)
    setTemplateValue(selectedOption); // Set the selected option state
    setTemplateId(selectedOption.id)
    setTemplateBody(selectedOption.templateBody)
    setSenderId(selectedOption.senderId)
  }

  const handleChange = (e) => {
    setCampaignName(e.target.value)
  }


  const addCampaign = () => {
    window.location.href = '/campaign/add-sms-campaign';
  }
  
  const selectCampaign = () => {
    window.location.href = '/campaign/select-sms-campaign';
  }
  
  return (
    <div className="row m-0 loanDataImport">
        <Card className="col-12">
            <CardHeader className="col-4 d-flex align-items-center">
                <div className="col-2">
                    <button
                        type="button"
                        className="btn btn-primary font-weight-bold transformBtn agentTransformBtn"
                        style={{ width: "100%" }}
                        onClick={addCampaign}
                    >
                        Add Campaign
                    </button>
                </div>
                <div className="col-2">
                    <button
                        type="button"
                        className="btn btn-primary font-weight-bold transformBtn agentTransformBtn"
                        style={{ width: "100%" }}
                        onClick={selectCampaign}
                    >
                        Select Campaign
                    </button>
                </div>
                <div className="col-8 d-flex align-items-center justify-content-center">
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                </div>
            </CardHeader>
        </Card>

        <div className="col-12 pl-0 pr-0 customCardDiv">
            {isLoadingOn && <Loader />}
            <Card className="uploadedDataCard">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    <div className="col-lg-3 d-flex justify-content-start align-items-center pl-5">
                        <h3 className="pl-0 mb-0">Campaign List</h3>
                    </div>
                    <div className="col-lg-9 d-flex justify-content-end">
                        <div style={{ width: "100%", maxWidth: "400px" }}>
                            <input
                                type="text"
                                style={{
                                    width: "80%",
                                    padding: "10px 15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "25px",
                                    fontSize: "14px",
                                    outline: "none",
                                    boxShadow: "none",
                                    transition: "border-color 0.3s, box-shadow 0.3s",
                                }}
                                onFocus={(e) => {
                                    e.target.style.borderColor = "#007bff";
                                    e.target.style.boxShadow = "0 0 5px rgba(0, 123, 255, 0.5)";
                                }}
                                onBlur={(e) => {
                                    e.target.style.borderColor = "#ccc";
                                    e.target.style.boxShadow = "none";
                                }}
                                onChange={handleInputChange}
                                placeholder="Enter Search value"
                                value={searchBoxValue}
                            />
                        </div>
                    </div>
                </CardHeader>
                <CardBody className="customCardBody">
                    <div className="table-responsive">
                        {columns && columns.length > 0 ? (
                            <Table
                                columns={columns}
                                data={csvData}
                                pageCount={pageCount}
                                pageIndex={pageIndex}
                                pageSize={pageSize}
                                setPageIndex={setPageIndex}
                                setPageSize={setPageSize}
                                totalRecords={totalRecords}
                                handleGotoPage={handleGotoPage}
                                errorMessage={errorMessage}
                                handleSort={handleSort}
                                sortBy={sortBy}
                                orderBy={orderBy}
                            />
                        ) : (
                            <p>No data available</p>
                        )}
                    </div>
                </CardBody>
            </Card>
        </div>

        {/* Modal Section */}
        <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={{
                overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" },
                content: {
                    margin: "auto",
                    padding: "20px",
                    maxWidth: "700px",
                    maxHeight: "550px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)",
                },
            }}
            ariaHideApp={false}
            contentLabel="Run Campaign Modal"
        >
            <div className="modalHeader d-flex justify-content-between align-items-center mb-3">
                <h4 className="modalTitle mb-0">Run Campaign</h4>
                <button
                    className="btn btn-link modalCloseBtn"
                    style={{ fontSize: "1.5rem", lineHeight: "1", color: "#007bff" }}
                    onClick={closeModal}
                >
                    <FaRegTimesCircle />
                </button>
            </div>
            <div className="modalBody">
                <Select
                    placeholder="Select Template"
                    options={templateOptions}
                    value={templateValue}
                    onChange={handleTemplateValue}
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            maxHeight: "200px", // Adjust height for scrollable dropdown
                            overflowY: "auto",
                        }),
                    }}
                />
                {templateValue && (
                  <div>
                    <div
                      style={{
                        marginTop: "20px",
                        border: "1px solid #ddd",
                        padding: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <label
                        style={{
                          fontWeight: "bold",
                          marginBottom: "10px",
                          display: "block",
                        }}
                      >
                        Sender (Header) - {senderId}
                      </label>
                    </div>
                    <div
                      style={{
                        marginTop: "20px",
                        border: "1px solid #ddd",
                        padding: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <label
                        style={{
                          fontWeight: "bold",
                          marginBottom: "10px",
                          display: "block",
                        }}
                      >
                        Template Body
                      </label>
                      <textarea
                        value={templateBody}
                        readOnly
                        style={{
                          width: "100%",
                          height: "200px",
                          resize: "none",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                          backgroundColor: "#e9ecef",
                        }}
                      />
                    </div>
                  </div>
                )}
                <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
                <div style={{ textAlign: "center", marginTop: 20 }}>
                    <button
                        className="btn btn-primary font-weight-bold transformBtn agentTransformBtn"
                        type="button"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </ReactModal>
    </div>
);


}

export default SmsCampaign


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <div className="tblScroll someradiush">
          <table {...getTableProps()} className="loanTable bucketWiseTable">
            <thead className="tableHead">
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="tableHeader"
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {// Render the header
                        column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
              {/* <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr> */}
            </thead>
            {/* <thead className="tfootmngbuckt">
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </thead> */}
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="tableTd">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = (val) => typeof val !== "number";
