import React, { useEffect, useMemo } from "react";
//import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
//import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
    // FormattedMessage, 
    injectIntl
} from "react-intl";
//import { useHistory } from "react-router-dom";
//import { Dropdown } from "react-bootstrap";
//import { DropdownMenu2 } from "../../../../_metronic/_partials/dropdowns";
import { useHtmlClassService } from "../../../../_metronic/layout";
import '../css/Chart.scss';
import { useDispatch, useSelector } from "react-redux";
import { intensities } from "../../../store/financierDashboard";


function AreaChartSingle(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.entities.auth.data);
    const intensity = props.intensity;
    const uiService = useHtmlClassService();

    
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseColor: objectPath.get(
                uiService.config,
                `js.colors.theme.base.danger`
            ),
            colorsThemeLightColor: objectPath.get(
                uiService.config,
                `js.colors.theme.light.danger`
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);

    useEffect(() => {
        const element = document.getElementById(`area_chart1`);

        if (!element) {
            return;
        }
        if(intensity) {
            const options = getChartOption(layoutProps, intensity);
            const chart = new ApexCharts(element, options);
            chart.render();
            return function cleanUp() {
                chart.destroy();
            };
        }
    // }, [layoutProps]);
    });



    return (
        <div>
            <div className="report-card-div areaChart">
                <div className={`card card-custom card-stretch gutter-b card-div`}>
                    {/* Body */}
                    <div className="card-body pt-4 position-relative overflow-hidden borderTopRadius">
                        {/* Title */}
                        <div className='col-lg-6 customercon'>Portfolio Churn Trend</div>
                        <div
                            id={`area_chart1`}
                            className="card-rounded-bottom"
                            style={{ height: "150px" }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(AreaChartSingle);

function getChartOption(layoutProps, intensity) {
    console.log(" intensity ===== ", intensity);
    var generateDayWiseTimeSeries = function (baseval, count, yrange) {
        var i = 0;
        var series = [];
        while (i < count) {
            var x = baseval;
            var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

            series.push([x, y]);
            baseval += 86400000;
            i++;
        }
        return series;
    }

    const options = {
        // series: [
        //     {
        //         name: 'Intensity',
        //         data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
        //             min: 10,
        //             max: 60
        //         })
        //     }
        // ],

        series:intensity,

        chart: {
            type: 'area',
            height: 415,
            stacked: true,
            events: {
                selection: function (chart, e) {
                    console.log(new Date(e.xaxis.min))
                }
            },
        },
        colors: ['#008FFB', '#00E396', '#CED4DC'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.6,
                opacityTo: 0.8,
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        },
        xaxis: {
            type: 'datetime'
        },

    };
    return options;
}

