import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { original } from "immer";

const initialState = {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
    trackEmailList: {
        data: null,
        loading: false,
        lastFetch: null,
        message: null,
        status: undefined,
        error: {},
    }
};

const slice = createSlice({
    name: "trackEmail",
    initialState,
    reducers: {
        trackEmailListRequested: (trackEmail, action) => {
            trackEmail.trackEmailList.loading = true;
            trackEmail.trackEmailList.message = null;
            trackEmail.trackEmailList.status = undefined;
            trackEmail.trackEmailList.error = {};
        },
        trackEmailListReceived: (trackEmail, action) => {
            trackEmail.trackEmailList.data = action.payload.data;
            console.log('trackEmailListReceived data here =========', action.payload.data);
            trackEmail.trackEmailList.loading = false;
            trackEmail.trackEmailList.lastFetch = Date.now();
            trackEmail.trackEmailList.message = null;
            trackEmail.trackEmailList.status = "success";
            trackEmail.trackEmailList.error = {};
        },
        trackEmailListRequestFailed: (trackEmail, action) => {
            trackEmail.trackEmailList.loading = false;
            trackEmail.trackEmailList.message = null;
            trackEmail.trackEmailList.status = undefined;
            trackEmail.trackEmailList.error = "Error";
        },

        clearMessagesRequested: (trackEmail) => {
            trackEmail.status = undefined;
            trackEmail.message = null;
            trackEmail.error = {};
        },
        clearUserListStorageRequested: () => initialState,

        currentSelectedRow: (trackEmail, action) => {
            trackEmail.statusManagement.rowData = action.payload;
        }
    },
});

const {
    trackEmailListRequested,
    trackEmailListReceived,
    trackEmailListRequestFailed,

    clearMessagesRequested,
    clearUserListStorageRequested,

    currentSelectedRow,
    statusChange,
} = slice.actions;
export default slice.reducer;

// Action Creators
export const trackEmailList = (userId, setLoanId, searchTelephone, searchBatchNumber) => (
    dispatch,
    getState
) => {
    console.log("Invoking trackEmailList Panel Api", userId, setLoanId, searchTelephone, searchBatchNumber);
    dispatch(
        apiCallBegan({
            url: "/api/v1/service",
            method: "post",
            data: {
                feature: "EMAIL_SEGMENTATION",
                featureType: "EMAIL_SEGMENTAION_TRACK_LIST",
                userId: userId,
                loanId: setLoanId,
                batchNumber: searchBatchNumber,

            },
            onStart: trackEmailListRequested.type,
            onSuccess: trackEmailListReceived.type,
            onError: trackEmailListRequestFailed.type,
        })
    );
};


export const clearMessages = () => (dispatch) => {
    console.log("in clearMessages...");
    dispatch(clearMessagesRequested());
};

export const clearUserListStorage = () => (dispatch) => {
    console.log("in clearUserListStorageRequested...");
    dispatch(clearUserListStorageRequested());
};