export const loanTable = [
    {
        csvColumnName: 'loanId',
        tableColumnName: 'Loan Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'referenceLoanId',
        tableColumnName: 'Reference Loan Id',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'loanType',
        tableColumnName: 'Loan Type',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agencyAllocationDate',
        tableColumnName: 'Agency allocation date',
        mandatory: 'Y',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'loanAmount',
        tableColumnName: 'Loan Amount',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'category',
        tableColumnName: 'Category',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mandateStatus',
        tableColumnName: 'Mandate Status',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'emiPaymentDate',
        tableColumnName: 'EMI Payment Date',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'maturityDate',
        tableColumnName: 'Maturity Date',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'loanTenure',
        tableColumnName: 'Loan Tenure',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    //yes or no agenct id value
    {
        csvColumnName: 'agentId',
        tableColumnName: 'Agent Id',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agencyId',
        tableColumnName: 'Agency Id',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'financierId',
        tableColumnName: 'Financier Id',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'principalOutStanding',
        tableColumnName: 'Principal OutStanding',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'penalty',
        tableColumnName: 'Penalty',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'totalOutStanding',
        tableColumnName: 'Total OutStanding',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'totalEMICount',
        tableColumnName: 'Total EMI Count',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'pendingEMICount',
        tableColumnName: 'Pending EMICount',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'totalEMIAmountPaid',
        tableColumnName: 'Total EMI Amount Paid',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'emiDueDate',
        tableColumnName: 'EMI Due Date',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'emiStartDate',
        tableColumnName: 'EMI Start Date',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'priority',
        tableColumnName: 'Priority',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'neverPenaltyCustomer',
        tableColumnName: 'Never Penalty Customer',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'customerStatus',
        tableColumnName: 'CustomerStatus',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'allocationDate',
        tableColumnName: 'Allocation Date',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'channel',
        tableColumnName: 'Channel',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'allocationDPD',
        tableColumnName: 'Allocation DPD',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'allocationBucket',
        tableColumnName: 'Allocation Bucket',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'loanDisbursementDate',
        tableColumnName: 'Loan Disbursement Date',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'productValue',
        tableColumnName: 'Product Value',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'fullName',
        tableColumnName: 'Full Name',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'address',
        tableColumnName: 'Address',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'addressType',
        tableColumnName: 'Address Type',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'isPrimary',
        tableColumnName: 'Is Primary',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'address1',
        tableColumnName: 'Address2',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'address2',
        tableColumnName: 'Address3',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'latitude',
        tableColumnName: 'Latitude',
        mandatory: 'N',//
        displayOnUI: 'N'
    },
    {
        csvColumnName: 'longitude',
        tableColumnName: 'Longitude',
        mandatory: 'N',//
        displayOnUI: 'N'
    },
    {
        csvColumnName: 'landmark',
        tableColumnName: 'Landmark',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'line1',
        tableColumnName: 'Line1',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'line2',
        tableColumnName: 'line2',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'roomNumber',
        tableColumnName: 'Room Number',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'city',
        tableColumnName: 'City',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'district',
        tableColumnName: 'District',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'taluka',
        tableColumnName: 'Taluka',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'state',
        tableColumnName: 'State',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'division',
        tableColumnName: 'division',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'pincode',
        tableColumnName: 'Pin Code',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile',
        tableColumnName: 'Mobile1',
        mandatory: 'Y',// issue in mobile field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile1',
        tableColumnName: 'Mobile2',
        mandatory: 'N',// issue in mobile field //gaus change to non mandatory at 27/07/2021
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile2',
        tableColumnName: 'Mobile3',
        mandatory: 'N',// issue in mobile field //gaus change to non mandatory at 27/07/2021
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile3',
        tableColumnName: 'Mobile4',
        mandatory: 'N',// issue in mobile field //gaus change to non mandatory at 27/07/2021
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile4',
        tableColumnName: 'Mobile5',
        mandatory: 'N',// issue in mobile field //gaus change to non mandatory at 27/07/2021
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile5',
        tableColumnName: 'Mobile6',
        mandatory: 'N',// issue in mobile field //gaus change to non mandatory at 27/07/2021
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'email',
        tableColumnName: 'email',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agreementDate',
        tableColumnName: 'Agreement Date',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'totalSettlementAmount',
        tableColumnName: 'Total Settlement Amount',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'intensity',
        tableColumnName: 'Intensity',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'npaDate',
        tableColumnName: 'NPA Date',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    }


]
export const AprTable = [
    {
        csvColumnName: 'callDate',
        tableColumnName: 'Call Date',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'interval',
        tableColumnName: 'Interval',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agent',
        tableColumnName: 'Agent',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'reportingTo',
        tableColumnName: 'Reporting To',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'totalCalls',
        tableColumnName: 'Total Calls',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'dialerCalls',
        tableColumnName: 'Dialer Calls',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'outboundCalls',
        tableColumnName: 'Outbound Calls',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'manualCalls',
        tableColumnName: 'Manual Calls',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'transferedCalls',
        tableColumnName: 'Transfered Calls',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'loginTime',
        tableColumnName: 'Login Time',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'breakCount',
        tableColumnName: 'Break Count',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'lunch',
        tableColumnName: 'Lunch',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'dinner',
        tableColumnName: 'Dinner',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'tea',
        tableColumnName: 'Tea',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'meeting',
        tableColumnName: 'Meeting',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'break5',
        tableColumnName: 'Break 5',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'restRoom',
        tableColumnName: 'Rest Room',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'break7',
        tableColumnName: 'Break 7',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'break8',
        tableColumnName: 'Break 8',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'break9',
        tableColumnName: 'Break 9',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'break10',
        tableColumnName: 'Break 10',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'totalBreakDuration',
        tableColumnName: 'Total Break Duration',
        mandatory: 'N',// issue in mobile field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'handleDuration',
        tableColumnName: 'Handle Duration',
        mandatory: 'N',// issue in mobile field //gaus change to non mandatory at 27/07/2021
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'averageHandleDuration',
        tableColumnName: 'Average Handle Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'idleDuration',
        tableColumnName: 'Idle Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'averageIdleDuration',
        tableColumnName: 'Average Idle Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'idleBlockDuration',
        tableColumnName: 'Idle Block Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'ringDuration',
        tableColumnName: 'Ring Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'averageRingDuration',
        tableColumnName: 'Average Ring Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'talkDuration',
        tableColumnName: 'Talk Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'averageTalkDuration',
        tableColumnName: 'Average Talk Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'holdDuration',
        tableColumnName: 'Hold Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'averageHoldDuration',
        tableColumnName: 'Average Hold Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'wrapupDuration',
        tableColumnName: 'Wrapup Duration',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'averageWrapupDuration',
        tableColumnName: 'Average Wrapup Durationt',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
]

export const loanTableForLegal = [
    {
        csvColumnName: 'loanId',
        tableColumnName: 'Loan Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'loanAmount',
        tableColumnName: 'Loan Amount',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'principalOutStanding',
        tableColumnName: 'Principal OutStanding',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'penalty',
        tableColumnName: 'Penalty',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'totalOutStanding',
        tableColumnName: 'Total OutStanding',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'fullName',
        tableColumnName: 'Full Name',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'address',
        tableColumnName: 'Address',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'address1',
        tableColumnName: 'Address2',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'address2',
        tableColumnName: 'Address3',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'landmark',
        tableColumnName: 'Landmark',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'line1',
        tableColumnName: 'Line1',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'line2',
        tableColumnName: 'line2',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'roomNumber',
        tableColumnName: 'Room Number',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'city',
        tableColumnName: 'City',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'district',
        tableColumnName: 'District',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'taluka',
        tableColumnName: 'Taluka',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'state',
        tableColumnName: 'State',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'pincode',
        tableColumnName: 'Pin Code',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile',
        tableColumnName: 'Mobile1',
        mandatory: 'Y',// issue in mobile field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile1',
        tableColumnName: 'Mobile2',
        mandatory: 'N',// issue in mobile field //gaus change to non mandatory at 27/07/2021
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'email',
        tableColumnName: 'email',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agreementDate',
        tableColumnName: 'Agreement Date',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'totalSettlementAmount',
        tableColumnName: 'Total Settlement Amount',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    }
]

export const userTable = [
    {
        csvColumnName: 'email',
        tableColumnName: 'Email',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'password',
        tableColumnName: 'Password',
        mandatory: 'Y',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'fullName',
        tableColumnName: 'Full Name',
        mandatory: 'Y',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'telephone',
        tableColumnName: 'Telephone',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agencyId',
        tableColumnName: 'Agency Id',
        mandatory: 'Y',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'role',
        tableColumnName: 'Role',
        mandatory: 'Y',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'reportingTo',
        tableColumnName: 'Reporting To',
        mandatory: 'Y',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'startDate',
        tableColumnName: 'Start Date',
        mandatory: 'Y',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'endDate',
        tableColumnName: 'End Date',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'city',
        tableColumnName: 'City',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    //yes or no agenct id value
    {
        csvColumnName: 'state',
        tableColumnName: 'State',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'pincode',
        tableColumnName: 'Pincode',
        mandatory: 'N',
        displayOnUI: 'Y'
    }
]

export const allocationTable = [
    {
        csvColumnName: 'loanId',
        tableColumnName: 'Loan Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agentId',
        tableColumnName: 'Agent Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'remarks',
        tableColumnName: 'remarks',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agencyId',
        tableColumnName: 'agency Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'principalOutStanding',
        tableColumnName: 'principal OutStanding',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'totalOutStanding',
        tableColumnName: 'total OutStanding',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'intensity',
        tableColumnName: 'Intensity',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'npaDate',
        tableColumnName: 'NPA Date',
        mandatory: 'N', // issue in email field
        displayOnUI: 'Y'
    }
]

export const dispositionTable = [
    {
        csvColumnName: 'loanId',
        tableColumnName: 'Loan Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agentId',
        tableColumnName: 'Agent Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agencyId',
        tableColumnName: 'Agency Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'dispositionCode',
        tableColumnName: 'Disposition Code',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'dispositionDate',
        tableColumnName: 'Disposition Date',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'dispositionStatus',
        tableColumnName: 'Disposition Status',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'emiAmount',
        tableColumnName: 'Emi Amount',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'penaltyAmount',
        tableColumnName: 'Penalty Amount',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'totalAmount',
        tableColumnName: 'Total Amount',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'collectionDate',
        tableColumnName: 'Collection Date',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'remark',
        tableColumnName: 'remark',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'contactNumber',
        tableColumnName: 'Contact Number',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'email',
        tableColumnName: 'Email',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
]

export const noticeTable = [
    {
        csvColumnName: 'loanId',
        tableColumnName: 'Loan Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile',
        tableColumnName: 'Mobile',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'email',
        tableColumnName: 'Email',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
]

export const emailSegmentation = [
    {
        csvColumnName: 'referenceLoanId',
        tableColumnName: 'Reference Loan Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile',
        tableColumnName: 'Mobile',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'email',
        tableColumnName: 'Email',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
]

export const paidFileTable = [
    {
        csvColumnName: 'loanId',
        tableColumnName: 'loan Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'financier',
        tableColumnName: 'Financier',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'amountCollected',
        tableColumnName: 'amount Collected',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'collectedDate',
        tableColumnName: 'collected Date',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'paymentMode',
        tableColumnName: 'payment Mode',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
]

export const VerificationTable = [
    {
        csvColumnName: 'caseId',
        tableColumnName: 'Case Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'name',
        tableColumnName: 'Full Name',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'address',
        tableColumnName: 'Address',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'mobile',
        tableColumnName: 'Mobile',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agentId',
        tableColumnName: 'AgentId',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agencyId',
        tableColumnName: 'Agency Id',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'financierId',
        tableColumnName: 'FinancierId',
        mandatory: 'N',
        displayOnUI: 'Y'
    }
]
export const VerificationAllocationTable = [
    {
        csvColumnName: 'caseId',
        tableColumnName: 'Case Id',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    // {
    //     csvColumnName: 'name',
    //     tableColumnName: 'Full Name',
    //     mandatory: 'Y',
    //     displayOnUI: 'Y'
    // },
    // {
    //     csvColumnName: 'address',
    //     tableColumnName: 'Address',
    //     mandatory: 'Y',
    //     displayOnUI: 'Y'
    // },
    // {
    //     csvColumnName: 'mobile',
    //     tableColumnName: 'Mobile',
    //     mandatory: 'N',//
    //     displayOnUI: 'Y'
    // },
    {
        csvColumnName: 'agentId',
        tableColumnName: 'AgentId',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'agencyId',
        tableColumnName: 'Agency Id',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'financierId',
        tableColumnName: 'FinancierId',
        mandatory: 'N',
        displayOnUI: 'Y'
    }
]

export const InterviewListTable = [
    {
        csvColumnName: 'srNo',
        tableColumnName: 'Sr.No.',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'date',
        tableColumnName: 'Date',
        mandatory: 'Y',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'source',
        tableColumnName: 'Source',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'postAppliedFor',
        tableColumnName: 'Post applied for',
        mandatory: 'N',//
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'interviewer',
        tableColumnName: 'Interviewer',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'fullName',
        tableColumnName: 'Full Name',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'nationality',
        tableColumnName: 'Nationality',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'address',
        tableColumnName: 'Address',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'contactNo',
        tableColumnName: 'Contact No.',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'alternateNo',
        tableColumnName: 'Alternate No.',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'dateOfBirth',
        tableColumnName: 'Date of Birth',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'age',
        tableColumnName: 'Age',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'gender',
        tableColumnName: 'Gender',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'languagesKnown',
        tableColumnName: 'Languages Known',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'maritalStatus',
        tableColumnName: 'Marital Status',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'emailId',
        tableColumnName: 'Email ID',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'DRANonDRA',
        tableColumnName: 'DRA/NonDRA',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'qualification',
        tableColumnName: 'Qualification',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    // ------------------------------------
    {
        csvColumnName: 'expectedSalary',
        tableColumnName: 'Expected Salary',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'offeredSalary',
        tableColumnName: 'Offered Salary',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'previousSalary',
        tableColumnName: 'Previous Salary',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'dateOfJoining',
        tableColumnName: 'Date of Joining',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'status',
        tableColumnName: 'Status',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'process',
        tableColumnName: 'Process',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    // ----------------------------------------------
    {
        csvColumnName: 'experience',
        tableColumnName: 'Experience',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'referenceName',
        tableColumnName: 'Reference Name',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'referenceContactNo',
        tableColumnName: 'Reference Contact No.',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'hrComment',
        tableColumnName: 'HR Comment',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'operationsComment',
        tableColumnName: 'Operations Comment',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
    {
        csvColumnName: 'managementComment',
        tableColumnName: 'Management Comment',
        mandatory: 'N',
        displayOnUI: 'Y'
    },
]