import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Loader from "../../Loader/Loader";
import LoaderPop from "../../Loader/LoaderPop";
import Moment from 'react-moment';
import "../css/generatedData.scss";
import "../../Loan/css/loan.scss";
import { useTable, useFilters, useGlobalFilter, usePagination, useSortBy, useRowSelect, useExpanded } from 'react-table';
import matchSorter from 'match-sorter'
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import { trackEmailList } from "../../../store/trackEmail";
import TrackExpandableTbl from "./TrackExpandableTbl";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap/Table";
const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: '#000',
    },
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

function TrackEmail(props) {
    const classes = useStyles();
    //console.log('Today page props:', props);
    const history = useHistory();
    const dispatch = useDispatch();
    //const [showAddAgencyForm, setShowAddAgencyForm] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pageLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchLoanId, setSearchLoanId] = useState('');
    const [searchBatchNumber, setSearchBatchNumber] = useState('');
    const [searchTelephone, setSearchTelephone] = useState('');
    const [expanded, setExpanded] = React.useState(false);
    const [selectedsubRows, setSelectedsubRows] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const user = useSelector(state => state.entities.auth.data);
    const loading = useSelector((state) => state.entities.trackEmail.trackEmailList.loading);

    console.log("loadingFirst----",loading)

    const modalDetails = ()=>{
        setModalIsOpen(true);
    }
  

    const columns = React.useMemo(
        () => [
           
            {
                Header:<div style={{ textAlign: 'center' }}>Loan Id</div>,
                accessor:'loanId',
                // accessor: (value) => {
                //     return `DIP${value.id}`
                // },
                // ======================modal is open ==============
                // Cell: ({ cell }) => {
                //   // console.log(" cell ", cell.row.original.userId);
                //   return (
                //     <>
                //       <div className="loanIdDownloadBtnDiv">
                //         <button
                //           className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                //           title="View details"
                //           onClick={() => modalDetails(cell.row.original)}
                //           value={cell.row.original.loanId}
                //         > {cell.row.original.loanId}</button>
                //       </div>
                //     </>
                //   );
                // },
         // ======================modal is closed ==============
                filter: "fuzzyText",
                disableSortBy: true,
                disableFilters: false,
              },
            {
                Header: 'Customer Name',
                accessor: 'customerName',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            
            {
                Header: 'Financier',
                accessor: 'financierName',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
            
            {
                Header: 'Date',
                accessor: 'date',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            
            },
            {
                Header: 'Email Sent',
                accessor: 'emailSent',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
               
            },
           {
                Header: 'Email',
                accessor:'toEmailId',
                filter: 'fuzzyText',
                disableSortBy: true,
                disableFilters: true,
            },
        ],
        []
    )

//   const  trackEmail = useSelector((state) => state.entities.trackEmail);
  const  trackEmail = useSelector((state) => state.entities.trackEmail.trackEmailList.data);

  console.log("trackEmail----",trackEmail)

    useEffect(() => {
        if (trackEmail && trackEmail.length !== 0) {

            setData(trackEmail);
        }
    }, [trackEmail]);


    // useEffect(() => {
    //     if (trackLegalLoanData.length !== 0) {
    //         if (hasMore === true && loading === false) {
    //             dispatch(trackLegal(user.userId, user.agencyId, (offset + setLimit), setLimit, startDate, endDate));
    //         }
    //     }
    // })



    // let errorMsg = "Something went wrong. Please try again.";

    //entity error
    // useEffect(() => {
    //     if (status === "error") {
    //         if (typeof error === "object") {
    //             errorMsg = error.message;
    //         }
    //         else {
    //             errorMsg = error;
    //         }
    //         setErrorAlertMsg(errorMsg);
    //         setShowErrorAlert(true);
    //         disableLoading();
    //         setTimeout(() => {
    //             setErrorAlertMsg(null);
    //             setShowErrorAlert(false);
    //             dispatch(clearMessages());
    //         }, 2000);
    //     }
    // }, [status])

    // useEffect(() => {
    //     if (status === "success" && !loading) {
    //         disableLoading();
    //     }
    // }, [status, loading])

    // useEffect(() => {
    //     if (status === "success" && message !== null && message !== undefined) {
    //         setErrorAlertMsg(message);
    //         setShowErrorAlert(true);
    //         setData(trackLegalLoanDataCopy);
    //         setTimeout(() => {
    //             setErrorAlertMsg(null);
    //             setShowErrorAlert(false);
    //             dispatch(clearMessages());
    //         }, 2000)

    //     }
    // }, [status, message])


    // const refreshTodayLoanInfo = () => {
    //     //console.log('refresh');
    //     if (user) {
    //         dispatch(trackLegal(user.userId, user.agencyId, 0, setLimit, firstDay, lastDay));
    //         enableLoading();
    //         // }
    //     }
    // }

    // const enableLoading = () => {
    //     setLoading(true);
    // };

    // const disableLoading = () => {
    //     setLoading(false);
    // };

    const searchRecord = () => {

        if (searchLoanId || searchTelephone || searchBatchNumber) {
            let setLoanId = searchLoanId.toUpperCase() || null;
            console.log('searchTelephone,searchLoanId--------', searchTelephone, setLoanId, searchBatchNumber)
            dispatch(trackEmailList(user.userId, setLoanId, searchTelephone, searchBatchNumber));
        }
        else {
            let errMsg = 'Please enter atleast one field.';
            setErrorAlertMsg(errMsg);
            setShowErrorAlert(true);

            setTimeout(() => {
                setErrorAlertMsg(null);
                setShowErrorAlert(false);
            }, 2000)
        }
    }


    const renderRowSubComponent = (row) => {
        console.log('renderRowSubComponent sayeed:', row)
        return (
            <div className="">
                <TrackExpandableTbl trackExpandableData={row.original.subRows} />
            </div>
        );
    };



    return (
        <div className="logs-card-div">
            {loading ? <Loader /> : " "}
                <Card className="loanInfoCard">
                    <CardHeader>
                        <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-start flex-column">
                            <div className="d-flex justify-content-center align-items-start flex-column">
                                <h3 className="mb-0">Track Email Info</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4 d-block">
                            {showErrorAlert &&
                                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                    {errorAlertMsg}
                                </Alert>
                            }

                            {showSuccessAlert &&
                                <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                                    {successAlertMsg}
                                </Alert>
                            }
                        </div>
                        {/* <div className="col-lg-3 d-flex justify-content-end align-items-center">
                            {hasMore == true ? (
                                <div className="d-flex justify-content-center align-items-center mr-2">
                                    <div className="spinner mr-8">
                                    </div>
                                </div>
                            ) :
                                (
                                    <div className=" d-flex justify-content-center align-items-center mr-4">
                                        <button className="defaultBtn transformBtn" onClick={refreshTodayLoanInfo}>
                                            <i className="flaticon-refresh refreshIcon flaticon-spin"></i>
                                        </button>
                                    </div>
                                )
                            }
                        </div> */}

                    </CardHeader>
                    <CardHeader>
                        <div className="col-lg-8 pl-0 customSearch d-flex justify-content-start align-items-center">
                            <div className="pl-1 col-lg-3 d-flex justify-content-start align-items-center">
                                <input
                                    value={searchLoanId}
                                    onChange={(e) => {
                                        
                                        setSearchLoanId(e.target.value || '') // Set undefined to remove the filter entirely

                                    }}
                                    placeholder="Loan Id"
                                    className="searchFilter w-100"
                                />
                            </div>
                            {/* <div className="pl-0 col-lg-3 d-flex justify-content-start align-items-center">
                                <input
                                    value={searchTelephone}
                                    onChange={e => {
                                        setSearchTelephone(e.target.value || '') // Set undefined to remove the filter entirely
                                    }}
                                    placeholder="Phone"
                                    className="searchFilter w-100"
                                />
                            </div> */}
                            <div className="pl-1 col-lg-3 d-flex justify-content-start align-items-center">
                                <input
                                    value={searchBatchNumber}
                                    onChange={(e) => {
                                        
                                        setSearchBatchNumber(e.target.value || '') // Set undefined to remove the filter entirely

                                    }}
                                    placeholder="Batch Number"
                                    className="searchFilter w-100"
                                />
                            </div>
                            <div className="col-lg-2">
                                <button
                                    id="button"
                                    type="button"
                                    className="btn btn-primary font-weight-bold upload  ml-4 pt-2 pb-2"
                                    // className="btn btn-primary font-weight-bold upload mr-4 transformBtn" style={{ width: "100%" }}
                                    onClick={searchRecord}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                        {/* <div className="col-lg-2 d-flex mr-4 justify-content-center align-items-center">
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="Show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div> */}

                    </CardHeader>
                    {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardHeader>
                            <div className="col-lg-8 pl-0 d-flex justify-content-start align-items-center">
                                <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-center">
                                    <DatePicker
                                        onChange={value => {

                                            let formatedStartDate = formatDate(value);
                                            if (formatedStartDate) {
                                                setStartDate(new Date(formatedStartDate));
                                            }


                                            //formikProps.setFieldValue("startDate", value)}
                                        }}
                                        value={startDate}
                                        name="startDate"
                                        // minDate={new Date()}
                                        format="dd-MM-y"
                                        className="form-control form-control-solid h-auto readOnlySpan"
                                        clearIcon={null}
                                        calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                                    />
                                </div>
                                <div className="col-lg-3 pl-0 d-flex justify-content-center align-items-center">
                                    <DatePicker
                                        onChange={value => {

                                            let formatedEndDate = formatDate(value);
                                            if (formatedEndDate) {
                                                setEndDate(new Date(formatedEndDate));
                                            }

                                            // if (endDate) {
                                            //     console.log('end date:', endDate);
                                            // }
                                            //formikProps.setFieldValue("endDate", value)}
                                        }}
                                        value={endDate}
                                        name="endDate"
                                        //minDate={new Date(startDate)}
                                        format="dd-MM-y"
                                        className="form-control form-control-solid h-auto readOnlySpan"
                                        clearIcon={null}
                                        calendarIcon={<div><i className="flaticon-calendar-1"></i></div>}
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <button
                                        id="button"
                                        type="button"
                                        className="btn btn-primary font-weight-bold upload transformBtn ml-4 pt-2 pb-2"
                                        // disabled={isEmailUnique}
                                        onClick={searchTrackLegalData}
                                    >
                                        Search
                                </button>
                                </div>
                            </div>
                        </CardHeader>
                    </Collapse> */}
                    <CardBody className="p-4">
                        {data && (
                            <Table columns={columns} data={data}
                                renderRowSubComponent={renderRowSubComponent}
                            />

                        )}


                        {/* <LogDetailsModal showModal={showLogDetailModal} hideModal={hideLogDetailModal} logsData={logDetail} /> */}
                    </CardBody>
                </Card>

                <div className="editModalDiv">
          <Modal
            show={showModal}
            // onHide={props.hideModal}
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            className="editTodayModalStyle attachmentModal " 
            
          >
            <Modal.Header closeButton>
              <h2 className="mb-0">Attachment</h2>
            </Modal.Header>
            {selectedsubRows && selectedsubRows.length == 0 ? <LoaderPop /> : ""}
            <Modal.Body className="p-10">
                { selectedsubRows && selectedsubRows.length > 0 ?
              <BootstrapTable
                striped
                bordered
                hover
                size="sm"
                className="inactable modalTrackTble"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>LoanId</th>
                    <th>Batch Number</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Send Date</th>
                    {/* <th>Email</th> */}
                    <th>Sms Send</th>
                    <th>Email Send</th>
                    <th>Attachment</th>
                    {/* <th>refrence</th> */}
                    {/* <th>batchNumber</th> */}
                    {/* <th>Financeir</th>
                    <th>Attachment</th> */}
                  </tr>
                </thead>
                <tbody>
                {/* {value && value.subRows && value.subRows.map((attchmentItem, i)=> */}
                  {selectedsubRows &&
                    selectedsubRows.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.loanId}</td>
                        <td>{item.batchNumber}</td>
                        <td>{item.mobile}</td>
                        <td>{item.email}</td>
                        <td><Moment format="DD-MM-YYYY hh:mm">{item.sendDate}</Moment></td>
                        {/* <td>{item.startDate}</td> */}
                        <td>{item.smsSend}</td>
                        <td>{item.emailSend}</td>
                        <td> <a href={item.shortUrl}>
                        {/* <i className="flaticon2-download-2"></i> */}
                        <i className="far fa-file-pdf pdfIcontrack"></i>
                        </a>
                        </td>
                        {/* <td>{item.shortUrl}</td> */}
                        {/* <td>{item.services ? item.services : ""}</td>
                        <td>{item.lastDispositionTime}</td> */}
                      </tr>
                    ))}
                </tbody>
              </BootstrapTable> :
              <h4 className="text-center">No Data Found</h4>
              }
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={() => setShowModal(false)}
                id="cancel"
                type="button"
                className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
        show={modalIsOpen}
        // onHide={closeModal}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
        className="editTodayModalStyleBiometric"
      >
        <Modal.Header closeButton>
          <div className="biometricHeaderTab">
            <h2 className="mb-0">Email Segmentation</h2>
            
          </div>
        </Modal.Header>
        <Modal.Body className="p-10">
          <div className="EmployeeBiometric">
            <BootstrapTable
              striped
              bordered
              hover
              size="sm"
              className="inactable"
            >
              <thead>
                <tr>
                  <th>LOAN ID</th>
                  <th>CUSTOMER NAME</th>
                  <th>FINANCIER</th>
                  <th>DATE</th>
                  <th>EMAIL SENT</th>
                  <th>EMAIL</th>
                </tr>
              </thead>
              <tbody>
                
              </tbody>
            </BootstrapTable>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setModalIsOpen(false)}
            id="cancel"
            type="button"
            className="btn btn-primary font-weight-bold upload mr-4 transformBtn"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
        </div>
            {/* </LoadingOverlay> */}
        </div>
    );
}



export default TrackEmail;




// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter }, }) {
    const count = preFilteredRows.length

    return (
        <div>
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    )
}


function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val



// Our table component
function Table({ columns, data, renderRowSubComponent }) {
    console.log(" Table columns ", columns);
    console.log(" Tabledata", data);
    console.log("Table renderRowSubComponent", renderRowSubComponent);

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const history = useHistory();
    // function displayTodayLoanInfo(loanId) {
    //     if (loanId) {
    //         history.push({
    //             pathname: '/loan/details',
    //             loanId: loanId
    //         });
    //     }
    // }

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        //state,
        visibleColumns,
        //preGlobalFilteredRows,
        //setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 5 }
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,//sort data
        useExpanded,
        usePagination,//use pagination
    )

    // We don't want to render all of the rows for this example, so cap
    // it for this use case

    page.map((row) => {
        // console.log(" row =========== ", (parseFloat(row.id)));
        // console.log(" row =========== ", (!parseFloat(row.id)));
        if (!(parseFloat(row.id) % 1)) {
            // console.log(" return ");
        }
    });

    let display = 'content';
    return (
        <>
            <div>
                <div className="tblScroll">
                    <table {...getTableProps()} className="trackTbl tblTrackBatch">
                        <thead className="tableHead">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
                                    {headerGroup.headers.map((column, index) => (
                                        <th key={index}>
                                            <table style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th className="generadatath" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render('Header')}
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? '↓'
                                                                        : '↑'
                                                                    : ''}
                                                            </span>
                                                        </th>
                                                    </tr>
                                                    {/* <tr>
                                                        <th>{column.canFilter ? column.render('Filter') : null}</th>
                                                    </tr> */}
                                                </thead>
                                            </table>
                                        </th>
                                    ))}
                                </tr>
                            ))}

                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        {!(parseFloat(row.id) % 1) && (
                                            <tr style={{ display: display }} className={`${display} ${display = 'content'}`}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    );
                                                })}
                                            </tr>
                                        )}

                                        {row.isExpanded && (
                                            <tr className={`p-0 ${display = 'none'}`}>
                                                <td className="p-0" colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent(row)}
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
                <div className="row m-0">
                    <div className="pagination col-lg-12">
                        <div className="col-lg-2">
                            <button className="defaultPaginationButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>{' '}
                            <button className="defaultPaginationButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>{' '}
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Total Records{' '}
                                <strong>
                                    {rows.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className="col-lg-3">
                            <span>
                                Go to page{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(page)
                                    }}
                                    className="gotoPage"
                                />
                            </span>{' '}
                        </div>
                        <div className="col-lg-3">
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                className="selectDisplayNoOfRecords"
                            >
                                {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                {/* <div>Showing the first 20 results of {rows.length} rows</div>
      <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div> */}
            </div>
        </>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

